import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";
import Swal from "sweetalert2";

const ModalDetallePagos = ({
    idPago,

    setIdPago,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const [input, setInput] = useState({
        razon_rechazado: "",
        checking: false,
    });
    const [pagoAceptado, setPagoAceptado] = useState("Aceptado");
    const [pagosPendientes, setPagosPendientes] = useState([]);
    const [pagosRealizados, setPagosRealizados] = useState([]);
    const [showPagosRealizados, setShowPagosRealizados] = useState(true);
    const [dataDetalle, setDataDetalle] = useState([]);

    const getPagos = async (id) => {
        console.log(id);
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/promoEmpresas/getOnePagoMasivo/${id}`,
            { headers }
        );
        console.log(data);
        // setPagosPendientes(data.data.pendientes);
        setPagosRealizados(data.data);
        setDataDetalle(data.data_detalle);
    };

    useEffect(() => {
        getPagos(idPago);
    }, []);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    // const handleSubmit = async (pagoPendiente) => {
    //     if (pagoAceptado !== "Aceptado") {
    //         if (input.razon_rechazado === "") {
    //             return Swal.fire({
    //                 icon: "warning",
    //                 text: "Debes especificar el motivo de rechazo",
    //                 confirmButtonText: "Aceptar",
    //             });
    //         }
    //     }
    //     setInput({
    //         ...input,
    //         checking: true,
    //     });
    //     console.log({
    //         estado: pagoAceptado,
    //         observacion_rechazado: input.razon_rechazado,
    //         id_pago: pagoPendiente.id,
    //         pago_empresa: pagoPendiente.pago_empresa,
    //     });
    //     const { data } = await axios.put(
    //         `${process.env.REACT_APP_PROXY}/${
    //             IsAlojamiento ? "pedidoCasa" : "pedidoEmbarcacion"
    //         }/actualizarPago/${pedido.id}`,
    //         {
    //             estado: pagoAceptado,
    //             observacion_rechazado: input.razon_rechazado,
    //             id_pago: pagoPendiente.id,
    //             pago_empresa: pagoPendiente.pago_empresa,
    //         },
    //         { headers }
    //     );
    //     console.log(data);
    //     if (data.message === "Registro de pago exitoso!!") {
    //         setRefreshBarra((prev) => !prev);
    //         setIdPago(null);
    //     }
    // };

    const handleClose = () => {
        setIdPago(null);
    };

    return (
        <div className={`relative items-center justify-center ${"flex"}`}>
            <div className="fixed top-0 left-0 flex w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex items-center justify-center w-full px-1 py-3 2xl:mx-auto md:px-2">
                    <div className="relative block flex-col items-center justify-center py-16 bg-white rounded-lg w-96  dark:bg-white  xl:py-12 h-[calc(100vh-10vh)] overflow-y-auto top-16">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-3 right-1 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="px-4 my-3 text-lg font-bold text-left">
                            Detalle de pagos
                        </header>
                        {/* <div className="flex w-full mx-auto ">
                            <div className="flex justify-center w-full">
                                <button
                                    className={`${
                                        !showPagosRealizados
                                            ? "p-3 text-blue-500 border border-blue-500 w-full max-w-[156px] rounded-lg"
                                            : "p-3 max-w-[156px]  text-white bg-blue-500 w-full border rounded-lg"
                                    }`}
                                    onClick={() =>
                                        setShowPagosRealizados(true)
                                    }>
                                    Realizados
                                </button>
                                {authUser.tipo_usuario < 3 && (
                                    <button
                                        className={`${
                                            showPagosRealizados
                                                ? "p-3 ml-2 text-blue-500 border border-blue-500 w-full max-w-[156px] rounded-lg"
                                                : "p-3  text-white bg-blue-500 border w-full max-w-[156px] rounded-lg ml-2"
                                        }`}
                                        onClick={() =>
                                            setShowPagosRealizados(false)
                                        }>
                                        Pendientes
                                    </button>
                                )}
                            </div>
                        </div> */}
                        {showPagosRealizados ? (
                            <>
                                {pagosRealizados.length > 0 ? (
                                    <div className="w-full p-4 ">
                                        {pagosRealizados.map(
                                            (pagoRealizado, index) => (
                                                <div
                                                    key={pagoRealizado.id}
                                                    className="w-full mt-4">
                                                    <h1 className="text-base font-medium ">
                                                        Resumen
                                                    </h1>
                                                    <hr />
                                                    <div className="flex justify-between mt-2">
                                                        <label>
                                                            Método de pago
                                                        </label>
                                                        <span>
                                                            {
                                                                pagoRealizado.metodo_pago
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <label>
                                                            Estado de pago
                                                        </label>
                                                        <span
                                                            className={`${
                                                                pagoRealizado.estado ===
                                                                "Aceptado"
                                                                    ? "text-green-600"
                                                                    : "text-red-500"
                                                            } uppercase font-medium`}>
                                                            {
                                                                pagoRealizado.estado
                                                            }
                                                        </span>
                                                    </div>
                                                    {pagoRealizado.estado !==
                                                        "Aceptado" &&
                                                        pagoRealizado.observacion && (
                                                            <div className="flex flex-col justify-between ">
                                                                <label>
                                                                    Motivo de
                                                                    rechazo
                                                                </label>
                                                                <span className="p-1 my-1 border-y">
                                                                    {
                                                                        pagoRealizado.observacion
                                                                    }
                                                                </span>
                                                            </div>
                                                        )}

                                                    <div className="flex justify-between ">
                                                        <label>Pagado</label>
                                                        <span>
                                                            {
                                                                pagoRealizado.monto_total
                                                            }
                                                            {" PEN"}
                                                            {/* {pedido.tipo_moneda} */}
                                                        </span>
                                                    </div>
                                                    {pagoRealizado.metodo_pago ===
                                                    "Stripe" ? (
                                                        <div className="flex justify-between w-full">
                                                            <label>
                                                                Fecha de
                                                                registro
                                                            </label>
                                                            <span>
                                                                {
                                                                    pagoRealizado.fecha_pago.split(
                                                                        "T"
                                                                    )[0]
                                                                }
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="flex justify-between w-full">
                                                            <label>
                                                                Fecha de carga
                                                                de imagen
                                                            </label>
                                                            <span>
                                                                {
                                                                    pagoRealizado.fecha_pago.split(
                                                                        "T"
                                                                    )[0]
                                                                }
                                                            </span>
                                                        </div>
                                                    )}
                                                    {pagoRealizado.metodo_pago ===
                                                        "Transaccion" &&
                                                        pagoRealizado.comprobante && (
                                                            <div className="flex flex-col font-medium">
                                                                Imagen de
                                                                comprobante
                                                                <img
                                                                    className="mt-3"
                                                                    src={`${process.env.REACT_APP_PROXY}/promoEmpresas/getImagenPago/${pagoRealizado.comprobante}`}
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <h1 className="mt-10 font-medium text-center">
                                        No posee pagos realizados
                                    </h1>
                                )}

                                {dataDetalle.length > 0 && (
                                    <div className="w-full p-4 mt-4">
                                        <h1 className="text-lg font-medium  mb-2">
                                            Detalle
                                        </h1>
                                        <hr />
                                        <table className="w-full border-2 border-gray-600">
                                            <thead>
                                                <tr>
                                                    <th className="p-4 uppercase border-2 border-gray-600">
                                                        Presonal
                                                    </th>
                                                    <th className="p-4 uppercase border-2 border-gray-600">
                                                        Cupon
                                                    </th>
                                                    <th className="p-4 uppercase border-2 border-gray-600">
                                                        Monto
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataDetalle.map((detalle) => (
                                                    <tr key={detalle.id}>
                                                        <td className="p-4 border-2 border-gray-600">
                                                            {detalle.cliente}
                                                        </td>
                                                        <td className="p-4 border-2 border-gray-600">
                                                            {
                                                                detalle.nombre_cupon
                                                            }
                                                        </td>
                                                        <td className="p-4 border-2 border-gray-600">
                                                            {detalle.monto}{" "}
                                                            {
                                                                detalle.tipo_moneda
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </>
                        ) : pagosPendientes.length > 0 ? (
                            <div className="w-full p-4 mt-8 ">
                                {pagosPendientes.map((pagoPendiente) => (
                                    <div
                                        key={pagoPendiente.id}
                                        className="w-full">
                                        {pagoPendiente.pago_empresa ===
                                            "Si" && (
                                            <div className="flex justify-between ">
                                                <label className="font-medium">
                                                    Pago de empresa
                                                </label>
                                            </div>
                                        )}
                                        <div className="flex justify-between ">
                                            <label>Método de pago</label>
                                            <span>{pagoPendiente.metodo}</span>
                                        </div>
                                        <div className="flex justify-between">
                                            <label>Tipo de pago</label>
                                            <span>{pagoPendiente.estado}</span>
                                        </div>
                                        <div className="flex justify-between ">
                                            <label>Pagado</label>
                                            <span>
                                                {pagoPendiente.pagado}{" "}
                                                {/* {pedido.tipo_moneda} */}
                                            </span>
                                        </div>
                                        {pagoPendiente.metodo === "Stripe" ? (
                                            <div className="flex justify-between w-full">
                                                <label>Fecha de registro</label>
                                                <span>
                                                    {
                                                        pagoPendiente.fecha_registro
                                                    }
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="flex justify-between w-full">
                                                <label>
                                                    Fecha de carga de imagen
                                                </label>
                                                <span>
                                                    {
                                                        pagoPendiente.fecha_registro
                                                    }
                                                </span>
                                            </div>
                                        )}
                                        {pagoPendiente.metodo ===
                                            "Transferencia bancaria" && (
                                            <div className="flex flex-col font-medium">
                                                Imagen de comprobante
                                                {pagoPendiente.imagen ? (
                                                    <img
                                                        className="mt-3"
                                                        src={`${process.env.REACT_APP_PROXY}/promoEmpresas/getImagenPago/`}
                                                    />
                                                ) : (
                                                    <Spinner />
                                                )}
                                            </div>
                                        )}
                                        <select
                                            value={pagoAceptado}
                                            onChange={(e) => {
                                                setInput({
                                                    ...input,
                                                    razon_rechazado: "",
                                                });
                                                setPagoAceptado(e.target.value);
                                            }}
                                            className="w-full p-2 mt-4 border rounded-lg">
                                            <option value={"Aceptado"}>
                                                Pago aceptado
                                            </option>
                                            <option value={"Rechazado"}>
                                                Pago rechazado
                                            </option>
                                        </select>
                                        {pagoAceptado === "Rechazado" && (
                                            <div className="flex flex-col w-full mt-4">
                                                <h1>
                                                    Detalle la razón de rechazo
                                                    a continuación
                                                </h1>
                                                <textarea
                                                    value={
                                                        input.razon_rechazado
                                                    }
                                                    className="w-full h-32 p-2 mt-4 border"
                                                    name="razon_rechazado"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        )}
                                        <button
                                            disabled={input.checking}
                                            // onClick={() =>
                                            //     handleSubmit(pagoPendiente)
                                            // }
                                            className="w-full disabled:bg-gray-400 rounded-lg bg-blue-bluecorner py-3 text-white  active:translate-y-[0.125rem] mt-10">
                                            Guardar
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <h1 className="mt-10 font-medium text-center">
                                No posee pagos pendientes
                            </h1>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDetallePagos;
