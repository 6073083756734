import React from "react";

const FlexibleColum = ({ manual }) => {
    return (
        <div className="bg-[#f3f3f3] ml-5 p-5 flex flex-col  -mt-4 border-2">
            <h1 className="text-[#053465] text-3xl font-bold mt-4">
                {manual.nombre}
            </h1>
            <h2 className="w-9/12 mt-10">{manual.descripcion}</h2>
            <span className="mt-48 text-[#FF6600]  text-lg">
                <div>
                    <a
                        href={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/manual/getArchivo/${manual.pdf}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <h1 className="cursor-pointer max-w-max">
                            Haz click aquí para previsualizar y descargar el
                            manual.
                        </h1>
                    </a>
                </div>
            </span>
        </div>
    );
};

export default FlexibleColum;
