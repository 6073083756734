import React from "react";
import { ShowStarsRating } from "../StarsRating";

const CardReview = ({ review, showAlojamientos }) => {
    return (
        <div className="mt-3 border rounded-md p-3 w-full cursor-pointer">
            <h1 className="font-bold text-xl">{review.nombre}</h1>
            <div className="flex justify-between items-center mt-1">
                <h1 className="font-semibold text-base">
                    {review.usuario_calificador}
                </h1>
                <ShowStarsRating width={5} starsQuantity={review.puntuacion} />
            </div>
            {showAlojamientos ? (
                <h2 className="mt-2 text-[#3C3C3C]">
                    del {review?.fecha_inicio?.split("T")[0]} al{" "}
                    {review?.fecha_final?.split("T")[0]}
                </h2>
            ) : (
                <h2 className="mt-2 text-[#3C3C3C]">
                    visitó el dia {review?.fecha?.split("T")[0]}
                </h2>
            )}
            <p className="mt-2">
                {review.comentarios.split(" ").slice(0, 20).join(" ")}
            </p>
        </div>
    );
};

export default CardReview;
