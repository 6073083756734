import React, { lazy } from "react";
import { Elements } from "@stripe/react-stripe-js";
/* import FormStripe from "../FormStripe"; */
import Spinner from "../../Spinner";
import colores from "../../helpers/colores";
import getPriceCard from "../../helpers/getPriceCard";

const FormStripe = lazy(() => import("../FormStripe"));

const PagoParcialEmbarcaciones = ({
    pedido,
    formaPago,
    setFormaPago,
    stripePromise,
    comprobantePreview,
    handleComprobante,
    handlePagoComprobante,
    pagoCompleto,
    setPagoCompleto,
    paying,
}) => {
    return (
        <div>
            <h1
                className={`mt-20 text-[${colores.primario}] text-2xl font-semibold`}
            >
                Tu pedido
            </h1>
            <div
                className={`text-[${colores.primario}] font-semibold text-base md:grid md:grid-cols-[50%_50%] flex flex-col-reverse`}
            >
                <div className="p-10">
                    {pedido.estado === "Pendiente de pago" && (
                        <div className="mb-10">
                            <div className="p-3 border-t-2 border-gray-600 rounded-t-lg border-x-2">
                                <input
                                    type="radio"
                                    name="tipo_pago"
                                    className="mr-2 outline-none cursor-pointer"
                                    checked={
                                        pagoCompleto === "pago_completo"
                                            ? "checked"
                                            : ""
                                    }
                                    onChange={(e) => {
                                        setPagoCompleto("pago_completo");
                                    }}
                                />
                                Pago Único:{" "}
                                <p className="text-gray-500">
                                    Paga el total ahora y listo.
                                </p>
                            </div>
                            <div className="p-3 border-2 border-gray-600 rounded-b-lg">
                                <input
                                    type="radio"
                                    name="tipo_pago"
                                    className="mr-2 outline-none cursor-pointer"
                                    checked={
                                        pagoCompleto === "pago_parcial"
                                            ? "checked"
                                            : ""
                                    }
                                    onChange={(e) => {
                                        setPagoCompleto("pago_parcial");
                                    }}
                                />
                                Paga una parte y otra mas adelante:{" "}
                                <p className="p-1 text-gray-500">
                                    {" "}
                                    Se debe depositar{" "}
                                    {formaPago === "mercado_pago" ||
                                    formaPago === "stripe"
                                        ? (
                                              pedido?.costo_total_alquiler / 2 +
                                              (pedido?.costo_total_alquiler /
                                                  2) *
                                                  0.05
                                          ).toFixed(2)
                                        : (
                                              pedido?.costo_total_alquiler / 2
                                          ).toFixed(2)}{" "}
                                    {pedido?.tipo_moneda}, (Este monto
                                    representa solo el 50% del costo de reserva,
                                    sin servicios adicionales ni garantia). El
                                    segundo 50% más servicios adicionales,
                                    garantía y saldos restantes se pagará 7 días
                                    antes del inicio del alquiler (
                                    {pedido?.fecha.split("T")[0]}
                                    ).
                                </p>
                            </div>
                        </div>
                    )}
                    <div
                        className={`rounded-lg border-2 border-gray-600 text-[${colores.primario}] p-5 text-xl`}
                    >
                        <h1>Elige tu metodo de pago</h1>
                        <hr className="my-5 border-t-2 border-gray-300" />
                        <p className="text-lg font-bold">
                            Pago con tarjeta de crédito/débito cuenta con un
                            costo adicional del 5% del monto a pagar.
                        </p>
                        <select
                            onChange={(e) => {
                                setFormaPago(e.target.value);
                            }}
                            value={formaPago}
                            className="bg-slate-100 mt-7 p-3 max-w-[500px] w-full border-gray-400 border rounded-md"
                        >
                            <option className="p-10" value="stripe">
                                Tarjeta de crédito o débito
                            </option>
                            <option className="p-10" value="transferencia">
                                Transferencia bancaria
                            </option>
                        </select>
                        {formaPago === "stripe" && (
                            <Elements stripe={stripePromise}>
                                <div className="flex w-full mt-5">
                                    <FormStripe
                                        tipo_pedido="Embarcacion"
                                        tipo_pago="Pago parcial"
                                        costos={{
                                            costo_tarjeta: 0,
                                            costo: pedido.pagos.primer_pago
                                                .monto,
                                            metodo: "Stripe",
                                        }}
                                        pedido={pedido}
                                        amount={(
                                            pedido.pagos.primer_pago.monto +
                                            getPriceCard(
                                                pedido.pagos.primer_pago.monto
                                            )
                                        ).toFixed(2)}
                                    />
                                </div>
                            </Elements>
                        )}
                        {formaPago === "transferencia" && (
                            <div>
                                <p className="mt-10 text-base font-bold text-justify">
                                    Realiza tu pago directamente en nuestra
                                    cuenta bancaria. Por favor, usa el número
                                    del pedido como referencia de pago. Tu
                                    pedido no se procesará hasta que se haya
                                    recibido el importe en nuestra cuenta.
                                </p>
                                <div className="w-full mt-4 text-base font-normal bg-white rounded-md">
                                    {pedido.tipo_moneda === "PEN" ? (
                                        <div>
                                            <div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Banco:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        INTERBANK
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Tipo de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        CUENTA CORRIENTE
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Cuenta en Soles:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        2003005063122
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        CCI:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        003-200-003005063122-32
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Titular de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        BLUSYSTEM S.A.C.
                                                    </p>
                                                </div>
                                            </div>
                                            <br />
                                            <div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Banco:
                                                    </p>{" "}
                                                    <p className="ml-3">BCP</p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Tipo de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        CUENTA CORRIENTE
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Cuenta en Soles:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        194-9928450-0-72
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        CCI:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        00219400992845007297
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Titular de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        BLUSYSTEM S.A.C.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Banco:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        INTERBANK
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Tipo de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        CUENTA CORRIENTE
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Cuenta en Dólares:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        2003005063130
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        CCI:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        003-200-003005063130-35
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Titular de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        20610169750
                                                    </p>
                                                </div>
                                            </div>
                                            <br />

                                            <div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Banco:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        INTERBANK
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Tipo de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        CUENTA CORRIENTE
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Cuenta en Dólares:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        194-9915492-1-93
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        CCI:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        00219400991549219398
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Titular de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        20610169750
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <br />
                                    <p className="font-bold">
                                        Numero de pedido: {pedido.id}
                                    </p>
                                </div>

                                <hr className="border-t-2 border-gray-300 my-15" />
                                <h1 className="mt-4 font-normal">
                                    Por favor, adjuntar su comprobante en
                                    formato Imagen
                                </h1>

                                <div className="relative flex mt-4 bg-white border-4 border-dashed rounded-lg w-72 h-72 ">
                                    {comprobantePreview ? (
                                        <div className="w-full h-full rounded-lg ">
                                            <img
                                                className="object-cover w-full h-full rounded-lg"
                                                src={comprobantePreview}
                                                alt="Comprobante de pago"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex items-center justify-center p-3 ">
                                            <div className="flex flex-col items-center justify-center w-full mx-auto ">
                                                <div className="relative flex flex-col items-center justify-center">
                                                    <span className="mt-2 font-bold text-center ">
                                                        Arrastre el comprobante
                                                    </span>
                                                    o
                                                    <span
                                                        className={`text-center text-[${colores.secundario}] font-normal`}
                                                    >
                                                        Sube la imagen desde tu
                                                        dispositivo
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        onChange={handleComprobante}
                                        accept="image/*"
                                        className="absolute top-0 left-0 bg-red-200 opacity-0 cursor-pointer w-72 h-72 file:cursor-pointer"
                                    />
                                </div>
                                <button
                                    className={`payButton bg-[${colores.secundario}] active:translate-y-1 w-full max-w-[288px] disabled:bg-slate-400 hover:disabled:bg-slate-400`}
                                    disabled={!formaPago || paying}
                                    onClick={() => handlePagoComprobante()}
                                >
                                    {paying ? <Spinner width={7} /> : "Pagar"}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="p-10">
                    <table className="w-full border-2 border-gray-600">
                        <thead>
                            <tr>
                                <th
                                    className="p-4 uppercase border-2 border-gray-600"
                                    colSpan={2}
                                >
                                    Producto
                                </th>
                                <th
                                    className="p-4 uppercase border-2 border-gray-600"
                                    colSpan={2}
                                >
                                    Subtotal
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}
                                >
                                    <div className="flex flex-auto">
                                        <img
                                            src={
                                                `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/embarcacion/getImagenPrincipal/` +
                                                pedido?.imagen_principal
                                            }
                                            alt="Imagen attribute"
                                            className="object-cover w-20 h-20 rounded-lg"
                                        />
                                        <div className="ml-3">
                                            <h1 className="font-bold">
                                                {pedido?.embarcacion} - Turno{" "}
                                                {pedido?.turno}
                                            </h1>
                                            <br />
                                            Fecha de reserva:{" "}
                                            {
                                                pedido?.fecha.split("T")[0]
                                            } <br /> A partir de las{" "}
                                            {pedido?.hora_entrada} hs hasta las
                                            {pedido?.hora_salida} hs
                                        </div>
                                    </div>
                                </td>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}
                                >
                                    {pedido.costo_total_alquiler.toFixed(2)}{" "}
                                    {pedido?.tipo_moneda}
                                </td>
                            </tr>
                            {/* <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}>
                                    Costo de IGV
                                </td>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}>
                                    {pedido?.costo_igv.toFixed(2)}{" "}
                                    {pedido?.tipo_moneda}
                                </td>
                            </tr> */}
                            <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}
                                >
                                    Costo de la garantia
                                </td>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}
                                >
                                    {pedido?.costo_garantia.toFixed(2)}{" "}
                                    {pedido?.tipo_moneda}
                                </td>
                            </tr>
                            {/* <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}>
                                    Costo de embarque
                                </td>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}>
                                    {pedido?.costo_embarque.toFixed(2)}{" "}
                                    {pedido?.tipo_moneda}
                                </td>
                            </tr> */}

                            {pedido?.serviciosAdicionales.map((service) => (
                                <tr key={service.id}>
                                    <td
                                        className="p-4 border-2 border-gray-600"
                                        colSpan={2}
                                    >
                                        {service.nombre}
                                    </td>
                                    <td
                                        className="p-4 border-2 border-gray-600"
                                        colSpan={2}
                                    >
                                        {service.costo_total.toFixed(2)}{" "}
                                        {pedido?.tipo_moneda}
                                    </td>
                                </tr>
                            ))}

                            <tr>
                                <td
                                    colSpan={4}
                                    className="p-4 font-bold text-center uppercase border-2 border-gray-600"
                                >
                                    Resumen
                                </td>
                            </tr>

                            <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}
                                >
                                    Costo total:
                                </td>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}
                                >
                                    {pedido?.costo_total} {pedido?.tipo_moneda}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}
                                >
                                    Pagado:
                                </td>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}
                                >
                                    {pedido?.pagado.toFixed(2)}{" "}
                                    {pedido?.tipo_moneda}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}
                                >
                                    Saldo:
                                </td>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}
                                >
                                    {pedido?.saldo} {pedido?.tipo_moneda}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="w-full mt-5">
                        <thead>
                            <tr>
                                <th
                                    className="p-4 uppercase border-2 border-gray-600"
                                    colSpan={2}
                                >
                                    Monto a abonar
                                </th>
                                <th
                                    className="p-4 uppercase border-2 border-gray-600"
                                    colSpan={2}
                                >
                                    Subtotal
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}
                                >
                                    Monto por alquiler:
                                </td>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}
                                >
                                    {formaPago === "stripe"
                                        ? pedido.pagos.primer_pago.monto.toFixed(
                                              2
                                          )
                                        : pedido.pagos.primer_pago.monto.toFixed(
                                              2
                                          )}{" "}
                                    {pedido?.tipo_moneda}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}
                                >
                                    Monto por tarjeta:
                                </td>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}
                                >
                                    {formaPago === "stripe"
                                        ? getPriceCard(
                                              pedido.pagos.primer_pago.monto
                                          ).toFixed(2)
                                        : 0.0}{" "}
                                    {pedido?.tipo_moneda}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PagoParcialEmbarcaciones;
