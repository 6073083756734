import React, { lazy } from "react";
import { Elements } from "@stripe/react-stripe-js";
/* import FormStripe from "../FormStripe"; */
import Spinner from "../../Spinner";
import colores from "../../helpers/colores";
import getPriceCard from "../../helpers/getPriceCard";

const FormStripe = lazy(() => import("../FormStripe"));

const TipoPagoEmpresaAllPromo = ({
    pedido,
    formaPago,
    setFormaPago,
    stripePromise,
    comprobantePreview,
    handleComprobante,
    handlePagoComprobante,
    paying,
    idEmpresa,
}) => {
    return (
        <div>
            <h1 className={`text-[${colores.primario}] text-2xl font-semibold`}>
                Promos usadas
            </h1>
            <div
                className={`text-[${colores.primario}] font-semibold text-base lg1170:grid lg1170:grid-cols-[50%_50%] flex flex-col-reverse`}>
                <div className="p-10">
                    <div
                        className={`rounded-lg border-2 border-gray-600 text-[${colores.primario}] p-5 text-xl`}>
                        <h1>Elige tu metodo de pago</h1>
                        <hr className="my-5 border-t-2 border-gray-300" />
                        <p className="text-lg font-bold">
                            Pago con tarjeta de crédito/débito cuenta con un
                            costo adicional del 5% del monto a pagar.
                        </p>
                        <select
                            onChange={(e) => {
                                setFormaPago(e.target.value);
                            }}
                            value={formaPago}
                            className="bg-slate-100 mt-7 p-3 max-w-[500px] w-full border-gray-400 border rounded-md">
                            <option className="p-10" value="stripe">
                                Tarjeta de crédito o débito
                            </option>
                            <option className="p-10" value="transferencia">
                                Transferencia bancaria
                            </option>
                        </select>
                        {formaPago === "stripe" && (
                            <Elements stripe={stripePromise}>
                                <div className="flex w-full mt-5">
                                    <FormStripe
                                        es_pago_empresa={true}
                                        tipo_pedido={
                                            "pago empresarial promociones"
                                        }
                                        costos={{
                                            costo: pedido.costo_total,
                                            costo_tarjeta: 0,
                                            metodo: "Stripe",
                                        }}
                                        pedido={pedido}
                                        amount={
                                            pedido.costo_total +
                                            getPriceCard(pedido.costo_total)
                                        }
                                        idEmpresa={idEmpresa}
                                        es_pago_empresa_all={true}
                                    />
                                </div>
                            </Elements>
                        )}
                        {formaPago === "transferencia" && (
                            <div>
                                <p className="mt-10 text-base font-bold text-justify">
                                    Realiza tu pago directamente en nuestra
                                    cuenta bancaria. Por favor, usa el número
                                    del pedido como referencia de pago. Tu
                                    pedido no se procesará hasta que se haya
                                    recibido el importe en nuestra cuenta.
                                </p>
                                <div className="w-full mt-4 text-base font-normal bg-white rounded-md">
                                    {pedido.tipo_moneda_costo_total ===
                                    "PEN" ? (
                                        <div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    Banco:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    BANCO DE CRÉDITO DEL PERÚ
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    Tipo de cuenta:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    CUENTA CORRIENTE
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    Cuenta en Soles:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    193 - 2468888 - 0 - 60
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    CCI:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    00219300246888806018
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    Titular de cuenta:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    LA ESQUINA AZUL S.A.C.
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    Banco:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    BANCO DE CRÉDITO DEL PERÚ
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    Tipo de cuenta:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    CUENTA CORRIENTE
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    Cuenta en Dólares:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    193 - 2457006 - 1 - 51
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    CCI:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    00219300245700615115
                                                </p>
                                            </div>
                                            <div className="flex flex-row">
                                                <p className="font-bold">
                                                    Titular de cuenta:
                                                </p>{" "}
                                                <p className="ml-3">
                                                    LA ESQUINA AZUL S.A.C.
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <br />
                                    <p className="font-bold">
                                        Numero de pedido: {pedido.id}
                                    </p>
                                </div>
                                <hr className="border-t-2 border-gray-300 my-15" />
                                <h1 className="mt-4 font-normal">
                                    Por favor, adjuntar su comprobante en
                                    formato Imagen
                                </h1>
                                <div className="relative flex mt-4 bg-white border-4 border-dashed rounded-lg w-72 h-72 ">
                                    {comprobantePreview ? (
                                        <div className="w-full h-full rounded-lg ">
                                            <img
                                                className="object-cover w-full h-full rounded-lg"
                                                src={comprobantePreview}
                                                alt="Comprobante de pago"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex items-center justify-center p-3 ">
                                            <div className="flex flex-col items-center justify-center w-full mx-auto ">
                                                <div className="relative flex flex-col items-center justify-center">
                                                    <span className="mt-2 font-bold text-center ">
                                                        Arrastre el comprobante
                                                    </span>
                                                    o
                                                    <span
                                                        className={`font-normal text-center text-[${colores.secundario}]`}>
                                                        Sube la imagen desde tu
                                                        dispositivo
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <input
                                        type="file"
                                        onChange={handleComprobante}
                                        accept="image/*"
                                        className="absolute top-0 left-0 bg-red-200 opacity-0 cursor-pointer w-72 h-72 file:cursor-pointer"
                                    />
                                </div>
                                <button
                                    className={`payButton bg-[${colores.secundario}] active:translate-y-1 w-full max-w-[288px] disabled:bg-slate-400 hover:disabled:bg-slate-400`}
                                    disabled={!formaPago || paying}
                                    onClick={() => handlePagoComprobante()}>
                                    {paying ? <Spinner width={7} /> : "Pagar"}
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div id="cupones" className="mt-4">
                    <h2 className="text-2xl font-bold mb-2">
                        Cupones Utilizados
                    </h2>
                    <table className="w-full border-2 border-gray-600">
                        <thead>
                            <tr>
                                <th className="p-4 uppercase border-2 border-gray-600">
                                    Cupon
                                </th>
                                <th className="p-4 uppercase border-2 border-gray-600">
                                    Personal
                                </th>
                                <th className="p-4 uppercase border-2 border-gray-600">
                                    Producto
                                </th>
                                <th className="p-4 uppercase border-2 border-gray-600">
                                    Fecha Reserva
                                </th>
                                <th className="p-4 uppercase border-2 border-gray-600">
                                    Monto Usado
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {pedido?.cupones?.map((cupon) =>
                                cupon.contenido.map((detalle, index) => (
                                    <tr key={index}>
                                        <td className="p-4 border-2 border-gray-600">
                                            {cupon.nombre}
                                        </td>
                                        <td className="p-4 border-2 border-gray-600">
                                            {detalle.personal}
                                        </td>
                                        <td className="p-4 border-2 border-gray-600">
                                            {detalle.casa}
                                        </td>
                                        <td className="p-4 border-2 border-gray-600">
                                            {detalle.fecha}
                                        </td>
                                        <td className="p-4 border-2 border-gray-600">
                                            {detalle.monto_usado}{" "}
                                            {detalle.tipo_moneda_monto_usado}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <h2 className="text-2xl font-bold mb-2">
                            Total a Pagar por la Empresa
                        </h2>
                        <table className="w-full border-2 border-gray-600">
                            <thead>
                                <tr>
                                    <th
                                        className="p-4 uppercase border-2 border-gray-600"
                                        colSpan={2}>
                                        Detalles de pago
                                    </th>
                                    <th
                                        className="p-4 uppercase border-2 border-gray-600"
                                        colSpan={2}>
                                        Subtotal
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        className="p-4 border-2 border-gray-600"
                                        colSpan={3}>
                                        <h3>Total a pagar por la empresa</h3>
                                    </td>
                                    <td
                                        className="p-2 border-2 border-gray-600"
                                        colSpan={1}>
                                        <h3 className="">
                                            {pedido.costo_total.toFixed(2)}{" "}
                                            {pedido.tipo_moneda_costo_total}
                                        </h3>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {formaPago === "stripe" && (
                            <span className="">
                                Pagarás {(pedido.costo_total * 0.05).toFixed(2)}{" "}
                                {pedido.tipo_moneda_costo_total} extra por
                                costos de tarjeta !
                            </span>
                        )}
                    </div>
                </div>

                {/* <div className="p-10">
                    <table className="w-full border-2 border-gray-600">
                        <thead>
                            <tr>
                                <th
                                    className="p-4 uppercase border-2 border-gray-600"
                                    colSpan={2}>
                                    Productos
                                </th>
                                <th
                                    className="p-4 uppercase border-2 border-gray-600"
                                    colSpan={2}>
                                    Subtotal
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}>
                                    <div className="flex flex-auto">
                                        <img
                                            src={
                                                `${
                                                    process.env
                                                        .REACT_APP_PROXY_ARCHIVOS
                                                }/bluecorner/v1/${
                                                    pedido.tipo_pedido ===
                                                    "Embarcacion"
                                                        ? "embarcacion"
                                                        : "casa"
                                                }/getImagenPrincipal/` +
                                                pedido?.imagen_principal
                                            }
                                            alt="Imagen attribute"
                                            className="object-cover w-20 h-20 rounded-lg"
                                        />
                                        {pedido.tipo_pedido ===
                                            "Embarcacion" && (
                                            <div className="ml-3">
                                                <h1 className="font-bold">
                                                    {pedido?.embarcacion}
                                                </h1>
                                                Fecha {pedido?.fecha}
                                                <br />
                                                Turno {pedido?.turno}
                                            </div>
                                        )}
                                        {pedido.tipo_pedido === "Casa" && (
                                            <div className="ml-3">
                                                <h1 className="font-bold">
                                                    {pedido?.casa}
                                                </h1>
                                                Desde {pedido?.fecha_inicio}
                                                <br />
                                                Hasta {pedido?.fecha_final}
                                            </div>
                                        )}
                                    </div>
                                </td>

                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={1}>
                                    {pedido.costo_total}{" "}
                                    {pedido?.tipo_moneda_costo_total}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {pedido.costo_total > 0 && (
                        <table className="w-full border-2 mt-4 border-gray-600 bordered">
                            <thead>
                                <tr>
                                    <th
                                        className="p-4 uppercase border-2 border-gray-600"
                                        colSpan={2}>
                                        Detalles de pago
                                    </th>
                                    <th
                                        className="p-4 uppercase border-2 border-gray-600"
                                        colSpan={2}>
                                        Subtotal
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={`text-xl`}>
                                    <td
                                        className="p-4 border-2 border-gray-600"
                                        colSpan={3}>
                                        <h3>Total a pagar por la empresa</h3>
                                    </td>
                                    <td
                                        className="p-2 border-2 border-gray-600"
                                        colSpan={1}>
                                        <h3 className="">
                                            {pedido.costo_total.toFixed(2)}{" "}
                                            {pedido.tipo_moneda_costo_total}
                                        </h3>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {formaPago === "stripe" && (
                        <span className="">
                            Pagarás {(pedido.costo_total * 0.05).toFixed(2)}{" "}
                            {pedido.tipo_moneda_costo_total} extra por costos de
                            tarjeta !
                        </span>
                    )}
                </div> */}
            </div>
        </div>
    );
};

export default TipoPagoEmpresaAllPromo;
