const initialState = {
  allUsers: [],
  usersInPage: { total: 0, data: [] },
  userSelected: {},
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS":
      return {
        ...state,
        usersInPage: action.payload,
      };
    case "USER_SELECTED":
      return {
        ...state,
        userSelected: action.payload,
      };
    case "CLEAR_USER_SELECTED":
      return {
        ...state,
        userSelected: {},
      };
    case "SEARCH_USERS":
      return {
        ...state,
        usersInPage: action.payload,
      };
    case "CLEAR_ALL_USERS":
      return {
        ...state,
        usersInPage: { total: 0, data: [] },
      };
    default:
      return state;
  }
};
