import axios from "axios";
export const GET_ALL_BOATS = "GET_ALL_BOATS";
export const CREATE_LABEL = "CREATE_LABEL";
export const RESET_CREATE_LABEL = "RESET_CREATE_LABEL";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const ONE_LABELS = "ONE_LABELS";
export const RESET_ONE_LABEL = "RESET_ONE_LABEL";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const ENABLE_BOATS = "ENABLE_BOATS";
export const RESET_ENEBLE_BOATS = "RESET_ENEBLE_BOATS";

export const getAllBoats = (
    pageNumber = 1,
    enabled = "Si",
    terminado = "todos",
    usuario = 0
) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/embarcacion/all/`,
            {
                pagina: pageNumber,
                habilitado: enabled,
                nombre: "",
                terminado: terminado,
                id_usuario: usuario,
            },
            { headers }
        );
        return dispatch({
            type: GET_ALL_BOATS,
            payload: json.data,
        });
    };
};

// export const searchProductByName = (enabled, input, terminado) => {
//   return async function (dispatch) {
//     const token = localStorage.getItem("token");
//     const headers = {
//       authorization: token,
//     };
//     const json = await axios.post(
//       `${process.env.REACT_APP_PROXY}/embarcacion/all/`,
//       {
//         pagina: 1,
//         habilitado: enabled,
//         nombre: input,
//         terminado: terminado,
//         id_usuario: 0,
//       },
//       { headers }
//     );
//     return dispatch({
//       type: SEARCH_PRODUCT,
//       payload: json.data,
//     });
//   };
// };

// export const resetCreateLabel = () => {
//     return {
//         type: RESET_CREATE_LABEL,
//     };
// };
export const resetEnebleBoats = () => {
    return {
        type: RESET_ENEBLE_BOATS,
    };
};

export const deleteBoats = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/embarcacion/eliminar/${id}`,
            { headers }
        );
        return dispatch({
            type: ENABLE_BOATS,
            payload: json.data.message,
        });
    };
};
export const enableBoats = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/embarcacion/restaurar/${id}`,
            {},
            { headers }
        );
        return dispatch({
            type: ENABLE_BOATS,
            payload: json.data.message,
        });
    };
};

export const resetOnLabel = () => {
    return async function (dispatch) {
        return dispatch({
            type: RESET_ONE_LABEL,
            payload: [],
        });
    };
};
