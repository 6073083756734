import getFeeByDay from "../getFeeByDay";

const getPricesFromCalendar = (
    date,
    precio_base,
    arrayFees,
    fee_cliente_siempre,
    fee_propietario_siempre
) => {
    let response = {};

    let ambos_fee = {
        fee_cliente: Number(
            (
                precio_base / (1 - fee_cliente_siempre / 100) -
                precio_base
            ).toFixed(2)
        ),
    };

   
    if (arrayFees.length > 0) {
        ambos_fee = getFeeByDay(
            date,
            arrayFees,
            fee_cliente_siempre,
            fee_propietario_siempre,
            precio_base
        );
    }
     console.log(ambos_fee.fee_cliente);
    return (response = {
        hasProperty: true,
        total: precio_base,
        fee_cliente: ambos_fee.fee_cliente,
        tiene_fee_por_fecha: ambos_fee.tiene_fee_por_fecha,
    });
};

export default getPricesFromCalendar;
