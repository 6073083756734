import Swal from "sweetalert2";
import {
    LOGIN,
    LOGOUT,
    REGISTER,
    VERIFY_TOKEN,
    SUMARANUNCIO,
    GET_POINTS,
    MOSTRARCARTEL,
} from "../actions/authActions";

const initialState = {
    checking: true,
    logged: false,
    correo: null,
    tipo_usuario: null,
    id_usuario: null,
    id_propietario: null,
    avatar: null,
    perfil: null,
    puntos: 0,
    casas: 0,
    embarcaciones: 0,
    color_primario: "",
    color_secundario: "",
    logo: "",
    id_empresa: "",
    tamano: "",
    nombre_empresa: "",
    password_cambiado: "",
    dias_prueba: "",
    plan: "",
    tipo: "",
    membresia: "",
    uso_prueba: "",
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            if (action.payload.message === "logeo exitoso") {
                localStorage.setItem("token", action.payload.token);
                return {
                    ...state,
                    checking: false,
                    logged: true,
                    correo: action.payload.data[0].correo,
                    tipo_usuario: action.payload.data[0].tipo_usuario,
                    id_usuario: action.payload.data[0].id,
                    id_propietario: action.payload.data[0].id_propietario,
                    avatar: action.payload.data[0].avatar,
                    puntos: action.payload.data[0].puntos,
                    casas: action.payload.data[0].casas,
                    embarcaciones: action.payload.data[0].embarcaciones,
                    perfil: action.payload.data[0].perfil,
                    logo: action.payload.data[0].logo || "",
                    id_empresa: action.payload.data[0].id_empresa || "",
                    tamano: action.payload.data[0].tamano || "",
                    uso_prueba: action.payload.data[0].uso_prueba || "",

                    dias_prueba: action.payload.data[0].dias_prueba || "",
                    plan: action.payload.data[0].plan || "",
                    tipo: action.payload.data[0].tipo || "",
                    membresia: action.payload.data[0].membresia || "",
                    nombre_empresa: action.payload.data[0].nombre_empresa || "",
                    password_cambiado:
                        action.payload.data[0].password_cambiado || "",

                    color_primario:
                        action.payload.data[0].color_primario || "#053465",
                    color_secundario:
                        action.payload.data[0].color_secundario || "#307FE2",
                    mostrar_resumen:
                        action.payload.data[0].mostrar_resumen || "",
                };
            } else if (
                action.payload.title === "Su cuenta ha sido deshabilitada"
            ) {
                Swal.fire({
                    title: action.payload.title,
                    text: "Por favor contacte con el centro de soporte",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            } else {
                Swal.fire({
                    title: "Ocurrió un error en el logeo",
                    text: action.payload.title,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return { state };
            }

        case VERIFY_TOKEN:
            if (action.payload.error) {
                return {
                    ...state,
                    checking: false,
                };
            } else {
                return {
                    ...state,
                    checking: false,
                    logged: true,
                    correo: action.payload.correo,
                    tipo_usuario: action.payload.tipo_usuario,
                    id_usuario: action.payload.id,
                    id_propietario: action.payload.id_propietario,
                    avatar: action.payload.avatar,
                    puntos: action.payload.puntos,
                    casas: action.payload.casas,
                    embarcaciones: action.payload.embarcaciones,
                    perfil: action.payload.perfil,
                    logo: action.payload.logo || "",
                    id_empresa: action.payload.id_empresa || "",
                    tamano: action.payload.tamano || "",
                    uso_prueba: action.payload.uso_prueba || "",
                    dias_prueba: action.payload.dias_prueba || "",
                    plan: action.payload.plan || "",
                    tipo: action.payload.tipo || "",
                    membresia: action.payload.membresia || "",
                    nombre_empresa: action.payload.nombre_empresa || "",
                    password_cambiado: action.payload.password_cambiado || "",
                    color_primario: action.payload.color_primario || "#053465",
                    color_secundario:
                        action.payload.color_secundario || "#307FE2",
                    mostrar_resumen: action.payload.mostrar_resumen || "",
                };
            }

        case REGISTER:
            return {
                ...state,
                fetching: false,
                msg: "peticion rechazada",
                error: action.payload,
                status: action.payload.status,
            };

        case SUMARANUNCIO:
            return {
                ...state,
                ...action.payload,
            };

        case GET_POINTS:
            return {
                ...state,
                puntos: action.payload,
            };

        case MOSTRARCARTEL:
            return {
                ...state,
                mostrar_resumen: "No",
            };
        case LOGOUT:
            localStorage.removeItem("token");
            return {
                checking: false,
                logged: false,
                correo: null,
                tipo_usuario: null,
                id_usuario: null,
                id_propietario: null,
            };
        default:
            return state;
    }
};
