import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  categorySelected,
  clearCategories,
  deleteCategory,
  getAllCategories,
  getCategoriesPerPage,
} from "../../redux/actions/categoriesActions";
import Swal from "sweetalert2";

import ModalEditCategory from "./ModalEditCategory";
import ModalCreateCategory from "./ModalCreateCategory";
import SearchBar from "./SearchBar";
import { Paginado } from "../helpers/Paginado";
import axios from "axios";
import Spinner from "../Spinner";

const CreateCategory = () => {
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [fakeRefresh, setfakeRefresh] = useState(false);
  const [habilitados, setHabilitados] = useState(true);

  const category = useSelector(
    (state) => state.categoriesReducer.categorySelected
  );

  let categoriesShowingInPage = useSelector(
    (state) => state.categoriesReducer.categoriesInPage
  );
  const allCategories = useSelector(
    (state) => state.categoriesReducer.allCategories
  );
  const dispatch = useDispatch();
  const CATEGORIES_PER_PAGE = 25;

  useEffect(() => {
    dispatch(getCategoriesPerPage(1, "Si"));
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearCategories());
    if (habilitados) dispatch(getCategoriesPerPage(1, "Si"));
    else dispatch(getCategoriesPerPage(1, "No"));
  }, [habilitados, dispatch]);

  const handleDelete = (idCategory) => {
    Swal.fire({
      title: "¿Seguro que quieres eliminarlo?",
      text: "Se enviará la categoría a deshabilitados!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCategory(idCategory));
        Swal.fire({
          title: "Categoría eliminada correctamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        dispatch(getCategoriesPerPage(page, "Si"));
        setPage(page);
      }
    });
  };

  const handleEnable = (id) => {
    Swal.fire({
      title: "¿Seguro que quieres habilitarlo?",
      text: "Se enviará el atributo a habilitados!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, habilitar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`${process.env.REACT_APP_PROXY}/categoria/restaurar/${id}`)
          .then((reponse) => {
            Swal.fire({
              title: "Categoría eliminada correctamente!",
              icon: "success",
              confirmButtonText: "Aceptar",
            });
            dispatch(getCategoriesPerPage(page, "No"));
          });
      }
    });
  };

  const handleEdit = (id) => {
    dispatch(categorySelected(id));
    setModalEditVisible(!modalEditVisible);
  };

  const pagination = (pageNumber) => {
    dispatch(clearCategories());
    if (habilitados) dispatch(getCategoriesPerPage(pageNumber, "Si"));
    else dispatch(getCategoriesPerPage(pageNumber, "No"));
    setPage(pageNumber);
  };

  return (
    <section className="bg-white">
      <h1 className="py-10 text-6xl font-bold text-center">Categorias</h1>

      <div className="flex w-full">
        <button
          className="inline-flex items-center px-2 py-2 mx-auto text-lg font-semibold text-blue-700 border border-blue-700 rounded group hover:bg-blue-700 hover:text-white"
          onClick={() => setModalCreateVisible(!modalCreateVisible)}
        >
          <svg
            className="w-5 h-5 text-blue-700 group-hover:text-white"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            {" "}
            <line x1="12" y1="5" x2="12" y2="19" />{" "}
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
          <span className="items-center">Añadir</span>
        </button>
      </div>
      <div className="container flex items-center justify-between p-1 mx-auto">
        <button
          className={
            habilitados
              ? "inline-block p-2 mx-3 text-green-700 border border-green-500 rounded group hover:bg-green-700 hover:text-white"
              : "inline-block p-2 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white"
          }
          onClick={() => {
            setHabilitados(!habilitados);
            setPage(1);
          }}
        >
          {habilitados
            ? `Habilitados (${categoriesShowingInPage.total})`
            : `Deshabilitados (${categoriesShowingInPage.total})`}
        </button>
        <SearchBar
          setfakeRefresh={setfakeRefresh}
          fakeRefresh={fakeRefresh}
          habilitados={habilitados}
        />
      </div>
      <Paginado
        elementsPerPage={CATEGORIES_PER_PAGE}
        cantTotalElements={categoriesShowingInPage.total}
        pagination={pagination}
      />
      <ModalCreateCategory
        setmodalVisible={setModalCreateVisible}
        modalVisible={modalCreateVisible}
        allCategories={allCategories}
        actualPage={page}
      />
      {category.id && (
        <ModalEditCategory
          setmodalVisible={setModalEditVisible}
          modalVisible={modalEditVisible}
          allCategories={allCategories}
          category={category}
          actualPage={page}
        />
      )}
      <div className="container mx-auto">
        <div className="flex flex-wrap mx-auto">
          <div className="w-full px-4">
            <div className="max-w-full overflow-x-auto">
              {categoriesShowingInPage.total > 0 ? (
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-center bg-blue-700">
                      <th className="w-[4%]  text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                        #
                      </th>
                      <th
                        className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              "
                      >
                        Nombre
                      </th>
                      <th className="  w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                        Descripción
                      </th>
                      <th className="w-[3%]  text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                        Cantidad
                      </th>
                      <th
                        className={
                          habilitados
                            ? "w-1/6  min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent "
                            : "w-1/12 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent "
                        }
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoriesShowingInPage?.data?.map((category, index) => {
                      return (
                        <tr key={category.id}>
                          <td
                            className={
                              habilitados
                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                            }
                          >
                            {page * 25 - 25 + index + 1}
                          </td>
                          <td
                            className={
                              habilitados
                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                            }
                          >
                            {category.nombre}
                          </td>
                          <td
                            className={
                              habilitados
                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                            }
                          >
                            {category.description
                              ? category.description
                              : "No posee descripción"}
                          </td>
                          <td
                            className={
                              habilitados
                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                            }
                          >
                            {category.cantidad ? category.cantidad : 0}
                          </td>
                          <td
                            className={
                              habilitados
                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                            }
                          >
                            {habilitados ? (
                              <div>
                                <button
                                  className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white"
                                  title="Editar"
                                  onClick={() => handleEdit(category.id)}
                                >
                                  <svg
                                    className="w-8 h-8 text-blue-500 group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                    <line x1="16" y1="5" x2="19" y2="8" />
                                  </svg>
                                </button>
                                <button
                                  className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white"
                                  onClick={() => handleDelete(category.id)}
                                  title="Eliminar"
                                >
                                  <svg
                                    className="w-8 h-8 text-red-500 group-hover:text-white"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="4" y1="7" x2="20" y2="7" />{" "}
                                    <line x1="10" y1="11" x2="10" y2="17" />{" "}
                                    <line x1="14" y1="11" x2="14" y2="17" />{" "}
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                  </svg>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button
                                  onClick={() => handleEnable(category.id)}
                                  title="Habilitar"
                                  className="inline-block px-1 py-1 mx-3 text-green-700 border border-green-500 rounded group hover:bg-green-700 hover:white"
                                >
                                  <svg
                                    className="w-8 h-8 text-green-500 group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <polyline points="17 1 21 5 17 9" />{" "}
                                    <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                    <polyline points="7 23 3 19 7 15" />{" "}
                                    <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                  </svg>
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateCategory;
