import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {
    getAllProduct,
    deleteProduct,
    enableProduct,
    resetEnebleProduct,
} from "../../redux/actions/productActions";
import { sumarAnuncio } from "../../redux/actions/authActions";
import SearchBar from "./SearchBar";
import { Paginado } from "../helpers/Paginado";
import Spinner from "../Spinner";
import EditarDetalle from "./EditarDetalle";
import { useNavigate, useParams } from "react-router";
import { normalizeString } from "../helpers/normalizeString";

const HousesAll = ({
    productsAll,
    setProductsAll,
    onboarding,
    setOnboarding,
    setPage,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { idEmpresa, tipoLink } = useParams();
    const authUser = useSelector((state) => state.authReducer);
    const [completed, setCompleted] = useState("todos");
    const [publicados, setPublicados] = useState("todos");
    const [enabled, setenabled] = useState("Si");
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [pageNumber, setpageNumber] = useState(1);
    const LABELS_PER_PAGE = 25;
    const [loading, setLoading] = useState(false);
    const autenticadorId = useSelector((state) => state.authReducer);
    const [showButtons, setShowButtons] = useState(false);
    const [modalEditarDetalle, setModalEditarDetalle] = useState(false);
    const [idProducto, setIdProducto] = useState(0);
    const product = useSelector((state) => state.productReducer.product);
    const token = localStorage.getItem("token");
    const enebleReduxProduct = useSelector(
        (state) => state.productReducer.enableProduct
    );
    const [tipo, setTipo] = useState(
        authUser.tipo_usuario > 2 && authUser.casas > 0
            ? "casa"
            : authUser.tipo_usuario > 2 && authUser.embarcaciones > 0
            ? "embarcacion"
            : "casa"
    );
    const [modalcalendario, setModalCalendario] = useState(false);
    const [producto, setProducto] = useState({
        checking: true,
        data: null,
    });
    const [cargandoRegreso, setCargandoRegreso] = useState(false);
    const getPedido = async (id) => {
        setLoading(true);
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/${tipo}/detalle/${id}/${
                authUser.logged ? authUser.id_usuario : 0
            }`
        );

        if (data.error) {
            navigate(`/dashboard/anuncios/`);
        }
        setProducto({
            checking: false,
            data: {
                ...data,
                tipo_producto: tipo,
            },
        });
        setTimeout(() => {
            setModalEditarDetalle(true);
        }, 2000);
    };

    useLayoutEffect(() => {
        if (token) {
            dispatch(
                getAllProduct(
                    1,
                    enabled,
                    completed,
                    token,
                    tipo,
                    autenticadorId.tipo_usuario === 3
                        ? autenticadorId.id_usuario
                        : autenticadorId.tipo_usuario === 4
                        ? autenticadorId.id_propietario
                        : 0,
                    publicados
                )
            );
        }
    }, [dispatch, tipo, cargandoRegreso]);

    useEffect(() => {
        if (idEmpresa) {
            setTipo(tipoLink);
            getPedido(idEmpresa);
        } else {
            setModalEditarDetalle(false);
            setModalCalendario(false);
            setLoading(false);
        }
    }, [idEmpresa, product]);

    useEffect(() => {
        setLoading(true);
        dispatch(
            getAllProduct(
                1,
                enabled,
                completed,
                token,
                tipo,
                autenticadorId.tipo_usuario === 3
                    ? autenticadorId.id_usuario
                    : autenticadorId.tipo_usuario === 4
                    ? autenticadorId.id_propietario
                    : 0,
                publicados
            )
        );
        setpageNumber(1);
    }, [dispatch, enabled, completed, publicados]);

    useEffect(() => {
        if (enebleReduxProduct === "Restaurado con exito") {
            dispatch(resetEnebleProduct());
            dispatch(
                getAllProduct(
                    1,
                    enabled,
                    completed,
                    token,
                    tipo,
                    autenticadorId.tipo_usuario === 3
                        ? autenticadorId.id_usuario
                        : autenticadorId.tipo_usuario === 4
                        ? autenticadorId.id_propietario
                        : 0,
                    publicados
                )
            );

            Swal.fire({
                title: "Procesado!",
                text: "producto restaurado",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        } else if (enebleReduxProduct === "Registro deshabilitado con exito") {
            dispatch(resetEnebleProduct());

            dispatch(
                getAllProduct(
                    1,
                    enabled,
                    completed,
                    token,
                    tipo,
                    autenticadorId.tipo_usuario === 3
                        ? autenticadorId.id_usuario
                        : autenticadorId.tipo_usuario === 4
                        ? autenticadorId.id_propietario
                        : 0,
                    publicados
                )
            );

            Swal.fire({
                title: "Procesado!",
                text: "producto deshabilitado",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        }
    }, [enebleReduxProduct]);

    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el producto a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteProduct(id, tipo));
                setLoading(true);
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el producto a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(enableProduct(id, tipo));
                setLoading(true);
            }
        });
    };

    const oneproduct = (id, pagina, nombre) => {
        return async function () {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/${tipo}/detalle/${id}/0`
            );
            if (json.data.error) {
                navigate(`/dashboard/anuncios/`);
            }

            if (json.data) {
                if (pagina === -1) {
                    setProducto({
                        checking: false,
                        data: {
                            ...json.data,
                            tipo_producto: tipo,
                        },
                    });
                    setModalCalendario(true);
                    // setModalEditarDetalle(true);
                    navigate(
                        `/${
                            autenticadorId.tipo_usuario > 2
                                ? "perfil"
                                : "dashboard"
                        }/anuncios/editar/${tipo}/${id}/`
                    );
                } else {
                    setOnboarding({
                        ...onboarding,
                        ...json.data,
                        tipo_producto: tipo,
                    });
                    setProductsAll(!productsAll);
                    setPage(Number(pagina));
                }
            }
        };
    };

    const handleEdit = (id, pagina, terminado, nombre) => {
        if (terminado === "Si") {
            navigate(
                `/${
                    autenticadorId.tipo_usuario > 2 ? "perfil" : "dashboard"
                }/anuncios/editar/${tipo}/${id}/`
            );
            // setLoading(true);
            // getPedido(id);
            // setIdProducto(id);
        } else {
            dispatch(oneproduct(id, pagina));

            setLoading(true);
        }
    };
    const precioVariable = (id, pagina, nombre) => {
        dispatch(oneproduct(id, pagina, nombre));
        setLoading(true);
    };

    const pagination = (pageNumber) => {
        setLoading(true);
        setpageNumber(pageNumber);

        dispatch(
            getAllProduct(
                pageNumber,
                enabled,
                completed,
                token,
                tipo,
                autenticadorId.tipo_usuario === 3
                    ? autenticadorId.id_usuario
                    : autenticadorId.tipo_usuario === 4
                    ? autenticadorId.id_propietario
                    : 0,
                publicados
            )
        );
    };

    return (
        <div className="md:pl-[15rem] md818:pl-[12rem] md920:pl-[11rem] lg:pl-[16rem] lg1170:pl-[16rem]  xl:pl-[16rem] 2xl:pl-[18rem] ">
            {modalEditarDetalle && idEmpresa ? (
                <EditarDetalle
                    tipo={tipo}
                    idProducto={idProducto}
                    setModalEditarDetalle={setModalEditarDetalle}
                    setOnboarding={setOnboarding}
                    onboarding={onboarding}
                    setProductsAll={setProductsAll}
                    setPage={setPage}
                    setModalCalendario={setModalCalendario}
                    modalcalendario={modalcalendario}
                    setProducto={setProducto}
                    producto={producto}
                    setLoading={setLoading}
                    setCargandoRegreso={setCargandoRegreso}
                />
            ) : (
                <>
                    <div
                        className={
                            loading
                                ? "hidden"
                                : "flex items-center justify-between w-full px-6  "
                        }>
                        <h1 className="text-4xl  py-10 text-center text-[#3C3C3C]">
                            Anuncios
                        </h1>
                        <div className="mt-4 md:ml-10">
                            <button
                                className="flex items-center px-3 py-2 mx-auto text-lg border rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:text-white"
                                onClick={() => (
                                    setOnboarding({
                                        id_propietario: null,
                                        id: null,
                                        tipo_producto: "",
                                        id_tipo_alojamiento: "",
                                        id_tipo_embarcacion: "",
                                        id_duracion: "",
                                        nombre: "",
                                        descripcion: "",
                                        link360: "",
                                        numero_habitaciones: 1,
                                        numero_personas: 1,
                                        numero_banos: 1,
                                        etiquetas: [],
                                        reglas_adicionales: "",
                                        extra: "",
                                        cancelacion: "",
                                        tipo_contrato: [],
                                        banner: "",
                                        imagenes: [],
                                        alquiler_minimo: 1,
                                        roomAttribute: {},
                                        servicios_adicionales: [],
                                        imagen_principal: "",
                                        tipo_moneda: "PEN",
                                        tipo_moneda_garantia: "PEN",
                                    }),
                                    setProductsAll(false)
                                )}>
                                <svg
                                    className="w-5 h-5 text-blue-bluecorner group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                <span className="items-center hidden text-base sm:block ">
                                    Crea un anuncio
                                </span>
                            </button>
                        </div>
                    </div>
                    <div
                        className={
                            authUser.tipo_usuario > 2 &&
                            authUser.casas === 0 &&
                            authUser.embarcaciones === 0
                                ? "hidden"
                                : "grid w-full grid-cols-1 px-6 mx-auto justify-items-center md:justify-items-stretch md:grid-cols-2"
                        }>
                        <div
                            className={
                                loading ? "hidden" : "flex mb-5 md:mb-0"
                            }>
                            <button
                                disabled={loading}
                                className={
                                    authUser.tipo_usuario > 2 &&
                                    authUser.casas === 0
                                        ? "hidden"
                                        : `${
                                              tipo !== "casa"
                                                  ? "p-3 text-blue-500 border-2 border-blue-500 px-6 w-[156px] rounded-lg hover:bg-white hover:text-blue-500"
                                                  : "p-3 px-6 w-[156px]  text-white bg-blue-500 border-2 rounded-lg border-blue-500"
                                          }`
                                }
                                onClick={() => (
                                    tipo !== "casa" && setLoading(true),
                                    setTipo("casa")
                                )}>
                                Alojamientos
                            </button>
                            <button
                                disabled={loading}
                                className={
                                    authUser.tipo_usuario > 2 &&
                                    authUser.embarcaciones === 0
                                        ? "hidden"
                                        : `${
                                              tipo !== "embarcacion"
                                                  ? "p-3 ml-2 text-blue-500 border-2 border-blue-500 px-6 w-[156px] rounded-lg hover:bg-white hover:text-blue-500"
                                                  : "p-3 px-6 w-[156px] text-white bg-blue-500 border-2 rounded-lg ml-2 border-blue-500"
                                          }`
                                }
                                onClick={() => (
                                    tipo !== "embarcacion" && setLoading(true),
                                    setTipo("embarcacion")
                                )}>
                                Embarcaciones
                            </button>
                        </div>
                        <div
                            className={
                                loading
                                    ? "hidden"
                                    : "flex items-center justify-end "
                            }>
                            <SearchBar
                                setfakeRefresh={setfakeRefresh}
                                fakeRefresh={fakeRefresh}
                                enabled={enabled}
                                completed={completed}
                                tipo={tipo}
                                autenticadorId={autenticadorId}
                                publicado={publicados}
                            />
                            <button className="relative flex items-center justify-center w-8 h-8 ml-5 border rounded-lg md:w-11 md:h-11 border-blue-bluecorner">
                                <svg
                                    onClick={() =>
                                        setShowButtons((prev) => !prev)
                                    }
                                    className="text-blue-500 h-7 w-7"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                    />
                                </svg>
                                {showButtons && (
                                    <div className="border rounded-lg absolute -left-24 top-[35px] z-10 bg-white">
                                        <div
                                            // disabled={allPedidos.checking}
                                            onClick={() => {
                                                // setHabilitados(true);
                                                setShowButtons((prev) => !prev);
                                                setPage(1);
                                                setenabled(
                                                    enabled === "Si"
                                                        ? "No"
                                                        : "Si"
                                                );
                                            }}
                                            className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                            <p
                                                className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                {enabled === "No"
                                                    ? `Habilitados`
                                                    : `Deshabilitadas`}
                                            </p>
                                        </div>
                                        <p className="font-bold">Terminados</p>
                                        <hr className="w-28 mx-auto border-[#053465] my-1"></hr>
                                        <div
                                            // disabled={allPedidos.checking}
                                            onClick={() => {
                                                setCompleted("todos");
                                                setShowButtons((prev) => !prev);
                                            }}
                                            className={
                                                completed === "todos"
                                                    ? "hidden"
                                                    : " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1"
                                            }>
                                            <p
                                                className={
                                                    "p-2 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg"
                                                }>
                                                Todos
                                            </p>
                                        </div>
                                        <div
                                            // disabled={allPedidos.checking}
                                            onClick={() => {
                                                setCompleted("si");
                                                setShowButtons((prev) => !prev);
                                            }}
                                            className={
                                                completed === "si"
                                                    ? "hidden"
                                                    : " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1 "
                                            }>
                                            <p
                                                className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                Completos
                                            </p>
                                        </div>
                                        <div
                                            // disabled={allPedidos.checking}
                                            onClick={() => {
                                                setCompleted("no");
                                                setShowButtons((prev) => !prev);
                                            }}
                                            className={
                                                completed === "no"
                                                    ? "hidden"
                                                    : " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1 "
                                            }>
                                            <p
                                                className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                Incompletos
                                            </p>
                                        </div>
                                        <p className="font-bold">Publicados</p>
                                        <hr className="w-28 mx-auto border-[#053465] my-1"></hr>
                                        <div
                                            // disabled={allPedidos.checking}
                                            onClick={() => {
                                                setPublicados("todos");
                                                setShowButtons((prev) => !prev);
                                            }}
                                            className={
                                                publicados === "todos"
                                                    ? "hidden"
                                                    : " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1 "
                                            }>
                                            <p
                                                className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                Todos
                                            </p>
                                        </div>
                                        <div
                                            // disabled={allPedidos.checking}
                                            onClick={() => {
                                                setPublicados("Publicado");
                                                setShowButtons((prev) => !prev);
                                            }}
                                            className={
                                                publicados === "Publicado"
                                                    ? "hidden"
                                                    : " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1 "
                                            }>
                                            <p
                                                className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                Publicado
                                            </p>
                                        </div>
                                        <div
                                            // disabled={allPedidos.checking}
                                            onClick={() => {
                                                setPublicados("Revision");
                                                setShowButtons((prev) => !prev);
                                            }}
                                            className={
                                                publicados === "Revision"
                                                    ? "hidden"
                                                    : " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1 "
                                            }>
                                            <p
                                                className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                Revision
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>

                    <section className="flex justify-center items-center lg:py-[10px]">
                        <div className="w-full px-11">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full ">
                                    <div className="flex flex-col">
                                        {product.total > 0 && !loading ? (
                                            <div className="w-full ">
                                                <table className="w-full overflow-x-auto mt-14 ">
                                                    <thead className="text-left">
                                                        <tr>
                                                            <th className="w-2/12 min-w-[14rem] md:w-1/12 "></th>
                                                            <th className="w-1/12 min-w-[10rem] hidden md:table-cell">
                                                                Dirección
                                                            </th>
                                                            <th className="w-1/12 min-w-[4rem] md:min-w-[5rem] table-cell">
                                                                Estado
                                                            </th>
                                                            <th className="w-1/12 min-w-[4rem] md:min-w-[5rem] hidden lg1170:table-cell ">
                                                                Publicado
                                                            </th>
                                                            <th className="w-1/12  min-w-[8rem] hidden lg:table-cell ">
                                                                Precio
                                                            </th>

                                                            <th className="w-1/12 text-right min-w-[8rem] hidden md:table-cell pr-10">
                                                                Acciones
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {product.data?.map(
                                                            (pro) => (
                                                                <tr
                                                                    key={pro.id}
                                                                    className="w-full border-t ">
                                                                    <td>
                                                                        <div
                                                                            className="flex items-center py-3 cursor-pointer"
                                                                            onClick={() =>
                                                                                handleEdit(
                                                                                    pro?.id,
                                                                                    pro?.numero_pagina,
                                                                                    pro?.terminado,
                                                                                    pro?.nombre
                                                                                )
                                                                            }>
                                                                            <img
                                                                                src={
                                                                                    `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/${tipo}/getImagenPrincipal/` +
                                                                                    `${
                                                                                        pro.imagen_principal_minificada
                                                                                            ? pro.imagen_principal_minificada
                                                                                            : pro.imagen_principal
                                                                                    }`
                                                                                }
                                                                                alt="Imagen principal"
                                                                                className="object-cover h-10 rounded-lg w-14"
                                                                            />
                                                                            <div className="flex flex-col">
                                                                                <h1 className="mx-3 text-[#3C3C3C]">
                                                                                    {
                                                                                        pro?.nombre
                                                                                    }
                                                                                </h1>
                                                                                <span className="mx-3 text-xs text-gray-400">
                                                                                    ID
                                                                                    #{" "}
                                                                                    {
                                                                                        pro.id
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="hidden md:table-cell">
                                                                        {/* {
                                                                            pro?.direccion_calle
                                                                        }{" "}
                                                                        {
                                                                            pro?.direccion_numero
                                                                        }
                                                                        ,{" "} */}
                                                                        {
                                                                            pro?.ciudad
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                            pro?.pais
                                                                        }
                                                                    </td>

                                                                    <td className="table-cell ">
                                                                        {pro.terminado ===
                                                                        "Si" ? (
                                                                            <div className="w-10 h-10 bg-[#053465] rounded-full flex items-center justify-center">
                                                                                <div className="w-8 h-8 bg-white rounded-full text-sm text-[#053465] flex items-center justify-center">
                                                                                    100
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                className={`w-10 h-10 border-4 border-white  rounded-full flex items-center justify-center ${
                                                                                    pro.numero_pagina <
                                                                                    4
                                                                                        ? "border-t-[#053465]"
                                                                                        : pro.numero_pagina <
                                                                                          7
                                                                                        ? "border-t-[#053465] border-r-[#053465] "
                                                                                        : "border-t-[#053465] border-r-[#053465] border-b-[#053465]"
                                                                                }  `}>
                                                                                <div className="w-8 h-8 bg-white rounded-full text-sm text-[#053465] flex items-center justify-center">
                                                                                    {Math.ceil(
                                                                                        (100 /
                                                                                            12) *
                                                                                            pro.numero_pagina
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                    <td className="hidden lg1170:table-cell">
                                                                        {pro.publicado &&
                                                                            pro.publicado}
                                                                    </td>
                                                                    <td className="hidden lg:table-cell">
                                                                        {
                                                                            pro?.moneda_precio_base
                                                                        }{" "}
                                                                        {
                                                                            pro?.precio_base
                                                                        }
                                                                    </td>

                                                                    <td
                                                                        className={
                                                                            enabled ===
                                                                            "Si"
                                                                                ? "hidden md:flex justify-end py-5 px-2"
                                                                                : "hidden md:flex justify-center py-5 "
                                                                        }>
                                                                        {enabled ===
                                                                        "Si" ? (
                                                                            <div className="flex">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleEdit(
                                                                                            pro?.id,
                                                                                            pro?.numero_pagina,
                                                                                            pro?.terminado,
                                                                                            pro?.nombre
                                                                                        )
                                                                                    }
                                                                                    title="Editar"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-bluecorner group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />
                                                                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                                        <line
                                                                                            x1="16"
                                                                                            y1="5"
                                                                                            x2="19"
                                                                                            y2="8"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                                {pro.terminado ===
                                                                                    "Si" && (
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            precioVariable(
                                                                                                pro.id,
                                                                                                -1,
                                                                                                pro?.nombre
                                                                                            )
                                                                                        }
                                                                                        title="Editar precios"
                                                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                                                        <svg
                                                                                            className="w-6 h-6 text-blue-bluecorner group-hover:text-white"
                                                                                            fill="none"
                                                                                            viewBox="0 0 24 24"
                                                                                            stroke="currentColor">
                                                                                            <path
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"
                                                                                                strokeWidth="2"
                                                                                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                                                            />
                                                                                        </svg>
                                                                                    </button>
                                                                                )}
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleDelete(
                                                                                            pro.id
                                                                                        )
                                                                                    }
                                                                                    title="Eliminar"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-bluecorner group-hover:text-white"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="4"
                                                                                            y1="7"
                                                                                            x2="20"
                                                                                            y2="7"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="10"
                                                                                            y1="11"
                                                                                            x2="10"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="14"
                                                                                            y1="11"
                                                                                            x2="14"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleEnable(
                                                                                            pro.id
                                                                                        )
                                                                                    }
                                                                                    title="Habilitar"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-bluecorner group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="2"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <polyline points="17 1 21 5 17 9" />{" "}
                                                                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                        <polyline points="7 23 3 19 7 15" />{" "}
                                                                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : product.total === 0 ? (
                                            <div className="mt-10 text-xl text-center">
                                                Aun no tienes ningun anuncio
                                            </div>
                                        ) : (
                                            <div className="mt-5">
                                                <Spinner />
                                            </div>
                                        )}
                                        <div
                                            className={
                                                loading
                                                    ? "hidden"
                                                    : "container flex items-center justify-between p-1 mx-auto"
                                            }>
                                            <Paginado
                                                elementsPerPage={
                                                    LABELS_PER_PAGE
                                                }
                                                cantTotalElements={
                                                    product.total
                                                }
                                                pagination={pagination}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>
    );
};

export default HousesAll;
