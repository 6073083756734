import axios from "axios";
export const GET_ALL_LABELS = "GET_ALL_LABELS";
export const CREATE_LABEL = "CREATE_LABEL";
export const RESET_CREATE_LABEL = "RESET_CREATE_LABEL";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const ONE_LABELS = "ONE_LABELS";
export const RESET_ONE_LABEL = "RESET_ONE_LABEL";
export const SEARCH_LABELS = "SEARCH_LABELS";
export const ENABLE_LABELS = "ENABLE_LABELS";
export const RESET_ENEBLE_LABELS = "RESET_ENEBLE_LABELS";

export const createLabel = (payload) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };

        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/etiqueta/`,
            payload,
            { headers }
        );

        return dispatch({
            type: CREATE_LABEL,
            payload: json.data.message,
        });
    };
};

export const editLabel = (id, payload) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };

        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/etiqueta/actualizar/${id}`,
            payload,
            { headers }
        );

        return dispatch({
            type: CREATE_LABEL,
            payload: json.data.message,
        });
    };
};

export const getAllLabels = (pageNumber = 1, enabled = "Si") => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };

        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/etiqueta/all/${pageNumber}/${enabled}/`,
            { headers }
        );

        return dispatch({
            type: GET_ALL_LABELS,
            payload: json.data,
        });
    };
};

export const searchLabelsByName = (enabled, input) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };

        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/etiqueta/all/1/${enabled}/?nombre=${input}`,
            { headers }
        );
        return dispatch({
            type: SEARCH_LABELS,
            payload: json.data,
        });
    };
};

export const resetCreateLabel = () => {
    return {
        type: RESET_CREATE_LABEL,
    };
};
export const resetEnebleLabel = () => {
    return {
        type: RESET_ENEBLE_LABELS,
    };
};

export const deleteLabels = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };

        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/etiqueta/eliminar/${id}`,
            { headers }
        );

        return dispatch({
            type: ENABLE_LABELS,
            payload: json.data.message,
        });
    };
};
export const enableLabels = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };

        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/etiqueta/restaurar/${id}`,
            {},
            { headers }
        );

        return dispatch({
            type: ENABLE_LABELS,
            payload: json.data.message,
        });
    };
};

export const oneLabels = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };

        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/etiqueta/detalle/${id}`,
            { headers }
        );

        return dispatch({
            type: ONE_LABELS,
            payload: json.data,
        });
    };
};
export const resetOnLabel = () => {
    return async function (dispatch) {
        return dispatch({
            type: RESET_ONE_LABEL,
            payload: [],
        });
    };
};
