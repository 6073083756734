import React from "react";

const ModalDetallesPedido = ({ pedido, modalDetalles, setModalDetalles }) => {
    const handleClose = () => {
        setModalDetalles((prev) => !prev);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalDetalles ? "flex" : "hidden"
            }`}
        >
            <div className="fixed top-0 w-[150%] h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex items-center justify-center px-4 py-3 2xl:mx-auto md:px-2">
                    <div className="relative flex flex-col items-center justify-center px-4 py-16 bg-white rounded-lg w-96 md:w-5/12 dark:bg-white md:px-24 xl:py-12 xl:px-24  overflow-auto h-[calc(100vh-5rem)]">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                        >
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mb-3 text-2xl font-bold">
                            Detalles de pedido
                        </header>
                        <div className="mt-5 text-[#053465] font-semibold text-md w-full p-3 overflow-auto">
                            <h1 className="text-2xl font-semibold">
                                Tu pedido
                            </h1>
                            <table className="w-full mt-5">
                                <thead>
                                    <tr>
                                        <th
                                            className="p-2 uppercase border"
                                            colSpan={2}
                                        >
                                            Producto
                                        </th>
                                        <th
                                            className="p-2 uppercase border"
                                            colSpan={2}
                                        >
                                            Subtotal
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            <h1 className="font-bold">
                                                {pedido.casa}
                                            </h1>
                                            Desde{" "}
                                            {pedido.fecha_inicio.split("T")[0]}
                                            <br />
                                            Hasta{" "}
                                            {pedido.fecha_final.split("T")[0]}
                                        </td>
                                        <td className="p-3 border" colSpan={1}>
                                            {pedido.costo_alquiler}{" "}
                                            {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                    {pedido.extras.map((extra) => {
                                        if (extra.tipo === "+")
                                            return (
                                                <tr
                                                    key={extra.id}
                                                    className="text-green-600"
                                                >
                                                    <td
                                                        className="p-2 border "
                                                        colSpan={3}
                                                    >
                                                        {extra.texto}
                                                    </td>
                                                    <td
                                                        className="p-2 border "
                                                        colSpan={1}
                                                    >
                                                        {extra.costo}{" "}
                                                        {pedido.tipo_moneda}
                                                    </td>
                                                </tr>
                                            );
                                        else return null;
                                    })}
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            Fee de Bluecorner
                                        </td>
                                        <td className="p-2 border" colSpan={1}>
                                            {pedido.costo_fee_bc}{" "}
                                            {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                    {pedido.extras.map((extra) => {
                                        if (extra.tipo === "-")
                                            return (
                                                <tr
                                                    key={extra.id}
                                                    className="text-red-500"
                                                >
                                                    <td
                                                        className="p-2 border"
                                                        colSpan={3}
                                                    >
                                                        {extra.texto}
                                                    </td>
                                                    <td
                                                        className="p-2 border "
                                                        colSpan={1}
                                                    >
                                                        {extra.tipo}{" "}
                                                        {extra.costo}{" "}
                                                        {pedido.tipo_moneda}
                                                    </td>
                                                </tr>
                                            );
                                        else return null;
                                    })}
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            Costo de IGV
                                        </td>
                                        <td className="p-2 border" colSpan={1}>
                                            {pedido.costo_igv}{" "}
                                            {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            Costo total alquiler:{" "}
                                        </td>
                                        <td className="p-2 border" colSpan={1}>
                                            {pedido.costo_total_alquiler}{" "}
                                            {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={4}
                                            className="p-2 font-bold text-center uppercase border"
                                        >
                                            Garantia
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            Costo de la garantia
                                        </td>
                                        <td className="p-2 border" colSpan={1}>
                                            {pedido.costo_garantia}{" "}
                                            {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={4}
                                            className="p-2 font-bold text-center uppercase border "
                                        >
                                            Servicios adicionales
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={1} className="p-2 border">
                                            Nombre de servicio
                                        </td>
                                        <td colSpan={1} className="p-2 border">
                                            Costo
                                        </td>
                                        <td colSpan={1} className="p-2 border">
                                            Costo Fee BC
                                        </td>
                                        <td colSpan={1} className="p-2 border">
                                            Costo total
                                        </td>
                                    </tr>
                                    {pedido.serviciosAdicionales.map(
                                        (service) => (
                                            <tr key={service.id}>
                                                <td
                                                    className="p-2 border"
                                                    colSpan={1}
                                                >
                                                    {service.nombre}
                                                </td>
                                                <td
                                                    className="p-2 border"
                                                    colSpan={1}
                                                >
                                                    {service.costo}
                                                </td>
                                                <td
                                                    className="p-2 border"
                                                    colSpan={1}
                                                >
                                                    {service.costo_fee_bc}
                                                </td>
                                                <td
                                                    className="p-2 border "
                                                    colSpan={1}
                                                >
                                                    {service.costo_total}{" "}
                                                    {pedido.tipo_moneda}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            Costo total servicios adicionales:
                                        </td>
                                        <td className="p-2 border" colSpan={1}>
                                            {
                                                pedido.costo_total_servicios_adicionales
                                            }{" "}
                                            {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={4}
                                            className="p-2 font-bold text-center uppercase border"
                                        >
                                            Resumen
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            Costo tarjeta:
                                        </td>
                                        <td className="p-2 border" colSpan={1}>
                                            {pedido.costo_tarjeta}{" "}
                                            {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            Costo total:
                                        </td>
                                        <td className="p-2 border" colSpan={1}>
                                            {pedido.costo_total}{" "}
                                            {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            Pagado:
                                        </td>
                                        <td className="p-2 border" colSpan={1}>
                                            {pedido.pagado} {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2 border" colSpan={3}>
                                            Saldo:
                                        </td>
                                        <td className="p-2 border" colSpan={1}>
                                            {pedido.saldo} {pedido.tipo_moneda}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button
                            className="w-full rounded-2xl border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                            onClick={handleClose}
                        >
                            CERRAR DETALLES
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDetallesPedido;
