import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import puntosDetalles from "../../../helpers/puntosDetalles";
import {
    getUserPoints,
    verifyToken,
} from "../../../../redux/actions/authActions";
import ModalHousesCoins from "../../../ModalHousesCoins";
import numberToCommas from "../../../helpers/numberToCommas";

const DataProprietor = ({ tipo = "datos" }) => {
    const dispatch = useDispatch();
    const [siguiente, setSiguiente] = useState("Guardar");
    const [selected, setSelected] = useState(tipo);
    const [propietario, setPropietario] = useState(null);
    const [contraseña, setContraseña] = useState({
        currentPassword: "",
        newPassword: "",
    });
    const [preview, setPreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [ojoActual, setOjoActual] = useState(false);
    const [ojoNueva, setOjoNueva] = useState(false);
    const usuarioId = useSelector((state) => state.authReducer);
    const [userPoints, setUserPoints] = useState({
        puntos: 0,
        puntos_expirar: [],
    });
    const [modalHousesCoins, setModalHousesCoins] = useState(false);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const bancos = [
        "BBVA",
        "Banco de Comercio",
        "Banco de Crédito del Perú",
        "Banco Interamericano de Finanzas (BanBif)",
        "Banco Pichincha",
        "Citibank Perú",
        "Interbank",
        "MiBanco",
        "Scotiabank Perú",
        "Banco GNB Perú",
        "Banco Falabella",
        "Banco Ripley",
        "Banco Santander Perú",
        "Alfin Banco",
        "Bank of China",
        "ICBC PERU BANK",
    ];
    const documento = ["DNI", "NIF", "RUC", "Pasaporte", "Cédula Extranjera"];

    const handleChangeImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(selectedFile);
            return;
        }
        setSelectedFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview(objectUrl);
    };

    const onepropertie = async (id) => {
        if (usuarioId.tipo_usuario < 3) {
            const jsonAdmin = await axios.get(
                `${process.env.REACT_APP_PROXY}/autenticacion/detalle/${usuarioId.id_usuario}`,
                { headers }
            );

            setPropietario(jsonAdmin.data.data);
        } else {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}${
                    usuarioId.tipo_usuario === 4
                        ? "/subpropietario/detalle/"
                        : usuarioId.tipo_usuario === 3
                        ? "/propietario/detalle/"
                        : "/cliente/detalle/"
                }${usuarioId.id_usuario}`,
                { headers }
            );

            setPropietario(json.data.data);
        }
    };

    const getPoints = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/cliente/getReferidos/${usuarioId.id_usuario}`,
            { headers }
        );

        setUserPoints(data);
    };

    useEffect(() => {
        onepropertie();
        dispatch(getUserPoints(usuarioId.id_usuario));
        getPoints();
    }, []);

    const handleUpdate = async (e) => {
        e.preventDefault();
        const form = new FormData();

        form.append("correo", propietario.correo?.trim());
        form.append("nombre", propietario.nombre?.trim());
        form.append("apellido_paterno", propietario.apellido_paterno?.trim());
        form.append("apellido_materno", propietario.apellido_materno?.trim());
        form.append(
            "tipo_documentacion",
            propietario.tipo_documentacion?.trim()
        );
        form.append("dni", propietario.dni?.trim());
        form.append("celular", propietario.celular?.trim());
        form.append("direccion", propietario.direccion?.trim());
        form.append("avatar_usuario", selectedFile);

        if (usuarioId.tipo_usuario < 3 && selected === "datos") {
            if (propietario.nombre.trim() === "") {
                Swal.fire({
                    title: "Debe ingresar su nombre",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }
            if (propietario.apellido_paterno.trim() === "") {
                Swal.fire({
                    title: "Debe ingresar su apellido paterno",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }
            if (
                !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
                    propietario.correo
                )
            ) {
                Swal.fire({
                    title: "Debe ingresar un correo correcto",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }

            setSiguiente("Guardando...");
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/autenticacion/actualizarPerfil/${usuarioId.id_usuario}`,
                form,
                { headers }
            );

            if (data.message === "actuaizacion exitoso") {
                dispatch(verifyToken());
                Swal.fire({
                    title: "Datos actualizados correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setSiguiente("Guardar");
                onepropertie();
            }
        }

        if (selected === "datos" && usuarioId.tipo_usuario > 2) {
            if (propietario.nombre.trim() === "") {
                Swal.fire({
                    title: "Debe ingresar su nombre",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }
            if (propietario.apellido_paterno.trim() === "") {
                Swal.fire({
                    title: "Debe ingresar su apellido paterno",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }
            if (
                !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
                    propietario.correo
                )
            ) {
                Swal.fire({
                    title: "Debe ingresar un correo correcto",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }
            if (propietario.celular.trim() === "") {
                Swal.fire({
                    title: "Debe ingresar su numero de celular",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }
            if (
                usuarioId.tipo_usuario === 3 &&
                propietario.direccion.trim() === ""
            ) {
                Swal.fire({
                    title: "Debe ingresar su dirección",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }
            setSiguiente("Guardando...");
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}${
                    usuarioId.tipo_usuario === 4
                        ? "/subpropietario/actualizar/"
                        : usuarioId.tipo_usuario === 3
                        ? "/propietario/actualizar/"
                        : "/cliente/actualizar/"
                }${usuarioId.id_usuario}`,
                form,
                { headers }
            );

            if (data.message === "actuaizacion exitoso") {
                dispatch(verifyToken());
                Swal.fire({
                    title: "Datos actualizados correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setSiguiente("Guardar");
                onepropertie();
            }
        }
        if (selected === "bancario") {
            setSiguiente("ACTUALIZANDO...");

            if (
                propietario.numero_cuenta?.trim() === "" ||
                propietario.numero_cuenta === null
            ) {
                setSiguiente("Guardar");

                Swal.fire({
                    title: "Debe ingresar un numero de cuenta",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }
            if (
                propietario.numero_cuenta_interbancaria?.trim() === "" ||
                propietario.numero_cuenta_interbancaria === null
            ) {
                setSiguiente("Guardar");

                Swal.fire({
                    title: "Debe ingresar un numero de cuenta",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                return;
            }

            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/propietario/actualizarBanco/${usuarioId.id_usuario}`,
                propietario,
                { headers }
            );

            if (data.message === "actuaizacion exitoso") {
                Swal.fire({
                    title: "Datos actualizados correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setSiguiente("Guardar");
                onepropertie();
            }
        }
        if (selected === "contraseña") {
            if (usuarioId.tipo_usuario < 3) {
                if (contraseña.newPassword !== "") {
                    const { data } = await axios.put(
                        `${process.env.REACT_APP_PROXY}/autenticacion/actualizarPasswordByAdmin/${usuarioId.id_usuario}`,
                        {
                            password: contraseña.newPassword,
                        },
                        { headers }
                    );

                    if (data.message === "actuaizacion exitoso") {
                        Swal.fire({
                            title: "Contraseña actualizada correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        setContraseña({
                            currentPassword: "",
                            newPassword: "",
                        });
                    }
                } else {
                    Swal.fire({
                        title: "La contraseña no puede estar vacia",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }
            } else if (
                contraseña.currentPassword !== "" &&
                contraseña.newPassword !== ""
            ) {
                setSiguiente("ACTUALIZANDO...");
                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}${
                        usuarioId.tipo_usuario === 4
                            ? "/subpropietario/actualizarPassword/"
                            : usuarioId.tipo_usuario === 3
                            ? "/propietario/actualizarPassword/"
                            : "/cliente/actualizarPassword/"
                    }${usuarioId.id_usuario}`,
                    contraseña,
                    { headers }
                );

                if (data.title === "Password actual ingresado es incorrecto") {
                    Swal.fire({
                        title: "Contraseña actual ingresada es incorrecta",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }

                if (data.message === "Contraseña actualizada correctamente") {
                    Swal.fire({
                        title: "Contraseña actualizada correctamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                    setContraseña({
                        currentPassword: "",
                        newPassword: "",
                    });
                    setSiguiente("Guardar");
                    onepropertie();
                }
                setSiguiente("Guardar");
            } else {
                Swal.fire({
                    title: "Por favor llene todos los campos",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };

    const handleModalBluecoins = () => {
        setModalHousesCoins(!modalHousesCoins);
    };

    return (
        <div className="w-full md:pl-[13rem] md818:pl-[14rem] md920:pl-[14rem] lg:pl-[17rem] p-4">
            {modalHousesCoins && (
                <ModalHousesCoins
                    modalVisible={modalHousesCoins}
                    setModalVisible={setModalHousesCoins}
                    userPoints={userPoints}
                    cliente={propietario}
                />
            )}
            <h1 className="py-5 text-4xl text-left w-11/12 xs:w-8/12 mx-auto lg1170:px-20 text-[#3C3C3C]">
                {tipo === "datos"
                    ? "Mi perfil"
                    : tipo === "bancario"
                    ? "Cuenta bancaria"
                    : "Contraseña"}
            </h1>
            {propietario && (
                <div className="mx-auto text-left text-[#3C3C3C]">
                    <div className="mt-2 tab-content" id="tabs-tabContent">
                        <div className="flex flex-col-reverse grid-cols-2 lg:grid lg:w-11/12 lg1170:w-8/12 md:mx-auto">
                            <div
                                className={
                                    selected === "datos"
                                        ? "tab-pane fade show active w-11/12 xs:w-8/12 mx-auto"
                                        : "hidden"
                                }
                                id="tabs-home"
                                role="tabpanel"
                                aria-labelledby="tabs-home-tab">
                                <div className="w-full mt-3">
                                    <label className="">
                                        Nombre
                                        <span className="text-xl text-red-600">
                                            *
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        value={propietario?.nombre}
                                        name="userName"
                                        onChange={(event) =>
                                            setPropietario({
                                                ...propietario,
                                                nombre: event.target.value,
                                            })
                                        }
                                        placeholder="Nombre"
                                        // disabled={editar ? false : true}
                                        className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none "
                                    />
                                </div>
                                <div className="w-full mt-3">
                                    <label className="">
                                        Apellido paterno
                                        <span className="text-xl text-red-600">
                                            *
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        value={propietario?.apellido_paterno}
                                        name="father_lastName"
                                        onChange={(event) =>
                                            setPropietario({
                                                ...propietario,
                                                apellido_paterno:
                                                    event.target.value,
                                            })
                                        }
                                        // disabled={editar ? false : true}
                                        placeholder="Apellido paterno"
                                        className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                    />
                                </div>
                                <div className="w-full mt-3">
                                    <label className="">
                                        Apellido materno{" "}
                                    </label>
                                    <input
                                        value={propietario?.apellido_materno}
                                        type="text"
                                        name="mother_lastName"
                                        onChange={(event) =>
                                            setPropietario({
                                                ...propietario,
                                                apellido_materno:
                                                    event.target.value,
                                            })
                                        }
                                        // disabled={editar ? false : true}
                                        placeholder="Apellido materno"
                                        className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                    />
                                </div>
                                {usuarioId.tipo_usuario > 2 && (
                                    <>
                                        <div className="w-full mt-3">
                                            <label className="">
                                                Tipo de documento
                                            </label>
                                            <select
                                                onChange={(event) =>
                                                    setPropietario({
                                                        ...propietario,
                                                        tipo_documentacion:
                                                            event.target.value,
                                                    })
                                                }
                                                name="userType"
                                                value={
                                                    propietario?.tipo_documentacion
                                                }
                                                className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none">
                                                <option
                                                    className="bg-transparent"
                                                    value="">
                                                    {" "}
                                                    Seleccionar{" "}
                                                </option>
                                                {documento.map((doc, i) => {
                                                    return (
                                                        <option
                                                            key={i}
                                                            className="bg-transparent"
                                                            value={doc}>
                                                            {" "}
                                                            {doc}{" "}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="w-full mt-3">
                                            <label className="">
                                                Numero de documento{" "}
                                                <span className="text-xl text-red-600">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                type="text"
                                                value={propietario?.dni}
                                                name="userDni"
                                                onChange={(event) =>
                                                    setPropietario({
                                                        ...propietario,
                                                        dni: event.target.value,
                                                    })
                                                }
                                                // disabled={editar ? false : true}
                                                placeholder="Dni "
                                                className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="w-full mt-3">
                                    <label className="">
                                        Correo electrónico{" "}
                                        <span className="text-xl text-red-600">
                                            *
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        value={propietario?.correo}
                                        name="userEmail"
                                        onChange={(event) =>
                                            setPropietario({
                                                ...propietario,
                                                correo: event.target.value,
                                            })
                                        }
                                        // disabled={editar ? false : true}
                                        placeholder="Correo electrónico"
                                        className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                    />
                                </div>
                                {usuarioId.tipo_usuario > 2 && (
                                    <div className="w-full mt-3">
                                        <label className="">
                                            Teléfono{" "}
                                            <span className="text-xl text-red-600">
                                                *
                                            </span>{" "}
                                        </label>
                                        <input
                                            // disabled={editar ? false : true}
                                            type="text"
                                            value={propietario?.celular}
                                            name="userCelular"
                                            onChange={(event) =>
                                                setPropietario({
                                                    ...propietario,
                                                    celular: event.target.value,
                                                })
                                            }
                                            placeholder="Celular"
                                            className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                        />
                                    </div>
                                )}
                                {usuarioId.tipo_usuario === 3 && (
                                    <div className="w-full mt-3">
                                        <label className="">
                                            Dirección{" "}
                                            <span className="text-xl text-red-600">
                                                *
                                            </span>{" "}
                                        </label>
                                        <input
                                            type="text"
                                            value={propietario?.direccion}
                                            name="userDireccion"
                                            onChange={(event) =>
                                                setPropietario({
                                                    ...propietario,
                                                    direccion:
                                                        event.target.value,
                                                })
                                            }
                                            // disabled={editar ? false : true}
                                            placeholder="Direccion de usuario"
                                            className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                        />
                                    </div>
                                )}

                                <button
                                    disabled={
                                        siguiente === "Guardar" ? false : true
                                    }
                                    className="w-full flex items-center justify-center mx-auto rounded-lg text-center  bg-blue-500 py-3  text-white hover:bg-blue-400 active:translate-y-[0.125rem]  mt-3 mb-14 disabled:bg-gray-400"
                                    onClick={handleUpdate}>
                                    {siguiente}
                                </button>
                            </div>
                            {selected === "datos" && (
                                <div className="flex flex-col items-center justify-center w-full px-4 text-center h-max">
                                    <div className="relative flex flex-col select-none">
                                        <div className="relative">
                                            <input
                                                onChange={handleChangeImage}
                                                type="file"
                                                accept="image/*"
                                                className="absolute left-0 z-50 w-full h-full bg-red-200 rounded-full opacity-0 cursor-pointer select-none file:cursor-pointer"
                                            />
                                            {preview ? (
                                                <img
                                                    src={preview}
                                                    className="rounded-full p-[0.050rem] w-72 h-72 border-[3px] object-cover cursor-pointer border-blue-bluecorner"
                                                    alt="foto de perfil"
                                                />
                                            ) : (
                                                <img
                                                    src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${propietario.avatar}`}
                                                    className="rounded-full p-[0.050rem] w-72 h-72 border-[3px] object-cover cursor-pointer border-blue-bluecorner"
                                                    alt="foto de perfil"
                                                />
                                            )}
                                        </div>
                                        <span className="relative mt-2 cursor-pointer text-blue-bluecorner">
                                            Cambiar foto de perfil{" "}
                                            <span className="text-xl text-red-600">
                                                *
                                            </span>
                                            <input
                                                onChange={handleChangeImage}
                                                type="file"
                                                className="absolute left-0 z-50 h-full bg-red-200 opacity-0 cursor-pointer select-none file:cursor-pointer"
                                            />
                                        </span>
                                    </div>
                                    {usuarioId.tipo_usuario !== 3 &&
                                        usuarioId.tipo_usuario !== 4 && (
                                            <div>
                                                {" "}
                                                {usuarioId.tipo_usuario > 2 && (
                                                    <div>
                                                        {userPoints?.referidos
                                                            ?.total_referidos >=
                                                            5 && (
                                                            <button
                                                                onClick={() =>
                                                                    window.open(
                                                                        `/alojamientos/30329/federal`,
                                                                        "_blank"
                                                                    )
                                                                }
                                                                className="p-3 mt-4 border rounded-lg border-blue-bluecorner hover:bg-blue-bluecorner hover:text-white active:translate-y-1">
                                                                Quiero canjear
                                                                el premio
                                                            </button>
                                                        )}

                                                        <div
                                                            onClick={
                                                                handleModalBluecoins
                                                            }
                                                            className="cursor-pointer text-blue-bluecorner font-medium flex flex-col grid-cols-[70%_30%] sm:grid-cols-[75%_25%] gap-1 mb-3 mt-6">
                                                            <div className="relative w-full h-4 bg-gray-300 rounded-md">
                                                                <div
                                                                    className="bg-blue-600 h-4 rounded-md animated-bar max-w-[100%]"
                                                                    style={{
                                                                        width: `${
                                                                            (userPoints
                                                                                ?.referidos
                                                                                ?.total_referidos *
                                                                                100) /
                                                                            5
                                                                        }%`,
                                                                    }}></div>
                                                                <span className="absolute font-bold left-1">
                                                                    {" "}
                                                                    {numberToCommas(
                                                                        userPoints
                                                                            ?.referidos
                                                                            ?.total_referidos
                                                                    )}{" "}
                                                                </span>
                                                                <span className="absolute font-bold right-1">
                                                                    {numberToCommas(
                                                                        5
                                                                    )}{" "}
                                                                </span>
                                                            </div>
                                                            <div className="flex flex-col mt-6 text-center">
                                                                {Math.max(
                                                                    0,
                                                                    5 -
                                                                        userPoints
                                                                            ?.referidos
                                                                            ?.total_referidos
                                                                ) === 0 ? (
                                                                    <>
                                                                        <span>
                                                                            ¡Ya
                                                                            tienes
                                                                            los{" "}
                                                                            <span className="font-bold">
                                                                                {/* {puntosDetalles.nombre_coins} */}
                                                                                referidos
                                                                            </span>{" "}
                                                                            necesarios
                                                                            para
                                                                            obtener
                                                                            un
                                                                        </span>
                                                                        <span className="text-2xl font-bold md:text-3xl">
                                                                            100%{" "}
                                                                        </span>

                                                                        <div className="flex justify-center">
                                                                            <span>
                                                                                de{" "}
                                                                                <b>
                                                                                    descuento
                                                                                    en
                                                                                    el
                                                                                    valor
                                                                                    del
                                                                                    alquiler
                                                                                    en
                                                                                    la
                                                                                    casa
                                                                                    Federal!
                                                                                </b>
                                                                            </span>
                                                                            <p></p>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span>
                                                                            Solo
                                                                            te
                                                                            faltan{" "}
                                                                            {numberToCommas(
                                                                                Math.max(
                                                                                    0,
                                                                                    5 -
                                                                                        userPoints
                                                                                            ?.referidos
                                                                                            ?.total_referidos
                                                                                )
                                                                            )}{" "}
                                                                            <span className="font-bold">
                                                                                {/* {puntosDetalles.nombre_coins}
                                                                                 */}
                                                                                referidos
                                                                            </span>{" "}
                                                                            para
                                                                            obtener
                                                                            un
                                                                        </span>
                                                                        <span className="text-2xl font-bold md:text-3xl">
                                                                            100%{" "}
                                                                        </span>
                                                                        <div className="flex justify-center">
                                                                            <p>
                                                                                <b>
                                                                                    descuento
                                                                                    en
                                                                                    el
                                                                                    valor
                                                                                    del
                                                                                    alquiler
                                                                                    en
                                                                                    la
                                                                                    cas
                                                                                    Federal!
                                                                                </b>
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                selected === "bancario"
                                    ? "tab-pane fade show active w-11/12 xs:w-8/12 mx-auto"
                                    : "hidden"
                            }
                            id="tabs-profile"
                            role="tabpanel"
                            aria-labelledby="tabs-profile-tab">
                            <div className="w-full mt-3">
                                <label className="">Banco </label>
                                <select
                                    onChange={(event) =>
                                        setPropietario({
                                            ...propietario,
                                            nombre_banco: event.target.value,
                                        })
                                    }
                                    name="userType"
                                    defaultValue={propietario?.nombre_banco}
                                    className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none">
                                    <option className="bg-transparent" value="">
                                        {" "}
                                        Seleccionar{" "}
                                    </option>
                                    {bancos.map((banco, i) => {
                                        return (
                                            <option
                                                key={i}
                                                className="bg-transparent"
                                                value={banco}>
                                                {" "}
                                                {banco}{" "}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="w-full mt-3 ">
                                <label className="">Tipo de cuenta</label>

                                <select
                                    onChange={(event) =>
                                        setPropietario({
                                            ...propietario,
                                            tipo_cuenta: event.target.value,
                                        })
                                    }
                                    name="tipo_cuenta"
                                    defaultValue={propietario?.tipo_cuenta}
                                    className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none">
                                    <option value="Ahorros"> Ahorros </option>
                                    <option value="Corriente">
                                        {" "}
                                        Corriente{" "}
                                    </option>
                                </select>
                            </div>

                            <div className="w-full mt-3 ">
                                <label className="">Moneda</label>

                                <select
                                    onChange={(event) =>
                                        setPropietario({
                                            ...propietario,
                                            moneda: event.target.value,
                                        })
                                    }
                                    name="moneda"
                                    defaultValue={propietario?.moneda}
                                    className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none">
                                    <option value="Soles"> Soles </option>
                                    <option value="Dolares"> Dolares </option>
                                </select>
                            </div>
                            <div className="w-full mt-3 ">
                                <label className="">Número de cuenta</label>

                                <input
                                    type="text"
                                    name="numero_cuenta"
                                    defaultValue={propietario?.numero_cuenta}
                                    onChange={(event) =>
                                        setPropietario({
                                            ...propietario,
                                            numero_cuenta: event.target.value,
                                        })
                                    }
                                    placeholder="Número de cuenta"
                                    className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                />
                            </div>
                            <div className="w-full mt-3 ">
                                <label className="">
                                    Número de cuenta interbancaria
                                </label>

                                <input
                                    type="text"
                                    name="numero_cuenta_interbancaria"
                                    defaultValue={
                                        propietario?.numero_cuenta_interbancaria
                                    }
                                    onChange={(event) =>
                                        setPropietario({
                                            ...propietario,
                                            numero_cuenta_interbancaria:
                                                event.target.value,
                                        })
                                    }
                                    placeholder="Número de cuenta interbancaria"
                                    className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                />
                            </div>

                            <button
                                disabled={
                                    siguiente === "Guardar" ? false : true
                                }
                                className="w-full xs:w-7/12 flex items-center justify-center mx-auto rounded-lg text-center  bg-blue-500 py-3  text-white hover:bg-blue-400 active:translate-y-[0.125rem]  mt-3 mb-3"
                                onClick={handleUpdate}>
                                {siguiente}
                            </button>
                        </div>
                        <div
                            className={
                                selected === "contraseña"
                                    ? "tab-pane fade show active w-11/12 xs:w-8/12 mx-auto"
                                    : "hidden"
                            }
                            id="tabs-messages"
                            role="tabpanel"
                            aria-labelledby="tabs-profile-tab">
                            <div
                                className={
                                    usuarioId.tipo_usuario < 3
                                        ? "hidden"
                                        : "w-full mt-3 "
                                }>
                                <label className="">Contraseña actual</label>

                                <input
                                    value={contraseña.currentPassword}
                                    type={`${ojoActual ? "text" : "password"}`}
                                    name="tipo_cuenta"
                                    onChange={(event) =>
                                        setContraseña({
                                            ...contraseña,
                                            currentPassword: event.target.value,
                                        })
                                    }
                                    placeholder="Contraseña actual"
                                    className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                />
                            </div>
                            <div className="w-full mt-4 ">
                                <label className="">Nueva contraseña:</label>

                                <input
                                    value={contraseña.newPassword}
                                    type={`${ojoNueva ? "text" : "password"}`}
                                    name="tipo_cuenta"
                                    onChange={(event) =>
                                        setContraseña({
                                            ...contraseña,
                                            newPassword: event.target.value,
                                        })
                                    }
                                    placeholder="Nueva contraseña"
                                    className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                />
                            </div>
                            <button
                                disabled={
                                    siguiente === "Guardar" ? false : true
                                }
                                className="w-full xs:w-7/12 flex items-center justify-center mx-auto rounded-lg text-center  bg-blue-500 py-3  text-white hover:bg-blue-400 active:translate-y-[0.125rem]  mt-3 mb-3"
                                onClick={handleUpdate}>
                                {siguiente}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DataProprietor;
