const dateFromGuiontoSlash = (date) => {
    //Recibe 2022-10-24 retorna 24-10-2022
    let newDate = date?.split("-");

    newDate = newDate?.reverse().join("/");

    return newDate;
};

export default dateFromGuiontoSlash;
