import React, { useState } from "react";
import Timeline from "react-calendar-timeline";
import moment from "moment";
import "react-calendar-timeline/lib/Timeline.css";

const PriceCalendarTimeline = ({ setPage, actualPage }) => {
    const [fecha, setFecha] = useState({ value: 0, previous: 0 });
    const groups = [{ id: 1, title: "Costo Base" }];

    let aux = new Date(2022, 5, 15).getTime();
    let aux2 = new Date(1655743500000);

    console.log(aux2);

    const items = [
        {
            id: 1,
            group: 1,
            title: "item 1",
            start_time: moment(),
            end_time: moment().add(1, "day"),
        },
        {
            id: 2,
            group: 1,
            title: "item 2",
            start_time: new Date(2022, 5, 0),
            end_time: new Date(2022, 5, 1),
        },
    ];
    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    // const dateOne = dates[0].toLocaleDateString("es-ES", options);

    console.log(aux);
    return (
        <div>
            <div className=" w-full mx-auto mt-20">
                <Timeline
                    groups={groups}
                    items={items}
                    defaultTimeStart={moment().add(-12, "hour")}
                    defaultTimeEnd={moment().add(12, "hour")}
                    onCanvasClick={(groupId, time, e) => {
                        console.log(
                            new Date(time).toLocaleDateString("es-ES", options)
                        );
                    }}
                />
            </div>
            <div className="flex items-center justify-center mt-20">
                <button
                    onClick={() => setPage(actualPage - 1)}
                    className="p-2 pl-5 pr-5 mr-2 text-lg text-gray-500 min-w-[118px] bg-transparent border-2 border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 ">
                    Anterior
                </button>

                {/* <button
                    disabled={!siguiente}
                    onClick={() => nextPage()}
                    className="p-2 pl-5 pr-5 text-lg text-gray-500 bg-transparent border-2 min-w-[118px] border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 disabled:bg-white disabled:border-black">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button> */}
            </div>
        </div>
    );
};

export default PriceCalendarTimeline;
