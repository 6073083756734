import { PRECIO_SOLES } from "./precio_soles";

const priceToMonedaElegida = (
    total_price,
    moneda_precio_base,
    redondear = true
) => {
    const moneda_elegida = localStorage.getItem("moneda");
    const monedaActual = moneda_precio_base;

    if (moneda_elegida === "PEN") {
        if (monedaActual === "PEN")
            return !redondear ? total_price : Math.ceil(total_price);
        if (monedaActual === "USD")
            return !redondear
                ? Number(total_price * PRECIO_SOLES.venta)
                : Math.ceil(Number(total_price * PRECIO_SOLES.venta));
    } else {
        if (monedaActual === "PEN")
            return !redondear
                ? Number(total_price / PRECIO_SOLES.compra)
                : Math.ceil(Number(total_price / PRECIO_SOLES.compra));
        if (monedaActual === "USD")
            return !redondear ? total_price : Math.ceil(total_price);
    }
};

export default priceToMonedaElegida;
