import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import numberToCommas from "../../helpers/numberToCommas";
import puntosDetalles from "../../helpers/puntosDetalles";
import Spinner from "../../Spinner";
import ButtonGenerarLinkPago from "./ButtonGenerarLinkPago";
import ModalDetallePagos from "./ModaDetallePagos";
import ModalDisponibilidad from "./ModalDisponibilidad";

const DetallesPrincipales = ({
    pedido_id,
    setPedidosPage,
    setDetallesPrincipales,
    showAlojamientos,
    porcentajeBarra,
    setPorcentajeBarra,
    handleDetallesAlquiler,
    handleLink,
    setModalDetallePagos,
    shouldReview,
    devolucionGarantia,
    handleChatLink,
    handleDelete,
    modalDisponibilidad,
    setModalDisponibilidad,
    modalDetallePagos,
    estado,
    setEstado,
    pedido,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [refreshBarra, setRefreshBarra] = useState(false);
    const [pedidoSelected, setPedidoSelected] = useState(null);
    const authUser = useSelector((state) => state.authReducer);
    const porcentaje = [5, 25, 40, 60, 80, 100];
    const estados = [
        "Confirmar disponibilidad",
        "Pendiente de pago",
        "Pago parcial",
        "Pago completo",
        "Reseña de casa",
        "Devolución de garantía",
    ];
    const navigate = useNavigate();

    const getPedidoSelected = async (id) => {
        setPedidoSelected(null);
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/${
                showAlojamientos ? "pedidoCasa" : "pedidoEmbarcacion"
            }/detalle/${id}`,
            { headers }
        );
        setEstado(estados.indexOf(data.estado) + 1);
        const porcentajeActual =
            porcentaje[estados.indexOf(data.estado) + 1 - 1];
        setPorcentajeBarra(porcentajeActual);
        setPedidoSelected(data);
    };

    useEffect(() => {
        getPedidoSelected(pedido_id);
    }, [refreshBarra]);
    console.log("pedidoSelected", pedidoSelected);
    return (
        <div>
            {pedidoSelected ? (
                <div className="p-4 pb-20 mt-8 w-full  md:pl-52 lg:w-11/12 lg:pl-72">
                    <div
                        className="flex items-center text-lg cursor-pointer md:mt-16 md:pl-28"
                        onClick={() => {
                            setPedidosPage((prev) => !prev);
                            setDetallesPrincipales((prev) => !prev);
                            setPedidoSelected({});
                            setEstado(null);
                        }}>
                        <svg
                            className="w-6 h-6 -ml-2 text-black"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <polyline points="15 18 9 12 15 6" />
                        </svg>
                        <span className="mx-1">Regresar</span>
                    </div>
                    <div className=" md:w-8/12 md:mx-auto">
                        <h1 className="text-lg font-medium mt-7 flex items-center gap-2">
                            {showAlojamientos
                                ? pedidoSelected.casa
                                : pedidoSelected.embarcacion}{" "}
                            <span>
                                <svg
                                    onClick={() =>
                                        window.open(
                                            `${
                                                showAlojamientos
                                                    ? `/alojamientos/${pedidoSelected.id_casa}/${pedidoSelected.casa}`
                                                    : `/embarcaciones/${pedidoSelected.id_embarcacion}/${pedidoSelected.embarcacion}`
                                            }`,
                                            "_blank"
                                        )
                                    }
                                    className="h-6 w-6 hover:scale-105 cursor-pointer text-blue-400"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />{" "}
                                    <polyline points="15 3 21 3 21 9" />{" "}
                                    <line x1="10" y1="14" x2="21" y2="3" />
                                </svg>
                            </span>
                        </h1>
                        <h2 className="mt-2">{pedidoSelected.cliente}</h2>
                        <span className="mt-2 text-2xl font-bold text-blue-400">
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(
                                authUser.tipo_usuario === 4 ||
                                    authUser.tipo_usuario === 3
                                    ? pedidoSelected.costo_alquiler
                                    : pedidoSelected.costo_total
                            )}{" "}
                            (Total alquiler)
                        </span>
                        <div
                            className={
                                pedidoSelected.costo_asumido_empresa > 0
                                    ? "flex items-center mb-2"
                                    : "hidden"
                            }>
                            <div
                                className={`  w-7 h-7 md:w-10 md:h-10 rounded-full mt-2 ${
                                    pedidoSelected.pagado_empresa !== "No"
                                        ? "bg-green-500"
                                        : "bg-gray-300"
                                } top-1/2  `}></div>
                            <p
                                className={`w-28 tracking-widest   text-xs md:text-sm ml-3 mt-2 ${
                                    pedidoSelected.pagado_empresa !== "No"
                                        ? "text-green-500"
                                        : "text-gray-300"
                                }`}>
                                Pago empresa
                            </p>
                        </div>
                        {authUser.tipo_usuario !== 3 &&
                            authUser.tipo_usuario !== 4 &&
                            authUser.tipo_usuario !== 6 &&
                            authUser.tipo_usuario !== 7 && (
                                <div>
                                    {pedidoSelected.descuento_puntos === 0 &&
                                        pedidoSelected.posibles_puntos > 0 && (
                                            <div className="">
                                                {puntosDetalles.nombre_coins}{" "}
                                                obtenidos{" "}
                                                {pedido.is_empresa === "No"
                                                    ? pedidoSelected.posibles_puntos
                                                    : 0}
                                            </div>
                                        )}
                                </div>
                            )}

                        {/* // CAMBIO ACA */}

                        <div className="mt-10 mb-7 ">
                            <div className="relative h-32 px-5 overflow-x-auto overflow-y-hidden ">
                                {porcentajeBarra && (
                                    <div className="flex absolute mt-16 xs:w-[45rem] w-[28rem] xl:w-[60rem] mx-auto h-1 md:h-2 bg-gray-300 top-3 md:top-4 ">
                                        <div
                                            style={{
                                                width:
                                                    porcentajeBarra &&
                                                    porcentajeBarra + "%",
                                            }}
                                            className={`bg-[#053465] `}></div>
                                    </div>
                                )}
                                <div className="flex justify-between w-[28rem] xs:w-[45rem] xl:w-[60rem] mt-16">
                                    <div
                                        className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                            estado >= 1
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}>
                                        <span
                                            className={`absolute top-[-50px] w-14 -right-3 text-[10px] md:text-xs tracking-widest ${
                                                estado >= 1
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}>
                                            Confirmando disponibilidad
                                        </span>
                                    </div>
                                    <div
                                        className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                            estado >= 2
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}>
                                        <span
                                            className={`absolute top-[-50px]  w-14 -right-3 tracking-widest  text-[10px] md:text-xs ${
                                                estado >= 2
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}>
                                            {" "}
                                            Pendiente de pago
                                        </span>
                                    </div>
                                    <div
                                        className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                            estado >= 3
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}>
                                        <span
                                            className={`absolute top-[-50px] w-14 -right-7 tracking-widest leading-4 text-[10px] md:text-xs ${
                                                estado >= 3
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}>
                                            Pago Parcial
                                        </span>
                                    </div>
                                    <div
                                        className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                            estado >= 4
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}>
                                        <span
                                            className={`absolute top-[-50px]  w-14 -right-7 tracking-widest text-[10px] md:text-xs ${
                                                estado >= 4
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}>
                                            Pago Completo
                                        </span>
                                    </div>
                                    <div
                                        className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                            estado >= 5
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}>
                                        <span
                                            className={`absolute top-[-50px] w-14 -right-7 tracking-widest text-[10px] md:text-xs ${
                                                estado >= 5
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}>
                                            Reseña de casa
                                        </span>
                                    </div>
                                    <div
                                        className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                            estado >= 6
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}>
                                        <span
                                            className={`absolute top-[-50px] md:bottom-[-65px] w-16 -right-7 tracking-widest   text-[10px] md:text-xs ${
                                                estado >= 6
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}>
                                            Devolucion de garantia
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* SEGUNDO CAMBIO ACA */}

                        {!showAlojamientos && (
                            <div className="flex flex-col py-3 mt-5 font-bold border-t">
                                <h1>Fecha de reserva</h1>
                                <h2 className="font-normal">
                                    {pedidoSelected?.fecha?.split("T")[0]}
                                </h2>
                            </div>
                        )}
                        <div className="flex justify-between py-3 mt-1 border-y">
                            <div className="flex flex-col font-bold">
                                <h1>Check-in</h1>
                                <h2 className="font-normal">
                                    {showAlojamientos
                                        ? pedidoSelected?.fecha_inicio?.split(
                                              "T"
                                          )[0]
                                        : pedidoSelected?.hora_entrada}{" "}
                                </h2>
                            </div>
                            <div className="flex flex-col font-bold">
                                <h1>Check-out</h1>
                                <h2 className="font-normal">
                                    {showAlojamientos
                                        ? pedidoSelected?.fecha_final?.split(
                                              "T"
                                          )[0]
                                        : pedidoSelected.hora_salida}{" "}
                                </h2>
                            </div>
                        </div>
                        <div className="flex flex-col mt-5">
                            <button
                                onClick={() => handleDetallesAlquiler()}
                                className="p-3 text-center text-blue-400 border border-blue-400 rounded-md hover:scale-105">
                                Detalle del alquiler
                            </button>
                            <ButtonGenerarLinkPago
                                pedido={pedidoSelected}
                                handleLink={handleLink}
                            />
                            {(authUser.tipo_usuario === 1 ||
                                authUser.tipo_usuario === 2 ||
                                authUser.tipo_usuario === 6) &&
                            (pedidoSelected.estado === "Pendiente de pago" ||
                                pedidoSelected.estado === "Pago parcial" ||
                                pedidoSelected.estado === "Pago completo") &&
                            pedidoSelected.pagado_empresa !== "Si" &&
                            pedidoSelected.costo_asumido_empresa > 0 ? (
                                <button
                                    onClick={() =>
                                        navigate(
                                            `/checkout-corporativo/${pedido_id}/${
                                                showAlojamientos
                                                    ? "casa"
                                                    : "embarcacion"
                                            }`
                                        )
                                    }
                                    className="p-3 mt-4 text-center text-blue-400 border border-blue-400 rounded-md hover:scale-105">
                                    Pago de Empresa
                                </button>
                            ) : null}
                            <button
                                onClick={() =>
                                    setModalDetallePagos((prev) => !prev)
                                }
                                className="p-3 mt-4 text-center text-blue-400 border border-blue-400 rounded-md hover:scale-105">
                                Detalle de pagos
                            </button>
                            {pedidoSelected.estado === "Pago completo" &&
                                authUser.tipo_usuario < 5 &&
                                shouldReview(
                                    showAlojamientos
                                        ? pedidoSelected.fecha_final
                                        : pedidoSelected.fecha
                                ) && (
                                    <Link
                                        to={`/reviews/${
                                            showAlojamientos
                                                ? "pedidoCasa"
                                                : "pedidoEmbarcacion"
                                        }/${pedidoSelected.id}`}
                                        className="p-3 mt-4 text-center text-blue-400 border border-blue-400 rounded-md hover:scale-105"
                                        title="Reseña">
                                        Hacer una reseña
                                    </Link>
                                )}
                            {pedidoSelected.estado === "Pago completo" &&
                                authUser.tipo_usuario < 3 &&
                                shouldReview(
                                    showAlojamientos
                                        ? pedidoSelected.fecha_final
                                        : pedidoSelected.fecha
                                ) && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            devolucionGarantia(
                                                pedidoSelected.id
                                            );
                                        }}
                                        className="p-3 mt-4 text-center text-blue-400 border border-blue-400 rounded-md hover:scale-105"
                                        title="Devolución de garantía">
                                        Notificar devolución de garantía
                                    </button>
                                )}
                            {pedidoSelected.estado === "Pago completo" &&
                                pedidoSelected.saldo === 0 &&
                                authUser.tipo_usuario !== 6 && (
                                    <button
                                        className="p-3 mt-4 text-center text-blue-400 border border-blue-400 rounded-md hover:scale-105"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleChatLink(pedidoSelected.id);
                                        }}
                                        title="Abrir chat">
                                        Ir al chat
                                    </button>
                                )}
                            <button
                                onClick={() => handleDelete(pedidoSelected.id)}
                                className="p-3 mt-4 text-center text-blue-400 border border-blue-400 rounded-md md:hidden hover:scale-105">
                                Eliminar
                            </button>
                        </div>
                        {modalDisponibilidad && (
                            <ModalDisponibilidad
                                IsAlojamiento={showAlojamientos}
                                pedido={pedidoSelected}
                                setModalDisponibilidad={setModalDisponibilidad}
                                modalDisponibilidad={modalDisponibilidad}
                            />
                        )}
                        {modalDetallePagos && (
                            <ModalDetallePagos
                                IsAlojamiento={showAlojamientos}
                                pedido={pedidoSelected}
                                setPedidoSelected={setPedidoSelected}
                                setDetallesPrincipales={setDetallesPrincipales}
                                setPedidosPage={setPedidosPage}
                                setModalDisponibilidad={setModalDetallePagos}
                                modalDisponibilidad={modalDetallePagos}
                                setRefreshBarra={setRefreshBarra}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex items-center justify-center h-screen">
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default DetallesPrincipales;
