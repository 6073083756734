import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "../../Spinner";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const EditarReglas = ({ producto, setProducto, setModal, tipo_producto }) => {
    const [data, setData] = useState({ total: 0, data: [] });
    const [preSelected, setPreSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({
        reglas: [],
        checkIn: producto.data?.check_in || "",
        checkOut: producto.data?.check_out || "",
    });
    const [siguiente, setSiguiente] = useState("Siguiente");
    const horarios = [];
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        const func = async () => {
            setLoading(true);
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/productoReglas/all/${producto.data.id}`,
                { headers }
            );
            console.log(data);
            setSelected({
                ...selected,
                reglas: data.map((regla) => {
                    return {
                        id_regla: regla.id_regla,
                        regla_seleccionada: regla.regla_seleccionada,
                    };
                }),
            });
            setPreSelected(
                data.map(
                    (regla) =>
                        `${regla.id_regla}${
                            regla.regla_seleccionada === "Si" ? true : false
                        }`
                )
            );
        };
        const funct = async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/regla/all/0/Si`,
                { headers }
            );
            setData(data);
            setLoading(false);
        };
        func();
        funct();
    }, []);

    const handleRuleClick = (rule, trueORfalse) => {
        const offButton = document.getElementById(`${rule.id}${false}`);
        const onButton = document.getElementById(`${rule.id}${true}`);

        if (!trueORfalse) {
            if (offButton.classList.contains("selected")) {
                console.log("Ya estaba prendido, lo apagaré");
                offButton.classList.remove(
                    "selected",
                    "bg-blue-500",
                    "border-blue-500"
                );
                offButton.classList.add("text-blue-500");

                setSelected({
                    ...selected,
                    reglas: selected.reglas.filter(
                        (regla) => regla.id_regla !== rule.id
                    ),
                });
            } else if (onButton.classList.contains("selected")) {
                console.log(
                    "El contrario estaba prendido, lo apagaré y prenderé el clickeado"
                );
                // Apago el contrario
                onButton.classList.remove(
                    "selected",
                    "bg-blue-500",
                    "border-blue-500"
                );
                onButton.classList.add("text-blue-500");
                // Prendo el clickeado
                offButton.classList.remove("text-blue-500");
                offButton.classList.add(
                    "selected",
                    "bg-blue-500",
                    "border-blue-500",
                    "text-white"
                );

                setSelected({
                    ...selected,
                    reglas: [
                        ...selected.reglas.filter(
                            (regla) => regla.id_regla !== rule.id
                        ),
                        {
                            id_regla: rule.id,
                            regla_seleccionada: trueORfalse ? "Si" : "No",
                        },
                    ],
                });
            } else {
                // console.log("Los 2 estaban apagados, prenderé el clickeado");
                offButton.classList.remove("text-blue-500");
                offButton.classList.add(
                    "selected",
                    "bg-blue-500",
                    "border-blue-500",
                    "text-white"
                );

                setSelected({
                    ...selected,
                    reglas: [
                        ...selected.reglas.filter(
                            (regla) => regla.id_regla !== rule.id
                        ),
                        {
                            id_regla: rule.id,
                            regla_seleccionada: trueORfalse ? "Si" : "No",
                        },
                    ],
                });
            }
        } else {
            if (onButton.classList.contains("selected")) {
                // console.log("Ya estaba prendido, lo apagaré");
                onButton.classList.remove(
                    "selected",
                    "bg-blue-500",
                    "border-blue-500"
                );
                onButton.classList.add("text-blue-500");

                setSelected({
                    ...selected,
                    reglas: selected.reglas.filter(
                        (regla) => regla.id_regla !== rule.id
                    ),
                });
            } else if (offButton.classList.contains("selected")) {
                // console.log(
                //     "El contrario estaba prendido, lo apagaré y prendere el clickeado"
                // );
                offButton.classList.remove(
                    "selected",
                    "bg-blue-500",
                    "border-blue-500"
                );
                offButton.classList.add("text-blue-500");
                // Prendo el clickeado
                onButton.classList.remove("text-blue-500");
                onButton.classList.add(
                    "selected",
                    "bg-blue-500",
                    "border-blue-500",
                    "text-white"
                );

                setSelected({
                    ...selected,
                    reglas: [
                        ...selected.reglas.filter(
                            (regla) => regla.id_regla !== rule.id
                        ),
                        {
                            id_regla: rule.id,
                            regla_seleccionada: trueORfalse ? "Si" : "No",
                        },
                    ],
                });
            } else {
                // console.log("Los 2 estaban apagados, prenderé el clickeado");
                onButton.classList.remove("text-blue-500");
                onButton.classList.add(
                    "selected",
                    "bg-blue-500",
                    "border-blue-500",
                    "text-white"
                );

                setSelected({
                    ...selected,
                    reglas: [
                        ...selected.reglas.filter(
                            (regla) => regla.id_regla !== rule.id
                        ),
                        {
                            id_regla: rule.id,
                            regla_seleccionada: trueORfalse ? "Si" : "No",
                        },
                    ],
                });
            }
        }
    };

    const updateProduct = async (payload) => {
        if (
            selected.reglas[1].regla_seleccionada === "Si" &&
            producto.data.numero_mascotas < 1
        ) {
            Swal.fire({
                title: "Debes agregar el numero de mascotas permitidas",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Aceptar!",
            });
            return;
        }
        if (tipo_producto === "casa") {
            setLoading(true);

            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/productoReglas`,
                payload,
                { headers }
            );

            const dataMascotas = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarPersonasHabitacionesBanos/${producto.data.id}`,
                {
                    numero_habitaciones: producto.data.numero_habitaciones,
                    numero_personas: producto.data.numero_personas,
                    numero_banos: producto.data.numero_banos,
                    numero_mascotas: producto.data.numero_mascotas,
                    numero_habitaciones_servicio:
                        producto.data.numero_habitaciones_servicio,
                    id: producto.data.id,
                    id_usuario: authUser.id_usuario,
                },
                { headers }
            );
            if (data.message === "registro exitoso") {
                Swal.fire({
                    title: "Actualizado con exito",
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "ok!",
                });
                setLoading(false);
                setModal(false);
            }
        }
    };

    const handleGuardar = () => {
        const payload = {
            id_producto: producto.data.id,
            reglas: selected.reglas,
            horarios: {
                check_in: selected.checkIn,
                check_out: selected.checkOut,
            },
            id_usuario: authUser.id_usuario,
        };

        tipo_producto === "casa"
            ? updateProduct(payload)
            : updateProduct({
                  reglas: producto.reglas,
                  id_usuario: authUser.id_usuario,
              });
    };

    return (
        <div className="p-4 border rounded-lg ">
            {loading ? (
                <Spinner />
            ) : (
                <div className="items-center w-full mx-3 my-5 ">
                    <h1 className="text-xl">Reglas</h1>
                    <div className="mt-3 overflow-hidden lg:mt-10">
                        <div className="overflow-y-auto ">
                            {data?.data?.map((rule) => {
                                return (
                                    <div
                                        key={rule.id}
                                        className="flex justify-between my-2">
                                        <div className="flex-col ">
                                            <p className=" text-sm text-[#3C3C3C]">
                                                {/* Se admiten mascotas */}
                                                {rule.nombre_si}
                                            </p>
                                            <p
                                                className={
                                                    rule.nombre_si ===
                                                        "Se permite mascota" &&
                                                    selected.reglas[1]
                                                        ?.regla_seleccionada ===
                                                        "Si"
                                                        ? " text-sm text-[#3C3C3C] mt-4"
                                                        : "hidden"
                                                }>
                                                {/* Se admiten mascotas */}
                                                {"Cantidad de mascotas"}
                                            </p>
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex">
                                                <div
                                                    id={`${rule.id}${false}`}
                                                    onClick={() =>
                                                        handleRuleClick(
                                                            rule,
                                                            false
                                                        )
                                                    }
                                                    className={
                                                        preSelected.includes(
                                                            `${rule.id}${false}`
                                                        )
                                                            ? "flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer mr-7 bg-blue-500 text-white selected"
                                                            : "flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer mr-7 text-blue-500"
                                                    }>
                                                    <svg
                                                        className={"h-4 w-4 "}
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <line
                                                            x1="18"
                                                            y1="6"
                                                            x2="6"
                                                            y2="18"
                                                        />{" "}
                                                        <line
                                                            x1="6"
                                                            y1="6"
                                                            x2="18"
                                                            y2="18"
                                                        />
                                                    </svg>
                                                </div>

                                                <div
                                                    id={`${rule.id}${true}`}
                                                    onClick={() =>
                                                        handleRuleClick(
                                                            rule,
                                                            true
                                                        )
                                                    }
                                                    className={
                                                        preSelected.includes(
                                                            `${rule.id}${true}`
                                                        )
                                                            ? "flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer mr-7 bg-blue-500 text-white selected"
                                                            : "flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer mr-7 text-blue-500"
                                                    }>
                                                    <svg
                                                        className="w-4 h-4 "
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <path d="M5 12l5 5l10 -10" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    rule.nombre_si ===
                                                        "Se permite mascota" &&
                                                    selected.reglas[1]
                                                        ?.regla_seleccionada ===
                                                        "Si"
                                                        ? "flex justify-between mt-2"
                                                        : "hidden"
                                                }>
                                                <div className="flex ">
                                                    <div
                                                        onClick={() =>
                                                            producto.data
                                                                .numero_mascotas >
                                                                0 &&
                                                            setProducto({
                                                                ...producto,
                                                                data: {
                                                                    ...producto.data,
                                                                    numero_mascotas:
                                                                        producto
                                                                            .data
                                                                            .numero_mascotas -
                                                                        1,
                                                                },
                                                            })
                                                        }
                                                        className="flex items-center justify-center w-8 h-8 border-2 rounded-lg cursor-pointer border-blue-bluecorner">
                                                        <svg
                                                            className="w-4 h-4 text-blue-bluecorner"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round">
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <line
                                                                x1="5"
                                                                y1="12"
                                                                x2="19"
                                                                y2="12"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="flex justify-center items-center text-[#3C3C3C] text-base  border-2 w-7 h-8 border-white rounded-lg">
                                                        {
                                                            producto.data
                                                                .numero_mascotas
                                                        }
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            setProducto({
                                                                ...producto,
                                                                data: {
                                                                    ...producto.data,
                                                                    numero_mascotas:
                                                                        producto
                                                                            .data
                                                                            .numero_mascotas +
                                                                        1,
                                                                },
                                                            })
                                                        }
                                                        className="flex items-center justify-center w-8 h-8 border-2 rounded-lg cursor-pointer border-blue-bluecorner">
                                                        <svg
                                                            className="w-4 h-4 text-blue-bluecorner"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round">
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <line
                                                                x1="12"
                                                                y1="5"
                                                                x2="12"
                                                                y2="19"
                                                            />{" "}
                                                            <line
                                                                x1="5"
                                                                y1="12"
                                                                x2="19"
                                                                y2="12"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarReglas;
