import React from "react";

const EjemploFinal = ({ values }) => {
    console.log(values);

    return (
        <div className="mt-4">
            <ul className="border  p-3 rounded-lg border-blue-bluecorner">
                {Number(values.intervalo_dias) === 1 ? (
                    <>
                        <li className="mt-2">
                            Si el cliente se queda <b>1</b> dia debe pagar{" "}
                            <b>{Number(values.price)}</b> {values.tipo_moneda}.
                        </li>
                        <li className="mt-2">
                            Si el cliente se queda <b>2</b> dias debe pagar{" "}
                            <b>
                                {Number(values.price) +
                                    Number(values.intervalo_precio)}
                            </b>{" "}
                            {values.tipo_moneda}.
                        </li>
                        <li className="mt-2">
                            Si el cliente se queda <b>3</b> dias debe pagar{" "}
                            <b>
                                {Number(values.price) +
                                    Number(values.intervalo_precio * 2)}
                            </b>{" "}
                            {values.tipo_moneda}.
                        </li>
                        <li className="mt-2">
                            Si el cliente se queda <b>4</b> dias debe pagar{" "}
                            <b>
                                {Number(values.price) +
                                    Number(values.intervalo_precio * 3)}
                            </b>{" "}
                            {values.tipo_moneda}.
                        </li>
                        <li className="mt-2">
                            Si el cliente se queda <b>5</b> dias debe pagar{" "}
                            <b>
                                {Number(values.price) +
                                    Number(values.intervalo_precio * 4)}
                            </b>{" "}
                            {values.tipo_moneda}.
                        </li>
                    </>
                ) : (
                    <>
                        <li className="mt-2">
                            Si el cliente se queda entre <b>1</b>-
                            <b>{values.intervalo_dias}</b> dias debe pagar{" "}
                            <b>{Number(values.price)}</b> {values.tipo_moneda}.
                        </li>
                        <li className="mt-2">
                            Si el cliente se queda entre{" "}
                            <b>{Number(values.intervalo_dias) + 1}</b>-
                            <b>{values.intervalo_dias * 2}</b> dias debe pagar{" "}
                            <b>
                                {" "}
                                {Number(values.price) +
                                    values.intervalo_precio * 1}
                            </b>{" "}
                            {values.tipo_moneda}.
                        </li>
                        <li className="mt-2">
                            Si el cliente se queda entre{" "}
                            <b>{values.intervalo_dias * 2 + 1}</b>-
                            <b>{values.intervalo_dias * 3}</b> dias debe pagar{" "}
                            <b>
                                {" "}
                                {Number(values.price) +
                                    values.intervalo_precio * 2}
                            </b>{" "}
                            {values.tipo_moneda}.
                        </li>
                        <li className="mt-2">
                            Si el cliente se queda entre{" "}
                            <b>{Number(values.intervalo_dias * 3) + 1}</b>-
                            <b>{values.intervalo_dias * 4}</b> dias debe pagar{" "}
                            <b>
                                {" "}
                                {Number(values.price) +
                                    values.intervalo_precio * 3}
                            </b>{" "}
                            {values.tipo_moneda}.
                        </li>
                        <li className="mt-2">
                            Si el cliente se queda entre{" "}
                            <b>{Number(values.intervalo_dias * 4) + 1}</b>-
                            <b>{values.intervalo_dias * 5}</b> dias debe pagar{" "}
                            <b>
                                {" "}
                                {Number(values.price) +
                                    values.intervalo_precio * 4}
                            </b>{" "}
                            {values.tipo_moneda}.
                        </li>
                    </>
                )}
            </ul>
        </div>
    );
};

export default EjemploFinal;
