import React, { useLayoutEffect } from "react";
import { useRef } from "react";

const ModalMessageTopRight = ({ message, success, error }) => {
    const cardRef = useRef(null);

    useLayoutEffect(() => {
        if (cardRef.current) {
            setTimeout(() => {
                cardRef.current.classList.add(
                    "-translate-x-[26rem]",
                    "duration-500",
                    "ease-linear"
                );
            }, 200);
            setTimeout(() => {
                cardRef.current.classList.remove("-translate-x-[26rem]");
            }, 3000);
        }
    }, [cardRef]);

    return (
        <div
            ref={cardRef}
            className="fixed -right-[25.5rem] top-2 bg-white p-3 rounded-lg flex items-center gap-2 shadow-md border">
            {!success && !error && (
                <svg
                    className="h-8 w-8 text-blue-400"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <circle cx="12" cy="12" r="9" />{" "}
                    <line x1="12" y1="8" x2="12.01" y2="8" />{" "}
                    <polyline points="11 12 12 12 12 16 13 16" />
                </svg>
            )}
            {error && (
                <svg
                    className="h-8 w-8 text-red-500"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    {" "}
                    <circle cx="12" cy="12" r="10" />{" "}
                    <line x1="15" y1="9" x2="9" y2="15" />{" "}
                    <line x1="9" y1="9" x2="15" y2="15" />
                </svg>
            )}
            {success && (
                <svg
                    className="h-8 w-8 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            )}
            <span className="font-medium">{message}</span>
        </div>
    );
};

export default ModalMessageTopRight;
