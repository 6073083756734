import axios from "axios";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useHeaders from "../../../hooks/useHeaders";
import ToggleButton from "../../ToggleButton";
import AutoComplete from "./AutoComplete";
import banner1 from "../../../img/promos/promo-san-valentin/Semana-Santa-Casa-Titi.webp";
import banner2 from "../../../img/promos/promo-san-valentin/Semana-Santa-Casa-Acuario.webp";
import banner3 from "../../../img/promos/promo-san-valentin/Semana-Santa-Casa-Pacha.webp";
import bannerSemanaSanta from "../../../img/promos/semana-santa/semana-santa-2024.webp";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import HouseModal from "./ModalCasa";

const images = [banner1, banner2, banner3];

const ExplorerSanValentin = ({
    vistaAdministrador,
    setVistaAdministrador,
    setRefreshHouses,
}) => {
    const [fields, setFields] = useState([]);
    const [input, setInput] = useState("");
    const { tipo_usuario } = useSelector((state) => state.authReducer);
    const { headers } = useHeaders();
    const debounceInput = useRef(null);
    const [fieldSelect, setFieldSelect] = useState(null);

    const handleInputChangeDebounce = (e) => {
        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            setInput(e.target.value);
            setFields([]);
            return;
        }
        setInput(e.target.value);
        debounceInput.current = setTimeout(async () => {
            try {
                const { data } = await axios(
                    `${process.env.REACT_APP_PROXY}/casasSemanaSanta/searchCasa/${e.target.value}`,
                    { headers }
                );
                console.log(data.data);
                setFields(data.data);
            } catch (error) {
                console.log(error.response);
            }
        }, 500);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setFields([]);
            setInput("");
        }, 200);
    };

    return (
        <div
            className={`${
                tipo_usuario !== null && tipo_usuario <= 2
                    ? "mt-[8rem]"
                    : "mt-[6rem]"
            }`}>
            {fieldSelect && (
                <HouseModal
                    fieldSelect={fieldSelect}
                    setRefreshHouses={setRefreshHouses}
                    setShowModal={setFieldSelect}
                    headers={headers}
                />
            )}
            <div
                className={`w-full bg-center 
                bg-cover flex flex-col bg-white text-center`}>
                <div className="h-full text-white swiper-san-valentin transition-all ease-out duration-[200ms] p-4 w-full xl:px-32 2xl:px-28 mx-auto mt-6">
                    <Swiper
                        // modules={[Navigation]}
                        className="h-[12rem] sm:h-[20rem] rounded-t-xl lg:h-[25rem] bg-gradient-to-bl from-white to-blue-bluecorner select-none flex justify-center items-center"
                        // grabCursor
                        slidesPerView={1}
                        centeredSlides
                        speed={1000}
                        // loop
                        // navigation
                    >
                        <SwiperSlide className="transition-all duration-200 ease-out rounded-lg">
                            <div className="relative w-full h-full transition-all duration-300 bg-gradient-to-t from-white to-blue-400">
                                <img
                                    src={bannerSemanaSanta}
                                    className="w-full h-full mx-auto transition-all duration-200 ease-out rounded-lg"
                                    alt={`Banner semana santa`}
                                />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    {/* <div className="p-4 py-2 font-medium rounded-b-lg bg-blue-bluecorner">
                        <span className="flex flex-col py-1 text-xl text-left">
                            Casas del jueves 6 al domingo 9 de abril
                        </span>
                    </div> */}
                </div>
            </div>
            {(tipo_usuario === 1 || tipo_usuario === 2) && (
                <div className="flex flex-col-reverse justify-between w-full gap-3 p-4 mt-4 lg:flex-row lg:items-center xl:px-32 2xl:px-28">
                    {vistaAdministrador ? (
                        <div className="relative flex-1 flex flex-col p-3 border-2 border-gray-300 rounded-md max-w-[400px]">
                            <div className="flex items-center gap-3">
                                <svg
                                    className="h-7 w-7"
                                    fill="gray"
                                    version="1.1"
                                    viewBox="0 0 486.196 486.196">
                                    <g>
                                        <g>
                                            <path d="M481.708,220.456l-228.8-204.6c-0.4-0.4-0.8-0.7-1.3-1c-5-4.8-13-5-18.3-0.3l-228.8,204.6c-5.6,5-6,13.5-1.1,19.1 c2.7,3,6.4,4.5,10.1,4.5c3.2,0,6.4-1.1,9-3.4l41.2-36.9v7.2v106.8v124.6c0,18.7,15.2,34,34,34c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0h70.6 c17.6,0,31.9-14.3,31.9-31.9v-121.3c0-2.7,2.2-4.9,4.9-4.9h72.9c2.7,0,4.9,2.2,4.9,4.9v121.3c0,17.6,14.3,31.9,31.9,31.9h72.2 c19,0,34-18.7,34-42.6v-111.2v-34v-83.5l41.2,36.9c2.6,2.3,5.8,3.4,9,3.4c3.7,0,7.4-1.5,10.1-4.5 C487.708,233.956,487.208,225.456,481.708,220.456z M395.508,287.156v34v111.1c0,9.7-4.8,15.6-7,15.6h-72.2c-2.7,0-4.9-2.2-4.9-4.9 v-121.1c0-17.6-14.3-31.9-31.9-31.9h-72.9c-17.6,0-31.9,14.3-31.9,31.9v121.3c0,2.7-2.2,4.9-4.9,4.9h-70.6c-0.3,0-0.5,0-0.8,0 s-0.5,0-0.8,0c-3.8,0-7-3.1-7-7v-124.7v-106.8v-31.3l151.8-135.6l153.1,136.9L395.508,287.156L395.508,287.156z"></path>
                                        </g>
                                    </g>
                                </svg>
                                <input
                                    value={input}
                                    onBlur={handleBlur}
                                    onChange={handleInputChangeDebounce}
                                    type={"text"}
                                    placeholder="Busca una casa para agregar"
                                    className="w-full outline-none"
                                />
                            </div>
                            {fields.length > 0 && (
                                <AutoComplete
                                    fields={fields}
                                    setRefreshHouses={setRefreshHouses}
                                    setFieldSelect={setFieldSelect}
                                />
                            )}
                        </div>
                    ) : (
                        <div></div>
                    )}

                    {/* {vistaAdministrador && (
                        <div className="flex items-center gap-1">
                            <svg
                                className="w-10 h-10 lg:h-8 lg:w-8 text-blue-bluecorner"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
                                />
                            </svg>
                            <h3 className="font-medium">
                                ¡Ahora puedes arrastrar las casas a la posición
                                que gustes!
                            </h3>
                        </div>
                    )} */}
                    <div className="flex items-center gap-2 py-3">
                        <span className="font-medium">Vista administrador</span>
                        <ToggleButton
                            isToggled={true}
                            onChangeToggle={(e) => setVistaAdministrador(e)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExplorerSanValentin;
