export const updateHouseFilters = (filters) => {
    return {
        type: "HOUSE_FILTERS",
        payload: filters,
    };
};

export const updateBoatFilters = (filters) => {
    return {
        type: "BOAT_FILTERS",
        payload: filters,
    };
};
