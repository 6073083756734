import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import feeBC from "../../helpers/feeBC";
const EditarGarantiaMascotas = ({ producto, setProducto, setModal }) => {
    const [input, setInput] = useState({
        garantia_mascota:
            producto.data?.garantia_mascota !== 0
                ? producto.data?.garantia_mascota
                : "",
        precio_base: producto.data?.precio_base,
        moneda_precio_base: producto.data?.moneda_precio_base,
        garantia: producto.data?.garantia,
        tipo_garantia: producto.data?.tipo_garantia,
        tipo_moneda_garantia: producto.data?.tipo_moneda_garantia,
        tipo_estadia: producto.data?.tipo_estadia,
        coin: producto.data?.moneda_precio_base || "PEN",
        price: producto.data?.precio_base || "0.00",
        warrantyType: producto.data?.tipo_garantia || "",
        warrantyTotal: producto.data?.garantia || "0.00",
        warrantyCoin: producto.data?.moneda_garantia || "PEN",
        tipo_moneda_garantia: producto.data?.tipo_moneda_garantia || "PEN",
        garantia_mascota: producto.data?.garantia_mascota || "0.00",
        tipo_estadia: producto.data?.tipo_estadia || "noche",
    });
    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const MONEDAS = ["PEN", "USD"];
    const [rangoDias, setRangoDias] = useState([]);
    const [rangoDia, setRangoDia] = useState({
        dia_inicio: "",
        dia_final: "",
        precio: "",
        id_casa: producto.data.id,
    });
    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        getRangoDias();
    }, []);

    const postRangoDia = async () => {
        if (rangoDia.precio === "") {
            alert("valor");
        } else if (rangoDia.dia_inicio === "") {
            alert("Desde");
        } else if (rangoDia.dia_final === "") {
            alert("Hasta");
        } else {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/casaGarantia/`,
                rangoDia,
                { headers }
            );

            console.log(data);
            if (data.message === "registro exitoso") {
                getRangoDias();
                setRangoDia({
                    dia_inicio: "",
                    dia_final: "",
                    precio: "",
                    id_casa: producto.data.id,
                });
            }
        }
    };

    const getRangoDias = async () => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/casaGarantia/all/${producto.data.id}`,
            { headers }
        );

        console.log(data);
        setRangoDias(data);
    };

    const deleteRangoDias = async (id) => {
        const { data } = await axios.delete(
            `${process.env.REACT_APP_PROXY}/casaGarantia/eliminar/${id}`,
            { headers }
        );

        console.log(data);
        getRangoDias();
    };

    const updateProduct = async (payload) => {
        console.log(payload);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/casa/actualizarPrecio/${producto.data.id}`,
            payload,
            { headers }
        );
        console.log(data);

        if (data.message === "actuaizacion exitoso") {
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    ...payload,
                },
            });
            setModal(false);
        }
    };

    const handleGuardar = () => {
        const payload = {
            precio_base: input.price,
            moneda_precio_base: input.coin,
            garantia: input.warrantyTotal,
            tipo_garantia: input.warrantyType,
            garantia_mascota:
                input.garantia_mascota === "" ||
                input.garantia_mascota === "0.00"
                    ? 0
                    : input.garantia_mascota,
            tipo_moneda_garantia:
                input.warrantyType === "Valor" ||
                input.warrantyType === "Por noche" ||
                input.warrantyType === "Por día" ||
                input.warrantyType === "Rango de días"
                    ? input.tipo_moneda_garantia
                    : input.coin,
            id: producto.data.id,
            tipo_estadia: input.tipo_estadia,
            id_usuario: authUser.id_usuario,
        };

        updateProduct(payload);
    };

    console.log(input);

    return (
        <div className="p-4 border rounded-lg">
            <div className="w-full mt-3">
                <div className="h-[50vh] overflow-hidden">
                    <div className="h-[50vh] overflow-auto w-[105%]">
                        <div className="mx-auto mt-2 w-72">
                            <label className=" w-full text-base text-[#3C3C3C]">
                                Garantía
                            </label>
                            <hr className="mt-2"></hr>
                            <br></br>
                            <label
                                className={
                                    input.warrantyType === "Porcentaje"
                                        ? "hidden"
                                        : " mt-2 w-full text-base text-[#3C3C3C]"
                                }>
                                Moneda de garantía
                            </label>
                            <select
                                className={
                                    input.warrantyType === "Porcentaje"
                                        ? "hidden"
                                        : "w-full border my-3 bg-transparent rounded-lg py-3 px-3  outline-none focus:outline-none text-[#3C3C3C] placeholder:font-bold"
                                }
                                name="tipo_moneda_garantia"
                                defaultValue={
                                    producto.data.tipo_moneda_garantia
                                }
                                onChange={handleInputChange}>
                                {MONEDAS.map((moneda) => (
                                    <option key={moneda} value={moneda}>
                                        {" "}
                                        {moneda}{" "}
                                    </option>
                                ))}
                            </select>
                            <select
                                className="w-full border my-3 bg-transparent rounded-lg py-3 px-3  outline-none focus:outline-none text-[#3C3C3C] placeholder:font-bold"
                                value={input.warrantyType}
                                name="warrantyType"
                                onChange={handleInputChange}>
                                <option> Ninguna </option>
                                <option> Porcentaje </option>
                                <option> Valor </option>
                                <option> Por noche </option>
                                <option> Por día </option>
                                <option> Rango de días </option>
                            </select>
                        </div>
                        {(input.warrantyType === "Valor" ||
                            input.warrantyType === "Por noche" ||
                            input.warrantyType === "Por día") && (
                            <input
                                autoComplete="off"
                                className="w-72 mx-auto border  flex bg-transparent rounded-lg py-3 px-3  outline-none focus:outline-none text-[#3C3C3C] placeholder:font-bold"
                                placeholder="100"
                                name="warrantyTotal"
                                type="number"
                                step=".01"
                                onWheel={(event) => event.target.blur()}
                                onChange={handleInputChange}
                                value={input.warrantyTotal}
                            />
                        )}

                        {input.warrantyType === "Rango de días" && (
                            <>
                                <div className="flex mx-auto w-72 ">
                                    <div className="">
                                        <label className="ml-1 text-base font-semibold ">
                                            Desde:
                                        </label>
                                        <input
                                            value={rangoDia.dia_inicio}
                                            type="number"
                                            name="rango_persona_inicio"
                                            placeholder="1"
                                            className="rounded-lg border border-[#E9E9E9] outline-none w-14 py-2 placeholder:text-center text-center"
                                            onChange={(e) =>
                                                setRangoDia({
                                                    ...rangoDia,
                                                    dia_inicio: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="ml-1 text-base font-semibold ">
                                            Hasta:
                                        </label>
                                        <input
                                            value={rangoDia.dia_final}
                                            type="number"
                                            name="rango_persona_final"
                                            placeholder="5"
                                            className="rounded-lg border border-[#E9E9E9] outline-none w-14 py-2 placeholder:text-center text-center "
                                            onChange={(e) =>
                                                setRangoDia({
                                                    ...rangoDia,
                                                    dia_final: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="ml-1 text-base font-semibold ">
                                            Valor:
                                        </label>
                                        <input
                                            value={rangoDia.precio}
                                            type="number"
                                            name="rango_persona_final"
                                            placeholder="100"
                                            className="rounded-lg border border-[#E9E9E9] outline-none w-14 py-2 placeholder:text-center text-center "
                                            onChange={(e) =>
                                                setRangoDia({
                                                    ...rangoDia,
                                                    precio: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="mx-auto w-72">
                                    <button
                                        onClick={() => postRangoDia()}
                                        className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                                        Agregar
                                    </button>
                                </div>
                                <hr className="mx-auto w-72"></hr>

                                <div
                                    className={
                                        rangoDias.length > 0
                                            ? "w-72 mx-auto"
                                            : "hidden"
                                    }>
                                    <table className="w-full mt-2 table-auto">
                                        <thead>
                                            <tr className="text-center bg-primary">
                                                <th
                                                    align="start"
                                                    className="py-1 font-semibold ">
                                                    Desde
                                                </th>
                                                <th
                                                    align="start"
                                                    className="py-1 font-semibold ">
                                                    Hasta
                                                </th>
                                                <th
                                                    align="start"
                                                    className="py-1 font-semibold ">
                                                    Valor
                                                </th>
                                                <th className="py-1 font-semibold ">
                                                    Acción
                                                </th>
                                            </tr>
                                        </thead>
                                        {rangoDias?.map((dia, i) => (
                                            <tbody key={i}>
                                                <tr>
                                                    <td className="w-16">
                                                        {dia.dia_inicio}
                                                    </td>
                                                    <td className="w-16">
                                                        {dia.dia_final}
                                                    </td>
                                                    <td className="w-16">
                                                        {dia.precio.toFixed(2)}
                                                    </td>
                                                    <td
                                                        onClick={() =>
                                                            deleteRangoDias(
                                                                dia.id
                                                            )
                                                        }
                                                        className={
                                                            "text-lg text-blue-bluecorner cursor-pointer justify-center items-center flex"
                                                        }>
                                                        <button className="flex items-center justify-center w-8 h-8 font-semibold text-white border-2 rounded-lg border-blue-bluecorner">
                                                            <span className="-mt-2 text-2xl text-blue-bluecorner">
                                                                x
                                                            </span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            </>
                        )}

                        {input.warrantyType === "Porcentaje" && (
                            <div className="flex items-center justify-center px-3 py-3 mx-auto border-2 rounded-lg w-72">
                                <input
                                    autoComplete="off"
                                    className="w-full rounded-lg outline-none"
                                    placeholder="15"
                                    name="warrantyTotal"
                                    type="number"
                                    step=".01"
                                    onChange={handleInputChange}
                                    value={input.warrantyTotal}></input>
                                <span>
                                    <svg
                                        className="w-4 h-4 text-[#3C3C3C] rounded-lg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <line x1="19" y1="5" x2="5" y2="19" />{" "}
                                        <circle cx="6.5" cy="6.5" r="2.5" />{" "}
                                        <circle cx="17.5" cy="17.5" r="2.5" />
                                    </svg>
                                </span>
                            </div>
                        )}
                        <p className="text-[#3C3C3C] text-sm text-center mt-5 font-semibold w-72 mx-auto ">
                            El precio pubicado tendra un{" "}
                            {producto.data.fee_cliente}% mas sobre el precio
                            base por la comisión de Bluecorner al cliente
                        </p>
                        <div className="mx-auto mt-6 w-72">
                            <label className=" w-full text-base text-[#3C3C3C]">
                                Garantía mascotas
                            </label>
                            <hr className="mt-2"></hr>
                            <div className="flex mt-3">
                                <div className="flex items-center justify-center">
                                    <p className="ml-1 text-base font-semibold ">
                                        Moneda:{" "}
                                    </p>
                                    <p
                                        // placeholder="Personas"
                                        className="w-24 text-center"
                                        // onChange={handleInputChange}
                                    >
                                        {input.tipo_moneda_garantia}
                                    </p>
                                </div>
                                <div>
                                    <label className="mx-2 text-base font-semibold ">
                                        Valor:
                                    </label>
                                    <input
                                        value={input.garantia_mascota}
                                        type="number"
                                        name="garantia_mascota"
                                        placeholder="100"
                                        className="rounded-lg border border-[#E9E9E9] outline-none w-16 py-2 placeholder:text-center text-center "
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarGarantiaMascotas;
