import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalCreateLabels from "./ModalCreateLabels";
import Swal from "sweetalert2";
import {
    getAllLabels,
    deleteLabels,
    enableLabels,
    oneLabels,
    resetEnebleLabel,
} from "../../redux/actions/labelsActions";
import SearchBar from "./SearchBar";
import { Paginado } from "../helpers/Paginado";
import ModalEditLabels from "./ModalEditLabels";
import Spinner from "../Spinner";

const CreateLabels = () => {
    const dispatch = useDispatch();
    const [create, setcreate] = useState(false);
    const [edit, setedit] = useState(false);
    const [enabled, setenabled] = useState("Si");
    const [showButtons, setShowButtons] = useState(false);
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [pageNumber, setpageNumber] = useState(1);
    const LABELS_PER_PAGE = 25;
    const [loading, setLoading] = useState(false);

    const labels = useSelector((state) => state.labelsReducer.labels);

    const reduxLabels = useSelector((state) => state.labelsReducer.oneLabels);

    const enebleReduxLabels = useSelector(
        (state) => state.labelsReducer.enableLabels
    );

    useEffect(() => {
        setLoading(false);
    }, [labels]);

    useEffect(() => {
        dispatch(getAllLabels());
    }, [dispatch, create]);

    useEffect(() => {
        dispatch(getAllLabels(pageNumber, enabled));
    }, [dispatch, edit]);

    useEffect(() => {
        setLoading(true);

        dispatch(getAllLabels(null, enabled));
        setpageNumber(1);
    }, [dispatch, enabled]);

    useEffect(() => {
        if (enebleReduxLabels === "Restaurado con exito") {
            dispatch(resetEnebleLabel());
            dispatch(getAllLabels(null, enabled));

            Swal.fire({
                title: "Procesado!",
                text: "etiqueta restaurada",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        } else if (enebleReduxLabels === "Registro deshabilitado con exito") {
            dispatch(resetEnebleLabel());
            dispatch(getAllLabels(null, enabled));

            Swal.fire({
                title: "Procesado!",
                text: "etiqueta deshabilitada",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        }
    }, [enebleReduxLabels]);

    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarla?",
            text: "Se enviará la etiqueta a deshabilitadas!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteLabels(id));
                setLoading(true);
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarla?",
            text: "Se enviará la etiqueta a habilitadas!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(enableLabels(id));
                setLoading(true);
            }
        });
    };

    const handleEdit = (id) => {
        dispatch(oneLabels(id));
        setedit(!edit);
    };

    const pagination = (pageNumber) => {
        setLoading(true);

        setpageNumber(pageNumber);
        dispatch(getAllLabels(pageNumber, enabled));
    };

    return (
        <div className=" w-full md:pl-[12rem]  lg:pl-[15rem] lg1170:pl-[16rem]  xl:pl-[16rem] p-4">
            <div className="flex items-center justify-between w-full ">
                <h1 className="py-5 text-4xl mx-4">Etiquetas</h1>
                <div className="mt-2 md:ml-10">
                    <button
                        className="flex w-8 h-8 md:w-auto md:h-auto items-center justify-center p-2 ml-4  text-blue-500 border border-blue-500 rounded-lg group hover:bg-blue-500 hover:text-white"
                        // className="flex items-center px-3 py-2 mx-auto text-lg font-semibold text-blue-bluecorner border-2 border-blue-bluecorner rounded-lg group hover:bg-blue-bluecorner hover:text-white"
                        onClick={() => setcreate(!create)}>
                        <svg
                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <line x1="12" y1="5" x2="12" y2="19" />{" "}
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        <h1 className="hidden md:flex">Crea una etiqueta</h1>
                    </button>
                </div>
            </div>
            <div className="flex justify-center items-center text-center">
                {create && (
                    <ModalCreateLabels create={create} setcreate={setcreate} />
                )}
                {edit && reduxLabels.id ? (
                    <ModalEditLabels
                        edit={edit}
                        setedit={setedit}
                        reduxLabels={reduxLabels}
                    />
                ) : null}
            </div>
            <div className="flex items-center md:p-4 justify-end w-full mt-6 md:mt-0 md:ml-5">
                <SearchBar
                    setfakeRefresh={setfakeRefresh}
                    fakeRefresh={fakeRefresh}
                    enabled={enabled}
                />
                <button className="flex justify-center items-center border ml-5 w-8 h-8 md:w-11 md:h-11 rounded-lg border-blue-bluecorner relative">
                    <svg
                        onClick={() => setShowButtons((prev) => !prev)}
                        className="h-7 w-7 md:h-8 md:w-8 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                        />
                    </svg>
                    {showButtons && (
                        <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                            <div
                                onClick={() => {
                                    setenabled("Si");
                                    setShowButtons((prev) => !prev);
                                    setpageNumber(1);
                                }}
                                className=" w-32 h-11 mx-auto cursor-pointer
                                rounded-lg flex items-center justify-start p-1
                                ">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Habilitados
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    setenabled("No");
                                    setShowButtons((prev) => !prev);
                                    setpageNumber(1);
                                }}
                                className=" w-32 h-11 mx-auto cursor-pointer
                                rounded-lg  flex items-center justify-start p-1
                                ">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Deshabilitados
                                </p>
                            </div>
                        </div>
                    )}
                </button>
            </div>
            <section className="flex justify-center items-center lg:py-[10px] ">
                <div className="flex text-center max-w-full overflow-x-auto w-full">
                    {labels.total === 0 && !loading ? (
                        <h1 className="font-medium w-full text-center mt-10">
                            No se encontraron etiquetas
                        </h1>
                    ) : labels.total > 0 && !loading ? (
                        <div className="w-full px-2">
                            <table className="mt-14 w-full overflow-x-auto">
                                <thead className="text-left">
                                    <tr>
                                        <th className="px-4 w-full min-w-[8rem] md:w-1/6 ">
                                            Nombre
                                        </th>
                                        <th className="w-1/6 min-w-[6rem] hidden md:table-cell">
                                            Casa
                                        </th>

                                        <th className="w-1/6 min-w-[6rem] hidden md:table-cell">
                                            Embarcacion
                                        </th>
                                        <th
                                            align="center"
                                            className="w-[8%] min-w-[10rem] ">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-left">
                                    {labels?.data?.map((label) => (
                                        <tr
                                            key={label.id}
                                            className="border-t w-full">
                                            <td className="p-5 flex">
                                                {label.nombre}
                                            </td>
                                            <td className="hidden md:table-cell">
                                                {label.casa}
                                            </td>
                                            <td className="hidden md:table-cell">
                                                {label.embarcacion}
                                            </td>
                                            <td className="">
                                                {enabled === "Si" ? (
                                                    <div className="flex justify-center ml-9">
                                                        <button
                                                            onClick={() =>
                                                                handleEdit(
                                                                    label?.id
                                                                )
                                                            }
                                                            title="Editar"
                                                            className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                            <svg
                                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round">
                                                                <path
                                                                    stroke="none"
                                                                    d="M0 0h24v24H0z"
                                                                />
                                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                <line
                                                                    x1="16"
                                                                    y1="5"
                                                                    x2="19"
                                                                    y2="8"
                                                                />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                            onClick={() =>
                                                                handleDelete(
                                                                    label.id
                                                                )
                                                            }
                                                            title="Eliminar">
                                                            <svg
                                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round">
                                                                {" "}
                                                                <path
                                                                    stroke="none"
                                                                    d="M0 0h24v24H0z"
                                                                />{" "}
                                                                <line
                                                                    x1="4"
                                                                    y1="7"
                                                                    x2="20"
                                                                    y2="7"
                                                                />{" "}
                                                                <line
                                                                    x1="10"
                                                                    y1="11"
                                                                    x2="10"
                                                                    y2="17"
                                                                />{" "}
                                                                <line
                                                                    x1="14"
                                                                    y1="11"
                                                                    x2="14"
                                                                    y2="17"
                                                                />{" "}
                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="flex justify-center ml-9">
                                                        <button
                                                            onClick={() =>
                                                                handleEnable(
                                                                    label.id
                                                                )
                                                            }
                                                            title="Habilitar"
                                                            className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                            <svg
                                                                className="w-8 h-8 text-blue-bluecorner group-hover:text-white"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round">
                                                                {" "}
                                                                <polyline points="17 1 21 5 17 9" />{" "}
                                                                <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                <polyline points="7 23 3 19 7 15" />{" "}
                                                                <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={5}>
                                            <div className="flex justify-center">
                                                <Paginado
                                                    actualPage={pageNumber}
                                                    elementsPerPage={
                                                        LABELS_PER_PAGE
                                                    }
                                                    cantTotalElements={
                                                        labels.total
                                                    }
                                                    pagination={pagination}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </div>
            </section>
        </div>
    );
};

export default CreateLabels;
