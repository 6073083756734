import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import dividirFechaDatePicker from "../../helpers/dividirFechaDatePicker";
import getFeeByDay from "../../helpers/getFeeByDay";
import { useSelector } from "react-redux";
const ModalEditVariablePrices = ({
    modalEditVisible,
    setModalEditVisible,
    onboarding,
    fakeRefresh,
    setFakeRefresh,
    fullVariable,
    select,
    setSiguiente,
    siguiente,
    feeArray,
}) => {
    registerLocale("es", es);
    const allDates = {
        months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ],
        days: [
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
            "Domingo",
        ],
    };

    const [accion, setAccion] = useState(fullVariable.accion);

    const [input, setInput] = useState({
        tipoVariante: select || "Selección por fechas",

        diaDesde: fullVariable.dia_inicio || "Lunes",
        diaHasta: fullVariable.dia_final || "Lunes",
        numero_dias: fullVariable.numero_dias || "",
        numero_dias_final: fullVariable.numero_dias_final || "",

        costo: fullVariable.title || "",
        precioPorPersona: fullVariable.precio_persona || "",
        tipo: fullVariable.tipo || "",
        tipo_tiempo: fullVariable.tipo_tiempo || "Siempre",
        fecha_final: fullVariable.fecha_final?.slice(0, 10),
        fecha_inicio: fullVariable.fecha_inicio?.slice(0, 10),
    });
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    const [feeAvanzado, setFeeAvanzado] = useState(0);
    const getEveryDayBetween2Dates = (arrayFechas) => {
        const [startDate, endDate] = arrayFechas.map(
            (fecha) => new Date(fecha + "T00:00:00")
        );

        const date = new Date(startDate.getTime());
        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    };
    let allDatesSelected;

    useEffect(() => {
        if (input.fecha_inicio !== "" && input.fecha_final !== "") {
            allDatesSelected = getEveryDayBetween2Dates([
                input.fecha_inicio,
                input.fecha_final,
            ]);

            if (input.costo !== "") {
                const precio_por_dia = input.costo / allDatesSelected.length;
                let suma_fee_propietario = 0;

                allDatesSelected.forEach((date, index) => {
                    const {
                        fee_cliente,
                        fee_propietario,
                        tiene_fee_por_fecha,
                    } = getFeeByDay(
                        date,
                        feeArray,
                        onboarding.fee_cliente,
                        onboarding.fee_propietario,
                        precio_por_dia
                    );
                    console.log(date);
                    suma_fee_propietario += Number(fee_propietario);
                });

                setFeeAvanzado(suma_fee_propietario);
            }
        }
    }, [input]);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleClose = () => {
        setInput({
            name: "",
            description: "",
            price: 0,
            coin: "",
        });
        setFakeRefresh(!fakeRefresh);
        setModalEditVisible(!modalEditVisible);
    };

    const updateProduct = async (payload) => {
        setSiguiente(null);

        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/productoPreciosVariables/actualizar/${fullVariable.id}`,
            payload,
            { headers }
        );
        if (
            data?.error !==
            "Existe conflicto en rango de numero de dias con otro rango, verifique porfavor"
        ) {
            setInput({
                name: "",
                description: "",
                price: 0,
                coin: "",
            });
            setFakeRefresh(!fakeRefresh);
            setModalEditVisible(!modalEditVisible);
            setSiguiente("Actualizar");
        } else {
            setSiguiente("Actualizar");
            Swal.fire({
                title: data.error,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    const handleSave = () => {
        if (input.costo < 1) {
            Swal.fire({
                title: "Error",
                text: "El precio debe ser mayor a 0",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        if (
            (input.tipoVariante === "Selección por numero de días" &&
                input.tipo === "Por Día" &&
                input.numero_dias !== "" &&
                input.numero_dias > 0 &&
                input.numero_dias_final !== "" &&
                input.numero_dias_final > 0 &&
                input.costo !== "") ||
            (input.tipoVariante === "Selección por numero de días" &&
                input.tipo === "Total" &&
                input.numero_dias !== "" &&
                input.numero_dias > 0 &&
                input.costo !== "")
        ) {
            const payload = {
                id_productos: onboarding.id,
                tipo_variante: input.tipoVariante,
                mes_inicio: "",
                mes_final: "",
                numero_dias: input.numero_dias,
                numero_dias_final: input.numero_dias_final,
                dia_inicio: input.diaDesde,
                dia_final: input.diaHasta,
                precio_persona: input.precioPorPersona,
                tipo: input.tipo,
                costo: input.costo,
                accion: accion,
                tipo_tiempo: input.tipo_tiempo,
                fecha_final: input.fecha_final,
                fecha_inicio: input.fecha_inicio,
                id_usuario: authUser.id_usuario,
            };
            updateProduct(payload);
        } else if (
            input.tipoVariante === "Selección por rango exacto de días" &&
            input.diaDesde !== "" &&
            input.diaHasta !== "" &&
            input.costo !== ""
        ) {
            const payload = {
                id_productos: onboarding.id,
                tipo_variante: input.tipoVariante,
                mes_inicio: "",
                mes_final: "",
                numero_dias: "",
                dia_inicio: input.diaDesde,
                dia_final: input.diaHasta,
                precio_persona: input.precioPorPersona,
                tipo: input.tipo,
                costo: input.costo,
                accion: accion,
                tipo_tiempo: input.tipo_tiempo,
                fecha_final: input.fecha_final,
                fecha_inicio: input.fecha_inicio,
                id_usuario: authUser.id_usuario,
            };
            updateProduct(payload);
        } else {
            Swal.fire({
                title: "Error",
                text: "Debe completar todos los campos",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }

        // setModalEditVisible(!modalEditVisible);
        // setFakeRefresh(!fakeRefresh);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalEditVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 left-0 z-10 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-20 sm:py-8 ">
                    <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg  xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] p-10 overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-5 right-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>

                        <div className="w-full my-3 text-left">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base  font-bold ">
                                Editar precio variable
                            </header>
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-sm  font-semibold ">
                                {`Precio base: ${onboarding?.precio_base} (${onboarding?.moneda_precio_base})`}
                            </header>
                        </div>
                        <div className="w-full text-left ">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base ">
                                Tipo variante
                            </header>
                        </div>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]">
                            <select
                                name="tipoVariante"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                                value={input.tipoVariante}
                                onChange={handleInputChange}>
                                <option>Selección por numero de días</option>

                                <option>
                                    Selección por rango exacto de días
                                </option>
                            </select>
                        </div>
                        <div className="w-full mt-2  rounded-lg border border-[#E9E9E9] ">
                            <select
                                name="tipo"
                                className="w-full my-3 bg-white border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                                value={input.tipo}
                                onChange={handleInputChange}>
                                <option value="Por Día">
                                    {" "}
                                    {input.tipoVariante ===
                                    "Selección por rango exacto de días"
                                        ? "Costo adicional"
                                        : "Costo Por Día"}
                                </option>
                                <option value="Total">Costo Total</option>
                            </select>
                        </div>
                        <div
                            className={
                                "w-full mt-2  rounded-lg border border-[#E9E9E9] "
                            }>
                            <select
                                name="tipo_tiempo"
                                className="w-full my-3 bg-white border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                                value={input.tipo_tiempo}
                                onChange={handleInputChange}>
                                <option value="Siempre">Siempre</option>
                                <option value="Por fechas">Por fechas</option>
                            </select>
                        </div>

                        {input.tipo_tiempo === "Por fechas" && (
                            <div className="flex mt-4 mb-2 justify-evenly">
                                <div className="flex flex-col ">
                                    <label className="w-full ml-1 text-base font-semibold ">
                                        Fecha inicial:
                                    </label>

                                    <DatePicker
                                        className="w-11/12 p-2 bg-white border rounded-lg cursor-pointer"
                                        placeholderText="Seleccionar fecha"
                                        locale="es"
                                        autoComplete="off"
                                        name="fecha_inicio"
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            input.fecha_inicio !== ""
                                                ? new Date(
                                                      dividirFechaDatePicker(
                                                          input.fecha_inicio
                                                      )[0],
                                                      dividirFechaDatePicker(
                                                          input.fecha_inicio
                                                      )[1] - 1,
                                                      dividirFechaDatePicker(
                                                          input.fecha_inicio
                                                      )[2]
                                                  )
                                                : input.fecha_inicio
                                        }
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                fecha_inicio: `${e.getFullYear()}-${`${
                                                    e.getMonth() + 1
                                                }`.padStart(
                                                    2,
                                                    "0"
                                                )}-${`${e.getDate()}`.padStart(
                                                    2,
                                                    "0"
                                                )}`,
                                            })
                                        }
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="w-full ml-1 text-base font-semibold ">
                                        Fecha final:
                                    </label>

                                    <DatePicker
                                        className="w-11/12 p-2 bg-white border rounded-lg cursor-pointer"
                                        placeholderText="Seleccionar fecha"
                                        locale="es"
                                        autoComplete="off"
                                        name="fecha_final"
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            input.fecha_final !== ""
                                                ? new Date(
                                                      dividirFechaDatePicker(
                                                          input.fecha_final
                                                      )[0],
                                                      dividirFechaDatePicker(
                                                          input.fecha_final
                                                      )[1] - 1,
                                                      dividirFechaDatePicker(
                                                          input.fecha_final
                                                      )[2]
                                                  )
                                                : input.fecha_final
                                        }
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                fecha_final: `${e.getFullYear()}-${`${
                                                    e.getMonth() + 1
                                                }`.padStart(
                                                    2,
                                                    "0"
                                                )}-${`${e.getDate()}`.padStart(
                                                    2,
                                                    "0"
                                                )}`,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {input.tipoVariante ===
                            "Selección por numero de días" &&
                            input.tipo === "Por Día" && (
                                <div className="flex flex-col mt-1 text-[#3C3C3C] ">
                                    <div className="inline-flex items-center mt-2">
                                        <div className="p-2 -ml-1 text-base font-semibold ">
                                            <label className="text-base font-semibold ">
                                                A partir de
                                            </label>
                                            <input
                                                value={input.numero_dias}
                                                className=" ml-4 p-2 py-2 mt-1.5 rounded-lg border border-[#E9E9E9] outline-none w-14 placeholder:text-center "
                                                placeholder="10"
                                                type="number"
                                                step=".01"
                                                name="numero_dias"
                                                onChange={handleInputChange}
                                            />{" "}
                                            a{" "}
                                            <input
                                                value={input.numero_dias_final}
                                                className="  p-2 py-2 mt-1.5 rounded-lg border border-[#E9E9E9] outline-none w-14 placeholder:text-center "
                                                placeholder="10"
                                                type="number"
                                                step=".01"
                                                name="numero_dias_final"
                                                onChange={handleInputChange}
                                            />
                                            <label className="ml-2 text-base font-semibold ">
                                                dias
                                            </label>
                                        </div>
                                    </div>

                                    <div className="inline-flex items-center ">
                                        <div className="p-2 -ml-1 text-base font-semibold ">
                                            <label className="text-base font-semibold ">
                                                {`Precio total (${onboarding?.moneda_precio_base})`}
                                            </label>
                                            <select
                                                defaultValue={
                                                    fullVariable.accion
                                                }
                                                onChange={(e) =>
                                                    setAccion(e.target.value)
                                                }
                                                className="outline-none rounded-lg border border-[#E9E9E9] ml-2 mr-2 md:mr-0 text-base px-2 py-2 ">
                                                <option className="text-lg font-semibold">
                                                    +
                                                </option>
                                                <option className="text-lg font-semibold">
                                                    -
                                                </option>
                                            </select>
                                            <input
                                                value={input.costo}
                                                className=" ml-4 p-2 py-2 mt-1.5 rounded-lg border border-[#E9E9E9] outline-none w-14 placeholder:text-center "
                                                placeholder="10"
                                                type="number"
                                                step=".01"
                                                name="costo"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                        {input.tipoVariante ===
                            "Selección por numero de días" &&
                            input.tipo === "Total" && (
                                <div className="flex flex-col text-[#3C3C3C] ">
                                    <div className="inline-flex items-center mt-2 ">
                                        <div className="p-2 -ml-1 text-base font-semibold ">
                                            <label className="text-base font-semibold ">
                                                Si el cliente reserva
                                            </label>
                                            <input
                                                className=" ml-4 p-2 py-2 mt-1.5 rounded-lg border border-[#E9E9E9] outline-none w-14 placeholder:text-center "
                                                placeholder="10"
                                                type="number"
                                                step=".01"
                                                name="numero_dias"
                                                value={input.numero_dias}
                                                onChange={handleInputChange}
                                            />
                                            <label className="ml-4 text-base font-semibold ">
                                                dias
                                            </label>
                                        </div>
                                    </div>

                                    <div className="inline-flex items-center ">
                                        <div className="p-2 -ml-1 text-base font-semibold ">
                                            <label className="text-base font-semibold ">
                                                {`Precio total (${onboarding?.moneda_precio_base})`}
                                            </label>

                                            <input
                                                className="md:ml-3 p-2 py-2 mt-1.5 rounded-lg border border-[#E9E9E9] outline-none w-24 placeholder:text-center"
                                                placeholder="10"
                                                type="number"
                                                step=".01"
                                                name="costo"
                                                value={input.costo}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                        {input.tipoVariante ===
                            "Selección por rango exacto de días" &&
                            input.tipo === "Por Día" && (
                                <div className="flex flex-col mt-3 text-[#3C3C3C]">
                                    <div className="flex w-10/12 pl-2 md:w-full md:pl-0">
                                        <div className="md:mr-5 -ml-1 -mt-1.5">
                                            <label className="p-2 text-base font-semibold ">
                                                Desde:
                                            </label>

                                            <select
                                                value={input.diaDesde}
                                                name="diaDesde"
                                                className="w-32 p-2 mt-1.5 rounded-lg border border-[#E9E9E9] md:w-auto"
                                                onChange={handleInputChange}>
                                                {allDates.days.map((month) => (
                                                    <option key={month}>
                                                        {month}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className=" ml-3 -mt-1.5">
                                            <label className="p-2 text-base font-semibold ">
                                                Hasta:
                                            </label>

                                            <select
                                                value={input.diaHasta}
                                                name="diaHasta"
                                                className="w-32 p-2 mt-1.5 rounded-lg border border-[#E9E9E9] md:w-auto"
                                                onChange={handleInputChange}>
                                                {allDates.days.map((month) => (
                                                    <option key={month}>
                                                        {month}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="inline-flex items-center justify-center mt-2 md:justify-start">
                                        <div className="flex items-center mx-1 ">
                                            <div className="mb-2 text-center">
                                                <label className="mr-4 text-base font-semibold md:pl-0 md:mr-11 ">
                                                    {`${
                                                        input.tipoVariante ===
                                                            "Selección por rango exacto de días" &&
                                                        input.tipo === "Por Día"
                                                            ? "Costo adicional"
                                                            : input.tipo
                                                    } (${
                                                        onboarding?.moneda_precio_base
                                                    })`}
                                                </label>
                                                <select
                                                    defaultValue={
                                                        fullVariable.accion
                                                    }
                                                    onChange={(e) =>
                                                        setAccion(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="outline-none rounded-lg border border-[#E9E9E9] mr-2  text-base px-2 py-2 ">
                                                    <option className="text-lg font-semibold">
                                                        +
                                                    </option>
                                                    <option className="text-lg font-semibold">
                                                        -
                                                    </option>
                                                </select>

                                                <input
                                                    value={input.costo}
                                                    className="md:ml-3 p-2 pt-[9.5px] rounded-lg border border-[#E9E9E9] outline-none w-14 placeholder:text-center"
                                                    placeholder="10"
                                                    type="number"
                                                    step=".01"
                                                    name="costo"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        {input.tipoVariante ===
                            "Selección por rango exacto de días" &&
                            input.tipo === "Total" && (
                                <div className="flex flex-col mt-3 text-[#3C3C3C]">
                                    <div className="flex w-10/12 pl-2 md:w-full md:pl-0">
                                        <div className=" md:mr-5 md:ml-2 -mt-1.5">
                                            <label className="p-2 text-base font-semibold ">
                                                Desde:
                                            </label>

                                            <select
                                                name="diaDesde"
                                                className="w-32 p-2 mt-1.5 rounded-lg border border-[#E9E9E9] md:w-auto bg-white "
                                                onChange={handleInputChange}
                                                value={input.diaDesde}>
                                                {allDates.days.map((month) => (
                                                    <option key={month}>
                                                        {month}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className=" ml-3 -mt-1.5">
                                            <label className="p-2 text-base font-semibold ">
                                                Hasta:
                                            </label>

                                            <select
                                                name="diaHasta"
                                                className="w-32 p-2 mt-1.5 rounded-lg border border-[#E9E9E9] md:w-auto bg-white "
                                                onChange={handleInputChange}
                                                value={input.diaHasta}>
                                                {allDates.days.map((month) => (
                                                    <option key={month}>
                                                        {month}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="inline-flex items-center justify-center mt-2 md:justify-start">
                                        <div className="flex items-center mx-1 ">
                                            <div className="mb-2 ml-1 text-center">
                                                <label className="mr-2 text-base font-semibold md:pl-0 md:mr-11 ">
                                                    {`Precio total (${onboarding?.moneda_precio_base})`}
                                                </label>

                                                <input
                                                    className="md:ml-3 p-2 pt-[9.5px] rounded-lg border border-[#E9E9E9] outline-none w-24 placeholder:text-center"
                                                    placeholder="10"
                                                    type="number"
                                                    step=".01"
                                                    name="costo"
                                                    value={input.costo}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        <button
                            className="w-full py-3 mt-4 text-center text-white border border-blue-400 rounded-md bg-blue-bluecorner hover:bg-white hover:text-blue-bluecorner disabled:bg-blue-400"
                            onClick={(e) => handleSave(e)}
                            disabled={!siguiente}>
                            {siguiente ? siguiente : <Spinner width={7} />}
                        </button>

                        {/* Selección por numero de días/Por Día/Siempre */}
                        {input.tipoVariante ===
                            "Selección por numero de días" &&
                            input.tipo === "Por Día" &&
                            input.tipo_tiempo === "Siempre" &&
                            input.costo > 0 &&
                            input.numero_dias !== "" &&
                            input.numero_dias_final !== "" && (
                                <div className="w-72">
                                    <p className="text-[#3C3C3C] text-sm  mt-2 ">
                                        {`Al cliente se le ${
                                            accion === "+"
                                                ? "cobrara dicional"
                                                : "descontara"
                                        } un valor de  "${input.costo} ${
                                            onboarding.tipo_producto ===
                                            "embarcacion"
                                                ? onboarding?.tipo_moneda
                                                : onboarding.moneda_precio_base
                                        } por dia". si elige de ${
                                            input.numero_dias
                                        } a ${input.numero_dias_final} dias.`}
                                    </p>
                                </div>
                            )}

                        {/* Selección por numero de días/Por Día/Por fechas */}
                        {input.tipoVariante ===
                            "Selección por numero de días" &&
                            input.tipo === "Por Día" &&
                            input.tipo_tiempo === "Por fechas" &&
                            input.costo > 0 &&
                            input.numero_dias !== "" &&
                            input.numero_dias_final !== "" &&
                            input.fecha_inicio !== "" &&
                            input.fecha_final !== "" && (
                                <div className="w-72">
                                    <p className="text-[#3C3C3C] text-sm  mt-2 ">
                                        {`Al cliente se le ${
                                            accion === "+"
                                                ? "cobrara dicional"
                                                : "descontara"
                                        } un valor de  "${input.costo} ${
                                            onboarding.tipo_producto ===
                                            "embarcacion"
                                                ? onboarding?.tipo_moneda
                                                : onboarding.moneda_precio_base
                                        } por dia". si elige de ${
                                            input.numero_dias
                                        } a ${
                                            input.numero_dias_final
                                        } dias entre  ${input.fecha_inicio
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")} al ${input.fecha_final
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")} .`}
                                    </p>
                                </div>
                            )}

                        {/* Selección por numero de días/Costo total/Siempre */}
                        {input.tipoVariante ===
                            "Selección por numero de días" &&
                            input.tipo === "Total" &&
                            input.tipo_tiempo === "Siempre" &&
                            input.costo > 0 &&
                            input.numero_dias !== "" && (
                                <div className="w-72">
                                    <p className="text-[#3C3C3C] text-sm  mt-2 ">
                                        {`Al cliente se le cobrara un valor total de ${
                                            input.costo
                                        } ${
                                            onboarding.tipo_producto ===
                                            "embarcacion"
                                                ? onboarding?.tipo_moneda
                                                : onboarding.moneda_precio_base
                                        }. si elige ${input.numero_dias} dias.`}
                                    </p>
                                </div>
                            )}

                        {/* Selección por numero de días/Costo total/Por fechas */}
                        {input.tipoVariante ===
                            "Selección por numero de días" &&
                            input.tipo === "Total" &&
                            input.tipo_tiempo === "Por fechas" &&
                            input.costo > 0 &&
                            input.numero_dias !== "" &&
                            input.fecha_inicio !== "" &&
                            input.fecha_final !== "" && (
                                <div className="w-72">
                                    <p className="text-[#3C3C3C] text-sm  mt-2 ">
                                        {`Al cliente se le cobrara un valor total de ${
                                            input.costo
                                        } ${
                                            onboarding.tipo_producto ===
                                            "embarcacion"
                                                ? onboarding?.tipo_moneda
                                                : onboarding.moneda_precio_base
                                        }. si elige ${
                                            input.numero_dias
                                        } dias entre  ${input.fecha_inicio
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")} al ${input.fecha_final
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")} .`}
                                    </p>
                                </div>
                            )}

                        {/* Selección por rango exacto de días/Costo adicional/Siempre */}
                        {input.tipoVariante ===
                            "Selección por rango exacto de días" &&
                            input.tipo === "Por Día" &&
                            input.tipo_tiempo === "Siempre" &&
                            input.costo > 0 &&
                            input.diaDesde !== "" &&
                            input.diaHasta !== "" && (
                                <div className="w-72">
                                    <p className="text-[#3C3C3C] text-sm  mt-2 ">
                                        {`Al cliente se le ${
                                            accion === "+"
                                                ? "cobrara dicional"
                                                : "descontara"
                                        } un valor de  "${input.costo} ${
                                            onboarding.tipo_producto ===
                                            "embarcacion"
                                                ? onboarding?.tipo_moneda
                                                : onboarding.moneda_precio_base
                                        } por dia". si elige del ${
                                            input.diaDesde
                                        } al ${input.diaHasta} .`}
                                    </p>
                                </div>
                            )}

                        {/* Selección por rango exacto de días/Costo adicional/Por fechas */}
                        {input.tipoVariante ===
                            "Selección por rango exacto de días" &&
                            input.tipo === "Por Día" &&
                            input.tipo_tiempo === "Por fechas" &&
                            input.costo > 0 &&
                            input.diaDesde !== "" &&
                            input.diaHasta !== "" &&
                            input.fecha_inicio !== "" &&
                            input.fecha_final !== "" && (
                                <div className="w-72">
                                    <p className="text-[#3C3C3C] text-sm  mt-2 ">
                                        {`Al cliente se le ${
                                            accion === "+"
                                                ? "cobrara dicional"
                                                : "descontara"
                                        } un valor de  "${input.costo} ${
                                            onboarding.tipo_producto ===
                                            "embarcacion"
                                                ? onboarding?.tipo_moneda
                                                : onboarding.moneda_precio_base
                                        } por dia". si elige del ${
                                            input.diaDesde
                                        } al ${
                                            input.diaHasta
                                        } entre  ${input.fecha_inicio
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")} al ${input.fecha_final
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")} `}
                                    </p>
                                </div>
                            )}

                        {/* Selección por rango exacto de días/Costo total/Siempre */}
                        {input.tipoVariante ===
                            "Selección por rango exacto de días" &&
                            input.tipo === "Total" &&
                            input.tipo_tiempo === "Siempre" &&
                            input.costo > 0 &&
                            input.diaDesde !== "" &&
                            input.diaHasta !== "" && (
                                <div className="w-72">
                                    <p className="text-[#3C3C3C] text-sm  mt-2 ">
                                        {`Al cliente se le cobrara un valor total de ${
                                            input.costo
                                        } ${
                                            onboarding.tipo_producto ===
                                            "embarcacion"
                                                ? onboarding?.tipo_moneda
                                                : onboarding.moneda_precio_base
                                        } si elige del ${input.diaDesde} al ${
                                            input.diaHasta
                                        } .`}
                                    </p>
                                </div>
                            )}

                        {/* Selección por rango exacto de días/Costo total/Por fechas */}
                        {input.tipoVariante ===
                            "Selección por rango exacto de días" &&
                            input.tipo === "Total" &&
                            input.tipo_tiempo === "Por fechas" &&
                            input.costo > 0 &&
                            input.diaDesde !== "" &&
                            input.diaHasta !== "" &&
                            input.fecha_inicio !== "" &&
                            input.fecha_final !== "" && (
                                <div className="w-72">
                                    <p className="text-[#3C3C3C] text-sm  mt-2 ">
                                        {`Al cliente se le cobrara un valor total de ${
                                            input.costo
                                        } ${
                                            onboarding.tipo_producto ===
                                            "embarcacion"
                                                ? onboarding?.tipo_moneda
                                                : onboarding.moneda_precio_base
                                        } si elige del ${input.diaDesde} al ${
                                            input.diaHasta
                                        } entre  ${input.fecha_inicio
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")} al ${input.fecha_final
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("-")} .`}
                                    </p>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditVariablePrices;
