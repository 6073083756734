import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { logoutUser } from "../../redux/actions/authActions";

const NavbarEmpresas = ({ selected }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        avatar,
        color_primario,
        color_secundario,
        tipo_usuario,
        logo,
        dias_prueba,
        tipo_membresia,
        nombre_empresa,
        password_cambiado,
    } = useSelector((state) => state.authReducer);

    const [burguerMenu, setBurguerMenu] = useState(false);
    const [menuSelected, setMenuSelected] = useState(selected);
    const [perfil, setPerfil] = useState(false);

    return (
        <>
            <div
                style={{ backgroundColor: color_primario }}
                className={`relative flex items-center justify-between p-1 md723:px-4 md723:pt-2 w-full `}
            >
                <div
                    onClick={() => {
                        navigate("/");
                    }}
                    className="relative cursor-pointer group"
                >
                    <div
                        className={`absolute w-full h-full top-0 -left-1  bg-[#E1E1E1]  rounded-2xl opacity-20 z-10 px-4 group-hover:opacity-40`}
                    ></div>
                    <div className="flex items-center gap-5 p-3 z-20">
                        <img
                            alt="Logo empresa"
                            src={`${process.env.REACT_APP_PROXY}/empresas/getImage/${logo}`}
                            className="h-12 xs:h-16 z-20"
                        />
                        <div className="text-white text-3xl font-semibold uppercase z-20 mx-2">
                            {nombre_empresa}
                        </div>
                    </div>
                </div>
                <div className="items-center justify-between hidden gap-4 text-white sm:flex">
                    <div
                        onClick={() => {
                            setMenuSelected("Usuarios");
                            navigate("/dashboard-empresas/usuarios");
                        }}
                        className={`${
                            menuSelected === "Usuarios"
                                ? `  bg-[#E1E1E1]/30`
                                : ` text-white hover:bg-[#E1E1E1]/30 `
                        } ${
                            tipo_usuario === 6 ? `` : `hidden `
                        } text-xs md723:text-sm md818:text-base px-4 text-center h-14  flex items-center justify-center cursor-pointer rounded-lg`}
                    >
                        <p>Usuarios</p>
                    </div>
                    <div
                        onClick={() => {
                            navigate("/dashboard-empresas/promociones");
                        }}
                        className={`${
                            menuSelected === "promociones"
                                ? `  bg-[#E1E1E1]/30`
                                : ` text-white hover:bg-[#E1E1E1]/30 `
                        } ${
                            tipo_usuario === 6 ? `` : `hidden `
                        } text-xs md723:text-sm md818:text-base px-4 text-center h-14  flex items-center justify-center cursor-pointer rounded-lg`}
                    >
                        <p>Crear promociones</p>
                    </div>
                    <div
                        onClick={() => {
                            navigate("/dashboard-empresas/reservaciones");
                        }}
                        className={`${
                            menuSelected === "Reservaciones"
                                ? `  bg-[#E1E1E1]/30`
                                : ` text-white hover:bg-[#E1E1E1]/30 `
                        } text-xs md723:text-sm md818:text-base px-4 text-center h-14 flex items-center justify-center cursor-pointer rounded-lg`}
                    >
                        <p>Reservaciones</p>
                    </div>
                    <div
                        onClick={() => {
                            navigate("/dashboard-empresas/editar-empresa");
                        }}
                        className={`${
                            menuSelected === "miEmpresa"
                                ? `  bg-[#E1E1E1]/30`
                                : ` text-white hover:bg-[#E1E1E1]/30 `
                        } ${
                            tipo_usuario === 6 ? `` : `hidden `
                        } text-xs md723:text-sm md818:text-base px-4 text-center h-14  flex items-center justify-center cursor-pointer rounded-lg`}
                    >
                        <p>Mi empresa</p>
                    </div>
                    <div
                        onClick={() => {
                            navigate("/dashboard-empresas/paquetes");
                        }}
                        className={`${
                            menuSelected === "paquetes"
                                ? `  bg-[#E1E1E1]/30`
                                : ` text-white hover:bg-[#E1E1E1]/30 `
                        } ${
                            tipo_usuario === 6 ? `` : `hidden `
                        } text-xs md723:text-sm md818:text-base px-4 text-center h-14  flex items-center justify-center cursor-pointer rounded-lg`}
                    >
                        <p>Paquetes</p>
                    </div>
                    <div
                        onClick={() => {
                            navigate("/dashboard-empresas/resenas");
                        }}
                        className={`${
                            menuSelected === "reseñas"
                                ? ` bg-[#E1E1E1]/30`
                                : ` text-white hover:bg-[#E1E1E1]/30 `
                        } ${
                            tipo_usuario === 7 ? `` : `hidden `
                        } text-xs md723:text-sm md818:text-base px-4 text-center h-14 flex items-center justify-center cursor-pointer rounded-lg`}
                    >
                        <p>Reseñas</p>
                    </div>
                    <div
                        onClick={() => {
                            navigate("/dashboard-empresas/favoritos");
                        }}
                        className={`${
                            menuSelected === "favoritos"
                                ? ` bg-[#E1E1E1]/30`
                                : ` text-white hover:bg-[#E1E1E1]/30 `
                        } ${
                            tipo_usuario === 7 ? `` : `hidden `
                        } text-xs md723:text-sm md818:text-base px-4 text-center h-14 flex items-center justify-center cursor-pointer rounded-lg`}
                    >
                        <p>Favoritos</p>
                    </div>
                </div>
                <div
                    className="relative cursor-pointer sm:hidden"
                    onClick={() => (
                        setBurguerMenu(!burguerMenu), setMenuSelected(false)
                    )}
                >
                    {!burguerMenu ? (
                        <svg
                            className="w-8 h-8 text-white sm:hidden"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-8 h-8 text-white sm:hidden"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    )}
                </div>
                <div
                    onMouseOver={() => setPerfil(true)}
                    onMouseOut={() => setPerfil(false)}
                    className="relative hidden mr-3 cursor-pointer sm:block"
                >
                    {password_cambiado === "No" && !perfil && (
                        <button
                            className={`absolute -bottom-20 right-8 border-none p-4 bg-yellow-500 text-white text-xs rounded-b-md rounded-tl-md animate-bounce`}
                        >
                            Cambia tu Contraseña
                        </button>
                    )}

                    <img
                        style={{
                            borderColor: color_secundario,
                        }}
                        src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${avatar}`}
                        className={`rounded-full w-12 h-12 border-[3px] object-cover cursor-pointer `}
                        alt="foto de perfil"
                    />
                    <div className="absolute z-50 -left-36 top-10">
                        <div
                            className={
                                perfil
                                    ? "shadow-md block bg-white z-50 w-56 mt-5 pt-4 m-1"
                                    : "hidden"
                            }
                        >
                            <div className="z-50 flex items-center h-full px-4">
                                <div
                                    className={`block w-full text-[${color_primario}]  h-full pb-4 `}
                                >
                                    <p className="px-2 mb-2 font-bold">
                                        Cuenta
                                    </p>

                                    <div
                                        onClick={() => (
                                            navigate(
                                                "/dashboard-empresas/perfil"
                                            ),
                                            setBurguerMenu(false)
                                        )}
                                        className="px-2 w-full h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start "
                                    >
                                        <p>Mi perfil</p>
                                    </div>

                                    <div
                                        onClick={() => (
                                            navigate(
                                                "/dashboard-empresas/cambiar-password"
                                            ),
                                            setBurguerMenu(false)
                                        )}
                                        className="px-2 w-full h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start "
                                    >
                                        <p>Cambiar contraseña</p>
                                    </div>
                                    <div
                                        onClick={() => (
                                            navigate(
                                                "/dashboard-empresas/membresias"
                                            ),
                                            setBurguerMenu(false)
                                        )}
                                        className={
                                            tipo_usuario === 6
                                                ? "px-2  w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start  "
                                                : "hidden"
                                        }
                                    >
                                        <p>Membresía</p>
                                    </div>
                                    <hr
                                        className={` mx-auto border-[${color_primario}] my-2 `}
                                    />
                                    <div
                                        onClick={() => (
                                            dispatch(logoutUser()),
                                            navigate("/")
                                        )}
                                        className="px-2 w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start   "
                                    >
                                        <p>Cerrar sesión</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        burguerMenu
                            ? "shadow-md bg-white w-full block z-50 sm:hidden absolute top-[4.5rem] left-0"
                            : "hidden"
                    }
                >
                    <div className="z-50 flex items-center h-full px-4 bg-white ">
                        <div
                            className={`block bg-white w-full text-[${color_primario}] h-full pb-4 `}
                        >
                            <p className="mt-4 mb-2 font-bold ">Menú</p>

                            <div
                                onClick={() => (
                                    navigate("/dashboard-empresas/usuarios"),
                                    setBurguerMenu(false)
                                )}
                                className={
                                    tipo_usuario === 6
                                        ? "  w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start  "
                                        : "hidden"
                                }
                            >
                                <p>Usuarios</p>
                            </div>
                            <div
                                onClick={() => (
                                    navigate("/dashboard-empresas/promociones"),
                                    setBurguerMenu(false)
                                )}
                                className={
                                    tipo_usuario === 6
                                        ? "  w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start  "
                                        : "hidden"
                                }
                            >
                                <p>Crear promociones</p>
                            </div>
                            <div
                                onClick={() => (
                                    navigate(
                                        "/dashboard-empresas/reservaciones"
                                    ),
                                    setBurguerMenu(false)
                                )}
                                className="  w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start  "
                            >
                                <p>Reservaciones</p>
                            </div>
                            <div
                                onClick={() => (
                                    navigate(
                                        "/dashboard-empresas/editar-empresa"
                                    ),
                                    setBurguerMenu(false)
                                )}
                                className={
                                    tipo_usuario === 6
                                        ? "  w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start  "
                                        : "hidden"
                                }
                            >
                                <p>Mi empresa</p>
                            </div>
                            <div
                                onClick={() => (
                                    navigate("/dashboard-empresas/paquetes"),
                                    setBurguerMenu(false)
                                )}
                                className={
                                    tipo_usuario === 6
                                        ? "  w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start  "
                                        : "hidden"
                                }
                            >
                                <p>Paquetes</p>
                            </div>
                            <div
                                onClick={() => (
                                    navigate("/dashboard-empresas/resenas"),
                                    setBurguerMenu(false)
                                )}
                                className={
                                    tipo_usuario === 7
                                        ? "  w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start  "
                                        : "hidden"
                                }
                            >
                                <p>Reseñas</p>
                            </div>
                            <div
                                onClick={() => (
                                    navigate("/dashboard-empresas/favoritos"),
                                    setBurguerMenu(false)
                                )}
                                className={
                                    tipo_usuario === 7
                                        ? "  w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start  "
                                        : "hidden"
                                }
                            >
                                <p>Favoritos</p>
                            </div>
                            <hr
                                className={` mx-auto border-[${color_primario}] my-2 `}
                            />

                            <p className="mb-2 font-bold ">Cuenta</p>

                            <div
                                onClick={() => (
                                    navigate("/dashboard-empresas/perfil"),
                                    setBurguerMenu(false)
                                )}
                                className="  w-full h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start "
                            >
                                <p>Mi perfil</p>
                            </div>
                            <div
                                onClick={() => (
                                    navigate("/dashboard-empresas/membresias"),
                                    setBurguerMenu(false)
                                )}
                                className="  w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start  "
                            >
                                <p>Membresía</p>
                            </div>
                            <hr
                                className={` mx-auto border-[${color_primario}] my-2 `}
                            />
                            <div
                                onClick={() => (
                                    dispatch(logoutUser()), navigate("/")
                                )}
                                className="w-full h-11 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start   "
                            >
                                <p>Cerrar sesión</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {tipo_membresia === "Prueba" && (
                <>
                    <div className="">
                        <div className="flex-col flex bg-[#FF8038] p-2 text-white z-40 justify-center items-center text-center md:flex-row w-full md:gap-5">
                            <span className="flex items-center text-xs font-bold lg:text-sm">
                                Esta es una versión de prueba - te quedan{" "}
                                {dias_prueba} días
                            </span>
                            <div
                                onClick={() =>
                                    navigate("/dashboard-empresas/membresias")
                                }
                                className={
                                    tipo_usuario === 6
                                        ? "flex justify-center p-1 mt-2 cursor-pointer md:mt-0"
                                        : "hidden"
                                }
                            >
                                <button className="bg-white rounded-lg p-2 px-5 text-base text-[#FF8038]">
                                    Activa tu membresía
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default NavbarEmpresas;
