import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ModalImagen = ({ setModalVisible, imagen, tipo }) => {
    const [photo, setPhoto] = useState({
        src: `${
            process.env.REACT_APP_PROXY_ARCHIVOS
        }${"/bluecorner/v1/productosHabitaciones/getImagen/"}${imagen}`,
        original: `${
            process.env.REACT_APP_PROXY_ARCHIVOS
        }${"/bluecorner/v1/productosHabitaciones/getImagen/"}${imagen}`,
        width: 400,
        height: 200,
    });

    const handleCloseModal = () => {
        setModalVisible(null);
    };

    return (
        <div
            className={`transition-all ease-out duration-500 flex-col bg-slate-50 absolute left-0 top-0 z-[999] h-[1000vh] w-full items-center`}
        >
            <div className="flex flex-col w-full h-full">
                <div
                    onClick={() => handleCloseModal()}
                    className="flex items-center justify-center w-8 h-8 p-2 m-4 font-medium border border-gray-300 rounded-full shadow-md cursor-pointer"
                >
                    <svg
                        className="w-4 h-4 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>

                <div className="w-full mx-auto xs:w-10/12">
                    {!!imagen && (
                        <Lightbox
                            mainSrc={photo.original}
                            onCloseRequest={handleCloseModal}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ModalImagen;
