import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ToggleButton from "../../ToggleButton";

const EditarPromocion = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
}) => {
    const [precioAntes, setPrecioAntes] = useState(
        producto.data.precio_tachado === null
            ? ""
            : producto.data.precio_tachado
    );
    const [toggleButton, setToggleButton] = useState(
        producto.data.mostrar_descuento === "Si" ? true : false
    );
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleEdit = () => {};

    const handleGuardar = async () => {
        if (!toggleButton) {
            const json = await axios.put(
                `${process.env.REACT_APP_PROXY}/${
                    producto.data.tipo_producto === "casa"
                        ? "casa"
                        : "embarcacion"
                }/actualizarDescuento/${producto.data.id}`,
                {
                    mostrar_descuento: "No",
                    precio_tachado: null,
                },

                { headers }
            );

            if (json.data.message === "actuaizacion exitoso") {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        mostrar_descuento: "No",
                        precio_tachado: null,
                    },
                });
                setModal(false);
            }
        } else {
            if (precioAntes < 1) {
                Swal.fire({
                    title: "La promocion debe tener un precio tachado para poder ser activada",
                    icon: "warning",
                });
            } else {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/${
                        producto.data.tipo_producto === "casa"
                            ? "casa"
                            : "embarcacion"
                    }/actualizarDescuento/${producto.data.id}`,
                    {
                        mostrar_descuento: "Si",
                        precio_tachado: precioAntes,
                    },

                    { headers }
                );

                if (json.data.message === "actuaizacion exitoso") {
                    setProducto({
                        ...producto,
                        data: {
                            ...producto.data,
                            mostrar_descuento: "Si",
                            precio_tachado: precioAntes,
                        },
                    });
                    setModal(false);
                }
            }
        }
    };

    return (
        <div className="p-4 border rounded-lg">
            <div>
                <div>
                    <div className="flex items-center justify-between w-full">
                        <h1>Promocion</h1>
                        <div>
                            <div className="flex items-center mx-2">
                                <label className="mr-2 ">Precio:</label>
                                <input
                                    disabled={!toggleButton}
                                    autoComplete="off"
                                    type="number"
                                    name="Precio tachado"
                                    onChange={(e) =>
                                        setPrecioAntes(e.target.value)
                                    }
                                    value={precioAntes}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                    placeholder="Precio tachado"
                                />
                                <label className="ml-2 ">
                                    {producto.data.moneda_precio_base ||
                                        producto.data.tipo_moneda}
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <p className="mr-2">
                                {!toggleButton ? "Desactivada" : "Activada"}
                            </p>
                            <ToggleButton
                                isToggled={toggleButton}
                                onChangeToggle={(e) => setToggleButton(e)}
                            />
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarPromocion;
