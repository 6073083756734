import NavBar from "../../NavBar";
import imgBlueSale from "../../../img/promos/promo_blue_sale.webp";
import imgCasa from "../../../img/promos/Banner-web-650x250-1.webp";
import imgEmbarcacion from "../../../img/promos/Banner-web-650x250-2.webp";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import colores from "../../helpers/colores";
import SecondHouses from "./SecondHouses";
import FirstHouses from "./FirstHouses";
import ToggleButton from "../../ToggleButton";
import { ModalAdmin } from "./ModalAdmin";
import Footer from "../../Footer";
import { Helmet } from "react-helmet";

const BlueSale = () => {
    const [toggleButton, setToggleButton] = useState(false);
    const HOUSES = [
        {
            nombre_identificador: "Casa Abuelos",
            house_id: 33362,
            precio_antes: 438,
            precio_despues: 389,
            moneda: "USD",
        },
        {
            nombre_identificador: "Retablo",
            house_id: 23962,
            precio_antes: 750,
            precio_despues: 667,
            moneda: "USD",
        },
        {
            nombre_identificador: "Floresta",
            house_id: 22239,
            precio_antes: 2250,
            precio_despues: 2000,
            moneda: "PEN",
        },
    ];

    const SECOND_HOUSES = [
        {
            nombre_identificador: "Casa Dalia",
            house_id: 19026,
            precio_antes: 5398,
            precio_despues: 4798,
            moneda: "PEN",
        },
        {
            nombre_identificador: "Casa Oceanica",
            house_id: 27544,
            precio_antes: 3125,
            precio_despues: 2778,
            moneda: "PEN",
        },
        {
            nombre_identificador: "Casa Alba",
            house_id: 18777,
            precio_antes: 2500,
            precio_despues: 2222,
            moneda: "PEN",
        },
    ];

    const authUser = useSelector((state) => state.authReducer);
    const [houses, setHouses] = useState([]);
    const [secondHouses, setSecondHouses] = useState({
        initial_date: "06/01/2023",
        final_date: "08/01/2023",
        second_houses: [],
    });
    const [indexImagen, setIndexImagen] = useState({
        indexIma: -2,
        indexCar: -1,
    });
    const [editar, setEditar] = useState({
        editar: false,
        id: null,
        fecha_inicio: "",
        fecha_final: "",
    });

    const [modalAgregar, setModalAgregar] = useState({
        activo: false,
        id_grupo: null,
        fecha_inicio: null,
        fecha_final: null,
    });

    const getHouses = async () => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/gruposBlueSale/allLanding/`
        );
        console.log(data);
        setHouses(data.data);
    };

    useEffect(() => {
        getHouses();
        // getSecoundHouses();
    }, [modalAgregar]);

    return (
        <>
            <Helmet>
                {/* <meta charSet="utf-8" /> */}
                <title>Ofertas - Blue Corner</title>{" "}
                <meta name="description" content="Ofertas - Blue Corner" />
                <link
                    rel="canonical"
                    href="https://example.com/dresses/green-dresses"
                />
            </Helmet>
            <NavBar />
            <div>
                <div className="md:px-6 xl:px-32 2xl:px-64 pt-0.5 ">
                    {/* <div
                        className={`${
                            authUser.tipo_usuario !== null &&
                            authUser.tipo_usuario <= 2
                                ? "pt-36"
                                : "pt-32"
                        } bg-[${
                            colores.secundario
                        }] flex w-full relative bg-no-repeat bg-cover h-48 md:h-[30rem] mt-24 justify-end overflow-hidden`}
                        style={{
                            backgroundImage: `url(${imgBlueSale})`,
                        }}>
                        <span className="absolute -bottom-10 -left-8 bg-[#053465] text-white font-medium text-2xl rounded-full p-6 pb-10 pt-3 pr-5 text-center pl-10">
                            Mira estas <br />
                            imperdibles <br />
                            ofertas
                        </span>
                    </div> */}
                </div>

                {/* <FirstHouses
                    firstHouses={houses}
                    indexImagen={indexImagen}
                    setIndexImagen={setIndexImagen}
                /> */}

                <div className="md:px-6 xl:px-32 2xl:px-64 pt-0.5 grid xs:grid-cols-2 gap-2 mt-40 px-2">
                    <img
                        onClick={() => {
                            window.open(
                                `/alojamientos/38858/casa-alicia`,
                                "_blank"
                            );
                        }}
                        alt="Imagen no encontrada"
                        src={imgCasa}
                        // className="object-cover w-full sm:h-[600px] h-[270px]  rounded-xl"
                        className="w-full mx-auto cursor-pointer rounded-xl "
                    />
                    <img
                        onClick={() => {
                            window.open(
                                `/embarcaciones/18693/velero-clasico`,
                                "_blank"
                            );
                        }}
                        alt="Imagen no encontrada"
                        src={imgEmbarcacion}
                        // className="object-cover w-full sm:h-[600px] h-[270px]  rounded-xl"
                        className="w-full mx-auto cursor-pointer rounded-xl"
                    />
                </div>

                {(authUser.tipo_usuario === 1 ||
                    authUser.tipo_usuario === 2) && (
                    <div className="flex justify-end px-8 mt-3 md:px-6 xl:px-32 2xl:px-64">
                        <p>
                            <span className="text-[#053465] font-medium mr-2">
                                Vista administrador
                            </span>
                        </p>
                        <div>
                            <ToggleButton
                                isToggled={false}
                                onChangeToggle={(e) => setToggleButton(e)}
                            />
                        </div>
                    </div>
                )}
                <div className="flex flex-col justify-center ">
                    <div className="relative z-10 mx-8 my-3 md:mx-6 xl:mx-32 2xl:mx-64">
                        {(authUser.tipo_usuario === 1 ||
                            authUser.tipo_usuario === 2) && (
                            <ModalAdmin
                                toggleButton={toggleButton}
                                editar={editar}
                                setEditar={setEditar}
                                modalAgregar={modalAgregar}
                                setModalAgregar={setModalAgregar}
                            />
                        )}
                    </div>
                    <SecondHouses
                        secondHouses={houses}
                        indexImagen={indexImagen}
                        setIndexImagen={setIndexImagen}
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BlueSale;
