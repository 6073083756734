import React from "react";
import { useSelector } from "react-redux";
import BackgroundImage from "../../img/corporativo/corporativo1.jpg";
import EQUIPO from "../../img/corporativo/equipo.png";
import CasasPlaya from "../../img/Comprobar_disponibilidad.png";
import CasasCampo from "../../img/bluecorner_agradecimiento.png";
import Embarcaciones from "../../img/Embarcaciones.png";
import Footer from "../Footer";
import NavBar from "../NavBar";
import LIMA2019 from "../../img/corporativo/cliente7.png";
import UBER from "../../img/corporativo/cliente2.png";
import HP from "../../img/corporativo/cliente4.png";
import HUAWEI from "../../img/corporativo/cliente5.png";
import CARGILL from "../../img/corporativo/cliente1.png";
import COSAS from "../../img/corporativo/cliente6.png";
import useScrollTo from "../../hooks/useScrollToTop";

const MembresiasHome = () => {
    const authUser = useSelector((state) => state.authReducer);
    useScrollTo();

    const ButtonAfiliate = () => (
        <button className="p-2 text-white rounded-md cursor-pointer bg-blue-bluecorner w-44">
            Contáctanos
        </button>
    );

    return (
        <div>
            <NavBar />
            <div
                className={`${
                    authUser.tipo_usuario !== null && authUser.tipo_usuario <= 2
                        ? "mt-[8rem]"
                        : "mt-[6rem]"
                }`}>
                <div
                    style={{
                        backgroundImage: `url(${BackgroundImage})`,
                        filter: "brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
                    }}
                    className={`w-full bg-[#053465] overflow-hidden h-48 md:h-96 bg-center bg-no-repeat text-center bg-cover flex justify-center relative`}>
                    <div className="absolute -bottom-9 text-white font-bold w-9/12 rounded-full pb-10 pt-1 text-xl bg-[#053465]">
                        Ofrece descuentos <br />
                        exclusivos a tus empleados
                    </div>
                </div>
            </div>
            <div className="flex flex-col text-center">
                <p className="my-4 text-2xl font-bold">
                    Membresías corporativas
                </p>
                <a
                    href={`https://web.whatsapp.com/send?text=Hola BlueCorner, vengo de la página de membresias, quiero saber más y solicitar una demo, gracias!&phone=51992683317`}
                    target="_blank">
                    <ButtonAfiliate />
                </a>
                <div className="flex flex-col w-11/12 gap-3 mx-auto mt-4 md:flex-row md:items-center md:gap-1 md:justify-center">
                    <div className="flex flex-col text-center font-semibold text-[#053465] md:text-left">
                        <span className="text-6xl md:mr-5">10%</span>
                        <span className="text-sm">de descuento en</span>
                    </div>
                    <div className="flex flex-col items-center gap-3 md:flex-row ">
                        <div className="w-10/12 max-w-sm">
                            <img
                                className="object-cover w-full h-56 rounded-t-md"
                                src={CasasCampo}
                                alt="Casas de campo"
                            />
                            <span className="text-white bg-[#053465] p-2 font-medium flex items-center text-sm rounded-b-md">
                                Casas de campo
                            </span>
                        </div>
                        <div className="w-10/12 max-w-sm">
                            <img
                                className="object-cover w-full h-56 rounded-t-md"
                                src={CasasPlaya}
                                alt="Casas de playa"
                            />
                            <span className="text-white bg-[#053465] p-2 font-medium flex items-center text-sm rounded-b-md">
                                Casas de playa
                            </span>
                        </div>
                        <div className="w-10/12 max-w-sm md:mt-8">
                            <img
                                className="object-cover w-full h-56 rounded-t-md"
                                src={Embarcaciones}
                                alt="Embarcaciones"
                            />
                            <span className="text-white bg-[#053465] p-2 font-medium flex items-center text-sm rounded-b-md">
                                Embarcaciones
                            </span>
                            <span className="text-right flex mt-2 font-medium text-[#053465]">
                                *Solo destinos nacionales
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col py-6 text-white bg-blue-bluecorner md:flex-row md:justify-center md:items-center xl:gap-20">
                <div className="flex items-center justify-center p-6 mx-6 my-10 text-2xl font-semibold tracking-wide rounded-md b md:h-96 md:w-96 md:text-5xl">
                    <p>¿Por qué deberías afiliar tu empresa?</p>
                </div>
                <div className="mx-6 gap-3 flex flex-col md:text-2xl max-w-[510px]">
                    <p>
                        Genera engagement empleado-empleador para aumentar la
                        retención dentro de tu empresa.
                    </p>
                    <p>
                        Incrementa la productividad y mejora el clima laboral
                        con colaboradores felices.
                    </p>
                </div>
            </div>
            <div className="flex flex-col grid-cols-2 gap-3 md:grid">
                <div className="flex flex-col w-full p-6 md:m-0 md:p-10 md:gap-32">
                    <div className="flex flex-col gap-3 md:text-xl">
                        <h2 className="text-[#053465] font-semibold text-2xl">
                            Nuestros clientes
                        </h2>
                        <p>
                            Empezamos en el 2019 siendo el operador oficial de
                            los Juegos Panamericanos Lima 2019 con más de 40
                            embarcaciones en 4 sedes a nivel nacional.
                        </p>
                        <p>
                            Desde entonces sumamos más de 20 clientes
                            corporativos que participaron de actividades de
                            team-building y coaching a bordo de una embarcación.
                        </p>
                        <p>
                            Ya son más de 4000 personas que han disfrutado de
                            una experiencia con Blue Corner.
                        </p>
                    </div>
                    <div className="flex flex-wrap items-center gap-4 my-3 justify-evenly">
                        <img
                            className="object-contain w-20 grayscale lg:w-28 xl:w-32"
                            src={LIMA2019}
                            alt="Lima 2019"
                        />
                        <img
                            className="object-cover w-20 grayscale lg:w-28 xl:w-32"
                            src={UBER}
                            alt="Uber"
                        />
                        <img
                            className="object-cover w-20 grayscale lg:w-28 xl:w-32"
                            src={HP}
                            alt="HP"
                        />
                        <img
                            className="object-cover w-20 grayscale lg:w-28 xl:w-32"
                            src={CARGILL}
                            alt="Cargill"
                        />
                        <img
                            className="object-cover w-20 grayscale lg:w-28 xl:w-32"
                            src={HUAWEI}
                            alt="HUAWEI"
                        />
                        <img
                            className="object-cover w-20 grayscale lg:w-28 xl:w-32"
                            src={COSAS}
                            alt="COSAS"
                        />
                    </div>
                </div>
                <img
                    className="object-cover w-full h-full"
                    src={EQUIPO}
                    alt="Equipo"
                />
            </div>
            <div className="flex flex-col items-center justify-center p-6">
                <h2 className="text-[#053465] font-semibold text-xl md:text-3xl">
                    Elije la membresía que se adapte mejor a tu empresa
                </h2>
                <div className="flex flex-col gap-4 m-4 md:flex-row">
                    <div className="flex flex-col items-center justify-center gap-2 p-8 bg-white rounded-md">
                        <h3 className="text-[#053465] font-bold text-xl">
                            Pago trimestral
                        </h3>
                        <span>4 pagos de</span>
                        <span className="text-2xl font-bold tracking-wider text-blue-bluecorner">
                            USD 460.20
                        </span>
                        <span className="text-sm">Incluye IGV</span>
                        <a
                            className=""
                            href={`https://web.whatsapp.com/send?text=Hola BlueCorner, vengo de la página de membresias, quiero saber más y solicitar una demo, gracias!&phone=51992683317`}
                            target="_blank">
                            <div className="flex mt-5">
                                <ButtonAfiliate />
                            </div>
                        </a>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 p-8 bg-white rounded-md">
                        <h3 className="text-[#053465] font-bold text-xl">
                            Pago semestral
                        </h3>
                        <span>2 pagos de</span>
                        <span className="text-2xl font-bold tracking-wider text-blue-bluecorner">
                            USD 778.80
                        </span>
                        <span className="bg-[#FF8A00] text-white rounded-md p-1 px-3 text-sm">
                            Ahorra USD 283.20
                        </span>
                        <span className="text-sm">Incluye IGV</span>
                        <a
                            className=""
                            href={`https://web.whatsapp.com/send?text=Hola BlueCorner, vengo de la página de membresias, quiero saber más y solicitar una demo, gracias!&phone=51992683317`}
                            target="_blank">
                            <div className="flex mt-5">
                                <ButtonAfiliate />
                            </div>
                        </a>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 p-8 bg-white rounded-md">
                        <h3 className="text-[#053465] font-bold text-xl">
                            Pago anual
                        </h3>
                        <span>1 pago de</span>
                        <span className="text-2xl font-bold tracking-wider text-blue-bluecorner">
                            USD 1416.20
                        </span>
                        <span className="bg-[#FF8A00] text-white rounded-md p-1 px-3 text-sm">
                            Ahorra USD 424.80
                        </span>
                        <span className="text-sm">Incluye IGV</span>

                        <a
                            className=""
                            href={`https://web.whatsapp.com/send?text=Hola BlueCorner, vengo de la página de membresias, quiero saber más y solicitar una demo, gracias!&phone=51992683317`}
                            target="_blank">
                            <div className="flex mt-5">
                                <ButtonAfiliate />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center pb-6">
                <div className="flex flex-col items-center justify-center gap-2 rounded-md">
                    <div className="flex flex-col md:flex-row">
                        <div className="flex flex-col items-center justify-center gap-2 rounded-md">
                            {/* <h2 className="text-[#053465] font-semibold text-xl md:text-3xl">
                                Solicita una demo!
                            </h2> */}
                            <a
                                className=""
                                href={`https://web.whatsapp.com/send?text=Hola BlueCorner. deseo agendar para solicitar demo, gracias!&phone=51992683317`}
                                target="_blank">
                                <div className="flex mt-5">
                                    <button className="p-2 text-white rounded-md cursor-pointer bg-blue-bluecorner w-max h-15">
                                        Quiero solicitar una demo!
                                    </button>
                                </div>
                            </a>
                        </div>
                        {/* <div className="flex flex-col items-center justify-center gap-2 p-8 rounded-md">
                            <h2 className="text-[#053465] font-semibold text-xl md:text-3xl">
                                Prueba la plataforma
                            </h2>
                            <a
                                className=""
                                href={`https://www.bluecorner.com.pe/my-account-empresa`}
                                target="_blank">
                                <div className="flex mt-5">
                                    <button className="p-2 text-lg text-white rounded-md cursor-pointer bg-blue-bluecorner w-100 h-15">
                                        Ingresa a la plataforma por 30 dias de
                                        prueba
                                    </button>
                                </div>
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MembresiasHome;
