import {
  GET_ALL_PROPERTIE,
  CREATE_PROPERTIE,
  RESET_CREATE_PROPERTIE,
  ONE_PROPERTIE,
  SEARCH_LABELS,
  RESET_ONE_PROPERTIE,
  ENABLE_PROPERTIE,
  RESET_ENEBLE_PROPERTIE,
  CLEAR_PROPERTIES,
} from "../actions/propertiesActions";

const initialState = {
  properties: { total: 0, data: [] },
  create: false,
  onePropertie: [],
  enableproperties: [],
};

export const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PROPERTIE:
      return {
        ...state,
        properties: action.payload,
      };
    case CREATE_PROPERTIE:
      return {
        ...state,
        create: action.payload,
      };
    case RESET_CREATE_PROPERTIE:
      return {
        ...state,
        create: false,
      };
    case ONE_PROPERTIE:
      return {
        ...state,
        onePropertie: action.payload,
      };
    case SEARCH_LABELS:
      return {
        ...state,
        properties: action.payload,
      };
    case RESET_ONE_PROPERTIE:
      return {
        ...state,
        onePropertie: action.payload,
      };
    case ENABLE_PROPERTIE:
      return {
        ...state,
        enableproperties: action.payload,
      };
    case RESET_ENEBLE_PROPERTIE:
      return {
        ...state,
        enableproperties: [],
      };
    case CLEAR_PROPERTIES:
      return {
        ...state,
        properties: { total: 0, data: [] },
      };

    default:
      return state;
  }
};
