import axios from "axios";
import React, { useState } from "react";
import useHeaders from "../../../hooks/useHeaders";
import formatedDate_YYYY_MM_DD from "../../helpers/formatedDate_YYYY_MM_DD";
import ModalEditarActividadPlanificada from "./ModalEditarActividadPlanificada";

const ActividadesPlanificadas = ({
    actividadesPlanificadas,
    setRefreshActividades,
    empresa,
}) => {
    const [modalEditarActividad, setModalEditarActividad] = useState(false);
    const [comentarioTerminado, setComentarioTerminado] = useState("");
    const [actividadSelected, setActividadSelected] = useState(null);
    const [modalFinish, setModalFinish] = useState(false);
    const { headers } = useHeaders();

    console.log(actividadesPlanificadas);
    const handleFinish = async () => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/actividadesCrm/actualizarRealizado/${actividadSelected}`,
            { comentario_finalizado: comentarioTerminado },
            { headers }
        );

        if (data.message === "actuaizacion exitoso") {
            setComentarioTerminado("");
            setModalFinish(false);
            setRefreshActividades((prev) => !prev);
        }
    };

    const handleDelete = async (id_actividad) => {
        const { data } = await axios.delete(
            `${process.env.REACT_APP_PROXY}/actividadesCrm/eliminar/${id_actividad}`,
            { headers }
        );

        if (data.message === "Registro deshabilitado con exito") {
            setRefreshActividades((prev) => !prev);
        }
    };

    return actividadesPlanificadas.map((actividad) => (
        <div key={actividad.id} className="flex w-full p-2 mt-5 text-xs">
            {modalEditarActividad && actividadSelected === actividad.id && (
                <ModalEditarActividadPlanificada
                    modalVisible={modalEditarActividad}
                    setModalVisible={setModalEditarActividad}
                    setRefreshActividades={setRefreshActividades}
                    empresa={empresa}
                    actividad={actividad}
                />
            )}
            <div className="min-w-fit">
                <img
                    className="object-cover w-12 h-12 border rounded-full cursor-pointer xl:h-16 xl:w-16"
                    src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${actividad.avatar_creador}`}
                    alt="avatar de usuario"
                />
            </div>
            <div className="flex flex-col w-full mt-1 ml-4 xl:items-center">
                <div className="flex items-baseline w-full gap-2 xl:justify-center">
                    <span
                        className="font-bold min-w-fit"
                        style={{ color: actividad.color_actividad }}>
                        {actividad.dias_restantes === 0 && `Para hoy`}
                        {actividad.dias_restantes < 0 &&
                            `Hace ${actividad.dias_restantes * -1} días`}
                        {actividad.dias_restantes > 0 &&
                            `Para dentro de ${actividad.dias_restantes} días`}
                        :
                    </span>
                    <span className="font-bold">"{actividad.actividad}"</span>
                </div>
                <div className="mt-2 text-gray-500">
                    Actividad creada por: <b>{actividad.usuario_creador}</b>{" "}
                    Para <b>{actividad.administrativo_asignado}</b>
                </div>
                <div className="mt-2 pr-2">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: actividad.resumen,
                        }}></span>
                </div>
                {modalFinish && actividadSelected === actividad.id && (
                    <div className="w-full mt-2 border-2">
                        <span className="flex w-full p-1 px-3 bg-gray-200">
                            Marcar como hecho
                        </span>
                        <div className="p-2">
                            <textarea
                                type="text"
                                placeholder="Escribir comentarios"
                                value={comentarioTerminado}
                                onChange={(e) =>
                                    setComentarioTerminado(e.target.value)
                                }
                                className="w-full h-20 p-1 border outline-none"
                            />
                            <div className="flex gap-3 mt-2">
                                <button
                                    className="px-4 py-1 font-semibold text-white bg-blue-bluecorner"
                                    onClick={handleFinish}>
                                    TERMINAR
                                </button>
                                <button
                                    className="font-semibold text-blue-bluecorner"
                                    onClick={() => setModalFinish(false)}>
                                    Descartar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex items-center gap-2 mt-2 text-gray-500">
                    <span
                        className="flex items-center gap-1 font-bold cursor-pointer hover:text-black"
                        onClick={() => {
                            setComentarioTerminado("");
                            setActividadSelected(actividad.id);
                            setModalFinish(true);
                        }}>
                        <svg
                            className="w-3 h-3 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path d="M5 12l5 5l10 -10" />
                        </svg>{" "}
                        <span>Marcar como hecho</span>
                    </span>
                    <span
                        className="flex items-center gap-1 font-bold cursor-pointer hover:text-black"
                        onClick={() => {
                            setActividadSelected(actividad.id);
                            setModalEditarActividad(true);
                        }}>
                        <svg
                            className="w-3 h-3 text-black"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                        </svg>
                        <span>Editar</span>
                    </span>
                    <span
                        className="flex items-center gap-1 font-bold cursor-pointer hover:text-black"
                        onClick={() => handleDelete(actividad.id)}>
                        <svg
                            className="w-3 h-3 text-black"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <line x1="18" y1="6" x2="6" y2="18" />{" "}
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                        <span>Cancelar</span>
                    </span>
                </div>
            </div>
        </div>
    ));
};

export default ActividadesPlanificadas;
