import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "../../../Spinner";
import Swal from "sweetalert2";
import EjemploFinal from "./EjemploFinal";
import ModalAddServiciosAvanzados from "./ModalAddServiciosAvanzados";
import ModalEditServiciosAvanzados from "./ModalEditServiciosAvanzados";
import { useSelector } from "react-redux";

const ModalServiciosAvanzados = ({
    id,
    nombre,
    moneda,
    setServicioAvanzado,
    producto,
}) => {
    const [input, setInput] = useState({
        fecha_inicio: "",
        fecha_final: "",
        id: id,
    });
    const [fakeRefresh, setFakeRefresh] = useState(false);
    const [variaciones, setVariaciones] = useState([]);
    const [variacion, setVariacion] = useState(null);

    const [modalVisible, setModalVisible] = useState(false);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        getServiciosAvanzados();
    }, [modalVisible, fakeRefresh, variacion]);

    const getServiciosAvanzados = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/productoServiciosAdicionalesAvanzado/all/${id}`,
            { headers }
        );

        setVariaciones(data);
    };
    const handleDelete = (idServicio) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminar esta variacion?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { data } = await axios.delete(
                    `${process.env.REACT_APP_PROXY}/productoServiciosAdicionalesAvanzado/eliminar/${idServicio}`,
                    {
                        data: {
                            id_usuario: authUser.id_usuario,
                            nombre: nombre,
                            id_productos: producto.data.id,
                        },
                        headers: headers,
                    }
                );
                if (data.message === "Registro eliminado con exito") {
                    Swal.fire({
                        title: "Registro eliminado con exito",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                    setFakeRefresh(!fakeRefresh);
                }
            }
        });
    };
    const handleClose = () => {
        setServicioAvanzado(null);
    };

    return (
        <div className={`relative items-center justify-center `}>
            <div className="fixed z-10 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-20 sm:py-8 ">
                    <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg  xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] p-10 overflow-y-auto ">
                        <header className="mb-3 mr-auto text-base font-bold lg:text-xl">
                            Opciones avanzadas
                        </header>
                        <div className="flex w-full items-center justify-between">
                            <h2 className="w-full font-semibold pl-4">
                                {nombre}
                            </h2>
                            <button
                                className="flex items-center px-3 py-2  text-blue-bluecorner border border-blue-bluecorner rounded-lg group"
                                onClick={() => setModalVisible(!modalVisible)}>
                                <svg
                                    className="w-3 h-3 text-blue-bluecorner"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                <span className="items-center">Añadir</span>
                            </button>
                        </div>

                        {variaciones.map((servicio, i) => {
                            return (
                                <div className="w-full mt-5" key={i}>
                                    <div className="flex items-center justify-between">
                                        <span className="font-semibold px-4">
                                            Desde:{" "}
                                            {servicio.fecha_inicio
                                                .split("-")
                                                .reverse()
                                                .join("-")}{" "}
                                            al{" "}
                                            {servicio.fecha_final
                                                .split("-")
                                                .reverse()
                                                .join("-")}
                                            {""} ({servicio.precio} {moneda})
                                        </span>
                                        <div className="flex">
                                            <span
                                                className="mx-2 cursor-pointer border p-1 border-blue-bluecorner rounded"
                                                onClick={() =>
                                                    setVariacion(servicio)
                                                }>
                                                <svg
                                                    className="w-6 h-6 text-blue-bluecorner"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                    <line
                                                        x1="16"
                                                        y1="5"
                                                        x2="19"
                                                        y2="8"
                                                    />
                                                </svg>
                                            </span>

                                            <span
                                                className="cursor-pointer border p-1 border-blue-bluecorner rounded"
                                                onClick={() => {
                                                    handleDelete(servicio.id);
                                                }}>
                                                <svg
                                                    className="w-6 h-6 text-blue-bluecorner"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <line
                                                        x1="4"
                                                        y1="7"
                                                        x2="20"
                                                        y2="7"
                                                    />{" "}
                                                    <line
                                                        x1="10"
                                                        y1="11"
                                                        x2="10"
                                                        y2="17"
                                                    />{" "}
                                                    <line
                                                        x1="14"
                                                        y1="11"
                                                        x2="14"
                                                        y2="17"
                                                    />{" "}
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="mt-1" />
                                </div>
                            );
                        })}

                        {modalVisible && (
                            <ModalAddServiciosAvanzados
                                create={modalVisible}
                                setCreate={setModalVisible}
                                id={id}
                                moneda={moneda}
                                producto={producto}
                                nombre={nombre}
                            />
                        )}
                        {variacion !== null && (
                            <ModalEditServiciosAvanzados
                                moneda={moneda}
                                variacion={variacion}
                                setCreate={setVariacion}
                                producto={producto}
                                nombre={nombre}
                            />
                        )}
                        <div className="mt-16">
                            <button
                                onClick={() => handleClose()}
                                className="absolute text-gray-800 dark:text-gray-400 top-1 right-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                                aria-label="close">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18 6L6 18"
                                        stroke="currentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M6 6L18 18"
                                        stroke="currentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalServiciosAvanzados;
