import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

const token = localStorage.getItem("token");
const headers = {
    authorization: token,
};

const ModalEditManual = ({
    modalEditVisible,
    setModalEditVisible,
    getAllManuals,
    manualSelected,
    setManualSelected,
}) => {
    const [cargando, SetCargando] = useState(false);

    const [input, setInput] = useState({
        name: manualSelected.nombre,
        description: manualSelected.descripcion,
        types: manualSelected.tipos_manual,
        file: manualSelected.pdf,
        pdf: `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/manual/getArchivo/${manualSelected.pdf}`,
    });
    const [selectNewPDF, setSelectNewPDF] = useState(false);
    const [allTypes, setAllTypes] = useState([]);
    const [typesSelected, setTypesSelected] = useState(
        manualSelected.tipos_manual
    );
    const form = new FormData();

    const getAllTypes = async (SioNo) => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/tipoManual/all/${SioNo}`,
            { headers }
        );
        if (data) {
            setAllTypes(data);
        }
    };

    useEffect(() => {
        getAllTypes("Si");
    }, []);

    const handleInputChange = (e) => {
        if (e.target.name === "types") {
            const { id, nombre } = JSON.parse(e.target.value);
            const type = {
                id_tipo_manual: id,
                tipo_manual: nombre,
            };
            setInput({
                ...input,
                [e.target.name]: [...input.types, type],
            });
            if (
                !typesSelected.some(
                    (types) => types.id_tipo_manual === type.id_tipo_manual
                )
            )
                setTypesSelected([...typesSelected, type]);
        } else if (e.target.name === "file") {
            setInput({
                ...input,
                [e.target.name]: e.target.files[0],
            });
            setSelectNewPDF(true);
        } else {
            setInput({
                ...input,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        if (!input.name) {
            Swal.fire({
                title: "Error!",
                text: "El tipo de manual debe tener un nombre",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else if (!input.types.length) {
            Swal.fire({
                title: "Error!",
                text: "Debe seleccionar al menos un tipo de manual",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else if (!input.file) {
            Swal.fire({
                title: "Error!",
                text: "Debe seleccionar un archivo",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else {
            SetCargando(true);

            form.append("nombre", input.name);
            form.append("descripcion", input.description);
            form.append("pdf-manuales", input.file);
            console.log(input.types);
            input.types.forEach((type) =>
                form.append("tipos_manuales[]", type.id_tipo_manual)
            );
            await axios
                .put(
                    `${process.env.REACT_APP_PROXY}/manual/actualizar/${manualSelected.id}`,
                    form,
                    { headers }
                )
                .then(() => {
                    Swal.fire({
                        title: "Tipo de manual actualizado correctamente!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                })
                .then(() => {
                    getAllManuals("Si");
                    setInput({
                        name: "",
                    });
                    setModalEditVisible(!modalEditVisible);
                    setManualSelected({});
                });
        }
    };

    const handleClose = () => {
        setManualSelected({});
        setModalEditVisible(!modalEditVisible);
    };
    console.log(input);
    return (
        <div
            className={`relative items-center justify-center ${
                modalEditVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed bottom-0 left-0 w-full z-20 h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-24 2xl:container 2xl:mx-auto  ">
                    <div className="relative w-full block justify-center px-4 py-12 bg-white rounded-lg  md:w-96 dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto md:overflow-y-hidden ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-2 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mt-10 px-2 text-left text-base font-bold lg:text-2xl">
                            Editar manual
                        </header>
                        <div className="w-full px-3 rounded-lg  mt-7">
                            <label className="flex ">Nombre del manual</label>
                            <input
                                type="text"
                                value={input.name}
                                name="name"
                                onChange={handleInputChange}
                                placeholder="Ejm: Manual de Gestión de servicios"
                                className="w-full border my-1 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full px-3 rounded-lg  mt-2">
                            <label className="flex ">
                                Descripción del manual
                            </label>
                            <textarea
                                type="text"
                                value={input.description}
                                name="description"
                                onChange={handleInputChange}
                                placeholder="Descripcion del manual"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 h-32 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full px-3 rounded-lg">
                            <label className="flex ">Categoría</label>
                            <select
                                id="types"
                                defaultValue={"Seleccione un tipo"}
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                name="types"
                                onChange={handleInputChange}>
                                <option disabled value={"Seleccione un tipo"}>
                                    Seleccione un tipo
                                </option>
                                {allTypes?.map((type) => (
                                    <option
                                        value={JSON.stringify(type)}
                                        key={type.id}
                                        className="p-2 outline-none">
                                        {type.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* <div className="w-full px-2 mt-5 rounded-2xl bg-gray-50 ring-2 ring-gray-200">
                            <select
                                className="w-full p-4 rounded-lg outline-none"
                                name="types"
                                onChange={handleInputChange}>
                                {allTypes.map((type) => (
                                    <option
                                        value={JSON.stringify(type)}
                                        key={type.id}
                                        className="p-2 outline-none">
                                        {type.nombre}
                                    </option>
                                ))}
                            </select>
                        </div> */}
                        <div className="mt-4">
                            {typesSelected.length > 0 &&
                                typesSelected?.map((type) => {
                                    return (
                                        <div
                                            className="grid items-center grid-cols-[90%_10%] gap-4  mt-1 "
                                            key={type.id_tipo_manual}>
                                            <span className="mx-2 ">
                                                {type.tipo_manual}
                                            </span>
                                            <button
                                                className="flex items-center justify-center w-5 h-5 border-2 rounded-full hover:bg-red-600 hover:text-white"
                                                onClick={() => {
                                                    setTypesSelected(
                                                        typesSelected?.filter(
                                                            (types) =>
                                                                types.id_tipo_manual !==
                                                                type.id_tipo_manual
                                                        )
                                                    );
                                                    setInput({
                                                        ...input,
                                                        types: input.types.filter(
                                                            (types) =>
                                                                types.id_tipo_manual !==
                                                                type.id_tipo_manual
                                                        ),
                                                    });
                                                }}>
                                                <span className="-mt-1 hover:text-white">
                                                    x
                                                </span>
                                            </button>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className=" mt-2">
                            {/* {!selectNewPDF && (
                                <div>
                                    <h1>Previsualizar archivo actual: </h1>
                                    <a
                                        href={`${input.pdf}`}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <h2 className="text-blue-600 ">
                                            {manualSelected.pdf.split("_")[1]}
                                        </h2>
                                    </a>
                                </div>
                            )} */}
                            <hr className="mt-3" />
                            <div className="w-full flex justify-between items-start">
                                <div>
                                    <h1 className="mt-4">Archivo</h1>
                                    <a
                                        href={`${input.pdf}`}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <h2 className="text-gray-500 mt-2">
                                            {manualSelected.pdf.split("_")[1]}
                                        </h2>
                                    </a>
                                </div>
                                <div className="cursor-pointer hover:scale-105 mt-4">
                                    <h1 className="relative text-blue-bluecorner cursor-pointer ">
                                        Editar
                                        <input
                                            type="file"
                                            className="opacity-0 file:cursor-pointer absolute left-0 top-0 w-9 h-4 bg-red-100"
                                            name="file"
                                            onChange={handleInputChange}
                                        />
                                    </h1>
                                </div>
                            </div>
                            <hr className="mt-4" />
                        </div>
                        <button
                            disabled={cargando}
                            className="w-full rounded-lg border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white  active:translate-y-[0.125rem]  mt-7"
                            onClick={handleCreate}>
                            {cargando ? "Guardando..." : "Guardar"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditManual;
