import React, { useState } from "react";
import ButtonCopyToClipboard from "../../ButtonCopyToClipboard";
import ModalMessageTopRight from "../../ModalMessageTopRight";

const ModalPromoCreada = ({
    setModalVisible,
    setModalPadre,
    cupon,
    cuponBlue = false,
}) => {
    const [modalTopRight, setModalTopRight] = useState(false);

    const handleClose = () => {
        setModalVisible((prev) => !prev);
        setModalPadre((prev) => !prev);
    };

    return (
        <div className="fixed flex justify-center z-40 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
            <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 w-full ">
                <div className="relative w-full max-w-sm flex flex-col items-center justify-center p-4 bg-white rounded-lg md:w-96 dark:bg-white h-[calc(100vh-60vh)] overflow-y-auto">
                    {modalTopRight && (
                        <ModalMessageTopRight
                            message="Cupón copiado al portapapeles correctamente"
                            success={true}
                        />
                    )}
                    <button
                        onClick={handleClose}
                        className="absolute text-gray-800 dark:text-gray-400 top-2 right-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                        <svg
                            className="rounded-full hover:bg-gray-600 hover:text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <header className="font-bold text-xl text-center">
                        {cuponBlue ? "¡Cupon creado!" : "¡Promoción creada!"}
                    </header>
                    <span className="text-center font-medium mt-3">
                        {cuponBlue
                            ? "Comparte el siguiente cupon con tu cliente"
                            : "Comparte el siguiente cupon con tus trabajadores"}
                    </span>
                    <div className="border p-2 text-gray-600 rounded-lg w-full mt-4 flex justify-between items-center">
                        <span>{cupon}</span>

                        <ButtonCopyToClipboard
                            confirmationText="Cupón copiado al portapapeles correctamente"
                            textToCopy={cupon}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalPromoCreada;
