import axios from "axios";
import React, { useState, useEffect, useReducer, useCallback } from "react";
import Swal from "sweetalert2";
import Spinner from "../Spinner";

const FiltrosEmbarcaciones = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [selectedDuraciones, setSelectedDuraciones] = useState([]);
    const [selectedTiposEmbarcaciones, setSelectedTiposEmbarcaciones] =
        useState([]);
    const filtersReducer = useCallback(
        (state, action) => {
            switch (action.type) {
                //TIPOS EMBARCACIONES////////////////////////////////////////////////////////
                case "updateTypes":
                    if (
                        state.tipos_embarcacion.some(
                            (type) => type === action.payload
                        )
                    )
                        return state;
                    return {
                        ...state,
                        tipos_embarcacion: [
                            ...state.tipos_embarcacion,
                            action.payload,
                        ],
                    };

                case "deleteTypes":
                    setSelectedTiposEmbarcaciones(
                        selectedTiposEmbarcaciones.filter(
                            (type) => type.id !== action.payload
                        )
                    );
                    return {
                        ...state,
                        tipos_embarcacion: state.tipos_embarcacion.filter(
                            (type) => type !== action.payload
                        ),
                    };

                //DURACIONES////////////////////////////////////////////////
                case "updateDurations":
                    if (
                        state.duracion.some(
                            (duration) => duration.id === action.payload
                        )
                    )
                        return state;
                    return {
                        ...state,
                        duracion: [...state.duracion, action.payload],
                    };

                case "deleteDurations":
                    setSelectedDuraciones(
                        selectedDuraciones.filter(
                            (duration) => duration.id !== action.payload
                        )
                    );
                    return {
                        ...state,
                        duracion: state.duracion.filter(
                            (duration) => duration !== action.payload
                        ),
                    };

                case "changeChecking":
                    return {
                        ...state,
                        checking: false,
                    };

                default:
                    return state;
            }
        },
        [selectedDuraciones, selectedTiposEmbarcaciones]
    );

    const [allFilters, setAllFilters] = useState({
        tipos_embarcacion: [],
        duracion: [],
    });
    const [siguiente, setSiguiente] = useState("Guardar");
    const [selectedFilters, dispatch] = useReducer(filtersReducer, {
        checking: true,
        tipos_embarcacion: [],
        duracion: [],
    });

    useEffect(() => {
        fillFilters();
        fillFields();
    }, []);

    const fillFilters = async () => {
        const tiposEmbarcacion = await axios(
            `${process.env.REACT_APP_PROXY}/propiedades/all/0/Si/Tipo Embarcacion`,
            { headers }
        );
        const duraciones = await axios(
            `${process.env.REACT_APP_PROXY}/propiedades/all/0/Si/Duracion`,
            { headers }
        );
        setAllFilters({
            ...allFilters,
            tipos_embarcacion: tiposEmbarcacion.data.data,
            duracion: duraciones.data.data,
        });
    };

    const fillFields = useCallback(async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/filtros/all/Embarcacion`
        );
        data[0].tipo_embarcacion.forEach((type) => {
            dispatch({
                type: "updateTypes",
                payload: type.id,
            });
            setSelectedTiposEmbarcaciones((prev) => [...prev, type]);
        });
        data[1].duracion.forEach((duration) => {
            dispatch({
                type: "updateDurations",
                payload: duration.id,
            });
            setSelectedDuraciones((prev) => [...prev, duration]);
        });
        dispatch({
            type: "changeChecking",
        });
    }, []);

    const registerFilters = async () => {
        setSiguiente(null);
        const filtros = {
            tipo_embarcacion: selectedFilters.tipos_embarcacion,
            duracion: selectedFilters.duracion,
        };
        console.log(filtros);
        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/filtros/`,
            {
                filtros,
                filtro_producto: "Embarcacion",
            },
            { headers }
        );
        Swal.fire({
            title: "Filtros actualizados correctamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
        });
        setSiguiente("Confirmar cambios");
    };

    return (
        <div className="flex flex-col w-full mx-auto">
            <div className="mx-auto">
                <hr className="mx-auto my-5 md:hidden" />
                <h2 className="mt-5 text-lg font-bold">
                    Tipos de embarcaciones
                </h2>

                {selectedFilters.checking ||
                allFilters.tipos_embarcacion.length === 0 ? (
                    <div className="mt-5">
                        <Spinner width={7} />
                    </div>
                ) : (
                    <div>
                        <select
                            defaultValue="Selecciona una opcion"
                            className="w-64 p-1 mt-4 border-2 rounded-lg"
                            onChange={(e) => {
                                if (
                                    selectedTiposEmbarcaciones.some(
                                        (type) =>
                                            type.id === Number(e.target.value)
                                    )
                                )
                                    return;
                                const newType =
                                    allFilters.tipos_embarcacion.filter(
                                        (type) =>
                                            type.id === Number(e.target.value)
                                    );
                                setSelectedTiposEmbarcaciones((prev) => [
                                    ...prev,
                                    ...newType,
                                ]);
                                dispatch({
                                    type: "updateTypes",
                                    payload: Number(e.target.value),
                                });
                            }}>
                            <option disabled>Selecciona una opcion</option>
                            {allFilters.tipos_embarcacion?.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.nombre}
                                </option>
                            ))}
                        </select>
                        {selectedTiposEmbarcaciones.length > 0 ? (
                            <div className="p-1">
                                <ul className="w-64 mt-5">
                                    {selectedTiposEmbarcaciones?.map((type) => (
                                        <li
                                            key={type.id}
                                            className="flex justify-between mt-3">
                                            <span className="font-medium">
                                                {type.nombre}
                                            </span>
                                            <button
                                                className="flex items-center justify-center w-8 h-8 font-semibold text-white border-2 border-blue-bluecorner rounded-lg"
                                                onClick={() => {
                                                    dispatch({
                                                        type: "deleteTypes",
                                                        payload: type.id,
                                                    });
                                                }}>
                                                <span className="flex text-blue-bluecorner">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M18 6L6 18"
                                                            stroke="currentColor"
                                                            strokeWidth="1.66667"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M6 6L18 18"
                                                            stroke="currentColor"
                                                            strokeWidth="1.66667"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <h1 className="mt-3 font-medium text-center">
                                Aun no tienes filtros asignados
                            </h1>
                        )}
                    </div>
                )}
            </div>
            <div className="mx-auto">
                <hr className="mx-auto my-5 " />
                <h2 className="mt-5 text-lg font-bold">Duraciones</h2>

                {selectedFilters.checking ||
                allFilters.tipos_embarcacion.length === 0 ? (
                    <div className="mt-5">
                        <Spinner width={7} />
                    </div>
                ) : (
                    <div>
                        <select
                            defaultValue="Selecciona una opcion"
                            className="w-64 p-1 mt-4 border-2 rounded-lg"
                            onChange={(e) => {
                                if (
                                    selectedDuraciones.some(
                                        (duration) =>
                                            duration.id ===
                                            Number(e.target.value)
                                    )
                                )
                                    return;
                                const newDuration = allFilters.duracion.filter(
                                    (duration) =>
                                        duration.id === Number(e.target.value)
                                );
                                setSelectedDuraciones((prev) => [
                                    ...prev,
                                    ...newDuration,
                                ]);
                                dispatch({
                                    type: "updateDurations",
                                    payload: Number(e.target.value),
                                });
                            }}>
                            <option disabled>Selecciona una opcion</option>
                            {allFilters.duracion?.map((duration) => (
                                <option key={duration.id} value={duration.id}>
                                    {duration.nombre}
                                </option>
                            ))}
                        </select>
                        {selectedDuraciones.length > 0 ? (
                            <div className="p-1">
                                <ul className="w-64 mt-5">
                                    {selectedDuraciones?.map((duration) => (
                                        <li
                                            key={duration.id}
                                            className="flex justify-between mt-3">
                                            <span className="font-medium">
                                                {duration.nombre}
                                            </span>
                                            <button
                                                className="flex items-center justify-center w-8 h-8 font-semibold text-white border-2 border-blue-bluecorner rounded-lg"
                                                onClick={() => {
                                                    dispatch({
                                                        type: "deleteDurations",
                                                        payload: duration.id,
                                                    });
                                                }}>
                                                <span className="flex text-blue-bluecorner">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M18 6L6 18"
                                                            stroke="currentColor"
                                                            strokeWidth="1.66667"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M6 6L18 18"
                                                            stroke="currentColor"
                                                            strokeWidth="1.66667"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <h1 className="mt-3 font-medium text-center">
                                Aun no tienes filtros asignados
                            </h1>
                        )}
                    </div>
                )}
            </div>

            <button
                onClick={() => registerFilters()}
                className="w-64 p-4 mx-auto mt-10 mb-10 text-white transition ease-in bg-blue-500 rounded-lg hover:scale-95">
                {siguiente ? siguiente : <Spinner width={7} />}
            </button>
        </div>
    );
};

export default FiltrosEmbarcaciones;
