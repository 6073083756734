import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

const SearchBar = ({
    setfakeRefresh,
    fakeRefresh,
    habilitados,
    getAllPedidos,
    showAlojamientos,
    estadoPedido,
}) => {
    const [input, setinput] = useState("");
    const dispatch = useDispatch();
    const debounceInput = useRef(null);

    const handleInputChange = (e) => {
        setinput(e.target.value);

        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            getAllPedidos(
                showAlojamientos,
                habilitados,
                1,

                "",
                estadoPedido
            );

            return;
        }
        debounceInput.current = setTimeout(() => {
            getAllPedidos(
                showAlojamientos,
                habilitados,
                1,
                e.target.value,
                estadoPedido
            );

            setfakeRefresh(!fakeRefresh);
        }, 1000);
    };

    return (
        <div className="flex p-1 border-2 rounded appearance-none w-full md:w-96 left-2 text-stone-900">
            <button className="flex items-center justify-center px-2">
                <svg
                    className="w-6 h-6 text-blue-bluecorner"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                </svg>
            </button>{" "}
            <input
                onBlur={() => setinput("")}
                value={input}
                type="text"
                onChange={handleInputChange}
                className="w-full py-2 bg-transparent outline-none appearance-none"
                placeholder="Buscar"
            />
        </div>
    );
};

export default SearchBar;
