import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const token = localStorage.getItem("token");
const headers = {
    authorization: token,
};

const RegisterPage = ({ tipo, idUsuario }) => {
    const [errors, setErrors] = useState({});
    const [input, setInput] = useState({
        userType: 0,
        userName: "",
        userCelular: "",
        id_propietario: idUsuario,
        userDni: "",
        userDireccion: "",
        mother_lastName: "",
        father_lastName: "",
        userEmail: "",
        userPassword: "",
        userPassword2: "",
    });
    const [showPass, setShowPass] = useState(false);

    const validateInput = (input) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        const errores = {};
        if (tipo === "subAdmin") {
            if (!input.userName) {
                errores.userName = "El nombre es requerido";
            }
            if (!input.mother_lastName) {
                errores.mother_lastName = "El apellido materno es requerido";
            }
            if (!input.father_lastName) {
                errores.father_lastName = "El apellido paterno es requerido";
            }
            if (!input.userDireccion) {
                errores.userDireccion = "La dirección es requerida";
            }
            if (!input.userCelular) {
                errores.userCelular = "El celular es requerido";
            }
            if (!input.userDni) {
                errores.userDni = "El dni es requerido";
            }
            if (!input.userEmail) {
                errores.userEmail = "El correo es requerido";
            } else if (!regExpEmail.test(input.userEmail)) {
                errores.userEmail = "El correo no es valido";
            }
            if (!input.userPassword) {
                errores.userPassword = "La contraseña es requerida";
            }
            if (!input.userPassword2) {
                errores.userPassword2 = "La contraseña es requerida";
            } else if (input.userPassword !== input.userPassword2) {
                errores.userPassword2 = "Las contraseñas no coinciden";
            }
        } else {
            if (input.userType === 0) {
                errores.userType = "Debe seleccionar un tipo de usuario";
            } else if (input.userName === "") {
                errores.userName = "Debe ingresar un nombre";
            } else if (!input.father_lastName) {
                errores.father_lastName = "Debe ingresar apellido paterno";
            } else if (!regExpEmail.test(input.userEmail)) {
                errores.userEmail =
                    "Debe ingresar un dirección de email válida";
            } else if (!input.userPassword) {
                errores.userPassword = "Debe ingresar una contraseña";
            } else if (
                !input.userPassword2 ||
                input.userPassword !== input.userPassword2
            ) {
                errores.userPassword2 = "Las contraseñas deben coincidir";
            }
        }
        return errores;
    };

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleRegister = (e) => {
        e.preventDefault();
        const errors = validateInput(input);
        setErrors(errors);

        if (tipo === "subAdmin") {
            if (Object.entries(errors).length === 0) {
                console.log(idUsuario);
                const usuarioNuevo = {
                    nombre: input.userName,
                    apellido_paterno: input.father_lastName,
                    apellido_materno: input.mother_lastName,
                    correo: input.userEmail,
                    password: input.userPassword,
                    tipo_usuario: 4,
                    dni: input.userDni,
                    direccion: input.userDireccion,
                    celular: input.userCelular,
                    id_propietario: input.id_propietario,
                };
                axios
                    .post(
                        `${process.env.REACT_APP_PROXY}/subpropietario/`,
                        usuarioNuevo,
                        {
                            headers,
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        if (response.data.message === "registro exitoso") {
                            Swal.fire({
                                title: "Registro realizado correctamente!",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            });
                            setInput({
                                userName: "",
                                userCelular: "",
                                userDni: "",
                                userDireccion: "",
                                mother_lastName: "",
                                father_lastName: "",
                                userEmail: "",
                                userPassword: "",
                                userPassword2: "",
                                tipo_usuario: "",

                                id_propietario: "",
                            });
                        } else {
                            Swal.fire({
                                title: "Se encontró un error en el registro",
                                text: response.data.title,
                                icon: "error",
                                confirmButtonText: "Aceptar",
                            });
                        }
                    });
            }
        } else {
            if (Object.entries(errors).length === 0) {
                const usuarioNuevo = {
                    nombre: input.userName,
                    apellido_paterno: input.father_lastName,
                    apellido_materno: input.mother_lastName,
                    correo: input.userEmail,
                    password: input.userPassword,
                    tipo_usuario: input.userType,
                };
                axios
                    .post(
                        `${process.env.REACT_APP_PROXY}/autenticacion`,
                        usuarioNuevo,
                        {
                            headers,
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        if (response.data.message === "registro exitoso") {
                            Swal.fire({
                                title: "Registro realizado correctamente!",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            });
                            setInput({
                                userType: 0,
                                userName: "",
                                mother_lastName: "",
                                father_lastName: "",
                                userEmail: "",
                                userPassword: "",
                                userPassword2: "",
                            });
                        } else {
                            Swal.fire({
                                title: "Se encontró un error en el registro",
                                text: response.data.title,
                                icon: "error",
                                confirmButtonText: "Aceptar",
                            });
                        }
                    });
            }
        }
    };

    console.log(input);

    return (
        <div>
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col max-w-md px-4 py-8 bg-white shadow-md sm:px-6 md:px-8 lg:px-14 rounded-3xl w-50">
                    {tipo === "subAdmin" ? (
                        <div className="self-center text-center text-2xl font-medium text-gray-800 sm:text-2xl">
                            Registra un nuevo{" "}
                            <span className="text-blue-500">A</span>
                            dministrador de tu{" "}
                            <span className="text-blue-500">C</span>uenta
                        </div>
                    ) : (
                        <div className="self-center text-2xl font-medium text-gray-800 sm:text-2xl">
                            Registrate en{" "}
                            <span className="text-blue-500">B</span>
                            lue
                            <span className="text-blue-500">C</span>orner
                        </div>
                    )}
                    <div className="self-center mt-4 text-gray-800 text-md sm:text-md">
                        {tipo === "subAdmin"
                            ? "Completa sus datos para el ingreso"
                            : " Completa tus datos de ingreso"}
                    </div>

                    <div className="mt-10">
                        <form action="#">
                            {tipo !== "subAdmin" && (
                                <div className="flex flex-col mb-5">
                                    <label className="mb-1 text-xs tracking-wide text-gray-600">
                                        Tipo de usuario:
                                    </label>
                                    {errors.userType && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.userType}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="w-6 h-6 text-blue-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                                <circle cx="12" cy="7" r="4" />
                                            </svg>
                                        </div>

                                        <select
                                            autoComplete="off"
                                            type="text"
                                            name="userType"
                                            onChange={handleInputChange}
                                            value={input.userType}
                                            className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400"
                                            placeholder="Ingresa tu nombre">
                                            <option value={0}>
                                                Seleccione tipo de usuario
                                            </option>
                                            <option value={1}>
                                                Administrador
                                            </option>
                                            <option value={2}>
                                                Gestor de tienda
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-col mb-5">
                                <label className="mb-1 text-xs tracking-wide text-gray-600">
                                    Nombre:
                                </label>
                                {errors.userName && (
                                    <p className="p-1 text-sm font-bold text-red-500 text-md">
                                        {errors.userName}
                                    </p>
                                )}
                                <div className="relative">
                                    <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                        <svg
                                            className="w-6 h-6 text-blue-500"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                            <circle cx="12" cy="7" r="4" />
                                        </svg>
                                    </div>

                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="userName"
                                        onChange={handleInputChange}
                                        value={input.userName}
                                        className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400"
                                        placeholder="Ingresa tu nombre"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mb-5">
                                <label
                                    htmlFor="email"
                                    className="mb-1 text-xs tracking-wide text-gray-600">
                                    Apellido paterno:
                                    {errors.father_lastName && (
                                        <p className="text-sm font-bold text-red-500 text-md">
                                            {errors.father_lastName}
                                        </p>
                                    )}
                                </label>
                                <div className="relative">
                                    <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                        <svg
                                            className="w-6 h-6 text-blue-500"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                            <circle cx="12" cy="7" r="4" />
                                        </svg>
                                    </div>

                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="father_lastName"
                                        value={input.father_lastName}
                                        onChange={handleInputChange}
                                        className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400"
                                        placeholder="Ingresa el apellido de tu padre"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mb-5">
                                <label
                                    htmlFor="email"
                                    className="mb-1 text-xs tracking-wide text-gray-600">
                                    Apellido materno:
                                </label>
                                {errors.mother_lastName && (
                                    <p className="p-1 text-sm font-bold text-red-500 text-md">
                                        {errors.mother_lastName}
                                    </p>
                                )}
                                <div className="relative">
                                    <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                        <svg
                                            className="w-6 h-6 text-blue-500"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                            <circle cx="12" cy="7" r="4" />
                                        </svg>
                                    </div>

                                    <input
                                        type="email"
                                        autoComplete="off"
                                        name="mother_lastName"
                                        value={input.mother_lastName}
                                        onChange={handleInputChange}
                                        className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400"
                                        placeholder="Ingresa el apellido de tu madre"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mb-5">
                                <label
                                    htmlFor="email"
                                    className="mb-1 text-xs tracking-wide text-gray-600">
                                    Dirección de E-Mail:
                                </label>
                                {errors.userEmail && (
                                    <p className="p-1 text-sm font-bold text-red-500 text-md">
                                        {errors.userEmail}
                                    </p>
                                )}
                                <div className="relative">
                                    <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                        <svg
                                            className="w-6 h-6 text-blue-500"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />{" "}
                                            <polyline points="22,6 12,13 2,6" />
                                        </svg>
                                    </div>

                                    <input
                                        type="email"
                                        autoComplete="off"
                                        name="userEmail"
                                        value={input.userEmail}
                                        onChange={handleInputChange}
                                        className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400"
                                        placeholder="Ingresa tu dirección de email"
                                    />
                                </div>
                            </div>
                            {tipo === "subAdmin" && (
                                <div className="flex flex-col mb-5">
                                    <label
                                        htmlFor="dni"
                                        className="mb-1 text-xs tracking-wide text-gray-600">
                                        Ingresa tu DNI:
                                    </label>
                                    {errors.userDni && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.userDni}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="h-6 w-6 text-blue-500"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                                />
                                            </svg>
                                        </div>

                                        <input
                                            type="dni"
                                            autoComplete="off"
                                            name="userDni"
                                            value={input.userDni}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400"
                                            placeholder="Ingresa tu DNI"
                                        />
                                    </div>
                                </div>
                            )}
                            {tipo === "subAdmin" && (
                                <div className="flex flex-col mb-5">
                                    <label
                                        htmlFor="celular"
                                        className="mb-1 text-xs tracking-wide text-gray-600">
                                        Ingresa tu celular:
                                    </label>
                                    {errors.userCelular && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.userCelular}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="h-6 w-6 text-blue-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <rect
                                                    x="5"
                                                    y="2"
                                                    width="14"
                                                    height="20"
                                                    rx="2"
                                                    ry="2"
                                                />{" "}
                                                <line
                                                    x1="12"
                                                    y1="18"
                                                    x2="12.01"
                                                    y2="18"
                                                />
                                            </svg>
                                        </div>

                                        <input
                                            type="celular"
                                            autoComplete="off"
                                            name="userCelular"
                                            value={input.userCelular}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400"
                                            placeholder="Ingresa tu Celular"
                                        />
                                    </div>
                                </div>
                            )}

                            {tipo === "subAdmin" && (
                                <div className="flex flex-col mb-5">
                                    <label
                                        htmlFor="direccion"
                                        className="mb-1 text-xs tracking-wide text-gray-600">
                                        Ingresa tu direccion:
                                    </label>
                                    {errors.userDireccion && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.userDireccion}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="h-6 w-6 text-blue-500"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <circle cx="12" cy="11" r="3" />{" "}
                                                <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 0 1 -2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0z" />
                                            </svg>
                                        </div>

                                        <input
                                            type="direccion"
                                            autoComplete="off"
                                            name="userDireccion"
                                            value={input.userDireccion}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400"
                                            placeholder="Ingresa tu Direccion"
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-col mb-6">
                                <label
                                    htmlFor="password"
                                    className="mb-1 text-xs tracking-wide text-gray-600">
                                    Ingresa tu contraseña:
                                </label>
                                {errors.userPassword && (
                                    <p className="p-1 text-sm font-bold text-red-500 text-md">
                                        {errors.userPassword}
                                    </p>
                                )}
                                <div className="py-0.3 relative w-full pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400">
                                    <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                        <span>
                                            <svg
                                                className="text-blue-500 h-7 w-7"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <rect
                                                    x="5"
                                                    y="11"
                                                    width="14"
                                                    height="10"
                                                    rx="2"
                                                />{" "}
                                                <circle cx="12" cy="16" r="1" />{" "}
                                                <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type={`${
                                                showPass ? "text" : "password"
                                            }`}
                                            name="userPassword"
                                            value={input.userPassword}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 rounded-2xl focus:outline-none focus:border-blue-400"
                                            placeholder="Ingresa tu contraseña"
                                        />

                                        {showPass ? (
                                            <>
                                                <svg
                                                    className="text-blue-500 cursor-pointer w-7 h-7"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setShowPass(!showPass);
                                                    }}>
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                    />
                                                </svg>
                                            </>
                                        ) : (
                                            <>
                                                <svg
                                                    className="text-blue-500 cursor-pointer w-7 h-7"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setShowPass(!showPass);
                                                    }}>
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                    />
                                                </svg>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col mb-6">
                                <label
                                    htmlFor="password"
                                    className="mb-1 text-xs tracking-wide text-gray-600">
                                    Repite tu contraseña:
                                </label>
                                {errors.userPassword2 && (
                                    <p className="p-1 text-sm font-bold text-red-500 text-md">
                                        {errors.userPassword2}
                                    </p>
                                )}
                                <div className="py-0.3 relative w-full pr-4 text-sm placeholder-gray-500 border border-gray-400 rounded-2xl focus:outline-none focus:border-blue-400">
                                    <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                        <span>
                                            <svg
                                                className="text-blue-500 h-7 w-7"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <rect
                                                    x="5"
                                                    y="11"
                                                    width="14"
                                                    height="10"
                                                    rx="2"
                                                />{" "}
                                                <circle cx="12" cy="16" r="1" />{" "}
                                                <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type={`${
                                                showPass ? "text" : "password"
                                            }`}
                                            name="userPassword2"
                                            value={input.userPassword2}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm placeholder-gray-500 rounded-2xl focus:outline-none focus:border-blue-400"
                                            placeholder="Ingresa tu contraseña"
                                        />

                                        {showPass ? (
                                            <>
                                                <svg
                                                    className="text-blue-500 cursor-pointer w-7 h-7"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setShowPass(!showPass);
                                                    }}>
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                    />
                                                </svg>
                                            </>
                                        ) : (
                                            <>
                                                <svg
                                                    className="text-blue-500 cursor-pointer w-7 h-7"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setShowPass(!showPass);
                                                    }}>
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                    />
                                                </svg>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full">
                                <button
                                    type="submit"
                                    onClick={handleRegister}
                                    className="flex items-center justify-center w-full py-2 mt-2 text-sm text-white transition duration-150 ease-in bg-blue-500 focus:outline-none sm:text-base hover:bg-blue-600 rounded-2xl">
                                    <span className="mr-2 uppercase">
                                        {tipo === "subAdmin"
                                            ? "REGISTRAR"
                                            : "REGISTRARME"}
                                    </span>
                                    <span>
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="flex items-center justify-center mt-6"></div>
            </div>
        </div>
    );
};

export default RegisterPage;
