import React, { useState } from "react";
import Footer from "../Footer";
import NavBar from "../NavBar";
import { useParams } from "react-router";
import axios from "axios";
import { useNavigate } from "react-router";
import useScrollToTop from "../../hooks/useScrollToTop";
import Swal from "sweetalert2";

const RecuerarContraseña = () => {
    const { token, id } = useParams();
    const navigate = useNavigate();
    const [ojo, setOjo] = useState(false);
    const [contraseña, setContraseña] = useState({
        contraseña: "",
        contraseña_confirmacion: "",
    });

    useScrollToTop();

    const enviarCorreo = async (correo) => {
        if (contraseña.contraseña !== contraseña.contraseña_confirmacion) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Las contraseñas no coinciden",
            });
        } else {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/autenticacion/recuperarPassword`,

                {
                    password: contraseña.contraseña,
                    id: id,
                    token: token,
                }
            );

            if (json.data.message === "Contraseña cambiada con exito!!") {
                Swal.fire({
                    title: "Exito!",

                    text: " Contraseña cambiada con exito",
                    icon: "success",

                    confirmButtonText: "Aceptar",
                }).then((result) => {
                    navigate(`${json.data.link}`);
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",

                    text: json.data.error,
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };
    return (
        <div>
            <NavBar />
            <div className="bg-[#053465] text-white text-center py-24 mt-32">
                <h1 className="text-4xl font-bold">Recuperar Contraseña</h1>
            </div>
            <div className="w-[340px] sm:w-3/5 mx-auto">
                <div className="w-full mt-4 ">
                    <label className="">Nueva contraseña:</label>

                    <input
                        value={contraseña.contraseña}
                        type={ojo ? "text" : "password"}
                        onChange={(e) => {
                            setContraseña({
                                ...contraseña,
                                contraseña: e.target.value,
                            });
                        }}
                        name="tipo_cuenta"
                        placeholder="Nueva contraseña"
                        className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                    />
                </div>
                <div
                    className={"tab-pane fade show active "}
                    id="tabs-messages"
                    role="tabpanel"
                    aria-labelledby="tabs-profile-tab"
                >
                    <div className="w-full mt-3 ">
                        <label className="">Repetir contraseña:</label>

                        <input
                            type={ojo ? "text" : "password"}
                            onChange={(e) => {
                                setContraseña({
                                    ...contraseña,
                                    contraseña_confirmacion: e.target.value,
                                });
                            }}
                            name="tipo_cuenta"
                            placeholder="Repetir contraseña"
                            value={contraseña.contraseña_confirmacion}
                            className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                        />
                    </div>
                </div>
                <button
                    onClick={() => enviarCorreo()}
                    className="w-full xs:w-7/12 flex items-center justify-center mx-auto rounded-lg text-center  bg-blue-500 py-3  text-white hover:bg-blue-400 active:translate-y-[0.125rem]  mt-3 mb-3"
                >
                    Guardar
                </button>
            </div>
            <Footer />
        </div>
    );
};

export default RecuerarContraseña;
