import React, { useState } from "react";
import axios from "axios";
import Spinner from "../../Spinner";

const ModalEditServices = ({
    modalEditVisible,
    setModalEditVisible,
    onboarding,
    setOnboarding,
    fakeRefresh,
    setFakeRefresh,
    aditionalService,
    setServicio,
}) => {
    const MONEDAS = ["PEN", "USD"];
    const [input, setInput] = useState({
        name: aditionalService.nombre,
        description: aditionalService.descripcion,
        price: aditionalService.precio,
        coin: aditionalService.tipo_moneda,
    });
    const [siguiente, setSiguiente] = useState("Actualizar");
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleEditService = async (e) => {
        setSiguiente(null);
        e.preventDefault();
        if (input.nombre !== "" && input.price !== "") {
            if (onboarding.tipo_producto === "casa") {
                const payload = {
                    id: aditionalService.id,
                    nombre: input.name,
                    descripcion: input.description,
                    precio: input.price,
                    tipo_moneda: input.coin,
                };
                await axios.put(
                    `${process.env.REACT_APP_PROXY}/productoServiciosAdicionales/actualizar/${aditionalService.id}

`,
                    payload,
                    { headers }
                );
                setFakeRefresh(!fakeRefresh);

                setModalEditVisible(!modalEditVisible);
                setServicio({});
                setInput({
                    name: "",
                    description: "",
                    price: 0,
                    coin: "",
                });
                setOnboarding({
                    ...onboarding,
                    servicios_adicionales: [
                        ...onboarding.servicios_adicionales.filter(
                            (service) => service.id !== aditionalService.id
                        ),
                        payload,
                    ],
                });
            } else if (onboarding.tipo_producto === "embarcacion") {
                const payload = {
                    id_embarcacion: onboarding.id,
                    nombre: input.name,
                    descripcion: input.description,
                    precio: input.price,
                    tipo_moneda: input.coin,
                };
                await axios.put(
                    `${process.env.REACT_APP_PROXY}/embarcacionServiciosAdicionales/actualizar/${aditionalService.id}

`,
                    payload,
                    { headers }
                );
                setFakeRefresh(!fakeRefresh);

                setModalEditVisible(!modalEditVisible);
                setServicio({});
                setInput({
                    name: "",
                    description: "",
                    price: 0,
                    coin: "",
                });
                setOnboarding({
                    ...onboarding,
                    servicios_adicionales: [
                        ...onboarding.servicios_adicionales.filter(
                            (service) => service.id !== aditionalService.id
                        ),
                        payload,
                    ],
                });
            }
        }
    };

    const handleClose = () => {
        setInput({
            name: "",
            description: "",
            price: 0,
            coin: "",
        });
        setServicio({});
        setModalEditVisible(!modalEditVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalEditVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 w-[130%] h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-4 py-16 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-12 xl:px-36">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mb-3 text-2xl font-bold">
                            Actualizar servicio existente
                        </header>
                        <div className="w-full px-2 mt-5 rounded-2xl bg-gray-50 ring-2 ring-gray-200">
                            <input
                                type="text"
                                value={input.name}
                                name="name"
                                onChange={handleInputChange}
                                placeholder="Nombre"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full px-2 mt-5 h-44 rounded-2xl bg-gray-50 ring-2 ring-gray-200">
                            <textarea
                                type="text"
                                value={input.description}
                                name="description"
                                onChange={handleInputChange}
                                placeholder="Descripción"
                                className="w-full h-40 my-3 bg-transparent border-none outline-none focus:outline-none"
                            />
                        </div>
                        <div className="grid grid-cols-[20%_40%_40%] mt-5">
                            <label className="text-lg font-semibold">
                                {" "}
                                Precio{" "}
                            </label>
                            <input
                                className="p-1 border-2 rounded-lg outline-none"
                                placeholder="100"
                                value={input.price}
                                name="price"
                                onChange={handleInputChange}></input>
                            <select
                                className="p-1 ml-2 border-2 rounded-lg"
                                name="coin"
                                value={input.coin}
                                onChange={handleInputChange}>
                                <option value={"PEN"}>{MONEDAS[0]}</option>
                                <option value={"USD"}>{MONEDAS[1]}</option>
                            </select>
                        </div>
                        <button
                            className="w-full rounded-2xl border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                            onClick={(e) => handleEditService(e)}
                            disabled={!siguiente}>
                            {siguiente ? siguiente : <Spinner width={7} />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditServices;
