import React from "react";
import logo from "../../../img/Home/BLUECORNER_PNG.png";
import background from "../../../img/bluecorner_agradecimiento.png";
import colores from "../../helpers/colores";
// import { useSearchParams } from "react-router-dom";

const PagoRechazado = () => {
    return (
        <div className="flex">
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    filter: "brightness( 100% ) contrast( 113% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
                }}
                className={`w-full bg-[${colores.primario}] h-screen bg-center bg-no-repeat
                bg-cover flex flex-col text-center`}>
                <div className="h-screen bg-[#00000074] p-2">
                    <div>
                        <img src={logo} className="mt-32" />
                    </div>
                    <h1 className="text-white text-4xl font-bold">
                        El pago ha sido rechazado
                    </h1>
                    <h1 className="text-white text-5xl">
                        Por favor cambie el medio de pago o contáctese con su
                        banco para resolver el problema.
                    </h1>
                    <a href="http://localhost:3001/">
                        <span
                            className={`bg-[${colores.secundario}] text-white p-3 min-w-max mx-auto cursor-pointer border border-white rounded-md text-lg`}>
                            {" "}
                            Volver al sitio{" "}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PagoRechazado;
