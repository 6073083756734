import getFeeByDay from "../getFeeByDay";
import priceToMonedaElegida from "../priceToMonedaElegida";

const getPricesFromDates = (
    date,
    arrayDates,
    moneda_precio_base,
    arrayFees,
    fee_cliente_siempre,
    fee_propietario_siempre
) => {
    let response = {};

    arrayDates.some((fecha) => {
        const newDate = new Date(fecha.date + "T00:00:00");
        if (date.getTime() === newDate.getTime()) {
            let ambos_fee = {
                fee_cliente: Number(
                    (
                        fecha.precio / (1 - fee_cliente_siempre / 100) -
                        fecha.precio
                    ).toFixed(2)
                ),
            };

            if (arrayFees.length > 0) {
                ambos_fee = getFeeByDay(
                    date,
                    arrayFees,
                    fee_cliente_siempre,
                    fee_propietario_siempre,
                    fecha.precio
                );
            }

            return (response = {
                hasProperty: true,
                total: fecha.precio,
                fee_cliente: ambos_fee.fee_cliente,
                tiene_fee_por_fecha: ambos_fee.tiene_fee_por_fecha,
            });
        } else {
            response = {
                hasProperty: false,
            };
        }
    });

    return response;
};

export default getPricesFromDates;
