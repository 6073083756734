import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ToggleButton from "../../ToggleButton";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import useHeaders from "../../../hooks/useHeaders";
import dateFromGuiontoSlash from "../../helpers/dateFromGuiontoSlash";
import formatedDate_YYYY_MM_DD from "../../helpers/formatedDate_YYYY_MM_DD";
import Swal from "sweetalert2";

const ModalCrearPaquetePersonalizado = ({ modalVisible, setModalVisible }) => {
    registerLocale("es", es);
    const authUser = useSelector((state) => state.authReducer);
    const { headers } = useHeaders();
    const [input, setInput] = useState({
        adultos: "",
        fecha_evento: formatedDate_YYYY_MM_DD(new Date()),
        ninos: "",
        descripcion: "",
        empresa: authUser.nombre_empresa,
    });

    const [cargando, setCargando] = useState(false);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };
    const handleChange = (event) => {
        const value = event.target.value;

        if (value.match(/^\d+$/) || event.nativeEvent.data === null) {
            setInput({
                ...input,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleCreate = async () => {
        if (input.adultos === "") {
            Swal.fire({
                title: "Error!",
                text: "Debes agregar la cantidad de personas",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
            return;
        }
        if (input.descripcion === "") {
            Swal.fire({
                title: "Error!",
                text: "El paquete debe de tener una descripcion",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
            return;
        }
        setCargando(true);
        try {
            const dataAdmin = await axios(
                `${process.env.REACT_APP_PROXY}/adminEmpresas/detalle/${authUser.id_usuario}`,
                { headers }
            );
            console.log(dataAdmin);
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/paquetesEmpresas/contactForm/`,
                {
                    ...input,
                    cliente: `${dataAdmin.data.data.nombre} ${dataAdmin.data.data.apellido_paterno}`,
                    telefono: dataAdmin.data.data.celular,
                },
                { headers }
            );

            console.log(data);

            if (data.message === "El email fue mandado correctamente") {
                Swal.fire({
                    title: "Paquete enviado!",
                    text: "Muy pronto nos pondremos en contacto",
                    icon: "success",
                    width: "350px",
                    confirmButtonText: "Aceptar",
                });
                setModalVisible(!modalVisible);
            }
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "intentelo de nuevo mas tarde",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        }
    };

    const handleClose = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div className="fixed top-0 left-0 z-40 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
            <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 ">
                <div className="relative w-full block justify-center px-4 py-16 bg-white rounded-lg md:w-96 dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto">
                    <button
                        onClick={handleClose}
                        className="absolute text-gray-800 dark:text-gray-400 top-8 right-4 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                        <svg
                            className="rounded-full hover:bg-gray-600 hover:text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>

                    <header className="text-lg font-medium">
                        Cotizar paquete
                    </header>
                    {/* <div className="flex items-center justify-between mt-4">
                        <span className="font-medium">Activada</span>
                        <ToggleButton
                            isToggled={input.activo === "Si" && true}
                            onChangeToggle={(active) => {
                                setInput({
                                    ...input,
                                    activo: active ? "Si" : "No",
                                });
                            }}
                        />
                    </div> */}
                    <hr className="mt-6" />
                    <div className="p-2">
                        <div>
                            <span className="flex mt-2">fecha del evento</span>
                            <DatePicker
                                className="w-full p-3 mt-2 border rounded-lg"
                                placeholderText="Desde"
                                autoComplete="off"
                                locale="es"
                                selected={
                                    new Date(input.fecha_evento + "T00:00:00")
                                }
                                value={dateFromGuiontoSlash(input.fecha_evento)}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) =>
                                    setInput({
                                        ...input,
                                        fecha_evento:
                                            formatedDate_YYYY_MM_DD(date),
                                    })
                                }
                            />
                        </div>
                        <div className="flex w-full gap-5">
                            <div className="flex flex-col ">
                                <span className="mt-2 ">
                                    Cantidad de adultos
                                </span>
                                <input
                                    value={input.adultos}
                                    type={"number"}
                                    name="adultos"
                                    onChange={handleInputChange}
                                    className="w-full p-3 mt-2 border rounded-lg"
                                />
                            </div>
                            <div className="flex flex-col">
                                <span className="mt-2 ">
                                    Cantidad de niños{"   "}
                                </span>
                                <input
                                    value={input.ninos}
                                    type={"number"}
                                    name="ninos"
                                    onChange={handleInputChange}
                                    className="w-full p-3 mt-2 border rounded-lg"
                                />
                            </div>
                        </div>
                        <div className="flex items-end w-full gap-2">
                            <div className="flex flex-col flex-1">
                                <span className="flex mt-2">
                                    Descripcion del paquete
                                </span>
                                <textarea
                                    value={input.descripcion}
                                    name="descripcion"
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            descripcion: e.target.value,
                                        })
                                    }
                                    className="w-full p-3 mt-2 border rounded-lg"
                                    placeholder="Quiero un viaje en embarcacion para el equipo de desarrollo "
                                />
                            </div>
                        </div>
                        <button
                            disabled={cargando}
                            className="w-full p-3 mt-6 text-white rounded-lg bg-blue-bluecorner active:translate-y-2"
                            onClick={() => handleCreate()}>
                            {cargando ? "Enviando.." : "Cotizar"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCrearPaquetePersonalizado;
