import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    getAllProduct,
    searchProductByName,
} from "../../redux/actions/productActions";

const SearchBar = ({
    setfakeRefresh,
    fakeRefresh,
    enabled,
    completed,
    tipo,
    autenticadorId,
    publicado,
}) => {
    const token = localStorage.getItem("token");
    const debounceInput = useRef(null);
    const [input, setinput] = useState("");
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setinput(e.target.value);
        if (debounceInput.current) clearTimeout(debounceInput.current);

        debounceInput.current = setTimeout(async () => {
            if (e.target.value.length > 0) {
                dispatch(
                    searchProductByName(
                        enabled,
                        e.target.value,
                        completed,
                        tipo,
                        autenticadorId.tipo_usuario === 3
                            ? autenticadorId.id_usuario
                            : autenticadorId.tipo_usuario === 4
                            ? autenticadorId.id_propietario
                            : 0,
                        publicado
                    )
                );
                setfakeRefresh(!fakeRefresh);
            } else {
                if (enabled === "Si") {
                    dispatch(
                        getAllProduct(
                            1,
                            "Si",
                            completed,
                            token,
                            tipo,
                            autenticadorId.tipo_usuario === 3
                                ? autenticadorId.id_usuario
                                : autenticadorId.tipo_usuario === 4
                                ? autenticadorId.id_propietario
                                : 0,
                            publicado
                        )
                    );
                } else
                    dispatch(
                        getAllProduct(
                            1,
                            "No",
                            completed,
                            token,
                            tipo,
                            autenticadorId.tipo_usuario === 3
                                ? autenticadorId.id_usuario
                                : autenticadorId.tipo_usuario === 4
                                ? autenticadorId.id_propietario
                                : 0,
                            publicado
                        )
                    );
            }
        }, 500);
    };

    return (
        <div className="flex w-64 p-1 border rounded appearance-none xs:w-72 lg:w-80 left-2 sm:max-w-screen-sm text-stone-900">
            <button className="flex items-center justify-center px-1">
                <svg
                    className="w-6 h-6 text-blue-bluecorner"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                </svg>
            </button>
            <input
                onBlur={() => setinput("")}
                value={input}
                type="text"
                onChange={handleInputChange}
                className="w-full px-1 py-2 bg-transparent outline-none appearance-none placeholder:text-[#3C3C3C]"
                placeholder="Buscar"
            />
        </div>
    );
};

export default SearchBar;
