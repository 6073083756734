import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// import { getRulesPerPage } from "../../redux/actions/rulesActions";
// import Spinner from "../Spinner";

const ModalDetallePaquete = ({ setPaquete, paquete }) => {
    console.log(paquete);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const { tipo_usuario } = useSelector((state) => state.authReducer);
    const [descuento, setDescuento] = useState(
        paquete.pedido_paquete_empresa.descuento
    );
    const [tipoVenta, setTipoVenta] = useState(
        paquete.pedido_paquete_empresa.estado_venta || "Proforma"
    );
    const [modalEdit, setModalEdit] = useState({
        visible: false,
        index: "",
        costo: "",
        personas: "",
    });
    const [siguiente, setSiguiente] = useState("Enviar");
    const [input, setInput] = useState({
        id_paquete: "",

        nombre_paquete: "",
        descripcion_paquete: "",
        numero_personas: "",
        costo: "",
    });
    const [rangos, setRangos] = useState([]);
    const [rangoPersonalizado, setRangoPersonalizado] = useState({
        habilitado: false,
        personas: "",
    });

    const [allPaquetes, setAllPaquetes] = useState([]);
    const [allPedidos, setAllPedidos] = useState(
        paquete.detalle_pedido_paquete_empresa
    );
    const [detalleDisabled, setDetalleDisabled] = useState(true);

    const getPaquetes = async (pagina = 1, habilitado = "Si", input = "") => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/paquetesempresas/all/${pagina}/${habilitado}/?nombre=${input}`,

            { headers }
        );

        setAllPaquetes(json.data.data);
    };

    const getRangos = async (paquete) => {
        if (paquete === "Selecciona un paquete") {
            setRangos([]);
            setInput({
                id_paquete: "",
                nombre_paquete: "",
                descripcion_paquete: "",
                numero_personas: "",
                costo: "",
            });
            setDetalleDisabled(true);
        } else if (paquete === "Paquete personalizado") {
            setDetalleDisabled(false);
            setRangos([]);
            setInput({
                ...input,
                id_paquete: "",
                descripcion_paquete: "",
                nombre_paquete: paquete,
            });
        } else {
            setDetalleDisabled(true);

            let index = allPaquetes.findIndex((item) => item.id == paquete);

            setInput({
                ...input,
                nombre_paquete: allPaquetes[index].nombre,
                id_paquete: Number(paquete),
                descripcion_paquete: allPaquetes[index].descripcion,
            });
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/paquetesempresas/detalle/${paquete}`,

                { headers }
            );

            if (json.data.paquetes_empresas_personas.length) {
                setRangos(json.data.paquetes_empresas_personas);
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "",
                    icon: "error",
                    width: "350px",
                    confirmButtonText: "Aceptar",
                });
            }
        }

        // setModalEditVisible(!modalEditVisible);
    };

    const addRango = (rango) => {
        if (rango === "Otro rango") {
            setRangoPersonalizado({
                habilitado: true,
                precio: "",
            });
            setInput({
                ...input,

                costo: 0,
                numero_personas: "Otro rango",
            });
        } else if (rango === "Selecciona un rango") {
            setRangoPersonalizado({
                habilitado: false,
                precio: "",
            });
            setInput({
                ...input,
                costo: 0,
                numero_personas: "Selecciona un rango",
            });
        } else {
            setRangoPersonalizado({
                habilitado: false,
                precio: "",
            });
            let index = rangos.findIndex(
                (item) => item.numero_personas == rango
            );

            setInput({
                ...input,

                costo: rangos[index].precio,
                numero_personas: rangos[index].numero_personas,
            });
        }
    };

    const agregarPedido = () => {
        setDetalleDisabled(true);
        setAllPedidos([...allPedidos, input]);
        setRangoPersonalizado({
            habilitado: false,
            precio: "",
        });
        setRangos([]);
        setInput({
            id_paquete: "",
            nombre_paquete: "",
            descripcion_paquete: "",
            numero_personas: "",
            costo: "",
        });
    };
    const modificarPedido = (i) => {
        let pedidoParaModificar = allPedidos;
        pedidoParaModificar[i].costo = Number(modalEdit.costo);
        pedidoParaModificar[i].numero_personas = Number(modalEdit.personas);
        setAllPedidos(pedidoParaModificar);
        setModalEdit({
            id_paquete: "",

            nombre_paquete: "",
            descripcion_paquete: "",
            numero_personas: "",
            costo: "",
        });
    };

    const sumaTotal = () => {
        let total = 0;

        allPedidos.map((item) => {
            total += item.costo;
        });

        return total;
    };

    useEffect(() => {
        getPaquetes();
    }, []);

    const handleCreate = async (e) => {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        setSiguiente(null);
        e.preventDefault();
        if (!allPedidos.length) {
            Swal.fire({
                title: "Error!",
                text: "debes agregar por lo menos un paquete y rango ",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
            setSiguiente("Enviar");
        } else {
            console.log({
                costo: sumaTotal(),
                costo_total: sumaTotal() - descuento,
                descuento: descuento,
                estado_venta: tipoVenta,
                detalle_pedido: allPedidos,
            });
            const json = await axios.put(
                `${process.env.REACT_APP_PROXY}/pedidoPaquetesEmpresas/actualizar/${paquete.id}`,
                {
                    costo: sumaTotal(),
                    costo_total: sumaTotal() - descuento,
                    descuento: descuento,
                    estado_venta: tipoVenta,
                    detalle_pedido: allPedidos,
                },
                {
                    headers,
                }
            );

            console.log(json.data);

            if (json.data.message === "registro exitoso") {
                Swal.fire({
                    title: "Paquete actualizado correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });

                setInput({
                    id_paquete: "",
                    nombre_paquete: "",
                    descripcion_paquete: "",
                    numero_personas: "",
                    costo: "",
                });
                setRangos([]);
                setAllPaquetes([]);
                setAllPedidos([]);
                setPaquete(null);
            }

            setSiguiente("Enviar");
        }
    };

    const handleClose = () => {
        setInput({
            id_paquete: "",
            nombre_paquete: "",
            descripcion_paquete: "",
            numero_personas: "",
            costo: "",
        });
        setRangos([]);
        setAllPaquetes([]);
        setPaquete(null);
    };

    console.log(allPedidos);
    console.log(input);

    return (
        <div className={`relative items-center justify-center flex`}>
            <div className="fixed bottom-0 left-0 z-20 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-8 ">
                    <div className="relative block justify-center px-4 bg-white rounded-lg  w-auto dark:bg-white md:px-6 md:h-[calc(100vh-30vh)] h-[calc(100vh-20vh)] overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="px-2 mt-10 text-base font-bold text-left lg:text-2xl">
                            Detalle del pedido
                        </header>

                        <div className="w-full mt-2">
                            <div className="mx-auto overflow-hidden bg-white rounded-lg ">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div
                                            className={
                                                allPedidos.length
                                                    ? "mt-2 rounded-lg w-full "
                                                    : "hidden"
                                            }>
                                            {/* --- */}

                                            <table className="w-full mt-2 border-2 border-gray-600 bordered">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            className="uppercase border-2 border-gray-600"
                                                            colSpan={2}>
                                                            Nombre
                                                        </th>
                                                        <th
                                                            className="uppercase border-2 border-gray-600 "
                                                            colSpan={2}>
                                                            Precio
                                                        </th>
                                                        {/* <th
                                                            className="p-4 uppercase border-2 border-gray-600"
                                                            colSpan={2}>
                                                            Precio
                                                        </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}>
                                                            {/* <div className="flex flex-auto">detalle</div> */}

                                                            <p className="flex items-center font-medium">
                                                                <img
                                                                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${paquete.pedido_paquete_empresa?.imagen_minificada}`}
                                                                    alt="Imagen principal"
                                                                    className="object-cover h-10 mt-2 mr-2 rounded-lg w-14"
                                                                />
                                                                {
                                                                    paquete
                                                                        .pedido_paquete_empresa
                                                                        ?.paquete_empresa
                                                                }
                                                            </p>
                                                        </td>
                                                        <td
                                                            className="p-2 border-2 border-gray-600"
                                                            colSpan={1}>
                                                            <span className="flex font-semibold ">
                                                                {paquete
                                                                    .pedido_paquete_empresa
                                                                    ?.costo_paquete >
                                                                0
                                                                    ? paquete
                                                                          .pedido_paquete_empresa
                                                                          ?.costo_paquete +
                                                                      " USD"
                                                                    : "a definir"}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        className={
                                                            allPedidos.length >
                                                            0
                                                                ? ""
                                                                : "hidden"
                                                        }>
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}>
                                                            <h3>
                                                                Servicios extras
                                                            </h3>
                                                        </td>
                                                        <td
                                                            className="p-2 font-semibold border-2 border-gray-600"
                                                            colSpan={1}>
                                                            <h3></h3>
                                                        </td>
                                                    </tr>
                                                    {allPedidos.map(
                                                        (paquete, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td
                                                                        className="relative border-2 border-gray-600"
                                                                        colSpan={
                                                                            3
                                                                        }>
                                                                        {/* <div className="flex flex-auto">detalle</div> */}

                                                                        <p className="flex items-center font-medium ">
                                                                            <img
                                                                                src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${paquete.imagen_minificada}`}
                                                                                alt="Imagen principal"
                                                                                className="object-cover h-10 mr-2 rounded-lg w-14"
                                                                            />
                                                                            {
                                                                                paquete.nombre_paquete
                                                                            }{" "}
                                                                        </p>
                                                                        <p
                                                                            className="absolute top-[-2px] right-[-2px]"
                                                                            title={
                                                                                paquete.descripcion_paquete
                                                                            }>
                                                                            <svg
                                                                                className="w-4 h-4 mt-1 mr-1 text-blue-500 cursor-help"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="2"
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                {" "}
                                                                                <path
                                                                                    stroke="none"
                                                                                    d="M0 0h24v24H0z"
                                                                                />{" "}
                                                                                <circle
                                                                                    cx="12"
                                                                                    cy="12"
                                                                                    r="9"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="12"
                                                                                    y1="8"
                                                                                    x2="12"
                                                                                    y2="12"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="12"
                                                                                    y1="16"
                                                                                    x2="12.01"
                                                                                    y2="16"
                                                                                />
                                                                            </svg>
                                                                        </p>
                                                                    </td>
                                                                    <td
                                                                        className="border-2 border-gray-600 "
                                                                        colSpan={
                                                                            1
                                                                        }>
                                                                        <span className="flex font-semibold ">
                                                                            {paquete.costo >
                                                                            0
                                                                                ? paquete.costo +
                                                                                  " USD"
                                                                                : "a definir"}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                    {descuento > 0 && (
                                                        <tr>
                                                            <td
                                                                className="p-4 border-2 border-gray-600"
                                                                colSpan={3}>
                                                                <h3>
                                                                    Descuento
                                                                </h3>
                                                            </td>
                                                            <td
                                                                className="p-2 font-semibold border-2 border-gray-600"
                                                                colSpan={1}>
                                                                <h3>
                                                                    -{descuento}{" "}
                                                                    USD
                                                                </h3>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}>
                                                            <h3>
                                                                Total a abonar
                                                            </h3>
                                                        </td>
                                                        <td
                                                            className="p-2 font-semibold border-2 border-gray-600"
                                                            colSpan={1}>
                                                            {descuento < 1 ? (
                                                                <p>
                                                                    {sumaTotal() +
                                                                        paquete
                                                                            .pedido_paquete_empresa
                                                                            .costo_paquete}{" "}
                                                                    USD
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {sumaTotal() +
                                                                        paquete
                                                                            .pedido_paquete_empresa
                                                                            .costo_paquete -
                                                                        descuento}{" "}
                                                                    USD
                                                                </p>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {/* --- */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDetallePaquete;
