import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import SearchBar from "./SearchBar";
import { Paginado } from "../helpers/Paginado";
import axios from "axios";
import ModalEditUser from "./ModalEditUser";
// import ModalEditPassword from "./ModalEditPassword";
import Spinner from "../Spinner";
import ModalNewUser from "./ModalNewUser";
import MembresiaDetalle from "../membresias/MembresiaDetalle";
import LinkEmpresa from "./LinkEmpresa";
import { useNavigate } from "react-router";
import ModalOptions from "./ModalOptions";

const token = localStorage.getItem("token");
const headers = {
    authorization: token,
};

const AllEmpresas = ({ setPanel, tipo, idUsuario }) => {
    const [modalNewUser, setModalNewUser] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [modalOptions, setModalOptions] = useState(false);
    const [page, setPage] = useState(1);
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [habilitados, setHabilitados] = useState(true);
    const [subPropietarios, setSubPropietarios] = useState({
        checking: true,
        subPropietarios: [],
        total: 0,
    });
    const [idSelect, setIdSelect] = useState(null);
    const [linkEmpresa, setLinkEmpresa] = useState(null);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const USERS_PER_PAGE = 20;

    const getAllSubPropietario = async (
        pagina = 1,
        habilitado = "Si",
        nombre = ""
    ) => {
        setSubPropietarios({ checking: true, subPropietarios: [] });
        const jsonembar = await axios.get(
            `${process.env.REACT_APP_PROXY}/empresas/all/${pagina}/${habilitado}?nombre=${nombre}`,
            { headers }
        );
        console.log(jsonembar.data);
        if (jsonembar.data.total > 0) {
            setSubPropietarios({
                checking: true,
                subPropietarios: jsonembar.data.data,
                total: jsonembar.data.total,
            });
        } else {
            setSubPropietarios({
                checking: false,
                subPropietarios: jsonembar.data.data,
                total: jsonembar.data.total,
            });
        }
    };

    useEffect(() => {
        if (habilitados) {
            getAllSubPropietario();
        } else {
            getAllSubPropietario(1, "No");
        }
    }, [habilitados, fakeRefresh]);

    const historialMembresia = (empresa) => {
        navigate(
            `/dashboard/historial-membresias/${empresa.id}/${empresa.nombre}`
        );
    };

    const handleDelete = async (idUser) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará la empresa a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/empresas/eliminar/${idUser}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Empresa eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllSubPropietario(1, "Si");
                    });
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará la empresa a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/empresas/restaurar/${id}`,
                        {},
                        {
                            headers,
                        }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Empresa restaurado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });

                        getAllSubPropietario(1, "No");
                    });
            }
        });
    };

    const handleEdit = async (id) => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/empresas/detalle/${id}`,
            { headers }
        );
        setIdSelect(json.data);
        setModalEditVisible(!modalEditVisible);
    };

    const handleOptionsButton = (empresa) => {
        setIdSelect(null);
        setIdSelect(empresa);
        setModalOptions((prev) => !prev);
    };

    const pagination = (pageNumber) => {
        if (habilitados) dispatch(getAllSubPropietario(pageNumber, "Si"));
        else dispatch(getAllSubPropietario(pageNumber, "No"));
        setPage(pageNumber);
    };

    return (
        <>
            {linkEmpresa !== null && (
                <LinkEmpresa
                    empresa={linkEmpresa}
                    setLinkEmpresa={setLinkEmpresa}
                />
            )}
            {modalOptions && idSelect && (
                <ModalOptions
                    empresaSelected={idSelect}
                    modalVisible={modalOptions}
                    setmodalVisible={setModalOptions}
                />
            )}
            <section className=" w-full md:pl-[12rem] lg:pl-[16rem] lg1170:pl-[16rem]  xl:pl-[16rem] p-4">
                <div className="flex items-center justify-between w-full ">
                    <h1 className="py-10 text-4xl text-center">Empresas</h1>

                    <div className="mt-2 lg:ml-10">
                        <button
                            className="flex items-center justify-center w-8 h-8 p-2 text-blue-500 border border-blue-500 rounded-lg lg:w-auto lg:h-auto group hover:bg-blue-500 hover:text-white"
                            onClick={() => setModalNewUser((prev) => !prev)}>
                            <svg
                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                            <h1 className="hidden lg:flex">Añadir empresa</h1>
                        </button>
                    </div>
                </div>

                <div className="flex items-center justify-end w-full mt-6 lg:p-4 lg:mt-0 lg:ml-5">
                    <SearchBar
                        setfakeRefresh={setfakeRefresh}
                        fakeRefresh={fakeRefresh}
                        habilitados={habilitados}
                        getAllSubPropietario={getAllSubPropietario}
                    />

                    <button className="relative flex items-center justify-center w-8 h-8 ml-5 border rounded-lg lg:w-11 lg:h-11 border-blue-bluecorner">
                        <svg
                            onClick={() => setShowButtons((prev) => !prev)}
                            className="text-blue-500 h-7 w-7 lg:h-8 lg:w-8"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                            />
                        </svg>
                        {showButtons && (
                            <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                                <div
                                    onClick={() => {
                                        setHabilitados(true);
                                        setShowButtons((prev) => !prev);
                                        setPage(1);
                                    }}
                                    className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                    <p
                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                        Habilitados
                                    </p>
                                </div>
                                <div
                                    onClick={() => {
                                        setHabilitados(false);
                                        setShowButtons((prev) => !prev);
                                        setPage(1);
                                    }}
                                    className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                    <p
                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                        Deshabilitados
                                    </p>
                                </div>
                            </div>
                        )}
                    </button>
                </div>
                {modalNewUser && (
                    <ModalNewUser
                        modalVisible={modalNewUser}
                        setmodalVisible={setModalNewUser}
                        setfakeRefresh={setfakeRefresh}
                    />
                )}

                {modalEditVisible && (
                    <ModalEditUser
                        subSelect={idSelect}
                        setmodalVisible={setModalEditVisible}
                        modalVisible={modalEditVisible}
                        setfakeRefresh={setfakeRefresh}
                    />
                )}

                <div className="flex justify-center items-center lg:py-[10px] ">
                    <div className="flex w-full max-w-full text-center">
                        {subPropietarios?.subPropietarios?.length > 0 ? (
                            <div className="w-full">
                                <table className="w-full mt-14">
                                    <thead className="text-left">
                                        <tr>
                                            <th className="px-4 w-4/6 min-w-[8rem] lg:w-2/6 ">
                                                Nombre
                                            </th>

                                            <th className="hidden w-1/6 lg:table-cell">
                                                Direccion
                                            </th>
                                            <th className="hidden w-1/6 lg1170:table-cell">
                                                Documento
                                            </th>
                                            <th className="hidden w-2/6 lg:w-1/6 xs:table-cell ">
                                                Estado
                                            </th>

                                            <th
                                                align="center"
                                                className="w-2/6">
                                                Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-left">
                                        {subPropietarios.subPropietarios?.map(
                                            (user) => {
                                                return (
                                                    <tr
                                                        key={user.id}
                                                        className="w-full border-t">
                                                        <td className="flex p-5">
                                                            {user.nombre}
                                                        </td>
                                                        <td className="hidden lg:table-cell">
                                                            {user.direccion}
                                                        </td>
                                                        <td className="hidden lg1170:table-cell">
                                                            {
                                                                user.numero_documento
                                                            }
                                                        </td>
                                                        <td className="hidden text-center xs:table-cell">
                                                            <p
                                                                onClick={() => {
                                                                    historialMembresia(
                                                                        user
                                                                    );
                                                                }}
                                                                className={`${
                                                                    user.membresia ===
                                                                    "No"
                                                                        ? "border-red-600 text-red-600"
                                                                        : user.membresia ===
                                                                              "Si" &&
                                                                          user.tipo ===
                                                                              "Aceptado"
                                                                        ? "border-green-600 text-green-600"
                                                                        : user.membresia ===
                                                                              "Si" &&
                                                                          user.tipo ===
                                                                              "Prueba" &&
                                                                          "border-orange-400 text-orange-400"
                                                                } py-1 border rounded-lg max-w-[130px] px-2 cursor-pointer hover:scale-105 relative`}>
                                                                {user?.pagos_revision >
                                                                    0 && (
                                                                    <>
                                                                        <span className="absolute right-[-2px] inline-flex w-4 h-4 rounded-full animate-ping bg-red-400 top-[-4px]"></span>
                                                                        <span
                                                                            className="absolute  rounded-full h-4 w-4 bg-red-500 right-[-2px] top-[-4px] text-center flex items-center justify-center text-xs text-white font-light
                                ">
                                                                            {" "}
                                                                            {
                                                                                user?.pagos_revision
                                                                            }
                                                                        </span>
                                                                    </>
                                                                )}
                                                                {user.membresia ===
                                                                "No"
                                                                    ? "Sin membresia"
                                                                    : user.membresia ===
                                                                          "Si" &&
                                                                      user.tipo ===
                                                                          "Aceptado"
                                                                    ? user.plan
                                                                    : user.membresia ===
                                                                          "Si" &&
                                                                      user.tipo ===
                                                                          "Prueba" &&
                                                                      `Prueba (${user.dias_prueba} días)`}
                                                                {/* {user.membresia ===
                                                                "Prueba"
                                                                    ? `${user.tipo_membresia} (${user.dias_prueba} días)`
                                                                    : user.membresia} */}
                                                            </p>
                                                        </td>

                                                        <td>
                                                            {habilitados ? (
                                                                <div className="flex justify-center ml-9">
                                                                    <button
                                                                        className="flex items-center justify-center w-8 h-8 mr-2 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                        title="Editar"
                                                                        onClick={() =>
                                                                            handleEdit(
                                                                                user.id
                                                                            )
                                                                        }>
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />{" "}
                                                                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                                            <line
                                                                                x1="16"
                                                                                y1="5"
                                                                                x2="19"
                                                                                y2="8"
                                                                            />
                                                                        </svg>
                                                                    </button>

                                                                    <button
                                                                        className="items-center justify-center hidden w-8 h-8 mr-2 text-blue-500 border border-blue-500 rounded lg:flex group hover:bg-blue-500 hover:text-white"
                                                                        title="Ver empleados"
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `/dashboard/empleados-empresa/${user.id}/${user.nombre}`
                                                                            )
                                                                        }>
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor">
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth="2"
                                                                                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                                                                            />
                                                                        </svg>
                                                                    </button>

                                                                    <button
                                                                        className="flex items-center justify-center w-8 h-8 mr-2 text-blue-500 border border-blue-500 rounded lg:hidden group hover:bg-blue-500 hover:text-white"
                                                                        title="Link de logeo empresa"
                                                                        onClick={() =>
                                                                            handleOptionsButton(
                                                                                user
                                                                            )
                                                                        }>
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />{" "}
                                                                            <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />{" "}
                                                                            <circle
                                                                                cx="12"
                                                                                cy="12"
                                                                                r="3"
                                                                            />
                                                                        </svg>
                                                                    </button>

                                                                    <button
                                                                        className="items-center justify-center hidden w-8 h-8 mr-2 text-blue-500 border border-blue-500 rounded lg:flex group hover:bg-blue-500 hover:text-white"
                                                                        title="Link de logeo empresa"
                                                                        onClick={() =>
                                                                            setLinkEmpresa(
                                                                                user
                                                                            )
                                                                        }>
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3" />{" "}
                                                                            <line
                                                                                x1="8"
                                                                                y1="12"
                                                                                x2="16"
                                                                                y2="12"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                        className="items-center justify-center hidden w-8 h-8 mr-2 text-blue-500 border border-blue-500 rounded lg:flex group hover:bg-blue-500 hover:text-white"
                                                                        title="Promociones pagadas"
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `/dashboard/pagos-all-promo-empresa/${user.id}/${user.nombre}`
                                                                            )
                                                                        }>
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor">
                                                                            <path
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                stroke-width="2"
                                                                                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                            />
                                                                        </svg>
                                                                    </button>

                                                                    <button
                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                user.id
                                                                            )
                                                                        }
                                                                        title="Eliminar">
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />{" "}
                                                                            <line
                                                                                x1="4"
                                                                                y1="7"
                                                                                x2="20"
                                                                                y2="7"
                                                                            />{" "}
                                                                            <line
                                                                                x1="10"
                                                                                y1="11"
                                                                                x2="10"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <line
                                                                                x1="14"
                                                                                y1="11"
                                                                                x2="14"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <div className="flex justify-center ml-5 ">
                                                                    <button
                                                                        onClick={() =>
                                                                            handleEnable(
                                                                                user.id
                                                                            )
                                                                        }
                                                                        title="Habilitar"
                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <polyline points="17 1 21 5 17 9" />{" "}
                                                                            <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                            <polyline points="7 23 3 19 7 15" />{" "}
                                                                            <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={5}>
                                                <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                                                <div className="flex justify-center">
                                                    {tipo !== "subAdmin" && (
                                                        <Paginado
                                                            elementsPerPage={
                                                                USERS_PER_PAGE
                                                            }
                                                            cantTotalElements={
                                                                subPropietarios.total
                                                            }
                                                            cantCategoriesSearching={
                                                                subPropietarios
                                                                    .subPropietarios
                                                                    ?.length
                                                            }
                                                            pagination={
                                                                pagination
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        ) : !subPropietarios.checking ? (
                            <h1>No se encontraron propietarios</h1>
                        ) : (
                            <Spinner margin_top={20} />
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default AllEmpresas;
