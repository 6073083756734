import axios from "axios";

export const getCategoriesPerPage = (pageNumber = 1, SioNo) => {
  return async function (dispatch) {
    const json = await axios(
      `${process.env.REACT_APP_PROXY}/categoria/all/${pageNumber}/${SioNo}/`
    );
    return dispatch({
      type: "GET_CATEGORIES",
      payload: json.data,
    });
  };
};

export const searchCategoriesByName = (input, SioNo) => {
  return async function (dispatch) {
    return dispatch({
      type: "SEARCH_CATEGORIES",
      payload: {
        input,
        SioNo,
      },
    });
  };
};

export const categorySelected = (id) => {
  return async function (dispatch) {
    const category = await axios(
      `${process.env.REACT_APP_PROXY}/categoria/detalle/${id}`
    );
    return dispatch({
      type: "CATEGORY_SELECTED",
      payload: {
        category,
      },
    });
  };
};

export const clearSelected = () => {
  return async function (dispatch) {
    return dispatch({
      type: "CLEAR_CATEGORY",
    });
  };
};

export const getAllCategories = () => {
  return async function (dispatch) {
    const json = await axios(`${process.env.REACT_APP_PROXY}/categoria/select`);
    return dispatch({
      type: "GET_ALL_CATEGORIES",
      payload: json.data,
    });
  };
};

export const deleteCategory = (id) => {
  return async function (dispatch) {
    await axios.delete(
      `${process.env.REACT_APP_PROXY}/categoria/eliminar/${id}`
    );
    return dispatch({
      type: "DELETE_CATEGORY",
      payload: id,
    });
  };
};

export const clearCategories = () => {
  return async function (dispatch) {
    return dispatch({
      type: "CLEAR_CATEGORIES",
    });
  };
};
