import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ToggleButton from "../../ToggleButton";
import useHeaders from "../../../hooks/useHeaders";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import formatedDate_YYYY_MM_DD from "../../helpers/formatedDate_YYYY_MM_DD";
import dateFromGuiontoSlash from "../../helpers/dateFromGuiontoSlash";
import ModalMessageTopRight from "../../ModalMessageTopRight";
import ButtonCopyToClipboard from "../../ButtonCopyToClipboard";

const ModalEditarPromociones = ({
    modalVisible,
    setModalVisible,
    promocion,
    getAllPromociones,
}) => {
    registerLocale("es", es);
    const { headers } = useHeaders();
    const [modalTopRight, setModalTopRight] = useState(false);
    const [input, setInput] = useState({
        nombre: promocion.nombre || "",
        desde: promocion.fecha_inicio || "",
        hasta: promocion.fecha_final || "",
        costo_asumido: promocion.costo_asumido || "",
        moneda_costo_asumido: promocion.tipo_moneda || "PEN",
        activo: promocion.activo,
        cantidadDeUso: promocion.uso_cupones || "",
    });
    const [mostrarInputNumero, setMostrarInputNumero] = useState(
        promocion.uso_cupones > 0 ? "si" : "no"
    );
    console.log(promocion);

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setMostrarInputNumero(value);
    };

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleChange = (event) => {
        const value = event.target.value;

        if (value.match(/^\d+$/) || event.nativeEvent.data === null) {
            setInput({
                ...input,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleUpdate = async () => {
        let uso_cupones;

        if (mostrarInputNumero === "no") {
            uso_cupones = 0;
        } else if (input.cantidadDeUso === "") {
            uso_cupones = 0;
        } else if (input.cantidadDeUso > 0) {
            uso_cupones = input.cantidadDeUso;
        } else {
            uso_cupones = 0;
        }

        const promoCompleta = {
            // id_usuario: authUser.id_usuario,
            nombre: input.nombre,
            fecha_inicio: input.desde,
            fecha_final: input.hasta,
            costo_asumido: input.costo_asumido,
            tipo_moneda: input.moneda_costo_asumido,
            activo: input.activo ? "Si" : "No",
            uso_cupones,
        };

        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/promoEmpresas/actualizar/${promocion.id}`,
            promoCompleta,
            { headers }
        );

        if (data.message === "actuaizacion exitoso") {
            getAllPromociones();
            setModalVisible(!modalVisible);
        }
    };

    const handleClose = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div className="fixed top-0 left-0 z-40 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
            <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 ">
                <div className="relative w-full block justify-center px-4 py-16 bg-white rounded-lg md:w-96 dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto">
                    {modalTopRight && (
                        <ModalMessageTopRight
                            message="Cupón copiado al portapapeles correctamente"
                            success={true}
                        />
                    )}
                    <button
                        onClick={handleClose}
                        className="absolute text-gray-800 dark:text-gray-400 top-8 right-4 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                        <svg
                            className="rounded-full hover:bg-gray-600 hover:text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <header className="text-lg font-medium">
                        Editar promocion
                    </header>
                    <div className="flex items-center justify-between mt-4">
                        <span className="font-medium">Activada</span>
                        <ToggleButton
                            isToggled={promocion.activo === "Si" && true}
                            onChangeToggle={(active) => {
                                setInput({
                                    ...input,
                                    activo: active ? "Si" : "No",
                                });
                            }}
                        />
                    </div>
                    <hr className="mt-6" />
                    <div className="p-2">
                        <div>
                            <span className="flex mt-2">
                                Nombre de la promoción
                            </span>
                            <input
                                value={input.nombre}
                                type={"text"}
                                name="nombre"
                                onChange={handleInputChange}
                                className="w-full p-3 mt-2 border rounded-lg"
                            />
                        </div>
                        <div className="flex w-full gap-5">
                            <div className="flex flex-col ">
                                <span className="mt-2 ">Desde</span>
                                <DatePicker
                                    className="w-full p-3 mt-2 border rounded-lg"
                                    placeholderText="Desde"
                                    autoComplete="off"
                                    locale="es"
                                    selected={
                                        new Date(input.desde + "T00:00:00")
                                    }
                                    value={dateFromGuiontoSlash(input.desde)}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) =>
                                        setInput({
                                            ...input,
                                            desde: formatedDate_YYYY_MM_DD(
                                                date
                                            ),
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col">
                                <span className="mt-2 ">Hasta</span>
                                <DatePicker
                                    className="w-full p-3 mt-2 border rounded-lg "
                                    placeholderText="Hasta"
                                    selected={
                                        new Date(input.hasta + "T00:00:00")
                                    }
                                    value={dateFromGuiontoSlash(input.hasta)}
                                    autoComplete="off"
                                    locale="es"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                        console.log(
                                            formatedDate_YYYY_MM_DD(date)
                                        );
                                        setInput({
                                            ...input,
                                            hasta: formatedDate_YYYY_MM_DD(
                                                date
                                            ),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex items-end w-full gap-2">
                            <div className="flex flex-col flex-1">
                                <span className="flex mt-2">
                                    Costo asumido por la empresa
                                </span>
                                <input
                                    value={input.costo_asumido}
                                    type={"text"}
                                    name="costo_asumido"
                                    onChange={handleChange}
                                    className="w-full p-3 mt-2 border rounded-lg"
                                />
                            </div>
                            <div className="flex flex-col w-20 ">
                                <span className="flex mt-2">Moneda</span>
                                <select
                                    className="w-full p-3 mt-2 border rounded-lg"
                                    name="moneda_costo_asumido"
                                    value={input.moneda_costo_asumido}
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            moneda_costo_asumido:
                                                e.target.value,
                                        })
                                    }>
                                    <option value="PEN">PEN</option>
                                    <option value="USD">USD</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <span className="flex mt-2">
                                Con cantidad de uso
                            </span>
                            <select
                                value={mostrarInputNumero}
                                onChange={handleSelectChange}
                                className="w-1/4 p-3 mt-2 border rounded-lg">
                                <option value="no">No</option>
                                <option value="si">Sí</option>
                            </select>
                            {mostrarInputNumero === "si" && (
                                <input
                                    value={input.cantidadDeUso}
                                    type="number"
                                    name="cantidadDeUso"
                                    placeholder="Cantidad"
                                    onChange={handleInputChange}
                                    className="w-2/4 p-3 mt-2 border rounded-lg border-black mx-4"
                                />
                            )}
                        </div>
                        <div>
                            <span className="flex mt-2">Cupón</span>
                            <div className="flex items-center justify-between w-full p-3 mt-2 border rounded-lg">
                                <span>{promocion.cupon}</span>
                                <ButtonCopyToClipboard
                                    confirmationText="Cupón copiado al portapapeles correctamente"
                                    textToCopy={promocion.cupon}
                                />
                            </div>
                        </div>

                        <button
                            className="w-full p-3 mt-6 text-white rounded-lg bg-blue-bluecorner"
                            onClick={() => handleUpdate()}>
                            Actualizar promocion
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditarPromociones;
