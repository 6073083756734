import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Lazy } from "swiper";
import { normalizeString } from "../../helpers/normalizeString";
import colores from "../../helpers/colores";
import Spinner from "../../Spinner";
import numberToCommas from "../../helpers/numberToCommas";
import priceToMonedaElegida from "../../helpers/priceToMonedaElegida";
import "swiper/css/lazy";

const SecondHouses = ({ secondHouses, indexImagen, setIndexImagen }) => {
    const moneda_elegida = localStorage.getItem("moneda");
    return (
        <div>
            {secondHouses?.map((house, index) => {
                return (
                    <div key={house.id}>
                        {house.casas?.length > 0 && (
                            <>
                                <div className="text-[#053465] font-bold text-2xl px-8 md:px-6 xl:px-32 2xl:px-64 mt-8 ">
                                    <p className="text-center">
                                        {" "}
                                        Casas del {house.fecha_inicio} al{" "}
                                        {house.fecha_final}
                                    </p>
                                </div>
                                <div className="w-full grid-cols-2 gap-4 p-4 md:grid lg:grid-cols-3 xl:px-32 2xl:px-64">
                                    {house.casas.map((casa, index) => (
                                        <div
                                            key={casa.id_casa}
                                            className="flex flex-col w-full px-4 mt-6 md:px-1 lg:px-1 rounded-t-xl">
                                            <div className="relative w-full h-[250px] flex shadow-xl">
                                                <Swiper
                                                    modules={[Navigation, Lazy]}
                                                    slidesPerView={1}
                                                    watchSlidesProgress
                                                    lazy={true}
                                                    navigation
                                                    rewind={true}
                                                    loop={true}>
                                                    <SwiperSlide
                                                        onClick={() =>
                                                            window.open(
                                                                `/alojamientos/${
                                                                    casa.id_casa
                                                                }/${normalizeString(
                                                                    casa.casa
                                                                )}`,
                                                                "_blank"
                                                            )
                                                        }>
                                                        <img
                                                            loading="lazy"
                                                            className="object-cover w-full h-full rounded-md swiper-lazy"
                                                            alt="Imagen del producto"
                                                            data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${casa.imagen_principal_minificada}`}
                                                            data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${casa.imagen_principal_minificada}`}
                                                        />
                                                        <div className="swiper-lazy-preloader">
                                                            <div className="flex flex-col w-full h-56 gap-2 p-3 bg-gray-200">
                                                                <div className="bg-slate-50 w-full h-[80%] rounded-md"></div>
                                                                <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                                <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                    {casa.imagenes.map(
                                                        (imagen) => (
                                                            <SwiperSlide
                                                                key={imagen.id}
                                                                onClick={() =>
                                                                    window.open(
                                                                        `/alojamientos/${
                                                                            casa.id_casa
                                                                        }/${normalizeString(
                                                                            casa.casa
                                                                        )}`,
                                                                        "_blank"
                                                                    )
                                                                }>
                                                                <img
                                                                    loading="lazy"
                                                                    className="object-cover w-full h-full rounded-md swiper-lazy"
                                                                    alt="Imagen del producto"
                                                                    data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${imagen.imagen_minificada}`}
                                                                    data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${imagen.imagen_minificada}`}
                                                                />
                                                                <div className="swiper-lazy-preloader">
                                                                    <div className="flex flex-col w-full h-56 gap-2 p-3 bg-gray-200">
                                                                        <div className="bg-slate-50 w-full h-[80%] rounded-md"></div>
                                                                        <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                                        <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                    )}
                                                </Swiper>
                                            </div>
                                            <a
                                                href={`/alojamientos/${
                                                    casa.id_casa
                                                }/${normalizeString(
                                                    casa.casa
                                                )}`}
                                                className={`bottom-0 flex flex-col w-full h-32 p-4 text-lg font-bold text-white bg-[${colores.primario}] rounded-b-xl cursor-pointer`}>
                                                <span className="">{`${casa.casa}`}</span>
                                                <span className="mt-2 text-lg font-semibold line-through">
                                                    {`Antes ${moneda_elegida} ${numberToCommas(
                                                        priceToMonedaElegida(
                                                            casa.precio_antes,
                                                            casa.tipo_moneda
                                                        )
                                                    )} `}
                                                </span>
                                                <span className="mt-2 text-xl font-bold">
                                                    {`Ahora ${moneda_elegida} ${numberToCommas(
                                                        priceToMonedaElegida(
                                                            casa.precio_despues,
                                                            casa.tipo_moneda
                                                        )
                                                    )} `}
                                                </span>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default SecondHouses;
