import React, { useState, useEffect } from "react";
import ModalAddServices from "./ModalAddServices";
import ModalEditServices from "./ModalEditServices";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";

const AditionalServices = ({
    onboarding,
    setOnboarding,
    setPage,
    actualPage,
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [servicios, setServicios] = useState({
        checking: true,
        data: [],
    });
    const [fakeRefresh, setFakeRefresh] = useState(false);
    const [servicio, setServicio] = useState({});
    const [siguiente, setSiguiente] = useState("Siguiente");
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        const func = async () => {
            if (onboarding.tipo_producto === "casa") {
                const { data } = await axios(
                    `${process.env.REACT_APP_PROXY}/productoServiciosAdicionales/all/${onboarding.id}`,
                    { headers }
                );
                console.log(data);
                setServicios({
                    checking: false,
                    data,
                });
            }
            if (onboarding.tipo_producto === "embarcacion") {
                const { data } = await axios(
                    `${process.env.REACT_APP_PROXY}/embarcacionServiciosAdicionales/all/${onboarding.id}`,
                    { headers }
                );
                setServicios({
                    checking: false,
                    data,
                });
            }
        };
        func();
    }, [fakeRefresh, onboarding.id]);

    const handleDelete = (idServicio) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminar el servicio?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                if (onboarding.tipo_producto === "casa") {
                    const { data } = axios.delete(
                        `${process.env.REACT_APP_PROXY}/productoServiciosAdicionales/eliminar/${idServicio}`,
                        { headers }
                    );
                }
                if (onboarding.tipo_producto === "embarcacion") {
                    const { data } = axios.delete(
                        `${process.env.REACT_APP_PROXY}/embarcacionServiciosAdicionales/eliminar/${idServicio}`,
                        { headers }
                    );
                }
                Swal.fire({
                    title: "Servicio eliminado correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setFakeRefresh(!fakeRefresh);
            }
        });
    };

    const handleEdit = (aditionalService) => {
        setServicio(aditionalService);
        setModalEditVisible(!modalEditVisible);
    };

    const nextPage = async () => {
        setSiguiente(null);
        if (onboarding.tipo_producto === "casa") {
            await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarPagina/${onboarding.id}`,
                {
                    numero_pagina: actualPage + 1,
                },
                { headers }
            );
            setPage(actualPage + 1);
        }
        if (onboarding.tipo_producto === "embarcacion") {
            await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacion/actualizarPagina/${onboarding.id}`,
                {
                    numero_pagina: actualPage + 1,
                },
                { headers }
            );
            setPage(actualPage + 1);
        }
    };
    console.log(onboarding);
    console.log(servicios);

    return (
        <div className="p-5 mx-auto xl:w-2/4">
            <h1 className="py-16 -mt-5 text-4xl font-bold text-center sm:py-10 sm:text-6xl">
                ¿Tienes algun servicio adicional?
            </h1>
            <div className="flex w-full">
                <button
                    className="inline-flex items-center px-3 py-2 mx-auto text-lg font-semibold text-blue-700 border border-blue-700 rounded group hover:bg-blue-700 hover:text-white "
                    onClick={() => setModalVisible(!modalVisible)}>
                    <svg
                        className="w-5 h-5 text-blue-700 group-hover:text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                    <span className="items-center">Añadir</span>
                </button>
            </div>
            <div className="m-10">
                {servicios.checking ? (
                    <Spinner width={20} />
                ) : (
                    servicios.data.map((servicio) => {
                        return (
                            <div className="w-full mt-8" key={servicio.id}>
                                <div className="flex items-center justify-between">
                                    <span className="text-3xl font-semibold">
                                        {servicio.nombre}:
                                    </span>
                                    <div className="flex">
                                        <span
                                            className="mx-2 cursor-pointer"
                                            onClick={() =>
                                                handleEdit(servicio)
                                            }>
                                            <svg
                                                className="w-8 h-8 text-blue-500 group-hover:text-white"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                <line
                                                    x1="16"
                                                    y1="5"
                                                    x2="19"
                                                    y2="8"
                                                />
                                            </svg>
                                        </span>
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => {
                                                handleDelete(servicio.id);
                                            }}>
                                            <svg
                                                className="w-8 h-8 text-red-500 group-hover:text-white"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <line
                                                    x1="4"
                                                    y1="7"
                                                    x2="20"
                                                    y2="7"
                                                />{" "}
                                                <line
                                                    x1="10"
                                                    y1="11"
                                                    x2="10"
                                                    y2="17"
                                                />{" "}
                                                <line
                                                    x1="14"
                                                    y1="11"
                                                    x2="14"
                                                    y2="17"
                                                />{" "}
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <hr />
                                <span className="text-2xl">
                                    {servicio.descripcion}
                                </span>
                                <br />
                                <span className="text-xl text-gray-500">{`$${
                                    servicio.precio
                                } ${
                                    servicio.tipo_moneda === "USD"
                                        ? "(USD)"
                                        : "(PEN)"
                                }`}</span>
                            </div>
                        );
                    })
                )}
            </div>
            <ModalAddServices
                fakeRefresh={fakeRefresh}
                setFakeRefresh={setFakeRefresh}
                onboarding={onboarding}
                setOnboarding={setOnboarding}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
            />
            {servicio.id && (
                <ModalEditServices
                    fakeRefresh={fakeRefresh}
                    setFakeRefresh={setFakeRefresh}
                    onboarding={onboarding}
                    setOnboarding={setOnboarding}
                    modalEditVisible={modalEditVisible}
                    aditionalService={servicio}
                    setServicio={setServicio}
                    setModalEditVisible={setModalEditVisible}
                />
            )}
            <div className="flex items-center justify-center mt-20">
                <button
                    onClick={() => setPage(actualPage - 1)}
                    className="p-2 pl-5 pr-5 mr-2 text-lg text-gray-500 bg-transparent border-2 border-gray-500 rounded-lg min-w-[118px] hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 ">
                    Anterior
                </button>

                <button
                    onClick={() => nextPage()}
                    disabled={!siguiente}
                    className="my-5 p-2 pl-5 pr-5 text-lg text-gray-500 bg-transparent border-2 border-gray-500 rounded-lg min-w-[118px] hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 disabled:bg-white disabled:border-black">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div>
        </div>
    );
};

export default AditionalServices;
