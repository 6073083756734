import axios from "axios";
import React, { useEffect, useState, FC } from "react";
import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";
import ModalNewEspacio from "./ModalNewEspacio";
import ModalEditEspacio from "./ModalEditEspacio";

const EditarImages = ({ producto, setProducto, setModal, tipo_producto }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [buttonChecking, setButtonChecking] = useState("Guardar");
    const [imagenesSecundarias, setImagenesSecundarias] = useState([]);

    const [espacios, setEspacios] = useState([]);
    const [espaciosId, setEspaciosId] = useState(0);
    const [espacioNew, setEspacioNew] = useState(false);

    const [cargandoSecundarias, setCargandoSecundarias] = useState(true);
    const [modalEliminar, setModalEliminar] = useState({
        visible: false,
        index: "",
    });
    const authUser = useSelector((state) => state.authReducer);

    const form = new FormData();

    useEffect(() => {
        cargarImagenes();
        cargarEtiquetas();
    }, [espacioNew, espaciosId]);

    const handleChangeImage = (e) => {
        if (e.target.files[0]) {
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    imagen_principal: e.target.files[0],
                },
            });
        }
    };

    const cargarEtiquetas = async () => {
        if (tipo_producto === "casa") {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/getAll/${producto.data.id}`,
                { headers }
            );
            console.log("cargarEtiquetas", data);
            setEspacios(data);
        }
    };

    const cargarImagenes = async () => {
        setCargandoSecundarias(true);
        if (tipo_producto === "casa") {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/productoImagenes/all/${producto.data.id}`,
                { headers }
            );
            setImagenesSecundarias(data);
            setCargandoSecundarias(false);
        }
        if (tipo_producto === "embarcacion") {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/embarcacionImagenes/all/${producto.data.id}`,
                { headers }
            );
            setImagenesSecundarias(data);
            setCargandoSecundarias(false);
        }
    };

    const multiLoadFile = function (event) {
        setCargandoSecundarias(true);
        const url = [...producto.data.imagenes];
        if (event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                url.push(event.target.files[i]);
            }

            url?.forEach((image) => {
                tipo_producto === "casa"
                    ? form.append("imagenes_casas", image)
                    : form.append("imagenes_embarcaciones", image);
            });

            if (tipo_producto === "casa") {
                form.append("id_productos", producto.data.id);
                form.append("id_usuario", authUser.id_usuario);
            } else {
                form.append("id_embarcacion", producto.data.id);
            }

            updateImagenes(form);
        }
    };

    const updateImagenes = async (form) => {
        if (tipo_producto === "casa") {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/productoImagenes`,
                form,
                { headers }
            );
            if (json.data.message === "Imagenes del producto guardadas") {
                cargarImagenes();
            }
        }
        if (tipo_producto === "embarcacion") {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/embarcacionImagenes`,
                form,
                { headers }
            );

            if (json.data.message === "Imagenes de la embarcacion guardadas") {
                cargarImagenes();
            }
        }
    };

    const cambiarImagenPrincipal = async (id, nombreImagen) => {
        setCargandoSecundarias(true);

        if (tipo_producto === "casa") {
            const json = await axios.put(
                `${process.env.REACT_APP_PROXY}/productoImagenes/convertToImagePrincipal/${id}`,
                {},
                { headers }
            );

            if (json.data.message === "Imagen actualizada con exito") {
                cargarImagenes();
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        imagen_principal: nombreImagen,
                    },
                });
            }
        }
        if (tipo_producto === "embarcacion") {
            const json = await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacionImagenes/convertToImagePrincipal/${id}`,
                {},
                { headers }
            );

            if (json.data.message === "Imagen actualizada con exito") {
                cargarImagenes();
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        imagen_principal: nombreImagen,
                    },
                });
            }
        }
    };

    const eliminarImagen = async (id) => {
        setCargandoSecundarias(true);
        if (tipo_producto === "casa") {
            const json = await axios.delete(
                `${process.env.REACT_APP_PROXY}/productoImagenes/eliminar/${id}`,
                {
                    data: { id_usuario: authUser.id_usuario },
                    headers: headers,
                }
            );

            if (json.data.message === "Registro deshabilitado con exito") {
                cargarImagenes(producto.data.id);
            }
        }
        if (tipo_producto === "embarcacion") {
            const json = await axios.delete(
                `${process.env.REACT_APP_PROXY}/embarcacionImagenes/eliminar/${id}`,
                { headers }
            );

            if (json.data.message === "Registro deshabilitado con exito") {
                cargarImagenes(producto.data.id);
            }
        }
    };
    const handleDeleteEspacio = async (id) => {
        try {
            const json = await axios.delete(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/deleteEtiquetaImagen/${id}`,
                {
                    headers: headers,
                }
            );
            console.log(json);
            if (
                json.data.message ===
                "No se puede eliminar porque tiene imagenes en esta etiqueta!"
            ) {
                Swal.fire({
                    title: "No se puede eliminar porque tiene imagenes en esta etiqueta!",
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Ok!",
                });
                return;
            }
            cargarEtiquetas();
        } catch (error) {
            console.log(error);
        }
    };

    const OcultarImagen = async (id, accion) => {
        setCargandoSecundarias(true);
        if (tipo_producto === "casa") {
            try {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/productoImagenes/ocultar/${id}`,
                    {
                        hide: accion === "No" ? "Si" : "No",
                    },
                    {
                        headers,
                    }
                );
                console.log(json.data);

                if (json.data.message === "Imagen oculta con exito") {
                    cargarImagenes(producto.data.id);
                }
            } catch (error) {
                console.log(error.response);
            }
        }
        if (tipo_producto === "embarcacion") {
            const json = await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacionImagenes/ocultar/${id}`,
                {
                    hide: accion === "No" ? "Si" : "No",
                },
                { headers }
            );

            if (json.data.message === "Imagen oculta con exito") {
                cargarImagenes(producto.data.id);
            }
        }
    };

    const updateProduct = async (form) => {
        if (tipo_producto === "embarcacion") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacion/actualizarImagenPrincipal/${producto.data.id}`,
                form,
                { headers }
            );
            setModal(false);
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    imagen_principal: data.imagen,
                },
            });
        }
        if (tipo_producto === "casa") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarImagenPrincipal/${producto.data.id}`,
                form,
                { headers }
            );
            if (data.message === "Imagenes actualizadas con exito") {
                setModal(false);
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        imagen_principal: data.imagen,
                    },
                });
            }
        }
    };

    const cambioDeEspacios = async () => {
        let array_orden = [];
        espacios.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        console.log(array_orden);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/putOrden`,
            {
                array_orden: array_orden,
            },
            { headers }
        );
    };

    useEffect(() => {
        espacios.length && cambioDeEspacios();
    }, [espacios]);

    const handleGuardar = async () => {
        let array_orden = [];
        imagenesSecundarias.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        console.log(array_orden);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${
                tipo_producto === "casa"
                    ? "productoImagenes"
                    : "embarcacionImagenes"
            }/updateOrden`,
            {
                array_orden: array_orden,
            },
            { headers }
        );
        console.log(data);
        if (!producto.data.imagen_principal) {
            Swal.fire({
                title: "Debes adjuntar una Foto de portada",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });
        } else {
            setButtonChecking(false);
            if (tipo_producto === "casa") {
                if (typeof producto.data.imagen_principal !== "string") {
                    form.append(
                        "imagen_principal_casa",
                        producto.data.imagen_principal
                    );
                    form.append("id_usuario", authUser.id_usuario);
                    updateProduct(form);
                } else {
                    setModal(false);
                }
            } else {
                if (typeof producto.data.imagen_principal !== "string") {
                    form.append(
                        "imagen_principal_embarcacion",
                        producto.data.imagen_principal
                    );
                    form.append("id_usuario", authUser.id_usuario);

                    updateProduct(form);
                } else {
                    setModal(false);
                }
            }
        }
    };

    return (
        <div className="p-1 rounded-lg w-full">
            {espacioNew && (
                <ModalNewEspacio
                    setEspacioNew={setEspacioNew}
                    producto={producto.data.id}
                />
            )}

            {espaciosId !== 0 && (
                <ModalEditEspacio
                    idSelect={espaciosId}
                    producto={producto.data.id}
                    setIdSelect={setEspaciosId}
                />
            )}
            <h1 className="text-2xl font-bold">Fotos</h1>
            <hr />
            <div className="mt-2">
                <h1>Foto de portada</h1>
                {producto.data.imagen_principal === "" ? (
                    <div className="mt-3 relative flex  justify-center w-[312px] h-32 border border-[#E9E9E9]  rounded-lg hover:cursor-pointer">
                        <div className="absolute">
                            <div className="flex flex-col w-full mx-auto ">
                                <div className="relative top-4 ">
                                    <span className="block text-base font-bold text-center ">
                                        Arrastra la foto aquí
                                    </span>
                                    <span className="block text-sm text-center ">
                                        Agrega una foto llamativa
                                    </span>
                                    <span className="block mt-8 font-normal text-center text-blue-bluecorner">
                                        sube la foto desde tu dispositivo
                                    </span>
                                </div>
                            </div>
                        </div>
                        <input
                            accept="image/*"
                            type="file"
                            name="icono_si"
                            className="w-full h-full opacity-0 cursor-pointer "
                            onChange={handleChangeImage}
                        />
                    </div>
                ) : (
                    <div>
                        <div className="relative flex items-center justify-center w-full h-48 mt-1 rounded-lg hover:cursor-pointer">
                            <div className="absolute w-full">
                                <div>
                                    {tipo_producto === "embarcacion" ? (
                                        <img
                                            src={
                                                producto.data.imagen_principal
                                                    ?.length
                                                    ? `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/embarcacion/getImagenPrincipal/${producto.data.imagen_principal}`
                                                    : URL.createObjectURL(
                                                          producto.data
                                                              .imagen_principal
                                                      )
                                            }
                                            className="object-cover w-full mx-auto mt-5 rounded-lg h-52 lg:h-72 xl:h-96 xl:mt-56 lg:mt-28"
                                        />
                                    ) : (
                                        <img
                                            src={
                                                producto.data.imagen_principal
                                                    ?.length
                                                    ? `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${producto.data.imagen_principal}`
                                                    : URL.createObjectURL(
                                                          producto.data
                                                              .imagen_principal
                                                      )
                                            }
                                            className="object-cover w-full mx-auto mt-5 rounded-lg h-52 lg:h-72 xl:h-96 xl:mt-56 lg:mt-28"
                                        />
                                    )}
                                </div>
                            </div>
                            <input
                                accept="image/*"
                                type="file"
                                name="icono_si"
                                className="w-full mt-5 rounded-lg opacity-0 cursor-pointer h-52 lg:h-72 xl:h-96 xl:mt-56 lg:mt-28"
                                onChange={handleChangeImage}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="mt-10 lg:mt-28 xl:mt-56">
                <h1>Fotos secundarias</h1>
                {cargandoSecundarias ? (
                    <Spinner />
                ) : (
                    // <div className="flex flex-wrap gap-2 lg:grid lg:grid-cols-2 2xl:grid-cols-3">
                    <div className="">
                        {tipo_producto !== "casa" && (
                            <div className="mt-4 relative flex justify-center h-52 w-full lg:h-72 xl:h-96 2xl:h-52 border border-[#E9E9E9] rounded-lg hover:cursor-pointer">
                                <div className="absolute">
                                    <div className="flex flex-col w-full mx-auto ">
                                        <div className="relative top-4 ">
                                            <span className="block mt-10 text-base font-bold text-center ">
                                                Agrega o arrastra una foto aquí
                                            </span>
                                            <span className="block mt-3 text-sm text-center">
                                                Agrega una foto llamativa
                                            </span>
                                            <span className="block mt-3 font-normal text-center text-blue-bluecorner">
                                                Sube la foto desde tu
                                                dispositivo
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    accept="image/*"
                                    multiple
                                    type="file"
                                    className="w-full h-full opacity-0 cursor-pointer"
                                    onChange={multiLoadFile}
                                />
                            </div>
                        )}
                        <div>
                            {tipo_producto === "casa" && (
                                <div>
                                    <p
                                        onClick={() => setEspacioNew(true)}
                                        className="p-2 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner w-36 text-center cursor-pointer flex items-center hover:scale-105"
                                    >
                                        {" "}
                                        + Nuevo espacio
                                    </p>
                                    <div>
                                        <p className="text-xl font-semibold">
                                            Espacios:
                                        </p>
                                        <ReactSortable
                                            handle=".handle"
                                            className="mt-4 gap-2"
                                            group="espacios"
                                            animation={200}
                                            delayOnTouchStart={true}
                                            delay={2}
                                            list={espacios}
                                            setList={setEspacios}
                                        >
                                            {espacios?.map((espacio, index) => (
                                                <div
                                                    key={index}
                                                    className=" border border-gray-200 rounded-md p-6   mt-2 relative handle"
                                                >
                                                    <p
                                                        onClick={() =>
                                                            setEspaciosId(
                                                                espacio
                                                            )
                                                        }
                                                        className="cursor-pointer "
                                                    >
                                                        {espacio.nombre}
                                                    </p>
                                                    <div
                                                        onClick={() =>
                                                            handleDeleteEspacio(
                                                                espacio.id
                                                            )
                                                        }
                                                        className="absolute rounded-md top-1 right-1 bg-slate-200 hover:scale-105 z-10 cursor-pointer"
                                                    >
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="9"
                                                            />{" "}
                                                            <path d="M10 10l4 4m0 -4l-4 4" />
                                                        </svg>
                                                    </div>
                                                    <div className="absolute rounded-md bottom-1 right-1 bg-slate-200 hover:scale-105 z-10 cursor-move ">
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <polyline points="5 9 2 12 5 15" />{" "}
                                                            <polyline points="9 5 12 2 15 5" />{" "}
                                                            <polyline points="15 19 12 22 9 19" />{" "}
                                                            <polyline points="19 9 22 12 19 15" />{" "}
                                                            <line
                                                                x1="2"
                                                                y1="12"
                                                                x2="22"
                                                                y2="12"
                                                            />{" "}
                                                            <line
                                                                x1="12"
                                                                y1="2"
                                                                x2="12"
                                                                y2="22"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            ))}
                                        </ReactSortable>
                                    </div>
                                </div>
                            )}
                        </div>
                        {tipo_producto !== "casa" && (
                            <ReactSortable
                                handle=".handle"
                                className="flex flex-wrap gap-2 lg:grid lg:grid-cols-2 2xl:grid-cols-3"
                                group="groupName"
                                animation={200}
                                delayOnTouchStart={true}
                                delay={2}
                                list={imagenesSecundarias}
                                setList={setImagenesSecundarias}
                            >
                                {imagenesSecundarias.map((image, index) => (
                                    <div
                                        key={index}
                                        className={`relative w-full mt-4 border rounded-lg cursor-move ${
                                            window.innerWidth < 500
                                                ? ""
                                                : "handle"
                                        } `}
                                    >
                                        <span
                                            onClick={() =>
                                                setModalEliminar({
                                                    visible: true,
                                                    index:
                                                        index ===
                                                        modalEliminar.index
                                                            ? null
                                                            : index,
                                                })
                                            }
                                            className={`absolute right-4 top-4 text-gray-800 dark:text-gray-400 rounded-sm bg-white hover:bg-slate-200 z-20`}
                                            aria-label="close"
                                        >
                                            <svg
                                                className="w-5 h-5 text-black cursor-pointer"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="1"
                                                />{" "}
                                                <circle cx="12" cy="5" r="1" />{" "}
                                                <circle cx="12" cy="19" r="1" />
                                            </svg>
                                        </span>
                                        <span
                                            className={`absolute left-4 top-4 text-gray-800 dark:text-gray-400 rounded-sm bg-white hover:bg-slate-200  handle  xs:hidden z-20`}
                                            aria-label="close"
                                        >
                                            <svg
                                                className="w-5 h-5 text-black handle"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <polyline points="5 9 2 12 5 15" />{" "}
                                                <polyline points="9 5 12 2 15 5" />{" "}
                                                <polyline points="15 19 12 22 9 19" />{" "}
                                                <polyline points="19 9 22 12 19 15" />{" "}
                                                <line
                                                    x1="2"
                                                    y1="12"
                                                    x2="22"
                                                    y2="12"
                                                />{" "}
                                                <line
                                                    x1="12"
                                                    y1="2"
                                                    x2="12"
                                                    y2="22"
                                                />
                                            </svg>
                                        </span>
                                        <div
                                            className={
                                                modalEliminar.visible ===
                                                    true &&
                                                modalEliminar.index === index
                                                    ? "absolute bg-white z-10 right-1 top-10 border rounded-lg  p-2"
                                                    : "hidden"
                                            }
                                        >
                                            <p
                                                onClick={() => (
                                                    setModalEliminar({
                                                        visible: false,
                                                        index: null,
                                                    }),
                                                    cambiarImagenPrincipal(
                                                        image.id,
                                                        image.imagen
                                                    )
                                                )}
                                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer"
                                            >
                                                Agregar a imagen principal
                                            </p>
                                            <p
                                                onClick={() => (
                                                    setModalEliminar({
                                                        visible: false,
                                                        index: null,
                                                    }),
                                                    OcultarImagen(
                                                        image.id,
                                                        image.oculto
                                                    )
                                                )}
                                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer"
                                            >
                                                {image.oculto === "No"
                                                    ? "Ocultar"
                                                    : "Mostrar"}
                                            </p>
                                            <p
                                                onClick={() => (
                                                    setModalEliminar({
                                                        visible: false,
                                                        index: null,
                                                    }),
                                                    eliminarImagen(image.id)
                                                )}
                                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer"
                                            >
                                                Eliminar
                                            </p>
                                        </div>

                                        {tipo_producto === "casa" ? (
                                            <img
                                                src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/productoImagenes/getImagen/${image.imagen}`}
                                                className={` object-cover w-full mx-auto rounded-lg h-52 lg:h-72 xl:h-96 2xl:h-52 ${
                                                    image.oculto === "Si"
                                                        ? " opacity-30"
                                                        : ""
                                                }`}
                                            />
                                        ) : (
                                            <img
                                                src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/embarcacionImagenes/getImagen/${image.imagen}`}
                                                className={` object-cover w-full mx-auto rounded-lg h-52 lg:h-72 xl:h-96 2xl:h-52 ${
                                                    image.oculto === "Si"
                                                        ? " opacity-30"
                                                        : ""
                                                }`}
                                            />
                                        )}
                                    </div>
                                ))}
                            </ReactSortable>
                        )}
                    </div>
                )}
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7"
                >
                    {buttonChecking ? buttonChecking : <Spinner width={7} />}
                </button>
            </div>
        </div>
    );
};

export default EditarImages;
