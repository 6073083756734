import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCategoriesPerPage,
  searchCategoriesByName,
} from "../../redux/actions/categoriesActions";

const SearchBar = ({ setfakeRefresh, fakeRefresh, habilitados }) => {
  const dispatch = useDispatch();
  const debounceInput = useRef(null);
  const [input, setInput] = useState("");

  const handleInputChangeDebounce = (e) => {
    setInput(e.target.value);
    if (debounceInput.current) clearTimeout(debounceInput.current);
    if (e.target.value === "") {
      if (habilitados) dispatch(getCategoriesPerPage(1, "Si"));
      else dispatch(getCategoriesPerPage(1, "No"));
      return;
    }
    debounceInput.current = setTimeout(async () => {
      if (habilitados) dispatch(searchCategoriesByName(e.target.value, "Si"));
      else dispatch(searchCategoriesByName(e.target.value), "No");
      setfakeRefresh(!fakeRefresh);
    }, 500);
  };

  return (
    <div className="flex p-1 m-5 border-2 rounded appearance-none w-96 left-2 sm:max-w-screen-sm text-stone-900">
      <input
        type="text"
        onBlur={() => setInput("")}
        value={input}
        onChange={handleInputChangeDebounce}
        className="w-full px-4 py-2 bg-transparent outline-none appearance-none"
        placeholder="Buscar..."
      />
      <button className="flex items-center justify-center px-5">
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
          <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
        </svg>
      </button>
    </div>
  );
};

export default SearchBar;
