import axios from "axios";
import React, { useEffect, useState } from "react";
import useHeaders from "../../../hooks/useHeaders";
import numberToCommas from "../../helpers/numberToCommas";
import Spinner from "../../Spinner";

const DetalleAlquilerCliente = ({ pedido_id, showAlojamientos }) => {
    const [pedidoSelected, setPedidoSelected] = useState(null);
    const { headers } = useHeaders();

    const getPedido = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/${
                showAlojamientos ? "pedidoCasa" : "pedidoEmbarcacion"
            }/detalle/${pedido_id}`,
            { headers }
        );

        setPedidoSelected(data);
    };

    useEffect(() => {
        getPedido();
    }, []);

    const getAlquilerCliente = () => {
        let total = pedidoSelected.descuento_puntos
            ? pedidoSelected.costo_alquiler +
              pedidoSelected.costo_fee_bc +
              pedidoSelected.costo_fee_propietario
            : pedidoSelected.costo_alquiler +
              pedidoSelected.costo_fee_bc +
              pedidoSelected.costo_fee_propietario;

        return total;
    };

    console.log(pedidoSelected);

    const getTotalAlquilerCliente = () => {
        //FUNCION QUE SUMA LOS AUMENTOS OCULTOS A CLIENTES
        let total = pedidoSelected.cotos_total_alquiler;

        // total = total - pedidoSelected.descuento_empresa;

        return total;
    };

    if (!pedidoSelected) {
        return <Spinner />;
    } else
        return (
            <div className="md:mt-14 pb-28 2xl:10/12 mx-auto">
                <div className="mt-5">
                    <h1 className="text-xl font-bold">Alquiler</h1>
                    <div className="flex justify-between py-3 border-b">
                        <span>Costo</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(getAlquilerCliente())}
                        </span>
                    </div>

                    {pedidoSelected.descuento_empresa > 0 && (
                        <div className="flex justify-between py-3 border-b">
                            <span>Descuento empresa </span>
                            <span>
                                - {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.descuento_empresa
                                )}
                            </span>
                        </div>
                    )}
                    {pedidoSelected.descuento_oferta > 0 && (
                        <div className="flex justify-between py-3 border-b">
                            <span>Descuento </span>
                            <span>
                                - {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.descuento_oferta
                                )}
                            </span>
                        </div>
                    )}
                    {/* {pedidoSelected.descuento_oferta > 0 && (
                        <div className="flex justify-between py-3 border-b">
                            <span>Descuento </span>
                            <span>
                                - {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.descuento_oferta
                                )}
                            </span>
                        </div>
                    )} */}

                    {pedidoSelected?.extras?.map((extra) => {
                        return (
                            <div
                                key={extra.id}
                                className="flex justify-between py-3 border-b">
                                <span>{extra.texto}</span>
                                <span>
                                    {pedidoSelected.tipo_moneda} {extra.tipo}{" "}
                                    {numberToCommas(extra.costo_total)}
                                </span>
                            </div>
                        );
                    })}

                    {pedidoSelected.descuento_puntos > 0 && (
                        <div className="flex justify-between py-3 border-b">
                            <span>Descuento Referidos</span>
                            <span>
                                - {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.descuento_puntos
                                )}
                            </span>
                        </div>
                    )}
                    {(pedidoSelected.descuento_bc_cupon > 0 ||
                        pedidoSelected.descuento_propietario_cupon > 0) && (
                        <div className="flex justify-between py-3 border-b">
                            <span>Descuento por cupón</span>
                            <span>
                                - {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.descuento_bc_cupon +
                                        pedidoSelected.descuento_propietario_cupon
                                )}
                            </span>
                        </div>
                    )}
                    {showAlojamientos && (
                        <div className="flex justify-between py-3 border-b">
                            <span>I.G.V.</span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_igv)}
                            </span>
                        </div>
                    )}
                    <div className="flex justify-between py-3 font-bold border-b">
                        <span>Total</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(
                                pedidoSelected.descuento_oferta > 0
                                    ? pedidoSelected.costo_total_alquiler -
                                          pedidoSelected.descuento_oferta
                                    : pedidoSelected.costo_total_alquiler
                            )}
                        </span>
                    </div>
                </div>
                <div className="mt-10">
                    <h1 className="text-xl font-bold">Adicionales</h1>
                    <div className="py-3">
                        <div className="grid grid-cols-[30%_70%] border-b">
                            <div>
                                <span>Garantia</span>
                            </div>
                            <div className="grid grid-cols-[33%_33%_33%] text-right">
                                <span></span>
                                <span></span>
                                <span>
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.costo_garantia
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    {pedidoSelected.serviciosAdicionales && (
                        <div>
                            {pedidoSelected.serviciosAdicionales.map(
                                (service) => (
                                    <div className="py-3" key={service.id}>
                                        <div className="grid grid-cols-[30%_70%] border-b">
                                            <div>
                                                <span>{service.nombre}</span>
                                            </div>
                                            <div className="grid grid-cols-[33%_33%_33%] text-right">
                                                <span></span>
                                                <span></span>
                                                <span>
                                                    {pedidoSelected.tipo_moneda}{" "}
                                                    {numberToCommas(
                                                        service.costo_total
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    {/* {!showAlojamientos && (
                        <div className="flex justify-between py-2 border-b">
                            <span>Costo embarque</span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_embarque)}
                            </span>
                        </div>
                    )} */}
                    <div className="flex justify-between py-3 font-bold border-b">
                        <span>Total</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(
                                pedidoSelected.costo_total_servicios_adicionales +
                                    pedidoSelected.costo_garantia
                                // +
                                // (!showAlojamientos
                                //     ? pedidoSelected.costo_embarque
                                //     : 0)
                            )}
                        </span>
                    </div>
                </div>
                <div className="mt-10">
                    <h1 className="text-xl font-bold">Resumen</h1>
                    <div className="flex justify-between py-3 border-b">
                        <span>Total alquiler </span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(
                                pedidoSelected.descuento_oferta > 0
                                    ? pedidoSelected.costo_total_alquiler -
                                          pedidoSelected.descuento_oferta
                                    : pedidoSelected.costo_total_alquiler
                            )}
                        </span>
                    </div>
                    <div className="flex justify-between py-3 border-b">
                        <span>Total adicionales</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(
                                pedidoSelected.costo_total_servicios_adicionales +
                                    pedidoSelected.costo_garantia
                                // +
                                // (!showAlojamientos &&
                                //     pedidoSelected.costo_embarque)
                            )}
                        </span>
                    </div>
                    <div className="flex justify-between py-3 border-b">
                        <span>Pagado</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(pedidoSelected.pagado)}
                        </span>
                    </div>
                    <div className="flex justify-between py-3 font-bold border-b">
                        <span>Saldo</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(pedidoSelected.saldo)}
                        </span>
                    </div>
                </div>

                {pedidoSelected.costo_asumido_empresa > 0 && (
                    <div className="mt-10">
                        <h1 className="text-xl font-bold">Detalles de pago</h1>
                        <div className="flex justify-between py-3 border-b">
                            <span>El cliente abonará un total de </span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.costo_total -
                                        pedidoSelected.costo_asumido_empresa
                                )}
                            </span>
                        </div>

                        <div className="flex justify-between py-3 border-b">
                            <span>La empresa abonará un total de</span>
                            <span>
                                <span className="flex justify-end mr-1">
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.costo_asumido_empresa
                                    )}
                                </span>
                            </span>
                        </div>
                        <div className="flex justify-between py-3 border-b">
                            <span>Total a abonar </span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_total)}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        );
};

export default DetalleAlquilerCliente;
