import axios from "axios";
export const GET_ALL_ATTRIBUTES = "GET_ALL_ATTRIBUTES";
export const CREATE_ATTRIBUTES = "CREATE_ATTRIBUTES";
export const RESET_CREATE_ATTRIBUTES = "RESET_CREATE_ATTRIBUTES";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const ONE_ATTRIBUTE = "ONE_ATTRIBUTE";
export const RESET_ONE_ATTRIBUTE = "RESET_ONE_ATTRIBUTE";
export const SEARCH_ATTRIBUTES = "SEARCH_ATTRIBUTES";
export const ENABLE_ATTRIBUTE = "ENABLE_ATTRIBUTE";
export const RESET_ENEBLE_ATTRIBUTES = "RESET_ENEBLE_ATTRIBUTES";

const token = localStorage.getItem("token");
const headers = {
  authorization: token,
};

export const createAttribute = (payload) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.post(
      `${process.env.REACT_APP_PROXY}/atributo/`,
      payload,
      { headers }
    );

    return dispatch({
      type: CREATE_ATTRIBUTES,
      payload: json.data.message,
    });
  };
};

export const editAttribute = (id, payload) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.put(
      `${process.env.REACT_APP_PROXY}/atributo/actualizar/${id}`,
      payload,
      { headers }
    );

    return dispatch({
      type: CREATE_ATTRIBUTES,
      payload: json.data.message,
    });
  };
};

export const getAllAttributes = (pageNumber = 1, enabled = "Si") => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.get(
      `${process.env.REACT_APP_PROXY}/atributo/all/${pageNumber}/${enabled}/`,
      { headers }
    );

    return dispatch({
      type: GET_ALL_ATTRIBUTES,
      payload: json.data,
    });
  };
};

export const searchAttributesByName = (enabled, input) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.get(
      `${process.env.REACT_APP_PROXY}/atributo/all/1/${enabled}/?nombre=${input}`,
      { headers }
    );
    return dispatch({
      type: SEARCH_ATTRIBUTES,
      payload: json.data,
    });
  };
};

export const resetCreateAttribute = () => {
  return {
    type: RESET_CREATE_ATTRIBUTES,
  };
};
export const resetEnebleAttribute = () => {
  return {
    type: RESET_ENEBLE_ATTRIBUTES,
  };
};

export const deleteAttribute = (id) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.delete(
      `${process.env.REACT_APP_PROXY}/atributo/eliminar/${id}`,
      { headers }
    );
    return dispatch({
      type: ENABLE_ATTRIBUTE,
      payload: json.data.message,
    });
  };
};
export const enableAttribute = (id) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.put(
      `${process.env.REACT_APP_PROXY}/atributo/restaurar/${id}`,
      {},
      { headers }
    );

    return dispatch({
      type: ENABLE_ATTRIBUTE,
      payload: json.data.message,
    });
  };
};

export const oneAttribute = (id) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.get(
      `${process.env.REACT_APP_PROXY}/atributo/detalle/${id}`,
      { headers }
    );

    return dispatch({
      type: ONE_ATTRIBUTE,
      payload: json.data,
    });
  };
};
export const resetOnAttribute = () => {
  return async function (dispatch) {
    return dispatch({
      type: RESET_ONE_ATTRIBUTE,
      payload: [],
    });
  };
};
