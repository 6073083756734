import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
    createLabel,
    resetCreateLabel,
} from "../../redux/actions/labelsActions";

const ModalCreateLabels = ({ create, setcreate }) => {
    const dispatch = useDispatch();
    const createRedux = useSelector((state) => state.labelsReducer.create);
    const [labels, setlabels] = useState({
        nombre: "",
        casa: "No",
        embarcacion: "No",
    });

    const handleSubmit = () => {
        if (!labels.nombre) {
            Swal.fire({
                title: "Error!",
                text: "La etiqueta debe tener un nombre",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            dispatch(createLabel(labels));
        }
    };
    useEffect(() => {
        if (createRedux === "registro exitoso") {
            setcreate(!create);
            dispatch(resetCreateLabel());

            Swal.fire({
                title: "Procesado!",
                text: "Etiqueta creada",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        }
    }, [createRedux, dispatch]);

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed z-10 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-36 2xl:container 2xl:mx-auto  sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <header className="mb-3 mr-auto text-base font-bold lg:text-2xl">
                            Agregar etiqueta
                        </header>
                        <div className="w-full px-3 rounded-lg border mt-7">
                            <input
                                onChange={(e) =>
                                    setlabels({
                                        ...labels,
                                        nombre: e.target.value,
                                    })
                                }
                                type="text"
                                value={labels.nombre}
                                placeholder="Nombre de la etiqueta"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full px-4 mt-2 flex items-center">
                            <input
                                onClick={() =>
                                    setlabels({
                                        ...labels,
                                        casa:
                                            labels.casa === "No" ? "Si" : "No",
                                    })
                                }
                                type="checkbox"
                                className="flex scale-150 mr-2  checked:bg-blue-bluecorner"
                            />
                            <label className="w-full my-4 text-base text-left border-none outline-none ">
                                Disponible para alojamientos
                            </label>
                        </div>
                        <div className="w-full px-4 flex">
                            <input
                                onClick={() =>
                                    setlabels({
                                        ...labels,
                                        embarcacion:
                                            labels.embarcacion === "No"
                                                ? "Si"
                                                : "No",
                                    })
                                }
                                type="checkbox"
                                className="scale-150  mr-2 checked:bg-blue-bluecorner"
                            />
                            <label className="w-full my-4 text-base text-left border-none outline-none ">
                                Disponible para embarcaciones
                            </label>
                        </div>
                        <div className="w-full px-4">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="pb-3 text-center ">
                                            <button
                                                className="w-full rounded-2xl border-b-4 border-b-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                                                onClick={() => handleSubmit()}>
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => setcreate(!create)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreateLabels;
