import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";

const EditarDescripcionLugar = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
    setRefreshData,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [selectUbication, setSelectUbication] = useState([]);
    const [allTypes, setAllTypes] = useState([]);
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        getProperties();
        tipo_producto === "casa" && getUbication();
    }, []);

    const getUbication = async () => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/productoUbicacion/all/${producto.data.id}`,
            { headers }
        );
        setSelectUbication(data);
    };

    const getProperties = async () => {
        if (tipo_producto === "casa") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/propiedades/select/ubicacion`,
                { headers }
            );
            setAllTypes(json.data);
        }
        if (tipo_producto === "embarcacion") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/propiedades/select/duracion`,
                { headers }
            );
            setAllTypes(json.data);
        }
    };

    const updateProduct = async (payload) => {
        if (tipo_producto === "embarcacion") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/productos/actualizarPropiedades/${payload.id}`,
                payload,
                { headers }
            );
            if (data.message === "registro exitoso") {
                setModal(false);
                setRefreshData((prev) => !prev);
            }
        }
        if (tipo_producto === "casa") {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/productoUbicacion/`,
                payload,
                { headers }
            );
            setModal(false);
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    ubicacion: selectUbication,
                },
            });
        }
    };

    const handleGuardar = () => {
        if (tipo_producto === "casa") {
            if (selectUbication.length === 0) {
                Swal.fire({
                    title: "Error",
                    text: "Debe seleccionar una ubicacion",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
            if (selectUbication.length > 0) {
                const arrayUbicaciones = selectUbication.map((ubi) => ubi.id);
                updateProduct({
                    id_producto: producto.data.id,
                    ubicacion: arrayUbicaciones,
                    id_usuario: authUser.id_usuario,
                });
            }
        }
        if (tipo_producto === "embarcacion") {
            if (producto.data.duracion === "") {
                Swal.fire({
                    title: "Error",
                    text: "Debe seleccionar una duracion",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            } else {
                updateProduct({
                    tipo: "Duracion",
                    id_propiedad: producto.data.id_duracion,
                    id: producto.data.id,
                    id_usuario: authUser.id_usuario,
                });
            }
        }
    };

    return (
        <div className="border p-4 rounded-lg">
            <h1>Descripción del lugar</h1>

            <div className="w-full mt-3 ">
                <div className="">
                    {!allTypes.length ? (
                        <Spinner width={7} margin_top={12} />
                    ) : null}
                    <div style={{}} className=" overflow-y-auto">
                        {allTypes?.map((type) => {
                            return (
                                <div key={type.id} className="w-full p-3">
                                    <div
                                        className={
                                            selectUbication.some(
                                                (item) => item.id === type.id
                                            )
                                                ? "flex justify-center lg:w-1/2 lg:mx-auto items-center text-base py-3 border border-solid border-[#3C3C3C] rounded-lg bg-[#F4F2F1] cursor-pointer"
                                                : "flex justify-center lg:w-1/2 lg:mx-auto items-center text-base  py-3 border border-solid border-[#E9E9E9]  rounded-lg cursor-pointer"
                                        }
                                        onClick={() =>
                                            tipo_producto === "casa"
                                                ? setSelectUbication(
                                                      selectUbication.some(
                                                          (item) =>
                                                              item.id ===
                                                              type.id
                                                      )
                                                          ? selectUbication.filter(
                                                                (item) =>
                                                                    item.id !==
                                                                    type.id
                                                            )
                                                          : [
                                                                ...selectUbication,
                                                                type,
                                                            ]
                                                  )
                                                : setProducto({
                                                      ...producto,
                                                      data: {
                                                          ...producto.data,
                                                          id_duracion: type.id,
                                                      },
                                                  })
                                        }>
                                        <span className="">{type.nombre}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarDescripcionLugar;
