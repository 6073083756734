import React from "react";

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

const getDaysBetween2Dates = (arrayDates) => {
    const [dateOne, dateTwo] = arrayDates;
    const time = Math.abs(dateTwo - dateOne);
    const days = Math.ceil(time / (1000 * 60 * 60 * 24));
    return days;
};

const ReservaDetailed = () => {
    const reserva = JSON.parse(localStorage.getItem("reserva"));
    const fecha_inicio = new Date(reserva.fecha_inicio);
    const fecha_final = new Date(reserva.fecha_final);
    const discountHalf = getDaysBetween2Dates([
        new Date(),
        new Date(reserva.fecha_inicio),
    ]);

    return (
        <div>
            <h1 className=" font-semibold">{reserva.house}</h1>
            <h1 className=" font-semibold">
                Reservado desde:{" "}
                {fecha_inicio.toLocaleDateString("es-ES", options)}
            </h1>
            <h1 className=" font-semibold">
                Reservado hasta:{" "}
                {fecha_final.toLocaleDateString("es-ES", options)}
            </h1>
        </div>
    );
};

export default ReservaDetailed;
