import React from "react";
import NavBar from "../NavBar";
import { Helmet } from "react-helmet";
import Footer from "../Footer";

import campo from "../../img/loyalty/casas_de_campo.webp";

import playa from "../../img/loyalty/casa_de_playa.webp";
import casa from "../../img/referidos/Casa-Federal.webp";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { updateHouseFilters } from "../../redux/actions/filtersActions";
import paramsURLhouses from "../alquilerScreen/alquilerAlojamiento/paramsURLhouses";

export const Referidos = () => {
    const authUser = useSelector((state) => state.authReducer);
    const filters = useSelector((state) => state.filtersReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const params = (filtros) => {
        let url = "";

        url = paramsURLhouses(filtros);

        return url;
    };
    return (
        <div>
            <NavBar />
            <Helmet>
                <link
                    rel="canonical"
                    href="https://example.com/dresses/green-dresses"
                />
                <meta
                    name="description"
                    content={`Blue Corner, Alquileres, Embarcaciones, Alojamientos`}
                />
            </Helmet>
            <div className="flex flex-col mt-28 xs:mt-32 w-5/6  md:w-2/3 mx-auto">
                <div className="w-full text-center  my-10 px-2 ">
                    <h2 className="text-2xl md:text-3xl 2xl:text-4xl font-bold ">
                        Programa Referidos
                    </h2>
                    <p className="mt-5 md:mt-7 text-base md:text-lg 2xl:text-xl ">
                        Por lo bueno se comparte
                    </p>

                    <p className="font-semibold  text-lg md:text-xl 2xl:text-2xl">
                        ¡Gana gratis un full day sin sorteos!
                    </p>

                    <p className="mt-5 md:mt-7 text-base md:text-lg 2xl:text-xl">
                        Recomiéndanos a 5 personas que relicen un alquiler y{" "}
                    </p>
                    <span className="font-semibold text-base md:text-lg 2xl:text-xl">
                        ganarás un full day en la casa Federal
                    </span>
                </div>
                <div
                    onClick={() => navigate("/alojamientos/30329/federal")}
                    className="grid gap-6 relative w-full mx-auto cursor-pointer"
                >
                    <div className="relative">
                        <img
                            className="w-full h-[15rem] md:h-[25rem] 2xl:h-[30rem] object-cover rounded-lg"
                            src={casa}
                            alt="casa"
                        />
                        <div className="absolute bottom-0 xs:top-0 left-0 w-full h-[15rem] md:h-[25rem] 2xl:h-[30rem] rounded-lg bg-white bg-opacity-30 xs:p-2 flex flex-col justify-end">
                            <div className=" w-full sm:w-2/3 md:w-1/2 p-2 mx-10">
                                {" "}
                                <p className=" text-2xl md:text-4xl 2xl:text-5xl text-white font-bold mb-10 underline ">
                                    Conocela aqui
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" flex flex-col items-start justify-start text-base md:text-xl 2xl:text-2xl  px-4 xs:px-10 mt-5 ">
                    <p className="font-semibold ">Es muy simple ganar: </p>

                    <ul className="marker:text-[#053465] list-decimal pl-10 my-4 text-sm md:text-xl 2xl:text-2xl ">
                        <li>
                            Tus referidos deberán dar tu número de DNI a la hora
                            de contactarse con nosotros para un alquiler
                        </li>
                        <li>
                            Una vez que llegas a 5 referidos, podrás canjear el
                            premio!
                        </li>
                    </ul>
                    <a
                        className="mx-auto cursor-pointer hover:scale-105  z-20 md:mt-5"
                        href={`https://wa.me/51941110270?text=""`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="my-4 w-56  md:w-44 sm:w-96 text-sm  md:text-xl 2xl:text-2xl h-12 md:h-16 2xl:h-20 bg-[#25D366] rounded-lg text-white px-2 py-1 text-center ">
                            <p className="">¿Tienes dudas?</p>
                            <p>Escríbenos al Whatsapp</p>
                        </div>
                    </a>
                </div>

                <div className=" text-base md:text-xl 2xl:text-2xl mb-10 mt-5">
                    <div className="mx-2 xs:mx-12">
                        <p className="font-bold">
                            Mira los alojamientos tenemos disponibles para ti
                        </p>
                        <div className="grid grid-cols-2 gap-6 mt-5 relative text-base xs:text-xl ">
                            <div
                                onClick={() => {
                                    dispatch(
                                        updateHouseFilters({
                                            query_value: "Campo",
                                        })
                                    );
                                    navigate(
                                        "/alquiler-de-alojamientos/" +
                                            params({
                                                ...filters.house_filters,
                                                query_value: "Campo",
                                            })
                                    );
                                }}
                                className="relative cursor-pointer hover:scale-105"
                            >
                                <img
                                    className="w-full rounded-lg"
                                    src={campo}
                                    alt="Casa de Campo"
                                />
                                <div className="absolute bottom-0 left-0 w-full bg-white bg-opacity-70 p-2">
                                    <p className="text-[#053465] font-bold">
                                        Casa de Campo
                                    </p>
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    dispatch(
                                        updateHouseFilters({
                                            query_value: "Playa",
                                        })
                                    );
                                    navigate(
                                        "/alquiler-de-alojamientos/" +
                                            params({
                                                ...filters.house_filters,
                                                query_value: "Playa",
                                            })
                                    );
                                }}
                                className="relative cursor-pointer hover:scale-105"
                            >
                                <img
                                    className="w-full rounded-lg"
                                    src={playa}
                                    alt="Casa de Playa"
                                />
                                <div className="absolute bottom-0 left-0 w-full bg-white bg-opacity-70 p-2">
                                    <p className="text-[#053465] font-bold">
                                        Casa de Playa
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
