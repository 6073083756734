import getTotalPrice from "./getTotalPrice";

const getTotalReservaInicial = (
    cantDays,
    selectedDays,
    currentProduct,
    is_padre = false
) => {
    return getTotalPrice(
        selectedDays,
        currentProduct,
        cantDays,
        true,
        false,
        false,
        false,
        true,
        false,
        true,
        is_padre
    );
    //}
};

export default getTotalReservaInicial;
