import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const EditarDescripcion = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
}) => {
    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [descripcion, setDescripcion] = useState(
        producto?.data.descripcion || ""
    );

    const handleInputChange = (e) => {
        setDescripcion(e.target.value);
    };

    const handleGuardar = async () => {
        setModal(false);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${tipo_producto}/actualizarDescripcion/${producto.data.id}`,
            {
                descripcion: descripcion,
                link360: producto.data.link360,
                id_usuario: authUser.id_usuario,
            },
            { headers }
        );
    };

    return (
        <div className="border p-4 rounded-lg">
            <h1>Descripción del anuncio</h1>

            <div className="w-full mt-3 ">
                <textarea
                    onChange={(e) => {
                        handleInputChange(e);
                        setProducto({
                            ...producto,
                            data: {
                                ...producto.data,
                                descripcion: e.target.value,
                            },
                        });
                    }}
                    value={descripcion}
                    type={"text"}
                    placeholder="Descripcion del alojamiento"
                    className="w-full h-32 border my-2 bg-transparent rounded-lg py-3 px-3 outline-none focus:outline-none "
                />
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarDescripcion;
