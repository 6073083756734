import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import "../stripeCheckoutForm.css";
import Swal from "sweetalert2";
import useMercadoPago from "../../../hooks/useMercadoPago";
import MPCardForm from "./MPCardForm";

const INITIAL_STATE = {
    cvc: "",
    cardExpirationMonth: "",
    cardExpirationYear: "",
    focus: "cardNumber",
    cardholderName: "",
    cardNumber: "",
    issuer: "",
};

const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4",
                    },
                },
                invalid: {
                    color: "#9e2146",
                },
            },
        }),
        []
    );

    return options;
};

const MPCheckout = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [pedido, setPedido] = useState(null);
    const [paying, setPaying] = useState(false);
    const navigate = useNavigate();
    const { tipo_producto, idReserva } = useParams();
    const [searchParams] = useSearchParams();
    const tipo_pago = searchParams.get("tipo-de-pago");
    const [cardForm, setCardForm] = useState(INITIAL_STATE);
    // const [resultPayment, setResultPayment] = useState(null);
    const { resultPayment, monto, setMonto } = useMercadoPago();

    const getReserva = async (idReserva) => {
        if (tipo_producto) {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/pedido${
                    tipo_producto === "embarcacion" ? "Embarcacion" : "Casa"
                }/detalle/${idReserva}`,
                { headers }
            );
            console.log(data);
            if (
                data.estado !== "Pago parcial" &&
                data.estado !== "Pendiente de pago"
            )
                navigate(
                    `${
                        tipo_producto === "embarcacion"
                            ? "checkout-embarcacion"
                            : "checkout"
                    }/${idReserva}`
                );
            setPedido(data);
            if (tipo_pago === "pago_completo") {
                setMonto(
                    (data.costo_total + data.costo_total * 0.05).toFixed(2)
                );
            } else {
                setMonto(
                    (
                        data.costo_total_alquiler / 2 +
                        (data.costo_total_alquiler / 2) * 0.05
                    ).toFixed(2)
                );
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (idReserva) getReserva(idReserva);
    }, [idReserva]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setPaying((prev) => !prev);
    };

    const handleInputChange = (e) => {
        setCardForm({
            ...cardForm,
            [e.target.dataset.name || e.target.name]: e.target.value,
        });
    };

    const handleInputFocus = (e) => {
        setCardForm({
            ...cardForm,
            focus: e.target.dataset.name || e.target.name,
        });
    };

    return (
        pedido && (
            <div className="grid grid-cols-1 md723:grid-cols-2">
                {tipo_producto === "embarcacion" ? (
                    <div className="bg-[#6772e5] p-16 md723:py-60 text-white font-semibold md723:h-screen flex flex-col">
                        <button
                            className="font-bold hover:underline mr-auto flex items-center"
                            onClick={() => navigate(-1)}>
                            <svg
                                className="h-8 w-8 text-white mx-3"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <circle cx="12" cy="12" r="10" />
                                <polyline points="12 8 8 12 12 16" />
                                <line x1="16" y1="12" x2="8" y2="12" />
                            </svg>{" "}
                            Volver atrás
                        </button>
                        <div className="ml-auto">
                            <div className="mt-10 mx-auto flex">
                                <div className="flex flex-col">
                                    <p className="text-2xl font-bold">
                                        Nombre del producto
                                    </p>
                                    <span>{pedido?.embarcacion}</span>
                                </div>
                                <div className=" mx-auto">
                                    {tipo_pago === "pago_completo" ? (
                                        <span className="text-4xl font-bold">
                                            {(
                                                pedido?.costo_total +
                                                pedido?.costo_total * 0.05
                                            ).toFixed(2)}{" "}
                                            {pedido?.tipo_moneda}
                                        </span>
                                    ) : (
                                        <span className="text-4xl font-bold">
                                            {pedido?.estado ===
                                                "Pago parcial" && (
                                                <div>
                                                    $
                                                    {(
                                                        pedido?.saldo +
                                                        pedido?.saldo * 0.05
                                                    ).toFixed(2)}{" "}
                                                    {pedido?.tipo_moneda}
                                                </div>
                                            )}
                                            {pedido?.estado ===
                                                "Pendiente de pago" && (
                                                <div>
                                                    $
                                                    {(
                                                        pedido?.costo_total_alquiler /
                                                            2 +
                                                        (pedido?.costo_total_alquiler /
                                                            2) *
                                                            0.05
                                                    ).toFixed(2)}{" "}
                                                    {pedido?.tipo_moneda}
                                                </div>
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="mt-10 mx-auto">
                                <p className="text-2xl font-bold">
                                    Descripción
                                </p>
                                <span className="max-w-[200px]">
                                    Reserva para el día{" "}
                                    {pedido?.fecha.split("T")[0]} a partir de
                                    las {pedido?.hora_entrada} horas hasta las{" "}
                                    {pedido?.hora_salida} horas
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="bg-[#6772e5] p-16 md723:py-60 text-white font-semibold md723:h-screen flex flex-col">
                        <button className="font-bold hover:underline mr-auto flex items-center">
                            <svg
                                className="h-8 w-8 text-white mx-3"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <circle cx="12" cy="12" r="10" />
                                <polyline points="12 8 8 12 12 16" />
                                <line x1="16" y1="12" x2="8" y2="12" />
                            </svg>{" "}
                            Volver atrás
                        </button>
                        <div className="ml-auto">
                            <div className="mt-10 mx-auto flex">
                                <div className="flex flex-col">
                                    <p className="text-2xl font-bold mr-10">
                                        Nombre del producto
                                    </p>
                                    <span>{pedido?.casa}</span>
                                </div>
                                <div className=" mx-auto">
                                    {tipo_pago === "pago_completo" ? (
                                        <span className="text-4xl font-bold">
                                            {(
                                                pedido?.costo_total +
                                                pedido?.costo_total * 0.05
                                            ).toFixed(2)}{" "}
                                            {pedido?.tipo_moneda}
                                        </span>
                                    ) : (
                                        <span className="text-4xl font-bold">
                                            {pedido?.estado ===
                                                "Pago parcial" && (
                                                <div>
                                                    $
                                                    {(
                                                        pedido?.saldo +
                                                        pedido?.saldo * 0.05
                                                    ).toFixed(2)}{" "}
                                                    {pedido?.tipo_moneda}
                                                </div>
                                            )}
                                            {pedido?.estado ===
                                                "Pendiente de pago" && (
                                                <div>
                                                    $
                                                    {(
                                                        pedido?.costo_total_alquiler /
                                                            2 +
                                                        (pedido?.costo_total_alquiler /
                                                            2) *
                                                            0.05
                                                    ).toFixed(2)}{" "}
                                                    {pedido?.tipo_moneda}
                                                </div>
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="mt-10 mx-auto">
                                <p className="text-2xl font-bold">
                                    Descripción
                                </p>
                                <span className="max-w-[200px]">
                                    Reserva desde el día{" "}
                                    {pedido?.fecha_inicio.split("T")[0]} hasta
                                    el día {pedido?.fecha_final.split("T")[0]}.
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="bg-white p-16 md723:py-60 font-semibold sm:h-screen">
                    <div className="container">
                        <form id="form-checkout">
                            <div className="form-control">
                                <input
                                    type="tel"
                                    name="cardNumber"
                                    id="form-checkout__cardNumber"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                            </div>
                            <div className="form-control">
                                <input
                                    type="tel"
                                    name="cardExpirationMonth"
                                    id="form-checkout__cardExpirationMonth"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                                <input
                                    type="tel"
                                    name="cardExpirationYear"
                                    id="form-checkout__cardExpirationYear"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                                <input
                                    type="tel"
                                    name="cvc"
                                    id="form-checkout__securityCode"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                            </div>
                            <div className="form-control">
                                <input
                                    type="text"
                                    name="cardholderName"
                                    id="form-checkout__cardholderName"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                />
                                <input
                                    type="email"
                                    name="cardholderEmail"
                                    id="form-checkout__cardholderEmail"
                                    onFocus={handleInputFocus}
                                />
                            </div>
                            <div className="form-control">
                                <select
                                    name="issuer"
                                    id="form-checkout__issuer"
                                    on></select>
                                <select
                                    name="identificationType"
                                    id="form-checkout__identificationType"></select>
                            </div>
                            <div className="form-control">
                                <input
                                    type="text"
                                    name="identificationNumber"
                                    id="form-checkout__identificationNumber"
                                />
                            </div>
                            <div className="form-control">
                                <select
                                    name="installments"
                                    id="form-checkout__installments"></select>
                            </div>
                            <div className="form-control">
                                <button
                                    type="submit"
                                    id="form-checkout__submit">
                                    Pagar
                                </button>
                            </div>
                            <progress value="0" className="progress-bar">
                                Cargando...
                            </progress>
                        </form>
                        {resultPayment && (
                            <p>{JSON.stringify(resultPayment)}</p>
                        )}
                    </div>
                    {/* <form onSubmit={handleSubmit} className="formStripe">
                        <div className="flex flex-col ">
                            <label className="labelStripe">Email</label>
                            <input
                                type="email"
                                onChange={handleInputChange}
                                className="StripeElement"
                                placeholder="bluecorner@bluecorner.com"
                            />
                        </div>
                        <div className="flex-col flex">
                            <label className="labelStripe">
                                Numero de tarjeta
                                <CardNumberElement
                                    options={options}
                                    onReady={() => {
                                        console.log(
                                            "CardNumberElement [ready]"
                                        );
                                    }}
                                    onChange={(event) => {
                                        console.log(
                                            "CardNumberElement [change]",
                                            event
                                        );
                                    }}
                                    onBlur={() => {
                                        console.log("CardNumberElement [blur]");
                                    }}
                                    onFocus={() => {
                                        console.log(
                                            "CardNumberElement [focus]"
                                        );
                                    }}
                                />
                            </label>
                            <div className="flex ">
                                <label className="labelStripe max-w-[250px]">
                                    <CardExpiryElement
                                        options={options}
                                        onReady={() => {
                                            console.log(
                                                "CardNumberElement [ready]"
                                            );
                                        }}
                                        onChange={(event) => {
                                            console.log(
                                                "CardNumberElement [change]",
                                                event
                                            );
                                        }}
                                        onBlur={() => {
                                            console.log(
                                                "CardNumberElement [blur]"
                                            );
                                        }}
                                        onFocus={() => {
                                            console.log(
                                                "CardNumberElement [focus]"
                                            );
                                        }}
                                    />
                                </label>
                                <label className="labelStripe max-w-[250px]">
                                    <CardCvcElement
                                        options={options}
                                        onReady={() => {
                                            console.log(
                                                "CardNumberElement [ready]"
                                            );
                                        }}
                                        onChange={(event) => {
                                            console.log(
                                                "CardNumberElement [change]",
                                                event
                                            );
                                        }}
                                        onBlur={() => {
                                            console.log(
                                                "CardNumberElement [blur]"
                                            );
                                        }}
                                        onFocus={() => {
                                            console.log(
                                                "CardNumberElement [focus]"
                                            );
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col ">
                            <label className="labelStripe">
                                Nombre en la tarjeta
                            </label>
                            <input
                                type="text"
                                onChange={handleInputChange}
                                className="StripeElement"
                                placeholder="Nombre en la tarjeta"
                            />
                        </div>
                        <button
                            type="submit"
                            className="payButton w-full disabled:bg-slate-400 hover:disabled:bg-slate-400"
                            disabled={!stripe || paying}>
                            Pagar
                        </button>
                    </form> */}
                </div>
            </div>
        )
    );
};

export default MPCheckout;
