import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import ModalCreateManual from "./ModalCreateManual";
import ModalEditManual from "./ModalEditManual";
import SearchBar from "./SearchBar";
import Spinner from "../../Spinner";

const token = localStorage.getItem("token");
const headers = {
    authorization: token,
};

const AllManuals = () => {
    const [modalCreateVisible, setModalCreateVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [showButtons, setShowButtons] = useState(false);
    const [habilitados, setHabilitados] = useState(true);
    const [allManuals, setAllManuals] = useState({
        checking: true,
        manuals: [],
    });
    const [manualSelected, setManualSelected] = useState({});

    const getAllManuals = async (habilitado, query = "") => {
        setAllManuals({
            checking: true,
            manuals: [],
        });
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/Manual/all/${habilitado}?nombre=${query}`,
            { headers }
        );

        setAllManuals({
            checking: false,
            manuals: data,
        });
    };

    useEffect(() => {
        getAllManuals("Si");
    }, []);

    useEffect(() => {
        if (habilitados) getAllManuals("Si");
        else getAllManuals("No");
    }, [habilitados]);

    const handleDelete = async (idManual) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el manual a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/manual/eliminar/${idManual}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Manual eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllManuals("Si");
                    });
            }
        });
    };

    const handleEnable = (idManual) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el manual  a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/manual/restaurar/${idManual}`,
                        {},
                        { headers }
                    )
                    .then((reponse) => {
                        Swal.fire({
                            title: "Manual restablecido correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllManuals("No");
                    });
            }
        });
    };

    const handleEdit = (manual) => {
        setManualSelected(manual);
        setModalEditVisible(!modalEditVisible);
    };

    return (
        <section className=" w-full md:pl-[13rem] md818:pl-[14rem] md920:pl-[14rem] lg:pl-[17rem] p-4">
            <div className="flex items-center justify-between w-full ">
                <h1 className="py-10 text-4xl  text-center">Manuales</h1>
                <div className="mt-2 md:ml-10">
                    <button
                        className="flex w-8 h-8 md:w-auto md:h-auto items-center justify-center p-2 text-blue-500 border border-blue-500 rounded-lg group hover:bg-blue-500 hover:text-white"
                        onClick={() =>
                            setModalCreateVisible(!modalCreateVisible)
                        }>
                        <svg
                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        <h1 className="hidden md:flex">Añadir manual</h1>
                    </button>
                </div>
            </div>
            {/* <button
                className="border-blue-bluecorner text-blue-bluecorner p-3 border rounded-md w-full "
                onClick={() => console.log("Creando categoria de manual...")}>
                Crear categoría de manual
            </button> */}
            <div className="flex items-center md:p-4 justify-end w-full mt-6 md:mt-0 md:ml-5">
                <SearchBar
                    habilitados={habilitados}
                    getAllManuals={getAllManuals}
                />
                <button className="flex justify-center items-center border ml-5 w-8 h-8 md:w-11 md:h-11 rounded-lg border-blue-bluecorner relative">
                    <svg
                        onClick={() => setShowButtons((prev) => !prev)}
                        className="h-7 w-7 md:h-8 md:w-8 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                        />
                    </svg>
                    {showButtons && (
                        <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                            <div
                                onClick={() => {
                                    setHabilitados(true);
                                    setShowButtons((prev) => !prev);
                                    setPage(1);
                                }}
                                className=" w-32 h-11 mx-auto cursor-pointer
                                rounded-lg flex items-center justify-start p-1
                                ">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Habilitados
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    setHabilitados(false);
                                    setShowButtons((prev) => !prev);
                                    setPage(1);
                                }}
                                className=" w-32 h-11 mx-auto cursor-pointer
                                rounded-lg  flex items-center justify-start p-1
                                ">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Deshabilitados
                                </p>
                            </div>
                        </div>
                    )}
                </button>
            </div>
            {/* <div className="container flex items-center justify-between p-1 mx-auto ">
                <button
                    className={
                        habilitados
                            ? "inline-block p-2 mx-3 text-green-700 border border-green-500 rounded group hover:bg-green-700 hover:text-white"
                            : "inline-block p-2 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white"
                    }
                    onClick={() => {
                        setHabilitados(!habilitados);
                        setPage(1);
                    }}>
                    {habilitados
                        ? `Habilitados (${allManuals.manuals.length})`
                        : `Deshabilitados (${allManuals.manuals.length})`}
                </button>
            </div> */}
            <ModalCreateManual
                modalVisible={modalCreateVisible}
                setmodalVisible={setModalCreateVisible}
                getAllManuals={getAllManuals}
                actualPage={page}
            />
            {Object.keys(manualSelected).length > 0 && (
                <ModalEditManual
                    setModalEditVisible={setModalEditVisible}
                    modalEditVisible={modalEditVisible}
                    getAllManuals={getAllManuals}
                    manualSelected={manualSelected}
                    setManualSelected={setManualSelected}
                />
            )}
            <div className="flex justify-center items-center lg:py-[10px] ">
                <div className="flex text-center max-w-full overflow-x-auto w-full">
                    {allManuals?.checking ? (
                        <Spinner />
                    ) : (
                        <div className="w-full">
                            <table className="mt-14 w-full overflow-x-auto">
                                <thead className="text-left">
                                    <tr>
                                        <th className="px-4 w-full min-w-[8rem] md:w-1/6 ">
                                            Nombre
                                        </th>
                                        <th className="w-2/6 min-w-[10rem] hidden md:table-cell">
                                            Descripcion
                                        </th>
                                        <th className="w-2.5/6 min-w-[10rem] hidden md:table-cell">
                                            Archivo Adjunto
                                        </th>
                                        <th className="w-2.5/6 min-w-[10rem] hidden md:table-cell">
                                            Tipos asignados
                                        </th>
                                        <th
                                            align="center"
                                            className="w-[8%] min-w-[10rem] ">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-left">
                                    {allManuals.manuals.map((category) => {
                                        return (
                                            <tr
                                                key={category.id}
                                                className="border-t w-full">
                                                <td className="p-5 flex">
                                                    {category.nombre}
                                                </td>
                                                <td className="hidden md:table-cell">
                                                    {category.descripcion}
                                                </td>
                                                <td className="hidden md:table-cell">
                                                    {category.pdf.split("_")[1]}
                                                </td>
                                                <td className="hidden md:table-cell">
                                                    {category.tipos_manual?.map(
                                                        (tipo, index) => (
                                                            <span key={index}>
                                                                {
                                                                    tipo.tipo_manual
                                                                }
                                                                .
                                                                <br />
                                                            </span>
                                                        )
                                                    )}
                                                </td>
                                                <td>
                                                    {habilitados ? (
                                                        <div className="flex justify-center ml-9">
                                                            <button
                                                                className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                title="Editar"
                                                                onClick={() =>
                                                                    handleEdit(
                                                                        category
                                                                    )
                                                                }>
                                                                <svg
                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    {" "}
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                    />{" "}
                                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                                    <line
                                                                        x1="16"
                                                                        y1="5"
                                                                        x2="19"
                                                                        y2="8"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <button
                                                                className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        category.id
                                                                    )
                                                                }
                                                                title="Eliminar">
                                                                <svg
                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    {" "}
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                    />{" "}
                                                                    <line
                                                                        x1="4"
                                                                        y1="7"
                                                                        x2="20"
                                                                        y2="7"
                                                                    />{" "}
                                                                    <line
                                                                        x1="10"
                                                                        y1="11"
                                                                        x2="10"
                                                                        y2="17"
                                                                    />{" "}
                                                                    <line
                                                                        x1="14"
                                                                        y1="11"
                                                                        x2="14"
                                                                        y2="17"
                                                                    />{" "}
                                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className="flex justify-center ml-9">
                                                            <button
                                                                onClick={() =>
                                                                    handleEnable(
                                                                        category.id
                                                                    )
                                                                }
                                                                title="Habilitar"
                                                                className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                <svg
                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    {" "}
                                                                    <polyline points="17 1 21 5 17 9" />{" "}
                                                                    <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                    <polyline points="7 23 3 19 7 15" />{" "}
                                                                    <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <hr className=" mx-auto border-white mt-12 pl-4 xs:hidden "></hr>
            </div>
        </section>
    );
};

export default AllManuals;
