import React, { useEffect, useState } from "react";
import numberToCommas from "../../helpers/numberToCommas";
import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Spinner from "../../Spinner";

const DetalleAlquilerBC = ({ pedido_id, showAlojamientos }) => {
    const [pedidoSelected, setPedidoSelected] = useState(null);
    const { headers } = useHeaders();

    const getPedido = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/${
                showAlojamientos ? "pedidoCasa" : "pedidoEmbarcacion"
            }/detalle/${pedido_id}`,
            { headers }
        );

        setPedidoSelected(data);
    };

    useEffect(() => {
        getPedido();
    }, []);

    const getTotalFeeCliente = () => {
        let total_fee_cliente =
            pedidoSelected.costo_fee_bc - pedidoSelected.descuento_empresa;

        if (showAlojamientos) {
            pedidoSelected.extras.forEach(
                (extra) => (total_fee_cliente += extra.costo_fee_cliente)
            );
        }

        return total_fee_cliente;
    };

    const getTotalFeePropietario = () => {
        let total_fee_propietario = pedidoSelected.costo_fee_propietario;

        if (showAlojamientos) {
            pedidoSelected.extras.forEach(
                (extra) =>
                    (total_fee_propietario += extra.costo_fee_propietario)
            );
        }
        return total_fee_propietario;
    };

    const getSubTotal = () => {
        let subtotal = pedidoSelected.costo_alquiler;

        if (showAlojamientos) {
            pedidoSelected.extras.forEach((extra) => (subtotal += extra.costo));

            // subtotal -= pedidoSelected.descuento_puntos;
        }

        if (showAlojamientos) {
            subtotal -=
                pedidoSelected.descuento_bc_cupon +
                pedidoSelected.descuento_propietario_cupon;
        }

        subtotal += pedidoSelected.costo_igv;

        // subtotal -= pedidoSelected.descuento_empresa;

        return subtotal;
    };
    console.log(pedidoSelected);
    if (!pedidoSelected) {
        return <Spinner />;
    } else
        return (
            <div className=" md:mt-14 pb-28 2xl:10/12 mx-auto">
                <div className="mt-5">
                    <h1 className="text-xl font-bold">Alquiler</h1>
                    <div className="grid grid-cols-[30%_70%] py-3 border-b">
                        <div>
                            <span></span>
                        </div>
                        <div className="grid grid-cols-[33%_33%_33%] text-right font-bold">
                            <span>Fee cliente</span>
                            <span>Fee propietario</span>
                            <span>Costo total</span>
                        </div>
                    </div>

                    <div className="grid grid-cols-[30%_70%] border-b py-3">
                        <div>
                            <span>Costo</span>
                        </div>
                        <div className="grid grid-cols-[33%_33%_33%] text-right">
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.descuento_puntos
                                        ? pedidoSelected.costo_fee_bc
                                        : pedidoSelected.costo_fee_bc
                                )}
                            </span>
                            <span>
                                {" "}
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.costo_fee_propietario
                                )}
                            </span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_alquiler)}
                            </span>
                        </div>
                    </div>

                    {pedidoSelected.descuento_empresa > 0 && (
                        <div className="grid grid-cols-[30%_70%] border-b py-3">
                            <span>Descuento empresa</span>
                            <div className="grid grid-cols-[33%_33%_33%] text-right">
                                <span className="">
                                    {pedidoSelected.tipo_moneda}
                                    {" - "}
                                    {numberToCommas(
                                        pedidoSelected.descuento_empresa
                                    )}
                                </span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    )}

                    {pedidoSelected?.extras?.map((extra) => {
                        return (
                            <div key={extra.id} className="">
                                <div className="grid grid-cols-[30%_70%] border-b py-3">
                                    <div>
                                        <span>{extra.texto}</span>
                                    </div>
                                    <div className="grid grid-cols-[33%_33%_33%] text-right">
                                        <span>
                                            {pedidoSelected.tipo_moneda}{" "}
                                            {numberToCommas(
                                                extra.costo_fee_cliente
                                            )}
                                        </span>
                                        <span>
                                            {pedidoSelected.tipo_moneda}{" "}
                                            {numberToCommas(
                                                extra.costo_fee_propietario
                                            )}
                                        </span>
                                        <span>
                                            {pedidoSelected.tipo_moneda}{" "}
                                            {numberToCommas(extra.costo)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    {showAlojamientos && (
                        <div className="flex justify-between py-3 border-b">
                            <span>I.G.V.</span>
                            <span className="mr-1">
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_igv)}
                            </span>
                        </div>
                    )}

                    <div className="border-[3px] border-gray-600 p-2 border-dotted">
                        <div className="grid grid-cols-[30%_70%] border-b py-3">
                            <div>
                                <span className="font-bold">Subtotal</span>
                            </div>
                            <div className="grid grid-cols-[33%_33%_33%] text-right">
                                <span>
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(getTotalFeeCliente())}
                                </span>
                                <span>
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(getTotalFeePropietario())}
                                </span>
                                <span>
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(getSubTotal())}
                                </span>
                            </div>
                        </div>
                        {pedidoSelected.descuento_bc_cupon > 0 && (
                            <div className="flex justify-between py-3 border-b">
                                <span className="font-bold">
                                    Descuento Bluecorner por cupón
                                </span>
                                <span>
                                    - {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.descuento_bc_cupon
                                    )}
                                </span>
                            </div>
                        )}
                        {pedidoSelected.descuento_puntos > 0 && (
                            <div className="flex justify-between py-3 border-b">
                                <span className="font-bold">
                                    Descuento Referidos
                                </span>

                                <span>
                                    - {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.descuento_puntos
                                    )}
                                </span>
                            </div>
                        )}
                        {pedidoSelected.descuento_propietario_cupon > 0 && (
                            <div className="flex justify-between py-3 border-b">
                                <span className="font-bold">
                                    Descuento Propietario por cupón
                                </span>
                                <span>
                                    - {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.descuento_propietario_cupon
                                    )}
                                </span>
                            </div>
                        )}

                        {pedidoSelected.descuento_oferta > 0 && (
                            <div className="flex justify-between py-3 border-b">
                                <span className="font-bold">
                                    Descuento oferta
                                </span>
                                <span>
                                    - {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.descuento_oferta
                                    )}
                                </span>
                            </div>
                        )}

                        <div className="grid grid-cols-[30%_70%] border-b py-3">
                            <div>
                                <span className="font-bold">Total</span>
                            </div>
                            <div className="grid grid-cols-[33%_33%_33%] text-right">
                                <span></span>
                                <span></span>
                                <span>
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.descuento_oferta > 0
                                            ? pedidoSelected.costo_total_alquiler -
                                                  pedidoSelected.descuento_oferta
                                            : pedidoSelected.costo_total_alquiler
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <h1 className="text-xl font-bold">Adicionales</h1>
                    <div className="grid grid-cols-[30%_70%] py-3 border-b">
                        <div>
                            <span></span>
                        </div>
                        <div className="grid grid-cols-[25%_25%_25%_25%] text-right font-bold">
                            <span>Fee de Blue corner</span>
                            <span>Costo</span>
                            <span>IGV</span>
                            <span>Costo total</span>
                        </div>
                    </div>

                    <div className="py-3">
                        <div className="grid grid-cols-[30%_70%] border-b items-center">
                            <span className="py-2">Garantia</span>
                            <div className="grid grid-cols-[33%_33%_33%] text-right">
                                <span></span>
                                <span></span>
                                <span>
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.costo_garantia
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* {!showAlojamientos && (
                        <div className="grid grid-cols-[30%_70%] border-b py-3">
                            <span>Costo embarque</span>
                            <span className="flex justify-end mr-1">
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_embarque)}
                            </span>
                        </div>
                    )} */}
                    {pedidoSelected.serviciosAdicionales && (
                        <div>
                            {pedidoSelected.serviciosAdicionales.map(
                                (service) => (
                                    <div className="py-3" key={service.id}>
                                        <div className="grid grid-cols-[30%_70%] border-b">
                                            <div>
                                                <span>{service.nombre}</span>
                                            </div>
                                            <div className="grid grid-cols-[25%_25%_25%_25%] text-right">
                                                <span>
                                                    {pedidoSelected.tipo_moneda}{" "}
                                                    {numberToCommas(
                                                        service.costo_fee_bc
                                                    )}
                                                </span>
                                                <span>
                                                    {" "}
                                                    {
                                                        pedidoSelected.tipo_moneda
                                                    }{" "}
                                                    {numberToCommas(
                                                        service.costo
                                                    )}
                                                </span>

                                                <span>
                                                    {" "}
                                                    {
                                                        pedidoSelected.tipo_moneda
                                                    }{" "}
                                                    {service.costo_igv
                                                        ? numberToCommas(
                                                              service?.costo_igv
                                                          )
                                                        : 0}
                                                </span>
                                                <span>
                                                    {pedidoSelected.tipo_moneda}{" "}
                                                    {numberToCommas(
                                                        service.costo_total
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    <div className="flex justify-between py-3 font-bold border-b">
                        <span>Total</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(
                                pedidoSelected.costo_total_servicios_adicionales +
                                    pedidoSelected.costo_garantia
                                //  +
                                // (!showAlojamientos
                                //     ? pedidoSelected.costo_embarque
                                //     : 0)
                            )}
                        </span>
                    </div>
                </div>
                <div className="mt-10">
                    <h1 className="text-xl font-bold">Resumen</h1>
                    <div className="flex justify-between py-3 border-b">
                        <span>Total alquiler </span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(
                                pedidoSelected.descuento_oferta > 0
                                    ? pedidoSelected.costo_total_alquiler -
                                          pedidoSelected.descuento_oferta
                                    : pedidoSelected.costo_total_alquiler
                            )}
                        </span>
                    </div>

                    <div className="flex justify-between py-3 border-b">
                        <span>Total adicionales</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(
                                pedidoSelected.costo_total_servicios_adicionales +
                                    pedidoSelected.costo_garantia +
                                    (!showAlojamientos
                                        ? pedidoSelected.costo_embarque
                                        : 0)
                            )}
                        </span>
                    </div>
                    <div className="flex justify-between py-3 border-b">
                        <span>Pagado</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(pedidoSelected.pagado)}
                        </span>
                    </div>
                    <div className="flex justify-between py-3 font-bold border-b">
                        <span>Saldo</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(pedidoSelected.saldo)}
                        </span>
                    </div>
                </div>

                {pedidoSelected.costo_asumido_empresa > 0 && (
                    <div className="mt-10">
                        <h1 className="text-xl font-bold">Detalles de pago</h1>
                        <div className="flex justify-between py-3 border-b">
                            <span>El cliente abonará un total de </span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.costo_total -
                                        pedidoSelected.costo_asumido_empresa
                                )}
                            </span>
                        </div>

                        <div className="flex justify-between py-3 border-b">
                            <span>La empresa abonará un total de</span>
                            <span>
                                <span className="flex justify-end mr-1">
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.costo_asumido_empresa
                                    )}
                                </span>
                            </span>
                        </div>
                        <div className="flex justify-between py-3 border-b">
                            <span>Total a abonar </span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_total)}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        );
};

export default DetalleAlquilerBC;
