import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
    clearUserSelected,
    getUsersPerPage,
} from "../../../redux/actions/usersActions";

const ModalEditPassword = ({
    tipo,
    id,
    setIdSelect,
    modalPasswordVisible,
    setModalPasswordVisible,
    user = { nombre: "" },
    actualPage,
    getAllSubPropietario,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [input, setInput] = useState({
        new_password: "",
    });
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (input.new_password !== "") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}${
                    tipo === "subAdmin"
                        ? "/subpropietario/actualizarPasswordByAdmin/"
                        : "/autenticacion/actualizarPasswordByAdmin/"
                }${tipo === "subAdmin" ? id : user.id_usuario}`,
                {
                    password: input.new_password,
                },
                { headers }
            );
            if (data.message === "actuaizacion exitoso") {
                Swal.fire({
                    title: "Contraseña actualizada correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                if (tipo !== "subAdmin") {
                    dispatch(getUsersPerPage(actualPage, "Si"));
                    dispatch(clearUserSelected());
                    setModalPasswordVisible(!modalPasswordVisible);
                } else {
                    getAllSubPropietario();
                    setModalPasswordVisible(!modalPasswordVisible);
                    setIdSelect(0);
                }
            } else {
                Swal.fire({
                    title: "Error intente nuevamente",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        } else {
            Swal.fire({
                title: "La contraseña no puede estar vacia",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    const handleClose = () => {
        setModalPasswordVisible(!modalPasswordVisible);
        dispatch(clearUserSelected());
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalPasswordVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-24 2xl:container 2xl:mx-auto md:px-28 sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-4 py-16 bg-white rounded-lg w-96 lg:w-6/12 dark:bg-white md:px-24 xl:py-12 xl:px-24">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mb-3 font-bold mr-auto">
                            Cambiar contraseña
                        </header>
                        <div className="flex flex-col items-center justify-start w-full">
                            <label className="p-2 font-semibold text-left w-full ">
                                Nueva contraseña:{" "}
                            </label>
                            <input
                                type="text"
                                name="new_password"
                                onChange={handleInputChange}
                                placeholder="Ingresa tu nueva contraseña"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <button
                            className="w-full rounded-md bg-blue-500 py-3 text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                            onClick={handleUpdate}>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditPassword;
