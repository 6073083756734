import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";
import { Link } from "react-router-dom";
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import formatedDate_YYYY_MM_DD from "../../helpers/formatedDate_YYYY_MM_DD";
//STRIPE
import { loadStripe } from "@stripe/stripe-js";
import ModalLogeo from "../../alquilerScreen/alquilerAlojamiento/ModalLogeo";
import feeBC from "../../helpers/feeBC";
import PagoCompletoEmbarcacion from "./PagoCompletoEmbarcacion";
import PagoParcialEmbarcaciones from "./PagoParcialEmbarcaciones";
import Swal from "sweetalert2";
import priceToMonedaElegida from "../../helpers/priceToMonedaElegida";
import colores from "../../helpers/colores";
import numberToCommas from "../../helpers/numberToCommas";

const CheckoutEmbarcaciones = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const { idReserva } = useParams();
    const [ticketVisible, setTicketVisible] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [pagoCompleto, setPagoCompleto] = useState("pago_completo");
    const [paying, setPaying] = useState(false);
    const [formaPago, setFormaPago] = useState("stripe");
    const [cuponCargandoText, setCuponCargandoText] = useState("Aplicar cupón");
    const [costoAsumidoEmpresa, setCostoAsumidoEmpresa] = useState(null);
    const [idcuponEmpresa, setIdcuponEmpresa] = useState("");

    const [totalCostoAsumidoEmpresa, setTotalCostoAsumidoEmpresa] =
        useState(null);
    const [descuentoCupon, setDescuentoCupon] = useState(null);
    const [cupon, setCupon] = useState("");
    const reserva = JSON.parse(localStorage.getItem("reserva-embarcacion"));
    const [pedido, setPedido] = useState({});
    const authUser = useSelector((state) => state.authReducer);
    const PRECIO_SOLES = 4.1;
    const navigate = useNavigate();
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );
    const [habilitarModal, setHabilitarModal] = useState(false);
    const [modalLogeo, setModalLogeo] = useState(false);
    const [comprobantePreview, setComprobantePreview] = useState(null);
    const [comprobante, setComprobante] = useState(null);
    const form = new FormData();

    const getReserva = async (idReserva) => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/pedidoEmbarcacion/detalle/${idReserva}`,
            { headers }
        );

        setPedido(data);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (idReserva) getReserva(idReserva);
    }, [idReserva]);
    console.log("reserva", reserva);

    const getTotalCostoAsumidoEmpresa = (costo_total) => {
        let total_costo_asumido = 0;

        if (costoAsumidoEmpresa) {
            const cantidad_cupon = costoAsumidoEmpresa.costo_asumido;
            //CASO 1: CUPON ES MUY GRANDE SE ADAPTA AL COSTO TOTAL, EL CLIENTE NO ABONA NADA
            if (cantidad_cupon >= costo_total) {
                total_costo_asumido = costo_total;
            } else if (cantidad_cupon < costo_total) {
                total_costo_asumido = cantidad_cupon;
            }
        }

        return total_costo_asumido;
    };

    const handleInputChange = (e) => setCupon(e.target.value);

    const handleReserva = async () => {
        if (authUser.logged === false) {
            setHabilitarModal(true);
            setModalLogeo(true);
            return;
        }
        if (!idReserva) {
            const token = localStorage.getItem("token");
            const headers = {
                authorization: token,
            };
            const array_servicios_adicionales = [];

            setTermsAccepted((prev) => !prev);

            let total_servicios_adicionales = 0;

            reserva?.aditional_services.forEach(
                (service) =>
                    (total_servicios_adicionales += priceToMonedaElegida(
                        (service.precio / (1 - reserva.fee_cliente / 100)) *
                            1.18,
                        service.tipo_moneda
                    ))
            );

            const costo_total =
                Number(reserva?.totalPrice) -
                reserva.descuento_oferta -
                (descuentoCupon
                    ? descuentoCupon.descuento_bc_cupon +
                      descuentoCupon.descuento_propietario_cupon
                    : 0);

            const costo_total_alquiler = (
                reserva?.initialReserva - reserva?.descuento_empresa
            ).toFixed(2);

            //--------------------SERVICIOS ADICIONALES-------------------------//

            reserva?.aditional_services.forEach((service) => {
                array_servicios_adicionales.push({
                    nombre: service.nombre,
                    costo: priceToMonedaElegida(
                        service.precio,
                        service.tipo_moneda
                    ).toFixed(2),
                    costo_fee_bc: (
                        priceToMonedaElegida(
                            service.precio / (1 - reserva.fee_cliente / 100),
                            service.tipo_moneda
                        ) -
                        priceToMonedaElegida(
                            service.precio,
                            service.tipo_moneda
                        )
                    ).toFixed(2),
                    costo_igv: (
                        priceToMonedaElegida(
                            (service.precio / (1 - reserva.fee_cliente / 100)) *
                                1.18,
                            service.tipo_moneda
                        ) -
                        priceToMonedaElegida(
                            service.precio / (1 - reserva.fee_cliente / 100),
                            service.tipo_moneda
                        )
                    ).toFixed(2),
                    costo_total: priceToMonedaElegida(
                        (service.precio / (1 - reserva.fee_cliente / 100)) *
                            1.18,
                        service.tipo_moneda
                    ).toFixed(2),
                });

                console.log(reserva.fee_cliente, array_servicios_adicionales);
            });

            //--------------------SERVICIOS ADICIONALES-------------------------//
            let id_referido = 0;

            if (reserva.clientReferido) {
                id_referido = reserva.clientReferido.id;
            }
            const reservaFinal = {
                realizado_bc: reserva.realizado_bc,
                id_cliente:
                    reserva.realizado_bc === "Si"
                        ? reserva.realizado_bc_cliente.id_cliente
                        : authUser.id_usuario,
                correo_cliente:
                    reserva.realizado_bc === "Si"
                        ? reserva.realizado_bc_cliente.correo_cliente
                        : authUser.correo,
                tipo_usuario_cliente:
                    reserva.realizado_bc === "Si"
                        ? reserva.realizado_bc_cliente.tipo_cliente
                        : authUser.tipo_usuario,

                id_embarcacion: reserva?.id_casa,
                id_duracion: reserva?.selected_turno.id_duracion,

                nombre_embarcacion: reserva?.boat,
                fecha: formatedDate_YYYY_MM_DD(new Date(reserva?.date.date)),
                hora_entrada: reserva?.hora_inicio,
                hora_salida: reserva?.hora_final,
                turno: reserva?.numero_turno,
                costo_alquiler: reserva?.priceOnlyAlquiler,
                costo_fee_bc: reserva?.feeBC_cliente,
                costo_fee_propietario: reserva?.feeBC_propietario,
                costo_total_alquiler:
                    Number(costo_total_alquiler) -
                    (descuentoCupon
                        ? descuentoCupon?.descuento_bc_cupon +
                          descuentoCupon?.descuento_propietario_cupon
                        : 0),
                costo_total_servicios_adicionales: Number(
                    total_servicios_adicionales
                ),
                costo_garantia: reserva?.warranty,
                costo_tarjeta: 0,
                pagado: 0,
                saldo: costo_total,
                costo_total: costo_total,
                tipo_moneda: reserva?.coin_base,
                servicios_adicionales: array_servicios_adicionales,
                id_cupon: descuentoCupon ? descuentoCupon?.id : 0,
                descuento_propietario_cupon: descuentoCupon
                    ? descuentoCupon.descuento_propietario_cupon
                    : 0,
                descuento_bc_cupon: descuentoCupon
                    ? descuentoCupon.descuento_bc_cupon
                    : 0,
                costo_asumido_empresa: getTotalCostoAsumidoEmpresa(costo_total),
                descuento_empresa: (reserva?.descuento_empresa).toFixed(2),
                descuento_oferta: reserva?.descuento_oferta,
                id_promocion_empresa: idcuponEmpresa,
                id_referido,
            };

            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/pedidoEmbarcacion`,
                reservaFinal,
                { headers }
            ); // GENERO LA RESERVA EN LA DB

            localStorage.removeItem("reserva-embarcacion");
            navigate("/comprobando-disponibilidad");
        }
    };

    const handleComprobante = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        setComprobante(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setComprobantePreview(objectUrl);
    };

    const handlePagoComprobante = async () => {
        setPaying(true);
        let costo_tarjeta = 0;
        let costo = 0;
        let estado = 0;
        let metodo = 0;
        let file = 0;

        if (pagoCompleto === "pago_parcial") {
            costo_tarjeta = 0;
            costo = (pedido?.pagos.primer_pago.monto).toFixed(2);
            estado = "Pago parcial";
            metodo = "Transferencia bancaria";
            file = comprobante;
        } else if (pagoCompleto === "pago_completo") {
            costo_tarjeta = 0;
            costo =
                pedido.estado === "Pendiente de pago"
                    ? pedido.pagos.primer_pago.monto +
                      pedido.pagos.segundo_pago.monto
                    : pedido.estado === "Pago parcial"
                    ? pedido.pagos.segundo_pago.monto
                    : null;
            estado = "Pago completo";
            metodo = "Transferencia bancaria";
            file = comprobante;
        }

        form.append("id", pedido.id);
        form.append("costo_tarjeta", costo_tarjeta);
        form.append("costo", costo);
        form.append("estado", estado);
        form.append("metodo", metodo);
        form.append("image_embarcacion_pago", file);

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/pedidoEmbarcacion/realizarPagoTemporal`,
                form,
                { headers }
            );

            if (data.message === "Pago temporal guardado") {
                navigate(`/pago_aprobado/comprobante/${pedido.id}`);
            }
        } catch (error) {
            Swal.fire({
                title: error.response.data,
                icon: "error",
            });
            setPaying(false);
        }
    };

    const priceToReservaCoin = (monto, tipo_moneda) => {
        let price = monto;

        if (reserva.coin_base === "PEN") {
            if (tipo_moneda === "PEN") return price;
            else price = (price * PRECIO_SOLES).toFixed(2);
        } else {
            if (tipo_moneda === "PEN")
                price = (price / PRECIO_SOLES).toFixed(2);
            else return price;
        }
        return Number(price);
    };

    const handleCupon = async () => {
        if (authUser.logged === false) {
            setHabilitarModal(true);
            setModalLogeo(true);
            return;
        }

        setCuponCargandoText(null);

        if (authUser.tipo_usuario > 5) {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/promoEmpresas/getCupon`,
                {
                    cupon: cupon,
                    id_cliente:
                        reserva.realizado_bc === "Si"
                            ? reserva.realizado_bc_cliente.id_cliente
                            : authUser.id_usuario,
                },
                { headers }
            );

            if (data.err) {
                setDescuentoCupon(null);
                Swal.fire({
                    title: "No se pudo aplicar el cupón",
                    text: `${data.err}`,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            } else {
                setDescuentoCupon(null);
                setCostoAsumidoEmpresa({
                    ...data.data,
                    costo_asumido: priceToReservaCoin(
                        data.data.costo_asumido,
                        data.data.tipo_moneda
                    ),
                });
                setIdcuponEmpresa(data.data.id);
                Swal.fire({
                    title: "Cupón aplicado con exito",
                    text: `Su empresa asumirá una suma de hasta ${priceToReservaCoin(
                        data.data.costo_asumido,
                        data.data.tipo_moneda
                    )} ${reserva?.coin_base}`,
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
            }
        } else {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/EmbarcacionCupones/getCupon`,
                {
                    cupon: cupon,
                    id_embarcacion: reserva.id_casa,
                    id_cliente:
                        reserva.realizado_bc === "Si"
                            ? reserva.realizado_bc_cliente.id_cliente
                            : authUser.id_usuario,
                },
                { headers }
            );
            if (data.err) {
                setDescuentoCupon(null);
                Swal.fire({
                    title: "No se pudo aplicar el cupón",
                    text: `${data.err}`,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            } else {
                // setDescuentoCupon(data.data);
                setDescuentoCupon({
                    ...data.data,
                    descuento_propietario_cupon: priceToReservaCoin(
                        data.data.descuento_propietario,
                        data.data.tipo_moneda
                    ),
                    descuento_bc_cupon: priceToReservaCoin(
                        data.data.descuento_bc,
                        data.data.tipo_moneda
                    ),
                });
                Swal.fire({
                    title: "Cupón aplicado con exito",
                    text: `Obtuviste un descuento de ${priceToReservaCoin(
                        data.data.descuento_bc +
                            data.data.descuento_propietario,
                        data.data.tipo_moneda
                    )} ${reserva?.coin_base}`,
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
            }
        }

        setCuponCargandoText("Aplicar cupón");
    };

    if (
        pedido.id &&
        pedido.estado !== "Pendiente de pago" &&
        pedido.estado !== "Pago parcial"
    ) {
        return (
            <div>
                <NavBar />
                <div
                    className={`${
                        authUser.tipo_usuario !== null &&
                        authUser.tipo_usuario <= 2
                            ? "pt-40"
                            : "pt-32"
                    } flex flex-col  select-none`}
                >
                    <div className={`bg-[${colores.primario}] text-center`}>
                        <h1 className="py-10 text-4xl font-bold text-white">
                            Checkout
                        </h1>
                    </div>
                    <div className="p-36">
                        <div className="p-10 text-xl border-8 border-dotted md:text-center md:text-3xl">
                            Esta reserva ya ha sido procesado y los pagos están
                            completos, muchas gracias por su reserva.
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    } else
        return (
            <div>
                <NavBar />
                <div className="absolute w-full ">
                    {habilitarModal && (
                        <ModalLogeo
                            modalLogeo={modalLogeo}
                            setModalLogeo={setModalLogeo}
                        />
                    )}
                </div>
                {idReserva ? (
                    pedido.id ? (
                        <div
                            className={`${
                                authUser.tipo_usuario !== null &&
                                authUser.tipo_usuario <= 2
                                    ? "pt-40"
                                    : "pt-32"
                            } flex flex-col  select-none`}
                        >
                            <div
                                className={`bg-[${colores.primario}] text-center`}
                            >
                                <h1 className="py-10 text-4xl font-bold text-white">
                                    Checkout
                                </h1>
                            </div>
                            <div className="p-3 mx-auto sm:p-10 lg:w-4/5">
                                {pagoCompleto === "pago_completo" ? (
                                    <PagoCompletoEmbarcacion
                                        pedido={pedido}
                                        formaPago={formaPago}
                                        setFormaPago={setFormaPago}
                                        stripePromise={stripePromise}
                                        comprobantePreview={comprobantePreview}
                                        handleComprobante={handleComprobante}
                                        handlePagoComprobante={
                                            handlePagoComprobante
                                        }
                                        pagoCompleto={pagoCompleto}
                                        setPagoCompleto={setPagoCompleto}
                                        paying={paying}
                                    />
                                ) : (
                                    <PagoParcialEmbarcaciones
                                        pedido={pedido}
                                        formaPago={formaPago}
                                        setFormaPago={setFormaPago}
                                        stripePromise={stripePromise}
                                        comprobantePreview={comprobantePreview}
                                        handleComprobante={handleComprobante}
                                        handlePagoComprobante={
                                            handlePagoComprobante
                                        }
                                        pagoCompleto={pagoCompleto}
                                        setPagoCompleto={setPagoCompleto}
                                        paying={paying}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-screen mt-40">
                            <Spinner width={20} />
                        </div>
                    )
                ) : reserva ? (
                    <div
                        className={`${
                            authUser.tipo_usuario !== null &&
                            authUser.tipo_usuario <= 2
                                ? "pt-40"
                                : "pt-32"
                        } flex flex-col  select-none`}
                    >
                        <div className={`bg-[${colores.primario}] text-center`}>
                            <h1 className="py-10 text-4xl font-bold text-white">
                                Checkout
                            </h1>
                        </div>
                        <div className="p-3 mx-auto sm:p-10 lg:w-4/5">
                            <div
                                className={`flex items-center bg-slate-100 p-5 text-xl mt-10 border-t-4 border-[${colores.secundario}]`}
                            >
                                <p className="flex w-full">
                                    <svg
                                        className="h-8 w-8 min-w-[32px] text-black flex mr-2"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                                        />
                                    </svg>
                                    <span>
                                        ¿Tienes un cupón?
                                        <span
                                            className={` text-[${colores.secundario}] cursor-pointer mx-1`}
                                            onClick={() =>
                                                setTicketVisible(
                                                    (prev) => !prev
                                                )
                                            }
                                        >
                                            Haz click aquí para introducir tu
                                            código
                                        </span>
                                    </span>
                                </p>
                            </div>
                            {ticketVisible && (
                                <div className="flex flex-col p-4 mt-5 border">
                                    <h1 className="text-lg">
                                        Si tienes un código de cupón, por favor,
                                        aplícalo abajo.
                                    </h1>
                                    <div className="grid grid-cols-2 md:grid-cols-[80%_20%]  gap-4 mt-5">
                                        <input
                                            type="text"
                                            name="cupon"
                                            value={cupon}
                                            onChange={handleInputChange}
                                            placeholder="Código de cupón"
                                            className="p-2 border border-orange-700"
                                        />
                                        <button
                                            onClick={handleCupon}
                                            className={`bg-[${colores.primario}] mx-2 text-white font-semibold p-2 min-w-[64px] active:translate-y-1`}
                                        >
                                            {cuponCargandoText || (
                                                <Spinner width={7} />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            )}
                            <h1
                                className={`mt-20 text-[${colores.primario}] text-2xl font-semibold`}
                            >
                                Tu pedido
                            </h1>
                            <div
                                className={`text-[${colores.primario}] font-semibold text-base md:grid md:grid-cols-[50%_50%] flex flex-col-reverse`}
                            >
                                <div className="p-10 ">
                                    <div>
                                        <div className="p-3 border-t-2 border-gray-600 rounded-t-lg border-x-2">
                                            Pago Único:{" "}
                                            <p className="text-gray-500">
                                                Paga el total ahora y listo.
                                            </p>
                                        </div>
                                        <div className="p-3 border-2 border-gray-600 rounded-b-lg">
                                            Paga una parte y otra mas adelante:{" "}
                                            <p className="p-1 text-gray-500">
                                                {" "}
                                                Se debe depositar{" "}
                                                {(
                                                    reserva?.initialReserva / 2
                                                ).toFixed(2)}{" "}
                                                {reserva?.coin_base}, (Este
                                                monto representa solo el 50% del
                                                costo de reserva, sin servicios
                                                adicionales ni garantia). El
                                                segundo 50% más servicios
                                                adicionales, garantía y saldos
                                                restantes se pagará 7 días antes
                                                del inicio del alquiler (
                                                {reserva?.date?.final_date}).
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={`my-20 rounded-lg border-2 border-gray-600 text-[${colores.primario}] p-5 text-xl`}
                                    >
                                        <h1>
                                            Déjanos tu número de celular para
                                            poder contactar y completar el
                                            proceso de alquiler
                                        </h1>
                                        <hr className="my-5 border-t-2 border-gray-300" />
                                        <p className="text-base">
                                            Pago con tarjeta de crédito/débito
                                            cuenta con un costo adicional del 5%
                                            del monto a pagar.
                                        </p>
                                        <div className="flex mt-5 text-xs">
                                            <span>
                                                <input
                                                    type="checkbox"
                                                    className="mr-2 outline-none cursor-pointer"
                                                    onChange={(e) =>
                                                        setTermsAccepted(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                                He leído y estoy de acuerdo con
                                                los
                                                <Link
                                                    to={
                                                        "terminos-y-condiciones"
                                                    }
                                                    className={`text-[${colores.secundario}] mx-1`}
                                                >
                                                    Términos y Condiciones
                                                </Link>
                                                y
                                                <Link
                                                    to={
                                                        "politicas-y-privacidad"
                                                    }
                                                    className={`text-[${colores.secundario}] mx-1`}
                                                >
                                                    Políticas de uso y
                                                    privacidad
                                                </Link>
                                                de la web{" "}
                                                <span className="text-2xl text-red-500">
                                                    *
                                                </span>
                                            </span>
                                        </div>
                                        <button
                                            id="comprobar_disponibilidad"
                                            className={`border-[${colores.secundario}] bg-[${colores.secundario}] border hover:bg-white hover:text-[${colores.secundario}] disabled:bg-gray-400 disabled:border-white disabled:text-white text-white font-semibold p-3 rounded-md mt-10 mb-6 text-base w-full lg:w-64 lg:ml-auto flex justify-center`}
                                            disabled={!termsAccepted}
                                            onClick={() => handleReserva()}
                                        >
                                            Comprobar disponibilidad
                                        </button>
                                    </div>
                                </div>

                                <div className="p-10 -mt-1">
                                    {reserva.clientReferido && (
                                        <div className="flex flex-col items-center justify-center w-3/4 gap-4 p-4 mx-10 border-2 border-blue-500 rounded-lg">
                                            <div className="flex items-start justify-center w-full">
                                                ¡Reserva realizada referida por{" "}
                                                <span className="ml-1">
                                                    {
                                                        reserva.clientReferido
                                                            .nombre
                                                    }{" "}
                                                    {
                                                        reserva.clientReferido
                                                            .apellido
                                                    }{" "}
                                                </span>
                                                !
                                            </div>{" "}
                                            <div className="flex items-center justify-center gap-4">
                                                <div></div>
                                                <div className="flex flex-col items-center justify-center">
                                                    <div className="flex items-center justify-center">
                                                        <div className="font-semibold text-center">
                                                            Recuerda que debes
                                                            concretar el pago
                                                            para que la persona
                                                            que te ha referido
                                                            se vea beneficiada.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <table className="w-full border-2 border-gray-600 md:mt-1 bordered">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="p-4 uppercase border-2 border-gray-600"
                                                    colSpan={2}
                                                >
                                                    Producto
                                                </th>
                                                <th
                                                    className="p-4 uppercase border-2 border-gray-600"
                                                    colSpan={2}
                                                >
                                                    Subtotal
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    className="p-4 border-2 border-gray-600"
                                                    colSpan={3}
                                                >
                                                    <div className="flex flex-auto">
                                                        <div className="min-h-[6rem]">
                                                            {" "}
                                                            <h1 className="font-bold">
                                                                {reserva?.boat}{" "}
                                                                - Turno{" "}
                                                                {
                                                                    reserva?.numero_turno
                                                                }
                                                            </h1>
                                                            <br />
                                                            Fecha de reserva:{" "}
                                                            {
                                                                reserva?.date
                                                                    .final_date
                                                            }{" "}
                                                            <br /> A partir de
                                                            las{" "}
                                                            {
                                                                reserva?.hora_inicio
                                                            }{" "}
                                                            hs hasta las{" "}
                                                            {
                                                                reserva?.hora_final
                                                            }{" "}
                                                            hs
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    className="p-2 text-sm border-2 border-gray-600"
                                                    colSpan={1}
                                                >
                                                    <span className="flex h-24 font-semibold ">
                                                        {numberToCommas(
                                                            reserva?.initialReserva
                                                        )}{" "}
                                                        {reserva?.coin_base}
                                                    </span>
                                                </td>
                                            </tr>
                                            {reserva?.descuento_empresa > 0 && (
                                                <tr className="text-green-500 h-14">
                                                    <td
                                                        className="p-4 border-2 border-gray-600"
                                                        colSpan={3}
                                                    >
                                                        <h3>
                                                            Descuento de{" "}
                                                            {
                                                                authUser.nombre_empresa
                                                            }
                                                        </h3>
                                                    </td>
                                                    <td
                                                        className="p-2 border-2 border-gray-600"
                                                        colSpan={1}
                                                    >
                                                        <h3 className="text-green-500 h-14">
                                                            -{" "}
                                                            {
                                                                reserva.descuento_empresa
                                                            }{" "}
                                                            {reserva?.coin_base}
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                            {reserva?.descuento_oferta > 0 && (
                                                <tr className="text-green-500 h-14">
                                                    <td
                                                        className="p-4 border-2 border-gray-600"
                                                        colSpan={3}
                                                    >
                                                        <h3>Descuento</h3>
                                                    </td>
                                                    <td
                                                        className="p-2 border-2 border-gray-600"
                                                        colSpan={1}
                                                    >
                                                        <h3 className="text-green-500 h-14">
                                                            -{" "}
                                                            {
                                                                reserva.descuento_oferta
                                                            }{" "}
                                                            {reserva?.coin_base}
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                            {descuentoCupon && (
                                                <tr className="text-green-500 ">
                                                    <td
                                                        className="border-2 border-gray-600"
                                                        colSpan={3}
                                                    >
                                                        <h3>
                                                            Descuento utilizando
                                                            cupón
                                                        </h3>
                                                    </td>
                                                    <td
                                                        className="border-2 border-gray-600 "
                                                        colSpan={1}
                                                    >
                                                        <h3 className="text-green-500 ">
                                                            -{""}
                                                            {descuentoCupon.descuento_bc_cupon +
                                                                descuentoCupon.descuento_propietario_cupon}{" "}
                                                            {reserva?.coin_base}
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                            {reserva?.aditional_services
                                                .length > 0 && (
                                                <tr>
                                                    <td
                                                        className="p-4 border-2 border-gray-600"
                                                        colSpan={3}
                                                    >
                                                        {reserva?.aditional_services.map(
                                                            (service) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            service.id
                                                                        }
                                                                        className="flex justify-between "
                                                                    >
                                                                        <span>
                                                                            {
                                                                                service.nombre
                                                                            }
                                                                            :{" "}
                                                                        </span>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </td>
                                                    <td
                                                        className="p-2 text-sm border-2 border-gray-600"
                                                        colSpan={1}
                                                    >
                                                        {reserva?.aditional_services.map(
                                                            (service) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            service.id
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {priceToMonedaElegida(
                                                                                (service.precio /
                                                                                    (1 -
                                                                                        reserva.fee_cliente /
                                                                                            100)) *
                                                                                    1.18,
                                                                                service.tipo_moneda
                                                                            ).toFixed(
                                                                                2
                                                                            )}{" "}
                                                                            {
                                                                                reserva?.coin_base
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td
                                                    className="p-4 border-2 border-gray-600"
                                                    colSpan={3}
                                                >
                                                    <h3>Garantía</h3>
                                                </td>
                                                <td
                                                    className="p-2 text-sm border-2 border-gray-600"
                                                    colSpan={1}
                                                >
                                                    <h3>
                                                        {reserva?.warranty}{" "}
                                                        {reserva?.coin_base}
                                                    </h3>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    className="p-4 border-2 border-gray-600"
                                                    colSpan={3}
                                                >
                                                    <h3>Total </h3>
                                                </td>
                                                <td
                                                    className="p-2 text-sm border-2 border-gray-600"
                                                    colSpan={1}
                                                >
                                                    <h3>
                                                        {numberToCommas(
                                                            descuentoCupon
                                                                ? reserva?.totalPrice -
                                                                      (descuentoCupon.descuento_bc_cupon +
                                                                          descuentoCupon.descuento_propietario_cupon +
                                                                          reserva.descuento_oferta)
                                                                : reserva?.totalPrice -
                                                                      reserva.descuento_oferta
                                                        )}{" "}
                                                        {reserva?.coin_base}
                                                    </h3>
                                                </td>
                                            </tr>
                                            {costoAsumidoEmpresa && (
                                                <>
                                                    <tr>
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}
                                                        >
                                                            <h3 className="p-3"></h3>
                                                        </td>
                                                        <td
                                                            className="p-2 border-2 border-gray-600"
                                                            colSpan={1}
                                                        >
                                                            {" "}
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td
                                                            className="p-4 text-green-500 border-2 border-gray-600"
                                                            colSpan={3}
                                                        >
                                                            <h3>
                                                                Cupón empresa{" "}
                                                                {
                                                                    authUser.nombre_empresa
                                                                }{" "}
                                                            </h3>
                                                        </td>
                                                        <td
                                                            className="p-2 text-green-500 border-2 border-gray-600"
                                                            colSpan={1}
                                                        >
                                                            <h3 className="">
                                                                -{" "}
                                                                {getTotalCostoAsumidoEmpresa(
                                                                    reserva?.totalPrice
                                                                )}{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>

                                                    <tr className="text-white bg-blue-bluecorner">
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}
                                                        >
                                                            <h3>
                                                                Total a abonar
                                                            </h3>
                                                        </td>
                                                        <td
                                                            className="p-2 border-2 border-gray-600"
                                                            colSpan={1}
                                                        >
                                                            <h3 className="">
                                                                {(descuentoCupon
                                                                    ? reserva?.totalPrice -
                                                                      (descuentoCupon.descuento_bc_cupon +
                                                                          descuentoCupon.descuento_propietario_cupon +
                                                                          reserva.descuento_oferta)
                                                                    : reserva?.totalPrice -
                                                                      reserva.descuento_oferta -
                                                                      getTotalCostoAsumidoEmpresa(
                                                                          descuentoCupon
                                                                              ? reserva?.totalPrice -
                                                                                    (descuentoCupon.descuento_bc_cupon +
                                                                                        descuentoCupon.descuento_propietario_cupon +
                                                                                        reserva.descuento_oferta)
                                                                              : reserva?.totalPrice -
                                                                                    reserva.descuento_oferta
                                                                      )
                                                                ).toFixed(
                                                                    2
                                                                )}{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className={`${
                            authUser.tipo_usuario !== null &&
                            authUser.tipo_usuario <= 2
                                ? "pt-40"
                                : "pt-32"
                        } flex flex-col  select-none`}
                    >
                        <div className={`bg-[${colores.primario}] text-center`}>
                            <h1 className="py-10 text-4xl font-bold text-white">
                                Checkout
                            </h1>
                        </div>
                        <div className="p-36">
                            <div className="p-10 text-xl border-8 border-dotted md:text-center md:text-3xl">
                                No posee ninguna reserva pendiente
                            </div>
                        </div>
                    </div>
                )}
                <Footer showWspText={true} />
            </div>
        );
};

export default CheckoutEmbarcaciones;
