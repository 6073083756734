import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useHeaders from "../../../hooks/useHeaders";

import ModalDetallePagos from "../../membresias/ModaDetallePagos";
import Spinner from "../../Spinner";
import DetailedMembresia from "./DetailedMembresia";
import SideBarMembresias from "./SideBarMembresias";

const HistorialMembresias = () => {
    const [membresiasEmpresa, setMembresiasEmpresa] = useState(null);
    const [membresiaSelected, setMembresiaSelected] = useState(null);

    const [modalDetalle, setModalDetalle] = useState({
        id_pago: "",
        checking: false,
        costoMembresia: "",
    });
    const { idEmpresa, nameEmpresa } = useParams();
    const { headers } = useHeaders();
    const navigate = useNavigate();

    const getMembresiasEmpresa = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/empresaSuscripcion/all/${idEmpresa}`,
            { headers }
        );
        console.log(data.data);
        setMembresiasEmpresa(data.data);
        setMembresiaSelected(data.data[0]);
    };

    useEffect(() => {
        getMembresiasEmpresa();
    }, [modalDetalle]);

    if (!membresiasEmpresa)
        return (
            <div className="flex items-center justify-center h-screen">
                <Spinner />
            </div>
        );
    if (membresiasEmpresa.length > 0)
        return (
            <div className=" w-full md:pl-[12rem] lg:pl-[16rem] lg1170:pl-[16rem]  xl:pl-[16rem] p-4">
                {/* {modalDetalle.checking && (
                    <ModalDetallePagos
                        setModalDetalle={setModalDetalle}
                        costoMembresia={modalDetalle.costoMembresia}
                        id_pago={modalDetalle.id_pago}
                        getMembresiasEmpresa={getMembresiasEmpresa}
                    />
                )} */}
                <div
                    className="flex items-center text-lg cursor-pointer w-max hover:underline hover:scale-105 mt-6"
                    onClick={() => navigate(-1)}>
                    <svg
                        className="w-6 h-6 text-black"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                    <span className="mx-1">Regresar</span>
                </div>
                <h1 className="mt-4 text-lg font-medium md:text-xl">
                    Membresias de empresa {nameEmpresa}
                </h1>
                <div className="flex flex-col gap-5 mt-4 md:flex-row md:gap-10">
                    <SideBarMembresias
                        membresias={membresiasEmpresa}
                        membresiaSelected={membresiaSelected}
                        setMembresiaSelected={setMembresiaSelected}
                    />
                    <DetailedMembresia
                        membresia={membresiaSelected}
                        setModalDetalle={setModalDetalle}
                        getMembresiasEmpresa={getMembresiasEmpresa}
                    />
                </div>
            </div>
        );
    else
        return (
            <div className=" w-full md:pl-[12rem] lg:pl-[16rem] lg1170:pl-[16rem]  xl:pl-[16rem] p-4">
                <div
                    className="flex items-center text-lg cursor-pointer w-max hover:underline hover:scale-105 mt-6"
                    onClick={() => navigate(-1)}>
                    <svg
                        className="w-6 h-6 text-black"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                    <span className="mx-1">Regresar</span>
                </div>
                <h1 className="p-6 mt-10 font-medium text-center md:text-lg">
                    No se encontraron membresias para {nameEmpresa}
                </h1>
            </div>
        );
};

export default HistorialMembresias;
