import axios from "axios";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useHeaders from "../../../hooks/useHeaders";
import ToggleButton from "../../ToggleButton";
import AutoComplete from "./AutoComplete";

import bannerAnoNuevon2024 from "../../../img/promos/Banner-ano-nuevo-2.webp";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import HouseModal from "./ModalCasa";

const ExplorerSanValentin = ({
    vistaAdministrador,
    setVistaAdministrador,
    setRefreshHouses,
}) => {
    const [fields, setFields] = useState([]);
    const [input, setInput] = useState("");
    const { tipo_usuario } = useSelector((state) => state.authReducer);
    const { headers } = useHeaders();
    const debounceInput = useRef(null);
    const [fieldSelect, setFieldSelect] = useState(null);

    const handleInputChangeDebounce = (e) => {
        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            setInput(e.target.value);
            setFields([]);
            return;
        }
        setInput(e.target.value);
        debounceInput.current = setTimeout(async () => {
            try {
                const { data } = await axios(
                    `${process.env.REACT_APP_PROXY}/casasSemanaSanta/searchCasa/${e.target.value}`,
                    { headers }
                );
                console.log(data.data);
                setFields(data.data);
            } catch (error) {
                console.log(error.response);
            }
        }, 500);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setFields([]);
            setInput("");
        }, 200);
    };

    return (
        <div
            className={`${
                tipo_usuario !== null && tipo_usuario <= 2
                    ? "mt-[8rem]"
                    : "mt-[6rem]"
            }`}
        >
            {fieldSelect && (
                <HouseModal
                    fieldSelect={fieldSelect}
                    setRefreshHouses={setRefreshHouses}
                    setShowModal={setFieldSelect}
                    headers={headers}
                />
            )}
            <div
                className={`w-full bg-center 
                bg-cover flex flex-col bg-white text-center`}
            >
                <div className="h-full text-white swiper-san-valentin transition-all ease-out duration-[200ms] p-4 w-full xl:px-32 2xl:px-28 mx-auto mt-6">
                    <Swiper
                        // modules={[Navigation]}
                        className="h-[12rem] sm:h-[20rem] rounded-t-xl lg:h-[25rem] bg-gradient-to-bl from-white to-blue-bluecorner select-none flex justify-center items-center"
                        // grabCursor
                        slidesPerView={1}
                        centeredSlides
                        speed={1000}
                        // loop
                        // navigation
                    >
                        <SwiperSlide className="transition-all duration-200 ease-out rounded-lg">
                            <div className="relative w-full h-full transition-all duration-300 bg-gradient-to-t from-white to-blue-400">
                                <img
                                    src={bannerAnoNuevon2024}
                                    className="w-full h-full mx-auto transition-all duration-200 ease-out rounded-lg"
                                    alt={`Banner semana santa`}
                                />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    {/* <div className="p-4 py-2 font-medium rounded-b-lg bg-blue-bluecorner">
                        <span className="flex flex-col py-1 text-xl text-left">
                            Casas del jueves 6 al domingo 9 de abril
                        </span>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ExplorerSanValentin;
