import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";

const EditarCapacidades = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
}) => {
    const [checkingGuardar, setCheckingGuardar] = useState(false);
    const authUser = useSelector((state) => state.authReducer);

    const updateProduct = async (payload) => {
        setCheckingGuardar(true);
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };

        if (tipo_producto === "casa") {
            console.log(payload);
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarPersonasHabitacionesBanos/${payload.id}`,
                payload,
                { headers }
            );
            if (data.message === "Actualizacion exitosa") {
                setModal(false);
            } else {
                Swal.fire({
                    title: "Hubo un error al procesar los datos",
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar!",
                });
            }
        } else if (tipo_producto === "embarcacion") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacion/actualizarPersonas/${producto.data.id}`,
                payload,
                { headers }
            );
            if (data.message === "Actualizacion exitosa") {
                setModal(false);
            } else {
                Swal.fire({
                    title: "Hubo un error al procesar los datos",
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar!",
                });
            }
        }
    };

    const handleGuardar = () => {
        if (tipo_producto === "casa") {
            if (producto.data.numero_habitaciones < 1) {
                Swal.fire({
                    title: "Debes seleccionar al menos una habitacion",
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Completar!",
                });
            }
            if (producto.data.numero_personas < 1) {
                Swal.fire({
                    title: "Debes seleccionar al menos una persona",
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Completar!",
                });
            }
            if (
                producto.data.numero_habitaciones > 0 &&
                producto.data.numero_personas > 0
            ) {
                updateProduct({
                    numero_habitaciones: producto.data.numero_habitaciones,
                    numero_personas: producto.data.numero_personas,
                    numero_banos: producto.data.numero_banos,
                    numero_mascotas: producto.data.numero_mascotas,
                    numero_habitaciones_servicio:
                        producto.data.numero_habitaciones_servicio,
                    id: producto.data.id,
                    id_usuario: authUser.id_usuario,
                });
            }
        }
        if (tipo_producto === "embarcacion") {
            if (producto.data.numero_personas < 1) {
                Swal.fire({
                    title: "Debes seleccionar al menos una persona",
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Completar!",
                });
            }
            if (producto.data.numero_personas > 0) {
                updateProduct({
                    numero_personas: producto.data.numero_personas,
                    id_usuario: authUser.id_usuario,
                });
            }
        }
    };

    return (
        <div className="border p-4 rounded-lg">
            <h1>Capacidades del alojamiento</h1>

            <div className="w-full p-4 mt-3 border rounded-lg">
                <div>
                    <div className="flex justify-between">
                        <div className="lg:mx-auto ">
                            <p className=" text-lg text-[#3C3C3C] mx-auto w-14">
                                Personas
                            </p>
                        </div>
                        <div className="flex lg:mx-auto">
                            <div
                                onClick={() =>
                                    producto.data.numero_personas > 0 &&
                                    setProducto({
                                        ...producto,
                                        data: {
                                            ...producto.data,
                                            numero_personas:
                                                producto.data.numero_personas -
                                                1,
                                        },
                                    })
                                }
                                className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                <svg
                                    className="h-4 w-4 text-blue-bluecorner"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </div>
                            <div className="flex justify-center items-center text-[#3C3C3C] text-base mx-2 border-2 w-8 h-8 border-white rounded-lg">
                                {producto.data.numero_personas}
                            </div>
                            <div
                                onClick={() =>
                                    setProducto({
                                        ...producto,
                                        data: {
                                            ...producto.data,
                                            numero_personas:
                                                producto.data.numero_personas +
                                                1,
                                        },
                                    })
                                }
                                className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                <svg
                                    className="h-4 w-4 text-blue-bluecorner"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    {tipo_producto === "casa" && (
                        <div className="flex justify-between mt-8">
                            <div className="lg:mx-auto">
                                <p className=" text-lg text-[#3C3C3C] mx-auto w-14">
                                    Habitaciones
                                </p>
                            </div>
                            <div className="flex lg:mx-auto">
                                <div
                                    onClick={() =>
                                        producto.data.numero_habitaciones > 0 &&
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                numero_habitaciones:
                                                    producto.data
                                                        .numero_habitaciones -
                                                    1,
                                            },
                                        })
                                    }
                                    className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                    <svg
                                        className="h-4 w-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div className="flex justify-center items-center text-[#3C3C3C] text-base mx-2 border-2 w-8 h-8 border-white rounded-lg">
                                    {producto.data.numero_habitaciones}
                                </div>
                                <div
                                    onClick={() =>
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                numero_habitaciones:
                                                    producto.data
                                                        .numero_habitaciones +
                                                    1,
                                            },
                                        })
                                    }
                                    className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                    <svg
                                        className="h-4 w-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )}
                    {tipo_producto === "casa" && (
                        <div className="flex justify-between mt-8">
                            <div className="lg:mx-auto">
                                <p className=" text-lg text-[#3C3C3C] mx-auto w-14">
                                    Cuarto de servicio
                                </p>
                            </div>
                            <div className="flex lg:mx-auto">
                                <div
                                    onClick={() =>
                                        producto.data
                                            .numero_habitaciones_servicio > 0 &&
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                numero_habitaciones_servicio:
                                                    producto.data
                                                        .numero_habitaciones_servicio -
                                                    1,
                                            },
                                        })
                                    }
                                    className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                    <svg
                                        className="h-4 w-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div className="flex justify-center items-center text-[#3C3C3C] text-base mx-2 border-2 w-8 h-8 border-white rounded-lg">
                                    {producto.data.numero_habitaciones_servicio}
                                </div>
                                <div
                                    onClick={() =>
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                numero_habitaciones_servicio:
                                                    producto.data
                                                        .numero_habitaciones_servicio +
                                                    1,
                                            },
                                        })
                                    }
                                    className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                    <svg
                                        className="h-4 w-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )}
                    {tipo_producto === "casa" && (
                        <div className="flex justify-between mt-8">
                            <div className="lg:mx-auto">
                                <p className=" text-lg text-[#3C3C3C] mx-auto w-14">
                                    Baños
                                </p>
                            </div>
                            <div className="flex lg:mx-auto">
                                <div
                                    onClick={() =>
                                        producto.data.numero_banos > 0 &&
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                numero_banos:
                                                    producto.data.numero_banos -
                                                    0.5,
                                            },
                                        })
                                    }
                                    className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                    <svg
                                        className="h-4 w-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div className="flex justify-center items-center text-[#3C3C3C] text-base mx-2 border-2 w-8 h-8 border-white rounded-lg">
                                    {producto.data.numero_banos}
                                </div>
                                <div
                                    onClick={() =>
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                numero_banos:
                                                    producto.data.numero_banos +
                                                    0.5,
                                            },
                                        })
                                    }
                                    className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                    <svg
                                        className="h-4 w-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* {tipo_producto === "casa" && (
                        <div className="flex justify-between mt-8">
                            <div className="lg:mx-auto">
                                <p className=" text-lg text-[#3C3C3C] mx-auto w-14">
                                    Mascotas
                                </p>
                            </div>
                            <div className="flex lg:mx-auto">
                                <div
                                    onClick={() =>
                                        producto.data.numero_mascotas > 0 &&
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                numero_mascotas:
                                                    producto.data
                                                        .numero_mascotas - 1,
                                            },
                                        })
                                    }
                                    className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                    <svg
                                        className="h-4 w-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div className="flex justify-center items-center text-[#3C3C3C] text-base mx-2 border-2 w-8 h-8 border-white rounded-lg">
                                    {producto.data.numero_mascotas}
                                </div>
                                <div
                                    onClick={() =>
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                numero_mascotas:
                                                    producto.data
                                                        .numero_mascotas + 1,
                                            },
                                        })
                                    }
                                    className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                                    <svg
                                        className="h-4 w-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    disabled={checkingGuardar}
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    {checkingGuardar ? <Spinner width={7} /> : "Guardar"}
                </button>
            </div>
        </div>
    );
};

export default EditarCapacidades;
