import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Paginado } from "../helpers/Paginado";
import ModalCargaMasiva from "../usuarios/empleadosEmpresas/ModalCargaMasiva";
import ModalNewEmpleado from "../usuarios/empleadosEmpresas/ModalNewEmpleado";
import ModalEditEmpleado from "../usuarios/empleadosEmpresas/ModalEditEmpleado";
import DinamicSearchBar from "../DinamicSearchBar";
import Spinner from "../Spinner";
import axios from "axios";
import Swal from "sweetalert2";

const EmpleadosEmpresa = () => {
    const [modalNewUser, setModalNewUser] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [modalMasiva, setModalMasiva] = useState(false);
    const [page, setPage] = useState(1);
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [habilitados, setHabilitados] = useState(true);
    const [selectedEmpleado, setSelectedEmpleado] = useState(null);
    const [allEmpleados, setAllEmpleados] = useState({
        checking: true,
        total: 0,
        empleados: [],
    });
    const { idEmpresa, nameEmpresa } = useParams();
    const navigate = useNavigate();

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const EMPLEADOS_PER_PAGE = 25;

    const getAllEmpleadosEmpresas = async (
        queryValue = "",
        habilitados,
        pageNumber = 1
    ) => {
        setAllEmpleados({
            checking: true,
            total: 0,
            empleados: [],
        });
        const { data } = await axios(
            `${
                process.env.REACT_APP_PROXY
            }/empleadoEmpresas/all/${pageNumber}/${
                habilitados ? "Si" : "No"
            }?nombre=${queryValue}&idEmpresa=${idEmpresa}`,
            { headers }
        );
        console.log(data);
        setAllEmpleados({
            checking: false,
            total: data.total,
            empleados: data.data,
        });
    };

    useEffect(() => {
        getAllEmpleadosEmpresas("", habilitados);
    }, [habilitados, fakeRefresh]);

    const handleDelete = async (idAdmin) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el admin a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/adminEmpresas/eliminar/${idAdmin}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Usuario eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllEmpleadosEmpresas("", habilitados);
                    });
            }
        });
    };

    const handleEnable = (idEmpleado) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el usuario a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/adminEmpresas/restaurar/${idEmpleado}`,
                        {},
                        {
                            headers,
                        }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Usuario restaurado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllEmpleadosEmpresas("", habilitados);
                    });
            }
        });
    };

    const handleEdit = (id) => {
        setSelectedEmpleado(id);
        setModalEditVisible(!modalEditVisible);
    };

    const pagination = (pageNumber) => {
        setPage(pageNumber);
        getAllEmpleadosEmpresas("", habilitados, pageNumber);
    };

    return (
        <>
            <section className=" w-full md:pl-[12rem] lg:pl-[16rem] lg1170:pl-[16rem]  xl:pl-[16rem] p-4">
                <div
                    className="flex items-center text-lg w-max cursor-pointer hover:underline hover:scale-105 mt-4"
                    onClick={() => navigate(-1)}>
                    <svg
                        className="w-6 h-6 text-black"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                    <span className="mx-1">Regresar</span>
                </div>
                <div className="flex items-center justify-between w-full ">
                    <h1 className="py-10 text-4xl  text-left">
                        Empleados de {nameEmpresa}
                    </h1>
                    {modalMasiva && (
                        <ModalCargaMasiva
                            modalVisible={modalMasiva}
                            setModalVisible={setModalMasiva}
                            idEmpresa={idEmpresa}
                            setfakeRefresh={setfakeRefresh}
                        />
                    )}
                    <div className="mt-2 md:ml-10 flex-col md:flex-row flex gap-4">
                        <button
                            className="flex md:w-auto md:h-auto items-center justify-center p-2 text-blue-500 border border-blue-500 rounded-lg group hover:bg-blue-500 hover:text-white"
                            onClick={() => setModalMasiva(!modalMasiva)}>
                            <svg
                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                            Carga masiva
                        </button>
                        <button
                            className="flex w-8 h-8 md:w-auto md:h-auto items-center justify-center p-2 text-blue-500 border border-blue-500 rounded-lg group hover:bg-blue-500 hover:text-white"
                            onClick={() => setModalNewUser((prev) => !prev)}>
                            <svg
                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                            <h1 className="hidden md:flex">Añadir empleado</h1>
                        </button>
                    </div>
                </div>

                <div className="flex items-center md:p-4 justify-end w-full mt-6 md:mt-0 md:ml-5">
                    <DinamicSearchBar
                        habilitados={habilitados}
                        callbackFunction={getAllEmpleadosEmpresas}
                    />
                    <button className="flex justify-center items-center border ml-5 w-8 h-8 md:w-11 md:h-11 rounded-lg border-blue-bluecorner relative">
                        <svg
                            onClick={() => setShowButtons((prev) => !prev)}
                            className="h-7 w-7 md:h-8 md:w-8 text-blue-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                            />
                        </svg>
                        {showButtons && (
                            <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                                <div
                                    onClick={() => {
                                        setHabilitados(true);
                                        setShowButtons((prev) => !prev);
                                        setPage(1);
                                    }}
                                    className=" w-32 h-11 mx-auto cursor-pointer
                                rounded-lg flex items-center justify-start p-1
                                ">
                                    <p
                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                        Habilitados
                                    </p>
                                </div>
                                <div
                                    onClick={() => {
                                        setHabilitados(false);
                                        setShowButtons((prev) => !prev);
                                        setPage(1);
                                    }}
                                    className=" w-32 h-11 mx-auto cursor-pointer
                                rounded-lg  flex items-center justify-start p-1
                                ">
                                    <p
                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                        Deshabilitados
                                    </p>
                                </div>
                            </div>
                        )}
                    </button>
                </div>
                {modalNewUser && (
                    <ModalNewEmpleado
                        modalVisible={modalNewUser}
                        setModalVisible={setModalNewUser}
                        setfakeRefresh={setfakeRefresh}
                        idEmpresa={idEmpresa}
                    />
                )}

                {modalEditVisible && (
                    <ModalEditEmpleado
                        idEmpleadoEmpresa={selectedEmpleado}
                        setModalVisible={setModalEditVisible}
                        modalVisible={modalEditVisible}
                        setfakeRefresh={setfakeRefresh}
                    />
                )}

                <div className="flex justify-center items-center lg:py-[5px] ">
                    <div className="flex text-center max-w-full overflow-x-auto w-full">
                        <div className="w-full">
                            {allEmpleados.checking ? (
                                <Spinner width={10} margin_top={10} />
                            ) : allEmpleados.empleados.length > 0 ? (
                                <div>
                                    <table className="mt-10 w-full overflow-x-auto text-left">
                                        <thead className="text-left">
                                            <tr>
                                                <th className="px-4 w-full min-w-[8rem] md:w-2/6 ">
                                                    Nombre
                                                </th>
                                                <th className="w-2.5/6 min-w-[10rem] hidden md:table-cell">
                                                    Correo electrónico
                                                </th>
                                                <th
                                                    align="center"
                                                    className="w-[8%] min-w-[10rem] ">
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allEmpleados.empleados.map(
                                                (empleado) => (
                                                    <tr
                                                        className="border-t w-full"
                                                        key={empleado.id}>
                                                        <td className="p-4 flex">
                                                            {empleado.nombre}{" "}
                                                            {
                                                                empleado.apellido_paterno
                                                            }{" "}
                                                            {
                                                                empleado.apellido_materno
                                                            }
                                                        </td>
                                                        <td className="hidden md:table-cell text-left">
                                                            {empleado.correo}
                                                        </td>
                                                        <td>
                                                            {habilitados ? (
                                                                <div className="flex justify-center ml-9">
                                                                    <button
                                                                        className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                        title="Editar"
                                                                        onClick={() =>
                                                                            handleEdit(
                                                                                empleado.id
                                                                            )
                                                                        }>
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />{" "}
                                                                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                                            <line
                                                                                x1="16"
                                                                                y1="5"
                                                                                x2="19"
                                                                                y2="8"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                        className="w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                empleado.id
                                                                            )
                                                                        }
                                                                        title="Eliminar">
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white mx-auto"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />{" "}
                                                                            <line
                                                                                x1="4"
                                                                                y1="7"
                                                                                x2="20"
                                                                                y2="7"
                                                                            />{" "}
                                                                            <line
                                                                                x1="10"
                                                                                y1="11"
                                                                                x2="10"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <line
                                                                                x1="14"
                                                                                y1="11"
                                                                                x2="14"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <div className="flex justify-center ml-5 ">
                                                                    <button
                                                                        onClick={() =>
                                                                            handleEnable(
                                                                                empleado.id
                                                                            )
                                                                        }
                                                                        title="Habilitar"
                                                                        className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <polyline points="17 1 21 5 17 9" />{" "}
                                                                            <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                            <polyline points="7 23 3 19 7 15" />{" "}
                                                                            <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="flex justify-center my-3">
                                        <Paginado
                                            elementsPerPage={EMPLEADOS_PER_PAGE}
                                            cantTotalElements={
                                                allEmpleados.total
                                            }
                                            pagination={pagination}
                                            actualPage={page}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="font-medium mt-10 text-lg">
                                    <h2>
                                        No se ha encontrado ningun empleado de
                                        empresa{" "}
                                        {!habilitados && "deshabilitado"}
                                    </h2>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EmpleadosEmpresa;
