import axios from "axios";

const updateLastTimeOnline = async (user_id) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const { data } = await axios.put(
        `${process.env.REACT_APP_PROXY}/autenticacion/actualizarLogin/${user_id}`,
        {},
        { headers }
    );
};

export default updateLastTimeOnline;
