import React, { useState } from "react";

import { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

const ModalPolitica = ({
    politica,
    porcentaje,
    modalVisible,
    setModalVisible,
}) => {
    registerLocale("es", es);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleClose = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed z-10 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto  sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-4 right-4 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <h1 className="font-medium text-lg">
                            Politica de cancelacion
                        </h1>

                        <div className=" overflow-auto w-[105%]">
                            <div
                                className={
                                    politica === "Flexible"
                                        ? "w-full mt-4 "
                                        : "hidden"
                                }>
                                <div className="flex justify-center ">
                                    <div
                                        className={`border border-[#E9E9E9] w-6 h-6 rounded-full mr-2 cursor-pointer ${
                                            politica === "Flexible"
                                                ? "bg-blue-bluecorner"
                                                : "bg-white"
                                        }`}></div>
                                    <div className="w-72 ">
                                        <p className="text-base font-bold">
                                            Flexible
                                        </p>
                                        <p className="text-base">
                                            Acepta cancelaciones con 72 horas
                                            antes del inicio del alquiler y
                                            devuelve el 100% del dinero al
                                            cliente.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    politica === "Moderado"
                                        ? "w-full mt-5 "
                                        : "hidden"
                                }>
                                <div className="flex justify-center ">
                                    <div
                                        className={`border border-[#E9E9E9] w-6 h-6 rounded-full mr-2 cursor-pointer ${
                                            politica === "Moderado"
                                                ? "bg-blue-bluecorner"
                                                : "bg-white"
                                        }`}></div>
                                    <div className="w-72 ">
                                        <p className="text-base font-bold">
                                            Moderado
                                        </p>
                                        <p className="text-base">
                                            Acepta cancelaciones con 72 horas
                                            antes del inicio del alquiler y
                                            devuelve un porcentaje determinado
                                            por el propietario.
                                        </p>
                                    </div>
                                </div>
                                {politica === "Moderado" && (
                                    <>
                                        <div className="flex justify-center">
                                            <p className="text-base font-semibold ">
                                                ¿Cual es el porcentaje a
                                                reembolsar?
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-center w-64 px-3 py-3 mx-auto border rounded-lg">
                                            <input
                                                disabled={true}
                                                autoComplete="off"
                                                className="w-full rounded-lg outline-none"
                                                placeholder="15"
                                                name="warrantyTotal"
                                                type="number"
                                                step=".01"
                                                value={
                                                    porcentaje || ""
                                                }></input>
                                            <span>
                                                <svg
                                                    className="w-4 h-4 text-[#3C3C3C] rounded-lg"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    <line
                                                        x1="19"
                                                        y1="5"
                                                        x2="5"
                                                        y2="19"
                                                    />{" "}
                                                    <circle
                                                        cx="6.5"
                                                        cy="6.5"
                                                        r="2.5"
                                                    />{" "}
                                                    <circle
                                                        cx="17.5"
                                                        cy="17.5"
                                                        r="2.5"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className={
                                    politica === "Reprogramación"
                                        ? "w-full mt-5 "
                                        : "hidden"
                                }>
                                <div className="flex justify-center ">
                                    <div
                                        className={`border border-[#E9E9E9] w-6 h-6 rounded-full mr-2 cursor-pointer ${
                                            politica === "Reprogramación"
                                                ? "bg-blue-bluecorner"
                                                : "bg-white"
                                        }`}></div>
                                    <div className="w-72 ">
                                        <p className="text-base font-bold">
                                            Reprogramación
                                        </p>
                                        <p className="text-base">
                                            Acepta cambios con 72 horas del
                                            inicio del alquiler, da como crédito
                                            el 100% del dinero al cliente y este
                                            será utilizado en un plazo máximo de
                                            12 meses. Dos reprogramaciones como
                                            máximo. Cabe señalar que por cada
                                            reprogramación, el cliente deberá
                                            pagar 20% del total del alquiler
                                            como fee administrativo a Blue
                                            Corner.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    politica === "Inflexible"
                                        ? "w-full mt-5 mb-4 "
                                        : "hidden"
                                }>
                                <div className="flex justify-center ">
                                    <div
                                        className={`border border-[#E9E9E9] w-6 h-6 rounded-full mr-2 cursor-pointer ${
                                            politica === "Inflexible"
                                                ? "bg-blue-bluecorner"
                                                : "bg-white"
                                        }`}></div>
                                    <div className="w-72 ">
                                        <p className="text-base font-bold">
                                            Inflexible
                                        </p>
                                        <p className="text-base">
                                            No se aceptan devoluciones ni
                                            reprogramaciones.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalPolitica;
