import addDaysToDate from "../addDaysToDate";
import getEveryDayBetween2Dates from "../getEveryDayBetween2Dates";
import getFeeByDay from "../getFeeByDay";
import priceToMonedaElegida from "../priceToMonedaElegida";

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

export const getTotalFromRangoExactoDias = (
    cantDays,
    selectedDays,
    arrayRangoExactoDiasPorDia,
    moneda_precio_base,
    fee_cliente_siempre,
    fee_propietario_siempre,
    feeCasas
) => {
    let totalPrice = 0;
    let tiene_fee_por_fechas = false;
    let total_fee_cliente = 0;
    let total_fee_propietario = 0;

    const newSelectedDays = [
        selectedDays[0],
        addDaysToDate(selectedDays[1], -1),
    ];

    const allDatesSelected = getEveryDayBetween2Dates(newSelectedDays);

    const dia_inicio = new Date(selectedDays[0])
        .toLocaleDateString("es-ES", options)
        .split(",")[0];
    const dia_final = new Date(selectedDays[1])
        .toLocaleDateString("es-ES", options)
        .split(",")[0];

    let rangoEncontrado = {};

    const cumpleCondicion = arrayRangoExactoDiasPorDia.find((range) => {
        if (
            range.dia_inicio.toLowerCase() === dia_inicio.toLowerCase() &&
            range.dia_final.toLowerCase() === dia_final.toLowerCase()
        ) {
            if (range.tipo_tiempo === "Por fechas") {
                const FDI = new Date(range.fecha_inicio + "T00:00:00");
                const FDF = new Date(range.fecha_final + "T00:00:00");
                const FRI = selectedDays[0];
                const FRF = selectedDays[1];
                if (
                    (FDI <= FRI && FDF >= FRI) ||
                    (FDI <= FRF && FDF >= FRF) ||
                    (FRI <= FDI && FRF >= FDI) ||
                    (FRI <= FDF && FRF >= FDF)
                ) {
                    return range;
                }
            }
        }
    });

    if (cumpleCondicion) {
        rangoEncontrado = cumpleCondicion;
    } else {
        rangoEncontrado = arrayRangoExactoDiasPorDia.find(
            (range) =>
                range.dia_inicio.toLowerCase() === dia_inicio.toLowerCase() &&
                range.dia_final.toLowerCase() === dia_final.toLowerCase()
        );
    }

    if (rangoEncontrado && cantDays <= 7) {
        let precio_rango = rangoEncontrado.precio;
        const precio_por_dia = precio_rango / (cantDays - 1);
        totalPrice += rangoEncontrado.precio;

        allDatesSelected.forEach((date, index) => {
            console.log("Total price inicial: ", totalPrice);

            const { fee_cliente, fee_propietario, tiene_fee_por_fecha } =
                getFeeByDay(
                    date,
                    feeCasas,
                    fee_cliente_siempre,
                    fee_propietario_siempre,
                    precio_por_dia
                );

            if (tiene_fee_por_fecha) {
                tiene_fee_por_fechas = true;
            }

            total_fee_propietario += fee_propietario;
            total_fee_cliente += fee_cliente;
        });

        if (tiene_fee_por_fechas) {
            console.log(
                "Tiene fee por fechas: ",
                totalPrice,
                total_fee_cliente
            );
            totalPrice += total_fee_cliente;
        } else if (!tiene_fee_por_fechas) {
            const cumpleCondicion = feeCasas.find((range) => {
                if (range.tipo_tiempo === "Dias") {
                    if (
                        range.dia_inicio.toLowerCase() ===
                            dia_inicio.toLowerCase() &&
                        range.dia_final.toLowerCase() ===
                            dia_final.toLowerCase()
                    ) {
                        return range;
                    }
                }
            });

            if (cumpleCondicion) {
                console.log(
                    "No Tiene fee por fechas, cumple condicion: ",
                    totalPrice,
                    total_fee_cliente
                );
                totalPrice += totalPrice * (cumpleCondicion.fee_cliente / 100);
                total_fee_propietario =
                    totalPrice * (cumpleCondicion.fee_propietario / 100);
                total_fee_cliente =
                    totalPrice * (cumpleCondicion.fee_cliente / 100);
            } else {
                console.log(
                    "No Tiene fee por fechas, no cumple condicion: ",
                    totalPrice,
                    total_fee_cliente
                );
                totalPrice += total_fee_cliente;
            }
        }

        if (rangoEncontrado.accion === "+") {
            return {
                accion: rangoEncontrado.accion,
                precio: priceToMonedaElegida(
                    rangoEncontrado.precio,
                    moneda_precio_base
                ),
                fee_cliente: priceToMonedaElegida(
                    total_fee_cliente,
                    moneda_precio_base
                ),
                fee_propietario: priceToMonedaElegida(
                    total_fee_propietario,
                    moneda_precio_base
                ),
                precio_total: priceToMonedaElegida(
                    totalPrice,
                    moneda_precio_base
                ),
                descripcion: "Aumento por días especiales",
            };
        } else {
            return {
                accion: rangoEncontrado.accion,
                precio: priceToMonedaElegida(
                    rangoEncontrado.precio * -1,
                    moneda_precio_base
                ),
                fee_cliente: 0,
                fee_propietario: 0,
                precio_total: priceToMonedaElegida(
                    rangoEncontrado.precio * -1,
                    moneda_precio_base
                ),
                descripcion: "Descuento por días especiales",
            };
        }
    } else
        return {
            accion: "+",
            precio: 0,
            fee_cliente: 0,
            fee_propietario: 0,
            precio_total: 0,
            descripcion: "",
        };
};
