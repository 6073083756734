import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavbarEmpresas from "../dashboardEmpresas/NavbarEmpresas";
import numberToCommas from "../helpers/numberToCommas";
import Spinner from "../Spinner";
import MembresiaDetalle from "./MembresiaDetalle";
import { NavbarEmpresasNew } from "../dashboardEmpresas/NavbarEmpresasNew";
import { NavbarMobileEmpresasNew } from "../dashboardEmpresas/NavbarMobileEmpresasNew";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { verifyToken } from "../../redux/actions/authActions";

const Membresias = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const dispatch = useDispatch();

    const authUser = useSelector((state) => state.authReducer);
    const Navigate = useNavigate();
    const PLANES = [
        {
            id: 1,
            nombre: "Pago mensual",
            precio_pequena: 69,
            precio_mediana: 149,
            precio_grande: 499,
            ahorra_pequeno: 0,
            ahorra_mediana: 0,
            ahorra_grande: 0,
            plan: "Mensual",
            color: "#a25ddc",
        },
        {
            id: 2,
            nombre: "Pago trimestral",
            precio_pequena: 197,
            precio_mediana: 425,
            precio_grande: 1422,
            ahorra_pequeno: 10,
            ahorra_mediana: 22,
            ahorra_grande: 75,
            plan: "Trimestral",
            color: "#0085ff",
        },
        {
            id: 3,
            nombre: "Pago semestral",
            precio_pequena: 352,
            precio_mediana: 760,
            precio_grande: 2545,
            ahorra_pequeno: 62,
            ahorra_mediana: 134,
            ahorra_grande: 449,
            plan: "Semestral",
            color: "#00ca72",
        },
    ];

    const [cargando, setCargando] = useState(false);
    const [historialMembresias, setHistorialMembresias] = useState([]);
    const postMembresia = async (plan, nombre) => {
        Navigate(`/dashboard-empresas/checkout/${authUser.id_empresa}/${plan}`);
    };

    const membresiaActiva = async () => {
        setCargando(true);
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/empresaSuscripcion/all/${authUser.id_empresa}`,

            { headers }
        );

        console.log(json.data);
        setHistorialMembresias(json.data.data);
        setCargando(false);
    };

    const activarMembresia = async () => {
        setCargando(true);
        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/empresaSuscripcion/postPrueba/`,
            { id_empresa: authUser.id_empresa },
            { headers }
        );
        Swal.fire({
            icon: "success",
            text: "Membresia activada",
            confirmButtonText: "Aceptar",
        });
        dispatch(verifyToken());
        membresiaActiva();
    };

    console.log(authUser);

    useEffect(() => {
        membresiaActiva();
    }, []);

    return (
        <>
            {/* <NavbarEmpresas /> */}
            <NavbarEmpresasNew>
                {/* detalle.bandera ? (
                 <MembresiaDetalle setDetalle={setDetalle} /> */}
                {
                    <div className="p-4 overflow-y-auto xs:p-10 md:p-16 md:mx-auto w-full">
                        <div className="w-full">
                            {authUser.uso_prueba === "No" &&
                            authUser.membresia === "No" &&
                            !historialMembresias.length ? (
                                <div className=" w-11/12 xs:w-10/12 bg-orange-400 py-2 text-center text-white font-medium mx-auto rounded-lg my-6 flex items-center justify-center">
                                    <p>Obten 15 dias gratis de membresia </p>
                                    <button
                                        disabled={cargando}
                                        onClick={() => activarMembresia()}
                                        className="cursor-pointer py-1 px-2 rounded-lg bg-white text-orange-400 mx-4 hover:scale-105">
                                        Obtener
                                    </button>
                                </div>
                            ) : null}
                        </div>
                        <h1 className="text-4xl">Membresía</h1>
                        <h2 className="mt-6">
                            Obtén el tipo de membresía que prefieras.
                        </h2>
                        {cargando ? (
                            <Spinner />
                        ) : (
                            <div className="justify-center gap-2 mt-6 lg:flex lg:gap-10 ">
                                {PLANES.map((plan) => (
                                    <div
                                        key={plan.id}
                                        onClick={() =>
                                            postMembresia(
                                                plan.plan,
                                                plan.nombre
                                            )
                                        }
                                        className={`relative flex flex-col items-center flex-grow py-6  mb-6 border rounded-lg cursor-pointer hover:scale-105 
                                    px-6
                                    `}>
                                        <div
                                            style={{
                                                backgroundColor: plan.color,
                                            }}
                                            className="absolute top-0 w-full h-2 rounded-t-lg "></div>
                                        <div className="flex">
                                            <span className="text-2xl font-bold">
                                                {plan.nombre}
                                            </span>
                                        </div>
                                        {plan.id === 2 && (
                                            <div
                                                style={{
                                                    backgroundColor: plan.color,
                                                }}
                                                className="absolute px-2 py-1 mt-1 ml-2 font-medium text-white rounded-lg top-14">
                                                <p>Más popular</p>
                                            </div>
                                        )}
                                        <span className="mt-10 text-base">
                                            1 pago de
                                        </span>
                                        <span className="mt-1.5 text-blue-bluecorner font-bold text-2xl">
                                            {authUser.tamano === "Pequeña" && (
                                                <div>
                                                    USD{" "}
                                                    {numberToCommas(
                                                        plan.precio_pequena
                                                    )}
                                                </div>
                                            )}

                                            {authUser.tamano === "Mediana" && (
                                                <div>
                                                    USD{" "}
                                                    {numberToCommas(
                                                        plan.precio_mediana
                                                    )}
                                                </div>
                                            )}

                                            {authUser.tamano === "Grande" && (
                                                <div>
                                                    USD{" "}
                                                    {numberToCommas(
                                                        plan.precio_grande
                                                    )}
                                                </div>
                                            )}
                                        </span>
                                        <span className="mt-1.5 text-xs">
                                            Incluye IGV
                                        </span>
                                        {plan.id > 1 ? (
                                            <span className="mt-6 bg-[#053465] p-1.5 px-4 font-bold text-sm text-white rounded-[10px]">
                                                {authUser.tamano ===
                                                    "Pequeña" && (
                                                    <div>
                                                        Ahorra USD{" "}
                                                        {numberToCommas(
                                                            plan.ahorra_pequeno
                                                        )}
                                                    </div>
                                                )}

                                                {authUser.tamano ===
                                                    "Mediana" && (
                                                    <div>
                                                        Ahorra USD{" "}
                                                        {numberToCommas(
                                                            plan.ahorra_mediana
                                                        )}
                                                    </div>
                                                )}

                                                {authUser.tamano ===
                                                    "Grande" && (
                                                    <div>
                                                        Ahorra USD{" "}
                                                        {numberToCommas(
                                                            plan.ahorra_grande
                                                        )}
                                                    </div>
                                                )}
                                            </span>
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                        )}
                        <hr className="h-1 bg-slate-200"></hr>
                        <p className="mt-6 text-2xl font-medium text-center">
                            Beneficios:
                        </p>
                        <div className="flex-col items-center justify-center px-2 py-4 mx-auto text-center text-gray-500 ">
                            <ul className="inline-block text-left list-disc marker:text-sky-800">
                                <li>
                                    <p className="text-lg">
                                        Personalizacion de landing con la marca
                                        y colores de tu empresa
                                    </p>
                                </li>

                                <li>
                                    <p className="text-lg">
                                        Tu personal tendra un descuento del 15%
                                        al 20% en las reservas
                                    </p>
                                </li>
                                <li>
                                    <p className="text-lg">
                                        Podras crear promociones (cupones) para
                                        tu personal en temporadas o fechas que
                                        tu desees
                                    </p>
                                </li>
                                <li>
                                    <p className="text-lg">
                                        Podras adquirir paquetes corporativos
                                        personalizados para eventos sociales y
                                        asi festejar al personal de tu empresa
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-16">
                            <h2 className="text-2xl font-medium text-center mb-4">
                                Historial de Membresías
                            </h2>
                            {historialMembresias.length === 0 ? (
                                <p className="text-center">
                                    Aún no tienes ninguna membresía.
                                </p>
                            ) : (
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Fecha Inicial
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Fecha Final
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Método de Pago
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Estado
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Tipo de Plan
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {historialMembresias.map(
                                            (membresia) => (
                                                <tr key={membresia.id}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {membresia.fecha_inicio}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {membresia.fecha_final}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {membresia.metodo}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {membresia.estado}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {membresia.plan}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                }
                <NavbarMobileEmpresasNew />
            </NavbarEmpresasNew>
        </>
    );
};

export default Membresias;
