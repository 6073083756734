import React, { useState, useEffect } from "react";

import ModalCreatePaquete from "./ModalCreatePaquete";
import Swal from "sweetalert2";
import axios from "axios";

// import SearchBar from "./SearchBar";

import Spinner from "../../Spinner";

import { Paginado } from "../../helpers/Paginado";
import NavbarEmpresas from "../../dashboardEmpresas/NavbarEmpresas";
import ModalEditPaquete from "./ModalEditPaquete";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ModalDetallePaquete from "./ModalDetallePaquete";
import ModalHistorialPagos from "./ModalHistorialPagos";
import { NavbarEmpresasNew } from "../../dashboardEmpresas/NavbarEmpresasNew";
import { NavbarMobileEmpresasNew } from "../../dashboardEmpresas/NavbarMobileEmpresasNew";
import CardPaquete from "./CardPaquete";
import ModalCrearPaquetePersonalizado from "./ModalCrearPaquetePersonalizado";
// import ModalEditPaquete from "./ModalEditPaquete";

const AllPaquetesEmpresa = ({ desdeBluecorner = false }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const navigate = useNavigate();
    const [modalCreateVisible, setModalCreateVisible] = useState(false);
    const [modalHistorialPagos, setModalHistorialPagos] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [enabled, setenabled] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [pageNumber, setpageNumber] = useState(1);
    const RULES_PER_PAGE = 25;
    const [paqueteSelected, setPaqueteSelected] = useState(null);
    const [allPaquetes, setAllPaquetes] = useState([]);
    const [allCardPaquetes, setAllCardPaquetes] = useState([]);
    const [paquete, setPaquete] = useState(null);
    const [totalPedidos, setTotalPedidos] = useState(0);
    const [checking, setChecking] = useState(true);

    const getPaquetes = async (pagina = 1, habilitado = "Si", input = "") => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/pedidopaquetesempresas/all/${pagina}/${habilitado}/${authUser.id_usuario}`,
            { headers }
        );
        setChecking(false);
        setAllPaquetes(json.data.data);
        setTotalPedidos(json.data.total);
        if (json.data.total === 0) {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/paquetesempresas/all/${pagina}/${habilitado}/paquete/?nombre=${input}`,

                { headers }
            );
            console.log(json);
            setAllCardPaquetes(json.data.data);
        }
    };

    useEffect(() => {
        getPaquetes();
        let carrito = JSON.parse(localStorage.getItem("carrito"));
        console.log(carrito);
    }, [modalCreateVisible, paquete]);

    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el pedido a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const json = await axios.delete(
                    `${process.env.REACT_APP_PROXY}/pedidoPaquetesEmpresas/eliminar/${id}`,

                    { headers }
                );

                if (json.data.message === "Registro deshabilitado con exito") {
                    Swal.fire({
                        title: "pedido eliminado correctamente!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                    getPaquetes(pageNumber, "Si");
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "no se pudo eliminar el pedido",
                        icon: "error",
                        width: "350px",
                        confirmButtonText: "Aceptar",
                    });
                }
            }
        });
    };
    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el pedido a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/pedidoPaquetesEmpresas/restaurar/${id}`,
                    {},
                    { headers }
                );

                if (json.data.message === "Registro habilitado con exito") {
                    Swal.fire({
                        title: "pedido habilitado correctamente!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                    getPaquetes(pageNumber, "No");
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "no se pudo eliminar el pedido",
                        icon: "error",
                        width: "350px",
                        confirmButtonText: "Aceptar",
                    });
                }
            }
        });
    };

    const addPaquete = (paquete) => {
        localStorage.setItem("carrito", JSON.stringify(paquete));
        localStorage.setItem("servicios", JSON.stringify([]));
        navigate("/paquetes-empresariales/servicios");
    };

    const handleEdit = async (paquete) => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/pedidoPaquetesEmpresas/detalle/${paquete.id}`,

            { headers }
        );

        if (json.data.detalle_pedido_paquete_empresa.length) {
            setPaquete({ ...json.data, id: paquete.id });
        } else {
            Swal.fire({
                title: "Error!",
                text: "",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        }

        // setModalEditVisible(!modalEditVisible);
    };

    const pagination = (pageNumber) => {
        setpageNumber(pageNumber);
        getPaquetes(pageNumber, enabled ? "Si" : "No");
    };

    return (
        <>
            {/* {!desdeBluecorner && <NavbarEmpresas />} */}
            {modalVisible && (
                <ModalCrearPaquetePersonalizado
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                />
            )}
            {!desdeBluecorner ? (
                <NavbarEmpresasNew>
                    {checking ? (
                        <Spinner />
                    ) : (
                        <div className="w-full p-4 md:p-10 md:mx-auto">
                            <div className="flex items-center justify-between w-full ">
                                <div className="flex-col items-start justify-start">
                                    <h1 className="py-5 text-4xl text-left">
                                        {desdeBluecorner
                                            ? "Pedidos de paquetes de empresa"
                                            : "Paquetes para mi empresa"}
                                    </h1>
                                    <div className="text-base w-[30rem] text-justify">
                                        Puedes elegir entre unos de los paquetes
                                        que te brindamos!, o si deseas podemos
                                        realizarte algun evento a tu medida ej:
                                        paseo en embarcación, cena ejecutiva,
                                        etc. Para que asi puedan confraternizar
                                        mas con tu personal de{" "}
                                        <b>{authUser.nombre_empresa}. </b>
                                    </div>
                                </div>
                                {!desdeBluecorner && (
                                    <div className="mt-2 md:ml-10">
                                        <button
                                            className="flex items-center justify-center w-8 h-8 p-2 ml-4 text-blue-500 border border-blue-500 rounded-lg md:w-auto md:h-auto group hover:bg-blue-500 hover:text-white"
                                            onClick={() =>
                                                navigate(
                                                    "/paquetes-empresariales"
                                                )
                                            }>
                                            <svg
                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <line
                                                    x1="12"
                                                    y1="5"
                                                    x2="12"
                                                    y2="19"
                                                />{" "}
                                                <line
                                                    x1="5"
                                                    y1="12"
                                                    x2="19"
                                                    y2="12"
                                                />
                                            </svg>
                                            <h1 className="hidden md:flex">
                                                Adquirir un paquete
                                            </h1>
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="absolute flex items-center justify-center w-full text-center adsolute">
                                {modalHistorialPagos && paqueteSelected && (
                                    <ModalHistorialPagos
                                        paqueteSelected={paqueteSelected}
                                        id_paquete={paqueteSelected.id}
                                        setModalDetalle={setModalHistorialPagos}
                                    />
                                )}
                                {modalCreateVisible && (
                                    <ModalCreatePaquete
                                        modalVisible={modalCreateVisible}
                                        setmodalVisible={setModalCreateVisible}
                                        pageNumber={pageNumber}
                                    />
                                )}
                                {paquete?.detalle_pedido_paquete_empresa
                                    ?.length &&
                                    desdeBluecorner && (
                                        <ModalEditPaquete
                                            setPaquete={setPaquete}
                                            actualPage={pageNumber}
                                            paquete={paquete}
                                        />
                                    )}
                                {paquete?.detalle_pedido_paquete_empresa
                                    ?.length &&
                                    !desdeBluecorner && (
                                        <ModalDetallePaquete
                                            setPaquete={setPaquete}
                                            actualPage={pageNumber}
                                            paquete={paquete}
                                        />
                                    )}
                            </div>
                            <div className="flex items-center justify-end w-full mt-6 md:p-4 md:mt-0 md:ml-5">
                                <button
                                    className={
                                        desdeBluecorner
                                            ? "relative z-0 flex items-center justify-center w-8 h-8 ml-5 border rounded-lg md:w-11 md:h-11 border-blue-bluecorner"
                                            : "hidden"
                                    }>
                                    <svg
                                        onClick={() =>
                                            setShowButtons((prev) => !prev)
                                        }
                                        className="text-blue-500 h-7 w-7 md:h-8 md:w-8"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                        />
                                    </svg>
                                    {showButtons && (
                                        <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                                            <div
                                                onClick={() => {
                                                    getPaquetes(1, "Si");
                                                    setenabled(true);
                                                    setShowButtons(
                                                        (prev) => !prev
                                                    );
                                                    setpageNumber(1);
                                                }}
                                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                                <p
                                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                    Habilitados
                                                </p>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    getPaquetes(1, "No");
                                                    setenabled(false);
                                                    setShowButtons(
                                                        (prev) => !prev
                                                    );
                                                    setpageNumber(1);
                                                }}
                                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                                <p
                                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                    Deshabilitados
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </button>
                            </div>

                            <div className="container flex items-center justify-between p-1 mx-auto">
                                <Paginado
                                    elementsPerPage={RULES_PER_PAGE}
                                    cantTotalElements={totalPedidos.total}
                                    pagination={pagination}
                                />
                            </div>

                            <section className="flex justify-center items-center lg:py-[10px] ">
                                <div className="flex w-full max-w-full overflow-x-auto text-center">
                                    {allPaquetes.length > 0 ? (
                                        <div className="w-full">
                                            <table className="w-full overflow-x-auto mt-14">
                                                <thead className="text-left">
                                                    <tr>
                                                        <th className=" md:table-cell px-4 w-full min-w-[8rem] md:w-4/12 ">
                                                            N° pedido
                                                        </th>
                                                        <th className="w-4/12 min-w-[8rem] hidden md:table-cell">
                                                            Costo total
                                                        </th>
                                                        <th className="w-4/12 min-w-[10rem] hidden md920:table-cell">
                                                            Fecha
                                                        </th>
                                                        <th className="w-212 min-w-[10rem] hidden lg1170:table-cell">
                                                            Estado
                                                        </th>
                                                        <th className="w-2/12 min-w-[8rem] hidden md:table-cell">
                                                            Estado de Pago
                                                        </th>
                                                        <th
                                                            align="center"
                                                            className="w-[8%] min-w-[8rem]">
                                                            Acciones
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-left">
                                                    {allPaquetes.map(
                                                        (paquete) => (
                                                            <tr
                                                                key={paquete.id}
                                                                className="w-full border-t">
                                                                <td className="flex items-center py-3">
                                                                    <img
                                                                        alt="Logo empresa"
                                                                        src={`${process.env.REACT_APP_PROXY}/empresas/getImage/${paquete.logo}`}
                                                                        className="object-cover h-10 mr-2 rounded-lg w-14"
                                                                    />
                                                                    {
                                                                        paquete.empresa
                                                                    }{" "}
                                                                    (
                                                                    {paquete.id}
                                                                    )
                                                                </td>
                                                                <td className="hidden md:table-cell">
                                                                    {
                                                                        paquete.costo_total
                                                                    }{" "}
                                                                    USD
                                                                </td>
                                                                <td className="hidden md920:table-cell">
                                                                    {
                                                                        paquete.fecha_evento
                                                                    }
                                                                </td>
                                                                <td className="hidden lg1170:table-cell">
                                                                    <p
                                                                        className={`${
                                                                            paquete.estado_venta ===
                                                                            "Proforma"
                                                                                ? "border-orange-400 text-orange-400"
                                                                                : "border-green-600 text-green-600"
                                                                        } py-1 border rounded-md max-w-[100px] px-2 cursor-pointer hover:scale-105 relative text-center`}>
                                                                        {
                                                                            paquete?.estado_venta
                                                                        }
                                                                    </p>
                                                                </td>
                                                                <td className="hidden md:table-cell">
                                                                    <p
                                                                        className={` ${
                                                                            paquete.estado_pago ===
                                                                            "No pagado"
                                                                                ? "border-orange-400 text-orange-400"
                                                                                : "border-green-600 text-green-600"
                                                                        } py-1 border rounded-md max-w-[100px] px-2 cursor-pointer relative text-center`}>
                                                                        {
                                                                            paquete?.estado_pago
                                                                        }
                                                                    </p>
                                                                </td>
                                                                <td className="">
                                                                    {enabled ? (
                                                                        <div className="flex ml-9">
                                                                            {paquete.estado_pago !==
                                                                                "Pagado" && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleEdit(
                                                                                            paquete
                                                                                        )
                                                                                    }
                                                                                    title="Editar"
                                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />
                                                                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                                        <line
                                                                                            x1="16"
                                                                                            y1="5"
                                                                                            x2="19"
                                                                                            y2="8"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                            )}

                                                                            <button
                                                                                className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                onClick={() => {
                                                                                    setPaqueteSelected(
                                                                                        paquete
                                                                                    );
                                                                                    setModalHistorialPagos(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                                title="Revisar pagos">
                                                                                <svg
                                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    strokeWidth="2"
                                                                                    stroke="currentColor"
                                                                                    fill="none"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                    <path
                                                                                        stroke="none"
                                                                                        d="M0 0h24v24H0z"
                                                                                    />
                                                                                    <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />{" "}
                                                                                    <line
                                                                                        x1="8"
                                                                                        y1="8"
                                                                                        x2="12"
                                                                                        y2="8"
                                                                                    />{" "}
                                                                                    <line
                                                                                        x1="8"
                                                                                        y1="12"
                                                                                        x2="12"
                                                                                        y2="12"
                                                                                    />{" "}
                                                                                    <line
                                                                                        x1="8"
                                                                                        y1="16"
                                                                                        x2="12"
                                                                                        y2="16"
                                                                                    />
                                                                                </svg>
                                                                            </button>
                                                                            {paquete.estado_pago !==
                                                                                "Pagado" && (
                                                                                <button
                                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                    onClick={() =>
                                                                                        handleDelete(
                                                                                            paquete.id
                                                                                        )
                                                                                    }
                                                                                    title="Eliminar">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="4"
                                                                                            y1="7"
                                                                                            x2="20"
                                                                                            y2="7"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="10"
                                                                                            y1="11"
                                                                                            x2="10"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="14"
                                                                                            y1="11"
                                                                                            x2="14"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                                    </svg>
                                                                                </button>
                                                                            )}
                                                                            {paquete.estado_venta ===
                                                                                "Venta" &&
                                                                                paquete.estado_pago ===
                                                                                    "No pagado" && (
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            navigate(
                                                                                                `/dashboard-empresas/paquetes/checkout/${paquete.id}`
                                                                                            )
                                                                                        }
                                                                                        title="Pagar pedido"
                                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                                        <svg
                                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                            fill="none"
                                                                                            viewBox="0 0 24 24"
                                                                                            stroke="currentColor">
                                                                                            <path
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"
                                                                                                strokeWidth="2"
                                                                                                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                                            />
                                                                                        </svg>
                                                                                    </button>
                                                                                )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="flex justify-center ml-9">
                                                                            <button
                                                                                onClick={() =>
                                                                                    handleEnable(
                                                                                        paquete.id
                                                                                    )
                                                                                }
                                                                                title="Habilitar"
                                                                                className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                                <svg
                                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                    {" "}
                                                                                    <polyline points="17 1 21 5 17 9" />{" "}
                                                                                    <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                    <polyline points="7 23 3 19 7 15" />{" "}
                                                                                    <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                                                            <div className="flex justify-center">
                                                                <Paginado
                                                                    actualPage={
                                                                        pageNumber
                                                                    }
                                                                    elementsPerPage={
                                                                        RULES_PER_PAGE
                                                                    }
                                                                    cantTotalElements={
                                                                        totalPedidos.total
                                                                    }
                                                                    pagination={
                                                                        pagination
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    ) : (
                                        <div>
                                            {authUser.tipo_usuario === 6 ? (
                                                <div>
                                                    <div className="text-xl font-semibold">
                                                        Conoce nuestros paquetes
                                                    </div>
                                                    <div>
                                                        {allCardPaquetes?.length >
                                                        0 ? (
                                                            <div>
                                                                <div className="w-full grid-cols-2 gap-4 p-4 mt-3 md:grid lg:grid-cols-2 2xl:grid-cols-3 xl:w-3/4 2xl:w-2/3 mx-auto">
                                                                    {allCardPaquetes
                                                                        .slice(
                                                                            0,
                                                                            3
                                                                        )
                                                                        .map(
                                                                            (
                                                                                paquete
                                                                            ) => (
                                                                                <CardPaquete
                                                                                    key={
                                                                                        paquete.id
                                                                                    }
                                                                                    paquete={
                                                                                        paquete
                                                                                    }
                                                                                    addPaquete={
                                                                                        addPaquete
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                                <div className="flex flex-col md920:flex-row justify-center  items-center gap-2 mx-auto">
                                                                    <div
                                                                        onClick={() =>
                                                                            navigate(
                                                                                "/paquetes-empresariales"
                                                                            )
                                                                        }
                                                                        className=" cursor-pointer mt-4 rounded-lg p-3 border-2 border-blue-500 text-blue-500 bg-white hover:bg-blue-500 hover:text-white w-80 ">
                                                                        Conoce
                                                                        todos
                                                                        aquí
                                                                    </div>
                                                                    <div
                                                                        onClick={() =>
                                                                            setModalVisible(
                                                                                true
                                                                            )
                                                                        }
                                                                        className=" cursor-pointer mt-4 rounded-lg p-3 border-2 border-blue-500 text-white bg-blue-500 hover:bg-white hover:text-blue-500 w-80 ">
                                                                        Paquete
                                                                        personalizado
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="justify-center w-full mt-10">
                                                                <Spinner />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <h1 className="w-full mt-10 font-medium text-center">
                                                    No se encontraron paquetes
                                                </h1>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </section>
                        </div>
                    )}
                    <NavbarMobileEmpresasNew />
                </NavbarEmpresasNew>
            ) : checking ? (
                <Spinner />
            ) : (
                <div className=" w-full md:pl-[13rem] md818:pl-[14rem] md920:pl-[13rem] lg:pl-[17rem] p-4">
                    <div className="flex items-center justify-between w-full ">
                        <div className="flex-col items-start justify-start mb-5">
                            <h1 className="py-5 text-4xl text-left">
                                {desdeBluecorner
                                    ? "Pedidos de paquetes de empresa"
                                    : "Paquetes para mi empresa"}
                            </h1>
                            {authUser.tipo_usuario === 6 && (
                                <div className="text-base w-[30rem] text-justify">
                                    Si deseas realizar eventos para el personal
                                    de tu empresa, ej: "Viajes en embarcación,
                                    cenas ejecutivas, actividades al aire
                                    libre", puedes escoger uno de los paquetes
                                    que te ofrecemos!, o te podemos personalizar
                                    un paquete a tu medidia contactandonos!- .
                                </div>
                            )}
                        </div>

                        {!desdeBluecorner && (
                            <div className="mt-2 md:ml-10">
                                <button
                                    className="flex items-center justify-center w-8 h-8 p-2 ml-4 text-blue-500 border border-blue-500 rounded-lg md:w-auto md:h-auto group hover:bg-blue-500 hover:text-white"
                                    onClick={() =>
                                        navigate("/paquetes-empresariales")
                                    }>
                                    <svg
                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    <h1 className="hidden md:flex">
                                        Adquirir un paquete
                                    </h1>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="absolute flex items-center justify-center w-full text-center adsolute">
                        {modalHistorialPagos && paqueteSelected && (
                            <ModalHistorialPagos
                                paqueteSelected={paqueteSelected}
                                id_paquete={paqueteSelected.id}
                                setModalDetalle={setModalHistorialPagos}
                            />
                        )}
                        {modalCreateVisible && (
                            <ModalCreatePaquete
                                modalVisible={modalCreateVisible}
                                setmodalVisible={setModalCreateVisible}
                                pageNumber={pageNumber}
                            />
                        )}
                        {paquete?.detalle_pedido_paquete_empresa?.length &&
                            desdeBluecorner && (
                                <ModalEditPaquete
                                    setPaquete={setPaquete}
                                    actualPage={pageNumber}
                                    paquete={paquete}
                                />
                            )}
                        {paquete?.detalle_pedido_paquete_empresa?.length &&
                            !desdeBluecorner && (
                                <ModalDetallePaquete
                                    setPaquete={setPaquete}
                                    actualPage={pageNumber}
                                    paquete={paquete}
                                />
                            )}
                    </div>
                    <div className="flex items-center justify-end w-full mt-6 md:p-4 md:mt-0 md:ml-5">
                        {/* <SearchBar
                        getPaquetes={getPaquetes}
                        setfakeRefresh={setfakeRefresh}
                        fakeRefresh={fakeRefresh}
                        enabled={enabled}
                    /> */}
                        <button
                            className={
                                desdeBluecorner
                                    ? "relative z-0 flex items-center justify-center w-8 h-8 ml-5 border rounded-lg md:w-11 md:h-11 border-blue-bluecorner"
                                    : "hidden"
                            }>
                            <svg
                                onClick={() => setShowButtons((prev) => !prev)}
                                className="text-blue-500 h-7 w-7 md:h-8 md:w-8"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                />
                            </svg>
                            {showButtons && (
                                <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                                    <div
                                        onClick={() => {
                                            getPaquetes(1, "Si");
                                            setenabled(true);
                                            setShowButtons((prev) => !prev);
                                            setpageNumber(1);
                                        }}
                                        className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                        <p
                                            className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                            Habilitados
                                        </p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            getPaquetes(1, "No");
                                            setenabled(false);
                                            setShowButtons((prev) => !prev);
                                            setpageNumber(1);
                                        }}
                                        className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                        <p
                                            className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                            Deshabilitados
                                        </p>
                                    </div>
                                </div>
                            )}
                        </button>
                    </div>

                    <div className="container flex items-center justify-between p-1 mx-auto">
                        <Paginado
                            elementsPerPage={RULES_PER_PAGE}
                            cantTotalElements={totalPedidos.total}
                            pagination={pagination}
                        />
                    </div>

                    <section className="flex justify-center items-center lg:py-[10px] ">
                        <div className="flex w-full max-w-full overflow-x-auto text-center">
                            {allPaquetes.length > 0 ? (
                                <div className="w-full">
                                    <table className="w-full overflow-x-auto mt-14">
                                        <thead className="text-left">
                                            <tr>
                                                <th className=" md:table-cell px-4 w-full min-w-[8rem] md:w-4/12 ">
                                                    N° pedido
                                                </th>
                                                <th className="w-4/12 min-w-[8rem] hidden md:table-cell">
                                                    Costo total
                                                </th>
                                                <th className="w-4/12 min-w-[10rem] hidden md920:table-cell">
                                                    Fecha
                                                </th>
                                                <th className="w-212 min-w-[10rem] hidden lg1170:table-cell">
                                                    Estado
                                                </th>
                                                <th className="w-2/12 min-w-[8rem] hidden md:table-cell">
                                                    Estado de Pago
                                                </th>
                                                <th
                                                    align="center"
                                                    className="w-[8%] min-w-[8rem]">
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-left">
                                            {allPaquetes.map((paquete) => (
                                                <tr
                                                    key={paquete.id}
                                                    className="w-full border-t">
                                                    <td className="flex items-center py-3">
                                                        <img
                                                            alt="Logo empresa"
                                                            src={`${process.env.REACT_APP_PROXY}/empresas/getImage/${paquete.logo}`}
                                                            className="object-cover h-10 mr-2 rounded-lg w-14"
                                                        />
                                                        {paquete.empresa} (
                                                        {paquete.id})
                                                    </td>
                                                    <td className="hidden md:table-cell">
                                                        {paquete.costo_total}{" "}
                                                        USD
                                                    </td>
                                                    <td className="hidden md920:table-cell">
                                                        {paquete.fecha_evento}
                                                    </td>
                                                    <td className="hidden lg1170:table-cell">
                                                        <p
                                                            className={`${
                                                                paquete.estado_venta ===
                                                                "Proforma"
                                                                    ? "border-orange-400 text-orange-400"
                                                                    : "border-green-600 text-green-600"
                                                            } py-1 border rounded-md max-w-[100px] px-2 cursor-pointer hover:scale-105 relative text-center`}>
                                                            {
                                                                paquete?.estado_venta
                                                            }
                                                        </p>
                                                    </td>
                                                    <td className="hidden md:table-cell">
                                                        <p
                                                            className={` ${
                                                                paquete.estado_pago ===
                                                                "No pagado"
                                                                    ? "border-orange-400 text-orange-400"
                                                                    : "border-green-600 text-green-600"
                                                            } py-1 border rounded-md max-w-[100px] px-2 cursor-pointer relative text-center`}>
                                                            {
                                                                paquete?.estado_pago
                                                            }
                                                        </p>
                                                    </td>
                                                    <td className="">
                                                        {enabled ? (
                                                            <div className="flex ml-9">
                                                                {paquete.estado_pago !==
                                                                    "Pagado" && (
                                                                    <button
                                                                        onClick={() =>
                                                                            handleEdit(
                                                                                paquete
                                                                            )
                                                                        }
                                                                        title="Editar"
                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />
                                                                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                            <line
                                                                                x1="16"
                                                                                y1="5"
                                                                                x2="19"
                                                                                y2="8"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                )}

                                                                <button
                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                    onClick={() => {
                                                                        setPaqueteSelected(
                                                                            paquete
                                                                        );
                                                                        setModalHistorialPagos(
                                                                            true
                                                                        );
                                                                    }}
                                                                    title="Revisar pagos">
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />
                                                                        <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />{" "}
                                                                        <line
                                                                            x1="8"
                                                                            y1="8"
                                                                            x2="12"
                                                                            y2="8"
                                                                        />{" "}
                                                                        <line
                                                                            x1="8"
                                                                            y1="12"
                                                                            x2="12"
                                                                            y2="12"
                                                                        />{" "}
                                                                        <line
                                                                            x1="8"
                                                                            y1="16"
                                                                            x2="12"
                                                                            y2="16"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                                {paquete.estado_pago !==
                                                                    "Pagado" && (
                                                                    <button
                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                paquete.id
                                                                            )
                                                                        }
                                                                        title="Eliminar">
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />{" "}
                                                                            <line
                                                                                x1="4"
                                                                                y1="7"
                                                                                x2="20"
                                                                                y2="7"
                                                                            />{" "}
                                                                            <line
                                                                                x1="10"
                                                                                y1="11"
                                                                                x2="10"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <line
                                                                                x1="14"
                                                                                y1="11"
                                                                                x2="14"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                        </svg>
                                                                    </button>
                                                                )}
                                                                {paquete.estado_venta ===
                                                                    "Venta" &&
                                                                    paquete.estado_pago ===
                                                                        "No pagado" && (
                                                                        <button
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/dashboard-empresas/paquetes/checkout/${paquete.id}`
                                                                                )
                                                                            }
                                                                            title="Pagar pedido"
                                                                            className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor">
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth="2"
                                                                                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                    )}
                                                            </div>
                                                        ) : (
                                                            <div className="flex justify-center ml-9">
                                                                <button
                                                                    onClick={() =>
                                                                        handleEnable(
                                                                            paquete.id
                                                                        )
                                                                    }
                                                                    title="Habilitar"
                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        {" "}
                                                                        <polyline points="17 1 21 5 17 9" />{" "}
                                                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                        <polyline points="7 23 3 19 7 15" />{" "}
                                                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={5}>
                                                    <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                                                    <div className="flex justify-center">
                                                        <Paginado
                                                            actualPage={
                                                                pageNumber
                                                            }
                                                            elementsPerPage={
                                                                RULES_PER_PAGE
                                                            }
                                                            cantTotalElements={
                                                                totalPedidos.total
                                                            }
                                                            pagination={
                                                                pagination
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            ) : (
                                <h1 className="w-full mt-10 font-medium text-center">
                                    No se encontraron paquetes
                                </h1>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </>
    );
};

export default AllPaquetesEmpresa;
