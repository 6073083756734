import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../NavBar";
import Footer from "../Footer";
import graciasAliados from "../../img/aliados gracias.jpg";

const PartnerAnswer = () => {
    return (
        <div>
            <NavBar />
            <div className=" mt-32 pt-16 ">
                <div className=" overflow-hidden  w-8/12 mx-auto">
                    <img
                        src={graciasAliados}
                        alt="gracias aliados"
                        className=" w-full transition duration-[1500ms]   hover:scale-150 hover:brightness-75"
                    />
                </div>
                <h2 className="text-[#55595c] text-center font-bold text-3xl pt-10">
                    Gracias por dejar tus datos
                </h2>
                <p className="text-[#55595c] text-center text-base mt-2">
                    Nos contactaremos lo más pronto posible
                </p>
            </div>
            <div className="  text-center mt-6">
                <Link
                    to="/"
                    className=" border-2 border-[#55595c] text-[#55595c] text-sm py-3 px-6 ">
                    Ir a la web
                </Link>
            </div>
            <div className="mt-12">
                <Footer />
            </div>
        </div>
    );
};

export default PartnerAnswer;
