import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { normalizeString } from "../../helpers/normalizeString";
import colores from "../../helpers/colores";
import Spinner from "../../Spinner";
import numberToCommas from "../../helpers/numberToCommas";
import priceToMonedaElegida from "../../helpers/priceToMonedaElegida";

const FirstHouses = ({ firstHouses, indexImagen, setIndexImagen }) => {
    const moneda_elegida = localStorage.getItem("moneda");
    return (
        <div>
            {firstHouses.houses.length > 0 ? (
                <>
                    <div className="text-[#053465] font-bold text-2xl px-8 md:px-6 xl:px-32 2xl:px-64 mt-8">
                        Ofertas del {firstHouses.initial_date} al{" "}
                        {firstHouses.final_date}
                    </div>
                    <div className="w-full grid-cols-2 gap-4 p-4 md:grid lg:grid-cols-3 xl:px-32 2xl:px-64">
                        {firstHouses.houses.map((house, index) => (
                            <div
                                key={house.id}
                                className="flex flex-col w-full px-4 mt-6 md:px-1 lg:px-1 rounded-t-xl">
                                <div className="relative w-full h-[300px] flex shadow-xl">
                                    <Swiper
                                        modules={[Navigation]}
                                        slidesPerView={1}
                                        navigation
                                        pagination={{
                                            clickable: true,
                                        }}
                                        rewind={true}
                                        loop={true}
                                        longSwipesMs={1200}
                                        onSlideNextTransitionStart={() =>
                                            setIndexImagen({
                                                indexIma:
                                                    indexImagen.indexCar ===
                                                        index &&
                                                    indexImagen.indexIma ===
                                                        house.imagenes.length -
                                                            1
                                                        ? -1
                                                        : indexImagen.indexCar ===
                                                          index
                                                        ? indexImagen.indexIma +
                                                          1
                                                        : 0,
                                                indexCar: index,
                                            })
                                        }
                                        onSlidePrevTransitionStart={() =>
                                            setIndexImagen({
                                                indexIma:
                                                    indexImagen.indexIma > -1 &&
                                                    indexImagen.indexCar ===
                                                        index
                                                        ? indexImagen.indexIma -
                                                          1
                                                        : house.imagenes
                                                              .length - 1,
                                                indexCar: index,
                                            })
                                        }>
                                        <SwiperSlide
                                            className="cursor-pointer"
                                            onClick={() =>
                                                window.open(
                                                    `/alojamientos/${
                                                        house.id
                                                    }/${normalizeString(
                                                        house.nombre
                                                    )}`,
                                                    "_blank"
                                                )
                                            }>
                                            <div className="h-full ">
                                                {indexImagen.indexIma > -1 &&
                                                indexImagen.indexCar ===
                                                    index ? (
                                                    <LazyLoadImage
                                                        className="object-cover w-full h-full rounded-xl "
                                                        alt="Imagen del producto"
                                                        src={`${
                                                            process.env
                                                                .REACT_APP_PROXY_ARCHIVOS
                                                        }/bluecorner/v1/productoImagenes/getImagen/${
                                                            house.imagenes[
                                                                indexImagen
                                                                    .indexIma
                                                            ]?.imagen_minificada
                                                        }`}
                                                    />
                                                ) : (
                                                    <LazyLoadImage
                                                        className="object-cover w-full h-full rounded-xl "
                                                        alt="Imagen del producto"
                                                        src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${house.imagen_principal_minificada}`}
                                                    />
                                                )}
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                <a
                                    href={`/alojamientos/${
                                        house.id
                                    }/${normalizeString(house.nombre)}`}
                                    className={`bottom-0 flex flex-col w-full h-44 p-4 text-lg font-bold text-white bg-[${colores.primario}] rounded-b-xl cursor-pointer`}>
                                    <span className="">{`${house.nombre}`}</span>
                                    <span className="mt-2 text-lg font-semibold line-through">
                                        {`Antes ${moneda_elegida} ${numberToCommas(
                                            priceToMonedaElegida(
                                                house.precio_antes,
                                                house.moneda
                                            )
                                        )} 
                                        del ${firstHouses.initial_date} al ${
                                            firstHouses.final_date
                                        }`}
                                    </span>
                                    <span className="mt-2 text-xl font-bold">
                                        {`Ahora ${moneda_elegida} ${numberToCommas(
                                            priceToMonedaElegida(
                                                house.precio_despues,
                                                house.moneda
                                            )
                                        )} 
                                        del ${firstHouses.initial_date} al ${
                                            firstHouses.final_date
                                        }`}
                                    </span>
                                </a>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className="justify-center w-full mt-10">
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default FirstHouses;
