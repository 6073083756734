import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import dividirFechaDatePicker from "../../../helpers/dividirFechaDatePicker";
import { useSelector } from "react-redux";

const ModalEditServiciosAvanzados = ({
    setCreate,
    variacion,
    moneda,
    producto,
    nombre,
}) => {
    registerLocale("es", es);
    const [desde, setdesde] = useState(variacion.fecha_inicio);
    const [hasta, sethasta] = useState(variacion.fecha_final);
    const [precio, setPrecio] = useState(variacion.precio);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    const updateProduct = async (payload) => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/productoServiciosAdicionalesAvanzado/actualizar/${variacion.id}`,
            payload,
            { headers }
        );

        if (data.message === "registro exitoso") {
            Swal.fire({
                title: "Exito",
                text: "Se ha actualizado correctamente",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
            setCreate(null);
        } else if (
            data.message === "Esas fechas ya se encuentran en otra variacion"
        ) {
            Swal.fire({
                title: "Error",
                text: "Esas fechas ya se encuentran en otra variacion",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            Swal.fire({
                title: "Error",
                text: `${data.error}`,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    const createNights = () => {
        if (desde === "" || hasta === "") {
            Swal.fire({
                title: "Error",
                text: "Debe ingresar una fecha",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else if (precio < 1 || precio === "") {
            Swal.fire({
                title: "Error",
                text: "Debe ingresar un precio",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else if (new Date(desde) <= new Date(hasta)) {
            const payload = {
                fecha_inicio: desde,
                fecha_final: hasta,
                precio: precio,
                id_productos_servicios_adicionales:
                    variacion.id_productos_servicios_adicionales,
                id_usuario: authUser.id_usuario,
                id_productos: producto.data.id,
                nombre: nombre,
            };
            updateProduct(payload);
        } else {
            Swal.fire({
                title: "Error",
                text: "La fecha final debe ser mayor a la fecha inicial",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed z-10 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto  sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <header className="mb-3 mr-auto text-base font-bold lg:text-2xl">
                            Nueva variación
                        </header>

                        <div className="flex w-11/12 ">
                            <div className="p-2">
                                <div>
                                    <div>
                                        <label className="p-2 text-base font-semibold  ">
                                            Desde
                                        </label>

                                        <DatePicker
                                            className="p-2  rounded-lg bg-white border w-11/12 cursor-pointer"
                                            placeholderText="Seleccionar fecha"
                                            locale="es"
                                            autoComplete="off"
                                            name="fechaDesde"
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                desde !== ""
                                                    ? new Date(
                                                          dividirFechaDatePicker(
                                                              desde
                                                          )[0],
                                                          dividirFechaDatePicker(
                                                              desde
                                                          )[1] - 1,
                                                          dividirFechaDatePicker(
                                                              desde
                                                          )[2]
                                                      )
                                                    : desde
                                            }
                                            onChange={(e) =>
                                                setdesde(
                                                    `${e.getFullYear()}-${`${
                                                        e.getMonth() + 1
                                                    }`.padStart(
                                                        2,
                                                        "0"
                                                    )}-${`${e.getDate()}`.padStart(
                                                        2,
                                                        "0"
                                                    )}`
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <label className="p-2 text-base font-semibold">
                                            Hasta
                                        </label>

                                        <DatePicker
                                            className="p-2  rounded-lg bg-white border w-11/12 cursor-pointer"
                                            placeholderText="Seleccionar fecha"
                                            locale="es"
                                            autoComplete="off"
                                            name="fechaDesde"
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                hasta !== ""
                                                    ? new Date(
                                                          dividirFechaDatePicker(
                                                              hasta
                                                          )[0],
                                                          dividirFechaDatePicker(
                                                              hasta
                                                          )[1] - 1,
                                                          dividirFechaDatePicker(
                                                              hasta
                                                          )[2]
                                                      )
                                                    : hasta
                                            }
                                            onChange={(e) =>
                                                sethasta(
                                                    `${e.getFullYear()}-${`${
                                                        e.getMonth() + 1
                                                    }`.padStart(
                                                        2,
                                                        "0"
                                                    )}-${`${e.getDate()}`.padStart(
                                                        2,
                                                        "0"
                                                    )}`
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <label className="p-2 text-base font-semibold">
                                            <span className="capitalize">
                                                Precio
                                            </span>{" "}
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                setPrecio(e.target.value)
                                            }
                                            value={precio}
                                            type="number"
                                            placeholder="5"
                                            className="p-2 ml-2 mt-1 rounded-lg border border-[#E9E9E9] outline-none w-16"
                                        />{" "}
                                        <span className=" text-base font-semibold">
                                            ({moneda})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="pb-3 text-center ">
                                            <button
                                                onClick={() => createNights()}
                                                className="w-full rounded-lg  bg-blue-bluecorner py-3 font-semibold text-white mt-3">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => setCreate(null)}
                            className="mt-6 text-base leading-none text-center text-gray-800 border-b border-transparent cursor-pointer dark:text-white dark:hover:border-white focus:outline-none hover:border-gray-800 focus:border-gray-800">
                            Cancelar
                        </button>
                        <button
                            onClick={() => setCreate(null)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditServiciosAvanzados;
