import React, { useEffect, useState } from "react";

import NavBar from "../NavBar";
import Footer from "../Footer";

import { Helmet } from "react-helmet";
import axios from "axios";

const getData = async () => {
    const json = await axios.post(
        `${process.env.REACT_APP_PROXY}/pagoCuotealo/pago`
    );

    let jsonData = json.data;

    jsonData = JSON.stringify(jsonData);

    const form = document.createElement("form");
    form.method = "POST";
    //form.action = "https://wapceu2pxtid01.azurewebsites.net/inicio"; // Ambiente de desarrollo
    form.action = "https://www.cuotealo.viabcp.com/inicio"; // Ambiente de produccion

    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "data";
    hiddenField.value = jsonData; // Usar la variable jsonData aquí

    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
};

const Cuotealo = () => {
    return (
        <div>
            <NavBar />
            <Helmet>
                <link
                    rel="canonical"
                    href="https://example.com/dresses/green-dresses"
                />
                <meta
                    name="description"
                    content={`Blue Corner, Alquileres, Embarcaciones, Alojamientos`}
                />
            </Helmet>
            <div className="p-10 bg-blue-300 rounded-lg mt-32">
                {" "}
                <button
                    onClick={getData}
                    className="bg-blue-500 text-white hover:scale-150 cursor-pointer p-4 rounded-lg "
                >
                    Cuotealo prod!
                </button>
            </div>

            <Footer />
        </div>
    );
};

export default Cuotealo;
