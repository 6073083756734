import React from "react";

const ModalEmpleadosFallidos = ({
    modalVisible,
    setModalVisible,
    empleadosCargados,
    empleadosFallidos,
}) => {
    const handleClose = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}
        >
            <div className="fixed bottom-0 left-0 w-full z-20 h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 sm:py-8 ">
                    <div className="relative w-full block justify-center px-4 bg-white rounded-lg  md:w-auto dark:bg-white md:px-14 h-[calc(100vh-10vh)] overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-3 right-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                        >
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mt-20 text-lg font-medium">
                            Usuarios cargados masivamente
                        </header>
                        {empleadosCargados.length > 0 && (
                            <div className="text-base py-7">
                                <span className="font-medium">
                                    Los siguientes usuarios fueron cargados
                                    correctamente:
                                </span>
                                <ul className="mt-4">
                                    {empleadosCargados.map(
                                        (empleado, index) => (
                                            <li className="mt-2" key={index}>
                                                {empleado.nombre}
                                            </li>
                                        )
                                    )}
                                </ul>

                                <div className="flex flex-col mb-5 mt-5">
                                    <label
                                        htmlFor="celular"
                                        className="mb-1 text-sm tracking-wide text-blue-600"
                                    >
                                        Su contraseña por defecto es
                                        <b> bluecorner</b>
                                    </label>
                                </div>
                            </div>
                        )}
                        {empleadosFallidos.length > 0 && (
                            <div className="text-base pb-4">
                                <span className="font-medium">
                                    Se encontraron errores en los siguientes
                                    usuarios:{" "}
                                </span>
                                <ul className="mt-4">
                                    {empleadosFallidos.map(
                                        (empleado, index) => (
                                            <li className="mt-2" key={index}>
                                                {empleado.errorMessage}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}
                        <button
                            onClick={handleClose}
                            className="mb-5 mx-auto w-full justify-center hover:scale-105 border rounded-lg border-blue-bluecorner p-3 flex items-center"
                        >
                            Aceptar y cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEmpleadosFallidos;
