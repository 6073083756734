import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useColores from "../hooks/useColores";
import BLUECORNER_PNG from "../img/Home/BLUECORNER_PNG.webp";

const Footer = ({ showWspText = null, messageWhatsapp = "" }) => {
    const [ventana, setVentana] = useState(false);
    const [input, setInput] = useState("");
    const [linkWhatsapp, setLinkWhatsapp] = useState("");
    const { primario, secundario, setColores } = useColores();
    const getBackgroundEmpresa = localStorage.getItem("background_navbar");
    const { idEmpresa } = useParams();
    const [background, setBackground] = useState(null);
    const [buttonChatOpened, setbuttonChatOpened] = useState(false);

    useLayoutEffect(() => {
        if (idEmpresa) {
            setBackground(getBackgroundEmpresa);
        }
        if (showWspText) {
            setTimeout(() => {
                setVentana(true);
            }, 5000);
        }
    }, []);

    useEffect(() => {
        if (messageWhatsapp === "") {
            setLinkWhatsapp(`https://wa.me/51941110270?text=${input}`);
        }

        if (messageWhatsapp === "halloween") {
            setLinkWhatsapp(`https://wa.link/a9ltbk`);
        }

        if (messageWhatsapp === "ano-nuevo") {
            setLinkWhatsapp(`https://wa.link/pwwmx0`);
        }
    }, [messageWhatsapp]);

    return (
        <div
            style={{ backgroundColor: background ? background : primario }}
            className={`flex flex-col  text-white p-4`}
        >
            <div className="z-10">
                {false && (
                    <div
                        style={{
                            backgroundImage:
                                "url(https://i.pinimg.com/474x/85/70/f6/8570f6339d3189c96e340d47a581d3b8.jpg)",
                        }}
                        className="w-72 h-64 bg-[#e6ddd4] fixed z-20 bottom-28 right-8 rounded-lg ease-in  duration-700  "
                    >
                        <div
                            // onClick={() => setVentana(false)}
                            className="bg-[#30534b] h-6 w-6 rounded-full absolute right-1 top-1 flex justify-center items-center cursor-pointer"
                        >
                            <svg
                                className="w-5 h-5 text-white hover:animate-spin"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <line x1="18" y1="6" x2="6" y2="18" />{" "}
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </div>
                        <div className="w-10/12 h-20 mt-5 ml-3 text-sm bg-white rounded-lg">
                            <p className="p-2 text-black">
                                {/* Hola, ¿cómo te puedo ayudar? 😊 */}
                                Estas interesado en alquilar o recibir
                                información? Chatea con uno de nuestros asesores
                            </p>
                        </div>
                        <div className="bg-[#f0f0f0] h-16 w-full absolute bottom-0 rounded-b-lg flex justify-evenly  items-center text-sm">
                            <input
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                type="text"
                                autoComplete="off"
                                className="w-9/12 h-12 px-4 py-2 text-black bg-white outline-none rounded-3xl focus:outline-none"
                            />
                        </div>
                    </div>
                )}

                <a href={linkWhatsapp} target="_blank" rel="noreferrer">
                    <div className="fixed select-none bottom-5 right-5 xs:right-10 bg-[#00BB22] shadow-lg rounded-full p-1.5 pl-2 pb-2 flex justify-center items-center hover:scale-110 transition animate-bounce">
                        <div
                            className={`${
                                ventana ? "opacity-100" : "opacity-0 invisible"
                            } absolute top-1.5 -left-[11.9rem] bg-[#C58B68] transition-all ease-in max-w-max text-gray-600 p-2 py-3 rounded-md shadow-lg cursor-pointer`}
                        >
                            <span className=" h-0 w-0 absolute top-0 -right-5 z-40 border-b-[1.5rem] border-l-[1.5rem] border-t-[1.5rem] border-l-[#C58B68] border-t-transparent border-b-transparent"></span>
                            <p className="text-white">¿Podemos ayudarte?</p>
                        </div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="z-20 w-12 h-12 cursor-pointer"
                            viewBox="0 0 175.216 175.552"
                        >
                            <defs>
                                <linearGradient
                                    id="b"
                                    x1="85.915"
                                    x2="86.535"
                                    y1="32.567"
                                    y2="137.092"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop offset="0" stopColor="#57d163" />
                                    <stop offset="1" stopColor="#23b33a" />
                                </linearGradient>
                                <filter
                                    id="a"
                                    width="1.115"
                                    height="1.114"
                                    x="-.057"
                                    y="-.057"
                                    colorInterpolationFilters="sRGB"
                                >
                                    <feGaussianBlur stdDeviation="3.531" />
                                </filter>
                            </defs>
                            <path
                                fill="#b3b3b3"
                                d="m54.532 138.45 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.523h.023c33.707 0 61.139-27.426 61.153-61.135.006-16.335-6.349-31.696-17.895-43.251A60.75 60.75 0 0 0 87.94 25.983c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.558zm-40.811 23.544L24.16 123.88c-6.438-11.154-9.825-23.808-9.821-36.772.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954zm0 0"
                                filter="url(#a)"
                            />
                            <path
                                fill="#fff"
                                d="m12.966 161.238 10.439-38.114a73.42 73.42 0 0 1-9.821-36.772c.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954z"
                            />
                            <path
                                fill="url(#linearGradient1780)"
                                d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.559 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.524h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.929z"
                            />
                            <path
                                fill="url(#b)"
                                d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.313-6.179 22.558 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.517 31.126 8.523h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.928z"
                            />
                            <path
                                fill="#fff"
                                fillRule="evenodd"
                                d="M68.772 55.603c-1.378-3.061-2.828-3.123-4.137-3.176l-3.524-.043c-1.226 0-3.218.46-4.902 2.3s-6.435 6.287-6.435 15.332 6.588 17.785 7.506 19.013 12.718 20.381 31.405 27.75c15.529 6.124 18.689 4.906 22.061 4.6s10.877-4.447 12.408-8.74 1.532-7.971 1.073-8.74-1.685-1.226-3.525-2.146-10.877-5.367-12.562-5.981-2.91-.919-4.137.921-4.746 5.979-5.819 7.206-2.144 1.381-3.984.462-7.76-2.861-14.784-9.124c-5.465-4.873-9.154-10.891-10.228-12.73s-.114-2.835.808-3.751c.825-.824 1.838-2.147 2.759-3.22s1.224-1.84 1.836-3.065.307-2.301-.153-3.22-4.032-10.011-5.666-13.647"
                            />
                        </svg>
                    </div>
                </a>
            </div>
            <div className="flex flex-col w-4/5 mx-auto max-w-7xl">
                <div className=" w-full mt-10 md:grid grid-cols-[80%,20%]">
                    <img
                        width="2364px"
                        height="800px"
                        alt="Logo bluecorner"
                        src={BLUECORNER_PNG}
                        className="mx-auto cursor-pointer w-96 md:h-32 mt-15 "
                    />
                    <div className="text-center lg:text-left lg:-ml-6 lg1170:-ml-3 xl:ml-3 2xl:ml-5 md:mt-8">
                        <h1 className="mb-2 ml-2 text-base font-medium">
                            SÍGUENOS
                        </h1>
                        <div className="flex justify-center lg:justify-start ">
                            <a
                                aria-label="icono instagram"
                                href="https://www.instagram.com/bluecorner.com.pe/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <svg
                                    className="mx-2 text-white transition ease-in cursor-pointer w-9 h-9 hover:scale-110"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <rect
                                        x="2"
                                        y="2"
                                        width="20"
                                        height="20"
                                        rx="5"
                                        ry="5"
                                    />{" "}
                                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
                                    <line
                                        x1="17.5"
                                        y1="6.5"
                                        x2="17.51"
                                        y2="6.5"
                                    />
                                </svg>
                            </a>
                            <a
                                aria-label="icono facebook"
                                href="https://www.facebook.com/bluecorner.com.pe"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <svg
                                    className="w-8 h-8 mx-2 text-white transition ease-in cursor-pointer hover:scale-110"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                                </svg>
                            </a>
                            <a
                                aria-label="icono linkedin"
                                href="https://www.linkedin.com/company/bluecorner/?originalSubdomain=pe"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <svg
                                    className="w-8 h-8 mx-2 text-white transition ease-in cursor-pointer hover:scale-110"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />{" "}
                                    <rect x="2" y="9" width="4" height="12" />{" "}
                                    <circle cx="4" cy="4" r="2" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="w-full mx-auto text-sm lg:grid lg:grid-cols-5">
                    <div className="flex flex-col mx-auto my-4 font-semibold text-center lg:text-left ">
                        <h1 className="text-base font-medium">BLUE CORNER</h1>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/terminos-y-condiciones"}>
                                Términos y Condiciones
                            </Link>
                        </span>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/politicas-de-uso-y-privacidad"}>
                                Políticas de uso y privacidad
                            </Link>
                        </span>
                        {/* <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/terminos-y-condiciones/loyalty"}>
                                Bluecoins
                            </Link>
                        </span> */}
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <a
                                href="https://share.hsforms.com/18DtQwHegS92opYQKMBFwXg1yt7u"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Libro de reclamaciones
                            </a>
                        </span>
                    </div>
                    <div className="flex flex-col mx-auto my-4 font-semibold text-center lg:text-left ">
                        <h1 className="text-base font-medium">CLIENTES</h1>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/alquiler-de-alojamientos"}>
                                Alojamientos{" "}
                            </Link>
                        </span>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/alquiler-de-embarcaciones"}>
                                Embarcaciones{" "}
                            </Link>
                        </span>

                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/urban-kitchen"}>
                                Experiencias gastronómicas
                            </Link>
                        </span>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/membresias"}>Membresías</Link>
                        </span>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/eventos-corporativos"}>
                                Eventos corporativos
                            </Link>
                        </span>
                    </div>
                    <div className="flex flex-col mx-auto my-4 font-semibold text-center lg:text-left ">
                        <h1 className="text-base font-medium">ALIADOS</h1>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to="/conviertete-en-aliado">
                                ¿Quiéres ser nuestro aliado?
                            </Link>
                        </span>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to="/aliado-para-embarcaciones">
                                Propietario de embarcaciones
                            </Link>
                        </span>

                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to="/paquetes-de-contenido">
                                Paquetes de contenido
                            </Link>
                        </span>
                    </div>
                    <div className="flex flex-col mx-auto my-4 font-semibold text-center lg:text-left">
                        <h1 className="text-base font-medium">COMUNIDAD</h1>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/contacto"}>Contáctanos</Link>
                        </span>
                        <span className="my-2 transition ease-in cursor-pointer hover:scale-110">
                            <Link to={"/blog"}>Blog</Link>
                        </span>
                    </div>
                    <div className="flex flex-col items-center w-full mx-auto my-4 font-semibold ">
                        <div className="flex flex-col ">
                            <h1 className="text-base font-medium text-center lg:text-left ">
                                CONTACTO
                            </h1>
                            <div className="flex flex-col w-full mx-auto text-center lg:text-left ">
                                <span className="flex items-center justify-center gap-1 my-2 lg:justify-start">
                                    <svg
                                        className="w-8 h-8"
                                        fill="#ffffff"
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="180px"
                                        height="180px"
                                        viewBox="-3.68 -3.68 38.03 38.03"
                                        stroke="#ffffff"
                                        strokeWidth="0.00030667000000000004"
                                        transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
                                    >
                                        <g
                                            id="SVGRepo_bgCarrier"
                                            strokeWidth="0"
                                        ></g>
                                        <g
                                            id="SVGRepo_tracerCarrier"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            stroke="#CCCCCC"
                                            strokeWidth="0.061334"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <g>
                                                <path d="M30.667,14.939c0,8.25-6.74,14.938-15.056,14.938c-2.639,0-5.118-0.675-7.276-1.857L0,30.667l2.717-8.017 c-1.37-2.25-2.159-4.892-2.159-7.712C0.559,6.688,7.297,0,15.613,0C23.928,0.002,30.667,6.689,30.667,14.939z M15.61,2.382 c-6.979,0-12.656,5.634-12.656,12.56c0,2.748,0.896,5.292,2.411,7.362l-1.58,4.663l4.862-1.545c2,1.312,4.393,2.076,6.963,2.076 c6.979,0,12.658-5.633,12.658-12.559C28.27,8.016,22.59,2.382,15.61,2.382z M23.214,18.38c-0.094-0.151-0.34-0.243-0.708-0.427 c-0.367-0.184-2.184-1.069-2.521-1.189c-0.34-0.123-0.586-0.185-0.832,0.182c-0.243,0.367-0.951,1.191-1.168,1.437 c-0.215,0.245-0.43,0.276-0.799,0.095c-0.369-0.186-1.559-0.57-2.969-1.817c-1.097-0.972-1.838-2.169-2.052-2.536 c-0.217-0.366-0.022-0.564,0.161-0.746c0.165-0.165,0.369-0.428,0.554-0.643c0.185-0.213,0.246-0.364,0.369-0.609 c0.121-0.245,0.06-0.458-0.031-0.643c-0.092-0.184-0.829-1.984-1.138-2.717c-0.307-0.732-0.614-0.611-0.83-0.611 c-0.215,0-0.461-0.03-0.707-0.03S9.897,8.215,9.56,8.582s-1.291,1.252-1.291,3.054c0,1.804,1.321,3.543,1.506,3.787 c0.186,0.243,2.554,4.062,6.305,5.528c3.753,1.465,3.753,0.976,4.429,0.914c0.678-0.062,2.184-0.885,2.49-1.739 C23.307,19.268,23.307,18.533,23.214,18.38z"></path>{" "}
                                            </g>
                                        </g>
                                    </svg>
                                    941 110 270
                                </span>
                                <span className="flex items-stretch justify-center gap-1 my-2 lg:justify-start">
                                    <svg
                                        className="h-7 w-7  text-white"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <circle cx="12" cy="12" r="10" />{" "}
                                        <polyline points="12 6 12 12 16 14" />
                                    </svg>
                                    <span className="-translate-y-1 ml-1 ">
                                        Lunes - Viernes<br></br>9 AM - 9 PM
                                        <br></br>
                                        Sábados <br></br>9 AM - 6 PM
                                    </span>
                                </span>

                                <div className="flex items-center justify-center gap-1 my-2 lg:justify-start">
                                    <svg
                                        className="w-6 h-6 ml-1"
                                        fill="#ffffff"
                                        viewBox="0 0 1920 1920"
                                    >
                                        <g
                                            id="SVGRepo_bgCarrier"
                                            strokeWidth="0"
                                        ></g>
                                        <g
                                            id="SVGRepo_tracerCarrier"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path
                                                d="M0 1694.235h1920V226H0v1468.235ZM112.941 376.664V338.94H1807.06v37.723L960 1111.233l-847.059-734.57ZM1807.06 526.198v950.513l-351.134-438.89-88.32 70.475 378.353 472.998H174.042l378.353-472.998-88.32-70.475-351.134 438.89V526.198L960 1260.768l847.059-734.57Z"
                                                fillRule="evenodd"
                                            ></path>
                                        </g>
                                    </svg>
                                    <span className="">
                                        info@bluecorner.com.pe
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="w-4/5 mx-auto mt-5" />
            <div className="w-4/5 mx-auto mt-5 text-center md:flex md:mx-auto md:justify-between">
                <div className="font-semibold ">@ 2023 Blue Corner</div>
                {/* <div className="flex justify-center mt-2 md:-mt-2">
                    <a
                        aria-label="icono instagram"
                        href="https://www.instagram.com/bluecorner.com.pe/"
                        target="_blank">
                        <svg
                            className="mx-2 text-white transition ease-in cursor-pointer w-9 h-9 hover:scale-110"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <rect
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="5"
                                ry="5"
                            />{" "}
                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                        </svg>
                    </a>
                    <a
                        aria-label="icono facebook"
                        href="https://www.facebook.com/bluecorner.com.pe"
                        target="_blank">
                        <svg
                            className="w-8 h-8 mx-2 text-white transition ease-in cursor-pointer hover:scale-110"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                        </svg>
                    </a>
                    <a
                        aria-label="icono linkedin"
                        href="https://www.linkedin.com/company/bluecorner/?originalSubdomain=pe"
                        target="_blank">
                        <svg
                            className="w-8 h-8 mx-2 text-white transition ease-in cursor-pointer hover:scale-110"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />{" "}
                            <rect x="2" y="9" width="4" height="12" />{" "}
                            <circle cx="4" cy="4" r="2" />
                        </svg>
                    </a>
                </div> */}
            </div>
        </div>
    );
};

export default Footer;
