import React from "react";
import { Helmet } from "react-helmet";
import { normalizeString } from "../../helpers/normalizeString";

const HelmetCasa = ({ currentProduct }) => {
    return (
        <Helmet>
            <title>{currentProduct?.nombre || ""} - Blue Corner</title>
            <meta
                name="description"
                content={`${
                    currentProduct?.descripcion
                        ?.replace(/\n/g, "<br>")
                        .slice(0, 100) || ""
                } ...`}
            />
            <meta
                property="og:description"
                content={`${
                    currentProduct?.descripcion
                        ?.replace(/\n/g, "<br>")
                        .slice(0, 100) || ""
                } ...`}
            />
            <meta
                property="og:image"
                content={`${
                    process.env.REACT_APP_PROXY_ARCHIVOS
                }${"/bluecorner/v1/casa/getImagenPrincipal/"}${
                    currentProduct?.imagen_principal
                }`}
            />
            <meta
                property="og:title"
                content={`${currentProduct?.nombre || ""} - Blue Corner`}
            />
            <meta
                property="og:url"
                content={`https://www.bluecorner.com.pe/alojamientos/${
                    currentProduct?.id
                }/${normalizeString(currentProduct?.nombre)}`}
            />
            <link
                rel="canonical"
                href={`https://bluecorner.com.pe/alojamientos/${
                    currentProduct.id
                }/${normalizeString(currentProduct?.nombre)}`}
            />
        </Helmet>
    );
};

export default HelmetCasa;
