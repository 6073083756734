import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import SearchBar from "./SearchBar";
import { Paginado } from "../../helpers/Paginado";
import {
    clearAllUsers,
    getUsersPerPage,
    userSelected,
} from "../../../redux/actions/usersActions";
import axios from "axios";
import ModalEditUser from "./ModalEditUser";
import ModalEditPassword from "./ModalEditPassword";
import Spinner from "../../Spinner";
import ModalNewUser from "./ModalNewUser";

const token = localStorage.getItem("token");
const headers = {
    authorization: token,
};

const Usuarios = ({ setPanel, tipo, idUsuario }) => {
    const [modalNewUser, setModalNewUser] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [modalPasswordVisible, setModalPasswordVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [habilitados, setHabilitados] = useState(true);
    const [subPropietarios, setSubPropietarios] = useState({
        checking: true,
        subPropietarios: [],
    });
    const [idSelect, setIdSelect] = useState(0);

    const user = useSelector((state) => state.usersReducer.userSelected);

    let userShowingInPage = useSelector(
        (state) => state.usersReducer.usersInPage
    );
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const dispatch = useDispatch();
    const USERS_PER_PAGE = 25;

    const getAllSubPropietario = async () => {
        setSubPropietarios({ checking: true, subPropietarios: [] });
        const jsonembar = await axios.get(
            `${process.env.REACT_APP_PROXY}/subpropietario/all/${
                habilitados ? "Si" : "No"
            }/${idUsuario}/`,
            { headers }
        );

        setSubPropietarios({
            checking: false,
            subPropietarios: jsonembar.data,
        });
    };

    useEffect(() => {
        if (tipo !== "subAdmin") {
            dispatch(getUsersPerPage(1, "Si"));
        } else {
            getAllSubPropietario();
        }
    }, [dispatch]);

    useEffect(() => {
        if (tipo !== "subAdmin") {
            dispatch(clearAllUsers());
            if (habilitados) dispatch(getUsersPerPage(1, "Si"));
            else dispatch(getUsersPerPage(1, "No"));
        } else {
            setSubPropietarios([]);
            setTimeout(() => {
                getAllSubPropietario();
            }, 300);
        }
    }, [habilitados, dispatch]);

    const handleDelete = async (idUser) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el usuario a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}${
                            tipo === "subAdmin"
                                ? "/subpropietario/eliminar/"
                                : "/autenticacion/eliminar/"
                        }${idUser}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Usuario eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        if (tipo === "subAdmin") {
                            getAllSubPropietario();
                        } else dispatch(getUsersPerPage(1, "Si"));
                    });
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el usuario a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}${
                            tipo === "subAdmin"
                                ? "/subpropietario/restaurar/"
                                : "/autenticacion/restaurar/"
                        }${id}`,
                        {},
                        {
                            headers,
                        }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Usuario restaurado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });

                        if (tipo === "subAdmin") {
                            getAllSubPropietario();
                        } else dispatch(getUsersPerPage(1, "No"));
                    });
            }
        });
    };

    const handleEdit = (id) => {
        if (tipo === "subAdmin") {
            setIdSelect(id);
            setModalEditVisible(!modalEditVisible);
        } else {
            dispatch(userSelected(id));
            setModalEditVisible(!modalEditVisible);
        }
    };

    const handleEditPassword = (id) => {
        if (tipo === "subAdmin") {
            setIdSelect(id);
            setModalPasswordVisible(!modalPasswordVisible);
        } else {
            dispatch(userSelected(id));
            setModalPasswordVisible(!modalPasswordVisible);
        }
    };

    const pagination = (pageNumber) => {
        dispatch(clearAllUsers());
        if (habilitados) dispatch(getUsersPerPage(pageNumber, "Si"));
        else dispatch(getUsersPerPage(pageNumber, "No"));
        setPage(pageNumber);
    };

    return (
        <section className=" w-full md:pl-[13rem] md818:pl-[14rem] md920:pl-[14rem] lg:pl-[17rem] p-4">
            <div className="flex items-center justify-between w-full ">
                <h1 className="py-10 text-4xl  text-center">
                    {tipo === "subAdmin" ? "Administradores" : "Usuarios"}
                </h1>
                {tipo !== "subAdmin" ? (
                    <div className="mt-2 md:ml-10">
                        <button
                            className="flex w-8 h-8 md:w-auto md:h-auto items-center justify-center p-2 text-blue-500 border border-blue-500 rounded-lg group hover:bg-blue-500 hover:text-white"
                            // onClick={() => setPanel("añadir nuevo")}
                            onClick={() => setModalNewUser((prev) => !prev)}>
                            <svg
                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                            <h1 className="hidden md:flex">Añadir usuario</h1>
                        </button>
                    </div>
                ) : (
                    <div className="mt-2 md:ml-10">
                        <button
                            className="flex w-8 h-8 md:w-auto md:h-auto items-center justify-center p-2 text-blue-500 border border-blue-500 rounded-lg group hover:bg-blue-500 hover:text-white"
                            // onClick={() => setPanel("añadir nuevo")}
                            onClick={() => setModalNewUser((prev) => !prev)}>
                            <svg
                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                            <h1 className="hidden md:flex">
                                Añadir Administrador
                            </h1>
                        </button>
                    </div>
                )}
            </div>
            {/* <div
                className={`container flex items-center ${
                    tipo === "subAdmin" ? "justify-around" : "justify-between"
                } p-1 mx-auto`}> */}
            <div className="flex items-center md:p-4 justify-end w-full mt-6 md:mt-0 md:ml-5">
                {tipo !== "subAdmin" && (
                    <SearchBar
                        setfakeRefresh={setfakeRefresh}
                        fakeRefresh={fakeRefresh}
                        habilitados={habilitados}
                    />
                )}
                <button className="flex justify-center items-center border ml-5 w-8 h-8 md:w-11 md:h-11 rounded-lg border-blue-bluecorner relative">
                    <svg
                        onClick={() => setShowButtons((prev) => !prev)}
                        className="h-7 w-7 md:h-8 md:w-8 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                        />
                    </svg>
                    {showButtons && (
                        <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                            <div
                                onClick={() => {
                                    setHabilitados(true);
                                    setShowButtons((prev) => !prev);
                                    setPage(1);
                                }}
                                className=" w-32 h-11 mx-auto cursor-pointer
                                rounded-lg flex items-center justify-start p-1
                                ">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Habilitados
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    setHabilitados(false);
                                    setShowButtons((prev) => !prev);
                                    setPage(1);
                                }}
                                className=" w-32 h-11 mx-auto cursor-pointer
                                rounded-lg  flex items-center justify-start p-1
                                ">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Deshabilitados
                                </p>
                            </div>
                        </div>
                    )}
                </button>
            </div>
            {modalNewUser && (
                <ModalNewUser
                    modalVisible={modalNewUser}
                    setmodalVisible={setModalNewUser}
                    tipo={tipo}
                    idUsuario={idUsuario}
                />
            )}

            {(user?.user?.id || idSelect !== 0) && (
                <ModalEditUser
                    tipo={tipo}
                    subSelect={idSelect}
                    setIdSelect={setIdSelect}
                    setmodalVisible={setModalEditVisible}
                    modalVisible={modalEditVisible}
                    user={user.user}
                    actualPage={page}
                    getAllSubPropietario={getAllSubPropietario}
                />
            )}

            {(user?.user?.id || idSelect !== 0) && (
                <ModalEditPassword
                    tipo={tipo}
                    id={idSelect}
                    setIdSelect={setIdSelect}
                    setModalPasswordVisible={setModalPasswordVisible}
                    modalPasswordVisible={modalPasswordVisible}
                    user={user.user}
                    actualPage={page}
                    getAllSubPropietario={getAllSubPropietario}
                />
            )}
            <div className="flex justify-center items-center lg:py-[10px] ">
                <div className="flex text-center max-w-full overflow-x-auto w-full">
                    {(tipo !== "subAdmin" && userShowingInPage.total > 0) ||
                    subPropietarios?.subPropietarios?.length > 0 ? (
                        <div className="w-full">
                            <table className="mt-14 w-full overflow-x-auto">
                                <thead className="text-left">
                                    <tr>
                                        <th className="px-4 w-full min-w-[8rem] md:w-2/6 ">
                                            Nombre
                                        </th>

                                        <th className="w-2.5/6 min-w-[10rem] hidden md:table-cell">
                                            Correo electrónico
                                        </th>
                                        {tipo === "subAdmin" && (
                                            <>
                                                <th className="w-2.5/6 min-w-[10rem] hidden md:table-cell">
                                                    DNI
                                                </th>
                                                <th className="w-2.5/6 min-w-[10rem] hidden md:table-cell">
                                                    Celular
                                                </th>
                                                <th className="w-2.5/6 min-w-[10rem] hidden md:table-cell">
                                                    Dirección
                                                </th>
                                            </>
                                        )}
                                        {tipo !== "subAdmin" && (
                                            <th className="w-2.5/6 min-w-[10rem] hidden lg:table-cell">
                                                Tipo de usuario
                                            </th>
                                        )}
                                        <th
                                            align="center"
                                            className="w-[8%] min-w-[10rem] ">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-left">
                                    {tipo !== "subAdmin"
                                        ? userShowingInPage?.data?.map(
                                              (user) => {
                                                  return (
                                                      <tr
                                                          key={user.id}
                                                          className="border-t w-full">
                                                          <td className="p-5 flex">
                                                              {user.nombre +
                                                                  " " +
                                                                  user.apellido_paterno +
                                                                  " "}
                                                              {
                                                                  user.apellido_materno
                                                              }
                                                          </td>
                                                          <td className="hidden md:table-cell">
                                                              {user.correo
                                                                  ? user.correo
                                                                  : "No posee correo electrónico"}
                                                          </td>
                                                          <td className="hidden lg:table-cell">
                                                              {user.tipo_usuario ===
                                                                  1 &&
                                                                  "Administrador"}
                                                              {user.tipo_usuario ===
                                                                  2 &&
                                                                  "Gestor de tienda"}
                                                          </td>
                                                          <td>
                                                              {habilitados ? (
                                                                  <div className="flex justify-center ml-9">
                                                                      <button
                                                                          className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                          title="Editar"
                                                                          onClick={() =>
                                                                              handleEdit(
                                                                                  tipo !==
                                                                                      "subAdmin"
                                                                                      ? user.id
                                                                                      : user
                                                                              )
                                                                          }>
                                                                          <svg
                                                                              className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth="2"
                                                                              stroke="currentColor"
                                                                              fill="none"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round">
                                                                              {" "}
                                                                              <path
                                                                                  stroke="none"
                                                                                  d="M0 0h24v24H0z"
                                                                              />{" "}
                                                                              <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                                              <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                                              <line
                                                                                  x1="16"
                                                                                  y1="5"
                                                                                  x2="19"
                                                                                  y2="8"
                                                                              />
                                                                          </svg>
                                                                      </button>
                                                                      <button
                                                                          className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                          title="Cambiar
                                                                          contraseña"
                                                                          onClick={() =>
                                                                              handleEditPassword(
                                                                                  user.id
                                                                              )
                                                                          }>
                                                                          <svg
                                                                              className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                              width="24"
                                                                              height="24"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth="2"
                                                                              stroke="currentColor"
                                                                              fill="none"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round">
                                                                              {" "}
                                                                              <path
                                                                                  stroke="none"
                                                                                  d="M0 0h24v24H0z"
                                                                              />{" "}
                                                                              <rect
                                                                                  x="5"
                                                                                  y="11"
                                                                                  width="14"
                                                                                  height="10"
                                                                                  rx="2"
                                                                              />{" "}
                                                                              <circle
                                                                                  cx="12"
                                                                                  cy="16"
                                                                                  r="1"
                                                                              />{" "}
                                                                              <path d="M8 11v-5a4 4 0 0 1 8 0" />
                                                                          </svg>
                                                                      </button>
                                                                      <button
                                                                          className="hidden md:flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                          onClick={() =>
                                                                              handleDelete(
                                                                                  user.id
                                                                              )
                                                                          }
                                                                          title="Eliminar">
                                                                          <svg
                                                                              className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                              width="24"
                                                                              height="24"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth="2"
                                                                              stroke="currentColor"
                                                                              fill="none"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round">
                                                                              {" "}
                                                                              <path
                                                                                  stroke="none"
                                                                                  d="M0 0h24v24H0z"
                                                                              />{" "}
                                                                              <line
                                                                                  x1="4"
                                                                                  y1="7"
                                                                                  x2="20"
                                                                                  y2="7"
                                                                              />{" "}
                                                                              <line
                                                                                  x1="10"
                                                                                  y1="11"
                                                                                  x2="10"
                                                                                  y2="17"
                                                                              />{" "}
                                                                              <line
                                                                                  x1="14"
                                                                                  y1="11"
                                                                                  x2="14"
                                                                                  y2="17"
                                                                              />{" "}
                                                                              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                          </svg>
                                                                      </button>
                                                                  </div>
                                                              ) : (
                                                                  <div className="flex justify-center ml-5 ">
                                                                      <button
                                                                          onClick={() =>
                                                                              handleEnable(
                                                                                  user.id
                                                                              )
                                                                          }
                                                                          title="Habilitar"
                                                                          className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                          <svg
                                                                              className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                              viewBox="0 0 24 24"
                                                                              fill="none"
                                                                              stroke="currentColor"
                                                                              strokeWidth="2"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round">
                                                                              {" "}
                                                                              <polyline points="17 1 21 5 17 9" />{" "}
                                                                              <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                              <polyline points="7 23 3 19 7 15" />{" "}
                                                                              <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                          </svg>
                                                                      </button>
                                                                  </div>
                                                              )}
                                                          </td>
                                                      </tr>
                                                  );
                                              }
                                          )
                                        : subPropietarios?.subPropietarios.map(
                                              (user) => {
                                                  return (
                                                      <tr
                                                          key={user.id}
                                                          className="border-t w-full">
                                                          <td className="p-5 flex">
                                                              {user.nombre +
                                                                  " " +
                                                                  user.apellido_paterno +
                                                                  " "}
                                                              {
                                                                  user.apellido_materno
                                                              }
                                                          </td>
                                                          <td className="hidden md:table-cell">
                                                              {user.correo}
                                                          </td>
                                                          <>
                                                              <td className="hidden md:table-cell">
                                                                  {user.dni}
                                                              </td>
                                                              <td className="hidden md:table-cell">
                                                                  {user.celular}
                                                              </td>
                                                              <td className="hidden md:table-cell">
                                                                  {
                                                                      user.direccion
                                                                  }
                                                              </td>
                                                          </>
                                                          <td className="">
                                                              {habilitados ? (
                                                                  <div className="flex justify-center ml-9">
                                                                      <button
                                                                          className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                          title="Editar"
                                                                          onClick={() =>
                                                                              handleEdit(
                                                                                  tipo !==
                                                                                      "subAdmin"
                                                                                      ? user.id
                                                                                      : user
                                                                              )
                                                                          }>
                                                                          <svg
                                                                              className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth="2"
                                                                              stroke="currentColor"
                                                                              fill="none"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round">
                                                                              {" "}
                                                                              <path
                                                                                  stroke="none"
                                                                                  d="M0 0h24v24H0z"
                                                                              />{" "}
                                                                              <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                                              <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                                              <line
                                                                                  x1="16"
                                                                                  y1="5"
                                                                                  x2="19"
                                                                                  y2="8"
                                                                              />
                                                                          </svg>
                                                                      </button>
                                                                      <button
                                                                          className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                          title="Cambiar contraseña"
                                                                          onClick={() =>
                                                                              handleEditPassword(
                                                                                  user.id
                                                                              )
                                                                          }>
                                                                          <svg
                                                                              className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                              width="24"
                                                                              height="24"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth="2"
                                                                              stroke="currentColor"
                                                                              fill="none"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round">
                                                                              {" "}
                                                                              <path
                                                                                  stroke="none"
                                                                                  d="M0 0h24v24H0z"
                                                                              />{" "}
                                                                              <rect
                                                                                  x="5"
                                                                                  y="11"
                                                                                  width="14"
                                                                                  height="10"
                                                                                  rx="2"
                                                                              />{" "}
                                                                              <circle
                                                                                  cx="12"
                                                                                  cy="16"
                                                                                  r="1"
                                                                              />{" "}
                                                                              <path d="M8 11v-5a4 4 0 0 1 8 0" />
                                                                          </svg>
                                                                      </button>
                                                                      <button
                                                                          className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                          onClick={() =>
                                                                              handleDelete(
                                                                                  user.id
                                                                              )
                                                                          }
                                                                          title="Eliminar">
                                                                          <svg
                                                                              className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                              width="24"
                                                                              height="24"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth="2"
                                                                              stroke="currentColor"
                                                                              fill="none"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round">
                                                                              {" "}
                                                                              <path
                                                                                  stroke="none"
                                                                                  d="M0 0h24v24H0z"
                                                                              />{" "}
                                                                              <line
                                                                                  x1="4"
                                                                                  y1="7"
                                                                                  x2="20"
                                                                                  y2="7"
                                                                              />{" "}
                                                                              <line
                                                                                  x1="10"
                                                                                  y1="11"
                                                                                  x2="10"
                                                                                  y2="17"
                                                                              />{" "}
                                                                              <line
                                                                                  x1="14"
                                                                                  y1="11"
                                                                                  x2="14"
                                                                                  y2="17"
                                                                              />{" "}
                                                                              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                          </svg>
                                                                      </button>
                                                                  </div>
                                                              ) : (
                                                                  <div className="flex justify-center ml-5 ">
                                                                      <button
                                                                          onClick={() =>
                                                                              handleEnable(
                                                                                  user.id
                                                                              )
                                                                          }
                                                                          title="Habilitar"
                                                                          className="flex w-8 h-8 items-center justify-center  mr-4  text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                          <svg
                                                                              className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                              viewBox="0 0 24 24"
                                                                              fill="none"
                                                                              stroke="currentColor"
                                                                              strokeWidth="2"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round">
                                                                              {" "}
                                                                              <polyline points="17 1 21 5 17 9" />{" "}
                                                                              <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                              <polyline points="7 23 3 19 7 15" />{" "}
                                                                              <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                          </svg>
                                                                      </button>
                                                                  </div>
                                                              )}
                                                          </td>
                                                      </tr>
                                                  );
                                              }
                                          )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={5}>
                                            <hr className=" mx-auto border-white mt-12 pl-4 xs:hidden "></hr>
                                            <div className="flex justify-center">
                                                {tipo !== "subAdmin" && (
                                                    <Paginado
                                                        elementsPerPage={
                                                            USERS_PER_PAGE
                                                        }
                                                        cantTotalElements={
                                                            userShowingInPage.total
                                                        }
                                                        cantCategoriesSearching={
                                                            userShowingInPage
                                                                ?.data?.length
                                                        }
                                                        pagination={pagination}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    ) : !subPropietarios.checking ? (
                        <h1>No se encontraron propietarios</h1>
                    ) : (
                        <Spinner margin_top={20} />
                    )}
                </div>
            </div>
        </section>
    );
};

export default Usuarios;
