import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../Spinner";

const token = localStorage.getItem("token");
const headers = {
    authorization: token,
};

let quillObj;
let formData = new FormData();

function undoChange() {
    this.quill.history.undo();
}
function redoChange() {
    this.quill.history.redo();
}

const imageHandler = async () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
        let file = input.files[0];

        formData.delete("imagen_entradas");
        formData.append("imagen_entradas", file);

        uploadFiles(formData, quillObj);
    };
};

const uploadFiles = async function (formData, quillObj) {
    document.getElementById("spiner").className = "mt-5";
    document.getElementById("contenido").className = "hidden";
    let json = await axios.post(
        `${process.env.REACT_APP_PROXY}/entrada/registrarImagen/`,
        formData,
        { headers }
    );
    console.log(json.data);
    if (json.data.message === "registro exitoso") {
        const range = quillObj.getEditorSelection();

        quillObj
            .getEditor()
            .insertEmbed(
                range.index,
                "image",
                `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/entrada/getImagenEntrada/${json.data.imagen}`
            );

        quillObj.getEditor().insertText(range.index + 1, "    ");

        document.getElementById("spiner").className = "hidden";
        document.getElementById("contenido").className = "mt-5";
    }
};

const CreateBlog = ({ setcreate, create }) => {
    const [description, setdescription] = useState("");
    const [title, settitle] = useState("");
    const [baner, setbaner] = useState("");
    const [preview, setPreview] = useState("");
    const [cargando, SetCargando] = useState(false);

    const handleChangeImage = (e) => {
        console.log(e.target.files[0]);
        setbaner(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview(objectUrl);
    };

    const addDetails = async (event) => {
        event.preventDefault();

        if (baner === "") {
            Swal.fire({
                title: "Error!",
                text: "Debes colocar un banner",
                icon: "error",

                confirmButtonText: "Aceptar",
            });
        }
        if (description === "") {
            Swal.fire({
                title: "Error!",
                text: "el contenido no puede estar vacio",
                icon: "error",

                confirmButtonText: "Aceptar",
            });
        }
        if (baner && description) {
            SetCargando(true);

            formData.append("banner_entradas", baner);
            formData.append("titulo", title);
            formData.append("descripcion", description);

            let json = await axios.post(
                `${process.env.REACT_APP_PROXY}/entrada/`,
                formData,
                { headers }
            );

            console.log(json.data);

            if (json.data.message === "registro exitoso") {
                Swal.fire({
                    title: "Procesado!",
                    text: "Entrada creada",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                formData.delete("banner_entradas");
                formData.delete("titulo");
                formData.delete("descripcion");
                formData.delete("imagen_entradas");
                setdescription("");
                setbaner("");
                settitle("");
                setPreview("");
                setTimeout(() => {
                    setcreate(!create);
                }, 500);
            }
        }
    };

    const onChangeText = (text) => {
        text = text !== "<p><br></p>" ? text : "";

        setdescription(text);
    };

    const Size = Quill.import("formats/size");
    Size.whitelist = ["extra-small", "small", "medium", "large"];
    Quill.register(Size, true);

    const Font = Quill.import("formats/font");
    Font.whitelist = [
        "arial",
        "comic-sans",
        "courier-new",
        "georgia",
        "helvetica",
        "Inter",
        "lucida",
    ];
    Quill.register(Font, true);

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "align",
        "strike",
        "script",
        "blockquote",
        "background",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "code-block",
    ];

    const modules = (props) => ({
        toolbar: {
            container: "#" + props,

            handlers: {
                undo: undoChange,
                redo: redoChange,
                image: imageHandler,
            },
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
    });

    return (
        <>
            <div className="w-full ">
                <div className="">
                    <div className="">
                        <form onSubmit={addDetails} className="">
                            <div className="flex items-center w-11/12 py-4 mx-auto text-lg sm:w-full sm:ml-6 mt-4">
                                <svg
                                    onClick={() => (
                                        setdescription(""),
                                        settitle(""),
                                        setPreview(""),
                                        setbaner(""),
                                        formData.delete("banner_entradas"),
                                        formData.delete("titulo"),
                                        formData.delete("descripcion"),
                                        formData.delete("imagen_entradas"),
                                        setcreate(!create)
                                    )}
                                    className="w-6 h-6 -ml-2 text-black cursor-pointer"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <polyline points="15 18 9 12 15 6" />
                                </svg>
                                <span
                                    onClick={() => (
                                        setdescription(""),
                                        settitle(""),
                                        setPreview(""),
                                        setbaner(""),
                                        formData.delete("banner_entradas"),
                                        formData.delete("titulo"),
                                        formData.delete("descripcion"),
                                        formData.delete("imagen_entradas"),
                                        setcreate(!create)
                                    )}
                                    className="mx-1 cursor-pointer">
                                    Regresar
                                </span>
                            </div>
                            <div className="w-11/12 mx-auto sm:w-7/12">
                                <div className="grid grid-cols-1">
                                    <label className="text-xl text-[#3C3C3C]">
                                        {" "}
                                        Título{" "}
                                    </label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={title}
                                        onChange={(e) =>
                                            settitle(e.target.value)
                                        }
                                        className="w-full h-12 p-4 mt-2 border border-[#E9E9E9] rounded-lg"
                                        placeholder="Título"
                                        required
                                    />
                                </div>

                                <div className="mt-5">
                                    <div className="mb-2">
                                        <label className="text-xl text-[#3C3C3C]">
                                            {" "}
                                            Imagen principal{" "}
                                        </label>
                                        {!baner ? (
                                            <div className="relative flex items-center justify-center sm:w-4/12 sm:min-w-[400px] h-64 border-gray-200 border rounded-lg hover:cursor-pointer my-5 py-4 px-6">
                                                <div className="absolute">
                                                    <div className="flex flex-col w-full mx-auto ">
                                                        <div className="relative ">
                                                            <span className="mt-2 font-normal text-gray-400 ">
                                                                Arrastre y
                                                                suelte una
                                                                imagen aquí
                                                            </span>

                                                            <span className="block font-normal text-center text-blue-400 ">
                                                                Buscar
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    name="icono_si"
                                                    className="w-full h-full opacity-0 cursor-pointer "
                                                    onChange={handleChangeImage}
                                                />
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="relative flex items-center justify-center sm:w-4/12 sm:min-w-[400px] h-64 border-gray-200 border rounded-lg hover:cursor-pointer my-5 py-4 px-6">
                                                    <div className="absolute bg-opacity-50 bg-slate-100">
                                                        <div>
                                                            <img
                                                                src={preview}
                                                                className="object-contain w-11/12 h-48 py-2 mx-auto mt-2"
                                                            />

                                                            <span className="text-base font-semibold ">
                                                                {baner?.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <input
                                                        accept="image/*"
                                                        type="file"
                                                        name="icono_si"
                                                        className="w-full h-full opacity-0 cursor-pointer "
                                                        onChange={
                                                            handleChangeImage
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <hr className="my-4 border-[#E9E9E9]"></hr>
                                </div>

                                <div id="spiner" className="hidden">
                                    <Spinner width={20} />
                                </div>
                                <label className="text-xl text-[#3C3C3C] py-5">
                                    {" "}
                                    Contenido{" "}
                                </label>
                                <div id="contenido" className="mt-5">
                                    <QuillToolbar toolbarId={"t1"} />
                                    <ReactQuill
                                        ref={(el) => {
                                            quillObj = el;
                                        }}
                                        theme="snow"
                                        value={description}
                                        onChange={onChangeText}
                                        placeholder={"Escribe algo..."}
                                        modules={modules("t1")}
                                        formats={formats}
                                        style={{
                                            backgroundColor: "white",
                                            height: "50vh",
                                        }}
                                    />
                                </div>

                                <div className="pb-6 mt-4 text-center">
                                    <button
                                        disabled={cargando}
                                        className="w-full p-3 text-center text-white border border-blue-400 rounded-md bg-blue-bluecorner xs:w-80 hover:bg-white hover:text-blue-bluecorner disabled:bg-blue-400">
                                        {cargando ? "Guardando..." : "Guardar"}
                                    </button>
                                </div>
                                <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

const CustomUndo = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
        <path
            className="ql-stroke"
            d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
        />
    </svg>
);

const CustomRedo = () => (
    <svg viewBox="0 0 18 18">
        <polygon
            className="ql-fill ql-stroke"
            points="12 10 14 12 16 10 12 10"
        />
        <path
            className="ql-stroke"
            d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
        />
    </svg>
);

const QuillToolbar = (props) => {
    return (
        <>
            {props.toolbarId !== undefined && (
                <div id={props.toolbarId}>
                    <span className="ql-formats">
                        <button className="ql-bold" />
                        <button className="ql-italic" />
                        <button className="ql-underline" />
                        <button className="ql-strike" />
                    </span>
                    <span className="ql-formats">
                        <select className="ql-font">
                            <option value="arial"> Arial </option>
                            <option value="comic-sans">Comic Sans</option>
                            <option value="courier-new">Courier New</option>
                            <option value="georgia">Georgia</option>
                            <option value="helvetica">Helvetica</option>
                            <option value="Inter" selected>
                                Inter
                            </option>
                            <option value="lucida">Lucida</option>
                        </select>
                        <select className="ql-size">
                            <option value="extra-small">Extra Small</option>
                            <option value="small">Small</option>
                            <option value="medium" selected>
                                Medium
                            </option>
                            <option value="large">Large</option>
                        </select>
                        <select className="ql-header">
                            <option value="1">Heading 1</option>
                            <option value="2">Heading 2</option>
                            <option value="3">Heading 3</option>
                            <option value="4">Heading 4</option>
                            <option value="5">Heading 5</option>
                            <option value="6">Heading 6</option>
                            <option value="" selected>
                                Normal
                            </option>
                        </select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-list" value="ordered" />
                        <button className="ql-list" value="bullet" />
                        <button className="ql-indent" value="-1" />
                        <button className="ql-indent" value="+1" />
                    </span>
                    <span className="ql-formats">
                        <button className="ql-script" value="super" />
                        <button className="ql-script" value="sub" />
                        <button className="ql-blockquote" />
                        <button className="ql-direction" />
                    </span>
                    <span className="ql-formats">
                        <select className="ql-align" />
                        <select className="ql-color" />
                        <select className="ql-background" />
                    </span>
                    <span className="ql-formats">
                        <button className="ql-link" />
                        <button className="ql-image" />
                        <button className="ql-video" />
                    </span>
                    <span className="ql-formats">
                        <button className="ql-formula" />
                        <button className="ql-code-block" />
                        <button className="ql-clean" />
                    </span>
                    <span className="ql-formats">
                        <button className="ql-undo">
                            <CustomUndo />
                        </button>
                        <button className="ql-redo">
                            <CustomRedo />
                        </button>
                    </span>
                </div>
            )}
        </>
    );
};

export default CreateBlog;
