import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const HouseModal = ({
    fieldSelect,
    setRefreshHouses,
    setShowModal,
    headers,
}) => {
    const { id, nombre, moneda_precio_base } = fieldSelect;
    const [monto, setMonto] = useState("");
    const [isPreeboking, setIsPreeboking] = useState(false);
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFinal, setFechaFinal] = useState("");

    const handleSave = async () => {
        console.log({
            fechaInicio,
            fechaFinal,
        });
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/casasSemanaSanta/`,
                {
                    id_casa: id,
                    preeboking: isPreeboking ? "Si" : "No",
                    precio: monto,
                    fecha_inicio: fechaInicio,
                    fecha_final: fechaFinal,
                },
                { headers }
            );
            console.log(data);
            Swal.fire({
                icon: "success",
                title: "Guardado con éxito",
                showConfirmButton: false,
                timer: 1000,
            });
            setRefreshHouses((prev) => !prev);
            setShowModal(false);
        } catch (error) {
            console.log(error.response);
        }
    };

    return (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full">
            <div className="absolute inset-0 bg-gray-900 bg-opacity-80"></div>
            <div className="relative z-50 w-full mx-auto bg-white rounded-lg md:max-w-md md:p-8">
                <button
                    onClick={() => setShowModal(false)}
                    className="absolute top-0 right-0 m-4 text-gray-800 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-800">
                    <svg
                        className="w-6 h-6 fill-current"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.4142 6.58579L10 9.99999L6.58578 6.58579L5.17157 7.99999L8.58578 11.4142L5.17157 14.8284L6.58578 16.2426L10 12.8284L13.4142 16.2426L14.8284 14.8284L11.4142 11.4142L14.8284 7.99999L13.4142 6.58579Z"
                            fill="currentColor"
                        />
                    </svg>
                </button>
                <div className="text-center">
                    <h2 className="mb-4 text-lg font-semibold">{nombre}</h2>
                    <div className="mb-4">
                        <label htmlFor="monto" className="block mb-2">
                            Monto:
                        </label>
                        <input
                            type="text"
                            id="monto"
                            value={monto}
                            onChange={(e) => setMonto(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-gray-500"
                        />
                        <span className="ml-2">{moneda_precio_base}</span>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="fechaInicio" className="block mb-2">
                            Fecha de inicio:
                        </label>
                        <input
                            type="date"
                            id="fechaInicio"
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-gray-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="fechaFinal" className="block mb-2">
                            Fecha final:
                        </label>
                        <input
                            type="date"
                            id="fechaFinal"
                            value={fechaFinal}
                            onChange={(e) => setFechaFinal(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-gray-500"
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="checkbox"
                            id="preeboking"
                            checked={isPreeboking}
                            onChange={(e) => setIsPreeboking(e.target.checked)}
                            className="mr-2"
                        />
                        <label htmlFor="preeboking">Preeboking</label>
                    </div>
                    <button
                        onClick={handleSave}
                        className="w-full px-4 py-2 mt-6 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HouseModal;
