import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Paginado } from "../helpers/Paginado";
import axios from "axios";
import Spinner from "../Spinner";
import { ShowStarsRating } from "../StarsRating";
import { useSelector } from "react-redux";

import NavbarEmpresas from "../dashboardEmpresas/NavbarEmpresas";

const Contador = ({ empresa = false }) => {
    const authUser = useSelector((state) => state.authReducer);
    const [page, setPage] = useState(1);
    const [showAlojamientos, setShowAlojamientos] = useState(
        authUser.tipo_usuario > 2 && authUser.casas > 0
            ? true
            : authUser.tipo_usuario > 2 && authUser.embarcaciones > 0
            ? false
            : true
    );
    const [habilitados, setHabilitados] = useState(true);
    const [reviewsShowingInPage, setReviewsShowingInPage] = useState({
        data: [],
        checking: true,
    });
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [modalDetail, setModalDetail] = useState(false);
    const [reviewSelected, setReviewSelected] = useState(null);
    const USERS_PER_PAGE = 25;

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const getAllReviews = async (pageNumber) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/modalContacto/detalle/`,
            { headers }
        );
        console.log(data);
        setReviewsShowingInPage({
            data: data,
            checking: false,
        });
    };

    useEffect(() => {
        getAllReviews();
    }, [habilitados, showAlojamientos]);

    const handleDelete = async (idReview) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminar la reseña?",
            text: "Se enviará a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/${
                            showAlojamientos
                                ? "reviewCasa"
                                : "reviewEmbarcacion"
                        }/eliminar/${idReview}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Reseña eliminada correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllReviews();
                    });
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitar la reseña?",
            text: "Se enviará a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/${
                            showAlojamientos
                                ? "reviewCasa"
                                : "reviewEmbarcacion"
                        }/restaurar/${id}`,
                        {},
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Reseña restaurada correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllReviews();
                    });
            }
        });
    };

    const reviewDetails = (review) => {
        setReviewSelected(review);
        setModalDetail((prev) => !prev);
    };

    const pagination = (pageNumber) => {
        getAllReviews(pageNumber);
        setPage(pageNumber);
    };

    return (
        <div className=" w-full md:pl-[12rem] md818:pl-[11rem] md920:pl-[11rem] lg:pl-[15rem] lg1170:pl-[16rem]  xl:pl-[16rem] ">
            <section className="w-full p-4 md:p-10 md:mx-auto">
                <h1 className="py-5 mx-4 text-4xl">Modal contacto</h1>

                <div className="flex flex-col pb-20">
                    {reviewsShowingInPage?.checking ? (
                        <Spinner margin_top={20} />
                    ) : (
                        <div className="w-full">
                            <table className="w-full mt-6 overflow-x-auto">
                                <thead className="text-left">
                                    <tr>
                                        <th
                                            className={
                                                authUser.tipo_usuario === 5
                                                    ? "hidden"
                                                    : "hidden md:table-cell w-full min-w-[10rem] md:w-2/12 "
                                            }>
                                            Modal contacto
                                        </th>
                                        <th className="w-full hidden md:table-cell min-w-[10rem] md:w-2/12 ">
                                            Enviados
                                        </th>
                                        <th className="w-full min-w-[20rem] md:w-4/12 hidden md:table-cell">
                                            Cerrados
                                        </th>
                                        <th className="w-full min-w-[14rem] md:w-2/12 hidden md:table-cell"></th>
                                        <th
                                            align="center"
                                            className="w-1/12 min-w-[8rem] hidden md:table-cell "></th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {reviewsShowingInPage?.data.map(
                                        (review) => (
                                            <tr
                                                key={review.id}
                                                className={`border-t w-full ${"cursor-pointer"}`}
                                                onClick={() =>
                                                    reviewDetails(review)
                                                }>
                                                <td
                                                    className={
                                                        authUser.tipo_usuario ===
                                                        5
                                                            ? "hidden"
                                                            : "hidden md:table-cell py-5"
                                                    }></td>
                                                <td className="table-cell">
                                                    {review.enviado}
                                                </td>
                                                <td className="table-cell ">
                                                    {review.cerrado}
                                                </td>
                                                <td className="hidden md:table-cell"></td>
                                                <td className="hidden md:table-cell"></td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};

export default Contador;
