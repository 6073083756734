const initialState = {
    allRules: [],
    rulesInPage: [],
    ruleSelected: {},
    checking: true,
};

export const rulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_RULES":
            return {
                ...state,
                allRules: action.payload,
                checking: false,
            };

        case "CLEAR_RULE":
            return {
                ...state,
                ruleSelected: {},
            };

        case "GET_ONE_RULE":
            return {
                ...state,
                ruleSelected: action.payload,
            };

        case "SEARCH_RULES":
            return {
                ...state,
                allRules: action.payload,
            };

        case "CLEAR_RULES":
            return {
                ...state,
                allRules: { total: 0, data: [] },
            };
        default:
            return state;
    }
};
