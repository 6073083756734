import React, { useEffect, useState } from "react";

import casa from "../../src/img/referidos/Casa-Federal.webp";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import CardAlquileres from "./pedidos/CardAlquileres";

const ModalHousesCoins = ({
    modalVisible,
    setModalVisible,
    userPoints,
    cliente,
}) => {
    const token = localStorage.getItem("token");

    const navigate = useNavigate();

    const handleClose = () => {
        setModalVisible(!modalVisible);
    };
    console.log("userPoints", userPoints);
    return (
        <div
            className={`relative items-center justify-center  ${
                modalVisible ? "flex z-30" : "hidden"
            }`}
        >
            <div className="fixed z-30 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className=" flex justify-end px-4 py-8">
                    <div className="relative w-full  md:w-9/12 xl:w-10/12 min-w-[300px] block  px-4 bg-white rounded-lg dark:bg-white  h-[calc(100vh-20vh)] p-10 overflow-y-auto mt-16">
                        <header className="mb-3 px-1 mr-auto text-base font-bold lg:text-xl flex justify-between">
                            <div>Espacio de referidos</div>
                            <div></div>
                        </header>
                        <button
                            onClick={() => handleClose()}
                            className="absolute text-gray-800 dark:text-gray-400 top-3 right-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <div className="flex justify-center items-start gap-20">
                            <div className="flex flex-col justify-center items-start">
                                <div className="flex flex-col justify-center items-center gap-6 mt-8 w-full">
                                    <p className="text-center font-semibold sm:text-xl">
                                        ¡Al tener 5 referidos tendrás un full
                                        day en la casa federal!
                                    </p>
                                    <div
                                        onClick={() =>
                                            window.open(
                                                `/alojamientos/30329/federal`,
                                                "_blank"
                                            )
                                        }
                                        className="relative rounded-lg cursor-pointer "
                                    >
                                        <img
                                            className="w-full max-w-3xl mx-auto h-80 object-cover rounded-lg"
                                            src={casa}
                                            alt="casa"
                                        />

                                        <div className="absolute bottom-0 left-0  w-full bg-blue-500 xs:p-2 rounded-b-lg">
                                            <div className="w-full h-10 xl:text-center">
                                                <p className=" text-base xs:text-xl sm:text-2xl text-white font-bold cursor-pointer">
                                                    Conócela aquí
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-center font-semibold sm:text-xl ">
                                        Pide a tus amigos que te refieran en sus
                                        reservas, colocando: <br /> tu correo:{" "}
                                        <span className="text-blue-500">
                                            {cliente.correo}
                                        </span>{" "}
                                        o tu DNI:{" "}
                                        <span className="text-blue-500">
                                            {cliente.dni}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div>
                                {" "}
                                <div className="mt-10 w-11/12 mx-auto">
                                    {userPoints.referidos?.array_referidos
                                        ?.length > 0 ? (
                                        <div>
                                            <hr className="my-2 border-t " />
                                            <p className="font-semibold text-xl xs:text-2xl text-center">
                                                Referidos confirmados
                                            </p>
                                            <div className="w-full bg-green-500 bg-opacity-10 rounded-md px-2">
                                                <table className="w-full mt-6 overflow-x-auto text-sm xs:text-base">
                                                    <thead className="text-left">
                                                        <tr>
                                                            <th className="  w-2/5 "></th>

                                                            <th className=" w-1/5  table-cell">
                                                                Cliente
                                                            </th>

                                                            <th className="w-1/5  table-cell">
                                                                Fecha
                                                            </th>

                                                            <th
                                                                className={`w-1/5  xs:table-cell hidden`}
                                                            >
                                                                Estado
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {userPoints.referidos?.array_referidos?.map(
                                                            (pedido) => (
                                                                <tr
                                                                    key={
                                                                        pedido.id
                                                                    }
                                                                    className={
                                                                        "w-full border-t cursor-pointer"
                                                                    }
                                                                    onClick={() =>
                                                                        navigate(
                                                                            "/" +
                                                                                pedido.link
                                                                        )
                                                                    }
                                                                >
                                                                    <td>
                                                                        <CardAlquileres
                                                                            key={
                                                                                pedido.id
                                                                            }
                                                                            pedido={
                                                                                pedido
                                                                            }
                                                                            tipo={
                                                                                pedido.tipo ===
                                                                                "casa"
                                                                                    ? "casa"
                                                                                    : "embarcacion"
                                                                            }
                                                                            referido={
                                                                                true
                                                                            }
                                                                        />
                                                                    </td>

                                                                    <td className="table-cell">
                                                                        {
                                                                            pedido.nombre
                                                                        }{" "}
                                                                    </td>

                                                                    <td className="table-cell">
                                                                        {
                                                                            pedido?.fecha_registro
                                                                        }
                                                                    </td>

                                                                    <td
                                                                        className={`hidden xs:table-cell`}
                                                                    >
                                                                        Confirmado
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mt-8 w-11/12 mx-auto">
                                    {userPoints.referidos_espera
                                        ?.array_referidosEspera?.length > 0 ? (
                                        <div>
                                            <p className="font-semibold text-xl xs:text-2xl text-center">
                                                Referidos por confirmar
                                            </p>
                                            <div className="w-full bg-yellow-300 bg-opacity-10 rounded-md px-2">
                                                <table className="w-full mt-6 overflow-x-auto text-sm xs:text-base">
                                                    <thead className="text-left">
                                                        <tr>
                                                            <th className="  w-2/5 "></th>

                                                            <th className=" w-1/5  table-cell">
                                                                Cliente
                                                            </th>

                                                            <th className="w-1/5  table-cell">
                                                                Fecha
                                                            </th>

                                                            <th
                                                                className={`w-1/5  xs:table-cell hidden`}
                                                            >
                                                                Estado
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {userPoints.referidos_espera?.array_referidosEspera?.map(
                                                            (pedido) => (
                                                                <tr
                                                                    key={
                                                                        pedido.id
                                                                    }
                                                                    className={
                                                                        "w-full border-t cursor-pointer"
                                                                    }
                                                                    onClick={() =>
                                                                        navigate(
                                                                            "/" +
                                                                                pedido.link
                                                                        )
                                                                    }
                                                                >
                                                                    <td>
                                                                        <CardAlquileres
                                                                            key={
                                                                                pedido.id
                                                                            }
                                                                            pedido={
                                                                                pedido
                                                                            }
                                                                            tipo={
                                                                                pedido.tipo ===
                                                                                "casa"
                                                                                    ? "casa"
                                                                                    : "embarcacion"
                                                                            }
                                                                            referido={
                                                                                true
                                                                            }
                                                                        />
                                                                    </td>

                                                                    <td className="table-cell">
                                                                        {
                                                                            pedido.nombre
                                                                        }{" "}
                                                                    </td>

                                                                    <td className="table-cell">
                                                                        {
                                                                            pedido?.fecha_registro
                                                                        }
                                                                    </td>

                                                                    <td
                                                                        className={`hidden xs:table-cell`}
                                                                    >
                                                                        {
                                                                            pedido?.estado
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalHousesCoins;
