import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const EditarReglasAdicionales = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
}) => {
    const [data, setData] = useState({ total: 0, data: [] });
    const [preSelected, setPreSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({
        reglas: [],
        checkIn: producto.data?.check_in || "",
        checkOut: producto.data?.check_out || "",
    });
    const [siguiente, setSiguiente] = useState("Siguiente");
    const horarios = [];
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    const updateProduct = async (payload) => {
        if (tipo_producto === "casa") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarReglasAdicionalesExtra/${producto.data.id}`,
                {
                    reglas_adicionales: producto.data.reglas_adicionales,
                    id_usuario: authUser.id_usuario,
                },
                { headers }
            );
            if (data.message === "actuaizacion exitoso") setModal(false);
        } else {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacion/actualizarReglasExtra/${producto.data.id}`,
                payload,
                { headers }
            );
            if (data.message === "actuaizacion exitoso") setModal(false);
        }
    };

    const handleGuardar = () => {
        tipo_producto === "casa"
            ? updateProduct()
            : updateProduct({
                  reglas: producto.data.reglas,
                  id_usuario: authUser.id_usuario,
              });
    };

    return (
        <div className="border p-4 rounded-lg">
            <h1>
                {tipo_producto === "casa" ? "Reglas adicionales" : "Reglas"}
            </h1>

            <div className="mt-3 ">
                <textarea
                    onChange={(e) =>
                        tipo_producto === "casa"
                            ? setProducto({
                                  ...producto,
                                  data: {
                                      ...producto.data,
                                      reglas_adicionales: e.target.value,
                                  },
                              })
                            : setProducto({
                                  ...producto,
                                  data: {
                                      ...producto.data,
                                      reglas: e.target.value,
                                  },
                              })
                    }
                    value={
                        tipo_producto === "casa"
                            ? producto.data.reglas_adicionales
                            : producto.data.reglas
                    }
                    placeholder="Ejm: Se requiere carnet de vacunación para el ingreso."
                    className="w-full h-20  text-[#3C3C3C] border my-3  rounded-lg py-3 px-3 outline-none focus:outline-none "
                />
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarReglasAdicionales;
