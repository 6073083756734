import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Lazy, Navigation } from "swiper";
import "swiper/css/lazy";
import "./Swiper.css";
import { ShowStarsRating } from "../../StarsRating";

const CardPaquete = ({ paquete, addPaquete }) => {
    return (
        <div
            id={paquete.id}
            key={paquete.id}
            className="relative mt-5 select-none rounded-xl md:mt-0 border-2 border-blue-500 hover:scale-105 hover:border-4"
        >
            <div className="relative w-full h-[200px]">
                <Swiper
                    modules={[Navigation, Lazy]}
                    slidesPerView={1}
                    lazy
                    navigation
                    pagination={{
                        clickable: true,
                    }}
                    rewind={true}
                    loop={true}
                >
                    {paquete.imagenes.map((imagen) => (
                        <SwiperSlide key={imagen.id} className="w-full">
                            <img
                                loading="lazy"
                                className="object-contain w-full h-64 sm:h-52 rounded-md swiper-lazy"
                                alt="Imagen del producto"
                                srcSet={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${imagen.imagen}`}
                            />
                            <div className="swiper-lazy-preloader">
                                <div className="bg-gray-200 h-56 w-full flex flex-col gap-2 p-3">
                                    <div className="bg-slate-50 w-full h-[80%] rounded-md"></div>
                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="bg-blue-500 h-2 w-full mt-2"></div>
            <div
                onClick={() => addPaquete(paquete)}
                className="flex flex-col justify-between items-start w-full  cursor-pointer"
            >
                <div className="px-4 py-2 w-full ">
                    <div className="text-left text-blue-500 mt-1 font-semibold">
                        {`Desde USD ${paquete.precio} `}
                        <span className="italic">( precio referencial )</span>
                    </div>

                    <div
                        className={`mt-3 2xl:text-2xl text-lg   font-bold  flex justify-between items-center`}
                    >
                        <span className="cursor-pointer">{paquete.nombre}</span>
                    </div>

                    <div className="h-28 mt-2 overflow-y-auto text-base text-gray-500 text-justify">
                        {paquete.descripcion}
                    </div>
                    {/*  <button
                        // disabled={cargando}

                        className={`mx-2 text-center w-full`}
                    >
                        <p
                            className={` font-semibold bg-blue-500 rounded-lg w-10/12 p-3 text-white  hover:bg-blue-600 mx-auto disabled:bg-blue-300`}
                        >
                            Adquirir paquete
                        </p>
                    </button> */}
                </div>
                <div className="w-full bg-blue-500  rounded-b-xl ">
                    <div className="flex items-center justify-center p-3">
                        <ShowStarsRating starsQuantity={5} width={6} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardPaquete;
