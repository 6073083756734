import React from "react";
import getTotalAditionalServices from "./getTotalAditionalServices";

const TotalPriceAndIGV = ({
    reserva,
    impuestoIGV,
    descuentoCupon,
    setTotalReservaFinal,
    descuento_oferta,
}) => {
    const aditionalServicePlusFee_BC = Number(
        getTotalAditionalServices(
            reserva.aditional_services,
            reserva.cantDays,
            [reserva.fecha_inicio, reserva.fecha_final],
            reserva.currentProduct
        ).toFixed(2)
    );

    const garantiaTotal = Number(reserva.warranty.split(" ")[0]);

    const total_descuento_cupon = descuentoCupon
        ? descuentoCupon.descuento_bc_cupon +
          descuentoCupon.descuento_propietario_cupon
        : 0;

    setTotalReservaFinal(
        impuestoIGV
            ? Number(
                  reserva.initialReserva_final +
                      Math.ceil(Number(reserva.initialReserva_final * 0.18)) +
                      aditionalServicePlusFee_BC +
                      garantiaTotal -
                      total_descuento_cupon
              ).toFixed(2)
            : Number(reserva.totalPrice - total_descuento_cupon)
    );

    return (
        <div>
            {impuestoIGV
                ? Number(
                      reserva.initialReserva_final +
                          Math.ceil(
                              Number(reserva.initialReserva_final * 0.18)
                          ) +
                          aditionalServicePlusFee_BC +
                          garantiaTotal -
                          total_descuento_cupon
                  ) - descuento_oferta
                : Number(reserva.totalPrice - total_descuento_cupon) -
                  descuento_oferta}{" "}
            {reserva?.coin_base}
        </div>
    );
};

export default TotalPriceAndIGV;
