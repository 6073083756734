import {
    GET_ALL_PRODUCT,
    CREATE_LABEL,
    RESET_CREATE_LABEL,
    ONE_LABELS,
    SEARCH_PRODUCT,
    RESET_ONE_LABEL,
    ENABLE_PRODUCT,
    RESET_ENEBLE_PRODUCT,
    PROMO_PADRE,
} from "../actions/productActions";

const initialState = {
    product: [],
    create: false,
    oneLabels: [],
    enableProduct: [],
    promo_padre: 0,
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCT:
            return {
                ...state,
                product: action.payload,
            };
        case CREATE_LABEL:
            return {
                ...state,
                create: action.payload,
            };
        case RESET_CREATE_LABEL:
            return {
                ...state,
                create: false,
            };
        case ONE_LABELS:
            return {
                ...state,
                oneLabels: action.payload,
            };
        case SEARCH_PRODUCT:
            return {
                ...state,
                product: action.payload,
            };
        case RESET_ONE_LABEL:
            return {
                ...state,
                oneLabels: action.payload,
            };
        case ENABLE_PRODUCT:
            return {
                ...state,
                enableProduct: action.payload,
            };
        case RESET_ENEBLE_PRODUCT:
            return {
                ...state,
                enableProduct: [],
            };
        case PROMO_PADRE:
            return {
                ...state,
                promo_padre: action.payload,
            };

        default:
            return state;
    }
};
