import React from "react";
import { ShowStarsRating } from "../StarsRating";

const ModalDetallesReseñas = ({ modalDetalles, setModalDetalles, reseñas }) => {
    const handleClose = () => {
        setModalDetalles(false);
    };

    return (
        <div
            className={` relative z-[60] items-center justify-center ${
                modalDetalles ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 w-[150%] h-full bg-gray-900 bg-opacity-80 sticky-0  ">
                <div className="flex items-center justify-center px-4 py-3 2xl:mx-auto md:px-2">
                    <div className="relative flex flex-col items-center justify-center px-4 py-16 bg-white rounded-lg w-8/12 lg:w-6/12 dark:bg-white   overflow-auto h-[calc(100vh-5rem)]">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mb-3 text-4xl font-bold">
                            Todas las reseñas
                        </header>
                        <div className="w-full p-3 overflow-auto grid sm:grid-cols-[40%_60%]">
                            <div className="mx-auto">
                                <div className="flex">
                                    <svg
                                        className={`h-10 w-10 text-[#FDCC0D] `}
                                        viewBox="0 0 24 24"
                                        fill="#FDCC0D"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                    </svg>
                                    <p className="text-4xl font-bold">
                                        {reseñas.promedio}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-4xl font-bold">
                                        {reseñas.total_resenas} reseñas
                                    </p>
                                </div>
                            </div>
                            <div>
                                {reseñas.resenas.map((reseña) => {
                                    return (
                                        <div
                                            key={reseña.id}
                                            className=" w-full mx-auto mt-8 text-xs text-center">
                                            <div className="">
                                                <div className="flex">
                                                    <svg
                                                        className="w-12 h-12 text-blue-500 rounded-full bg-slate-300  "
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                                        <circle
                                                            cx="12"
                                                            cy="7"
                                                            r="4"
                                                        />
                                                    </svg>
                                                    <div className="ml-2">
                                                        <p className="font-bold text-base">
                                                            {
                                                                reseña.usuario_calificador
                                                            }
                                                        </p>
                                                        <p className="-ml-2 text-gray-400 text-base">
                                                            {reseña.fecha_registro
                                                                ?.slice(0, 10)
                                                                .split("-")
                                                                .reverse()
                                                                .join("-")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-mt-2 ">
                                                <ShowStarsRating
                                                    starsQuantity={Number(
                                                        reseña.puntuacion
                                                    )}
                                                    width={5}
                                                />
                                            </div>
                                            <div>
                                                <p className="text-gray-500 text-base text-left">
                                                    {reseña.comentarios}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <button
                            className="w-full rounded-2xl border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                            onClick={handleClose}>
                            CERRAR RESEÑAS
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDetallesReseñas;
