import React from "react";

const CardAlquileres = ({ pedido, tipo, referido = false }) => {
    if (tipo === "casa") {
        return (
            <div className="flex items-center py-3 cursor-pointer">
                <img
                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${pedido.imagen_principal_minificada}`}
                    alt="Imagen de la reserva"
                    className="object-cover h-10 rounded-lg w-14"
                />
                <div className="flex mx-3 ">
                    <div className="flex flex-col">
                        {referido ? (
                            <>
                                <h1 className="">{pedido.nombre_casa}</h1>
                            </>
                        ) : (
                            <>
                                <h1 className="">{pedido.casa}</h1>
                                <p>
                                    <span className="text-xs text-gray-400">
                                        Pedido : # {pedido.id}
                                    </span>
                                    {pedido.cotizacion === "Si" && (
                                        <span className="text-xs text-orange-400">
                                            {" "}
                                            - Cotizacion
                                        </span>
                                    )}
                                </p>
                            </>
                        )}
                    </div>
                    {pedido.is_empresa === "Si" && (
                        <div
                            title="Es un pedido de empresa"
                            className="flex items-center justify-center w-5 h-5 mx-1 font-medium text-white border-2 bg-blue-bluecorner">
                            E
                        </div>
                    )}
                </div>
            </div>
        );
    }
    return (
        <div className="flex items-center py-3 cursor-pointer">
            <img
                src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/embarcacion/getImagenPrincipal/${pedido.imagen_principal}`}
                alt="Imagen de la reserva"
                className="object-cover h-10 rounded-lg w-14"
            />

            <div className="flex flex-col">
                {referido ? (
                    <>
                        <h1 className="mx-3">{pedido.nombre_embarcacion}</h1>
                    </>
                ) : (
                    <>
                        <div className="flex flex-col">
                            <h1 className="mx-3">{pedido.embarcacion}</h1>
                            <span className="mx-3 text-xs text-gray-400">
                                Pedido : # {pedido.id}
                            </span>
                        </div>
                        {pedido.is_empresa === "Si" && (
                            <div
                                title="Es un pedido de empresa"
                                className="flex items-center justify-center w-5 h-5 mx-1 font-medium text-white border-2 bg-blue-bluecorner">
                                E
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CardAlquileres;
