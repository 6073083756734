import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "../../../Spinner";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import dividirFechaDatePicker from "../../../helpers/dividirFechaDatePicker";
import { useSelector } from "react-redux";

const ModalAddServices = ({
    modalVisible,
    setModalVisible,
    producto,
    setProducto,
    fakeRefresh,
    setFakeRefresh,
    tipo_producto,
}) => {
    registerLocale("es", es);
    const DIAS = [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
    ];

    const [input, setInput] = useState({
        id: producto.data.id,
        rango_persona_inicio: "",
        rango_persona_final: "",
        precio: "",
        tipo: "por noche",
        tipo_tiempo: "Siempre",
        fecha_inicio: "",
        fecha_final: "",
        dia_inicio: "Lunes",
        dia_final: "Lunes",
        id_propiedad: "",
    });
    const [siguiente, setSiguiente] = useState("Guardar");
    const [horarios, setHorarios] = useState([]);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    const getAllDuracion = async (proietario) => {
        const jsonembar = await axios.get(
            `${process.env.REACT_APP_PROXY}/propiedades/all/0/si/duracion`,
            { headers }
        );

        setHorarios(jsonembar.data.data);
    };

    useEffect(() => {
        getAllDuracion();
    }, []);

    const handleInputChange = (e) => {
        if (e.target.value === "Siempre") {
            setInput({
                ...input,
                [e.target.name]: e.target.value,
                fecha_inicio: "",
                fecha_final: "",

                dia_inicio: "Lunes",
                dia_final: "Lunes",
            });
        } else {
            setInput({
                ...input,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        if (input.rango_persona_inicio === "") {
            return Swal.fire({
                title: "Error",
                text: "Debe ingresar un  rango de inicio",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else if (input.rango_persona_final === "") {
            return Swal.fire({
                title: "Error",
                text: "Debe ingresar un rango final",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else if (input.precio === 0 || input.precio === "") {
            return Swal.fire({
                title: "Error",
                text: "Debe ingresar un precio mayor a 0",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else if (
            input.id_propiedad === "" &&
            tipo_producto === "embarcacion"
        ) {
            return Swal.fire({
                title: "Error",
                text: "Debe ingresar un turno",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            setSiguiente(null);
            let payload;
            let error;
            if (tipo_producto === "casa") {
                payload = {
                    id_casa: input.id,
                    rango_persona_inicio: Number(input.rango_persona_inicio),
                    rango_persona_final: Number(input.rango_persona_final),
                    precio: Number(input.precio),
                    tipo: input.tipo,
                    tipo_tiempo: input.tipo_tiempo,
                    fecha_final: input.fecha_final,
                    fecha_inicio: input.fecha_inicio,
                    dia_inicio: input.dia_inicio,
                    dia_final: input.dia_final,
                    id_usuario: authUser.id_usuario,
                };
            } else {
                payload = {
                    id_embarcacion: input.id,
                    rango_persona_inicio: Number(input.rango_persona_inicio),
                    rango_persona_final: Number(input.rango_persona_final),
                    precio: Number(input.precio),
                    tipo_tiempo: input.tipo_tiempo,
                    fecha_final: input.fecha_final,
                    fecha_inicio: input.fecha_inicio,
                    dia: input.dia_inicio,
                    id_propiedad: input.id_propiedad,
                    id_usuario: authUser.id_usuario,
                };
            }

            if (tipo_producto === "casa") {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/casaPersonasAdicional/
`,
                    {
                        ...payload,
                    },
                    { headers }
                );

                error = data.error;
            } else if (tipo_producto === "embarcacion") {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/embarcacionPersonasAdicional/
`,
                    {
                        ...payload,
                    },
                    { headers }
                );
                error = data.error;
            }
            if (error) {
                Swal.fire({
                    title: "Error",
                    text: error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                setSiguiente("Guardar");
            } else {
                setSiguiente("Guardar");
                setModalVisible(!modalVisible);
                setInput({
                    id: producto.data.id,
                    rango_persona_inicio: "",
                    rango_persona_final: "",
                    precio: "",
                    tipo: "por noche",
                    tipo_tiempo: "Siempre",
                    fecha_inicio: "",
                    fecha_final: "",
                    dia_inicio: "Lunes",
                    dia_final: "Lunes",

                    id_propiedad: "",
                });
                setFakeRefresh(!fakeRefresh);
            }
        }
    };

    const handleClose = () => {
        setInput({
            id: producto.data.id,
            rango_persona_inicio: "",
            rango_persona_final: "",
            precio: "",
            tipo: "por noche",
            tipo_tiempo: "Siempre",
            fecha_inicio: "",
            fecha_final: "",
            dia_inicio: "Lunes",
            dia_final: "Lunes",

            id_propiedad: "",
        });
        setModalVisible(!modalVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 left-0 z-10 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-20 sm:py-8 ">
                    <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg  xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] p-10 overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-4 right-4 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <div className="w-full my-3 text-left">
                            <header className="mb-3  text-[#3C3C3C]  text-base  font-bold ">
                                Nuevo rango
                            </header>
                            <header className="mb-3  text-[#3C3C3C]  text-sm  font-semibold ">
                                Precio base: {producto.data?.precio_base} (
                                {tipo_producto === "casa"
                                    ? producto.data.moneda_precio_base
                                    : producto.data.tipo_moneda}
                                )
                            </header>
                        </div>
                        <div className="w-full text-left ">
                            <header className="mb-3  text-[#3C3C3C]  text-base ">
                                Rango de personas
                            </header>
                        </div>

                        <div className={"w-full text-lef "}>
                            <label className="ml-1 text-base font-semibold ">
                                Por:
                            </label>
                            <select
                                name="tipo_tiempo"
                                className="w-30 my-3 bg-white p-2 ml-4 outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C] rounded-lg border border-[#E9E9E9]"
                                value={input.tipo_tiempo}
                                onChange={handleInputChange}>
                                <option value="Siempre">Siempre</option>
                                <option value="Fecha">Fecha</option>
                                <option value="Rango de días">
                                    Rango de dias
                                </option>

                                <option value="Días de la semana">
                                    {tipo_producto === "casa"
                                        ? "Días de la semana"
                                        : "Día de la semana"}
                                </option>
                            </select>
                        </div>
                        <div
                            className={
                                tipo_producto === "casa"
                                    ? "hidden"
                                    : "w-full text-lef "
                            }>
                            <label className="ml-1 text-base font-semibold ">
                                Turno:
                            </label>
                            <select
                                name="id_propiedad"
                                className="w-30 my-3 bg-white p-2 ml-4 outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C] rounded-lg border border-[#E9E9E9]"
                                value={input.id_propiedad}
                                onChange={handleInputChange}>
                                <option value="">Selecionar</option>
                                {horarios.map((propiedad, i) => {
                                    return (
                                        <option key={i} value={propiedad.id}>
                                            {propiedad.nombre}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        {input.tipo_tiempo === "Fecha" && (
                            <div className="flex flex-col mb-2">
                                <div className="">
                                    <label className="ml-1 text-base font-semibold ">
                                        Fecha inicial:
                                    </label>

                                    <DatePicker
                                        className="w-11/12 p-2 bg-white border rounded-lg cursor-pointer"
                                        placeholderText="Seleccionar fecha"
                                        locale="es"
                                        autoComplete="off"
                                        name="fechaDesde"
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            input.fecha_inicio !== ""
                                                ? new Date(
                                                      dividirFechaDatePicker(
                                                          input.fecha_inicio
                                                      )[0],
                                                      dividirFechaDatePicker(
                                                          input.fecha_inicio
                                                      )[1] - 1,
                                                      dividirFechaDatePicker(
                                                          input.fecha_inicio
                                                      )[2]
                                                  )
                                                : input.fecha_inicio
                                        }
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                fecha_inicio: `${e.getFullYear()}-${`${
                                                    e.getMonth() + 1
                                                }`.padStart(
                                                    2,
                                                    "0"
                                                )}-${`${e.getDate()}`.padStart(
                                                    2,
                                                    "0"
                                                )}`,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="ml-1 text-base font-semibold ">
                                        Fecha final:
                                    </label>

                                    <DatePicker
                                        className="w-11/12 p-2 bg-white border rounded-lg cursor-pointer"
                                        placeholderText="Seleccionar fecha"
                                        locale="es"
                                        autoComplete="off"
                                        name="fechaDesde"
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            input.fecha_final !== ""
                                                ? new Date(
                                                      dividirFechaDatePicker(
                                                          input.fecha_final
                                                      )[0],
                                                      dividirFechaDatePicker(
                                                          input.fecha_final
                                                      )[1] - 1,
                                                      dividirFechaDatePicker(
                                                          input.fecha_final
                                                      )[2]
                                                  )
                                                : input.fecha_final
                                        }
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                fecha_final: `${e.getFullYear()}-${`${
                                                    e.getMonth() + 1
                                                }`.padStart(
                                                    2,
                                                    "0"
                                                )}-${`${e.getDate()}`.padStart(
                                                    2,
                                                    "0"
                                                )}`,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {(input.tipo_tiempo === "Días de la semana" ||
                            input.tipo_tiempo === "Rango de días") && (
                            <div className="flex justify-between mb-2">
                                <div className="w-2/5">
                                    <label className="ml-1 text-base font-semibold ">
                                        {tipo_producto === "casa"
                                            ? "Día inicial:"
                                            : "Día:"}
                                    </label>
                                    <div
                                        className={
                                            "w-full mt-2 rounded-lg border border-[#E9E9E9] "
                                        }>
                                        {input.tipo_tiempo ===
                                        "Rango de días" ? (
                                            <input
                                                value={input.dia_inicio || ""}
                                                type="number"
                                                name="dia_inicio"
                                                placeholder="Numero de dias"
                                                className="w-32 py-2 text-center rounded-lg outline-none placeholder:text-center"
                                                onChange={handleInputChange}
                                            />
                                        ) : (
                                            <select
                                                name="dia_inicio"
                                                className="w-full my-3 bg-white border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                                                value={input.dia_inicio}
                                                onChange={handleInputChange}>
                                                {DIAS.map((day) => (
                                                    <option
                                                        key={day}
                                                        value={day}>
                                                        {day}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={
                                        tipo_producto === "casa"
                                            ? "w-2/5"
                                            : "hidden"
                                    }>
                                    <label className="ml-1 text-base font-semibold ">
                                        Día final:
                                    </label>
                                    <div
                                        className={
                                            "w-full mt-2 rounded-lg border border-[#E9E9E9] "
                                        }>
                                        {input.tipo_tiempo ===
                                        "Rango de días" ? (
                                            <input
                                                value={input.dia_final || ""}
                                                type="number"
                                                name="dia_final"
                                                placeholder="Numero de dias"
                                                className="w-32 py-2 text-center rounded-lg outline-none placeholder:text-center"
                                                onChange={handleInputChange}
                                            />
                                        ) : (
                                            <select
                                                name="dia_final"
                                                className="w-full my-3 bg-white border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                                                value={input.dia_final}
                                                onChange={handleInputChange}>
                                                {DIAS.map((day) => (
                                                    <option
                                                        key={day}
                                                        value={day}>
                                                        {day}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex flex-col mt-1  text-[#3C3C3C]">
                            <div className="flex ">
                                <div className="">
                                    <label className="ml-1 text-base font-semibold ">
                                        Desde:
                                    </label>
                                    <input
                                        value={input.rango_persona_inicio}
                                        type="number"
                                        name="rango_persona_inicio"
                                        placeholder="Personas"
                                        className="rounded-lg border border-[#E9E9E9] outline-none w-32 py-2 placeholder:text-center text-center"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>
                                    <label className="ml-1 text-base font-semibold ">
                                        Hasta:
                                    </label>
                                    <input
                                        value={input.rango_persona_final}
                                        type="number"
                                        name="rango_persona_final"
                                        placeholder="Personas"
                                        className="rounded-lg border border-[#E9E9E9] outline-none w-32 py-2 placeholder:text-center text-center "
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div
                                className={
                                    tipo_producto === "casa"
                                        ? " w-[307px] mt-2  rounded-lg border border-[#E9E9E9] "
                                        : "hidden"
                                }>
                                <select
                                    name="tipo"
                                    className="w-full my-3 bg-white border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                                    value={input.tipo}
                                    onChange={handleInputChange}>
                                    <option value="por noche">
                                        Precio por noche
                                    </option>
                                    <option value="por estadia">
                                        Precio por estadía
                                    </option>
                                </select>
                            </div>

                            <div className="mt-2 ">
                                <div className="flex items-center ">
                                    <div className="mb-2 ">
                                        <label className="ml-1 mr-1 text-sm font-semibold ">
                                            {/* {onboarding.tipo_producto ===
                                                "embarcacion"
                                                    ? `Precio por dia (${onboarding?.tipo_moneda})`
                                                    : `Precio por dia (${onboarding.moneda_precio_base})`} */}
                                            {tipo_producto === "casa"
                                                ? "Precio adicional " +
                                                  input.tipo
                                                : "Precio adicional"}{" "}
                                            (
                                            {tipo_producto === "casa"
                                                ? producto.data
                                                      .moneda_precio_base
                                                : producto.data.tipo_moneda}
                                            )
                                        </label>
                                    </div>
                                    <div className="w-[90px]     flex border rounded-lg border-[#E9E9E9] items-center justify-center text-center py-2 px-3">
                                        <p>+</p>
                                        <input
                                            autoComplete="off"
                                            className="w-full rounded-lg outline-none"
                                            placeholder=" 15"
                                            value={input.precio}
                                            name="precio"
                                            type="number"
                                            step=".01"
                                            onChange={
                                                handleInputChange
                                            }></input>
                                    </div>
                                </div>
                                <p
                                    className={
                                        input.precio > 0
                                            ? "text-[#3C3C3C] text-sm  text-center mt-2 font-semibold"
                                            : "hidden"
                                    }>
                                    Recibiras{" "}
                                    {tipo_producto === "casa"
                                        ? producto.data.moneda_precio_base
                                        : producto.data.tipo_moneda}{" "}
                                    {Number(
                                        input.precio -
                                            (producto.data.fee_propietario /
                                                100) *
                                                input.precio
                                    ).toFixed(2)}{" "}
                                    y Bluecorner{" "}
                                    {tipo_producto === "casa"
                                        ? producto.data.moneda_precio_base
                                        : producto.data.tipo_moneda}{" "}
                                    {Number(
                                        input.precio *
                                            (producto.data.fee_propietario /
                                                100)
                                    ).toFixed(2)}{" "}
                                    de comisión
                                </p>
                            </div>
                        </div>

                        <button
                            className="w-full py-3 mt-4 text-center text-white border border-blue-400 rounded-md bg-blue-bluecorner hover:bg-white hover:text-blue-bluecorner disabled:bg-blue-400"
                            onClick={(e) => handleCreate(e)}
                            disabled={!siguiente}>
                            {siguiente ? siguiente : <Spinner width={7} />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAddServices;
