import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import useHeaders from "../../../hooks/useHeaders";

const AutoComplete = ({ fields, setRefreshHouses, setFieldSelect }) => {
    const { headers } = useHeaders();

    const handleFieldClick = async (
        id_casa,
        nombre_casa,
        moneda_precio_base
    ) => {
        Swal.fire({
            title: "Es una casa preeboking ?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Si",
            denyButtonText: "No",
            customClass: {
                actions: "my-actions",
                cancelButton: "order-1 right-gap",
                confirmButton: "order-2",
                denyButton: "order-3",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_PROXY}/casasSemanaSanta/`,
                        { id_casa: id_casa, preeboking: "Si" },
                        { headers }
                    );
                    Swal.fire({
                        icon: "success",
                        title: "Guardado con exito",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                    setRefreshHouses((prev) => !prev);
                } catch (error) {
                    console.log(error.response);
                }
            } else if (result.isDenied) {
                try {
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_PROXY}/casasSemanaSanta/`,
                        { id_casa: id_casa, preeboking: "No" },
                        { headers }
                    );
                    Swal.fire({
                        icon: "success",
                        title: "Guardado con exito",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                    setRefreshHouses((prev) => !prev);
                } catch (error) {
                    console.log(error.response);
                }
            }
        });
    };

    const handleFieldClickNew = async (selectedField) => {
        setFieldSelect(selectedField);
    };

    return (
        <div
            className={`absolute z-20 bg-gray-100 border-2 rounded-md border-gray-300 -left-1 p-1 top-14 w-[102%] max-h-80 mt-0.5 overflow-auto`}>
            {fields.map(({ id, nombre, check, moneda_precio_base }) => (
                <button
                    disabled={check === "No"}
                    key={id}
                    onClick={() =>
                        // handleFieldClick(id, nombre, moneda_precio_base)
                        handleFieldClickNew({ id, nombre, moneda_precio_base })
                    }
                    className="w-full p-2 mt-1 bg-white border-2 rounded-md cursor-pointer disabled:bg-gray-100 disabled:cursor-not-allowed">
                    {nombre}{" "}
                    {check === "No" && (
                        <span className="font-medium text-blue-bluecorner underline">
                            ya esta en lista
                        </span>
                    )}
                </button>
            ))}
        </div>
    );
};

export default AutoComplete;
