import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper";

const ImagenFullScreen = ({
    imagenSelected,
    modalVisible,
    setModalVisible,
    tipo_conversacion,
    setImagenSelected,
}) => {
    const swiperRef = useRef(null);
    const [zoomActive, setZoomActive] = useState(false);
    const [fullScreenActive, setFullScreenActive] = useState(false);

    const handleClose = () => {
        setImagenSelected(null);
        setModalVisible(!modalVisible);
    };

    useEffect(() => {
        document.body.classList.add("overflow-hidden");

        return () => document.body.classList.remove("overflow-hidden");
    }, []);

    return (
        <div className={`relative items-center justify-center flex`}>
            <div
                className={`fixed top-0 w-[130%] h-full bg-black ${
                    fullScreenActive ? "bg-opacity-100" : "bg-opacity-90"
                } sticky-0 z-40 transition-all ${
                    modalVisible ? "scale-100" : "scale-0"
                } duration-500 `}>
                <div className="flex items-center justify-center px-4 2xl:container 2xl:mx-auto md:px-28 ">
                    <div className="relative flex flex-col items-center justify-center ">
                        <div className="absolute flex text-gray-800 top-4 right-0 z-50 dark:text-gray-400 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <button>
                                {fullScreenActive ? (
                                    <svg
                                        className="w-8 h-8 m-2 rounded-full hover:scale-125 bg-slate-500 text-white p-0.5 cursor-pointer"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        onClick={() => {
                                            setFullScreenActive(
                                                !fullScreenActive
                                            );
                                            document
                                                .exitFullscreen()
                                                .catch((error) =>
                                                    console.log(error)
                                                );
                                        }}>
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M15 19v-2a2 2 0 0 1 2 -2h2" />{" "}
                                        <path d="M15 5v2a2 2 0 0 0 2 2h2" />{" "}
                                        <path d="M5 15h2a2 2 0 0 1 2 2v2" />{" "}
                                        <path d="M5 9h2a2 2 0 0 0 2 -2v-2" />
                                    </svg>
                                ) : (
                                    <svg
                                        className="w-8 h-8 m-2 rounded-full hover:scale-125 bg-slate-500 text-white p-0.5 cursor-pointer"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        onClick={() => {
                                            setFullScreenActive(
                                                !fullScreenActive
                                            );
                                            document.documentElement
                                                .requestFullscreen()
                                                .catch((error) =>
                                                    console.log(error)
                                                );
                                        }}>
                                        {" "}
                                        <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />{" "}
                                        <path d="M4 16v2a2 2 0 0 0 2 2h2" />{" "}
                                        <path d="M16 4h2a2 2 0 0 1 2 2v2" />{" "}
                                        <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                                    </svg>
                                )}
                            </button>
                            <button
                                onClick={() => swiperRef.current.zoom.toggle()}
                                className="cursor-pointer">
                                {zoomActive ? (
                                    <svg
                                        className="w-8 h-8 m-2 rounded-full hover:scale-125 bg-slate-500 text-white p-0.5 cursor-pointer"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="11" cy="11" r="8" />{" "}
                                        <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                        />{" "}
                                        <line x1="8" y1="11" x2="14" y2="11" />
                                    </svg>
                                ) : (
                                    <svg
                                        className="w-8 h-8 m-2 rounded-full hover:scale-125 bg-slate-500 text-white p-0.5 cursor-pointer"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="11" cy="11" r="8" />{" "}
                                        <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                        />{" "}
                                        <line x1="11" y1="8" x2="11" y2="14" />{" "}
                                        <line x1="8" y1="11" x2="14" y2="11" />
                                    </svg>
                                )}
                            </button>{" "}
                            <button
                                onClick={() => {
                                    handleClose();
                                    if (fullScreenActive) {
                                        setFullScreenActive(!fullScreenActive);
                                        document
                                            .exitFullscreen()
                                            .catch((error) =>
                                                console.log(error)
                                            );
                                    }
                                }}>
                                <svg
                                    className="w-8 h-8 m-2 rounded-full hover:scale-125 bg-slate-500 text-white p-0.5 cursor-pointer"
                                    width="36"
                                    height="36"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18 6L6 18"
                                        stroke="currentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M6 6L18 18"
                                        stroke="currentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="w-screen h-screen overflow-auto lg:px-20">
                            <div
                                className="flex h-full select-none brounded-2xl"
                                onClick={() => {
                                    if (!fullScreenActive) {
                                        handleClose();
                                    }
                                }}>
                                <Swiper
                                    onSwiper={(swiper) =>
                                        (swiperRef.current = swiper)
                                    }
                                    modules={[Zoom]}
                                    slidesPerView={1}
                                    zoom={true}
                                    onZoomChange={() => {
                                        setZoomActive(!zoomActive);
                                    }}>
                                    <SwiperSlide
                                        onClick={(e) => e.stopPropagation()}>
                                        <div className="flex items-center justify-center h-full cursor-pointer swiper-zoom-container">
                                            <img
                                                alt="Imagen no encontrada"
                                                src={`${
                                                    process.env.REACT_APP_PROXY
                                                }/${
                                                    tipo_conversacion === "casa"
                                                        ? "conversacionCasa"
                                                        : "conversacionEmbarcacion"
                                                }/getArchive/${imagenSelected}`}
                                                className="mx-auto md818:h-full lg:p-20"
                                            />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImagenFullScreen;
