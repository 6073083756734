import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";

const ModalEditDescripcionImegen = ({
    modalVisible,
    setModalVisible,
    descriptionEdit,
    setDescriptionEdit,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const [saving, setSaving] = useState(false);

    const [input, setInput] = useState(descriptionEdit);

    const handleGuardar = async () => {
        setSaving(true);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/productoImagenes/putImagen/${modalVisible}`,
            {
                descripcion: input,
            },
            { headers }
        );

        Swal.fire({
            title: "Descripcion actualaizada",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok!",
        });
        setSaving(false);

        setDescriptionEdit("");
        setModalVisible(0);
    };

    const handleClose = () => {
        setDescriptionEdit("");
        setModalVisible(0);
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 z-50">
            <div className="flex items-center justify-center h-full">
                <div className="relative block bg-white rounded-lg w-72 h-96 max-w-md">
                    <button
                        onClick={handleClose}
                        className="absolute text-gray-800 dark:text-gray-400 top-8 right-4 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                        <svg
                            className="rounded-full hover:bg-gray-600 hover:text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <div className="flex justify-center items-center">
                        {/* ... Contenido del cuadro blanco ... */}
                        <div className="max-w-md px-4 py-1 mx-auto bg-white sm:px-5 rounded-3xl mt-12 ">
                            <div>
                                <header className="mb-5 text-sm font-medium">
                                    Editar descripción
                                </header>
                            </div>
                            <div className="flex justify-center my-2 ">
                                <textarea
                                    className="border rounded-md px-2 border-[#1C4CBB] w-11/12 h-44"
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    placeholder="Descripcion (opcional)"
                                />
                            </div>
                            <div className="flex flex-col gap-4 mt-8">
                                {saving ? (
                                    <Spinner />
                                ) : (
                                    <button
                                        onClick={() => handleGuardar()}
                                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                                        Guardar descripcion
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditDescripcionImegen;
