import React, { useEffect, useLayoutEffect, useState } from "react";
import BLUECORNER_PNG from "../img/Home/BLUECORNER_PNG.webp";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/actions/authActions";
import NavBarAdministrativo from "./NavBarAdministrativo";
import {
    updateBoatFilters,
    updateHouseFilters,
} from "../redux/actions/filtersActions";
import useColores from "../hooks/useColores";
import BurguerMenuHome from "./BurguerMenuHome";
import useCuentaRegresiva from "../hooks/useCuentaRegresiva";

const NavBar = ({ height = 24 }) => {
    const monedaStorage = localStorage.getItem("moneda");
    if (!monedaStorage) {
        localStorage.setItem("moneda", "PEN");
    }
    const [burguerMenu, setBurguerMenu] = useState(false);
    const [perfil, setPerfil] = useState(false);
    const [modalMoneda, setModalMoneda] = useState(false);
    const [tipoMoneda, setTipoMoneda] = useState(
        monedaStorage ? monedaStorage : "PEN"
    );
    const [background, setBackground] = useState(null);
    const [logoEmpresa, setLogoEmpresa] = useState(null);
    const { idEmpresa } = useParams();
    const getLogoEmpresa = localStorage.getItem("logo_empresa");
    const getBackgroundEmpresa = localStorage.getItem("background_navbar");
    const [dropDown1, setDropDown1] = useState(false);
    const [dropDown2, setDropDown2] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.authReducer);
    const { primario, secundario, setColores } = useColores();
    const {
        reloj,
        totalDays,
        totalHours,
        totalMinutes,
        totalSeconds,
        fullText,
    } = useCuentaRegresiva("4/30/2024");
    const [bannerVisible, setBannerVisible] = useState(true);

    const cambioDeMoneda = (moneda) => {
        localStorage.setItem("moneda", moneda);
        setTipoMoneda(moneda);
        setModalMoneda(false);
        window.location.reload();
    };

    useEffect(() => {
        if (burguerMenu) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }

        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [burguerMenu]);

    useLayoutEffect(() => {
        if (idEmpresa) {
            setBackground(getBackgroundEmpresa);
            setLogoEmpresa(getLogoEmpresa);
        }
    }, []);

    return (
        <nav className="select-none">
            {authUser.tipo_usuario !== null && authUser.tipo_usuario <= 2 && (
                <NavBarAdministrativo
                    link={"/dashboard/anuncios"}
                    text={"DASHBOARD"}
                />
            )}

            <div
                className={`${
                    authUser.tipo_usuario !== null && authUser.tipo_usuario <= 2
                        ? "top-9"
                        : "top-0"
                } h-${height} fixed items-center justify-center left-0 flex flex-col w-full z-30`}
                style={{ backgroundColor: background ? background : primario }}
            >
                <div className="flex items-center justify-center gap-2 w-full p-0 mx-auto xs:pt-2 2xl:w-11/12 md818:p-3 ">
                    {logoEmpresa || authUser.logo ? (
                        <img
                            width="2364px"
                            height="800px"
                            alt="Logo bluecorner"
                            src={`${
                                process.env.REACT_APP_PROXY
                            }/empresas/getImage/${
                                logoEmpresa || authUser.logo
                            }`}
                            className="w-auto h-12 cursor-pointer xs:h-16"
                            onClick={() => {
                                navigate("/");
                            }}
                        />
                    ) : (
                        <div>
                            <img
                                width="2364px"
                                height="800px"
                                alt="Logo bluecorner"
                                src={BLUECORNER_PNG}
                                className="hidden w-auto cursor-pointer h-28 h-18 sm:h-32 md:h-32 xl:h-20 2xl:h-20 xs:flex lg1170:h-20 lg:mx-5 2xl:mr-10"
                                onClick={() => {
                                    navigate("/");
                                }}
                            />
                            <img
                                width="2364px"
                                height="800px"
                                alt="Logo bluecorner"
                                src={BLUECORNER_PNG}
                                className="w-auto h-20 mr-2 cursor-pointer sm:h-32 md:h-32 md:-ml-3 xl:h-28 xs:hidden"
                                onClick={() => {
                                    navigate("/");
                                }}
                            />
                        </div>
                    )}

                    <div
                        className="cursor-pointer lg1170:hidden"
                        onClick={() => {
                            setPerfil(false);
                            setBurguerMenu(!burguerMenu);
                        }}
                    >
                        <svg
                            className="w-10 h-10 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </div>
                    <div
                        className={`items-center justify-center hidden cursor-pointer lg1170:flex `}
                    >
                        <div className="grid grid-cols-2 lg1170:flex ">
                            <div className="flex flex-col items-center justify-center lg1170:flex-row">
                                <div
                                    className={`relative  w-full`}
                                    onMouseOut={() => setDropDown1(!dropDown1)}
                                    onMouseOver={() => setDropDown1(!dropDown1)}
                                >
                                    <span className="flex items-center justify-end w-full mx-1 text-center">
                                        <span
                                            onClick={() => {
                                                navigate(
                                                    "/alquiler-de-alojamientos"
                                                );
                                                dispatch(
                                                    updateHouseFilters({
                                                        fecha_inicio: "",
                                                        fecha_final: "",
                                                        query_value: "",
                                                        query_tipo: "",
                                                        numero_personas: 0,
                                                        numero_habitaciones: 0,
                                                        numero_camas: 0,
                                                        numero_banos: 0,
                                                        precio_rango: 0,
                                                        tipo_moneda: "",
                                                        atributos: [],
                                                        reglas: [],
                                                        ubicacion: [],
                                                        tipo_alojamiento: 0,
                                                        pagina: 1,
                                                        order: "precio",
                                                        latitud: 0,
                                                        longitud: 0,
                                                        zoom: 10,
                                                        adultos: 0,
                                                        ninos: 0,
                                                        numero_noches: 0,
                                                    })
                                                );
                                            }}
                                            className="mr-1 text-white hover:bg-[#E1E1E1]/30 w-fit text-xs md723:text-sm md818:text-base px-1 lg:px-2 text-center h-14 justify-between flex items-center cursor-pointer rounded-lg"
                                        >
                                            <span className="flex">
                                                Alojamientos
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div
                                    className={`relative `}
                                    onMouseOut={() => setDropDown2(!dropDown2)}
                                    onMouseOver={() => setDropDown2(!dropDown2)}
                                >
                                    <span className="flex items-center justify-end w-full mx-1 text-center">
                                        <span
                                            onClick={() => {
                                                navigate(
                                                    "/alquiler-de-embarcaciones"
                                                );
                                                dispatch(
                                                    updateBoatFilters({
                                                        fecha: "",
                                                        query_value: "",
                                                        query_tipo: "",
                                                        numero_personas: 0,
                                                        tipo_embarcacion: 0,
                                                        duracion: [],
                                                        pagina: 1,
                                                    })
                                                );
                                            }}
                                            className="md723:mr-1 text-white hover:bg-[#E1E1E1]/30 w-fit text-xs md723:text-sm md818:text-base px-1 lg:px-2 text-center h-14 justify-between flex items-center cursor-pointer rounded-lg"
                                        >
                                            <span className="flex">
                                                Embarcaciones
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center lg1170:flex-row">
                                <div
                                    className={`relative `}
                                    onMouseOut={() => setDropDown2(!dropDown2)}
                                    onMouseOver={() => setDropDown2(!dropDown2)}
                                >
                                    <span className="flex items-center justify-end w-full mx-1 text-center">
                                        <span
                                            onClick={() => {
                                                navigate("/promo-halloween");
                                            }}
                                            className="md723:mr-1 text-white hover:bg-[#E1E1E1]/30 w-fit text-xs md723:text-sm md818:text-base px-1 lg:px-2 text-center h-14 justify-between flex items-center cursor-pointer rounded-lg"
                                        >
                                            <span className="flex">
                                                Halloween
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div
                                    className={`relative `}
                                    onMouseOut={() => setDropDown2(!dropDown2)}
                                    onMouseOver={() => setDropDown2(!dropDown2)}
                                >
                                    <span className="flex items-center justify-end w-full mx-1 text-center">
                                        <span
                                            onClick={() => {
                                                navigate("/promo-ano-nuevo");
                                            }}
                                            className="md723:mr-1 text-white hover:bg-[#E1E1E1]/30 w-fit text-xs md723:text-sm md818:text-base px-1 lg:px-2 text-center h-14 justify-between flex items-center cursor-pointer rounded-lg"
                                        >
                                            <span className="flex">
                                                Año nuevo
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={"items-center hidden text-white lg1170:flex"}
                    >
                        {!authUser.logged && (
                            <a href={"/my-account"}>
                                <span>
                                    <svg
                                        className="w-8 h-8 ml-2 text-white cursor-pointer"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                        />
                                    </svg>
                                </span>
                            </a>
                        )}
                    </div>
                    <div className="relative hidden p-2 lg1170:flex z-[999] ">
                        {authUser.logged && (
                            <div className="items-center text-center sm:flex sm:justify-between z-[999] ">
                                <div
                                    onMouseOut={() => (
                                        setPerfil(false), setBurguerMenu(false)
                                    )}
                                    onMouseOver={() => (
                                        setPerfil(true), setBurguerMenu(false)
                                    )}
                                    className="relative hidden mr-3 cursor-pointer sm:block"
                                >
                                    <img
                                        src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${authUser.avatar}`}
                                        className={`rounded-full w-14 h-14 border-[3px] object-cover cursor-pointer border-[#307FE2] ml-2`}
                                        alt="foto de perfil"
                                    />

                                    <div
                                        className={
                                            perfil
                                                ? `absolute top-6 md723:top-6 w-[166px] z-[999]  pt-5 right-0 text-[${primario}]`
                                                : "hidden"
                                        }
                                    >
                                        <div
                                            className={
                                                perfil
                                                    ? "block bg-white  top-16 md723:top-16 py-2 rounded-lg shadow-md"
                                                    : "hidden"
                                            }
                                        >
                                            <div
                                                onClick={() => {
                                                    authUser.tipo_usuario !==
                                                        null &&
                                                    authUser.tipo_usuario <= 2
                                                        ? navigate(
                                                              "/dashboard/datos"
                                                          )
                                                        : authUser.tipo_usuario <
                                                          6
                                                        ? navigate(
                                                              "/perfil/datos"
                                                          )
                                                        : navigate(
                                                              "/dashboard-empresas/perfil"
                                                          );
                                                }}
                                                className={` w-40 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4`}
                                            >
                                                <p>Mi perfil</p>
                                            </div>
                                            {authUser.tipo_usuario === 6 &&
                                                authUser.tipo_usuario !==
                                                    null && (
                                                    <div
                                                        onClick={() => {
                                                            navigate(
                                                                "/dashboard-empresas/editar-empresa"
                                                            );
                                                        }}
                                                        className={` w-40 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4`}
                                                    >
                                                        <p>Mi empresa</p>
                                                    </div>
                                                )}
                                            <div
                                                onClick={() => {
                                                    authUser.tipo_usuario < 6
                                                        ? navigate(
                                                              "/perfil/reservaciones"
                                                          )
                                                        : navigate(
                                                              "/dashboard-empresas/reservaciones"
                                                          );
                                                }}
                                                className={
                                                    authUser.tipo_usuario > 2
                                                        ? ` w-40 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4`
                                                        : "hidden"
                                                }
                                            >
                                                <p>Alquileres</p>
                                            </div>

                                            <div
                                                onClick={() =>
                                                    navigate("/favoritos")
                                                }
                                                className={` w-40 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4`}
                                            >
                                                <p>Favoritos</p>
                                            </div>
                                            <hr
                                                className={`w-40 mx-auto border-[${primario}] my-1`}
                                            ></hr>

                                            <div
                                                onClick={() => (
                                                    setPerfil(false),
                                                    dispatch(logoutUser())
                                                )}
                                                className={` w-40 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4`}
                                            >
                                                <p>Cerrar sesión</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <div className="hidden lg1170:flex z-[999]">
                            <div
                                onMouseOver={() => (
                                    setModalMoneda(true), setBurguerMenu(false)
                                )}
                                onMouseOut={() => (
                                    setModalMoneda(false), setBurguerMenu(false)
                                )}
                                style={{
                                    backgroundColor: secundario,
                                }}
                                className={`relative p-2 mr-2 text-xs rounded-lg cursor-pointer  min-w-max md:p-3 `}
                            >
                                <p className="font-semibold text-white">
                                    {tipoMoneda}
                                </p>
                                <div
                                    className={
                                        modalMoneda
                                            ? `absolute top-6 right-0 left-[-7px] w-16 z-[999]  pt-5    `
                                            : "hidden"
                                    }
                                >
                                    <div
                                        className={
                                            modalMoneda
                                                ? "block bg-white  top-16 md723:top-16 py-2 rounded-lg shadow-md"
                                                : "hidden"
                                        }
                                    >
                                        <div
                                            onClick={() =>
                                                tipoMoneda === "USD"
                                                    ? setModalMoneda(false)
                                                    : cambioDeMoneda("USD")
                                            }
                                            className={` w-14 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4 `}
                                        >
                                            <p>USD</p>
                                        </div>

                                        <div
                                            onClick={() =>
                                                tipoMoneda === "PEN"
                                                    ? setModalMoneda(false)
                                                    : cambioDeMoneda("PEN")
                                            }
                                            className={` w-14 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4 `}
                                        >
                                            <p>PEN</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div
                    onClick={() => {
                        window.open(
                            `/alojamientos/38858/casa-alicia`,
                            "_blank"
                        );
                    }}
                    className="w-full text-xs text-center bg-blue-500 cursor-pointer xs:text-base">
                    <p className="font-medium text-white ">
                        ¡Aprovecha las ofertas de la Casa del Mes!{" "}
                        {fullText || "0 dias 0 horas 0 minutos 0 segundos"} !{" "}
                        <span>Conócela aqui</span>
                    </p>
                </div> */}
            </div>

            {/* MENU HAMBURGUESA A PARTIR DE ACÁ */}

            <BurguerMenuHome
                burguerMenu={burguerMenu}
                setBurguerMenu={setBurguerMenu}
                cambioDeMoneda={cambioDeMoneda}
                logoutUser={logoutUser}
                tipoMoneda={tipoMoneda}
            />
        </nav>
    );
};

export default NavBar;
