import axios from "axios";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const CREATE_LABEL = "CREATE_LABEL";
export const RESET_CREATE_LABEL = "RESET_CREATE_LABEL";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const ONE_LABELS = "ONE_LABELS";
export const RESET_ONE_LABEL = "RESET_ONE_LABEL";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const ENABLE_PRODUCT = "ENABLE_PRODUCT";
export const RESET_ENEBLE_PRODUCT = "RESET_ENEBLE_PRODUCT";
export const PROMO_PADRE = "PROMO_PADRE";

export const getAllProduct = (
    pageNumber = 1,
    enabled = "Si",
    terminado = "todos",
    tokenSent,
    product = "casa",
    usuario = 0,
    publicado = "todos"
) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    return async function (dispatch) {
        const token1 = tokenSent;
        const headers = {
            authorization: token1,
        };
        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/${product}/all/`,
            {
                pagina: pageNumber,
                habilitado: enabled,
                nombre: "",
                terminado: terminado,
                id_usuario: usuario,
                publicado: publicado,
            },
            { headers }
        );

        return dispatch({
            type: GET_ALL_PRODUCT,
            payload: json.data,
        });
    };
};

export const searchProductByName = (
    enabled,
    input,
    terminado,
    product = "casa",
    usuario = 0,
    publicado
) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    return async function (dispatch) {
        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/${product}/all/`,
            {
                pagina: 1,
                habilitado: enabled,
                nombre: input,
                terminado: terminado,
                id_usuario: usuario,
                publicado: publicado,
            },
            { headers }
        );

        return dispatch({
            type: SEARCH_PRODUCT,
            payload: json.data,
        });
    };
};

// export const resetCreateLabel = () => {
//     return {
//         type: RESET_CREATE_LABEL,
//     };
// };
export const resetEnebleProduct = () => {
    return {
        type: RESET_ENEBLE_PRODUCT,
    };
};

export const deleteProduct = (id, tipo) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    return async function (dispatch) {
        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/${tipo}/eliminar/${id}`,
            { headers }
        );

        return dispatch({
            type: ENABLE_PRODUCT,
            payload: json.data.message,
        });
    };
};
export const enableProduct = (id, tipo) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    return async function (dispatch) {
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/${tipo}/restaurar/${id}`,
            {},
            { headers }
        );

        return dispatch({
            type: ENABLE_PRODUCT,
            payload: json.data.message,
        });
    };
};

export const resetOnLabel = () => {
    return async function (dispatch) {
        return dispatch({
            type: RESET_ONE_LABEL,
            payload: [],
        });
    };
};
export const promoPadre = (monto) => {
    console.log(monto);
    return async function (dispatch) {
        return dispatch({
            type: PROMO_PADRE,
            payload: monto,
        });
    };
};
