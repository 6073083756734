import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Lazy } from "swiper";
import { normalizeString } from "../../helpers/normalizeString";
import priceToMonedaElegida from "../../helpers/priceToMonedaElegida";
import colores from "../../helpers/colores";
import "swiper/css/lazy";
import Swal from "sweetalert2";
import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";

const CardVerano = ({ product, vistaAdministrador, setRefreshHouses }) => {
    const moneda_elegida = localStorage.getItem("moneda");
    const { headers } = useHeaders();

    const deleteImage = (house_id, house_name) => {
        Swal.fire({
            title: `¿Seguro que quieres eliminar ${house_name} de la promo?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, mejor no",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/casaVerano/eliminar/${house_id}`,
                        { headers }
                    );

                    setRefreshHouses((prev) => !prev);
                } catch (error) {
                    console.log(error.response);
                }
            }
        });
    };

    return (
        <div
            id={product.id}
            key={product.id}
            className="relative mt-2 shadow-xl select-none rounded-xl md:mt-0">
            <div className="relative w-full h-[250px] flex">
                {vistaAdministrador && (
                    <span
                        onClick={() => deleteImage(product.id, product.nombre)}
                        className={`absolute right-2 top-2 p-0.5 text-gray-800 dark:text-gray-400 rounded-sm bg-white hover:bg-slate-100 z-10`}
                        aria-label="close">
                        <svg
                            className="w-5 h-5 text-blue-500 cursor-pointer"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </span>
                )}
                <Swiper
                    modules={[Navigation, Lazy]}
                    slidesPerView={1}
                    watchSlidesProgress
                    lazy={true}
                    navigation
                    rewind={true}
                    loop={true}>
                    <SwiperSlide
                        className="cursor-pointer"
                        onClick={() =>
                            window.open(
                                `/alojamientos/${product.id}/${normalizeString(
                                    product.nombre
                                )}`,
                                "_blank"
                            )
                        }>
                        <img
                            loading="lazy"
                            className="object-cover w-full h-full rounded-md swiper-lazy"
                            alt="Imagen del producto"
                            data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${product.imagen_principal_minificada}`}
                            data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${product.imagen_principal_minificada}`}
                        />
                        <div className="swiper-lazy-preloader">
                            <div className="flex flex-col w-full h-56 gap-2 p-3 bg-gray-200">
                                <div className="bg-slate-50 w-full h-[80%] rounded-md"></div>
                                <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    {product.imagenes.map((imagen) => (
                        <SwiperSlide
                            key={imagen.id}
                            onClick={() =>
                                window.open(
                                    `/alojamientos/${
                                        product.id
                                    }/${normalizeString(product.nombre)}`,
                                    "_blank"
                                )
                            }>
                            <img
                                loading="lazy"
                                className="object-cover w-full h-full rounded-md swiper-lazy"
                                alt="Imagen del producto"
                                data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${imagen.imagen_minificada}`}
                                data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${imagen.imagen_minificada}`}
                            />
                            <div className="swiper-lazy-preloader">
                                <div className="flex flex-col w-full h-56 gap-2 p-3 bg-gray-200">
                                    <div className="bg-slate-50 w-full h-[80%] rounded-md"></div>
                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className="absolute bottom-0 left-0 z-10 flex items-center w-full h-20 p-5 text-lg font-bold text-white bg-black bg-opacity-50 2xl:text-2xl">
                    <span>
                        {`Desde ${priceToMonedaElegida(
                            Number(product.precio_base),
                            product.moneda_precio_base
                        )} ${
                            // product.moneda_precio_base
                            moneda_elegida
                        }`}
                    </span>
                    <span className="mx-2 text-base font-medium">
                        por {product.tipo_estadia === "noche" ? "noche" : "día"}
                    </span>
                </div>
            </div>
            <div className="px-4 py-2">
                <div className="flex items-center justify-between">
                    {/* <ShowStarsRating
                                        starsQuantity={5}
                                        width={6}
                                    /> */}
                    <div className="p-2 mt-1 text-white bg-orange-500 rounded-lg">
                        Mínimo {product.alquiler_minimo}{" "}
                        {product.tipo_estadia === "noche" ? "noches" : "días"}
                    </div>
                </div>
                <div
                    className={`mt-3 2xl:text-2xl text-lg  text-[${colores.primario}] font-bold  flex justify-between items-center`}>
                    <a
                        href={`/alojamientos/${product.id}/${normalizeString(
                            product.nombre
                        )}`}
                        className="cursor-pointer">
                        {product.nombre}
                    </a>
                </div>

                <div className="mt-1 text-base text-gray-500 mb-7">
                    {product.ciudad}, {product.pais}
                </div>
                <div className="absolute bottom-1 left-4 text-amber-600">
                    <span className="mr-2">
                        {product.numero_personas} personas
                    </span>
                    <span className="mx-2">
                        {product.numero_habitaciones} habitaciones
                    </span>
                    <span className="mx-2">{product.numero_banos} baños</span>
                </div>
            </div>
        </div>
    );
};

export default CardVerano;
