import addDaysToDate from "../addDaysToDate";
import getEveryDayBetween2Dates from "../getEveryDayBetween2Dates";
import getFeeByDay from "../getFeeByDay";
import priceToMonedaElegida from "../priceToMonedaElegida";

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

export const getTotalFromDayNumbers = (
    cantDays,
    arrayNumeroDiasPorDia,
    moneda_precio_base,
    fee_cliente_siempre,
    fee_propietario_siempre,
    feeCasas,
    selectedDays
) => {
    let totalPrice = 0;
    let tiene_fee_por_fechas = false;
    let total_fee_cliente = 0;
    let total_fee_propietario = 0;

    let dayNumbers = {
        precio: 0,
    };

    const newSelectedDays = [
        selectedDays[0],
        addDaysToDate(selectedDays[1], -1),
    ];

    const allDatesSelected = getEveryDayBetween2Dates(newSelectedDays);

    const dia_inicio = new Date(selectedDays[0])
        .toLocaleDateString("es-ES", options)
        .split(",")[0];
    const dia_final = new Date(selectedDays[1])
        .toLocaleDateString("es-ES", options)
        .split(",")[0];

    const cumpleCondicion = arrayNumeroDiasPorDia.find((rango) => {
        if (
            cantDays >= Number(rango.numero_dias) &&
            cantDays <= Number(rango.numero_dias_final)
        ) {
            if (rango.tipo_tiempo === "Por fechas") {
                const FDI = new Date(rango.fecha_inicio + "T00:00:00");
                const FDF = new Date(rango.fecha_final + "T00:00:00");
                const FRI = selectedDays[0];
                const FRF = selectedDays[1];
                if (
                    (FDI <= FRI && FDF >= FRI) ||
                    (FDI <= FRF && FDF >= FRF) ||
                    (FRI <= FDI && FRF >= FDI) ||
                    (FRI <= FDF && FRF >= FDF)
                ) {
                    return rango;
                }
            }
        }
    });

    if (cumpleCondicion) {
        dayNumbers.precio = cumpleCondicion.precio;

        allDatesSelected.forEach((date, index) => {
            const { fee_cliente, tiene_fee_por_fecha } = getFeeByDay(
                date,
                feeCasas,
                fee_cliente_siempre,
                fee_propietario_siempre,
                dayNumbers.precio
            );

            if (tiene_fee_por_fecha) {
                tiene_fee_por_fechas = true;
            }

            total_fee_cliente += fee_cliente;
        });
    } else {
        arrayNumeroDiasPorDia.forEach((range) => {
            if (
                cantDays >= Number(range.numero_dias) &&
                cantDays <= Number(range.numero_dias_final)
            ) {
                if (range.tipo_tiempo === "Siempre") {
                    dayNumbers.precio = range.precio;

                    allDatesSelected.forEach((date, index) => {
                        const {
                            fee_cliente,
                            fee_propietario,
                            tiene_fee_por_fecha,
                        } = getFeeByDay(
                            date,
                            feeCasas,
                            fee_cliente_siempre,
                            fee_propietario_siempre,
                            dayNumbers.precio
                        );

                        if (tiene_fee_por_fecha) {
                            tiene_fee_por_fechas = true;
                        }

                        total_fee_cliente += fee_cliente;
                        total_fee_propietario += fee_propietario;
                    });
                }
            }
        });
    }

    let result = {
        price: 0,
        fee_cliente: 0,
        fee_propietario: 0,
        descripcion: "",
        price_total: 0,
    };

    if (tiene_fee_por_fechas) {
        dayNumbers.precio += total_fee_cliente;
    } else if (!tiene_fee_por_fechas) {
        const cumpleCondicion = feeCasas.find((range) => {
            if (range.tipo_tiempo === "Dias") {
                if (
                    range.dia_inicio.toLowerCase() ===
                        dia_inicio.toLowerCase() &&
                    range.dia_final.toLowerCase() === dia_final.toLowerCase()
                ) {
                    return range;
                }
            }
        });

        if (cumpleCondicion) {
            dayNumbers.precio +=
                dayNumbers.precio * (cumpleCondicion.fee_cliente / 100);
            total_fee_propietario =
                dayNumbers.precio * (cumpleCondicion.fee_propietario / 100);
            total_fee_cliente =
                dayNumbers.precio * (cumpleCondicion.fee_cliente / 100);
        } else {
            totalPrice += total_fee_cliente;
        }
    }

    if (dayNumbers) {
        if (dayNumbers?.accion === "-") {
            result.price = priceToMonedaElegida(
                dayNumbers?.precio * -1 * (cantDays - 1),
                moneda_precio_base
            );
            result.price_total = priceToMonedaElegida(
                dayNumbers?.precio * -1 * (cantDays - 1),
                moneda_precio_base
            );
            result.descripcion = "Descuento por larga estadia";
        } else {
            result.price = priceToMonedaElegida(
                dayNumbers?.precio * (cantDays - 1),
                moneda_precio_base
            );
            result.fee_cliente = priceToMonedaElegida(
                total_fee_cliente,
                moneda_precio_base
            );
            result.fee_propietario = 0;
            result.descripcion = "Aumento por larga estadia";
            result.price_total =
                priceToMonedaElegida(
                    dayNumbers?.precio * (cantDays - 1),
                    moneda_precio_base
                ) + priceToMonedaElegida(total_fee_cliente, moneda_precio_base);
        }

        return result;
    } else return result;
};
