import React, { useEffect, useState } from "react";

const StarsRating = ({ starsQuantity, setInput }) => {
    const [rating, setRating] = useState(null);
    const [hoverValue, setHoverValue] = useState(null);

    const Star = ({ index }) => {
        return (
            <svg
                className="h-8 w-8 text-[#FDCC0D] cursor-pointer"
                viewBox="0 0 24 24"
                fill={`${index <= (hoverValue || rating) ? "#FDCC0D" : "#FFF"}`}
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                onMouseEnter={() => setHoverValue(index)}
                onMouseLeave={() => setHoverValue(null)}
                onClick={() => {
                    setRating(index);
                    setInput((prev) => {
                        return {
                            ...prev,
                            puntuacion: index + 1,
                        };
                    });
                }}>
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
            </svg>
        );
    };

    return (
        <div className="flex mt-4">
            {[...Array(starsQuantity)].map((star, index) => (
                <div key={index}>
                    <Star index={index} />
                </div>
            ))}
        </div>
    );
};

export const ShowStarsRating = ({ starsQuantity, width = 10 }) => {
    const [rating, setRating] = useState(null);

    const Star = ({ index }) => {
        return (
            <svg
                className={`h-${width} w-${width} text-[#FDCC0D]`}
                viewBox="0 0 24 24"
                fill="#FDCC0D"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
            </svg>
        );
    };

    return (
        <div className="flex">
            {[...Array(starsQuantity)].map((star, index) => (
                <div key={index}>
                    <Star index={index} />
                </div>
            ))}
        </div>
    );
};

export default StarsRating;
