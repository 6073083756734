import Calendar from "react-calendar";
import "./Calendar.css";
import "react-calendar/dist/Calendar.css";
import React from "react";
import formatedDate_YYYY_MM_DD from "../helpers/formatedDate_YYYY_MM_DD";
import addDaysToDate from "../helpers/addDaysToDate";

const DetailedCalendarAlojamientos = ({
    arrayPedidos,
    arrayMeses,
    arrayDias,
    arrayFechas,
    currentProduct,
    setErrorDates,
    setCantDays,
    setSelectedDays,
    minimaDisponibilidad,
}) => {
    const getDaysBetween2Dates = (arrayDates) => {
        const [dateOne, dateTwo] = arrayDates;
        let nuevosDias = 0;

        const primerAño = dateOne.getFullYear();
        const primerMes = dateOne.getMonth();
        const primerDia = dateOne.getDate();
        let nuevaPrimeraFecha = new Date(primerAño, primerMes, primerDia);

        while (nuevaPrimeraFecha <= dateTwo) {
            nuevaPrimeraFecha.setDate(nuevaPrimeraFecha.getDate() + 1);
            nuevosDias++;
        }

        return nuevosDias;
    };

    const allowReservation = (
        array_dias_reserva,
        array_dias_disponibilidad,
        numero_noches_necesarios
    ) => {
        let cantDiasCoinciden = 0;

        const new_array_disponiblidad = array_dias_disponibilidad.map((day) =>
            day.getTime()
        );

        array_dias_reserva.forEach((day_reserva) => {
            if (new_array_disponiblidad.includes(day_reserva.getTime())) {
                cantDiasCoinciden += 1;
            }
        });

        if (cantDiasCoinciden - 1 >= numero_noches_necesarios) return true;
        else return false;
    };

    const onChangeCalendar = (twoDates) => {
        let dates = twoDates;

        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        let dateOne = dates[0];
        let dateTwo = dates[1];

        let totalDays = getDaysBetween2Dates(dates);

        const allDatesSelected = getEveryDayBetween2Dates(dates).map((date) =>
            date.getTime()
        );
        const allDaysSelected = getEveryDayBetween2Dates(dates).map((date) =>
            date.getDay()
        );
        const allMonthssSelected = getEveryDayBetween2Dates(dates).map((date) =>
            date.getMonth()
        );

        const isAvailable = minimaDisponibilidad.map(
            ({ fecha_inicio, fecha_final, numero_noches }) => {
                const FDI = new Date(fecha_inicio + "T00:00:00");
                const FDF = new Date(fecha_final + "T00:00:00");
                const [FRI, FRF] = dates;

                const array_dias_disponibilidad = getEveryDayBetween2Dates([
                    FDI,
                    FDF,
                ]);
                const array_dias_reserva = getEveryDayBetween2Dates([FRI, FRF]);

                if (FRI <= FDI) {
                    if (FRF <= FDI) {
                        return {
                            available: true,
                            message: "Se puede reservar",
                        }; // case 1
                    } else {
                        if (FRF >= FDF) {
                            return {
                                available: true,
                                message: "Se puede reservar",
                            }; // case 4
                        } else {
                            if (
                                allowReservation(
                                    array_dias_reserva,
                                    array_dias_disponibilidad,
                                    numero_noches
                                )
                            ) {
                                return {
                                    available: true,
                                    message: "Se puede reservar",
                                };
                            } else {
                                return {
                                    available: false,
                                    message: `Es necesario reservar minimamente ${numero_noches} noches entre el día ${FDI.toLocaleDateString(
                                        "es-ES",
                                        options
                                    )} y el día ${FDF.toLocaleDateString(
                                        "es-ES",
                                        options
                                    )}`,
                                }; // case 2
                            }
                        }
                    }
                } else {
                    if (FRI > FDF) {
                        return {
                            available: true,
                            message: "Se puede reservar",
                        }; // case 5
                    } else {
                        if (
                            allowReservation(
                                array_dias_reserva,
                                array_dias_disponibilidad,
                                numero_noches
                            )
                        ) {
                            return {
                                available: true,
                                message: "Se puede reservar",
                            };
                        } else {
                            return {
                                available: false,
                                message: `Es necesario reservar minimamente ${numero_noches} noches entre el día ${FDI.toLocaleDateString(
                                    "es-ES",
                                    options
                                )} y el día ${FDF.toLocaleDateString(
                                    "es-ES",
                                    options
                                )}`,
                            }; // case 3
                        }
                    }
                }
            }
        );

        if (
            allDatesSelected.some(
                (date) =>
                    arrayFechas.includes(
                        formatedDate_YYYY_MM_DD(new Date(date))
                    ) ||
                    arrayPedidos.includes(
                        formatedDate_YYYY_MM_DD(new Date(date))
                    )
            ) ||
            allDaysSelected.some((day) => arrayDias.includes(day)) ||
            allMonthssSelected.some((month) => arrayMeses.includes(month))
        ) {
            setCantDays(0);
            setSelectedDays([]);
            setErrorDates("No puede elegir días no disponibles");
        } else {
            if (isAvailable.some(({ available }) => available === false)) {
                setCantDays(0);
                setSelectedDays([]);
                const { message } = isAvailable.find(
                    ({ available }) => available === false
                );
                setErrorDates(message);
            } else if (
                totalDays <
                (currentProduct.tipo_estadia === "dia"
                    ? currentProduct.alquiler_minimo
                    : currentProduct.alquiler_minimo + 1)
            ) {
                let match = false;
                const [FRI, FRF] = dates;
                minimaDisponibilidad.forEach(
                    ({ fecha_inicio, fecha_final, numero_noches }) => {
                        const FDI = new Date(fecha_inicio + "T00:00:00");
                        const FDF = new Date(fecha_final + "T00:00:00");
                        if (
                            (FDI <= FRI && FDF >= FRI) ||
                            (FDI <= FRF && FDF >= FRF) ||
                            (FRI <= FDI && FRF >= FDI) ||
                            (FRI <= FDF && FRF >= FDF)
                        ) {
                            if (totalDays >= numero_noches) {
                                match = true;
                            }
                        }
                    }
                );
                if (match) {
                    setErrorDates(null);
                    setCantDays(totalDays);
                    setSelectedDays([dateOne, dateTwo]);
                } else {
                    setCantDays(0);
                    setSelectedDays([]);
                    setErrorDates(`Seleccione un mínimo de
                    ${
                        currentProduct.alquiler_minimo +
                        (currentProduct.tipo_estadia === "dia" ? 0 : 1)
                    }
                    dias`);
                }
            } else {
                setErrorDates(null);
                setCantDays(totalDays);
                setSelectedDays([dateOne, dateTwo]);
            }
        }
    };

    const calculateDisabledTiles = (date) => {
        if (arrayFechas?.length > 0) {
            const block = arrayFechas?.some((fecha) => {
                return (
                    date.getTime() ===
                        new Date(fecha + "T00:00:00").getTime() ||
                    arrayDias.some((day) => day === date.getDay()) ||
                    arrayMeses.some((month) => date.getMonth() === month)
                );
            });
            if (block) return block;
        }
        if (arrayPedidos.length > 0) {
            const block = arrayPedidos?.some((fecha) => {
                return (
                    date.getTime() ===
                        new Date(fecha + "T00:00:00").getTime() ||
                    arrayDias.some((day) => day === date.getDay()) ||
                    arrayMeses.some((month) => date.getMonth() === month)
                );
            });
            if (block) return block;
        }
        if (arrayDias.length > 0) {
            return (
                arrayDias.some((day) => date.getDay() === day) ||
                arrayMeses.some((month) => date.getMonth() === month)
            );
        }
        return arrayMeses.some((month) => date.getMonth() === month);
    };

    const getEveryDayBetween2Dates = (arrayFechas) => {
        const [startDate, endDate] = arrayFechas.map(
            (fecha) => new Date(fecha)
        );
        const date = new Date(startDate.getTime());
        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    };

    return (
        <Calendar
            selectRange={true}
            onChange={onChangeCalendar}
            minDate={new Date()}
            tileDisabled={({ activeStartDate, date, view }) =>
                calculateDisabledTiles(date)
            }
        />
    );
};

export default DetailedCalendarAlojamientos;
