import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

const CRMModalEncargado = ({ setcreate, nombreEmpresa }) => {
    const dispatch = useDispatch();
    const createRedux = useSelector((state) => state.attributesReducer.create);
    const form = new FormData();
    const [cargando, SetCargando] = useState(false);
    const [administradores, setAdministradores] = useState([]);
    const [idEncargado, setIdEncargado] = useState(
        nombreEmpresa.id_encargado_bluecorner
    );
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const buscarAdministrador = async () => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/Autenticacion/getPersonal/`,
            { headers }
        );

        setAdministradores(data.data);
    };

    const handleSubmit = async () => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/empresas/actualizarAdministrativo/${nombreEmpresa.id_empresa}`,
            {
                id_encargado_bluecorner: idEncargado,
            },
            { headers }
        );
        console.log(data);
        if (data.message === "Actualizacion exitosa!") {
            Swal.fire({
                title: "Procesado!",
                text: "Encargado actualizado correctamente",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
            setcreate({
                bandera: false,
                nombre: "",
            });
        }
    };
    useEffect(() => {
        buscarAdministrador();
        // Swal.fire({
        //     title: "Procesado!",
        //     text: "Servicio creado",
        //     icon: "success",
        //     confirmButtonText: "Aceptar",
        // });
    }, []);

    return (
        <div className="relative z-20 flex items-center justify-center ">
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-48">
                    <div className="relative flex flex-col items-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <div className="w-full my-3 text-left">
                            <header className="mb-1 pl-2 text-[#3C3C3C]  text-base md:text-xl font-bold ">
                                Encargado de la empresa
                            </header>
                        </div>

                        <div className="w-full text-left">
                            <header className=" pl-2 mb-2 text-[#3C3C3C]  text-base">
                                {nombreEmpresa.nombre}
                            </header>
                        </div>
                        <div className="h-64 mx-auto overflow-y-auto w-72 xs:w-80 ">
                            {administradores.map((administrador) => (
                                <div
                                    onClick={() =>
                                        setIdEncargado(administrador.id)
                                    }
                                    className={
                                        idEncargado === administrador.id
                                            ? "w-60 xs:w-72 items-center  p-2 m-1  text-center   border-2 border-gray-400 rounded-lg  bg-blue-500 text-white cursor-pointer"
                                            : "w-60 xs:w-72 items-center p-2 m-1  text-center border-2 border-gray-400 rounded-lg  hover:bg-blue-500 hover:text-white cursor-pointer"
                                    }
                                    key={administrador.id}
                                    value={administrador.id}>
                                    {administrador.personal}
                                </div>
                            ))}
                        </div>
                        <div className="w-full ">
                            <div className="mx-auto overflow-hidden bg-white rounded-lg ">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="py-3"></div>
                                        <div className="pb-3 text-center ">
                                            <button
                                                onClick={() => handleSubmit()}
                                                disabled={cargando}
                                                className="w-full p-3 text-center text-white border border-blue-400 rounded-md bg-blue-bluecorner hover:bg-white hover:text-blue-bluecorner disabled:bg-blue-400">
                                                {cargando
                                                    ? "Guardando..."
                                                    : "Guardar"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() =>
                                setcreate({
                                    bandera: false,
                                    nombre: "",
                                })
                            }
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CRMModalEncargado;
