import React from "react";

const CardSubPropietario = ({ admin }) => {
    console.log(admin);
    return (
        <div className="mt-3 border p-3 rounded-lg">
            <div className="flex items-center justify-between">
                <h1 className="text-base font-medium mr-1">Nombre: </h1>
                <div className="text-base font-medium">
                    {admin.nombre} {admin.apellido_paterno}{" "}
                    {admin.apellido_materno}
                </div>
            </div>
            <div className="flex items-center justify-between">
                <h1 className="text-base font-medium mr-1">Celular: </h1>
                <div className="text-base font-medium">{admin.celular}</div>
            </div>
            <div className="flex items-center justify-between">
                <h1 className="text-base font-medium mr-1">Correo: </h1>
                <div className="text-base font-medium">{admin.correo}</div>
            </div>
        </div>
    );
};

export default CardSubPropietario;
