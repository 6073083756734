import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Spinner from "../Spinner";
import EditarAlquilerMinimo from "./ModalsEditarDetalles/AlquilerMinimo/EditarAlquilerMinimo";
import EditarAtributos from "./ModalsEditarDetalles/EditarAtributos";
import EditarCapacidades from "./ModalsEditarDetalles/EditarCapacidades";
import EditarCheckIn from "./ModalsEditarDetalles/EditarCheckIn";
import EditarCheckOut from "./ModalsEditarDetalles/EditarCheckOut";
import EditarDescripcion from "./ModalsEditarDetalles/EditarDescripcion";
import EditarDescripcionLugar from "./ModalsEditarDetalles/EditarDescripcionLugar";
import EditarHabitaciones from "./ModalsEditarDetalles/EditarHabitaciones";
import EditarImages from "./ModalsEditarDetalles/EditarImages";
import EditarLink360 from "./ModalsEditarDetalles/EditarLink360";
import EditarNombre from "./ModalsEditarDetalles/EditarNombre";
import EditarPoliticas from "./ModalsEditarDetalles/EditarPoliticas";
import EditarPrecioBaseYGarantia from "./ModalsEditarDetalles/EditarPrecioBaseYGarantia";
import EditarReglas from "./ModalsEditarDetalles/EditarReglas";
import EditarReglasAdicionales from "./ModalsEditarDetalles/EditarReglasAdicionales";
import EditarTarifas from "./ModalsEditarDetalles/TarifasAdicionales/EditarTarifas";
import EditarTipoPropiedad from "./ModalsEditarDetalles/EditarTipoPropiedad";
import EditarEtiquetas from "./ModalsEditarDetalles/EditarEtiquetas";
import EditarTurnosEmbarcacion from "./ModalsEditarDetalles/EditarTurnosEmbarcacion";
import EditarMapa from "./ModalsEditarDetalles/UbicacionMapa/EditarMapa";
import SidebarDetalle from "./SidebarDetalle";
import EditarDetalleDesktop from "./EditarDetalleDesktop";
import PriceCalendar from "./onboarding 6/priceCalendar/PriceCalendar";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import EditarPartidaRegistral from "./ModalsEditarDetalles/EditarPartidaRegistral";
import EditarFee from "./ModalsEditarDetalles/FeeAvanzado/EditarFee";
import EditarAdministrador from "./ModalsEditarDetalles/EditarAdministrador";
import EditarPrecioPorPersona from "./ModalsEditarDetalles/PrecioPorPersona/EditarPrecioPorPersona";
import EditarGarantiaMascotas from "./ModalsEditarDetalles/EditarGarantiaMascotas";
import EditarAlquilerMaximo from "./ModalsEditarDetalles/AlquilerMaximo/EditarAlquilerMaximo";
import EditarPrecioPorEmbarque from "./ModalsEditarDetalles/EditarPrecioPorEmbarque";
import { normalizeString } from "../helpers/normalizeString";
import EditarHabitacionesDeServicios from "./ModalsEditarDetalles/EditarHabitacionesDeServicios";
import EditarClaveExterna from "./ModalsEditarDetalles/EditarClaveExterna";
import EditarOferta from "./ModalsEditarDetalles/EditarOferta";
import EditarCasaTop from "./ModalsEditarDetalles/EditarCasaTop";
import EditarOfertaCasas from "./ModalsEditarDetalles/EditarOfertaCasas";
import EditarFeeEmpresa from "./ModalsEditarDetalles/FeeEmpresa/EditarFeeEmpresa";

const EditarDetalle = ({
    setModalEditarDetalle,
    tipo,
    setProductsAll,
    setPage,
    setModalCalendario,
    modalcalendario,
    producto,
    setProducto,
    setLoading,
    setCargandoRegreso,
}) => {
    const navigate = useNavigate();
    const [showDetails, setShowDetails] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [regresar, setRegresar] = useState(false);
    const [administradores, setAdministradores] = useState([]);
    // COMPONENTES ACA //
    const [showFotos, setShowFotos] = useState(false);
    const [showSobreAlojamiento, setShowSobreAlojamiento] = useState(false);
    const [showPropiedades, setShowPropiedades] = useState(false);
    const [showReglas, setShowReglas] = useState(false);
    const [showPoliticas, setShowPoliticas] = useState(false);
    const [showPrecios, setShowPrecios] = useState(false);
    const [showTarifasAdicionales, setShowTarifasAdicionales] = useState(false);
    const [showConfiguracion, setShowConfiguracion] = useState(false);
    const [modalEditarFeeEmpresa, setModalEditarFeeEmpresa] = useState(false);

    // COMPONENTES ACA //

    // MODALS ACA //

    const [modalImages, setModalImages] = useState(false);
    const [modalNombre, setModalNombre] = useState(false);
    const [modalDescripcion, setModalDescripcion] = useState(false);
    const [modalTour360, setModalTour360] = useState(false);
    const [modalCapacidades, setModalCapacidades] = useState(false);
    const [modalTipoPropiedad, setModalTipoPropiedad] = useState(false);
    const [modalDescripcionLugar, setModalDescripcionLugar] = useState(false);
    const [modalHabitaciones, setModalHabitaciones] = useState(false);
    const [modalHabitacionesDeServicio, setModalHabitacionesDeServicio] =
        useState(false);
    const [modalAtributos, setModalAtributos] = useState(false);
    const [modalCheckIn, setModalCheckIn] = useState(false);
    const [modalCheckOut, setModalCheckOut] = useState(false);
    const [modalReglas, setModalReglas] = useState(false);
    const [modalReglasAdicionales, setModalReglasAdicionales] = useState(false);
    const [modalPrecioBaseGarantia, setModalPrecioBaseGarantia] =
        useState(false);
    const [modalAlquilerMinimo, setModalAlquilerMinimo] = useState(false);
    const [modalAlquilerMaximo, setModalAlquilerMaximo] = useState(false);

    const [modalEditarPoliticas, setModalEditarPoliticas] = useState(false);
    const [modalEditarPartidaRegistral, setModalEditarPartidaRegistral] =
        useState(false);
    const [modalEditarTarifas, setModalEditarTarifas] = useState(false);
    const [modalGarantiaMascota, setModalGarantiaMascota] = useState(false);
    const [modalFee, setModalFee] = useState(false);
    const [modalAdministrador, setModalAdministrador] = useState(false);
    const [modalTop, setModalTop] = useState(false);
    const [modalEtiquetas, setModalEtiquetas] = useState(false);
    const [modalTurnosEmbarcacion, setModalTurnosEmbarcacion] = useState(false);
    const [modalMapa, setModalMapa] = useState(false);
    const [modalEditarPrecioPorPersona, setModalEditarPrecioPorPersona] =
        useState(false);
    const [modalEditarPrecioPorEmbarque, setModalEditarPrecioPorEmbarque] =
        useState(false);
    const [modalClaveExterna, setModalClaveExterna] = useState(false);
    const [modalEditarOferta, setModalEditarOferta] = useState(false);
    const [modalEditarOfertaCasas, setModalEditarOfertaCasas] = useState(false);

    // MODALS ACA //
    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const publicar = async () => {
        setCargando(true);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${producto.data.tipo_producto}/updatePublicado/${producto.data.id}`,
            {
                publicado:
                    producto.data.publicado !== "Publicado"
                        ? "Publicado"
                        : "Revision",
                producto_nombre: producto.data.nombre,
                id_propietario: producto.data.id_propietario,
            },
            { headers }
        );

        if (data.message === "Actualizado con exito") {
            const jsonDetalle = await axios.get(
                `${process.env.REACT_APP_PROXY}/${tipo}/detalle/${producto.data.id}/0`
            );

            Swal.fire({
                title: data.publicado.publicado,
                text:
                    data.publicado.publicado === "Revision"
                        ? "El producto se ha puesto en revision"
                        : "El producto ha sido publicado",
                icon: "success",
                confirmButtonText: "Ok",
            });

            setProducto({
                checking: false,
                data: {
                    ...jsonDetalle.data,
                    tipo_producto: tipo,
                },
            });
            setCargando(false);
        }
    };
    const buscarAdministrador = async (id) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/Autenticacion/getPersonal/`,
            { headers }
        );

        setAdministradores(data.data);
    };

    useEffect(() => {
        buscarAdministrador();
    }, []);

    return (
        <div className="w-full">
            <div
                className={
                    regresar
                        ? "hidden"
                        : "flex items-center text-lg cursor-pointer pt-4 px-4 mt-6  w-full  "
                }
                onClick={() => {
                    setModalEditarDetalle(false);
                    setModalCalendario(false);
                    // setLoading(true);
                    // setCargandoRegreso((prev) => !prev);
                    navigate(
                        `/${
                            authUser.tipo_usuario > 2 ? "perfil" : "dashboard"
                        }/anuncios`
                    );
                }}
            >
                <svg
                    className="w-6 h-6 -ml-2 text-black"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    {" "}
                    <polyline points="15 18 9 12 15 6" />
                </svg>
                <span className="mx-1">Regresar</span>
            </div>
            {modalcalendario ? (
                <PriceCalendar
                    setPage={setPage}
                    setOnboarding={setProducto}
                    onboarding={producto.data}
                    producto={producto}
                    setProductsAll={setProductsAll}
                    setModalCalendario={setModalCalendario}
                    tipo={tipo}
                    setRegresar={setRegresar}
                />
            ) : (
                <div className=" pb-20 w-full">
                    {producto.checking ? (
                        <Spinner margin_top={10} />
                    ) : (
                        <div className="w-full">
                            <div className="flex flex-col w-full ">
                                <div className="flex items-center justify-between lg:justify-start w-full">
                                    <h1 className="text-2xl font-bold lg:text-4xl">
                                        {producto.data.nombre}
                                    </h1>
                                    <Link
                                        target="_blank"
                                        to={
                                            tipo === "casa"
                                                ? `/alojamientos/${
                                                      producto.data.id
                                                  }/${normalizeString(
                                                      producto.data.nombre
                                                  )}`
                                                : `/embarcaciones/${
                                                      producto.data.id
                                                  }/${normalizeString(
                                                      producto.data.nombre
                                                  )}`
                                        }
                                        className="mt-2 text-right lg:mx-5 text-blue-bluecorner hover:underline"
                                    >
                                        Visitar mi publicación
                                    </Link>
                                </div>
                                <div className="flex mt-6">
                                    <button
                                        className={`${
                                            showDetails
                                                ? "p-3 px-6 w-[156px]  text-white bg-blue-500 border rounded-lg border-blue-500"
                                                : "p-3 text-blue-500 border border-blue-500 px-6 w-[156px] rounded-lg hover:bg-white hover:text-blue-500"
                                        }`}
                                        onClick={() => setShowDetails(true)}
                                    >
                                        Detalles
                                    </button>
                                    <button
                                        className={`${
                                            showDetails
                                                ? "p-3 ml-2 text-blue-500 border border-blue-500 px-6 w-[156px] rounded-lg hover:bg-white hover:text-blue-500"
                                                : "p-3 px-6 w-[156px] text-white bg-blue-500 border rounded-lg ml-2 border-blue-500"
                                        }`}
                                        onClick={() => setModalCalendario(true)}
                                    >
                                        Disponibilidad
                                    </button>
                                </div>
                                {/* MOBILE ACA */}
                                <div className="lg:grid lg:grid-cols-[35%_65%] lg1170:grid-cols-[25%_75%] lg:w-10/12 gap-20 xl:gap-32  max-w-5xl">
                                    <SidebarDetalle
                                        tipo_producto={tipo}
                                        setShowFotos={setShowFotos}
                                        setShowSobreAlojamiento={
                                            setShowSobreAlojamiento
                                        }
                                        setShowPropiedades={setShowPropiedades}
                                        setShowPrecios={setShowPrecios}
                                        setShowPoliticas={setShowPoliticas}
                                        setShowReglas={setShowReglas}
                                        setShowTarifasAdicionales={
                                            setShowTarifasAdicionales
                                        }
                                        setShowConfiguracion={
                                            setShowConfiguracion
                                        }
                                        producto={producto}
                                        publicar={publicar}
                                        cargando={cargando}
                                    />
                                    <div className="lg:hidden">
                                        <div className="mt-8">
                                            {modalImages ? (
                                                <EditarImages
                                                    setProducto={setProducto}
                                                    producto={producto}
                                                    setModal={setModalImages}
                                                    tipo_producto={tipo}
                                                />
                                            ) : (
                                                <div>
                                                    <div className="flex items-end justify-between">
                                                        <h1 className="text-xl font-bold">
                                                            Fotos
                                                        </h1>

                                                        <h1
                                                            onClick={() =>
                                                                setModalImages(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                    <div className="w-full">
                                                        <Link
                                                            to={
                                                                "/paquetes-de-contenido"
                                                            }
                                                            className="my-3 text-base font-normal cursor-pointer select-none text-blue-bluecorner hover:underline"
                                                        >
                                                            Consulte aquí si
                                                            quieres mejorar tus
                                                            fotos, contacta con
                                                            Blue Corner para
                                                            brindar el servicio.
                                                        </Link>
                                                    </div>
                                                    <img
                                                        className="object-cover w-full mt-4 rounded-lg h-52"
                                                        src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/${tipo}/getImagenPrincipal/${producto.data.imagen_principal}`}
                                                        alt="Imagen principal"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <hr className="my-6" />
                                        <div className="flex flex-col">
                                            <div className="flex justify-between">
                                                <h1 className="text-xl font-bold">
                                                    {tipo === "casa"
                                                        ? "Sobre el alojamiento"
                                                        : "Sobre la embarcacion"}
                                                </h1>
                                            </div>
                                            <div>
                                                {modalNombre ? (
                                                    <EditarNombre
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalNombre
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between mt-7">
                                                        <div>
                                                            <h1>
                                                                Título del
                                                                anuncio
                                                            </h1>
                                                            <h2 className="mt-2 text-xs">
                                                                {
                                                                    producto
                                                                        .data
                                                                        .nombre
                                                                }
                                                            </h2>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalNombre(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                            </div>
                                            <hr className="my-4" />

                                            {modalTipoPropiedad ? (
                                                <EditarTipoPropiedad
                                                    setProducto={setProducto}
                                                    producto={producto}
                                                    setModal={
                                                        setModalTipoPropiedad
                                                    }
                                                    tipo_producto={tipo}
                                                />
                                            ) : (
                                                <div className="flex justify-between ">
                                                    <div>
                                                        {tipo === "casa"
                                                            ? "Tipo de propiedad"
                                                            : "Tipo de embarcación"}
                                                        <h2 className="mt-2 text-xs">
                                                            {tipo === "casa"
                                                                ? producto.data
                                                                      .tipo_alojamiento
                                                                : producto.data
                                                                      .tipo_embarcacion}
                                                        </h2>
                                                    </div>
                                                    <h1
                                                        onClick={() =>
                                                            setModalTipoPropiedad(
                                                                true
                                                            )
                                                        }
                                                        className="font-normal text-blue-bluecorner"
                                                    >
                                                        Editar
                                                    </h1>
                                                </div>
                                            )}
                                            <hr className="my-4" />
                                            {modalDescripcion ? (
                                                <EditarDescripcion
                                                    setProducto={setProducto}
                                                    producto={producto}
                                                    setModal={
                                                        setModalDescripcion
                                                    }
                                                    tipo_producto={tipo}
                                                />
                                            ) : (
                                                <div className="flex justify-between ">
                                                    <div>
                                                        <h1>
                                                            Descripción del
                                                            anuncio
                                                        </h1>
                                                        <h2 className="mt-2 text-xs">
                                                            {
                                                                producto.data
                                                                    .descripcion
                                                            }
                                                        </h2>
                                                    </div>
                                                    <h1
                                                        onClick={() =>
                                                            setModalDescripcion(
                                                                true
                                                            )
                                                        }
                                                        className="font-normal text-blue-bluecorner"
                                                    >
                                                        Editar
                                                    </h1>
                                                </div>
                                            )}
                                            <hr className="my-4" />
                                            {modalTour360 ? (
                                                <EditarLink360
                                                    setProducto={setProducto}
                                                    producto={producto}
                                                    setModal={setModalTour360}
                                                    tipo_producto={tipo}
                                                />
                                            ) : (
                                                <div className="flex justify-between ">
                                                    <div>
                                                        <h1>Tour 360°</h1>
                                                        <h2 className="mt-2 text-xs">
                                                            {producto.data
                                                                .link360 ||
                                                                "No posee video Tour 360°"}
                                                        </h2>
                                                    </div>
                                                    <h1
                                                        onClick={() =>
                                                            setModalTour360(
                                                                true
                                                            )
                                                        }
                                                        className="font-normal text-blue-bluecorner"
                                                    >
                                                        Editar
                                                    </h1>
                                                </div>
                                            )}
                                            <hr className="my-4" />
                                            {tipo === "casa" && (
                                                <div>
                                                    {modalCapacidades ? (
                                                        <EditarCapacidades
                                                            setProducto={
                                                                setProducto
                                                            }
                                                            producto={producto}
                                                            setModal={
                                                                setModalCapacidades
                                                            }
                                                            tipo_producto={tipo}
                                                        />
                                                    ) : (
                                                        <div className="flex justify-between ">
                                                            <div>
                                                                <h1>
                                                                    Capacidades
                                                                    del
                                                                    alojamiento
                                                                </h1>
                                                                <div className="flex flex-col mt-2 text-xs">
                                                                    <h2>
                                                                        {
                                                                            producto
                                                                                .data
                                                                                .numero_personas
                                                                        }{" "}
                                                                        Personas
                                                                    </h2>
                                                                    <h2>
                                                                        {
                                                                            producto
                                                                                .data
                                                                                .numero_habitaciones
                                                                        }{" "}
                                                                        Habitaciones
                                                                    </h2>
                                                                    <h2>
                                                                        {
                                                                            producto
                                                                                .data
                                                                                .numero_habitaciones_servicio
                                                                        }{" "}
                                                                        Habitaciones
                                                                        de
                                                                        servicio
                                                                    </h2>
                                                                    <h2>
                                                                        {
                                                                            producto
                                                                                .data
                                                                                .numero_banos
                                                                        }{" "}
                                                                        Baños
                                                                    </h2>
                                                                    {/* <h2>
                                                                        {
                                                                            producto
                                                                                .data
                                                                                .numero_mascotas
                                                                        }{" "}
                                                                        Mascotas
                                                                    </h2> */}
                                                                </div>
                                                            </div>
                                                            <h1
                                                                onClick={() =>
                                                                    setModalCapacidades(
                                                                        true
                                                                    )
                                                                }
                                                                className="font-normal text-blue-bluecorner"
                                                            >
                                                                Editar
                                                            </h1>
                                                        </div>
                                                    )}
                                                    <hr className="my-4" />
                                                </div>
                                            )}
                                        </div>
                                        {tipo === "casa" && (
                                            <div>
                                                {modalHabitaciones ? (
                                                    <EditarHabitaciones
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalHabitaciones
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between ">
                                                        <div className="w-full ">
                                                            <h1>
                                                                Detalles de
                                                                ambientes y
                                                                habitaciones
                                                            </h1>
                                                            <div className="flex flex-col">
                                                                {producto.data.habitacciones_detalle?.map(
                                                                    (
                                                                        habitacion,
                                                                        index
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                habitacion.id
                                                                            }
                                                                        >
                                                                            {habitacion
                                                                                .productos_habitacion_detalle
                                                                                ?.length >
                                                                                0 && (
                                                                                <div className="mt-3 ">
                                                                                    <h1 className="font-medium">
                                                                                        Habitacion{" "}
                                                                                        {index +
                                                                                            1}
                                                                                    </h1>
                                                                                    {habitacion.productos_habitacion_detalle.map(
                                                                                        (
                                                                                            detalle
                                                                                        ) => (
                                                                                            <div
                                                                                                className="flex flex-col mt-1 text-xs"
                                                                                                key={
                                                                                                    detalle.id
                                                                                                }
                                                                                            >
                                                                                                <div>
                                                                                                    <span>
                                                                                                        {
                                                                                                            detalle.cantidad
                                                                                                        }{" "}
                                                                                                    </span>
                                                                                                    <span className="capitalize">
                                                                                                        {detalle.nombre ===
                                                                                                            "cama" &&
                                                                                                            detalle.nombre}
                                                                                                    </span>{" "}
                                                                                                    <span>
                                                                                                        {detalle.nombre ===
                                                                                                            "camarote" ||
                                                                                                        detalle.nombre ===
                                                                                                            "divan"
                                                                                                            ? `${
                                                                                                                  detalle.nombre ===
                                                                                                                  "camarote"
                                                                                                                      ? "Camarote"
                                                                                                                      : "Divan"
                                                                                                              } de una cama ` +
                                                                                                              detalle.detalle +
                                                                                                              " y "
                                                                                                            : detalle.detalle}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {detalle.nombre ===
                                                                                                            "camarote" ||
                                                                                                        (detalle.nombre ===
                                                                                                            "divan" &&
                                                                                                            detalle.detalle2)
                                                                                                            ? "una cama " +
                                                                                                              detalle.detalle2
                                                                                                            : null}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                        <h1
                                                            onClick={() => {
                                                                setProducto({
                                                                    ...producto,
                                                                    data: {
                                                                        ...producto.data,
                                                                        roomAttribute:
                                                                            {},
                                                                    },
                                                                });
                                                                setModalHabitaciones(
                                                                    true
                                                                );
                                                            }}
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                                <hr className="my-4" />
                                            </div>
                                        )}

                                        {tipo === "casa" &&
                                            producto.data
                                                .numero_habitaciones_servicio >
                                                0 && (
                                                <div>
                                                    {modalHabitacionesDeServicio ? (
                                                        <EditarHabitacionesDeServicios
                                                            setProducto={
                                                                setProducto
                                                            }
                                                            producto={producto}
                                                            setModal={
                                                                setModalHabitacionesDeServicio
                                                            }
                                                            tipo_producto={tipo}
                                                        />
                                                    ) : (
                                                        <div className="flex justify-between ">
                                                            <div className="w-full ">
                                                                <h1>
                                                                    Detalle de
                                                                    habitaciones
                                                                    de servicio
                                                                </h1>
                                                                <div className="flex flex-col">
                                                                    {producto.data.habitacciones_detalle?.map(
                                                                        (
                                                                            habitacion,
                                                                            index
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    habitacion.id
                                                                                }
                                                                            >
                                                                                {habitacion
                                                                                    .productos_habitacion_detalle
                                                                                    ?.length >
                                                                                    0 && (
                                                                                    <div className="mt-3 ">
                                                                                        <h1 className="font-medium">
                                                                                            Habitacion{" "}
                                                                                            {index +
                                                                                                1}
                                                                                        </h1>
                                                                                        {habitacion.productos_habitacion_detalle.map(
                                                                                            (
                                                                                                detalle
                                                                                            ) => (
                                                                                                <div
                                                                                                    className="flex flex-col mt-1 text-xs"
                                                                                                    key={
                                                                                                        detalle.id
                                                                                                    }
                                                                                                >
                                                                                                    <div>
                                                                                                        <span>
                                                                                                            {
                                                                                                                detalle.cantidad
                                                                                                            }{" "}
                                                                                                        </span>
                                                                                                        <span className="capitalize">
                                                                                                            {detalle.nombre ===
                                                                                                                "cama" &&
                                                                                                                detalle.nombre}
                                                                                                        </span>{" "}
                                                                                                        <span>
                                                                                                            {detalle.nombre ===
                                                                                                                "camarote" ||
                                                                                                            detalle.nombre ===
                                                                                                                "divan"
                                                                                                                ? `${
                                                                                                                      detalle.nombre ===
                                                                                                                      "camarote"
                                                                                                                          ? "Camarote"
                                                                                                                          : "Divan"
                                                                                                                  } de una cama ` +
                                                                                                                  detalle.detalle +
                                                                                                                  " y "
                                                                                                                : detalle.detalle}
                                                                                                        </span>
                                                                                                        <span>
                                                                                                            {detalle.nombre ===
                                                                                                                "camarote" ||
                                                                                                            (detalle.nombre ===
                                                                                                                "divan" &&
                                                                                                                detalle.detalle2)
                                                                                                                ? "una cama " +
                                                                                                                  detalle.detalle2
                                                                                                                : null}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <h1
                                                                onClick={() => {
                                                                    setProducto(
                                                                        {
                                                                            ...producto,
                                                                            data: {
                                                                                ...producto.data,
                                                                                roomAttribute:
                                                                                    {},
                                                                            },
                                                                        }
                                                                    );
                                                                    setModalHabitacionesDeServicio(
                                                                        true
                                                                    );
                                                                }}
                                                                className="font-normal text-blue-bluecorner"
                                                            >
                                                                Editar
                                                            </h1>
                                                        </div>
                                                    )}
                                                    <hr className="my-4" />
                                                </div>
                                            )}
                                        {tipo === "casa" && (
                                            <>
                                                {modalAlquilerMinimo ? (
                                                    <EditarAlquilerMinimo
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalAlquilerMinimo
                                                        }
                                                    />
                                                ) : (
                                                    <div className="flex justify-between ">
                                                        <div>
                                                            <h1>
                                                                Alquiler mínimo
                                                            </h1>
                                                            <h2 className="mt-2 text-xs">
                                                                {
                                                                    producto
                                                                        .data
                                                                        .alquiler_minimo
                                                                }{" "}
                                                                {producto.data
                                                                    .alquiler_minimo ===
                                                                1
                                                                    ? producto
                                                                          .data
                                                                          .tipo_estadia
                                                                    : `${producto.data.tipo_estadia}s`}
                                                            </h2>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalAlquilerMinimo(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                                <hr className="my-4" />
                                                {modalAlquilerMaximo ? (
                                                    <EditarAlquilerMaximo
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalAlquilerMaximo
                                                        }
                                                    />
                                                ) : (
                                                    <div className="flex justify-between ">
                                                        <div>
                                                            <h1>
                                                                Alquiler maximo
                                                            </h1>
                                                            <h2
                                                                className={
                                                                    producto
                                                                        .data
                                                                        .alquiler_maximo >
                                                                    0
                                                                        ? "mt-2 text-xs"
                                                                        : "hidden"
                                                                }
                                                            >
                                                                {
                                                                    producto
                                                                        .data
                                                                        .alquiler_maximo
                                                                }{" "}
                                                                {producto.data
                                                                    .alquiler_maximo ===
                                                                1
                                                                    ? producto
                                                                          .data
                                                                          .tipo_estadia
                                                                    : `${producto.data.tipo_estadia}s`}
                                                            </h2>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalAlquilerMaximo(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                                <hr className="my-4" />
                                            </>
                                        )}

                                        <div>
                                            {modalMapa ? (
                                                <EditarMapa
                                                    setProducto={setProducto}
                                                    producto={producto}
                                                    setModal={setModalMapa}
                                                    tipo_producto={tipo}
                                                />
                                            ) : (
                                                <div className="flex justify-between ">
                                                    <div>
                                                        <h1>Ubicación</h1>
                                                        <div className="flex flex-col mt-2 text-xs">
                                                            <h2>
                                                                {
                                                                    producto
                                                                        .data
                                                                        .pais
                                                                }
                                                                ,{" "}
                                                                {
                                                                    producto
                                                                        .data
                                                                        .departamento
                                                                }
                                                                ,{" "}
                                                                {
                                                                    producto
                                                                        .data
                                                                        .ciudad
                                                                }
                                                            </h2>
                                                            <h2>
                                                                Calle{" "}
                                                                {
                                                                    producto
                                                                        .data
                                                                        .direccion_calle
                                                                }
                                                            </h2>
                                                            <h2>
                                                                Numero{" "}
                                                                {
                                                                    producto
                                                                        .data
                                                                        .direccion_numero
                                                                }
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <h1
                                                        onClick={() =>
                                                            setModalMapa(true)
                                                        }
                                                        className="font-normal text-blue-bluecorner"
                                                    >
                                                        Editar
                                                    </h1>
                                                </div>
                                            )}
                                            <hr className="my-4" />
                                        </div>
                                        {tipo === "casa" && (
                                            <div>
                                                {modalDescripcionLugar ? (
                                                    <EditarDescripcionLugar
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalDescripcionLugar
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between ">
                                                        <div>
                                                            <h1>
                                                                Descripción del
                                                                lugar
                                                            </h1>
                                                            {producto.data.ubicacion.map(
                                                                (ubicacion) => (
                                                                    <h2
                                                                        key={
                                                                            ubicacion.id
                                                                        }
                                                                        className="mt-2 text-xs"
                                                                    >
                                                                        {
                                                                            ubicacion.nombre
                                                                        }
                                                                    </h2>
                                                                )
                                                            )}
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalDescripcionLugar(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}{" "}
                                                <hr className="my-4" />
                                            </div>
                                        )}

                                        {tipo === "casa" && (
                                            <div>
                                                {modalAtributos ? (
                                                    <EditarAtributos
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalAtributos
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between ">
                                                        <div className="w-full ">
                                                            <h1>Servicios</h1>
                                                            {producto.data.atributos.map(
                                                                (atributo) => (
                                                                    <div
                                                                        className="flex w-full mt-2"
                                                                        key={
                                                                            atributo.id
                                                                        }
                                                                    >
                                                                        <img
                                                                            className="w-6"
                                                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/atributo/getImage/${atributo.imagen}`}
                                                                            alt="Atributo de la propiedad"
                                                                        />
                                                                        <h1 className="ml-2">
                                                                            {
                                                                                atributo.nombre
                                                                            }
                                                                        </h1>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                        <h1
                                                            onClick={() => {
                                                                setModalAtributos(
                                                                    true
                                                                );
                                                            }}
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div>
                                            {modalEtiquetas ? (
                                                <EditarEtiquetas
                                                    setProducto={setProducto}
                                                    producto={producto}
                                                    setModal={setModalEtiquetas}
                                                    tipo_producto={tipo}
                                                />
                                            ) : (
                                                <div className="flex justify-between ">
                                                    <div className="w-full ">
                                                        <h1>Etiquetas</h1>
                                                    </div>
                                                    <h1
                                                        onClick={() => {
                                                            setModalEtiquetas(
                                                                true
                                                            );
                                                        }}
                                                        className="font-normal text-blue-bluecorner"
                                                    >
                                                        Editar
                                                    </h1>
                                                </div>
                                            )}
                                        </div>
                                        {tipo === "casa" && (
                                            <div>
                                                <hr className="my-4" />{" "}
                                                {modalEditarPartidaRegistral ? (
                                                    <EditarPartidaRegistral
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalEditarPartidaRegistral
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between mt-4">
                                                        <div>
                                                            <h1>
                                                                Partida
                                                                registral
                                                            </h1>
                                                            <h2 className="mt-2 text-xs">
                                                                {producto.data.partida_registral.split(
                                                                    "_"
                                                                )[1] ||
                                                                    "No posee partida registral"}
                                                            </h2>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalEditarPartidaRegistral(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {tipo === "casa" && (
                                            <div>
                                                <hr className="my-4" />
                                                <div className="flex justify-between mt-4">
                                                    <h1 className="text-xl font-bold">
                                                        Reglas y Políticas
                                                    </h1>
                                                </div>
                                                {modalCheckIn ? (
                                                    <EditarCheckIn
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalCheckIn
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between mt-7">
                                                        <div>
                                                            <h1>Check-in</h1>
                                                            <h2 className="mt-2 text-xs">
                                                                {producto.data
                                                                    .check_in ||
                                                                    "No posee horario de check-in"}
                                                            </h2>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalCheckIn(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                                <hr className="my-4" />
                                                {modalCheckOut ? (
                                                    <EditarCheckOut
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalCheckOut
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between ">
                                                        <div>
                                                            <h1>Check-out</h1>
                                                            <h2 className="mt-2 text-xs">
                                                                {producto.data
                                                                    .check_out ||
                                                                    "No posee horario de check-out"}
                                                            </h2>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalCheckOut(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                                <hr className="my-4" />
                                                {modalReglas ? (
                                                    <EditarReglas
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalReglas
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between ">
                                                        <div>
                                                            <h1>Reglas</h1>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalReglas(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <hr className="my-4" />
                                        {modalReglasAdicionales ? (
                                            <EditarReglasAdicionales
                                                setProducto={setProducto}
                                                producto={producto}
                                                setModal={
                                                    setModalReglasAdicionales
                                                }
                                                tipo_producto={tipo}
                                            />
                                        ) : (
                                            <div className="flex justify-between ">
                                                <div>
                                                    <h1>Reglas adicionales</h1>
                                                    <h2 className="mt-2 text-xs">
                                                        {tipo === "casa"
                                                            ? producto.data
                                                                  .reglas_adicionales
                                                            : producto.data
                                                                  .reglas ||
                                                              "No posee reglas adicionales"}
                                                    </h2>
                                                </div>
                                                <h1
                                                    onClick={() =>
                                                        setModalReglasAdicionales(
                                                            true
                                                        )
                                                    }
                                                    className="font-normal text-blue-bluecorner"
                                                >
                                                    Editar
                                                </h1>
                                            </div>
                                        )}
                                        <hr className="my-4" />

                                        {modalEditarPoliticas ? (
                                            <EditarPoliticas
                                                setProducto={setProducto}
                                                producto={producto}
                                                setModal={
                                                    setModalEditarPoliticas
                                                }
                                                tipo_producto={tipo}
                                            />
                                        ) : (
                                            <div className="flex justify-between mt-3">
                                                <div>
                                                    <h1>
                                                        Política de cancelación
                                                    </h1>
                                                    <h2 className="mt-2 text-xs">
                                                        {producto.data
                                                            .cancelacion ||
                                                            "No posee política de cancelación"}
                                                    </h2>
                                                </div>
                                                <h1
                                                    onClick={() =>
                                                        setModalEditarPoliticas(
                                                            true
                                                        )
                                                    }
                                                    className="font-normal text-blue-bluecorner"
                                                >
                                                    Editar
                                                </h1>
                                            </div>
                                        )}
                                        <hr className="my-4" />

                                        {tipo === "casa" && (
                                            <div>
                                                <div className="flex justify-between mt-4">
                                                    <h1 className="text-xl font-bold">
                                                        Tarifas
                                                    </h1>
                                                </div>
                                                {modalPrecioBaseGarantia ? (
                                                    <EditarPrecioBaseYGarantia
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalPrecioBaseGarantia
                                                        }
                                                    />
                                                ) : (
                                                    <div className="flex justify-between mt-7">
                                                        <div>
                                                            <h1>Precio base</h1>
                                                            {tipo ===
                                                                "casa" && (
                                                                <span className="mt-2 text-xs">
                                                                    {producto
                                                                        .data
                                                                        .precio_base &&
                                                                    producto
                                                                        .data
                                                                        .moneda_precio_base ? (
                                                                        <h2>
                                                                            Desde{" "}
                                                                            {
                                                                                producto
                                                                                    .data
                                                                                    .precio_base
                                                                            }{" "}
                                                                            {
                                                                                producto
                                                                                    .data
                                                                                    .moneda_precio_base
                                                                            }{" "}
                                                                            por{" "}
                                                                            {
                                                                                producto
                                                                                    .data
                                                                                    .tipo_estadia
                                                                            }
                                                                        </h2>
                                                                    ) : (
                                                                        "Aún no posee precio o tipo de moneda"
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalPrecioBaseGarantia(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                                <hr className="my-4" />
                                                {modalEditarPrecioPorPersona ? (
                                                    <EditarPrecioPorPersona
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalEditarPrecioPorPersona
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between mt-3">
                                                        <div>
                                                            <h1>
                                                                Precio por
                                                                personas
                                                            </h1>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalEditarPrecioPorPersona(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                                <hr className="my-4" />
                                                {modalGarantiaMascota ? (
                                                    <EditarGarantiaMascotas
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalGarantiaMascota
                                                        }
                                                    />
                                                ) : (
                                                    <div className="flex justify-between ">
                                                        <div>
                                                            {producto.data
                                                                .tipo_garantia ===
                                                            "Valor" ? (
                                                                <h1 className="mb-2">
                                                                    Garantía{" "}
                                                                    {
                                                                        producto
                                                                            .data
                                                                            .garantia
                                                                    }{" "}
                                                                    {
                                                                        producto
                                                                            .data
                                                                            .tipo_moneda_garantia
                                                                    }
                                                                </h1>
                                                            ) : producto.data
                                                                  .tipo_garantia ===
                                                              "Porcentaje" ? (
                                                                <h2 className="mt-2 text-xs">
                                                                    Garantía{" "}
                                                                    {
                                                                        producto
                                                                            .data
                                                                            .garantia
                                                                    }{" "}
                                                                    % del valor
                                                                </h2>
                                                            ) : producto.data
                                                                  .tipo_garantia ===
                                                              "Rango de días" ? (
                                                                <h2 className="mt-2 text-xs">
                                                                    Garantía por
                                                                    rango de día
                                                                    (Precio
                                                                    varia segun
                                                                    cantidad de
                                                                    dias)
                                                                </h2>
                                                            ) : (
                                                                <h2 className="mt-2 text-xs">
                                                                    Garantía{" "}
                                                                    {
                                                                        producto
                                                                            .data
                                                                            .garantia
                                                                    }{" "}
                                                                    {
                                                                        producto
                                                                            .data
                                                                            .tipo_moneda_garantia
                                                                    }{" "}
                                                                    {
                                                                        producto
                                                                            .data
                                                                            .tipo_garantia
                                                                    }
                                                                </h2>
                                                            )}
                                                            <h1>
                                                                Garantia por
                                                                mascotas{" "}
                                                            </h1>
                                                            <h2 className="mt-2 text-xs">
                                                                {producto.data
                                                                    .garantia_mascota !==
                                                                0
                                                                    ? producto
                                                                          .data
                                                                          .garantia_mascota +
                                                                      " " +
                                                                      producto
                                                                          .data
                                                                          .tipo_moneda_garantia
                                                                    : ""}
                                                            </h2>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalGarantiaMascota(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal cursor-pointer text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}

                                                <hr className="my-4" />
                                                {modalEditarTarifas ? (
                                                    <EditarTarifas
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalEditarTarifas
                                                        }
                                                        tipo_producto={tipo}
                                                    />
                                                ) : (
                                                    <div className="flex justify-between mt-3">
                                                        <div>
                                                            <h1>
                                                                Tarifas
                                                                adicionales
                                                            </h1>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalEditarTarifas(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                                {authUser.tipo_usuario < 3 && (
                                                    <>
                                                        <hr className="my-4" />
                                                        {modalFee ? (
                                                            <EditarFee
                                                                setProducto={
                                                                    setProducto
                                                                }
                                                                tipo_producto={
                                                                    tipo
                                                                }
                                                                producto={
                                                                    producto
                                                                }
                                                                setModal={
                                                                    setModalFee
                                                                }
                                                            />
                                                        ) : (
                                                            <div className="mt-7">
                                                                <div className="flex justify-between w-full">
                                                                    <div>
                                                                        <h1>
                                                                            Fee
                                                                            cliente
                                                                        </h1>
                                                                        <span className="mt-2 text-xs">
                                                                            {
                                                                                producto
                                                                                    .data
                                                                                    .fee_cliente
                                                                            }
                                                                            %
                                                                        </span>
                                                                    </div>
                                                                    <h1
                                                                        onClick={() =>
                                                                            setModalFee(
                                                                                true
                                                                            )
                                                                        }
                                                                        className="font-normal text-blue-bluecorner"
                                                                    >
                                                                        Editar
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                {authUser.tipo_usuario < 3 && (
                                                    <>
                                                        <hr className="my-4" />
                                                        <div>
                                                            {" "}
                                                            {modalEditarFeeEmpresa ? (
                                                                <EditarFeeEmpresa
                                                                    setProducto={
                                                                        setProducto
                                                                    }
                                                                    producto={
                                                                        producto
                                                                    }
                                                                    setModal={
                                                                        setModalEditarFeeEmpresa
                                                                    }
                                                                />
                                                            ) : (
                                                                <div className="flex justify-between mt-7">
                                                                    <div>
                                                                        <h1>
                                                                            Fee
                                                                            Empresas
                                                                        </h1>
                                                                        <div className="flex flex-col item-center">
                                                                            <span className="mt-2 text-xs">
                                                                                Descuento
                                                                                empresas
                                                                                free{" "}
                                                                                <span className="font-bold">
                                                                                    {" "}
                                                                                    {
                                                                                        producto
                                                                                            .data
                                                                                            .descuento_empresa_free
                                                                                    }

                                                                                    %
                                                                                </span>
                                                                            </span>

                                                                            <span className="mt-2 text-xs">
                                                                                Descuento
                                                                                empresas
                                                                                premium{" "}
                                                                                <span className="font-bold">
                                                                                    {
                                                                                        producto
                                                                                            .data
                                                                                            .descuento_empresa_premium
                                                                                    }

                                                                                    %
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <h1
                                                                        onClick={() =>
                                                                            setModalEditarFeeEmpresa(
                                                                                true
                                                                            )
                                                                        }
                                                                        className="font-normal cursor-pointer text-blue-bluecorner"
                                                                    >
                                                                        Editar
                                                                    </h1>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        <div
                                            className={
                                                producto.data.tipo_producto ===
                                                "embarcacion"
                                                    ? ""
                                                    : "hidden"
                                            }
                                        >
                                            <div className="flex justify-between mt-4">
                                                <h1 className="text-xl font-bold">
                                                    Tarifas
                                                </h1>
                                            </div>
                                            {tipo === "embarcacion" && (
                                                <div>
                                                    <hr className="my-4" />
                                                    {modalTurnosEmbarcacion ? (
                                                        <EditarTurnosEmbarcacion
                                                            setProducto={
                                                                setProducto
                                                            }
                                                            producto={producto}
                                                            setModal={
                                                                setModalTurnosEmbarcacion
                                                            }
                                                            tipo_producto={tipo}
                                                        />
                                                    ) : (
                                                        <div className="flex justify-between ">
                                                            <div>
                                                                <h1>
                                                                    Turnos y
                                                                    precios
                                                                </h1>
                                                            </div>
                                                            <h1
                                                                onClick={() =>
                                                                    setModalTurnosEmbarcacion(
                                                                        true
                                                                    )
                                                                }
                                                                className="font-normal text-blue-bluecorner"
                                                            >
                                                                Editar
                                                            </h1>
                                                        </div>
                                                    )}{" "}
                                                </div>
                                            )}
                                            <hr className="my-4" />

                                            {modalEditarPrecioPorPersona ? (
                                                <EditarPrecioPorPersona
                                                    setProducto={setProducto}
                                                    producto={producto}
                                                    setModal={
                                                        setModalEditarPrecioPorPersona
                                                    }
                                                    tipo_producto={tipo}
                                                />
                                            ) : (
                                                <div className="flex justify-between mt-3">
                                                    <div>
                                                        <h1>
                                                            Precio por personas
                                                        </h1>
                                                    </div>
                                                    <h1
                                                        onClick={() =>
                                                            setModalEditarPrecioPorPersona(
                                                                true
                                                            )
                                                        }
                                                        className="font-normal text-blue-bluecorner"
                                                    >
                                                        Editar
                                                    </h1>
                                                </div>
                                            )}

                                            <hr className="my-4" />
                                            {modalEditarPrecioPorEmbarque ? (
                                                <EditarPrecioPorEmbarque
                                                    setProducto={setProducto}
                                                    producto={producto}
                                                    setModal={
                                                        setModalEditarPrecioPorEmbarque
                                                    }
                                                    tipo_producto={tipo}
                                                />
                                            ) : (
                                                <div className="flex justify-between mt-7">
                                                    <div>
                                                        <h1>
                                                            Precio de embarque
                                                            por persona
                                                        </h1>
                                                    </div>
                                                    <h1
                                                        onClick={() =>
                                                            setModalEditarPrecioPorEmbarque(
                                                                true
                                                            )
                                                        }
                                                        className="font-normal text-blue-bluecorner"
                                                    >
                                                        Editar
                                                    </h1>
                                                </div>
                                            )}

                                            <hr className="my-4" />
                                            {modalEditarTarifas ? (
                                                <EditarTarifas
                                                    setProducto={setProducto}
                                                    producto={producto}
                                                    setModal={
                                                        setModalEditarTarifas
                                                    }
                                                    tipo_producto={tipo}
                                                />
                                            ) : (
                                                <div className="flex justify-between mt-3">
                                                    <div>
                                                        <h1>
                                                            Tarifas adicionales
                                                        </h1>
                                                    </div>
                                                    <h1
                                                        onClick={() =>
                                                            setModalEditarTarifas(
                                                                true
                                                            )
                                                        }
                                                        className="font-normal text-blue-bluecorner"
                                                    >
                                                        Editar
                                                    </h1>
                                                </div>
                                            )}

                                            {authUser.tipo_usuario < 3 && (
                                                <>
                                                    <hr className="my-4" />
                                                    {modalFee ? (
                                                        <EditarFee
                                                            setProducto={
                                                                setProducto
                                                            }
                                                            producto={producto}
                                                            setModal={
                                                                setModalFee
                                                            }
                                                        />
                                                    ) : (
                                                        <div className="mt-4">
                                                            <div className="flex justify-between w-full">
                                                                <div>
                                                                    <h1>
                                                                        Fee
                                                                        cliente
                                                                    </h1>
                                                                    <span className="mt-2 text-xs">
                                                                        {
                                                                            producto
                                                                                .data
                                                                                .fee_cliente
                                                                        }
                                                                        %
                                                                    </span>
                                                                </div>
                                                                <h1
                                                                    onClick={() =>
                                                                        setModalFee(
                                                                            true
                                                                        )
                                                                    }
                                                                    className="font-normal text-blue-bluecorner"
                                                                >
                                                                    Editar
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {authUser.tipo_usuario < 3 && (
                                                <>
                                                    <hr className="my-4" />
                                                    <div>
                                                        {" "}
                                                        {modalEditarFeeEmpresa ? (
                                                            <EditarFeeEmpresa
                                                                setProducto={
                                                                    setProducto
                                                                }
                                                                producto={
                                                                    producto
                                                                }
                                                                setModal={
                                                                    setModalEditarFeeEmpresa
                                                                }
                                                            />
                                                        ) : (
                                                            <div className="flex justify-between mt-7">
                                                                <div>
                                                                    <h1>
                                                                        Fee
                                                                        Empresas
                                                                    </h1>
                                                                    <div className="flex flex-col item-center">
                                                                        <span className="mt-2 text-xs">
                                                                            Descuento
                                                                            empresas
                                                                            free{" "}
                                                                            <span className="font-bold">
                                                                                {" "}
                                                                                {
                                                                                    producto
                                                                                        .data
                                                                                        .descuento_empresa_free
                                                                                }

                                                                                %
                                                                            </span>
                                                                        </span>

                                                                        <span className="mt-2 text-xs">
                                                                            Descuento
                                                                            empresas
                                                                            premium{" "}
                                                                            <span className="font-bold">
                                                                                {
                                                                                    producto
                                                                                        .data
                                                                                        .descuento_empresa_premium
                                                                                }

                                                                                %
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <h1
                                                                    onClick={() =>
                                                                        setModalEditarFeeEmpresa(
                                                                            true
                                                                        )
                                                                    }
                                                                    className="font-normal cursor-pointer text-blue-bluecorner"
                                                                >
                                                                    Editar
                                                                </h1>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {(tipo === "casa" ||
                                            authUser.tipo_usuario < 3) && (
                                            <>
                                                <div className="flex justify-between mt-4">
                                                    <h1 className="text-xl font-bold">
                                                        Configuraciones
                                                    </h1>
                                                </div>

                                                {modalClaveExterna ? (
                                                    <EditarClaveExterna
                                                        setProducto={
                                                            setProducto
                                                        }
                                                        producto={producto}
                                                        setModal={
                                                            setModalClaveExterna
                                                        }
                                                    />
                                                ) : (
                                                    <div className="flex justify-between mt-7">
                                                        <div>
                                                            <h1>
                                                                Clave externa
                                                            </h1>
                                                            <span className="mt-2 text-xs">
                                                                {producto.data
                                                                    .clave_externa
                                                                    ? producto
                                                                          .data
                                                                          .clave_externa
                                                                    : ""}
                                                            </span>
                                                        </div>
                                                        <h1
                                                            onClick={() =>
                                                                setModalClaveExterna(
                                                                    true
                                                                )
                                                            }
                                                            className="font-normal cursor-pointer text-blue-bluecorner"
                                                        >
                                                            Editar
                                                        </h1>
                                                    </div>
                                                )}
                                                {authUser.tipo_usuario < 3 && (
                                                    <>
                                                        <hr className="my-4" />
                                                        {modalAdministrador ? (
                                                            <EditarAdministrador
                                                                setProducto={
                                                                    setProducto
                                                                }
                                                                producto={
                                                                    producto
                                                                }
                                                                setModal={
                                                                    setModalAdministrador
                                                                }
                                                                administradores={
                                                                    administradores
                                                                }
                                                            />
                                                        ) : (
                                                            <div className="flex justify-between mt-7">
                                                                <div>
                                                                    <h1>
                                                                        Administrador
                                                                        de la
                                                                        propiedad
                                                                    </h1>
                                                                    <span className="mt-2 text-xs">
                                                                        {administradores.length >
                                                                        0
                                                                            ? administradores.find(
                                                                                  (
                                                                                      id
                                                                                  ) =>
                                                                                      id.id ===
                                                                                      producto
                                                                                          .data
                                                                                          .id_encargado
                                                                              )
                                                                                  ?.personal
                                                                            : ""}
                                                                    </span>
                                                                </div>
                                                                <h1
                                                                    onClick={() =>
                                                                        setModalAdministrador(
                                                                            true
                                                                        )
                                                                    }
                                                                    className="font-normal text-blue-bluecorner"
                                                                >
                                                                    Editar
                                                                </h1>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {authUser.tipo_usuario < 3 && (
                                                    <>
                                                        <hr className="my-4" />
                                                        {modalTop ? (
                                                            <EditarCasaTop
                                                                setProducto={
                                                                    setProducto
                                                                }
                                                                producto={
                                                                    producto
                                                                }
                                                                setModal={
                                                                    setModalTop
                                                                }
                                                            />
                                                        ) : (
                                                            <div className="flex justify-between mt-7">
                                                                <div>
                                                                    <h1>
                                                                        Casa Top
                                                                    </h1>
                                                                    <span className="mt-2 text-xs">
                                                                        {
                                                                            producto
                                                                                .data
                                                                                .casa_top
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <h1
                                                                    onClick={() =>
                                                                        setModalTop(
                                                                            true
                                                                        )
                                                                    }
                                                                    className="font-normal text-blue-bluecorner"
                                                                >
                                                                    Editar
                                                                </h1>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {authUser.tipo_usuario < 3 && (
                                            <>
                                                {producto.data.tipo_producto ===
                                                    "embarcacion" && (
                                                    <>
                                                        <hr className="my-4" />
                                                        {modalEditarOferta ? (
                                                            <EditarOferta
                                                                setProducto={
                                                                    setProducto
                                                                }
                                                                producto={
                                                                    producto
                                                                }
                                                                setModal={
                                                                    setModalEditarOferta
                                                                }
                                                                tipo_producto={
                                                                    tipo
                                                                }
                                                                administradores={
                                                                    administradores
                                                                }
                                                            />
                                                        ) : (
                                                            <div className="flex justify-between mt-7">
                                                                <div>
                                                                    <h1>
                                                                        Ofertas
                                                                    </h1>
                                                                </div>

                                                                <h1
                                                                    onClick={() =>
                                                                        setModalEditarOferta(
                                                                            true
                                                                        )
                                                                    }
                                                                    className="font-normal cursor-pointer text-blue-bluecorner"
                                                                >
                                                                    Editar
                                                                </h1>
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                <div
                                                    onClick={() => publicar()}
                                                    className="p-3 text-blue-500 border border-blue-500 px-4 w-[156px] rounded-lg hover:bg-blue-500 hover:text-white text-center mt-10 cursor-pointer"
                                                >
                                                    {cargando ? (
                                                        <Spinner width={7} />
                                                    ) : (
                                                        <p className="text-xl ">
                                                            {producto.data
                                                                .publicado !==
                                                            "Publicado"
                                                                ? "Publicar"
                                                                : "En revision"}
                                                        </p>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        {authUser.tipo_usuario < 3 && (
                                            <>
                                                {producto.data.tipo_producto !==
                                                    "embarcacion" && (
                                                    <>
                                                        <hr className="my-4" />
                                                        {modalEditarOfertaCasas ? (
                                                            <EditarOfertaCasas
                                                                setProducto={
                                                                    setProducto
                                                                }
                                                                producto={
                                                                    producto
                                                                }
                                                                setModal={
                                                                    setModalEditarOfertaCasas
                                                                }
                                                                tipo_producto={
                                                                    tipo
                                                                }
                                                                administradores={
                                                                    administradores
                                                                }
                                                            />
                                                        ) : (
                                                            <div className="flex justify-between mt-7">
                                                                <div>
                                                                    <h1>
                                                                        Ofertas
                                                                    </h1>
                                                                </div>

                                                                <h1
                                                                    onClick={() =>
                                                                        setModalEditarOfertaCasas(
                                                                            true
                                                                        )
                                                                    }
                                                                    className="font-normal cursor-pointer text-blue-bluecorner"
                                                                >
                                                                    Editar
                                                                </h1>
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                <div
                                                    onClick={() => publicar()}
                                                    className="p-3 text-blue-500 border border-blue-500 px-4 w-[156px] rounded-lg hover:bg-blue-500 hover:text-white text-center mt-10 cursor-pointer"
                                                >
                                                    {cargando ? (
                                                        <Spinner width={7} />
                                                    ) : (
                                                        <p className="text-xl ">
                                                            {producto.data
                                                                .publicado !==
                                                            "Publicado"
                                                                ? "Publicar"
                                                                : "En revision"}
                                                        </p>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {/* MOBILE ACA */}

                                    {/* DESKTOP ACA */}

                                    <div className="flex-col hidden lg:flex w-full ">
                                        <EditarDetalleDesktop
                                            tipo={tipo}
                                            producto={producto}
                                            setProducto={setProducto}
                                            showFotos={showFotos}
                                            setShowFotos={setShowFotos}
                                            showSobreAlojamiento={
                                                showSobreAlojamiento
                                            }
                                            setShowSobreAlojamiento={
                                                setShowSobreAlojamiento
                                            }
                                            showPropiedades={showPropiedades}
                                            setShowPropiedades={
                                                setShowPropiedades
                                            }
                                            showReglas={showReglas}
                                            setShowReglas={setShowReglas}
                                            showPoliticas={showPoliticas}
                                            setShowPoliticas={setShowPoliticas}
                                            showPrecios={showPrecios}
                                            setShowPrecios={setShowPrecios}
                                            showTarifasAdicionales={
                                                showTarifasAdicionales
                                            }
                                            showConfiguracion={
                                                showConfiguracion
                                            }
                                            setShowConfiguracion={
                                                setShowConfiguracion
                                            }
                                            setShowTarifasAdicionales={
                                                setShowTarifasAdicionales
                                            }
                                            administradores={administradores}
                                        />
                                    </div>

                                    {/* DESKTOP ACA */}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default EditarDetalle;
