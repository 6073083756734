import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Spinner from "../../../Spinner";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import dividirFechaDatePicker from "../../../helpers/dividirFechaDatePicker";
import { useSelector } from "react-redux";
import ModalPromoCreada from "../../../dashboardEmpresas/promociones/ModalPromoCreada";
import ButtonCopyToClipboard from "../../../ButtonCopyToClipboard";

const ModalEditServices = ({
    modalEditVisible,
    setModalEditVisible,
    producto,
    setProducto,
    fakeRefresh,
    setFakeRefresh,
    tipo_producto,
    editRango,
    setPrecio,
}) => {
    registerLocale("es", es);

    const [input, setInput] = useState({
        descuento_bc: editRango.descuento_bc,
        descuento_propietario: editRango.descuento_propietario,
        fecha_expiracion: editRango.fecha_expiracion,
    });
    const [siguiente, setSiguiente] = useState("Guardar");
    const [fields, setFields] = useState([]);
    const debounceInput = useRef(null);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const [modalPromoCreada, setModalPromoCreada] = useState(false);

    const [owner, setOwner] = useState({
        id_propietario: editRango.id_cliente,
        propietario: editRango.cliente,
    });
    const handleBlur = () => {
        setTimeout(() => {
            setFields([]);
        }, 200);
    };

    const handleInputChange = (e) => {
        if (e.target.name === "ownerName") {
            setOwner({
                id_propietario: null,
                propietario: e.target.value,
            });
            if (debounceInput.current) clearTimeout(debounceInput.current);
            if (e.target.value === "") {
                setFields([]);
            }
            debounceInput.current = setTimeout(async () => {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/cliente/autocomplete?autocomplete=${e.target.value}`,
                    { headers }
                );

                setFields(data);
            }, 500);
        } else {
            setInput({
                ...input,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;

        if (value.match(/^\d+$/) || event.nativeEvent.data === null) {
            setInput({
                ...input,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        if (owner.id_propietario === null) {
            return Swal.fire({
                title: "Error",
                text: "Debe seleccionar un cliente",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else if (input.fecha_expiracion === "") {
            return Swal.fire({
                title: "Error",
                text: "Debe ingresar una fecha de expiracion",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else if (
            input.descuento_bc <= 0 &&
            input.descuento_propietario <= 0
        ) {
            return Swal.fire({
                title: "Error",
                text: "Debe ingresar aunque sea un tipo de descuento",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            setSiguiente(null);
            let payload;
            let error;
            if (tipo_producto === "casa") {
                payload = {
                    id_cliente: owner.id_propietario,
                    id_casa: producto.data.id,
                    id_usuario: authUser.id_usuario,
                    descuento_bc: input.descuento_bc,
                    descuento_propietario: input.descuento_propietario,
                    fecha_expiracion: input.fecha_expiracion,
                    tipo_moneda: producto.data.moneda_precio_base,
                };
            } else {
                payload = {
                    id_cliente: owner.id_propietario,
                    id_embarcacion: producto.data.id,
                    id_usuario: authUser.id_usuario,
                    descuento_bc: input.descuento_bc,
                    descuento_propietario: input.descuento_propietario,
                    fecha_expiracion: input.fecha_expiracion,
                    tipo_moneda: producto.data.tipo_moneda,
                };
            }

            if (tipo_producto === "casa") {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}/casaCupones/actualizar/${editRango.id},
`,
                    {
                        ...payload,
                    },
                    { headers }
                );

                error = data.error;
            } else if (tipo_producto === "embarcacion") {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}/EmbarcacionCupones/actualizar/${editRango.id}
`,
                    {
                        ...payload,
                    },
                    { headers }
                );
                error = data.error;
            }

            if (error) {
                Swal.fire({
                    title: "Error",
                    text: error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                setSiguiente("Guardar");
            } else {
                Swal.fire({
                    title: "Cupon actualizado correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setSiguiente("Guardar");
                setInput({
                    descuento_bc: "",
                    descuento_propietario: "",
                    fecha_expiracion: "",
                });
                setOwner({
                    id_propietario: null,
                    propietario: null,
                });
                setPrecio({});
                setModalPromoCreada(true);
                setModalEditVisible(!modalEditVisible);
                setSiguiente("Guardar");
                setFakeRefresh(!fakeRefresh);
            }
        }
    };

    const handleClose = () => {
        setInput({
            descuento_bc: "",
            descuento_propietario: "",
            fecha_expiracion: "",
        });
        setOwner({
            id_propietario: null,
            propietario: null,
        });
        setPrecio({});
        setSiguiente("Guardar");
        setModalEditVisible(!modalEditVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalEditVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 left-0 z-10 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-20 sm:py-16 ">
                    <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg  xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] p-10 overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-4 right-4 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>

                        <div className="w-full my-3 text-left">
                            <header className="mb-3 ml-1 mr-1 text-[#3C3C3C]  text-base  font-bold ">
                                Nuevo cupon
                            </header>
                            <header className="mb-3  text-[#3C3C3C]   ml-1 mr-1 font-semibold ">
                                Moneda: (
                                {tipo_producto === "casa"
                                    ? producto.data.moneda_precio_base
                                    : producto.data.tipo_moneda}
                                )
                            </header>
                        </div>

                        <div className="flex items-center mt-2 ">
                            <div className="mb-2 ">
                                <label className="ml-1 mr-1 text-base font-semibold ">
                                    Descuento de Bluecorner (
                                    {tipo_producto === "casa"
                                        ? producto.data.moneda_precio_base
                                        : producto.data.tipo_moneda}
                                    )
                                </label>
                            </div>
                            <div className="w-[90px]     flex border rounded-lg border-[#E9E9E9] items-center justify-center text-center py-2 px-3">
                                <p>-</p>
                                <input
                                    autoComplete="off"
                                    className="w-full rounded-lg outline-none"
                                    placeholder=" 15"
                                    value={input.descuento_bc}
                                    name="descuento_bc"
                                    type="text"
                                    onChange={handleChange}></input>
                            </div>
                        </div>
                        <div className="flex items-center mt-2 ">
                            <div className="mb-2 ">
                                <label className="ml-1 mr-1 text-base font-semibold ">
                                    Descuento de Propietario (
                                    {tipo_producto === "casa"
                                        ? producto.data.moneda_precio_base
                                        : producto.data.tipo_moneda}
                                    )
                                </label>
                            </div>
                            <div className="w-[88px]     flex border rounded-lg border-[#E9E9E9] items-center justify-center text-center py-2 px-3">
                                <p>-</p>
                                <input
                                    autoComplete="off"
                                    className="w-full rounded-lg outline-none"
                                    placeholder=" 15"
                                    value={input.descuento_propietario}
                                    name="descuento_propietario"
                                    type="text"
                                    onChange={handleChange}></input>
                            </div>
                        </div>

                        <div className="flex flex-col mb-2">
                            <div className="">
                                <label className="ml-1 text-base font-semibold ">
                                    Fecha de expiración:
                                </label>

                                <DatePicker
                                    className="w-full p-2 bg-white border rounded-lg cursor-pointer"
                                    placeholderText="Seleccionar fecha"
                                    locale="es"
                                    autoComplete="off"
                                    name="fecha_expiracion"
                                    dateFormat="dd/MM/yyyy"
                                    selected={
                                        input.fecha_expiracion !== ""
                                            ? new Date(
                                                  dividirFechaDatePicker(
                                                      input.fecha_expiracion
                                                  )[0],
                                                  dividirFechaDatePicker(
                                                      input.fecha_expiracion
                                                  )[1] - 1,
                                                  dividirFechaDatePicker(
                                                      input.fecha_expiracion
                                                  )[2]
                                              )
                                            : input.fecha_expiracion
                                    }
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            fecha_expiracion: `${e.getFullYear()}-${`${
                                                e.getMonth() + 1
                                            }`.padStart(
                                                2,
                                                "0"
                                            )}-${`${e.getDate()}`.padStart(
                                                2,
                                                "0"
                                            )}`,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex flex-col mb-2">
                            <div className="">
                                <label className="ml-1 text-base font-semibold ">
                                    Nombre del cliente:
                                </label>

                                <input
                                    autoComplete="off"
                                    type="text"
                                    onBlur={handleBlur}
                                    value={owner.propietario}
                                    name="ownerName"
                                    onChange={handleInputChange}
                                    placeholder="Ingrese el nombre del cliente"
                                    className="w-full border my-3 bg-transparent rounded-lg py-3 px-3 outline-none focus:outline-none placeholder:text-[#3C3C3C]/70 placeholder:font-bold"
                                />

                                <div className="-mt-2 overflow-y-auto max-h-56">
                                    {fields.length > 0 &&
                                        fields.map((owner) => (
                                            <div
                                                key={owner.id}
                                                className="w-full border my-3 bg-transparent rounded-lg py-3 px-3 outline-none focus:outline-none placeholder:text-[#3C3C3C]/70 placeholder:font-bold cursor-pointer"
                                                onClick={() => {
                                                    setOwner({
                                                        id_propietario:
                                                            owner.id,
                                                        propietario:
                                                            owner.clientes,
                                                    });

                                                    setFields([]);
                                                }}>
                                                {owner.clientes}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between w-full p-2 mt-4 text-gray-600 border rounded-lg">
                            <span>{editRango.cupon}</span>

                            <ButtonCopyToClipboard
                                confirmationText="Cupón copiado al portapapeles correctamente"
                                textToCopy={editRango.cupon}
                            />
                        </div>

                        <button
                            className="w-full py-3 mt-4 text-center text-white border border-blue-400 rounded-md bg-blue-bluecorner hover:bg-white hover:text-blue-bluecorner disabled:bg-blue-400"
                            onClick={(e) => handleCreate(e)}
                            disabled={!siguiente}>
                            {siguiente ? siguiente : <Spinner width={7} />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditServices;
