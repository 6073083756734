import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import SearchBar from "./SearchBar";
import { Paginado } from "../../helpers/Paginado";
import axios from "axios";
import ModalEditPropietario from "./ModalEditPropietario";
import ModalPasswordPropietario from "./ModalPasswordPropietario";
import Spinner from "../../Spinner";
import Modal3buttons from "./Modal3buttons";

const Propietarios = () => {
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [modalPasswordVisible, setModalPasswordVisible] = useState(false);
    const [modal3Buttons, setModal3Buttons] = useState(false);
    const [page, setPage] = useState(1);
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [habilitados, setHabilitados] = useState(true);
    const [showButtons, setShowButtons] = useState(false);
    const [userShowingInPage, setUserShowingInPage] = useState({
        checking: true,
        data: {
            total: 0,
            data: [],
        },
    });
    const [propietarioSelected, setPropietarioSelected] = useState(null);
    useState("");
    const USERS_PER_PAGE = 25;

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const getAllPropietarios = async (nombre = "", pagina = 1) => {
        setUserShowingInPage({
            checking: true,
            data: {
                total: 0,
                data: [],
            },
        });
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/propietario/all/${pagina}/${
                habilitados ? "Si" : "No"
            }/?nombre=${nombre}`,
            { headers }
        );
        setUserShowingInPage({
            checking: false,
            data,
        });
    };

    useEffect(() => {
        setUserShowingInPage([]);
        getAllPropietarios();
    }, [habilitados]);

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el propietario a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/propietario/restaurar/${id}`,
                        {},
                        { headers }
                    )
                    .then((response) => {
                        if (
                            response.data.message ===
                            "Registro habilitado con exito"
                        ) {
                            Swal.fire({
                                title: "Propietario habilitado correctamente!",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            });
                            getAllPropietarios();
                        } else {
                            Swal.fire({
                                title: "Error al habilitar el propietario!",
                                icon: "error",
                                confirmButtonText: "Aceptar",
                            });
                        }
                    });
            }
        });
    };

    const handleDelete = async (idUser) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el propietario a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/propietario/eliminar/${idUser}`,
                        { headers }
                    )
                    .then((response) => {
                        if (
                            response.data.message ===
                            "Registro deshabilitado con exito"
                        ) {
                            Swal.fire({
                                title: "Propietario eliminado correctamente!",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            });
                            getAllPropietarios();
                        } else {
                            Swal.fire({
                                title: "Error al eliminar el propietario!",
                                icon: "error",
                                confirmButtonText: "Aceptar",
                            });
                        }
                    });
            }
        });
    };

    const handleEdit = (user) => {
        setPropietarioSelected(user);
        setModalEditVisible(!modalEditVisible);
    };

    const handleEditPassword = (user) => {
        setPropietarioSelected(user);
        setModalPasswordVisible(!modalPasswordVisible);
    };

    const pagination = (pageNumber) => {
        setPage(pageNumber);
        getAllPropietarios("", pageNumber);
    };

    return (
        <div className=" w-full md:pl-[13rem] md818:pl-[14rem] md920:pl-[14rem] lg:pl-[17rem] p-4">
            <div className="flex items-center justify-between w-full md:justify-start ">
                <h1 className="py-10 text-4xl text-center">Propietarios</h1>
            </div>

            {modalEditVisible && propietarioSelected && (
                <ModalEditPropietario
                    setModalEditVisible={setModalEditVisible}
                    modalEditVisible={modalEditVisible}
                    user={propietarioSelected}
                    getAllPropietarios={getAllPropietarios}
                    actualPage={page}
                />
            )}
            {modalPasswordVisible && (
                <ModalPasswordPropietario
                    setModalPasswordVisible={setModalPasswordVisible}
                    modalPasswordVisible={modalPasswordVisible}
                    user={propietarioSelected}
                    setUser={setPropietarioSelected}
                    actualPage={page}
                />
            )}
            {modal3Buttons && propietarioSelected && (
                <Modal3buttons
                    setModal3Buttons={setModal3Buttons}
                    user={propietarioSelected}
                    getAllPropietarios={getAllPropietarios}
                />
            )}

            <div className="flex items-center justify-end w-full p-1 md:mt-0 ">
                <SearchBar getAllPropietarios={getAllPropietarios} />
                <button className="relative flex items-center justify-center w-8 h-8 ml-5 border rounded-lg md:w-11 md:h-11 border-blue-bluecorner">
                    <svg
                        onClick={() => setShowButtons((prev) => !prev)}
                        className="text-blue-500 h-7 w-7 md:h-8 md:w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                        />
                    </svg>
                    {showButtons && (
                        <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                            <div
                                onClick={() => {
                                    setHabilitados(true);
                                    setShowButtons((prev) => !prev);
                                    setPage(1);
                                }}
                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Habilitados
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    setHabilitados(false);
                                    setShowButtons((prev) => !prev);
                                    setPage(1);
                                }}
                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Deshabilitados
                                </p>
                            </div>
                        </div>
                    )}
                </button>
            </div>

            <section className="flex justify-center items-center lg:py-[10px] ">
                <div className="flex w-full max-w-full overflow-x-auto text-center">
                    {userShowingInPage?.data?.total > 0 ? (
                        <div className="w-full">
                            <table className="w-full overflow-x-auto mt-14">
                                <thead className="text-left">
                                    <tr>
                                        <th className="px-4 w-full min-w-[8rem] md:w-4/12 ">
                                            Nombre
                                        </th>
                                        <th className="hidden md:table-cell px-4 w-full min-w-[8rem] md:w-4/12 ">
                                            Correo electrónico
                                        </th>
                                        <th className="w-4/12 min-w-[10rem] hidden lg1170:table-cell">
                                            Documento
                                        </th>
                                        <th className="w-4/12 min-w-[8rem] hidden lg:table-cell">
                                            Celular
                                        </th>
                                        <th className="w-4/12 min-w-[10rem] hidden xl:table-cell">
                                            Ultima conexión
                                        </th>
                                        <th
                                            align="center"
                                            className="w-[8%] min-w-[10rem] ">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-left">
                                    {userShowingInPage?.data?.data?.map(
                                        (user) => (
                                            <tr
                                                key={user.id}
                                                className={`border-t w-full ${
                                                    habilitados &&
                                                    "cursor-pointer"
                                                }`}
                                                onClick={() => {
                                                    habilitados &&
                                                        setPropietarioSelected(
                                                            user
                                                        );
                                                    habilitados &&
                                                        setModal3Buttons(
                                                            (prev) => !prev
                                                        );
                                                }}>
                                                <td className="flex p-5">
                                                    {user.nombre +
                                                        " " +
                                                        user.apellido_paterno +
                                                        " "}
                                                    {user.apellido_materno}
                                                </td>
                                                <td className="hidden md:table-cell">
                                                    {user.correo}
                                                </td>
                                                <td className="hidden lg1170:table-cell">
                                                    {user.dni}
                                                </td>
                                                <td className="hidden lg:table-cell">
                                                    {user.celular}
                                                </td>
                                                <td className="hidden xl:table-cell">
                                                    {user.ultima_conexion}
                                                </td>

                                                <td className="">
                                                    {habilitados ? (
                                                        <div className="flex justify-center ml-9">
                                                            <button
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    handleEdit(
                                                                        user
                                                                    );
                                                                }}
                                                                title="Editar"
                                                                className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                <svg
                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                    />
                                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                    <line
                                                                        x1="16"
                                                                        y1="5"
                                                                        x2="19"
                                                                        y2="8"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <button
                                                                className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                title="Cambiar
                                                                contraseña"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    handleEditPassword(
                                                                        user
                                                                    );
                                                                }}>
                                                                <svg
                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                    />{" "}
                                                                    <rect
                                                                        x="5"
                                                                        y="11"
                                                                        width="14"
                                                                        height="10"
                                                                        rx="2"
                                                                    />{" "}
                                                                    <circle
                                                                        cx="12"
                                                                        cy="16"
                                                                        r="1"
                                                                    />{" "}
                                                                    <path d="M8 11v-5a4 4 0 0 1 8 0" />
                                                                </svg>
                                                            </button>
                                                            <button
                                                                className="items-center justify-center hidden w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded md:flex group hover:bg-blue-500 hover:text-white"
                                                                title="Eliminar"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    handleDelete(
                                                                        user.id
                                                                    );
                                                                }}>
                                                                <svg
                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    {" "}
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                    />{" "}
                                                                    <line
                                                                        x1="4"
                                                                        y1="7"
                                                                        x2="20"
                                                                        y2="7"
                                                                    />{" "}
                                                                    <line
                                                                        x1="10"
                                                                        y1="11"
                                                                        x2="10"
                                                                        y2="17"
                                                                    />{" "}
                                                                    <line
                                                                        x1="14"
                                                                        y1="11"
                                                                        x2="14"
                                                                        y2="17"
                                                                    />{" "}
                                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className="flex justify-center ml-5 ">
                                                            <button
                                                                onClick={() =>
                                                                    handleEnable(
                                                                        user.id
                                                                    )
                                                                }
                                                                title="Habilitar"
                                                                className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                <svg
                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    {" "}
                                                                    <polyline points="17 1 21 5 17 9" />{" "}
                                                                    <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                    <polyline points="7 23 3 19 7 15" />{" "}
                                                                    <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                {/* <tfoot>
                                    <tr>
                                        <td colSpan={5}>
                                            <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                                            <div className="flex justify-center">
                                                <Paginado
                                                    actualPage={page}
                                                    elementsPerPage={
                                                        USERS_PER_PAGE
                                                    }
                                                    cantTotalElements={
                                                        userShowingInPage.total
                                                    }
                                                    pagination={pagination}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot> */}
                            </table>
                            <div className="container flex items-center justify-between p-1 mx-auto">
                                <Paginado
                                    elementsPerPage={USERS_PER_PAGE}
                                    cantTotalElements={
                                        userShowingInPage?.data.total
                                    }
                                    pagination={pagination}
                                    actualPage={page}
                                />
                            </div>
                        </div>
                    ) : userShowingInPage.checking === false &&
                      userShowingInPage.data.total === 0 ? (
                        <div className="mx-auto mt-10 text-xl text-center">
                            Aun no tienes ningun propietario
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </div>
            </section>
        </div>
    );
};

export default Propietarios;
