import React, { useEffect, useState, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions/authActions";

import { useNavigate } from "react-router";
import ModalOlvidadoContraseña from "../contraseña/ModalOlvidadoContraseña";
import jwt_decode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";

// import FacebookLogin from "react-facebook-login";
// import { GoogleLogin } from "@react-oauth/google";

import colores from "../helpers/colores";

const FacebookLogin = lazy(() => import("./../helpers/LogeoFacebook"));
const GoogleLogin = lazy(() => import("./../helpers/LogeoGoogle.js"));

const UserLogin = ({ registro, setRegistro }) => {
    const emailStorage = localStorage.getItem("email");
    const [errors, setErrors] = useState({});
    const [showPass, setShowPass] = useState(false);
    const [olvido, setOlvido] = useState(false);
    const [medioDeRegistro, setMedioDeRegistro] = useState("");

    const [login, setLogin] = useState({
        correo: emailStorage || "",
        password: "",
        rememberme: false,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.authReducer);

    const googleDatos = async (data) => {
        const credencialToken = jwt_decode(data.credential);

        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/autenticacion/loginGoogle`,
            {
                email: credencialToken.email,
            }
        );
        console.log(json.data);
        if (json.data.message === "logeo exitoso") {
            dispatch(loginUser(null, null, json.data));
        } else {
            Swal.fire({
                title: "Error",
                text: `${json.data.title}`,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    const responseFacebook = async (data) => {
        let nombre = data.name?.split(" ");

        if (nombre) {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/autenticacion/loginGoogle`,
                {
                    email: data.email,
                }
            );
            console.log(json.data);
            if (json.data.message === "logeo exitoso") {
                dispatch(loginUser(null, null, json.data));
            } else {
                Swal.fire({
                    title: "Error",
                    text: `${json.data.title}`,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };

    useEffect(() => {
        if (authUser.tipo_usuario) {
            if (authUser.tipo_usuario < 3) {
                navigate("/dashboard/anuncios");
            } else if (
                authUser.tipo_usuario === 3 ||
                authUser.tipo_usuario === 4
            ) {
                navigate("/perfil/anuncios");
            } else if (authUser.tipo_usuario === 6) {
                navigate("/dashboard-empresas/usuarios");
            } else {
                navigate("/");
            }
        }
    }, [authUser]);

    useEffect(() => {
        /* global google*/
        google.accounts.id.initialize({
            client_id:
                "383088837702-acs6qsjvqiaihnfnkv6ggk0npke4coji.apps.googleusercontent.com",
            callback: googleDatos,
        });
    }, []);

    const validateInput = (form) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores = {};
        form.correo = form.correo.trim();
        if (!regExpEmail.test(form.correo)) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        }
        return errores;
    };

    const handleInputChange = (e) => {
        if (e.target.name === "rememberme") {
            setLogin({
                ...login,
                rememberme: e.target.checked,
            });
        } else {
            setLogin({
                ...login,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateInput(login);
        setErrors(errors);
        if (Object.entries(errors).length === 0) {
            if (login.rememberme) localStorage.setItem("email", login.correo);
            else localStorage.removeItem("email");
            dispatch(loginUser(login.correo, login.password));
        }
    };

    window.handleCredentialResponse = function (res) {};

    return (
        <div>
            {olvido && (
                <ModalOlvidadoContraseña
                    olvido={olvido}
                    setOlvido={setOlvido}
                />
            )}
            <div
                className={
                    registro
                        ? "hidden"
                        : "p-5 mt-10 mb-14 w-[340px] mx-auto shadow-xl"
                }>
                <h1
                    className={`text-[${colores.primario}] text-4xl font-bold `}>
                    Iniciar sesión
                </h1>
                <div className="flex items-center mx-auto mt-4 text-lg text-gray-500">
                    <p>¿Aun no tienes una cuenta? </p>
                </div>
                <p
                    onClick={() => setRegistro(true)}
                    className={`text-[${colores.secundario}] cursor-pointer text-lg pl-1`}>
                    Registrate ahora
                </p>

                <form
                    // className={medioDeRegistro === "Correo" ? "" : "hidden"}
                    onSubmit={(e) => handleSubmit(e)}>
                    <div className="w-full mt-4">
                        <label className="font-semibold">
                            Correo electrónico:
                            <span className="text-xl text-red-600">*</span>
                        </label>
                        {errors.correo && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.correo}
                            </p>
                        )}
                        <input
                            type="text"
                            value={login.correo}
                            name="correo"
                            onChange={handleInputChange}
                            placeholder="Ingrese su correo electrónico"
                            className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                        />
                    </div>
                    <div className="w-full mt-4">
                        <label className="font-semibold ">
                            Contraseña:
                            <span className="text-xl text-red-600">*</span>
                        </label>
                        {errors.password && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.password}
                            </p>
                        )}
                        <div className="flex items-center w-full rounded-lg - ring-2 ring-gray-200 ">
                            <input
                                type={`${showPass ? "text" : "password"}`}
                                name="password"
                                value={login.password}
                                onChange={handleInputChange}
                                className={`w-full p-3 placeholder-gray-500 rounded-lg focus:outline-none focus:border-[${colores.secundario}]`}
                                placeholder="Ingresa tu contraseña"
                            />

                            {showPass ? (
                                <>
                                    <svg
                                        className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => {
                                            setShowPass(!showPass);
                                        }}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                        />
                                    </svg>
                                </>
                            ) : (
                                <>
                                    <svg
                                        className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => {
                                            setShowPass(!showPass);
                                        }}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                        />
                                    </svg>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center mt-4">
                        <input
                            type="checkbox"
                            name="rememberme"
                            className="mr-2 "
                            onChange={handleInputChange}
                        />
                        <h2 className="text-base font-semibold">Recuérdame</h2>
                    </div>
                    <button
                        type="submit"
                        className={`p-2 px-4 text-lg font-semibold text-white bg-[${colores.primario}] mt-4 rounded-lg`}>
                        Acceder
                    </button>
                    <p
                        onClick={() => setOlvido(true)}
                        className={`block mt-5 text-center text-lg text-[${colores.secundario}] cursor-pointer`}>
                        ¿Olvidaste la contraseña?
                    </p>
                </form>
                <div className="flex items-center justify-center mx-auto mt-2 text-gray-500">
                    <p>o</p>
                </div>
                <div className="flex items-center justify-center mx-auto mt-2">
                    <Suspense fallback={<div>Loading...</div>}>
                        <GoogleLogin googleDatos={googleDatos} />
                    </Suspense>
                </div>
                <div className="flex items-center justify-center mx-auto mt-2">
                    {/* <div>
                        <FacebookLogin
                            appId="515412373874363"
                            fields="name,email"
                            // onClick={componentClicked}
                            callback={responseFacebook}
                            textButton="Iniciar sesión con Facebook"
                            cssClass="w-[260px] mx-auto mt-3 bg-blue-500 text-white rounded-2xl pl-[20px] pr-[27px]  py-[6px] flex justify-between items-center text-sm font-sans font-medium hover:bg-blue-400"
                            icon="fa-facebook"
                        />
                    </div> */}
                    <Suspense fallback={<div>Loading...</div>}>
                        <FacebookLogin responseFacebook={responseFacebook} />
                    </Suspense>
                </div>

                {/* <div className="flex items-center justify-center mx-auto mt-4 text-sm text-gray-500">
                    <p>¿Aun no tienes una cuenta? </p>
                    <p
                        onClick={() => setRegistro(true)}
                        className={`text-[${colores.secundario}] cursor-pointer pl-1`}>
                        Registrate ahora
                    </p>
                </div> */}
                <div className="flex items-center justify-center mt-4">
                    <button
                        onClick={() => navigate("/my-account-empresa")}
                        className={`p-2 px-4 text-lg font-semibold text-white bg-[${colores.primario}] mt-4 rounded-lg `}>
                        Registrarme como empresa
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserLogin;
