import React from "react";
import { useSelector } from "react-redux";

const ButtonGenerarLinkPago = ({ pedido, handleLink }) => {
    const { tipo_usuario } = useSelector((state) => state.authReducer);
    const { pagos, estado } = pedido;
    const { primer_pago, segundo_pago, costo_asumido } = pagos;

    const ButtonLink = () => {
        if (
            primer_pago.must_pay === false &&
            segundo_pago.must_pay === false &&
            costo_asumido.must_pay === true &&
            (tipo_usuario === 6 || tipo_usuario === 7)
        ) {
            return null;
        }

        if (tipo_usuario === 6) return null;

        return (
            <button
                onClick={() => handleLink(pedido)}
                className={`p-3 mt-4 text-center text-blue-400 border border-blue-400 rounded-md hover:scale-105`}>
                Generar link de pago
            </button>
        );
    };

    if (tipo_usuario === 3 || tipo_usuario === 4) return null;

    if (estado === "Confirmar disponibilidad") {
        if (tipo_usuario === 1 || tipo_usuario === 2) {
            return <ButtonLink />;
        }
    }

    if (estado === "Pendiente de pago" || estado === "Pago parcial") {
        return <ButtonLink />;
    }

    return null;
};

export default ButtonGenerarLinkPago;
