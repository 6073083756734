import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import ModalAgregarHab from "./ModalAgregarHab";
import ModalNuevaHab from "./ModalNuevaHab";
import Swal from "sweetalert2";
import { ReactSortable } from "react-sortablejs";

const EditarHabitaciones = ({ producto, setProducto, setModal }) => {
    const [roomBule, setroomBule] = useState([]);
    const [roomVisibility, setroomVisibility] = useState(0);
    const [modalNuevaHabitacion, setModalNuevaHabitacion] = useState(false);
    const [cargando, setCargando] = useState(false);

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        roomVisibility === 0 && detail(producto.data.id);
    }, [roomVisibility, modalNuevaHabitacion]);

    const detail = async (id) => {
        let aux = {};
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/productosHabitaciones/all/${id}/Normal`,
            { headers }
        );

        setroomBule(json.data);

        await json.data?.map((r) => {
            return (aux = {
                ...aux,
                [r.id]: r.productos_habitacion_detalle,
            });
        });

        setProducto({
            ...producto,
            data: {
                ...producto.data,
                roomAttribute: {
                    ...producto.data.roomAttribute,
                    ...aux,
                },
            },
        });
    };

    const deleteHabitacion = async (id) => {
        setCargando(true);
        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/productosHabitaciones/deleteHabitacion/${id}`,
            { headers }
        );

        detail(producto.data.id);
        setCargando(false);
    };
    const handleGuardar = () => {
        setModal(false);
    };

    const setDeleteHabitaciones = async (room) => {
        Swal.fire({
            title: "¿Esta seguro que desea eliminar la habitacion?",
            showCancelButton: true,
            confirmButtonText: "Si, deseo eliminar",
            denyButtonText: `Cancelar`,

            text: "Esta accion no se puede revertir",
            icon: "warning",

            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                await axios.delete(
                    `${process.env.REACT_APP_PROXY}/productosHabitaciones/deleteHabitacion/${room.id}`,

                    { headers }
                );

                Swal.fire("Eliminado con exito", "", "success");
                detail(producto.data.id);
            }
        });
    };

    const cambioDeEspacios = async () => {
        let array_orden = [];
        roomBule.forEach((imagen) => {
            array_orden.push(imagen.id);
        });

        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/productosHabitaciones/putOrden`,
            {
                array_orden: array_orden,
            },
            { headers }
        );
        console.log(data);
    };

    useEffect(() => {
        roomBule.length && cambioDeEspacios();
    }, [roomBule]);

    return (
        <div className="border p-4 rounded-lg">
            <div className="w-full justify-between flex mb-2 ">
                <div>
                    <h1>Ambientes y habitaciones</h1>
                </div>
                <div className="flex ">
                    <button
                        onClick={() => setModalNuevaHabitacion(true)}
                        className="inline-flex items-center px-3 py-1 mx-auto text font-semibold text-blue-bluecorner border border-blue-bluecorner rounded-lg group hover:bg-blue-bluecorner hover:text-white"
                    >
                        <span className="items-center">+ Crear</span>
                    </button>
                </div>
            </div>
            <div className="w-full">
                <div className="mt-2">
                    {!roomBule.length
                        ? "No hay habitaciones registradas"
                        : null}
                </div>
                <ReactSortable
                    handle=".handle"
                    className="mt-4 gap-2"
                    group="rooms"
                    animation={200}
                    delayOnTouchStart={true}
                    delay={2}
                    list={roomBule}
                    setList={setroomBule}
                >
                    {roomBule?.map((room, i) => (
                        <div
                            key={i}
                            className="border border-gray-200 rounded-md p-6   mt-2 relative handle"
                        >
                            <div className="w-full">
                                <div className="w-full justify-between flex mt-4">
                                    <div className="flex justify-center items-center gap-4">
                                        <div className="p-3 border-2 border-blue-500 rounded-lg justify-center items-center cursor-move z-0">
                                            {" "}
                                            <svg
                                                className="w-6 h-6 text-blue-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <polyline points="5 9 2 12 5 15" />{" "}
                                                <polyline points="9 5 12 2 15 5" />{" "}
                                                <polyline points="15 19 12 22 9 19" />{" "}
                                                <polyline points="19 9 22 12 19 15" />{" "}
                                                <line
                                                    x1="2"
                                                    y1="12"
                                                    x2="22"
                                                    y2="12"
                                                />{" "}
                                                <line
                                                    x1="12"
                                                    y1="2"
                                                    x2="12"
                                                    y2="22"
                                                />
                                            </svg>
                                        </div>
                                        <h1 className="py-2 font-medium text-center">
                                            {room.nombre_habitacion}
                                        </h1>
                                    </div>
                                    <div className="flex ">
                                        <button
                                            onClick={() =>
                                                setroomVisibility(room)
                                            }
                                            className="inline-flex items-center px-3 mx-auto text font-semibold text-blue-bluecorner border border-blue-bluecorner rounded-lg group hover:bg-blue-bluecorner hover:text-white"
                                        >
                                            <span className="items-center">
                                                <svg
                                                    className="h-6 w-6 "
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeÑinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                    <line
                                                        x1="16"
                                                        y1="5"
                                                        x2="19"
                                                        y2="8"
                                                    />
                                                </svg>
                                            </span>
                                        </button>

                                        <button
                                            onClick={() =>
                                                setDeleteHabitaciones(room)
                                            }
                                            className="inline-flex items-center px-3 mx-auto text font-semibold text-blue-bluecorner border border-blue-bluecorner rounded-lg group hover:bg-blue-bluecorner hover:text-white"
                                        >
                                            <span className="items-center">
                                                <svg
                                                    className="h-6 w-6"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <line
                                                        x1="4"
                                                        y1="7"
                                                        x2="20"
                                                        y2="7"
                                                    />{" "}
                                                    <line
                                                        x1="10"
                                                        y1="11"
                                                        x2="10"
                                                        y2="17"
                                                    />{" "}
                                                    <line
                                                        x1="14"
                                                        y1="11"
                                                        x2="14"
                                                        y2="17"
                                                    />{" "}
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {roomVisibility === room && (
                                <ModalAgregarHab
                                    producto={producto}
                                    setProducto={setProducto}
                                    room={room}
                                    setroomVisibility={setroomVisibility}
                                />
                            )}
                        </div>
                    ))}
                </ReactSortable>
            </div>
            {roomVisibility !== 0 && (
                <ModalAgregarHab
                    producto={producto}
                    setProducto={setProducto}
                    room={roomVisibility}
                    setroomVisibility={setroomVisibility}
                />
            )}
            {modalNuevaHabitacion && (
                <ModalNuevaHab
                    setModalNuevaHabitacion={setModalNuevaHabitacion}
                    id_productos={producto.data.id}
                    tipo={"Normal"}
                />
            )}
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg"
                >
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarHabitaciones;
