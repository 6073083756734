import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { NavbarEmpresasNew } from "./NavbarEmpresasNew";
import Swal from "sweetalert2";

const CambiarPassword = () => {
    const [siguiente, setSiguiente] = useState("Guardar");

    const [selectedFile, setSelectedFile] = useState(null);
    const [adminInfo, setAdminInfo] = useState(null);
    const [ojoActual, setOjoActual] = useState(false);
    const [ojoNueva, setOjoNueva] = useState(false);
    const [contraseña, setContraseña] = useState({
        currentPassword: "",
        newPassword: "",
    });
    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const getAdminEmpresaInfo = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/adminEmpresas/detalle/${authUser.id_usuario}`,
            { headers }
        );

        setAdminInfo(data.data);
    };

    useEffect(() => {
        getAdminEmpresaInfo();
    }, []);

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (
            contraseña.currentPassword !== "" &&
            contraseña.newPassword !== ""
        ) {
            setSiguiente("ACTUALIZANDO...");
            const { data } = await axios.put(
                `${
                    process.env.REACT_APP_PROXY
                }${"/autenticacion/actualizarPasswordBySelf/"}${
                    authUser.id_usuario
                }`,
                contraseña,
                { headers }
            );

            if (data.title === "Password actual ingresado es incorrecto") {
                Swal.fire({
                    title: "Contraseña actual ingresada es incorrecta",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }

            if (data.message === "Contraseña actualizada correctamente") {
                Swal.fire({
                    title: "Contraseña actualizada correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setContraseña({
                    currentPassword: "",
                    newPassword: "",
                });
                setSiguiente("Guardar");
            }
            setSiguiente("Guardar");
        } else {
            Swal.fire({
                title: "Por favor llene todos los campos",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    return (
        <NavbarEmpresasNew>
            <div className="w-full">
                <h1 className="py-5 text-4xl text-left w-11/12 xs:w-8/12 mx-auto lg1170:px-20 text-[#3C3C3C]">
                    Cambiar Contraseña
                </h1>

                <div className="mx-auto text-left text-[#3C3C3C]">
                    <div className="mt-2 tab-content" id="tabs-tabContent">
                        <div className="flex flex-col-reverse grid-cols-2 md:grid md:w-11/12 lg1170:w-8/12 md:mx-auto">
                            <div
                                className="tab-pane fade show active w-11/12 xs:w-8/12 mx-auto"
                                id="tabs-messages"
                                role="tabpanel"
                                aria-labelledby="tabs-profile-tab"
                            >
                                <div className="w-full mt-3 ">
                                    <label className="">
                                        Contraseña actual
                                    </label>
                                    <div className="flex items-center w-full rounded-lg  ">
                                        <input
                                            value={contraseña.currentPassword}
                                            type={`${
                                                ojoActual ? "text" : "password"
                                            }`}
                                            name="tipo_cuenta"
                                            onChange={(event) =>
                                                setContraseña({
                                                    ...contraseña,
                                                    currentPassword:
                                                        event.target.value,
                                                })
                                            }
                                            placeholder="Contraseña actual"
                                            className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                        />
                                        {ojoActual ? (
                                            <>
                                                <svg
                                                    className={`w-6 h-6 mx-2 text-[${authUser.color_primario}] cursor-pointer`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setOjoActual(
                                                            !ojoActual
                                                        );
                                                    }}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                    />
                                                </svg>
                                            </>
                                        ) : (
                                            <>
                                                <svg
                                                    className={`w-6 h-6 mx-2 text-[${authUser.color_primario}] cursor-pointer`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setOjoActual(
                                                            !ojoActual
                                                        );
                                                    }}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                    />
                                                </svg>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full mt-4 ">
                                    <label className="">
                                        Nueva contraseña:
                                    </label>
                                    <div className="flex items-center w-full rounded-lg  ">
                                        <input
                                            value={contraseña.newPassword}
                                            type={`${
                                                ojoNueva ? "text" : "password"
                                            }`}
                                            name="tipo_cuenta"
                                            onChange={(event) =>
                                                setContraseña({
                                                    ...contraseña,
                                                    newPassword:
                                                        event.target.value,
                                                })
                                            }
                                            placeholder="Nueva contraseña"
                                            className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                        />
                                        {ojoNueva ? (
                                            <>
                                                <svg
                                                    className={`w-6 h-6 mx-2 text-[${authUser.color_primario}] cursor-pointer`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setOjoNueva(!ojoNueva);
                                                    }}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                    />
                                                </svg>
                                            </>
                                        ) : (
                                            <>
                                                <svg
                                                    className={`w-6 h-6 mx-2 text-[${authUser.color_primario}] cursor-pointer`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setOjoNueva(!ojoNueva);
                                                    }}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                    />
                                                </svg>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <button
                                    disabled={
                                        siguiente === "Guardar" ? false : true
                                    }
                                    className="w-full xs:w-7/12 flex items-center justify-center mx-auto rounded-lg text-center  bg-blue-500 py-3  text-white hover:bg-blue-400 active:translate-y-[0.125rem]  mt-3 mb-3"
                                    onClick={handleUpdate}
                                >
                                    {siguiente}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NavbarEmpresasNew>
    );
};

export default CambiarPassword;
