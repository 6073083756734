import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const token = localStorage.getItem("token");
const headers = {
    authorization: token,
};

const ModalCreateManualType = ({
    modalVisible,
    setmodalVisible,
    actualPage,
    getAllTypes,
}) => {
    const [cargando, SetCargando] = useState(false);
    const [input, setInput] = useState({
        name: "",
        description: "",
        id_categoria_padre: 0,
    });
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        if (!input.name) {
            Swal.fire({
                title: "Error!",
                text: "El tipo de manual debe tener un nombre",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else {
            SetCargando(true);
            await axios.post(
                `${process.env.REACT_APP_PROXY}/tipoManual/`,
                {
                    nombre: input.name,
                },
                { headers }
            );
            Swal.fire({
                title: "Tipo de manual creado correctamente!",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
            getAllTypes("Si");
            setInput({
                name: "",
                description: "",
                id_categoria_padre: 0,
            });
            setmodalVisible(!modalVisible);
        }
    };

    const handleClose = () => {
        setInput({
            name: "",
            description: "",
            id_categoria_padre: 0,
        });
        setmodalVisible(!modalVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 w-[130%] h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-4 py-16 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-12 xl:px-36">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mb-3 text-2xl font-bold">
                            Crear nuevo tipo de Manual
                        </header>
                        <div className="w-full px-2 mt-5 rounded-2xl bg-gray-50 ring-2 ring-gray-200">
                            <input
                                type="text"
                                value={input.name}
                                name="name"
                                onChange={handleInputChange}
                                placeholder="Nombre de tipo de manual"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none"
                            />
                        </div>
                        <button
                            disabled={cargando}
                            className="w-full rounded-2xl border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10 disabled:bg-blue-400"
                            onClick={handleCreate}>
                            {cargando ? "CREANDO..." : "CREAR"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreateManualType;
