import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

const ModalEditNights = ({ setNightupdate, nightupdate }) => {
    const dispatch = useDispatch();
    const [desde, setdesde] = useState("");
    const [hasta, sethasta] = useState("");
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        setdesde(nightupdate.fecha_inicio);
        sethasta(nightupdate.fecha_final);
    }, []);

    const updateNochesminimas = (payload, id) => {
        return async function () {
            const json = await axios.put(
                `${process.env.REACT_APP_PROXY}/productoMinimaDisponibilidad/actualizar/${id}`,
                payload,
                { headers }
            );

            if (
                json.data.message ===
                "Esas fechas ya se encuentran en la minima disponibilidad"
            ) {
                Swal.fire({
                    title: "Error",
                    text: "Esta fecha ya se encuentra en la minima disponibilidad",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
            if (json.data.message === "registro exitoso") {
                Swal.fire({
                    title: "Exito",
                    text: "Se ha actualizado la minima disponibilidad",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                setNightupdate(false);
            }
        };
    };

    const actualizarNoches = () => {
        if (new Date(desde) <= new Date(hasta)) {
            dispatch(
                updateNochesminimas(
                    {
                        fecha_inicio: desde,
                        fecha_final: hasta,

                        id_productos: nightupdate.id_productos,
                    },
                    nightupdate.id
                )
            );
        } else {
            Swal.fire({
                title: "Error",
                text: "La fecha final debe ser mayor a la fecha inicial",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed top-0 w-[130%] h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-1 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 xl:px-32">
                        <header className="mb-3 text-3xl font-bold">
                            Actualizar variacion
                        </header>

                        <div className="flex w-11/12 mx-auto">
                            <div className=" p-2 pb-4 mx-auto mt-5 overflow-auto bg-blue-100 border-2 border-blue-400 rounded-lg">
                                <div className="inline-flex">
                                    <div className="grid grid-cols-1 m-1">
                                        <label className="p-2 text-base font-semibold text-center ">
                                            Desde
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                setdesde(e.target.value)
                                            }
                                            value={desde}
                                            type="date"
                                            placeholder="fecha"
                                            className="p-2  rounded-lg"
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 m-1">
                                        <label className="p-2 text-base font-semibold text-center ">
                                            Hasta
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                sethasta(e.target.value)
                                            }
                                            value={hasta}
                                            type="date"
                                            placeholder="fecha"
                                            className="p-2  rounded-lg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="pb-3 text-center ">
                                            <button
                                                onClick={() =>
                                                    actualizarNoches()
                                                }
                                                className="w-full rounded-2xl border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10">
                                                ACTUALIZAR
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => setNightupdate(false)}
                            className="mt-6 text-base leading-none text-center text-gray-800 border-b border-transparent cursor-pointer dark:text-white dark:hover:border-white focus:outline-none hover:border-gray-800 focus:border-gray-800">
                            Cancelar
                        </button>
                        <button
                            onClick={() => setNightupdate(false)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditNights;
