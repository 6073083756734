import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";

const EditarAtributos = ({ producto, setProducto, setModal }) => {
    const [data, setData] = useState({
        total: 0,
        data: [],
    });
    const [stateAuxiliar, setStateAuxiliar] = useState([]);
    const [selected, setSelected] = useState([]);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        const func = async () => {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/productoAtributos/all/${producto.data.id}`,
                { headers }
            );
            setSelected(data.map((attribute) => attribute.id_atributo));
            setStateAuxiliar(data);
        };
        const funct = async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/atributo/all/0/Si/`,
                { headers }
            );
            setData(data);
        };
        func();
        funct();
    }, []);

    const handleAttributeClick = (attribute) => {
        const button = document.getElementById(`${attribute.id}`);
        if (selected.includes(attribute.id)) {
            setSelected(selected.filter((attr) => attr !== attribute.id));
            setStateAuxiliar(
                stateAuxiliar.filter(
                    (attr) => attr.id_atributo !== attribute.id
                )
            );
            button.classList.remove("selected", "bg-blue-100");
            button.classList.replace("border-blue-700", "border-gray-400");
        } else {
            setSelected([...selected, attribute.id]);
            setStateAuxiliar([...stateAuxiliar, attribute]);
            button.classList.add("selected", "bg-blue-100");
            button.classList.replace("border-gray-400", "border-blue-700");
        }
    };

    const updateProduct = async (payload) => {
        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/productoAtributos`,
            payload,
            { headers }
        );
        console.log(data);
        if (data.message === "registro exitoso") {
            console.log(stateAuxiliar);
            setModal(false);
        }
    };

    const handleGuardar = () => {
        console.log(stateAuxiliar);
        setProducto({
            ...producto,
            data: {
                ...producto.data,
                atributos: stateAuxiliar,
            },
        });
        const payload = {
            id_producto: producto.data.id,
            atributos: selected,
            id_usuario: authUser.id_usuario,
        };
        updateProduct(payload);
    };

    return (
        <div className="border p-4 rounded-lg">
            <h1>Servicios</h1>

            <div className="flex flex-col">
                <div className="">
                    {data.data.length === 0 ? <Spinner width={7} /> : null}
                    <div className="h-[60vh] overflow-hidden">
                        <div className="h-[60vh] overflow-auto w-[105%] md:w-[104%] lg:w-[103%] 2xl:w-[102%] grid grid-cols-2">
                            {data?.data.map((attribute) => {
                                return (
                                    <div
                                        key={attribute.id}
                                        className="w-full p-3"
                                        onClick={() =>
                                            handleAttributeClick(attribute)
                                        }>
                                        <div
                                            id={attribute.id}
                                            className={
                                                selected.includes(attribute.id)
                                                    ? " items-center text-center text-base py-4 border border-solid border-[#3C3C3C] rounded-lg bg-[#F4F2F1] h-24 cursor-pointer"
                                                    : " items-center text-center text-base  py-4 border border-solid border-[#E9E9E9]  rounded-lg h-24  cursor-pointer"
                                            }>
                                            <img
                                                className="w-6 h-6 mx-auto"
                                                src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/atributo/getImage/${attribute.imagen}`}
                                            />
                                            <span className=" text-sm text-[#3C3C3C]">
                                                {attribute.nombre}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={() => handleGuardar()}
                        className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditarAtributos;
