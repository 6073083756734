import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const ModalNewEmpleado = ({
    modalVisible,
    setModalVisible,
    setfakeRefresh,
    idEmpresa = null,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    const [errors, setErrors] = useState({});
    const [input, setInput] = useState({
        userName: "",
        userCelular: "",
        tipo_documento: "DNI",
        userDni: "",
        mother_lastName: "",
        father_lastName: "",
        userEmail: "",
    });

    const validateInput = (input) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        const errores = {};
        if (input.userName === "") {
            errores.userName = "Debe ingresar un nombre";
        } else if (!input.father_lastName) {
            errores.father_lastName = "Debe ingresar apellido paterno";
        } else if (!regExpEmail.test(input.userEmail)) {
            errores.userEmail = "Debe ingresar un dirección de email válida";
        } else if (!input.userDni) {
            errores.userDni = "Debe ingresar su número de documento";
        } else if (!input.userCelular) {
            errores.userCelular = "Debe ingresar número de celular";
        }
        return errores;
    };

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleRegister = (e) => {
        e.preventDefault();
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            const usuarioNuevo = {
                nombre: input.userName,
                apellido_paterno: input.father_lastName,
                apellido_materno: input.mother_lastName,
                correo: input.userEmail,
                celular: input.userCelular,
                tipo_documentacion: input.tipo_documento,
                dni: input.userDni,
                id_empresa: idEmpresa || authUser.id_empresa,
            };
            axios
                .post(
                    `${process.env.REACT_APP_PROXY}/empleadoEmpresas`,
                    usuarioNuevo,
                    {
                        headers,
                    }
                )
                .then((response) => {
                    if (response.data.message === "registro exitoso") {
                        Swal.fire({
                            title: "Registro realizado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        setInput({
                            userName: "",
                            userCelular: "",
                            tipo_documento: "DNI",
                            userDni: "",
                            userDireccion: "",
                            mother_lastName: "",
                            father_lastName: "",
                        });
                        setfakeRefresh((prev) => !prev);
                        setModalVisible(!modalVisible);
                    } else {
                        Swal.fire({
                            title: "Se encontró un error en el registro",
                            text: response.data.title,
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        });
                    }
                });
        }
    };

    const handleClose = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
            <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 ">
                <div className="relative w-full block justify-center px-4 py-24  bg-white rounded-lg md:w-96 dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto">
                    <button
                        onClick={handleClose}
                        className="absolute text-gray-800 dark:text-gray-400 top-8 right-4 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                        <svg
                            className="rounded-full hover:bg-gray-600 hover:text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <div className="max-w-md px-4 py-1 mx-auto bg-white sm:px-5 rounded-3xl">
                        <div>
                            <header className="mb-5 text-sm font-medium">
                                Añadir empleado de empresa
                            </header>
                            <form action="#">
                                <div className="flex flex-col mb-5">
                                    <label className="mb-1 text-xs tracking-wide text-gray-600">
                                        Nombre:
                                    </label>
                                    {errors.userName && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.userName}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="w-6 h-6 text-blue-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                                <circle cx="12" cy="7" r="4" />
                                            </svg>
                                        </div>

                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="userName"
                                            onChange={handleInputChange}
                                            value={input.userName}
                                            className="w-full py-2 pl-10 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                            placeholder="Ingresa tu nombre"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col mb-5">
                                    <label
                                        htmlFor="email"
                                        className="mb-1 text-xs tracking-wide text-gray-600">
                                        Apellido paterno:
                                        {errors.father_lastName && (
                                            <p className="text-sm font-bold text-red-500 text-md">
                                                {errors.father_lastName}
                                            </p>
                                        )}
                                    </label>
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="w-6 h-6 text-blue-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                                <circle cx="12" cy="7" r="4" />
                                            </svg>
                                        </div>

                                        <input
                                            type="text"
                                            autoComplete="off"
                                            name="father_lastName"
                                            value={input.father_lastName}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                            placeholder="Ingresa el apellido de tu padre"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col mb-5">
                                    <label
                                        htmlFor="email"
                                        className="mb-1 text-xs tracking-wide text-gray-600">
                                        Apellido materno:
                                    </label>
                                    {errors.mother_lastName && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.mother_lastName}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="w-6 h-6 text-blue-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                                                <circle cx="12" cy="7" r="4" />
                                            </svg>
                                        </div>

                                        <input
                                            type="email"
                                            autoComplete="off"
                                            name="mother_lastName"
                                            value={input.mother_lastName}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                            placeholder="Ingresa el apellido de tu madre"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col mb-5">
                                    <label
                                        htmlFor="email"
                                        className="mb-1 text-xs tracking-wide text-gray-600">
                                        E-Mail:
                                    </label>
                                    {errors.userEmail && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.userEmail}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="w-6 h-6 text-blue-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />{" "}
                                                <polyline points="22,6 12,13 2,6" />
                                            </svg>
                                        </div>

                                        <input
                                            type="email"
                                            autoComplete="off"
                                            name="userEmail"
                                            value={input.userEmail}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                            placeholder="Ingresa tu dirección de email"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col mb-5">
                                    <label
                                        htmlFor="tipo_documento"
                                        className="mb-1 text-xs tracking-wide text-gray-600">
                                        Tipo de documento:
                                    </label>
                                    {errors.tipo_documento && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.tipo_documento}
                                        </p>
                                    )}
                                    <div className="relative p-2 border rounded-lg">
                                        <select
                                            className="w-full outline-none"
                                            name="tipo_documento"
                                            onChange={handleInputChange}>
                                            <option value={"DNI"}>DNI</option>
                                            <option value={"Pasaporte"}>
                                                Pasaporte
                                            </option>
                                            <option value={"CI"}>CI</option>
                                            <option value={"RUC"}>RUC</option>
                                            <option
                                                value={"Documento de viaje"}>
                                                Documento de viaje
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-col mb-5">
                                    <label
                                        htmlFor="dni"
                                        className="mb-1 text-xs tracking-wide text-gray-600">
                                        Ingresa tu documento:
                                    </label>
                                    {errors.userDni && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.userDni}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="w-6 h-6 text-blue-500"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                                />
                                            </svg>
                                        </div>

                                        <input
                                            type="dni"
                                            autoComplete="off"
                                            name="userDni"
                                            value={input.userDni}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                            placeholder="Ingresa tu DNI"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col mb-5">
                                    <label
                                        htmlFor="celular"
                                        className="mb-1 text-xs tracking-wide text-gray-600">
                                        Ingresa tu celular:
                                    </label>
                                    {errors.userCelular && (
                                        <p className="p-1 text-sm font-bold text-red-500 text-md">
                                            {errors.userCelular}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <div className="absolute top-0 left-0 inline-flex items-center justify-center w-10 h-full text-gray-400 ">
                                            <svg
                                                className="w-6 h-6 text-blue-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <rect
                                                    x="5"
                                                    y="2"
                                                    width="14"
                                                    height="20"
                                                    rx="2"
                                                    ry="2"
                                                />{" "}
                                                <line
                                                    x1="12"
                                                    y1="18"
                                                    x2="12.01"
                                                    y2="18"
                                                />
                                            </svg>
                                        </div>

                                        <input
                                            type="celular"
                                            autoComplete="off"
                                            name="userCelular"
                                            value={input.userCelular}
                                            onChange={handleInputChange}
                                            className="w-full py-2 pl-10 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                            placeholder="Ingresa tu Celular"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col mb-5 mt-5">
                                    <label
                                        htmlFor="celular"
                                        className="mb-1 text-sm tracking-wide text-blue-600">
                                        Su contraseña por defecto será
                                        <b> bluecorner</b>
                                    </label>
                                </div>

                                <div className="flex w-full">
                                    <button
                                        type="submit"
                                        onClick={handleRegister}
                                        className="flex items-center justify-center w-full py-2 mt-2 text-sm text-white transition duration-150 ease-in bg-blue-500 rounded-lg focus:outline-none sm:text-base hover:bg-blue-600">
                                        <span className="mr-2 uppercase">
                                            REGISTRAR
                                        </span>
                                        <span>
                                            <svg
                                                className="w-6 h-6"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="flex items-center justify-center mt-6"></div>
                </div>
            </div>
        </div>
    );
};

export default ModalNewEmpleado;
