import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {
    getAllBoats,
    deleteBoats,
    enableBoats,
    resetEnebleBoats,
} from "../../redux/actions/boatsActions";
import SearchBar from "./SearchBar";
import { Paginado } from "../helpers/Paginado";
import Spinner from "../Spinner";

const BoatsAll = ({
    boatsAlll,
    setBoatsAll,
    onboarding,
    setOnboarding,

    setPage,
}) => {
    const dispatch = useDispatch();
    const [completed, setCompleted] = useState("todos");
    const [product, setProduct] = useState("embarcacion");
    const [enabled, setenabled] = useState("Si");
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [pageNumber, setpageNumber] = useState(1);
    const LABELS_PER_PAGE = 25;
    const [loading, setLoading] = useState(false);

    const boats = useSelector((state) => state.boatsReducer.boats);

    const enebleReduxBoats = useSelector(
        (state) => state.boatsReducer.enableBoats
    );
    const autenticadorId = useSelector((state) => state.authReducer);

    useEffect(() => {
        setLoading(false);
    }, [boats]);

    useEffect(() => {
        dispatch(
            getAllBoats(
                1,
                "Si",
                "todos",
                autenticadorId.tipo_usuario === 3
                    ? autenticadorId.id_usuario
                    : autenticadorId.tipo_usuario === 4
                    ? autenticadorId.id_propietario
                    : 0
            )
        );
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);

        dispatch(
            getAllBoats(
                1,
                enabled,
                completed,
                autenticadorId.tipo_usuario === 3
                    ? autenticadorId.id_usuario
                    : autenticadorId.tipo_usuario === 4
                    ? autenticadorId.id_propietario
                    : 0
            )
        );
        setpageNumber(1);
    }, [dispatch, enabled, completed]);

    useEffect(() => {
        if (enebleReduxBoats === "Restaurado con exito") {
            dispatch(resetEnebleBoats());
            dispatch(
                getAllBoats(
                    1,
                    enabled,
                    "todos",
                    autenticadorId.tipo_usuario === 3
                        ? autenticadorId.id_usuario
                        : autenticadorId.tipo_usuario === 4
                        ? autenticadorId.id_propietario
                        : 0
                )
            );

            Swal.fire({
                title: "Procesado!",
                text: "Embarcacion restaurada",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        } else if (enebleReduxBoats === "Registro deshabilitado con exito") {
            dispatch(resetEnebleBoats());
            dispatch(
                getAllBoats(
                    1,
                    enabled,
                    "todos",
                    autenticadorId.tipo_usuario === 3
                        ? autenticadorId.id_usuario
                        : autenticadorId.tipo_usuario === 4
                        ? autenticadorId.id_propietario
                        : 0
                )
            );

            Swal.fire({
                title: "Procesado!",
                text: "Embarcacion deshabilitada",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        }
    }, [enebleReduxBoats]);

    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarla?",
            text: "Se enviará la embarcacion a deshabilitadas!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteBoats(id));
                setLoading(true);
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarla?",
            text: "Se enviará la embarcacion a habilitadas!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(enableBoats(id));
                setLoading(true);
            }
        });
    };

    const oneBoats = (id, pagina) => {
        return async function () {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/embarcacion/detalle/${id}/0`
            );
            if (json.data) {
                console.log(json.data);
                setOnboarding({ ...onboarding, ...json.data });
                setBoatsAll(!boatsAlll);
                setPage(Number(pagina));
            }
        };
    };

    const handleEdit = (id, pagina) => {
        Swal.fire({
            title: "¿Seguro que quieres editar la embarcacion?",

            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, editar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(oneBoats(id, pagina));
                setLoading(true);
            }
        });
    };

    const pagination = (pageNumber) => {
        setLoading(true);

        setpageNumber(pageNumber);
        dispatch(
            getAllBoats(
                pageNumber,
                enabled,
                completed,
                autenticadorId.tipo_usuario === 3
                    ? autenticadorId.id_usuario
                    : autenticadorId.tipo_usuario === 4
                    ? autenticadorId.id_propietario
                    : 0
            )
        );
    };

    return (
        <div>
            <div className="flex w-full">
                <button
                    className="inline-flex items-center px-3 py-2 mx-auto text-lg font-semibold text-blue-700 border border-blue-700 rounded group hover:bg-blue-700 hover:text-white"
                    onClick={() => setBoatsAll(!boatsAlll)}>
                    <svg
                        className="w-5 h-5 text-blue-700 group-hover:text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                    <span className="items-center">Añadir</span>
                </button>
            </div>

            <div className="container flex items-center justify-between p-1 mx-auto">
                <div>
                    <button
                        className={
                            enabled === "Si"
                                ? "inline-block p-2 text-sm mx-3 text-green-700 border border-green-500 rounded group hover:bg-green-700 hover:text-white"
                                : "inline-block p-2 text-sm mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white"
                        }
                        onClick={() => (
                            setLoading(!loading),
                            setenabled(enabled === "Si" ? "No" : "Si")
                        )}>
                        {enabled === "Si"
                            ? `Habilitados (${boats?.total ? boats?.total : 0})`
                            : `Deshabilitadas (${
                                  boats?.total ? boats?.total : 0
                              })`}
                    </button>
                    <select
                        onChange={(e) => setCompleted(e.target.value)}
                        defaultValue="todos"
                        className="border-2 border-gray-400 rounded px-1 py-[7px] w-[116px] mx-3 mt-1 text-sm">
                        <option value="todos">Todos</option>
                        <option value="si">Completos</option>
                        <option value="no">Incompletos</option>
                    </select>
                </div>

                <SearchBar
                    setfakeRefresh={setfakeRefresh}
                    fakeRefresh={fakeRefresh}
                    enabled={enabled}
                    product={product}
                    completed={completed}
                    autenticadorId={autenticadorId}
                />
            </div>
            <div className="container flex items-center justify-between p-1 mx-auto">
                <Paginado
                    elementsPerPage={LABELS_PER_PAGE}
                    cantTotalElements={boats.total}
                    pagination={pagination}
                />
            </div>

            <section className="flex justify-center items-center lg:py-[10px] w-[95%] mx-auto">
                <div className="container">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full ">
                            <div className="flex max-w-full text-center">
                                {boats.total > 0 && !loading ? (
                                    <table className="w-full table-auto">
                                        <thead className="bg-blue-700">
                                            <tr className="text-center bg-primary">
                                                <th className=" w-[3%]  text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                                                    #
                                                </th>
                                                <th className="w-1/12 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                                                    Banner
                                                </th>
                                                <th className="w-1/12 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                                                    Nombre
                                                </th>
                                                <th className="w-1/12 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                                                    Precio
                                                </th>

                                                <th className="w-1/12 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                                                    Direccion
                                                </th>
                                                <th className="w-1/12 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                                                    Etiquetas
                                                </th>
                                                <th className="w-1/12 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                                                    Accion
                                                </th>
                                            </tr>
                                        </thead>
                                        {boats.data?.map((p, i) => (
                                            <tbody key={p.id}>
                                                <tr>
                                                    <td
                                                        className={
                                                            enabled === "Si" &&
                                                            p.terminado === "No"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#f8f8c6] border-b border-l border-[#E8E8E8]"
                                                                : enabled ===
                                                                      "Si" &&
                                                                  p.terminado ===
                                                                      "Si"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#cbfac0] border-b border-l border-[#E8E8E8]"
                                                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                        }>
                                                        {pageNumber * 25 -
                                                            25 +
                                                            i +
                                                            1}
                                                    </td>
                                                    <td
                                                        className={
                                                            enabled === "Si" &&
                                                            p.terminado === "No"
                                                                ? "flex justify-center text-center text-dark font-medium text-base py-8 px-2 bg-[#f8f8c6] border-b border-l border-[#E8E8E8]"
                                                                : enabled ===
                                                                      "Si" &&
                                                                  p.terminado ===
                                                                      "Si"
                                                                ? "flex justify-center text-center text-dark font-medium text-base py-8 px-2 bg-[#cbfac0] border-b border-l border-[#E8E8E8]"
                                                                : "flex justify-center text-center text-dark font-medium text-base py-8 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                        }>
                                                        <img
                                                            className="object-cover w-12 h-12"
                                                            alt="banner de producto"
                                                            src={
                                                                `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/embarcacion/getImagenPrincipal/` +
                                                                p.imagen_principal
                                                            }
                                                        />
                                                    </td>
                                                    <td
                                                        className={
                                                            enabled === "Si" &&
                                                            p.terminado === "No"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#f8f8c6] border-b border-l border-[#E8E8E8]"
                                                                : enabled ===
                                                                      "Si" &&
                                                                  p.terminado ===
                                                                      "Si"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#cbfac0] border-b border-l border-[#E8E8E8]"
                                                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                        }>
                                                        {p?.nombre}
                                                    </td>
                                                    <td
                                                        className={
                                                            enabled === "Si" &&
                                                            p.terminado === "No"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#f8f8c6] border-b border-l border-[#E8E8E8]"
                                                                : enabled ===
                                                                      "Si" &&
                                                                  p.terminado ===
                                                                      "Si"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#cbfac0] border-b border-l border-[#E8E8E8]"
                                                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                        }>
                                                        {p?.precio_base}{" "}
                                                        {p.moneda_precio_base}
                                                    </td>

                                                    <td
                                                        className={
                                                            enabled === "Si" &&
                                                            p.terminado === "No"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#f8f8c6] border-b border-l border-[#E8E8E8]"
                                                                : enabled ===
                                                                      "Si" &&
                                                                  p.terminado ===
                                                                      "Si"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#cbfac0] border-b border-l border-[#E8E8E8]"
                                                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                        }>
                                                        {p?.ciudad},{" "}
                                                        {p?.departamento},{" "}
                                                        {p?.pais}
                                                    </td>
                                                    <td
                                                        className={
                                                            enabled === "Si" &&
                                                            p.terminado === "No"
                                                                ? "text-center text-dark font-medium text-xs py-5 px-2 bg-[#f8f8c6] border-b border-l border-[#E8E8E8]"
                                                                : enabled ===
                                                                      "Si" &&
                                                                  p.terminado ===
                                                                      "Si"
                                                                ? "text-center text-dark font-medium text-xs py-5 px-2 bg-[#cbfac0] border-b border-l border-[#E8E8E8]"
                                                                : "text-center text-dark font-medium text-xs py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                        }>
                                                        {p?.etiquetas?.map(
                                                            (e, i) => (
                                                                <span key={i}>
                                                                    {e.nombre},{" "}
                                                                </span>
                                                            )
                                                        )}
                                                    </td>

                                                    <td
                                                        className={
                                                            enabled === "Si" &&
                                                            p.terminado === "No"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#f8f8c6] border-b border-l border-[#E8E8E8]"
                                                                : enabled ===
                                                                      "Si" &&
                                                                  p.terminado ===
                                                                      "Si"
                                                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#cbfac0] border-b border-l border-[#E8E8E8]"
                                                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                        }>
                                                        {enabled === "Si" ? (
                                                            <div>
                                                                <button
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            p?.id,
                                                                            p?.numero_pagina
                                                                        )
                                                                    }
                                                                    title="Editar"
                                                                    className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white">
                                                                    <svg
                                                                        className="w-8 h-8 text-blue-500 group-hover:text-white"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />
                                                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                        <line
                                                                            x1="16"
                                                                            y1="5"
                                                                            x2="19"
                                                                            y2="8"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                                <button
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            p?.id
                                                                        )
                                                                    }
                                                                    title="Eliminar"
                                                                    className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white">
                                                                    <svg
                                                                        className="w-8 h-8 text-red-500 group-hover:text-white"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />
                                                                        <line
                                                                            x1="4"
                                                                            y1="7"
                                                                            x2="20"
                                                                            y2="7"
                                                                        />
                                                                        <line
                                                                            x1="10"
                                                                            y1="11"
                                                                            x2="10"
                                                                            y2="17"
                                                                        />
                                                                        <line
                                                                            x1="14"
                                                                            y1="11"
                                                                            x2="14"
                                                                            y2="17"
                                                                        />
                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                onClick={() =>
                                                                    handleEnable(
                                                                        p?.id
                                                                    )
                                                                }
                                                                title="Habilitar"
                                                                className="inline-block px-1 py-1 mx-3 text-green-700 border border-green-500 rounded group hover:bg-green-700 hover:white">
                                                                <svg
                                                                    className="w-8 h-8 text-green-500 group-hover:text-white"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    {" "}
                                                                    <polyline points="17 1 21 5 17 9" />{" "}
                                                                    <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                    <polyline points="7 23 3 19 7 15" />{" "}
                                                                    <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                </svg>
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                ) : (
                                    <Spinner />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BoatsAll;
