import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import "swiper/css/lazy";
import "../../empresas/paquetesEmpresa/Swiper.css";
import useHeaders from "../../../hooks/useHeaders";
import { ReactSortable } from "react-sortablejs";
import { useSelector } from "react-redux";
import CardSanValentin from "./CardSanValentin";

const AllCards = ({ vistaAdministrador, refreshHouses, setRefreshHouses }) => {
    const [allHouses, setAllHouses] = useState([]);
    const [checking, setChecking] = useState(true);
    const { headers } = useHeaders();
    const authUser = useSelector((state) => state.authReducer);

    const getHouses = async () => {
        try {
            setChecking(true);
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/casasSemanaSanta/getAllAnoNuevo`
            );
            setChecking(false);
            console.log(data);
            setAllHouses(data);
        } catch (error) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        getHouses();
    }, [refreshHouses]);

    const handleDragEnd = async () => {
        const allHousesID = allHouses.map((house) => house.id);

        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casaSanValentin/updateOrden/`,
                { array_orden: allHousesID },
                { headers }
            );
        } catch (error) {
            console.log(error.response);
        }
    };

    return (
        <div>
            {checking ? (
                <div className="justify-center w-full py-10">
                    <Spinner />
                </div>
            ) : (
                allHouses.length > 0 && (
                    <div>
                        <div className="p-4 xl:px-32 2xl:px-28">
                            <ReactSortable
                                disabled={!vistaAdministrador}
                                onEnd={() => handleDragEnd()}
                                animation={200}
                                delayOnTouchStart={true}
                                delay={2}
                                list={allHouses}
                                setList={setAllHouses}
                                className="w-full grid-cols-2 gap-4 mt-4 md:grid lg:grid-cols-3 ">
                                {allHouses.map((product, i) => (
                                    <CardSanValentin
                                        tipo={product.tipo}
                                        name={product.id}
                                        key={i}
                                        product={product}
                                        vistaAdministrador={vistaAdministrador}
                                        setRefreshHouses={setRefreshHouses}
                                    />
                                ))}
                            </ReactSortable>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default AllCards;
