import axios from "axios";
import React, { useState, useEffect, useReducer, useCallback } from "react";
import Swal from "sweetalert2";
import Spinner from "../Spinner";
import FiltrosEmbarcaciones from "./FiltrosEmbarcaciones";

const FiltrosAlojamientos = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [showAlojamientos, setShowAlojamientos] = useState(true);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [selectedRules, setSelectedRules] = useState([]);
    const [selectedTiposAlojamientos, setSelectedTiposAlojamientos] = useState(
        []
    );
    const [selectedUbicaciones, setSelectedUbicaciones] = useState([]);
    const filtersReducer = useCallback(
        (state, action) => {
            switch (action.type) {
                //ATRIBUTOS////////////////////////////////////////////////////////
                case "updateAtributos":
                    if (
                        state.atributos.some(
                            (atribute) => atribute === action.payload
                        )
                    )
                        return state;
                    return {
                        ...state,
                        atributos: [...state.atributos, action.payload],
                    };

                case "deleteAtributos":
                    setSelectedAttributes(
                        selectedAttributes.filter(
                            (attribute) => attribute.id !== action.payload
                        )
                    );
                    return {
                        ...state,
                        atributos: state.atributos.filter(
                            (attribute) => attribute !== action.payload
                        ),
                    };

                //REGLAS////////////////////////////////////////////////
                case "updateRules":
                    if (state.reglas.some((rule) => rule.id === action.payload))
                        return state;
                    return {
                        ...state,
                        reglas: [...state.reglas, action.payload],
                    };

                case "deleteRules":
                    setSelectedRules(
                        selectedRules.filter(
                            (rule) => rule.id !== action.payload
                        )
                    );
                    return {
                        ...state,
                        reglas: state.reglas.filter(
                            (rule) => rule !== action.payload
                        ),
                    };

                //TIPOS DE ALOJAMIENTO////////////////////////////////////////////////
                case "updateTypes":
                    if (
                        state.tipo_alojamiento.some(
                            (tipo) => tipo.id === action.payload
                        )
                    )
                        return state;
                    return {
                        ...state,
                        tipo_alojamiento: [
                            ...state.tipo_alojamiento,
                            action.payload,
                        ],
                    };

                case "deleteTypes":
                    setSelectedTiposAlojamientos(
                        selectedTiposAlojamientos.filter(
                            (tipo) => tipo.id !== action.payload
                        )
                    );
                    return {
                        ...state,
                        tipo_alojamiento: state.tipo_alojamiento.filter(
                            (tipo) => tipo !== action.payload
                        ),
                    };

                //TIPOS DE UBICACION////////////////////////////////////////////////
                case "updateUbications":
                    if (
                        state.ubicacion.some(
                            (ubicacion) => ubicacion.id === action.payload
                        )
                    )
                        return state;
                    return {
                        ...state,
                        ubicacion: [...state.ubicacion, action.payload],
                    };

                case "deleteUbications":
                    setSelectedUbicaciones(
                        selectedUbicaciones.filter(
                            (ubicacion) => ubicacion.id !== action.payload
                        )
                    );
                    return {
                        ...state,
                        ubicacion: state.ubicacion.filter(
                            (ubicacion) => ubicacion !== action.payload
                        ),
                    };

                case "changeChecking":
                    return {
                        ...state,
                        checking: action.payload,
                    };

                default:
                    return state;
            }
        },
        [
            selectedAttributes,
            selectedRules,
            selectedUbicaciones,
            selectedTiposAlojamientos,
        ]
    );

    const [allFilters, setAllFilters] = useState({
        atributos: [],
        reglas: [],
        tipo_alojamiento: [],
        ubicacion: [],
    });
    const [siguiente, setSiguiente] = useState("Guardar");
    const [selectedFilters, dispatch] = useReducer(filtersReducer, {
        checking: true,
        atributos: [],
        reglas: [],
        tipo_alojamiento: [],
        ubicacion: [],
    });

    useEffect(() => {
        "Entrando useEffect fillFilters";
        fillFilters();
    }, [showAlojamientos]);

    useEffect(() => {
        "Entrando useEffect fillFields";
        fillFields();
    }, [showAlojamientos]);

    const fillFilters = async () => {
        setAllFilters({
            atributos: [],
            reglas: [],
            tipo_alojamiento: [],
            ubicacion: [],
        });
        const atributos = await axios(
            `${process.env.REACT_APP_PROXY}/atributo/all/0/Si`,
            { headers }
        );
        const reglas = await axios(
            `${process.env.REACT_APP_PROXY}/regla/all/0/Si`,
            { headers }
        );
        const tiposAlojamiento = await axios(
            `${process.env.REACT_APP_PROXY}/propiedades/all/0/Si/Tipo Alojamiento`,
            { headers }
        );
        const ubicaciones = await axios(
            `${process.env.REACT_APP_PROXY}/propiedades/all/0/Si/Ubicacion`,
            { headers }
        );

        setAllFilters({
            ...allFilters,
            atributos: atributos.data.data,
            reglas: reglas.data.data,
            tipo_alojamiento: tiposAlojamiento.data.data,
            ubicacion: ubicaciones.data.data,
        });
    };

    const fillFields = useCallback(async () => {
        setSelectedAttributes([]);
        setSelectedRules([]);
        setSelectedTiposAlojamientos([]);
        setSelectedUbicaciones([]);
        dispatch({
            type: "changeChecking",
            payload: true,
        });
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/filtros/all/Casas`
        );
        data[0].atributos.forEach((attribute) => {
            dispatch({
                type: "updateAtributos",
                payload: attribute.id,
            });
            setSelectedAttributes((prev) => [...prev, attribute]);
        });
        data[1].reglas.forEach((rule) => {
            dispatch({
                type: "updateRules",
                payload: rule.id,
            });
            setSelectedRules((prev) => [...prev, rule]);
        });
        data[2].tipo_alojamiento.forEach((type) => {
            dispatch({
                type: "updateTypes",
                payload: type.id,
            });
            setSelectedTiposAlojamientos((prev) => [...prev, type]);
        });
        data[3].ubicacion.forEach((ubication) => {
            dispatch({
                type: "updateUbications",
                payload: ubication.id,
            });
            setSelectedUbicaciones((prev) => [...prev, ubication]);
        });
        dispatch({
            type: "changeChecking",
            payload: false,
        });
    }, []);

    const registerFilters = async () => {
        setSiguiente(null);
        await axios.post(
            `${process.env.REACT_APP_PROXY}/filtros/`,
            {
                filtros: selectedFilters,
                filtro_producto: "Casas",
            },
            { headers }
        );
        Swal.fire({
            title: "Filtros actualizados correctamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
        });
        setSiguiente("Confirmar cambios");
    };

    return (
        <div className=" w-full md:pl-[12rem]  lg:pl-[17rem]  p-4 ">
            <h1 className="py-5 text-4xl mx-4">Filtros</h1>
            <div className="flex flex-col md:flex-row w-full justify-between items-center">
                <div className="flex w-full mx-auto ">
                    <div className="flex p-2">
                        <button
                            disabled={selectedFilters.checking}
                            className={`${
                                !showAlojamientos
                                    ? "p-3 text-blue-500 border border-blue-500 px-6 max-w-[156px] rounded-lg"
                                    : "p-3 px-6 max-w-[156px]  text-white bg-blue-500 border rounded-lg"
                            }`}
                            onClick={() => setShowAlojamientos(true)}>
                            Alojamientos
                        </button>
                        <button
                            disabled={selectedFilters.checking}
                            className={`${
                                showAlojamientos
                                    ? "p-3 ml-2 text-blue-500 border border-blue-500 px-6 max-w-[156px] rounded-lg"
                                    : "p-3 px-6 max-w-[156px] text-white bg-blue-500 border rounded-lg ml-2"
                            }`}
                            onClick={() => setShowAlojamientos(false)}>
                            Embarcaciones
                        </button>
                    </div>
                </div>
            </div>
            {showAlojamientos ? (
                <div className="flex flex-col w-full mx-auto">
                    <div className="mx-auto">
                        <hr className="mx-auto my-5 md:hidden" />
                        <h2 className="mt-5 text-lg font-bold">Atributos</h2>

                        {selectedFilters.checking ||
                        allFilters?.atributos?.length === 0 ? (
                            <div className="mt-5">
                                <Spinner width={7} />
                            </div>
                        ) : (
                            <div>
                                <select
                                    defaultValue="Selecciona una opcion"
                                    className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                    onChange={(e) => {
                                        if (
                                            selectedAttributes.some(
                                                (attribute) =>
                                                    attribute.id ===
                                                    Number(e.target.value)
                                            )
                                        )
                                            return;
                                        const newAttr =
                                            allFilters?.atributos.filter(
                                                (attribute) =>
                                                    attribute.id ===
                                                    Number(e.target.value)
                                            );
                                        setSelectedAttributes((prev) => [
                                            ...prev,
                                            ...newAttr,
                                        ]);
                                        dispatch({
                                            type: "updateAtributos",
                                            payload: Number(e.target.value),
                                        });
                                    }}>
                                    <option disabled>
                                        Selecciona una opcion
                                    </option>
                                    {allFilters?.atributos?.map((atributo) => (
                                        <option
                                            key={atributo.id}
                                            value={atributo.id}>
                                            {atributo.nombre}
                                        </option>
                                    ))}
                                </select>
                                {selectedAttributes.length > 0 ? (
                                    <div className="p-1">
                                        <ul className="w-64 mt-5">
                                            {selectedAttributes?.map(
                                                (attribute) => (
                                                    <li
                                                        key={attribute.id}
                                                        className="flex justify-between mt-3">
                                                        <span className="font-medium">
                                                            {attribute.nombre}
                                                        </span>
                                                        <button
                                                            className="flex items-center justify-center w-8 h-8 font-semibold text-white border-2 border-blue-bluecorner rounded-lg"
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: "deleteAtributos",
                                                                    payload:
                                                                        attribute.id,
                                                                });
                                                            }}>
                                                            <span className="flex text-blue-bluecorner">
                                                                <svg
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M18 6L6 18"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.66667"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M6 6L18 18"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.66667"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                ) : (
                                    <h1 className="mt-3 font-medium text-center">
                                        Aun no tienes filtros asignados
                                    </h1>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="mx-auto">
                        <hr className="mx-auto my-5 " />
                        <h2 className="mt-5 text-lg font-bold">Reglas</h2>

                        {selectedFilters.checking ||
                        allFilters?.atributos?.length === 0 ? (
                            <div className="mt-5">
                                <Spinner width={7} />
                            </div>
                        ) : (
                            <div>
                                <select
                                    defaultValue="Selecciona una opcion"
                                    className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                    onChange={(e) => {
                                        if (
                                            selectedRules.some(
                                                (rule) =>
                                                    rule.id ===
                                                    Number(e.target.value)
                                            )
                                        )
                                            return;
                                        const newRule =
                                            allFilters?.reglas?.filter(
                                                (rules) =>
                                                    rules.id ===
                                                    Number(e.target.value)
                                            );
                                        setSelectedRules((prev) => [
                                            ...prev,
                                            ...newRule,
                                        ]);
                                        dispatch({
                                            type: "updateRules",
                                            payload: Number(e.target.value),
                                        });
                                    }}>
                                    <option disabled>
                                        Selecciona una opcion
                                    </option>
                                    {allFilters?.reglas?.map((rule) => (
                                        <option key={rule.id} value={rule.id}>
                                            {rule.nombre_si}
                                        </option>
                                    ))}
                                </select>
                                {selectedRules.length > 0 ? (
                                    <div className="p-1">
                                        <ul className="w-64 mt-5">
                                            {selectedRules?.map((rule) => (
                                                <li
                                                    key={rule.id}
                                                    className="flex justify-between mt-3">
                                                    <span className="font-medium">
                                                        {rule.nombre_si}
                                                    </span>
                                                    <button
                                                        className="flex items-center justify-center w-8 h-8 font-semibold text-white border-2 border-blue-bluecorner rounded-lg"
                                                        onClick={() => {
                                                            dispatch({
                                                                type: "deleteRules",
                                                                payload:
                                                                    rule.id,
                                                            });
                                                        }}>
                                                        <span className="flex text-blue-bluecorner">
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M18 6L6 18"
                                                                    stroke="currentColor"
                                                                    strokeWidth="1.66667"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M6 6L18 18"
                                                                    stroke="currentColor"
                                                                    strokeWidth="1.66667"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <h1 className="mt-3 font-medium text-center">
                                        Aun no tienes filtros asignados
                                    </h1>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="mx-auto">
                        <hr className="mx-auto my-5 " />
                        <h2 className="mt-5 text-lg font-bold">
                            Tipos de alojamiento
                        </h2>

                        {selectedFilters.checking ||
                        allFilters?.atributos?.length === 0 ? (
                            <div className="mt-5">
                                <Spinner width={7} />
                            </div>
                        ) : (
                            <div>
                                <select
                                    defaultValue="Selecciona una opcion"
                                    className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                    onChange={(e) => {
                                        if (
                                            selectedTiposAlojamientos.some(
                                                (tipo) =>
                                                    tipo.id ===
                                                    Number(e.target.value)
                                            )
                                        )
                                            return;
                                        const newType =
                                            allFilters?.tipo_alojamiento?.filter(
                                                (tipo) =>
                                                    tipo.id ===
                                                    Number(e.target.value)
                                            );
                                        setSelectedTiposAlojamientos((prev) => [
                                            ...prev,
                                            ...newType,
                                        ]);
                                        dispatch({
                                            type: "updateTypes",
                                            payload: Number(e.target.value),
                                        });
                                    }}>
                                    <option disabled>
                                        Selecciona una opcion
                                    </option>
                                    {allFilters?.tipo_alojamiento?.map(
                                        (tipo) => (
                                            <option
                                                key={tipo.id}
                                                value={tipo.id}>
                                                {tipo.nombre}
                                            </option>
                                        )
                                    )}
                                </select>
                                {selectedTiposAlojamientos.length > 0 ? (
                                    <div className="p-1">
                                        <ul className="w-64 mt-5">
                                            {selectedTiposAlojamientos?.map(
                                                (tipo) => (
                                                    <li
                                                        key={tipo.id}
                                                        className="flex justify-between mt-3">
                                                        <span className="font-medium">
                                                            {tipo.nombre}
                                                        </span>
                                                        <button
                                                            className="flex items-center justify-center w-8 h-8 font-semibold text-white border-2 border-blue-bluecorner rounded-lg"
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: "deleteTypes",
                                                                    payload:
                                                                        tipo.id,
                                                                });
                                                            }}>
                                                            <span className="flex text-blue-bluecorner">
                                                                <svg
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M18 6L6 18"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.66667"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M6 6L18 18"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.66667"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                ) : (
                                    <h1 className="mt-3 font-medium text-center">
                                        Aun no tienes filtros asignados
                                    </h1>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="mx-auto">
                        <hr className="mx-auto my-5 " />
                        <h2 className="mt-5 text-lg font-bold">
                            Tipos de ubicaciones
                        </h2>

                        {selectedFilters.checking ||
                        allFilters?.atributos?.length === 0 ? (
                            <div className="mt-5">
                                <Spinner width={7} />
                            </div>
                        ) : (
                            <div>
                                <select
                                    defaultValue="Selecciona una opcion"
                                    className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                    onChange={(e) => {
                                        if (
                                            selectedUbicaciones.some(
                                                (ubicacion) =>
                                                    ubicacion.id ===
                                                    Number(e.target.value)
                                            )
                                        )
                                            return;
                                        const newUbication =
                                            allFilters?.ubicacion?.filter(
                                                (ubicacion) =>
                                                    ubicacion.id ===
                                                    Number(e.target.value)
                                            );
                                        setSelectedUbicaciones((prev) => [
                                            ...prev,
                                            ...newUbication,
                                        ]);
                                        dispatch({
                                            type: "updateUbications",
                                            payload: Number(e.target.value),
                                        });
                                    }}>
                                    <option disabled>
                                        Selecciona una opcion
                                    </option>
                                    {allFilters?.ubicacion?.map((ubicacion) => (
                                        <option
                                            key={ubicacion.id}
                                            value={ubicacion.id}>
                                            {ubicacion.nombre}
                                        </option>
                                    ))}
                                </select>
                                {selectedUbicaciones.length > 0 ? (
                                    <div className="p-1">
                                        <ul className="w-64 mt-5">
                                            {selectedUbicaciones?.map(
                                                (ubicacion) => (
                                                    <li
                                                        key={ubicacion.id}
                                                        className="flex justify-between mt-3">
                                                        <span className="font-medium">
                                                            {ubicacion.nombre}
                                                        </span>
                                                        <button
                                                            className="flex items-center justify-center w-8 h-8 font-semibold text-white border-2 border-blue-bluecorner rounded-lg"
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: "deleteUbications",
                                                                    payload:
                                                                        ubicacion.id,
                                                                });
                                                            }}>
                                                            <span className="flex text-blue-bluecorner">
                                                                <svg
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M18 6L6 18"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.66667"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M6 6L18 18"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.66667"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                ) : (
                                    <h1 className="mt-3 font-medium text-center">
                                        Aun no tienes filtros asignados
                                    </h1>
                                )}
                            </div>
                        )}
                    </div>
                    <button
                        onClick={() => registerFilters()}
                        className="w-64 p-4 mx-auto mt-10 mb-10 text-white transition ease-in bg-blue-500 rounded-lg hover:scale-95">
                        {siguiente ? siguiente : <Spinner width={7} />}
                    </button>
                </div>
            ) : (
                <FiltrosEmbarcaciones />
            )}
        </div>
    );
};

export default FiltrosAlojamientos;
