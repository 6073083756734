import axios from "axios";
import React, { useEffect, useState } from "react";
import useHeaders from "../../../hooks/useHeaders";
import ActividadesPlanificadas from "./ActividadesPlanificadas";
import TodasLasActividades from "./TodasLasActividades";
import FeedEmpresa from "./TodasLasActividades";

const FeedActividadEmpresa = ({
    empresa,
    refreshActividades,
    setRefreshActividades,
}) => {
    const { headers } = useHeaders();
    const [todasLasActividades, setTodasLasActividades] = useState(true);
    const [actividadesOpen, setActividadesOpen] = useState(true);
    const [actividadesPlanificadas, setActividadesPlanificadas] = useState([]);
    const [actividadesFeed, setActividadesFeed] = useState([]);

    const getAllActividades = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/actividadesCrm/allPendientes/${empresa.id}`,
            { headers }
        );

        const actividadesFeed = await axios(
            `${process.env.REACT_APP_PROXY}/empresas/getFeed/${empresa.id}`,
            { headers }
        );

        setActividadesFeed(actividadesFeed.data.data);

        setActividadesPlanificadas(data.data);
    };

    useEffect(() => {
        getAllActividades();
    }, [refreshActividades]);

    return (
        <div className="pb-10">
            {actividadesPlanificadas.length > 0 && (
                <div className="mt-5">
                    <div
                        className="flex items-center justify-center gap-1 cursor-pointer select-none p-3 border-y"
                        onClick={() => setActividadesOpen(!actividadesOpen)}>
                        <div className="flex items-center w-44">
                            {!actividadesOpen ? (
                                <svg
                                    className="h-4 w-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                    <polyline points="9 6 15 12 9 18" />
                                </svg>
                            ) : (
                                <svg
                                    className="h-4 w-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                    <polyline points="6 9 12 15 18 9" />
                                </svg>
                            )}
                            <span>Actividades planificadas</span>
                        </div>
                    </div>
                    {actividadesOpen && (
                        <ActividadesPlanificadas
                            actividadesPlanificadas={actividadesPlanificadas}
                            setRefreshActividades={setRefreshActividades}
                            empresa={empresa}
                        />
                    )}
                    <div
                        className="flex items-center justify-center gap-1 cursor-pointer select-none p-3 mt-5 border-y"
                        onClick={() =>
                            setTodasLasActividades(!todasLasActividades)
                        }>
                        <div className="flex items-center w-44">
                            {!todasLasActividades ? (
                                <svg
                                    className="h-4 w-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                    <polyline points="9 6 15 12 9 18" />
                                </svg>
                            ) : (
                                <svg
                                    className="h-4 w-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                    <polyline points="6 9 12 15 18 9" />
                                </svg>
                            )}
                            <span>Todas las actividades</span>
                        </div>
                    </div>
                    {todasLasActividades && (
                        <TodasLasActividades
                            actividades={actividadesFeed}
                            empresa={empresa}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default FeedActividadEmpresa;
