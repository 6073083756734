import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const ModalCambiarPassword = ({
    setModalCambiarPasswordVisible,
    modalCambiarPasswordVisible,
}) => {
    const [siguiente, setSiguiente] = useState("Cambiar contraseña");
    const [ojoActual, setOjoActual] = useState(false);
    const [ojoNueva, setOjoNueva] = useState(false);
    const [contraseña, setContraseña] = useState({
        currentPassword: "",
        newPassword: "",
    });
    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleUpdate = async () => {
        console.log(contraseña);
        if (
            contraseña.currentPassword !== "" &&
            contraseña.newPassword !== ""
        ) {
            setSiguiente("Actualizando...");
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/adminEmpresas/actualizarPassword/${authUser.id_usuario}`,
                contraseña,
                { headers }
            );

            if (data.title === "Password actual ingresado es incorrecto") {
                Swal.fire({
                    title: "Contraseña actual ingresada es incorrecta",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }

            if (data.message === "Contraseña actualizada correctamente") {
                Swal.fire({
                    title: "Contraseña actualizada correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setContraseña({
                    currentPassword: "",
                    newPassword: "",
                });
                setSiguiente("Cambiar contraseña");
                setModalCambiarPasswordVisible(!modalCambiarPasswordVisible);
            }
            setSiguiente("Cambiar contraseña");
        } else {
            Swal.fire({
                title: "Por favor complete ambos campos para cambiar su contraseña",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    return (
        <div className="flex flex-col">
            <div className="w-full ">
                <label className=" ">Contraseña actual</label>

                <input
                    value={contraseña.currentPassword}
                    type={`${ojoActual ? "text" : "password"}`}
                    onChange={(event) =>
                        setContraseña({
                            ...contraseña,
                            currentPassword: event.target.value,
                        })
                    }
                    placeholder="Contraseña actual"
                    className="w-full border my-3 bg-transparent rounded-lg py-3 px-3 outline-none focus:outline-none"
                />
            </div>
            <div className="w-full mt-4 ">
                <label className=" ">Nueva contraseña:</label>

                <input
                    value={contraseña.newPassword}
                    type={`${ojoNueva ? "text" : "password"}`}
                    onChange={(event) =>
                        setContraseña({
                            ...contraseña,
                            newPassword: event.target.value,
                        })
                    }
                    placeholder="Nueva contraseña"
                    className="w-full border my-3 bg-transparent rounded-lg py-3 px-3 outline-none focus:outline-none"
                />
            </div>
            <button
                disabled={siguiente === "Cambiar contraseña" ? false : true}
                className="w-2/3 flex items-center justify-center mx-auto rounded-lg text-center py-3 text-blue-bluecorner border-blue-bluecorner hover:scale-105 border active:translate-y-[0.125rem]  my-4  disabled:bg-gray-400"
                onClick={handleUpdate}>
                {siguiente}
            </button>
        </div>
    );
};

export default ModalCambiarPassword;
