import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";

const EditarPartidaRegistral = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
}) => {
    const [updating, setUpdating] = useState(false);
    const [preview, setPreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const handleChangeImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(selectedFile);
            return;
        }
        setSelectedFile(e.target.files[0]);

        const objectUrl = URL.createObjectURL(e.target.files[0]);

        setPreview(objectUrl);

        setProducto({
            ...producto,
            data: {
                ...producto.data,
                partida_registral: objectUrl,
            },
        });
    };

    console.log(producto);

    const updateProduct = async () => {
        const form = new FormData();
        setUpdating((prev) => !prev);
        form.append("id_usuario", authUser.id_usuario);
        if (tipo_producto === "casa") {
            console.log(producto.data.cancelacion);
            form.append("cancelacion", producto.data.cancelacion);
            form.append(
                "porcentaje_moderado",
                producto.data.porcentaje_moderado
            );
            form.append("imagen_partida_casa", selectedFile);
            const jsonCancelacion = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarCancelacion/${producto.data.id}`,
                form,
                { headers }
            );

            console.log(jsonCancelacion);

            if (jsonCancelacion.data.message === "actuaizacion exitoso") {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        cancelacion: jsonCancelacion.data.data.cancelacion,
                        partida_registral: selectedFile
                            ? jsonCancelacion.data.data.partida_registral
                            : producto.data.partida_registral,
                    },
                });
            }
            setModal(false);
        } else if (tipo_producto === "embarcacion") {
            form.append("cancelacion", producto.data.cancelacion);
            form.append(
                "porcentaje_moderado",
                producto.data.porcentaje_moderado
            );
            form.append("imagen_partida_embarcacion", selectedFile);
            const jsonCancelacion = await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacion/actualizarCancelacion/${producto.data.id}`,
                form,
                { headers }
            );

            console.log(jsonCancelacion);

            if (jsonCancelacion.data.message === "actuaizacion exitoso") {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        cancelacion: jsonCancelacion.data.data.cancelacion,
                        partida_registral: selectedFile
                            ? jsonCancelacion.data.data.partida_registral
                            : producto.data.partida_registral,
                    },
                });
            }
            setModal(false);
        }
    };

    const handleGuardar = () => {
        updateProduct();
    };

    return (
        <div className="p-4 mt-8 border rounded-lg">
            <h1>Partida registral</h1>

            <div className="mt-4">
                {producto.data.partida_registral ? (
                    <div className="relative">
                        <input
                            onChange={handleChangeImage}
                            type="file"
                            accept="image/*"
                            className="absolute left-0 z-50 w-full h-full bg-red-200 rounded-full opacity-0 cursor-pointer select-none file:cursor-pointer"
                        />
                        {preview ? (
                            <img
                                src={preview}
                                className="mt-3 relative flex  items-center justify-center min-h-[13rem] border border-[#E9E9E9]  rounded-lg hover:cursor-pointer"
                                alt="foto de perfil"
                            />
                        ) : (
                            <img
                                src={`${process.env.REACT_APP_PROXY}/casa/getPartidaCasa/${producto.data.partida_registral}`}
                                className="mt-3 relative flex  items-center justify-center min-h-[13rem] border border-[#E9E9E9]  rounded-lg hover:cursor-pointer"
                                alt="foto de perfil"
                            />
                        )}
                    </div>
                ) : (
                    <div>
                        <span>Aún no posee partida registral</span>
                        <div className="mt-3 relative flex  items-center justify-center min-h-[13rem] border border-[#E9E9E9]  rounded-lg hover:cursor-pointer">
                            <div className="absolute">
                                <div className="flex flex-col w-full mx-auto ">
                                    <div className="relative">
                                        <span className="block text-base font-bold text-center ">
                                            Arrastra la foto aquí
                                        </span>
                                        <span className="block text-sm text-center ">
                                            Agrega una foto llamativa
                                        </span>
                                        <span className="block mt-8 font-normal text-center  text-blue-bluecorner">
                                            sube la foto desde tu dispositivo
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <input
                                accept="image/*"
                                type="file"
                                name="icono_si"
                                className="w-full h-full opacity-0 min-h-[13rem] cursor-pointer "
                                onChange={handleChangeImage}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex justify-end">
                <button
                    disabled={updating}
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner active:translate-y-0.5 text-blue-bluecorner p-3 px-7 my-6 rounded-lg min-w-[107.95px]">
                    {updating ? <Spinner width={6} /> : "Guardar"}
                </button>
            </div>
        </div>
    );
};

export default EditarPartidaRegistral;
