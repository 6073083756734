const paramsURLhouses = (filtros, home = false) => {
    return `query_tipo=${home ? "" : filtros.query_tipo}&query_value=${
        filtros.query_value
    }&fecha_final=${home ? "" : filtros.fecha_final}&fecha_inicio=${
        home ? "" : filtros.fecha_inicio
    }&numero_banos=${home ? 0 : filtros.numero_banos}&numero_camas=${
        home ? 0 : filtros.numero_camas
    }&numero_habitaciones=${
        home ? 0 : filtros.numero_habitaciones
    }&numero_personas=${home ? 0 : filtros.numero_personas}&id=${
        filtros.id_usuario ? filtros.id_usuario : 0
    }&pagina=${home ? 1 : filtros.pagina}&tipo_alojamiento=${
        filtros.tipo_alojamiento
    }&atributos=${home ? "" : filtros.atributos}&reglas=${
        home ? "" : filtros.reglas
    }&ubicacion=${home ? "" : filtros.ubicacion}&precio_rango=${
        home ? 0 : filtros.precio_rango
    }&tipo_moneda=${home ? "" : filtros.tipo_moneda}&order=${
        filtros.order
    }&mayor_menor=${home ? "" : filtros.mayor_menor}&latitud=${
        home ? 0 : filtros.latitud
    }&longitud=${home ? 0 : filtros.longitud}&zoom=${
        home ? 10 : filtros.zoom
    }&numero_noches=${home ? 0 : filtros.numero_noches}`;
};

export default paramsURLhouses;
