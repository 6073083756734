import React, { useState, useEffect } from "react";

import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";

// import SearchBar from "./SearchBar";
import { useNavigate, useParams } from "react-router-dom";

import { NavbarMobileEmpresasNew } from "../NavbarMobileEmpresasNew";
import Spinner from "../../Spinner";
import { NavbarEmpresasNew } from "../NavbarEmpresasNew";
import { useSelector } from "react-redux";
// import ModalUsuariosCasa from "./ModalUsuariosCasa";

const AlquileresTotales = () => {
    const { headers } = useHeaders();

    const [comforts, setComforts] = useState([]);
    const [comfortsTotal, setComfortsTotal] = useState(0);
    const [enabled, setenabled] = useState(true);
    const [pageNumber, setpageNumber] = useState(1);
    const [create, setcreate] = useState(false);
    const [modalEnabled, setModalEnabled] = useState(false);
    const [cargando, setCargando] = useState(false);
    const COMFORTS_PER_PAGE = 20;
    const [modalUsuarioVisible, setModalUsuarioVisible] = useState(false);
    const navigate = useNavigate();
    const [mes, setMes] = useState(new Date().getMonth() + 1);
    const [ano, setAno] = useState(new Date().getFullYear());
    const [verMas, setVerMas] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [selectedNoches, setSelectedNoches] = useState(null);
    const [selectedDescuento, setSelectedNDescuento] = useState(null);

    const authUser = useSelector((state) => state.authReducer);
    const months = [
        { name: "Enero", value: 1 },
        { name: "Febrero", value: 2 },
        { name: "Marzo", value: 3 },
        { name: "Abril", value: 4 },
        { name: "Mayo", value: 5 },
        { name: "Junio", value: 6 },
        { name: "Julio", value: 7 },
        { name: "Agosto", value: 8 },
        { name: "Septiembre", value: 9 },
        { name: "Octubre", value: 10 },
        { name: "Noviembre", value: 11 },
        { name: "Diciembre", value: 12 },
    ];

    const getPedidos = async ({ pagina, habilitado, query }) => {
        try {
            setCargando(false);
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/estadisticasEmpresas/allEstadisticas/`,
                {
                    mes: mes,
                    ano: ano,
                    id_empresa: authUser.id_empresa,
                },
                { headers }
            );
            console.log(data);
            setComforts(data);
            setCargando(true);
        } catch (error) {
            console.log(error.response);
        }
    };

    const handleAccordionToggle = (index) => {
        if (selectedReservation === index) {
            setSelectedReservation(null);
        } else {
            setSelectedReservation(index);
        }
    };

    const handleAccordionToggleNoches = (index) => {
        if (selectedNoches === index) {
            setSelectedNoches(null);
        } else {
            setSelectedNoches(index);
        }
    };

    const handleAccordionToggleDescuento = (index) => {
        if (selectedDescuento === index) {
            setSelectedNDescuento(null);
        } else {
            setSelectedNDescuento(index);
        }
    };

    useEffect(() => {
        getPedidos({ pagina: pageNumber, habilitado: enabled, query: "" });
    }, [enabled, create, mes, ano]);

    function formatearNumero(numero) {
        return numero
            .toLocaleString("es-ES")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return (
        <div>
            <div className="absolute z-[999] flex items-center justify-center w-full text-center"></div>
            <NavbarEmpresasNew>
                <div className="w-full">
                    <div className="flex items-center justify-between w-full px-6 mx-auto ">
                        <h1 className="text-4xl  py-10 text-center text-[#3C3C3C]">
                            Estadisticas
                        </h1>
                    </div>
                    <div className="flex items-center justify-end py-10 pr-6 mx-auto sm:px-6">
                        <div
                            onMouseOut={() => setModalEnabled(false)}
                            onMouseOver={() => setModalEnabled(true)}
                            className="relative ml-2">
                            <select
                                onChange={(e) => setMes(Number(e.target.value))}
                                name={"ano"}
                                value={mes}
                                className="w-full p-2 py-[9px] mt-1 border rounded-xl">
                                {months.map((mes) => {
                                    return (
                                        <option
                                            key={mes.name}
                                            className="bg-transparent"
                                            value={mes.value}>
                                            {" "}
                                            {mes.name}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="relative mx-2">
                            <select
                                onChange={(e) => setAno(Number(e.target.value))}
                                name={"ano"}
                                value={ano}
                                className="w-full p-2 py-[9px] mt-1 border rounded-xl">
                                <option className="bg-transparent" value="2023">
                                    {" "}
                                    2023{" "}
                                </option>
                                <option className="bg-transparent" value="2024">
                                    {" "}
                                    2024{" "}
                                </option>
                                <option className="bg-transparent" value="2025">
                                    {" "}
                                    2025{" "}
                                </option>
                            </select>
                        </div>
                    </div>
                    <section
                        style={{
                            backgroundColor: `${authUser.color_primario}4D`, // Aquí establecemos la opacidad (en este caso, 4D representa un 30% de opacidad)
                            border: `2px solid ${authUser.color_primario}`,
                        }}
                        className="flex justify-center items-center lg:py-6 mx-4 border bg-opacity-3 rounded-lg mb-10 py-4">
                        <div className="w-full px-2 xs:px-11">
                            <div className="flex flex-col">
                                {cargando ? (
                                    <>
                                        <div className={`w-full `}>
                                            {/* Mostrar total de reservas */}
                                            <div className="flex justify-between mb-4 px-2 border-b-2 pb-2 text-lg font-semibold">
                                                <p>Alquileres totales:</p>
                                                <p>{comforts.total_reservas}</p>
                                            </div>
                                            {/* Mostrar lista de clientes */}
                                            {comforts.reservas_cliente.map(
                                                (reserva, index) => (
                                                    <div
                                                        key={reserva.id_cliente}
                                                        className="mb-4">
                                                        {/* Mostrar nombre y apellido del cliente */}
                                                        <div
                                                            onClick={() =>
                                                                handleAccordionToggle(
                                                                    index
                                                                )
                                                            }
                                                            className={`cursor-pointer flex justify-between px-2 border rounded-lg items-center py-2 font-medium bg-white ${
                                                                selectedReservation ===
                                                                    index &&
                                                                "bg-slate-100"
                                                            }`}>
                                                            <h1 className="mx-3 text-[#3C3C3C] text-base">
                                                                {`${reserva.cliente_nombre} ${reserva.cliente_apellido}`}
                                                            </h1>

                                                            {/* Mostrar total de pedidos */}
                                                            <div className="flex items-center ">
                                                                <p className="text-[#3C3C3C] text-base">
                                                                    Reservas{" "}
                                                                    {
                                                                        reserva.total_pedidos
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>

                                                        {/* Mostrar detalle de pedidos si está seleccionado */}
                                                        {selectedReservation ===
                                                            index && (
                                                            <div className="my-2 ">
                                                                <ul>
                                                                    {reserva.pedidos.map(
                                                                        (
                                                                            pedido,
                                                                            i
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className="mb-4 flex justify-between items-center  px-2 border rounded-lg py-1 bg-slate-50">
                                                                                {/* Mostrar imagen y nombre de la propiedad */}
                                                                                <div className="flex items-center gap-2">
                                                                                    <img
                                                                                        src={`${
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_PROXY_ARCHIVOS
                                                                                        }/bluecorner/v1/${
                                                                                            pedido.topo ===
                                                                                            "casa"
                                                                                                ? "casa"
                                                                                                : "embarcacion"
                                                                                        }/getImagenPrincipal/${
                                                                                            pedido.imagen_principal_minificada
                                                                                        }`}
                                                                                        alt={
                                                                                            pedido.nombre
                                                                                        }
                                                                                        className="object-cover h-8 rounded-lg w-10"
                                                                                    />

                                                                                    <p className="text-[#3C3C3C] text-base">
                                                                                        {
                                                                                            pedido.nombre
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                {/* Mostrar costo total de alquiler */}
                                                                                <div className="flex items-center mt-2">
                                                                                    <p className="text-[#3C3C3C] text-base">
                                                                                        Alquiler
                                                                                        total:{" "}
                                                                                        {
                                                                                            pedido.tipo_moneda
                                                                                        }
                                                                                        {formatearNumero(
                                                                                            pedido.costo_total_real_alquiler
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className={`w-full `}>
                                            {/* Mostrar total de reservas */}
                                            <div className="flex justify-between mb-4 px-2 border-b-2 pb-2 text-lg font-semibold">
                                                <p>Noches totales:</p>
                                                <p>{comforts.total_noches}</p>
                                            </div>
                                            {/* Mostrar lista de clientes */}
                                            {comforts.reservas_cliente.map(
                                                (reserva, index) => (
                                                    <div
                                                        key={reserva.id_cliente}
                                                        className="mb-4">
                                                        {/* Mostrar nombre y apellido del cliente */}
                                                        <div
                                                            onClick={() =>
                                                                handleAccordionToggleNoches(
                                                                    index
                                                                )
                                                            }
                                                            className={`cursor-pointer flex justify-between px-2 border rounded-lg items-center py-2 font-medium bg-white ${
                                                                selectedNoches ===
                                                                    index &&
                                                                "bg-slate-100"
                                                            }`}>
                                                            <h1 className="mx-3 text-[#3C3C3C] text-base">
                                                                {`${reserva.cliente_nombre} ${reserva.cliente_apellido}`}
                                                            </h1>

                                                            {/* Mostrar total de pedidos */}
                                                            <div className="flex items-center ">
                                                                <p className="text-[#3C3C3C] text-base">
                                                                    Noches{" "}
                                                                    {
                                                                        reserva.total_noches_personal
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>

                                                        {/* Mostrar detalle de pedidos si está seleccionado */}
                                                        {selectedNoches ===
                                                            index && (
                                                            <div className="my-2 ">
                                                                <ul>
                                                                    {reserva.pedidos.map(
                                                                        (
                                                                            pedido,
                                                                            i
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className="mb-4 flex justify-between items-center  px-2 border rounded-lg py-1 bg-slate-50">
                                                                                {/* Mostrar imagen y nombre de la propiedad */}
                                                                                <div className="flex items-center gap-2">
                                                                                    <img
                                                                                        src={`${
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_PROXY_ARCHIVOS
                                                                                        }/bluecorner/v1/${
                                                                                            pedido.topo ===
                                                                                            "casa"
                                                                                                ? "casa"
                                                                                                : "embarcacion"
                                                                                        }/getImagenPrincipal/${
                                                                                            pedido.imagen_principal_minificada
                                                                                        }`}
                                                                                        alt={
                                                                                            pedido.nombre
                                                                                        }
                                                                                        className="object-cover h-8 rounded-lg w-10"
                                                                                    />

                                                                                    <p className="text-[#3C3C3C] text-base">
                                                                                        {
                                                                                            pedido.nombre
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                {/* Mostrar costo total de alquiler */}
                                                                                <div className="flex items-center mt-2">
                                                                                    <p className="text-[#3C3C3C] text-base">
                                                                                        Noches:{" "}
                                                                                        {formatearNumero(
                                                                                            pedido.noches
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>

                                        <div className="w-full">
                                            {/* ... otros códigos ... */}
                                            <div className="flex justify-between mb-4 px-2 border-b-2 pb-2 text-lg font-semibold">
                                                <p>Descuentos totales:</p>
                                                <p>
                                                    PEN{" "}
                                                    {formatearNumero(
                                                        comforts.total_descuento
                                                    )}
                                                </p>
                                            </div>
                                            {comforts.reservas_cliente.map(
                                                (reserva, index) => (
                                                    <div
                                                        key={reserva.id_cliente}
                                                        className="mb-4">
                                                        <div
                                                            onClick={() =>
                                                                handleAccordionToggleDescuento(
                                                                    index
                                                                )
                                                            }
                                                            className={`cursor-pointer flex justify-between px-2 border rounded-lg items-center py-2 font-medium bg-white ${
                                                                selectedNoches ===
                                                                    index &&
                                                                "bg-slate-100"
                                                            }`}>
                                                            <h1 className="mx-3 text-[#3C3C3C] text-base">
                                                                {`${reserva.cliente_nombre} ${reserva.cliente_apellido}`}
                                                            </h1>
                                                            <div className="flex items-center">
                                                                <p className="text-[#3C3C3C] text-base">
                                                                    Descuento
                                                                    otorgado{" "}
                                                                    {formatearNumero(
                                                                        reserva.total_descuento_personal
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {selectedDescuento ===
                                                            index && (
                                                            <div className="my-2">
                                                                <table className="w-full border-collapse border border-gray-300">
                                                                    <thead>
                                                                        <tr className="bg-slate-50">
                                                                            <th className="px-2 py-1 text-[#3C3C3C] text-left">
                                                                                Propiedad
                                                                            </th>
                                                                            <th className="px-2 py-1 text-[#3C3C3C] text-left">
                                                                                Alquiler
                                                                                Real
                                                                            </th>
                                                                            <th className="px-2 py-1 text-[#3C3C3C] text-left">
                                                                                Alquiler
                                                                                con
                                                                                Descuento
                                                                            </th>
                                                                            <th className="px-2 py-1 text-[#3C3C3C] text-left">
                                                                                Descuento
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {reserva.pedidos.map(
                                                                            (
                                                                                pedido,
                                                                                i
                                                                            ) => (
                                                                                <tr
                                                                                    key={
                                                                                        i
                                                                                    }
                                                                                    className="bg-slate-50">
                                                                                    <td className="px-2 py-1 text-[#3C3C3C] text-base">
                                                                                        <div className="flex items-center gap-2">
                                                                                            <img
                                                                                                src={`${
                                                                                                    process
                                                                                                        .env
                                                                                                        .REACT_APP_PROXY_ARCHIVOS
                                                                                                }/bluecorner/v1/${
                                                                                                    pedido.tipo ===
                                                                                                    "casa"
                                                                                                        ? "casa"
                                                                                                        : "embarcacion"
                                                                                                }/getImagenPrincipal/${
                                                                                                    pedido.imagen_principal_minificada
                                                                                                }`}
                                                                                                alt={
                                                                                                    pedido.nombre
                                                                                                }
                                                                                                className="object-cover h-8 rounded-lg w-10"
                                                                                            />
                                                                                            <p>
                                                                                                {
                                                                                                    pedido.nombre
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="px-2 py-1 text-[#3C3C3C] text-base">
                                                                                        {
                                                                                            pedido.tipo_moneda
                                                                                        }{" "}
                                                                                        {formatearNumero(
                                                                                            pedido.costo_total_real_alquiler
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="px-2 py-1 text-[#3C3C3C] text-base">
                                                                                        {
                                                                                            pedido.tipo_moneda
                                                                                        }{" "}
                                                                                        {formatearNumero(
                                                                                            pedido.costo_total_alquiler
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="px-2 py-1 text-[#3C3C3C] text-base">
                                                                                        {
                                                                                            pedido.tipo_moneda
                                                                                        }{" "}
                                                                                        {formatearNumero(
                                                                                            pedido.descuento_empresa
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>

                                        <div className="w-full">
                                            <div className="flex justify-between mb-4 px-2 border-b-2 pb-2 text-lg font-semibold">
                                                <p>Tipo de casas:</p>
                                            </div>

                                            <div className="flex items-center gap-4 justify-center ">
                                                <div className=" w-1/2 bg-slate-50 rounded-lg max-w-md py-3">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Playa
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_playa
                                                                        .reservas
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_playa
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-3 w-1/2 bg-slate-50 rounded-lg max-w-md">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Campo
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_campo
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_campo
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full mt-2">
                                            <div className="flex justify-between mb-4 px-2 border-b-2 pb-2 text-lg font-semibold">
                                                <p>Zona y lugar:</p>
                                            </div>

                                            <div className="flex items-center gap-4 justify-center ">
                                                <div className=" w-1/2 bg-slate-50 rounded-lg max-w-md py-3">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Lima
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_lima
                                                                        .reservas
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_lima
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-3 w-1/2 bg-slate-50 rounded-lg max-w-md">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Asia
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_asia
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_asia
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-4 justify-center  mt-2">
                                                <div className=" w-1/2 bg-slate-50 rounded-lg max-w-md py-3">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Centro
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_centro
                                                                        .reservas
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_centro
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-3 w-1/2 bg-slate-50 rounded-lg max-w-md">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Norte
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_norte
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_norte
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-4 justify-center  mt-2">
                                                <div className=" w-1/2 bg-slate-50 rounded-lg max-w-md py-3">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Sur
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_sur
                                                                        .reservas
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_sur
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-3 w-1/2 bg-slate-50 rounded-lg max-w-md">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Sur chico
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_sur_chico
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_sur_chico
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-4 justify-center  mt-2">
                                                <div className=" w-1/2 bg-slate-50 rounded-lg max-w-md py-3">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Selva
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_selva
                                                                        .reservas
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts
                                                                        .total_selva
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-3 w-1/2 bg-slate-50 rounded-lg max-w-md">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Sierra
                                                        </p>
                                                    </div>
                                                    <div className="grid grid-cols-2 text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_sierra
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Noches:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts
                                                                        .total_sierra
                                                                        .noches
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full">
                                            <div className="flex justify-between mb-4 px-2 border-b-2 pb-2 text-lg font-semibold mt-2">
                                                <p>Fechas especiales:</p>
                                            </div>

                                            <div className="flex items-center gap-4 justify-center ">
                                                <div className=" w-1/2 bg-slate-50 rounded-lg max-w-md py-3">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Año nuevo
                                                        </p>
                                                    </div>
                                                    <div className="grid  text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {
                                                                    comforts.total_ano_nuevo
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-3 w-1/2 bg-slate-50 rounded-lg max-w-md">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Navidad
                                                        </p>
                                                    </div>
                                                    <div className="grid  text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts.total_navidad
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-3 w-1/2 bg-slate-50 rounded-lg max-w-md">
                                                    <div>
                                                        <p className="text-center font-semibold text-lg">
                                                            Fiestas patrias
                                                        </p>
                                                    </div>
                                                    <div className="grid  text-center">
                                                        <div>
                                                            {" "}
                                                            <p className=" font-medium">
                                                                Reservas:
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    comforts.total_fiestas_patrias
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full mt-2">
                                            <div className="flex justify-between mb-4 px-2 border-b-2 pb-2 text-lg font-semibold">
                                                <p>Top colaboradores:</p>
                                            </div>
                                            {comforts.top_clientes.map(
                                                (reserva, index) => (
                                                    <div
                                                        key={reserva.id_cliente}
                                                        className="mb-4">
                                                        <div
                                                            className={`flex justify-between px-2 border rounded-lg items-center py-2 font-medium bg-white ${
                                                                selectedNoches ===
                                                                    index &&
                                                                "bg-slate-100"
                                                            }`}>
                                                            <h1 className="mx-3 text-[#3C3C3C] text-base">
                                                                {`${reserva.cliente_nombre} ${reserva.cliente_apellido}`}
                                                            </h1>
                                                            <div className="flex items-center">
                                                                <p className="text-[#3C3C3C] text-base">
                                                                    Reservas{" "}
                                                                    {
                                                                        reserva.total_pedidos
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <Spinner />
                                )}
                            </div>
                        </div>
                    </section>
                </div>
                <NavbarMobileEmpresasNew />
            </NavbarEmpresasNew>
        </div>
    );
};

export default AlquileresTotales;
