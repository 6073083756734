import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CardSubPropietario from "./CardSubPropietario";

const Modal3buttons = ({ user, getAllPropietarios, setModal3Buttons }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [administradores, setAdministradores] = useState([]);
    const [subPropietarios, setSubPropietarios] = useState(false);
    const [datosBancarios, setDatosBancarios] = useState(false);
    const [input, setInput] = useState({
        nombre_cuenta: user.nombre_cuenta || "",
        numero_cuenta: user.numero_cuenta || "",
        nombre_banco: user.nombre_banco || "",
        dni_cuenta: user.dni_cuenta || "",
        tipo_cuenta: user.tipo_cuenta || "",
    });

    const bancos = [
        "BBVA",
        "Banco de Comercio",
        "Banco de Crédito del Perú",
        "Banco Interamericano de Finanzas (BanBif)",
        "Banco Pichincha",
        "Citibank Perú",
        "Interbank",
        "MiBanco",
        "Scotiabank Perú",
        "Banco GNB Perú",
        "Banco Falabella",
        "Banco Ripley",
        "Banco Santander Perú",
        "Alfin Banco",
        "Bank of China",
        "ICBC PERU BANK",
    ];

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const getAllSubPropietario = async (propietario) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/subpropietario/all/${
                true ? "Si" : "No"
            }/${propietario.id}/`,
            { headers }
        );
        console.log(data);
        setAdministradores(data);
    };

    const handleUpdateDatosBancarios = async (e) => {
        e.preventDefault();
        if (input.dni_cuenta !== "") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/propietario/actualizarBanco/${user.id}`,
                input,
                { headers }
            );
            console.log(data);
            if (data.message === "actuaizacion exitoso") {
                Swal.fire({
                    title: "Detalles bancarios actualizados correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                getAllPropietarios();
                setModal3Buttons((prev) => !prev);
            } else {
                Swal.fire({
                    title: "Error intente nuevamente",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        } else {
            Swal.fire({
                title: "El campo dni de la cuenta no puede estar vacio",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    const handleTienda = () => {
        navigate(`/tienda/${user.id}/1`);
    };

    const handleClose = () => {
        setModal3Buttons((prev) => !prev);
    };

    return (
        <div className={`relative items-center justify-center `}>
            <div className="fixed top-0 z-20 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-24">
                    <div className="relative flex flex-col  justify-center px-4 py-16 bg-white rounded-lg w-96 md:w-5/12 dark:bg-white md:px-24 xl:py-12 xl:px-24">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>

                        {subPropietarios && (
                            <div>
                                <header className="mb-3 text-lg font-bold">
                                    Sub propietarios de {user.nombre}
                                </header>
                                {administradores.length > 0 ? (
                                    administradores.map((admin) => (
                                        <CardSubPropietario
                                            key={admin.id}
                                            admin={admin}
                                        />
                                    ))
                                ) : (
                                    <h2 className="text-center mt-4">
                                        {user.nombre} aún no ha asignado
                                        subpropietarios
                                    </h2>
                                )}
                            </div>
                        )}

                        {datosBancarios && (
                            <div>
                                <header className="mb-3 text-lg font-bold">
                                    Editar detalles bancarios
                                </header>
                                <div className="flex items-center justify-between mt-2">
                                    <label className="w-1/2 p-2 font-semibold">
                                        Documento del titular de cuenta
                                    </label>
                                    <div className="w-1/2">
                                        <input
                                            type="text"
                                            name="dni_cuenta"
                                            value={input.dni_cuenta}
                                            onChange={handleInputChange}
                                            placeholder="Nuevo Documento"
                                            className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center  mt-2">
                                    <div className="w-1/2 ">
                                        <label className="p-2 font-semibold">
                                            Nombre del Banco
                                        </label>
                                    </div>
                                    <div className="w-1/2">
                                        <select
                                            name="nombre_banco"
                                            value={input.nombre_banco}
                                            onChange={handleInputChange}
                                            className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none">
                                            <option
                                                className="bg-transparent"
                                                value="">
                                                {" "}
                                                Seleccionar{" "}
                                            </option>
                                            {bancos.map((banco, i) => {
                                                return (
                                                    <option
                                                        key={i}
                                                        className="bg-transparent"
                                                        value={banco}>
                                                        {" "}
                                                        {banco}{" "}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mt-2">
                                    <label className="w-1/2 p-2 font-semibold">
                                        Nombre de la cuenta
                                    </label>
                                    <div className="w-1/2">
                                        <input
                                            type="text"
                                            name="nombre_cuenta"
                                            value={input.nombre_cuenta}
                                            onChange={handleInputChange}
                                            placeholder="Nuevo nombre de cuenta"
                                            className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mt-2">
                                    <label className="w-1/2 p-2 font-semibold">
                                        Numero de la cuenta
                                    </label>
                                    <div className="w-1/2">
                                        <input
                                            type="text"
                                            name="numero_cuenta"
                                            value={input.numero_cuenta}
                                            onChange={handleInputChange}
                                            placeholder="Nuevo numero de cuenta"
                                            className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mt-2">
                                    <label className="w-1/2 p-2 font-semibold">
                                        Tipo de cuenta
                                    </label>
                                    <div className="w-1/2">
                                        <input
                                            type="text"
                                            name="tipo_cuenta"
                                            value={input.tipo_cuenta}
                                            onChange={handleInputChange}
                                            placeholder="Nuevo tipo de cuenta"
                                            className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <button
                                    className="w-full rounded-md mt-5  bg-blue-bluecorner py-3 font-bold text-white active:translate-y-[0.125rem] hover:scale-105"
                                    onClick={handleUpdateDatosBancarios}>
                                    Guardar
                                </button>
                            </div>
                        )}

                        {!subPropietarios && !datosBancarios && (
                            <div>
                                <header className="mb-3 text-xl font-bold mr-auto">
                                    ¿Qué desea hacer?
                                </header>
                                <button
                                    className="w-full rounded-md border border-blue-bluecorner py-3 text-blue-bluecorner  mt-4 hover:scale-105 active:translate-y-[0.125rem]"
                                    onClick={() => {
                                        getAllSubPropietario(user);
                                        setSubPropietarios((prev) => !prev);
                                    }}>
                                    Ver sub propietarios
                                </button>
                                <button
                                    className="w-full rounded-md border border-blue-bluecorner py-3 text-blue-bluecorner  mt-4 hover:scale-105 active:translate-y-[0.125rem]"
                                    onClick={() =>
                                        setDatosBancarios((prev) => !prev)
                                    }>
                                    Ver datos bancarios
                                </button>
                                <button
                                    className="w-full rounded-md border border-blue-bluecorner py-3 text-blue-bluecorner  mt-4 hover:scale-105 active:translate-y-[0.125rem]"
                                    onClick={handleTienda}>
                                    Visitar tienda del propietario
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal3buttons;
