import React, { useState } from "react";

import BoatsAll from "./BoatsAll";

import ProductName from "./onboarding 1/ProductName";
import ProductType from "./onboarding 1/ProductType";
import BoatsDuration from "./onboarding 1/BoatsDuration";
import ProductDescription from "./onboarding 1/ProductDescription";
import ProductLabels from "./onboarding 2/ProductLabels";
import ProductPeople from "./onboarding 2/ProductPeople";

import ProductUbication from "./onboarding 3/ProductUbication";

import ExtraRules from "./onboarding 4/ExtraRules";
import CancellationPolicy from "./onboarding 4/CancellationPolicy";
import ProductImages from "./onboarding 4/ProductImages";

import AditionalServices from "./onboarding 5/AditionalServices";

import RevisaAnuncio from "./onboarding 6/RevisaAnuncio";
import VariablePricesBoats from "./onboarding 5/VariablePricesBoats";
import DaysAvailableBoats from "./onboarding 5/DaysAvailableBoats";
import PriceCalendarTimeline from "./onboarding 6/priceCalendar/PriceCalendarTimeline";
import PriceCalendar from "./onboarding 6/priceCalendar/PriceCalendar";
const Boats = ({ page, setPage, boatsAlll, setBoatsAll }) => {
    const [onboarding, setOnboarding] = useState({
        id_propietario: null,
        id: null,
        tipo_producto: "embarcacion",
        id_tipo_alojamiento: "",
        id_tipo_embarcacion: "",
        duracion: [],
        nombre: "",
        descripcion: "",
        link360: "",
        precio_base: null,
        tipo_moneda: "PEN",
        numero_personas: 1,

        etiquetas: [],
        reglas: "",
        extra: "",
        cancelacion: "",
        tipo_contrato: [],
        imagen_principal: "",
        banner: "",
        imagenes: [],
        alquiler_minimo: 1,
        roomAttribute: {},
        servicios_adicionales: [],
    });

    return (
        <div className="w-full">
            <h1
                className={
                    boatsAlll
                        ? "text-6xl font-bold py-10 text-center"
                        : "hidden"
                }
            >
                Embarcaciones
            </h1>
            {boatsAlll && (
                <BoatsAll
                    boatsAlll={boatsAlll}
                    setBoatsAll={setBoatsAll}
                    onboarding={onboarding}
                    setOnboarding={setOnboarding}
                    page={page}
                    setPage={setPage}
                />
            )}
            {/* <div>{page === 1 && !boatsAlll && <PriceCalendarTimeline />}</div> */}

            <div>
                {page === 1 && !boatsAlll && (
                    <ProductName
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setBoatsAll}
                    />
                )}
            </div>
            <div>
                {page === 2 && !boatsAlll && (
                    <ProductDescription
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
            <div>
                {page === 3 && !boatsAlll && (
                    <ProductType
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>

            <div>
                {page === 4 && !boatsAlll && (
                    <ProductLabels
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
            <div>
                {page === 5 && !boatsAlll && (
                    <ProductPeople
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
            <div>
                {page === 6 && !boatsAlll && (
                    <BoatsDuration
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>

            <div>
                {page === 7 && !boatsAlll && (
                    <ProductUbication
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>

            <div>
                {page === 8 && !boatsAlll && (
                    <ExtraRules
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
            <div>
                {page === 9 && !boatsAlll && (
                    <CancellationPolicy
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
            <div>
                {page === 10 && !boatsAlll && (
                    <ProductImages
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>

            <div>
                {page === 11 && !boatsAlll && (
                    <AditionalServices
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
            {/* <div>
                {page === 12 && !boatsAlll && (
                    <VariablePricesBoats
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div> */}
            <div>
                {page === 12 && !boatsAlll && (
                    <PriceCalendar
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setBoatsAll}
                    />
                )}
            </div>
            <div>
                {page === 13 && !boatsAlll && (
                    <DaysAvailableBoats
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
            <div>
                {page === 14 && !boatsAlll && (
                    <RevisaAnuncio
                        boatsAlll={boatsAlll}
                        setBoatsAll={setBoatsAll}
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
        </div>
    );
};

export default Boats;
