import React, { useState } from "react";

import HousesAll from "./HousesAll";
import ProductName from "./onboarding 1/ProductName";
import ProductType from "./onboarding 1/ProductType";
import ProductDuration from "./onboarding 1/ProductDuration";
import ProductPeople from "./onboarding 2/ProductPeople";
import ProductUbication from "./onboarding 3/ProductUbication";
import AttributesCapacity from "./onboarding 3/AttributesCapacity";
import AttributesRules from "./onboarding 3/AttributesRules";
import CancellationPolicy from "./onboarding 4/CancellationPolicy";
import ProductImages from "./onboarding 4/ProductImages";
import Pricing from "./onboarding 5/Pricing";
import VariableNights from "./onboarding 6/VariableNights";
import RevisaAnuncio from "./onboarding 6/RevisaAnuncio";
import DaysAvailableBoats from "./onboarding 5/DaysAvailableBoats";
import PriceCalendar from "./onboarding 6/priceCalendar/PriceCalendar";
import OnboardingBienvenida from "./onboarding 1/OnboardingBienvenida";
import Onboarding1 from "./onboarding 1/Onboarding1";
import AnuncioTerminado from "./onboarding 6/AnuncioTerminado";
import BoatsDuration from "./onboarding 1/BoatsDuration";
import PartidaRegistral from "./onboarding 4/PartidaRegistral";
import ProductHabitacionDetalles from "./onboarding 2/ProductHabitacionDetalles";

const Houses = ({ page, setPage, productsAll, setProductsAll }) => {
    const [onboarding, setOnboarding] = useState({
        id_propietario: null,
        id: null,
        tipo_producto: "",
        id_tipo_alojamiento: "",
        id_tipo_embarcacion: "",
        id_duracion: "",
        nombre: "",
        descripcion: "",
        link360: "",
        numero_habitaciones: 1,
        numero_personas: 1,
        numero_habitaciones_servicio: 0,
        numero_mascotas: 0,
        numero_banos: 1,
        etiquetas: [],
        reglas_adicionales: "",
        extra: "",
        cancelacion: "",
        tipo_contrato: [],
        banner: "",
        imagenes: [],
        alquiler_minimo: 1,
        roomAttribute: {},
        servicios_adicionales: [],
        imagen_principal: "",
        tipo_moneda: "PEN",
        tipo_moneda_garantia: "PEN",
        porcentaje_moderado: "",
    });
    console.log("onboarding", onboarding);
    return (
        <div className="">
            {productsAll && (
                <HousesAll
                    productsAll={productsAll}
                    setProductsAll={setProductsAll}
                    onboarding={onboarding}
                    setOnboarding={setOnboarding}
                    page={page}
                    setPage={setPage}
                />
            )}
            <div>
                {page === 0 && !productsAll && (
                    <OnboardingBienvenida
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {page === 1 && !productsAll && (
                    <Onboarding1
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {page === 2 && !productsAll && (
                    <ProductName
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>

            <div>
                {page === 3 && !productsAll && (
                    <ProductType
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {page === 4 &&
                    !productsAll &&
                    onboarding.tipo_producto === "casa" && (
                        <ProductDuration
                            actualPage={page}
                            setPage={setPage}
                            setOnboarding={setOnboarding}
                            onboarding={onboarding}
                            setProductsAll={setProductsAll}
                        />
                    )}
            </div>
            <div>
                {page === 4 &&
                    !productsAll &&
                    onboarding.tipo_producto === "embarcacion" && (
                        <BoatsDuration
                            actualPage={page}
                            setPage={setPage}
                            setOnboarding={setOnboarding}
                            onboarding={onboarding}
                            setProductsAll={setProductsAll}
                        />
                    )}
            </div>
            <div>
                {page === 5 && !productsAll && (
                    <ProductPeople
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {page === 6 && !productsAll && (
                    <ProductHabitacionDetalles
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {page === 7 && !productsAll && (
                    <ProductUbication
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>

            <div>
                {page === 8 && !productsAll && (
                    <ProductImages
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {page === 9 && !productsAll && (
                    <AttributesCapacity
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {page === 10 && !productsAll && (
                    <AttributesRules
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {page === 11 && !productsAll && (
                    <CancellationPolicy
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>

            <div>
                {page === 12 && !productsAll && (
                    <PartidaRegistral
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>

            <div>
                {page === 13 && !productsAll && (
                    <Pricing
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {page === 14 && !productsAll && (
                    <RevisaAnuncio
                        productsAll={productsAll}
                        setProductsAll={setProductsAll}
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
            <div>
                {page === 15 && !productsAll && (
                    <AnuncioTerminado
                        productsAll={productsAll}
                        setProductsAll={setProductsAll}
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div>
            {/* <div>
                {page === 22 && !productsAll && (
                    <ProductDescription
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div> */}

            {/* <div>
                {page === 5 && !productsAll && (
                    <ProductLabels
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div> */}

            {/* <div>
                {page === 7 && !productsAll && (
                    <ProductRoom
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div> */}

            {/* <div>
                {page === 11 && !productsAll && (
                    <ExtraRules
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div> */}

            {/* <div>
                {page === 15 && !productsAll && (
                    <AditionalServices
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div> */}

            {page === -1 && !productsAll && (
                <PriceCalendar
                    setPage={setPage}
                    setOnboarding={setOnboarding}
                    onboarding={onboarding}
                    setProductsAll={setProductsAll}
                />
            )}

            {/* <div>
                {page === 16 && !productsAll && (
                    <VariablePrices
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                    />
                )}
            </div> */}
            <div>
                {page === 17 && !productsAll && (
                    <VariableNights
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
            <div>
                {" "}
                {page === 18 && !productsAll && (
                    <DaysAvailableBoats
                        actualPage={page}
                        setPage={setPage}
                        setOnboarding={setOnboarding}
                        onboarding={onboarding}
                        setProductsAll={setProductsAll}
                    />
                )}
            </div>
        </div>
    );
};

export default Houses;
