import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalCreatePaquete from "./ModalCreatePaquete";
import Swal from "sweetalert2";
import axios from "axios";

import SearchBar from "./SearchBar";

import Spinner from "../../../Spinner";

import { Paginado } from "../../../helpers/Paginado";
import ModalEditPaquete from "./ModalEditPaquete";
import ModalAddImagenes from "./ModalAddImagenes";
import AllServicios from "../servicios/AllServicios";

const AllPaquetes = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [modalCreateVisible, setModalCreateVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [modalImagenes, setModalImagenes] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const [enabled, setenabled] = useState(true);
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [pageNumber, setpageNumber] = useState(1);
    const RULES_PER_PAGE = 25;
    const [showServicios, setShowServicios] = useState(false);
    const [allPaquetes, setAllPaquetes] = useState([]);
    const [paquete, setPaquete] = useState(null);
    const allRules = useSelector((state) => state.rulesReducer.allRules);
    const [checking, setChecking] = useState(true);

    const getPaquetes = async (pagina = 1, habilitado = "Si", input = "") => {
        setChecking(true);
        const { data } = await axios.get(
            `${
                process.env.REACT_APP_PROXY
            }/paquetesEmpresas/all/${pagina}/${habilitado}/${
                showServicios ? "servicio" : "paquete"
            }/?nombre=${input}`,

            { headers }
        );

        setChecking(false);

        setAllPaquetes(data.data);
    };

    useEffect(() => {
        if (!modalCreateVisible && !modalEditVisible) {
            getPaquetes();
        }
    }, [modalCreateVisible, modalEditVisible, showServicios]);

    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el paquete a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const json = await axios.delete(
                    `${process.env.REACT_APP_PROXY}/paquetesempresas/eliminar/${id}`,

                    { headers }
                );

                if (json.data.message === "Registro deshabilitado con exito") {
                    Swal.fire({
                        title: "Paquete eliminado correctamente!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                    getPaquetes(pageNumber, "Si");
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "no se pudo eliminar el paquete",
                        icon: "error",
                        width: "350px",
                        confirmButtonText: "Aceptar",
                    });
                }
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el paquete a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/paquetesempresas/restaurar/${id}`,
                    {},
                    { headers }
                );

                if (json.data.message === "Registro habilitado con exito") {
                    Swal.fire({
                        title: "Paquete habilitado correctamente!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                    getPaquetes(pageNumber, "No");
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "no se pudo eliminar el paquete",
                        icon: "error",
                        width: "350px",
                        confirmButtonText: "Aceptar",
                    });
                }
            }
        });
    };

    const handleEdit = async (paquete) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/paquetesEmpresas/detalle/${paquete.id}`,
                { headers }
            );

            if (data.length > 0) {
                setPaquete({ ...data[0] });
                setModalEditVisible(true);
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "",
                    icon: "error",
                    width: "350px",
                    confirmButtonText: "Aceptar",
                });
            }
        } catch (error) {
            console.log(error.response);
        }
    };

    const pagination = (pageNumber) => {
        setpageNumber(pageNumber);
        getPaquetes(pageNumber, enabled ? "Si" : "No");
    };

    return (
        <div className=" w-full md:pl-[13rem] md818:pl-[14rem] md920:pl-[14rem] lg:pl-[17rem] p-4">
            <div className="flex items-center justify-between w-full mt-4">
                <h1 className="py-5 mx-4 text-3xl lg1170:text-4xl">
                    Paquetes y servicios para empresas
                </h1>
                <div className="mt-2 md:ml-10">
                    <button
                        className="flex items-center justify-center w-8 h-8 p-2 ml-4 text-blue-500 border border-blue-500 rounded-lg md:w-auto md:h-auto group hover:bg-blue-500 hover:text-white"
                        onClick={() =>
                            setModalCreateVisible(!modalCreateVisible)
                        }>
                        <svg
                            className="w-5 h-5 text-blue-500 group-hover:text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <line x1="12" y1="5" x2="12" y2="19" />{" "}
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        <h1 className="hidden md:flex">
                            {" "}
                            {showServicios && "Crea un servicio"}
                            {!showServicios && "Crea un paquete"}
                        </h1>
                    </button>
                </div>
            </div>
            <div className="absolute flex items-center justify-center w-full text-center adsolute">
                {modalCreateVisible && (
                    <ModalCreatePaquete
                        modalVisible={modalCreateVisible}
                        setmodalVisible={setModalCreateVisible}
                        pageNumber={pageNumber}
                        showServicios={showServicios}
                    />
                )}
                {paquete && modalEditVisible && (
                    <ModalEditPaquete
                        setPaquete={setPaquete}
                        paquete={paquete}
                        actualPage={pageNumber}
                        showServicios={showServicios}
                        setModalVisible={setModalEditVisible}
                    />
                )}
                {modalImagenes !== null && (
                    <ModalAddImagenes
                        paquete={modalImagenes}
                        setmodalVisible={setModalImagenes}
                        showServicios={showServicios}
                    />
                )}
            </div>
            <div className="flex flex-col w-full md:flex-row gap-4">
                <div className="flex w-full">
                    <button
                        className={`${
                            showServicios
                                ? "p-3 text-blue-500 border border-blue-500 w-full max-w-[156px] rounded-lg transition-all ease-in-out duration-200"
                                : "p-3 max-w-[156px] text-white bg-blue-500 w-full border-2 rounded-lg transition-all ease-in-out duration-200"
                        }`}
                        onClick={() => setShowServicios(false)}>
                        Paquetes
                    </button>
                    <button
                        className={`${
                            !showServicios
                                ? "p-3 ml-2 text-blue-500 border border-blue-500 w-full max-w-[156px] rounded-lg transition-all ease-in-out duration-200"
                                : "p-3  text-white bg-blue-500 border-2 w-full max-w-[156px] rounded-lg ml-2 transition-all ease-in-out duration-200"
                        }`}
                        onClick={() => setShowServicios(true)}>
                        Servicios
                    </button>
                </div>
                <div className="flex items-center">
                    <SearchBar
                        getPaquetes={getPaquetes}
                        setfakeRefresh={setfakeRefresh}
                        fakeRefresh={fakeRefresh}
                        enabled={enabled}
                    />

                    <button className="relative z-0 flex items-center justify-center w-8 h-8 ml-5 border rounded-lg md:w-11 md:h-11 border-blue-bluecorner">
                        <svg
                            onClick={() => setShowButtons((prev) => !prev)}
                            className="text-blue-500 h-7 w-7 md:h-8 md:w-8"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                            />
                        </svg>
                        {showButtons && (
                            <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                                <div
                                    onClick={() => {
                                        getPaquetes(1, "Si");
                                        setenabled(true);
                                        setShowButtons((prev) => !prev);
                                        setpageNumber(1);
                                    }}
                                    className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                    <p
                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                        Habilitados
                                    </p>
                                </div>
                                <div
                                    onClick={() => {
                                        getPaquetes(1, "No");
                                        setenabled(false);
                                        setShowButtons((prev) => !prev);
                                        setpageNumber(1);
                                    }}
                                    className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                    <p
                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                        Deshabilitados
                                    </p>
                                </div>
                            </div>
                        )}
                    </button>
                </div>
            </div>

            <div className="container flex items-center justify-between p-1 mx-auto">
                <Paginado
                    elementsPerPage={RULES_PER_PAGE}
                    cantTotalElements={allRules.total}
                    pagination={pagination}
                />
            </div>

            {!showServicios ? (
                checking ? (
                    <Spinner />
                ) : (
                    <section className="flex justify-center items-center lg:py-[10px] ">
                        <div className="flex w-full max-w-full overflow-x-auto text-center">
                            {allPaquetes.length > 0 ? (
                                <div className="w-full">
                                    <table className="w-full overflow-x-auto mt-14">
                                        <thead className="text-left">
                                            <tr>
                                                <th className=" md:table-cell px-4 w-full min-w-[8rem] md:w-4/12 ">
                                                    Nombre
                                                </th>
                                                <th className="w-4/12 min-w-[10rem] hidden lg1170:table-cell">
                                                    Descripción
                                                </th>
                                                <th className="w-2/12 min-w-[10rem] hidden md:table-cell">
                                                    Precio
                                                </th>
                                                <th
                                                    align="center"
                                                    className="w-[8%] min-w-[10rem]">
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-left">
                                            {allPaquetes.map((paquete) => (
                                                <tr
                                                    key={paquete.id}
                                                    className="w-full border-t">
                                                    <td className="flex p-5">
                                                        {paquete.nombre}
                                                    </td>
                                                    <td className="hidden lg1170:table-cell">
                                                        {paquete.descripcion}
                                                    </td>
                                                    <td className="hidden md:table-cell">
                                                        {paquete.precio}
                                                    </td>
                                                    <td className="">
                                                        {enabled ? (
                                                            <div className="flex justify-center ml-9">
                                                                <button
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            paquete
                                                                        )
                                                                    }
                                                                    title="Editar"
                                                                    className="flex items-center justify-center w-8 h-8 mr-2 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />
                                                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                        <line
                                                                            x1="16"
                                                                            y1="5"
                                                                            x2="19"
                                                                            y2="8"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                                <button
                                                                    onClick={() =>
                                                                        setModalImagenes(
                                                                            paquete
                                                                        )
                                                                    }
                                                                    title="Imagenes"
                                                                    className="flex items-center justify-center w-8 h-8 mr-2 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        {" "}
                                                                        <rect
                                                                            x="3"
                                                                            y="3"
                                                                            width="18"
                                                                            height="18"
                                                                            rx="2"
                                                                            ry="2"
                                                                        />{" "}
                                                                        <circle
                                                                            cx="8.5"
                                                                            cy="8.5"
                                                                            r="1.5"
                                                                        />{" "}
                                                                        <polyline points="21 15 16 10 5 21" />
                                                                    </svg>
                                                                </button>
                                                                <button
                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            paquete.id
                                                                        )
                                                                    }
                                                                    title="Eliminar">
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        {" "}
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />{" "}
                                                                        <line
                                                                            x1="4"
                                                                            y1="7"
                                                                            x2="20"
                                                                            y2="7"
                                                                        />{" "}
                                                                        <line
                                                                            x1="10"
                                                                            y1="11"
                                                                            x2="10"
                                                                            y2="17"
                                                                        />{" "}
                                                                        <line
                                                                            x1="14"
                                                                            y1="11"
                                                                            x2="14"
                                                                            y2="17"
                                                                        />{" "}
                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div className="flex justify-center ml-9">
                                                                <button
                                                                    onClick={() =>
                                                                        handleEnable(
                                                                            paquete.id
                                                                        )
                                                                    }
                                                                    title="Habilitar"
                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        {" "}
                                                                        <polyline points="17 1 21 5 17 9" />{" "}
                                                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                        <polyline points="7 23 3 19 7 15" />{" "}
                                                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={5}>
                                                    <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                                                    <div className="flex justify-center">
                                                        <Paginado
                                                            actualPage={
                                                                pageNumber
                                                            }
                                                            elementsPerPage={
                                                                RULES_PER_PAGE
                                                            }
                                                            cantTotalElements={
                                                                allRules.total
                                                            }
                                                            pagination={
                                                                pagination
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            ) : (
                                <h1 className="w-full mt-10 font-medium text-center">
                                    No se encontraron paquetes
                                </h1>
                            )}
                        </div>
                    </section>
                )
            ) : null}
            {showServicios && (
                <AllServicios
                    allPaquetes={allPaquetes}
                    getPaquetes={getPaquetes}
                    checking={checking}
                    enabled={enabled}
                    setModalEditVisible={setModalEditVisible}
                    setPaquete={setPaquete}
                    setModalImagenes={setModalImagenes}
                />
            )}
        </div>
    );
};

export default AllPaquetes;
