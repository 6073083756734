import React from "react";
import logo from "../../../img/Home/BLUECORNER_PNG.png";
import background from "../../../img/Comprobar_disponibilidad.png";
import { Link } from "react-router-dom";
import colores from "../../helpers/colores";

const ComprobarDisponibilidad = () => {
    return (
        <div className="flex">
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    filter: "brightness( 100% ) contrast( 113% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
                }}
                className={`w-full bg-[${colores.primario}] h-screen bg-center bg-no-repeat
                bg-cover flex flex-col text-center`}>
                <div className="h-screen bg-[rgba(5,52,101,0.60)]">
                    <div className="w-full">
                        <img
                            src={logo}
                            className="mt-20 lg:h-36 min-h-[3rem] w-full lg:max-w-md"
                        />
                    </div>
                    <h1 className="text-white text-4xl font-bold p-4 lg:text-5xl mt-20">
                        ¡Gracias por tu reserva!
                    </h1>
                    <h1 className="text-white text-xl mt-5 font-bold lg:text-3xl">
                        Nos pondremos en contacto contigo tan pronto podamos
                    </h1>
                    <div className="px-10">
                        <div className="mt-10 bg-neutral-200 flex flex-col py-10 rounded-lg max-w-lg mx-auto lg:mt-28 opacity-95">
                            <h1 className="text-2xl text-gray-600 py-2 px-10 mb-10">
                                Mientras esperas, puedes ver algunos de nuestros
                                alojamientos.
                            </h1>
                            <Link to="/">
                                <span
                                    className={`font-semibold hover:border hover:underline border-gray-400 bg-[${colores.secundario}] text-white p-3 min-w-max mx-auto cursor-pointer rounded-md text-lg`}>
                                    Volver al sitio
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComprobarDisponibilidad;
