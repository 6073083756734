import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import SearchBar from "./SearchBar";
import { Paginado } from "../helpers/Paginado";
import ModalEditCliente from "./ModalEditCliente";
import ModalPasswordCliente from "./ModalPasswordCliente";
import Spinner from "../Spinner";
import puntosDetalles from "../helpers/puntosDetalles";
import ModalHousesCoins from "../ModalHousesCoins";
import ModalLogeo from "../alquilerScreen/alquilerAlojamiento/ModalLogeo";

const AllClientes = () => {
    const [habilitarModal, setHabilitarModal] = useState(false);
    const [modalLogeo, setModalLogeo] = useState(false);

    const [modalPasswordVisible, setModalPasswordVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [showButtons, setShowButtons] = useState(false);
    const [habilitados, setHabilitados] = useState(true);
    const [clienteSelected, setCienteSelected] = useState(null);
    const [modalHousesCoins, setModalHousesCoins] = useState(false);
    const [userPoints, setUserPoints] = useState({});

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const [allClientes, setAllClientes] = useState({
        checking: true,
        clientes: { data: [], total: 0 },
    });
    const USERS_PER_PAGE = 25;

    const getAllClientes = async (pagina = 1, nombre = "") => {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        setAllClientes({
            checking: true,
            clientes: { data: [], total: 0 },
        });
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/cliente/all/${pagina}/${
                habilitados ? "Si" : "No"
            }/?nombre=${nombre}`,
            { headers }
        );

        setAllClientes({
            checking: false,
            clientes: { data: data.data, total: data.total },
        });
    };

    useEffect(() => {
        if (!habilitarModal) {
            getAllClientes();
        }
    }, [habilitados, habilitarModal]);

    const handleDelete = async (id) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el usuario a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                const token = localStorage.getItem("token");
                const headers = {
                    authorization: token,
                };
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/cliente/eliminar/${id}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Usuario eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllClientes();
                    });
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el cliente a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/cliente/restaurar/${id}`,
                        {},
                        { headers }
                    )
                    .then((reponse) => {
                        Swal.fire({
                            title: "Cliente restablecido correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllClientes();
                    });
            }
        });
    };

    const handleEdit = (user) => {
        setCienteSelected(user);
        setModalEditVisible(!modalEditVisible);
    };

    const handleEditPassword = (user) => {
        setCienteSelected(user);
        setModalPasswordVisible(!modalPasswordVisible);
    };

    const pagination = (pagina) => {
        setPage(pagina);
        getAllClientes(pagina);
    };
    const handleModalHouses = async (user) => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/cliente/getReferidos/${user.id_usuario}`,
            { headers }
        );

        await setUserPoints(data);

        setModalHousesCoins(!modalHousesCoins);
    };

    return (
        <section className="w-full p-4 md:mx-auto">
            <div className="absolute w-full ">
                {habilitarModal && (
                    <ModalLogeo
                        modalLogeo={habilitarModal}
                        setModalLogeo={setHabilitarModal}
                        admin={true}
                    />
                )}
            </div>
            <div className="flex items-center justify-between w-full ">
                <h1 className="py-10 text-4xl text-center">Clientes</h1>
                <button
                    onClick={() => setHabilitarModal(true)}
                    className="relative flex items-center justify-center px-2 py-3 ml-5 font-semibold border rounded-lg border-blue-bluecorner hover:bg-blue-bluecorner hover:text-white"
                >
                    Nuevo cliente
                </button>
            </div>

            <div className="flex items-center justify-end w-full p-1 md:mt-0 ">
                <SearchBar getAllClientes={getAllClientes} />
                <button className="relative flex items-center justify-center w-8 h-8 ml-5 border rounded-lg md:w-11 md:h-11 border-blue-bluecorner">
                    <svg
                        onClick={() => setShowButtons((prev) => !prev)}
                        className="text-blue-500 h-7 w-7 md:h-8 md:w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                        />
                    </svg>
                    {showButtons && (
                        <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                            <div
                                onClick={() => {
                                    setHabilitados(true);
                                    setShowButtons((prev) => !prev);
                                    setPage(1);
                                }}
                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11"
                            >
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg"
                                >
                                    Habilitados
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    setHabilitados(false);
                                    setShowButtons((prev) => !prev);
                                    setPage(1);
                                }}
                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11"
                            >
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg"
                                >
                                    Deshabilitados
                                </p>
                            </div>
                        </div>
                    )}
                </button>
            </div>

            {modalEditVisible && (
                <ModalEditCliente
                    setModalEditVisible={setModalEditVisible}
                    modalEditVisible={modalEditVisible}
                    user={clienteSelected}
                    getAllClientes={getAllClientes}
                />
            )}
            {modalPasswordVisible && (
                <ModalPasswordCliente
                    setModalPasswordVisible={setModalPasswordVisible}
                    modalPasswordVisible={modalPasswordVisible}
                    user={clienteSelected}
                />
            )}
            {modalHousesCoins && (
                <>
                    <ModalHousesCoins
                        modalVisible={modalHousesCoins}
                        setModalVisible={setModalHousesCoins}
                        cliente={clienteSelected}
                        userPoints={userPoints}
                    />
                </>
            )}

            <div className=" w-full md:pl-[13rem] md818:pl-[14rem] md920:pl-[14rem] lg:pl-[17rem] p-4">
                <div className="flex w-full max-w-full overflow-x-auto text-center">
                    {allClientes?.checking === false ? (
                        allClientes.clientes.total > 0 ? (
                            <div className="w-full">
                                <table className="w-full overflow-x-auto mt-14">
                                    <thead className="text-left">
                                        <tr>
                                            <th className="px-4 w-full min-w-[8rem] md:w-3/12 ">
                                                Nombre
                                            </th>
                                            <th className="hidden md:table-cell px-4 w-full min-w-[5rem] md:w-2/12 ">
                                                Correo electrónico
                                            </th>
                                            <th className="w-3/12 min-w-[10rem] hidden lg1170:table-cell">
                                                Documento
                                            </th>
                                            <th className="w-3/12 min-w-[10rem] hidden lg:table-cell">
                                                Celular
                                            </th>
                                            <th className="w-3/12 min-w-[6rem] hidden xl:table-cell">
                                                Total referidos
                                            </th>
                                            <th
                                                align="center"
                                                className="w-[8%] min-w-[10rem] "
                                            >
                                                Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-left">
                                        {allClientes?.clientes?.data?.map(
                                            (user) => (
                                                <tr
                                                    key={user.id}
                                                    className={`border-t w-full ${
                                                        habilitados &&
                                                        "cursor-pointer"
                                                    }`}
                                                    onClick={() => {
                                                        habilitados &&
                                                            handleEdit(user);
                                                    }}
                                                >
                                                    <td className="flex p-5">
                                                        {user.nombre +
                                                            " " +
                                                            user.apellido_paterno +
                                                            " "}
                                                        {user.apellido_materno}
                                                    </td>
                                                    <td className="hidden md:table-cell">
                                                        {user.correo}
                                                    </td>
                                                    <td className="hidden lg1170:table-cell">
                                                        {user.dni}
                                                    </td>
                                                    <td className="hidden lg:table-cell">
                                                        {user.celular}
                                                    </td>
                                                    <td className="hidden xl:table-cell">
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setCienteSelected(
                                                                    user
                                                                );
                                                                handleModalHouses(
                                                                    user
                                                                );
                                                            }}
                                                            className="w-20 h-12 p-2 border rounded-lg cursor-pointer border-blue-bluecorner hover:bg-blue-bluecorner hover:text-white active:translate-y-1"
                                                        >
                                                            {Number(
                                                                user.referidos_total_casa
                                                            ) +
                                                                Number(
                                                                    user.referidos_total_embarcacion
                                                                )}
                                                        </button>
                                                    </td>
                                                    <td className="">
                                                        {habilitados ? (
                                                            <div className="flex justify-center ml-9">
                                                                <button
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        handleEdit(
                                                                            user
                                                                        );
                                                                    }}
                                                                    title="Editar"
                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                >
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    >
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />
                                                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                        <line
                                                                            x1="16"
                                                                            y1="5"
                                                                            x2="19"
                                                                            y2="8"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                                <button
                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                    title="Cambiar
                                                                    contraseña"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        handleEditPassword(
                                                                            user
                                                                        );
                                                                    }}
                                                                >
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    >
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />{" "}
                                                                        <rect
                                                                            x="5"
                                                                            y="11"
                                                                            width="14"
                                                                            height="10"
                                                                            rx="2"
                                                                        />{" "}
                                                                        <circle
                                                                            cx="12"
                                                                            cy="16"
                                                                            r="1"
                                                                        />{" "}
                                                                        <path d="M8 11v-5a4 4 0 0 1 8 0" />
                                                                    </svg>
                                                                </button>
                                                                <button
                                                                    className="items-center justify-center hidden w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded md:flex group hover:bg-blue-500 hover:text-white"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        handleDelete(
                                                                            user.id
                                                                        );
                                                                    }}
                                                                    title="Eliminar"
                                                                >
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    >
                                                                        {" "}
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />{" "}
                                                                        <line
                                                                            x1="4"
                                                                            y1="7"
                                                                            x2="20"
                                                                            y2="7"
                                                                        />{" "}
                                                                        <line
                                                                            x1="10"
                                                                            y1="11"
                                                                            x2="10"
                                                                            y2="17"
                                                                        />{" "}
                                                                        <line
                                                                            x1="14"
                                                                            y1="11"
                                                                            x2="14"
                                                                            y2="17"
                                                                        />{" "}
                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div className="flex justify-center ml-5 ">
                                                                <button
                                                                    onClick={() =>
                                                                        handleEnable(
                                                                            user.id
                                                                        )
                                                                    }
                                                                    title="Habilitar"
                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                >
                                                                    <svg
                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    >
                                                                        {" "}
                                                                        <polyline points="17 1 21 5 17 9" />{" "}
                                                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                        <polyline points="7 23 3 19 7 15" />{" "}
                                                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={5}>
                                                <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                                                <div className="flex justify-center">
                                                    <Paginado
                                                        actualPage={page}
                                                        elementsPerPage={
                                                            USERS_PER_PAGE
                                                        }
                                                        cantTotalElements={
                                                            allClientes.clientes
                                                                .total
                                                        }
                                                        pagination={pagination}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        ) : habilitados ? (
                            <h1 className="mx-auto mt-10 text-lg font-medium text-center">
                                Aun no tiene clientes habilitados
                            </h1>
                        ) : (
                            <h1 className="mx-auto mt-10 text-lg font-medium text-center">
                                Aun no tiene clientes deshabilitados
                            </h1>
                        )
                    ) : (
                        <Spinner />
                    )}
                </div>
            </div>
        </section>
    );
};

export default AllClientes;
