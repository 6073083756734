import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";

const EditarTipoPropiedad = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [allTypes, setAllTypes] = useState([]);
    const authUser = useSelector((state) => state.authReducer);
    useEffect(() => {
        getProperties();
    }, []);

    const getProperties = async () => {
        if (tipo_producto === "casa") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/propiedades/select/Tipo Alojamiento`,
                { headers }
            );
            setAllTypes(json.data);
        }
        if (tipo_producto === "embarcacion") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/propiedades/select/Tipo Embarcacion`,
                { headers }
            );
            setAllTypes(json.data);
        }
    };

    const updateProduct = async (payload) => {
        if (tipo_producto === "casa") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarPropiedades/${producto.data.id}`,
                payload,
                { headers }
            );
            if (data.message === "actuaizacion exitoso") {
                setModal(false);
            }
        }
        if (tipo_producto === "embarcacion") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacion/actualizarTipoEmbarcacion/${producto.data.id}`,
                payload,
                { headers }
            );
            if (data.message === "actuaizacion exitoso") {
                setModal(false);
            }
        }
    };

    const handleGuardar = () => {
        if (
            producto.data.id_tipo_alojamiento === "" &&
            producto.data.id_tipo_embarcacion === ""
        ) {
            Swal.fire({
                title: "Error",
                text: "Debe seleccionar un tipo de propiedad",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
        if (
            producto.data.id_tipo_alojamiento !== "" ||
            producto.data.id_tipo_embarcacion !== ""
        ) {
            if (tipo_producto === "casa") {
                updateProduct({
                    tipo: "Tipo Alojamiento",
                    id_propiedad: producto.data.id_tipo_alojamiento,
                    id_usuario: authUser.id_usuario,
                });
            } else {
                updateProduct({
                    id_propiedad: producto.data.id_tipo_embarcacion,
                    id_usuario: authUser.id_usuario,
                });
            }
        }
    };

    return (
        <div className="border p-3 mt-3 rounded-lg">
            <h1>Tipo de alojamiento</h1>

            <div className="w-full mt-3 ">
                <div className="overflow-hidden">
                    {!allTypes.length ? (
                        <Spinner width={7} margin_top={12} />
                    ) : null}
                    <div style={{}} className="overflow-y-auto">
                        {allTypes?.map((type) => {
                            return (
                                <div key={type.id} className="w-full p-3">
                                    <div
                                        className={
                                            producto.data
                                                .id_tipo_alojamiento ===
                                                type.id ||
                                            producto.data
                                                .id_tipo_embarcacion === type.id
                                                ? "flex justify-center lg:w-1/2 lg:mx-auto items-center text-base py-3 border border-solid border-[#3C3C3C] rounded-lg bg-[#F4F2F1] cursor-pointer"
                                                : "flex justify-center lg:w-1/2 lg:mx-auto items-center text-base  py-3 border border-solid border-[#E9E9E9]  rounded-lg cursor-pointer"
                                        }
                                        onClick={() =>
                                            tipo_producto === "casa"
                                                ? setProducto({
                                                      ...producto,
                                                      data: {
                                                          ...producto.data,
                                                          id_tipo_alojamiento:
                                                              type.id,
                                                          tipo_alojamiento:
                                                              type.nombre,
                                                      },
                                                  })
                                                : setProducto({
                                                      ...producto,
                                                      data: {
                                                          ...producto.data,
                                                          id_tipo_embarcacion:
                                                              type.id,
                                                          tipo_embarcacion:
                                                              type.nombre,
                                                      },
                                                  })
                                        }>
                                        <span className="">{type.nombre}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarTipoPropiedad;
