const getEveryDayBetween2Dates = (arrayFechas) => {
    const [startDate, endDate] = arrayFechas.map((fecha) => new Date(fecha));
    const date = new Date(startDate.getTime());
    const dates = [];

    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
};

export default getEveryDayBetween2Dates;
