import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ReactSortable } from "react-sortablejs";
import Spinner from "../../../Spinner";

const ModalAddImagenes = ({ paquete, setmodalVisible, showServicios }) => {
    const [siguiente, setSiguiente] = useState("Guardar");
    const [cargando, setCargando] = useState(false);
    const [imagenesPaquete, setImagenesPaquete] = useState([]);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const getImagenesPaquete = async () => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/paquetesEmpresasImagenes/all/${paquete.id}`,
            {
                headers,
            }
        );

        setImagenesPaquete(json.data);
        setCargando(false);
    };

    useEffect(() => {
        getImagenesPaquete();
    }, []);

    const multiLoadFile = async (event) => {
        const form = new FormData();

        const imagenes = [...imagenesPaquete];
        if (event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                imagenes.push(event.target.files[i]);
            }
            form.append("id_paquete_empresa", paquete.id);
            imagenes?.forEach((image) => {
                form.append("imagenes_paquetes", image);
            });

            try {
                setCargando(true);
                const json = await axios.post(
                    `${process.env.REACT_APP_PROXY}/paquetesEmpresasImagenes/`,
                    form,
                    { headers }
                );

                if (json.data.message === "registro exitoso!") {
                    Swal.fire({
                        title: "Imagenes cargadas correctamente!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                    getImagenesPaquete();
                }
            } catch (error) {
                console.log(error);
                setCargando(false);
            }
        }
    };

    const eliminarImagene = async (imagen, index) => {
        if (imagen.id) {
            setCargando(true);
            try {
                const json = await axios.delete(
                    `${process.env.REACT_APP_PROXY}/paquetesEmpresasImagenes/eliminar/${imagen.id}`,
                    {
                        headers,
                    }
                );

                setImagenesPaquete(
                    imagenesPaquete.filter((imagen, i) => i !== index)
                );
                setCargando(false);
            } catch (error) {
                setCargando(false);
            }
        } else {
            setImagenesPaquete(
                imagenesPaquete.filter((imagen, i) => i !== index)
            );
        }
    };

    const ordenImagenes = async () => {
        setSiguiente(null);
        let array_orden = [];
        imagenesPaquete.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/paquetesEmpresasImagenes/updateOrden`,
            {
                array_orden: array_orden,
            },
            { headers }
        );

        setSiguiente("Guardar");
        handleClose();
    };

    const handleClose = () => {
        setmodalVisible(null);
    };

    return (
        <div className={`relative items-center justify-center `}>
            <div className="fixed bottom-0 left-0 z-20 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center py-8 2xl:container 2xl:mx-auto md:px-10 sm:py-8 ">
                    <div className="relative block px-2 justify-center bg-white rounded-lg  w-auto dark:bg-white md:px-10 h-[calc(100vh-30vh)] overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="px-3 mt-10 text-base font-bold text-left lg:text-xl">
                            {showServicios && "Imágenes del servicio"}
                            {!showServicios && "Imágenes del paquete"}
                        </header>
                        <div className="px-3 mt-2 rounded-lg w-80 ">
                            <label className="flex font-medium">
                                {paquete.nombre}
                            </label>
                        </div>
                        <div className="px-3 mt-4 w-80 ">
                            <div className="mt-2 relative flex justify-center h-28   border border-[#E9E9E9] rounded-lg hover:cursor-pointer">
                                <div className="absolute">
                                    <div className="flex flex-col mx-auto ">
                                        <div className="relative top-4 ">
                                            <span className="block mt-2 text-base font-bold text-center ">
                                                Agrega una o varias imagenes
                                            </span>

                                            <span className="block mt-3 font-normal text-center text-blue-bluecorner">
                                                Sube las imagenes desde tu
                                                dispositivo
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    accept="image/*"
                                    multiple
                                    type="file"
                                    className="w-full h-full opacity-0 cursor-pointer"
                                    onChange={(event) => multiLoadFile(event)}
                                />
                            </div>
                        </div>

                        <div className="mt-2 w-full">
                            <hr className="w-full mt-4" />
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        {cargando ? (
                                            <Spinner />
                                        ) : (
                                            <ReactSortable
                                                handle=".handle"
                                                className="flex flex-wrap justify-around w-80"
                                                group="groupName"
                                                animation={200}
                                                delayOnTouchStart={true}
                                                delay={2}
                                                list={imagenesPaquete}
                                                setList={setImagenesPaquete}>
                                                {imagenesPaquete?.map(
                                                    (image, index) => (
                                                        <div
                                                            key={index}
                                                            className={`relative mt-4 w-36 border rounded-lg cursor-move ${
                                                                window.innerWidth <
                                                                500
                                                                    ? ""
                                                                    : "handle"
                                                            }`}>
                                                            <span
                                                                onClick={() =>
                                                                    eliminarImagene(
                                                                        image,
                                                                        index
                                                                    )
                                                                }
                                                                className={`absolute right-1 top-1 text-gray-800 dark:text-gray-400 rounded-sm bg-white hover:bg-slate-200 `}
                                                                aria-label="close">
                                                                <svg
                                                                    className="w-4 h-4 text-blue-500 cursor-pointer"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M6 18L18 6M6 6l12 12"
                                                                    />
                                                                </svg>
                                                            </span>

                                                            <img
                                                                src={
                                                                    image.imagen_minificada
                                                                        ? `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${image.imagen_minificada}`
                                                                        : URL.createObjectURL(
                                                                              image
                                                                          )
                                                                }
                                                                className="object-cover w-full h-20 mx-auto rounded-md"
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </ReactSortable>
                                        )}

                                        <div className="pb-3 text-center w-80 ">
                                            <button
                                                className="w-full rounded-lg border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white  active:translate-y-[0.125rem]  mt-10 disabled:bg-slate-400 disabled:border-slate-400"
                                                onClick={() => ordenImagenes()}
                                                disabled={!siguiente}>
                                                {siguiente
                                                    ? siguiente
                                                    : "Cargando.."}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAddImagenes;
