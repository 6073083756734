import DateIsBetween2Dates from "./DateIsBetween2Dates";

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

const getFeeByDay = (
    date,
    arrayFees,
    fee_cliente_siempre,
    fee_propietario_siempre,
    price
) => {
    const fee_por_fecha = arrayFees.find((fee) => {
        if (fee.tipo_tiempo === "Fecha") {
            if (DateIsBetween2Dates(fee.fecha_inicio, fee.fecha_final, date)) {
                return fee;
            }
        }
    });

    if (fee_por_fecha) {
        return {
            fee_cliente: Number(
                price / (1 - fee_por_fecha.fee_cliente / 100) - price
            ),
            tiene_fee_por_fecha: true,
        };
    } else {
        return {
            fee_cliente: Number(
                price / (1 - fee_cliente_siempre / 100) - price
            ),
        };
    }
};

export default getFeeByDay;
