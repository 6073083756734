import axios from "axios";
import React, { lazy, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useHeaders from "../../../hooks/useHeaders";
// import ReactQuill, { Quill } from "react-quill";
import Swal from "sweetalert2";
const ReactQuill = lazy(() => import("react-quill"));
const { Quill } = lazy(() => import("react-quill"));

const ModalEnviarMensaje = ({
    modalVisible,
    setModalVisible,
    empresa,
    setRefreshActividades,
}) => {
    const authUser = useSelector((state) => state.authReducer);
    const [input, setInput] = useState({
        id_empresa: empresa.id,
        id_remitente: authUser.id_usuario,
        asunto: "",
        resumen: "",
        destinatarios: [],
    });
    const [loading, setLoading] = useState(false);
    const [allUsersBC, setAllUsersBC] = useState([]);
    const { headers } = useHeaders();
    const quillRef = useRef(null);

    const Image = Quill.import("formats/image");
    Image.className = "image-react-quill";
    Quill.register(Image, true);

    const getAllUsersBC = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/autenticacion/all/1/Si`,
            { headers }
        );

        setAllUsersBC(data.data);
    };

    useEffect(() => {
        getAllUsersBC();
    }, []);

    const handleDestinatarios = (id_user) => {
        const {
            correo,
            id_usuario,
            nombre,
            apellido_materno,
            apellido_paterno,
        } = allUsersBC.filter((user) => {
            return user.id === Number(id_user);
        })[0];

        const destinatario = {
            correo,
            id_usuario,
            nombre,
            apellido_materno,
            apellido_paterno,
        };

        if (
            input.destinatarios.some(
                (user) => Number(id_user) === user.id_usuario
            )
        )
            return;

        setInput({
            ...input,
            destinatarios: [...input.destinatarios, destinatario],
        });
    };

    const handleDeleteDestinatario = (id_user) => {
        setInput({
            ...input,
            destinatarios: input.destinatarios.filter(
                (user) => user.id_usuario !== id_user
            ),
        });
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [3, 4, 5, 6, false] }],
                    [
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "code",
                    ],
                    [{ color: [] }, { background: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            },
        }),
        []
    );

    function imageHandler() {
        const editor = quillRef.current.getEditor();

        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            const form = new FormData();

            form.append("imagen_mensajes_crm", file);

            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/mensajesCrm/postImage/`,
                form,
                { headers }
            );

            if (data.message === "registro exitoso") {
                editor.insertEmbed(
                    editor.getSelection(),
                    "image",
                    `${process.env.REACT_APP_PROXY}/mensajesCrm/getImage/${data.imagen}`
                );
            }
        };
    }

    const onChangeText = (text) => {
        text = text !== "<p><br></p>" ? text : "";
        setInput({ ...input, resumen: text });
    };

    const handlePlanificar = async () => {
        setLoading(true);
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/mensajesCrm/`,
                input,
                { headers }
            );

            if (data.message === "registro exitoso") {
                Swal.fire({
                    title: "Mensajes enviados con exito!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                }).then(() => {
                    setLoading(false);
                    setRefreshActividades((prev) => !prev);
                    setModalVisible(false);
                });
            }
        } catch (error) {
            console.log(error.response.data);
        }
    };

    const handleClose = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed bottom-0 left-0 z-20 w-full h-full bg-gray-900 border-style bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 sm:py-8 ">
                    <div className="relative w-full block justify-center px-4 py-6 bg-white rounded-lg dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-4 right-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="p-2 px-1 text-base font-semibold text-left lg:text-2xl">
                            Enviar un mensaje
                        </header>
                        <hr />
                        <div>
                            <div className="flex flex-col mt-6">
                                <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-3">
                                    <span className="ml-1 text-sm text-gray-600 min-w-fit">
                                        Primero selecciona los destinatarios:
                                    </span>
                                    <select
                                        disabled={allUsersBC.length === 0}
                                        defaultValue="Selecciona una opcion"
                                        className="w-full p-1 border rounded-lg outline-none"
                                        onChange={(e) =>
                                            handleDestinatarios(e.target.value)
                                        }>
                                        <option disabled>
                                            {allUsersBC.length === 0
                                                ? "Cargando"
                                                : "Selecciona una opcion"}
                                        </option>
                                        {allUsersBC.length > 0 &&
                                            allUsersBC.map((user) => (
                                                <option
                                                    key={user.id}
                                                    value={user.id}>
                                                    {user.nombre}{" "}
                                                    {user.apellido_materno}{" "}
                                                    {user.apellido_paterno}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <span className="border-b-2 min-h-[3rem] py-1 mt-1 outline-none gap-2 flex flex-wrap w-full select-none">
                                    {input.destinatarios.map((user) => (
                                        <div
                                            key={user.id_usuario}
                                            className="border rounded-lg p-1.5 gap-1.5 flex items-center">
                                            <span>
                                                {user.nombre}{" "}
                                                {user.apellido_materno}{" "}
                                                {user.apellido_paterno}
                                            </span>
                                            <svg
                                                onClick={() =>
                                                    handleDeleteDestinatario(
                                                        user.id_usuario
                                                    )
                                                }
                                                className="w-4 h-4 text-red-500 cursor-pointer hover:scale-110"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <polyline points="3 6 5 6 21 6" />{" "}
                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />{" "}
                                                <line
                                                    x1="10"
                                                    y1="11"
                                                    x2="10"
                                                    y2="17"
                                                />{" "}
                                                <line
                                                    x1="14"
                                                    y1="11"
                                                    x2="14"
                                                    y2="17"
                                                />
                                            </svg>
                                        </div>
                                    ))}
                                </span>
                            </div>
                            <div className="flex flex-col mt-6 ml-2">
                                <span className="text-sm text-gray-600 ">
                                    Asunto del mensaje
                                </span>
                                <input
                                    className="py-1 border-b-2 outline-none placeholder:italic"
                                    value={input.asunto}
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            asunto: e.target.value,
                                        })
                                    }
                                    placeholder="Asunto del mensaje..."
                                />
                            </div>
                            <div className="flex flex-col ml-1">
                                <span className="mt-5 mb-2 text-sm text-gray-600">
                                    Descripción de la nota
                                </span>
                                <ReactQuill
                                    ref={quillRef}
                                    theme="snow"
                                    value={input.resumen}
                                    onChange={onChangeText}
                                    placeholder={"Escribe algo..."}
                                    modules={modules}
                                    style={{
                                        backgroundColor: "white",
                                        height: "40vh",
                                    }}
                                />
                            </div>
                            <div className="flex flex-wrap justify-between w-full gap-2 mt-28 md:mt-20">
                                <button
                                    className="p-2 px-4 font-bold text-white cursor-pointer bg-blue-bluecorner min-w-fit hover:bg-blue-800 disabled:bg-gray-400"
                                    onClick={handlePlanificar}
                                    disabled={loading}>
                                    ENVIAR MENSAJE
                                </button>
                                <button
                                    className="p-2 font-bold cursor-pointer text-blue-bluecorner min-w-fit hover:bg-gray-300 hover:text-black"
                                    onClick={handleClose}>
                                    DESCARTAR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEnviarMensaje;
