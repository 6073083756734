import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useSelector } from "react-redux";
import ModalLogeo from "../alquilerScreen/alquilerAlojamiento/ModalLogeo";
import axios from "axios";
import dividirFechaDatePicker from "../helpers/dividirFechaDatePicker";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";

export const FormularioDeReservaScrapy = ({
    habilitarModalFormulario,
    setHabilitarModalFormulario,
    id_casa,
    nombre_casa,
}) => {
    registerLocale("es", es);
    const authUser = useSelector((state) => state.authReducer);
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [datosCliente, setDatosCliente] = useState(null);
    const [cotizar, setCotizar] = useState(false);
    const [cargando, setCargando] = useState(false);

    const [input, setInput] = useState({
        nombre: "",
        apellido: "",
        correo: "",
        celular: "",
        fecha_inicio: "",
        fecha_final: "",
        nota: "",
    });

    useEffect(() => {
        if (authUser.logged) {
            onepropertie(authUser.id_usuario);
        }
    }, [habilitarModalFormulario]);

    const formulario = () => {
        if (authUser.logged === false) {
            setHabilitarModalFormulario(true);

            return;
        }
        onepropertie(authUser.id_usuario);
    };

    const enviar = async () => {
        if (input.fecha_inicio === "" || input.fecha_final === "") {
            Swal.fire({
                title: "Debes seleccionar una fecha de inicio y una final",
                icon: "warning",
                confirmButtonText: "Ok",
            });
            return;
        }
        setCargando(true);
        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/pedidoCasa/`,
            {
                id_cliente: authUser.id_usuario,
                tipo_usuario_cliente: authUser.tipo_usuario,
                id_casa: id_casa,
                fecha_inicio: input.fecha_inicio,
                fecha_final: input.fecha_final,
                costo_alquiler: "",
                costo_fee_bc: "",
                costo_fee_bc_propietario: "",
                costo_igv: "",
                descuento_por_puntos: "",
                id_cupon: 0,
                descuento_bc_cupon: 0,
                descuento_propietario_cupon: 0,
                descuento_empresa: 0,
                costo_asumido_empresa: 0,
                costo_total_alquiler: 0,
                costo_total_servicios_adicionales: 0,
                costo_garantia: 0,
                costo_tarjeta: 0,
                pagado: 0,
                saldo: 0,
                costo_total: 0,
                tipo_moneda: "USD",
                posibles_puntos: 0,
                puntos_canjeados: 0,
                cotizacion: "Si",
                extras: [],
                servicios_adicionales: [],
                nota: input.nota,
                correo_cliente: input.correo,
                nombre_casa: nombre_casa,
            },
            { headers }
        );
        console.log(json.data);
        setCargando(false);
        if (json.data.message === "Registro exitoso") {
            navigate("/comprobando-disponibilidad");
        } else {
            Swal.fire({
                title: "Hubo un error en el registro, intente de nuevo por favor",
                icon: "error",
            });
        }
    };

    const onepropertie = async (id) => {
        if (authUser.tipo_usuario < 3) {
            const jsonAdmin = await axios.get(
                `${process.env.REACT_APP_PROXY}/autenticacion/detalle/${authUser.id_usuario}`,
                { headers }
            );

            setDatosCliente(jsonAdmin.data.data);
            setInput({
                ...input,

                nombre: jsonAdmin.data.data.nombre,
                apellido: jsonAdmin.data.data.apellido_paterno,
                correo: jsonAdmin.data.data.correo,
                celular: jsonAdmin.data.data.celular,
            });
        } else if (authUser.tipo_usuario === 6 || authUser.tipo_usuario === 7) {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/adminEmpresas/detalle/${authUser.id_usuario}`,
                { headers }
            );

            setDatosCliente(data.data);
            setInput({
                ...input,

                nombre: data.data.nombre,
                apellido: data.data.apellido_paterno,
                correo: data.data.correo,
                celular: data.data.celular,
            });
        } else {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}${
                    authUser.tipo_usuario === 4
                        ? "/subpropietario/detalle/"
                        : authUser.tipo_usuario === 3
                        ? "/datosCliente/detalle/"
                        : "/cliente/detalle/"
                }${authUser.id_usuario}`,
                { headers }
            );

            setDatosCliente(json.data.data);
            setInput({
                ...input,

                nombre: json.data.data.nombre,
                apellido: json.data.data.apellido_paterno,
                correo: json.data.data.correo,
                celular: json.data.data.celular,
            });
        }
        setCotizar(true);
    };
    return (
        <div>
            <div>
                {!cotizar || authUser.logged === false ? (
                    <button
                        onClick={() => formulario(true)}
                        className={`mx-2 text-center`}>
                        <p
                            className={` font-semibold bg-blue-500 rounded-lg min-w-max p-3 text-white w-48 hover:bg-blue-600`}>
                            Cotizar
                        </p>
                    </button>
                ) : (
                    <div className="p-4 border-2 border-[#053465] rounded-lg ">
                        <p className="text-xl font-semibold text-center sm:text-2xl">
                            Datos para la reserva
                        </p>
                        <div className="relative grid w-10/12 grid-cols-2 gap-4 mx-auto mt-2">
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Nombre:
                                </label>
                                <input
                                    disabled
                                    autoComplete="off"
                                    type="text"
                                    name="nombre"
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            nombre: e.target.value,
                                        })
                                    }
                                    value={input.nombre}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 cursor-not-allowed "
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Apellido:
                                </label>
                                <input
                                    disabled
                                    autoComplete="off"
                                    type="text"
                                    name="apellido"
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            apellido: e.target.value,
                                        })
                                    }
                                    value={input.apellido}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 cursor-not-allowed"
                                    placeholder="Apellido"
                                />
                            </div>
                        </div>
                        <div className="relative grid w-10/12 grid-cols-2 gap-4 mx-auto mt-2">
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Celular:
                                </label>
                                <input
                                    disabled
                                    autoComplete="off"
                                    type="number"
                                    name="celular"
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            celular: e.target.value,
                                        })
                                    }
                                    value={input.celular}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 cursor-not-allowed"
                                    placeholder="Celular"
                                />
                            </div>
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Correo:
                                </label>
                                <input
                                    disabled
                                    autoComplete="off"
                                    type="text"
                                    name="correo"
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            correo: e.target.value,
                                        })
                                    }
                                    value={input.correo}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 cursor-not-allowed"
                                    placeholder="Correo"
                                />
                            </div>
                        </div>
                        <div className="relative grid w-10/12 grid-cols-2 gap-4 mx-auto mt-2">
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Fecha de inicio:
                                </label>
                                <DatePicker
                                    className=" w-full p-2 mx-auto rounded-lg cursor-pointer  border-[#e5e7eb] border"
                                    placeholderText="Fecha de entrada"
                                    locale="es"
                                    autoComplete="off"
                                    dateFormat="dd/MM/yyyy"
                                    selected={
                                        input.fecha_inicio !== ""
                                            ? new Date(
                                                  dividirFechaDatePicker(
                                                      input.fecha_inicio
                                                  )[0],
                                                  dividirFechaDatePicker(
                                                      input.fecha_inicio
                                                  )[1] - 1,
                                                  dividirFechaDatePicker(
                                                      input.fecha_inicio
                                                  )[2]
                                              )
                                            : input.fecha_inicio
                                    }
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            fecha_inicio: `${e.getFullYear()}-${`${
                                                e.getMonth() + 1
                                            }`.padStart(
                                                2,
                                                "0"
                                            )}-${`${e.getDate()}`.padStart(
                                                2,
                                                "0"
                                            )}`,
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Fecha de salida:
                                </label>
                                <DatePicker
                                    className="w-full p-2 mx-auto rounded-lg cursor-pointer  border-[#e5e7eb] border "
                                    placeholderText="Fecha de salida"
                                    locale="es"
                                    autoComplete="off"
                                    dateFormat="dd/MM/yyyy"
                                    selected={
                                        input.fecha_final !== ""
                                            ? new Date(
                                                  dividirFechaDatePicker(
                                                      input.fecha_final
                                                  )[0],
                                                  dividirFechaDatePicker(
                                                      input.fecha_final
                                                  )[1] - 1,
                                                  dividirFechaDatePicker(
                                                      input.fecha_final
                                                  )[2]
                                              )
                                            : input.fecha_final
                                    }
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            fecha_final: `${e.getFullYear()}-${`${
                                                e.getMonth() + 1
                                            }`.padStart(
                                                2,
                                                "0"
                                            )}-${`${e.getDate()}`.padStart(
                                                2,
                                                "0"
                                            )}`,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="relative grid w-10/12 grid-cols-1 gap-4 mx-auto mt-2">
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Comentario:
                                </label>
                                <textarea
                                    autoComplete="off"
                                    type="text"
                                    name="nota"
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            nota: e.target.value,
                                        })
                                    }
                                    value={input.nota}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                    placeholder="Comentario"
                                />
                            </div>
                        </div>
                        <div className="relative grid w-10/12 grid-cols-1 gap-4 mx-auto mt-2">
                            <button
                                disabled={cargando}
                                onClick={() => enviar()}
                                className={`mx-2 text-center`}>
                                <p
                                    className={` font-semibold bg-blue-500 rounded-lg min-w-max p-3 text-white w-40 hover:bg-blue-600 mx-auto disabled:bg-blue-300`}>
                                    {cargando ? "Enviando..." : "Cotizar"}
                                </p>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
