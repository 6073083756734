import { getTotalFromDayNumbers } from "../helpers/getTotalPrice/getTotalFromDayNumbers";
import { getTotalFromRangoExactoDias } from "../helpers/getTotalPrice/getTotalFromRangoExactoDias";
import getTotalPrice from "./getTotalPrice";

const getTotalReservaSimple = (
    cantDays,
    selectedDays,
    currentProduct,
    es_empleado_empresa,
    membresia
) => {
    const TOTAL_RESERVA_INICIAL = getTotalPrice(
        selectedDays,
        currentProduct,
        cantDays,
        true,
        false,
        false,
        false,
        true,
        es_empleado_empresa,
        true,
        false,
        membresia
    );

    const TOTAL_FROM_DAY_NUMBERS = getTotalFromDayNumbers(
        cantDays,
        currentProduct.precios_variables.arrayNumeroDiasPorDia,
        currentProduct.moneda_precio_base,
        currentProduct.fee_cliente,
        currentProduct.fee_propietario,
        currentProduct.feeCasas,
        selectedDays
    ).price_total;

    const TOTAL_FROM_RANGE_DAYS = getTotalFromRangoExactoDias(
        cantDays,
        selectedDays,
        currentProduct.precios_variables.arrayRangoExactoDiasPorDia,
        currentProduct.moneda_precio_base,
        currentProduct.fee_cliente,
        currentProduct.fee_propietario,
        currentProduct.feeCasas
    ).precio_total;

    let totalReservaSimple = TOTAL_RESERVA_INICIAL;

    totalReservaSimple = totalReservaSimple + TOTAL_FROM_DAY_NUMBERS;

    totalReservaSimple = totalReservaSimple + TOTAL_FROM_RANGE_DAYS;

    return Number(Math.floor(totalReservaSimple));
};

export default getTotalReservaSimple;
