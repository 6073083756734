import axios from "axios";
import React, { useEffect, useState } from "react";
import useHeaders from "../../../hooks/useHeaders";
import numberToCommas from "../../helpers/numberToCommas";
import Spinner from "../../Spinner";

const DetalleAlquilerPropietario = ({ pedido_id, showAlojamientos }) => {
    const [pedidoSelected, setPedidoSelected] = useState(null);
    const { headers } = useHeaders();

    const getPedido = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/${
                showAlojamientos ? "pedidoCasa" : "pedidoEmbarcacion"
            }/detalle/${pedido_id}`,
            { headers }
        );

        setPedidoSelected(data);
    };

    useEffect(() => {
        getPedido();
    }, []);

    const getTotalAlquilerPropietario = () => {
        let total = getSubTotal() + getTotalFeePropietario();

        return total;
    };

    const getTotalServiciosAdicionalesPropietario = () => {
        let total_servicios = 0;

        pedidoSelected.serviciosAdicionales.map(
            (service) => (total_servicios += service.costo)
        );

        total_servicios += pedidoSelected.costo_garantia;
        // if (!showAlojamientos) {
        //     total_servicios += pedidoSelected.costo_embarque;
        // }

        return total_servicios;
    };

    const getTotalFeePropietario = () => {
        let total_fee_propietario = pedidoSelected.costo_fee_propietario;

        if (showAlojamientos) {
            pedidoSelected.extras.forEach(
                (extra) =>
                    (total_fee_propietario += extra.costo_fee_propietario)
            );
        }

        return total_fee_propietario;
    };

    const getSubTotal = () => {
        let subtotal = pedidoSelected.costo_alquiler;

        if (showAlojamientos) {
            pedidoSelected.extras.forEach((extra) => (subtotal += extra.costo));

            // POR AHORA CUPON SOLO EN CASAS
        }
        subtotal -= pedidoSelected.descuento_propietario_cupon;

        subtotal += pedidoSelected.costo_igv;

        return subtotal;
    };

    if (!pedidoSelected) {
        return <Spinner />;
    } else
        return (
            <div className=" md:mt-14 pb-28 2xl:10/12 mx-auto">
                <div className="mt-5">
                    <h1 className="text-xl font-bold">Alquiler</h1>
                    <div className="grid grid-cols-[30%_70%] py-3 border-b">
                        <div>
                            <span></span>
                        </div>
                        <div className="grid grid-cols-[49%_49%] text-right font-bold">
                            <span>Fee de Blue Corner</span>
                            <span>Costo total</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-[30%_70%] border-b py-3">
                        <div>
                            <span>Costo</span>
                        </div>
                        <div className="grid grid-cols-2 text-right">
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.costo_fee_propietario
                                )}
                            </span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_alquiler)}
                            </span>
                        </div>
                    </div>

                    {pedidoSelected?.extras?.map((extra) => {
                        return (
                            <div key={extra.id} className="">
                                <div className="grid grid-cols-[30%_70%] border-b py-3">
                                    <div>
                                        <span>{extra.texto}</span>
                                    </div>
                                    <div className="grid grid-cols-2 text-right">
                                        <span>
                                            {pedidoSelected.tipo_moneda}{" "}
                                            {numberToCommas(
                                                extra.costo_fee_propietario
                                            )}
                                        </span>
                                        <span>
                                            {pedidoSelected.tipo_moneda}{" "}
                                            {numberToCommas(extra.costo)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {showAlojamientos && (
                        <div className="flex justify-between py-3 border-b">
                            <span>I.G.V.</span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_igv)}
                            </span>
                        </div>
                    )}

                    {pedidoSelected.descuento_propietario_cupon > 0 && (
                        <div className="flex justify-between py-3 border-b">
                            <span>Descuento por cupón</span>
                            <span>
                                - {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(
                                    pedidoSelected.descuento_propietario_cupon
                                )}
                            </span>
                        </div>
                    )}

                    <div className="border-[3px] border-gray-600 p-2 border-dotted">
                        <div className="grid grid-cols-[30%_70%] py-3">
                            <div>
                                <span className="font-bold">Subtotal</span>
                            </div>
                            <div className="grid grid-cols-2 text-right">
                                <span>
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(getTotalFeePropietario())}
                                </span>
                                <span>
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(getSubTotal())}
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between py-3 font-bold border-t">
                            <span className="font-bold">Total</span>
                            <span>
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(getTotalAlquilerPropietario())}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <h1 className="text-xl font-bold">Adicionales</h1>
                    <div className="grid grid-cols-[30%_70%] py-3 border-b">
                        <div>
                            <span></span>
                        </div>
                        <div className="grid grid-cols-[33%_33%_33%] text-right font-bold">
                            <span></span>
                            <span></span>
                            <span>Costo total</span>
                        </div>
                    </div>

                    <div className="py-3">
                        <div className="grid grid-cols-[30%_70%] border-b">
                            <div>
                                <span>Garantia</span>
                            </div>
                            <div className="grid grid-cols-[33%_33%_33%] text-right">
                                <span></span>
                                <span></span>
                                <span>
                                    {pedidoSelected.tipo_moneda}{" "}
                                    {numberToCommas(
                                        pedidoSelected.costo_garantia
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* {!showAlojamientos && (
                        <div className="grid grid-cols-[30%_70%] border-b py-3">
                            <span>Costo embarque</span>
                            <span className="flex justify-end mr-0.5">
                                {pedidoSelected.tipo_moneda}{" "}
                                {numberToCommas(pedidoSelected.costo_embarque)}
                            </span>
                        </div>
                    )} */}
                    {pedidoSelected.serviciosAdicionales && (
                        <div>
                            {pedidoSelected.serviciosAdicionales.map(
                                (service) => (
                                    <div className="py-3" key={service.id}>
                                        <div className="grid grid-cols-[30%_70%] border-b">
                                            <div>
                                                <span>{service.nombre}</span>
                                            </div>
                                            <div className="grid grid-cols-[33%_33%_33%] text-right">
                                                <span></span>
                                                <span></span>
                                                <span>
                                                    {pedidoSelected.tipo_moneda}{" "}
                                                    {numberToCommas(
                                                        service.costo
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    <div className="flex justify-between py-3 font-bold border-b">
                        <span>Total</span>
                        <span>
                            {pedidoSelected.tipo_moneda}{" "}
                            {numberToCommas(
                                getTotalServiciosAdicionalesPropietario()
                            )}
                        </span>
                    </div>
                </div>
            </div>
        );
};

export default DetalleAlquilerPropietario;
