import React, { useEffect, useState } from "react";
import axios from "axios";
import CardExperience from "./CardExperience";
import useHeaders from "../../hooks/useHeaders";
import ModalVerMas from "./ModalVerMas";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import dividirFechaDatePicker from "../helpers/dividirFechaDatePicker";
import Swal from "sweetalert2";
const COLORS = {
    green: "#77DD70",
    red: "#FF6961",
    yellow: "#FDFD87",
};

const CUADRANTES_INITIAL_STATE = {
    //Fila 1
    1: {
        id_matriz: 1,
        tag: "A3",
        color: COLORS.green,
        tipo: "campo",
        casas: [],
    },
    2: {
        id_matriz: 2,
        tag: "B3",
        color: COLORS.red,
        tipo: "campo",
        casas: [],
    },
    3: {
        id_matriz: 3,
        tag: "C3",
        color: COLORS.red,
        tipo: "campo",
        casas: [],
    },
    4: {
        id_matriz: 4,
        tag: "C3",
        color: COLORS.red,
        tipo: "playa",
        casas: [],
    },
    5: {
        id_matriz: 5,
        tag: "B3",
        color: COLORS.red,
        tipo: "playa",
        casas: [],
    },
    6: {
        id_matriz: 6,
        tag: "A3",
        color: COLORS.green,
        tipo: "playa",
        casas: [],
    },
    //Fila 2
    7: {
        id_matriz: 7,
        tag: "A2",
        color: COLORS.green,
        tipo: "campo",
        casas: [],
    },
    8: {
        id_matriz: 8,
        tag: "B2",
        color: COLORS.yellow,
        tipo: "campo",
        casas: [],
    },
    9: {
        id_matriz: 9,
        tag: "C2",
        color: COLORS.red,
        tipo: "campo",
        casas: [],
    },
    10: {
        id_matriz: 10,
        tag: "C2",
        color: COLORS.red,
        tipo: "playa",
        casas: [],
    },
    11: {
        id_matriz: 11,
        tag: "B2",
        color: COLORS.yellow,
        tipo: "playa",
        casas: [],
    },
    12: {
        id_matriz: 12,
        tag: "A2",
        color: COLORS.green,
        tipo: "playa",
        casas: [],
    },
    //Fila 3
    13: {
        id_matriz: 13,
        tag: "A1",
        color: COLORS.green,
        tipo: "campo",
        casas: [],
    },
    14: {
        id_matriz: 14,
        tag: "B1",
        color: COLORS.green,
        tipo: "campo",
        casas: [],
    },
    15: {
        id_matriz: 15,
        tag: "C1",
        color: COLORS.yellow,
        tipo: "campo",
        casas: [],
    },
    16: {
        id_matriz: 16,
        tag: "C1",
        color: COLORS.yellow,
        tipo: "playa",
        casas: [],
    },
    17: {
        id_matriz: 17,
        tag: "B1",
        color: COLORS.green,
        tipo: "playa",
        casas: [],
    },
    18: {
        id_matriz: 18,
        tag: "A1",
        color: COLORS.green,
        tipo: "playa",
        casas: [],
    },
    //Fila 4
    19: {
        id_matriz: 19,
        tag: "a1",
        color: COLORS.green,
        tipo: "campo",
        casas: [],
    },
    20: {
        id_matriz: 20,
        tag: "b1",
        color: COLORS.yellow,
        tipo: "campo",
        casas: [],
    },
    21: {
        id_matriz: 21,
        tag: "c1",
        color: COLORS.yellow,
        tipo: "campo",
        casas: [],
    },
    22: {
        id_matriz: 22,
        tag: "c1",
        color: COLORS.yellow,
        tipo: "playa",
        casas: [],
    },
    23: {
        id_matriz: 23,
        tag: "b1",
        color: COLORS.yellow,
        tipo: "playa",
        casas: [],
    },
    24: {
        id_matriz: 24,
        tag: "a1",
        color: COLORS.green,
        tipo: "playa",
        casas: [],
    },
    //Fila 5
    25: {
        id_matriz: 25,
        tag: "a2",
        color: COLORS.green,
        tipo: "campo",
        casas: [],
    },
    26: {
        id_matriz: 26,
        tag: "b2",
        color: COLORS.green,
        tipo: "campo",
        casas: [],
    },
    27: {
        id_matriz: 27,
        tag: "c2",
        color: COLORS.yellow,
        tipo: "campo",
        casas: [],
    },
    28: {
        id_matriz: 28,
        tag: "c2",
        color: COLORS.yellow,
        tipo: "playa",
        casas: [],
    },
    29: {
        id_matriz: 29,
        tag: "b2",
        color: COLORS.green,
        tipo: "playa",
        casas: [],
    },
    30: {
        id_matriz: 30,
        tag: "a2",
        color: COLORS.green,
        tipo: "playa",
        casas: [],
    },
    //Fila 6
    31: {
        id_matriz: 31,
        tag: "a3",
        color: COLORS.green,
        tipo: "campo",
        casas: [],
    },
    32: {
        id_matriz: 32,
        tag: "b3",
        color: COLORS.green,
        tipo: "campo",
        casas: [],
    },
    33: {
        id_matriz: 33,
        tag: "c3",
        color: COLORS.yellow,
        tipo: "campo",
        casas: [],
    },
    34: {
        id_matriz: 34,
        tag: "c3",
        color: COLORS.yellow,
        tipo: "playa",
        casas: [],
    },
    35: {
        id_matriz: 35,
        tag: "b3",
        color: COLORS.green,
        tipo: "playa",
        casas: [],
    },
    36: {
        id_matriz: 36,
        tag: "a3",
        color: COLORS.green,
        tipo: "playa",
        casas: [],
    },
};

const BlueExperience = () => {
    registerLocale("es", es);
    const { headers } = useHeaders();
    const [modalVerMasVisible, setModalVerMasVisible] = useState(false);
    const [cuadranteSelected, setCuadranteSelected] = useState(null);
    const [cuadrantes, setCuadrantes] = useState(CUADRANTES_INITIAL_STATE);
    const [refreshMatriz, setRefreshMatriz] = useState(false);
    const [input, setInput] = useState({
        fecha_inicio: "",
        fecha_final: "",
    });

    const getMatriz = async () => {
        const new_cuadrante = { ...CUADRANTES_INITIAL_STATE };

        setCuadranteSelected(null);
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/casaExperience/getMatriz/`,
                {
                    fecha_inicio: input.fecha_inicio,
                    fecha_final: input.fecha_final,
                },
                { headers }
            );
            data.data.forEach((casa) => {
                new_cuadrante[casa.id_matriz] = {
                    ...new_cuadrante[casa.id_matriz],
                    casas: [
                        ...new_cuadrante[casa.id_matriz].casas,
                        {
                            nombre_casa: casa.nombre,
                            id_casa: casa.id,
                            lugar: casa.lugar,
                        },
                    ],
                };
            });

            setCuadrantes(new_cuadrante);
            setCuadranteSelected(
                new_cuadrante[cuadranteSelected?.id_matriz] || null
            );
        } catch (error) {
            console.log(error.response);
            Swal.fire({
                title: error.response.data.error,
                icon: "error",
            });
        }
    };

    useEffect(() => {
        getMatriz();
    }, [refreshMatriz]);

    return (
        <div className="flex flex-col justify-center absolute w-full md:pl-[13rem] lg:pl-[10rem] p-4">
            <div className="relative grid mx-6  my-7 lg:mx-32 xl:mx-52 2xl:mx-72 grid-cols-[40%,40%,18%] gap-2  pt-4 z-50 ">
                <div className="flex flex-col ">
                    <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                        Fecha inicial:
                    </label>
                    <DatePicker
                        className=" w-full p-2 mx-auto rounded-lg cursor-pointer  border-[#e5e7eb] border"
                        placeholderText="Fecha inicial"
                        locale="es"
                        autoComplete="off"
                        dateFormat="dd/MM/yyyy"
                        selected={
                            input.fecha_inicio !== ""
                                ? new Date(
                                      dividirFechaDatePicker(
                                          input.fecha_inicio
                                      )[0],
                                      dividirFechaDatePicker(
                                          input.fecha_inicio
                                      )[1] - 1,
                                      dividirFechaDatePicker(
                                          input.fecha_inicio
                                      )[2]
                                  )
                                : input.fecha_inicio
                        }
                        onChange={(e) =>
                            setInput({
                                ...input,
                                fecha_inicio: `${e.getFullYear()}-${`${
                                    e.getMonth() + 1
                                }`.padStart(
                                    2,
                                    "0"
                                )}-${`${e.getDate()}`.padStart(2, "0")}`,
                            })
                        }
                    />
                </div>

                <div className="flex flex-col ">
                    <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                        Fecha final:
                    </label>
                    <DatePicker
                        className="w-full p-2 mx-auto rounded-lg cursor-pointer  border-[#e5e7eb] border "
                        placeholderText="Fecha final"
                        locale="es"
                        autoComplete="off"
                        dateFormat="dd/MM/yyyy"
                        selected={
                            input.fecha_final !== ""
                                ? new Date(
                                      dividirFechaDatePicker(
                                          input.fecha_final
                                      )[0],
                                      dividirFechaDatePicker(
                                          input.fecha_final
                                      )[1] - 1,
                                      dividirFechaDatePicker(
                                          input.fecha_final
                                      )[2]
                                  )
                                : input.fecha_final
                        }
                        onChange={(e) =>
                            setInput({
                                ...input,
                                fecha_final: `${e.getFullYear()}-${`${
                                    e.getMonth() + 1
                                }`.padStart(
                                    2,
                                    "0"
                                )}-${`${e.getDate()}`.padStart(2, "0")}`,
                            })
                        }
                    />
                </div>
                <div onClick={() => getMatriz()} className="flex items-end ">
                    <p className="w-full p-2 text-center text-white bg-blue-500 border border-blue-500 rounded-lg cursor-pointer hover:bg-blue-400">
                        Buscar
                    </p>
                </div>
            </div>
            <div className="relative mx-6 border my-7 lg:mx-32 xl:mx-52 2xl:mx-72 bg-slate-100">
                <ModalVerMas
                    setRefreshMatriz={setRefreshMatriz}
                    cuadrante={cuadranteSelected}
                    modalVisible={modalVerMasVisible}
                    setModalVisible={setModalVerMasVisible}
                    setCuadranteSelected={setCuadranteSelected}
                />

                <div className="grid grid-cols-6 grid-rows-6">
                    {Object.keys(cuadrantes).map((cuadrante) => (
                        <CardExperience
                            key={cuadrantes[cuadrante].id_matriz}
                            cuadrante={cuadrantes[cuadrante]}
                            setCuadranteSelected={setCuadranteSelected}
                            setModalVerMasVisible={setModalVerMasVisible}
                        />
                    ))}
                </div>

                {/* INDICACIONES CON POSICIÓN RELATIVA */}
                <span className="absolute w-full font-bold text-center -top-5">
                    Precio máximo soles
                </span>
                <span className="absolute w-full font-bold text-center -bottom-5">
                    Precio mínimo soles
                </span>
                <div className="absolute top-0 flex items-center justify-center h-full font-bold -left-9 md:-left-11">
                    <span className="flex text-center -rotate-90">Campo</span>
                </div>
                <div className="absolute top-0 flex items-center justify-center h-full font-bold -right-8 md:-right-11">
                    <span className="flex text-center rotate-90">Playa</span>
                </div>
                {/*FIN INDICACIONES CON POSICIÓN RELATIVA */}

                {/* CARTELES DE PRECIOS CON POSICIÓN RELATIVA */}
                <span className="absolute top-0 flex justify-center w-full">
                    <p className="w-32 py-2 font-bold tracking-wider text-center bg-white border border-gray-600">
                        S/. 11304
                    </p>
                </span>
                <span className="font-bold absolute top-[6.8rem] flex justify-center w-full">
                    <p className="w-32 py-2 tracking-wider text-center bg-white border border-gray-600">
                        S/. 3174
                    </p>
                </span>
                <span className="font-bold absolute top-[14.8rem] flex justify-center w-full">
                    <p className="w-32 py-2 tracking-wider text-center bg-white border border-gray-600">
                        S/. 2163
                    </p>
                </span>
                <span className="font-bold absolute top-[22.8rem] flex justify-center w-full">
                    <p className="w-32 py-2 tracking-wider text-center bg-white border border-gray-600">
                        S/. 1532
                    </p>
                </span>
                <span className="font-bold absolute top-[30.8rem] flex justify-center w-full">
                    <p className="w-32 py-2 tracking-wider text-center bg-white border border-gray-600">
                        S/. 1040
                    </p>
                </span>
                <span className="font-bold absolute top-[38.8rem] flex justify-center w-full">
                    <p className="w-32 py-2 tracking-wider text-center bg-white border border-gray-600">
                        S/. 537
                    </p>
                </span>
                {/* FIN CARTELES DE PRECIOS CON POSICIÓN RELATIVA */}
            </div>

            <div className="mx-6 font-medium my-7 lg:mx-32 xl:mx-52 2xl:mx-72">
                <div className="flex flex-col gap-8 md:flex-row md:justify-between">
                    <div className="flex flex-col">
                        <span>BE = Blue Experience</span>
                        <span>
                            Mediana puede cambiar con la variación de precios
                            (mínimo y máximo)
                        </span>
                        <div className="flex flex-col gap-1 mt-3">
                            <div className="flex items-center gap-2">
                                <span
                                    className="px-16 py-3"
                                    style={{
                                        backgroundColor: COLORS.green,
                                    }}></span>
                                <span>Cumple con las expectativas</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <span
                                    className="px-16 py-3"
                                    style={{
                                        backgroundColor: COLORS.yellow,
                                    }}></span>
                                <span>Expectativas observadas</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <span
                                    className="px-16 py-3"
                                    style={{
                                        backgroundColor: COLORS.red,
                                    }}></span>
                                <span>No cumple con las expectativas</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            C1 = Casa de costo moderado con{" "}
                            <b>calificación baja</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.red,
                            }}>
                            C2 = Casa de costo elevado con{" "}
                            <b>calificación baja</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.red,
                            }}>
                            C3 = Casa de costo muy elevado con{" "}
                            <b>calificación baja</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            B1 = Casa de costo moderado con{" "}
                            <b>calificación media</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.yellow,
                            }}>
                            B2 = Casa de costo elevado con{" "}
                            <b>calificación media</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.red,
                            }}>
                            B3 = Casa de costo muy elevado con{" "}
                            <b>calificación media</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            A1 = Casa de costo moderado con{" "}
                            <b>calificación alta</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            A2 = Casa de costo elevado con{" "}
                            <b>calificación alta</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            A3 = Casa de costo muy elevado con{" "}
                            <b>calificación alta</b>
                        </span>
                    </div>
                    {/* SEGUNDA COLUMNA A PARTIR DE ACA */}
                    <div className="flex flex-col">
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.yellow,
                            }}>
                            c1 = Casa de costo moderado con{" "}
                            <b>calificación baja</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.yellow,
                            }}>
                            c2 = Casa de costo bajo con <b>calificación baja</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.yellow,
                            }}>
                            c3 = Casa de costo muy bajo con{" "}
                            <b>calificación baja</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.yellow,
                            }}>
                            b1 = Casa de costo moderado bajo con{" "}
                            <b>calificación media</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            b2 = Casa de costo bajo elevado con{" "}
                            <b>calificación media</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            b3 = Casa de costo muy bajo con{" "}
                            <b>calificación media</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            a1 = Casa de costo moderado bajo con{" "}
                            <b>calificación alta</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            a2 = Casa de costo bajo con <b>calificación alta</b>
                        </span>
                        <span
                            className="px-10 py-1 border-2"
                            style={{
                                backgroundColor: COLORS.green,
                            }}>
                            a3 = Casa de costo muy bajo con{" "}
                            <b>calificación alta</b>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlueExperience;
