import axios from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const EditarPoliticas = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        getPolitica(producto.data.id);
    }, []);

    const getPolitica = async (id) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/productoTipoContrato/all/${id}`,
            { headers }
        );

        let aux = data.map((c) => {
            return c.nombre;
        });

        if (aux.length > 0) {
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    tipo_contato: aux,
                },
            });
        }
    };

    console.log(producto);

    const updateProduct = async () => {
        if (tipo_producto === "casa") {
            const form = new FormData();
            form.append("cancelacion", producto.data.cancelacion);
            form.append(
                "porcentaje_moderado",
                producto.data.porcentaje_moderado
            );
            form.append("id_usuario", authUser.id_usuario);
            const jsonCancelacion = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarCancelacion/${producto.data.id}`,
                form,
                { headers }
            );
            console.log(jsonCancelacion);
            if (jsonCancelacion.data.message === "actuaizacion exitoso") {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        cancelacion: jsonCancelacion.data.data.cancelacion,
                    },
                });
            }
            setModal(false);
        } else if (tipo_producto === "embarcacion") {
            const form = new FormData();
            form.append("cancelacion", producto.data.cancelacion);
            form.append(
                "porcentaje_moderado",
                producto.data.porcentaje_moderado
            );
            form.append("id_usuario", authUser.id_usuario);
            const jsonCancelacion = await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacion/actualizarCancelacion/${producto.data.id}`,
                form,
                { headers }
            );
            console.log(jsonCancelacion);
            if (jsonCancelacion.data.message === "actuaizacion exitoso") {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        cancelacion: jsonCancelacion.data.data.cancelacion,
                    },
                });
            }
            setModal(false);
        }
    };

    const handleGuardar = () => {
        if (!producto.data.cancelacion) {
            Swal.fire({
                title: "Debes seleccionar una politicas de cancelacion",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });
        } else if (
            producto.data.cancelacion === "Moderado" &&
            producto.data.porcentaje_moderado <= 0
        ) {
            Swal.fire({
                title: "Debes ingresar un porcentaje de cancelacion",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });
        } else {
            updateProduct();
        }
    };

    return (
        <div className="p-4 border rounded-lg">
            <h1>Política de cancelación</h1>

            <div className="w-full mt-3">
                <div className="h-[55vh] overflow-hidden">
                    <div className="h-[55vh] overflow-auto w-[105%]">
                        <div className="w-full mt-4 ">
                            <div className="flex justify-center ">
                                <div
                                    onClick={() =>
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                cancelacion: "Flexible",
                                            },
                                        })
                                    }
                                    className={`border border-[#E9E9E9] w-6 h-6 rounded-full mr-2 cursor-pointer ${
                                        producto.data.cancelacion === "Flexible"
                                            ? "bg-blue-bluecorner"
                                            : "bg-white"
                                    }`}></div>
                                <div className="w-72 ">
                                    <p className="text-base font-bold">
                                        Flexible
                                    </p>
                                    <p className="text-base">
                                        Acepta cancelaciones con 72 horas antes
                                        del inicio del alquiler y devuelve el
                                        100% del dinero al cliente.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-5 ">
                            <div className="flex justify-center ">
                                <div
                                    onClick={() =>
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                cancelacion: "Moderado",
                                            },
                                        })
                                    }
                                    className={`border border-[#E9E9E9] w-6 h-6 rounded-full mr-2 cursor-pointer ${
                                        producto.data.cancelacion === "Moderado"
                                            ? "bg-blue-bluecorner"
                                            : "bg-white"
                                    }`}></div>
                                <div className="w-72 ">
                                    <p className="text-base font-bold">
                                        Moderado
                                    </p>
                                    <p className="text-base">
                                        Acepta cancelaciones con 72 horas antes
                                        del inicio del alquiler y devuelve un
                                        porcentaje determinado por el
                                        propietario.
                                    </p>
                                </div>
                            </div>
                            {producto.data.cancelacion === "Moderado" && (
                                <>
                                    <div className="flex justify-center">
                                        <p className="text-base font-semibold ">
                                            ¿Cual es el porcentaje a reembolsar?
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-center w-64 px-3 py-3 mx-auto border rounded-lg">
                                        <input
                                            autoComplete="off"
                                            className="w-full rounded-lg outline-none"
                                            placeholder="15"
                                            name="warrantyTotal"
                                            type="number"
                                            step=".01"
                                            onChange={(e) =>
                                                setProducto({
                                                    ...producto,
                                                    data: {
                                                        ...producto.data,
                                                        porcentaje_moderado:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                            value={
                                                producto.data
                                                    .porcentaje_moderado || ""
                                            }></input>
                                        <span>
                                            <svg
                                                className="w-4 h-4 text-[#3C3C3C] rounded-lg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <line
                                                    x1="19"
                                                    y1="5"
                                                    x2="5"
                                                    y2="19"
                                                />{" "}
                                                <circle
                                                    cx="6.5"
                                                    cy="6.5"
                                                    r="2.5"
                                                />{" "}
                                                <circle
                                                    cx="17.5"
                                                    cy="17.5"
                                                    r="2.5"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="w-full mt-5 ">
                            <div className="flex justify-center ">
                                <div
                                    onClick={() =>
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                cancelacion: "Reprogramación",
                                            },
                                        })
                                    }
                                    className={`border border-[#E9E9E9] w-6 h-6 rounded-full mr-2 cursor-pointer ${
                                        producto.data.cancelacion ===
                                        "Reprogramación"
                                            ? "bg-blue-bluecorner"
                                            : "bg-white"
                                    }`}></div>
                                <div className="w-72 ">
                                    <p className="text-base font-bold">
                                        Reprogramación
                                    </p>
                                    <p className="text-base">
                                        Acepta cambios con 72 horas del inicio
                                        del alquiler, da como crédito el 100%
                                        del dinero al cliente y este será
                                        utilizado en un plazo máximo de 12
                                        meses. Dos reprogramaciones como máximo.
                                        Cabe señalar que por cada
                                        reprogramación, el cliente deberá pagar
                                        20% del total del alquiler como fee
                                        administrativo a Blue Corner.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-5 mb-4 ">
                            <div className="flex justify-center ">
                                <div
                                    onClick={() =>
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                cancelacion: "Inflexible",
                                            },
                                        })
                                    }
                                    className={`border border-[#E9E9E9] w-6 h-6 rounded-full mr-2 cursor-pointer ${
                                        producto.data.cancelacion ===
                                        "Inflexible"
                                            ? "bg-blue-bluecorner"
                                            : "bg-white"
                                    }`}></div>
                                <div className="w-72 ">
                                    <p className="text-base font-bold">
                                        Inflexible
                                    </p>
                                    <p className="text-base">
                                        No se aceptan devoluciones ni
                                        reprogramaciones.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarPoliticas;
