import React, { useEffect, useState, useRef } from "react";
import BLUECORNER_PNG2 from "../../img/Home/BLUECORNER_PNG-2.png";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import Swal from "sweetalert2";
import { io } from "socket.io-client";
import ModalLogeo from "../alquilerScreen/alquilerAlojamiento/ModalLogeo";
import pdfIcon from "../../assets/typeFilesImages/pdf.png";
import excelIcon from "../../assets/typeFilesImages/excel.png";
import ImagenFullScreen from "./ImagenFullScreen";

const Conversation = () => {
    const [message, setMessage] = useState("");
    const authUser = useSelector((state) => state.authReducer);
    const { tipo, id_pedido } = useParams();
    const [pedido, setPedido] = useState(null);
    const [infoChat, setInfoChat] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [usersOnline, setUsersOnline] = useState([]);
    const [conversation, setConversation] = useState({
        conversacion: {},
        usuarios: [],
        conversacion_id: 0,
    });
    const [socket, setSocket] = useState(null);
    const [logueado, setLogueado] = useState(false);
    const [modalLogeo, setModalLogeo] = useState(false);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [imagenSelected, setImagenSelected] = useState(null);
    const [modalFullScreen, setModalFullScreen] = useState(false);
    const navigate = useNavigate();
    const scrollRef = useRef();

    useEffect(() => {
        if (socket && conversation.conversacion_id) {
            if (tipo === "embarcacion") {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/conversacionEmbarcacion/updateOnlineUser`,
                        {
                            id_conversacion_embarcacion:
                                conversation.conversacion_id,
                            id_usuario: authUser.id_usuario,
                            is_online: "Si",
                        },
                        { headers }
                    )
                    .then((response) => {
                        socket.emit("joinRoom", {
                            chatroomId: conversation.conversacion_id,
                        });
                        axios.put(
                            `${process.env.REACT_APP_PROXY}/conversacionEmbarcacion/MessageSeen`,
                            {
                                id_conversacion_embarcacion:
                                    conversation.conversacion_id,
                                id_usuario: authUser.id_usuario,
                            },
                            { headers }
                        );
                    });
            } else if (tipo === "casa") {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/conversacionCasa/updateOnlineUser`,
                        {
                            id_conversacion_casa: conversation.conversacion_id,
                            id_usuario: authUser.id_usuario,
                            is_online: "Si",
                        },
                        { headers }
                    )
                    .then((response) => {
                        socket.emit("joinRoom", {
                            chatroomId: conversation.conversacion_id,
                        });
                        axios.put(
                            `${process.env.REACT_APP_PROXY}/conversacionCasa/MessageSeen`,
                            {
                                id_conversacion_casa:
                                    conversation.conversacion_id,
                                id_usuario: authUser.id_usuario,
                            },
                            { headers }
                        );
                    });
            }
        }

        return () => {
            if (socket && conversation.conversacion_id) {
                if (tipo === "casa") {
                    axios
                        .put(
                            `${process.env.REACT_APP_PROXY}/conversacionCasa/updateOnlineUser`,
                            {
                                id_conversacion_casa:
                                    conversation.conversacion_id,
                                id_usuario: authUser.id_usuario,
                                is_online: "No",
                            },
                            { headers }
                        )
                        .then((response) => {
                            socket.emit("leaveRoom", {
                                chatroomId: conversation.conversacion_id,
                            });
                            socket.on("users-online", (text) => {
                                getAllUsersConnected().then((response) => {
                                    setUsersOnline(response);
                                });
                            });
                        });
                } else if (tipo === "embarcacion") {
                    axios
                        .put(
                            `${process.env.REACT_APP_PROXY}/conversacionEmbarcacion/updateOnlineUser`,
                            {
                                id_conversacion_embarcacion:
                                    conversation.conversacion_id,
                                id_usuario: authUser.id_usuario,
                                is_online: "No",
                            },
                            { headers }
                        )
                        .then((response) => {
                            socket.emit("leaveRoom", {
                                chatroomId: conversation.conversacion_id,
                            });
                            socket.on("users-online", (text) => {
                                getAllUsersConnected().then((response) => {
                                    setUsersOnline(response);
                                });
                            });
                        });
                }
            }
        };
    }, [conversation.conversacion_id, socket]);

    useEffect(() => {
        if (!authUser.id_usuario) {
            setLogueado(false);
        } else {
            setLogueado(true);
            setCurrentUser(authUser);
        }
    }, [authUser]);

    const getAllUsersConnected = async () => {
        const allUsers = await axios(
            `${process.env.REACT_APP_PROXY}/${
                tipo === "casa" ? "conversacionCasa" : "conversacionEmbarcacion"
            }/getAllUsersOnline/${conversation.conversacion_id}`,
            { headers }
        );

        return allUsers.data.users_online;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id_pedido) {
            getPedido(id_pedido);
            getConversation(id_pedido);
        }
    }, [id_pedido]);

    useEffect(() => {
        if (socket && conversation.conversacion_id) {
            socket.on("users-online", (text) => {
                getAllUsersConnected().then((response) => {
                    setUsersOnline(response);
                });
            });
        }
    }, [socket, conversation.conversacion_id]);

    useEffect(() => {
        if (socket) {
            socket.on(
                "msg-recieve",
                ({
                    message,
                    id_usuario,
                    usuario,
                    registro_mensaje,
                    tipo_mensaje,
                }) => {
                    setArrivalMessage({
                        mensaje: message,
                        id_usuario: id_usuario,
                        usuario: usuario,
                        tipo_mensaje: tipo_mensaje,
                        registro_mensaje: registro_mensaje,
                    });

                    updateMessageSeen();
                }
            );
        }
    }, [socket]);

    const updateMessageSeen = async () => {
        if (tipo === "casa") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/conversacionCasa/MessageSeen`,
                {
                    id_conversacion_casa: conversation.conversacion_id,
                    id_usuario: authUser.id_usuario,
                },
                { headers }
            );
        } else if (tipo === "embarcacion") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/conversacionEmbarcacion/MessageSeen`,
                {
                    id_conversacion_embarcacion: conversation.conversacion_id,
                    id_usuario: authUser.id_usuario,
                },
                { headers }
            );
        }
    };

    useEffect(() => {
        if (arrivalMessage) {
            if (!conversation.conversacion[0].mensaje) {
                console.log("mensaje");
                setConversation({
                    ...conversation,
                    conversacion: [
                        {
                            ...conversation.conversacion[0],
                            id_usuario: arrivalMessage.id_usuario,
                            mensaje: arrivalMessage.mensaje,
                            tipo_mensaje: arrivalMessage.tipo_mensaje,
                            registro_mensaje: arrivalMessage.registro_mensaje,
                            usuario: arrivalMessage.usuario,
                        },
                    ],
                });
            } else {
                console.log("imagen");
                setConversation({
                    ...conversation,
                    conversacion: [
                        ...conversation.conversacion,
                        arrivalMessage,
                    ],
                });
            }

            setArrivalMessage(null);
        }
    }, [arrivalMessage]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [conversation, scrollRef.current]);

    const getPedido = async (id_pedido) => {
        if (tipo === "casa") {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/pedidoCasa/detalle/${id_pedido}`,
                { headers }
            );
            setPedido(data);
        } else if (tipo === "embarcacion") {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/pedidoEmbarcacion/detalle/${id_pedido}`,
                { headers }
            );
            setPedido(data);
        }
    };

    const getConversation = async (id_pedido) => {
        if (tipo === "casa") {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/conversacionCasa/getConversacion/${id_pedido}`,
                { headers }
            );

            setConversation({
                ...data,
                conversacion_id: data.conversacion[0].id,
            });

            if (!socket) {
                setSocket(
                    io(process.env.REACT_APP_PROXY_ARCHIVOS, {
                        auth: {
                            token: JSON.stringify({
                                room_id: data.conversacion[0].id,
                                user_id: authUser.id_usuario,
                            }),
                        },
                    })
                );
            }
        } else if (tipo === "embarcacion") {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/conversacionEmbarcacion/getConversacion/${id_pedido}`,
                { headers }
            );

            setConversation({
                ...data,
                conversacion_id: data.conversacion[0].id,
            });

            if (!socket) {
                setSocket(
                    io(process.env.REACT_APP_PROXY_ARCHIVOS, {
                        auth: {
                            token: JSON.stringify({
                                room_id: data.conversacion[0].id,
                                user_id: authUser.id_usuario,
                            }),
                        },
                    })
                );
            }
        }
    };

    const isAllowed = () => {
        let allow = false;

        if (authUser.tipo_usuario < 3) {
            allow = true;
        }
        conversation.usuarios?.forEach((user) => {
            if (user.id_usuario === authUser.id_usuario) allow = true;
        });

        return allow;
    };

    const getUsuarios = () => {
        const [user1, user2] = conversation.usuarios?.filter((user) => {
            if (user.tipo_usuario_conversacion !== "BlueCorner") {
                return user;
            }
        });

        return (
            <div>
                <span className="mr-1">Chat entre</span>
                <b>
                    {user1.usuario} <span className="font-normal">y</span>{" "}
                    {user2.usuario}.
                </b>
            </div>
        );
    };

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleNewMessage = async (uploadedFile = null) => {
        const [user1, user2, user3, user4] = conversation.usuarios?.map(
            (user) => {
                return user.id_usuario;
            }
        );

        if (
            authUser.id_usuario !== user1 &&
            authUser.id_usuario !== user2 &&
            authUser.id_usuario !== user3 &&
            authUser.id_usuario !== user4
        ) {
            Swal.fire("No tienes autorización para escribir en este chat");
        } else {
            if (tipo === "casa") {
                let new_message;
                if (uploadedFile) {
                    new_message = new FormData();

                    new_message.append(
                        "id_conversacion_casa",
                        conversation.conversacion[0].id
                    );
                    new_message.append("id_usuario", authUser.id_usuario);
                    new_message.append(
                        "tipo_mensaje",
                        uploadedFile.name.split(".")[1]
                    );
                    new_message.append("mensaje_casa", uploadedFile);
                    new_message.append("casa", conversation.pedido.casa);
                    new_message.append(
                        "fecha_inicio",
                        conversation.pedido.fecha_inicio
                    );
                    new_message.append(
                        "fecha_final",
                        conversation.pedido.fecha_final
                    );
                    new_message.append("id_pedido", id_pedido);
                    conversation.usuarios.forEach(
                        (user) =>
                            user.id_usuario === authUser.id_usuario &&
                            new_message.append(
                                "usuario_que_escribio",
                                JSON.stringify(user)
                            )
                    );
                    conversation.usuarios.forEach(
                        (user) =>
                            user.id_usuario !== authUser.id_usuario &&
                            new_message.append("usuarios", JSON.stringify(user))
                    );
                } else {
                    if (message.trim() === "") {
                        return;
                    }
                    new_message = {
                        id_conversacion_casa: conversation.conversacion[0].id,
                        id_usuario: authUser.id_usuario,
                        mensaje: message,
                        tipo_mensaje: "texto",
                        casa: conversation.pedido.casa,
                        fecha_inicio: conversation.pedido.fecha_inicio,
                        fecha_final: conversation.pedido.fecha_final,
                        id_pedido: id_pedido,
                        usuario_que_escribio: conversation.usuarios?.filter(
                            (user) => user.id_usuario === authUser.id_usuario
                        ),
                        usuarios: conversation.usuarios?.filter(
                            (user) => user.id_usuario !== authUser.id_usuario
                        ),
                    };
                }

                setMessage("");

                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/conversacionCasa/insertMessage`,
                    new_message,
                    { headers }
                );

                if (data.message === "Mensaje guardado con exito") {
                    socket.emit("send-msg", {
                        id_from: currentUser.id_usuario,
                        chatroomId: conversation.conversacion_id,
                        tipo_mensaje: data.tipo_mensaje,
                        message: data.mensaje,
                        registro_mensaje: "ahora mismo",
                        usuario: conversation.usuarios.find(
                            (user) => user.id_usuario === authUser.id_usuario
                        ).usuario,
                    });
                }
            } else if (tipo === "embarcacion") {
                let new_message;
                if (uploadedFile) {
                    new_message = new FormData();

                    new_message.append(
                        "id_conversacion_embarcacion",
                        conversation.conversacion[0].id
                    );
                    new_message.append("id_usuario", authUser.id_usuario);
                    new_message.append(
                        "tipo_mensaje",
                        uploadedFile.name.split(".")[1]
                    );
                    new_message.append("mensaje_embarcacion", uploadedFile);
                    new_message.append(
                        "embarcacion",
                        conversation.pedido.embarcacion
                    );
                    new_message.append("fecha", conversation.pedido.fecha);
                    new_message.append("id_pedido", id_pedido);
                    conversation.usuarios.forEach(
                        (user) =>
                            user.id_usuario === authUser.id_usuario &&
                            new_message.append(
                                "usuario_que_escribio",
                                JSON.stringify(user)
                            )
                    );
                    conversation.usuarios.forEach(
                        (user) =>
                            user.id_usuario !== authUser.id_usuario &&
                            new_message.append("usuarios", JSON.stringify(user))
                    );
                } else {
                    if (message.trim() === "") {
                        return;
                    }
                    new_message = {
                        id_conversacion_embarcacion:
                            conversation.conversacion[0].id,
                        id_usuario: authUser.id_usuario,
                        tipo_mensaje: "texto",
                        embarcacion: conversation.pedido.embarcacion,
                        fecha: conversation.pedido.fecha,
                        id_pedido: id_pedido,
                        usuario_que_escribio: conversation.usuarios?.filter(
                            (user) => user.id_usuario === authUser.id_usuario
                        ),
                        usuarios: conversation.usuarios?.filter(
                            (user) => user.id_usuario !== authUser.id_usuario
                        ),
                        mensaje: message,
                    };
                }

                setMessage("");

                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/conversacionEmbarcacion/insertMessage`,
                    new_message,
                    { headers }
                );
                console.log(data);

                if (data.message === "Mensaje guardado con exito") {
                    socket.emit("send-msg", {
                        id_from: currentUser.id_usuario,
                        chatroomId: conversation.conversacion_id,
                        tipo_mensaje: data.tipo_mensaje,
                        message: data.mensaje,
                        registro_mensaje: "ahora mismo",
                        usuario: conversation.usuarios.find(
                            (user) => user.id_usuario === authUser.id_usuario
                        ).usuario,
                    });
                }
            }
        }
    };

    const messageIsImage = (extension) => {
        if (
            extension === "jpeg" ||
            extension === "jpg" ||
            extension === "png" ||
            extension === "tif"
        ) {
            return true;
        }

        return false;
    };

    const typeOfMessage = (extension) => {
        switch (extension) {
            case "pdf":
                return "pdf";
            case "doc":
                return "word";
            case "docx":
                return "word";
            case "docm":
                return "word";
            case "dot":
                return "word";
            case "xla":
                return "excel";
            case "xlam":
                return "excel";
            case "xll":
                return "excel";
            case "xlm":
                return "excel";
            case "xls":
                return "excel";
            case "xlsm":
                return "excel";
            case "xlsx":
                return "excel";
            case "xlt":
                return "excel";
            default:
                return "archivo";
        }
    };

    if (!pedido || !conversation.usuarios.length > 0) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spinner />
            </div>
        );
    } else if (pedido && conversation.usuarios.length > 0) {
        if (!isAllowed() || !logueado) {
            return (
                <div className="w-full h-screen bg-slate-100 flex flex-col justify-center items-center">
                    {modalLogeo && (
                        <ModalLogeo
                            modalLogeo={modalLogeo}
                            setModalLogeo={setModalLogeo}
                            id_pedido={id_pedido}
                            tipo={tipo}
                        />
                    )}
                    {logueado ? (
                        <>
                            {" "}
                            <div className="p-4">
                                <h1 className="font-medium text-center text-base lg:text-lg">
                                    No tienes autorización para participar en
                                    este chat
                                </h1>
                            </div>
                            <Link
                                to={"/"}
                                className="font-medium mt-2 border p-3 rounded-lg border-blue-bluecorner text-blue-bluecorner hover:bg-slate-50 active:translate-y-1"
                            >
                                Ir al inicio
                            </Link>
                        </>
                    ) : (
                        <>
                            <div className="p-4">
                                <h1 className="font-medium text-center text-base lg:text-lg">
                                    Debe iniciar sesión para poder participar en
                                    este chat
                                </h1>
                            </div>
                            <button
                                onClick={() => setModalLogeo(true)}
                                className="font-medium mt-2 border p-3 rounded-lg border-blue-bluecorner text-blue-bluecorner hover:bg-slate-50 active:translate-y-1"
                            >
                                Iniciar sesión
                            </button>
                        </>
                    )}
                </div>
            );
        } else
            return (
                <div className="w-full min-h-screen bg-slate-100">
                    {modalFullScreen && imagenSelected && (
                        <ImagenFullScreen
                            imagenSelected={imagenSelected}
                            modalVisible={modalFullScreen}
                            setModalVisible={setModalFullScreen}
                            setImagenSelected={setImagenSelected}
                            tipo_conversacion={tipo}
                        />
                    )}
                    <div className="flex h-full">
                        <div className="flex-1 bg-slate-100 w-full min-h-full h-full ">
                            {pedido && conversation && (
                                <div className="main-body container m-auto w-11/12 h-full flex flex-col">
                                    <div className="main flex-1 flex flex-col">
                                        <div className="  heading flex-2 p-2">
                                            <div className="text-3xl text-gray-700 mb-4 bg-blue-bluecorner rounded-lg p-1">
                                                <img
                                                    alt="Logo bluecorner"
                                                    src={BLUECORNER_PNG2}
                                                    onClick={() =>
                                                        navigate("/")
                                                    }
                                                    className=" mr-2 cursor-pointer h-20 lg:mx-5 2xl:mr-10"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="flex items-center text-lg w-max cursor-pointer hover:underline hover:scale-105"
                                            onClick={() => navigate(-1)}
                                        >
                                            <svg
                                                className="w-6 h-6 text-black"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <polyline points="15 18 9 12 15 6" />
                                            </svg>
                                            <span className="mx-1">
                                                Regresar
                                            </span>
                                        </div>
                                        <div className="flex-1 flex flex-col md:flex-row h-full">
                                            <span
                                                className="flex items-center text-lg w-max cursor-pointer hover:underline hover:scale-105 md:hidden"
                                                onClick={() =>
                                                    setInfoChat((prev) => !prev)
                                                }
                                            >
                                                <svg
                                                    className={`${
                                                        infoChat === true
                                                            ? "-rotate-90"
                                                            : "-rotate-180"
                                                    } w-6 h-6 text-black mt-1 mr-1`}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    <polyline points="15 18 9 12 15 6" />
                                                </svg>{" "}
                                                Ver info de chat
                                            </span>
                                            {infoChat && (
                                                <div
                                                    className={`${
                                                        infoChat
                                                            ? "block"
                                                            : "hidden"
                                                    } transition ease-in-out flex-1 h-full px-2 mt-3 md:hidden `}
                                                >
                                                    <div className="grid grid-cols-2 gap-2">
                                                        {" "}
                                                        {conversation.usuarios.map(
                                                            (user) => (
                                                                <div
                                                                    key={
                                                                        user.id_usuario
                                                                    }
                                                                    className="w-full entry cursor-pointer transform hover:scale-105 duration-300 transition-transform bg-white mb-4 rounded-lg p-4 flex shadow-md"
                                                                >
                                                                    <div className="flex-2">
                                                                        <div className="w-12 h-12 relative">
                                                                            <div className="w-12 h-12 relative mr-2">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${user.avatar}`}
                                                                                    className="rounded-full p-[0.050rem] w-12 h-12 border-[3px] object-cover cursor-pointer border-blue-bluecorner"
                                                                                    alt="avatar de perfil"
                                                                                />
                                                                            </div>
                                                                            <span
                                                                                className={`absolute ${
                                                                                    usersOnline.includes(
                                                                                        user.id_usuario
                                                                                    )
                                                                                        ? "bg-green-400"
                                                                                        : "bg-gray-300"
                                                                                } -bottom-1.5 -right-1 w-3.5 h-3.5 rounded-full`}
                                                                            ></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-1 px-2 overflow-hidden">
                                                                        <div className="truncate">
                                                                            <span className="text-gray-800">
                                                                                {
                                                                                    user.usuario
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <span className="text-xs text-gray-800">
                                                                            {
                                                                                user.tipo_usuario_conversacion
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="flex flex-col entry cursor-pointer transform hover:scale-105 duration-300 transition-transform bg-white mb-4 rounded-lg p-4 shadow-md">
                                                        <div>
                                                            {tipo === "casa" ? (
                                                                <img
                                                                    className="rounded-lg object-cover"
                                                                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${conversation.pedido.imagen_principal}`}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className="rounded-lg object-cover"
                                                                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/embarcacion/getImagenPrincipal/${conversation.pedido.imagen_principal}`}
                                                                />
                                                            )}
                                                        </div>
                                                        <div>
                                                            Reserva para{" "}
                                                            <span className="mx-1 font-semibold">
                                                                {tipo === "casa"
                                                                    ? conversation
                                                                          .pedido
                                                                          .casa
                                                                    : conversation
                                                                          .pedido
                                                                          .embarcacion}
                                                            </span>
                                                        </div>
                                                        {tipo === "casa" && (
                                                            <div className="mt-1">
                                                                <div>
                                                                    Desde el día{" "}
                                                                    {
                                                                        conversation
                                                                            .pedido
                                                                            .fecha_inicio
                                                                    }
                                                                </div>
                                                                <div>
                                                                    Hasta el dia{" "}
                                                                    {
                                                                        conversation
                                                                            .pedido
                                                                            .fecha_final
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        {tipo ===
                                                            "embarcacion" && (
                                                            <div className="mt-1">
                                                                <div>
                                                                    Reserva el
                                                                    día{" "}
                                                                    {
                                                                        conversation
                                                                            .pedido
                                                                            .fecha
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="sidebar hidden lg:flex w-1/3 flex-2 flex-col pr-6">
                                                <div className="flex mt-3 flex-col entry cursor-pointer transform hover:scale-105 duration-300 transition-transform bg-white mb-4 rounded-lg p-4 shadow-md">
                                                    <div>
                                                        {tipo === "casa" ? (
                                                            <img
                                                                className="rounded-lg object-cover"
                                                                src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${conversation.pedido?.imagen_principal}`}
                                                            />
                                                        ) : (
                                                            <img
                                                                className="rounded-lg object-cover"
                                                                src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/embarcacion/getImagenPrincipal/${conversation.pedido?.imagen_principal}`}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="mt-2">
                                                        Reserva para{" "}
                                                        <span className="mx-1 font-semibold">
                                                            {tipo === "casa"
                                                                ? conversation
                                                                      .pedido
                                                                      ?.casa
                                                                : conversation
                                                                      .pedido
                                                                      ?.embarcacion}
                                                        </span>
                                                    </div>
                                                    {tipo === "casa" && (
                                                        <div className="mt-1">
                                                            <div>
                                                                Desde el día{" "}
                                                                {
                                                                    conversation
                                                                        .pedido
                                                                        ?.fecha_inicio
                                                                }
                                                            </div>
                                                            <div>
                                                                Hasta el dia{" "}
                                                                {
                                                                    conversation
                                                                        .pedido
                                                                        ?.fecha_final
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                    {tipo === "embarcacion" && (
                                                        <div className="mt-1">
                                                            <div>
                                                                Reserva el día{" "}
                                                                {
                                                                    conversation
                                                                        .pedido
                                                                        ?.fecha
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex-1 h-full mt-1">
                                                    {conversation.usuarios?.map(
                                                        (user) => (
                                                            <div
                                                                key={
                                                                    user.id_usuario
                                                                }
                                                                className="entry cursor-pointer transform hover:scale-105 duration-300 transition-transform bg-white mb-4 rounded-lg p-4 flex shadow-md"
                                                            >
                                                                <div className="flex-2">
                                                                    <div className="w-12 h-12 relative">
                                                                        <div className="w-12 h-12 relative mr-2">
                                                                            <img
                                                                                src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${user.avatar}`}
                                                                                className="rounded-full p-[0.050rem] w-12 h-12 border-[3px] object-cover cursor-pointer border-blue-bluecorner"
                                                                                alt="avatar de perfil"
                                                                            />
                                                                        </div>
                                                                        <span
                                                                            className={`absolute ${
                                                                                usersOnline.includes(
                                                                                    user.id_usuario
                                                                                )
                                                                                    ? "bg-green-400"
                                                                                    : "bg-gray-300"
                                                                            } -bottom-1.5 -right-1 w-3.5 h-3.5 rounded-full`}
                                                                        ></span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-1 px-2 overflow-hidden">
                                                                    <div className="truncate">
                                                                        <span className="text-gray-800">
                                                                            {
                                                                                user.usuario
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <span className="text-xs text-gray-800">
                                                                        {
                                                                            user.tipo_usuario_conversacion
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <div className="chat-area flex flex-col w-full mt-1">
                                                <div className="flex flex-col">
                                                    <div className="text-xl py-1.5 mb-6 border-b-2 border-gray-200">
                                                        {authUser.tipo_usuario <
                                                            3 && (
                                                            <div className="flex">
                                                                <span className="mr-1 flex">
                                                                    {getUsuarios()}
                                                                </span>
                                                            </div>
                                                        )}
                                                        {authUser.tipo_usuario >
                                                            2 && (
                                                            <div>
                                                                Chateando con{" "}
                                                                {conversation.usuarios.map(
                                                                    (
                                                                        user,
                                                                        index
                                                                    ) => {
                                                                        if (
                                                                            user.id_usuario !==
                                                                            authUser.id_usuario
                                                                        ) {
                                                                            return (
                                                                                <div
                                                                                    className="inline"
                                                                                    key={
                                                                                        user.id_usuario
                                                                                    }
                                                                                >
                                                                                    <b>
                                                                                        {index ===
                                                                                            conversation
                                                                                                .usuarios
                                                                                                .length -
                                                                                                1 &&
                                                                                            " y "}
                                                                                        {
                                                                                            user.usuario
                                                                                        }
                                                                                        {index !==
                                                                                        conversation
                                                                                            .usuarios
                                                                                            .length -
                                                                                            1
                                                                                            ? ", "
                                                                                            : "."}
                                                                                    </b>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="h-[70vh] relative overflow-auto p-1 chat-messages-container">
                                                    <div
                                                        className={` ${
                                                            conversation
                                                                .conversacion[0]
                                                                .mensaje
                                                                ? "min-h-[90%] max-h-max"
                                                                : "h-[90%]"
                                                        } `}
                                                    >
                                                        {conversation
                                                            .conversacion[0]
                                                            .mensaje !==
                                                        null ? (
                                                            conversation.conversacion.map(
                                                                (
                                                                    message,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        message.id_usuario ===
                                                                        authUser.id_usuario
                                                                    ) {
                                                                        return (
                                                                            <div
                                                                                ref={
                                                                                    index ===
                                                                                    conversation
                                                                                        .conversacion
                                                                                        .length -
                                                                                        1
                                                                                        ? scrollRef
                                                                                        : null
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {message.mensaje && (
                                                                                    <div className="messages my-1">
                                                                                        <div className="message flex text-right">
                                                                                            <div className="flex-1">
                                                                                                {messageIsImage(
                                                                                                    message.tipo_mensaje
                                                                                                ) ? (
                                                                                                    <div className="bg-blue-bluecorner inline-block bg-opacity-60 w-8/12 md:w-1/3 p-2 rounded-md hover:cursor-zoom-in">
                                                                                                        <img
                                                                                                            onClick={() => {
                                                                                                                setImagenSelected(
                                                                                                                    message.mensaje
                                                                                                                );
                                                                                                                setModalFullScreen(
                                                                                                                    true
                                                                                                                );
                                                                                                            }}
                                                                                                            className="rounded-md w-full hover:scale-105"
                                                                                                            src={`${
                                                                                                                process
                                                                                                                    .env
                                                                                                                    .REACT_APP_PROXY
                                                                                                            }/${
                                                                                                                tipo ===
                                                                                                                "casa"
                                                                                                                    ? "conversacionCasa"
                                                                                                                    : "conversacionEmbarcacion"
                                                                                                            }/getArchive/${
                                                                                                                message.mensaje
                                                                                                            }`}
                                                                                                            alt={
                                                                                                                message.mensaje
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                ) : message.tipo_mensaje ===
                                                                                                  "texto" ? (
                                                                                                    <div className="inline-block text-left bg-opacity-70 max-w-[75%] md:max-w-[50%] bg-blue-bluecorner rounded-lg p-2 text-white ">
                                                                                                        <span>
                                                                                                            {
                                                                                                                message.mensaje
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className="inline-block text-left bg-opacity-70 max-w-[75%] md:max-w-[50%] bg-blue-bluecorner rounded-lg p-2 text-white break-all">
                                                                                                        <a
                                                                                                            className="flex items-center justify-center gap-2 hover:underline"
                                                                                                            target={
                                                                                                                "_blank"
                                                                                                            }
                                                                                                            href={`${
                                                                                                                process
                                                                                                                    .env
                                                                                                                    .REACT_APP_PROXY
                                                                                                            }/${
                                                                                                                tipo ===
                                                                                                                "casa"
                                                                                                                    ? "conversacionCasa"
                                                                                                                    : "conversacionEmbarcacion"
                                                                                                            }/getArchive/${
                                                                                                                message.mensaje
                                                                                                            }`}
                                                                                                        >
                                                                                                            {typeOfMessage(
                                                                                                                message.tipo_mensaje
                                                                                                            ) ===
                                                                                                            "word" ? (
                                                                                                                <svg
                                                                                                                    className="w-10 h-10"
                                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                                    x="0px"
                                                                                                                    y="0px"
                                                                                                                    width="48"
                                                                                                                    height="48"
                                                                                                                    viewBox="0 0 48 48"
                                                                                                                >
                                                                                                                    <path
                                                                                                                        fill="#2196F3"
                                                                                                                        d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                                                                                                                    ></path>
                                                                                                                    <path
                                                                                                                        fill="#FFF"
                                                                                                                        d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
                                                                                                                    ></path>
                                                                                                                    <path
                                                                                                                        fill="#0D47A1"
                                                                                                                        d="M27 42L6 38 6 10 27 6z"
                                                                                                                    ></path>
                                                                                                                    <path
                                                                                                                        fill="#FFF"
                                                                                                                        d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
                                                                                                                    ></path>
                                                                                                                </svg>
                                                                                                            ) : typeOfMessage(
                                                                                                                  message.tipo_mensaje
                                                                                                              ) ===
                                                                                                              "pdf" ? (
                                                                                                                <img
                                                                                                                    className="w-10 h-10 p-0.5"
                                                                                                                    src={
                                                                                                                        pdfIcon
                                                                                                                    }
                                                                                                                    alt="Archivo pdf"
                                                                                                                />
                                                                                                            ) : typeOfMessage(
                                                                                                                  message.tipo_mensaje
                                                                                                              ) ===
                                                                                                              "excel" ? (
                                                                                                                <img
                                                                                                                    className="w-10 h-10 p-1"
                                                                                                                    src={
                                                                                                                        excelIcon
                                                                                                                    }
                                                                                                                    alt="Archivo excel"
                                                                                                                />
                                                                                                            ) : null}
                                                                                                            <span>
                                                                                                                {message.mensaje
                                                                                                                    .split(
                                                                                                                        "_"
                                                                                                                    )
                                                                                                                    .slice(
                                                                                                                        1
                                                                                                                    )
                                                                                                                    .join(
                                                                                                                        "_"
                                                                                                                    )}
                                                                                                            </span>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                )}
                                                                                                <div className="px-1">
                                                                                                    <small className="text-xs text-gray-500">
                                                                                                        {
                                                                                                            message.registro_mensaje
                                                                                                        }
                                                                                                    </small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <div
                                                                                ref={
                                                                                    index ===
                                                                                    conversation
                                                                                        .conversacion
                                                                                        .length -
                                                                                        1
                                                                                        ? scrollRef
                                                                                        : null
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {message.mensaje && (
                                                                                    <div className="messages ">
                                                                                        <div
                                                                                            className={`${
                                                                                                messageIsImage(
                                                                                                    message.tipo_mensaje
                                                                                                )
                                                                                                    ? ""
                                                                                                    : "items-center"
                                                                                            } message flex gap-3`}
                                                                                        >
                                                                                            <div className="w-12 h-12">
                                                                                                <div className="relative">
                                                                                                    <img
                                                                                                        src={`${
                                                                                                            process
                                                                                                                .env
                                                                                                                .REACT_APP_PROXY
                                                                                                        }/autenticacion/getImage/${
                                                                                                            conversation.usuarios.find(
                                                                                                                (
                                                                                                                    user
                                                                                                                ) =>
                                                                                                                    user.id_usuario ===
                                                                                                                    message.id_usuario
                                                                                                            )
                                                                                                                .avatar
                                                                                                        }`}
                                                                                                        className="rounded-full p-[0.050rem] w-12 h-12 border-[3px] object-cover cursor-pointer border-blue-bluecorner"
                                                                                                        alt="avatar de perfil"
                                                                                                    />
                                                                                                    <span
                                                                                                        className={`absolute ${
                                                                                                            usersOnline.includes(
                                                                                                                message.id_usuario
                                                                                                            )
                                                                                                                ? "bg-green-400"
                                                                                                                : "bg-gray-300"
                                                                                                        } -bottom-1.5 -right-1 w-3.5 h-3.5 rounded-full`}
                                                                                                    ></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="flex flex-col max-w-[75%] md:max-w-[50%]">
                                                                                                <span className="text-xs text-gray-500 max-w-max">
                                                                                                    {
                                                                                                        message.usuario
                                                                                                    }
                                                                                                </span>
                                                                                                <div
                                                                                                    className={`${
                                                                                                        messageIsImage(
                                                                                                            message.tipo_mensaje
                                                                                                        )
                                                                                                            ? "w-12/12 md:w-9/12"
                                                                                                            : ""
                                                                                                    }  flex mt-1 text-left bg-gray-200 rounded-lg text-gray-700 max-w-max break-all`}
                                                                                                >
                                                                                                    <div className="p-2">
                                                                                                        {message.tipo_mensaje ===
                                                                                                        "texto" ? (
                                                                                                            <span className="flex">
                                                                                                                {
                                                                                                                    message.mensaje
                                                                                                                }
                                                                                                            </span>
                                                                                                        ) : messageIsImage(
                                                                                                              message.tipo_mensaje
                                                                                                          ) ? (
                                                                                                            <div className="flex bg-opacity-60 rounded-md">
                                                                                                                <img
                                                                                                                    onClick={() => {
                                                                                                                        setImagenSelected(
                                                                                                                            message.mensaje
                                                                                                                        );
                                                                                                                        setModalFullScreen(
                                                                                                                            true
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    className="rounded-md w-full hover:cursor-zoom-in hover:scale-105"
                                                                                                                    src={`${
                                                                                                                        process
                                                                                                                            .env
                                                                                                                            .REACT_APP_PROXY
                                                                                                                    }/${
                                                                                                                        tipo ===
                                                                                                                        "casa"
                                                                                                                            ? "conversacionCasa"
                                                                                                                            : "conversacionEmbarcacion"
                                                                                                                    }/getArchive/${
                                                                                                                        message.mensaje
                                                                                                                    }`}
                                                                                                                    alt={
                                                                                                                        message.mensaje
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div className="flex text-left bg-opacity-70 rounded-lg break-all">
                                                                                                                <a
                                                                                                                    className="flex items-center justify-center gap-2 hover:underline"
                                                                                                                    target={
                                                                                                                        "_blank"
                                                                                                                    }
                                                                                                                    href={`${
                                                                                                                        process
                                                                                                                            .env
                                                                                                                            .REACT_APP_PROXY
                                                                                                                    }/${
                                                                                                                        tipo ===
                                                                                                                        "casa"
                                                                                                                            ? "conversacionCasa"
                                                                                                                            : "conversacionEmbarcacion"
                                                                                                                    }/getArchive/${
                                                                                                                        message.mensaje
                                                                                                                    }`}
                                                                                                                >
                                                                                                                    {typeOfMessage(
                                                                                                                        message.tipo_mensaje
                                                                                                                    ) ===
                                                                                                                    "word" ? (
                                                                                                                        <svg
                                                                                                                            className="w-10 h-10"
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            x="0px"
                                                                                                                            y="0px"
                                                                                                                            width="48"
                                                                                                                            height="48"
                                                                                                                            viewBox="0 0 48 48"
                                                                                                                        >
                                                                                                                            <path
                                                                                                                                fill="#2196F3"
                                                                                                                                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                                                                                                                            ></path>
                                                                                                                            <path
                                                                                                                                fill="#FFF"
                                                                                                                                d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
                                                                                                                            ></path>
                                                                                                                            <path
                                                                                                                                fill="#0D47A1"
                                                                                                                                d="M27 42L6 38 6 10 27 6z"
                                                                                                                            ></path>
                                                                                                                            <path
                                                                                                                                fill="#FFF"
                                                                                                                                d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
                                                                                                                            ></path>
                                                                                                                        </svg>
                                                                                                                    ) : typeOfMessage(
                                                                                                                          message.tipo_mensaje
                                                                                                                      ) ===
                                                                                                                      "pdf" ? (
                                                                                                                        <img
                                                                                                                            className="w-10 h-10 p-0.5"
                                                                                                                            src={
                                                                                                                                pdfIcon
                                                                                                                            }
                                                                                                                            alt="Archivo pdf"
                                                                                                                        />
                                                                                                                    ) : typeOfMessage(
                                                                                                                          message.tipo_mensaje
                                                                                                                      ) ===
                                                                                                                      "excel" ? (
                                                                                                                        <img
                                                                                                                            className="w-10 h-10 p-1"
                                                                                                                            src={
                                                                                                                                excelIcon
                                                                                                                            }
                                                                                                                            alt="Archivo excel"
                                                                                                                        />
                                                                                                                    ) : null}
                                                                                                                    <span>
                                                                                                                        {message.mensaje
                                                                                                                            .split(
                                                                                                                                "_"
                                                                                                                            )
                                                                                                                            .slice(
                                                                                                                                1
                                                                                                                            )
                                                                                                                            .join(
                                                                                                                                "_"
                                                                                                                            )}
                                                                                                                    </span>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="px-1 -mt-1">
                                                                                                    <small className="text-xs text-gray-500 max-w-max">
                                                                                                        {
                                                                                                            message.registro_mensaje
                                                                                                        }
                                                                                                    </small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    }
                                                                }
                                                            )
                                                        ) : (
                                                            <div className="text-center items-center flex justify-center w-full h-full">
                                                                <h1>
                                                                    Aun no hay
                                                                    mensajes en
                                                                    este chat,
                                                                    sé el
                                                                    primero en
                                                                    decir ¡Hola!
                                                                </h1>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="sticky bottom-0">
                                                        <form
                                                            className="write bg-white shadow flex rounded-lg w-full"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                handleNewMessage();
                                                            }}
                                                        >
                                                            <div className="flex-1 w-full">
                                                                <input
                                                                    maxLength={
                                                                        256
                                                                    }
                                                                    name="message"
                                                                    className="w-full block outline-none py-4 px-4 bg-transparent resize-none max-h-20"
                                                                    rows="1"
                                                                    value={
                                                                        message
                                                                    }
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                    autoComplete="off"
                                                                    placeholder="Type a message..."
                                                                    autoFocus
                                                                />
                                                            </div>
                                                            <div className="p-2 px-4 flex content-center items-center">
                                                                <div className="relative">
                                                                    <input
                                                                        className="w-6 h-6 absolute right-3 opacity-0 cursor-pointer file:cursor-pointer"
                                                                        type="file"
                                                                        accept=".xlsx, .xls, .csv, .doc, .docx, application/msword, text/plain, application/pdf, image/*"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleNewMessage(
                                                                                e
                                                                                    .target
                                                                                    .files[0]
                                                                            )
                                                                        }
                                                                    />
                                                                    <svg
                                                                        className="h-6 w-6 mx-3 text-blue-bluecorner hover:scale-105 active:translate-y-0.5 cursor-pointer"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    >
                                                                        <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                                                                    </svg>
                                                                </div>
                                                                <div className="flex-1">
                                                                    <button type="submit">
                                                                        <span className="flex items-center justify-center align-text-bottom">
                                                                            <svg
                                                                                className="h-6 w-6 mt-1 rotate-45 text-blue-bluecorner hover:scale-105 active:translate-y-0.5"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="2"
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            >
                                                                                {" "}
                                                                                <path
                                                                                    stroke="none"
                                                                                    d="M0 0h24v24H0z"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="10"
                                                                                    y1="14"
                                                                                    x2="21"
                                                                                    y2="3"
                                                                                />{" "}
                                                                                <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" />
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
    }
};

export default Conversation;
