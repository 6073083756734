import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../../components/NavBar";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";
import ReservaDetailed from "../checkout/ReservaDetailed";
import { useSelector } from "react-redux";

const ReservaDetails = () => {
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <NavBar />
            <div
                className={`${
                    authUser.tipo_usuario !== null && authUser.tipo_usuario <= 2
                        ? "pt-40"
                        : "pt-32"
                } flex flex-col  select-none`}>
                <div className="bg-[#053465] text-center">
                    <h1 className="text-white text-4xl font-bold py-20">
                        Detalles de pedido
                    </h1>
                </div>
                <div className="p-3 sm:p-10 lg:w-4/5 mx-auto">
                    <div className=" text-[#053465] text-lg font-semibold text-md">
                        <h1>Gracias. Tu pedido hace sido recibido.</h1>
                        <div className="grid grid-cols-4 mt-5">
                            <div className="flex flex-col">
                                <span className="text-base font-normal">
                                    NÚMERO DEL PEDIDO:
                                </span>
                                <span>23723</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-base font-normal">
                                    FECHA:
                                </span>
                                <span>27 de mayo de 2022</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-base font-normal">
                                    TOTAL:
                                </span>
                                <span>23723 Soles(PEN)</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-base font-normal">
                                    MÉTODO DE PAGO:
                                </span>
                                <span>
                                    Déjanos tu número de celular para poder
                                    contactar y completar el proceso de alquiler
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-20 text-[#053465] font-semibold text-md">
                        <h1 className="text-4xl font-semibold">
                            Detalles de tu reserva
                        </h1>
                        <div className="my-10 grid grid-cols-[80%_20%]">
                            <div className="border p-2">
                                <h2 className="text-lg">Producto</h2>
                                <hr className="my-2 " />
                                <div className="min-h-[6rem]">
                                    <ReservaDetailed />
                                </div>
                                <hr className="my-2" />
                                <h3>Método de pago</h3> <hr className="my-2" />
                                <h3>Costo total</h3>
                            </div>
                            <div className="border p-2">
                                <h3 className="text-lg"> Total </h3>
                                <hr className="my-2" />
                                <span className=" font-semibold flex h-24">
                                    1548.6 Soles (PEN)
                                </span>
                                <hr className="my-2" />{" "}
                                <h3>Requiere confirmación</h3>
                                <hr className="my-2" />
                                <h3>1548.6 Soles (PEN)</h3>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className="text-xl text-[#053465] font-semibold">
                            Se enviará un mail con los detalles de tu reserva.
                        </h1>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ReservaDetails;
