import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import numberToCommas from "../../helpers/numberToCommas";
import { Paginado } from "../../helpers/Paginado";
import Spinner from "../../Spinner";
import CardAlquileres from "../CardAlquileres";
import DetalleAlquiler from "./DetalleAlquiler";
import DetallesPrincipales from "./DetallesPrincipales";
import ModalDetallesPedido from "./ModalDetallesPedido";
import SearchBar from "./SearchBar";
import puntosDetalles from "../../helpers/puntosDetalles";
import NavbarEmpresas from "../../dashboardEmpresas/NavbarEmpresas";
import ModalDetalleCotizacion from "./ModalDetalleCotizacion";
import { NavbarEmpresasNew } from "../../dashboardEmpresas/NavbarEmpresasNew";
import { NavbarMobileEmpresasNew } from "../../dashboardEmpresas/NavbarMobileEmpresasNew";

const AllPedidos = ({ tipo = "Alquileres", empresa = false }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const [showAlojamientos, setShowAlojamientos] = useState(
        authUser.tipo_usuario > 2 && authUser.casas > 0
            ? true
            : authUser.tipo_usuario > 2 && authUser.embarcaciones > 0
            ? false
            : true
    );
    const [cotizacionDetalle, setCotizacionDetalle] = useState(null);
    const [pedidosPage, setPedidosPage] = useState(true);
    const [modalDetalles, setModalDetalles] = useState(false);
    const [modalDetallePagos, setModalDetallePagos] = useState(false);
    const [modalDisponibilidad, setModalDisponibilidad] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [page, setPage] = useState(1);
    const [habilitados, setHabilitados] = useState(true);
    const [allPedidos, setAllPedidos] = useState({
        checking: true,
        pedidos: [],
        total: 0,
    });
    const [pedidoSelected, setPedidoSelected] = useState({});
    const [detallesPrincipales, setDetallesPrincipales] = useState(false);
    const [detallesPrecios, setDetallesPrecios] = useState(false);
    const [estado, setEstado] = useState(null);
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [click, setClick] = useState(false);
    const porcentaje = [5, 25, 40, 60, 80, 100];
    const [porcentajeBarra, setPorcentajeBarra] = useState(null);
    const PEDIDOS_PER_PAGE = 25;
    const [estadoPedido, setEstadoPedido] = useState("Todos");
    const estados = [
        "Confirmar disponibilidad",
        "Pendiente de pago",
        "Pago parcial",
        "Pago completo",
        "Reseña de casa",
        "Devolución de garantía",
    ];
    const estadosPedidos = [
        "Todos",
        "Confirmar disponibilidad",
        "Pendiente de pago",
        "Pago parcial",
        "Pago completo",
        "Devolución de garantía",
    ];
    const getAllPedidos = async (
        tipo_pedido,
        habilitado,
        pageNumber = 1,
        nombre = "",
        estadoPedido
    ) => {
        setAllPedidos({
            checking: true,
            pedidos: [],
            total: 0,
        });
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/${tipo_pedido}/all/${pageNumber}/${habilitado}/${estadoPedido}/${authUser.id_usuario}?nombre=${nombre}`,
            { headers }
        );

        setAllPedidos({
            checking: false,
            pedidos: data.data,
            total: data.total,
        });
    };

    useEffect(() => {
        getAllPedidos(
            authUser.tipo_usuario > 2 && authUser.casas > 0
                ? "pedidoCasa"
                : authUser.tipo_usuario > 2 && authUser.embarcaciones > 0
                ? "pedidoEmbarcacion"
                : "pedidoCasa",
            "Si",
            1,
            "",
            estadoPedido
        );

        return () => {
            setPedidosPage(true);
            setDetallesPrincipales(false);
            setDetallesPrecios(false);
        };
    }, []);

    useEffect(() => {
        if (habilitados) {
            getAllPedidos(
                showAlojamientos ? "pedidoCasa" : "pedidoEmbarcacion",
                "Si",
                1,
                "",
                estadoPedido
            );
        } else if (!habilitados) {
            getAllPedidos(
                showAlojamientos ? "pedidoCasa" : "pedidoEmbarcacion",
                "No",
                1,
                "",
                estadoPedido
            );
        }
    }, [habilitados, showAlojamientos, pedidoSelected, estadoPedido]);

    const handleDelete = async (idPedido) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el pedido a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/${
                            showAlojamientos
                                ? "pedidoCasa"
                                : "pedidoEmbarcacion"
                        }/eliminar/${idPedido}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Pedido eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllPedidos(
                            showAlojamientos
                                ? "pedidoCasa"
                                : "pedidoEmbarcacion",
                            "Si",
                            1,
                            "",
                            estadoPedido
                        );
                    });
            }
        });
    };

    const handleEnable = (idPedido) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el manual  a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/${
                            showAlojamientos
                                ? "pedidoCasa"
                                : "pedidoEmbarcacion"
                        }/restaurar/${idPedido}`,
                        {},
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Manual restablecido correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllPedidos(
                            showAlojamientos
                                ? "pedidoCasa"
                                : "pedidoEmbarcacion",
                            "No",
                            1,
                            "",
                            estadoPedido
                        );
                    });
            }
        });
    };

    const pagination = (pageNumber) => {
        getAllPedidos(
            showAlojamientos ? "pedidoCasa" : "pedidoEmbarcacion",
            habilitados ? "Si" : "No",
            pageNumber,
            "",
            estadoPedido
        );
        setPage(pageNumber);
    };

    const handleLink = (pedido) => {
        setPedidoSelected(pedido);
        setModalDisponibilidad((prev) => !prev);
    };

    const detailsMobile = async (pedido, tipoPedido) => {
        setPedidosPage((prev) => !prev);
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/${tipoPedido}/detalle/${pedido.id}`,
            { headers }
        );

        if (pedido.casa) {
            data.casa = pedido.casa;
        } else if (pedido.embarcacion) data.embarcacion = pedido.embarcacion;
        setPedidoSelected({ ...data, is_empresa: pedido.is_empresa });
        setEstado(estados.indexOf(data.estado) + 1);
        const porcentajeActual =
            porcentaje[estados.indexOf(data.estado) + 1 - 1];
        setPorcentajeBarra(porcentajeActual);
        setDetallesPrincipales((prev) => !prev);
    };

    const handleDetallesAlquiler = () => {
        setDetallesPrincipales((prev) => !prev);
        setDetallesPrecios((prev) => !prev);
    };

    const shouldReview = (fecha_final) => {
        if (fecha_final) {
            const aux_date = fecha_final.split("/").reverse().join("/");
            const new_fecha_final = new Date(aux_date);
            const actual_date = new Date();

            if (new_fecha_final < actual_date) return true;
        }
        return false;
    };

    const handleChatLink = (pedido_id) => {
        navigate(
            `/conversacion/${
                showAlojamientos ? "casa" : "embarcacion"
            }/${pedido_id}`
        );
    };

    const devolucionGarantia = async (pedido_id) => {
        Swal.fire({
            title: "¿Deseas notificar al cliente que ya enviamos su garantía?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const nuevo_estado = {
                    id: pedido_id,
                    estado: "Devolución de garantía",
                };

                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}/${
                        showAlojamientos
                            ? `pedidoCasa/actualizarDevolucionGarantia/${pedido_id}`
                            : "pedidoEmbarcacion/actualizarEstado/"
                    }/`,
                    nuevo_estado,
                    { headers }
                );

                if (data.message === "Pedido actualizado con exito") {
                    Swal.fire({
                        title: "Se ha enviado un email al cliente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                } else {
                    Swal.fire({
                        title: "Ocurrió un error en la actualización",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }
            }
        });
    };

    const getPedido = async (pedido) => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/pedidoCasa/detalleCotizacion/${pedido.id}`,
            { headers }
        );

        setCotizacionDetalle({ ...data, id_pedido: pedido.id });
    };

    console.log(allPedidos?.pedidos);

    if (pedidosPage)
        return (
            <>
                {cotizacionDetalle && (
                    <ModalDetalleCotizacion
                        cotizacionDetalle={cotizacionDetalle}
                        setCotizacionDetalle={setCotizacionDetalle}
                        getAllPedidos={getAllPedidos}
                        estadoPedido={estadoPedido}
                    />
                )}
                {/* <div className={empresa ? "" : "hidden"}>
                    <NavbarEmpresas selected="Reservaciones" />
                </div> */}
                {empresa ? (
                    <NavbarEmpresasNew>
                        <section className="w-full p-4 md:p-10 md:mx-auto">
                            <div className="flex-col items-start justify-start mb-5">
                                <h1 className="py-5 text-4xl text-left">
                                    {tipo}{" "}
                                </h1>
                                {authUser.tipo_usuario === 6 && (
                                    <div className="text-base w-[30rem] text-justify">
                                        Aca podras ver las reservas que hicieron
                                        el personal de tu empresa{" "}
                                        <b>{authUser.nombre_empresa}. </b>
                                    </div>
                                )}

                                {authUser.tipo_usuario === 7 && (
                                    <div className="text-base w-[30rem] text-justify">
                                        Aca podras ver tus reservas, para hacer
                                        un seguimiento y realizar los pagos, ya
                                        sea por transferencia o tarjeta de
                                        debito/credito!.{" "}
                                        <b>{authUser.nombre_empresa}. </b>
                                    </div>
                                )}
                            </div>
                            <div
                                className={
                                    authUser.tipo_usuario > 2 &&
                                    authUser.casas === 0 &&
                                    authUser.embarcaciones === 0
                                        ? "hidden"
                                        : "flex flex-col items-center justify-between w-full md:flex-row"
                                }>
                                <div className="flex w-full mx-auto ">
                                    <div className="flex w-full ">
                                        <button
                                            disabled={allPedidos.checking}
                                            className={
                                                authUser.tipo_usuario > 2 &&
                                                authUser.casas === 0
                                                    ? "hidden"
                                                    : `${
                                                          !showAlojamientos
                                                              ? "p-3 text-blue-500 border border-blue-500 w-full max-w-[156px] rounded-lg"
                                                              : "p-3 max-w-[156px]  text-white bg-blue-500 w-full border-2 rounded-lg"
                                                      }`
                                            }
                                            onClick={() => (
                                                setClick(true),
                                                setShowAlojamientos(true)
                                            )}>
                                            Alojamientos
                                        </button>
                                        <button
                                            disabled={allPedidos.checking}
                                            className={
                                                authUser.tipo_usuario > 2 &&
                                                authUser.embarcaciones === 0
                                                    ? "hidden"
                                                    : `${
                                                          showAlojamientos
                                                              ? "p-3 ml-2 text-blue-500 border border-blue-500 w-full max-w-[156px] rounded-lg"
                                                              : "p-3  text-white bg-blue-500 border-2 w-full max-w-[156px] rounded-lg ml-2"
                                                      }`
                                            }
                                            onClick={() => (
                                                setClick(true),
                                                setShowAlojamientos(false)
                                            )}>
                                            Embarcaciones
                                        </button>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end w-full p-1 mt-6 md:mt-0 md:ml-5">
                                    <SearchBar
                                        setfakeRefresh={setfakeRefresh}
                                        fakeRefresh={fakeRefresh}
                                        habilitados={habilitados ? "Si" : "No"}
                                        getAllPedidos={getAllPedidos}
                                        showAlojamientos={
                                            showAlojamientos
                                                ? "pedidoCasa"
                                                : "pedidoEmbarcacion"
                                        }
                                        estadoPedido={estadoPedido}
                                    />
                                    <button className="relative flex items-center justify-center w-8 h-8 ml-5 border rounded-lg md:w-11 md:h-11 border-blue-bluecorner">
                                        <svg
                                            onClick={() =>
                                                setShowButtons((prev) => !prev)
                                            }
                                            className="text-blue-500 h-7 w-7"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                            />
                                        </svg>
                                        {showButtons && (
                                            <div className="border rounded-lg absolute -left-40 top-[40px] z-10 bg-white">
                                                <div
                                                    disabled={
                                                        allPedidos.checking
                                                    }
                                                    onClick={() => {
                                                        setHabilitados(
                                                            !habilitados
                                                        );
                                                        setShowButtons(
                                                            (prev) => !prev
                                                        );
                                                        setPage(1);
                                                    }}
                                                    className="flex items-center justify-start w-48 p-1 mx-auto my-1 rounded-lg cursor-pointer h-11">
                                                    <p
                                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                        {!habilitados
                                                            ? "Habilitados"
                                                            : "Deshabilitados"}
                                                    </p>
                                                </div>
                                                {estadosPedidos.map((e) => {
                                                    return (
                                                        <div
                                                            disabled={
                                                                allPedidos.checking
                                                            }
                                                            onClick={() => {
                                                                setEstadoPedido(
                                                                    e
                                                                );
                                                                setShowButtons(
                                                                    (prev) =>
                                                                        !prev
                                                                );
                                                                setPage(1);
                                                            }}
                                                            className="flex items-center justify-start w-48 p-1 mx-auto my-1 rounded-lg cursor-pointer h-11">
                                                            <p
                                                                className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                                {e}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>

                            {modalDetalles && (
                                <ModalDetallesPedido
                                    modalDetalles={modalDetalles}
                                    setModalDetalles={setModalDetalles}
                                    pedido={pedidoSelected}
                                />
                            )}

                            <div className="flex flex-col pb-20">
                                {allPedidos?.checking ? (
                                    <Spinner margin_top={20} />
                                ) : allPedidos.pedidos?.length > 0 ? (
                                    <div>
                                        <div className="w-full ">
                                            <table className="w-full mt-6 overflow-x-auto">
                                                <thead className="text-left">
                                                    <tr>
                                                        <th className="md:w-[14rem] w-full min-w-[14rem] lg:w-2/12 "></th>

                                                        <th className="md:w-[14rem] w-2/12 lg:w-2/12 min-w-[10rem] hidden xs:table-cell">
                                                            Cliente
                                                        </th>
                                                        {!showAlojamientos && (
                                                            <th className="w-1/12 min-w-[10rem] hidden 3xl:table-cell">
                                                                Fecha
                                                            </th>
                                                        )}
                                                        <th className="w-1/12 min-w-[10rem] hidden xl:table-cell">
                                                            Check-in
                                                        </th>
                                                        <th className="w-1/12 min-w-[10rem] hidden xl:table-cell">
                                                            Check-out
                                                        </th>
                                                        <th
                                                            className={`w-2/12 min-w-[10rem] table-cell md:hidden md920:table-cell lg:hidden lg1170:table-cell xl:hidden ${
                                                                showAlojamientos
                                                                    ? "2xl:table-cell"
                                                                    : "3xl:table-cell"
                                                            } `}>
                                                            Estado
                                                        </th>
                                                        {authUser.tipo_usuario !==
                                                            4 &&
                                                            authUser.tipo_usuario !==
                                                                3 &&
                                                            authUser.tipo_usuario !==
                                                                6 &&
                                                            authUser.tipo_usuario !==
                                                                7 && (
                                                                <th className="w-1/12 min-w-[8rem] hidden 2xl:table-cell">
                                                                    {
                                                                        puntosDetalles.nombre_coins
                                                                    }{" "}
                                                                    obtenidos
                                                                </th>
                                                            )}
                                                        <th className="w-1/12 min-w-[8rem] hidden lg1170:table-cell">
                                                            Costo alquiler
                                                        </th>
                                                        <th
                                                            align="center"
                                                            className="w-1/12 min-w-[8rem] hidden lg:table-cell ">
                                                            Acciones
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {allPedidos?.pedidos?.map(
                                                        (pedido) => (
                                                            <tr
                                                                key={pedido.id}
                                                                className={
                                                                    authUser.id_usuario ===
                                                                        619 &&
                                                                    pedido.id_casa ===
                                                                        221
                                                                        ? "hidden"
                                                                        : "w-full border-t cursor-pointer"
                                                                }
                                                                onClick={() =>
                                                                    detailsMobile(
                                                                        pedido,
                                                                        showAlojamientos
                                                                            ? "pedidoCasa"
                                                                            : "pedidoEmbarcacion"
                                                                    )
                                                                }>
                                                                <td>
                                                                    <CardAlquileres
                                                                        key={
                                                                            pedido.id
                                                                        }
                                                                        pedido={
                                                                            pedido
                                                                        }
                                                                        tipo={
                                                                            showAlojamientos
                                                                                ? "casa"
                                                                                : "embarcacion"
                                                                        }
                                                                    />
                                                                </td>

                                                                <td className="hidden xs:table-cell">
                                                                    {
                                                                        pedido.cliente
                                                                    }{" "}
                                                                </td>
                                                                {showAlojamientos ? (
                                                                    <>
                                                                        <td className="hidden xl:table-cell">
                                                                            {
                                                                                pedido?.fecha_inicio?.split(
                                                                                    "T"
                                                                                )[0]
                                                                            }
                                                                        </td>
                                                                        <td className="hidden xl:table-cell">
                                                                            {
                                                                                pedido?.fecha_final?.split(
                                                                                    "T"
                                                                                )[0]
                                                                            }{" "}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td className="hidden 3xl:table-cell">
                                                                            {
                                                                                pedido?.fecha?.split(
                                                                                    "T"
                                                                                )[0]
                                                                            }
                                                                        </td>
                                                                        <td className="hidden xl:table-cell">
                                                                            {
                                                                                pedido.hora_entrada
                                                                            }
                                                                        </td>
                                                                        <td className="hidden xl:table-cell">
                                                                            {
                                                                                pedido.hora_salida
                                                                            }{" "}
                                                                        </td>
                                                                    </>
                                                                )}
                                                                <td
                                                                    className={`table-cell md:hidden md920:table-cell lg:hidden lg1170:table-cell xl:hidden ${
                                                                        showAlojamientos
                                                                            ? "2xl:table-cell"
                                                                            : "3xl:table-cell"
                                                                    } `}>
                                                                    {
                                                                        pedido.estado
                                                                    }
                                                                </td>
                                                                {authUser.tipo_usuario !==
                                                                    4 &&
                                                                    authUser.tipo_usuario !==
                                                                        3 &&
                                                                    authUser.tipo_usuario !==
                                                                        6 &&
                                                                    authUser.tipo_usuario !==
                                                                        7 && (
                                                                        <td
                                                                            className={
                                                                                "hidden 2xl:table-cell"
                                                                            }>
                                                                            {pedido.is_empresa ===
                                                                            "Si"
                                                                                ? 0
                                                                                : pedido.descuento_puntos ===
                                                                                      0 &&
                                                                                  pedido.posibles_puntos >
                                                                                      0
                                                                                ? pedido.posibles_puntos
                                                                                : 0}
                                                                        </td>
                                                                    )}

                                                                <td className="hidden lg1170:table-cell">
                                                                    {
                                                                        pedido.tipo_moneda
                                                                    }{" "}
                                                                    {numberToCommas(
                                                                        authUser.tipo_usuario ===
                                                                            4 ||
                                                                            authUser.tipo_usuario ===
                                                                                3
                                                                            ? pedido.costo_alquiler
                                                                            : pedido.costo_total_alquiler
                                                                    )}
                                                                </td>
                                                                <td className="hidden lg:table-cell ">
                                                                    {habilitados ? (
                                                                        <div className="flex justify-center ">
                                                                            {pedido.cotizacion !==
                                                                                "Si" && (
                                                                                <button
                                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                    title="Detalles bancarios"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        detailsMobile(
                                                                                            pedido,
                                                                                            showAlojamientos
                                                                                                ? "pedidoCasa"
                                                                                                : "pedidoEmbarcacion"
                                                                                        );
                                                                                    }}>
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="2"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />{" "}
                                                                                        <polyline points="14 2 14 8 20 8" />{" "}
                                                                                        <line
                                                                                            x1="16"
                                                                                            y1="13"
                                                                                            x2="8"
                                                                                            y2="13"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="16"
                                                                                            y1="17"
                                                                                            x2="8"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <polyline points="10 9 9 9 8 9" />
                                                                                    </svg>
                                                                                </button>
                                                                            )}
                                                                            {pedido.cotizacion ===
                                                                                "Si" &&
                                                                                authUser.tipo_usuario <
                                                                                    3 && (
                                                                                    <button
                                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                        title="Editar cotizacion"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation();
                                                                                            getPedido(
                                                                                                pedido
                                                                                            );
                                                                                        }}>
                                                                                        <svg
                                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                            fill="none"
                                                                                            viewBox="0 0 24 24"
                                                                                            stroke="currentColor">
                                                                                            <path
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"
                                                                                                strokeWidth="2"
                                                                                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                                                            />
                                                                                        </svg>
                                                                                    </button>
                                                                                )}
                                                                            {(pedido.estado ===
                                                                                "Pago completo" ||
                                                                                pedido.estado ===
                                                                                    "Reseña de casa" ||
                                                                                pedido.estado ===
                                                                                    "Devolución de garantía") &&
                                                                                (authUser.tipo_usuario <
                                                                                    3 ||
                                                                                    authUser.tipo_usuario ===
                                                                                        5 ||
                                                                                    authUser.tipo_usuario ===
                                                                                        7) &&
                                                                                shouldReview(
                                                                                    showAlojamientos
                                                                                        ? pedido.fecha_final
                                                                                        : pedido.fecha
                                                                                ) && (
                                                                                    <Link
                                                                                        to={`/reviews/${
                                                                                            showAlojamientos
                                                                                                ? "pedidoCasa"
                                                                                                : "pedidoEmbarcacion"
                                                                                        }/${
                                                                                            pedido.id
                                                                                        }`}>
                                                                                        <button
                                                                                            className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                            title="Reseña">
                                                                                            <svg
                                                                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                                viewBox="0 0 24 24"
                                                                                                fill="none"
                                                                                                stroke="currentColor"
                                                                                                strokeWidth="2"
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round">
                                                                                                {" "}
                                                                                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </Link>
                                                                                )}
                                                                            {pedido.estado ===
                                                                                "Pago completo" &&
                                                                                authUser.tipo_usuario <
                                                                                    3 &&
                                                                                shouldReview(
                                                                                    showAlojamientos
                                                                                        ? pedido.fecha_final
                                                                                        : pedido.fecha
                                                                                ) && (
                                                                                    <button
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation();
                                                                                            devolucionGarantia(
                                                                                                pedido.id
                                                                                            );
                                                                                        }}
                                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                        title="Notificar devolución de Garantia">
                                                                                        <svg
                                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                            width="24"
                                                                                            height="24"
                                                                                            viewBox="0 0 24 24"
                                                                                            strokeWidth="2"
                                                                                            stroke="currentColor"
                                                                                            fill="none"
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round">
                                                                                            <path
                                                                                                stroke="none"
                                                                                                d="M0 0h24v24H0z"
                                                                                            />
                                                                                            <rect
                                                                                                x="7"
                                                                                                y="9"
                                                                                                width="14"
                                                                                                height="10"
                                                                                                rx="2"
                                                                                            />
                                                                                            <circle
                                                                                                cx="14"
                                                                                                cy="14"
                                                                                                r="2"
                                                                                            />
                                                                                            <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
                                                                                        </svg>
                                                                                    </button>
                                                                                )}
                                                                            {pedido.estado ===
                                                                                "Pago completo" &&
                                                                                authUser.tipo_usuario !==
                                                                                    6 && (
                                                                                    <button
                                                                                        className="relative flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation();
                                                                                            handleChatLink(
                                                                                                pedido.id
                                                                                            );
                                                                                        }}
                                                                                        title="Abrir chat">
                                                                                        <svg
                                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                            fill="none"
                                                                                            viewBox="0 0 24 24"
                                                                                            stroke="currentColor">
                                                                                            <path
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"
                                                                                                strokeWidth="2"
                                                                                                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                                                                                            />
                                                                                        </svg>
                                                                                        {pedido.mensajes_no_leidos >
                                                                                            0 && (
                                                                                            <div className="absolute w-5 p-2 text-[0.60rem] h-5 rounded-full bg-red-500 -top-3 -right-3 flex justify-center items-center font-bold text-white ">
                                                                                                <span
                                                                                                    className={`${
                                                                                                        pedido.mensajes_no_leidos >
                                                                                                            99 &&
                                                                                                        "ml-0.5"
                                                                                                    } p-2 flex `}>
                                                                                                    {pedido.mensajes_no_leidos >
                                                                                                    99
                                                                                                        ? "99+"
                                                                                                        : pedido.mensajes_no_leidos}
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                    </button>
                                                                                )}
                                                                            {authUser.tipo_usuario !==
                                                                                6 && (
                                                                                <button
                                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        handleDelete(
                                                                                            pedido.id
                                                                                        );
                                                                                    }}
                                                                                    title="Eliminar">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="4"
                                                                                            y1="7"
                                                                                            x2="20"
                                                                                            y2="7"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="10"
                                                                                            y1="11"
                                                                                            x2="10"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <line
                                                                                            x1="14"
                                                                                            y1="11"
                                                                                            x2="14"
                                                                                            y2="17"
                                                                                        />{" "}
                                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                                    </svg>
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="flex justify-center ml-9">
                                                                            {/* <button
                                                                                onClick={() =>
                                                                                    handleEnable(
                                                                                        pedido.id
                                                                                    )
                                                                                }
                                                                                title="Habilitar"
                                                                                className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                                <svg
                                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                    {" "}
                                                                                    <polyline points="17 1 21 5 17 9" />{" "}
                                                                                    <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                    <polyline points="7 23 3 19 7 15" />{" "}
                                                                                    <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                                </svg>
                                                                            </button> */}
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : habilitados ? (
                                    <h1 className="h-32 overflow-hidden text-3xl text-center">
                                        {tipo === "Alquiler "
                                            ? "Aun no tienes ningun Alquiler :("
                                            : "Aun no tienes ninguna Reservacion :("}
                                    </h1>
                                ) : (
                                    <h1 className="h-32 overflow-hidden text-lg text-center md:text-3xl">
                                        {tipo === "Alquiler "
                                            ? "Aun no tienes ningun Alquiler deshabilitado :("
                                            : "Aun no tienes ninguna Reservacion deshabilitada :("}
                                    </h1>
                                )}
                                <div className="flex items-center">
                                    <Paginado
                                        elementsPerPage={PEDIDOS_PER_PAGE}
                                        cantTotalElements={allPedidos?.total}
                                        pagination={pagination}
                                    />
                                </div>
                            </div>
                        </section>
                        <NavbarMobileEmpresasNew />
                    </NavbarEmpresasNew>
                ) : (
                    <section className="p-4 bg-white md:pl-[23rem] md818:pl-[20rem] md920:pl-[14rem] lg1170:pl-[16rem]  xl:pl-[16rem] 2xl:pl-[18rem]">
                        <h1 className="py-5 mx-4 text-4xl">{tipo}</h1>
                        <div
                            className={
                                authUser.tipo_usuario > 2 &&
                                authUser.casas === 0 &&
                                authUser.embarcaciones === 0
                                    ? "hidden"
                                    : "flex flex-col items-center justify-between w-full md:flex-row"
                            }>
                            <div className="flex w-full mx-auto ">
                                <div className="flex w-full ">
                                    <button
                                        disabled={allPedidos.checking}
                                        className={
                                            authUser.tipo_usuario > 2 &&
                                            authUser.casas === 0
                                                ? "hidden"
                                                : `${
                                                      !showAlojamientos
                                                          ? "p-3 text-blue-500 border border-blue-500 w-full max-w-[156px] rounded-lg"
                                                          : "p-3 max-w-[156px]  text-white bg-blue-500 w-full border-2 rounded-lg"
                                                  }`
                                        }
                                        onClick={() => (
                                            setClick(true),
                                            setShowAlojamientos(true)
                                        )}>
                                        Alojamientos
                                    </button>
                                    <button
                                        disabled={allPedidos.checking}
                                        className={
                                            authUser.tipo_usuario > 2 &&
                                            authUser.embarcaciones === 0
                                                ? "hidden"
                                                : `${
                                                      showAlojamientos
                                                          ? "p-3 ml-2 text-blue-500 border border-blue-500 w-full max-w-[156px] rounded-lg"
                                                          : "p-3  text-white bg-blue-500 border-2 w-full max-w-[156px] rounded-lg ml-2"
                                                  }`
                                        }
                                        onClick={() => (
                                            setClick(true),
                                            setShowAlojamientos(false)
                                        )}>
                                        Embarcaciones
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-end w-full p-1 mt-6 md:mt-0 md:ml-5">
                                <SearchBar
                                    setfakeRefresh={setfakeRefresh}
                                    fakeRefresh={fakeRefresh}
                                    habilitados={habilitados ? "Si" : "No"}
                                    getAllPedidos={getAllPedidos}
                                    showAlojamientos={
                                        showAlojamientos
                                            ? "pedidoCasa"
                                            : "pedidoEmbarcacion"
                                    }
                                    estadoPedido={estadoPedido}
                                />
                                <button className="relative flex items-center justify-center w-8 h-8 ml-5 border rounded-lg md:w-11 md:h-11 border-blue-bluecorner">
                                    <svg
                                        onClick={() =>
                                            setShowButtons((prev) => !prev)
                                        }
                                        className="text-blue-500 h-7 w-7"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                        />
                                    </svg>
                                    {showButtons && (
                                        <div className="border rounded-lg absolute -left-40 top-[40px] z-10 bg-white">
                                            <div
                                                disabled={allPedidos.checking}
                                                onClick={() => {
                                                    setHabilitados(
                                                        !habilitados
                                                    );
                                                    setShowButtons(
                                                        (prev) => !prev
                                                    );
                                                    setPage(1);
                                                }}
                                                className="flex items-center justify-start w-48 p-1 mx-auto my-1 rounded-lg cursor-pointer h-11">
                                                <p
                                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                    {!habilitados
                                                        ? "Habilitados"
                                                        : "Deshabilitados"}
                                                </p>
                                            </div>
                                            {estadosPedidos.map((e) => {
                                                return (
                                                    <div
                                                        disabled={
                                                            allPedidos.checking
                                                        }
                                                        onClick={() => {
                                                            setEstadoPedido(e);
                                                            setShowButtons(
                                                                (prev) => !prev
                                                            );
                                                            setPage(1);
                                                        }}
                                                        className="flex items-center justify-start w-48 p-1 mx-auto my-1 rounded-lg cursor-pointer h-11">
                                                        <p
                                                            className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                            {e}
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </button>
                            </div>
                        </div>

                        {modalDetalles && (
                            <ModalDetallesPedido
                                modalDetalles={modalDetalles}
                                setModalDetalles={setModalDetalles}
                                pedido={pedidoSelected}
                            />
                        )}

                        <div className="flex flex-col pb-20">
                            {allPedidos?.checking ? (
                                <Spinner margin_top={20} />
                            ) : allPedidos.pedidos?.length > 0 ? (
                                <div>
                                    <div className="w-full ">
                                        <table className="w-full mt-6 overflow-x-auto">
                                            <thead className="text-left">
                                                <tr>
                                                    <th className="md:w-[14rem] w-full min-w-[14rem] lg:w-2/12 "></th>

                                                    <th className="md:w-[14rem] w-2/12 lg:w-2/12 min-w-[10rem] hidden xs:table-cell">
                                                        Cliente
                                                    </th>
                                                    {!showAlojamientos && (
                                                        <th className="w-1/12 min-w-[10rem] hidden 3xl:table-cell">
                                                            Fecha
                                                        </th>
                                                    )}
                                                    <th className="w-1/12 min-w-[10rem] hidden xl:table-cell">
                                                        Check-in
                                                    </th>
                                                    <th className="w-1/12 min-w-[10rem] hidden xl:table-cell">
                                                        Check-out
                                                    </th>
                                                    <th
                                                        className={`w-2/12 min-w-[10rem] table-cell md:hidden md920:table-cell lg:hidden lg1170:table-cell xl:hidden ${
                                                            showAlojamientos
                                                                ? "2xl:table-cell"
                                                                : "3xl:table-cell"
                                                        } `}>
                                                        Estado
                                                    </th>
                                                    {authUser.tipo_usuario !==
                                                        4 &&
                                                        authUser.tipo_usuario !==
                                                            3 &&
                                                        authUser.tipo_usuario !==
                                                            6 &&
                                                        authUser.tipo_usuario !==
                                                            7 && (
                                                            <th className="w-1/12 min-w-[8rem] hidden 2xl:table-cell">
                                                                {
                                                                    puntosDetalles.nombre_coins
                                                                }{" "}
                                                                obtenidos
                                                            </th>
                                                        )}
                                                    <th className="w-1/12 min-w-[8rem] hidden lg1170:table-cell">
                                                        Costo alquiler
                                                    </th>
                                                    <th
                                                        align="center"
                                                        className="w-1/12 min-w-[8rem] hidden lg:table-cell ">
                                                        Acciones
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                                {allPedidos?.pedidos?.map(
                                                    (pedido) => (
                                                        <tr
                                                            key={pedido.id}
                                                            className={
                                                                authUser.id_usuario ===
                                                                    619 &&
                                                                pedido.id_casa ===
                                                                    221
                                                                    ? "hidden"
                                                                    : "w-full border-t cursor-pointer"
                                                            }
                                                            onClick={() =>
                                                                detailsMobile(
                                                                    pedido,
                                                                    showAlojamientos
                                                                        ? "pedidoCasa"
                                                                        : "pedidoEmbarcacion"
                                                                )
                                                            }>
                                                            <td>
                                                                <CardAlquileres
                                                                    key={
                                                                        pedido.id
                                                                    }
                                                                    pedido={
                                                                        pedido
                                                                    }
                                                                    tipo={
                                                                        showAlojamientos
                                                                            ? "casa"
                                                                            : "embarcacion"
                                                                    }
                                                                />
                                                            </td>

                                                            <td className="hidden xs:table-cell">
                                                                {pedido.cliente}{" "}
                                                            </td>
                                                            {showAlojamientos ? (
                                                                <>
                                                                    <td className="hidden xl:table-cell">
                                                                        {
                                                                            pedido?.fecha_inicio?.split(
                                                                                "T"
                                                                            )[0]
                                                                        }
                                                                    </td>
                                                                    <td className="hidden xl:table-cell">
                                                                        {
                                                                            pedido?.fecha_final?.split(
                                                                                "T"
                                                                            )[0]
                                                                        }{" "}
                                                                    </td>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <td className="hidden 3xl:table-cell">
                                                                        {
                                                                            pedido?.fecha?.split(
                                                                                "T"
                                                                            )[0]
                                                                        }
                                                                    </td>
                                                                    <td className="hidden xl:table-cell">
                                                                        {
                                                                            pedido.hora_entrada
                                                                        }
                                                                    </td>
                                                                    <td className="hidden xl:table-cell">
                                                                        {
                                                                            pedido.hora_salida
                                                                        }{" "}
                                                                    </td>
                                                                </>
                                                            )}
                                                            <td
                                                                className={`table-cell md:hidden md920:table-cell lg:hidden lg1170:table-cell xl:hidden ${
                                                                    showAlojamientos
                                                                        ? "2xl:table-cell"
                                                                        : "3xl:table-cell"
                                                                } `}>
                                                                {pedido.estado}
                                                            </td>
                                                            {authUser.tipo_usuario !==
                                                                4 &&
                                                                authUser.tipo_usuario !==
                                                                    3 &&
                                                                authUser.tipo_usuario !==
                                                                    6 &&
                                                                authUser.tipo_usuario !==
                                                                    7 && (
                                                                    <td
                                                                        className={
                                                                            "hidden 2xl:table-cell"
                                                                        }>
                                                                        {pedido.is_empresa ===
                                                                        "Si"
                                                                            ? 0
                                                                            : pedido.descuento_puntos ===
                                                                                  0 &&
                                                                              pedido.posibles_puntos >
                                                                                  0
                                                                            ? pedido.posibles_puntos
                                                                            : 0}
                                                                    </td>
                                                                )}

                                                            <td className="hidden lg1170:table-cell">
                                                                {
                                                                    pedido.tipo_moneda
                                                                }{" "}
                                                                {numberToCommas(
                                                                    authUser.tipo_usuario ===
                                                                        4 ||
                                                                        authUser.tipo_usuario ===
                                                                            3
                                                                        ? pedido.costo_alquiler
                                                                        : pedido.costo_total_alquiler
                                                                )}
                                                            </td>
                                                            <td className="hidden lg:table-cell ">
                                                                {habilitados ? (
                                                                    <div className="flex justify-center ">
                                                                        {pedido.cotizacion !==
                                                                            "Si" && (
                                                                            <button
                                                                                className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                title="Detalles bancarios"
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation();
                                                                                    detailsMobile(
                                                                                        pedido,
                                                                                        showAlojamientos
                                                                                            ? "pedidoCasa"
                                                                                            : "pedidoEmbarcacion"
                                                                                    );
                                                                                }}>
                                                                                <svg
                                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                    {" "}
                                                                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />{" "}
                                                                                    <polyline points="14 2 14 8 20 8" />{" "}
                                                                                    <line
                                                                                        x1="16"
                                                                                        y1="13"
                                                                                        x2="8"
                                                                                        y2="13"
                                                                                    />{" "}
                                                                                    <line
                                                                                        x1="16"
                                                                                        y1="17"
                                                                                        x2="8"
                                                                                        y2="17"
                                                                                    />{" "}
                                                                                    <polyline points="10 9 9 9 8 9" />
                                                                                </svg>
                                                                            </button>
                                                                        )}
                                                                        {pedido.cotizacion ===
                                                                            "Si" &&
                                                                            authUser.tipo_usuario <
                                                                                3 && (
                                                                                <button
                                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                    title="Editar cotizacion"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        getPedido(
                                                                                            pedido
                                                                                        );
                                                                                    }}>
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        stroke="currentColor">
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            strokeWidth="2"
                                                                                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                            )}
                                                                        {(pedido.estado ===
                                                                            "Pago completo" ||
                                                                            pedido.estado ===
                                                                                "Reseña de casa" ||
                                                                            pedido.estado ===
                                                                                "Devolución de garantía") &&
                                                                            (authUser.tipo_usuario <
                                                                                3 ||
                                                                                authUser.tipo_usuario ===
                                                                                    5) &&
                                                                            shouldReview(
                                                                                showAlojamientos
                                                                                    ? pedido.fecha_final
                                                                                    : pedido.fecha
                                                                            ) && (
                                                                                <Link
                                                                                    to={`/reviews/${
                                                                                        showAlojamientos
                                                                                            ? "pedidoCasa"
                                                                                            : "pedidoEmbarcacion"
                                                                                    }/${
                                                                                        pedido.id
                                                                                    }`}>
                                                                                    <button
                                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                        title="Reseña">
                                                                                        <svg
                                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                            viewBox="0 0 24 24"
                                                                                            fill="none"
                                                                                            stroke="currentColor"
                                                                                            strokeWidth="2"
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round">
                                                                                            {" "}
                                                                                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </Link>
                                                                            )}
                                                                        {pedido.estado ===
                                                                            "Pago completo" &&
                                                                            authUser.tipo_usuario <
                                                                                5 &&
                                                                            shouldReview(
                                                                                showAlojamientos
                                                                                    ? pedido.fecha_final
                                                                                    : pedido.fecha
                                                                            ) && (
                                                                                <button
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        devolucionGarantia(
                                                                                            pedido.id
                                                                                        );
                                                                                    }}
                                                                                    className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                    title="Notificar devolución de Garantia">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />
                                                                                        <rect
                                                                                            x="7"
                                                                                            y="9"
                                                                                            width="14"
                                                                                            height="10"
                                                                                            rx="2"
                                                                                        />
                                                                                        <circle
                                                                                            cx="14"
                                                                                            cy="14"
                                                                                            r="2"
                                                                                        />
                                                                                        <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
                                                                                    </svg>
                                                                                </button>
                                                                            )}
                                                                        {pedido.estado ===
                                                                            "Pago completo" &&
                                                                            authUser.tipo_usuario <
                                                                                5 && (
                                                                                <button
                                                                                    className="relative flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        handleChatLink(
                                                                                            pedido.id
                                                                                        );
                                                                                    }}
                                                                                    title="Abrir chat">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        stroke="currentColor">
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            strokeWidth="2"
                                                                                            d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                                                                                        />
                                                                                    </svg>
                                                                                    {pedido.mensajes_no_leidos >
                                                                                        0 && (
                                                                                        <div className="absolute w-5 p-2 text-[0.60rem] h-5 rounded-full bg-red-500 -top-3 -right-3 flex justify-center items-center font-bold text-white ">
                                                                                            <span
                                                                                                className={`${
                                                                                                    pedido.mensajes_no_leidos >
                                                                                                        99 &&
                                                                                                    "ml-0.5"
                                                                                                } p-2 flex `}>
                                                                                                {pedido.mensajes_no_leidos >
                                                                                                99
                                                                                                    ? "99+"
                                                                                                    : pedido.mensajes_no_leidos}
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                                </button>
                                                                            )}
                                                                        <button
                                                                            className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                handleDelete(
                                                                                    pedido.id
                                                                                );
                                                                            }}
                                                                            title="Eliminar">
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="2"
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                {" "}
                                                                                <path
                                                                                    stroke="none"
                                                                                    d="M0 0h24v24H0z"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="4"
                                                                                    y1="7"
                                                                                    x2="20"
                                                                                    y2="7"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="10"
                                                                                    y1="11"
                                                                                    x2="10"
                                                                                    y2="17"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="14"
                                                                                    y1="11"
                                                                                    x2="14"
                                                                                    y2="17"
                                                                                />{" "}
                                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex justify-center ml-9">
                                                                        {/* <button
                                                                            onClick={() =>
                                                                                handleEnable(
                                                                                    pedido.id
                                                                                )
                                                                            }
                                                                            title="Habilitar"
                                                                            className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white">
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                {" "}
                                                                                <polyline points="17 1 21 5 17 9" />{" "}
                                                                                <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                <polyline points="7 23 3 19 7 15" />{" "}
                                                                                <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                            </svg>
                                                                        </button> */}
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : habilitados ? (
                                <h1 className="h-32 overflow-hidden text-3xl text-center">
                                    {tipo === "Alquiler "
                                        ? "Aun no tienes ningun Alquiler :("
                                        : "Aun no tienes ninguna Reservacion :("}
                                </h1>
                            ) : (
                                <h1 className="h-32 overflow-hidden text-lg text-center md:text-3xl">
                                    {tipo === "Alquiler "
                                        ? "Aun no tienes ningun Alquiler deshabilitado :("
                                        : "Aun no tienes ninguna Reservacion deshabilitada :("}
                                </h1>
                            )}
                            <div className="flex items-center">
                                <Paginado
                                    elementsPerPage={PEDIDOS_PER_PAGE}
                                    cantTotalElements={allPedidos?.total}
                                    pagination={pagination}
                                />
                            </div>
                        </div>
                    </section>
                )}
            </>
        );
    else if (!pedidosPage && !detallesPrincipales && detallesPrecios) {
        return (
            <DetalleAlquiler
                showAlojamientos={showAlojamientos}
                setDetallesPrecios={setDetallesPrecios}
                setDetallesPrincipales={setDetallesPrincipales}
                pedidoSelected={pedidoSelected}
            />
        );
    }
    return (
        detallesPrincipales && (
            <DetallesPrincipales
                pedido={pedidoSelected}
                pedido_id={pedidoSelected.id}
                setPedidosPage={setPedidosPage}
                setDetallesPrincipales={setDetallesPrincipales}
                showAlojamientos={showAlojamientos}
                porcentajeBarra={porcentajeBarra}
                setPorcentajeBarra={setPorcentajeBarra}
                handleDetallesAlquiler={handleDetallesAlquiler}
                handleLink={handleLink}
                setModalDetallePagos={setModalDetallePagos}
                shouldReview={shouldReview}
                handleChatLink={handleChatLink}
                handleDelete={handleDelete}
                modalDisponibilidad={modalDisponibilidad}
                setModalDisponibilidad={setModalDisponibilidad}
                modalDetallePagos={modalDetallePagos}
                estado={estado}
                setEstado={setEstado}
                devolucionGarantia={devolucionGarantia}
            />
        )
    );
};

export default AllPedidos;
