import axios from "axios";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import dividirFechaDatePicker from "../../../helpers/dividirFechaDatePicker";

const ModalEditFee = ({ modalVisible, setModalVisible, feeSelected }) => {
    registerLocale("es", es);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const [input, setInput] = useState({
        fee_cliente: feeSelected.fee_cliente,
        fee_propietario: feeSelected.fee_propietario,
        tipo_tiempo: feeSelected.tipo_tiempo,
        fecha_inicio: feeSelected.fecha_inicio,
        fecha_final: feeSelected.fecha_final,
        dia: feeSelected.dia,
        dia_inicio: feeSelected.dia_inicio,
        dia_final: feeSelected.dia_final,
    });

    const DIAS = [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
    ];

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = async () => {
        const payload = {
            id_casa: feeSelected.id_casa,
            tipo_tiempo: input.tipo_tiempo,
            fecha_inicio: input.fecha_inicio,
            fecha_final: input.fecha_final,
            dia_inicio: input.dia_inicio,
            dia_final: input.dia_final,
            fee_cliente: input.fee_cliente,
            fee_propietario: input.fee_propietario,
        };

        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/casaFee/actualizar/${feeSelected.id}`,
            payload,
            { headers }
        );

        if (data) {
            setModalVisible(!modalVisible);
        }
    };

    const handleClose = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed z-10 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-20 sm:py-8 ">
                    <div className="flex-col relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg  xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] p-10 overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-4 right-4 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <h1 className="font-medium text-lg">
                            Editar fee avanzado
                        </h1>
                        <div className="w-full mt-4">
                            <label className="ml-1 text-base font-semibold ">
                                Por:
                            </label>
                            <select
                                name="tipo_tiempo"
                                className="w-30 my-3 bg-white p-2 ml-4 outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C] rounded-lg border border-[#E9E9E9]"
                                value={input.tipo_tiempo}
                                onChange={handleInputChange}>
                                <option value="Fecha">Fecha</option>
                                <option value="Dias">
                                    Rango exacto de Dias
                                </option>
                            </select>
                        </div>

                        {input.tipo_tiempo === "Fecha" && (
                            <div className="flex flex-col my-4">
                                <div className="">
                                    <label className="ml-1 text-base font-semibold ">
                                        Fecha inicial:
                                    </label>
                                    {/* <input
                                        value={input.fecha_inicio}
                                        type="date"
                                        name="fecha_inicio"
                                        className="rounded-lg border border-[#E9E9E9] outline-none w-32 py-2 placeholder:text-center text-center"
                                        onChange={handleInputChange}
                                    /> */}
                                    <DatePicker
                                        className="p-2  rounded-lg bg-white border w-11/12 cursor-pointer"
                                        placeholderText="Seleccionar fecha"
                                        locale="es"
                                        autoComplete="off"
                                        name="fecha_inicio"
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            input.fecha_inicio !== ""
                                                ? new Date(
                                                      dividirFechaDatePicker(
                                                          input.fecha_inicio
                                                      )[0],
                                                      dividirFechaDatePicker(
                                                          input.fecha_inicio
                                                      )[1] - 1,
                                                      dividirFechaDatePicker(
                                                          input.fecha_inicio
                                                      )[2]
                                                  )
                                                : input.fecha_inicio
                                        }
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                fecha_inicio: `${e.getFullYear()}-${`${
                                                    e.getMonth() + 1
                                                }`.padStart(
                                                    2,
                                                    "0"
                                                )}-${`${e.getDate()}`.padStart(
                                                    2,
                                                    "0"
                                                )}`,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="ml-1 text-base font-semibold ">
                                        Fecha final:
                                    </label>
                                    {/* <input
                                        value={input.fecha_final}
                                        type="date"
                                        name="fecha_final"
                                        className="rounded-lg border border-[#E9E9E9] outline-none w-32 py-2 placeholder:text-center text-center "
                                        onChange={handleInputChange}
                                    /> */}
                                    <DatePicker
                                        className="p-2  rounded-lg bg-white border w-11/12 cursor-pointer"
                                        placeholderText="Seleccionar fecha"
                                        locale="es"
                                        autoComplete="off"
                                        name="fecha_final"
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            input.fecha_final !== ""
                                                ? new Date(
                                                      dividirFechaDatePicker(
                                                          input.fecha_final
                                                      )[0],
                                                      dividirFechaDatePicker(
                                                          input.fecha_final
                                                      )[1] - 1,
                                                      dividirFechaDatePicker(
                                                          input.fecha_final
                                                      )[2]
                                                  )
                                                : input.fecha_final
                                        }
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                fecha_final: `${e.getFullYear()}-${`${
                                                    e.getMonth() + 1
                                                }`.padStart(
                                                    2,
                                                    "0"
                                                )}-${`${e.getDate()}`.padStart(
                                                    2,
                                                    "0"
                                                )}`,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {input.tipo_tiempo === "Dias" && (
                            <div className="flex flex-col my-4">
                                <div className="w-full flex mt-4">
                                    <label className="ml-1 text-base font-semibold pt-2">
                                        Día inicial:
                                    </label>
                                    <select
                                        name="dia_inicio"
                                        className="w-30 bg-white p-2 ml-4 outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C] rounded-lg border border-[#E9E9E9]"
                                        value={input.dia_inicio}
                                        onChange={handleInputChange}>
                                        {DIAS.map((day) => (
                                            <option key={day} value={day}>
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="w-full flex mt-4">
                                    <label className="ml-1 text-base font-semibold pt-2 ">
                                        Día final:
                                    </label>
                                    <select
                                        name="dia_final"
                                        className="w-30 bg-white p-2 ml-[26px] outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C] rounded-lg border border-[#E9E9E9]"
                                        value={input.dia_final}
                                        onChange={handleInputChange}>
                                        {DIAS.map((day) => (
                                            <option key={day} value={day}>
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}

                        <div className="w-1/2">
                            <div className="w-32 mt-2 lg:mx-auto">
                                <label className=" w-full text-base text-[#3C3C3C]">
                                    Fee propietario
                                </label>
                            </div>

                            <div className="w-32 lg:mx-auto flex border-2 rounded-lg items-center justify-center py-3 px-3">
                                <input
                                    autoComplete="off"
                                    className="w-full  rounded-lg outline-none"
                                    placeholder="15"
                                    name="fee_propietario"
                                    type="number"
                                    step=".01"
                                    onChange={handleInputChange}
                                    value={input.fee_propietario}></input>
                                <span>
                                    <svg
                                        className="w-4 h-4 text-[#3C3C3C] rounded-lg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <line x1="19" y1="5" x2="5" y2="19" />{" "}
                                        <circle cx="6.5" cy="6.5" r="2.5" />{" "}
                                        <circle cx="17.5" cy="17.5" r="2.5" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="w-1/2  ">
                            <div className="w-32 mt-2 lg:mx-auto">
                                <label className=" w-full text-base text-[#3C3C3C]">
                                    Fee cliente
                                </label>
                            </div>

                            <div className="w-32 lg:mx-auto flex border-2 rounded-lg items-center justify-center py-3 px-3">
                                <input
                                    autoComplete="off"
                                    className="w-full  rounded-lg outline-none"
                                    placeholder="15"
                                    name="fee_cliente"
                                    type="number"
                                    step=".01"
                                    onChange={handleInputChange}
                                    value={input.fee_cliente}
                                />
                                <span>
                                    <svg
                                        className="w-4 h-4 text-[#3C3C3C] rounded-lg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <line x1="19" y1="5" x2="5" y2="19" />{" "}
                                        <circle cx="6.5" cy="6.5" r="2.5" />{" "}
                                        <circle cx="17.5" cy="17.5" r="2.5" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <button
                            className="bg-blue-bluecorner text-white p-3 w-full rounded-lg mt-7"
                            onClick={() => handleUpdate()}>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditFee;
