import React, { useState } from "react";
import { useDispatch } from "react-redux";

import axios from "axios";
import Spinner from "../../Spinner";

const ExtraRules = ({ onboarding, setOnboarding, setPage, actualPage }) => {
    const dispatch = useDispatch();
    const [siguiente, setSiguiente] = useState("Siguiente");
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const updateProduct = (payload) => {
        setSiguiente(null);
        return async function () {
            if (onboarding.tipo_producto === "casa") {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/casa/actualizarReglasAdicionalesExtra/${onboarding.id}`,
                    payload,
                    { headers }
                );

                const jsonPage = await axios.put(
                    `${process.env.REACT_APP_PROXY}/casa/actualizarPagina/${onboarding.id}`,
                    {
                        numero_pagina: actualPage + 1,
                    },
                    { headers }
                );

                if (json.data.message === "actuaizacion exitoso") {
                    setOnboarding({
                        ...onboarding,
                        reglas_adicionales: json.data.data.reglas_adicionales,
                        extra: json.data.data.extra,
                    });
                    setPage(actualPage + 1);
                }
            } else if (onboarding.tipo_producto === "embarcacion") {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/embarcacion/actualizarReglasExtra/${onboarding.id}`,
                    payload,
                    { headers }
                );

                const jsonPage = await axios.put(
                    `${process.env.REACT_APP_PROXY}/embarcacion/actualizarPagina/${onboarding.id}`,
                    {
                        numero_pagina: actualPage + 1,
                    },
                    { headers }
                );
                if (json.data.message === "actuaizacion exitoso") {
                    console.log("embarcacion");
                    setOnboarding({
                        ...onboarding,
                        reglas: json.data.data.reglas,
                        extra: json.data.data.extra,
                    });
                    setPage(actualPage + 1);
                }
            }
        };
    };

    const nextPage = () => {
        dispatch(
            onboarding.tipo_producto === "casa"
                ? updateProduct({
                      reglas_adicionales: onboarding.reglas_adicionales,
                      extra: onboarding.extra,
                  })
                : updateProduct({
                      reglas: onboarding.reglas,
                      extra: onboarding.extra,
                  })
        );
    };
    return (
        <div className="w-full">
            <h1 className="py-8 text-4xl font-bold text-center sm:py-8 sm:text-6xl">
                {onboarding.tipo_producto === "casa"
                    ? "Reglas Adicionales"
                    : "Reglas"}
            </h1>
            <div className="p-4 mt-2 text-center ">
                <textarea
                    onChange={(e) =>
                        setOnboarding(
                            onboarding.tipo_producto === "casa"
                                ? {
                                      ...onboarding,
                                      reglas_adicionales: e.target.value,
                                  }
                                : {
                                      ...onboarding,
                                      reglas: e.target.value,
                                  }
                        )
                    }
                    value={
                        onboarding.tipo_producto === "casa"
                            ? onboarding.reglas_adicionales
                            : onboarding.reglas
                    }
                    autoComplete="off"
                    id="default"
                    type="text"
                    name="default"
                    placeholder="Ej: se requiere carnet de vacunación o prueba COVID para ingresar"
                    className="w-full py-10 text-sm font-bold text-center border-2 border-blue-300 rounded-lg sm:w-10/12 lg:text-3xl lg:py-12 lg:placeholder:font-medium focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>

            <h1 className="py-8 text-4xl font-bold text-center sm:py-8 sm:text-6xl">
                Extra
            </h1>
            <div className="p-4 mt-2 text-center ">
                <textarea
                    onChange={(e) =>
                        setOnboarding({ ...onboarding, extra: e.target.value })
                    }
                    value={onboarding.extra}
                    autoComplete="off"
                    id="default"
                    type="text"
                    name="default"
                    placeholder="Ej: Incluye: sábanas, almohadas, jabón y papel higiénico (no incluye toallas)"
                    className="w-full py-10 text-sm font-bold text-center border-2 border-blue-300 rounded-lg sm:w-10/12 lg:text-3xl lg:py-12 lg:placeholder:font-medium focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>
            <div className="flex items-center justify-center mt-20">
                <button
                    onClick={() => setPage(actualPage - 1)}
                    className="p-2 pl-5 pr-5 mr-2 text-lg text-gray-500 bg-transparent border-2 border-gray-500 rounded-lg min-w-[118px] hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 ">
                    Anterior
                </button>

                <button
                    onClick={() => nextPage()}
                    disabled={!siguiente}
                    className="my-5 p-2 pl-5 pr-5 text-lg text-gray-500 bg-transparent border-2 border-gray-500 rounded-lg min-w-[118px] hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 disabled:bg-white disabled:border-black">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div>
        </div>
    );
};

export default ExtraRules;
