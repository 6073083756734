import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import useHeaders from "../../../hooks/useHeaders";

const AutoComplete = ({ fields, setRefreshHouses }) => {
    const { headers } = useHeaders();

    const handleFieldClick = async (id_casa, nombre_casa) => {
        Swal.fire({
            title: `¿Seguro que quieres agregar ${nombre_casa} a la promo?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, agregar!",
            cancelButtonText: "No, mejor no",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_PROXY}/casaVerano/`,
                        { id_casa: id_casa },
                        { headers }
                    );

                    setRefreshHouses((prev) => !prev);
                } catch (error) {
                    console.log(error.response);
                }
            }
        });
    };

    return (
        <div
            className={`absolute z-20 bg-gray-100 border-2 rounded-md border-gray-300 -left-1 p-1 top-14 w-[102%] max-h-80 mt-0.5 overflow-auto`}>
            {fields.map(({ id, nombre, check }) => (
                <button
                    disabled={check === "No"}
                    key={id}
                    onClick={() => handleFieldClick(id, nombre)}
                    className="w-full p-2 mt-1 bg-white border-2 rounded-md cursor-pointer disabled:bg-gray-100 disabled:cursor-default">
                    {nombre}
                </button>
            ))}
        </div>
    );
};

export default AutoComplete;
