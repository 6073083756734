import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
const ClientReferido = ({ setClientReferido, clientReferido }) => {
    const authUser = useSelector((state) => state.authReducer);
    const [queryClientReferido, setQueryClientReferido] = useState("");
    const [isGetReferido, setIsGetReferido] = useState("");

    const getClientReferido = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/cliente/getClientesByQuery/?query=${queryClientReferido}`
        );

        if (data.length > 0) {
            if (
                (authUser.tipo_usuario === 6 || authUser.tipo_usuario === 7) &&
                authUser.id_empresa === data[0].id_empresa
            ) {
                setClientReferido("");
                setIsGetReferido("Empresa");
            } else {
                setClientReferido(data[0]);
            }
        } else {
            setClientReferido("");
            setIsGetReferido("No");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center w-full gap-2 mb-2 ">
            <div className="flex items-start justify-start w-full text-green-600 ">
                <div>¿Fuiste referido para esta reserva?</div>
            </div>
            <div className="flex items-center justify-center w-full gap-2">
                <div className="w-full">
                    <input
                        className="p-1.5 rounded-lg border-2 border-blue-500 text-blue-500 w-full placeholder:text-xs placeholder:text-center"
                        onChange={(e) => setQueryClientReferido(e.target.value)}
                        type="text"
                        name="referido"
                        placeholder="Ingrese el DNI o correo de la persona que te refirió"
                    />
                </div>
                <div
                    onClick={() => getClientReferido()}
                    className="bg-blue-500 text-white rounded-lg hover:scale-105 p-1.5 cursor-pointer">
                    <svg
                        class="h-8 w-8 text-white"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        {" "}
                        <path stroke="none" d="M0 0h24v24H0z" />{" "}
                        <circle cx="9" cy="7" r="4" />{" "}
                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />{" "}
                        <path d="M16 11h6m-3 -3v6" />
                    </svg>
                </div>
            </div>
            {clientReferido ? (
                <div className="flex items-center justify-center w-full gap-4 p-3 border-2 border-blue-500 rounded-lg">
                    <div></div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="flex items-center justify-center">
                            <div className="font-semibold ">Referido: </div>
                            <div className="">
                                {clientReferido.nombre}{" "}
                                {clientReferido.apellido}{" "}
                            </div>
                        </div>
                    </div>
                </div>
            ) : isGetReferido === "No" ? (
                <div className="flex items-center justify-center w-full gap-4 p-3 border-2 border-blue-500 rounded-lg">
                    No se encontro al usuario que te esta referiendo
                </div>
            ) : isGetReferido === "Empresa" ? (
                <div className="flex items-center justify-center w-full gap-4 p-3 border-2 border-blue-500 rounded-lg">
                    No puedes ser referido de este usuario porque perteneces a
                    la misma empresa.
                </div>
            ) : null}
        </div>
    );
};

export default ClientReferido;
