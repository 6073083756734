import React from "react";

export const ImagenListon = ({
    src,
    className,
    alt,
    listonText,
    listonColor,
    loading,
    dataSrc,
    dataSrcSet,
    mostrarListon = false,
}) => {
    return (
        <div>
            {mostrarListon && (
                <div
                    style={{ backgroundColor: listonColor || "rgb(185 28 28)" }}
                    className={`absolute left-[-40px] z-20 py-2 text-center -rotate-45  w-44 top-6`}>
                    <p className="text-lg text-white">{listonText}</p>
                </div>
            )}
            <img
                data-src={dataSrc || ""}
                data-srcset={dataSrcSet || ""}
                loading={loading}
                src={src || ""}
                className={`${className}`}
                alt={alt}
            />
        </div>
    );
};
