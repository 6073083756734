import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

const token = localStorage.getItem("token");
const headers = {
    authorization: token,
};

const ModalCreateManual = ({
    modalVisible,
    setmodalVisible,
    getAllManuals,
}) => {
    const [input, setInput] = useState({
        name: "",
        description: "",
        types: [],
        file: "",
    });
    const [cargando, SetCargando] = useState(false);

    const [typesSelected, setTypesSelected] = useState([]);
    const [allTypes, setAllTypes] = useState([]);
    const [inputFile, setInputFile] = useState(null);
    const form = new FormData();

    useEffect(() => {
        getAllTypes("Si");
    }, []);

    const handleInputChange = (e) => {
        if (e.target.name === "types") {
            const type = JSON.parse(e.target.value);
            setInput({
                ...input,
                [e.target.name]: [...input.types, type.id],
            });
            if (!typesSelected.includes(type.nombre))
                setTypesSelected([...typesSelected, type.nombre]);
        } else if (e.target.name === "file") {
            console.log(e.target.files[0]);
            setInput({
                ...input,
                [e.target.name]: e.target.files[0],
            });
        } else {
            setInput({
                ...input,
                [e.target.name]: e.target.value,
            });
        }
    };

    const getAllTypes = async (SioNo) => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/tipoManual/all/${SioNo}`,
            { headers }
        );
        if (data) {
            setAllTypes(data);
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        if (!input.name) {
            Swal.fire({
                title: "Error!",
                text: "El tipo de manual debe tener un nombre",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else if (!typesSelected.length) {
            Swal.fire({
                title: "Error!",
                text: "Debes seleccionar al menos un tipo de manual",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else if (!input.file) {
            Swal.fire({
                title: "Error!",
                text: "Debes seleccionar un archivo",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else {
            SetCargando(true);

            form.append("nombre", input.name);
            form.append("descripcion", input.description);
            form.append("pdf-manuales", input.file);

            input.types.forEach((type) =>
                form.append("tipos_manuales[]", type)
            );
            await axios.post(`${process.env.REACT_APP_PROXY}/Manual/`, form, {
                headers,
            });
            Swal.fire({
                title: "Manual creado correctamente!",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
            getAllManuals("Si");
            document.getElementById("file").value = "";
            document.getElementById("types").value = "Seleccione un tipo";
            setInput({
                name: "",
                description: "",
                file: "",
                types: [],
            });
            setTypesSelected([]);
            setmodalVisible(!modalVisible);
        }
    };

    const handleClose = () => {
        setTypesSelected([]);
        setInput({
            name: "",
            description: "",
            types: [],
            file: "",
        });
        document.getElementById("file").value = "";
        document.getElementById("types").value = "Seleccione un tipo";

        form.delete("nombre");
        form.delete("descripcion");
        form.delete("pdf-manuales");
        form.delete("tipos_manuales[]");

        setmodalVisible(!modalVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed bottom-0 left-0 w-full z-20 h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4  2xl:container 2xl:mx-auto md:px-28 py-24 ">
                    <div className="relative w-full block justify-center px-4 py-12 bg-white rounded-lg  md:w-96 dark:bg-white  h-[calc(100vh-10vh)] overflow-y-auto md:overflow-y-hidden ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-2 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mt-10 px-2 text-left text-base font-bold lg:text-2xl">
                            Crear manual
                        </header>
                        <div className="w-full px-3 rounded-lg  mt-7">
                            <label className="flex ">Nombre del manual</label>
                            <input
                                type="text"
                                value={input.name}
                                name="name"
                                onChange={handleInputChange}
                                placeholder="Ejm: Manual de Gestión de servicios"
                                className="w-full border my-1 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full px-3 rounded-lg  mt-2">
                            <label className="flex ">
                                Descripción del manual
                            </label>
                            <textarea
                                type="text"
                                value={input.description}
                                name="description"
                                onChange={handleInputChange}
                                placeholder="Descripcion del manual"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 h-32 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full px-3 rounded-lg  mt-2">
                            <label className="flex ">Categoría</label>
                            <select
                                id="types"
                                defaultValue={"Seleccione un tipo"}
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                name="types"
                                onChange={handleInputChange}>
                                <option disabled value={"Seleccione un tipo"}>
                                    Seleccione un tipo
                                </option>
                                {allTypes?.map((type) => (
                                    <option
                                        value={JSON.stringify(type)}
                                        key={type.id}
                                        className="p-2 outline-none">
                                        {type.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-4">
                            {typesSelected.length > 0 &&
                                typesSelected?.map((type, index) => {
                                    return (
                                        <div
                                            className="grid items-center grid-cols-[90%_10%] gap-4  mt-1 "
                                            key={type + index}>
                                            <span className="mx-2 ">
                                                {type}
                                            </span>
                                            <button
                                                className="flex items-center justify-center w-5 h-5 border-2 rounded-full hover:bg-red-600 hover:text-white"
                                                onClick={() => {
                                                    setTypesSelected(
                                                        typesSelected?.filter(
                                                            (types) =>
                                                                types !== type
                                                        )
                                                    );
                                                }}>
                                                <span className="-mt-1 hover:text-white">
                                                    x
                                                </span>
                                            </button>
                                        </div>
                                    );
                                })}
                        </div>
                        <hr />
                        <div className="">
                            <input
                                id="file"
                                type="file"
                                name="file"
                                className="file:bg-blue-bluecorner file:text-white file:p-3 file:border-none file:rounded-md p-3 px-1"
                                onChange={handleInputChange}
                            />
                        </div>
                        <hr />
                        <button
                            disabled={cargando}
                            className="w-full rounded-lg border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white  active:translate-y-[0.125rem]  mt-7"
                            onClick={handleCreate}>
                            {cargando ? "Creando..." : "Guardar"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreateManual;
