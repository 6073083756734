import axios from "axios";
import React, { lazy, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import useHeaders from "../../../hooks/useHeaders";
import formatedDate_YYYY_MM_DD from "../../helpers/formatedDate_YYYY_MM_DD";
// import ReactQuill, { Quill } from "react-quill";
import Swal from "sweetalert2";
const ReactQuill = lazy(() => import("react-quill"));
const { Quill } = lazy(() => import("react-quill"));

const ModalPlanificarActividad = ({
    modalVisible,
    setModalVisible,
    empresa,
    setRefreshActividades,
}) => {
    const authUser = useSelector((state) => state.authReducer);
    const [input, setInput] = useState({
        id_empresa: empresa.id,
        tipo_actividad: "Correo electronico",
        usuario_creado: authUser.id_usuario,
        usuario_asignado: "",
        actividad: "",
        resumen: "",
        fecha_limite: "",
        estado_actividad: "",
    });
    const [allUsersBC, setAllUsersBC] = useState([]);
    const { headers } = useHeaders();
    const quillRef = useRef(null);

    registerLocale("es", es);

    const Image = Quill.import("formats/image");
    Image.className = "image-react-quill";
    Quill.register(Image, true);

    const getAllUsersBC = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/autenticacion/all/1/Si`,
            { headers }
        );

        setAllUsersBC(data.data);
    };

    useEffect(() => {
        getAllUsersBC();
    }, []);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [3, 4, 5, 6, false] }],
                    [
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "code",
                    ],
                    [{ color: [] }, { background: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            },
        }),
        []
    );

    function imageHandler() {
        const editor = quillRef.current.getEditor();

        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            const form = new FormData();

            form.append("imagen_actividades_crm", file);

            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/actividadesCrm/postImage/`,
                form,
                { headers }
            );

            console.log(data);

            if (data.message === "registro exitoso") {
                editor.insertEmbed(
                    editor.getSelection(),
                    "image",
                    `${process.env.REACT_APP_PROXY}/actividadesCrm/getImage/${data.imagen}`
                );
            }
        };
    }

    const onChangeText = (text) => {
        text = text !== "<p><br></p>" ? text : "";
        setInput({ ...input, resumen: text });
    };

    const handlePlanificar = async () => {
        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/actividadesCrm/`,
            input,
            { headers }
        );

        if (data.message === "registro exitoso") {
            Swal.fire({
                title: "Actividad creada con exito!",
                icon: "success",
                confirmButtonText: "Aceptar",
            }).then(() => {
                setRefreshActividades((prev) => !prev);
                setModalVisible(false);
            });
        }
    };

    const handleClose = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed bottom-0 left-0 z-20 w-full h-full bg-gray-900 border-style bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 sm:py-8 ">
                    <div className="relative w-full block justify-center px-4 py-6 bg-white rounded-lg dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-4 right-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="p-2 text-base font-semibold text-left lg:text-2xl">
                            Planificación de actividad
                        </header>
                        <hr />
                        <div>
                            <div className="flex flex-col mt-6">
                                <span className="ml-1 text-sm text-gray-600">
                                    Tipo de actividad
                                </span>
                                <select
                                    className="py-1 border-b-2 outline-none"
                                    value={input.tipo_actividad}
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            tipo_actividad: e.target.value,
                                        })
                                    }>
                                    <option>Correo electronico</option>
                                    <option>Llamada</option>
                                    <option>Reunion</option>
                                    <option>Por hacer</option>
                                    <option>Subir documento</option>
                                </select>
                            </div>
                            <div className="flex flex-col mt-6">
                                <span className="text-sm text-gray-600">
                                    Nombre de actividad
                                </span>
                                <input
                                    className="py-1 border-b-2 outline-none"
                                    value={input.actividad}
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            actividad: e.target.value,
                                        })
                                    }
                                    placeholder="Por ejemplo, conversación de una propuesta"
                                />
                            </div>
                            <div className="flex flex-col mt-6">
                                <span className="ml-1 text-sm text-gray-600">
                                    Estado de actividad
                                </span>
                                <select
                                    className="py-1 border-b-2 outline-none"
                                    value={input.estado_actividad}
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            estado_actividad: e.target.value,
                                        })
                                    }>
                                    <option>Ocupado</option>
                                    <option>Conectado</option>
                                    <option>Sin respuesta</option>
                                    <option>Numero incorrecto</option>
                                </select>
                            </div>
                            <div className="flex flex-col mt-6">
                                <span className="ml-1 text-sm text-gray-600">
                                    Fecha vencimiento
                                </span>
                                <DatePicker
                                    className="w-full py-1 ml-1 border-b-2 outline-none"
                                    placeholderText="Fecha de vencimiento"
                                    autoComplete="off"
                                    locale="es"
                                    selected={new Date()}
                                    value={input.fecha_limite}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) =>
                                        setInput({
                                            ...input,
                                            fecha_limite:
                                                formatedDate_YYYY_MM_DD(date),
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col mt-6">
                                <span className="ml-1 text-sm text-gray-600">
                                    Asignada a
                                </span>
                                <select
                                    className="py-1 border-b-2 outline-none"
                                    value={input.usuario_asignado}
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            usuario_asignado: e.target.value,
                                        })
                                    }>
                                    {allUsersBC.length > 0 &&
                                        allUsersBC.map((user) => (
                                            <option
                                                key={user.id}
                                                value={user.id}>
                                                {user.nombre}{" "}
                                                {user.apellido_materno}{" "}
                                                {user.apellido_paterno}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="flex flex-col ml-1">
                                <span className="mt-5 mb-2 text-sm text-gray-600">
                                    Resumen de actividad
                                </span>
                                <ReactQuill
                                    ref={quillRef}
                                    theme="snow"
                                    value={input.resumen}
                                    onChange={onChangeText}
                                    placeholder={"Escribe algo..."}
                                    modules={modules}
                                    style={{
                                        backgroundColor: "white",
                                        height: "30vh",
                                    }}
                                />
                            </div>
                            <div className="flex flex-wrap justify-between w-full gap-2 mt-28 md:mt-20">
                                <button
                                    className="p-2 font-bold text-white cursor-pointer bg-blue-bluecorner min-w-fit hover:bg-blue-800"
                                    onClick={handlePlanificar}>
                                    PLANIFICACION
                                </button>
                                <button
                                    className="p-2 font-bold cursor-pointer text-blue-bluecorner min-w-fit hover:bg-gray-300 hover:text-black"
                                    onClick={handleClose}>
                                    DESCARTAR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalPlanificarActividad;
