import {
    GET_ALL_ATTRIBUTES,
    CREATE_ATTRIBUTES,
    RESET_CREATE_ATTRIBUTES,
    ONE_ATTRIBUTE,
    SEARCH_ATTRIBUTES,
    RESET_ONE_ATTRIBUTE,
    ENABLE_ATTRIBUTE,
    RESET_ENEBLE_ATTRIBUTES,
} from "../actions/attributesActions";

const initialState = {
    allAttributes: [],
    attributes: [],
    create: false,
    oneAttribute: [],
    enableAttribute: [],
};

export const attributesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ATTRIBUTES:
            return {
                ...state,
                attributes: action.payload,
                allAttributes: action.payload,
            };
        case CREATE_ATTRIBUTES:
            return {
                ...state,
                create: action.payload,
            };
        case RESET_CREATE_ATTRIBUTES:
            return {
                ...state,
                create: false,
            };
        case ONE_ATTRIBUTE:
            return {
                ...state,
                oneAttribute: action.payload,
            };
        case SEARCH_ATTRIBUTES:
            return {
                ...state,
                attributes: action.payload,
            };
        case RESET_ONE_ATTRIBUTE:
            return {
                ...state,
                oneAttribute: action.payload,
            };
        case ENABLE_ATTRIBUTE:
            return {
                ...state,
                enableAttribute: action.payload,
            };
        case RESET_ENEBLE_ATTRIBUTES:
            return {
                ...state,
                enableAttribute: [],
            };

        default:
            return state;
    }
};
