import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import puntosDetalles from "../helpers/puntosDetalles";
import ModalHousesCoins from "../ModalHousesCoins";

const ModalEditCliente = ({
    modalEditVisible,
    setModalEditVisible,
    user,
    getAllClientes,
}) => {
    const [input, setInput] = useState({
        userName: user.nombre,
        father_lastName: user.apellido_paterno,
        mother_lastName: user.apellido_materno,
        userEmail: user.correo,
        tipo_documentacion: user.tipo_documentacion || "",
        userDni: user.dni,
        userCelular: user.celular,
    });
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [modalHousesCoins, setModalHousesCoins] = useState(false);
    const documento = ["DNI", "NIF", "RUC", "Pasaporte", "Cédula Extranjera"];
    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/cliente/actualizar/${user.id}`,
            {
                nombre: input.userName,
                apellido_paterno: input.father_lastName,
                apellido_materno: input.mother_lastName,
                correo: input.userEmail,
                dni: input.userDni,
                celular: input.userCelular,
                tipo_documentacion: input.tipo_documentacion,
            },
            { headers }
        );

        Swal.fire({
            title: "Cliente actualizado correctamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
        });
        getAllClientes();
        setModalEditVisible((prev) => !prev);
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el usuario a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                const token = localStorage.getItem("token");
                const headers = {
                    authorization: token,
                };
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/cliente/eliminar/${id}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Usuario eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        setModalEditVisible((prev) => !prev);
                        getAllClientes();
                    });
            }
        });
    };

    const handleClose = () => {
        setModalEditVisible((prev) => !prev);
    };

    const handleModalBluecoins = () => {
        setModalHousesCoins(!modalHousesCoins);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalEditVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-8">
                    <div className="relative w-full block justify-center px-4 py-16 bg-white rounded-lg  md:w-5/12 dark:bg-white md:px-24 xl:py-12 xl:px-24 h-[calc(100vh-10vh)] overflow-y-auto">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-5 right-1 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mb-3 font-bold mr-auto ">
                            Datos del propietario
                        </header>

                        <div className="w-full mt-4">
                            <label className="font-semibold">Nombre</label>
                            <input
                                type="text"
                                value={input.userName}
                                name="userName"
                                onChange={handleInputChange}
                                placeholder="nombre"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Apellido paterno
                            </label>
                            <input
                                type="text"
                                value={input.father_lastName}
                                name="father_lastName"
                                onChange={handleInputChange}
                                placeholder="apellido paterno"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Apellido materno
                            </label>
                            <input
                                type="text"
                                value={input.mother_lastName}
                                name="mother_lastName"
                                onChange={handleInputChange}
                                placeholder="apellido materno"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Tipo de documento
                            </label>
                            <select
                                value={input.tipo_documentacion}
                                name="tipo_documentacion"
                                onChange={handleInputChange}
                                placeholder="DNI"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none">
                                <option className="bg-transparent" value="">
                                    {" "}
                                    Seleccionar{" "}
                                </option>
                                {documento.map((doc, i) => {
                                    return (
                                        <option
                                            key={i}
                                            className="bg-transparent"
                                            value={doc}>
                                            {" "}
                                            {doc}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Numero de documento
                            </label>
                            <input
                                type="text"
                                value={input.userDni}
                                name="userDni"
                                onChange={handleInputChange}
                                placeholder="DNI"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Correo electrónico:{" "}
                            </label>
                            <input
                                type="text"
                                value={input.userEmail}
                                name="userEmail"
                                onChange={handleInputChange}
                                placeholder="Correo electrónico"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        {/* <div className="w-full mt-4">
                            <label className="font-semibold">
                                DNI del usuario:{" "}
                            </label>
                            <input
                                type="text"
                                value={input.userDni}
                                name="userDni"
                                onChange={handleInputChange}
                                placeholder="Dni del usuario"
                                className="w-full p-3 outline-none - rounded-2xl bg-gray-50 ring-2 ring-gray-200"
                            />
                        </div> */}
                        <div className="w-full mt-2">
                            <label className="font-semibold">Teléfono</label>
                            <input
                                type="text"
                                value={input.userCelular}
                                name="userCelular"
                                onChange={handleInputChange}
                                placeholder="Celular del usuario"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        {/* <div className="w-full mt-2">
                            <label className="font-semibold">Dirección </label>
                            <input
                                type="text"
                                value={input.userDireccion}
                                name="userDireccion"
                                onChange={handleInputChange}
                                placeholder="Direccion del usuario"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div> */}
                        {modalHousesCoins && (
                            <ModalHousesCoins
                                modalVisible={modalHousesCoins}
                                setModalVisible={setModalHousesCoins}
                                clienteSelected={user}
                            />
                        )}
                        <button
                            className="w-full rounded-md bg-blue-500 py-3 text-white mt-5 active:translate-y-[0.125rem] "
                            onClick={handleUpdate}>
                            Guardar
                        </button>
                        <button
                            className="md:hidden w-full rounded-md border border-blue-bluecorner py-3 text-blue-bluecorner  mt-4 active:translate-y-[0.125rem]"
                            onClick={() => handleModalBluecoins()}>
                            {user.puntos_total} {puntosDetalles.nombre_coins}{" "}
                            disponibles
                        </button>
                        <button
                            className="md:hidden w-full rounded-md border border-blue-bluecorner py-3 text-blue-bluecorner  mt-4 active:translate-y-[0.125rem]"
                            onClick={() => handleDelete(user.id)}>
                            Eliminar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditCliente;
