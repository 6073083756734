const initialState = {
    house_filters: {
        id_usuario: 0,
        fecha_inicio: "",
        fecha_final: "",
        query_value: "",
        query_tipo: "",
        numero_personas: 0,
        numero_habitaciones: 0,
        numero_camas: 0,
        numero_banos: 0,
        numero_noches: 0,
        precio_rango: 0,
        tipo_moneda: "",
        atributos: [],
        reglas: [],
        ubicacion: [],
        tipo_alojamiento: 0,
        pagina: 1,
        order: "precio",
        mayor_menor: "",
        latitud: 0,
        longitud: 0,
        zoom: 10,
        ninos: 0,
        adultos: 0,
    },
    boat_filters: {
        fecha: "",
        query_value: "",
        query_tipo: "",
        numero_personas: 0,
        tipo_embarcacion: 0,
        duracion: [],
        pagina: 1,
        order: "precio",
        mayor_menor: "",
    },
};

export const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
        case "HOUSE_FILTERS":
            return {
                ...state,
                house_filters: {
                    ...state.house_filters,
                    ...action.payload,
                },
            };
        case "BOAT_FILTERS":
            return {
                ...state,
                boat_filters: {
                    ...state.boat_filters,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};
