import axios from "axios";
import React, { useRef, useState } from "react";
import {
    GoogleMap,
    LoadScript,
    Marker,
    StandaloneSearchBox,
} from "@react-google-maps/api";
import Swal from "sweetalert2";
import Spinner from "../../../Spinner";
import { useSelector } from "react-redux";

const EditarMapa = ({ producto, setProducto, setModal, tipo_producto }) => {
    const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const authUser = useSelector((state) => state.authReducer);
    const mapRef = useRef(null);
    const [searchBox, setSearchBox] = useState(null);
    const [siguiente, setSiguiente] = useState("Guardar");
    const [coordinates, setCoordinates] = useState({
        lat: producto.data.latitud || -12.04318,
        lng: producto.data.longitud || -77.02824,
        zoom: producto.data.mapZoom || 11,
    });
    const [ubication, setUbication] = useState({
        property_address: producto.data.direccion_calle,
        property_number: producto.data.direccion_numero,
        property_city: producto.data.ciudad,
        property_region: producto.data.departamento,
        property_postalCode: producto.data.codigo_postal,
        property_country: producto.data.pais,
    });
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleInputChange = (e) => {
        setUbication({
            ...ubication,
            [e.target.name]: e.target.value,
        });
    };

    const updateProduct = async (payload) => {
        setSiguiente(null);
        if (tipo_producto === "casa") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarMapa/${producto.data.id}`,
                payload,
                { headers }
            );
            if (data.message === "actuaizacion exitoso") {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        ...payload,
                    },
                });
                setModal(false);
            }
        } else if (tipo_producto === "embarcacion") {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/embarcacion/actualizarMapa/${producto.data.id}`,
                payload,
                { headers }
            );
            if (data.message === "actuaizacion exitoso") {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        ...payload,
                    },
                });
                setModal(false);
            }
        }
    };

    const handleGuardar = () => {
        const payload = {
            longitud: coordinates.lng,
            latitud: coordinates.lat,
            direccion_numero: ubication.property_number,
            direccion_calle: ubication.property_address,
            ciudad: ubication.property_city,
            departamento: ubication.property_region,
            codigo_postal: ubication.property_postalCode,
            pais: ubication.property_country,
            mapZoom: 14,
            id: producto.data.id,
            id_usuario: authUser.id_usuario,
        };
        console.log(payload);
        if (
            payload.direccion_calle === "" ||
            payload.direccion_calle === null ||
            payload.direccion_calle === undefined
        )
            alert("Calle");
        else if (
            payload.direccion_numero === "" ||
            payload.direccion_numero === null ||
            payload.direccion_numero === undefined
        )
            alert("Numero");
        else if (
            payload.ciudad === "" ||
            payload.ciudad === null ||
            payload.ciudad === undefined
        )
            alert("Ciudad");
        else if (
            payload.departamento === "" ||
            payload.departamento === null ||
            payload.departamento === undefined
        )
            alert("Departamento/Estado/Provincia/Región");
        else if (
            payload.codigo_postal === "" ||
            payload.codigo_postal === null ||
            payload.codigo_postal === undefined
        )
            alert("Codigo postal");
        else if (
            payload.pais === "" ||
            payload.pais === null ||
            payload.pais === undefined
        )
            alert("Pais o región");
        else updateProduct(payload);
    };

    const alert = (alert) => {
        Swal.fire({
            title: `Debes completar el campo de dirección: ${alert}`,
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Completar!",
        });
    };

    const handlePlacesChanged = () => {
        if (searchBox) {
            const places = searchBox.getPlaces();
            if (places.length === 0) return;

            const place = places[0];
            const location = place.geometry?.location;
            if (location) {
                setCoordinates({
                    lat: location.lat(),
                    lng: location.lng(),
                    zoom: 15,
                });
            }
        }
    };

    const onSearchBoxMounted = (ref) => {
        setSearchBox(ref);
    };

    return (
        <div className="p-2 border rounded-lg">
            <h1>Ubicación</h1>

            <div className="w-full mt-3 ">
                <div className="overflow-hidden">
                    <div className="w-full overflow-auto">
                        <label className="text-base text-[#3C3C3C]">
                            Señale la direccion de su propiedad en el mapa
                        </label>
                        <div className="h-80 md:h-96 xl:h-[35rem] mt-2 relative">
                            <StandaloneSearchBox
                                onLoad={onSearchBoxMounted}
                                onPlacesChanged={handlePlacesChanged}>
                                <input
                                    type="text"
                                    placeholder="Busca un lugar"
                                    className="z-50 input-box"
                                    style={{
                                        boxSizing: `border-box`,
                                        border: `1px solid transparent`,
                                        width: `300px`,
                                        height: `32px`,
                                        padding: `0 12px`,
                                        borderRadius: `3px`,
                                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                        fontSize: `14px`,
                                        outline: `none`,
                                        textOverflow: `ellipses`,
                                        position: "absolute",
                                        left: "50%",
                                        marginLeft: "-150px",
                                        top: "10px",
                                        zIndex: "50",
                                    }}
                                />
                            </StandaloneSearchBox>
                            <GoogleMap
                                options={{ disableDefaultUI: true }}
                                mapContainerStyle={{
                                    height: "92%",
                                    width: "100%",
                                    position: "relative",
                                    borderRadius: "8px",
                                }}
                                center={{
                                    lat: Number(coordinates.lat),
                                    lng: Number(coordinates.lng),
                                }}
                                zoom={coordinates.zoom}
                                onDragEnd={() => {
                                    const newCenter =
                                        mapRef.current.getCenter();
                                    setCoordinates({
                                        lat: newCenter.lat(),
                                        lng: newCenter.lng(),
                                        zoom: mapRef.current.getZoom(),
                                    });
                                }}
                                onLoad={(map) => (mapRef.current = map)}>
                                <Marker
                                    position={{
                                        lat: Number(coordinates.lat),
                                        lng: Number(coordinates.lng),
                                    }}
                                    draggable={true}
                                    onDragEnd={(e) => {
                                        setCoordinates({
                                            lat: e.latLng.lat(),
                                            lng: e.latLng.lng(),
                                        });
                                    }}
                                />
                            </GoogleMap>
                        </div>
                        <div className="p-1 mt-2">
                            <label className="text-base text-[#3C3C3C]">
                                Calle
                            </label>
                            <input
                                autoComplete="off"
                                value={ubication.property_address}
                                name="property_address"
                                onChange={handleInputChange}
                                type="text"
                                className="w-full px-3 py-2 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                            />
                        </div>
                        <div className="p-1 mt-2">
                            <label className="text-base text-[#3C3C3C]">
                                Número
                            </label>
                            <input
                                autoComplete="off"
                                value={ubication.property_number}
                                name="property_number"
                                onChange={handleInputChange}
                                type="text"
                                className="w-full px-3 py-2 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                            />
                        </div>
                        <div className="p-1 mt-2">
                            <label className="text-base text-[#3C3C3C]">
                                Ciudad
                            </label>
                            <input
                                autoComplete="off"
                                value={ubication.property_city}
                                name="property_city"
                                onChange={handleInputChange}
                                type="text"
                                className="w-full px-3 py-2 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                            />
                        </div>
                        <div className="p-1 mt-2">
                            <label className="text-base text-[#3C3C3C]">
                                Departamento/Estado/Provincia/Región
                            </label>
                            <input
                                autoComplete="off"
                                value={ubication.property_region}
                                name="property_region"
                                onChange={handleInputChange}
                                type="text"
                                className="w-full px-3 py-2 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                            />
                        </div>
                        <div className="p-1 mt-2">
                            <label className="text-base text-[#3C3C3C]">
                                Código postal
                            </label>
                            <input
                                autoComplete="off"
                                value={ubication.property_postalCode}
                                name="property_postalCode"
                                onChange={handleInputChange}
                                type="text"
                                className="w-full px-3 py-2 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                            />
                        </div>
                        <div className="p-1 mt-2">
                            <label className="text-base text-[#3C3C3C]">
                                País
                            </label>
                            <input
                                autoComplete="off"
                                value={ubication.property_country}
                                name="property_country"
                                onChange={handleInputChange}
                                type="text"
                                className="w-full px-3 py-2 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    disabled={!siguiente}
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div>
        </div>
    );
};

export default EditarMapa;
