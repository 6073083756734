import React from "react";
import imgChica from "../../img/Home/mujer.png";
import { ShowStarsRating } from "../StarsRating";

const CardPropietario = ({ propietario }) => {
    return (
        <div className="relative flex justify-center w-4/5 max-w-xl p-5 mx-auto border border-gray-300 my-28">
            <img
                src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${propietario?.avatar}`}
                alt="Imagen propietario"
                className="absolute object-cover border-2 rounded-full w-36 h-36 -top-16"
            />
            <div>
                {/* <h1 className="mt-20 text-4xl font-extrabold text-center">
                    {propietario?.nombre_tienda || "Cargando..."}
                </h1> */}
                <h1 className="mt-20 text-2xl font-semibold text-center">
                    {propietario?.nombre} {propietario?.apellido_paterno}{" "}
                    {propietario?.apellido_materno}
                </h1>
                <div className="flex justify-center my-5">
                    <ShowStarsRating starsQuantity={5} />
                </div>
            </div>
        </div>
    );
};

export default CardPropietario;
