import axios from "axios";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import Navbar from "../NavBar";
import Footer from "../Footer";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router";
import "./Calendar.css";
import MapDetail from "./MapDetail";

import Spinner from "../Spinner";
import getTotalPrice from "./getTotalPrice";
import { useDispatch, useSelector } from "react-redux";
import { getTotalFromDayNumbers } from "../helpers/getTotalPrice/getTotalFromDayNumbers";
import { getTotalFromRangoExactoDias } from "../helpers/getTotalPrice/getTotalFromRangoExactoDias";
import getTotalReservaInicial from "./getTotalReservaInicial";
import getTotalReservaSimple from "./getTotalReservaSimple";
import getTotalReservaFinal from "./getTotalReservaFinal";
import Swal from "sweetalert2";
import ModalLogeo from "../alquilerScreen/alquilerAlojamiento/ModalLogeo";
import ModalDetallesReseñas from "./ModalDetallesReseñas";

import puntosDetalles from "../helpers/puntosDetalles";
import ubication_icon from "../../img/iconos/ubication_icon.png";
import people_icon from "../../img/iconos/people_icon.png";
import bedrooms_icon from "../../img/iconos/bedrooms_icon.png";
import bathrooms_icon from "../../img/iconos/bathrooms_icon.png";
import { getUserPoints } from "../../redux/actions/authActions";
import getFeeBC from "./getFeeBC";
import ViewReview from "../reviews/ViewReview";

import priceToMonedaElegida from "../helpers/priceToMonedaElegida";

import colores from "../helpers/colores";
import BluecoinsBar from "./BluecoinsBar";

import { ModalGaleriaImagenes } from "./ModalGaleriaImagenes";

import ImagesDetailedCasa from "./componentsDetailed/ImagesDetailedCasa";
import HelmetCasa from "./componentsDetailed/HelmetCasa";
import DetailedReserva from "./componentsDetailed/DetailedReserva";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import ModalImagen from "./ModalImagen";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export const HabitacionComponent = ({ habitacion }) => {
    const descripcionConSaltosDeLinea = habitacion.descripcion.split("\n");

    return (
        <div>
            {descripcionConSaltosDeLinea.map((linea, index) => (
                <React.Fragment key={index}>
                    <span className="text-sm">{linea}</span>
                    {index < descripcionConSaltosDeLinea.length - 1 && <br />}
                </React.Fragment>
            ))}
        </div>
    );
};

const DetailedAlojamiento = () => {
    const [currentProduct, setCurrentProduct] = useState({});

    const [aditionalServices, setAditionalServices] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);

    const [cantDays, setCantDays] = useState(0);

    const [modalImagesVisible, setModalImagesVisible] = useState(false);
    const [arrayMeses, setArrayMeses] = useState([]);
    const [arrayDias, setArrayDias] = useState([]);
    const [arrayFechas, setArrayFechas] = useState([]);
    const [arrayPedidos, setArrayPedidos] = useState([]);
    const [favorite, setFavorite] = useState(false);
    const [loading, setLoading] = useState(true);
    const [habilitarModal, setHabilitarModal] = useState(false);
    const [habilitarModalFormulario, setHabilitarModalFormulario] =
        useState(false);
    const [modalLogeo, setModalLogeo] = useState(false);
    const [modalDetalles, setModalDetalles] = useState(false);

    const [cantMascotas, setCantMascotas] = useState(0);
    const dispatch = useDispatch();
    const { idProduct, productName } = useParams();
    const showMap = useRef(false);
    const authUser = useSelector((state) => state.authReducer);
    const promoPadre = useSelector((state) => state.productReducer);
    const [modalVisible, setModalVisible] = useState(null);
    const moneda_elegida = localStorage.getItem("moneda");

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const swiperRef = useRef(null);
    const swiperRefHabitacionServicio = useRef(null);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const goNextServi = () => {
        if (
            swiperRefHabitacionServicio.current &&
            swiperRefHabitacionServicio.current.swiper
        ) {
            swiperRefHabitacionServicio.current.swiper.slideNext();
        }
    };

    const goPrevServi = () => {
        if (
            swiperRefHabitacionServicio.current &&
            swiperRefHabitacionServicio.current.swiper
        ) {
            swiperRefHabitacionServicio.current.swiper.slidePrev();
        }
    };

    useEffect(() => {
        getProductDetails(idProduct);
        window.scrollTo(0, 0);
        dispatch(getUserPoints(authUser.id_usuario));
    }, [authUser.id_usuario]);

    useLayoutEffect(() => {
        showMap.current = true;
    }, []);

    const getProductDetails = async (id) => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/casa/detalle/${id}/${
                authUser.logged ? authUser.id_usuario : 0
            }`
        );

        setFavorite(data.favorito === "Si" ? true : false);
        setCurrentProduct({ ...data, huespedes: 0 });
        setArrayPedidos(data.disponibilidad.pedidos);
        setArrayDias(data.disponibilidad.arrayDias);
        setArrayMeses(data.disponibilidad.arrayMeses);
        setArrayFechas(data.disponibilidad.arrayFechas);
        setAditionalServices(
            data.servicios_adicionales.filter(
                (servicio_adicional) =>
                    servicio_adicional.tipo === "Obligatorio"
            )
        );
        setLoading(true);
    };

    const getGarantiaTotal = () => {
        let totalWarranty = currentProduct.garantia;

        if (currentProduct.tipo_garantia === "Valor") {
            totalWarranty = priceToMonedaElegida(
                totalWarranty,
                currentProduct.tipo_moneda_garantia
            );
        } else if (currentProduct.tipo_garantia === "Porcentaje") {
            totalWarranty = Math.ceil(
                Number(
                    getTotalReservaInicial(
                        cantDays,
                        selectedDays,
                        currentProduct
                    ) *
                        (totalWarranty / 100)
                )
            );
        } else if (currentProduct.tipo_garantia === "Por noche") {
            totalWarranty = priceToMonedaElegida(
                Number(currentProduct.garantia * (cantDays - 1)),
                currentProduct.tipo_moneda_garantia
            );
        } else if (currentProduct.tipo_garantia === "Por día") {
            totalWarranty = priceToMonedaElegida(
                Number(currentProduct.garantia * cantDays),
                currentProduct.tipo_moneda_garantia
            );
        } else if (currentProduct.tipo_garantia === "Rango de días") {
            if (currentProduct.rango_dias_garantias) {
                const selected_rango =
                    currentProduct.rango_dias_garantias.filter((range) => {
                        if (
                            cantDays >= range.dia_inicio &&
                            cantDays <= range.dia_final
                        ) {
                            return range;
                        }
                    });
                if (selected_rango.length > 0) {
                    totalWarranty = priceToMonedaElegida(
                        selected_rango[0].precio,
                        currentProduct.tipo_moneda_garantia
                    );
                } else {
                    totalWarranty = 0;
                }
            } else {
                totalWarranty = 0;
            }
        }

        if (cantMascotas > 0) {
            totalWarranty =
                Number(totalWarranty) + currentProduct.garantia_mascota;
        }

        return `${totalWarranty} ${moneda_elegida}`;
    };

    const postFavorite = async () => {
        setLoading(false);
        if (authUser.logged === false) {
            setHabilitarModal(true);
            setModalLogeo(true);
        } else {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/favorito/`,
                {
                    id_producto: currentProduct.id,
                    id_cliente: authUser.id_usuario,
                    tipo: "casa",
                },
                { headers }
            );

            if (json.data.message === "registro exitoso") {
                setFavorite(true);
                setLoading(true);
            } else {
                Swal.fire({
                    title: "Error",
                    text: "No se pudo agregar al favorito",
                    icon: "error",
                    confirmButtonText: "Ok",
                });

                setLoading(true);
            }
        }
    };

    const deleteFavorite = async () => {
        setLoading(false);
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/favorito/eliminar`,
            {
                id_producto: currentProduct.id,
                id_cliente: authUser.id_usuario,
                tipo: "casa",
            },
            { headers }
        );

        if (json.data.message === "Registro eliminado con exito") {
            setFavorite(false);
            setLoading(true);
        } else {
            Swal.fire({
                title: "Error",
                text: "No se pudo eliminar del favorito",
                icon: "error",
                confirmButtonText: "Ok",
            });

            setLoading(true);
        }
    };

    console.log(currentProduct);

    if (
        currentProduct.publicado === "Publicado" ||
        (currentProduct.publicado === "Revision" &&
            (authUser.tipo_usuario === 1 ||
                authUser.tipo_usuario === 2 ||
                authUser.id_usuario === currentProduct.id_propietario ||
                authUser.id_usuario === currentProduct.id_encargado))
    ) {
        return (
            <div>
                {currentProduct?.nombre && (
                    <HelmetCasa currentProduct={currentProduct} />
                )}
                <Navbar />

                {modalVisible && (
                    <ModalImagen
                        setModalVisible={setModalVisible}
                        imagen={modalVisible}
                        tipo="productos"
                    />
                )}
                <div className="absolute w-full ">
                    {habilitarModal && (
                        <ModalLogeo
                            setLoadingFavorito={setLoading}
                            modalLogeo={modalLogeo}
                            setModalLogeo={setModalLogeo}
                        />
                    )}
                </div>
                <div className="absolute w-full ">
                    {habilitarModalFormulario && (
                        <ModalLogeo
                            modalLogeo={habilitarModalFormulario}
                            setModalLogeo={setHabilitarModalFormulario}
                        />
                    )}
                </div>
                <div className="absolute w-full ">
                    {modalDetalles && (
                        <ModalDetallesReseñas
                            modalDetalles={modalDetalles}
                            setModalDetalles={setModalDetalles}
                            reseñas={currentProduct.resena}
                        />
                    )}
                </div>
                <div className="w-11/12 mx-auto xs:w-4/5">
                    <ImagesDetailedCasa
                        currentProduct={currentProduct}
                        setModalImagesVisible={setModalImagesVisible}
                        modalImagesVisible={modalImagesVisible}
                    />

                    <section className="w-11/12 mx-auto mb-10 lg:grid grid-cols-[60%_40%]">
                        <div className="">
                            <div className="flex flex-col p-6 ">
                                {authUser.tipo_usuario === 5 &&
                                    currentProduct.id === 30329 &&
                                    currentProduct?.alianza_empresa ===
                                        "No" && (
                                        <BluecoinsBar
                                            currentProduct={currentProduct}
                                        />
                                    )}
                                <div className="flex items-center justify-between ">
                                    <h1
                                        className={`text-[${colores.primario}] text-3xl font-bold`}>
                                        {currentProduct?.nombre ||
                                            "Cargando..."}
                                    </h1>
                                    {!loading ? (
                                        <div>
                                            {" "}
                                            <Spinner width={16} />{" "}
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                favorite
                                                    ? deleteFavorite()
                                                    : postFavorite();
                                            }}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className={`h-16 w-16 p-1 ${
                                                    favorite
                                                        ? "text-red-500 bg-white"
                                                        : "text-gray-100 bg-red-200 "
                                                }  rounded-full cursor-pointer`}
                                                viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                                <h2 className="flex items-center mt-5 text-xl text-gray-500">
                                    <img
                                        className={`w-4 h-5 mr-3 text-[${colores.secundario}] `}
                                        src={ubication_icon}
                                        alt="icono_ubicacion"
                                    />
                                    <span className="mx-1 text-base">
                                        {currentProduct?.ciudad ||
                                            "Cargando..."}
                                        ,
                                    </span>
                                    <span className="mx-1 text-base">
                                        {currentProduct?.pais || "Cargando..."}
                                    </span>
                                </h2>
                                <div className="flex flex-col w-10/12 mt-2 2xl:w-7/12 xs:flex-row">
                                    <div>
                                        <span className="flex items-center  text-[#c58b68]">
                                            <img
                                                className={`w-6 h-4 mr-2 text-[${colores.secundario}] `}
                                                src={people_icon}
                                                alt="icono_personas"
                                            />
                                            {currentProduct?.numero_personas}{" "}
                                            Personas
                                        </span>
                                    </div>
                                    <div className="md:mx-4">
                                        <span className="flex items-center  text-[#c58b68]">
                                            <img
                                                className={`w-7 h-4 mr-2 text-[${colores.secundario}]`}
                                                src={bedrooms_icon}
                                                alt="icono_habitaciones"
                                            />
                                            {
                                                currentProduct?.numero_habitaciones
                                            }{" "}
                                            Habitaciones
                                        </span>
                                    </div>
                                    <span className="flex items-center  text-[#c58b68]">
                                        <img
                                            className={`h-4 w-6 mr-2 text-[${colores.secundario}] `}
                                            src={bathrooms_icon}
                                            alt="icono_baños"
                                        />
                                        {currentProduct?.numero_banos} Baños
                                    </span>
                                </div>
                                {currentProduct?.link360 &&
                                    /^(ftp|http|https):\/\/[^ "]+$/.test(
                                        currentProduct.link360
                                    ) && (
                                        <iframe
                                            className="w-full h-[300px] md:h-100 mt-10 md:mt-5"
                                            src={currentProduct.link360}
                                            allowFullScreen={true}
                                            title="Video 360°"
                                        />
                                    )}
                            </div>
                            <hr className="w-11/12 mx-auto border-black boder-t-2" />
                            <div className="w-11/12 mx-auto mt-7">
                                <h3 className="text-2xl font-semibold ">
                                    Sobre el alojamiento
                                </h3>
                                <div
                                    className="mt-3 text-lg"
                                    dangerouslySetInnerHTML={{
                                        __html: currentProduct?.descripcion?.replace(
                                            /\n/g,
                                            "<br>"
                                        ),
                                    }}></div>
                            </div>

                            {currentProduct?.habitacciones_detalle?.length >
                                0 && (
                                <div className="w-11/12 mx-auto mt-7">
                                    <hr className="mx-auto border-black mt-7 boder-t-2" />
                                    <h3 className="mt-5 text-2xl font-semibold">
                                        Distribución de habitaciones
                                    </h3>
                                    {/* <div className="grid grid-cols-2 gap-4">
                                     */}
                                    <div className="relative mt-8">
                                        <div className=" absolute top-[-35px] right-4 gap-2 ">
                                            <button
                                                onClick={goPrev}
                                                className="p-2 border-2 rounded-full shadow-2xl hover:scale-105">
                                                <svg
                                                    className="w-5 h-5 text-black"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <polyline points="15 6 9 12 15 18" />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={goNext}
                                                className="p-2 ml-2 border-2 rounded-full shadow-2xl hover:scale-105">
                                                <svg
                                                    className="w-5 h-5 text-black"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <polyline points="9 18 15 12 9 6" />
                                                </svg>
                                            </button>
                                        </div>
                                        <Swiper
                                            ref={swiperRef}
                                            modules={[
                                                Navigation,
                                                Pagination,
                                                Autoplay,
                                            ]}
                                            spaceBetween={10}
                                            slidesPerView={1}
                                            breakpoints={{
                                                500: {
                                                    slidesPerView: 2,
                                                    spaceBetween: 10,
                                                },
                                            }}
                                            className="px-1">
                                            {currentProduct?.habitacciones_detalle?.map(
                                                (habitacion, index) => (
                                                    <SwiperSlide
                                                        key={habitacion.id}>
                                                        {habitacion.imagen ===
                                                        "sinImagen.png" ? (
                                                            <div className="mt-3 rounded-xl p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-80 overflow-hidden mx-2 mb-2">
                                                                <div className="h-44">
                                                                    {" "}
                                                                    <img
                                                                        onClick={() =>
                                                                            setModalVisible(
                                                                                habitacion.imagen
                                                                            )
                                                                        }
                                                                        src={`${
                                                                            process
                                                                                .env
                                                                                .REACT_APP_PROXY_ARCHIVOS
                                                                        }${"/bluecorner/v1/productosHabitaciones/getImagen/"}${
                                                                            habitacion.imagen_minificada
                                                                        }`}
                                                                        alt="imagen habitacion"
                                                                        className="object-cover w-full cursor-pointer rounded-xl h-44"
                                                                    />
                                                                </div>
                                                                <h3
                                                                    className={`text-[${colores.primario}] text-lg font-semibold mb-4 `}>
                                                                    {
                                                                        habitacion.nombre_habitacion
                                                                    }
                                                                </h3>
                                                                <HabitacionComponent
                                                                    habitacion={
                                                                        habitacion
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="mt-3 rounded-xl p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-80 overflow-hidden">
                                                                <img
                                                                    onClick={() =>
                                                                        setModalVisible(
                                                                            habitacion.imagen
                                                                        )
                                                                    }
                                                                    src={`${
                                                                        process
                                                                            .env
                                                                            .REACT_APP_PROXY_ARCHIVOS
                                                                    }${"/bluecorner/v1/productosHabitaciones/getImagen/"}${
                                                                        habitacion.imagen_minificada
                                                                    }`}
                                                                    alt="imagen habitacion"
                                                                    className="object-cover w-full cursor-pointer rounded-xl h-44 "
                                                                />
                                                                <h3
                                                                    className={`text-[${colores.primario}] text-lg font-semibold mb-1 `}>
                                                                    {
                                                                        habitacion.nombre_habitacion
                                                                    }
                                                                </h3>
                                                                <HabitacionComponent
                                                                    habitacion={
                                                                        habitacion
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </SwiperSlide>
                                                )
                                            )}
                                        </Swiper>
                                    </div>
                                </div>
                            )}

                            {currentProduct?.habitacciones_detalle_servicio
                                ?.length > 0 && (
                                <div className="w-11/12 mx-auto mt-7">
                                    <hr className="mx-auto border-black mt-7 boder-t-2" />
                                    <h3 className="mt-5 text-2xl font-semibold">
                                        Distribución de cuartos de servicios
                                    </h3>

                                    <div className="relative mt-8">
                                        <div className=" absolute top-[-35px] right-4 gap-2 ">
                                            <button
                                                onClick={goPrevServi}
                                                className="p-2 border-2 rounded-full shadow-2xl hover:scale-105">
                                                <svg
                                                    className="w-5 h-5 text-black"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <polyline points="15 6 9 12 15 18" />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={goNextServi}
                                                className="p-2 ml-2 border-2 rounded-full shadow-2xl hover:scale-105">
                                                <svg
                                                    className="w-5 h-5 text-black"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <polyline points="9 18 15 12 9 6" />
                                                </svg>
                                            </button>
                                        </div>
                                        <Swiper
                                            ref={swiperRefHabitacionServicio}
                                            modules={[
                                                Navigation,
                                                Pagination,
                                                Autoplay,
                                            ]}
                                            spaceBetween={10}
                                            loop={true}
                                            slidesPerView={1}
                                            breakpoints={{
                                                500: {
                                                    slidesPerView: 2,
                                                    spaceBetween: 10,
                                                },
                                            }}
                                            className="px-1">
                                            {currentProduct?.habitacciones_detalle_servicio?.map(
                                                (habitacion, index) => (
                                                    <SwiperSlide
                                                        key={habitacion.id}>
                                                        {habitacion.imagen ===
                                                        "sinImagen.png" ? (
                                                            <div className="mt-3 rounded-xl p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-80 overflow-hidden mx-2 mb-2">
                                                                <div className="h-44">
                                                                    {" "}
                                                                    <img
                                                                        onClick={() =>
                                                                            setModalVisible(
                                                                                habitacion.imagen
                                                                            )
                                                                        }
                                                                        src={`${
                                                                            process
                                                                                .env
                                                                                .REACT_APP_PROXY_ARCHIVOS
                                                                        }${"/bluecorner/v1/productosHabitaciones/getImagen/"}${
                                                                            habitacion.imagen_minificada
                                                                        }`}
                                                                        alt="imagen habitacion"
                                                                        className="object-cover w-full cursor-pointer rounded-xl h-44"
                                                                    />
                                                                </div>
                                                                <h3
                                                                    className={`text-[${colores.primario}] text-lg font-semibold mb-4 `}>
                                                                    {
                                                                        habitacion.nombre_habitacion
                                                                    }
                                                                </h3>
                                                                <HabitacionComponent
                                                                    habitacion={
                                                                        habitacion
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="mt-3 rounded-xl p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-80 overflow-hidden">
                                                                <img
                                                                    onClick={() =>
                                                                        setModalVisible(
                                                                            habitacion.imagen
                                                                        )
                                                                    }
                                                                    src={`${
                                                                        process
                                                                            .env
                                                                            .REACT_APP_PROXY_ARCHIVOS
                                                                    }${"/bluecorner/v1/productosHabitaciones/getImagen/"}${
                                                                        habitacion.imagen_minificada
                                                                    }`}
                                                                    alt="imagen habitacion"
                                                                    className="object-cover w-full cursor-pointer rounded-xl h-44 "
                                                                />
                                                                <h3
                                                                    className={`text-[${colores.primario}] text-lg font-semibold mb-1 `}>
                                                                    {
                                                                        habitacion.nombre_habitacion
                                                                    }
                                                                </h3>
                                                                <HabitacionComponent
                                                                    habitacion={
                                                                        habitacion
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </SwiperSlide>
                                                )
                                            )}
                                        </Swiper>
                                    </div>
                                </div>
                            )}
                            <hr className="w-11/12 mx-auto border-black mt-7 boder-t-2" />

                            <div className="w-11/12 mx-auto mt-7">
                                <h3 className="text-2xl font-semibold ">
                                    Puedes contar con esto
                                </h3>
                                <div className="grid-cols-2 xs:grid">
                                    {currentProduct?.atributos?.map(
                                        (atributo, index) => (
                                            <div className="mt-3" key={index}>
                                                <div className="flex items-center">
                                                    <img
                                                        className="w-8"
                                                        src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/atributo/getImage/${atributo.imagen}`}
                                                        alt={atributo.nombre}
                                                    />
                                                    <span className="mx-2 capitalize">
                                                        {atributo.nombre}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <hr className="w-11/12 mx-auto border-black mt-7 boder-t-2" />
                            <div className="w-11/12 mx-auto mt-7">
                                <h3 className="text-2xl font-semibold ">
                                    Estas son las reglas
                                </h3>
                                <div className="grid-cols-2 xs:grid">
                                    {currentProduct?.reglas?.map(
                                        (regla, index) => (
                                            <div className="mt-3" key={index}>
                                                {regla.regla_seleccionada ===
                                                "Si" ? (
                                                    <div className="flex items-center">
                                                        <img
                                                            className="w-8"
                                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/regla/getImagen/${regla.icono_si}`}
                                                            alt={
                                                                regla.nombre_si
                                                            }
                                                        />
                                                        <span className="mx-2 capitalize">
                                                            {regla.nombre_si}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center">
                                                        <img
                                                            className="w-8"
                                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/regla/getImagen/${regla.icono_no}`}
                                                            alt={
                                                                regla.nombre_no
                                                            }
                                                        />
                                                        <span className="mx-2 capitalize">
                                                            {regla.nombre_no}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )}
                                    <div className="grid mt-3 font-medium">
                                        <div>
                                            {" "}
                                            <h6 className="text-lg ">
                                                Check in :{" "}
                                                <span className="font-normal">
                                                    {currentProduct.check_in}{" "}
                                                    hrs
                                                </span>
                                            </h6>
                                        </div>
                                        <div>
                                            {" "}
                                            <h6 className="text-lg ">
                                                Check out :{" "}
                                                <span className="font-normal">
                                                    {currentProduct.check_out}{" "}
                                                    hrs
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="w-11/12 mx-auto border-black mt-7 boder-t-2" />
                            {/* {currentProduct?.reglas_adicionales?.length > 0 && ( */}
                            <div className="w-11/12 mx-auto mt-7">
                                <h3 className="text-2xl font-semibold ">
                                    Estas son las reglas adicionales
                                </h3>
                                <p
                                    className="mt-2 text-lg"
                                    dangerouslySetInnerHTML={{
                                        __html: currentProduct?.reglas_adicionales?.replace(
                                            /\n/g,
                                            "<br>"
                                        ),
                                    }}></p>

                                <p className="my-3 uppercase">
                                    * Precios solo del mes actual, los
                                    siguientes meses a consultar
                                </p>
                                <p className="my-3 uppercase">
                                    * Precios por feriados a consultar (Día de
                                    la madre, Día del padre, Semana Santa,
                                    Fiestas Patrias, Navidad y Año Nuevo, y
                                    otros días feriados)
                                </p>
                            </div>
                            {/*   )} */}
                            {currentProduct?.garantia > 0 && (
                                <div className="w-11/12 mx-auto mt-7">
                                    <hr className="mx-auto border-black w-12/13 mt-7 boder-t-2" />
                                    <h3 className="text-2xl font-semibold mt-7 ">
                                        Garantía
                                    </h3>
                                    <h2 className="mt-2 text-lg">
                                        {currentProduct?.tipo_garantia ===
                                        "Porcentaje"
                                            ? `${currentProduct.garantia}% del costo del alquiler`
                                            : currentProduct.tipo_garantia ===
                                              "Valor"
                                            ? getGarantiaTotal()
                                            : currentProduct.tipo_garantia ===
                                              "por_noche"
                                            ? `${priceToMonedaElegida(
                                                  currentProduct.garantia,
                                                  currentProduct.tipo_moneda_garantia
                                              )} por noche`
                                            : currentProduct.tipo_garantia ===
                                              "por_dia"
                                            ? `${priceToMonedaElegida(
                                                  currentProduct.garantia,
                                                  currentProduct.tipo_moneda_garantia
                                              )} por dia`
                                            : currentProduct.tipo_garantia ===
                                              "Rango de días"
                                            ? `Por rango de días`
                                            : null}
                                    </h2>
                                    <h2 className="mt-2 text-lg">
                                        *Reembolsable previa revisión del estado
                                        del inmueble al finalizar la estadía
                                    </h2>
                                </div>
                            )}
                            {currentProduct?.cancelacion && (
                                <div className="w-11/12 mx-auto mt-7">
                                    <hr className="mx-auto border-black w-12/13 mt-7 boder-t-2" />

                                    <h3 className="text-2xl font-semibold mt-7">
                                        Cancelación
                                    </h3>
                                    <div>
                                        <h2 className="mt-2 text-lg">
                                            Tipo de cancelacion:{" "}
                                            {currentProduct.cancelacion}
                                        </h2>
                                        <h2 className="mt-2 text-lg">
                                            {currentProduct.cancelacion ===
                                                "Moderado" && (
                                                <p className="text-base">
                                                    {`Acepta cancelaciones con 72
                                                    horas antes del inicio del
                                                    alquiler y devuelve un
                                                    porcentaje de ${currentProduct.porcentaje_moderado}%.`}
                                                </p>
                                            )}
                                            {currentProduct.cancelacion ===
                                                "Flexible" && (
                                                <p className="text-base">
                                                    Acepta cancelaciones con 72
                                                    horas antes del inicio del
                                                    alquiler y devuelve el 100%
                                                    del dinero al cliente.
                                                </p>
                                            )}
                                            {currentProduct.cancelacion ===
                                                "Reprogramación" && (
                                                <p className="text-base">
                                                    Acepta cambios con 72 horas
                                                    del inicio del alquiler, da
                                                    como crédito el 100% del
                                                    dinero al cliente y este
                                                    será utilizado en un plazo
                                                    máximo de 12 meses. Dos
                                                    reprogramaciones como
                                                    máximo. Cabe señalar que por
                                                    cada reprogramación, el
                                                    cliente deberá pagar 20% del
                                                    total del alquiler como fee
                                                    administrativo a Blue
                                                    Corner.
                                                </p>
                                            )}
                                            {currentProduct.cancelacion ===
                                                "Inflexible" && (
                                                <p className="text-base">
                                                    No se aceptan devoluciones
                                                    ni reprogramaciones.
                                                </p>
                                            )}
                                        </h2>
                                    </div>
                                </div>
                            )}
                        </div>

                        <DetailedReserva
                            currentProduct={currentProduct}
                            setCurrentProduct={setCurrentProduct}
                            aditionalServices={aditionalServices}
                            setAditionalServices={setAditionalServices}
                            habilitarModalFormulario={habilitarModalFormulario}
                            setHabilitarModalFormulario={
                                setHabilitarModalFormulario
                            }
                            arrayDias={arrayDias}
                            arrayMeses={arrayMeses}
                            arrayFechas={arrayFechas}
                            arrayPedidos={arrayPedidos}
                            selectedDays={selectedDays}
                            setSelectedDays={setSelectedDays}
                            cantDays={cantDays}
                            setCantDays={setCantDays}
                            getGarantiaTotal={getGarantiaTotal}
                            cantMascotas={cantMascotas}
                            setCantMascotas={setCantMascotas}
                            showMap={showMap}
                        />
                    </section>
                </div>
                {!currentProduct.resena ? (
                    <Spinner />
                ) : (
                    <ViewReview
                        currentProduct={currentProduct}
                        setModalDetalles={setModalDetalles}
                    />
                )}
                {/* <div className="w-4/5 mx-auto">
                    {showMap?.current &&
                    Object.keys(currentProduct).length !== 0 ? (
                        <MapDetail
                            ubication={{
                                lat: Number(currentProduct?.latitud),
                                long: Number(currentProduct?.longitud),
                            }}
                        />
                    ) : null}
                </div> */}

                {currentProduct?.imagenes && modalImagesVisible && (
                    <ModalGaleriaImagenes
                        modalVisible={modalImagesVisible}
                        setModalVisible={setModalImagesVisible}
                        imagenes={currentProduct?.imagenes}
                        imagen_principal={currentProduct?.imagen_principal}
                        tipo="productos"
                        producto={currentProduct.id}
                    />
                )}
                <Footer showWspText={true} />
            </div>
        );
    } else
        return (
            <div>
                {currentProduct?.nombre && (
                    <HelmetCasa currentProduct={currentProduct} />
                )}
                <Navbar />

                {currentProduct.id ? (
                    <div
                        className={`${
                            authUser.tipo_usuario !== null &&
                            authUser.tipo_usuario <= 2
                                ? "pt-40"
                                : "pt-32"
                        } flex flex-col  select-none`}>
                        <div className="p-36">
                            <div className="p-10 text-xl border-8 border-dotted md:text-center md:text-3xl">
                                Casa no disponible en este momento
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="my-56">
                        <Spinner />
                    </div>
                )}
                <Footer showWspText={true} />
            </div>
        );
};

export default DetailedAlojamiento;
