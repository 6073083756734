import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
    getRulesPerPage,
    clearRuleSelected,
} from "../../redux/actions/rulesActions";
import Spinner from "../Spinner";

const ModalEditRules = ({
    modalVisible,
    setmodalVisible,
    rule,
    actualPage,
}) => {
    const [siguiente, setSiguiente] = useState("Guardar");
    const [input, setInput] = useState({
        nombre_si: rule.nombre_si,
        icono_si: rule.icono_si,
        nombre_no: rule.nombre_no,
        icono_no: rule.icono_no,
    });

    const [selectedFile, setSelectedFile] = useState({
        icono_si: "",
        icono_no: "",
    });
    const [preview, setPreview] = useState({
        icono_si: "",
        icono_no: "",
    });
    const form = new FormData();
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = async (e) => {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        setSiguiente(null);
        e.preventDefault();

        if (!input.nombre_si || !input.nombre_no) {
            Swal.fire({
                title: "Error!",
                text: "La regla debe tener sus nombres",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else {
            form.append("nombre_si", input.nombre_si);
            form.append("icono_si_regla", input.icono_si.image);
            form.append("nombre_no", input.nombre_no);
            form.append("icono_no_regla", input.icono_no.image);
            console.log(input);
            const json = await axios.put(
                `${process.env.REACT_APP_PROXY}/regla/actualizar/${rule.id}`,
                form,
                { headers }
            );

            Swal.fire({
                title: "Regla actualizada correctamente!",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
            dispatch(getRulesPerPage(actualPage, "Si"));
            dispatch(clearRuleSelected());
            setmodalVisible(!modalVisible);
        }
    };

    useEffect(() => {
        document.body.classList.add("overflow-hidden");
        return () => document.body.classList.remove("overflow-hidden");
    }, []);

    const handleClose = () => {
        setmodalVisible(!modalVisible);
        dispatch(clearRuleSelected());
    };

    const handleChangeImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(selectedFile);
            return;
        }
        console.log(e.target.name);
        console.log(e.target.files[0]);
        setInput({
            ...input,
            [e.target.name]: {
                name: "Existe",
                image: e.target.files[0],
            },
        });
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview({
            ...preview,
            [e.target.name]: objectUrl,
        });
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed z-10 bottom-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-24 2xl:container 2xl:mx-auto pl-44 xl:px-28 sm:py-24 ">
                    <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg  xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mt-10 px-2 text-left text-base font-bold lg:text-2xl">
                            Editar regla
                        </header>
                        <div className="w-full px-3 rounded-lg mt-7">
                            <label className="flex font-semibold w-[300px]">
                                Nombre de la regla permitida
                            </label>
                            <input
                                type="text"
                                value={input.nombre_si}
                                name="nombre_si"
                                onChange={handleInputChange}
                                placeholder="Ej: Se permite mascota"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full mt-2">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="p-3">
                                            <div className="mb-2">
                                                <label className="flex font-semibold">
                                                    Icono de la regla permitida
                                                </label>
                                                {!input?.icono_si.name ? (
                                                    <div className="mt-2 flex justify-between  w-full border-dashed rounded-lg ">
                                                        <div className=" flex items-center ">
                                                            <img
                                                                src={
                                                                    `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/regla/getImagen/` +
                                                                    rule.icono_si
                                                                }
                                                                className="object-cover w-8 h-8 "
                                                            />
                                                        </div>
                                                        <span className="text-blue-bluecorner flex items-center cursor-pointer ">
                                                            Editar
                                                            <input
                                                                accept="image/*"
                                                                type="file"
                                                                name="icono_si"
                                                                placeholder="Editar"
                                                                className="absolute mb-10 w-9 h-12 opacity-0 cursor-pointer text-xs"
                                                                onChange={
                                                                    handleChangeImage
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="relative flex items-center justify-center w-full h-48 border-gray-200 border-dashed rounded-lg hover:cursor-pointer">
                                                            <div className="absolute bg-opacity-50 bg-slate-100">
                                                                <div>
                                                                    {input?.icono_si && (
                                                                        <img
                                                                            src={
                                                                                preview.icono_si
                                                                            }
                                                                            className="object-contain w-full mx-auto mt-2 h-36"
                                                                        />
                                                                    )}
                                                                    <hr className="mt-1 w-80" />
                                                                    <span className="text-base font-semibold">
                                                                        {
                                                                            input
                                                                                ?.icono_si
                                                                                ?.image
                                                                                .name
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <input
                                                                accept="image/*"
                                                                type="file"
                                                                name="icono_si"
                                                                className="w-full h-full opacity-0 cursor-pointer "
                                                                onChange={
                                                                    handleChangeImage
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="w-full p-2 mt-5">
                                            <label className="flex font-semibold">
                                                Nombre de la regla prohibida
                                            </label>
                                            <input
                                                type="text"
                                                value={input.nombre_no}
                                                name="nombre_no"
                                                onChange={handleInputChange}
                                                placeholder="Ej: No se permite mascota"
                                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                            />
                                        </div>
                                        <div className="w-full mt-2">
                                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                                <div className="md:flex">
                                                    <div className="w-full">
                                                        <div className="p-3">
                                                            <div className="mb-2">
                                                                <label className="flex font-semibold">
                                                                    Icono de la
                                                                    regla
                                                                    prohibida
                                                                </label>
                                                                {!input
                                                                    ?.icono_no
                                                                    .name ? (
                                                                    <div className="mt-2 flex justify-between  w-full border-dashed rounded-lg ">
                                                                        <div className=" flex items-center ">
                                                                            <img
                                                                                src={
                                                                                    `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/regla/getImagen/` +
                                                                                    rule.icono_no
                                                                                }
                                                                                className="object-cover w-8 h-8 "
                                                                            />
                                                                        </div>
                                                                        <span className="text-blue-bluecorner flex items-center cursor-pointer ">
                                                                            Editar
                                                                            <input
                                                                                accept="image/*"
                                                                                type="file"
                                                                                name="icono_no"
                                                                                placeholder="Editar"
                                                                                className="absolute mb-10 w-9 h-12 opacity-0 cursor-pointer text-xs"
                                                                                onChange={
                                                                                    handleChangeImage
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <div className="relative flex items-center justify-center w-full h-48 border-gray-200 border-dashed rounded-lg hover:cursor-pointer">
                                                                            <div className="absolute bg-opacity-50 bg-slate-100">
                                                                                <div>
                                                                                    {input?.icono_no && (
                                                                                        <img
                                                                                            src={
                                                                                                preview.icono_no
                                                                                            }
                                                                                            className="object-contain w-full mx-auto mt-2 h-36"
                                                                                        />
                                                                                    )}
                                                                                    <hr className="mt-1 w-80" />
                                                                                    <span className="text-base font-semibold">
                                                                                        {
                                                                                            input
                                                                                                ?.icono_no
                                                                                                ?.image
                                                                                                .name
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <input
                                                                                accept="image/*"
                                                                                type="file"
                                                                                name="icono_no"
                                                                                className="w-full h-full opacity-0 cursor-pointer "
                                                                                onChange={
                                                                                    handleChangeImage
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pb-20 text-center ">
                                            <button
                                                className="w-full rounded-lg border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white  active:translate-y-[0.125rem]  mt-10"
                                                onClick={(e) => handleUpdate(e)}
                                                disabled={!siguiente}>
                                                {siguiente ? (
                                                    siguiente
                                                ) : (
                                                    <Spinner width={7} />
                                                )}
                                            </button>
                                        </div>
                                        <hr className=" mx-auto border-white mt-4 xs:hidden "></hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditRules;
