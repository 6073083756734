import { combineReducers } from "redux";

import { fakeReducer } from "./fakeReducer";
import { rulesReducer } from "./rulesReducer";
import { categoriesReducer } from "./categoriesReducer";
import { attributesReducer } from "./attributesReducer";
import { labelsReducer } from "./labelsReducer";
import { usersReducer } from "./usersReducer";
import { productReducer } from "./productReducer";
import { propertiesReducer } from "./propertiesReducer";
import { blogsReducer } from "./blogsReducer";
import { boatsReducer } from "./boatsReducer";
import { authReducer } from "./authReducer";
import { filtersReducer } from "./filtersReducer";

export const rootReducer = combineReducers({
    fakeReducer,
    categoriesReducer,
    attributesReducer,
    usersReducer,
    labelsReducer,
    rulesReducer,
    productReducer,
    propertiesReducer,
    blogsReducer,
    boatsReducer,
    authReducer,
    filtersReducer,
});
