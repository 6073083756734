import axios from "axios";
import React from "react";
import useHeaders from "../../hooks/useHeaders";

const AutoComplete = ({ fields, setRefreshMatriz, id_matriz_selected }) => {
    const { headers } = useHeaders();

    const handleFieldClick = async (id_casa) => {
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casaExperience/updateMatriz/${id_casa}`,
                { id_matriz: id_matriz_selected },
                { headers }
            );
            setRefreshMatriz((prev) => !prev);
        } catch (error) {
            console.log(error.response);
        }
    };

    return (
        <div className={`w-full max-w-lg max-h-72 mt-0.5 overflow-auto`}>
            {fields.map(({ id, id_matriz, nombre }) => (
                <div
                    key={id}
                    onClick={() => handleFieldClick(id)}
                    className="p-2 mt-1 bg-white border cursor-pointer">
                    {nombre}
                </div>
            ))}
        </div>
    );
};

export default AutoComplete;
