import React, { useState } from "react";
import { ShowStarsRating } from "../StarsRating";

const ViewReview = ({ currentProduct, setModalDetalles }) => {
    const [masComentarios, setMasComentarios] = useState([]);

    return (
        <div
            className={
                currentProduct.resena?.total_resenas === 0
                    ? "hidden"
                    : "w-11/12 mx-auto mt-7"
            }>
            <hr></hr>
            <h1 className="mt-5 text-4xl font-semibold text-center">Reseñas</h1>

            <div className="grid sm:grid-cols-2">
                {currentProduct.resena?.resenas.slice(0, 6).map((resena) => {
                    return (
                        <div
                            key={resena.id}
                            className="w-1/2 mx-auto mt-8 text-xs text-center ">
                            <div className="">
                                <div className="flex">
                                    <img
                                        src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${resena.avatar}`}
                                        className="rounded-full p-[0.050rem] w-12 h-12 border-[3px] object-cover cursor-pointer border-blue-bluecorner"
                                        alt="foto de perfil"
                                    />
                                    <div className="ml-1 text-left">
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {
                                                    resena.usuario_calificador?.split(
                                                        " "
                                                    )[0]
                                                }
                                            </p>
                                            <span className="pt-1 ml-1">
                                                <ShowStarsRating
                                                    starsQuantity={Number(
                                                        resena.puntuacion
                                                    )}
                                                    width={4}
                                                />
                                            </span>
                                        </div>
                                        <p className="text-base text-gray-400 ">
                                            {resena.fecha_registro
                                                ?.slice(0, 10)
                                                .split("-")
                                                .reverse()
                                                .join("-")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mt-2 ">
                                                <ShowStarsRating
                                                    starsQuantity={Number(
                                                        reseña.puntuacion
                                                    )}
                                                    width={5}
                                                />
                                            </div> */}
                            <div>
                                {resena.comentarios.length < 100 ? (
                                    <p className="text-base text-left mt-1">
                                        {resena.comentarios}
                                    </p>
                                ) : masComentarios.indexOf(resena.id) === -1 ? (
                                    <p className="text-base text-left ">
                                        {resena.comentarios.substring(0, 100)}
                                        ... <br></br>
                                        <span
                                            onClick={() =>
                                                setMasComentarios([
                                                    ...masComentarios,
                                                    resena.id,
                                                ])
                                            }
                                            className="font-semibold text-black cursor-pointer hover:border-b hover:border-black">
                                            {" Mostrar más >"}
                                        </span>
                                    </p>
                                ) : (
                                    <p className="text-base text-left ">
                                        {resena.comentarios}
                                        <br></br>
                                        <span
                                            onClick={() =>
                                                setMasComentarios(
                                                    masComentarios.filter(
                                                        (index) =>
                                                            index !== resena.id
                                                    )
                                                )
                                            }
                                            className="font-semibold text-black cursor-pointer hover:border-b hover:border-black">
                                            {" Mostrar menos >"}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                    );
                })}
                <div
                    onClick={() => setModalDetalles(true)}
                    className={
                        currentProduct.resena?.total_resenas < 6
                            ? "hidden"
                            : "w-1/2 mx-auto mt-8 text-base font-semibold text-center border border-black rounded-md py-3 px-6 cursor-pointer hover:bg-gray-100"
                    }>
                    Mostrar todo: {currentProduct.resena?.total_resenas} reseñas
                </div>
            </div>
        </div>
    );
};

export default ViewReview;
