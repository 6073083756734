import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LoadScript } from "@react-google-maps/api";

const renderLoader = () => <p>Loading</p>;

ReactDOM.render(
    // <React.StrictMode>
    <GoogleOAuthProvider clientId="383088837702-acs6qsjvqiaihnfnkv6ggk0npke4coji.apps.googleusercontent.com">
        <Provider store={store}>
            <BrowserRouter>
                <Suspense fallback={renderLoader()}>
                    <LoadScript
                        libraries={["places"]}
                        googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}>
                        <App />
                    </LoadScript>
                </Suspense>
            </BrowserRouter>
        </Provider>
    </GoogleOAuthProvider>,
    // </React.StrictMode>,
    document.getElementById("root")
);
