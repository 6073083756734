import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  clearSelected,
  getAllCategories,
  getCategoriesPerPage,
} from "../../redux/actions/categoriesActions";
import Spinner from "../Spinner";

const ModalCreateCategory = ({
  modalVisible,
  setmodalVisible,
  allCategories,
  category = { nombre: "" },
  actualPage,
}) => {
  const [siguiente, setSiguiente] = useState("Actualizar Categoría");
  const [input, setInput] = useState({
    name: category.nombre,
    description: "",
    id_categoria_padre: category.id_categoria_padre,
  });
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = async (e) => {
    const categoriaId = e.target.value;
    setInput({
      ...input,
      id_categoria_padre: categoriaId,
    });
  };

  const handleUpdate = async (e) => {
    setSiguiente(null);
    e.preventDefault();
    await axios.put(
      `${process.env.REACT_APP_PROXY}/categoria/actualizar/${category.id}`,
      {
        nombre: input.name,
        id_categoria_padre: input.id_categoria_padre,
      }
    );
    Swal.fire({
      title: "Categoría actualizada correctamente!",
      icon: "success",
      confirmButtonText: "Aceptar",
    });
    dispatch(getCategoriesPerPage(actualPage, "Si"));
    dispatch(getAllCategories());
    dispatch(clearSelected());
    setmodalVisible(!modalVisible);
  };

  const handleClose = () => {
    setmodalVisible(!modalVisible);
    dispatch(clearSelected());
  };

  return (
    <div
      className={`relative items-center justify-center ${
        modalVisible ? "flex" : "hidden"
      }`}
    >
      <div className="fixed top-0 w-[130%] h-full bg-gray-900 bg-opacity-80 sticky-0">
        <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 sm:py-48">
          <div className="relative flex flex-col items-center justify-center px-4 py-16 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-12 xl:px-36">
            <button
              onClick={handleClose}
              className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
            >
              <svg
                className="rounded-full hover:bg-gray-600 hover:text-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="currentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="currentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <header className="mb-3 text-2xl font-bold">
              Crear nueva categoría
            </header>
            <div className="w-full px-2 mt-5 rounded-2xl bg-gray-50 ring-2 ring-gray-200">
              <input
                type="text"
                value={input.name}
                name="name"
                onChange={handleInputChange}
                placeholder="Nombre"
                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none"
              />
            </div>
            <div className="w-full px-2 mt-5 h-44 rounded-2xl bg-gray-50 ring-2 ring-gray-200">
              <textarea
                type="text"
                value={input.description}
                name="description"
                onChange={handleInputChange}
                placeholder="Descripción"
                className="w-full h-40 my-3 bg-transparent border-none outline-none focus:outline-none"
              />
            </div>
            <select
              onChange={handleSelect}
              name="id_categoria_padre"
              className="w-full p-2 mt-5 border-2 rounded-lg"
            >
              {category.id_categoria_padre !== 0 ? (
                <>
                  <option value={category.id_categoria_padre}>
                    {category.categoria_padre}
                  </option>
                </>
              ) : (
                <option value={0}> Ninguna categoría padre </option>
              )}
              {allCategories
                .filter(
                  (categories) =>
                    categories.nombre !== category.nombre &&
                    categories.nombre !== category.categoria_padre
                )
                .map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.nombre}
                  </option>
                ))}
              <option value={0}> Ninguna categoría padre </option>
            </select>
            <button
              className="w-full rounded-2xl border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
              onClick={(e) => handleUpdate(e)}
              disabled={!siguiente}
            >
              {siguiente ? siguiente : <Spinner width={7} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateCategory;
