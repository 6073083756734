import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// import { getRulesPerPage } from "../../redux/actions/rulesActions";
// import Spinner from "../Spinner";

const ModalCreatePaquete = ({ modalVisible, setmodalVisible, actualPage }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const { id_usuario } = useSelector((state) => state.authReducer);
    const [siguiente, setSiguiente] = useState("Enviar");
    const [input, setInput] = useState({
        id_paquete: "",

        nombre_paquete: "",
        descripcion_paquete: "",
        numero_personas: "",
        costo: "",
    });
    const [rangos, setRangos] = useState([]);
    const [rangoPersonalizado, setRangoPersonalizado] = useState({
        habilitado: false,
        personas: "",
    });

    const [allPaquetes, setAllPaquetes] = useState([]);
    const [allPedidos, setAllPedidos] = useState([]);
    const [detalleDisabled, setDetalleDisabled] = useState(true);

    const getPaquetes = async (pagina = 1, habilitado = "Si", input = "") => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/paquetesempresas/all/${pagina}/${habilitado}/?nombre=${input}`,

            { headers }
        );

        setAllPaquetes(json.data.data);
    };

    const getRangos = async (paquete) => {
        if (paquete === "Selecciona un paquete") {
            setRangos([]);
            setInput({
                id_paquete: "",
                nombre_paquete: "",
                descripcion_paquete: "",
                numero_personas: "",
                costo: "",
            });
            setDetalleDisabled(true);
        } else if (paquete === "Paquete personalizado") {
            setDetalleDisabled(false);
            setRangos([]);
            setInput({
                ...input,
                id_paquete: "",
                descripcion_paquete: "",
                nombre_paquete: paquete,
            });
        } else {
            setDetalleDisabled(true);

            let index = allPaquetes.findIndex((item) => item.id == paquete);

            setInput({
                ...input,
                nombre_paquete: allPaquetes[index].nombre,
                id_paquete: Number(paquete),
                descripcion_paquete: allPaquetes[index].descripcion,
            });
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/paquetesempresas/detalle/${paquete}`,

                { headers }
            );

            if (json.data.paquetes_empresas_personas.length) {
                setRangos(json.data.paquetes_empresas_personas);
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "",
                    icon: "error",
                    width: "350px",
                    confirmButtonText: "Aceptar",
                });
            }
        }

        // setModalEditVisible(!modalEditVisible);
    };

    const addRango = (rango) => {
        if (rango === "Otro rango") {
            setRangoPersonalizado({
                habilitado: true,
                precio: "",
            });
            setInput({
                ...input,

                costo: 0,
                numero_personas: "Otro rango",
            });
        } else if (rango === "Selecciona un rango") {
            setRangoPersonalizado({
                habilitado: false,
                precio: "",
            });
            setInput({
                ...input,
                costo: 0,
                numero_personas: "Selecciona un rango",
            });
        } else {
            setRangoPersonalizado({
                habilitado: false,
                precio: "",
            });
            let index = rangos.findIndex(
                (item) => item.numero_personas == rango
            );

            setInput({
                ...input,

                costo: rangos[index].precio,
                numero_personas: rangos[index].numero_personas,
            });
        }
    };

    const agregarPedido = () => {
        setDetalleDisabled(true);
        setAllPedidos([...allPedidos, input]);
        setRangoPersonalizado({
            habilitado: false,
            precio: "",
        });
        setRangos([]);
        setInput({
            id_paquete: "",
            nombre_paquete: "",
            descripcion_paquete: "",
            numero_personas: "",
            costo: "",
        });
    };

    const sumaTotal = () => {
        let total = 0;

        allPedidos.map((item) => {
            total += item.costo;
        });

        return total;
    };

    useEffect(() => {
        getPaquetes();
    }, []);

    const handleCreate = async (e) => {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        setSiguiente(null);

        if (!allPedidos.length) {
            Swal.fire({
                title: "Error!",
                text: "debes agregar por lo menos un paquete y rango ",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
            setSiguiente("Enviar");
        } else {
            console.log({
                id_cliente: id_usuario,
                costo: sumaTotal(),
                detalle_pedido: allPedidos,
            });
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/pedidoPaquetesEmpresas/`,
                {
                    id_cliente: id_usuario,
                    costo: sumaTotal(),
                    detalle_pedido: allPedidos,
                },
                {
                    headers,
                }
            );

            console.log(json.data);

            if (json.data.message === "registro exitoso") {
                Swal.fire({
                    title: "Paquete enviado correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });

                setInput({
                    id_paquete: "",
                    nombre_paquete: "",
                    descripcion_paquete: "",
                    numero_personas: "",
                    costo: "",
                });
                setRangos([]);
                setAllPaquetes([]);
                setAllPedidos([]);
                setmodalVisible(!modalVisible);
            }

            setSiguiente("Enviar");
        }
    };

    const handleClose = () => {
        setInput({
            id_paquete: "",
            nombre_paquete: "",
            descripcion_paquete: "",
            numero_personas: "",
            costo: "",
        });
        setRangos([]);
        setAllPaquetes([]);
        setmodalVisible(!modalVisible);
    };

    console.log(allPedidos);
    console.log(input);

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed bottom-0 left-0 z-20 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-10 sm:py-8 ">
                    <div className="relative block justify-center px-4 bg-white rounded-lg  w-auto dark:bg-white md:px-10 h-[calc(100vh-10vh)] overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="px-2 mt-10 text-base font-bold text-left lg:text-2xl">
                            Adquirir un paquete
                        </header>
                        <div className="px-3 rounded-lg w-80 mt-7 ">
                            <label className="flex font-semibold">
                                Nombre del paquete
                            </label>
                            <select
                                value={input.id_paquete || input.nombre_paquete}
                                className="w-full p-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                onChange={(e) => {
                                    getRangos(e.target.value);
                                }}>
                                <option>Selecciona un paquete</option>

                                {allPaquetes.length > 0 &&
                                    allPaquetes.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.nombre}{" "}
                                        </option>
                                    ))}
                                <option>Paquete personalizado</option>
                            </select>
                        </div>
                        <div
                            className={
                                input.descripcion_paquete || !detalleDisabled
                                    ? "px-3 rounded-lg w-80 mt-2 "
                                    : "hidden"
                            }>
                            <label className="flex font-semibold">
                                Detalle del paquete
                            </label>
                            <textarea
                                type="text"
                                value={input.descripcion_paquete}
                                onChange={(e) => {
                                    setInput({
                                        ...input,
                                        descripcion_paquete: e.target.value,
                                    });
                                }}
                                name="description"
                                disabled={detalleDisabled}
                                className="w-full h-20 p-3 my-3 border rounded-lg outline-none disable:bg-slate-100 focus:outline-none "
                            />
                        </div>
                        <div
                            className={
                                rangos.length || !detalleDisabled
                                    ? "px-3 rounded-lg w-80 mt-3 "
                                    : "hidden"
                            }>
                            <label className="flex font-semibold">
                                Rango de personas
                            </label>
                            <select
                                className="w-full p-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                value={input.numero_personas}
                                onChange={(e) => addRango(e.target.value)}>
                                <option>Selecciona un rango</option>
                                {rangos?.map((user, i) => (
                                    <option
                                        key={i}
                                        value={user.numero_personas}>
                                        Hasta {user.numero_personas} personas (
                                        {user.precio}USD)
                                    </option>
                                ))}
                                <option>Otro rango</option>
                            </select>
                        </div>
                        <div
                            className={
                                rangoPersonalizado.habilitado
                                    ? "flex items-center px-3 mt-2 rounded-lg w-80 "
                                    : "hidden"
                            }>
                            <input
                                type="text"
                                value={rangoPersonalizado.personas || ""}
                                name="description"
                                onChange={(e) => (
                                    setInput({
                                        ...input,
                                        numero_personas: e.target.value,
                                    }),
                                    setRangoPersonalizado({
                                        ...rangoPersonalizado,
                                        personas: e.target.value,
                                    })
                                )}
                                placeholder="80"
                                className="w-20 h-12 p-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                            />
                            <p className="ml-2 font-semibold">Personas</p>
                            <button
                                className={
                                    "w-20 rounded-lg border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white  active:translate-y-[0.125rem] ml-6"
                                }
                                onClick={() =>
                                    input.numero_personas > 0 && agregarPedido()
                                }
                                disabled={!siguiente}>
                                Agregar
                            </button>
                        </div>
                        <div className="mt-2 w-80">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="pb-3 text-center w-80 ">
                                            <button
                                                className={
                                                    input.costo > 0
                                                        ? "w-20 rounded-lg border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white  active:translate-y-[0.125rem]  mt-4 "
                                                        : "hidden"
                                                }
                                                onClick={() => agregarPedido()}
                                                disabled={!siguiente}>
                                                Agregar
                                            </button>
                                        </div>
                                        {/* <hr className="w-80 " /> */}
                                        <div
                                            className={
                                                allPedidos.length
                                                    ? "mt-2 rounded-lg w-80 "
                                                    : "hidden"
                                            }>
                                            <table className="w-full mt-2 overflow-x-auto">
                                                <thead className="text-left">
                                                    <tr className="pb-5">
                                                        <th className="table-cell w-6/12 px-4 pb-2">
                                                            Nombre
                                                        </th>
                                                        <th className="w-2/12 pb-2 md:table-cell">
                                                            Personas
                                                        </th>
                                                        <th className="w-3/12 pb-2 pl-1">
                                                            Precio
                                                        </th>
                                                        <th className="w-3/12 "></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-left">
                                                    {allPedidos.map(
                                                        (pedido, i) => (
                                                            <tr
                                                                key={i}
                                                                className="w-full border-t">
                                                                <td className="flex">
                                                                    {
                                                                        pedido.nombre_paquete
                                                                    }
                                                                </td>
                                                                <td className="table-cell">
                                                                    {
                                                                        pedido.numero_personas
                                                                    }
                                                                </td>
                                                                <td className="table-cell">
                                                                    {pedido.costo >
                                                                    0
                                                                        ? `${pedido.costo} USD`
                                                                        : `a cotizar`}
                                                                </td>
                                                                <td className="table-cell">
                                                                    <div>
                                                                        <svg
                                                                            onClick={() =>
                                                                                setAllPedidos(
                                                                                    allPedidos.filter(
                                                                                        (
                                                                                            pedido,
                                                                                            index
                                                                                        ) =>
                                                                                            index !==
                                                                                            i
                                                                                    )
                                                                                )
                                                                            }
                                                                            className="w-5 h-5 ml-1 text-red-500 cursor-pointer"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor">
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth="2"
                                                                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>

                                            <div
                                                className={
                                                    allPedidos.length
                                                        ? " font-medium text-right mt-5"
                                                        : "hidden"
                                                }>
                                                <p>Total:{sumaTotal()} USD</p>
                                            </div>
                                        </div>

                                        <div className="pb-3 text-center w-80 ">
                                            <button
                                                className="w-full rounded-lg border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white  active:translate-y-[0.125rem]  mt-10 disabled:bg-slate-400 disabled:border-slate-400"
                                                onClick={(e) => handleCreate(e)}
                                                disabled={!siguiente}>
                                                {siguiente
                                                    ? siguiente
                                                    : "Enviando.."}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreatePaquete;
