import React, { useState } from "react";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import AllCards from "./AllCards";
import ExplorerSanValentin from "./ExplorerSanValentin";
import { TyC } from "./TyC";

const PromoSanValentin = () => {
    const [vistaAdministrador, setVistaAdministrador] = useState(false);
    const [refreshHouses, setRefreshHouses] = useState(false);

    return (
        <div>
            <div className="max-w-screen-xl mx-auto">
                <NavBar />
                <ExplorerSanValentin
                    vistaAdministrador={vistaAdministrador}
                    setVistaAdministrador={setVistaAdministrador}
                    setRefreshHouses={setRefreshHouses}
                />
                <AllCards
                    vistaAdministrador={vistaAdministrador}
                    refreshHouses={refreshHouses}
                    setRefreshHouses={setRefreshHouses}
                />
                {/* <TyC /> */}
            </div>
            <Footer />
        </div>
    );
};

export default PromoSanValentin;
