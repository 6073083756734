import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";

const ProductDescription = ({
    onboarding,
    setOnboarding,
    setPage,
    actualPage,
}) => {
    const dispatch = useDispatch();
    const [siguiente, setSiguiente] = useState("Siguiente");
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const updateProduct = (payload) => {
        setSiguiente(null);

        return async function () {
            const jsonPage = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarPagina/${onboarding.id}`,
                {
                    numero_pagina: actualPage + 1,
                },
                { headers }
            );

            if (onboarding.tipo_producto === "casa") {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/casa/actualizarDescripcion/${payload.id}`,
                    payload,
                    { headers }
                );

                if (json.data.message === "actuaizacion exitoso") {
                    setOnboarding({
                        ...onboarding,
                        descripcion: json.data.data.descripcion,
                        link360: json.data.data.link360,
                    });
                    setPage(actualPage + 1);
                }
            }
            if (onboarding.tipo_producto === "embarcacion") {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/embarcacion/actualizarDescripcion/${payload.id}`,
                    payload,
                    { headers }
                );
                if (json.data.message === "actuaizacion exitoso") {
                    setOnboarding({
                        ...onboarding,
                        descripcion: json.data.data.descripcion,
                        link360: json.data.data.link360,
                    });
                    setPage(actualPage + 1);
                }
            }
        };
    };

    const nextPage = () => {
        if (onboarding.descripcion) {
            dispatch(
                updateProduct({
                    descripcion: onboarding.descripcion,
                    link360: onboarding.link360,
                    id: onboarding.id,
                })
            );
        } else {
            alert();
        }
    };

    const alert = () => {
        Swal.fire({
            title: "Debes ingresar una descripción",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Completar!",
        });
    };
    return (
        <div className="w-full">
            <h1 className="py-8 text-4xl font-bold text-center sm:py-8 sm:text-6xl">
                Descripción
            </h1>
            <div className="p-4 mt-2 text-center ">
                <textarea
                    onChange={(e) =>
                        setOnboarding({
                            ...onboarding,
                            descripcion: e.target.value,
                        })
                    }
                    value={onboarding.descripcion}
                    autoComplete="off"
                    id="default"
                    type="text"
                    name="default"
                    placeholder="Ingrese Descripción"
                    className="w-full py-10 text-sm font-bold text-center border-2 border-blue-300 rounded-lg sm:w-10/12 lg:text-5xl lg:py-12 lg:placeholder:font-medium focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>
            <h1 className="py-8 text-4xl font-bold text-center sm:py-8 sm:text-6xl">
                Link Tour 360°
            </h1>
            <div className="p-4 mt-2 text-center ">
                <input
                    onChange={(e) =>
                        setOnboarding({
                            ...onboarding,
                            link360: e.target.value,
                        })
                    }
                    value={onboarding.link360}
                    autoComplete="off"
                    id="default"
                    type="text"
                    name="default"
                    placeholder="Ingrese Link Tour 360°"
                    className="w-full py-10 text-sm font-bold text-center border-2 border-blue-300 rounded-lg sm:w-10/12 lg:text-5xl lg:py-12 lg:placeholder:font-medium focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>
            <h1 className="py-6 text-sm text-blue-400 text-center sm:py-6 sm:text-2xl cursor-pointer">
                ¿Te interesa que Blue Corner te realice un video 360?
            </h1>
            <div className="flex items-center justify-center mt-20">
                <button
                    onClick={() => setPage(actualPage - 1)}
                    className="p-2 pl-5 pr-5 mr-2 text-lg text-gray-500 min-w-[118px] bg-transparent border-2 border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 "
                >
                    Anterior
                </button>

                <button
                    disabled={!siguiente}
                    onClick={() => nextPage()}
                    className="p-2 pl-5 pr-5 text-lg text-gray-500 bg-transparent border-2 min-w-[118px] border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 disabled:bg-white disabled:border-black"
                >
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div>
        </div>
    );
};

export default ProductDescription;
