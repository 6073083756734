import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
    editLabel,
    resetCreateLabel,
    resetOnLabel,
} from "../../redux/actions/labelsActions";

const ModalEditLabels = ({ edit, setedit, reduxLabels }) => {
    const dispatch = useDispatch();
    const createRedux = useSelector((state) => state.labelsReducer.create);
    const [labels, setlabels] = useState({
        nombre: reduxLabels.nombre,
        casa: reduxLabels.casa,
        embarcacion: reduxLabels.embarcacion,
    });

    const handleSubmit = () => {
        if (!labels.nombre) {
            Swal.fire({
                title: "Error!",
                text: "La etiqueta debe tener un nombre",
                icon: "error",
                confirmButtonText: "ok",
            });
        } else {
            dispatch(editLabel(reduxLabels.id, labels));
        }
    };
    useEffect(() => {
        if (createRedux === "actuaizacion exitoso") {
            setedit(!edit);
            dispatch(resetOnLabel());
            dispatch(resetCreateLabel());
            Swal.fire({
                title: "Procesado!",
                text: "etiqueta editada",
                icon: "success",
                confirmButtonText: "ok",
            });
        }
    }, [createRedux, dispatch]);

    return (
        <div className="relative flex justify-center items-center">
            <div className="fixed z-10 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-36 2xl:container 2xl:mx-auto md:px-28 sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 xl:px-32">
                        <header className="mb-3 mr-auto text-base font-bold lg:text-2xl">
                            Editar etiqueta
                        </header>
                        <div className="w-full px-3 rounded-lg border mt-7">
                            <input
                                onChange={(e) =>
                                    setlabels({
                                        ...labels,
                                        nombre: e.target.value,
                                    })
                                }
                                type="text"
                                value={labels?.nombre}
                                placeholder="Nombre"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full px-4 mt-2 flex items-baseline">
                            {labels.casa === "Si" ? (
                                <input
                                    onClick={() =>
                                        setlabels({
                                            ...labels,
                                            casa:
                                                labels.casa === "Si"
                                                    ? "No"
                                                    : "Si",
                                        })
                                    }
                                    defaultChecked
                                    type="checkbox"
                                    className=" checked:bg-blue-bluecorner mr-2 scale-150 flex"
                                />
                            ) : (
                                <input
                                    onClick={() =>
                                        setlabels({
                                            ...labels,
                                            casa:
                                                labels.casa === "No"
                                                    ? "Si"
                                                    : "No",
                                        })
                                    }
                                    type="checkbox"
                                    className=" checked:bg-blue-bluecorner mr-2 scale-150 flex"
                                />
                            )}
                            <label className="w-full my-4 text-base text-left border-none outline-none ">
                                Disponible para alojamientos
                            </label>
                        </div>
                        <div className="w-full px-4 mt-2 flex items-baseline">
                            {labels.embarcacion === "Si" ? (
                                <input
                                    onClick={() =>
                                        setlabels({
                                            ...labels,
                                            embarcacion:
                                                labels.embarcacion === "Si"
                                                    ? "No"
                                                    : "Si",
                                        })
                                    }
                                    defaultChecked
                                    type="checkbox"
                                    className=" checked:bg-blue-bluecorner mr-2 flex scale-150"
                                />
                            ) : (
                                <input
                                    onClick={() =>
                                        setlabels({
                                            ...labels,
                                            embarcacion:
                                                labels.embarcacion === "No"
                                                    ? "Si"
                                                    : "No",
                                        })
                                    }
                                    type="checkbox"
                                    className=" checked:bg-blue-bluecorner flex mr-2 scale-150"
                                />
                            )}
                            <label className="w-full my-4 font-normal text-base text-left border-none outline-none">
                                Disponible para embarcaciones
                            </label>
                        </div>
                        <div className="w-full  px-4">
                            <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className=" text-center pb-3">
                                            <button
                                                className="w-full rounded-2xl border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                                                onClick={() => handleSubmit()}>
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => (
                                setedit(!edit), dispatch(resetOnLabel())
                            )}
                            className="text-gray-800 dark:text-gray-400 absolute top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditLabels;
