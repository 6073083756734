import React, { useEffect, useState, lazy } from "react";
import { useNavigate, useParams } from "react-router";
import { Elements } from "@stripe/react-stripe-js";
import Spinner from "../../Spinner";
import axios from "axios";
import NavbarEmpresas from "../../dashboardEmpresas/NavbarEmpresas";
import useColores from "../../../hooks/useColores";
/* import FormStripeMembresias from "./FormStripeMembresias"; */
import { loadStripe } from "@stripe/stripe-js";
import useHeaders from "../../../hooks/useHeaders";
import dateFromGuiontoSlash from "../../helpers/dateFromGuiontoSlash";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "../../../redux/actions/authActions";

const FormStripeMembresias = lazy(() => import("./FormStripeMembresias"));

const CheckoutMembresias = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const PLANES = [
        {
            id: 1,
            nombre: "Pago mensual",
            precio_pequena: 69,
            precio_mediana: 149,
            precio_grande: 499,
            ahorra_pequeno: 0,
            ahorra_mediana: 0,
            ahorra_grande: 0,
            plan: "Mensual",
            color: "#a25ddc",
        },
        {
            id: 2,
            nombre: "Pago trimestral",
            precio_pequena: 197,
            precio_mediana: 425,
            precio_grande: 1422,
            ahorra_pequeno: 10,
            ahorra_mediana: 22,
            ahorra_grande: 75,
            plan: "Trimestral",
            color: "#0085ff",
        },
        {
            id: 3,
            nombre: "Pago semestral",
            precio_pequena: 352,
            precio_mediana: 760,
            precio_grande: 2545,
            ahorra_pequeno: 62,
            ahorra_mediana: 134,
            ahorra_grande: 449,
            plan: "Semestral",
            color: "#00ca72",
        },
    ];

    const { idEmpresa, tipoMembresia } = useParams();
    const { headers } = useHeaders();
    const { primario, secundario } = useColores();
    const [comprobantePreview, setComprobantePreview] = useState(null);
    const [comprobante, setComprobante] = useState(null);
    const [formaPago, setFormaPago] = useState("stripe");
    const [membresia, setMembresia] = useState({
        loading: true,
        tipo_moneda: "USD",
        id: 0,
    });
    const [paying, setPaying] = useState(false);
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );
    const form = new FormData();

    useEffect(() => {
        getPagosEmpresa();
    }, []);

    const getPagosEmpresa = async () => {
        try {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/empresas/detalle/${idEmpresa}`,
                { headers }
            );
            console.log(data);

            const membresiaPlan = PLANES.find(
                (plan) => plan.plan === tipoMembresia
            );

            if (membresiaPlan) {
                const costo =
                    membresiaPlan[`precio_${data.tamano.toLowerCase()}`];
                const ahorro =
                    membresiaPlan[`ahorra_${data.tamano.toLowerCase()}`];

                setMembresia({
                    loading: false,
                    tipo_moneda: "USD",
                    id: data.id,
                    costo,
                    ahorro,
                    // Agrega aquí otros campos que quieras incluir desde la respuesta del servidor
                });
            }
        } catch (error) {
            console.error("Error al obtener los datos de la empresa", error);
        }
    };
    console.log(membresia);
    const handleComprobante = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        setComprobante(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setComprobantePreview(objectUrl);
    };

    const handlePagoComprobante = async () => {
        let file = comprobante;
        setPaying(true);

        form.append("imagen_empresa_pago_suscripcion", file);

        form.append("plan", tipoMembresia);
        form.append("id_empresa", idEmpresa);
        form.append("costo", `${membresia.costo}`);

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/empresaSuscripcion/postTransaccion`,
                form
            );

            if (data.message === "registro exitoso") {
                dispatch(verifyToken());
                Swal.fire({
                    title: "Comprobante enviado con exito",
                    confirmButtonText: "Aceptar",
                    icon: "success",
                }).then((res) => {
                    navigate("/dashboard-empresas/membresias");
                });
            }
        } catch (error) {
            Swal.fire({
                title: error.response.data,
                icon: "error",
            });
            setPaying(false);
        }
    };

    if (membresia.loading) {
        return (
            <div className="flex items-center h-screen">
                <Spinner />;
            </div>
        );
    }
    return (
        <>
            <NavbarEmpresas />
            <div className="px-10">
                <h1
                    className={`mt-20 text-[${primario}] text-2xl font-semibold`}>
                    Tu membresía
                </h1>
                <div
                    className={`text-[${primario}] font-semibold text-base md:grid md:grid-cols-[50%_50%] flex flex-col-reverse`}>
                    <div className="p-10">
                        <div
                            className={` border-2 border-gray-600 text-[${primario}] p-5 text-xl`}>
                            <h1>Elige tu metodo de pago</h1>
                            <hr className="my-5 border-t-2 border-gray-300" />
                            <p className="text-lg font-bold">
                                Pago con tarjeta de crédito/débito cuenta con un
                                costo adicional del 5% del monto a pagar.
                            </p>
                            <select
                                onChange={(e) => {
                                    setFormaPago(e.target.value);
                                }}
                                value={formaPago}
                                className="bg-slate-100 mt-7 p-3 max-w-[500px] w-full border-gray-400 border rounded-md">
                                <option className="p-10" value="stripe">
                                    Tarjeta de crédito o débito
                                </option>
                                <option className="p-10" value="transferencia">
                                    Transferencia bancaria
                                </option>
                            </select>
                            {formaPago === "stripe" && (
                                <Elements stripe={stripePromise}>
                                    {console.log(secundario)}
                                    <div className="flex w-full mt-5">
                                        <FormStripeMembresias
                                            tipo_membresia={tipoMembresia}
                                            costos={{
                                                costo: membresia.costo,
                                                costo_tarjeta:
                                                    membresia.costo * 0.05,
                                                costo_total:
                                                    membresia.costo +
                                                    membresia.costo * 0.05,
                                                metodo: "Stripe",
                                            }}
                                            membresia={membresia}
                                            amount={
                                                membresia.costo +
                                                membresia.costo * 0.05
                                            }
                                        />
                                    </div>
                                </Elements>
                            )}
                            {formaPago === "transferencia" && (
                                <div>
                                    <p className="mt-10 text-base font-bold text-justify">
                                        Realiza tu pago directamente en nuestra
                                        cuenta bancaria. Por favor, usa el
                                        número del pedido como referencia de
                                        pago. Tu pedido no se procesará hasta
                                        que se haya recibido el importe en
                                        nuestra cuenta.
                                    </p>
                                    <div className="w-full mt-4 text-base font-normal bg-white rounded-md">
                                        {membresia.tipo_moneda === "PEN" ? (
                                            <div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Banco:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        BANCO DE CRÉDITO DEL
                                                        PERÚ
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Tipo de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        CUENTA CORRIENTE
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Cuenta en Soles:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        193 - 2468888 - 0 - 60
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        CCI:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        00219300246888806018
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Titular de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        LA ESQUINA AZUL S.A.C.
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Banco:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        BANCO DE CRÉDITO DEL
                                                        PERÚ
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Tipo de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        CUENTA CORRIENTE
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Cuenta en Dólares:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        193 - 2457006 - 1 - 51
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        CCI:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        00219300245700615115
                                                    </p>
                                                </div>
                                                <div className="flex flex-row">
                                                    <p className="font-bold">
                                                        Titular de cuenta:
                                                    </p>{" "}
                                                    <p className="ml-3">
                                                        LA ESQUINA AZUL S.A.C.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <br />
                                        {/* <p className="font-bold">
                                            Numero de pedido: {membresia.id}
                                        </p> */}
                                    </div>
                                    <hr className="border-t-2 border-gray-300 my-15" />
                                    <h1 className="mt-4 font-normal">
                                        Por favor, adjuntar su comprobante en
                                        formato Imagen
                                    </h1>
                                    <div className="relative flex mt-4 bg-white border-4 border-dashed rounded-lg w-72 h-72 ">
                                        {comprobantePreview ? (
                                            <div className="w-full h-full rounded-lg ">
                                                <img
                                                    className="object-cover w-full h-full rounded-lg"
                                                    src={comprobantePreview}
                                                    alt="Comprobante de pago"
                                                />
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-center p-3 ">
                                                <div className="flex flex-col items-center justify-center w-full mx-auto ">
                                                    <div className="relative flex flex-col items-center justify-center">
                                                        <span className="mt-2 font-bold text-center ">
                                                            Arrastre el
                                                            comprobante
                                                        </span>
                                                        o
                                                        <span
                                                            className={`font-normal text-center text-[${secundario}]`}>
                                                            Sube la imagen desde
                                                            tu dispositivo
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <input
                                            type="file"
                                            onChange={handleComprobante}
                                            accept="image/*"
                                            className="absolute top-0 left-0 bg-red-200 opacity-0 cursor-pointer w-72 h-72 file:cursor-pointer"
                                        />
                                    </div>
                                    <button
                                        className={`payButton active:translate-y-1 w-full max-w-[288px] disabled:bg-slate-400 hover:disabled:bg-slate-400`}
                                        disabled={!formaPago || paying}
                                        style={{
                                            backgroundColor: "#053465",
                                        }}
                                        onClick={() => handlePagoComprobante()}>
                                        {paying ? (
                                            <Spinner width={7} />
                                        ) : (
                                            "Pagar"
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="p-10">
                        <table className="w-full border-2 border-gray-600">
                            <thead>
                                <tr>
                                    <th
                                        className="p-4 uppercase border-2 border-gray-600"
                                        colSpan={2}>
                                        Producto
                                    </th>
                                    <th
                                        className="p-4 uppercase border-2 border-gray-600"
                                        colSpan={2}>
                                        Subtotal
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        className="p-4 border-2 border-gray-600"
                                        colSpan={3}>
                                        <div className="flex flex-col lg:flex-row items-center">
                                            {/* <img
                                                alt="Logo empresa"
                                                src={`${process.env.REACT_APP_PROXY}/empresas/getImage/${membresia.logo}`}
                                                className="h-12 cursor-pointer xs:h-16"
                                                onClick={() => {
                                                    navigate("/");
                                                }}
                                            /> */}
                                            <div className="flex flex-col mt-4 ml-3 justify-center">
                                                {/* <span className="font-bold">
                                                    {membresia.nombre}
                                                </span> */}
                                                <span>
                                                    Membresia: {tipoMembresia}
                                                </span>
                                                {/* <span>
                                                    Fecha de caducidad{" "}
                                                    {dateFromGuiontoSlash(
                                                        membresia?.fecha_caducidad?.split(
                                                            "T"
                                                        )[0]
                                                    )}
                                                </span> */}
                                                <br />
                                            </div>
                                        </div>
                                    </td>

                                    <td
                                        className="p-4 border-2 border-gray-600"
                                        colSpan={1}>
                                        {membresia.costo + membresia.ahorro}{" "}
                                        {membresia.tipo_moneda}
                                    </td>
                                </tr>
                                {membresia.ahorro > 0 ? (
                                    <tr>
                                        <td
                                            className="p-4 border-2 border-gray-600"
                                            colSpan={3}>
                                            <div className="flex flex-col lg:flex-row items-center">
                                                {/* <img
                                                alt="Logo empresa"
                                                src={`${process.env.REACT_APP_PROXY}/empresas/getImage/${membresia.logo}`}
                                                className="h-12 cursor-pointer xs:h-16"
                                                onClick={() => {
                                                    navigate("/");
                                                }}
                                            /> */}
                                                <div className="flex flex-col mt-4 ml-3 justify-center">
                                                    {/* <span className="font-bold">
                                                    {membresia.nombre}
                                                </span> */}
                                                    <span className="text-green-500">
                                                        Descuento
                                                    </span>
                                                    {/* <span>
                                                    Fecha de caducidad{" "}
                                                    {dateFromGuiontoSlash(
                                                        membresia?.fecha_caducidad?.split(
                                                            "T"
                                                        )[0]
                                                    )}
                                                </span> */}
                                                    <br />
                                                </div>
                                            </div>
                                        </td>

                                        <td
                                            className="p-4 border-2 border-gray-600 text-green-500"
                                            colSpan={1}>
                                            -{membresia.ahorro}{" "}
                                            {membresia.tipo_moneda}
                                        </td>
                                    </tr>
                                ) : null}

                                {formaPago === "stripe" && (
                                    <tr>
                                        <td
                                            className="p-4 border-2 border-gray-600"
                                            colSpan={3}>
                                            Costo tarjeta:
                                        </td>
                                        <td
                                            className="p-4 border-2 border-gray-600"
                                            colSpan={1}>
                                            {(membresia.costo * 0.05).toFixed(
                                                2
                                            )}{" "}
                                            {membresia.tipo_moneda}
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td
                                        className="p-4 border-2 border-gray-600"
                                        colSpan={3}>
                                        Costo total:
                                    </td>
                                    <td
                                        className="p-4 border-2 border-gray-600"
                                        colSpan={1}>
                                        {formaPago === "stripe"
                                            ? (
                                                  membresia.costo +
                                                  membresia.costo * 0.05
                                              ).toFixed(2)
                                            : membresia.costo}{" "}
                                        {membresia.tipo_moneda}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckoutMembresias;
