import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import ModalDetallePagos from "./ModaDetallePagos";
import { useNavigate, useParams } from "react-router";
import { Paginado } from "../helpers/Paginado";
import Spinner from "../Spinner";

const PagosAllPromociones = () => {
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [modalCrearPromocion, setModalCrearPromocion] = useState(false);
    const [page, setPage] = useState(1);
    const [habilitados, setHabilitados] = useState(true);
    const [selectedPromocion, setSelectedPromocion] = useState(null);
    const [allPromociones, setAllPromociones] = useState({
        checking: true,
        total: 0,
        promociones: [],
    });
    const [dataPagoAll, setDataPagoAll] = useState({
        checking: true,
        total: 0,
        pagosMasivos: [],
    });
    const [verCuponesUsados, setVerCuponesUsados] = useState(false);
    const [idPago, setIdPago] = useState(null);
    const { idEmpresa, nameEmpresa } = useParams();

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const PROMOCIONES_PER_PAGE = 25;
    const PAGOS_PER_PAGE = 25;
    const navigate = useNavigate();

    const getPagosMasivos = async (pageNumber = 1) => {
        setDataPagoAll({
            checking: true,
            total: 0,
            pagosMasivos: [],
        });

        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/promoEmpresas/getPromoMasivo/${pageNumber}?nombre=${nameEmpresa}`,
                { headers }
            );
            console.log("pagosall ", data);
            setDataPagoAll({
                checking: false,
                total: data.total,
                pagosMasivos: data.data,
            });
        } catch (error) {
            console.error("Error al obtener pagos masivos:", error);
            setDataPagoAll({
                checking: false,
                total: 0,
                pagosMasivos: [],
            });
        }
    };

    useEffect(() => {
        getPagosMasivos();
    }, [habilitados, idPago]);

    const handleEdit = (promocion) => {
        setSelectedPromocion(promocion);
        setModalEditVisible(!modalEditVisible);
    };

    const paginationPagos = (pageNumber) => {
        getPagosMasivos(pageNumber);
    };

    return (
        <>
            {idPago && (
                <ModalDetallePagos
                    idPago={idPago}
                    setIdPago={setIdPago}
                    idEmpresa={idEmpresa}
                />
            )}
            <div className=" w-full md:pl-[12rem] lg:pl-[16rem] lg1170:pl-[16rem]  xl:pl-[16rem] p-4">
                <div
                    className="flex items-center text-lg w-max cursor-pointer hover:underline hover:scale-105 mt-4"
                    onClick={() => navigate(-1)}>
                    <svg
                        className="w-6 h-6 text-black"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                    <span className="mx-1">Regresar</span>
                </div>
                <section className="w-full p-4 md:p-10 md:mx-auto">
                    <h1 className="py-8 text-4xl text-left">
                        Pagos masivos realizados por {nameEmpresa}
                    </h1>
                    <div className="flex justify-center items-center lg:py-[5px] ">
                        <div className="flex w-full max-w-full overflow-x-auto text-center">
                            <div className="w-full">
                                {dataPagoAll.checking ? (
                                    <Spinner width={10} margin_top={10} />
                                ) : dataPagoAll.pagosMasivos.length > 0 ? (
                                    <div>
                                        <table className="w-full mt-10 overflow-x-auto text-left">
                                            <thead className="text-left">
                                                <tr>
                                                    <th className="w-1/12 min-w-[6rem] hidden md:table-cell">
                                                        Monto Total
                                                    </th>
                                                    <th className="w-1/12 min-w-[8rem] hidden md:table-cell">
                                                        Método de Pago
                                                    </th>
                                                    <th className="w-1/12 min-w-[8rem] hidden md:table-cell">
                                                        Estado
                                                    </th>
                                                    <th className="w-1/12 min-w-[8rem] hidden md:table-cell">
                                                        Fecha de Pago
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataPagoAll.pagosMasivos.map(
                                                    (pago) => (
                                                        <tr
                                                            onClick={() =>
                                                                setIdPago(
                                                                    pago.id
                                                                )
                                                            }
                                                            className="w-full border-t cursor-pointer"
                                                            key={pago.id}>
                                                            <td className="hidden md:table-cell">
                                                                {
                                                                    pago.monto_total
                                                                }
                                                            </td>
                                                            <td className="hidden md:table-cell">
                                                                {
                                                                    pago.metodo_pago
                                                                }
                                                            </td>
                                                            <td className="hidden md:table-cell">
                                                                {pago.estado}
                                                            </td>
                                                            <td className="hidden md:table-cell">
                                                                {
                                                                    pago.fecha_pago.split(
                                                                        "T"
                                                                    )[0]
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="flex justify-center my-3">
                                            <Paginado
                                                elementsPerPage={PAGOS_PER_PAGE}
                                                cantTotalElements={
                                                    dataPagoAll.total
                                                }
                                                pagination={paginationPagos}
                                                actualPage={page}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mt-10 text-lg font-medium">
                                        <h2>
                                            No se ha encontrado ningún pago
                                            masivo realizado.
                                        </h2>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default PagosAllPromociones;
