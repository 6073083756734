import React, { useState, useEffect, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/actions/authActions";
import Swal from "sweetalert2";
import axios from "axios";
import jwt_decode from "jwt-decode";
// import { GoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login";
// import FacebookLogin from "react17-facebook-login";
// import FacebookLogin from "@greatsumini/react-facebook-login";

import colores from "../helpers/colores";
import { useDispatch, useSelector } from "react-redux";
const FacebookLogin = lazy(() => import("./../helpers/LogeoFacebook"));
const GoogleLogin = lazy(() => import("./../helpers/LogeoGoogle.js"));

const UserRegister = ({
    registro,
    setRegistro,
    desdeModal = false,
    setModalLogeo,
}) => {
    const [showPass, setShowPass] = useState(false);
    const [register, setRegister] = useState({
        correo: "",
        password: "",
        password2: "",
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        dni: "",
        tipo_documentacion: "",
        direccion: "",
        celular: "",
        tipo_usuario: "",
        tipo_logueo: "Correo",
    });
    const [medioDeRegistro, setMedioDeRegistro] = useState("");
    const [registrarse, setRegistrarse] = useState("Registrarse");
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.authReducer);
    const documento = ["DNI", "NIF", "RUC", "Pasaporte", "Cédula Extranjera"];

    useEffect(() => {
        if (authUser.tipo_usuario && desdeModal === false) {
            Swal.fire({
                title: "Registro exitoso!",

                icon: "success",
                confirmButtonText: "Aceptar",
            });
            if (authUser.tipo_usuario === 3 || authUser.tipo_usuario === 4) {
                navigate("/perfil/anuncios");
            } else if (authUser.tipo_usuario === 6) {
                navigate("/dashboard-empresas/usuarios");
            } else {
                navigate("/");
            }
        }
    }, [authUser]);

    const googleDatos = async (data) => {
        const credencialToken = jwt_decode(data.credential);

        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}${
                register.tipo_usuario === "Cliente"
                    ? "/cliente/"
                    : "/propietario/"
            }`,
            {
                correo: credencialToken.email,
                nombre: credencialToken.given_name,
                apellido_paterno: credencialToken.family_name,
                tipo_usuario: register.tipo_usuario === "Vendedor" ? 3 : 5,
                tipo_logueo: "Google",
                password: "",
                apellido_materno: "",
                tipo_documentacion: "",
                dni: "",
                direccion: "",
                celular: "",
            }
        );

        if (json.data.message === "logeo exitoso") {
            dispatch(loginUser(null, null, null, json.data));
            // Swal.fire({
            //     icon: "success",
            //     title: "Registro exitoso",
            //     text: "Ahora puedes iniciar sesión",
            // });
            // setRegistro(false);
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: `${json.data.title}`,
            });

            setRegister({
                correo: "",
                password: "",
                password2: "",
                nombre: "",
                apellido_paterno: "",
                apellido_materno: "",
                tipo_documentacion: "",
                dni: "",
                direccion: "",
                celular: "",
                tipo_usuario: "",
                tipo_logueo: "Correo",
            });
            setMedioDeRegistro("");
        }
    };

    const handleInputChange = (e) => {
        if (e.target.name === "tipo_usuario") {
            setRegister({
                correo: "",
                password: "",
                password2: "",
                nombre: "",
                apellido_paterno: "",
                apellido_materno: "",
                tipo_documentacion: "",
                dni: "",
                direccion: "",
                celular: "",
                tipo_usuario: e.target.value,
            });
            setErrors({});
        } else {
            setRegister({
                ...register,
                [e.target.name]: e.target.value,
            });
        }
    };

    const validateInput = (form) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.))$/
        );
        let errores = {};
        if (!regExpEmail.test(form.correo)) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        } else if (form.password !== form.password2) {
            errores.password2 = "Las contraseñas deben coincidir";
        } else if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre";
        } else if (!form.apellido_paterno) {
            errores.apellido_paterno = "Debe ingresar al menos un apellido";
        } else if (!form.celular) {
            errores.celular = "Debe ingresar un numero de celular";
        } else if (form.tipo_usuario === "Vendedor") {
            if (!form.tipo_documentacion) {
                errores.tipo_documentacion =
                    "Debe ingresar su tipo de documento";
            } else if (!form.dni) {
                errores.dni = "Debe ingresar su dni";
            } else if (!form.direccion) {
                errores.direccion = "Debe ingresar su dirección";
            } else if (!form.celular) {
                errores.celular = "Debe ingresar un numero de celular";
            }
        }
        return errores;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        /* const errors = validateInput(register); */
        const errors = {};
        setErrors(errors);

        console.log(errors);
        if (Object.entries(errors).length === 0) {
            const logeoUsuario = {
                correo: register.correo,
                password: register.password,
                tipo_usuario: register.tipo_usuario === "Vendedor" ? 3 : 5,
                nombre: register.nombre,
                apellido_materno: register.apellido_materno,
                apellido_paterno: register.apellido_paterno,
                tipo_documentacion: register.tipo_documentacion,
                dni: register.dni,
                direccion: register.direccion,
                celular: register.celular,
                tipo_logueo: register.tipo_logueo,
            };
            setRegistrarse("Registrando...");

            axios
                .post(
                    `${process.env.REACT_APP_PROXY}${
                        register.tipo_usuario === "Cliente"
                            ? "/cliente/"
                            : "/propietario/"
                    }`,
                    logeoUsuario
                )
                .then((response) => {
                    if (response.data.message === "logeo exitoso") {
                        if (
                            authUser.tipo_usuario < 3 &&
                            authUser.tipo_usuario !== null
                        ) {
                            setModalLogeo(false);
                        } else {
                            dispatch(
                                loginUser(null, null, null, response.data)
                            );
                        }
                    } else {
                        setRegistrarse("Registrar");
                        Swal.fire({
                            title: "Ya existe un usuario asociado a ese email",
                            text: "Intente recuperar su contraseña",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        });
                        setRegister({
                            correo: "",
                            password: "",
                            password2: "",
                            nombre: "",
                            apellido_paterno: "",
                            apellido_materno: "",
                            tipo_documentacion: "",
                            dni: "",
                            direccion: "",
                            celular: "",
                            tipo_usuario: e.target.value,
                            tipo_logueo: "",
                        });
                    }
                });
        }
    };
    const responseFacebook = async (data) => {
        let nombre = data.name?.split(" ");

        if (nombre) {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}${
                    register.tipo_usuario === "Cliente"
                        ? "/cliente/"
                        : "/propietario/"
                }`,
                {
                    correo: data.email,
                    nombre: nombre[0],
                    apellido_paterno: "",
                    tipo_usuario: register.tipo_usuario === "Vendedor" ? 3 : 5,
                    tipo_logueo: "Facebook",
                    password: "",
                    apellido_materno: "",
                    tipo_documentacion: "",
                    dni: "",
                    direccion: "",
                    celular: "",
                }
            );

            if (json.data.message === "logeo exitoso") {
                dispatch(loginUser(null, null, null, json.data));
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${json.data.title}`,
                });

                setRegister({
                    correo: "",
                    password: "",
                    password2: "",
                    nombre: "",
                    apellido_paterno: "",
                    apellido_materno: "",
                    tipo_documentacion: "",
                    dni: "",
                    direccion: "",
                    celular: "",
                    tipo_usuario: "",
                    tipo_logueo: "Correo",
                });
                setMedioDeRegistro("");
            }
        }
    };

    return (
        <div>
            <div
                className={
                    registro
                        ? "p-5 mt-10 mb-14 w-[340px]  mx-auto shadow-xl"
                        : "hiden"
                }>
                <h1 className={`text-[${colores.primario}] text-4xl font-bold`}>
                    Registrarse como
                </h1>
                <div className="flex justify-around mt-5">
                    <div
                        onClick={() =>
                            setRegister({
                                ...register,
                                tipo_usuario: "Cliente",
                            })
                        }
                        className={`flex justify-center items-center text-sm border mx-auto rounded-[3px] border-[#dadce0] w-[150px] px-[10px] py-1 text-center mt-3 font-sans text-[#3c4043] font-medium cursor-pointer hover:bg-[#f7f8f9] hover:border-[#cadff5] ${
                            register.tipo_usuario === "Cliente" &&
                            "bg-[#F4F2F1]"
                        }  cursor-pointer`}>
                        <p className=" text-[#3C3C3C]">Cliente</p>
                    </div>
                    <div
                        onClick={() =>
                            setRegister({
                                ...register,
                                tipo_usuario: "Vendedor",
                            })
                        }
                        className={`flex justify-center items-center text-sm border mx-auto rounded-[3px] border-[#dadce0] w-[150px] px-[10px] py-1 text-center mt-3 font-sans text-[#3c4043] font-medium cursor-pointer hover:bg-[#f7f8f9] hover:border-[#cadff5] ${
                            register.tipo_usuario === "Vendedor" &&
                            "bg-[#F4F2F1]"
                        }  cursor-pointer`}>
                        <p className="text-sm text-[#3C3C3C]">Propietario</p>
                    </div>
                </div>

                <div
                    className={
                        register.tipo_usuario !== ""
                            ? "grid grid-cols-1 justify-items-center mt-5"
                            : "hidden"
                    }>
                    {/* <GoogleLogin
                        size="medium"
                        shape="pill"
                        width="260"
                        theme="filled_blue"
                        auto_select="false"
                        text="signup_with"
                        onSuccess={googleDatos}
                        onError={() => {
                            console.log("Login Failed");
                        }}
                    /> */}
                    <Suspense fallback={<div>Loading...</div>}>
                        <GoogleLogin googleDatos={googleDatos} />
                    </Suspense>

                    {/* <div className="flex items-center justify-center mx-auto">
                        <p>o</p>
                    </div> */}
                    {/* <div>
                        <FacebookLogin
                            appId="515412373874363"
                            fields="name,email"
                            // onClick={componentClicked}
                            callback={responseFacebook}
                            textButton="Registrarse con Facebook"
                            cssClass="w-[260px] mx-auto mt-3 bg-blue-500 text-white rounded-2xl pl-[20px] pr-[33px]  py-[6px] flex justify-between items-center text-sm font-sans font-medium hover:bg-blue-400"
                            icon="fa-facebook"
                        />
                    </div> */}
                    <Suspense fallback={<div>Loading...</div>}>
                        <FacebookLogin responseFacebook={responseFacebook} />
                    </Suspense>
                    <div
                        onClick={() =>
                            setMedioDeRegistro(
                                medioDeRegistro === "Correo" ? " " : "Correo"
                            )
                        }
                        className={` flex  items-center   text-sm border mx-auto rounded-2xl border-[#dadce0] w-[260px] px-[10px] py-[6px] text-center mt-3 font-sans text-[#3c4043] font-medium cursor-pointer hover:bg-[#f7f8f9] hover:border-[#cadff5] ${
                            medioDeRegistro === "Correo"
                                ? "bg-[#f7f8f9] border-[#cadff5]"
                                : "bg-white"
                        }`}>
                        <svg
                            className="w-4 h-4 text-black "
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />{" "}
                            <polyline points="22,6 12,13 2,6" />
                        </svg>
                        <p className="mx-auto">Registrarse con Correo</p>
                    </div>
                </div>

                {register.tipo_usuario === "Cliente" ? (
                    <form
                        className={
                            medioDeRegistro === "Correo" ? " mt-5" : "hidden"
                        }
                        onSubmit={(e) => handleSubmit(e)}>
                        <div className="w-full ">
                            <label className="font-semibold">
                                Correo electrónico:
                                <span className="text-xl text-red-600">*</span>
                            </label>{" "}
                            {errors.correo && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.correo}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.correo}
                                name="correo"
                                onChange={handleInputChange}
                                placeholder="Ingrese su correo electrónico "
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold ">
                                Contraseña:
                                <span className="text-xl text-red-600">*</span>
                            </label>{" "}
                            {errors.password && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password}
                                </p>
                            )}
                            <div className="flex items-center w-full rounded-lg - ring-2 ring-gray-200 ">
                                <input
                                    type={`${showPass ? "text" : "password"}`}
                                    name="password"
                                    value={register.password}
                                    onChange={handleInputChange}
                                    className={`w-full p-3 placeholder-gray-500 rounded-lg focus:outline-none focus:border-[${colores.secundario}]`}
                                    placeholder="Ingresa tu contraseña"
                                />

                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold ">
                                Repita su contraseña:
                                <span className="text-xl text-red-600">*</span>
                            </label>{" "}
                            {errors.password2 && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password2}
                                </p>
                            )}
                            <div className="flex items-center w-full rounded-lg - ring-2 ring-gray-200 ">
                                <input
                                    type={`${showPass ? "text" : "password"}`}
                                    name="password2"
                                    value={register.password2}
                                    onChange={handleInputChange}
                                    className={`w-full p-3 placeholder-gray-500 rounded-lg focus:outline-none focus:border-[${colores.secundario}]`}
                                    placeholder="Ingresa tu contraseña"
                                />

                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Nombre:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.nombre && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.nombre}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.nombre}
                                name="nombre"
                                onChange={handleInputChange}
                                placeholder="Ingrese su nombre"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Apellido paterno:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.apellido_paterno && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.apellido_paterno}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.apellido_paterno}
                                name="apellido_paterno"
                                onChange={handleInputChange}
                                placeholder="Ingrese su apellido paterno"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        {/* <div className="w-full mt-4">
                            <label className="font-semibold">
                                Apellido materno:
                            </label>
                            <input
                                type="text"
                                value={register.apellido_materno}
                                name="apellido_materno"
                                onChange={handleInputChange}
                                placeholder="Ingrese su apellido materno"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div> */}
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Numero de celular:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.celular && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.celular}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.celular}
                                name="celular"
                                onChange={handleInputChange}
                                placeholder="Ingrese su numero telefónico"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <p className="mt-3 text-lg">
                                Sus datos personales se utilizarán para
                                respaldar su experiencia en este sitio web, para
                                administrar el acceso a su cuenta y para otros
                                fines descritos en nuestros{" "}
                                <Link
                                    to="/terminos-y-condiciones"
                                    className={`text-lg text-[${colores.secundario}]`}>
                                    Términos y Condiciones
                                </Link>
                                {" y "}
                                <Link
                                    to="/politicas-de-uso-y-privacidad"
                                    className={`text-lg text-[${colores.secundario}]`}>
                                    Políticas de uso y privacidad
                                </Link>
                            </p>
                        </div>
                        <button
                            type="submit"
                            className={`p-2 px-4 text-lg font-semibold text-white bg-[${colores.primario}] mt-4 rounded-lg`}>
                            {registrarse}
                        </button>
                    </form>
                ) : (
                    <form
                        onSubmit={(e) => handleSubmit(e)}
                        className={
                            medioDeRegistro === "Correo" &&
                            register.tipo_usuario === "Vendedor"
                                ? ""
                                : "hidden"
                        }>
                        <div className="w-full ">
                            <label className="font-semibold">
                                Correo electrónico:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.correo && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.correo}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.correo}
                                name="correo"
                                onChange={handleInputChange}
                                placeholder="Ingrese su correo electrónico"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold ">
                                Contraseña:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.password && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password}
                                </p>
                            )}
                            <div className="flex items-center w-full rounded-lg - ring-2 ring-gray-200 ">
                                <input
                                    type={`${showPass ? "text" : "password"}`}
                                    name="password"
                                    value={register.password}
                                    onChange={handleInputChange}
                                    className={`w-full p-3 placeholder-gray-500 rounded-lg focus:outline-none focus:border-[${colores.secundario}]`}
                                    placeholder="Ingresa tu contraseña"
                                />

                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold ">
                                Repita su contraseña:
                                <span className="text-xl text-red-600">*</span>
                            </label>{" "}
                            {errors.password2 && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password2}
                                </p>
                            )}
                            <div className="flex items-center w-full rounded-lg - ring-2 ring-gray-200 ">
                                <input
                                    type={`${showPass ? "text" : "password"}`}
                                    name="password2"
                                    value={register.password2}
                                    onChange={handleInputChange}
                                    className={`w-full p-3 placeholder-gray-500 rounded-lg focus:outline-none focus:border-[${colores.secundario}]`}
                                    placeholder="Ingresa tu contraseña"
                                />

                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Nombre:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.nombre && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.nombre}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.nombre}
                                name="nombre"
                                onChange={handleInputChange}
                                placeholder="Ingrese su nombre"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Apellido paterno:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.apellido_paterno && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.apellido_paterno}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.apellido_paterno}
                                name="apellido_paterno"
                                onChange={handleInputChange}
                                placeholder="Ingrese su apellido paterno"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Apellido materno:
                            </label>
                            <input
                                type="text"
                                value={register.apellido_materno}
                                name="apellido_materno"
                                onChange={handleInputChange}
                                placeholder="Ingrese su apellido materno"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Tipo de documento:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.tipo_documentacion && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.tipo_documentacion}
                                </p>
                            )}
                            <select
                                value={register.tipo_documentacion}
                                name="tipo_documentacion"
                                onChange={handleInputChange}
                                placeholder="Ingrese su numero de DNI"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200">
                                <option className="bg-transparent" value="">
                                    {" "}
                                    Seleccionar{" "}
                                </option>
                                {documento.map((doc, i) => {
                                    return (
                                        <option
                                            key={i}
                                            className="bg-transparent"
                                            value={doc}>
                                            {" "}
                                            {doc}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Numero de documento:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.dni && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.dni}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.dni}
                                name="dni"
                                onChange={handleInputChange}
                                placeholder="Ingrese su numero de documento"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Direccion:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.direccion && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.direccion}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.direccion}
                                name="direccion"
                                onChange={handleInputChange}
                                placeholder="Ingrese su direccion de residencia"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Numero de celular:
                                <span className="text-xl text-red-600">*</span>
                            </label>
                            {errors.celular && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.celular}
                                </p>
                            )}
                            <input
                                type="text"
                                value={register.celular}
                                name="celular"
                                onChange={handleInputChange}
                                placeholder="Ingrese su numero telefónico"
                                className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <p className="mt-3 text-lg">
                                Sus datos personales se utilizarán para
                                respaldar su experiencia en este sitio web, para
                                administrar el acceso a su cuenta y para otros
                                fines descritos en nuestros{" "}
                                <Link
                                    to="/terminos-y-condiciones"
                                    className={`text-lg text-[${colores.secundario}]`}>
                                    Términos y Condiciones
                                </Link>
                                {" y "}
                                <Link
                                    to="/politicas-de-uso-y-privacidad"
                                    className={`text-lg text-[${colores.secundario}]`}>
                                    Políticas de uso y privacidad
                                </Link>
                            </p>
                        </div>
                        <button
                            type="submit"
                            className={`p-2 px-4 text-lg font-semibold text-white bg-[${colores.primario}] mt-4 rounded-lg`}>
                            {registrarse}
                        </button>
                    </form>
                )}
                <div className="flex items-center justify-center mx-auto mt-4 text-sm text-gray-500">
                    <p>¿Ya tienes una cuenta? </p>
                    <p
                        onClick={() => setRegistro(false)}
                        className={`text-[${colores.secundario}] cursor-pointer pl-1`}>
                        Iniciar sesión
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UserRegister;
