import React, { useEffect } from "react";
import Spinner from "../Spinner";
import { ShowStarsRating } from "../StarsRating";

const ReviewModalDetail = ({
    setModalDetail,
    review,
    handleDelete,
    handleEnable,
    habilitado,
}) => {
    const handleClose = () => {
        setModalDetail((prev) => !prev);
    };

    // useEffect(() => {
    //     document.body.classList.add("overflow-hidden");
    //     return () => document.body.classList.remove("overflow-hidden");
    // }, []);

    return (
        <div className={`relative items-center justify-center`}>
            <div className="fixed bottom-0 left-0 w-full z-20 h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 sm:py-24 ">
                    <div className="relative w-full block justify-center px-4 bg-white rounded-lg pb-10 md:w-auto dark:bg-white md:px-24 xl:py-12 xl:px-36 h-[calc(100vh-10vh)] overflow-y-auto md:overflow-y-hidden ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-1 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mt-20 px-2 text-left text-base font-bold lg:text-2xl">
                            {console.log(review)}
                            {review.nombre}
                        </header>
                        <div className="mt-2 px-2 text-left text-sm lg:text-xl">
                            {review.usuario_calificador}
                        </div>
                        <div className="mt-3 px-1">
                            <ShowStarsRating
                                width={7}
                                starsQuantity={review.puntuacion}
                            />
                        </div>
                        <hr className="mt-5" />
                        <h1 className="mt-5 px-2 text-left text-base font-bold lg:text-2xl">
                            Comentario
                        </h1>
                        <p className="mt-2 px-2 text-left text-sm lg:text-xl">
                            {review.comentarios}
                        </p>
                        <div className="mt-10 md:justify-end flex flex-col w-full items-center">
                            <button className=" bg-blue-bluecorner p-3 text-white rounded-md w-full border lg:w-1/2 border-blue-bluecorner">
                                Publicar
                            </button>
                            {habilitado ? (
                                <button
                                    onClick={() => {
                                        handleDelete(review.id);
                                        setModalDetail((prev) => !prev);
                                    }}
                                    className="text-blue-bluecorner border border-blue-bluecorner w-full lg:w-1/2 p-3 rounded-md mt-5">
                                    Eliminar
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        handleEnable(review.id);
                                        setModalDetail((prev) => !prev);
                                    }}
                                    className="text-blue-bluecorner border border-blue-bluecorner w-full lg:w-1/2 p-3 rounded-md mt-5">
                                    Restaurar
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewModalDetail;
