import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import Spinner from "../../Spinner";
import axios from "axios";
import { useSelector } from "react-redux";
import ToggleButton from "../../ToggleButton";
import { ReactSortable } from "react-sortablejs";
import ModalEditDescripcionImegen from "./ModalEditDescripcionImegen";

const ModalEditEspacio = ({ producto, idSelect, setIdSelect }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    const [editarNombreEspacio, setEditarNombreEspacio] = useState(false);
    const [espaciosId, setEspaciosId] = useState(idSelect);
    const [espaciosImagenes, setEspaciosImagenes] = useState([]);
    const [toggleButton, setToggleButton] = useState(false);
    const [nombreEspacio, setNombreEspacio] = useState("");
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [editarTitulo, setEditarTitulo] = useState(false);
    const [espacios, setEspacios] = useState([]);
    const [espacioTranferir, setEspaciosTransferir] = useState(0);
    const [selectedImageIds, setSelectedImageIds] = useState([]);
    const [imagenPrev, setImagenPrev] = useState(null);
    const [descripcion, setDescripcion] = useState("");
    const [descriptionEdit, setDescriptionEdit] = useState("");
    const [isEditing, setIsEditing] = useState(0);

    const [tituloEspacio, setTituloEspacio] = useState(idSelect?.nombre ?? "");
    useEffect(() => {
        getOne();
        cargarEtiquetas();
    }, [idSelect, isEditing]);

    const handleImageCheckboxChange = (imageId) => {
        const isSelected = selectedImageIds.includes(imageId);

        setSelectedImageIds((prevIds) =>
            isSelected
                ? prevIds.filter((id) => id !== imageId)
                : [...prevIds, imageId]
        );
    };
    const getOne = async () => {
        const dataGetOne = await axios.get(
            `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/getOne/${idSelect.id}`,
            { headers }
        );

        setEspaciosImagenes(dataGetOne.data);
    };

    const multiLoadFile = async (event) => {
        const form = new FormData();

        setLoading(true);
        if (!espaciosId.id) {
            Swal.fire({
                title: "Primero debes guardar el nombre del espacio antes de subir fotos",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });
            return;
        }
        form.append("id_productos", `${producto}`);
        form.append("id_etiqueta_imagen", `${espaciosId.id}`);
        form.append("imagenes_casas", imagenPrev);
        form.append("descripcion", descripcion);

        try {
            const data = await axios.post(
                `${process.env.REACT_APP_PROXY}/productoImagenes`,
                form,
                { headers }
            );
            console.log(data);

            const dataGetOne = await axios.get(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/getOne/${espaciosId.id}`,
                { headers }
            );

            setEspaciosImagenes(dataGetOne.data);
            setImagenPrev(null);
            setDescripcion("");
        } catch (error) {
            console.log(error.response);
        }

        setLoading(false);
    };

    const oneLoadFile = async (event) => {
        if (event.target.files[0]) {
            setImagenPrev(event.target.files[0]);
        }
    };

    const crearEtiquetaEspacio = async () => {
        if (nombreEspacio.trim() === "") {
            Swal.fire({
                title: "Debes agregar el nombre del espacio",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });

            return;
        }
        try {
            const data = await axios.post(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/postEtiquetaImagen`,
                {
                    nombre: nombreEspacio,
                    id_casa: producto,
                },
                { headers }
            );
            console.log(data);
            console.log(data.data.data.id);

            setEspaciosId(data.data.data);
        } catch (error) {
            console.log(error.response);
        }

        setLoading(false);
    };

    const handleDelete = async (id) => {
        setLoading(true);

        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/productoImagenes/eliminar/${id}`,
            {
                data: { id_usuario: authUser.id_usuario },
                headers: headers,
            }
        );

        if (json.data.message === "Registro deshabilitado con exito") {
            const dataGetOne = await axios.get(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/getOne/${espaciosId.id}`,
                { headers }
            );

            setEspaciosImagenes(dataGetOne.data);
            setLoading(false);
        }
    };

    const actualizarTitulo = async () => {
        if (tituloEspacio.trim() === "") {
            Swal.fire({
                title: "Debes agregar el nombre del espacio",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });

            return;
        }
        try {
            const dataGetOne = await axios.put(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/putEtiquetaImagen/${espaciosId.id}`,
                {
                    nombre: tituloEspacio,
                },
                { headers }
            );
            console.log(dataGetOne);
            getOne();
        } catch (error) {
            console.log(error);
        }
    };

    const cargarEtiquetas = async () => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/getAll/${producto}`,
            { headers }
        );
        console.log("cargarEtiquetas", data);
        setEspacios(data);
        setEspaciosTransferir(data[0]);
    };
    const handleTransferirFotos = async () => {
        if (!espacioTranferir.id) {
            Swal.fire({
                title: "Debes agregar el espacio a donde se transferiran",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });

            return;
        }
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/productoImagenes/updateEtiqueta`,
                {
                    id_etiqueta_imagen: espacioTranferir?.id,
                    array_imagenes: selectedImageIds,
                },
                { headers }
            );
            console.log(data);
            Swal.fire({
                title: "Imagenes transferidas con exito!",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok!",
            });
            getOne();

            setSelectedImageIds([]);
        } catch (error) {
            Swal.fire({
                title: "Error al transferir imagenes",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok!",
            });
        }
    };
    const handleGuardar = async () => {
        let array_orden = [];
        espaciosImagenes.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        console.log(array_orden);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${"productoImagenes"}/updateOrden`,
            {
                array_orden: array_orden,
            },
            { headers }
        );
        console.log(data);
        Swal.fire({
            title: "Espacio actualizado",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok!",
        });
        setIdSelect(0);
    };
    console.log(descriptionEdit);

    const handleEditDescription = (descripcion, id) => {
        setDescriptionEdit(descripcion);
        setIsEditing(id);
    };
    return (
        <div className="relative flex items-center justify-center z-30">
            {isEditing !== 0 && (
                <ModalEditDescripcionImegen
                    modalVisible={isEditing}
                    setModalVisible={setIsEditing}
                    descriptionEdit={descriptionEdit}
                    setDescriptionEdit={setDescriptionEdit}
                />
            )}
            <div className="fixed z-10 bottom-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-24 2xl:container 2xl:mx-auto md:px-20 sm:py-24 ">
                    <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg p-10 xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] overflow-y-auto ">
                        <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                            <h2 className="text-base font-medium">
                                Editar espacio
                            </h2>
                            <hr className="w-full bg-gray-300" />
                            {espaciosId === 0 && (
                                <div className=" w-full flex gap-2">
                                    <input
                                        value={nombreEspacio}
                                        onChange={(e) =>
                                            setNombreEspacio(e.target.value)
                                        }
                                        placeholder="Nombre del espacio"
                                        className="w-44 p-1 border rounded-lg border-gray-400"></input>
                                    <p
                                        onClick={() => crearEtiquetaEspacio()}
                                        className="border rounded-lg w-24 text-center px-2 py-1 border-blue-bluecorner text-blue-bluecorner cursor-pointer">
                                        Guardar
                                    </p>
                                    <hr className="w-full bg-gray-300" />
                                </div>
                            )}

                            <div className="flex gap-1 justify-between w-full items-start">
                                <div className="flex items-center">
                                    <p className="mr-2 font-bold">
                                        {editarTitulo ? (
                                            <input
                                                onChange={(e) =>
                                                    setTituloEspacio(
                                                        e.target.value
                                                    )
                                                }
                                                value={tituloEspacio}
                                                type="string"
                                                className="w-full pl-1 border rounded-md"
                                            />
                                        ) : (
                                            <h2 className="mx-1 mt-1 font-medium lg:mt-0">
                                                {tituloEspacio}
                                            </h2>
                                        )}{" "}
                                    </p>
                                    {editarTitulo ? (
                                        <button
                                            onClick={() => (
                                                actualizarTitulo(),
                                                setEditarTitulo((prev) => !prev)
                                            )}
                                            title="Editar precio"
                                            className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-[#E6E6E6] hover:opacity-100 mx-1">
                                            <svg
                                                className="w-3 h-3 text-blue-700"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                />
                                            </svg>
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() =>
                                                setEditarTitulo((prev) => !prev)
                                            }
                                            title="Editar precio"
                                            className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-[#E6E6E6] hover:opacity-100 mr-2">
                                            <svg
                                                className="w-3 h-3"
                                                id="Capa_2"
                                                data-name="Capa 2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 100 99.97">
                                                <g id="Testimonios">
                                                    <g>
                                                        <path d="m.01,95.86c1.03-2.98,1.79-6.04,2.66-9.06,1.65-5.68,3.25-11.36,4.88-17.05.35-1.21.41-1.22,1.3-.33,7.26,7.26,14.53,14.52,21.79,21.79.86.86.85.88-.32,1.21-8.47,2.41-16.93,4.82-25.39,7.23-.28.08-.54.21-.82.31-1.24,0-2.47-.02-3.71.01-.34,0-.41-.06-.4-.4.03-1.23.01-2.47.01-3.71Z" />
                                                        <path d="m36.11,88.77c-.31-.02-.47-.25-.65-.43-7.95-7.95-15.9-15.91-23.87-23.84-.72-.72-.2-1.05.23-1.48,8.48-8.48,16.96-16.96,25.44-25.44,7.95-7.95,15.9-15.9,23.86-23.85q.95-.95,1.88-.02c7.77,7.77,15.53,15.55,23.32,23.29.73.73.64,1.1-.03,1.77-16.45,16.42-32.87,32.86-49.3,49.29-.27.27-.49.61-.89.71Z" />
                                                        <path d="m78.52,0c2.53-.02,4.61.81,6.3,2.48,4.23,4.17,8.46,8.36,12.61,12.61,3.32,3.4,3.45,8.57.29,12.11-1.94,2.18-4.09,4.17-6.13,6.27-.61.63-.95.17-1.34-.22-4.55-4.55-9.1-9.1-13.65-13.65-3.29-3.29-6.56-6.58-9.87-9.84-.63-.62-.66-1.02,0-1.64,1.85-1.78,3.63-3.62,5.46-5.43C73.97.92,76.1-.04,78.52,0Z" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    )}
                                </div>
                                <div>
                                    <div className="flex items-center">
                                        <p className="mr-2">
                                            {!toggleButton
                                                ? "Transferir fotos de espacio"
                                                : "Seleccionar fotos"}
                                        </p>
                                        <ToggleButton
                                            isToggled={toggleButton}
                                            onChangeToggle={(e) =>
                                                setToggleButton(e)
                                            }
                                        />
                                    </div>
                                    {toggleButton && espacios !== 0 && (
                                        <>
                                            <div className="flex gap-1 items-center">
                                                <p className="font-semibold">
                                                    Espacio:
                                                </p>
                                                <div className=" px-2 rounded-lg border border-[#E9E9E9] select-none mt-2">
                                                    <select
                                                        name="tipoVariante"
                                                        className=" my-1 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                                                        value={
                                                            espacioTranferir?.id ||
                                                            ""
                                                        }
                                                        onChange={(e) => {
                                                            const selectedId =
                                                                e.target.value;
                                                            const selectedSpace =
                                                                espacios.find(
                                                                    (espacio) =>
                                                                        espacio.id ===
                                                                        parseInt(
                                                                            selectedId
                                                                        )
                                                                );

                                                            setEspaciosTransferir(
                                                                selectedSpace
                                                            );
                                                        }}>
                                                        {espacios?.map(
                                                            (espacio) => (
                                                                <option
                                                                    value={
                                                                        espacio.id
                                                                    }
                                                                    key={
                                                                        espacio.id
                                                                    }>
                                                                    {
                                                                        espacio.nombre
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <button
                                                onClick={() =>
                                                    handleTransferirFotos()
                                                }
                                                disabled={
                                                    selectedImageIds.length ===
                                                    0
                                                }
                                                className={`p-3 px-5 text-white border rounded-lg mx-auto mt-2 ${
                                                    selectedImageIds.length ===
                                                    0
                                                        ? "bg-gray-300 cursor-not-allowed"
                                                        : "bg-[#1C4CBB] hover:scale-105"
                                                }`}>
                                                Transferir fotos (
                                                {selectedImageIds.length})
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                            {espaciosId?.nombre && !loading && (
                                <div>
                                    <div className="mt-4 relative flex justify-center h-52 w-full lg:h-72 xl:h-96 2xl:h-52 border border-[#E9E9E9] rounded-lg hover:cursor-pointer">
                                        <div className="absolute">
                                            <div className="flex flex-col w-full mx-auto ">
                                                <div className="relative top-4 ">
                                                    <span className="block mt-10 text-base font-bold text-center ">
                                                        Agrega o arrastra una
                                                        foto aquí
                                                    </span>
                                                    <span className="block mt-3 text-sm text-center">
                                                        Agrega una foto
                                                        llamativa
                                                    </span>
                                                    <span className="block mt-3 font-normal text-center text-blue-bluecorner">
                                                        Sube la foto desde tu
                                                        dispositivo
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <input
                                            accept="image"
                                            // multiple
                                            type="file"
                                            className="w-full h-full opacity-0 cursor-pointer"
                                            // onChange={multiLoadFile}
                                            onChange={oneLoadFile}
                                        />
                                    </div>
                                    {imagenPrev && (
                                        <div>
                                            <p>Nueva imagen</p>
                                            <div
                                                className={`relative w-full mt-4    ${
                                                    window.innerWidth < 500
                                                        ? ""
                                                        : "handle"
                                                } `}>
                                                <img
                                                    src={URL.createObjectURL(
                                                        imagenPrev
                                                    )}
                                                    className={` object-cover w-full mx-auto rounded-lg h-52 `}
                                                />
                                                <div
                                                    onClick={() =>
                                                        setImagenPrev(null)
                                                    }
                                                    className="absolute rounded-md top-1 right-1 bg-slate-200 hover:scale-105 ">
                                                    <svg
                                                        className="w-6 h-6 text-blue-500"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="9"
                                                        />{" "}
                                                        <path d="M10 10l4 4m0 -4l-4 4" />
                                                    </svg>
                                                </div>
                                                <div className="flex justify-center my-2 ">
                                                    <textarea
                                                        className="border rounded-md px-2 border-[#1C4CBB] w-10/12"
                                                        value={descripcion}
                                                        onChange={(e) =>
                                                            setDescripcion(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Descripcion (opcional)"
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-4 mt-8">
                                                    {saving ? (
                                                        <Spinner />
                                                    ) : (
                                                        <button
                                                            onClick={() =>
                                                                multiLoadFile()
                                                            }
                                                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                                                            Guardar imagen
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <hr className="mt-6 w-full  "></hr>
                                        </div>
                                    )}
                                    <ReactSortable
                                        handle=".handle"
                                        className="flex flex-wrap gap-2 lg:grid lg:grid-cols-2 "
                                        group="groupName"
                                        animation={200}
                                        delayOnTouchStart={true}
                                        delay={2}
                                        list={espaciosImagenes}
                                        setList={setEspaciosImagenes}>
                                        {espaciosImagenes?.map(
                                            (image, index) => (
                                                <div
                                                    key={index}
                                                    className={`relative w-full mt-4 border rounded-lg handle cursor-move`}>
                                                    {toggleButton &&
                                                        espacios !== 0 && (
                                                            <input
                                                                type="checkbox"
                                                                onChange={() =>
                                                                    handleImageCheckboxChange(
                                                                        image.id
                                                                    )
                                                                }
                                                                checked={selectedImageIds.includes(
                                                                    image.id
                                                                )}
                                                                className="absolute top-2 left-2 z-10 cursor-pointer w-5 h-5"
                                                            />
                                                        )}
                                                    <img
                                                        src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/productoImagenes/getImagen/${image.imagen_minificada}`}
                                                        className={` object-cover w-full mx-auto rounded-lg h-52  ${
                                                            selectedImageIds.includes(
                                                                image.id
                                                            )
                                                                ? "scale-95"
                                                                : ""
                                                        }`}
                                                    />
                                                    <div
                                                        onClick={() =>
                                                            handleDelete(
                                                                image.id
                                                            )
                                                        }
                                                        className="absolute rounded-md top-1 right-1 bg-slate-200 hover:scale-105 cursor-pointer ">
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round">
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="9"
                                                            />{" "}
                                                            <path d="M10 10l4 4m0 -4l-4 4" />
                                                        </svg>
                                                    </div>
                                                    <div
                                                        title={
                                                            image.descripcion
                                                                .length
                                                                ? `Descripcion: ${image.descripcion}`
                                                                : `Sin descripcion`
                                                        }
                                                        className="absolute rounded-md top-1 left-1 bg-slate-200 hover:scale-105 cursor-help ">
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round">
                                                            {" "}
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                            />{" "}
                                                            <line
                                                                x1="12"
                                                                y1="8"
                                                                x2="12"
                                                                y2="12"
                                                            />{" "}
                                                            <line
                                                                x1="12"
                                                                y1="16"
                                                                x2="12.01"
                                                                y2="16"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <button
                                                        onClick={() =>
                                                            handleEditDescription(
                                                                image.descripcion,
                                                                image.id
                                                            )
                                                        }
                                                        title="editar descripcion"
                                                        className="absolute rounded-md bottom-1 left-1 bg-slate-200 hover:scale-105 cursor-pointer">
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            )
                                        )}
                                    </ReactSortable>
                                    <div className="flex flex-col gap-4 mt-8">
                                        {saving ? (
                                            <Spinner />
                                        ) : (
                                            <button
                                                onClick={() => handleGuardar()}
                                                className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                                                Actualizar
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                            {loading && <div>{<Spinner />}</div>}
                        </div>
                        <button
                            onClick={() => setIdSelect(0)}
                            className="absolute text-gray-800 dark:text-gray-400 top-1 right-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>{" "}
                </div>{" "}
            </div>{" "}
        </div>
    );
};

export default ModalEditEspacio;
