import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import "swiper/css/lazy";
import "../../empresas/paquetesEmpresa/Swiper.css";
import CardVerano from "./CardVerano";
import useHeaders from "../../../hooks/useHeaders";
import { ReactSortable } from "react-sortablejs";
import { useSelector } from "react-redux";

const AllCards = ({ vistaAdministrador, refreshHouses, setRefreshHouses }) => {
    const [houses, setHouses] = useState([]);
    const [checking, setChecking] = useState(true);
    const { headers } = useHeaders();
    const authUser = useSelector((state) => state.authReducer);
    const getHouses = async () => {
        try {
            setChecking(true);
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/casaVerano/all/${
                    authUser.id_usuario ? authUser.id_usuario : 0
                }`
            );
            console.log(data);
            setChecking(false);
            setHouses(data);
        } catch (error) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        getHouses();
    }, [refreshHouses]);

    const handleDragEnd = async () => {
        const allHousesID = houses.map((house) => house.id);

        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casaVerano/updateOrden/`,
                { array_orden: allHousesID },
                { headers }
            );
        } catch (error) {
            console.log(error.response);
        }
    };

    return (
        <div>
            {checking ? (
                <div className="justify-center w-full py-10">
                    <Spinner />
                </div>
            ) : houses.length > 0 ? (
                <ReactSortable
                    disabled={!vistaAdministrador}
                    onEnd={() => handleDragEnd()}
                    animation={200}
                    delayOnTouchStart={true}
                    delay={2}
                    list={houses}
                    setList={setHouses}
                    className="w-full grid-cols-2 gap-4 p-4 md:grid lg:grid-cols-3 2xl:grid-cols-4 xl:px-32 2xl:px-28">
                    {houses.map((product) => (
                        <CardVerano
                            name={product.id}
                            key={product.id}
                            product={product}
                            vistaAdministrador={vistaAdministrador}
                            setRefreshHouses={setRefreshHouses}
                        />
                    ))}
                </ReactSortable>
            ) : (
                <h2 className="py-10 font-medium text-center">
                    No se encontraron casas asignadas a la promo
                </h2>
            )}
        </div>
    );
};

export default AllCards;
