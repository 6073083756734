import { useEffect, useState } from "react";
// import { formConfig } from "../components/checkout/mercadopago/formConfig";
import useScript from "./useScript";

export default function useMercadoPago() {
    const [resultPayment, setResultPayment] = useState(undefined);
    const [monto, setMonto] = useState("0");
    const { MercadoPago } = useScript(
        "https://sdk.mercadopago.com/js/v2",
        "MercadoPago"
    );

    useEffect(() => {
        if (MercadoPago) {
            console.log("Entro a useEffect monto: ", monto);
            const mp = new MercadoPago(
                process.env.REACT_APP_PUBLICK_KEY_MERCADOPAGO,
                { locale: "es-AR" }
            );

            mp.checkout({
                preference: { id: "Your_preference_id" },
                // render: {
                //     container: ".cho-container", // Indica el nombre de la clase donde se mostrará el botón de pagar.
                //     label: "Pagar", // Indica el texto del botón de pago
                // },
            });
            // if (monto !== "0") {
            //     const cardForm = mp.cardForm({
            //         amount: monto,
            //         autoMount: true,
            //         form: formConfig,
            //         callbacks: {
            //             onFormMounted: (error) => {
            //                 if (error)
            //                     return console.warn(
            //                         "Form Mounted handling error: ",
            //                         error
            //                     );
            //                 else console.log("Form creado monto: ", monto);
            //             },

            //             onSubmit: (event) => {
            //                 event.preventDefault();

            //                 const {
            //                     paymentMethodId: payment_method_id,
            //                     issuerId: issuer_id,
            //                     cardholderEmail: email,
            //                     amount,
            //                     token,
            //                     installments,
            //                     identificationNumber,
            //                     identificationType,
            //                 } = cardForm.getCardFormData();

            //                 fetch(
            //                     `${process.env.REACT_APP_PROXY}/mercadoPago/`,
            //                     {
            //                         // entry point backend
            //                         method: "POST",
            //                         headers: {
            //                             "Access-Control-Allow-Origin": "*",
            //                             "Access-Control-Request-Method":
            //                                 "GET, POST, DELETE, PUT, OPTIONS",
            //                             "Content-Type": "application/json",
            //                         },
            //                         body: JSON.stringify({
            //                             token,
            //                             issuer_id,
            //                             payment_method_id,
            //                             transaction_amount: 1000,
            //                             installments: Number(installments),
            //                             description: "Descripción del producto",
            //                             payer: {
            //                                 email,
            //                                 identification: {
            //                                     type: identificationType,
            //                                     number: identificationNumber,
            //                                 },
            //                             },
            //                         }),
            //                     }
            //                 )
            //                     .then((res) => res.json())
            //                     .then((data) => setResultPayment(data))
            //                     .catch((err) => {
            //                         setResultPayment(err);
            //                     });
            //             },
            //             onFetching: (resource) => {
            //                 // Animate progress bar
            //                 const progressBar =
            //                     document.querySelector(".progress-bar");
            //                 progressBar.removeAttribute("value");

            //                 return () => {
            //                     progressBar.setAttribute("value", "0");
            //                 };
            //             },
            //         },
            //     });
            // }
        }
    }, [MercadoPago, monto, setMonto]);

    return { resultPayment, monto, setMonto };
}
