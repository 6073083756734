import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import Spinner from "../../Spinner";
import axios from "axios";
import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import ModalEditDescripcionImegen from "./ModalEditDescripcionImegen";

const ModalNewEspacio = ({ producto, setEspacioNew }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    const [espaciosId, setEspaciosId] = useState(0);
    const [espaciosImagenes, setEspaciosImagenes] = useState([]);

    const [nombreEspacio, setNombreEspacio] = useState("");
    const [loading, setLoading] = useState(false);
    const [espacios, setEspacios] = useState([]);
    const [saving, setSaving] = useState(false);

    const [selectedImageIds, setSelectedImageIds] = useState([]);
    const [imagenPrev, setImagenPrev] = useState(null);
    const [descripcion, setDescripcion] = useState("");
    const [descriptionEdit, setDescriptionEdit] = useState("");
    const [isEditing, setIsEditing] = useState(0);

    useEffect(() => {
        getOne();
    }, [isEditing]);
    const handleEditDescription = (descripcion, id) => {
        setDescriptionEdit(descripcion);
        setIsEditing(id);
    };
    const getOne = async () => {
        const dataGetOne = await axios.get(
            `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/getOne/${espaciosId.id}`,
            { headers }
        );

        setEspaciosImagenes(dataGetOne.data);
    };
    const multiLoadFile = async (event) => {
        const form = new FormData();

        setLoading(true);
        if (!espaciosId.id) {
            Swal.fire({
                title: "Primero debes guardar el nombre del espacio antes de subir fotos",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });
            return;
        }
        form.append("id_productos", `${producto}`);
        form.append("id_etiqueta_imagen", `${espaciosId.id}`);
        form.append("imagenes_casas", imagenPrev);
        form.append("descripcion", descripcion);

        try {
            const data = await axios.post(
                `${process.env.REACT_APP_PROXY}/productoImagenes`,
                form,
                { headers }
            );
            console.log(data);

            const dataGetOne = await axios.get(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/getOne/${espaciosId.id}`,
                { headers }
            );

            setEspaciosImagenes(dataGetOne.data);
            setImagenPrev(null);
            setDescripcion("");
        } catch (error) {
            console.log(error.response);
        }

        setLoading(false);
    };

    const oneLoadFile = async (event) => {
        if (event.target.files[0]) {
            setImagenPrev(event.target.files[0]);
        }
    };
    const crearEtiquetaEspacio = async () => {
        if (nombreEspacio.trim() === "") {
            Swal.fire({
                title: "Debes agregar el nombre del espacio",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });

            return;
        }
        try {
            const data = await axios.post(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/postEtiquetaImagen`,
                {
                    nombre: nombreEspacio,
                    id_casa: producto,
                },
                { headers }
            );
            console.log(data);
            console.log(data.data.data.id);

            setEspaciosId(data.data.data);
        } catch (error) {
            console.log(error.response);
        }

        setLoading(false);
    };

    const handleDelete = async (id) => {
        setLoading(true);

        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/productoImagenes/eliminar/${id}`,
            {
                data: { id_usuario: authUser.id_usuario },
                headers: headers,
            }
        );

        if (json.data.message === "Registro deshabilitado con exito") {
            const dataGetOne = await axios.get(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/getOne/${espaciosId.id}`,
                { headers }
            );

            setEspaciosImagenes(dataGetOne.data);
            setLoading(false);
        }
    };
    const handleGuardar = async () => {
        let array_orden = [];
        espaciosImagenes.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        console.log(array_orden);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${"productoImagenes"}/updateOrden`,
            {
                array_orden: array_orden,
            },
            { headers }
        );
        console.log(data);
        Swal.fire({
            title: "Espacio Guardado",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok!",
        });
        setEspacioNew(false);
    };
    return (
        <div className="relative flex items-center justify-center z-30">
            {isEditing !== 0 && (
                <ModalEditDescripcionImegen
                    modalVisible={isEditing}
                    setModalVisible={setIsEditing}
                    descriptionEdit={descriptionEdit}
                    setDescriptionEdit={setDescriptionEdit}
                />
            )}
            <div className="fixed z-10 bottom-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-24 2xl:container 2xl:mx-auto md:px-20 sm:py-24 ">
                    <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg p-10 xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] overflow-y-auto ">
                        <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                            <h2 className="text-base font-medium">
                                Nuevo espacio
                            </h2>
                            <hr className="w-full bg-gray-300" />
                            {espaciosId === 0 && (
                                <div className=" w-full flex gap-2">
                                    <input
                                        value={nombreEspacio}
                                        onChange={(e) =>
                                            setNombreEspacio(e.target.value)
                                        }
                                        placeholder="Nombre del espacio"
                                        className="w-44 p-1 border rounded-lg border-gray-400"></input>
                                    <p
                                        onClick={() => crearEtiquetaEspacio()}
                                        className="border rounded-lg w-24 text-center px-2 py-1 border-blue-bluecorner text-blue-bluecorner cursor-pointer">
                                        Guardar
                                    </p>
                                    <hr className="w-full bg-gray-300" />
                                </div>
                            )}

                            <p className="w-full ">
                                {espaciosId?.nombre &&
                                    `Añadir fotos de ${espaciosId?.nombre}`}
                            </p>
                            {espaciosId?.nombre && !loading && (
                                <div>
                                    <div className="mt-4 relative flex justify-center h-52 w-full lg:h-72 xl:h-96 2xl:h-52 border border-[#E9E9E9] rounded-lg hover:cursor-pointer">
                                        <div className="absolute">
                                            <div className="flex flex-col w-full mx-auto ">
                                                <div className="relative top-4 ">
                                                    <span className="block mt-10 text-base font-bold text-center ">
                                                        Agrega o arrastra una
                                                        foto aquí
                                                    </span>
                                                    <span className="block mt-3 text-sm text-center">
                                                        Agrega una foto
                                                        llamativa
                                                    </span>
                                                    <span className="block mt-3 font-normal text-center text-blue-bluecorner">
                                                        Sube la foto desde tu
                                                        dispositivo
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <input
                                            accept="image/*"
                                            multiple
                                            type="file"
                                            className="w-full h-full opacity-0 cursor-pointer"
                                            onChange={oneLoadFile}
                                        />
                                    </div>
                                    {imagenPrev && (
                                        <div>
                                            <p>Nueva imagen</p>
                                            <div
                                                className={`relative w-full mt-4    ${
                                                    window.innerWidth < 500
                                                        ? ""
                                                        : "handle"
                                                } `}>
                                                <img
                                                    src={URL.createObjectURL(
                                                        imagenPrev
                                                    )}
                                                    className={` object-cover w-full mx-auto rounded-lg h-52 `}
                                                />
                                                <div
                                                    onClick={() =>
                                                        setImagenPrev(null)
                                                    }
                                                    className="absolute rounded-md top-1 right-1 bg-slate-200 hover:scale-105 ">
                                                    <svg
                                                        className="w-6 h-6 text-blue-500"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="9"
                                                        />{" "}
                                                        <path d="M10 10l4 4m0 -4l-4 4" />
                                                    </svg>
                                                </div>
                                                <div className="flex justify-center my-2 ">
                                                    <textarea
                                                        className="border rounded-md px-2 border-[#1C4CBB] w-10/12"
                                                        value={descripcion}
                                                        onChange={(e) =>
                                                            setDescripcion(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Descripcion (opcional)"
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-4 mt-8">
                                                    {saving ? (
                                                        <Spinner />
                                                    ) : (
                                                        <button
                                                            onClick={() =>
                                                                multiLoadFile()
                                                            }
                                                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                                                            Guardar imagen
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <hr className="mt-6 w-full  "></hr>
                                        </div>
                                    )}
                                    <ReactSortable
                                        handle=".handle"
                                        className="flex flex-wrap gap-2 lg:grid lg:grid-cols-2 "
                                        group="groupName"
                                        animation={200}
                                        delayOnTouchStart={true}
                                        delay={2}
                                        list={espaciosImagenes}
                                        setList={setEspaciosImagenes}>
                                        {espaciosImagenes?.map(
                                            (image, index) => (
                                                <div
                                                    key={index}
                                                    className={`relative w-full mt-4 border rounded-lg handle cursor-move`}>
                                                    <img
                                                        src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/productoImagenes/getImagen/${image.imagen_minificada}`}
                                                        className={` object-cover w-full mx-auto rounded-lg h-52  ${
                                                            selectedImageIds.includes(
                                                                image.id
                                                            )
                                                                ? "scale-95"
                                                                : ""
                                                        }`}
                                                    />
                                                    <div
                                                        onClick={() =>
                                                            handleDelete(
                                                                image.id
                                                            )
                                                        }
                                                        className="absolute rounded-md top-1 right-1 bg-slate-200 hover:scale-105 cursor-pointer ">
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round">
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="9"
                                                            />{" "}
                                                            <path d="M10 10l4 4m0 -4l-4 4" />
                                                        </svg>
                                                    </div>
                                                    <div
                                                        title={
                                                            image.descripcion
                                                                .length
                                                                ? `Descripcion: ${image.descripcion}`
                                                                : `Sin descripcion`
                                                        }
                                                        className="absolute rounded-md top-1 left-1 bg-slate-200 hover:scale-105 cursor-help ">
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round">
                                                            {" "}
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                            />{" "}
                                                            <line
                                                                x1="12"
                                                                y1="8"
                                                                x2="12"
                                                                y2="12"
                                                            />{" "}
                                                            <line
                                                                x1="12"
                                                                y1="16"
                                                                x2="12.01"
                                                                y2="16"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <button
                                                        onClick={() =>
                                                            handleEditDescription(
                                                                image.descripcion,
                                                                image.id
                                                            )
                                                        }
                                                        title="editar descripcion"
                                                        className="absolute rounded-md bottom-1 left-1 bg-slate-200 hover:scale-105 cursor-pointer">
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            )
                                        )}
                                    </ReactSortable>
                                </div>
                            )}
                            {loading && <div>{<Spinner />}</div>}
                            <div className="flex flex-col gap-4 mt-8">
                                {saving ? (
                                    <Spinner />
                                ) : (
                                    <button
                                        onClick={() => handleGuardar()}
                                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                                        Guardar
                                    </button>
                                )}
                            </div>
                        </div>
                        <button
                            onClick={() => setEspacioNew(false)}
                            className="absolute text-gray-800 dark:text-gray-400 top-1 right-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>{" "}
                </div>{" "}
            </div>{" "}
        </div>
    );
};

export default ModalNewEspacio;
