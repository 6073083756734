import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import dividirFechaDatePicker from "../../helpers/dividirFechaDatePicker";
import Spinner from "../../Spinner";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import axios from "axios";

const ModalDetalleCotizacion = ({
    cotizacionDetalle,
    setCotizacionDetalle,
    getAllPedidos,
    estadoPedido,
}) => {
    registerLocale("es", es);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [siguiente, setSiguiente] = useState("Guardar");

    const [input, setInput] = useState({
        ...cotizacionDetalle,
        tipo_moneda: "USD",
        costo_alquiler: "",
    });

    const handleSubmit = async () => {
        setSiguiente(null);
        if (Number(input.costo_alquiler) < 1) {
            Swal.fire({
                title: "Error!",
                text: "La cotizacion debe de tener un costo",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            setSiguiente("Guardar");
            return;
        }
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/pedidoCasa/actualizarCotizacion/${cotizacionDetalle.id_pedido}`,
                {
                    costo_alquiler: Number(input.costo_alquiler),
                    costo_fee_bc:
                        (input.costo_alquiler / 100) * input.fee_cliente,
                    costo_fee_bc_propietario:
                        (input.costo_alquiler / 100) * input.fee_propietario,
                    costo_igv: 0,
                    costo_garantia: 0,
                    tipo_moneda: input.tipo_moneda,
                },

                { headers }
            );

            if (data.message === "Actualizacion exitosa!") {
                Swal.fire({
                    title: "Se ha guardado la cotizacion correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                getAllPedidos("pedidoCasa", "Si", 1, "", estadoPedido);
                setCotizacionDetalle(null);
            }
        } catch (error) {
            console.log(error.response.data);
        }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed top-0 left-0 z-10 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-12">
                    <div className="relative flex flex-col items-center px-4 py-12 bg-white rounded-lg w-96 dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto">
                        <div className="w-full my-3 text-left">
                            <header className=" pl-2 text-[#3C3C3C]  text-base  font-bold ">
                                Cotizacion
                            </header>
                        </div>
                        <div className="w-full text-left">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-sm ">
                                {cotizacionDetalle.casa}
                            </header>
                        </div>

                        <div className="relative grid w-full grid-cols-2 gap-4 mx-auto mt-2">
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Nombre:
                                </label>
                                <input
                                    disabled
                                    autoComplete="off"
                                    type="text"
                                    name="nombre"
                                    value={input.cliente}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 cursor-not-allowed "
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Celular:
                                </label>
                                <input
                                    disabled
                                    autoComplete="off"
                                    type="text"
                                    name="celular"
                                    value={input.cliente_celular}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 cursor-not-allowed"
                                    placeholder="Apellido"
                                />
                            </div>
                        </div>
                        <div className="relative grid w-full grid-cols-1 gap-4 mx-auto mt-2">
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Correo:
                                </label>
                                <input
                                    disabled
                                    autoComplete="off"
                                    type="text"
                                    name="correo"
                                    value={input.cliente_correo}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 cursor-not-allowed "
                                    placeholder="correo"
                                />
                            </div>
                        </div>

                        <div className="relative grid w-full grid-cols-2 gap-4 mx-auto mt-2">
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Fecha de inicio:
                                </label>
                                <DatePicker
                                    className=" w-full p-2 mx-auto rounded-lg  border-[#e5e7eb] border disabled:bg-slate-50 cursor-not-allowed"
                                    placeholderText="Fecha de entrada"
                                    locale="es"
                                    autoComplete="off"
                                    dateFormat="dd/MM/yyyy"
                                    disabled
                                    selected={
                                        input.fecha_inicio !== ""
                                            ? new Date(
                                                  dividirFechaDatePicker(
                                                      input.fecha_inicio
                                                  )[2],
                                                  dividirFechaDatePicker(
                                                      input.fecha_inicio
                                                  )[1] - 1,
                                                  dividirFechaDatePicker(
                                                      input.fecha_inicio
                                                  )[0]
                                              )
                                            : input.fecha_inicio
                                    }
                                />
                            </div>
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Fecha de salida:
                                </label>
                                <DatePicker
                                    className="w-full p-2 mx-auto rounded-lg  border-[#e5e7eb] border disabled:bg-slate-50 cursor-not-allowed"
                                    placeholderText="Fecha de salida"
                                    locale="es"
                                    autoComplete="off"
                                    dateFormat="dd/MM/yyyy"
                                    disabled
                                    selected={
                                        input.fecha_final !== ""
                                            ? new Date(
                                                  dividirFechaDatePicker(
                                                      input.fecha_final
                                                  )[2],
                                                  dividirFechaDatePicker(
                                                      input.fecha_final
                                                  )[1] - 1,
                                                  dividirFechaDatePicker(
                                                      input.fecha_final
                                                  )[0]
                                              )
                                            : input.fecha_final
                                    }
                                />
                            </div>
                        </div>

                        <div className="relative grid w-full grid-cols-1 gap-4 mx-auto mt-2">
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Comentario:
                                </label>
                                <textarea
                                    autoComplete="off"
                                    type="text"
                                    name="nota"
                                    disabled
                                    value={input.nota}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 cursor-not-allowed"
                                    placeholder="Comentario"
                                />
                            </div>
                        </div>

                        <div className="relative grid w-full grid-cols-2 gap-4 mx-auto mt-2">
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Moneda:
                                </label>
                                <select
                                    onChange={(e) => {
                                        setInput({
                                            ...input,
                                            tipo_moneda: e.target.value,
                                        });
                                    }}
                                    defaultValue="USD"
                                    className="w-full py-2 pl-4   placeholder:text-[#3C3C3C] text-[#3C3C3C] border border-[#e5e7eb] rounded-lg focus:outline-none">
                                    <option
                                        className="text-[#3C3C3C]"
                                        value="USD">
                                        USD
                                    </option>
                                    <option
                                        className="text-[#3C3C3C]"
                                        value="PEN">
                                        PEN
                                    </option>
                                </select>
                            </div>
                            <div className="flex flex-col ">
                                <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                    Costo alquiler:
                                </label>
                                <input
                                    autoComplete="off"
                                    type="number"
                                    min={-1}
                                    name="costo"
                                    value={input.costo_alquiler}
                                    onChange={(e) => {
                                        setInput({
                                            ...input,
                                            costo_alquiler: e.target.value,
                                        });
                                    }}
                                    className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                    placeholder="Costo"
                                />
                            </div>
                        </div>
                        <div
                            className={
                                Number(input.costo_alquiler) > 0
                                    ? "relative grid w-full grid-cols-1 mt-2"
                                    : "hidden"
                            }>
                            <div className="flex justify-between">
                                <p className="w-1/2 mb-1 text-sm tracking-wide text-gray-600">
                                    Comision cliente:
                                </p>
                                <p className="w-1/2 mb-1 text-sm tracking-wide text-gray-600 ">
                                    {(input.costo_alquiler / 100) *
                                        input.fee_cliente}{" "}
                                    {input.tipo_moneda}
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className="w-1/2 mb-1 text-sm tracking-wide text-gray-600">
                                    Comision propietario:
                                </p>
                                <p className="w-1/2 mb-1 text-sm tracking-wide text-gray-600 ">
                                    {(input.costo_alquiler / 100) *
                                        input.fee_propietario}{" "}
                                    {input.tipo_moneda}
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className="w-1/2 mb-1 text-sm tracking-wide text-gray-600">
                                    Total a pagar por reserva:
                                </p>
                                <p className="w-1/2 mb-1 text-sm tracking-wide text-gray-600 ">
                                    {(input.costo_alquiler / 100) *
                                        input.fee_cliente +
                                        Number(input.costo_alquiler)}{" "}
                                    {input.tipo_moneda}
                                </p>
                            </div>
                        </div>

                        <div className="w-full mt-4">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="pb-3 text-center ">
                                            <button
                                                onClick={(e) => handleSubmit(e)}
                                                disabled={!siguiente}
                                                className="w-full py-3 mt-4 text-center text-white border border-blue-400 rounded-md bg-blue-bluecorner hover:bg-white hover:text-blue-bluecorner disabled:bg-blue-400">
                                                {siguiente ? (
                                                    siguiente
                                                ) : (
                                                    <Spinner width={7} />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => setCotizacionDetalle(null)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDetalleCotizacion;
