import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";

const EditarTurnosEmbarcacion = ({ producto, setProducto, setModal }) => {
    const [siguiente, setSiguiente] = useState("Guardar");

    const [horarios, setHorarios] = useState([]);
    const [agregar, setAgregar] = useState(false);

    const [render, setRender] = useState(false);

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const horario = [];
    const [horarioSelect, setHorarioSelect] = useState([]);
    const [indiceSelect, setIndiceSelect] = useState(-1);

    for (let i = 0; i < 24; i++) {
        if (i <= 9) {
            horario.push(`0${i}:00`);
        } else {
            horario.push(`${i}:00`);
        }
    }

    useEffect(() => {
        getHorarios(producto.data.id);
    }, []);

    const getHorarios = async (id) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/embarcacionDuracion/all/${id}`,
            { headers }
        );

        setHorarios(data);
        setHorarioSelect(data);
    };

    const cambiarPersonas = async (e, tipo, turno) => {
        let aux = horarioSelect[indiceSelect];
        if (tipo === "capacidad_personas") {
            aux.capacidad_personas = e;
        }
        if (tipo === "costo") {
            aux.costo = e;
        }
        if (tipo === "coordinar") {
            aux.a_coordinar = e;
        }
        if (tipo === "tipo_garantia") {
            aux.tipo_garantia = e;
        }
        if (tipo === "precio_garantia") {
            aux.precio_garantia = e;
        }
        if (tipo === "check-in") {
            aux.turno[turno].hora_inicio = e;
        }
        if (tipo === "check-out") {
            aux.turno[turno].hora_final = e;
        }

        let auxhorario = horarioSelect;

        auxhorario.splice(indiceSelect, 1, aux);

        setHorarioSelect(auxhorario);
        setRender(!render);
    };

    const updateProduct = async () => {
        setSiguiente(null);

        let verificar = [];
        horarioSelect.map((item) => {
            if (item.turno.length === 1) {
                if (
                    item.turno[0].hora_inicio !== "00:00" &&
                    item.turno[0].hora_fin !== "00:00" &&
                    item.turno[0].hora_inicio !== "00:00:00" &&
                    item.turno[0].hora_fin !== "00:00:00" &&
                    item.capacidad_personas !== "" &&
                    item.costo !== "0.00" &&
                    item.costo > 0 &&
                    item.capacidad_personas > 0 &&
                    (item.precio_garantia > 0 ||
                        item.tipo_garantia === "A coordinar")
                ) {
                    verificar.push(item);
                } else if (
                    item.a_coordinar === "Si" &&
                    item.capacidad_personas !== "" &&
                    item.costo !== "0.00" &&
                    item.costo > 0 &&
                    item.capacidad_personas > 0 &&
                    (item.precio_garantia > 0 ||
                        item.tipo_garantia === "A coordinar")
                ) {
                    verificar.push(item);
                }
            } else {
                if (
                    item.a_coordinar === "Si" &&
                    item.capacidad_personas !== "" &&
                    item.costo !== "0.00" &&
                    item.costo > 0 &&
                    item.capacidad_personas > 0 &&
                    (item.precio_garantia > 0 ||
                        item.tipo_garantia === "A coordinar")
                ) {
                    verificar.push({
                        ...item,
                        turno: [item.turno[0]],
                    });
                } else if (
                    item.turno[0].hora_inicio !== "00:00" &&
                    item.turno[0].hora_fin !== "00:00" &&
                    item.turno[0].hora_inicio !== "00:00:00" &&
                    item.turno[0].hora_fin !== "00:00:00" &&
                    item.turno[1].hora_inicio !== "00:00" &&
                    item.turno[1].hora_fin !== "00:00" &&
                    item.turno[1].hora_inicio !== "00:00:00" &&
                    item.turno[1].hora_fin !== "00:00:00" &&
                    item.capacidad_personas !== "" &&
                    item.costo !== "0.00" &&
                    item.costo > 0 &&
                    item.capacidad_personas > 0 &&
                    (item.precio_garantia > 0 ||
                        item.tipo_garantia === "A coordinar")
                ) {
                    return verificar.push(item);
                } else if (
                    item.turno[0].hora_inicio !== "00:00" &&
                    item.turno[0].hora_fin !== "00:00" &&
                    item.turno[0].hora_inicio !== "00:00:00" &&
                    item.turno[0].hora_fin !== "00:00:00" &&
                    item.capacidad_personas !== "" &&
                    item.costo !== "0.00" &&
                    item.costo > 0 &&
                    item.capacidad_personas > 0 &&
                    (item.precio_garantia > 0 ||
                        item.tipo_garantia === "A coordinar")
                ) {
                    verificar.push({
                        ...item,
                        turno: [item.turno[0]],
                    });
                } else if (
                    item.turno[1].hora_inicio !== "00:00" &&
                    item.turno[1].hora_fin !== "00:00" &&
                    item.turno[1].hora_inicio !== "00:00:00" &&
                    item.turno[1].hora_fin !== "00:00:00" &&
                    item.capacidad_personas !== "" &&
                    item.costo !== "0.00" &&
                    item.costo > 0 &&
                    item.capacidad_personas > 0 &&
                    (item.precio_garantia > 0 ||
                        item.tipo_garantia === "A coordinar")
                ) {
                    verificar.push({
                        ...item,
                        turno: [item.turno[1]],
                    });
                }
            }
        });

        if (!verificar.length) {
            Swal.fire({
                title: "Debe ingresar por lo menos un horario ",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Continuar!",
            });
            setSiguiente("Guardar");
            return;
        }

        const jsonPrecio = await axios.put(
            `${process.env.REACT_APP_PROXY}/embarcacion/actualizarPrecio/${producto.data.id}`,
            {
                tipo_moneda: producto.data.tipo_moneda,
                tipo_moneda_garantia: producto.data.tipo_moneda_garantia,
            },
            { headers }
        );

        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/embarcacionDuracion`,
            {
                id_embarcacion: producto.data.id,
                horarios: verificar,
            },
            { headers }
        );
        if (
            jsonPrecio.data.message === "actuaizacion exitoso" &&
            data.message === "Registrado con exito"
        ) {
            setModal(false);
        } else {
            Swal.fire({
                title: "Algo ha salido mal, comuniquese con un administrador",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Continuar",
            });
        }
    };

    const handleGuardar = () => {
        updateProduct();
    };

    return (
        <div className="p-4 border rounded-lg ">
            <h1>Turnos y precios</h1>

            <div className="w-full mt-3">
                <div className="w-full p-2 overflow-y-auto">
                    <div className="mt-4">
                        <p className="mb-4 text-sm ">
                            Fijemos la moneda con la que alquilarás tu
                            embarcación.
                        </p>
                        <label className="w-full text-base text-[#3C3C3C]">
                            Moneda
                        </label>
                        <br></br>
                        <select
                            className="w-full border my-3 bg-transparent rounded-lg py-3 pl-3  outline-none focus:outline-none text-[#3C3C3C] placeholder:font-bold"
                            name="coin"
                            onChange={(e) =>
                                setProducto({
                                    ...producto,
                                    data: {
                                        ...producto.data,
                                        tipo_moneda: e.target.value,
                                    },
                                })
                            }
                            defaultValue={producto.data.tipo_moneda}>
                            <option className="text-sm" value={"PEN"}>
                                Soles
                            </option>
                            <option className="text-sm" value={"USD"}>
                                Dolares
                            </option>
                        </select>
                        <br></br>
                        <label className=" w-full text-base text-[#3C3C3C]">
                            Moneda de garantía
                        </label>
                        <br></br>
                        <select
                            className="w-full border my-3 bg-transparent rounded-lg py-3 pl-3  outline-none focus:outline-none text-[#3C3C3C] placeholder:font-bold"
                            name="coin"
                            onChange={(e) =>
                                setProducto({
                                    ...producto,
                                    data: {
                                        ...producto.data,
                                        tipo_moneda_garantia: e.target.value,
                                    },
                                })
                            }
                            defaultValue={producto.data.tipo_moneda_garantia}>
                            <option className="text-sm" value={"PEN"}>
                                Soles
                            </option>
                            <option className="text-sm" value={"USD"}>
                                Dolares
                            </option>
                        </select>
                        <p className="mb-4 text-sm ">
                            Selecciona el tipo de alquiler y determina su
                            precio.
                        </p>
                    </div>

                    <div className="flex flex-col mt-2 lg:flex-row">
                        {horarios?.map((poli, i) => {
                            return (
                                <div
                                    key={poli.id_duracion}
                                    className={
                                        indiceSelect === i
                                            ? "flex justify-center w-full lg:mx-3 mt-2 items-center text-base p-4 border border-solid border-[#3C3C3C] font-bold rounded-lg bg-[#F4F2F1] cursor-pointer "
                                            : "flex justify-center w-full lg:mx-3 mt-2 items-center text-base p-4 border border-solid border-[#E9E9E9] font-bold rounded-lg cursor-pointer "
                                    }
                                    onClick={() => (
                                        indiceSelect !== i
                                            ? setIndiceSelect(i)
                                            : setIndiceSelect(-1),
                                        setRender(!render)
                                    )}>
                                    <span className="">{poli.duracion}</span>
                                </div>
                            );
                        })}
                    </div>
                    {!horarios.length ? <Spinner width={20} /> : null}
                    <div
                        className={
                            indiceSelect !== -1 ? "w-full mt-4  " : "hidden"
                        }>
                        <label className=" w-full text-base text-[#3C3C3C]">
                            Número de personas
                        </label>
                        <input
                            className="w-full border my-3 bg-transparent rounded-lg py-3 px-3  outline-none focus:outline-none text-[#3C3C3C] "
                            type="number"
                            placeholder="1"
                            name="price"
                            value={
                                horarioSelect[indiceSelect]?.capacidad_personas
                            }
                            onChange={(e) =>
                                cambiarPersonas(
                                    e.target.value,
                                    "capacidad_personas"
                                )
                            }></input>
                    </div>
                    <div
                        className={
                            indiceSelect !== -1 ? "w-full mt-3 grid" : "hidden"
                        }>
                        <label className="text-base text-[#3C3C3C]">
                            {" "}
                            Precio{" "}
                        </label>
                        <input
                            className="p-3 mt-2 border rounded-lg outline-none text-[#3C3C3C]"
                            type="number"
                            placeholder="100"
                            name="price"
                            step=".01"
                            value={horarioSelect[indiceSelect]?.costo}
                            onChange={(e) =>
                                cambiarPersonas(e.target.value, "costo")
                            }></input>
                        {/* <p
                            className={
                                horarioSelect[indiceSelect]?.costo > 0
                                    ? "text-[#3C3C3C] text-sm  text-center mt-2 font-semibold"
                                    : "hidden"
                            }>
                            Recibiras{" "}
                            {producto.data.tipo_moneda === "USD"
                                ? "USD"
                                : "PEN"}{" "}
                            {Number(
                                horarioSelect[indiceSelect]?.costo -
                                    (producto.data.fee_propietario / 100) *
                                        horarioSelect[indiceSelect]?.costo
                            ).toFixed(2)}{" "}
                            y Bluecorner{" "}
                            {producto.data.tipo_moneda === "USD"
                                ? "USD"
                                : "PEN"}{" "}
                            {Number(
                                horarioSelect[indiceSelect]?.costo *
                                    (producto.data.fee_propietario / 100)
                            ).toFixed(2)}{" "}
                            de comisión
                        </p> */}
                    </div>
                    <hr className="my-3"></hr>
                    <div
                        className={
                            indiceSelect !== -1 ? "w-full mt-3 grid" : "hidden"
                        }>
                        <label className="text-base text-[#3C3C3C]">
                            {" "}
                            Tipo garantia{" "}
                        </label>
                        <select
                            className="w-full  border my-3 bg-transparent rounded-lg py-3 px-3  outline-none focus:outline-none text-[#3C3C3C] "
                            value={horarioSelect[indiceSelect]?.tipo_garantia}
                            name="warrantyType"
                            onChange={(e) =>
                                cambiarPersonas(e.target.value, "tipo_garantia")
                            }>
                            <option> Valor </option>
                            <option> Porcentaje </option>
                            <option> A coordinar </option>
                        </select>
                        {horarioSelect[indiceSelect]?.tipo_garantia ===
                            "Valor" && (
                            <input
                                autoComplete="off"
                                className="border  flex bg-transparent rounded-lg py-3 px-3  outline-none focus:outline-none text-[#3C3C3C] "
                                placeholder="100"
                                name="warrantyTotal"
                                type="number"
                                step=".01"
                                onWheel={(event) => event.target.blur()}
                                onChange={(e) =>
                                    cambiarPersonas(
                                        e.target.value,
                                        "precio_garantia"
                                    )
                                }
                                value={
                                    horarioSelect[indiceSelect]?.precio_garantia
                                }
                            />
                        )}
                        {horarioSelect[indiceSelect]?.tipo_garantia ===
                            "Porcentaje" && (
                            <div className="flex items-center justify-center px-3 py-3 border rounded-lg text-[#3C3C3C] ">
                                <input
                                    autoComplete="off"
                                    className="w-full rounded-lg outline-none"
                                    placeholder="15"
                                    name="warrantyTotal"
                                    type="number"
                                    step=".01"
                                    onChange={(e) =>
                                        cambiarPersonas(
                                            e.target.value,
                                            "precio_garantia"
                                        )
                                    }
                                    value={
                                        horarioSelect[indiceSelect]
                                            ?.precio_garantia
                                    }></input>
                                <span>
                                    <svg
                                        className="w-4 h-4 text-[#3C3C3C] rounded-lg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <line x1="19" y1="5" x2="5" y2="19" />{" "}
                                        <circle cx="6.5" cy="6.5" r="2.5" />{" "}
                                        <circle cx="17.5" cy="17.5" r="2.5" />
                                    </svg>
                                </span>
                            </div>
                        )}
                    </div>
                    <hr className="my-3 "></hr>
                    <div
                        className={
                            indiceSelect !== -1
                                ? "w-80 mt-2 text-base text-[#3C3C3C]"
                                : "hidden"
                        }>
                        {horarioSelect[indiceSelect]?.a_coordinar === "Si" ? (
                            <label
                                onClick={() =>
                                    cambiarPersonas("No", "coordinar")
                                }
                                className="flex ">
                                {" "}
                                Horario a coordinar{" "}
                                <svg
                                    onClick={() =>
                                        cambiarPersonas("No", "coordinar")
                                    }
                                    className="h-4 w-4 mt-[6px] ml-2 text-blue-500 cursor-pointer"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <rect
                                        x="4"
                                        y="4"
                                        width="16"
                                        height="16"
                                        rx="2"
                                    />{" "}
                                    <path d="M9 12l2 2l4 -4" />
                                </svg>
                            </label>
                        ) : (
                            <label className="flex ">
                                {" "}
                                Horario a coordinar{" "}
                                <svg
                                    onClick={() =>
                                        horarioSelect[indiceSelect]
                                            ?.duracion !== "Half Day"
                                            ? (cambiarPersonas(
                                                  "Si",
                                                  "coordinar"
                                              ),
                                              cambiarPersonas(
                                                  "00:00",
                                                  "check-in",
                                                  0
                                              ),
                                              cambiarPersonas(
                                                  "00:00",
                                                  "check-out",
                                                  0
                                              ))
                                            : (cambiarPersonas(
                                                  "Si",
                                                  "coordinar"
                                              ),
                                              cambiarPersonas(
                                                  "00:00",
                                                  "check-in",
                                                  0
                                              ),
                                              cambiarPersonas(
                                                  "00:00",
                                                  "check-out",
                                                  0
                                              ),
                                              cambiarPersonas(
                                                  "00:00",
                                                  "check-in",
                                                  1
                                              ),
                                              cambiarPersonas(
                                                  "00:00",
                                                  "check-out",
                                                  1
                                              ))
                                    }
                                    className="h-4 w-4 mt-[6px] ml-2 cursor-pointer"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <rect
                                        x="4"
                                        y="4"
                                        width="16"
                                        height="16"
                                        rx="2"
                                    />
                                </svg>
                            </label>
                        )}
                    </div>

                    <div
                        className={
                            indiceSelect !== -1 &&
                            horarioSelect[indiceSelect]?.a_coordinar !== "Si"
                                ? "w-full mt-2"
                                : "hidden"
                        }>
                        {horarioSelect[indiceSelect]?.turno.length === 1 ? (
                            <div className={"mt-3 grid grid-cols-2 "}>
                                <div>
                                    <div className="grid w-9/12 mt-3 ">
                                        <label className=" text-base text-[#3C3C3C]">
                                            Check-in
                                        </label>

                                        <input
                                            value={horarioSelect[
                                                indiceSelect
                                            ]?.turno[0].hora_inicio.substr(
                                                0,
                                                5
                                            )}
                                            onChange={(e) =>
                                                cambiarPersonas(
                                                    e.target.value,
                                                    "check-in",
                                                    0
                                                )
                                            }
                                            className="p-3  mt-2  border  rounded-lg outline-none cursor-pointer text-[#3C3C3C]"
                                            type="time"></input>
                                    </div>
                                </div>
                                <div className="grid w-9/12 mt-3 ">
                                    <label className=" text-base text-[#3C3C3C]">
                                        Check-out
                                    </label>

                                    <input
                                        value={horarioSelect[
                                            indiceSelect
                                        ]?.turno[0].hora_final.substr(0, 5)}
                                        onChange={(e) =>
                                            cambiarPersonas(
                                                e.target.value,
                                                "check-out",
                                                0
                                            )
                                        }
                                        className="p-3  mt-2  border  rounded-lg outline-none cursor-pointer text-[#3C3C3C]"
                                        type="time"></input>
                                </div>
                            </div>
                        ) : (
                            <>
                                <p className="mt-2 text-base font-bold ">
                                    Primer turno
                                </p>
                                <div className={"mt-1 grid grid-cols-2"}>
                                    <div>
                                        <div className="grid w-9/12 mt-3 ">
                                            <label className=" text-base text-[#3C3C3C]">
                                                Check-in
                                            </label>
                                            <input
                                                value={horarioSelect[
                                                    indiceSelect
                                                ]?.turno[0].hora_inicio.substr(
                                                    0,
                                                    5
                                                )}
                                                onChange={(e) =>
                                                    cambiarPersonas(
                                                        e.target.value,
                                                        "check-in",
                                                        0
                                                    )
                                                }
                                                className="p-3  mt-2  border  rounded-lg outline-none cursor-pointer text-[#3C3C3C]"
                                                type="time"></input>
                                        </div>
                                    </div>
                                    <div className="grid w-9/12 mt-3 ">
                                        <label className=" text-base text-[#3C3C3C]">
                                            Check-out
                                        </label>

                                        <input
                                            value={horarioSelect[
                                                indiceSelect
                                            ]?.turno[0].hora_final.substr(0, 5)}
                                            onChange={(e) =>
                                                cambiarPersonas(
                                                    e.target.value,
                                                    "check-out",
                                                    0
                                                )
                                            }
                                            className="p-3  mt-2  border  rounded-lg outline-none cursor-pointer text-[#3C3C3C]"
                                            type="time"></input>
                                    </div>
                                </div>
                                <div>
                                    <p
                                        className={
                                            agregar
                                                ? " text-base mt-2 font-bold"
                                                : "hidden"
                                        }>
                                        Segundo turno{" "}
                                        <span
                                            onClick={() => (
                                                setAgregar(false),
                                                cambiarPersonas(
                                                    "00:00",
                                                    "check-in",
                                                    1
                                                ),
                                                cambiarPersonas(
                                                    "00:00",
                                                    "check-out",
                                                    1
                                                )
                                            )}
                                            className="px-3 pb-1 font-normal border rounded-lg cursor-pointer text-blue-bluecorner border-blue-bluecorner">
                                            {" "}
                                            x{" "}
                                        </span>
                                    </p>
                                    <div
                                        className={
                                            agregar ||
                                            horarioSelect[indiceSelect]
                                                ?.turno[1].hora_inicio !==
                                                "00:00" ||
                                            horarioSelect[indiceSelect]
                                                ?.turno[1].hora_final !==
                                                "00:00"
                                                ? "mt-1 grid grid-cols-2"
                                                : "hidden"
                                        }>
                                        <div>
                                            <div className="grid w-9/12 mt-3 ">
                                                <label className=" text-base text-[#3C3C3C]">
                                                    Check-in
                                                </label>

                                                <input
                                                    value={horarioSelect[
                                                        indiceSelect
                                                    ]?.turno[1].hora_inicio.substr(
                                                        0,
                                                        5
                                                    )}
                                                    onChange={(e) =>
                                                        cambiarPersonas(
                                                            e.target.value,
                                                            "check-in",
                                                            1
                                                        )
                                                    }
                                                    className="p-3  mt-2  border  rounded-lg outline-none cursor-pointer text-[#3C3C3C]"
                                                    type="time"></input>
                                            </div>
                                        </div>
                                        <div className="grid w-9/12 mt-3 ">
                                            <label className=" text-base text-[#3C3C3C]">
                                                Check-out
                                            </label>

                                            <input
                                                value={horarioSelect[
                                                    indiceSelect
                                                ]?.turno[1].hora_final.substr(
                                                    0,
                                                    5
                                                )}
                                                onChange={(e) =>
                                                    cambiarPersonas(
                                                        e.target.value,
                                                        "check-out",
                                                        1
                                                    )
                                                }
                                                className="p-3  mt-2  border  rounded-lg outline-none cursor-pointer text-[#3C3C3C]"
                                                type="time"></input>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            !agregar ? "mt-4 " : "hidden"
                                        }>
                                        <button
                                            onClick={() => setAgregar(true)}
                                            className="flex items-center px-3 py-2 mx-auto text-lg border rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:text-white">
                                            <svg
                                                className="w-5 h-5 text-blue-bluecorner group-hover:text-white"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <line
                                                    x1="12"
                                                    y1="5"
                                                    x2="12"
                                                    y2="19"
                                                />{" "}
                                                <line
                                                    x1="5"
                                                    y1="12"
                                                    x2="19"
                                                    y2="12"
                                                />
                                            </svg>
                                            <span className="items-center text-base sm:block ">
                                                Agregar Turno
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    disabled={!siguiente}
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div>
            {/* <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div> */}
        </div>
    );
};

export default EditarTurnosEmbarcacion;
