const feeBC = {
    feeAlquiler: 0.1,
    feeAlquilerEmbarcacion: 0.25,
    feeServiciosAdicionales: 0.1,
    feeServiciosAdicionalesEmbarcaciones: 0.82,
    feeCostoTarjetaCasa: 0.05,
    feeCostoTarjetaEmbarcaciones: 0.05,
    propietarioAlquiler: 0.1,
    propietarioEmbarcacion: 0.1,
};

export default feeBC;
