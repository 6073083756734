import axios from "axios";
export const GET_ALL_PROPERTIE = "GET_ALL_PROPERTIE";
export const CREATE_PROPERTIE = "CREATE_PROPERTIE";
export const RESET_CREATE_PROPERTIE = "RESET_CREATE_PROPERTIE";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const ONE_PROPERTIE = "ONE_PROPERTIE";
export const RESET_ONE_PROPERTIE = "RESET_ONE_PROPERTIE";
export const SEARCH_LABELS = "SEARCH_LABELS";
export const ENABLE_PROPERTIE = "ENABLE_PROPERTIE";
export const RESET_ENEBLE_PROPERTIE = "RESET_ENEBLE_PROPERTIE";
export const CLEAR_PROPERTIES = "CLEAR_PROPERTIES";

export const createpropertie = (payload) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.post(
      `${process.env.REACT_APP_PROXY}/propiedades/`,
      payload,
      { headers }
    );

    return dispatch({
      type: CREATE_PROPERTIE,
      payload: json.data.message,
    });
  };
};

export const editPropertie = (id, payload) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.put(
      `${process.env.REACT_APP_PROXY}/propiedades/actualizar/${id}`,
      payload,
      { headers }
    );

    return dispatch({
      type: CREATE_PROPERTIE,
      payload: json.data.message,
    });
  };
};

export const getAllPropertie = (
  pageNumber = 1,
  enabled = "Si",
  tipo = "Todos"
) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.get(
      `${process.env.REACT_APP_PROXY}/propiedades/all/${pageNumber}/${enabled}/${tipo}`,
      { headers }
    );

    return dispatch({
      type: GET_ALL_PROPERTIE,
      payload: json.data,
    });
  };
};

export const searchLabelsByName = (enabled, input, completed) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.get(
      `${process.env.REACT_APP_PROXY}/propiedades/all/1/${enabled}/${completed}/?nombre=${input}`,
      { headers }
    );
    return dispatch({
      type: SEARCH_LABELS,
      payload: json.data,
    });
  };
};

export const resetCreatepropertie = () => {
  return {
    type: RESET_CREATE_PROPERTIE,
  };
};
export const resetEneblePropertie = () => {
  return {
    type: RESET_ENEBLE_PROPERTIE,
  };
};

export const deletePropertie = (id) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.delete(
      `${process.env.REACT_APP_PROXY}/propiedades/eliminar/${id}`,
      { headers }
    );
    return dispatch({
      type: ENABLE_PROPERTIE,
      payload: json.data.message,
    });
  };
};
export const enableproperties = (id) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.put(
      `${process.env.REACT_APP_PROXY}/propiedades/restaurar/${id}`,
      {},
      { headers }
    );

    return dispatch({
      type: ENABLE_PROPERTIE,
      payload: json.data.message,
    });
  };
};

export const onepropertie = (id) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.get(
      `${process.env.REACT_APP_PROXY}/propiedades/detalle/${id}`,
      { headers }
    );

    return dispatch({
      type: ONE_PROPERTIE,
      payload: json.data,
    });
  };
};
export const resetOnPropertie = () => {
  return async function (dispatch) {
    return dispatch({
      type: RESET_ONE_PROPERTIE,
      payload: [],
    });
  };
};

export const clearProperties = () => {
  return async function (dispatch) {
    return dispatch({
      type: CLEAR_PROPERTIES,
    });
  };
};
