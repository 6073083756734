import React from "react";
import logoBlueCorner from "../img/BLUECORNER_PNG.png";

const SpinnerVerifyToken = () => {
    return (
        <div className="lds-circle flex justify-center items-center h-screen">
            <img
                src={logoBlueCorner}
                alt="Bluecorner"
                className=" w-full max-w-lg "
                // motion-safe:animate-[spin_4s_linear_infinite]
            />
        </div>
    );
};

export default SpinnerVerifyToken;
