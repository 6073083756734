import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ToggleButton from "../../ToggleButton";

const EditarOferta = ({ producto, setProducto, setModal, tipo_producto }) => {
    const [precioAntes, setPrecioAntes] = useState(
        producto.data.oferta[0]?.descuento
            ? producto.data.oferta[0].descuento
            : ""
    );
    const [toggleButton, setToggleButton] = useState(
        producto.data.oferta[0]?.activado === "Si" ? true : false
    );
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleEdit = () => {};

    const handleGuardar = async () => {
        if (!toggleButton && !producto.data.oferta.length) {
            setModal(false);
            return;
        }
        // if (!toggleButton && producto.data.oferta.length) {
        //     const json = await axios.delete(
        //         `${process.env.REACT_APP_PROXY}/embarcacionesOfertas/eliminar/${producto.data.oferta[0]?.id}`,

        //         { headers }
        //     );

        //     console.log(json.data);
        //     setProducto({
        //         ...producto,
        //         data: {
        //             ...producto.data,
        //             oferta: [],
        //         },
        //     });
        //     setModal(false);
        //     return;
        // }
        if (precioAntes < 1) {
            Swal.fire({
                title: "La oferta debe tener un descuento para poder ser activada",
                icon: "warning",
            });
        } else {
            if (!producto.data.oferta.length) {
                const json = await axios.post(
                    `${process.env.REACT_APP_PROXY}/embarcacionesOfertas/`,
                    {
                        id_embarcacion: producto.data.id,
                        descuento: precioAntes,
                    },

                    { headers }
                );

                if (json.data.message === "registro exitoso") {
                    setProducto({
                        ...producto,
                        data: {
                            ...producto.data,
                            oferta: [json.data.data],
                        },
                    });
                    setModal(false);
                }
            } else {
                const json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/embarcacionesOfertas/actualizar/${producto.data.oferta[0]?.id}`,
                    {
                        activado: toggleButton ? "Si" : "No",
                        descuento: precioAntes,
                    },

                    { headers }
                );

                if (json.data.message === "actuaizacion exitoso") {
                    setProducto({
                        ...producto,
                        data: {
                            ...producto.data,
                            oferta: [json.data.data],
                        },
                    });
                    setModal(false);
                }
            }
        }
    };

    return (
        <div className="p-4 border rounded-lg">
            <div>
                <div>
                    <div className="flex items-center justify-between w-full">
                        <h1>Oferta</h1>
                        <div>
                            <div className="flex items-center mx-2">
                                <label className="mr-2 ">Descuento:</label>
                                <input
                                    disabled={!toggleButton}
                                    autoComplete="off"
                                    type="number"
                                    name="Precio tachado"
                                    onChange={(e) =>
                                        setPrecioAntes(e.target.value)
                                    }
                                    value={precioAntes}
                                    className="w-6/12 py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                    placeholder="Descuento"
                                />
                                <label className="ml-2 ">
                                    {/* {producto.data.moneda_precio_base ||
                                        producto.data.tipo_moneda} */}
                                    %
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <p className="mr-2">
                                {!toggleButton ? "Desactivada" : "Activada"}
                            </p>
                            <ToggleButton
                                isToggled={toggleButton}
                                onChangeToggle={(e) => setToggleButton(e)}
                            />
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarOferta;
