import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

const CRMModalEditEmpresas = ({
    selectEdit,
    setSelectEdit,
    setColum1,
    setColum2,
    setColum3,
    estado,
    colum1,
    colum2,
    colum3,
}) => {
    const [nuevoEstado, setNuevoEstado] = useState(estado);

    const handleSubmit = () => {
        if (estado === nuevoEstado) {
            setSelectEdit(null);
        } else {
            if (estado === "Seguimiento") {
                setColum1(colum1.filter((c) => c !== selectEdit));
                if (nuevoEstado === "Oferta enviada") {
                    setColum2([...colum2, selectEdit]);
                } else {
                    setColum3([...colum3, selectEdit]);
                }
            } else if (estado === "Oferta enviada") {
                setColum2(colum2.filter((c) => c !== selectEdit));
                if (nuevoEstado === "Seguimiento") {
                    setColum1([...colum1, selectEdit]);
                } else {
                    setColum3([...colum3, selectEdit]);
                }
            } else {
                setColum3(colum3.filter((c) => c !== selectEdit));
                if (nuevoEstado === "Seguimiento") {
                    setColum1([...colum1, selectEdit]);
                } else {
                    setColum2([...colum2, selectEdit]);
                }
            }
            setSelectEdit(null);
        }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed z-10 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-12 2xl:container 2xl:mx-auto  sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <header className="mb-3 mr-auto text-base font-bold lg:text-2xl mt-5 text-center">
                            ¿Quieres cambiar el estado de tu cliente?
                        </header>
                        <label className="w-full my-4 text-base text-left border-none outline-none ">
                            Selecciona el estado
                        </label>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <select
                                onChange={(e) => setNuevoEstado(e.target.value)}
                                defaultValue={estado}
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]">
                                <option
                                    className="text-[#3C3C3C]"
                                    value="Seguimiento">
                                    Seguimiento
                                </option>
                                <option
                                    className="text-[#3C3C3C]"
                                    value="Oferta enviada">
                                    Oferta enviada
                                </option>
                                <option
                                    className="text-[#3C3C3C]"
                                    value="Negocio cerrado">
                                    Negocio cerrado
                                </option>
                            </select>
                        </div>

                        <div className="w-full px-4">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="pb-3 text-center ">
                                            <button
                                                className="w-full rounded-2xl border-b-4 border-b-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                                                onClick={() => handleSubmit()}>
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => setSelectEdit(null)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CRMModalEditEmpresas;
