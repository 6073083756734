import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import ModalCambiarPassword from "./ModalCambiarPassword";
import Swal from "sweetalert2";
import { NavbarEmpresasNew } from "./NavbarEmpresasNew";
import { NavbarMobileEmpresasNew } from "./NavbarMobileEmpresasNew";

const PerfilEmpresa = () => {
    const [siguiente, setSiguiente] = useState("Guardar");
    const [preview, setPreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [adminInfo, setAdminInfo] = useState(null);
    const [modalCambiarPasswordVisible, setModalCambiarPasswordVisible] =
        useState(false);
    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    // const bancos = [
    //     "BBVA",
    //     "Banco de Comercio",
    //     "Banco de Crédito del Perú",
    //     "Banco Interamericano de Finanzas (BanBif)",
    //     "Banco Pichincha",
    //     "Citibank Perú",
    //     "Interbank",
    //     "MiBanco",
    //     "Scotiabank Perú",
    //     "Banco GNB Perú",
    //     "Banco Falabella",
    //     "Banco Ripley",
    //     "Banco Santander Perú",
    //     "Alfin Banco",
    //     "Bank of China",
    //     "ICBC PERU BANK",
    // ];

    const documento = ["DNI", "NIF", "RUC", "Pasaporte", "Cédula Extranjera"];

    const handleChangeImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(selectedFile);
            return;
        }
        setSelectedFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview(objectUrl);
    };

    const getAdminEmpresaInfo = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/adminEmpresas/detalle/${authUser.id_usuario}`,
            { headers }
        );

        setAdminInfo(data.data);
    };

    useEffect(() => {
        getAdminEmpresaInfo();
    }, []);

    const handleUpdate = async (e) => {
        e.preventDefault();
        const form = new FormData();

        form.append("correo", adminInfo.correo?.trim());
        form.append("nombre", adminInfo.nombre?.trim());
        form.append("apellido_paterno", adminInfo.apellido_paterno?.trim());
        form.append("apellido_materno", adminInfo.apellido_materno?.trim());
        form.append("tipo_documentacion", adminInfo.tipo_documentacion?.trim());
        form.append("dni", adminInfo.dni?.trim());
        form.append("celular", adminInfo.celular?.trim());
        form.append("direccion", adminInfo.direccion?.trim());
        form.append("avatar_usuario", selectedFile);

        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/adminEmpresas/actualizar/${authUser.id_usuario}`,
            form,
            { headers }
        );

        if (data.message === "actuaizacion exitoso") {
            Swal.fire({
                title: "Perfil actualizado correctamente",
                icon: "success",
            });
        } else {
            Swal.fire({
                title: "No se pudo actualizar el perfil",
                text: "Vuelva a intentarlo por favor",
                icon: "error",
            });
        }
    };

    return (
        <NavbarEmpresasNew>
            <div className="w-full">
                <h1 className="py-5 text-4xl text-left w-11/12 xs:w-8/12 mx-auto lg1170:px-20 text-[#3C3C3C]">
                    Mi perfil
                </h1>
                {adminInfo && (
                    <div className="mx-auto text-left text-[#3C3C3C]">
                        <div className="mt-2 tab-content" id="tabs-tabContent">
                            <div className="flex flex-col-reverse grid-cols-2 md:grid md:w-11/12 lg1170:w-8/12 md:mx-auto">
                                <div
                                    className="w-11/12 mx-auto tab-pane fade show active xs:w-8/12"
                                    id="tabs-home"
                                    role="tabpanel"
                                    aria-labelledby="tabs-home-tab"
                                >
                                    <div className="w-full mt-3">
                                        <label className="">
                                            Nombre
                                            <span className="text-xl text-red-600">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            value={adminInfo?.nombre}
                                            name="userName"
                                            onChange={(event) =>
                                                setAdminInfo({
                                                    ...adminInfo,
                                                    nombre: event.target.value,
                                                })
                                            }
                                            placeholder="Nombre"
                                            // disabled={editar ? false : true}
                                            className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none "
                                        />
                                    </div>
                                    <div className="w-full mt-3">
                                        <label className="">
                                            Apellido paterno
                                            <span className="text-xl text-red-600">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            value={adminInfo?.apellido_paterno}
                                            name="father_lastName"
                                            onChange={(event) =>
                                                setAdminInfo({
                                                    ...adminInfo,
                                                    apellido_paterno:
                                                        event.target.value,
                                                })
                                            }
                                            // disabled={editar ? false : true}
                                            placeholder="Apellido paterno"
                                            className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                        />
                                    </div>
                                    <div className="w-full mt-3">
                                        <label className="">
                                            Apellido materno{" "}
                                        </label>
                                        <input
                                            value={adminInfo?.apellido_materno}
                                            type="text"
                                            name="mother_lastName"
                                            onChange={(event) =>
                                                setAdminInfo({
                                                    ...adminInfo,
                                                    apellido_materno:
                                                        event.target.value,
                                                })
                                            }
                                            // disabled={editar ? false : true}
                                            placeholder="Apellido materno"
                                            className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                        />
                                    </div>
                                    <>
                                        <div className="w-full mt-3">
                                            <label className="">
                                                Tipo de documento
                                            </label>
                                            <select
                                                onChange={(event) =>
                                                    setAdminInfo({
                                                        ...adminInfo,
                                                        tipo_documentacion:
                                                            event.target.value,
                                                    })
                                                }
                                                name="userType"
                                                value={
                                                    adminInfo?.tipo_documentacion
                                                }
                                                className="w-full px-3 py-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                            >
                                                <option
                                                    className="bg-transparent"
                                                    value=""
                                                >
                                                    {" "}
                                                    Seleccionar{" "}
                                                </option>
                                                {documento.map((doc, i) => {
                                                    return (
                                                        <option
                                                            key={i}
                                                            className="bg-transparent"
                                                            value={doc}
                                                        >
                                                            {" "}
                                                            {doc}{" "}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="w-full mt-3">
                                            <label className="">
                                                Numero de documento{" "}
                                                <span className="text-xl text-red-600">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                type="text"
                                                value={adminInfo?.dni}
                                                name="userDni"
                                                onChange={(event) =>
                                                    setAdminInfo({
                                                        ...adminInfo,
                                                        dni: event.target.value,
                                                    })
                                                }
                                                // disabled={editar ? false : true}
                                                placeholder="Dni "
                                                className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                            />
                                        </div>
                                    </>
                                    <div className="w-full mt-3">
                                        <label className="">
                                            Correo electrónico{" "}
                                            <span className="text-xl text-red-600">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            value={adminInfo?.correo}
                                            name="userEmail"
                                            onChange={(event) =>
                                                setAdminInfo({
                                                    ...adminInfo,
                                                    correo: event.target.value,
                                                })
                                            }
                                            // disabled={editar ? false : true}
                                            placeholder="Correo electrónico"
                                            className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                        />
                                    </div>
                                    <div className="w-full mt-3">
                                        <label className="">
                                            Teléfono{" "}
                                            <span className="text-xl text-red-600">
                                                *
                                            </span>{" "}
                                        </label>
                                        <input
                                            // disabled={editar ? false : true}
                                            type="text"
                                            value={adminInfo?.celular}
                                            name="userCelular"
                                            onChange={(event) =>
                                                setAdminInfo({
                                                    ...adminInfo,
                                                    celular: event.target.value,
                                                })
                                            }
                                            placeholder="Celular"
                                            className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                        />
                                    </div>
                                    <div className="w-full mt-3">
                                        <label className="">
                                            Dirección{" "}
                                            <span className="text-xl text-red-600">
                                                *
                                            </span>{" "}
                                        </label>
                                        <input
                                            type="text"
                                            value={adminInfo?.direccion}
                                            name="userDireccion"
                                            onChange={(event) =>
                                                setAdminInfo({
                                                    ...adminInfo,
                                                    direccion:
                                                        event.target.value,
                                                })
                                            }
                                            // disabled={editar ? false : true}
                                            placeholder="Direccion de usuario"
                                            className="w-full p-3 my-3 mt-2 bg-transparent border rounded-lg outline-none focus:outline-none"
                                        />
                                    </div>
                                    <button
                                        className="w-full flex items-center justify-center mx-auto rounded-lg text-center py-3 text-blue-bluecorner border-blue-bluecorner hover:scale-105 border active:translate-y-[0.125rem]  my-4  disabled:bg-gray-400"
                                        onClick={() =>
                                            setModalCambiarPasswordVisible(
                                                !modalCambiarPasswordVisible
                                            )
                                        }
                                    >
                                        {modalCambiarPasswordVisible
                                            ? "Cancelar y no guardar"
                                            : "Cambiar contraseña"}
                                    </button>
                                    {modalCambiarPasswordVisible && (
                                        <ModalCambiarPassword
                                            setModalCambiarPasswordVisible={
                                                setModalCambiarPasswordVisible
                                            }
                                            modalCambiarPasswordVisible={
                                                modalCambiarPasswordVisible
                                            }
                                        />
                                    )}
                                    <button
                                        disabled={
                                            siguiente === "Guardar"
                                                ? false
                                                : true
                                        }
                                        className="w-full flex items-center justify-center mx-auto rounded-lg text-center  bg-blue-500 py-3  text-white hover:scale-105 active:translate-y-[0.125rem]  mt-3 mb-14 disabled:bg-gray-400"
                                        onClick={handleUpdate}
                                    >
                                        {siguiente}
                                    </button>
                                </div>
                                <div className="flex flex-col items-center justify-center w-full px-4 text-center h-max">
                                    <div className="relative flex flex-col select-none">
                                        <div className="relative">
                                            <input
                                                onChange={handleChangeImage}
                                                type="file"
                                                accept="image/*"
                                                className="absolute left-0 z-50 w-full h-full bg-red-200 rounded-full opacity-0 cursor-pointer select-none file:cursor-pointer"
                                            />
                                            {preview ? (
                                                <img
                                                    src={preview}
                                                    className="rounded-full p-[0.050rem] w-72 h-72 border-[3px] object-cover cursor-pointer border-blue-bluecorner"
                                                    alt="foto de perfil"
                                                />
                                            ) : (
                                                <img
                                                    src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${adminInfo.avatar}`}
                                                    className="rounded-full p-[0.050rem] w-72 h-72 border-[3px] object-cover cursor-pointer border-blue-bluecorner"
                                                    alt="foto de perfil"
                                                />
                                            )}
                                        </div>
                                        <span className="relative mt-2 cursor-pointer text-blue-bluecorner">
                                            Cambiar foto de perfil{" "}
                                            <span className="text-xl text-red-600">
                                                *
                                            </span>
                                            <input
                                                onChange={handleChangeImage}
                                                type="file"
                                                className="absolute left-0 z-50 h-full bg-red-200 opacity-0 cursor-pointer select-none file:cursor-pointer"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <NavbarMobileEmpresasNew />
        </NavbarEmpresasNew>
    );
};

export default PerfilEmpresa;
