import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import { useNavigate, useParams } from "react-router-dom";
import ReservaDetailed from "../ReservaDetailed";
import getTotalAditionalServices from "../getTotalAditionalServices";
import TotalPriceAndIGV from "../TotalPriceAndIGV";
import { useSelector } from "react-redux";
import formatedDate_YYYY_MM_DD from "../../helpers/formatedDate_YYYY_MM_DD";
import Spinner from "../../Spinner";
import Swal from "sweetalert2";
import { loadStripe } from "@stripe/stripe-js";
import PagoCompleto from "./PagoCompleto";
import PagoParcial from "./PagoParcial";
import ModalLogeo from "../../alquilerScreen/alquilerAlojamiento/ModalLogeo";
import puntosDetalles from "../../helpers/puntosDetalles";
import getPriceAditionalService from "../../helpers/getTotalPrice/getPriceAditionalService";
import colores from "../../helpers/colores";
import ModalPolitica from "./ModalPolitica";
import { PRECIO_SOLES } from "../../helpers/precio_soles";
import priceToMonedaElegida from "../../helpers/priceToMonedaElegida";

const Checkout = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const { idReserva } = useParams();
    const [ticketVisible, setTicketVisible] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [pagoCompleto, setPagoCompleto] = useState("pago_completo");
    const [isPrimerPagoBc, setIsPrimerPagoBc] = useState(false);
    const [montoPrimerpago, setMontoPrimerpago] = useState(0);
    const [paying, setPaying] = useState(false);
    const [formaPago, setFormaPago] = useState("stripe");
    const [impuestoIGV, setImpuestoIGV] = useState(false);
    const reserva = JSON.parse(localStorage.getItem("reserva"));
    console.log(reserva);
    const [pedido, setPedido] = useState({});
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.authReducer);
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );
    const [totalReservaFinal, setTotalReservaFinal] = useState(0);
    const [cuponCargandoText, setCuponCargandoText] = useState("Aplicar cupón");
    const [costoAsumidoEmpresa, setCostoAsumidoEmpresa] = useState(null);
    const [idcuponEmpresa, setIdcuponEmpresa] = useState("");

    const [totalCostoAsumidoEmpresa, setTotalCostoAsumidoEmpresa] =
        useState(null);
    const [descuentoCupon, setDescuentoCupon] = useState(null);
    const [cupon, setCupon] = useState("");
    const [comprobantePreview, setComprobantePreview] = useState(null);
    const [comprobante, setComprobante] = useState(null);
    const [habilitarModal, setHabilitarModal] = useState(false);
    const [modalLogeo, setModalLogeo] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [datosCliente, setDatosCliente] = useState(null);
    const form = new FormData();

    const getReserva = async (idReserva) => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/pedidoCasa/detalle/${idReserva}`,
            { headers }
        );

        setPedido(data);
    };
    const onepropertie = async (id) => {
        if (authUser.tipo_usuario < 3) {
            const jsonAdmin = await axios.get(
                `${process.env.REACT_APP_PROXY}/autenticacion/detalle/${authUser.id_usuario}`,
                { headers }
            );

            setDatosCliente(jsonAdmin.data.data);
        } else if (authUser.tipo_usuario === 6 || authUser.tipo_usuario === 7) {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/adminEmpresas/detalle/${authUser.id_usuario}`,
                { headers }
            );

            setDatosCliente(data.data);
        } else {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}${
                    authUser.tipo_usuario === 4
                        ? "/subpropietario/detalle/"
                        : authUser.tipo_usuario === 3
                        ? "/datosCliente/detalle/"
                        : "/cliente/detalle/"
                }${authUser.id_usuario}`,
                { headers }
            );

            setDatosCliente(json.data.data);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);

        if (idReserva) getReserva(idReserva);
    }, [idReserva]);

    useEffect(() => {
        onepropertie();
    }, [modalLogeo]);

    const priceToReservaCoin = (monto, tipo_moneda) => {
        let price = monto;

        if (reserva.coin_base === "PEN") {
            if (tipo_moneda === "PEN") return price;
            else price = price * PRECIO_SOLES.venta;
        } else {
            if (tipo_moneda === "PEN") price = price / PRECIO_SOLES.compra;
            else return price;
        }
        return Number(price);
    };

    const getTotalCostoAsumidoEmpresa = (costo_total) => {
        let total_costo_asumido = 0;

        if (costoAsumidoEmpresa) {
            const cantidad_cupon = costoAsumidoEmpresa.costo_asumido;
            //CASO 1: CUPON ES MUY GRANDE SE ADAPTA AL COSTO TOTAL, EL CLIENTE NO ABONA NADA
            if (cantidad_cupon >= costo_total) {
                total_costo_asumido = costo_total;
            } else if (cantidad_cupon < costo_total) {
                total_costo_asumido = cantidad_cupon;
            }
        }

        return total_costo_asumido;
    };

    const handleReserva = async () => {
        if (authUser.logged === false) {
            setHabilitarModal(true);
            setModalLogeo(true);
            return;
        }
        if (!idReserva && !pedido.id) {
            const fee_cliente = reserva.feeBC_cliente;
            const fee_propietario = reserva.feeBC_propietario;
            const token = localStorage.getItem("token");
            const headers = {
                authorization: token,
            };
            const array_extras = [];
            const array_servicios_adicionales = [];

            form.append("correo", datosCliente.correo?.trim());
            form.append("nombre", datosCliente.nombre?.trim());
            form.append(
                "apellido_paterno",
                datosCliente.apellido_paterno?.trim()
            );
            form.append(
                "apellido_materno",
                datosCliente.apellido_materno?.trim()
            );
            form.append(
                "tipo_documentacion",
                datosCliente.tipo_documentacion?.trim()
            );
            form.append("dni", datosCliente.dni?.trim());
            form.append("celular", datosCliente.celular?.trim());
            form.append("direccion", datosCliente.direccion?.trim());
            form.append("avatar_usuario", datosCliente.avatar?.trim());

            setTermsAccepted((prev) => !prev);

            if (authUser.tipo_usuario > 2 && datosCliente !== null) {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}${
                        authUser.tipo_usuario === 4
                            ? "/subpropietario/actualizar/"
                            : authUser.tipo_usuario === 3
                            ? "/propietario/actualizar/"
                            : "/cliente/actualizar/"
                    }${authUser.id_usuario}`,
                    form,
                    { headers }
                );
            } else if (
                authUser.tipo_usuario === 6 ||
                authUser.tipo_usuario === 7
            ) {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}/adminEmpresas/actualizar/${authUser.id_usuario}`,
                    form,
                    { headers }
                );
            }

            const total_servicios_adicionales = getTotalAditionalServices(
                reserva?.aditional_services,
                reserva.cantDays,
                [reserva.fecha_inicio, reserva.fecha_final],
                reserva.currentProduct
            );

            let costo_total = impuestoIGV
                ? Number(
                      reserva?.initialReserva_final +
                          Math.ceil(
                              Number(reserva?.initialReserva_final * 0.18)
                          ) +
                          Number(
                              getTotalAditionalServices(
                                  reserva?.aditional_services,
                                  reserva.cantDays,
                                  [reserva.fecha_inicio, reserva.fecha_final]
                              )
                          ) +
                          Number(reserva?.warranty.split(" ")[0])
                  )
                : Number(reserva?.totalPrice);
            //--------------------ARRAY EXTRAS-------------------------//

            if (reserva?.discountDays.price < 0) {
                array_extras.push({
                    tipo: "-",
                    texto: reserva?.discountDays.descripcion,
                    costo: reserva?.discountDays.price,
                    fee_cliente: reserva?.discountDays.fee_cliente,
                    fee_propietario: reserva?.discountDays.fee_propietario,
                    costo_total: reserva?.discountDays.price_total,
                });
            } else if (reserva?.discountDays.price > 0) {
                array_extras.push({
                    tipo: "+",
                    texto: reserva?.discountDays.descripcion,
                    costo: reserva?.discountDays.price,
                    fee_cliente: reserva?.discountDays.fee_cliente,
                    fee_propietario: reserva?.discountDays.fee_propietario,
                    costo_total: reserva?.discountDays.price_total,
                });
            }

            if (reserva?.discountSpecialDays.precio > 0) {
                array_extras.push({
                    tipo: "+",
                    texto: reserva?.discountSpecialDays.descripcion,
                    costo: reserva?.discountSpecialDays.precio,
                    fee_cliente: reserva?.discountSpecialDays.fee_cliente,
                    fee_propietario:
                        reserva?.discountSpecialDays.fee_propietario,
                    costo_total: reserva?.discountSpecialDays.precio_total,
                });
            } else if (reserva?.discountSpecialDays.precio < 0) {
                array_extras.push({
                    tipo: "-",
                    texto: reserva?.discountSpecialDays.descripcion,
                    costo: reserva?.discountSpecialDays.precio,
                    fee_cliente: reserva?.discountSpecialDays.fee_cliente,
                    fee_propietario:
                        reserva?.discountSpecialDays.fee_propietario,
                    costo_total: reserva?.discountSpecialDays.precio_total,
                });
            }

            //--------------------ARRAY EXTRAS-------------------------//

            //--------------------SERVICIOS ADICIONALES-------------------------//

            reserva?.aditional_services.forEach((service) => {
                let precio = 0;
                let precio_avanzado = 0;
                let precioExtra = 0;

                const dia_inicio = new Date(reserva.fecha_inicio);
                const dia_final = new Date(reserva.fecha_final);

                if (service.avanzado.length > 0) {
                    let coincidencia_avanzada = service.avanzado.find(
                        (service_avanzado) => {
                            service_avanzado.fecha_inicio = new Date(
                                service_avanzado.fecha_inicio
                            );
                            service_avanzado.fecha_final = new Date(
                                service_avanzado.fecha_final
                            );

                            if (
                                (service_avanzado.fecha_inicio <= dia_inicio &&
                                    service_avanzado.fecha_final >=
                                        dia_inicio) ||
                                (service_avanzado.fecha_inicio <= dia_final &&
                                    service_avanzado.fecha_final >=
                                        dia_final) ||
                                (dia_inicio <= service_avanzado.fecha_inicio &&
                                    dia_final >=
                                        service_avanzado.fecha_inicio) ||
                                (dia_inicio <= service_avanzado.fecha_final &&
                                    dia_final >= service_avanzado.fecha_final)
                            ) {
                                return service_avanzado;
                            }
                        }
                    );

                    if (coincidencia_avanzada) {
                        precio_avanzado = coincidencia_avanzada.precio;
                    }
                }
                if (reserva.currentProduct.tipo_estadia === "noche") {
                    reserva.cantDays--;
                }
                if (service.intervalo_dias > 0 && precio_avanzado === 0) {
                    precioExtra =
                        parseInt(reserva.cantDays / service.intervalo_dias) *
                        service.intervalo_precio;
                    precioExtra += service.precio;
                } else {
                    precioExtra = precio_avanzado;
                }

                const precio_con_fee = Number(
                    getPriceAditionalService(
                        service,
                        reserva.cantDays,
                        [reserva.fecha_inicio, reserva.fecha_final],
                        reserva.currentProduct
                    )
                );

                if (precioExtra) {
                    precio = priceToMonedaElegida(
                        precioExtra,
                        service.tipo_moneda
                    );
                } else {
                    precio = priceToMonedaElegida(
                        service.precio,
                        service.tipo_moneda
                    );
                }

                let costo_fee_bc = Number((precio_con_fee - precio).toFixed(2));

                array_servicios_adicionales.push({
                    nombre: service.nombre,
                    costo: priceToReservaCoin(precio, service.tipo_moneda),
                    costo_fee_bc: priceToReservaCoin(
                        costo_fee_bc,
                        service.tipo_moneda
                    ),
                    costo_total: priceToReservaCoin(
                        precio + costo_fee_bc,
                        service.tipo_moneda
                    ),
                });
            });

            costo_total =
                costo_total -
                reserva.descuento_oferta -
                (descuentoCupon
                    ? descuentoCupon.descuento_bc_cupon +
                      descuentoCupon.descuento_propietario_cupon
                    : 0);

            //--------------------FIN SERVICIOS ADICIONALES-------------------------//
            let id_referido = 0;

            if (reserva.clientReferido) {
                id_referido = reserva.clientReferido.id;
            }

            const reservaFinal = {
                realizado_bc: reserva.realizado_bc,
                huespedes: reserva.huespedes,
                numero_ninos: reserva.numero_ninos,
                numero_adultos: reserva.numero_adultos,
                id_cliente:
                    reserva.realizado_bc === "Si"
                        ? reserva.realizado_bc_cliente.id_cliente
                        : authUser.id_usuario,
                correo_cliente:
                    reserva.realizado_bc === "Si"
                        ? reserva.realizado_bc_cliente.correo_cliente
                        : authUser.correo,
                tipo_usuario_cliente:
                    reserva.realizado_bc === "Si"
                        ? reserva.realizado_bc_cliente.tipo_cliente
                        : authUser.tipo_usuario,
                nombre_casa: reserva?.house,
                id_casa: reserva?.id_casa,
                fecha_inicio: formatedDate_YYYY_MM_DD(
                    new Date(reserva?.fecha_inicio)
                ),
                fecha_final: formatedDate_YYYY_MM_DD(
                    new Date(reserva?.fecha_final)
                ),
                costo_alquiler: reserva?.priceOnlyAlquiler,
                costo_fee_bc: fee_cliente,
                costo_fee_bc_propietario: fee_propietario,
                costo_igv: impuestoIGV
                    ? Math.ceil(Number(reserva?.initialReserva_final * 0.18))
                    : 0,
                costo_total_alquiler: impuestoIGV
                    ? reserva?.initialReserva_final +
                      Math.ceil(Number(reserva?.initialReserva_final * 0.18)) -
                      (descuentoCupon
                          ? descuentoCupon.descuento_bc_cupon +
                            descuentoCupon.descuento_propietario_cupon
                          : 0)
                    : reserva?.initialReserva_final -
                      (descuentoCupon
                          ? descuentoCupon.descuento_bc_cupon +
                            descuentoCupon.descuento_propietario_cupon
                          : 0),
                costo_total: costo_total,
                servicios_adicionales: array_servicios_adicionales,
                costo_total_servicios_adicionales: total_servicios_adicionales,
                costo_garantia: Number(reserva?.warranty.split(" ")[0]),
                costo_tarjeta: 0,
                pagado: 0,
                saldo: costo_total,
                tipo_moneda: reserva?.coin_base,
                extras: array_extras,
                descuento_por_puntos: reserva.points_discount
                    ? reserva.total_points_discount
                    : 0,
                posibles_puntos: reserva.points_discount
                    ? 0
                    : reserva.puntos_obtenidos,
                puntos_canjeados: reserva.points_discount
                    ? reserva.puntos_canjeados
                    : 0,
                id_cupon: descuentoCupon ? descuentoCupon.id : 0,
                descuento_propietario_cupon: descuentoCupon
                    ? descuentoCupon.descuento_propietario_cupon
                    : 0,
                descuento_bc_cupon: descuentoCupon
                    ? descuentoCupon.descuento_bc_cupon
                    : 0,
                descuento_empresa: reserva?.descuento_empresa,
                costo_asumido_empresa: getTotalCostoAsumidoEmpresa(costo_total),
                descuento_oferta: reserva?.descuento_oferta,
                id_promocion_empresa: idcuponEmpresa,
                id_referido,
            };

            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/pedidoCasa`,
                reservaFinal,
                { headers }
            ); // GENERO LA RESERVA EN LA DB

            if (data.message === "Registro exitoso") {
                localStorage.removeItem("reserva");
                navigate("/comprobando-disponibilidad");
            } else {
                Swal.fire({
                    title: "Hubo un error en el registro, intente de nuevo por favor",
                    icon: "error",
                });
            }
        }
    };

    const handleComprobante = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        setComprobante(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setComprobantePreview(objectUrl);
    };

    const handlePagoComprobante = async () => {
        setPaying(true);
        let costo_tarjeta = 0;

        let costo = 0;

        let estado = 0;
        let metodo = 0;
        let file = 0;

        if (pagoCompleto === "pago_parcial") {
            if (isPrimerPagoBc) {
                costo = montoPrimerpago;
            } else {
                costo = pedido?.pagos.primer_pago.monto;
            }
            costo_tarjeta = 0;

            estado = "Pago parcial";
            metodo = "Transferencia bancaria";
            file = comprobante;
        } else if (pagoCompleto === "pago_completo") {
            costo_tarjeta = 0;
            console.log(pedido);
            costo =
                pedido.estado === "Pendiente de pago"
                    ? pedido.pagos.segundo_pago.monto
                    : pedido.estado === "Pago parcial"
                    ? pedido.pagos.segundo_pago.monto
                    : null;

            estado = "Pago completo";
            metodo = "Transferencia bancaria";
            file = comprobante;
        }

        form.append("id", pedido.id);
        form.append("costo_tarjeta", costo_tarjeta);

        form.append("costo", costo);

        form.append("estado", estado);
        form.append("metodo", metodo);
        form.append("image_casa_pago", file);

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/pedidoCasa/realizarPagoTemporal`,
                form,
                { headers }
            );

            if (data.message === "Pago temporal guardado") {
                navigate(`/pago_aprobado/comprobante/${pedido.id}`);
            }
        } catch (error) {
            Swal.fire({
                title: error.response.data,
                icon: "error",
            });
            setPaying(false);
        }
    };

    const handleInputChange = (e) => setCupon(e.target.value);

    const handleCupon = async () => {
        if (authUser.logged === false) {
            setHabilitarModal(true);
            setModalLogeo(true);
            return;
        }

        setCuponCargandoText(null);

        if (authUser.tipo_usuario > 5) {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/promoEmpresas/getCupon`,
                {
                    cupon: cupon,
                    id_cliente:
                        reserva.realizado_bc === "Si"
                            ? reserva.realizado_bc_cliente.id_cliente
                            : authUser.id_usuario,
                },
                { headers }
            );
            console.log(data);
            if (data.err) {
                setDescuentoCupon(null);
                Swal.fire({
                    title: "No se pudo aplicar el cupón",
                    text: `${data.err}`,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            } else {
                setCostoAsumidoEmpresa({
                    ...data.data,
                    costo_asumido: priceToReservaCoin(
                        data.data.costo_asumido,
                        data.data.tipo_moneda
                    ),
                });
                setIdcuponEmpresa(data.data.id);
                Swal.fire({
                    title: "Cupón aplicado con exito",
                    text: `Su empresa asumirá una suma de hasta ${priceToReservaCoin(
                        data.data.costo_asumido,
                        data.data.tipo_moneda
                    )} ${reserva?.coin_base}`,
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
            }
        } else {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/casaCupones/getCupon`,
                {
                    cupon: cupon,
                    id_casa: reserva.id_casa,
                    id_cliente:
                        reserva.realizado_bc === "Si"
                            ? reserva.realizado_bc_cliente.id_cliente
                            : authUser.id_usuario,
                },
                { headers }
            );
            if (data.err) {
                setDescuentoCupon(null);
                Swal.fire({
                    title: "No se pudo aplicar el cupón",
                    text: `${data.err}`,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            } else {
                // setDescuentoCupon(data.data);
                setDescuentoCupon({
                    ...data.data,
                    descuento_propietario_cupon: priceToReservaCoin(
                        data.data.descuento_propietario,
                        data.data.tipo_moneda
                    ),
                    descuento_bc_cupon: priceToReservaCoin(
                        data.data.descuento_bc,
                        data.data.tipo_moneda
                    ),
                });
                Swal.fire({
                    title: "Cupón aplicado con exito",
                    text: `Obtuviste un descuento de ${priceToReservaCoin(
                        data.data.descuento_bc +
                            data.data.descuento_propietario,
                        data.data.tipo_moneda
                    )} ${reserva?.coin_base}`,
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
            }
        }

        setCuponCargandoText("Aplicar cupón");
    };

    if (
        pedido.id &&
        pedido.estado !== "Pendiente de pago" &&
        pedido.estado !== "Pago parcial"
    ) {
        return (
            <div>
                <NavBar />
                <div
                    className={`${
                        authUser.tipo_usuario !== null &&
                        authUser.tipo_usuario <= 2
                            ? "pt-40"
                            : "pt-32"
                    } flex flex-col  select-none`}>
                    <div className={`bg-[${colores.primario}] text-center`}>
                        <h1 className="py-10 text-4xl font-bold text-white">
                            Checkout
                        </h1>
                    </div>
                    <div className="p-36">
                        <div className="p-10 text-xl border-8 border-dotted md:text-center md:text-3xl">
                            Esta reserva ya ha sido procesado y los pagos están
                            completos, muchas gracias por su reserva.
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    } else
        return (
            <div>
                <NavBar />
                <div className="absolute w-full ">
                    {habilitarModal && (
                        <ModalLogeo
                            modalLogeo={modalLogeo}
                            setModalLogeo={setModalLogeo}
                        />
                    )}
                    {modalVisible && (
                        <ModalPolitica
                            setModalVisible={setModalVisible}
                            modalVisible={modalVisible}
                            politica={reserva?.cancelacion}
                            porcentaje={reserva?.porcentaje_moderado}
                        />
                    )}
                </div>
                {idReserva ? (
                    pedido.id ? (
                        <div
                            className={`${
                                authUser.tipo_usuario !== null &&
                                authUser.tipo_usuario <= 2
                                    ? "pt-40"
                                    : "pt-32"
                            } flex flex-col  select-none`}>
                            <div
                                className={`bg-[${colores.primario}] text-center`}>
                                <h1 className="py-10 text-4xl font-bold text-white">
                                    Checkout
                                </h1>
                            </div>
                            <div className="p-3 mx-auto sm:p-10 lg:w-4/5">
                                {pagoCompleto === "pago_completo" ? (
                                    <PagoCompleto
                                        pedido={pedido}
                                        formaPago={formaPago}
                                        setFormaPago={setFormaPago}
                                        stripePromise={stripePromise}
                                        comprobantePreview={comprobantePreview}
                                        handleComprobante={handleComprobante}
                                        handlePagoComprobante={
                                            handlePagoComprobante
                                        }
                                        pagoCompleto={pagoCompleto}
                                        setPagoCompleto={setPagoCompleto}
                                        paying={paying}
                                    />
                                ) : (
                                    <PagoParcial
                                        pedido={pedido}
                                        formaPago={formaPago}
                                        setFormaPago={setFormaPago}
                                        stripePromise={stripePromise}
                                        comprobantePreview={comprobantePreview}
                                        handleComprobante={handleComprobante}
                                        handlePagoComprobante={
                                            handlePagoComprobante
                                        }
                                        pagoCompleto={pagoCompleto}
                                        setPagoCompleto={setPagoCompleto}
                                        setIsPrimerPagoBc={setIsPrimerPagoBc}
                                        setMontoPrimerpago={setMontoPrimerpago}
                                        paying={paying}
                                        isPrimerPagoBc={isPrimerPagoBc}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-screen mt-40">
                            <Spinner width={20} />
                        </div>
                    )
                ) : reserva ? (
                    <div
                        className={`${
                            authUser.tipo_usuario !== null &&
                            authUser.tipo_usuario <= 2
                                ? "pt-40"
                                : "pt-32"
                        } flex flex-col  select-none`}>
                        <div className={`bg-[${colores.primario}] text-center`}>
                            <h1 className="py-10 text-4xl font-bold text-white">
                                Checkout
                            </h1>
                        </div>
                        <div className="p-3 mx-auto sm:p-10 lg:w-4/5">
                            {authUser.tipo_usuario < 6 && (
                                <>
                                    <div
                                        className={`flex items-center bg-slate-100 p-5 text-xl mt-10 border-t-4 border-[${colores.secundario}]`}>
                                        <p className="flex w-full">
                                            <svg
                                                className="h-8 w-8 min-w-[32px] text-black flex mr-2"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                                                />
                                            </svg>
                                            <span>
                                                ¿Tienes un cupón?
                                                <span
                                                    className={`text-[${colores.secundario}] cursor-pointer mx-1`}
                                                    onClick={() =>
                                                        setTicketVisible(
                                                            (prev) => !prev
                                                        )
                                                    }>
                                                    Haz click aquí para
                                                    introducir tu código
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                    {ticketVisible && (
                                        <div className="flex flex-col p-4 mt-5 border">
                                            <h1 className="text-lg">
                                                Si tienes un código de cupón,
                                                por favor, aplícalo abajo.
                                            </h1>
                                            <div className="grid grid-cols-2 md:grid-cols-[80%_20%]  gap-4 mt-5">
                                                <input
                                                    type="text"
                                                    name="cupon"
                                                    value={cupon}
                                                    onChange={handleInputChange}
                                                    placeholder="Código de cupón"
                                                    className="p-2 border border-orange-700"
                                                />
                                                <button
                                                    onClick={handleCupon}
                                                    className={`bg-[${colores.primario}] mx-2 text-white font-semibold p-2 min-w-[64px] active:translate-y-1`}>
                                                    {cuponCargandoText || (
                                                        <Spinner width={7} />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {authUser.tipo_usuario > 5 && (
                                <>
                                    <div
                                        className={`flex items-center bg-slate-100 p-5 text-xl mt-10 border-t-4 border-[${colores.secundario}]`}>
                                        <p className="flex w-full">
                                            <svg
                                                className="h-8 w-8 min-w-[32px] text-black flex mr-2"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                                                />
                                            </svg>
                                            <span>
                                                ¿Tienes un cupón?
                                                <span
                                                    className={`text-[${colores.secundario}] cursor-pointer mx-1`}
                                                    onClick={() =>
                                                        setTicketVisible(
                                                            (prev) => !prev
                                                        )
                                                    }>
                                                    Haz click aquí para
                                                    introducir tu código
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                    {ticketVisible && (
                                        <div className="flex flex-col p-4 mt-5 border">
                                            <h1 className="text-lg">
                                                Si tienes un código de cupón,
                                                por favor, aplícalo abajo.
                                            </h1>
                                            <div className="grid grid-cols-2 md:grid-cols-[80%_20%]  gap-4 mt-5">
                                                <input
                                                    type="text"
                                                    name="cupon"
                                                    value={cupon}
                                                    onChange={handleInputChange}
                                                    placeholder="Código de cupón"
                                                    className="p-2 border border-orange-700"
                                                />
                                                <button
                                                    onClick={handleCupon}
                                                    className={`bg-[${colores.primario}] mx-2 text-white font-semibold p-2 min-w-[64px] active:translate-y-1`}>
                                                    {cuponCargandoText || (
                                                        <Spinner width={7} />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            <h1
                                className={`mt-20  text-[${colores.primario}] text-2xl font-semibold`}>
                                Tu pedido
                            </h1>
                            <div
                                className={`text-[${colores.primario}] font-semibold text-base md:grid md:grid-cols-[50%_50%] flex flex-col-reverse`}>
                                <div className="p-10">
                                    <div>
                                        <div className="p-3 border-t-2 border-gray-600 rounded-t-lg border-x-2">
                                            Pago Único:{" "}
                                            <p className="text-gray-500">
                                                Realiza el pago total de tu
                                                reserva.
                                            </p>
                                        </div>
                                        <div className="p-3 border-2 border-gray-600 rounded-b-lg">
                                            Pago en dos partes:{" "}
                                            <p className="p-1 text-gray-500">
                                                <p className="p-1 text-gray-500">
                                                    Realiza ahora el pago de la
                                                    mitad del aquiler que
                                                    representa{" "}
                                                    {impuestoIGV
                                                        ? (reserva?.initialReserva_final +
                                                              Math.ceil(
                                                                  Number(
                                                                      reserva?.initialReserva_final *
                                                                          0.18
                                                                  )
                                                              )) /
                                                          2
                                                        : reserva?.initialReserva_final /
                                                          2}{" "}
                                                    {reserva.coin_base} y 7 días
                                                    antes del check in (
                                                    {
                                                        reserva?.fecha_inicio.split(
                                                            "T"
                                                        )[0]
                                                    }
                                                    ) realiza el pago pendiente
                                                    junto con la garantía y
                                                    servicios adicionales.
                                                </p>
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={`my-20 rounded-lg border-2 border-gray-600 text-[${colores.primario}] p-5 text-xl`}>
                                        <h1>
                                            Déjanos tu número de celular para
                                            poder contactar y completar el
                                            proceso de alquiler
                                        </h1>
                                        <hr className="my-5 border-t-2 border-gray-300" />
                                        {!impuestoIGV && (
                                            <p className="text-base">
                                                EL PRECIO TOTAL NO INCLUYE IGV
                                            </p>
                                        )}
                                        <p className="text-base">
                                            Pago con tarjeta de crédito/débito
                                            cuenta con un costo adicional del 5%
                                            del monto a pagar.
                                        </p>
                                        <div className="flex mt-5 text-xs">
                                            <span>
                                                <input
                                                    type="checkbox"
                                                    className="mr-2 outline-none cursor-pointer"
                                                    onChange={(e) =>
                                                        setTermsAccepted(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                                He leído y estoy de acuerdo con
                                                los
                                                <a
                                                    className={`text-[${colores.secundario}] mx-1`}
                                                    href="/terminos-y-condiciones"
                                                    target="_blank">
                                                    Términos y Condiciones
                                                </a>
                                                ,
                                                <a
                                                    className={`text-[${colores.secundario}] mx-1`}
                                                    href="politicas-y-privacidad"
                                                    target="_blank">
                                                    Políticas de uso y
                                                    privacidad
                                                </a>{" "}
                                                y{" "}
                                                <span
                                                    onClick={() =>
                                                        setModalVisible(true)
                                                    }
                                                    className={`text-[${colores.secundario}] mx-1 cursor-pointer`}>
                                                    Politica de cancelacion de
                                                    la propiedad
                                                </span>{" "}
                                                <span className="text-2xl text-red-500">
                                                    *
                                                </span>
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                authUser.logged
                                                    ? "w-full mt-3"
                                                    : "hidden"
                                            }>
                                            <hr className="my-3"></hr>
                                            <label className="">
                                                Teléfono{" "}
                                                <span className="text-red-600 ">
                                                    *
                                                </span>{" "}
                                            </label>
                                            <input
                                                // disabled={editar ? false : true}
                                                type="number"
                                                value={
                                                    datosCliente?.celular || ""
                                                }
                                                name="userCelular"
                                                onChange={(event) =>
                                                    setDatosCliente({
                                                        ...datosCliente,
                                                        celular:
                                                            event.target.value,
                                                    })
                                                }
                                                placeholder="Celular"
                                                className="w-full p-3 my-3 mt-2 bg-transparent border border-blue-500 rounded-lg outline-none focus:outline-none"
                                            />
                                        </div>
                                        <button
                                            id="comprobar_disponibilidad"
                                            className={`border-[${colores.secundario}] bg-[${colores.secundario}] border hover:bg-white hover:text-[${colores.secundario}] disabled:bg-gray-400 disabled:border-white disabled:text-white disabled:cursor-not-allowed text-white font-semibold p-3 rounded-md mt-10 mb-6 text-base w-full lg:w-64 lg:ml-auto flex justify-center`}
                                            disabled={
                                                !termsAccepted ||
                                                datosCliente?.celular?.length <
                                                    5
                                            }
                                            onClick={() => handleReserva()}>
                                            {authUser.logged
                                                ? "Comprobar disponibilidad"
                                                : "Iniciar sección o registrarse"}
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {reserva.clientReferido && (
                                        <div className="flex flex-col items-center justify-center w-3/4 gap-4 p-4 mx-10 border-2 border-blue-500 rounded-lg">
                                            <div className="flex items-start justify-center w-full">
                                                ¡Reserva realizada referida por{" "}
                                                <span className="ml-1">
                                                    {
                                                        reserva.clientReferido
                                                            .nombre
                                                    }{" "}
                                                    {
                                                        reserva.clientReferido
                                                            .apellido
                                                    }{" "}
                                                </span>
                                                !
                                            </div>{" "}
                                            <div className="flex items-center justify-center gap-4">
                                                <div></div>
                                                <div className="flex flex-col items-center justify-center">
                                                    <div className="flex items-center justify-center">
                                                        <div className="font-semibold text-center">
                                                            Recuerda que debes
                                                            concretar el pago
                                                            para que la persona
                                                            que te ha referido
                                                            se vea beneficiada.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="p-10">
                                        <table className="w-full border-2 border-gray-600 md:mt-1 bordered">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="p-4 uppercase border-2 border-gray-600"
                                                        colSpan={2}>
                                                        Producto
                                                    </th>
                                                    <th
                                                        className="p-4 uppercase border-2 border-gray-600"
                                                        colSpan={2}>
                                                        Subtotal
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="p-4 border-2 border-gray-600"
                                                        colSpan={3}>
                                                        <div className="flex flex-auto">
                                                            <ReservaDetailed />
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="p-2 border-2 border-gray-600"
                                                        colSpan={1}>
                                                        <span className="flex h-24 font-semibold ">
                                                            {costoAsumidoEmpresa
                                                                ? reserva?.initialReserva
                                                                : reserva?.initialReserva}{" "}
                                                            {reserva?.coin_base}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {reserva?.descuento_oferta >
                                                    0 && (
                                                    <tr className="text-green-500 h-14">
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}>
                                                            <h3>Descuento</h3>
                                                        </td>
                                                        <td
                                                            className="p-2 border-2 border-gray-600"
                                                            colSpan={1}>
                                                            <h3 className="text-green-500 h-14">
                                                                -{" "}
                                                                {
                                                                    reserva.descuento_oferta
                                                                }{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                )}
                                                {descuentoCupon && (
                                                    <tr className="text-green-500 h-14">
                                                        <td
                                                            className="border-2 border-gray-600"
                                                            colSpan={3}>
                                                            <h3>
                                                                Descuento
                                                                utilizando cupón
                                                            </h3>
                                                        </td>
                                                        <td
                                                            className="border-2 border-gray-600 "
                                                            colSpan={1}>
                                                            <h3 className="text-green-500 h-14">
                                                                -{" "}
                                                                {descuentoCupon.descuento_bc_cupon +
                                                                    descuentoCupon.descuento_propietario_cupon}{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                )}
                                                {reserva?.discountDays.price !==
                                                    0 && (
                                                    <tr>
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}>
                                                            <h3 className="text-green-500 h-14">
                                                                {
                                                                    reserva
                                                                        ?.discountDays
                                                                        .descripcion
                                                                }
                                                            </h3>
                                                        </td>
                                                        <td
                                                            className="p-2 border-2 border-gray-600"
                                                            colSpan={1}>
                                                            <h3 className="text-green-500 h-14">
                                                                {
                                                                    reserva
                                                                        ?.discountDays
                                                                        .price_total
                                                                }{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                )}
                                                {reserva?.discountSpecialDays
                                                    .precio !== 0 && (
                                                    <tr>
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}>
                                                            <h3 className="text-green-500 h-14">
                                                                {
                                                                    reserva
                                                                        ?.discountSpecialDays
                                                                        .descripcion
                                                                }
                                                            </h3>
                                                        </td>
                                                        <td
                                                            className="p-2 border-2 border-gray-600"
                                                            colSpan={1}>
                                                            <h3 className="text-green-500 h-14">
                                                                {
                                                                    reserva
                                                                        ?.discountSpecialDays
                                                                        .precio_total
                                                                }{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                )}

                                                {reserva?.descuento_empresa >
                                                    0 && (
                                                    <tr className="text-green-500 h-14">
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}>
                                                            <h3>
                                                                Descuento de{" "}
                                                                {
                                                                    authUser.nombre_empresa
                                                                }
                                                            </h3>
                                                        </td>
                                                        <td
                                                            className="p-2 border-2 border-gray-600"
                                                            colSpan={1}>
                                                            <h3 className="text-green-500 h-14">
                                                                -{" "}
                                                                {
                                                                    reserva.descuento_empresa
                                                                }{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                )}
                                                {reserva?.points_discount && (
                                                    <tr className="text-green-500 h-14">
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}>
                                                            <h3>
                                                                Descuento por
                                                                referidos
                                                            </h3>
                                                        </td>
                                                        <td
                                                            className="p-2 border-2 border-gray-600"
                                                            colSpan={1}>
                                                            <h3 className="text-green-500 h-14">
                                                                -{" "}
                                                                {
                                                                    reserva.total_points_discount
                                                                }{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr className="text-green-500 ">
                                                    <td
                                                        className="p-4 border-2 border-gray-600"
                                                        colSpan={3}>
                                                        <div className="flex items-baseline">
                                                            <input
                                                                type="checkbox"
                                                                className="mr-2 outline-none cursor-pointer"
                                                                onChange={(e) =>
                                                                    setImpuestoIGV(
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                            />
                                                            <h5>
                                                                Desea boleta o
                                                                factura?
                                                                (Opcional)
                                                                Incremento por
                                                                IGV de 18%
                                                            </h5>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="p-2 border-2 border-gray-600"
                                                        colSpan={1}>
                                                        {!impuestoIGV ? (
                                                            <h3 className="text-green-600 h-14">
                                                                {"0.00"}{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        ) : (
                                                            <h3 className="text-green-600 h-14">
                                                                {Math.ceil(
                                                                    Number(
                                                                        reserva?.initialReserva_final *
                                                                            0.18
                                                                    )
                                                                )}{" "}
                                                                {
                                                                    reserva?.coin_base
                                                                }
                                                            </h3>
                                                        )}
                                                    </td>
                                                </tr>
                                                {reserva?.aditional_services
                                                    .length > 0 && (
                                                    <tr>
                                                        <td
                                                            className="p-4 border-2 border-gray-600"
                                                            colSpan={3}>
                                                            {reserva?.aditional_services.map(
                                                                (service) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                service.id
                                                                            }
                                                                            className="flex justify-between ">
                                                                            <span>
                                                                                {
                                                                                    service.nombre
                                                                                }

                                                                                :{" "}
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </td>
                                                        <td
                                                            className="p-2 border-2 border-gray-600"
                                                            colSpan={1}>
                                                            {reserva?.aditional_services.map(
                                                                (service) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                service.id
                                                                            }>
                                                                            <span>
                                                                                {getPriceAditionalService(
                                                                                    service,
                                                                                    reserva.cantDays,
                                                                                    [
                                                                                        reserva.fecha_inicio,
                                                                                        reserva.fecha_final,
                                                                                    ],
                                                                                    reserva.currentProduct
                                                                                )}{" "}
                                                                                {
                                                                                    reserva?.coin_base
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td
                                                        className="p-4 border-2 border-gray-600"
                                                        colSpan={3}>
                                                        <h3>Garantía</h3>
                                                    </td>
                                                    <td
                                                        className="p-2 border-2 border-gray-600"
                                                        colSpan={1}>
                                                        <h3>
                                                            {reserva.warranty}
                                                        </h3>
                                                    </td>
                                                </tr>
                                                <tr
                                                    className={`${
                                                        !costoAsumidoEmpresa &&
                                                        "bg-blue-bluecorner text-white"
                                                    }`}>
                                                    <td
                                                        className={` p-4 border-2 border-gray-600`}
                                                        colSpan={3}>
                                                        <h3>Total </h3>
                                                    </td>
                                                    <td
                                                        className="p-2 border-2 border-gray-600"
                                                        colSpan={1}>
                                                        <h3>
                                                            <TotalPriceAndIGV
                                                                descuentoCupon={
                                                                    descuentoCupon
                                                                }
                                                                impuestoIGV={
                                                                    impuestoIGV
                                                                }
                                                                reserva={
                                                                    reserva
                                                                }
                                                                descuento_oferta={
                                                                    reserva.descuento_oferta
                                                                }
                                                                setTotalReservaFinal={
                                                                    setTotalReservaFinal
                                                                }
                                                            />
                                                        </h3>
                                                    </td>
                                                </tr>
                                                {costoAsumidoEmpresa && (
                                                    <>
                                                        <tr>
                                                            <td
                                                                className="p-4 border-2 border-gray-600"
                                                                colSpan={3}>
                                                                <h3 className="p-3"></h3>
                                                            </td>
                                                            <td
                                                                className="p-2 border-2 border-gray-600"
                                                                colSpan={1}>
                                                                {" "}
                                                            </td>
                                                        </tr>
                                                        <tr className="">
                                                            <td
                                                                className="p-4 text-green-500 border-2 border-gray-600"
                                                                colSpan={3}>
                                                                <h3>
                                                                    Cupón
                                                                    empresa{" "}
                                                                    {
                                                                        authUser.nombre_empresa
                                                                    }{" "}
                                                                </h3>
                                                            </td>
                                                            <td
                                                                className="p-2 text-green-500 border-2 border-gray-600"
                                                                colSpan={1}>
                                                                <h3 className="">
                                                                    -{" "}
                                                                    {getTotalCostoAsumidoEmpresa(
                                                                        totalReservaFinal
                                                                    )}{" "}
                                                                    {
                                                                        reserva?.coin_base
                                                                    }
                                                                </h3>
                                                            </td>
                                                        </tr>

                                                        <tr className="text-white bg-blue-bluecorner">
                                                            <td
                                                                className="p-4 border-2 border-gray-600"
                                                                colSpan={3}>
                                                                <h3>
                                                                    Total a
                                                                    abonar
                                                                </h3>
                                                            </td>
                                                            <td
                                                                className="p-2 border-2 border-gray-600"
                                                                colSpan={1}>
                                                                <h3 className="">
                                                                    {totalReservaFinal -
                                                                        getTotalCostoAsumidoEmpresa(
                                                                            totalReservaFinal
                                                                        )}{" "}
                                                                    {
                                                                        reserva?.coin_base
                                                                    }
                                                                </h3>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className={`${
                            authUser.tipo_usuario !== null &&
                            authUser.tipo_usuario <= 2
                                ? "pt-40"
                                : "pt-32"
                        } flex flex-col  select-none`}>
                        <div className={`bg-[${colores.primario}] text-center`}>
                            <h1 className="py-10 text-4xl font-bold text-white">
                                Checkout
                            </h1>
                        </div>
                        <div className="p-36">
                            <div className="p-10 text-xl border-8 border-dotted md:text-center md:text-3xl">
                                No posee ninguna reserva pendiente
                            </div>
                        </div>
                    </div>
                )}
                <Footer showWspText={true} />
            </div>
        );
};

export default Checkout;
