import addDaysToDate from "../addDaysToDate";
import feeBC from "../feeBC";
import priceToMonedaElegida from "../priceToMonedaElegida";

const getPriceAditionalService = (
    service,
    cantDays,
    selectedDays,
    currentProduct
) => {
    let newSelectedDays;

    if (currentProduct?.tipo_estadia === "noche") {
        // if (true) {

        newSelectedDays = [selectedDays[0], addDaysToDate(selectedDays[1], -1)];
        cantDays--;
    } else {
        newSelectedDays = [selectedDays[0], selectedDays[1]];
    }

    const dia_inicio = new Date(newSelectedDays[0]);
    const dia_final = new Date(newSelectedDays[1]);

    let precioTotal = 0;
    let precio_avanzado = 0;
    let precioExtra = 0;

    if (service.avanzado.length > 0) {
        let coincidencia_avanzada = service.avanzado.find(
            (service_avanzado) => {
                service_avanzado.fecha_inicio = new Date(
                    service_avanzado.fecha_inicio
                );
                service_avanzado.fecha_final = new Date(
                    service_avanzado.fecha_final
                );

                if (
                    (service_avanzado.fecha_inicio <= dia_inicio &&
                        service_avanzado.fecha_final >= dia_inicio) ||
                    (service_avanzado.fecha_inicio <= dia_final &&
                        service_avanzado.fecha_final >= dia_final) ||
                    (dia_inicio <= service_avanzado.fecha_inicio &&
                        dia_final >= service_avanzado.fecha_inicio) ||
                    (dia_inicio <= service_avanzado.fecha_final &&
                        dia_final >= service_avanzado.fecha_final)
                ) {
                    return service_avanzado;
                }
            }
        );

        if (coincidencia_avanzada) {
            precio_avanzado = coincidencia_avanzada.precio;
        }
    }

    if (service.intervalo_dias > 0 && precio_avanzado === 0) {
        precioExtra =
            parseInt(cantDays / service.intervalo_dias) *
            service.intervalo_precio;
    }

    if (precio_avanzado > 0) {
        precioTotal =
            (precio_avanzado + precioExtra) /
            (1 - feeBC.feeServiciosAdicionales);
    } else {
        precioTotal =
            (service.precio + precioExtra) /
            (1 - feeBC.feeServiciosAdicionales);
    }

    return priceToMonedaElegida(precioTotal, service.tipo_moneda);
};

export default getPriceAditionalService;
