import getPricesFromDates from "../helpers/getTotalPrice/getPriceFromDates";
import getPriceFromDays from "../helpers/getTotalPrice/getPriceFromDays";
import getPriceFromMonths from "../helpers/getTotalPrice/getPriceFromMonths";
import addDaysToDate from "../helpers/addDaysToDate";

import priceToMonedaElegida from "../helpers/priceToMonedaElegida";
import getFeeByDay from "../helpers/getFeeByDay";
import getPricesFromCalendar from "../helpers/getTotalPrice/getPriceFromCalendar";

const getFeeBC = (selectedDays, currentProduct, cantDays) => {
    let fee_bc = 0;
    let is_numero_dias_total = false;
    let suma_fee_cliente = 0;
    let fee_cliente_siempre = 0;
    let tiene_fee_por_fechas = false;

    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    let newSelectedDays = [];
    if (currentProduct.tipo_estadia === "noche") {
        newSelectedDays = [selectedDays[0], addDaysToDate(selectedDays[1], -1)];
        cantDays--;
    } else {
        newSelectedDays = [selectedDays[0], selectedDays[1]];
    }

    const allDatesSelected = getEveryDayBetween2Dates(newSelectedDays);

    let dia_inicio = new Date(selectedDays[0])
        .toLocaleDateString("es-ES", options)
        .split(",")[0];
    let dia_final = new Date(selectedDays[1])
        .toLocaleDateString("es-ES", options)
        .split(",")[0];

    /********************* Array de dias exactos SIEMPRE y POR FECHAS    */

    if (!is_numero_dias_total) {
        let rangoEncontrado = {};
        const cumpleCondicion =
            currentProduct.precios_variables.arrayRangoExactoDiasTotal.find(
                (range) => {
                    if (range.tipo_tiempo === "Por fechas") {
                        if (
                            range.dia_inicio.toLowerCase() ===
                                dia_inicio.toLowerCase() &&
                            range.dia_final.toLowerCase() ===
                                dia_final.toLowerCase()
                        ) {
                            const FDI = new Date(
                                range.fecha_inicio + "T00:00:00"
                            );
                            const FDF = new Date(
                                range.fecha_final + "T00:00:00"
                            );
                            const FRI = selectedDays[0];
                            const FRF = selectedDays[1];
                            if (
                                (FDI <= FRI && FDF >= FRI) ||
                                (FDI <= FRF && FDF >= FRF) ||
                                (FRI <= FDI && FRF >= FDI) ||
                                (FRI <= FDF && FRF >= FDF)
                            ) {
                                return range;
                            }
                        }
                    }
                }
            );

        if (cumpleCondicion) {
            rangoEncontrado = cumpleCondicion;
        } else {
            rangoEncontrado =
                currentProduct.precios_variables.arrayRangoExactoDiasTotal.find(
                    (range) =>
                        range.dia_inicio.toLowerCase() ===
                            dia_inicio.toLowerCase() &&
                        range.dia_final.toLowerCase() ===
                            dia_final.toLowerCase() &&
                        range.tipo_tiempo === "Siempre"
                );
        }

        if (rangoEncontrado && cantDays <= 7) {
            is_numero_dias_total = true;
            fee_bc = rangoEncontrado.precio;

            const precio_por_dia = fee_bc / cantDays;

            allDatesSelected.forEach((date, index) => {
                const { fee_cliente, tiene_fee_por_fecha } = getFeeByDay(
                    date,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario,
                    precio_por_dia
                );

                suma_fee_cliente += fee_cliente;

                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
            });
        }
    }

    /********************* Array de dias exactos SIEMPRE y POR FECHAS    */

    /********************* Array de numero de dias SIEMPRE y POR FECHAS    */
    if (!is_numero_dias_total) {
        const cumpleCondicionNumberDays =
            currentProduct.precios_variables.arrayNumeroDiasTotal.find(
                (rango) => {
                    let newCantDays = cantDays;
                    if (currentProduct.tipo_estadia === "noche") {
                        newCantDays = cantDays + 1;
                    }
                    if (Number(rango.numero_dias) === newCantDays) {
                        if (rango.tipo_tiempo === "Por fechas") {
                            const FDI = new Date(
                                rango.fecha_inicio + "T00:00:00"
                            );
                            const FDF = new Date(
                                rango.fecha_final + "T00:00:00"
                            );
                            const FRI = selectedDays[0];
                            const FRF = selectedDays[1];
                            if (
                                (FDI <= FRI && FDF >= FRI) ||
                                (FDI <= FRF && FDF >= FRF) ||
                                (FRI <= FDI && FRF >= FDI) ||
                                (FRI <= FDF && FRF >= FDF)
                            ) {
                                return rango;
                            }
                        }
                    }
                }
            );

        if (cumpleCondicionNumberDays) {
            is_numero_dias_total = true;
            fee_bc = cumpleCondicionNumberDays.precio;

            const precio_por_dia = fee_bc / cantDays;

            allDatesSelected.forEach((date, index) => {
                const { fee_cliente, tiene_fee_por_fecha } = getFeeByDay(
                    date,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario,
                    precio_por_dia
                );

                suma_fee_cliente += fee_cliente;

                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
            });
        } else {
            currentProduct.precios_variables.arrayNumeroDiasTotal.forEach(
                (value) => {
                    let newCantDays = cantDays;
                    if (currentProduct.tipo_estadia === "noche") {
                        newCantDays = cantDays + 1;
                    }
                    if (Number(value.numero_dias) === newCantDays) {
                        if (value.tipo_tiempo === "Siempre") {
                            is_numero_dias_total = true;

                            fee_bc = value.precio;

                            const precio_por_dia = fee_bc / cantDays;

                            allDatesSelected.forEach((date, index) => {
                                const { fee_cliente, tiene_fee_por_fecha } =
                                    getFeeByDay(
                                        date,
                                        currentProduct.feeCasas,
                                        currentProduct.fee_cliente,
                                        currentProduct.fee_propietario,
                                        precio_por_dia
                                    );

                                suma_fee_cliente += fee_cliente;

                                if (tiene_fee_por_fecha) {
                                    tiene_fee_por_fechas = true;
                                }
                            });
                        }
                    }
                }
            );
        }
    }

    /********************* Array de numero de dias SIEMPRE y POR FECHAS    */

    if (!is_numero_dias_total) {
        allDatesSelected.forEach((date) => {
            if (
                getPricesFromDates(
                    date,
                    currentProduct.precios_variables.arrayFechas,
                    currentProduct.moneda_precio_base,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario
                ).hasProperty
            ) {
                const { total, fee_cliente, tiene_fee_por_fecha } =
                    getPricesFromDates(
                        date,
                        currentProduct.precios_variables.arrayFechas,
                        currentProduct.moneda_precio_base,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario
                    );
                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                return (fee_bc += total), (suma_fee_cliente += fee_cliente);
            } else if (
                getPriceFromDays(
                    date,
                    currentProduct.precios_variables.arrayDias,
                    currentProduct.moneda_precio_base,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario
                ).hasProperty
            ) {
                const { total, fee_cliente, tiene_fee_por_fecha } =
                    getPriceFromDays(
                        date,
                        currentProduct.precios_variables.arrayDias,
                        currentProduct.moneda_precio_base,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario
                    );
                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                return (fee_bc += total), (suma_fee_cliente += fee_cliente);
            } else if (
                getPriceFromMonths(
                    date,
                    currentProduct.precios_variables.arrayMeses,
                    currentProduct.moneda_precio_base,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario
                ).hasProperty
            ) {
                const { total, fee_cliente, tiene_fee_por_fecha } =
                    getPriceFromMonths(
                        date,
                        currentProduct.precios_variables.arrayMeses,
                        currentProduct.moneda_precio_base,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario
                    );
                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                return (fee_bc += total), (suma_fee_cliente += fee_cliente);
            } else {
                const { total, fee_cliente, tiene_fee_por_fecha } =
                    getPricesFromCalendar(
                        date,
                        currentProduct.precio_base,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario
                    );
                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                return (fee_bc += total), (suma_fee_cliente += fee_cliente);
            }
        });
    }

    if (tiene_fee_por_fechas) {
        fee_cliente_siempre += suma_fee_cliente;
    } else if (!tiene_fee_por_fechas) {
        const cumpleCondicion = currentProduct.feeCasas.find((range) => {
            if (range.tipo_tiempo === "Dias") {
                if (
                    range.dia_inicio.toLowerCase() ===
                        dia_inicio.toLowerCase() &&
                    range.dia_final.toLowerCase() === dia_final.toLowerCase()
                ) {
                    return range;
                }
            }
        });

        if (cumpleCondicion) {
            fee_cliente_siempre +=
                fee_bc / (1 - cumpleCondicion.fee_cliente / 100) - fee_bc;
        } else {
            fee_cliente_siempre += suma_fee_cliente;
        }
    }

    const { huespedes } = currentProduct;
    dia_inicio = new Date(selectedDays[0]);

    dia_final = new Date(selectedDays[1]);

    let rango = currentProduct.personas_adicionales.find((range) => {
        if (
            huespedes <= range.rango_persona_final &&
            huespedes >= range.rango_persona_inicio
        ) {
            if (range.tipo_tiempo === "Fecha") {
                range.fecha_inicio = new Date(range.fecha_inicio);
                range.fecha_final = new Date(range.fecha_final);

                if (
                    (range.fecha_inicio <= dia_inicio &&
                        range.fecha_final >= dia_inicio) ||
                    (range.fecha_inicio <= dia_final &&
                        range.fecha_final >= dia_final) ||
                    (dia_inicio <= range.fecha_inicio &&
                        dia_final >= range.fecha_inicio) ||
                    (dia_inicio <= range.fecha_final &&
                        dia_final >= range.fecha_final)
                ) {
                    return range;
                }
            }
        }
    });

    if (!rango) {
        const dia_inicio = new Date(selectedDays[0])
            .toLocaleDateString("es-ES", options)
            .split(",")[0];
        const dia_final = new Date(selectedDays[1])
            .toLocaleDateString("es-ES", options)
            .split(",")[0];

        rango = currentProduct.personas_adicionales.find((range) => {
            if (
                huespedes <= range.rango_persona_final &&
                huespedes >= range.rango_persona_inicio
            ) {
                if (range.tipo_tiempo === "Días de la semana") {
                    if (
                        range.dia_inicio.toLowerCase() ===
                            dia_inicio.toLowerCase() &&
                        range.dia_final.toLowerCase() ===
                            dia_final.toLowerCase()
                    ) {
                        return range;
                    }
                }
            }
        });
    }

    if (!rango) {
        rango = currentProduct.personas_adicionales.find((range) => {
            if (
                huespedes <= range.rango_persona_final &&
                huespedes >= range.rango_persona_inicio
            ) {
                if (range.tipo_tiempo === "Siempre") {
                    return range;
                }
            }
        });
    }

    if (rango) {
        const dia_inicio = new Date(selectedDays[0])
            .toLocaleDateString("es-ES", options)
            .split(",")[0];
        const dia_final = new Date(selectedDays[1])
            .toLocaleDateString("es-ES", options)
            .split(",")[0];
        let total_personas = 0;
        let total_fee_personas_cliente = 0;

        if (rango.tipo === "por estadia") {
            total_personas = rango.precio;
            const precio_por_dia = rango.precio / cantDays;

            allDatesSelected.forEach((date) => {
                const { fee_cliente, tiene_fee_por_fecha } = getFeeByDay(
                    date,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario,
                    precio_por_dia
                );
                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                total_fee_personas_cliente += fee_cliente;
            });

            if (tiene_fee_por_fechas) {
                fee_cliente_siempre += total_fee_personas_cliente;
            } else if (!tiene_fee_por_fechas) {
                const cumpleCondicion = currentProduct.feeCasas.find(
                    (range) => {
                        if (range.tipo_tiempo === "Dias") {
                            if (
                                range.dia_inicio.toLowerCase() ===
                                    dia_inicio.toLowerCase() &&
                                range.dia_final.toLowerCase() ===
                                    dia_final.toLowerCase()
                            ) {
                                return range;
                            }
                        }
                    }
                );

                if (cumpleCondicion) {
                    fee_cliente_siempre +=
                        total_personas /
                            (1 - cumpleCondicion.fee_cliente / 100) -
                        total_personas;
                } else {
                    fee_cliente_siempre += total_fee_personas_cliente;
                }
            }
        } else {
            total_personas = rango.precio * cantDays;
            const precio_por_dia = rango.precio;

            allDatesSelected.forEach((date) => {
                const { fee_cliente, tiene_fee_por_fecha } = getFeeByDay(
                    date,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario,
                    precio_por_dia
                );
                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                total_fee_personas_cliente += fee_cliente;
            });

            if (tiene_fee_por_fechas) {
                fee_cliente_siempre += total_fee_personas_cliente;
            } else if (!tiene_fee_por_fechas) {
                const cumpleCondicion = currentProduct.feeCasas.find(
                    (range) => {
                        if (range.tipo_tiempo === "Dias") {
                            if (
                                range.dia_inicio.toLowerCase() ===
                                    dia_inicio.toLowerCase() &&
                                range.dia_final.toLowerCase() ===
                                    dia_final.toLowerCase()
                            ) {
                                return range;
                            }
                        }
                    }
                );

                if (cumpleCondicion) {
                    fee_cliente_siempre +=
                        total_personas /
                            (1 - cumpleCondicion.fee_cliente / 100) -
                        total_personas;
                } else {
                    fee_cliente_siempre += total_fee_personas_cliente;
                }
            }
        }
    }

    let combo_parrilla_inicio_1 = new Date("2023-06-29T00:00:00");
    let combo_parrilla_final_1 = new Date("2023-07-02T00:00:00");

    let combo_parrilla_inicio_2 = new Date("2023-07-27T00:00:00");
    let combo_parrilla_final_2 = new Date("2023-07-30T00:00:00");

    if (
        (selectedDays[0] > combo_parrilla_inicio_1 &&
            selectedDays[0] <= combo_parrilla_final_1) ||
        (selectedDays[1] > combo_parrilla_inicio_1 &&
            selectedDays[1] <= combo_parrilla_final_1) ||
        (selectedDays[0] <= combo_parrilla_inicio_1 &&
            selectedDays[1] >= combo_parrilla_final_1)
    ) {
        if (currentProduct.moneda_precio_base === "PEN") {
            fee_cliente_siempre += 222;
        } else {
            fee_cliente_siempre += 60;
        }
    } else if (
        (selectedDays[0] > combo_parrilla_inicio_2 &&
            selectedDays[0] <= combo_parrilla_final_2) ||
        (selectedDays[1] > combo_parrilla_inicio_2 &&
            selectedDays[1] <= combo_parrilla_final_2) ||
        (selectedDays[0] <= combo_parrilla_inicio_2 &&
            selectedDays[1] >= combo_parrilla_final_2)
    ) {
        if (currentProduct.moneda_precio_base === "PEN") {
            fee_cliente_siempre += 222;
        } else {
            fee_cliente_siempre += 60;
        }
    } else if (currentProduct.id === 24049) {
        if (currentProduct.moneda_precio_base === "PEN") {
            fee_cliente_siempre += 222;
        } else {
            fee_cliente_siempre += 60;
        }
    }

    let fee_bluecorner = {
        fee_cliente: priceToMonedaElegida(
            fee_cliente_siempre,
            currentProduct.moneda_precio_base,
            true
        ),
        fee_propietario: 0,
    };

    return fee_bluecorner;
};

const getEveryDayBetween2Dates = (arrayFechas) => {
    const [startDate, endDate] = arrayFechas.map((fecha) => new Date(fecha));
    const date = new Date(startDate.getTime());
    const dates = [];

    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
};

export default getFeeBC;
