import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../../Spinner";
import ModalAddFee from "./ModalAddFee";
import ModalEditFee from "./ModalEditFee";

const EditarFee = ({ producto, setProducto, setModal, tipo_producto }) => {
    const [input, setInput] = useState({
        fee_propietario: producto.data?.fee_propietario || 10,
        fee_cliente: producto.data?.fee_cliente || 10,
    });
    const [modalAddFeeVisible, setModalAddFeeVisible] = useState(false);
    const [modalEditFeeVisible, setModalEditFeeVisible] = useState(false);
    const [feeDeleted, setFeeDeleted] = useState(false);
    const [allFees, setAllFees] = useState([]);
    const [feeSelected, setfeeSelected] = useState(null);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const alert = (alert) => {
        Swal.fire({
            title: `Debes completar el campo de: ${alert}`,
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Completar!",
        });
    };

    const getFees = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/casaFee/all/${producto.data.id}`,
            { headers }
        );

        setAllFees(data.data);
    };

    useEffect(() => {
        getFees();
    }, [modalAddFeeVisible, modalEditFeeVisible, feeDeleted]);

    const updateProduct = async (payload) => {
        console.log(payload);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${producto.data.tipo_producto}/updateFeeBluecorner/${producto.data.id}`,
            payload,
            { headers }
        );

        if (data.message === "Actualizado con exito") {
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    ...payload,
                },
            });
            setModal(false);
        }
    };

    const handleGuardar = () => {
        const payload = {
            fee_propietario: input.fee_propietario,
            fee_cliente: input.fee_cliente,
        };
        if (payload.fee_propietario === "") alert("fee propietario");
        else if (payload.fee_propietario === "") alert("fee propietario");
        else updateProduct(payload);
    };

    const handleDelete = (feeId) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminar el fee?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/casaFee/eliminar/${feeId}`,
                        { headers }
                    )
                    .then((res) => setFeeDeleted(!feeDeleted));
            }
        });
    };

    return (
        <div className="p-4 border rounded-lg">
            <div className="w-full mt-3 ">
                <div className="w-1/2 ">
                    <div className="w-32 mt-2 ">
                        <label className=" w-full text-base text-[#3C3C3C]">
                            Fee cliente
                        </label>
                    </div>

                    <div className="flex items-center justify-center w-32 px-3 py-3 border-2 rounded-lg">
                        <input
                            autoComplete="off"
                            className="w-full rounded-lg outline-none"
                            placeholder="15"
                            name="fee_cliente"
                            type="number"
                            step=".01"
                            onChange={handleInputChange}
                            value={input.fee_cliente}
                        ></input>
                        <span>
                            <svg
                                className="w-4 h-4 text-[#3C3C3C] rounded-lg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="19" y1="5" x2="5" y2="19" />{" "}
                                <circle cx="6.5" cy="6.5" r="2.5" />{" "}
                                <circle cx="17.5" cy="17.5" r="2.5" />
                            </svg>
                        </span>
                    </div>
                </div>
                <button
                    className="p-3 mt-4 border rounded-lg text-blue-bluecorner border-blue-bluecorner"
                    onClick={() => setModalAddFeeVisible(!modalAddFeeVisible)}
                >
                    Añadir Fee Avanzado
                </button>
                {modalAddFeeVisible && (
                    <ModalAddFee
                        tipo_producto={tipo_producto}
                        producto={producto}
                        modalVisible={modalAddFeeVisible}
                        setModalVisible={setModalAddFeeVisible}
                    />
                )}
                {modalEditFeeVisible && feeSelected && (
                    <ModalEditFee
                        tipo_producto={tipo_producto}
                        producto={producto}
                        modalVisible={modalEditFeeVisible}
                        setModalVisible={setModalEditFeeVisible}
                        feeSelected={feeSelected}
                    />
                )}
                {allFees.length > 0 ? (
                    allFees.map((fee) => (
                        <div key={fee.id} className="flex justify-between mt-4">
                            {fee.tipo_tiempo === "Dias" && (
                                <div>
                                    <p>Fee cliente: {fee.fee_cliente}%</p>
                                    {/* <p>
                                        Fee propietario: {fee.fee_propietario}%
                                    </p> */}
                                    <p>
                                        Desde el {fee.dia_inicio} hasta el{" "}
                                        {fee.dia_final}
                                    </p>
                                </div>
                            )}
                            {fee.tipo_tiempo === "Fecha" && (
                                <div>
                                    <div>
                                        <p>Fee cliente: {fee.fee_cliente}%</p>
                                        {/* <p>
                                            Fee propietario:{" "}
                                            {fee.fee_propietario}%
                                        </p> */}
                                        <p>
                                            Desde el {fee.fecha_inicio} hasta el{" "}
                                            {fee.fecha_final}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className="flex">
                                <span
                                    className="p-1 mx-2 border rounded cursor-pointer border-blue-bluecorner h-fit"
                                    onClick={() => {
                                        setfeeSelected(fee);
                                        setModalEditFeeVisible(
                                            !modalEditFeeVisible
                                        );
                                    }}
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-bluecorner"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                        <line x1="16" y1="5" x2="19" y2="8" />
                                    </svg>
                                </span>
                                <span
                                    className="p-1 border rounded cursor-pointer border-blue-bluecorner h-fit"
                                    onClick={() => {
                                        handleDelete(fee.id);
                                    }}
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="4" y1="7" x2="20" y2="7" />{" "}
                                        <line x1="10" y1="11" x2="10" y2="17" />{" "}
                                        <line x1="14" y1="11" x2="14" y2="17" />{" "}
                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    ))
                ) : (
                    <Spinner width={7} margin_top={7} />
                )}
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7"
                >
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarFee;
