import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ModalVariablePricesBoats from "./ModalVariablePricesBoats";
import ModalEditVariablePricesBoats from "./ModalEditVariablePricesBoats";
import Spinner from "../../Spinner";

const VariablePricesBoats = ({
    onboarding,
    setOnboarding,
    setPage,
    actualPage,
}) => {
    const [siguiente, setSiguiente] = useState("Siguiente");

    const [duraciones, setDuraciones] = useState([]);
    const [variables, setVariables] = useState([]);
    const [duracionSelect, setDuracionSelect] = useState("");
    const [editDuracionSelect, setEditDuracionSelect] = useState("");
    const [createVisible, setCreateVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);

    const [fakeRefresh, setFakeRefresh] = useState(false);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        const getduraciones = async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/embarcacionPreciosVariables/getDuracion/${onboarding.id}`,
                { headers }
            );
            console.log(data);
            setDuraciones(data);
        };
        const getvariables = async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/embarcacionPreciosVariables/all/${onboarding.id}`,
                { headers }
            );
            console.log(data);

            setVariables(data);
        };
        getduraciones();
        getvariables();
    }, []);

    useEffect(() => {
        const getvariables = async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/embarcacionPreciosVariables/all/${onboarding.id}`,
                { headers }
            );
            setVariables(data);
        };

        getvariables();
    }, [fakeRefresh]);

    const deleteVariable = async (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "Una vez eliminado, no podrás recuperar este registro",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "No, cancelar",
        }).then(async (result) => {
            if (result.value) {
                const { data } = await axios.delete(
                    ` ${process.env.REACT_APP_PROXY}/embarcacionPreciosVariables/eliminar/${id}`,
                    { headers }
                );
                console.log(data);
                if (data.message === "Registro eliminado con exito") {
                    Swal.fire(
                        "Eliminado",
                        "El registro ha sido eliminado",
                        "success"
                    );
                    setFakeRefresh(!fakeRefresh);
                }
            }
        });
    };

    const nextPage = async () => {
        setSiguiente(null);

        await axios.put(
            `${process.env.REACT_APP_PROXY}/embarcacion/actualizarPagina/${onboarding.id}`,
            {
                numero_pagina: actualPage + 1,
            }
        );
        setPage(actualPage + 1);
    };

    let Overnight = variables.findIndex((v) => v.duracion === "Overnight");
    let half = variables.findIndex((v) => v.duracion === "Half Day");
    let full = variables.findIndex((v) => v.duracion === "full day");

    return (
        <div className="w-full p-5 ">
            <h1 className="py-16 -mt-5 text-4xl font-bold text-center sm:py-10 sm:text-6xl">
                ¿Tienes variaciones de precio por fechas?
            </h1>

            <div className="w-10/12 mx-auto sm:flex xl:w-8/12 ">
                {!duraciones?.length ? <Spinner width={20} /> : null}
                {duraciones?.map((duracion) => (
                    <div
                        onClick={() => (
                            setDuracionSelect(duracion),
                            setCreateVisible(!createVisible)
                        )}
                        key={duracion.id}
                        className="items-center w-3/12 py-3 mx-auto my-2 text-xs text-center capitalize duration-150 transform border-2 border-gray-400 rounded-md select-none md:text-base lg:text-xl lg:py-8 hover:scale-110">
                        {duracion.duracion}
                    </div>
                ))}
            </div>
            {createVisible && (
                <ModalVariablePricesBoats
                    duracion={duracionSelect}
                    modalVisible={createVisible}
                    setModalVisible={setCreateVisible}
                    fakeRefresh={fakeRefresh}
                    setFakeRefresh={setFakeRefresh}
                    onboarding={onboarding}
                />
            )}
            {editVisible && (
                <ModalEditVariablePricesBoats
                    duracion={editDuracionSelect}
                    modalVisible={editVisible}
                    setModalVisible={setEditVisible}
                    fakeRefresh={fakeRefresh}
                    setFakeRefresh={setFakeRefresh}
                    onboarding={onboarding}
                />
            )}
            <div
                className={
                    variables?.findIndex((v) => v.duracion === "Overnight") !==
                    -1
                        ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5"
                        : "hidden"
                }>
                <div className="w-full">
                    <p className="text-xl font-semibold text-center sm:text-2xl ">
                        Overnight
                    </p>
                    <table className="w-full mt-2 table-auto">
                        <thead className="bg-blue-700 ">
                            <tr className="text-center bg-primary">
                                <th className=" w-1/12 min-w-[85px] text-xs font-semibold text-white py-1  px-3 ">
                                    Overnight
                                </th>
                                <th className="w-1/12 px-3 py-1 text-xs font-semibold text-white ">
                                    Costo por dia
                                </th>
                                <th className="w-2/12 px-3 py-1 text-xs font-semibold text-white "></th>
                                <th className="w-1/12 px-3 py-1 text-xs font-semibold text-white "></th>
                            </tr>
                        </thead>
                        {variables[Overnight]?.precios_variables?.map(
                            (otro, i) => (
                                <tbody key={i}>
                                    <tr>
                                        <td
                                            className={
                                                "text-center text-dark  text-xs py-1 px-2  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                            }>
                                            {otro?.tipo_variante}
                                        </td>
                                        <td
                                            className={
                                                "text-center text-dark  text-xs py-1 px-2  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                            }>
                                            {otro?.costo} (
                                            {onboarding.tipo_moneda})
                                        </td>
                                        <td
                                            className={
                                                "text-center text-dark  text-xs py-1 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                            }>
                                            {otro?.tipo_variante ===
                                                "Por Dia" && otro.dia}
                                            {otro?.tipo_variante ===
                                                "Por Mes" && otro?.mes}
                                            {otro?.tipo_variante ===
                                                "Rango de Fechas" &&
                                                "del " +
                                                    otro?.fecha_inicio
                                                        .slice(0, 10)
                                                        .split("-")
                                                        .reverse()
                                                        .join("-") +
                                                    " al " +
                                                    otro?.fecha_final
                                                        .slice(0, 10)
                                                        .split("-")
                                                        .reverse()
                                                        .join("-")}
                                        </td>
                                        <td
                                            className={
                                                "text-center text-dark  text-base py-1 px-2 text-red-600  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] lg:text-xl"
                                            }>
                                            <button
                                                onClick={() => (
                                                    setEditDuracionSelect(otro),
                                                    setEditVisible(!editVisible)
                                                )}
                                                title="Editar"
                                                className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white">
                                                <svg
                                                    className="w-4 h-4 text-blue-500 group-hover:text-white"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />
                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                    <line
                                                        x1="16"
                                                        y1="5"
                                                        x2="19"
                                                        y2="8"
                                                    />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={() =>
                                                    deleteVariable(otro?.id)
                                                }
                                                title="Eliminar"
                                                className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white">
                                                <svg
                                                    className="w-4 h-4 text-red-500 group-hover:text-white"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="7"
                                                        x2="20"
                                                        y2="7"
                                                    />
                                                    <line
                                                        x1="10"
                                                        y1="11"
                                                        x2="10"
                                                        y2="17"
                                                    />
                                                    <line
                                                        x1="14"
                                                        y1="11"
                                                        x2="14"
                                                        y2="17"
                                                    />
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            )
                        )}
                    </table>
                </div>
            </div>

            <div
                className={
                    variables?.findIndex((v) => v.duracion === "Half Day") !==
                    -1
                        ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5 "
                        : "hidden"
                }>
                <div className="w-full">
                    <p className="text-xl font-semibold text-center sm:text-2xl ">
                        Half Day
                    </p>
                    <table className="w-full mt-2 table-auto">
                        <thead className="bg-blue-700 ">
                            <tr className="text-center bg-primary">
                                <th className=" w-1/12 min-w-[85px] text-xs font-semibold text-white py-1  px-3 ">
                                    Half Day
                                </th>
                                <th className="w-1/12 px-3 py-1 text-xs font-semibold text-white ">
                                    Costo por dia
                                </th>
                                <th className="w-2/12 px-3 py-1 text-xs font-semibold text-white "></th>
                                <th className="w-1/12 px-3 py-1 text-xs font-semibold text-white "></th>
                            </tr>
                        </thead>
                        {variables[half]?.precios_variables?.map((otro, i) => (
                            <tbody key={i}>
                                <tr>
                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro?.tipo_variante}
                                    </td>
                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro?.costo} ({onboarding.tipo_moneda})
                                    </td>

                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro?.tipo_variante === "Por Dia" &&
                                            otro?.dia}
                                        {otro?.tipo_variante === "Por Mes" &&
                                            otro?.mes}
                                        {otro?.tipo_variante ===
                                            "Rango de Fechas" &&
                                            "del " +
                                                otro?.fecha_inicio
                                                    .slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("-") +
                                                " al " +
                                                otro?.fecha_final
                                                    .slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("-")}
                                    </td>
                                    <td
                                        className={
                                            "text-center text-dark  text-base py-1 px-2 text-red-600  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] lg:text-xl"
                                        }>
                                        <button
                                            onClick={() => (
                                                setEditDuracionSelect(otro),
                                                setEditVisible(!editVisible)
                                            )}
                                            title="Editar"
                                            className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white">
                                            <svg
                                                className="w-4 h-4 text-blue-500 group-hover:text-white"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                <line
                                                    x1="16"
                                                    y1="5"
                                                    x2="19"
                                                    y2="8"
                                                />
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() =>
                                                deleteVariable(otro?.id)
                                            }
                                            title="Eliminar"
                                            className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white">
                                            <svg
                                                className="w-4 h-4 text-red-500 group-hover:text-white"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <line
                                                    x1="4"
                                                    y1="7"
                                                    x2="20"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="10"
                                                    y1="11"
                                                    x2="10"
                                                    y2="17"
                                                />
                                                <line
                                                    x1="14"
                                                    y1="11"
                                                    x2="14"
                                                    y2="17"
                                                />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>

            <div
                className={
                    variables?.findIndex((v) => v.duracion === "full day") !==
                    -1
                        ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5"
                        : "hidden"
                }>
                <div className="w-full">
                    <p className="text-xl font-semibold text-center sm:text-2xl ">
                        Full Day
                    </p>
                    <table className="w-full mt-2 table-auto">
                        <thead className="bg-blue-700 ">
                            <tr className="text-center bg-primary">
                                <th className=" w-1/12 min-w-[85px] text-xs font-semibold text-white py-1  px-3 ">
                                    Full Day
                                </th>
                                <th className="w-1/12 px-3 py-1 text-xs font-semibold text-white ">
                                    Costo por dia
                                </th>
                                <th className="w-2/12 px-3 py-1 text-xs font-semibold text-white "></th>
                                <th className="w-1/12 px-3 py-1 text-xs font-semibold text-white "></th>
                            </tr>
                        </thead>
                        {variables[full]?.precios_variables?.map((otro, i) => (
                            <tbody key={i}>
                                <tr>
                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro?.tipo_variante}
                                    </td>
                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro?.costo} ({onboarding.tipo_moneda})
                                    </td>
                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro?.tipo_variante === "Por Dia" &&
                                            otro?.dia}
                                        {otro?.tipo_variante === "Por Mes" &&
                                            otro?.mes}
                                        {otro?.tipo_variante ===
                                            "Rango de Fechas" &&
                                            "del " +
                                                otro?.fecha_inicio
                                                    ?.slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("-") +
                                                " al " +
                                                otro?.fecha_final
                                                    ?.slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("-")}
                                    </td>
                                    <td
                                        className={
                                            "text-center text-dark  text-base py-1 px-2 text-red-600  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] lg:text-xl"
                                        }>
                                        <button
                                            onClick={() => (
                                                setEditDuracionSelect(otro),
                                                setEditVisible(!editVisible)
                                            )}
                                            title="Editar"
                                            className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white">
                                            <svg
                                                className="w-4 h-4 text-blue-500 group-hover:text-white"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                <line
                                                    x1="16"
                                                    y1="5"
                                                    x2="19"
                                                    y2="8"
                                                />
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() =>
                                                deleteVariable(otro?.id)
                                            }
                                            title="Eliminar"
                                            className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white">
                                            <svg
                                                className="w-4 h-4 text-red-500 group-hover:text-white"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <line
                                                    x1="4"
                                                    y1="7"
                                                    x2="20"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="10"
                                                    y1="11"
                                                    x2="10"
                                                    y2="17"
                                                />
                                                <line
                                                    x1="14"
                                                    y1="11"
                                                    x2="14"
                                                    y2="17"
                                                />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>

            <div className="flex items-center justify-center mt-20">
                <button
                    onClick={() => setPage(actualPage - 1)}
                    className="p-2 pl-5 pr-5 mr-2 text-lg text-gray-500 min-w-[118px] bg-transparent border-2 border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 ">
                    Anterior
                </button>

                <button
                    disabled={!siguiente}
                    onClick={() => nextPage()}
                    className="p-2 pl-5 pr-5 text-lg text-gray-500 bg-transparent border-2 min-w-[118px] border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 disabled:bg-white disabled:border-black">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div>
        </div>
    );
};

export default VariablePricesBoats;
