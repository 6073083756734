export const normalizeString = (string) => {
    let CADENA = string;

    CADENA = CADENA?.replace(/ /g, "-");

    CADENA = CADENA?.toLowerCase()
        .trim()
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

    return CADENA;
};
