import React, { useState, useEffect, useLayoutEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../../Spinner";
import axios from "axios";
import ModalNights from "./ModalNights";
import ModalEditNights from "./ModalEditNights";
import { useSelector } from "react-redux";

const EditarAlquilerMinimo = ({ producto, setProducto, setModal }) => {
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [availability, setAvailability] = useState([]);
    const [nightupdate, setNightupdate] = useState(false);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    useEffect(() => {
        getAllNoches();
    }, []);
    useEffect(() => {
        getAllNoches();
    }, [create, nightupdate]);

    useLayoutEffect(() => {
        setLoading(false);
    }, [availability]);

    const getAllNoches = async () => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/productoMinimaDisponibilidad/all/${producto.data.id}`,
            { headers }
        );
        let aux = json.data?.map((l) => {
            return {
                fecha_inicio: l.fecha_inicio.substring(0, 10),
                fecha_final: l.fecha_final.substring(0, 10),
                numero_noches: l.numero_noches,
                id: l.id,
                id_productos: l.id_productos,
            };
        });

        setAvailability(aux.length > 0 ? aux : []);
    };

    const deleteNoches = async (id) => {
        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/productoMinimaDisponibilidad/eliminar/${id}`,
            {
                data: { id_usuario: authUser.id_usuario },
                headers: headers,
            }
        );

        if (json.data.message === "Eliminado con exito") {
            Swal.fire({
                title: "Exito",
                text: "Se ha eliminado la minima disponibilidad",
                icon: "success",
                confirmButtonText: "Ok",
            });
            getAllNoches();
        }
    };

    const eliminarNoches = (nights) => {
        Swal.fire({
            title: "Estas seguro?",
            text: "Una vez eliminado no podras recuperarlo!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                deleteNoches(nights.id);
            }
        });
    };

    const updateProduct = async (payload) => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/casa/actualizarAlquilerMinimo/${payload.id}`,
            payload,
            { headers }
        );
        if (data.message === "actuaizacion exitoso") {
            setModal(false);
        }
    };

    const handleGuardar = () => {
        if (producto.data.alquiler_minimo < 1) {
            Swal.fire({
                title: "Error",
                text: "Debe ingresar un alquiler minimo",
                icon: "error",
                confirmButtonText: "Ok",
            });
        } else {
            updateProduct({
                alquiler_maximo: producto.data.alquiler_maximo,
                alquiler_minimo: producto.data.alquiler_minimo,
                id: producto.data.id,
                id_usuario: authUser.id_usuario,
            });
        }
    };

    return (
        <div className="border p-2 xs:p-4 rounded-lg">
            <h1>Alquiler mínimo</h1>

            <div className="w-full mt-3 ">
                <div className="align-top">
                    <div className="p-4 mt-2 flex justify-center items-center ">
                        <div
                            onClick={() =>
                                producto.data.alquiler_minimo > 1 &&
                                setProducto({
                                    ...producto,
                                    data: {
                                        ...producto.data,
                                        alquiler_minimo:
                                            producto.data.alquiler_minimo - 1,
                                    },
                                })
                            }
                            className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                            <svg
                                className="h-4 w-4 text-blue-bluecorner"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                        </div>
                        <div className="px-3 text-center font-bold outline-none focus:outline-none text-[#3C3C3C]  text-base mx-2 rounded-lg">
                            {producto.data.alquiler_minimo}
                        </div>
                        <div
                            onClick={() =>
                                setProducto({
                                    ...producto,
                                    data: {
                                        ...producto.data,
                                        alquiler_minimo:
                                            producto.data.alquiler_minimo + 1,
                                    },
                                })
                            }
                            className=" flex justify-center items-center border-2 w-8 h-8 border-blue-bluecorner rounded-lg cursor-pointer">
                            <svg
                                className="h-4 w-4 text-blue-bluecorner"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                        </div>
                        <div>
                            <p className="py-2 pl-2 font-medium text-center ">
                                <span className="capitalize">
                                    {producto.data.tipo_estadia}{" "}
                                </span>{" "}
                                (s)
                            </p>
                        </div>
                    </div>
                    <hr className=" border-gray-200 mb-4" />
                    <h1>Variacion por fecha</h1>
                    <div className="flex w-full mt-3">
                        <button
                            className="inline-flex items-center px-3 py-2  text-blue-bluecorner border border-blue-bluecorner rounded-lg group"
                            onClick={() => setCreate(!create)}>
                            <svg
                                className="w-3 h-3 text-blue-bluecorner"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                            <span className="items-center">Añadir</span>
                        </button>
                    </div>
                    {loading ? <Spinner width={20} /> : null}
                    {create && (
                        <ModalNights
                            create={create}
                            setCreate={setCreate}
                            producto={producto}
                        />
                    )}
                    {nightupdate && (
                        <ModalEditNights
                            producto={producto}
                            nightupdate={nightupdate}
                            setNightupdate={setNightupdate}
                        />
                    )}

                    <section
                        className={
                            availability.length < 1
                                ? "hidden"
                                : "flex justify-center items-center lg:py-[10px] mt-2 "
                        }>
                        <div className="w-full mt-3">
                            <table className="w-full ">
                                <thead>
                                    <tr>
                                        <th
                                            className="w-3/12 font-semibold "
                                            align="center">
                                            Desde
                                        </th>

                                        <th
                                            className="w-3/12 font-semibold  "
                                            align="center">
                                            Hasta
                                        </th>
                                        <th
                                            className="w-2/12 font-semibold  "
                                            align="center">
                                            <span className="capitalize">
                                                {producto.data.tipo_estadia}
                                            </span>
                                            s{" "}
                                            {producto.data.tipo_estadia ===
                                            "noche"
                                                ? "minimas"
                                                : "minimos"}
                                        </th>
                                        <th
                                            className="w-4/12 font-semibold "
                                            align="center">
                                            Acción
                                        </th>
                                    </tr>
                                </thead>
                                {availability?.map((nights, i) => (
                                    <tbody key={i}>
                                        <tr>
                                            <td align="center" className="py-3">
                                                {nights?.fecha_inicio
                                                    .split("-")
                                                    .reverse()
                                                    .join("-")}
                                            </td>

                                            <td align="center">
                                                {nights?.fecha_final
                                                    .split("-")
                                                    .reverse()
                                                    .join("-")}
                                            </td>
                                            <td align="center">
                                                {nights.numero_noches}
                                            </td>

                                            <td align="center">
                                                {
                                                    <div className="">
                                                        <button
                                                            onClick={() =>
                                                                setNightupdate(
                                                                    nights
                                                                )
                                                            }
                                                            title="Editar"
                                                            className="inline-block px-1 py-1 mr-3 text-blue-bluecorner border border-blue-bluecorner rounded ">
                                                            <svg
                                                                className="w-6 h-6 text-blue-bluecorner "
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round">
                                                                <path
                                                                    stroke="none"
                                                                    d="M0 0h24v24H0z"
                                                                />
                                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                <line
                                                                    x1="16"
                                                                    y1="5"
                                                                    x2="19"
                                                                    y2="8"
                                                                />
                                                            </svg>
                                                        </button>
                                                        <button
                                                            onClick={() =>
                                                                eliminarNoches(
                                                                    nights
                                                                )
                                                            }
                                                            title="Eliminar"
                                                            className="px-1 py-1  text-blue-bluecorner border border-blue-bluecorner rounded ">
                                                            <svg
                                                                className="w-6 h-6 text-blue-bluecorner "
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round">
                                                                <path
                                                                    stroke="none"
                                                                    d="M0 0h24v24H0z"
                                                                />
                                                                <line
                                                                    x1="4"
                                                                    y1="7"
                                                                    x2="20"
                                                                    y2="7"
                                                                />
                                                                <line
                                                                    x1="10"
                                                                    y1="11"
                                                                    x2="10"
                                                                    y2="17"
                                                                />
                                                                <line
                                                                    x1="14"
                                                                    y1="11"
                                                                    x2="14"
                                                                    y2="17"
                                                                />
                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </section>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarAlquilerMinimo;
