import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalCreateAttributes from "./ModalCreateAttributes";
import Swal from "sweetalert2";
import {
    getAllAttributes,
    deleteAttribute,
    enableAttribute,
    oneAttribute,
    resetEnebleAttribute,
} from "../../redux/actions/attributesActions";
import SearchBar from "./SearchBar";
import { Paginado } from "../helpers/Paginado";
import ModalEditAttributes from "./ModalEditAttributes";
import Spinner from "../Spinner";

const CreateAttributes = () => {
    const dispatch = useDispatch();
    const [create, setcreate] = useState(false);
    const [edit, setedit] = useState(false);
    const [enabled, setenabled] = useState("Si");
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [pageNumber, setpageNumber] = useState(1);
    const ATTRIBUTES_PER_PAGE = 25;
    const [loading, setLoading] = useState(false);
    const [modalHabilitado, setModalHabilitado] = useState(false);

    const attributes = useSelector(
        (state) => state.attributesReducer.attributes
    );

    const reduxAttribute = useSelector(
        (state) => state.attributesReducer.oneAttribute
    );

    const enebleReduxAttribute = useSelector(
        (state) => state.attributesReducer.enableAttribute
    );

    useEffect(() => {
        setLoading(false);
    }, [attributes]);

    useEffect(() => {
        dispatch(getAllAttributes(0, "Si"));
    }, [dispatch, create]);

    useEffect(() => {
        dispatch(getAllAttributes(pageNumber, enabled));
    }, [dispatch, edit]);

    useEffect(() => {
        setLoading(true);

        dispatch(getAllAttributes(null, enabled));
        setpageNumber(1);
    }, [dispatch, enabled]);

    useEffect(() => {
        if (enebleReduxAttribute === "Restaurado con exito") {
            dispatch(resetEnebleAttribute());
            dispatch(getAllAttributes(null, enabled));

            Swal.fire({
                title: "Procesado!",
                text: "atributo restaurado",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        } else if (
            enebleReduxAttribute === "Registro deshabilitado con exito"
        ) {
            dispatch(resetEnebleAttribute());
            dispatch(getAllAttributes(null, enabled));

            Swal.fire({
                title: "Procesado!",
                text: "atributo deshabilitado",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        }
    }, [enebleReduxAttribute]);

    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el atributo a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteAttribute(id));
                setLoading(true);
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el atributo a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(enableAttribute(id));
                setLoading(true);
            }
        });
    };

    const handleEdit = (id) => {
        dispatch(oneAttribute(id));
        setedit(!edit);
    };

    const pagination = (pageNumber) => {
        setLoading(true);

        setpageNumber(pageNumber);
        dispatch(getAllAttributes(pageNumber, enabled));
    };

    return (
        <div className=" w-full md:pl-[12rem] md818:pl-[11rem] md920:pl-[11rem] lg:pl-[15rem] lg1170:pl-[16rem]  xl:pl-[16rem] ">
            <div className="flex items-center justify-between px-6 w-full mx-auto ">
                <h1 className="text-4xl  py-10 text-center text-[#3C3C3C]">
                    Servicios
                </h1>
                <div className="mt-4 md:ml-10">
                    <button
                        className="flex items-center px-3 py-2 mx-auto text-lg  text-blue-bluecorner border border-blue-bluecorner rounded-lg group hover:bg-blue-bluecorner hover:text-white"
                        onClick={() => setcreate(!create)}>
                        <svg
                            className="w-5 h-5 text-blue-bluecorner group-hover:text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <line x1="12" y1="5" x2="12" y2="19" />{" "}
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        <span className="items-center text-base hidden sm:block ">
                            Crea un servicio
                        </span>
                    </button>
                </div>
            </div>
            <div className="absolute flex items-center justify-center w-full text-center z-20">
                {create && (
                    <ModalCreateAttributes
                        create={create}
                        setcreate={setcreate}
                        pageNumber={pageNumber}
                    />
                )}
                {edit && reduxAttribute.id ? (
                    <ModalEditAttributes
                        edit={edit}
                        setedit={setedit}
                        reduxAttribute={reduxAttribute}
                        pageNumber={pageNumber}
                    />
                ) : null}
            </div>
            <div className="flex items-center justify-end sm:px-6 pr-6 mx-auto">
                {/* <button
                    className={
                        enabled === "Si"
                            ? "inline-block p-2 mx-3 text-blue-bluecorner border border-blue-bluecorner rounded group hover:bg-blue-bluecorner hover:text-white"
                            : "inline-block p-2 mx-3 text-blue-bluecorner border border-blue-bluecorner rounded group hover:bg-blue-bluecorner hover:text-white"
                    }
                    onClick={() => (
                        setLoading(!loading),
                        setenabled(enabled === "Si" ? "No" : "Si")
                    )}>
                    {enabled === "Si"
                        ? `Habilitados (${
                              attributes?.total ? attributes?.total : 0
                          })`
                        : `Deshabilitados (${
                              attributes?.total ? attributes?.total : 0
                          })`}
                </button> */}

                <SearchBar
                    setfakeRefresh={setfakeRefresh}
                    fakeRefresh={fakeRefresh}
                    enabled={enabled}
                />
                <div
                    onMouseOut={() => setModalHabilitado(false)}
                    onMouseOver={() => setModalHabilitado(true)}
                    className="relative">
                    <button className="w-8 h-8 xs:w-[51px] xs:h-[51px] flex items-center justify-center px-1 py-1  text-blue-700 border-2 border-blue-500 rounded-lg group hover:bg-blue-700 hover:text-white">
                        <svg
                            className="w-6 h-6 xs:w-8 xs:h-8 text-blue-500 group-hover:text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                            />
                        </svg>
                    </button>
                    <div
                        className={
                            modalHabilitado
                                ? "absolute top-8 xs:top-[50px]  w-36 z-10  pt-2 right-0 "
                                : "hidden"
                        }>
                        <div
                            className={
                                modalHabilitado
                                    ? "block bg-white  top-16 md723:top-20 py-2 rounded-lg shadow-md"
                                    : "hidden"
                            }>
                            <div
                                onClick={() => (
                                    setLoading(!loading),
                                    setModalHabilitado(false),
                                    setenabled(enabled === "Si" ? "No" : "Si")
                                )}
                                className=" w-32 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start pl-4 ">
                                {enabled === "No"
                                    ? `Habilitados`
                                    : `Deshabilitadas`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="flex justify-center items-center lg:py-[10px] ">
                <div className="w-full px-11">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full ">
                            <div className="flex flex-col">
                                {attributes.total === 0 && !loading ? (
                                    <h1 className="font-medium w-full text-center mt-10">
                                        No se encontraron atributos
                                    </h1>
                                ) : attributes.total > 0 && !loading ? (
                                    <div className="w-full ">
                                        <table className="mt-14 w-full overflow-x-auto">
                                            <thead className="text-left">
                                                <tr>
                                                    <th className="w-full min-w-[10rem] md:w-4/12 ">
                                                        Nombre
                                                    </th>

                                                    <th className="w-1/12 text-right min-w-[8rem] md:table-cell pr-10">
                                                        Acciones
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                                {attributes.data?.map(
                                                    (attribute) => (
                                                        <tr
                                                            key={attribute.id}
                                                            className="border-t w-full ">
                                                            <td>
                                                                <div
                                                                    className="items-center flex py-3 cursor-pointer"
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            attribute?.id
                                                                        )
                                                                    }>
                                                                    <img
                                                                        src={
                                                                            `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/atributo/getImage/` +
                                                                            attribute?.imagen
                                                                        }
                                                                        alt="Imagen attribute"
                                                                        className="w-10 h-10 object-cover rounded"
                                                                    />
                                                                    <h1 className="mx-3 text-[#3C3C3C] text-base">
                                                                        {
                                                                            attribute?.nombre
                                                                        }
                                                                    </h1>
                                                                </div>
                                                            </td>

                                                            <td
                                                                className={
                                                                    enabled ===
                                                                    "Si"
                                                                        ? " md:flex justify-end py-5 px-2"
                                                                        : " md:flex  justify-center pl-7 md:justify-end py-5 md:pr-10 "
                                                                }>
                                                                {enabled ===
                                                                "Si" ? (
                                                                    <div className="flex">
                                                                        <button
                                                                            onClick={() =>
                                                                                handleEdit(
                                                                                    attribute?.id
                                                                                )
                                                                            }
                                                                            title="Editar"
                                                                            className="flex items-center justify-center px-1  h-8 w-8 py-1 mr-3 text-blue-bluecorner border border-blue-bluecorner rounded group hover:bg-blue-bluecorner hover:white">
                                                                            <svg
                                                                                className="h-6 w-6 text-blue-bluecorner group-hover:text-white"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="2"
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                <path
                                                                                    stroke="none"
                                                                                    d="M0 0h24v24H0z"
                                                                                />
                                                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                                <line
                                                                                    x1="16"
                                                                                    y1="5"
                                                                                    x2="19"
                                                                                    y2="8"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                        <button
                                                                            className="flex items-center justify-center px-1 py-1 h-8 w-8 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:text-white"
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    attribute.id
                                                                                )
                                                                            }
                                                                            title="Eliminar">
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="2"
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                {" "}
                                                                                <path
                                                                                    stroke="none"
                                                                                    d="M0 0h24v24H0z"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="4"
                                                                                    y1="7"
                                                                                    x2="20"
                                                                                    y2="7"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="10"
                                                                                    y1="11"
                                                                                    x2="10"
                                                                                    y2="17"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="14"
                                                                                    y1="11"
                                                                                    x2="14"
                                                                                    y2="17"
                                                                                />{" "}
                                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <button
                                                                            onClick={() =>
                                                                                handleEnable(
                                                                                    attribute.id
                                                                                )
                                                                            }
                                                                            title="Habilitar"
                                                                            className="flex items-center justify-center px-1 py-1 h-8 w-8 mx-3 text-blue-bluecorner border border-blue-bluecorner rounded group hover:bg-blue-bluecorner hover:white">
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-bluecorner group-hover:text-white"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                {" "}
                                                                                <polyline points="17 1 21 5 17 9" />{" "}
                                                                                <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                <polyline points="7 23 3 19 7 15" />{" "}
                                                                                <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <Spinner />
                                )}
                            </div>

                            <div className="container flex items-center justify-between p-1 mx-auto">
                                <Paginado
                                    elementsPerPage={ATTRIBUTES_PER_PAGE}
                                    cantTotalElements={attributes.total}
                                    pagination={pagination}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CreateAttributes;
