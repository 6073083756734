import React, { useState } from "react";

const CardExperience = ({
    cuadrante,
    setModalVerMasVisible,
    setCuadranteSelected,
}) => {
    return (
        <div
            className="relative flex flex-col h-32 font-bold border border-gray-600 cursor-pointer select-none"
            onClick={() => {
                setCuadranteSelected(cuadrante);
                setModalVerMasVisible(true);
            }}
            style={{ backgroundColor: cuadrante.color }}>
            <div className="flex justify-between w-full h-24">
                <span className="h-fit p-0.5">{cuadrante.tag}</span>
                {cuadrante.casas.length > 0 && (
                    <div className="px-1 py-4 overflow-hidden">
                        {cuadrante.casas.slice(0, 4).map((casa) => (
                            <h2
                                title={casa.nombre_casa}
                                key={casa.id_casa}
                                className="overflow-hidden font-medium text-ellipsis whitespace-nowrap">
                                {casa.nombre_casa}
                            </h2>
                        ))}
                    </div>
                )}
                <span className="h-fit p-0.5">({cuadrante.casas.length})</span>
            </div>
            {cuadrante.casas.length > 4 && (
                <div className="flex items-center justify-center h-full text-center">
                    <h3
                        className="z-40 mt-2 cursor-pointer hover:underline"
                        onClick={() => {
                            setCuadranteSelected(cuadrante);
                            setModalVerMasVisible(true);
                        }}>
                        Ver más
                    </h3>
                </div>
            )}
            {cuadrante.id_matriz === 19 && (
                <span className="absolute z-50 flex justify-center font-bold -top-5 -right-7">
                    <p className="px-3 py-2 tracking-wider text-center bg-white border border-gray-600">
                        3 BE
                    </p>
                </span>
            )}
            {cuadrante.id_matriz === 20 && (
                <span className="absolute z-50 flex justify-center font-bold -top-5 -right-7">
                    <p className="px-3 py-2 tracking-wider text-center bg-white border border-gray-600">
                        2 BE
                    </p>
                </span>
            )}
            {cuadrante.id_matriz === 22 && (
                <span className="absolute z-50 flex justify-center font-bold -top-5 -right-7">
                    <p className="px-3 py-2 tracking-wider text-center bg-white border border-gray-600">
                        2 BE
                    </p>
                </span>
            )}
            {cuadrante.id_matriz === 23 && (
                <span className="absolute z-50 flex justify-center font-bold -top-5 -right-7">
                    <p className="px-3 py-2 tracking-wider text-center bg-white border border-gray-600">
                        3 BE
                    </p>
                </span>
            )}
        </div>
    );
};

export default CardExperience;
