import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";

const EditarCheckIn = ({ producto, setProducto, setModal, tipo_producto }) => {
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({
        reglas: [],
        checkIn: producto.data?.check_in || "",
        checkOut: producto.data?.check_out || "",
    });

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    const handleHorariosACoordinar = (e) => {
        if (e.target.checked) {
            setSelected({
                ...selected,
                checkIn: "A coordinar",
            });
        } else {
            setSelected({
                ...selected,
                checkIn: "00:00",
            });
        }
    };

    const handleCheckIn = (e) => {
        setSelected({
            ...selected,
            checkIn: e.target.value,
        });
    };

    const updateProduct = async (payload) => {
        if (tipo_producto === "casa") {
            const response = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarCheckInOut/${payload.id_producto}`,
                payload.horarios,
                { headers }
            );
            if (response) {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        check_in: payload.horarios.check_in,
                    },
                });
                setModal(false);
            }
        } else {
            console.log("embarcacion");
        }
    };

    const handleGuardar = () => {
        const payload = {
            id_producto: producto.data.id,
            reglas: selected.reglas,
            horarios: {
                check_in: selected.checkIn,
                check_out: selected.checkOut,
                id_usuario: authUser.id_usuario,
            },
        };

        console.log("Payload final: ", payload);
        tipo_producto === "casa"
            ? updateProduct(payload)
            : updateProduct({ reglas: producto.reglas });
    };

    return (
        <div className=" border p-4 rounded-lg">
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <div className="p-3 gap-2 items-center flex">
                        <input
                            type="checkbox"
                            defaultChecked={
                                producto.data.check_in === "A coordinar"
                            }
                            onChange={handleHorariosACoordinar}
                        />
                        <span className="">Horarios a coordinar</span>
                    </div>
                    {selected.checkIn !== "A coordinar" && (
                        <div className="mx-3 w-full pr-3 justify-between flex items-center">
                            <h1>Check-in</h1>
                            <div>
                                {tipo_producto === "casa" && (
                                    <>
                                        <div className="w-full grid ">
                                            <input
                                                className="p-3 w-[120px] mt-2  border  rounded-lg outline-none cursor-pointer text-[#3C3C3C]"
                                                value={selected.checkIn}
                                                onChange={handleCheckIn}
                                                type="time"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarCheckIn;
