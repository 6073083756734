import React, { useState, useRef, useEffect } from "react";

const useCuentaRegresiva = (date) => {
    //Recibe fecha en formato mm/dd/aaaa
    const Ref = useRef(null);

    const [totalSeconds, setTotalSeconds] = useState(0);
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [totalHours, setTotalHours] = useState(0);
    const [totalDays, setTotalDays] = useState(0);
    const [fullText, setfullText] = useState("");

    const getTimeRemaining = (time) => {
        const total = Date.parse(time) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor(total / (1000 * 60 * 60));

        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };

    const startTimer = (time) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(time);

        if (total >= 0) {
            const textDays = Math.floor(hours / 24);
            const textHours = Math.floor(hours - textDays * 24);

            setTotalDays(textDays);
            setTotalHours(textHours);
            setTotalMinutes(minutes);
            setTotalSeconds(seconds);
            setfullText(
                `${textDays} días ${textHours} horas ${minutes} minutos ${seconds} segundos`
            );
        }
    };

    const clearTimer = (time) => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(time);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        return new Date(date);
    };

    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);

    return {
        totalDays,
        totalHours,
        totalMinutes,
        totalSeconds,
        fullText,
    };
};

export default useCuentaRegresiva;
