import React from "react";
import NavBar from "../NavBar";
import { Helmet } from "react-helmet";
import Footer from "../Footer";
import banner from "../../img/banner-loyalty.webp";
import campo from "../../img/loyalty/casas_de_campo.webp";

import playa from "../../img/loyalty/casa_de_playa.webp";
import registrate from "../../img/loyalty/DJI_0408-1.webp";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { updateHouseFilters } from "../../redux/actions/filtersActions";
import paramsURLhouses from "../alquilerScreen/alquilerAlojamiento/paramsURLhouses";

export const LoyaltyNew = () => {
    const authUser = useSelector((state) => state.authReducer);
    const filters = useSelector((state) => state.filtersReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const params = (filtros) => {
        let url = "";

        url = paramsURLhouses(filtros);

        return url;
    };
    return (
        <div>
            <NavBar />
            <Helmet>
                <link
                    rel="canonical"
                    href="https://example.com/dresses/green-dresses"
                />
                <meta
                    name="description"
                    content={`Blue Corner, Alquileres, Embarcaciones, Alojamientos`}
                />
            </Helmet>
            <div className="flex flex-col mx-auto mt-28 xs:mt-32  lg:px-20 xl:px-32 w-full md:w-4/5 2xl:w-3/4">
                <div className=" text-3xl md:text-4xl 2xl:text-5xl mt-10 mx-auto font-bold">
                    Programa Loyalty
                </div>
                <img className="mt-5 w-full " src={banner} alt="banner" />
                <div className="mt-6 flex flex-col items-center justify-center text-base md:text-xl 2xl:text-2xl px-4 xs:px-10 text-center">
                    <p className="font-semibold ">
                        Queremos premiarte por confiar en nosotros.{" "}
                    </p>

                    <p className=" ">
                        Obten beneficios al reservar más experiencias
                    </p>
                    <p> con nosotros </p>
                    <p className="mt-7 md:mt-10 ">Te regalamos:</p>

                    <p className="font-semibold ">
                        2D/1N en uno de nuestros alojamientos
                    </p>
                    <p className="mt-7 md:mt-10 ">Además:</p>
                    <p className=" text-center">
                        Te ponemos la diversión, elige cualquiera de nuestros{" "}
                        <span className="font-semibold">
                            packs de alimentos y bebidas
                        </span>
                    </p>
                </div>
                <div className="my-6 grid grid-cols-2 gap-6 text-xs xs:text-sm md:text-base 2xl:text-lg   px-4 xs:px-10 text-center select-none">
                    <div
                        onClick={() =>
                            navigate(
                                authUser.tipo_usuario === null
                                    ? "/my-account"
                                    : authUser.tipo_usuario === 5
                                    ? "/perfil/reservaciones"
                                    : authUser.tipo_usuario < 3
                                    ? `/dashboard/anuncios`
                                    : "/my-account"
                            )
                        }
                        className="border-2 border-[#053465] bg-[#053465] rounded-lg p-4  xs:py-6 text-white cursor-pointer hover:scale-105"
                    >
                        <span className="font-semibold">
                            {" "}
                            Si eres cliente de BlueCorner
                        </span>{" "}
                        Haz click aquí para ver cuántas noches has alquilado
                    </div>
                    <div
                        onClick={() => navigate("/my-account/register")}
                        className="border-2 border-[#053465]  rounded-lg p-4  xs:py-6 text-[#053465] cursor-pointer hover:scale-105"
                    >
                        <span className="font-semibold">
                            ¿Aún no eres cliente pero quieres estos beneficios?
                        </span>{" "}
                        Registrate aqui
                    </div>
                </div>
                <div className="mt-6 border-2 border-[#053465] rounded-lg text-base md:text-xl 2xl:text-2xl   mx-4 xs:mx-10 p-4 text-center">
                    <p>Alquila 5 noches y te regalamos:</p>
                    <p>
                        <span className="font-semibold">
                            pack de alimentos*
                        </span>{" "}
                        para tu estadía
                    </p>
                    <div className="grid grid-cols-3 gap-4 mt-4">
                        <div>
                            <p>Pack:</p>
                            <p>Parrillero Cervecero</p>
                        </div>
                        <div>
                            <p>Pack:</p>
                            <p>Dulcero</p>
                        </div>

                        <div>
                            <p>Pack:</p>
                            <p>Snacks salados</p>
                        </div>
                    </div>
                    <hr className="my-4 bg-black h-[2px]" />
                    <p>y además también:</p>
                    <p className="my-2">Alquila 14 noches y te regalamos:</p>
                    <p className="font-semibold">2 días / 1 noche gratis**</p>
                </div>
                <div className="mx-4 xs:mx-10 text-xs xs:text-base mb-3">
                    <p>*tipo de pack a elección del cliente</p>
                    <p>
                        **noche gratis es en base al costo promedio de noches
                        alquiladas anteriormente
                    </p>
                </div>
                <div className=" mx-4 xs:mx-10 text-xl md:text-2xl 2xl:text-3xl mb-3 mt-5">
                    <div className="mx-2 xs:mx-12">
                        <p className="font-bold">
                            Suma puntos ahora haciendo una reserva
                        </p>
                        <div className="grid grid-cols-2 gap-6 mt-5 relative text-base xs:text-xl ">
                            <div
                                onClick={() => {
                                    dispatch(
                                        updateHouseFilters({
                                            query_value: "Campo",
                                        })
                                    );
                                    navigate(
                                        "/alquiler-de-alojamientos/" +
                                            params({
                                                ...filters.house_filters,
                                                query_value: "Campo",
                                            })
                                    );
                                }}
                                className="relative cursor-pointer hover:scale-105"
                            >
                                <img
                                    className="w-full rounded-lg"
                                    src={campo}
                                    alt="Casa de Campo"
                                />
                                <div className="absolute bottom-0 left-0 w-full bg-white bg-opacity-70 p-2">
                                    <p className="text-[#053465] font-bold">
                                        Casa de Campo
                                    </p>
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    dispatch(
                                        updateHouseFilters({
                                            query_value: "Playa",
                                        })
                                    );
                                    navigate(
                                        "/alquiler-de-alojamientos/" +
                                            params({
                                                ...filters.house_filters,
                                                query_value: "Playa",
                                            })
                                    );
                                }}
                                className="relative cursor-pointer hover:scale-105"
                            >
                                <img
                                    className="w-full rounded-lg"
                                    src={playa}
                                    alt="Casa de Playa"
                                />
                                <div className="absolute bottom-0 left-0 w-full bg-white bg-opacity-70 p-2">
                                    <p className="text-[#053465] font-bold">
                                        Casa de Playa
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid gap-6 mt-8 relative ">
                        <div className="relative">
                            <img
                                className="w-full h-full md:h-[20rem] 2xl:h-[25rem] object-cover rounded-lg"
                                src={registrate}
                                alt="registrate"
                            />
                            <div className="absolute bottom-0 xs:top-0 left-0 w-full h-full md:h-[20rem] 2xl:h-[25rem] bg-white bg-opacity-20 xs:p-2 flex flex-col justify-end">
                                <div className=" w-3/4 sm:w-2/3 md:w-1/2 p-4">
                                    {" "}
                                    <p className=" text-base md:text-lg 2xl:text-xl text-white font-bold">
                                        ¿Quieres empezar a disfrutar de estos
                                        beneficios?
                                    </p>
                                    <div className="mt-6 gap-4 flex text-xs xs:text-sm md:text-base 2xl:text-xl">
                                        <button
                                            onClick={() =>
                                                navigate("/my-account/register")
                                            }
                                            className="bg-[#053465] border-2 border-[#053465] rounded-lg text-white py-2 px-4 font-medium hover:scale-105"
                                        >
                                            Regístrate aquí
                                        </button>
                                        <button
                                            onClick={() =>
                                                navigate("/my-account")
                                            }
                                            className="border-white border-2 rounded-lg text-white py-2 px-4 font-medium hover:scale-105"
                                        >
                                            Inicia sesión
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-10 mt-2  mx-4 md:mx-10 ">
                        <p className="font-bold text-base xs:text-base md:text-xl 2xl:text-2xl mt-10">
                            Términos y Condiciones del Programa Loyalty
                        </p>
                        <ul className="marker:text-[#053465] list-disc pl-10 mt-4 text-sm md:text-lg  2xl:text-xl ">
                            <li>
                                El cliente elegirá el pack: Parrillero +
                                Cervezas, Dulcero o Snacks salados
                            </li>
                            <li>
                                La noche regalada será del precio promedio de
                                todas las noches que alquiló el cliente
                            </li>
                            <li>
                                No hay fecha de vencimiento: el cliente puede
                                acumular las noches de manera indefinida
                            </li>
                            <li>
                                Las noches empezarán a contar a partir de Julio
                                2023
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
