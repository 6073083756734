import React, { useEffect, useRef, useState } from "react";

const ToggleButton = ({ isToggled, onChangeToggle }) => {
    const [cambiando, setCambiando] = useState(true);
    const [initialState, setInitialState] = useState(isToggled);
    const [active, setActive] = useState(isToggled);
    const toggleRef = useRef(null);
    const backgroundRef = useRef(null);

    const onToggle = () => {
        if (initialState) {
            if (active) {
                toggleRef.current.classList.add("translate-x-[-1.9rem]");
                toggleRef.current.classList.replace(
                    "bg-blue-500",
                    "bg-gray-300"
                );
                backgroundRef.current.classList.replace(
                    "bg-blue-200",
                    "bg-gray-200"
                );
            } else {
                toggleRef.current.classList.remove("translate-x-[-1.9rem]");
                toggleRef.current.classList.replace(
                    "bg-gray-300",
                    "bg-blue-500"
                );
                backgroundRef.current.classList.replace(
                    "bg-gray-200",
                    "bg-blue-200"
                );
            }
        } else {
            if (!active) {
                toggleRef.current.classList.add("translate-x-[1.9rem]");
                toggleRef.current.classList.replace(
                    "bg-gray-300",
                    "bg-blue-500"
                );
                backgroundRef.current.classList.replace(
                    "bg-gray-200",
                    "bg-blue-200"
                );
            } else {
                toggleRef.current.classList.remove("translate-x-[1.9rem]");
                toggleRef.current.classList.replace(
                    "bg-blue-500",
                    "bg-gray-300"
                );
                backgroundRef.current.classList.replace(
                    "bg-blue-200",
                    "bg-gray-200"
                );
            }
        }
        setActive((prev) => !prev);
    };

    useEffect(() => {
        if (cambiando) {
            onChangeToggle(active);
        }
    }, [active]);

    return (
        <div
            ref={backgroundRef}
            className={`${
                initialState ? "bg-blue-200" : "bg-gray-200"
            } w-16 h-8 rounded-2xl relative flex items-center cursor-pointer duration-200 ease-linear select-none`}
            onClick={() => onToggle()}>
            <span
                ref={toggleRef}
                className={`${
                    initialState
                        ? "bg-blue-500 right-0.5"
                        : "bg-gray-300 left-0.5"
                } rounded-full duration-200 ease-linear h-[1.80rem] w-[1.80rem] absolute `}></span>
        </div>
    );
};

export default ToggleButton;
