import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";

const SidebarDetalle = ({
    tipo_producto,
    setShowFotos,
    setShowSobreAlojamiento,
    setShowPropiedades,
    setShowPrecios,
    setShowPoliticas,
    setShowReglas,
    setShowTarifasAdicionales,
    setShowConfiguracion,
    producto,
    publicar,
    cargando,
}) => {
    const INITIAL_STATE = {
        fotos: true,
        sobre_alojamiento: false,
        propiedades: false,
        reglas: false,
        politicas: false,
        precios: false,
        tarifas_adicionales: false,
    };

    const [buttons, setButtons] = useState(INITIAL_STATE);
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        setShowFotos(true);
    }, []);

    const setAllModalsFalse = () => {
        setShowFotos(false);
        setShowSobreAlojamiento(false);
        setShowPropiedades(false);
        setShowPrecios(false);
        setShowPoliticas(false);
        setShowReglas(false);
        setShowTarifasAdicionales(false);
        setShowConfiguracion(false);
    };

    const handleButtonClick = (name, setModal) => {
        setAllModalsFalse();
        setButtons({
            [name]: true,
        });
        setModal((prev) => !prev);
    };

    return (
        <div>
            <div
                className={`hidden lg:flex flex-col border p-6 mt-10   w-[272px] rounded-lg`}>
                <h1
                    onClick={() => handleButtonClick("fotos", setShowFotos)}
                    className={`${
                        buttons.fotos && "bg-[#053465]/30 text-white"
                    } p-4 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start`}>
                    Fotos
                </h1>
                <h1
                    onClick={() =>
                        handleButtonClick(
                            "sobre_alojamiento",
                            setShowSobreAlojamiento
                        )
                    }
                    className={`${
                        buttons.sobre_alojamiento &&
                        "bg-[#053465]/30 text-white"
                    } p-4 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start`}>
                    {tipo_producto === "casa"
                        ? "Sobre el alojamiento"
                        : "Sobre la embarcacion"}
                </h1>
                {/* <h1
                    onClick={() =>
                        handleButtonClick("propiedades", setShowPropiedades)
                    }
                    className={`${
                        buttons.propiedades && "bg-[#053465]/30 text-white"
                    } p-4 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start`}>
                    {tipo_producto === "casa"
                        ? "Propiedad y habitaciones"
                        : "Propiedades"}
                </h1> */}
                {tipo_producto === "casa" && (
                    <h1
                        onClick={() =>
                            handleButtonClick("reglas", setShowReglas)
                        }
                        className={`${
                            buttons.reglas && "bg-[#053465]/30 text-white"
                        } p-4 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start`}>
                        Reglas y Políticas
                    </h1>
                )}
                {/* <h1
                    onClick={() =>
                        handleButtonClick("politicas", setShowPoliticas)
                    }
                    className={`${
                        buttons.politicas && "bg-[#053465]/30 text-white"
                    } p-4 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start`}>
                    Políticas
                </h1> */}
                {tipo_producto === "casa" && (
                    <h1
                        onClick={() =>
                            handleButtonClick("precios", setShowPrecios)
                        }
                        className={`${
                            buttons.precios && "bg-[#053465]/30 text-white"
                        } p-4 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start`}>
                        Tarifas
                    </h1>
                )}
                {tipo_producto === "embarcacion" && (
                    <h1
                        onClick={() =>
                            handleButtonClick(
                                "tarifas_adicionales",
                                setShowTarifasAdicionales
                            )
                        }
                        className={`${
                            buttons.tarifas_adicionales &&
                            "bg-[#053465]/30 text-white"
                        } p-4 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start`}>
                        Tarifas
                    </h1>
                )}
                {(tipo_producto === "casa" || authUser.tipo_usuario < 3) && (
                    <h1
                        onClick={() =>
                            handleButtonClick(
                                "Configuraciones",
                                setShowConfiguracion
                            )
                        }
                        className={`${
                            buttons.Configuraciones &&
                            "bg-[#053465]/30 text-white"
                        } p-4 cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start`}>
                        Configuraciones
                    </h1>
                )}
            </div>
            {authUser.tipo_usuario < 3 && (
                <div
                    onClick={() => publicar()}
                    className="p-3 text-blue-500 border border-blue-500 px-4 w-[156px] rounded-lg hover:bg-blue-500 hover:text-white text-center mt-6 cursor-pointer hidden lg:block">
                    {cargando ? (
                        <Spinner width={7} />
                    ) : (
                        <p className="text-xl ">
                            {producto.data.publicado !== "Publicado"
                                ? "Publicar"
                                : "En revision"}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default SidebarDetalle;
