import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";
import { Paginado } from "../../helpers/Paginado";
import NavbarEmpresas from "../NavbarEmpresas";
import { useSelector } from "react-redux";
import ModalCrearPromociones from "./ModalCrearPromociones";
import ModalEditarPromociones from "./ModalEditarPromociones";
import { NavbarEmpresasNew } from "../NavbarEmpresasNew";
import { NavbarMobileEmpresasNew } from "../NavbarMobileEmpresasNew";
import ModalCuponesPorPagar from "./ModalCuponesPorPagar";
import ModalDetallePagos from "./ModaDetallePagos";

const CrearPromociones = () => {
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [modalCrearPromocion, setModalCrearPromocion] = useState(false);
    const [page, setPage] = useState(1);
    const [habilitados, setHabilitados] = useState(true);
    const [selectedPromocion, setSelectedPromocion] = useState(null);
    const [allPromociones, setAllPromociones] = useState({
        checking: true,
        total: 0,
        promociones: [],
    });
    const [dataPagoAll, setDataPagoAll] = useState({
        checking: true,
        total: 0,
        pagosMasivos: [],
    });
    const [verCuponesUsados, setVerCuponesUsados] = useState(false);
    const [idPago, setIdPago] = useState(null);
    const authUser = useSelector((state) => state.authReducer);

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const PROMOCIONES_PER_PAGE = 25;
    const PAGOS_PER_PAGE = 25;

    console.log("authUser", authUser);
    const getAllPromociones = async (queryValue = "", pageNumber = 1) => {
        setAllPromociones({
            checking: true,
            total: 0,
            promociones: [],
        });
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/promoEmpresas/all/${pageNumber}/${authUser.id_usuario}?nombre=${queryValue}`,
            { headers }
        );

        console.log(data);
        setAllPromociones({
            checking: false,
            total: data.total,
            promociones: data.data,
        });
    };
    const getPagosMasivos = async (pageNumber = 1) => {
        setDataPagoAll({
            checking: true,
            total: 0,
            pagosMasivos: [],
        });

        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/promoEmpresas/getPromoMasivo/${pageNumber}?nombre=${authUser.nombre_empresa}`,
                { headers }
            );
            console.log("pagosall ", data);
            setDataPagoAll({
                checking: false,
                total: data.total,
                pagosMasivos: data.data,
            });
        } catch (error) {
            console.error("Error al obtener pagos masivos:", error);
            setDataPagoAll({
                checking: false,
                total: 0,
                pagosMasivos: [],
            });
        }
    };

    useEffect(() => {
        getAllPromociones();
        getPagosMasivos();
    }, [habilitados]);

    const handleDelete = async (idPromo) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarla?",
            text: "Se eliminará la promoción definitivamente",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/promoEmpresas/eliminar/${idPromo}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Promoción eliminada correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllPromociones();
                    });
            }
        });
    };

    const handleEdit = (promocion) => {
        setSelectedPromocion(promocion);
        setModalEditVisible(!modalEditVisible);
    };

    const pagination = (pageNumber) => {
        setPage(pageNumber);
        getAllPromociones("", pageNumber);
    };

    const paginationPagos = (pageNumber) => {
        getPagosMasivos(pageNumber);
    };

    return (
        <>
            {/* <NavbarEmpresas selected="promociones" /> */}
            <NavbarEmpresasNew>
                {verCuponesUsados && (
                    <ModalCuponesPorPagar
                        setVerCuponesUsados={setVerCuponesUsados}
                    />
                )}
                {idPago && (
                    <ModalDetallePagos idPago={idPago} setIdPago={setIdPago} />
                )}
                <div className="flex flex-col">
                    <section className="w-full p-4 md:p-10 md:mx-auto">
                        <div className="flex flex-col lg:flex-row items-center justify-between w-full ">
                            <div className="flex-col items-start justify-start">
                                <h1 className="py-8 text-4xl text-left">
                                    Promociones
                                </h1>
                                <div className="text-base md: w-[30rem] text-justify">
                                    Crea promociones y gift cards para tus que
                                    tus colaboradores puedan disfrutar de unos
                                    días en familia.
                                </div>
                            </div>
                            <div className="flex gap-4 mt-2 md:ml-10">
                                <button
                                    className="flex items-center justify-center  p-2 text-blue-500 border border-blue-500 rounded-lg w-auto h-auto group hover:bg-blue-500 hover:text-white"
                                    onClick={() =>
                                        setModalCrearPromocion((prev) => !prev)
                                    }>
                                    <svg
                                        className="w-6 h-6 text-blue-500 group-hover:text-white"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <line
                                            x1="12"
                                            y1="5"
                                            x2="12"
                                            y2="19"
                                        />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    <h1 className="">Añadir promoción</h1>
                                </button>
                                <button
                                    className="flex items-center justify-center p-2 text-blue-500 border border-blue-500 rounded-lg w-auto h-auto group hover:bg-blue-500 hover:text-white"
                                    onClick={() =>
                                        setVerCuponesUsados((prev) => !prev)
                                    }>
                                    <h1 className="">Promociones usadas</h1>
                                </button>
                            </div>
                        </div>
                        {modalCrearPromocion && (
                            <ModalCrearPromociones
                                modalVisible={modalCrearPromocion}
                                setModalVisible={setModalCrearPromocion}
                                getAllPromociones={getAllPromociones}
                            />
                        )}
                        {modalEditVisible && (
                            <ModalEditarPromociones
                                modalVisible={modalEditVisible}
                                setModalVisible={setModalEditVisible}
                                promocion={selectedPromocion}
                                getAllPromociones={getAllPromociones}
                            />
                        )}

                        <div className="flex justify-center items-center lg:py-[5px] ">
                            <div className="flex w-full max-w-full overflow-x-auto text-center">
                                <div className="w-full">
                                    {allPromociones.checking ? (
                                        <Spinner width={10} margin_top={10} />
                                    ) : allPromociones.promociones.length >
                                      0 ? (
                                        <div>
                                            <table className="w-full mt-10 overflow-x-auto text-left">
                                                <thead className="text-left">
                                                    <tr>
                                                        <th className="px-4 w-full min-w-[8rem] md:w-2/12 ">
                                                            Nombre
                                                        </th>
                                                        <th className="w-1/12  min-w-[6rem] hidden md:table-cell ">
                                                            Monto
                                                        </th>
                                                        <th className="w-1/12  min-w-[8rem] hidden xl:table-cell ">
                                                            Cupones usados
                                                        </th>
                                                        <th className="w-1/12  min-w-[8rem] hidden md:table-cell ">
                                                            Fecha inicio
                                                        </th>
                                                        <th className="w-1/12  min-w-[8rem] hidden md:table-cell ">
                                                            Fecha final
                                                        </th>
                                                        <th
                                                            align="center"
                                                            className="w-[8%] min-w-[10rem] ">
                                                            Acciones
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allPromociones.promociones.map(
                                                        (promocion) => (
                                                            <tr
                                                                className="w-full border-t"
                                                                key={
                                                                    promocion.id
                                                                }>
                                                                <td className="flex p-4">
                                                                    {
                                                                        promocion.nombre
                                                                    }{" "}
                                                                </td>
                                                                <td className="hidden md:table-cell">
                                                                    {
                                                                        promocion.costo_asumido
                                                                    }{" "}
                                                                    {
                                                                        promocion.tipo_moneda
                                                                    }
                                                                </td>
                                                                <td className="hidden xl:table-cell">
                                                                    {
                                                                        promocion.uso_cupones_usados
                                                                    }
                                                                </td>
                                                                <td className="hidden md:table-cell">
                                                                    {
                                                                        promocion.fecha_inicio
                                                                    }
                                                                </td>
                                                                <td className="hidden md:table-cell">
                                                                    {
                                                                        promocion.fecha_final
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {habilitados && (
                                                                        <div className="flex justify-center ">
                                                                            <button
                                                                                className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                title="Editar"
                                                                                onClick={() =>
                                                                                    handleEdit(
                                                                                        promocion
                                                                                    )
                                                                                }>
                                                                                <svg
                                                                                    className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                    viewBox="0 0 24 24"
                                                                                    strokeWidth="2"
                                                                                    stroke="currentColor"
                                                                                    fill="none"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                    {" "}
                                                                                    <path
                                                                                        stroke="none"
                                                                                        d="M0 0h24v24H0z"
                                                                                    />{" "}
                                                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                                                    <line
                                                                                        x1="16"
                                                                                        y1="5"
                                                                                        x2="19"
                                                                                        y2="8"
                                                                                    />
                                                                                </svg>
                                                                            </button>
                                                                            <button
                                                                                className="items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                                onClick={() =>
                                                                                    handleDelete(
                                                                                        promocion.id
                                                                                    )
                                                                                }
                                                                                title="Eliminar">
                                                                                <svg
                                                                                    className="w-6 h-6 mx-auto text-blue-500 group-hover:text-white"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    strokeWidth="2"
                                                                                    stroke="currentColor"
                                                                                    fill="none"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                    {" "}
                                                                                    <path
                                                                                        stroke="none"
                                                                                        d="M0 0h24v24H0z"
                                                                                    />{" "}
                                                                                    <line
                                                                                        x1="4"
                                                                                        y1="7"
                                                                                        x2="20"
                                                                                        y2="7"
                                                                                    />{" "}
                                                                                    <line
                                                                                        x1="10"
                                                                                        y1="11"
                                                                                        x2="10"
                                                                                        y2="17"
                                                                                    />{" "}
                                                                                    <line
                                                                                        x1="14"
                                                                                        y1="11"
                                                                                        x2="14"
                                                                                        y2="17"
                                                                                    />{" "}
                                                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                            <div className="flex justify-center my-3">
                                                <Paginado
                                                    elementsPerPage={
                                                        PROMOCIONES_PER_PAGE
                                                    }
                                                    cantTotalElements={
                                                        allPromociones.total
                                                    }
                                                    pagination={pagination}
                                                    actualPage={page}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mt-10 text-lg font-medium">
                                            <h2>
                                                No se ha encontrado ningun
                                                administrador de empresa{" "}
                                                {!habilitados &&
                                                    "deshabilitado"}
                                            </h2>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="w-full p-4 md:p-10 md:mx-auto">
                        <h1 className="py-8 text-4xl text-left">
                            Pagos masivos realizados
                        </h1>
                        <div className="flex justify-center items-center lg:py-[5px] ">
                            <div className="flex w-full max-w-full overflow-x-auto text-center">
                                <div className="w-full">
                                    {dataPagoAll.checking ? (
                                        <Spinner width={10} margin_top={10} />
                                    ) : dataPagoAll.pagosMasivos.length > 0 ? (
                                        <div>
                                            <table className="w-full mt-10 overflow-x-auto text-left">
                                                <thead className="text-left">
                                                    <tr>
                                                        <th className="w-1/12 min-w-[6rem] hidden md:table-cell">
                                                            Monto Total
                                                        </th>
                                                        <th className="w-1/12 min-w-[8rem] hidden md:table-cell">
                                                            Método de Pago
                                                        </th>
                                                        <th className="w-1/12 min-w-[8rem] hidden md:table-cell">
                                                            Estado
                                                        </th>
                                                        <th className="w-1/12 min-w-[8rem] hidden md:table-cell">
                                                            Fecha de Pago
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataPagoAll.pagosMasivos.map(
                                                        (pago) => (
                                                            <tr
                                                                onClick={() =>
                                                                    setIdPago(
                                                                        pago.id
                                                                    )
                                                                }
                                                                className="w-full border-t cursor-pointer"
                                                                key={pago.id}>
                                                                <td className="hidden md:table-cell">
                                                                    {
                                                                        pago.monto_total
                                                                    }
                                                                </td>
                                                                <td className="hidden md:table-cell">
                                                                    {
                                                                        pago.metodo_pago
                                                                    }
                                                                </td>
                                                                <td className="hidden md:table-cell">
                                                                    {
                                                                        pago.estado
                                                                    }
                                                                </td>
                                                                <td className="hidden md:table-cell">
                                                                    {
                                                                        pago.fecha_pago.split(
                                                                            "T"
                                                                        )[0]
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                            <div className="flex justify-center my-3">
                                                <Paginado
                                                    elementsPerPage={
                                                        PAGOS_PER_PAGE
                                                    }
                                                    cantTotalElements={
                                                        dataPagoAll.total
                                                    }
                                                    pagination={paginationPagos}
                                                    actualPage={page}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mt-10 text-lg font-medium">
                                            <h2>
                                                No se ha encontrado ningún pago
                                                masivo realizado.
                                            </h2>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <NavbarMobileEmpresasNew />
            </NavbarEmpresasNew>
        </>
    );
};

export default CrearPromociones;
