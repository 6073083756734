import axios from "axios";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import NavBar from "../NavBar";
import StarsRating from "../StarsRating";
import ModalLogeo from "../alquilerScreen/alquilerAlojamiento/ModalLogeo";
import Spinner from "../Spinner";

const Reviews = () => {
    const { tipo, idPedido } = useParams();
    const navigate = useNavigate();
    const [habilitarModal, setHabilitarModal] = useState(false);
    const [modalLogeo, setModalLogeo] = useState(false);
    const [loading, setLoading] = useState(true);
    const [input, setInput] = useState({
        puntuacion: 1,
        comentarios: "",
        nombre: "",
        id_cliente: 0,
        producto: "",
        estado: "",
    });
    const authUser = useSelector((state) => state.authReducer);

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        handleDetails();
    }, []);

    useEffect(() => {
        handleDetails();
    }, [authUser]);

    const handleDetails = async (pedido) => {
        const json = await axios(
            `${process.env.REACT_APP_PROXY}/${tipo}/detalle/${idPedido}`,
            { headers }
        );

        if (json.data === "No existe este  pedido") {
            Swal.fire({
                title: "Error",
                text: "No existe este pedido",
                icon: "error",
                confirmButtonText: "Ok",
            }).then((result) => {
                if (result.value) {
                    navigate("/");
                }
            });
        } else if (json.data.error) {
            Swal.fire({
                title: "Error",
                text: `Debe iniciar session  para poder reseñar`,
                icon: "error",
                confirmButtonText: "Ok",
            }).then((result) => {
                if (result.value) {
                    setHabilitarModal(true);
                    setModalLogeo(true);
                }
            });
        } else {
            setInput({
                ...input,
                nombre: json.data.cliente,
                estado: json.data.estado,
                producto: json.data.casa
                    ? json.data.casa
                    : json.data.embarcacion,
                id_cliente: json.data.id_cliente,
            });
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!input.comentarios.length) {
            Swal.fire({
                title: "Error",
                text: "Debe ingresar un comentario",
                icon: "error",
                confirmButtonText: "Ok",
            });
        } else if (
            input.estado !== "Pago completo" &&
            input.estado !== "Devolución de garantía"
        ) {
            Swal.fire({
                title: "Error",
                text: "Aún no corresponde realizar la reseña, se habilitará despues del dia de la estadia",
                icon: "error",
                confirmButtonText: "Ok",
            });
            setLoading(false);
        } else {
            if (input.id_cliente === authUser.id_usuario) {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/${
                        tipo === "pedidoEmbarcacion"
                            ? "reviewEmbarcacion"
                            : "reviewCasa"
                    }/`,
                    tipo === "pedidoEmbarcacion"
                        ? {
                              ...input,
                              id_pedidos_embarcacion: idPedido,
                          }
                        : { ...input, id_pedidos_casa: idPedido },
                    { headers }
                );
                console.log(data);
                setLoading(false);
                if (data.message === "registro exitoso") {
                    const { data } = await axios.put(
                        `${process.env.REACT_APP_PROXY}/${
                            tipo === "pedidoEmbarcacion"
                                ? "pedidoEmbarcacion"
                                : "pedidoCasa"
                        }/actualizarEstado`,
                        {
                            id: idPedido,
                            estado:
                                tipo === "pedidoEmbarcacion"
                                    ? "Reseña de embarcación"
                                    : "Reseña de casa",
                        },
                        { headers }
                    );
                    console.log(data);
                    if (data.message === "Pedido actualizado con exito") {
                        Swal.fire({
                            title: "Reseña registrada",
                            text: " Gracias por su reseña",
                            icon: "success",
                            confirmButtonText: "Ok",
                        }).then((result) => {
                            if (result.value) {
                                navigate("/");
                            }
                        });
                    }
                }
            } else {
                Swal.fire({
                    title: "Error",
                    text: `Debe iniciar session con ${input.nombre} para poder reseñar`,
                    icon: "error",
                    confirmButtonText: "Ok",
                }).then((result) => {
                    if (result.value) {
                        setHabilitarModal(true);
                        setModalLogeo(true);
                        setLoading(false);
                    }
                });
            }
        }
    };

    return (
        <div className="mt-40">
            <NavBar />
            <div className="absolute w-full ">
                {habilitarModal && (
                    <ModalLogeo
                        modalLogeo={modalLogeo}
                        setModalLogeo={setModalLogeo}
                        id_cliente={input.id_cliente}
                    />
                )}
            </div>

            {loading ? (
                <Spinner width={40} />
            ) : input.estado === "Reseña de casa" ||
              input.estado === "Reseña de embarcación" ? (
                <div className="p-10 sm:p-36">
                    <div className="p-2 text-xl border-8 border-dotted sm:p-10 md:text-center md:text-3xl">
                        Ya se ha realizado la reseña de este pedido
                    </div>
                </div>
            ) : input.estado !== "Pago completo" &&
              input.estado !== "Devolución de garantía" ? (
                <div className="p-10 sm:p-36">
                    <div className="p-2 text-xl border-8 border-dotted sm:p-10 md:text-center md:text-3xl">
                        Aún no corresponde realizar la reseña, se habilitará
                        despues del dia de la estadia
                    </div>
                </div>
            ) : (
                <div className="w-4/5 mx-auto ">
                    <div className="flex flex-col">
                        <h1 className="mt-5 text-3xl font-bold text-center sm:text-5xl">
                            {input?.producto}
                        </h1>
                        <p className="mt-10 text-2xl font-semibold text-center ">
                            Reseña
                        </p>

                        <span className="text-lg text-center">
                            Tu comentario es muy importante para nosotros.
                        </span>
                        <span className="mt-3 text-lg text-center">
                            Como fue la experiencia en {input?.producto}?
                        </span>
                        <span className="mt-4 text-lg text-center">
                            Tu puntuación{" "}
                            <span className="text-red-500">*</span>
                        </span>
                        <div className="mx-auto ">
                            <StarsRating
                                starsQuantity={5}
                                setInput={setInput}
                            />
                        </div>
                        <form
                            className="flex flex-col mt-4 text-lg"
                            onSubmit={handleSubmit}>
                            <label>
                                Tu comentario{" "}
                                <span className="text-red-500">*</span>
                            </label>
                            <textarea
                                className="h-24 p-3 border-2 rounded-lg border-blue-bluecorner"
                                name="comentarios"
                                value={input.comentarios}
                                onChange={handleInputChange}
                            />
                            <label className="mt-4">Nombre</label>
                            <input
                                disabled={true}
                                className="p-3 font-semibold bg-gray-200 border-2 rounded-lg border-blue-bluecorner"
                                name="nombre"
                                value={input.nombre}
                                onChange={handleInputChange}
                            />

                            <button
                                type="submit"
                                className="items-center w-32 p-2 mx-auto mt-3 font-semibold text-center text-white bg-blue-700 rounded-lg items disabled:bg-slate-400 disabled:cursor-not-allowed"
                                disabled={
                                    input.comentarios.length < 10 ||
                                    !input.puntuacion
                                        ? true
                                        : false
                                }>
                                {" "}
                                Enviar
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Reviews;
