import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const EditarLink360 = ({ producto, setProducto, setModal, tipo_producto }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const [link360, setLink360] = useState(producto?.data.link360 || "");

    const handleInputChange = (e) => {
        setLink360(e.target.value);
    };

    const handleGuardar = async () => {
        setModal(false);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${tipo_producto}/actualizarDescripcion/${producto.data.id}`,
            {
                descripcion: producto.data.descripcion,
                link360: link360,
                id_usuario: authUser.id_usuario,
            },
            { headers }
        );
    };

    return (
        <div className="border p-4 rounded-lg">
            <h1>Tour 360°</h1>
            <Link
                to={"/paquetes-de-contenido"}
                className="text-blue-bluecorner flex mt-3 hover:underline cursor-pointer select-none">
                Consulte aquí por los servicios de Blue Corner para el tour 360°
                contactando con nosotros
            </Link>
            <div className="w-full mt-3 ">
                <textarea
                    onChange={(e) => {
                        handleInputChange(e);
                        setProducto({
                            ...producto,
                            data: {
                                ...producto.data,
                                link360: e.target.value,
                            },
                        });
                    }}
                    value={link360}
                    type={"text"}
                    placeholder="Link del Tour 360°"
                    className="w-full h-32 border my-2 bg-transparent rounded-lg py-3 px-3 outline-none focus:outline-none "
                />
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarLink360;
