import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../../Spinner";
import ModalAddServices from "./ModalAddServices";
import ModalEditServices from "./ModalEditServices";

const EditarCupones = ({ producto, setProducto, setModal, tipo_producto }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [precios, setPrecios] = useState({
        checking: true,
        data: [],
    });
    const [fakeRefresh, setFakeRefresh] = useState(false);
    const [precio, setPrecio] = useState({});
    const [horarios, setHorarios] = useState([]);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        const func = async () => {
            if (tipo_producto === "casa") {
                const json = await axios.get(
                    `${process.env.REACT_APP_PROXY}/casaCupones/all/${producto.data.id}`,
                    { headers }
                );

                setPrecios({
                    checking: false,
                    data: json.data.data,
                });
            }
            if (tipo_producto === "embarcacion") {
                const json = await axios.get(
                    `${process.env.REACT_APP_PROXY}/EmbarcacionCupones/all/${producto.data.id}`,
                    { headers }
                );
                setPrecios({
                    checking: false,
                    data: json.data.data,
                });
            }
        };
        func();
    }, [fakeRefresh, producto.data.id]);

    const handleEdit = (editRango) => {
        setPrecio(editRango);
        setModalEditVisible(!modalEditVisible);
    };

    const handleDelete = (idServicio) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminar el cupon?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                if (tipo_producto === "casa") {
                    const { data } = axios.delete(
                        `${process.env.REACT_APP_PROXY}/casaCupones/eliminar/${idServicio}`,
                        { headers }
                    );
                }
                // if (tipo_producto === "embarcacion") {
                //     const { data } = axios.delete(
                //         `${process.env.REACT_APP_PROXY}/embarcacionPersonasAdicional/eliminar/${idServicio}`,
                //         { headers }
                //     );
                // }
                Swal.fire({
                    title: "Cupon eliminado correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setFakeRefresh(!fakeRefresh);
            }
        });
    };

    const handleGuardar = async () => {
        setModal(false);
    };

    return (
        <div className="p-4 border rounded-lg">
            <div>
                <div>
                    <div className="flex items-center justify-between w-full">
                        <h1>Cupones</h1>
                        <div>
                            <div className="flex">
                                <button
                                    className="flex items-center px-3 py-2 border rounded-lg text-blue-bluecorner border-blue-bluecorner group"
                                    onClick={() =>
                                        setModalVisible(!modalVisible)
                                    }>
                                    <svg
                                        className="w-3 h-3 text-blue-bluecorner"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    <span className="items-center">Crear</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {precios.checking ? (
                            <Spinner width={20} />
                        ) : (
                            precios.data?.map((precio) => {
                                return (
                                    <div
                                        className="w-full mt-5"
                                        key={precio.id}>
                                        <div className="flex items-center justify-between">
                                            <p>
                                                <span className="">
                                                    Cupon{" "}
                                                    <strong>
                                                        {precio.cupon}
                                                    </strong>{" "}
                                                    que expira en{" "}
                                                    {precio.fecha_expiracion},
                                                    descuento de (
                                                    {precio.tipo_moneda}){" "}
                                                    {precio.descuento_bc > 0 &&
                                                    precio.descuento_propietario >
                                                        0
                                                        ? `${precio.descuento_bc} por BC y ${precio.descuento_propietario} por propietario`
                                                        : precio.descuento_bc >
                                                          0
                                                        ? `${precio.descuento_bc} por BC`
                                                        : `${precio.descuento_propietario} por propietario`}
                                                </span>
                                                <span>
                                                    {" "}
                                                    - cliente "{precio.cliente}"
                                                </span>
                                            </p>
                                            <div className="flex">
                                                <span
                                                    className="p-1 mx-2 border rounded cursor-pointer border-blue-bluecorner"
                                                    onClick={() =>
                                                        handleEdit(precio)
                                                    }>
                                                    <svg
                                                        className="w-6 h-6 text-blue-bluecorner"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                        <line
                                                            x1="16"
                                                            y1="5"
                                                            x2="19"
                                                            y2="8"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    className="p-1 border rounded cursor-pointer border-blue-bluecorner"
                                                    onClick={() => {
                                                        handleDelete(precio.id);
                                                    }}>
                                                    <svg
                                                        className="w-6 h-6 text-blue-bluecorner"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <line
                                                            x1="4"
                                                            y1="7"
                                                            x2="20"
                                                            y2="7"
                                                        />{" "}
                                                        <line
                                                            x1="10"
                                                            y1="11"
                                                            x2="10"
                                                            y2="17"
                                                        />{" "}
                                                        <line
                                                            x1="14"
                                                            y1="11"
                                                            x2="14"
                                                            y2="17"
                                                        />{" "}
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <hr className="mt-1" />
                                    </div>
                                );
                            })
                        )}
                    </div>
                    <ModalAddServices
                        fakeRefresh={fakeRefresh}
                        setFakeRefresh={setFakeRefresh}
                        producto={producto}
                        setProducto={setProducto}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        tipo_producto={tipo_producto}
                    />
                    {precio.id && (
                        <ModalEditServices
                            fakeRefresh={fakeRefresh}
                            setFakeRefresh={setFakeRefresh}
                            producto={producto}
                            modalEditVisible={modalEditVisible}
                            editRango={precio}
                            setPrecio={setPrecio}
                            setModalEditVisible={setModalEditVisible}
                            tipo_producto={tipo_producto}
                        />
                    )}
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarCupones;
