import {
    GET_ALL_BOATS,
    CREATE_LABEL,
    RESET_CREATE_LABEL,
    ONE_LABELS,
    SEARCH_PRODUCT,
    RESET_ONE_LABEL,
    ENABLE_BOATS,
    RESET_ENEBLE_BOATS,
} from "../actions/boatsActions";

const initialState = {
    boats: [],
    create: false,
    oneLabels: [],
    enableBoats: [],
};

export const boatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_BOATS:
            return {
                ...state,
                boats: action.payload,
            };
        case CREATE_LABEL:
            return {
                ...state,
                create: action.payload,
            };
        case RESET_CREATE_LABEL:
            return {
                ...state,
                create: false,
            };
        case ONE_LABELS:
            return {
                ...state,
                oneLabels: action.payload,
            };
        case SEARCH_PRODUCT:
            return {
                ...state,
                boats: action.payload,
            };
        case RESET_ONE_LABEL:
            return {
                ...state,
                oneLabels: action.payload,
            };
        case ENABLE_BOATS:
            return {
                ...state,
                enableBoats: action.payload,
            };
        case RESET_ENEBLE_BOATS:
            return {
                ...state,
                enableBoats: [],
            };

        default:
            return state;
    }
};
