import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ToggleButton from "../../ToggleButton";

const EditarCasaTop = ({ producto, setProducto, setModal, tipo_producto }) => {
    const [toggleButton, setToggleButton] = useState(
        producto.data.casa_top === "Si" ? true : false
    );
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleGuardar = async () => {
        let top = toggleButton ? "Si" : "No";
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/casa/updateTop/${producto.data.id}`,
            {
                top,
            },

            { headers }
        );
        console.log(json);
        if (json.data.message === "actualizado con exito") {
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    casa_top: top,
                },
            });
            setModal(false);
        }
    };

    return (
        <div className="p-4 border rounded-lg">
            <div>
                <div>
                    <div className="flex items-center justify-between w-full">
                        <h1>Casa Top</h1>

                        <div className="flex items-center">
                            <p className="mr-2">
                                {!toggleButton ? "No" : "Si"}
                            </p>
                            <ToggleButton
                                isToggled={toggleButton}
                                onChangeToggle={(e) => setToggleButton(e)}
                            />
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarCasaTop;
