import axios from "axios";
import React, { useState, useEffect } from "react";
import { CRMNotaEmpresas } from "./actividades/CRMNotaEmpresas";

export const CRMDetalleEmpresa = ({ detalleEmpresa, setDetalleEmpresa }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [empresa, setEmpresa] = useState(null);
    const getDetalle = async () => {
        const jsonembar = await axios.get(
            `${process.env.REACT_APP_PROXY}/empresas/detalle/${detalleEmpresa.id}`,
            { headers }
        );
        console.log(jsonembar.data);
        setEmpresa(jsonembar.data);
    };
    useEffect(() => {
        getDetalle();
    }, []);
    console.log(detalleEmpresa);
    return (
        <div>
            <div
                className={
                    "flex items-center text-lg cursor-pointer pt-4 px-4 mb-3 "
                }
                onClick={() =>
                    setDetalleEmpresa({
                        bandera: false,
                        id: "",
                    })
                }>
                <svg
                    className="w-6 h-6 -ml-2 text-black"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    {" "}
                    <polyline points="15 18 9 12 15 6" />
                </svg>
                <span className="mx-1">Regresar</span>
            </div>
            <div className="sm:flex">
                <div className="lg:h-[50vh] sm:h-[60vh] px-1 py-1 sm:w-3/5 border-y sm:mx-2 sm:border-x">
                    <div className="">
                        <p className="pl-2 text-2xl font-semibold sm:text-4xl">
                            {empresa?.nombre}
                        </p>
                        <div className="pl-2 lg:flex">
                            <div className="w-1/2">
                                <p className="text-lg sm:text-xl text-[053465] mt-1 font-semibold">
                                    Empresa
                                </p>
                                <div className="grid lg:grid-cols-[30%,70%]">
                                    <p className="text-sm font-semibold sm:text-base ">
                                        Nombre:
                                    </p>
                                    <p className="pt-1 text-sm">
                                        {empresa?.nombre}
                                    </p>
                                </div>
                                <div className="grid lg:grid-cols-[30%,70%]">
                                    <p className="text-sm font-semibold sm:text-base ">
                                        Direccion:
                                    </p>
                                    <p className="pt-1 text-sm">
                                        {empresa?.direccion}
                                    </p>
                                </div>
                                <div className="grid lg:grid-cols-[30%,70%]">
                                    <p className="text-sm font-semibold sm:text-base ">
                                        Documento:
                                    </p>
                                    <p className="pt-1 text-sm">
                                        {empresa?.tipo_documento}{" "}
                                        {empresa?.numero_documento}
                                    </p>
                                </div>
                            </div>
                            {/* encargado */}
                            <hr className="my-2 sm:hidden" />

                            <div className="w-1/2 ">
                                <p className="text-lg sm:text-xl text-[053465] mt-1 font-semibold">
                                    Encargado
                                </p>
                                <div className="grid w-full lg:grid-cols-[30%,70%]">
                                    <p className="text-sm font-semibold sm:text-base ">
                                        Nombre:
                                    </p>
                                    <div className="pt-1 text-sm">
                                        <span>
                                            {empresa?.admin_empresa.nombre}
                                        </span>
                                        <span className="ml-2 ">
                                            {
                                                empresa?.admin_empresa
                                                    .apellido_paterno
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="grid lg:grid-cols-[30%,70%]">
                                    <p className="text-sm font-semibold sm:text-base ">
                                        Correo:
                                    </p>
                                    <p className="pt-1 text-sm ">
                                        {empresa?.admin_empresa.correo}
                                    </p>
                                </div>
                                <div className="grid lg:grid-cols-[30%,70%]">
                                    <p className="text-sm font-semibold sm:text-base ">
                                        Telefono:
                                    </p>
                                    <p className="pt-1 text-sm">
                                        {empresa?.admin_empresa.celular}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-2 sm:w-2/5 sm:mt-0">
                    <CRMNotaEmpresas empresa={empresa} />
                </div>
            </div>
        </div>
    );
};
