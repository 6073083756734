import React from "react";
import getPriceAditionalService from "../helpers/getTotalPrice/getPriceAditionalService";

const getTotalAditionalServices = (
    aditionalServices,
    cantDays,
    selectedDays,
    currentProduct
) => {
    let totalPrice = 0;

    aditionalServices.forEach((service) => {
        totalPrice += Number(
            getPriceAditionalService(
                service,
                cantDays,
                selectedDays,
                currentProduct
            )
        );
    });

    return totalPrice;
};

export default getTotalAditionalServices;
