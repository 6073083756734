import React, { useState } from "react";
import { useNavigate } from "react-router";
import LinkEmpresa from "./LinkEmpresa";

const ModalOptions = ({ modalVisible, setmodalVisible, empresaSelected }) => {
    const [linkEmpresa, setLinkEmpresa] = useState(null);
    const navigate = useNavigate();

    const handleClose = () => {
        setmodalVisible(!modalVisible);
    };

    console.log(empresaSelected);
    return (
        <div className="fixed top-0 left-0 z-20 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
            <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 ">
                {linkEmpresa && (
                    <LinkEmpresa
                        empresa={empresaSelected}
                        setLinkEmpresa={setLinkEmpresa}
                    />
                )}
                <div className="relative w-full block justify-center px-4 py-16 bg-white rounded-lg md:w-96 dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto">
                    <button
                        onClick={handleClose}
                        className="absolute text-gray-800 dark:text-gray-400 top-8 right-4 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                        <svg
                            className="rounded-full hover:bg-gray-600 hover:text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <div className="flex flex-col mt-10 items-center gap-5">
                        <button
                            className="border border-blue-bluecorner text-blue-bluecorner hover:scale-105 p-3 rounded-md min-w-[13rem]"
                            onClick={() =>
                                navigate(
                                    `/dashboard/empleados-empresa/${empresaSelected.id}/${empresaSelected.nombre}`
                                )
                            }>
                            Ver empleados de empresa
                        </button>
                        <button className="border border-blue-bluecorner text-blue-bluecorner hover:scale-105 p-3 rounded-md min-w-[13rem]" onClick={() => setLinkEmpresa(prev => !prev)}>
                            Ver Link de logeo empresa
                        </button>
                        <button className="border border-blue-bluecorner text-blue-bluecorner hover:scale-105 p-3 rounded-md min-w-[13rem]" onClick={() => navigate(`/dashboard/historial-membresias/${empresaSelected.id}/${empresaSelected.nombre}`)}>
                            Ver historial de membresias
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalOptions;
