import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import colores from "../../helpers/colores";
import dividirFechaDatePicker from "../../helpers/dividirFechaDatePicker";
import NavBar from "../../NavBar";
import Spinner from "../../Spinner";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

export const CheckoutPaquetesEmpresa = () => {
    registerLocale("es", es);

    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const navigate = useNavigate();
    const [termsAccepted, setTermsAccepted] = useState(false);

    const [carrito, setCarrito] = useState([
        JSON.parse(localStorage.getItem("carrito")),
        ...JSON.parse(localStorage.getItem("servicios_completos")),
    ]);
    const [datosCliente, setDatosCliente] = useState(null);
    const [cargando, setCargando] = useState(false);
    const [input, setInput] = useState({
        hora_inicio: "",
        hora_final: "",
        numero_ninos: "",
        numero_adultos: "",
        fecha_evento: "",
    });
    const sumaTotal = () => {
        let total = 0;

        carrito.map((item) => {
            total += item.precio;
        });

        return total;
    };
    const onepropertie = async (id) => {
        if (authUser.tipo_usuario < 3) {
            const jsonAdmin = await axios.get(
                `${process.env.REACT_APP_PROXY}/autenticacion/detalle/${authUser.id_usuario}`,
                { headers }
            );

            setDatosCliente(jsonAdmin.data.data);
        } else if (authUser.tipo_usuario === 6 || authUser.tipo_usuario === 7) {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/adminEmpresas/detalle/${authUser.id_usuario}`,
                { headers }
            );

            setDatosCliente(data.data);
        } else {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}${
                    authUser.tipo_usuario === 4
                        ? "/subpropietario/detalle/"
                        : authUser.tipo_usuario === 3
                        ? "/datosCliente/detalle/"
                        : "/cliente/detalle/"
                }${authUser.id_usuario}`,
                { headers }
            );

            setDatosCliente(json.data.data);
        }
    };

    const eliminarPaquete = (i) => {
        let filtrar = carrito.filter((item, index) => index !== i);

        setCarrito(carrito.filter((item, index) => index !== i));

        let filtrolocalStorage = filtrar.splice(1);

        localStorage.setItem(
            "servicios_completos",
            JSON.stringify(filtrolocalStorage)
        );

        // setListaDePaquetes(filtrar);
    };

    const handleCreate = async () => {
        if (input.fecha_evento === "") {
            Swal.fire({
                title: "Error!",
                text: "debes agregar la fecha del evento",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else if (input.hora_final === "" || input.hora_inicio === "") {
            Swal.fire({
                title: "Error!",
                text: "debes agregar la hora de inicio y hora final",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else if (input.numero_adultos < 1) {
            Swal.fire({
                title: "Error!",
                text: "debes agregar el numero de adultos que asistiran",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
        } else {
            setCargando(true);
            if (authUser.tipo_usuario > 2 && datosCliente !== null) {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}${
                        authUser.tipo_usuario === 4
                            ? "/subpropietario/actualizar/"
                            : authUser.tipo_usuario === 3
                            ? "/propietario/actualizar/"
                            : "/cliente/actualizar/"
                    }${authUser.id_usuario}`,
                    datosCliente,
                    { headers }
                );
            } else if (
                authUser.tipo_usuario === 6 ||
                authUser.tipo_usuario === 7
            ) {
                const { data } = await axios.put(
                    `${process.env.REACT_APP_PROXY}/adminEmpresas/actualizar/${authUser.id_usuario}`,
                    datosCliente,
                    { headers }
                );
            }
            // let newcarrito = carrito;
            // let servicios = newcarrito.splice(1);

            let detalle_pedido = [];
            carrito?.map((servicio, i) => {
                if (i !== 0) {
                    detalle_pedido.push({
                        id_paquete_empresa: servicio.id,
                        nombre_paquete: servicio.nombre,
                        descripcion_paquete: servicio.descripcion,
                        costo: servicio.precio,
                    });
                }
            });

            console.log({
                id_cliente: authUser.id_usuario,
                id_paquete_empresa: carrito[0].id,
                costo: sumaTotal(),
                costo_paquete: carrito[0].precio,
                fecha_evento: input.fecha_evento,
                hora_inicio: input.hora_inicio,
                hora_final: input.hora_final,
                numero_adultos: input.numero_adultos,
                numero_ninos:
                    input.numero_ninos === "" ? 0 : input.numero_ninos,

                detalle_pedido: detalle_pedido,
            });
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/pedidoPaquetesEmpresas/`,
                {
                    id_cliente: authUser.id_usuario,
                    id_paquete_empresa: carrito[0].id,
                    costo: sumaTotal(),
                    costo_paquete: carrito[0].precio,
                    fecha_evento: input.fecha_evento,
                    hora_inicio: input.hora_inicio,
                    hora_final: input.hora_final,
                    numero_adultos: input.numero_adultos,
                    numero_ninos:
                        input.numero_ninos === "" ? 0 : input.numero_ninos,

                    detalle_pedido: detalle_pedido,
                },
                {
                    headers,
                }
            );

            console.log(json.data);

            if (json.data.message === "registro exitoso") {
                Swal.fire({
                    title: "Paquete enviado correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });

                localStorage.setItem("carrito", JSON.stringify([]));

                navigate("/comprobando-disponibilidad-paquetes");
            }
            setCargando(false);
        }
    };

    useEffect(() => {
        onepropertie();
    }, []);
    console.log(input);
    console.log(carrito);

    return (
        <>
            <NavBar />

            <div
                className={`${
                    authUser.tipo_usuario !== null && authUser.tipo_usuario <= 2
                        ? "mt-[9.5rem] py-5"
                        : "mt-[8rem]"
                } `}>
                <div
                    className={
                        "flex items-center text-lg cursor-pointer pt-4 mt-2 px-4 "
                    }
                    onClick={() =>
                        navigate("/paquetes-empresariales/servicios")
                    }>
                    <svg
                        className="w-6 h-6 -ml-2 text-black"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {" "}
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                    <span className="mx-1">Regresar</span>
                </div>
                <div className="mt-4 md:w-6/12 md:mx-auto">
                    <table className="w-full border-2 border-gray-600 md:mt-1 bordered">
                        <thead>
                            <tr>
                                <th
                                    className="p-4 uppercase border-2 border-gray-600"
                                    colSpan={2}>
                                    Producto(s)
                                </th>
                                <th
                                    className="p-4 uppercase border-2 border-gray-600"
                                    colSpan={2}>
                                    Subtotal
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}>
                                    {/* <div className="flex flex-auto">detalle</div> */}

                                    <p className="flex items-center font-medium">
                                        <img
                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${carrito[0]?.imagenes[0]?.imagen_minificada}`}
                                            alt="Imagen principal"
                                            className="object-cover h-10 mt-2 mr-2 rounded-lg w-14"
                                        />
                                        {carrito[0]?.nombre}
                                    </p>
                                    <p className="text-xs font-light">
                                        {carrito[0]?.descripcion}
                                    </p>
                                </td>
                                <td
                                    className="p-2 border-2 border-gray-600"
                                    colSpan={1}>
                                    <span className="flex font-semibold ">
                                        {carrito[0]?.precio > 0
                                            ? carrito[0]?.precio + " USD"
                                            : "a definir"}
                                    </span>
                                </td>
                            </tr>
                            <tr className={carrito.length > 1 ? "" : "hidden"}>
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}>
                                    <h3>Servicios extras</h3>
                                </td>
                                <td
                                    className="p-2 font-semibold border-2 border-gray-600"
                                    colSpan={1}>
                                    <h3></h3>
                                </td>
                            </tr>
                            {carrito?.map((paquete, i) => {
                                return (
                                    <tr
                                        key={i}
                                        className={i === 0 ? "hidden" : ""}>
                                        <td
                                            className="p-4 border-2 border-gray-600"
                                            colSpan={3}>
                                            {/* <div className="flex flex-auto">detalle</div> */}

                                            <p className="flex items-center font-medium">
                                                <img
                                                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${paquete.imagenes[0]?.imagen_minificada}`}
                                                    alt="Imagen principal"
                                                    className="object-cover h-10 mt-2 mr-2 rounded-lg w-14"
                                                />
                                                {paquete.nombre}
                                                <span>
                                                    <svg
                                                        onClick={() =>
                                                            eliminarPaquete(i)
                                                        }
                                                        className={
                                                            i === 0
                                                                ? "hidden"
                                                                : "w-5 h-5 mt-1 ml-1 text-red-500 cursor-pointer"
                                                        }
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor">
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                    </svg>
                                                </span>
                                            </p>
                                            <p className="text-xs font-light">
                                                {paquete.descripcion}
                                            </p>
                                        </td>
                                        <td
                                            className="p-2 border-2 border-gray-600"
                                            colSpan={1}>
                                            <span className="flex font-semibold ">
                                                {paquete.precio > 0
                                                    ? paquete.precio + " USD"
                                                    : "a definir"}
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}

                            <tr className="text-white bg-blue-300">
                                <td
                                    className="p-4 border-2 border-gray-600"
                                    colSpan={3}>
                                    <h3>Total a abonar (valor referencial)</h3>
                                </td>
                                <td
                                    className="p-2 font-semibold border-2 border-gray-600"
                                    colSpan={1}>
                                    <h3>{sumaTotal()} USD</h3>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        className={`mt-6 rounded-lg border-2 border-gray-600 text-[${colores.primario}] p-5 text-xl`}>
                        <h1>Detalle</h1>
                        <hr className="my-5 border-t-2 border-gray-300" />

                        <div className="">
                            <div className="relative grid w-10/12 grid-cols-2 gap-4 mx-auto mt-2">
                                <div className="flex flex-col ">
                                    <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                        Hora inicial:
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="time"
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                hora_inicio: e.target.value,
                                            })
                                        }
                                        value={input.hora_inicio}
                                        className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                    />
                                </div>
                                <div className="flex flex-col ">
                                    <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                        Hora final:
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="time"
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                hora_final: e.target.value,
                                            })
                                        }
                                        value={input.hora_final}
                                        className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                    />
                                </div>
                            </div>
                            <div className="relative grid w-10/12 grid-cols-2 gap-4 mx-auto mt-2">
                                <div className="flex flex-col ">
                                    <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                        Adultos:
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="number"
                                        name="numero_adultos"
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                numero_adultos: e.target.value,
                                            })
                                        }
                                        value={input.numero_adultos}
                                        className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                        placeholder="Numero de adultos"
                                    />
                                </div>
                                <div className="flex flex-col ">
                                    <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                        Niños:
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="number"
                                        name="numero_ninos"
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                numero_ninos: e.target.value,
                                            })
                                        }
                                        value={input.numero_ninos}
                                        className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50"
                                        placeholder="Numero de niños"
                                    />
                                </div>
                            </div>
                            <div className="relative grid w-10/12 gap-4 mx-auto mt-2 grid-cols-">
                                <div className="flex flex-col ">
                                    <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                        Fecha de evento:
                                    </label>
                                    <DatePicker
                                        className=" w-full py-2 pl-4 pr-4 mx-auto rounded-lg cursor-pointer text-sm border-[#e5e7eb] border"
                                        placeholderText="Fecha de evento"
                                        locale="es"
                                        autoComplete="off"
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            input.fecha_evento !== ""
                                                ? new Date(
                                                      dividirFechaDatePicker(
                                                          input.fecha_evento
                                                      )[0],
                                                      dividirFechaDatePicker(
                                                          input.fecha_evento
                                                      )[1] - 1,
                                                      dividirFechaDatePicker(
                                                          input.fecha_evento
                                                      )[2]
                                                  )
                                                : input.fecha_evento
                                        }
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                fecha_evento: `${e.getFullYear()}-${`${
                                                    e.getMonth() + 1
                                                }`.padStart(
                                                    2,
                                                    "0"
                                                )}-${`${e.getDate()}`.padStart(
                                                    2,
                                                    "0"
                                                )}`,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`my-6 rounded-lg border-2 border-gray-600 text-[${colores.primario}] p-5 text-xl`}>
                        <h1>Requiere Confirmación</h1>
                        <hr className="my-5 border-t-2 border-gray-300" />

                        <p className="text-base">
                            Pago con tarjeta de crédito/débito cuenta con un
                            costo adicional del 5% del monto a pagar.
                        </p>
                        <div className="flex mt-5 text-xs">
                            <span>
                                <input
                                    type="checkbox"
                                    className="mr-2 outline-none cursor-pointer"
                                    onChange={(e) =>
                                        setTermsAccepted(e.target.checked)
                                    }
                                />
                                He leído y estoy de acuerdo con los
                                <a
                                    className={`text-[${colores.secundario}] mx-1`}
                                    href="/terminos-y-condiciones"
                                    target="_blank">
                                    Términos y Condiciones
                                </a>
                                y
                                <a
                                    className={`text-[${colores.secundario}] mx-1`}
                                    href="politicas-y-privacidad"
                                    target="_blank">
                                    Políticas de uso y privacidad
                                </a>{" "}
                                <span className="text-2xl text-red-500">*</span>
                            </span>
                        </div>
                        <div className="w-full mt-3">
                            <hr className="my-3"></hr>
                            <label className="">
                                Teléfono{" "}
                                <span className="text-red-600 ">*</span>{" "}
                            </label>
                            <input
                                // disabled={editar ? false : true}
                                type="number"
                                value={datosCliente?.celular || ""}
                                name="userCelular"
                                onChange={(event) =>
                                    setDatosCliente({
                                        ...datosCliente,
                                        celular: event.target.value,
                                    })
                                }
                                placeholder="Celular"
                                className="w-full p-3 my-3 mt-2 bg-transparent border border-blue-500 rounded-lg outline-none focus:outline-none"
                            />
                        </div>
                        <button
                            id="comprobar_disponibilidad"
                            className={`border-[${colores.secundario}] bg-[${colores.secundario}] border hover:bg-white hover:text-[${colores.secundario}] disabled:bg-gray-400 disabled:border-white disabled:text-white disabled:cursor-not-allowed text-white font-semibold p-3 rounded-md mt-10 mb-6 text-base w-full lg:w-64 lg:ml-auto flex justify-center`}
                            disabled={
                                !termsAccepted ||
                                datosCliente?.celular?.length < 5 ||
                                cargando ||
                                carrito?.length === 0
                            }
                            onClick={() => handleCreate()}>
                            {cargando ? (
                                <Spinner width={7} />
                            ) : (
                                "Comprobar disponibilidad"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
