import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const TITLES = [
    {
        id: 1,
        title: "Usuarios",
        url: "usuarios",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 62.39">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m53.4,27.32c1.41.22,2.72.32,4,.64,8.74,2.17,15.03,10.14,15.08,19.14.01,2.64.02,5.28,0,7.92-.04,4.06-3.3,7.34-7.36,7.35-10.06.03-20.12.02-30.18,0-4.06,0-7.38-3.29-7.35-7.34.02-3.68-.31-7.38.23-11.05,1.27-8.59,9.01-15.79,17.68-16.5.34-.03.68-.1,1.36-.2-3.87-1.13-6.71-3.13-8.63-6.34-1.89-3.18-2.51-6.57-1.65-10.19C38.1,4.3,43.7-.08,50.3,0c6.36.07,11.95,4.75,13.24,11.08,1.44,7.03-2.04,12.65-10.14,16.25Z"
                        />
                        <path
                            fill="white"
                            d="m17.38,24.84c-7.04-2.13-9.24-8.37-8.29-13.13,1.15-5.76,5.83-9.33,11.87-9.16,5.08.14,9.72,4.39,10.41,9.66.76,5.81-2.66,11.05-8.79,12.69,3.26,0,6.03.78,8.64,2.2,1.15.62,1.15.7.17,1.57-5.69,5.06-8.55,11.4-8.58,19.02,0,1.86,0,3.73,0,5.59q0,1.64-1.7,1.64c-4.35,0-8.7,0-13.05,0-4.27,0-7.38-2.52-7.8-6.74-.4-4.09-.53-8.26,1.13-12.17,3.01-7.11,8.5-10.69,15.99-11.17Z"
                        />
                        <path
                            fill="white"
                            d="m82.24,24.91c9.43.16,16.59,6.75,17.53,15.9.27,2.59.34,5.2-.03,7.78-.53,3.7-3.61,6.32-7.36,6.33-4.58.02-9.17-.03-13.75.03-1.16.01-1.43-.42-1.43-1.49-.01-3.37.27-6.76-.31-10.11-1.01-5.89-3.8-10.78-8.3-14.71-.92-.8-.91-.91.17-1.5,2.62-1.43,5.41-2.25,8.53-2.22-7.12-2.13-9.48-8.2-8.57-13.09,1.09-5.78,5.76-9.4,11.78-9.27,5.15.11,9.83,4.36,10.53,9.67.76,5.81-2.67,11.04-8.82,12.69Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 2,
        title: "Crear promociones",
        url: "promociones",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.74 100">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m2.04,100C.57,99.28,0,98.12,0,96.46c.04-11.21.02-22.42.02-33.64,0-2.18.53-2.92,2.6-3.68,1.44-.52,2.86-1.06,4.31-1.56,1.96-.67,3.51.06,4.21,1.98,4.13,11.32,8.26,22.64,12.37,33.97.89,2.46.2,3.87-2.27,4.76-1.53.55-3.04,1.14-4.56,1.71H2.04Z"
                        />
                        <path
                            fill="white"
                            d="m92.12,46.46c.78-.86,1.46-1.67,2.21-2.42,1.39-1.4,3.27-1.48,4.51-.23,1.23,1.24,1.15,3.11-.25,4.51-2.43,2.45-4.86,4.89-7.31,7.31-1.55,1.54-3.23,1.52-4.79-.03-2.42-2.41-4.84-4.82-7.24-7.24-1.44-1.45-1.54-3.33-.26-4.58,1.25-1.22,3.1-1.11,4.51.28.74.73,1.45,1.48,2.18,2.23.36-.3.22-.61.23-.88,0-7.54.03-15.09,0-22.63-.03-8.29-5.55-14.93-13.68-16.49-1.08-.21-2.18-.32-3.28-.29-.08.35.19.43.34.57,10.84,9.4,12.06,26.18,2.64,37-5.89,6.76-13.39,9.8-22.3,9.21-11.74-.78-21.91-10.13-23.83-21.76C23.33,16.27,32.98,2.72,47.68.28,48.76.1,49.87,0,50.98,0c6.31.01,12.62-.02,18.93.01,10.84.05,20.48,8.66,21.81,19.42.14,1.1.18,2.2.18,3.31,0,7.48,0,14.96,0,22.44,0,.34-.1.7.23,1.29Zm-39.67-6.47c.92-.11,2.08-.2,3.21-.57,4.29-1.41,6.81-5.69,5.62-9.69-.81-2.74-2.82-4.37-5.35-5.45-2-.85-4.04-1.62-6.05-2.47-1.24-.52-1.38-1.77-.32-2.61.55-.44,1.22-.59,1.91-.65,1.82-.14,3.36.54,4.83,1.53,1.49,1.01,3.12.75,4.05-.59.95-1.36.59-3.01-.9-4.07-1.17-.84-2.44-1.51-3.81-1.94-3.08-.96-6.12-.93-8.92.84-2.56,1.61-3.77,4.02-3.44,7.05.3,2.76,1.84,4.65,4.44,5.66,1.79.7,3.56,1.44,5.33,2.15.73.29,1.44.6,2.05,1.12,1.02.86,1.08,1.92.16,2.87-.69.7-1.55,1.06-2.53,1.11-2.19.12-4.1-.53-5.68-2.08-.82-.81-1.75-1.25-2.91-.95-2.15.56-2.83,3.13-1.22,4.81,2.52,2.63,5.68,3.8,9.51,3.93Z"
                        />
                        <path
                            fill="white"
                            d="m31.6,54.09c4.77.09,9.37,1.1,13.33,3.78,3.98,2.7,8.35,3.96,13,4.61,1.9.27,3.79.59,5.66,1.01,4.07.91,6.6,4.75,5.79,8.71-.82,4.01-4.56,6.53-8.68,5.84-2.5-.42-4.99-.88-7.49-1.31-3.97-.69-7.93-1.4-11.9-2.07-1.75-.3-3.18.63-3.47,2.18-.29,1.59.74,3.02,2.52,3.34,3.77.69,7.55,1.34,11.32,2,2.78.49,5.57.97,8.35,1.46,7.08,1.26,14.1-3.92,14.95-11.09.14-1.17.59-1.81,1.68-2.29,4.19-1.86,8.31-3.87,12.49-5.75,2.65-1.2,5.24-.89,7.56.84,4.41,3.29,3.94,9.95-.9,12.58-3.79,2.07-7.65,4.03-11.48,6.04-6.85,3.59-13.73,7.14-20.55,10.79-3.48,1.87-7.09,2.28-10.95,1.71-5.91-.89-11.83-1.65-17.75-2.47-1.58-.22-3.15-.44-4.73-.63-.69-.08-1.05-.42-1.3-1.09-4.01-11.06-8.04-22.11-12.06-33.17-.43-1.19-.43-1.21.79-1.64,3.34-1.17,6.58-2.65,10.14-3.09,1.22-.15,2.45-.32,3.69-.29Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 3,
        title: "Reservaciones",
        url: "reservaciones",
        textInfo:
            "Aca podras ver los pedidos de reservas que te haran los clientes!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.54 100">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m49.75,73.36c-13.18,0-26.36,0-39.54,0-2.33,0-3.81-1.47-3.7-3.65.07-1.4.92-2.61,2.33-2.98.88-.23,1-.68,1.02-1.46.13-4.61.59-9.18,1.81-13.66,2.56-9.41,8.55-15.56,17.67-18.73,4.8-1.67,9.77-2.49,14.84-2.72,2.23-.1,2.67-.68,2.18-2.91-.13-.59-.6-.49-.95-.62-1.55-.58-2.41-1.8-2.36-3.42.04-1.46.94-2.68,2.33-3.1.36-.11.75-.15,1.12-.15,2.18-.01,4.36-.02,6.54,0,1.77.02,3.13,1.14,3.4,2.76.3,1.81-.65,3.41-2.46,3.91-.71.19-.83.53-.84,1.16-.01,2.3-.03,2.24,2.32,2.37,6.05.32,11.94,1.35,17.52,3.84,8.03,3.58,12.86,9.79,15.02,18.23,1.09,4.28,1.53,8.65,1.64,13.06.02.8.2,1.2,1.04,1.44,1.67.47,2.56,2.08,2.29,3.82-.25,1.63-1.67,2.79-3.48,2.79-9.72,0-19.44,0-29.16,0-3.53,0-7.06,0-10.59,0Zm6.41-29.71c-1.32-.17-2.31.99-2.74,2.1-.84,2.14.65,4.19,3.11,4.39,2.28.18,4.53.52,6.71,1.24,2.81.94,4.81,2.67,5.55,5.65.11.43.28.86.5,1.25.79,1.35,2.4,1.9,3.86,1.36,1.42-.53,2.34-2.12,1.99-3.59-1.04-4.33-3.3-7.73-7.41-9.73-3.36-1.64-6.95-2.23-11.58-2.66Z"
                        />
                        <path
                            fill="white"
                            d="m49.65,99.99c-15.95,0-31.9,0-47.84,0-.31,0-.62-.02-.93,0-.84.08-1.02-.26-.76-1.04.94-2.75,1.86-5.51,2.76-8.28.19-.57.51-.84,1.09-.93,3.27-.5,5.22-2.38,5.72-5.66.15-.98.78-.88,1.44-.88,11.21,0,22.42,0,33.63,0q1.62,0,1.62-1.64t1.69-1.69c1.38,0,2.77.03,4.15,0,.73-.02.94.28.96.98.06,2.36.08,2.36,2.45,2.36,10.9,0,21.79.01,32.69-.02,1.02,0,1.45.26,1.6,1.32.39,2.68,2.54,4.77,5.25,5.16.92.13,1.36.53,1.63,1.41.78,2.55,1.66,5.07,2.5,7.6.43,1.3.42,1.31-.97,1.31-16.22,0-32.45,0-48.67,0Z"
                        />
                        <path
                            fill="white"
                            d="m46.37,6.67c0-1.14-.03-2.29,0-3.43C46.43,1.36,47.86,0,49.73,0c1.87,0,3.37,1.35,3.4,3.19.05,2.35.05,4.71,0,7.07-.04,1.85-1.53,3.2-3.4,3.19-1.83,0-3.3-1.42-3.36-3.25-.03-1.18,0-2.36,0-3.53Z"
                        />
                        <path
                            fill="white"
                            d="m19.93,16.77c-.04,1.36-.63,2.41-1.86,2.99-1.25.6-2.54.54-3.61-.36-1.43-1.2-2.76-2.52-3.95-3.96-1.07-1.31-.9-3.29.25-4.47,1.18-1.21,3.2-1.4,4.54-.3,1.43,1.19,2.73,2.55,3.96,3.95.51.59.72,1.33.67,2.13Z"
                        />
                        <path
                            fill="white"
                            d="m79.57,16.21c-.01-.5.36-1.3,1.01-1.97,1.06-1.09,2.12-2.18,3.23-3.23,1.47-1.38,3.63-1.38,4.94-.03,1.28,1.32,1.29,3.44-.06,4.88-1.09,1.16-2.21,2.29-3.38,3.37-1.13,1.03-2.48,1.2-3.86.55-1.28-.6-1.87-1.69-1.88-3.56Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 4,
        title: "Mi empresa",
        url: "miEmpresa",

        icono: (
            <svg
                className="w-8 h-8 text-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />
                <line x1="13" y1="7" x2="13" y2="7.01" />
                <line x1="17" y1="7" x2="17" y2="7.01" />
                <line x1="17" y1="11" x2="17" y2="11.01" />
                <line x1="17" y1="15" x2="17" y2="15.01" />
            </svg>
        ),
    },
    {
        id: 5,
        title: "Reseñas",
        url: "resenas",
        textInfo: "Aca podras ver todo el detalle de tus contactos",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.76 95.45">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m55.13,3.27l12.24,24.79,27.36,3.98c4.81.7,6.73,6.61,3.25,10l-19.8,19.3,4.67,27.25c.82,4.79-4.2,8.44-8.5,6.18l-24.47-12.87-24.47,12.87c-4.3,2.26-9.32-1.39-8.5-6.18l4.67-27.25L1.78,42.03c-3.48-3.39-1.56-9.3,3.25-10l27.36-3.98L44.62,3.27c2.15-4.36,8.36-4.36,10.51,0Z"
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 6,
        title: "Paquetes",
        url: "paquetes",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.76 95.45">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m55.13,3.27l12.24,24.79,27.36,3.98c4.81.7,6.73,6.61,3.25,10l-19.8,19.3,4.67,27.25c.82,4.79-4.2,8.44-8.5,6.18l-24.47-12.87-24.47,12.87c-4.3,2.26-9.32-1.39-8.5-6.18l4.67-27.25L1.78,42.03c-3.48-3.39-1.56-9.3,3.25-10l27.36-3.98L44.62,3.27c2.15-4.36,8.36-4.36,10.51,0Z"
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 7,
        title: "Favoritos",
        url: "favoritos",
        textInfo:
            "Aca podras ver los leads que te llegan, desde chats y pedidos!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 87.07 100">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m.04,54.11c0-7.86-.1-15.72.03-23.57.14-8.34,3.89-14.75,10.99-19.13,3.44-2.12,6.37-.48,6.58,3.58.32,6.3,4.67,11.61,10.77,13.11,1.1.27,2.21.4,3.35.4,7.82-.01,15.64.01,23.46-.01,7.7-.02,13.8-5.86,14.2-13.53.15-2.97,1.71-4.6,4.24-4.46.68.04,1.29.29,1.86.63,7.26,4.3,11.34,10.66,11.44,19.09.18,16.02.1,32.05.03,48.08-.05,10.94-9.36,20.72-20.26,21.54-1.59.12-3.18.15-4.78.15-12.84,0-25.67-.01-38.51,0-6.84.01-12.74-2.23-17.44-7.28C2.14,88.56.1,83.6.06,77.92c-.07-7.94-.02-15.87-.02-23.81Zm60.99-2.84c.04-2.35-1.44-3.81-3.63-3.79-1.19.01-2.04.62-2.83,1.41-5.24,5.26-10.5,10.5-15.75,15.75q-1.19,1.19-2.41-.04c-1.21-1.21-2.39-2.45-3.65-3.61-1.6-1.48-3.72-1.48-5.14-.08-1.42,1.41-1.48,3.57.02,5.13,2.4,2.5,4.84,4.94,7.34,7.34,1.66,1.59,3.7,1.56,5.31-.05,6.56-6.52,13.09-13.07,19.63-19.62.71-.71,1.17-1.55,1.11-2.45Z"
                        />
                        <path
                            fill="white"
                            d="m43.45,23.72c-3.81,0-7.62,0-11.44,0-5.31-.01-9.29-3.6-9.72-8.9-.17-2.09-.24-4.21.07-6.29C23.13,3.47,26.84.13,31.95.07c7.7-.09,15.41-.09,23.11,0,5.61.06,9.46,3.99,9.69,9.71.07,1.82.08,3.65-.11,5.48-.51,4.8-4.43,8.4-9.28,8.46-.08,0-.16,0-.23,0-3.89,0-7.78,0-11.67,0Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 8,
        title: "Estadisticas",
        url: "estadisticas",
        textInfo:
            "Aca podras configurar los diferentes medios de pago que te ofrece BLU!",
        icono: (
            <svg
                className="w-8 h-8 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                />
            </svg>
        ),
    },
];

export const BurguerMenuMobileNew = ({ setShowBurgerMenu, showBurgerMenu }) => {
    const [showChildren, setShowChildren] = useState(-1);
    const navigate = useNavigate();
    const {
        avatar,
        color_primario,
        color_secundario,
        tipo_usuario,
        membresia,
        dias_prueba,
        tipo_membresia,
        nombre_empresa,
        password_cambiado,
    } = useSelector((state) => state.authReducer);

    const handleNavigate = (title) => {
        console.log(title);
        if (title.url) {
            navigate(`/dashboard-empresas/${title.url}`);
            setShowBurgerMenu(false);
        } else {
            if (showChildren === title.id) {
                setShowChildren(-1);
            } else {
                setShowChildren(title.id);
            }
        }
    };

    const handleChildrenClick = (url) => {
        navigate(`/perfil/${url}`);
        setShowBurgerMenu(false);
    };

    const ocultar = (titulo) => {
        const nombres = [
            "Usuarios",
            "Crear promociones",
            "Mi empresa",
            "Paquetes",
            "Estadisticas",
        ];

        if (nombres.includes(titulo) && tipo_usuario === 7) {
            return "hidden";
        } else {
            return "";
        }
    };

    const opcionesPremium = (titulo) => {
        const nombres = ["Crear promociones", "Paquetes", "Estadisticas"];

        if (nombres.includes(titulo) && membresia === "No") {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div
            style={{ backgroundColor: color_primario }}
            className={`${
                showBurgerMenu ? "translate-x-0" : "translate-x-full"
            } sm:hidden fixed flex flex-col overflow-auto top-0 right-0 left-0 bottom-0 transition-all duration-500 ease-in-out z-50  text-white`}>
            <button
                onClick={() => setShowBurgerMenu(false)}
                className="fixed p-1 font-medium right-1 hover:underline w-max">
                Cerrar
            </button>
            <div className="mt-10">
                {TITLES.map((title) => (
                    <div
                        className={`transition ease-in  p-1 ${ocultar(
                            title.title
                        )}   ${
                            tipo_usuario === 6 &&
                            (title.id === 5 || title.id === 5)
                                ? "hidden"
                                : ""
                        }
                        `}
                        key={title.id}>
                        <div className={`flex flex-col`}>
                            <button
                                title={
                                    opcionesPremium(title.title) &&
                                    "opcion premium"
                                }
                                disabled={opcionesPremium(title.title)}
                                className={`flex items-center gap-2   ${
                                    opcionesPremium(title.title)
                                        ? " bg-slate-300 bg-opacity-20 mr-2 py-2 rounded-md pl-1 cursor-not-allowed"
                                        : " cursor-pointer"
                                }`}
                                onClick={() => handleNavigate(title)}>
                                <span className="text-white">
                                    {title.icono}
                                </span>
                                <span className="font-medium capitalize ">
                                    {title.title}
                                </span>
                                {opcionesPremium(title.title) && (
                                    <svg
                                        className="h-5 w-5 text-white"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <circle cx="12" cy="8" r="7" />{" "}
                                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
                                    </svg>
                                )}
                            </button>
                            <div
                                style={{
                                    height:
                                        showChildren === title.id
                                            ? `${
                                                  title.children &&
                                                  title.children.length * 2.5
                                              }rem`
                                            : "0rem",
                                }}
                                className={`${
                                    showChildren === title.id
                                        ? `opacity-100 visible`
                                        : "opacity-0 invisible"
                                } transition-all duration-300 ease-in-out`}>
                                {title.children &&
                                    title.children.map((children) => (
                                        <div
                                            onClick={() =>
                                                handleChildrenClick(
                                                    children.url
                                                )
                                            }
                                            key={children.id}
                                            className={`p-2 pl-10 hover:underline ${ocultar(
                                                children.id
                                            )}`}>
                                            {children.title}
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <hr className="mt-2" />
                    </div>
                ))}
            </div>
        </div>
    );
};
