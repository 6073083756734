import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Spinner from "../../../Spinner";
import ModalAddServices from "./ModalAddServices";
import ModalEditServices from "./ModalEditServices";
import ModalServiciosAvanzados from "./ModalServiciosAvanzados";

const EditarTarifas = ({ producto, setProducto, setModal, tipo_producto }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [servicios, setServicios] = useState({
        checking: true,
        data: [],
    });
    const [servicioAvanzado, setServicioAvanzado] = useState(null);
    const [fakeRefresh, setFakeRefresh] = useState(false);
    const [servicio, setServicio] = useState({});
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        const func = async () => {
            if (tipo_producto === "casa") {
                const { data } = await axios(
                    `${process.env.REACT_APP_PROXY}/productoServiciosAdicionales/all/${producto.data.id}`,
                    { headers }
                );
                console.log(data);
                setServicios({
                    checking: false,
                    data,
                });
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        servicios_adicionales: data,
                    },
                });
            }
            if (tipo_producto === "embarcacion") {
                const { data } = await axios(
                    `${process.env.REACT_APP_PROXY}/embarcacionServiciosAdicionales/all/${producto.data.id}`,
                    { headers }
                );
                console.log(data);
                setServicios({
                    checking: false,
                    data,
                });
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        servicios_adicionales: data,
                    },
                });
            }
        };
        func();
    }, [fakeRefresh, producto.data.id]);

    const handleEdit = (aditionalService) => {
        setServicio(aditionalService);
        setModalEditVisible(!modalEditVisible);
    };

    const handleDelete = (idServicio) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminar el servicio?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (tipo_producto === "casa") {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/productoServiciosAdicionales/eliminar/${idServicio}`,
                        {
                            data: { id_usuario: authUser.id_usuario },
                            headers: headers,
                        }
                    );
                    if (data.message === `Servicio eliminado correctamente!`) {
                        Swal.fire({
                            title: "Precio eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        setFakeRefresh(!fakeRefresh);
                    }
                }
                if (tipo_producto === "embarcacion") {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/embarcacionServiciosAdicionales/eliminar/${idServicio}`,
                        {
                            data: { id_usuario: authUser.id_usuario },
                            headers: headers,
                        }
                    );
                    if (data.message === `Servicio eliminado correctamente!`) {
                        Swal.fire({
                            title: "Precio eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        setFakeRefresh(!fakeRefresh);
                    }
                }
            }
        });
    };

    const handleGuardar = async () => {
        setModal(false);
    };

    return (
        <div className="border p-4 rounded-lg">
            <div>
                <div>
                    <div className="w-full flex items-center justify-between">
                        <h1>Tarifas</h1>
                        <div>
                            <div className="flex">
                                <button
                                    className="flex items-center px-3 py-2  text-blue-bluecorner border border-blue-bluecorner rounded-lg group"
                                    onClick={() =>
                                        setModalVisible(!modalVisible)
                                    }>
                                    <svg
                                        className="w-3 h-3 text-blue-bluecorner"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    <span className="items-center">Añadir</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {servicios.checking ? (
                            <Spinner width={10} />
                        ) : (
                            servicios.data.map((servicio) => {
                                return (
                                    <div
                                        className="w-full mt-5"
                                        key={servicio.id}>
                                        <div className="flex items-center justify-between">
                                            <span className="font-semibold">
                                                {servicio.nombre}:
                                            </span>
                                            <div className="flex">
                                                <span
                                                    className="mx-2 cursor-pointer border p-1 border-blue-bluecorner rounded"
                                                    onClick={() =>
                                                        handleEdit(servicio)
                                                    }>
                                                    <svg
                                                        className="w-6 h-6 text-blue-bluecorner"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                        <line
                                                            x1="16"
                                                            y1="5"
                                                            x2="19"
                                                            y2="8"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    className="cursor-pointer border p-1 border-blue-bluecorner rounded mr-2"
                                                    onClick={() => {
                                                        setServicioAvanzado({
                                                            id: servicio.id,
                                                            nombre: servicio.nombre,
                                                            moneda: servicio.tipo_moneda,
                                                        });
                                                    }}>
                                                    <svg
                                                        className="h-6 w-6 text-blue-bluecorner"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />{" "}
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="3"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    className="cursor-pointer border p-1 border-blue-bluecorner rounded"
                                                    onClick={() => {
                                                        handleDelete(
                                                            servicio.id
                                                        );
                                                    }}>
                                                    <svg
                                                        className="w-6 h-6 text-blue-bluecorner"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <line
                                                            x1="4"
                                                            y1="7"
                                                            x2="20"
                                                            y2="7"
                                                        />{" "}
                                                        <line
                                                            x1="10"
                                                            y1="11"
                                                            x2="10"
                                                            y2="17"
                                                        />{" "}
                                                        <line
                                                            x1="14"
                                                            y1="11"
                                                            x2="14"
                                                            y2="17"
                                                        />{" "}
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <hr className="mt-1" />
                                        {/* <span>{servicio.descripcion}</span> */}
                                        <span>{servicio.tipo}</span>
                                        <br />
                                        <span className=" text-gray-500">{`${servicio.precio} ${servicio.tipo_moneda}`}</span>
                                    </div>
                                );
                            })
                        )}
                    </div>
                    {modalVisible && (
                        <ModalAddServices
                            fakeRefresh={fakeRefresh}
                            setFakeRefresh={setFakeRefresh}
                            producto={producto}
                            setProducto={setProducto}
                            modalVisible={modalVisible}
                            setModalVisible={setModalVisible}
                            tipo_producto={tipo_producto}
                        />
                    )}
                    {modalEditVisible && servicio.id && (
                        <ModalEditServices
                            fakeRefresh={fakeRefresh}
                            setFakeRefresh={setFakeRefresh}
                            producto={producto}
                            setProducto={setProducto}
                            modalEditVisible={modalEditVisible}
                            aditionalService={servicio}
                            setServicio={setServicio}
                            setModalEditVisible={setModalEditVisible}
                            tipo_producto={tipo_producto}
                        />
                    )}
                    {servicioAvanzado !== null && (
                        <ModalServiciosAvanzados
                            setServicioAvanzado={setServicioAvanzado}
                            nombre={servicioAvanzado.nombre}
                            id={servicioAvanzado.id}
                            moneda={servicioAvanzado.moneda}
                            producto={producto}
                        />
                    )}
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarTarifas;
