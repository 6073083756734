import React from "react";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import AllCards from "./AllCards";
import ExplorerAnoNuevo from "./ExplorerAnoNuevo";

const PromoAnoNuevo = () => {
    return (
        <div>
            <NavBar />
            <ExplorerAnoNuevo />
            <AllCards />
            <Footer />
        </div>
    );
};

export default PromoAnoNuevo;
