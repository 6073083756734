import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";

const ProductLabels = ({ onboarding, setOnboarding, setPage, actualPage }) => {
    const dispatch = useDispatch();
    const [siguiente, setSiguiente] = useState("Siguiente");

    const [enviar, setEnviar] = useState(true);
    const [click, setClick] = useState(true);

    const [productLabels, setProductLabels] = useState([]);
    const [labelrandom, setLabelrandom] = useState([]);
    const [text, setText] = useState("");
    let matches = [];
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        random();
    }, []);

    useEffect(() => {
        if (labelrandom.length && enviar) {
            dispatch(etiquetas(onboarding.id));
            setEnviar(false);
        }
    }, [labelrandom]);

    // useEffect(() => {
    //     if (text.length) {
    //         labelName(text);
    //     } else {
    //         console.log("no hay texto");

    //         setProductLabels([]);
    //     }
    // }, [text]);

    console.log(productLabels);

    const labelName = async (text) => {
        console.log(text);
        if (text !== "") {
            if (onboarding.tipo_producto === "casa") {
                const json = await axios.get(
                    `${process.env.REACT_APP_PROXY}/etiqueta/allByRandom/casa?nombre=${text}`,
                    { headers }
                );

                let idDeEtiquetas = labelrandom.map((item) => item.id);

                matches = json.data.filter((label) => {
                    return !idDeEtiquetas.includes(label.id);
                });

                setProductLabels(matches);
            } else if (onboarding.tipo_producto === "embarcacion") {
                const json = await axios.get(
                    `${process.env.REACT_APP_PROXY}/etiqueta/allByRandom/embarcacion?nombre=${text}`,
                    { headers }
                );
                let idDeEtiquetas = labelrandom.map((item) => item.id);

                matches = json.data.filter((label) => {
                    return !idDeEtiquetas.includes(label.id);
                });
                setProductLabels(matches);
            }
        } else {
            setProductLabels([]);
        }
    };

    const random = async () => {
        if (onboarding.tipo_producto === "casa") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/etiqueta/allByRandom/casa`,
                { headers }
            );
            setLabelrandom(json.data);
        } else if (onboarding.tipo_producto === "embarcacion") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/etiqueta/allByRandom/embarcacion`,
                { headers }
            );
            setLabelrandom(json.data);
        }
    };

    const addLabel = (etiqueta) => {
        let etiquetasEncontradas = onboarding.etiquetas.filter(
            (etiquetas) => etiquetas.id === etiqueta.id
        );
        //  onboarding.etiquetas.map((l) => {
        //     if (l.id === etiqueta.id) {
        //         return etiqueta.id;
        //     }
        // });
        console.log(etiquetasEncontradas);
        if (!etiquetasEncontradas.length) {
            setOnboarding({
                ...onboarding,
                etiquetas: [...onboarding.etiquetas, etiqueta],
            });
        } else {
            let etiquetasFiltradas = onboarding.etiquetas.filter(
                (etiquetas) => {
                    if (etiquetas.id !== etiqueta.id) {
                        return etiquetas;
                    }
                }
            );

            setOnboarding({ ...onboarding, etiquetas: etiquetasFiltradas });
        }

        let deleteLabel = productLabels.filter((etiquetas) => {
            if (etiquetas.id !== etiqueta.id) {
                return etiquetas;
            }
        });

        setProductLabels(deleteLabel);

        // setText(text);
    };

    const updateProduct = (payload) => {
        setSiguiente(null);

        return async function () {
            if (onboarding.tipo_producto === "casa") {
                const json = await axios.post(
                    `${process.env.REACT_APP_PROXY}/productoEtiquetas`,
                    payload,
                    { headers }
                );
                const jsonPage = await axios.put(
                    `${process.env.REACT_APP_PROXY}/casa/actualizarPagina/${onboarding.id}`,
                    {
                        numero_pagina: actualPage + 1,
                    },
                    { headers }
                );

                if (json.data.message === "registro exitoso") {
                    setPage(actualPage + 1);
                }
            } else if (onboarding.tipo_producto === "embarcacion") {
                const json = await axios.post(
                    `${process.env.REACT_APP_PROXY}/embarcacionEtiquetas`,
                    payload,
                    { headers }
                );
                const jsonPage = await axios.put(
                    `${process.env.REACT_APP_PROXY}/embarcacion/actualizarPagina/${onboarding.id}`,
                    {
                        numero_pagina: actualPage + 1,
                    },
                    { headers }
                );

                if (json.data.message === "registro exitoso") {
                    setPage(actualPage + 1);
                }
            }
        };
    };

    const etiquetas = (id) => {
        return async function () {
            if (onboarding.tipo_producto === "casa") {
                const json = await axios.get(
                    `${process.env.REACT_APP_PROXY}/productoEtiquetas/all/${id}`,
                    { headers }
                );

                let misEtiquetas = json.data.map((etiqueta) => {
                    return {
                        nombre: etiqueta.nombre,
                        id: etiqueta.id_etiqueta,
                    };
                });

                console.log(json.data);
                if (json.data.length > 0) {
                    setOnboarding({
                        ...onboarding,
                        etiquetas: misEtiquetas,
                    });

                    let idDeEtiquetas = misEtiquetas.map((etiqueta) => {
                        return etiqueta.id;
                    });

                    let noRepetidas = labelrandom.filter((etiqueta) => {
                        return !idDeEtiquetas.includes(etiqueta.id);
                    });

                    setLabelrandom([...noRepetidas, ...misEtiquetas]);
                }
            } else if (onboarding.tipo_producto === "embarcacion") {
                const json = await axios.get(
                    `${process.env.REACT_APP_PROXY}/embarcacionEtiquetas/all/${id}`,
                    { headers }
                );

                let misEtiquetas = json.data.map((l) => {
                    return { nombre: l.nombre, id: l.id_etiqueta };
                });

                if (json.data.length > 0) {
                    setOnboarding({
                        ...onboarding,
                        etiquetas: misEtiquetas,
                    });

                    let idDeEtiquetas = misEtiquetas.map((etiqueta) => {
                        return etiqueta.id;
                    });

                    let noRepetidas = labelrandom.filter((etiqueta) => {
                        return !idDeEtiquetas.includes(etiqueta.id);
                    });

                    setLabelrandom([...noRepetidas, ...misEtiquetas]);
                }
            }
        };
    };

    const nextPage = () => {
        let idDeEtiquetas = onboarding.etiquetas?.map((e, i) => {
            return e.id;
        });

        if (onboarding.etiquetas?.length > 0) {
            dispatch(
                onboarding.tipo_producto === "casa"
                    ? updateProduct({
                          etiquetas: idDeEtiquetas,
                          id_producto: onboarding.id,
                      })
                    : updateProduct({
                          etiquetas: idDeEtiquetas,
                          id_embarcacion: onboarding.id,
                      })
            );
        } else {
            alert();
        }
    };

    const alert = () => {
        Swal.fire({
            title: "Debes seleccionar al menos una etiqueta",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Completar!",
        });
    };

    return (
        <div className="w-full">
            <h1 className="py-8 text-4xl font-bold text-center sm:py-8 sm:text-6xl">
                Describe tu casa con palabras
            </h1>

            <div className="p-4 mt-2 text-center ">
                <input
                    // onChange={(e) => setText(e.target.value)}
                    onChange={(e) => (
                        setText(e.target.value), labelName(e.target.value)
                    )}
                    autoComplete="off"
                    id="default"
                    type="text"
                    name="default"
                    value={text}
                    placeholder="Escribe o selecciona palabras"
                    className="w-full py-8 text-sm font-bold text-center border-2 border-blue-300 rounded-lg sm:w-10/12 lg:text-3xl lg:py-12 lg:placeholder:font-medium focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />

                {!labelrandom.length ? <Spinner width={20} /> : null}
                <div className="grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 sm:w-10/12 mx-auto  ">
                    {productLabels?.map((label) => {
                        return (
                            <div
                                onClick={() => (
                                    addLabel(label),
                                    setLabelrandom([...labelrandom, label])
                                )}
                                key={label.id}
                                className={
                                    "items-center mx-1 py-1  my-1 text-center capitalize duration-150 transform border-2 border-blue-400 bg-blue-100 rounded-md select-none hover:scale-110"
                                }>
                                {label.nombre}
                            </div>
                        );
                    })}
                </div>
                <div className="grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 sm:w-10/12 mx-auto">
                    {labelrandom?.map((r) => {
                        return (
                            <div
                                onClick={() => addLabel(r)}
                                key={r.id}
                                className={
                                    onboarding.etiquetas?.includes(r)
                                        ? "items-center mx-1 py-1  my-1 text-center capitalize duration-150 transform border-2 border-lime-400 bg-lime-100 rounded-md select-none hover:scale-110"
                                        : "items-center mx-1 py-1  my-1 text-center capitalize duration-150 transform border-2 border-gray-400 rounded-md select-none hover:scale-110"
                                }>
                                <p>{r.nombre}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="flex items-center justify-center mt-20">
                <button
                    onClick={() => setPage(actualPage - 1)}
                    className="p-2 pl-5 pr-5 mr-2 text-lg text-gray-500 min-w-[118px] bg-transparent border-2 border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 ">
                    Anterior
                </button>

                <button
                    disabled={!siguiente}
                    onClick={() => nextPage()}
                    className="p-2 pl-5 pr-5 text-lg text-gray-500 bg-transparent border-2 min-w-[118px] border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 disabled:bg-white disabled:border-black">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div>
        </div>
    );
};

export default ProductLabels;
