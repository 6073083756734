import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Paginado } from "../helpers/Paginado";
import axios from "axios";
import Spinner from "../Spinner";
import { ShowStarsRating } from "../StarsRating";
import { useSelector } from "react-redux";
import ReviewModalDetail from "./ReviewModalDetail";
import SearchBar from "./SearchBar";
import CardReview from "./CardReview";
import NavbarEmpresas from "../dashboardEmpresas/NavbarEmpresas";
import { NavbarEmpresasNew } from "../dashboardEmpresas/NavbarEmpresasNew";
import { NavbarMobileEmpresasNew } from "../dashboardEmpresas/NavbarMobileEmpresasNew";

const ReviewsDashboard = ({ empresa = false }) => {
    const authUser = useSelector((state) => state.authReducer);
    const [page, setPage] = useState(1);
    const [showAlojamientos, setShowAlojamientos] = useState(
        authUser.tipo_usuario > 2 && authUser.casas > 0
            ? true
            : authUser.tipo_usuario > 2 && authUser.embarcaciones > 0
            ? false
            : true
    );
    const [habilitados, setHabilitados] = useState(true);
    const [reviewsShowingInPage, setReviewsShowingInPage] = useState({
        data: [],
        checking: true,
    });
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [modalDetail, setModalDetail] = useState(false);
    const [reviewSelected, setReviewSelected] = useState(null);
    const USERS_PER_PAGE = 25;

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const getAllReviews = async (pageNumber) => {
        setReviewsShowingInPage({
            checking: true,
            data: {
                data: [],
                total: 0,
            },
        });
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/${
                showAlojamientos ? "reviewCasa" : "reviewEmbarcacion"
            }/all/${pageNumber || page}/${habilitados ? "Si" : "No"}/${
                authUser.id_usuario
            }`,
            { headers }
        );

        setReviewsShowingInPage({ checking: false, data: data });
    };

    useEffect(() => {
        setReviewsShowingInPage([]);
        getAllReviews();
    }, [habilitados, showAlojamientos]);

    const handleDelete = async (idReview) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminar la reseña?",
            text: "Se enviará a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/${
                            showAlojamientos
                                ? "reviewCasa"
                                : "reviewEmbarcacion"
                        }/eliminar/${idReview}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Reseña eliminada correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllReviews();
                    });
            }
        });
    };

    const handleEnable = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitar la reseña?",
            text: "Se enviará a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/${
                            showAlojamientos
                                ? "reviewCasa"
                                : "reviewEmbarcacion"
                        }/restaurar/${id}`,
                        {},
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Reseña restaurada correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllReviews();
                    });
            }
        });
    };

    const reviewDetails = (review) => {
        setReviewSelected(review);
        setModalDetail((prev) => !prev);
    };

    const pagination = (pageNumber) => {
        getAllReviews(pageNumber);
        setPage(pageNumber);
    };

    return (
        <>
            {empresa ? (
                <NavbarEmpresasNew>
                    <div className="w-full p-4  md:mx-auto">
                        {modalDetail && reviewSelected && (
                            <ReviewModalDetail
                                setModalDetail={setModalDetail}
                                review={reviewSelected}
                                habilitado={habilitados}
                                handleEnable={handleEnable}
                                handleDelete={handleDelete}
                            />
                        )}
                        <Paginado
                            elementsPerPage={USERS_PER_PAGE}
                            cantTotalElements={reviewsShowingInPage.data.total}
                            pagination={pagination}
                        />
                        <section className="w-full p-4 lg:p-10 lg:mx-auto">
                            <div className="flex-col items-start justify-start">
                                <h1 className="py-5 text-4xl text-left">
                                    Reseñas
                                </h1>

                                <div className="text-base w-[30rem] text-justify mb-5">
                                    Aca puedes ver las reseñas que hiciste en
                                    tus reservas!
                                </div>
                            </div>
                            <div
                                className={
                                    authUser.tipo_usuario > 2 &&
                                    authUser.casas === 0 &&
                                    authUser.embarcaciones === 0
                                        ? "hidden"
                                        : "flex flex-col lg:flex-row w-full justify-between items-center"
                                }
                            >
                                <div className="flex w-full mx-auto ">
                                    <div className="flex p-2">
                                        <button
                                            disabled={
                                                reviewsShowingInPage.checking
                                            }
                                            className={
                                                authUser.tipo_usuario > 2 &&
                                                authUser.casas === 0
                                                    ? "hidden"
                                                    : `${
                                                          !showAlojamientos
                                                              ? "p-3 text-blue-500 border border-blue-500 px-6 max-w-[156px] rounded-lg"
                                                              : "p-3 px-6 max-w-[156px]  text-white bg-blue-500 border rounded-lg"
                                                      }`
                                            }
                                            onClick={() =>
                                                setShowAlojamientos(true)
                                            }
                                        >
                                            Alojamientos
                                        </button>
                                        <button
                                            disabled={
                                                reviewsShowingInPage.checking
                                            }
                                            className={
                                                authUser.tipo_usuario > 2 &&
                                                authUser.embarcaciones === 0
                                                    ? "hidden"
                                                    : `${
                                                          showAlojamientos
                                                              ? "p-3 ml-2 text-blue-500 border border-blue-500 px-6 max-w-[156px] rounded-lg"
                                                              : "p-3 px-6 max-w-[156px] text-white bg-blue-500 border rounded-lg ml-2"
                                                      }`
                                            }
                                            onClick={() =>
                                                setShowAlojamientos(false)
                                            }
                                        >
                                            Embarcaciones
                                        </button>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end w-full  p-1 mt-6 lg:mt-0 lg:ml-5">
                                    <SearchBar
                                        setfakeRefresh={setfakeRefresh}
                                        fakeRefresh={fakeRefresh}
                                        enabled={habilitados}
                                    />
                                    <button className="relative flex items-center justify-center w-8 h-8 ml-5 border rounded-lg lg:w-11 lg:h-11 border-blue-bluecorner">
                                        <svg
                                            onClick={() =>
                                                setShowButtons((prev) => !prev)
                                            }
                                            className="text-blue-500 h-7 w-7 lg:h-8 lg:w-8"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                            />
                                        </svg>
                                        {showButtons && (
                                            <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                                                <div
                                                    onClick={() => {
                                                        setHabilitados(true);
                                                        setShowButtons(
                                                            (prev) => !prev
                                                        );
                                                        setPage(1);
                                                    }}
                                                    className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer  h-11"
                                                >
                                                    <p
                                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg"
                                                    >
                                                        Habilitados
                                                    </p>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setHabilitados(false);
                                                        setShowButtons(
                                                            (prev) => !prev
                                                        );
                                                        setPage(1);
                                                    }}
                                                    className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer  h-11"
                                                >
                                                    <p
                                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg"
                                                    >
                                                        Deshabilitados
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>

                            <div className="flex flex-col pb-20">
                                {reviewsShowingInPage?.checking ? (
                                    <Spinner margin_top={20} />
                                ) : reviewsShowingInPage.data?.data.length >
                                  0 ? (
                                    <div className="w-full">
                                        <div className="w-full p-3  lg:hidden">
                                            {reviewsShowingInPage?.data?.data?.map(
                                                (review) => (
                                                    <div
                                                        onClick={() =>
                                                            reviewDetails(
                                                                review
                                                            )
                                                        }
                                                    >
                                                        <CardReview
                                                            review={review}
                                                            showAlojamientos={
                                                                showAlojamientos
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <table className="w-full mt-6 overflow-x-auto">
                                            <thead className="text-left">
                                                <tr>
                                                    <th
                                                        className={
                                                            authUser.tipo_usuario ===
                                                            5
                                                                ? "hidden"
                                                                : "hidden lg:table-cell w-full min-w-[10rem] lg:w-2/12 "
                                                        }
                                                    >
                                                        Cliente
                                                    </th>
                                                    <th className="w-full hidden lg:table-cell min-w-[10rem] lg:w-2/12 ">
                                                        Reserva
                                                    </th>
                                                    <th className="w-full min-w-[20rem] lg:w-4/12 hidden xl:table-cell">
                                                        Comentario
                                                    </th>
                                                    <th className="w-full min-w-[14rem] lg:w-2/12 hidden lg:table-cell">
                                                        Puntuación
                                                    </th>
                                                    <th
                                                        align="center"
                                                        className="w-1/12 min-w-[8rem] hidden lg:table-cell "
                                                    >
                                                        Acciones
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="">
                                                {reviewsShowingInPage?.data?.data?.map(
                                                    (review) => (
                                                        <tr
                                                            key={review.id}
                                                            className={`border-t w-full ${"cursor-pointer"}`}
                                                            onClick={() =>
                                                                reviewDetails(
                                                                    review
                                                                )
                                                            }
                                                        >
                                                            <td
                                                                className={
                                                                    authUser.tipo_usuario ===
                                                                    5
                                                                        ? "hidden"
                                                                        : "hidden lg:table-cell py-5"
                                                                }
                                                            >
                                                                {
                                                                    review.usuario_calificador
                                                                }
                                                            </td>
                                                            <td className="hidden lg:table-cell">
                                                                {review.nombre}
                                                            </td>
                                                            <td className="hidden xl:table-cell ">
                                                                {review.comentarios
                                                                    .split(" ")
                                                                    .slice(
                                                                        0,
                                                                        20
                                                                    )
                                                                    .join(" ")}
                                                            </td>
                                                            <td className="hidden lg:table-cell">
                                                                {review.puntuacion ? (
                                                                    <div className="flex items-center">
                                                                        <ShowStarsRating
                                                                            width={
                                                                                7
                                                                            }
                                                                            starsQuantity={Number(
                                                                                review.puntuacion
                                                                            )}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    "No posee puntuacion"
                                                                )}
                                                            </td>
                                                            <td className="hidden lg:table-cell">
                                                                {habilitados ? (
                                                                    <div className="flex justify-center ml-5">
                                                                        <button
                                                                            className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                handleDelete(
                                                                                    review.id
                                                                                );
                                                                            }}
                                                                            title="Eliminar"
                                                                        >
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="2"
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            >
                                                                                {" "}
                                                                                <path
                                                                                    stroke="none"
                                                                                    d="M0 0h24v24H0z"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="4"
                                                                                    y1="7"
                                                                                    x2="20"
                                                                                    y2="7"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="10"
                                                                                    y1="11"
                                                                                    x2="10"
                                                                                    y2="17"
                                                                                />{" "}
                                                                                <line
                                                                                    x1="14"
                                                                                    y1="11"
                                                                                    x2="14"
                                                                                    y2="17"
                                                                                />{" "}
                                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex justify-center ml-5">
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                handleEnable(
                                                                                    review.id
                                                                                );
                                                                            }}
                                                                            title="Habilitar"
                                                                            className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                        >
                                                                            <svg
                                                                                className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            >
                                                                                {" "}
                                                                                <polyline points="17 1 21 5 17 9" />{" "}
                                                                                <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                <polyline points="7 23 3 19 7 15" />{" "}
                                                                                <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : habilitados ? (
                                    <h1 className="h-32 text-lg text-center lg:text-xl">
                                        Aun no tienes ninguna reseña :(
                                    </h1>
                                ) : (
                                    <h1 className="h-32 text-lg text-center lg:text-xl">
                                        Aun no tienes ninguna reseña
                                        deshabilitada :(
                                    </h1>
                                )}
                            </div>
                        </section>
                    </div>
                    <NavbarMobileEmpresasNew />
                </NavbarEmpresasNew>
            ) : (
                <div className=" w-full md:pl-[12rem] md818:pl-[11rem] md920:pl-[11rem] lg:pl-[15rem]   ">
                    {modalDetail && reviewSelected && (
                        <ReviewModalDetail
                            setModalDetail={setModalDetail}
                            review={reviewSelected}
                            habilitado={habilitados}
                            handleEnable={handleEnable}
                            handleDelete={handleDelete}
                        />
                    )}
                    <Paginado
                        elementsPerPage={USERS_PER_PAGE}
                        cantTotalElements={reviewsShowingInPage.data.total}
                        pagination={pagination}
                    />
                    <section className="w-full p-4 lg:p-10 lg:mx-auto">
                        <h1 className="py-5 mx-4 text-4xl">Reseñas</h1>
                        <div
                            className={
                                authUser.tipo_usuario > 2 &&
                                authUser.casas === 0 &&
                                authUser.embarcaciones === 0
                                    ? "hidden"
                                    : "flex flex-col lg:flex-row w-full justify-between items-center"
                            }
                        >
                            <div className="flex w-full mx-auto ">
                                <div className="flex p-2">
                                    <button
                                        disabled={reviewsShowingInPage.checking}
                                        className={
                                            authUser.tipo_usuario > 2 &&
                                            authUser.casas === 0
                                                ? "hidden"
                                                : `${
                                                      !showAlojamientos
                                                          ? "p-3 text-blue-500 border border-blue-500 px-6 max-w-[156px] rounded-lg"
                                                          : "p-3 px-6 max-w-[156px]  text-white bg-blue-500 border rounded-lg"
                                                  }`
                                        }
                                        onClick={() =>
                                            setShowAlojamientos(true)
                                        }
                                    >
                                        Alojamientos
                                    </button>
                                    <button
                                        disabled={reviewsShowingInPage.checking}
                                        className={
                                            authUser.tipo_usuario > 2 &&
                                            authUser.embarcaciones === 0
                                                ? "hidden"
                                                : `${
                                                      showAlojamientos
                                                          ? "p-3 ml-2 text-blue-500 border border-blue-500 px-6 max-w-[156px] rounded-lg"
                                                          : "p-3 px-6 max-w-[156px] text-white bg-blue-500 border rounded-lg ml-2"
                                                  }`
                                        }
                                        onClick={() =>
                                            setShowAlojamientos(false)
                                        }
                                    >
                                        Embarcaciones
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-end w-full  p-1 mt-6 lg:mt-0 lg:ml-5">
                                <SearchBar
                                    setfakeRefresh={setfakeRefresh}
                                    fakeRefresh={fakeRefresh}
                                    enabled={habilitados}
                                />
                                <button className="relative flex items-center justify-center w-8 h-8 ml-5 border rounded-lg lg:w-11 lg:h-11 border-blue-bluecorner">
                                    <svg
                                        onClick={() =>
                                            setShowButtons((prev) => !prev)
                                        }
                                        className="text-blue-500 h-7 w-7 lg:h-8 lg:w-8"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                        />
                                    </svg>
                                    {showButtons && (
                                        <div className="border rounded-lg absolute -left-24 top-[35px] bg-white">
                                            <div
                                                onClick={() => {
                                                    setHabilitados(true);
                                                    setShowButtons(
                                                        (prev) => !prev
                                                    );
                                                    setPage(1);
                                                }}
                                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer  h-11"
                                            >
                                                <p
                                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg"
                                                >
                                                    Habilitados
                                                </p>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setHabilitados(false);
                                                    setShowButtons(
                                                        (prev) => !prev
                                                    );
                                                    setPage(1);
                                                }}
                                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer  h-11"
                                            >
                                                <p
                                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg"
                                                >
                                                    Deshabilitados
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </button>
                            </div>
                        </div>
                        {/* <div className="container flex items-center justify-between p-2 mt-4">
                    <button
                        className={
                            habilitados
                                ? "inline-block rounded-lg max-w-[156px] p-3 px-6  text-green-700 border border-green-500  group hover:bg-green-700 hover:text-white"
                                : "inline-block rounded-lg max-w-[156px] p-3 px-3  text-red-700 border border-red-500  group hover:bg-red-700 hover:text-white"
                        }
                        onClick={() => {
                            setHabilitados(!habilitados);
                            setPage(1);
                        }}>
                        {habilitados
                            ? `Habilitados (${reviewsShowingInPage?.data.total})`
                            : `Deshabilitados (${reviewsShowingInPage?.data.total})`}
                    </button>
                </div> */}
                        <div className="flex flex-col pb-20">
                            {reviewsShowingInPage?.checking ? (
                                <Spinner margin_top={20} />
                            ) : reviewsShowingInPage.data?.data.length > 0 ? (
                                <div className="w-full">
                                    <div className="w-full p-3  lg:hidden">
                                        {reviewsShowingInPage?.data?.data?.map(
                                            (review) => (
                                                <div
                                                    onClick={() =>
                                                        reviewDetails(review)
                                                    }
                                                >
                                                    <CardReview
                                                        review={review}
                                                        showAlojamientos={
                                                            showAlojamientos
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <table className="w-full mt-6 overflow-x-auto">
                                        <thead className="text-left">
                                            <tr>
                                                <th
                                                    className={
                                                        authUser.tipo_usuario ===
                                                        5
                                                            ? "hidden"
                                                            : "hidden lg:table-cell w-full min-w-[10rem] lg:w-2/12 "
                                                    }
                                                >
                                                    Cliente
                                                </th>
                                                <th className="w-full hidden lg:table-cell min-w-[10rem] lg:w-2/12 ">
                                                    Reserva
                                                </th>
                                                <th className="w-full min-w-[20rem] lg:w-4/12 hidden xl:table-cell">
                                                    Comentario
                                                </th>
                                                <th className="w-full min-w-[14rem] lg:w-2/12 hidden lg:table-cell">
                                                    Puntuación
                                                </th>
                                                <th
                                                    align="center"
                                                    className="w-1/12 min-w-[8rem] hidden lg:table-cell "
                                                >
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {reviewsShowingInPage?.data?.data?.map(
                                                (review) => (
                                                    <tr
                                                        key={review.id}
                                                        className={`border-t w-full ${"cursor-pointer"}`}
                                                        onClick={() =>
                                                            reviewDetails(
                                                                review
                                                            )
                                                        }
                                                    >
                                                        <td
                                                            className={
                                                                authUser.tipo_usuario ===
                                                                5
                                                                    ? "hidden"
                                                                    : "hidden lg:table-cell py-5"
                                                            }
                                                        >
                                                            {
                                                                review.usuario_calificador
                                                            }
                                                        </td>
                                                        <td className="hidden lg:table-cell">
                                                            {review.nombre}
                                                        </td>
                                                        <td className="hidden xl:table-cell ">
                                                            {review.comentarios
                                                                .split(" ")
                                                                .slice(0, 20)
                                                                .join(" ")}
                                                        </td>
                                                        <td className="hidden lg:table-cell">
                                                            {review.puntuacion ? (
                                                                <div className="flex items-center">
                                                                    <ShowStarsRating
                                                                        width={
                                                                            7
                                                                        }
                                                                        starsQuantity={Number(
                                                                            review.puntuacion
                                                                        )}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                "No posee puntuacion"
                                                            )}
                                                        </td>
                                                        <td className="hidden lg:table-cell">
                                                            {habilitados ? (
                                                                <div className="flex justify-center ml-5">
                                                                    <button
                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            handleDelete(
                                                                                review.id
                                                                            );
                                                                        }}
                                                                        title="Eliminar"
                                                                    >
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        >
                                                                            {" "}
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />{" "}
                                                                            <line
                                                                                x1="4"
                                                                                y1="7"
                                                                                x2="20"
                                                                                y2="7"
                                                                            />{" "}
                                                                            <line
                                                                                x1="10"
                                                                                y1="11"
                                                                                x2="10"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <line
                                                                                x1="14"
                                                                                y1="11"
                                                                                x2="14"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <div className="flex justify-center ml-5">
                                                                    <button
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            handleEnable(
                                                                                review.id
                                                                            );
                                                                        }}
                                                                        title="Habilitar"
                                                                        className="flex items-center justify-center w-8 h-8 mr-4 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                    >
                                                                        <svg
                                                                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        >
                                                                            {" "}
                                                                            <polyline points="17 1 21 5 17 9" />{" "}
                                                                            <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                            <polyline points="7 23 3 19 7 15" />{" "}
                                                                            <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : habilitados ? (
                                <h1 className="h-32 text-lg text-center lg:text-xl">
                                    Aun no tienes ninguna reseña :(
                                </h1>
                            ) : (
                                <h1 className="h-32 text-lg text-center lg:text-xl">
                                    Aun no tienes ninguna reseña deshabilitada
                                    :(
                                </h1>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </>
    );
};

export default ReviewsDashboard;
