import React, { useState } from "react";
import ModalMessageTopRight from "./ModalMessageTopRight";

const ButtonCopyToClipboard = ({ confirmationText, textToCopy }) => {
    const [modalMessageTop, setModalMessageTop] = useState(false);

    const handleCopy = () => {
        setModalMessageTop(true);
        navigator.clipboard.writeText(textToCopy);
        setTimeout(() => {
            setModalMessageTop(false);
        }, 4000);
    };

    return (
        <div onClick={() => handleCopy()}>
            {modalMessageTop && (
                <ModalMessageTopRight message={confirmationText} success />
            )}
            <svg
                className="w-8 h-8 cursor-pointer text-blue-bluecorner active:scale-75"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <rect x="8" y="8" width="12" height="12" rx="2" />{" "}
                <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
            </svg>
        </div>
    );
};

export default ButtonCopyToClipboard;
