import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";
import axios from "axios";
import { useParams } from "react-router-dom";
import CardHouses from "./CardHouses";
import CardPropietario from "./CardPropietario";
import useScrollTo from "../../hooks/useScrollToTop";
import Spinner from "../Spinner";

const Store = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const { idOwner } = useParams();
    const [propietario, setPropietario] = useState(null);
    const [allHouses, setAllHouses] = useState([]);
    const [allBoats, setAllBoats] = useState([]);
    useScrollTo();

    const getPropietarioDetail = async (id) => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/propietario/detalle/${id}`,
            { headers }
        );
        setPropietario(data.data);

        return data;
    };

    const getPropietarioHouses = async (id) => {
        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/casa/all/`,
            {
                pagina: 1,
                habilitado: "Si",
                nombre: "",
                terminado: "Si",
                id_usuario: id,
                publicado: "todos",
            },
            { headers }
        );

        setAllHouses(data.data);
    };

    const getPropietarioBoats = async (id) => {
        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/embarcacion/all/`,
            {
                pagina: 1,
                habilitado: "Si",
                nombre: "",
                terminado: "Si",
                id_usuario: id,
                publicado: "todos",
            },
            { headers }
        );
        setAllBoats(data.data);
    };

    useEffect(() => {
        getPropietarioDetail(idOwner);
        getPropietarioHouses(idOwner);
        getPropietarioBoats(idOwner);
    }, []);

    return (
        <div className="flex flex-col mt-32">
            <NavBar />
            <CardPropietario propietario={propietario} />
            <div className={allHouses?.length > 0 ? "" : "hidden"}>
                <h1 className="mx-auto text-3xl uppercase font-mono font-semibold w-max p-1 ">
                    Alojamientos disponibles:{" "}
                </h1>
                <div>
                    {allHouses?.length > 0 ? (
                        <div className="grid-cols-2 gap-5 mb-20 p-10 sm:grid sm:p-4 lg:grid-cols-3 2xl:grid-cols-4 md:w-4/5 md:mx-auto md:p-0 2xl:gap-10">
                            {allHouses.map((house) => (
                                <CardHouses
                                    key={house.id}
                                    house={house}
                                    tipo="casa"
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="mb-20">
                            <Spinner width={20} />
                        </div>
                    )}
                </div>
            </div>
            <div className={allBoats?.length > 0 ? "" : "hidden"}>
                <h1 className="mx-auto text-3xl uppercase font-mono font-semibold w-max p-1 ">
                    Embarcaciones disponibles:{" "}
                </h1>
                <div>
                    {allBoats?.length > 0 ? (
                        <div className="grid-cols-2 gap-5 mb-20 p-10 sm:grid sm:p-4 lg:grid-cols-3 2xl:grid-cols-4 md:w-4/5 md:mx-auto md:p-0 2xl:gap-10">
                            {allBoats.map((boat) => (
                                <CardHouses
                                    key={boat.id}
                                    house={boat}
                                    tipo="embarcacion"
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="mb-20">
                            <Spinner width={20} />
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Store;
