import React from "react";
import { Lazy, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/lazy";
import "./Swiper.css";

const CardServicios = ({ paquete, index, servicios, setServicios }) => {
    const addServicio = (index) => {
        if (servicios.includes(index)) {
            let filtro = servicios.filter((s) => s !== index);
            setServicios(filtro);
            localStorage.setItem("servicios", JSON.stringify(filtro));
        } else {
            setServicios([...servicios, index]);
            localStorage.setItem(
                "servicios",
                JSON.stringify([...servicios, index])
            );
        }
    };

    return (
        <div
            id={paquete.id}
            key={paquete.id}
            className={`w-full max-w-md sm:max-w-4xl relative first:my-3 my-8 sm:my-4 flex bg-white flex-col sm:flex-row shadow-md select-none rounded-md mx-auto hover:scale-105 ${
                servicios.includes(index) &&
                "ease-in-out scale-95 bg-blue-100 border-4 border-blue-500"
            }`}
        >
            <div className="relative w-full sm:max-w-[14rem]">
                <Swiper
                    modules={[Navigation, Lazy]}
                    slidesPerView={1}
                    lazy
                    navigation
                    pagination={{
                        clickable: true,
                    }}
                    rewind={true}
                    loop={true}
                >
                    {paquete.imagenes.map((imagen) => (
                        <SwiperSlide key={imagen.id} className="w-full">
                            <img
                                loading="lazy"
                                className="object-cover w-full h-64 sm:h-56 rounded-md swiper-lazy"
                                alt="Imagen del producto"
                                srcSet={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${imagen.imagen}`}
                            />
                            <div className="swiper-lazy-preloader">
                                <div className="bg-gray-200 h-56 w-full flex flex-col gap-2 p-3">
                                    <div className="bg-slate-50 w-full h-[80%] rounded-md"></div>
                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="absolute bottom-0 text-sm left-0 z-10 flex items-center w-full h-14 p-5 font-bold rounded-bl-xl text-white bg-black bg-opacity-70 ">
                    <div className="flex flex-col">
                        <span>{`Desde USD ${paquete.precio}`}</span>
                        <span>( precio referencial )</span>
                    </div>
                </div>
            </div>
            <div
                onClick={() => addServicio(index)}
                className="w-full px-1 py-2 flex flex-col justify-between cursor-pointer"
            >
                <div className="px-2">
                    <div
                        className={`text-xl font-bold flex justify-between items-center`}
                    >
                        <span>{paquete.nombre}</span>
                    </div>

                    <div className="mt-1 text-xs text-gray-500">
                        {paquete.descripcion}
                    </div>
                </div>

                <div className="flex justify-center my-4 px-2 w-full sm:my-1 sm:justify-end">
                    <button
                        className={`text-center w-full max-w-xs flex sm:w-28`}
                    >
                        <p
                            className={`font-bold tracking-wide rounded-lg p-3 w-full text-white mx-auto ${
                                servicios.includes(index)
                                    ? "bg-red-500 hover:bg-red-600"
                                    : "bg-blue-500 hover:bg-blue-600"
                            }`}
                        >
                            {servicios.includes(index) ? "Eliminar" : "Agregar"}
                        </p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CardServicios;
