import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
    clearUserSelected,
    getUsersPerPage,
} from "../../../redux/actions/usersActions";

const token = localStorage.getItem("token");
const headers = {
    authorization: token,
};

const ModalEditUser = ({
    tipo,
    subSelect,
    setIdSelect,
    getAllSubPropietario,
    modalVisible,
    setmodalVisible,
    user = { nombre: "" },
    actualPage,
}) => {
    const [actualizar, setActualizar] = useState("Guardar");
    const [input, setInput] = useState(
        tipo === "subAdmin"
            ? {
                  userName: subSelect.nombre,
                  userCelular: subSelect.celular,

                  userDni: subSelect.dni,
                  userDireccion: subSelect.direccion,
                  mother_lastName: subSelect.apellido_materno,
                  father_lastName: subSelect.apellido_paterno,
                  userEmail: subSelect.correo,
              }
            : {
                  userName: user.nombre,
                  father_lastName: user.apellido_paterno,
                  mother_lastName: user.apellido_materno,
                  userEmail: user.correo,
                  userType: user.tipo_usuario,
              }
    );
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setActualizar("ACTUALIZANDO...");
        await axios.put(
            `${process.env.REACT_APP_PROXY}${
                tipo === "subAdmin"
                    ? "/subpropietario/actualizar/"
                    : "/autenticacion/actualizar/"
            }${tipo === "subAdmin" ? subSelect.id : user.id_usuario}`,

            tipo === "subAdmin"
                ? {
                      nombre: input.userName,
                      apellido_paterno: input.father_lastName,
                      apellido_materno: input.mother_lastName,
                      correo: input.userEmail,
                      celular: input.userCelular,
                      direccion: input.userDireccion,
                      dni: input.userDni,
                  }
                : {
                      nombre: input.userName,
                      apellido_paterno: input.father_lastName,
                      apellido_materno: input.mother_lastName,
                      correo: input.userEmail,
                      id: user.id_usuario,
                  },
            { headers }
        );
        Swal.fire({
            title: "Usuario actualizado correctamente!",
            icon: "success",
            confirmButtonText: "Aceptar",
        });
        if (tipo === "subAdmin") {
            getAllSubPropietario();
            setmodalVisible(!modalVisible);
            setIdSelect(0);
        } else {
            dispatch(getUsersPerPage(actualPage, "Si"));
            dispatch(clearUserSelected());
            setmodalVisible(!modalVisible);
        }
    };

    const handleDelete = async (idUser) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el usuario a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}${
                            tipo === "subAdmin"
                                ? "/subpropietario/eliminar/"
                                : "/autenticacion/eliminar/"
                        }${idUser}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Usuario eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        if (tipo === "subAdmin") {
                            getAllSubPropietario();
                        } else dispatch(getUsersPerPage(1, "Si"));
                    });
            }
        });
    };

    const handleClose = () => {
        setmodalVisible(!modalVisible);
        dispatch(clearUserSelected());
        setIdSelect(0);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-24 2xl:container 2xl:mx-auto md:px-28 ">
                    <div className="relative w-full block justify-center px-4 py-16 bg-white rounded-lg md:w-96 dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-2 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mb-3 font-bold mr-auto ">
                            Editar usuario
                        </header>
                        <div className="w-full mt-4">
                            <label className="font-semibold">Nombre</label>
                            <input
                                type="text"
                                value={input.userName}
                                name="userName"
                                onChange={handleInputChange}
                                placeholder="Nombre"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Apellido paterno
                            </label>
                            <input
                                type="text"
                                value={input.father_lastName}
                                name="father_lastName"
                                onChange={handleInputChange}
                                placeholder="Apellido paterno"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Apellido materno
                            </label>
                            <input
                                type="text"
                                value={input.mother_lastName}
                                name="mother_lastName"
                                onChange={handleInputChange}
                                placeholder="Apellido materno"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        <div className="w-full mt-4">
                            <label className="font-semibold">
                                Correo electrónico
                            </label>
                            <input
                                type="text"
                                value={input.userEmail}
                                name="userEmail"
                                onChange={handleInputChange}
                                placeholder="Correo electrónico"
                                className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                            />
                        </div>
                        {tipo === "subAdmin" && (
                            <>
                                <div className="w-full mt-4">
                                    <label className="font-semibold">
                                        Celular
                                    </label>
                                    <input
                                        type="text"
                                        value={input.userCelular}
                                        name="userCelular"
                                        onChange={handleInputChange}
                                        placeholder="Celular"
                                        className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                    />
                                </div>
                                <div className="w-full mt-4">
                                    <label className="font-semibold">DNI</label>
                                    <input
                                        type="text"
                                        value={input.userDni}
                                        name="userDni"
                                        onChange={handleInputChange}
                                        placeholder="Dni"
                                        className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                    />
                                </div>
                                <div className="w-full mt-4">
                                    <label className="font-semibold">
                                        Dirección
                                    </label>
                                    <input
                                        type="text"
                                        value={input.userDireccion}
                                        name="userDireccion"
                                        onChange={handleInputChange}
                                        placeholder="Dirección"
                                        className="w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none"
                                    />
                                </div>
                            </>
                        )}
                        {tipo !== "subAdmin" && (
                            <div className="w-full mt-4">
                                <label className="font-semibold">
                                    Tipo de usuario
                                </label>
                                <select
                                    onChange={handleInputChange}
                                    name="userType"
                                    value={input.userType}
                                    className=" w-full border my-3 bg-transparent rounded-lg p-3 outline-none focus:outline-none">
                                    <option value={1}> Administrador </option>
                                    <option value={2}>Gestor de tienda</option>
                                </select>
                            </div>
                        )}
                        <button
                            disabled={
                                actualizar === "ACTUALIZANDO..." ? true : false
                            }
                            className="w-full rounded-md bg-blue-500 py-3 text-white mt-5 active:translate-y-[0.125rem] "
                            onClick={handleUpdate}>
                            {actualizar}
                        </button>
                        <button
                            className="w-full md:hidden rounded-md border border-blue-bluecorner py-3 text-blue-bluecorner  mt-4 active:translate-y-[0.125rem]"
                            onClick={() => handleDelete(user.id)}>
                            Eliminar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditUser;
