import React, { useRef, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import markerIcon from "../../img/Home/marker-mapbox.png";

const MapDetail = ({ ubication }) => {
    const { lat, long } = ubication;

    const [coordinates, setCoordinates] = useState({
        lat: ubication.lat,
        lng: ubication.long,
        zoom: 15,
    });

    return (
        <div>
            <div className="p-1 mt-20 mb-6 border-2 h-80 md:h-[650px]">
                <GoogleMap
                    mapContainerStyle={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "10px",
                    }}
                    center={{ lat: coordinates.lat, lng: coordinates.lng }}
                    zoom={15}
                >
                    <Marker
                        opacity={0.5}
                        position={{ lat, lng: long }}
                        icon={{
                            url: markerIcon,
                            scaledSize: new window.google.maps.Size(50, 50),
                        }}
                    />
                </GoogleMap>
            </div>
        </div>
    );
};

export default MapDetail;
