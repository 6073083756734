import React, { useState } from "react";
import Footer from "../Footer";
import NavBar from "../NavBar";
import UserLogin from "./UserLogin";
import UserRegister from "./UserRegister";
import useScrollToTop from "../../hooks/useScrollToTop";
import colores from "../helpers/colores";

const MyAccount = ({ state = false }) => {
    const [registro, setRegistro] = useState(state);

    useScrollToTop();
    return (
        <div>
            <NavBar />
            <div
                className={`bg-[${colores.primario}] text-white text-center py-10 mt-24`}>
                <h1 className="text-4xl font-bold">Mi cuenta</h1>
                {/* <h2 className="w-3/5 mx-auto mt-5 font-semibold">
          Toda la información que el usuario necesita, estará dispuesta en esta
          sección.
        </h2> */}
            </div>
            <div className="grid grid-cols-1  mx-auto sm:grid-cols-2 lg:grid-cols-[50%,50%]  xl:w-4/5">
                {!registro ? (
                    <>
                        <div className="flex justify-center sm:justify-center">
                            <UserLogin
                                registro={registro}
                                setRegistro={setRegistro}
                            />
                        </div>
                        <div className="flex items-center justify-center pb-4 ">
                            <div className=" hidden sm:block  shadow-xl px-8 py-6 rounded-2xl text-[#053465]   h-auto    leading-loose ">
                                <p className="mt-3 text-5xl font-bold ">
                                    !No te pierdas este beneficio!
                                </p>
                                <br></br>
                                <br></br>

                                <p className="text-4xl ">
                                    Al registrarte y alquilar una casa
                                    acumularás Blue Coins y podrás obtener un
                                    10% de descuento en tu siguiente alquiler.
                                    <p
                                        onClick={() =>
                                            window.open(
                                                `/terminos-y-condiciones/loyalty`,
                                                "_blank"
                                            )
                                        }
                                        className="my-6 font-medium underline transition ease-in cursor-pointer hover:scale-95 underline-offset-2">
                                        {" "}
                                        Conoce más acerca de Blue Coins
                                    </p>
                                </p>
                            </div>
                            <div className=" sm:hidden shadow-xl px-2 py-2 rounded-md text-[#053465]   mx-4 h-auto  ">
                                <p className="text-lg font-bold ">
                                    !No te pierdas este beneficio!
                                </p>
                                <p>
                                    Al registrarte y alquilar una casa
                                    acumularás Blue Coins y podrás obtener un
                                    10% de descuento en tu siguiente alquiler.
                                    <p
                                        onClick={() =>
                                            window.open(
                                                `/terminos-y-condiciones/loyalty`,
                                                "_blank"
                                            )
                                        }
                                        className="font-medium underline transition ease-in cursor-pointer hover:scale-95 underline-offset-2">
                                        {" "}
                                        Conoce más acerca de Blue Coins
                                    </p>
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex justify-center ">
                            <UserRegister
                                registro={registro}
                                setRegistro={setRegistro}
                            />
                        </div>
                        <div className="flex items-center justify-center pb-4 ">
                            <div className=" hidden sm:block  shadow-xl px-8 py-6 rounded-2xl text-[#053465]   h-auto    leading-loose ">
                                <p className="mt-3 text-5xl font-bold ">
                                    !No te pierdas este beneficio!
                                </p>
                                <br></br>
                                <br></br>

                                <p className="text-4xl ">
                                    Al registrarte y alquilar una casa
                                    acumularás Blue Coins y podrás obtener un
                                    10% de descuento en tu siguiente alquiler.
                                    <p
                                        onClick={() =>
                                            window.open(
                                                `/terminos-y-condiciones/loyalty`,
                                                "_blank"
                                            )
                                        }
                                        className="my-6 font-medium underline transition ease-in cursor-pointer hover:scale-95 underline-offset-2">
                                        {" "}
                                        Conoce más acerca de Blue Coins
                                    </p>
                                </p>
                            </div>
                            <div className=" sm:hidden shadow-xl px-2 py-2 rounded-md text-[#053465]   mx-4 h-auto  ">
                                <p className="text-lg font-bold ">
                                    !No te pierdas este beneficio!
                                </p>
                                <p>
                                    Al registrarte y alquilar una casa
                                    acumularás Blue Coins y podrás obtener un
                                    10% de descuento en tu siguiente alquiler.
                                    <p
                                        onClick={() =>
                                            window.open(
                                                `/terminos-y-condiciones/loyalty`,
                                                "_blank"
                                            )
                                        }
                                        className="font-medium underline transition ease-in cursor-pointer hover:scale-95 underline-offset-2">
                                        {" "}
                                        Conoce más acerca de Blue Coins
                                    </p>
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default MyAccount;
