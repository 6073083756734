import axios from "axios";
import React, { useRef, useState } from "react";
import { normalizeString } from "../helpers/normalizeString";
import useHeaders from "../../hooks/useHeaders";
import AutoComplete from "./AutoComplete";
import Swal from "sweetalert2";

const ModalVerMas = ({
    cuadrante,
    modalVisible,
    setModalVisible,
    setCuadranteSelected,
    setRefreshMatriz,
}) => {
    const [input, setInput] = useState({
        nombre_casa: "",
    });
    const { headers } = useHeaders();
    const [fields, setFields] = useState([]);
    const debounceInput = useRef(null);

    const handleInputChangeDebounce = (e) => {
        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            setInput({
                ...input,
                nombre_casa: e.target.value,
            });
            setFields([]);
            return;
        }
        setInput({
            ...input,
            nombre_casa: e.target.value,
        });
        debounceInput.current = setTimeout(async () => {
            try {
                const { data } = await axios(
                    `${process.env.REACT_APP_PROXY}/casaExperience/autocompleteMatriz/${e.target.value}`,
                    { headers }
                );
                setFields(data.data);
            } catch (error) {
                console.log(error.response);
            }
        }, 500);
    };

    const handleDelete = async (event, id_casa) => {
        event.stopPropagation();
        try {
            Swal.fire({
                title: "¿Seguro que quieres dejar la casa sin matriz?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, eliminar!",
                cancelButtonText: "No, mejor no",
            }).then((result) => {
                if (result.isConfirmed) {
                    setRefreshMatriz((prev) => !prev);
                }
            });
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casaExperience/updateMatriz/${id_casa}`,
                { id_matriz: 0 },
                { headers }
            );
        } catch (error) {
            console.log(error.response);
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            setFields([]);
            setInput({
                ...input,
                nombre_casa: "",
            });
        }, 200);
    };

    const handleClose = () => {
        setCuadranteSelected(null);
        setModalVisible(false);
        setInput({
            ...input,
            nombre_casa: "",
        });
    };

    return (
        <div
            className={`${
                !modalVisible
                    ? "translate-y-full invisible"
                    : "translate-y-0 flex visible"
            } transition-all ease-out duration-500 flex-col bg-slate-50 absolute left-0 top-0 z-[999] h-full w-full items-center`}>
            <div className="flex flex-col w-full h-full">
                <div
                    onClick={() => handleClose()}
                    className="flex items-center justify-center w-8 h-8 p-2 m-4 font-medium border border-gray-300 rounded-full shadow-md cursor-pointer">
                    <svg
                        className="w-4 h-4 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>
                <div className="flex flex-col items-center justify-center px-10">
                    <div className="relative flex flex-col items-center w-full max-w-xl gap-3 p-3 bg-white border rounded-md md:flex-row">
                        <h2 className="font-medium min-w-max">
                            Agregar una nueva casa
                        </h2>
                        <input
                            type={"text"}
                            onBlur={handleBlur}
                            value={input.nombre_casa}
                            onChange={handleInputChangeDebounce}
                            className="w-full p-2 border rounded-md"
                        />
                    </div>
                    {fields.length > 0 && (
                        <AutoComplete
                            id_matriz_selected={cuadrante?.id_matriz}
                            setRefreshMatriz={setRefreshMatriz}
                            fields={fields}
                            setFields={setFields}
                            input={input}
                            setInput={setInput}
                        />
                    )}
                </div>
                <div className="flex flex-col items-center mt-4">
                    <h1 className="text-lg">
                        Casas del cuadrante:{" "}
                        <span className="font-medium">
                            {cuadrante?.tag} {cuadrante?.tipo}
                        </span>
                    </h1>
                    <div className="flex flex-col w-full overflow-auto max-h-[36rem] max-w-xl gap-3 text-lg ">
                        {!cuadrante ? (
                            <div className="text-lg font-medium">
                                Cargando casas...
                            </div>
                        ) : (
                            cuadrante?.casas?.map((casa) => (
                                <button
                                    title={casa.nombre_casa}
                                    key={casa.id_casa}
                                    className="relative flex items-center justify-between gap-3 p-3 border border-gray-400 rounded-md text-ellipsis whitespace-nowrap hover:bg-gray-200/60"
                                    onClick={() => {
                                        window.open(
                                            `/alojamientos/${
                                                casa.id_casa
                                            }/${normalizeString(
                                                casa.nombre_casa
                                            )}`,
                                            "_blank"
                                        );
                                    }}>
                                    <span>
                                        {console.log(casa)}
                                        {casa.nombre_casa} ({casa.lugar})
                                    </span>
                                    <div
                                        onClick={(event) =>
                                            handleDelete(event, casa.id_casa)
                                        }>
                                        <svg
                                            className="w-6 h-6 text-red-500 hover:scale-110"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                            />
                                        </svg>
                                    </div>
                                </button>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalVerMas;
