import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import colores from "../../helpers/colores";
import Spinner from "../../Spinner";
import puntosDetalles from "../../helpers/puntosDetalles";
import getTotalReservaInicial from "../getTotalReservaInicial";
import priceToMonedaElegida from "../../helpers/priceToMonedaElegida";
import numberToCommas from "../../helpers/numberToCommas";
import { getTotalFromDayNumbers } from "../../helpers/getTotalPrice/getTotalFromDayNumbers";
import { getTotalFromRangoExactoDias } from "../../helpers/getTotalPrice/getTotalFromRangoExactoDias";
import getPriceAditionalService from "../../helpers/getTotalPrice/getPriceAditionalService";
import getTotalReservaSimple from "../getTotalReservaSimple";
import DetailedCalendarAlojamientos from "../DetailedCalendarAlojamientos";
import getTotalReservaFinal from "../getTotalReservaFinal";
import getTotalPrice from "../getTotalPrice";
import getFeeBC from "../getFeeBC";
import axios from "axios";
import Swal from "sweetalert2";
import { FormularioDeReservaScrapy } from "../FormularioDeReservaScrapy";
import ClientReferido from "./ClientReferido";

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

const DetailedReserva = ({
    currentProduct,
    setCurrentProduct,
    aditionalServices,
    setAditionalServices,
    habilitarModalFormulario,
    setHabilitarModalFormulario,
    arrayDias,
    arrayFechas,
    arrayMeses,
    arrayPedidos,
    getGarantiaTotal,
    selectedDays,
    setSelectedDays,
    cantDays,
    setCantDays,
    cantMascotas,
    setCantMascotas,
    showMap,
}) => {
    const authUser = useSelector((state) => state.authReducer);
    const navigate = useNavigate();

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [input, setInput] = useState({
        nombre_cliente: "",
        id_cliente: "",
        correo_cliente: "",
        tipo_cliente: "",
    });

    const [fields, setFields] = useState([]);
    const [adultos, setAdultos] = useState(0);
    const [ninos, setNinos] = useState(0);

    const [errorDates, setErrorDates] = useState(null);
    const [clientReferido, setClientReferido] = useState(null);
    const [pointsDiscount, setPointsDiscount] = useState(false);

    const [modalHuespedesVisible, setModalHuespedesVisible] = useState(false);

    const moneda_elegida = localStorage.getItem("moneda");

    const debounceInput = useRef(null);

    const menos10Porciento = (valor, product) => {
        let nuevoValor = valor - 0.1 * valor;
        let valorFinal;
        (authUser.tipo_usuario === 6 || authUser.tipo_usuario === 7) &&
        product?.alianza_empresa === "No"
            ? (valorFinal = Math.ceil(nuevoValor))
            : (valorFinal = Math.ceil(valor));

        return valorFinal;
    };

    const calcularDescuento = (
        monto,
        oferta_activada,
        porcentaje,
        desde = false
    ) => {
        if (!porcentaje || oferta_activada === "No") {
            return 0;
        } else {
            let montoSinIGV = monto;
            let descuento = (Number(montoSinIGV) * Number(porcentaje)) / 100;

            return desde ? Math.ceil(monto - descuento) : Math.floor(descuento);
        }
    };

    const handleInputChangeDebounce = (e) => {
        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            setInput({ nombre_cliente: e.target.value, id_cliente: "" });
            setFields([]);
            return;
        }
        setInput({ nombre_cliente: e.target.value, id_cliente: "" });
        debounceInput.current = setTimeout(async () => {
            try {
                const { data } = await axios(
                    `${process.env.REACT_APP_PROXY}/cliente/autocomplete?autocomplete=${e.target.value}`,
                    { headers }
                );

                setFields(data);
            } catch (error) {
                console.log(error.response);
            }
        }, 500);
    };

    const getCantidadTotalPuntos = () => {
        let cantidad_puntos = 0;
        let valor_division =
            moneda_elegida === "USD"
                ? puntosDetalles.dolares
                : puntosDetalles.soles;

        cantidad_puntos = Math.floor(
            (getTotalReservaInicial(cantDays, selectedDays, currentProduct) /
                valor_division) *
                1000
        );

        cantidad_puntos = Math.floor(cantidad_puntos / 1000);

        cantidad_puntos = cantidad_puntos * 1000;

        return cantidad_puntos;
    };

    const getDscuentoEmpresa = (descuentoFree, descuentoPremium) => {
        let descuento =
            authUser.membresia === "No"
                ? Number(descuentoFree) / 100
                : Number(descuentoPremium) / 100;
        return descuento;
    };

    const handleHuespedes = (e, sumarOrestar, tipo, persona) => {
        e.preventDefault();

        if (tipo === "mascota") {
            if (sumarOrestar === "restar") {
                setCantMascotas(Math.max(0, cantMascotas - 1));
            } else {
                setCantMascotas(
                    Math.min(currentProduct.numero_mascotas, cantMascotas + 1)
                );
            }
        } else if (tipo === "huespedes") {
            if (sumarOrestar === "restar") {
                if (persona === "adultos" && adultos > 0) {
                    setAdultos(adultos - 1);
                    setCurrentProduct((prev) => {
                        return {
                            ...prev,
                            huespedes: Math.max(0, prev.huespedes - 1),
                        };
                    });
                } else if (persona === "ninos" && ninos > 0) {
                    setNinos(ninos - 1);
                    setCurrentProduct((prev) => {
                        return {
                            ...prev,
                            huespedes: Math.max(0, prev.huespedes - 1),
                        };
                    });
                }
            } else {
                if (
                    persona === "adultos" &&
                    currentProduct.numero_personas > adultos + ninos
                ) {
                    setAdultos(adultos + 1);
                } else if (
                    persona === "ninos" &&
                    currentProduct.numero_personas > adultos + ninos
                ) {
                    setNinos(ninos + 1);
                }

                setCurrentProduct((prev) => {
                    return {
                        ...prev,
                        huespedes: Math.min(
                            prev.numero_personas,
                            prev.huespedes + 1
                        ),
                    };
                });
            }
        }
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        if (event.target.name === "mascotas") {
            setCantMascotas(inputValue !== "" ? Number(inputValue) : "");
        }
        if (event.target.name === "adultos") {
            setAdultos(inputValue !== "" ? Number(inputValue) : "");
        }
        if (event.target.name === "ninos") {
            setNinos(inputValue !== "" ? Number(inputValue) : "");
        }
    };

    const handlerAditionalService = (service, event) => {
        if (event.target.checked) {
            setAditionalServices([...aditionalServices, service]);
        } else {
            setAditionalServices(
                aditionalServices.filter(
                    (services) => services.id !== service.id
                )
            );
        }
    };

    const initialReserva_final = () => {
        return pointsDiscount
            ? getTotalReservaSimple(
                  cantDays,
                  selectedDays,
                  currentProduct,
                  authUser.tipo_usuario === 6 || authUser.tipo_usuario === 7,
                  authUser.membresia
              ) - getTotalReservaInicial(cantDays, selectedDays, currentProduct)
            : getTotalReservaSimple(
                  cantDays,
                  selectedDays,
                  currentProduct,
                  authUser.tipo_usuario === 6 || authUser.tipo_usuario === 7,
                  authUser.membresia
              );
    };

    const handleReserva = () => {
        const TOTAL_HUESPEDES = Number(adultos) + Number(ninos);

        if (!currentProduct.huespedes) {
            return Swal.fire({
                title: "No has seleccionado la cantidad de huespedes",
                icon: "warning",
                confirmButtonText: "Entendido",
            });
        }
        if (TOTAL_HUESPEDES > currentProduct.numero_personas) {
            return Swal.fire({
                title: `Solo se permiten hasta ${currentProduct.numero_personas} personas incluido niños y adultos`,
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        }
        if (Number(cantMascotas) > currentProduct.numero_mascotas) {
            return Swal.fire({
                title: `Solo se permiten hasta ${
                    currentProduct.numero_mascotas === 1
                        ? "una"
                        : currentProduct.numero_mascotas
                } ${
                    currentProduct.numero_mascotas === 1
                        ? "mascota"
                        : "mascotas"
                }`,
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        }
        if (
            input.id_cliente === "" &&
            (authUser.tipo_usuario === 1 || authUser.tipo_usuario === 2)
        ) {
            return Swal.fire({
                title: "Debes de seleccionar un cliente para poder hacer la reserva",
                icon: "warning",
                confirmButtonText: "Entendido",
            });
        }

        const discountSpecialDays = getTotalFromRangoExactoDias(
            cantDays,
            selectedDays,
            currentProduct.precios_variables.arrayRangoExactoDiasPorDia,
            currentProduct.moneda_precio_base,
            currentProduct.fee_cliente,
            currentProduct.fee_propietario,
            currentProduct.feeCasas
        );

        const initial_price = getTotalPrice(
            selectedDays,
            currentProduct,
            cantDays,
            true,
            false,
            false,
            false,
            false
        );

        const datos_reserva = {
            clientReferido: pointsDiscount ? null : clientReferido,
            realizado_bc: input.id_cliente !== "" ? "Si" : "",
            realizado_bc_cliente: input,
            huespedes: currentProduct.huespedes,
            numero_adultos: adultos,
            numero_ninos: ninos,
            currentProduct: currentProduct,
            house: currentProduct.nombre,
            coin_base: moneda_elegida,
            fecha_inicio: selectedDays[0],
            fecha_final:
                currentProduct.tipo_estadia === "dia"
                    ? selectedDays[1]
                    : selectedDays[1],
            id_casa: currentProduct.id,
            warranty: getGarantiaTotal(),
            total_points_discount: Number(
                getTotalReservaInicial(cantDays, selectedDays, currentProduct)
            ),
            points_discount: pointsDiscount,
            puntos_obtenidos: getCantidadTotalPuntos(),
            puntos_canjeados: currentProduct.promocion_puntos,
            cantDays,
            aditional_services: aditionalServices,
            discountDays: getTotalFromDayNumbers(
                cantDays,
                currentProduct.precios_variables.arrayNumeroDiasPorDia,
                currentProduct.moneda_precio_base,
                currentProduct.fee_cliente,
                currentProduct.fee_propietario,
                currentProduct.feeCasas,
                selectedDays
            ),
            discountSpecialDays: discountSpecialDays,
            priceOnlyAlquiler:
                getTotalPrice(
                    selectedDays,
                    currentProduct,
                    cantDays,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false, //ver ? empleado empresa
                    false // redondeo
                ) -
                getFeeBC(selectedDays, currentProduct, cantDays)
                    .fee_propietario,
            feeBC_cliente: getFeeBC(selectedDays, currentProduct, cantDays)
                .fee_cliente,
            feeBC_propietario: getFeeBC(selectedDays, currentProduct, cantDays)
                .fee_propietario,
            initialPrice: initial_price,
            initialReserva: getTotalReservaInicial(
                cantDays,
                selectedDays,
                currentProduct
            ),
            initialReserva_final: initialReserva_final(),
            totalPrice: pointsDiscount
                ? getTotalReservaFinal(
                      cantDays,
                      selectedDays,
                      currentProduct,
                      aditionalServices,
                      authUser.tipo_usuario === 6 ||
                          authUser.tipo_usuario === 7,
                      getGarantiaTotal,
                      authUser.membresia
                  ) -
                  getTotalReservaInicial(cantDays, selectedDays, currentProduct)
                : getTotalReservaFinal(
                      cantDays,
                      selectedDays,
                      currentProduct,
                      aditionalServices,
                      authUser.tipo_usuario === 6 ||
                          authUser.tipo_usuario === 7,
                      getGarantiaTotal,
                      authUser.membresia
                  ),
            descuento_oferta:
                getTotalPrice(
                    selectedDays,
                    currentProduct,
                    cantDays,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false, //ver ? empleado empresa
                    false, // redondeo,
                    true //dia del padre
                ) > 0
                    ? getTotalPrice(
                          selectedDays,
                          currentProduct,
                          cantDays,
                          false,
                          false,
                          false,
                          false,
                          true,
                          false, //ver ? empleado empresa
                          false, // redondeo
                          true //dia del padre
                      )
                    : calcularDescuento(
                          getTotalReservaInicial(
                              cantDays,
                              selectedDays,
                              currentProduct
                          ),
                          currentProduct.oferta_activada,
                          currentProduct.oferta
                      ),
            descuento_empresa:
                authUser.tipo_usuario === 6 || authUser.tipo_usuario === 7
                    ? Math.floor(
                          getTotalReservaInicial(
                              cantDays,
                              selectedDays,
                              currentProduct
                          ) *
                              getDscuentoEmpresa(
                                  currentProduct.descuento_empresa_free,
                                  currentProduct.descuento_empresa_premium
                              )
                      )
                    : 0,
            cancelacion: currentProduct.cancelacion,
            porcentaje_moderado: currentProduct.porcentaje_moderado,
        };
        localStorage.setItem("reserva", JSON.stringify(datos_reserva));
        navigate("/checkout");
    };

    return (
        <div>
            <hr className="w-11/12 mx-auto border-black mt-7 boder-t-2 lg:hidden" />

            {currentProduct?.extras?.length > 0 && (
                <div className="w-11/12 mx-auto mt-7">
                    <h3 className="text-2xl font-semibold ">Extras</h3>
                </div>
            )}
            <div
                className={`mt-5 bg-[${colores.primario}] w-11/12 mx-auto p-4 text-white text-2xl text-center rounded-lg align-middle font-bold`}>
                {currentProduct.precio_base ? (
                    authUser.tipo_usuario === 6 ||
                    authUser.tipo_usuario === 7 ||
                    currentProduct.alianza_empresa === "Si" ||
                    currentProduct.oferta_activada !== "Si" ? (
                        <h2>
                            Desde {""}
                            {menos10Porciento(
                                priceToMonedaElegida(
                                    Number(
                                        currentProduct?.precio_base /
                                            (1 -
                                                currentProduct.fee_cliente /
                                                    100)
                                    ),
                                    currentProduct.moneda_precio_base
                                ),
                                currentProduct
                            )}{" "}
                            {""}
                            {moneda_elegida} por {currentProduct.tipo_estadia}
                        </h2>
                    ) : (
                        <div>
                            <h2 className="line-through">
                                Antes {""}
                                {menos10Porciento(
                                    priceToMonedaElegida(
                                        Number(
                                            currentProduct?.precio_base /
                                                (1 -
                                                    currentProduct.fee_cliente /
                                                        100)
                                        ),
                                        currentProduct.moneda_precio_base
                                    ),
                                    currentProduct
                                )}
                                {""}
                                {moneda_elegida} por{" "}
                                {currentProduct.tipo_estadia}
                            </h2>
                            <h2>
                                Ahora {""}
                                {calcularDescuento(
                                    priceToMonedaElegida(
                                        Number(
                                            currentProduct?.precio_base /
                                                (1 -
                                                    currentProduct.fee_cliente /
                                                        100)
                                        ),
                                        currentProduct.moneda_precio_base
                                    ),
                                    currentProduct.oferta_activada,
                                    currentProduct.oferta,
                                    true
                                )}{" "}
                                {""}
                                {moneda_elegida} por{" "}
                                {currentProduct.tipo_estadia}
                            </h2>
                        </div>
                    )
                ) : (
                    <Spinner width={7} />
                )}
            </div>
            <div className="w-11/12 mx-auto mt-6 text-center ">
                {currentProduct.precio_base ? (
                    false ? (
                        <div>
                            <div className="grid grid-cols-[40%,20%,40%]  mb-6">
                                <div className="mb-4">
                                    <h4 className="text-lg">Mínimo</h4>
                                    <h4
                                        className={`text-[${colores.primario}] text-lg  font-bold mt-1 ml-2`}>
                                        {currentProduct?.alquiler_minimo}
                                        {currentProduct?.alquiler_minimo === 1
                                            ? ` ${currentProduct.tipo_estadia}`
                                            : ` ${currentProduct.tipo_estadia}s`}
                                    </h4>
                                </div>
                                <div className="flex items-center justify-center w-full h-full">
                                    <p
                                        className={`bg-[${colores.primario}] w-full h-1 rotate-90`}></p>
                                </div>
                                <div className="">
                                    <span className="text-lg ">Mínimo</span>
                                    <span
                                        className={`text-[${colores.primario}] text-lg  font-bold mt-1 ml-2`}>
                                        {currentProduct?.tipo_san_valentin ===
                                        "Playa"
                                            ? "2"
                                            : "1"}
                                        {currentProduct?.tipo_san_valentin ===
                                        "Playa"
                                            ? ` ${currentProduct.tipo_estadia}s`
                                            : ` ${currentProduct.tipo_estadia}`}
                                    </span>
                                    <p className="font-medium text-red-700">
                                        solo por San Valentín
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mb-4">
                            <span className="text-lg xl:text-xl">Mínimo</span>
                            <span
                                className={`text-[${colores.primario}] text-lg xl:text-xl font-bold mt-1 ml-2`}>
                                {currentProduct?.alquiler_minimo}
                                {currentProduct?.alquiler_minimo === 1
                                    ? ` ${currentProduct.tipo_estadia}`
                                    : ` ${currentProduct.tipo_estadia}s`}
                            </span>
                        </div>
                    )
                ) : (
                    <Spinner width={7} />
                )}
                {(authUser.tipo_usuario === 1 ||
                    authUser.tipo_usuario === 2) && (
                    <div className="flex flex-col ">
                        <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                            Agregar un cliente:
                        </label>
                        <input
                            autoComplete="off"
                            type="text"
                            name="cliente"
                            onChange={(e) => handleInputChangeDebounce(e)}
                            value={input.nombre_cliente}
                            className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                            placeholder="Cliente"
                        />

                        <div className="overflow-y-auto max-h-28">
                            {fields?.map(
                                ({ id, clientes, correo, tipo_usuario }) => (
                                    <p
                                        key={id}
                                        onClick={() => (
                                            setInput({
                                                nombre_cliente: clientes,
                                                id_cliente: id,
                                                correo_cliente: correo,
                                                tipo_cliente: tipo_usuario,
                                            }),
                                            setFields([])
                                        )}
                                        className="w-full p-2 mt-1 bg-white border border-[#e5e7eb] rounded-lg cursor-pointer hover:bg-slate-50 ">
                                        {clientes}
                                    </p>
                                )
                            )}
                        </div>
                    </div>
                )}
                <span className="mt-10 text-lg">
                    Tarifa sujeta a variación según la fecha seleccionada
                </span>
            </div>
            <div
                className={
                    currentProduct?.alianza_empresa === "No"
                        ? "w-full md:w-11/12 p-1 rounded-lg mx-auto mt-2 bg-slate-100"
                        : "hidden"
                }>
                {showMap.current && Object.keys(currentProduct).length !== 0 ? (
                    <DetailedCalendarAlojamientos
                        arrayPedidos={arrayPedidos}
                        arrayDias={arrayDias}
                        arrayFechas={arrayFechas}
                        arrayMeses={arrayMeses}
                        currentProduct={currentProduct}
                        setCantDays={setCantDays}
                        setSelectedDays={setSelectedDays}
                        minimaDisponibilidad={
                            currentProduct?.minima_disponibilidad
                        }
                        setErrorDates={setErrorDates}
                    />
                ) : (
                    <Spinner />
                )}
                {cantDays === 0 ? (
                    cantDays < currentProduct?.alquiler_minimo + 1 &&
                    errorDates ? (
                        <div className="p-4 mt-5 text-lg text-center text-red-400 bg-white rounded-lg">
                            {errorDates || (
                                <div>
                                    Seleccione un mínimo de{" "}
                                    {currentProduct?.alquiler_minimo +
                                        (currentProduct.tipo_estadia === "dia"
                                            ? 0
                                            : 1)}
                                    {" dias"}
                                </div>
                            )}
                        </div>
                    ) : (
                        currentProduct.alquiler_minimo && (
                            <div className="p-4 mt-5 text-lg text-center text-red-400 bg-white rounded-lg">
                                <div>
                                    Seleccione un mínimo de{" "}
                                    {currentProduct?.alquiler_minimo +
                                        (currentProduct.tipo_estadia === "dia"
                                            ? 0
                                            : 1)}
                                    {" dias"}
                                </div>
                            </div>
                        )
                    )
                ) : currentProduct.alquiler_maximo > 0 &&
                  cantDays >
                      (currentProduct.tipo_estadia === "dia"
                          ? currentProduct.alquiler_maximo
                          : currentProduct.alquiler_maximo + 1) ? (
                    <div className="p-4 mt-5 text-lg text-center text-red-400 bg-white rounded-lg">
                        El alquiler máximo es de{" "}
                        {currentProduct.tipo_estadia === "dia"
                            ? currentProduct.alquiler_maximo
                            : currentProduct.alquiler_maximo + 1}
                        {" dias"}
                    </div>
                ) : (
                    <div className="p-4 mt-5 text-lg bg-white rounded-lg ">
                        {!pointsDiscount && (
                            <ClientReferido
                                setClientReferido={setClientReferido}
                                clientReferido={clientReferido}
                            />
                        )}
                        <h3 className="text-lg font-bold">
                            Fechas de reserva{" "}
                        </h3>
                        <hr />
                        <h3 className="grid justify-between grid-cols-2 text-base lg:flex md:text-lg">
                            <span>Empieza: </span>
                            <span className="text-right">
                                {selectedDays[0].toLocaleDateString(
                                    "es-ES",
                                    options
                                )}
                            </span>
                        </h3>
                        <h3 className="grid justify-between grid-cols-2 mt-3 text-base lg:flex md:text-lg">
                            <span>Termina:</span>{" "}
                            {currentProduct.tipo_estadia === "dia" ? (
                                <span className="text-right">
                                    {selectedDays[1].toLocaleDateString(
                                        "es-ES",
                                        options
                                    )}
                                </span>
                            ) : (
                                <span className="text-right">
                                    {selectedDays[1].toLocaleDateString(
                                        "es-ES",
                                        options
                                    )}
                                </span>
                            )}
                        </h3>
                        <h3 className="my-2 text-base font-bold md:text-lg">
                            Huéspedes:
                        </h3>{" "}
                        <div
                            className={`border-2 border-[${colores.secundario}] p-2 rounded-lg flex flex-col select-none text-base md:text-lg`}>
                            <div
                                className="flex justify-between cursor-pointer "
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setModalHuespedesVisible((prev) => !prev);
                                }}>
                                <span>
                                    {currentProduct.numero_mascotas > 0
                                        ? `Huéspedes ${currentProduct.huespedes} / Mascotas ${cantMascotas}`
                                        : `Huéspedes ${currentProduct.huespedes}`}
                                </span>
                                {modalHuespedesVisible ? (
                                    <span className="text-center">
                                        <svg
                                            className="w-8 h-8 text-black"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />{" "}
                                            <polyline points="6 15 12 9 18 15" />
                                        </svg>
                                    </span>
                                ) : (
                                    <span>
                                        <svg
                                            className="w-8 h-8 text-black"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />{" "}
                                            <polyline points="6 9 12 15 18 9" />
                                        </svg>
                                    </span>
                                )}
                            </div>
                            {modalHuespedesVisible && (
                                <div>
                                    <div>
                                        Adultos
                                        <p className="text-xs text-gray-500">
                                            De 13 años o más
                                        </p>
                                        <div className="flex items-center justify-center mx-auto mt-2 ">
                                            <button
                                                className={`w-8 py-1 mr-4 text-white transition bg-[${colores.secundario}] rounded-lg hover:scale-110`}
                                                onClick={(e) =>
                                                    handleHuespedes(
                                                        e,
                                                        "restar",
                                                        "huespedes",
                                                        "adultos"
                                                    )
                                                }>
                                                -
                                            </button>

                                            <input
                                                className="w-6 text-center bg-transparent outline-none select-none"
                                                type="number"
                                                name="adultos"
                                                value={adultos}
                                                onChange={handleInputChange}
                                            />
                                            <button
                                                className={`w-8 py-1 mx-4 text-white transition bg-[${colores.secundario}] rounded-lg hover:scale-110`}
                                                onClick={(e) =>
                                                    handleHuespedes(
                                                        e,
                                                        "sumar",
                                                        "huespedes",
                                                        "adultos"
                                                    )
                                                }>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        Niños
                                        <p className="text-xs text-gray-500">
                                            De 0 a 12 años
                                        </p>
                                        <div className="flex items-center justify-center mx-auto mt-2 ">
                                            <button
                                                className={`w-8 py-1 mr-4 text-white transition bg-[${colores.secundario}] rounded-lg hover:scale-110`}
                                                onClick={(e) =>
                                                    handleHuespedes(
                                                        e,
                                                        "restar",
                                                        "huespedes",
                                                        "ninos"
                                                    )
                                                }>
                                                -
                                            </button>
                                            {/* <span>{ninos}</span> */}
                                            <input
                                                className="w-6 text-center bg-transparent outline-none select-none"
                                                type="number"
                                                name="ninos"
                                                value={ninos}
                                                onChange={handleInputChange}
                                            />
                                            <button
                                                className={`w-8 py-1 mx-4 text-white transition bg-[${colores.secundario}] rounded-lg hover:scale-110`}
                                                onClick={(e) =>
                                                    handleHuespedes(
                                                        e,
                                                        "sumar",
                                                        "huespedes",
                                                        "ninos"
                                                    )
                                                }>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    {currentProduct.numero_mascotas > 0 && (
                                        <div>
                                            Mascotas
                                            <div className="flex items-center justify-center mx-auto mt-2 ">
                                                <button
                                                    className={`w-8 py-1 mr-4 text-white transition bg-[${colores.secundario}] rounded-lg hover:scale-110`}
                                                    onClick={(e) =>
                                                        handleHuespedes(
                                                            e,
                                                            "restar",
                                                            "mascota"
                                                        )
                                                    }>
                                                    -
                                                </button>
                                                {/* <span>
                                                                    {
                                                                        cantMascotas
                                                                    }
                                                                </span> */}
                                                <input
                                                    className="w-6 text-center bg-transparent outline-none select-none"
                                                    type="number"
                                                    name="mascotas"
                                                    value={cantMascotas}
                                                    onChange={handleInputChange}
                                                />
                                                <button
                                                    className={`w-8 py-1 mx-4 text-white transition bg-[${colores.secundario}] rounded-lg hover:scale-110`}
                                                    onClick={(e) =>
                                                        handleHuespedes(
                                                            e,
                                                            "sumar",
                                                            "mascota"
                                                        )
                                                    }>
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="flex justify-end w-full"
                                        onClick={() =>
                                            setModalHuespedesVisible(
                                                (prev) => !prev
                                            )
                                        }>
                                        <button className="text-base font-medium hover:underline">
                                            Cerrar
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <h3 className="mt-2 text-lg font-bold">
                            Precio de alquiler:
                        </h3>{" "}
                        <hr />
                        <div className="flex justify-between text-base md:text-lg">
                            <span>Precio de alquiler:</span>
                            <h3 className="text-center">
                                {numberToCommas(
                                    getTotalReservaInicial(
                                        cantDays,
                                        selectedDays,
                                        currentProduct
                                    )
                                )}{" "}
                                {moneda_elegida}
                            </h3>
                        </div>
                        {currentProduct.id !== 30329
                            ? null
                            : authUser.puntos >= 5 &&
                              authUser.tipo_usuario === 5 && (
                                  <>
                                      <h3 className="mt-2 text-lg font-bold">
                                          Canje Programa Referidos
                                      </h3>
                                      <hr />

                                      <div className="flex items-baseline w-full text-base md:text-lg">
                                          <input
                                              className={`mr-2 scale-150 checked:bg-[${colores.secundario}]`}
                                              type="checkbox"
                                              onChange={() =>
                                                  setPointsDiscount(
                                                      (prev) => !prev
                                                  )
                                              }
                                          />
                                          <span className="ml-1 text-green-600">
                                              Canjear ahora descuento del 100%
                                              en el precio del alquiler por 5
                                              referidos{" "}
                                          </span>
                                      </div>
                                  </>
                              )}
                        {currentProduct.oferta_activada === "Si" ? (
                            <div className="flex justify-between text-green-500">
                                <span className="font-normal">
                                    Descuento ({currentProduct.oferta}
                                    %):
                                </span>
                                <h3 className="text-base text-center">
                                    -{" "}
                                    {numberToCommas(
                                        calcularDescuento(
                                            getTotalReservaInicial(
                                                cantDays,
                                                selectedDays,
                                                currentProduct
                                            ),
                                            currentProduct.oferta_activada,
                                            currentProduct.oferta
                                        )
                                    )}{" "}
                                    {moneda_elegida}
                                </h3>
                            </div>
                        ) : (
                            ""
                        )}
                        {getTotalReservaInicial(
                            cantDays,
                            selectedDays,
                            currentProduct,
                            true
                        ) !== 0 ? (
                            <div className="flex justify-between text-green-500">
                                <span className="font-normal">
                                    Descuento 10 % (16 - 17 JUN):
                                </span>
                                <h3 className="text-base text-center">
                                    -{" "}
                                    {numberToCommas(
                                        getTotalReservaInicial(
                                            cantDays,
                                            selectedDays,
                                            currentProduct,
                                            true
                                        )
                                    )}{" "}
                                    {moneda_elegida}
                                </h3>
                            </div>
                        ) : (
                            ""
                        )}
                        {((authUser.membresia === "No" &&
                            Number(currentProduct.descuento_empresa_free) >
                                0) ||
                            (authUser.membresia === "Si" &&
                                Number(
                                    currentProduct.descuento_empresa_premium
                                ) > 0)) &&
                            (authUser.tipo_usuario === 6 ||
                                authUser.tipo_usuario === 7) && (
                                <div>
                                    <div className="flex justify-between text-base text-green-500 md:text-lg">
                                        <span>
                                            Descuento de{" "}
                                            {authUser.nombre_empresa}
                                        </span>
                                        <h3 className="text-center">
                                            -{" "}
                                            {numberToCommas(
                                                Math.floor(
                                                    getTotalReservaInicial(
                                                        cantDays,
                                                        selectedDays,
                                                        currentProduct
                                                    ) *
                                                        getDscuentoEmpresa(
                                                            currentProduct.descuento_empresa_free,
                                                            currentProduct.descuento_empresa_premium
                                                        )
                                                )
                                            )}{" "}
                                            {moneda_elegida}
                                        </h3>
                                    </div>
                                </div>
                            )}
                        {getTotalFromDayNumbers(
                            cantDays,
                            currentProduct?.precios_variables
                                .arrayNumeroDiasPorDia,
                            currentProduct.moneda_precio_base,
                            currentProduct.fee_cliente,
                            currentProduct.fee_propietario,
                            currentProduct.feeCasas,
                            selectedDays
                        ).price_total !== 0 && (
                            <div>
                                <div className="flex justify-between text-base text-green-500 md:text-lg">
                                    <span>
                                        {
                                            getTotalFromDayNumbers(
                                                cantDays,
                                                currentProduct
                                                    ?.precios_variables
                                                    .arrayNumeroDiasPorDia,
                                                currentProduct.moneda_precio_base,
                                                currentProduct.fee_cliente,
                                                currentProduct.fee_propietario,
                                                currentProduct.feeCasas,
                                                selectedDays
                                            ).descripcion
                                        }
                                    </span>
                                    <h3 className="text-center">
                                        {numberToCommas(
                                            getTotalFromDayNumbers(
                                                cantDays,
                                                currentProduct
                                                    ?.precios_variables
                                                    .arrayNumeroDiasPorDia,
                                                currentProduct.moneda_precio_base,
                                                currentProduct.fee_cliente,
                                                currentProduct.fee_propietario,
                                                currentProduct.feeCasas,
                                                selectedDays
                                            ).price_total
                                        )}{" "}
                                        {moneda_elegida}
                                    </h3>
                                </div>
                            </div>
                        )}
                        {getTotalFromRangoExactoDias(
                            cantDays,
                            selectedDays,
                            currentProduct?.precios_variables
                                .arrayRangoExactoDiasPorDia,
                            currentProduct.moneda_precio_base,
                            currentProduct.fee_cliente,
                            currentProduct.fee_propietario,
                            currentProduct.feeCasas
                        ).precio_total !== 0 && (
                            <div>
                                <div className="flex justify-between text-base text-green-500 md:text-lg">
                                    <span>
                                        {
                                            getTotalFromRangoExactoDias(
                                                cantDays,
                                                selectedDays,
                                                currentProduct
                                                    ?.precios_variables
                                                    .arrayRangoExactoDiasPorDia,
                                                currentProduct.moneda_precio_base,
                                                currentProduct.fee_cliente,
                                                currentProduct.fee_propietario,
                                                currentProduct.feeCasas
                                            ).descripcion
                                        }
                                    </span>
                                    <h3 className="text-center">
                                        {numberToCommas(
                                            getTotalFromRangoExactoDias(
                                                cantDays,
                                                selectedDays,
                                                currentProduct
                                                    ?.precios_variables
                                                    .arrayRangoExactoDiasPorDia,
                                                currentProduct.moneda_precio_base,
                                                currentProduct.fee_cliente,
                                                currentProduct.fee_propietario,
                                                currentProduct.feeCasas
                                            ).precio_total
                                        )}{" "}
                                        {moneda_elegida}
                                    </h3>
                                </div>
                            </div>
                        )}
                        {pointsDiscount && (
                            <div className="flex justify-between text-base text-green-500 md:text-lg">
                                <span>Canje Programa Referidos </span>
                                <span>
                                    -{" "}
                                    {numberToCommas(
                                        getTotalReservaInicial(
                                            cantDays,
                                            selectedDays,
                                            currentProduct
                                        )
                                    )}{" "}
                                    {moneda_elegida}
                                </span>
                            </div>
                        )}
                        <h3 className="mt-2 text-lg font-bold">
                            Garantía (Reembolsable){" "}
                        </h3>
                        <hr />
                        <h2 className="flex justify-between my-2 text-base font-normal md:text-lg">
                            <div>
                                {currentProduct?.tipo_garantia === "Porcentaje"
                                    ? `${currentProduct.garantia} % del costo del alquiler`
                                    : ""}
                            </div>
                            <div>{getGarantiaTotal()}</div>
                        </h2>
                        <hr />
                        <div className="mt-2 text-base md:text-lg">
                            {currentProduct.servicios_adicionales.length >
                                0 && (
                                <div>
                                    <h3 className="text-lg font-bold">
                                        Servicios adicionales{" "}
                                    </h3>
                                    <hr />
                                    {currentProduct?.servicios_adicionales.map(
                                        (service, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="flex justify-between">
                                                    <div className="flex justify-start">
                                                        {service.tipo ===
                                                        "Obligatorio" ? (
                                                            <input
                                                                type="checkbox"
                                                                disabled
                                                                checked
                                                                className={`mr-2 scale-150 checked:bg-[${colores.secundario}]`}
                                                            />
                                                        ) : (
                                                            <input
                                                                type="checkbox"
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handlerAditionalService(
                                                                        service,
                                                                        event
                                                                    )
                                                                }
                                                                className={`mr-2 scale-150 checked:bg-[${colores.secundario}]`}
                                                            />
                                                        )}

                                                        <span
                                                            className={`font-normal flex items-center ${
                                                                aditionalServices.includes(
                                                                    service
                                                                )
                                                                    ? "text-black-500"
                                                                    : "text-gray-300"
                                                            }`}>
                                                            {service.nombre}:{" "}
                                                        </span>
                                                    </div>
                                                    <span
                                                        className={`font-normal ${
                                                            aditionalServices.includes(
                                                                service
                                                            )
                                                                ? "text-black-500"
                                                                : "text-gray-300"
                                                        } text-base md:text-lg`}>
                                                        {numberToCommas(
                                                            getPriceAditionalService(
                                                                service,
                                                                cantDays,
                                                                selectedDays,
                                                                currentProduct
                                                            )
                                                        )}{" "}
                                                        {moneda_elegida}
                                                    </span>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            )}

                            <div className="flex justify-between w-full mt-5 ">
                                <b>Precio total de la reserva:</b>
                                <hr />
                                <span className="">
                                    {pointsDiscount
                                        ? numberToCommas(
                                              getTotalReservaFinal(
                                                  cantDays,
                                                  selectedDays,
                                                  currentProduct,
                                                  aditionalServices,
                                                  authUser.tipo_usuario === 6 ||
                                                      authUser.tipo_usuario ===
                                                          7,
                                                  getGarantiaTotal,
                                                  authUser.membresia
                                              ) -
                                                  getTotalReservaInicial(
                                                      cantDays,
                                                      selectedDays,
                                                      currentProduct
                                                  ) -
                                                  calcularDescuento(
                                                      getTotalReservaInicial(
                                                          cantDays,
                                                          selectedDays,
                                                          currentProduct
                                                      ),
                                                      currentProduct.oferta_activada,
                                                      currentProduct.oferta
                                                  )
                                          )
                                        : numberToCommas(
                                              getTotalReservaFinal(
                                                  cantDays,
                                                  selectedDays,
                                                  currentProduct,
                                                  aditionalServices,
                                                  authUser.tipo_usuario === 6 ||
                                                      authUser.tipo_usuario ===
                                                          7,
                                                  getGarantiaTotal,
                                                  authUser.membresia
                                              ) -
                                                  calcularDescuento(
                                                      getTotalReservaInicial(
                                                          cantDays,
                                                          selectedDays,
                                                          currentProduct
                                                      ),
                                                      currentProduct.oferta_activada,
                                                      currentProduct.oferta
                                                  ) -
                                                  getTotalReservaInicial(
                                                      cantDays,
                                                      selectedDays,
                                                      currentProduct,
                                                      true
                                                  )
                                          )}{" "}
                                    {moneda_elegida}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <button
                    id="boton-reservar-casa"
                    className={`w-full p-3 mt-5 text-lg text-center text-white bg-[${colores.secundario}] rounded-lg disabled:bg-gray-400 disabled:cursor-not-allowed`}
                    disabled={
                        (currentProduct.alquiler_maximo > 0 &&
                            cantDays >
                                (currentProduct.tipo_estadia === "dia"
                                    ? currentProduct.alquiler_maximo
                                    : currentProduct.alquiler_maximo + 1)) ||
                        selectedDays.length === 0 ||
                        currentProduct.publicado === "Revision"
                    }
                    onClick={() => handleReserva()}>
                    Reservar ahora
                </button>
                {currentProduct.publicado === "Revision" ? (
                    <p className="text-sm text-center text-red-500">
                        El anuncio esta en revisión, no se puede reservar
                    </p>
                ) : null}
            </div>
            <div
                className={
                    currentProduct?.alianza_empresa !== "No"
                        ? "hidden"
                        : "flex flex-col w-4/5 mx-auto mt-5 text-xs text-center"
                }>
                <span>
                    * Pago Total: Pago del 100%, cuando la diferencia entre la
                    fecha de reserva y el Check-in sea menor a 7 dias.
                </span>
                <span className="mt-5">
                    * Pago Parcial: Pago de la mitad del alquiler (sin contar
                    garantia, servicios adicionales), y el saldo 7 dias antes de
                    Check-in.
                </span>
                <span className="mt-5">
                    Pago con tarjeta de crédito/débito cuenta con un costo
                    adicional del 5% del monto a pagar.
                </span>
            </div>
            <div
                className={
                    currentProduct?.alianza_empresa !== "No" &&
                    currentProduct.precio_base
                        ? "flex flex-col w-4/5 mx-auto mt-5 text-center relative"
                        : "hidden"
                }>
                <FormularioDeReservaScrapy
                    setHabilitarModalFormulario={setHabilitarModalFormulario}
                    habilitarModalFormulario={habilitarModalFormulario}
                    id_casa={currentProduct.id}
                    nombre_casa={currentProduct.nombre}
                />
            </div>
        </div>
    );
};

export default DetailedReserva;
