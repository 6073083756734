import React from "react";
import numberToCommas from "../../helpers/numberToCommas";
import { useSelector } from "react-redux";
import DetalleAlquilerBC from "./DetalleAlquilerBC";
import DetalleAlquilerCliente from "./DetalleAlquilerCliente";
import DetalleAlquilerPropietario from "./DetalleAlquilerPropietario";

const DetalleAlquiler = ({
    showAlojamientos,
    setDetallesPrecios,
    setDetallesPrincipales,
    pedidoSelected,
}) => {
    const authUser = useSelector((state) => state.authReducer);

    return (
        <div className="p-4 pb-20 mt-8 w-full  md:pl-52 lg:w-11/12 lg:pl-72">
            <div
                className="flex items-center text-lg cursor-pointer md:mt-2 "
                onClick={() => {
                    setDetallesPrecios((prev) => !prev);
                    setDetallesPrincipales((prev) => !prev);
                }}>
                <svg
                    className="w-6 h-6 -ml-2 text-black"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    {" "}
                    <polyline points="15 18 9 12 15 6" />
                </svg>
                <span className="mx-1">Regresar</span>
            </div>
            <h1 className="mt-4 text-lg font-medium md:text-center md:text-2xl">
                Detalle del alquiler
            </h1>
            <h2 className="mt-5 text-lg font-medium text-blue-500 md:text-center md:text-2xl gap-2 flex justify-center items-center">
                {showAlojamientos
                    ? pedidoSelected.casa
                    : pedidoSelected.embarcacion}

                <span>
                    <svg
                        onClick={() =>
                            window.open(
                                `${
                                    showAlojamientos
                                        ? `/alojamientos/${pedidoSelected.id_casa}/${pedidoSelected.casa}`
                                        : `/embarcaciones/${pedidoSelected.id_embarcacion}/${pedidoSelected.embarcacion}`
                                }`,
                                "_blank"
                            )
                        }
                        className="h-6 w-6 hover:scale-105 cursor-pointer text-blue-400"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {" "}
                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />{" "}
                        <polyline points="15 3 21 3 21 9" />{" "}
                        <line x1="10" y1="14" x2="21" y2="3" />
                    </svg>
                </span>
            </h2>
            <span className="flex text-sm md:justify-center md:text-lg">
                {showAlojamientos
                    ? `Desde el ${
                          pedidoSelected?.fecha_inicio?.split("T")[0]
                      } hasta
                    el ${pedidoSelected?.fecha_final?.split("T")[0]}`
                    : `Reserva para el día ${
                          pedidoSelected?.fecha?.split("T")[0]
                      }`}
            </span>

            {authUser.tipo_usuario <= 2 && (
                <DetalleAlquilerBC
                    pedido_id={pedidoSelected.id}
                    showAlojamientos={showAlojamientos}
                />
            )}

            {(authUser.tipo_usuario === 3 || authUser.tipo_usuario === 4) && (
                <DetalleAlquilerPropietario
                    pedido_id={pedidoSelected.id}
                    showAlojamientos={showAlojamientos}
                />
            )}

            {(authUser.tipo_usuario === 5 ||
                authUser.tipo_usuario === 6 ||
                authUser.tipo_usuario === 7) && (
                <DetalleAlquilerCliente
                    pedido_id={pedidoSelected.id}
                    showAlojamientos={showAlojamientos}
                />
            )}
        </div>
    );
};

export default DetalleAlquiler;
