import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
    editPropertie,
    resetCreatepropertie,
    resetOnPropertie,
} from "../../redux/actions/propertiesActions";
import Spinner from "../Spinner";

const ModalEditProperties = ({ edit, setedit, reduxPropertie }) => {
    const [siguiente, setSiguiente] = useState("Guardar");
    const dispatch = useDispatch();
    const createRedux = useSelector((state) => state.propertiesReducer.create);

    const [properties, setproperties] = useState({
        nombre: reduxPropertie.nombre,
        tipo: reduxPropertie.tipo,
        numero_turnos: reduxPropertie.numero_turnos,
    });

    const handleSubmit = () => {
        setSiguiente(null);
        if (properties.nombre.trim() === "") {
            Swal.fire({
                title: "Error!",
                text: "La propiedad debe tener un nombre",
                icon: "error",
                confirmButtonText: "ok",
            });
        }
        if (!properties.tipo || properties.tipo === "tipo") {
            Swal.fire({
                title: "Error!",
                text: "La propiedad debe tener un tipo",
                icon: "error",
                confirmButtonText: "ok",
            });
        }
        if (properties.nombre.trim() !== "" && properties.tipo !== "tipo") {
            dispatch(editPropertie(reduxPropertie.id, properties));
        }
    };
    useEffect(() => {
        if (createRedux === "actuaizacion exitoso") {
            setedit(!edit);
            dispatch(resetOnPropertie());
            dispatch(resetCreatepropertie());
            Swal.fire({
                title: "Procesado!",
                text: "propiedad editada",
                icon: "success",
                confirmButtonText: "ok",
            });
        }
    }, [createRedux, dispatch]);

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-36 2xl:container 2xl:mx-auto  sm:py-48">
                    <div className="relative flex flex-col items-center  px-4 py-12 bg-white rounded-lg w-96  dark:bg-white ">
                        <div className="w-full text-left my-3">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base  font-bold ">
                                Editar propiedad
                            </header>
                        </div>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <input
                                onChange={(e) =>
                                    setproperties({
                                        ...properties,
                                        nombre: e.target.value,
                                    })
                                }
                                type="text"
                                value={properties?.nombre}
                                placeholder="Nombre"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C]"
                            />
                        </div>
                        <div className="w-full text-left my-1">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base ">
                                Tipo de propiedad
                            </header>
                        </div>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <select
                                onChange={(e) =>
                                    setproperties({
                                        ...properties,
                                        tipo: e.target.value,
                                    })
                                }
                                value={properties?.tipo}
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]">
                                <option
                                    className="text-[#3C3C3C]"
                                    value="Tipo Alojamiento">
                                    Tipo Alojamiento
                                </option>
                                <option
                                    className="text-[#3C3C3C]"
                                    value="Tipo Embarcacion">
                                    Tipo Embarcacion
                                </option>
                                <option
                                    className="text-[#3C3C3C]"
                                    value="Ubicacion">
                                    Ubicacion
                                </option>
                                <option
                                    className="text-[#3C3C3C]"
                                    value="Duracion">
                                    Duracion
                                </option>
                            </select>
                        </div>
                        {properties.tipo === "Duracion" && (
                            <div className="flex flex-col">
                                <label className="mt-4  text-[#3C3C3C]">
                                    Numero de turnos:{" "}
                                </label>
                                <input
                                    className="w-1/2 p-1 mx-auto mt-3 rounded-lg border border-[#E9E9E9]"
                                    type="number"
                                    placeholder="0"
                                    value={properties.numero_turnos}
                                    onChange={(e) =>
                                        setproperties({
                                            ...properties,
                                            numero_turnos: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        )}
                        <div className="w-full ">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="pb-3 text-center ">
                                            <button
                                                onClick={(e) => handleSubmit(e)}
                                                disabled={!siguiente}
                                                className="text-center border-blue-400 bg-blue-bluecorner text-white w-full  border hover:bg-white hover:text-blue-bluecorner py-3 rounded-md disabled:bg-blue-400 mt-4">
                                                {siguiente ? (
                                                    siguiente
                                                ) : (
                                                    <Spinner width={7} />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => (
                                setedit(!edit), dispatch(resetOnPropertie())
                            )}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditProperties;
