import axios from "axios";

export const getRulesPerPage = (pageNumber = 1, SioNo) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios(
            `${process.env.REACT_APP_PROXY}/regla/all/${pageNumber}/${SioNo}`,
            { headers }
        );
        return dispatch({
            type: "GET_RULES",
            payload: json.data,
        });
    };
};

export const searchRulesByName = (query, SioNo) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/regla/all/1/${SioNo}/?nombre=${query}`,
            { headers }
        );
        return dispatch({
            type: "SEARCH_RULES",
            payload: json.data,
        });
    };
};

export const ruleSelected = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const category = await axios(
            `${process.env.REACT_APP_PROXY}/categoria/detalle/${id}`,
            { headers }
        );
        return dispatch({
            type: "RULE_SELECTED",
            payload: {
                category,
            },
        });
    };
};

export const clearRuleSelected = () => {
    return async function (dispatch) {
        return dispatch({
            type: "CLEAR_RULE",
        });
    };
};

export const getOneRule = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios(
            `${process.env.REACT_APP_PROXY}/regla/detalle/${id}`,
            { headers }
        );

        return dispatch({
            type: "GET_ONE_RULE",
            payload: json.data,
        });
    };
};

export const getAllRules = () => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios(
            `${process.env.REACT_APP_PROXY}/categoria/select`,
            { headers }
        );
        return dispatch({
            type: "GET_ALL_RULES",
            payload: json.data,
        });
    };
};

export const deleteRule = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        await axios.delete(
            `${process.env.REACT_APP_PROXY}/regla/eliminar/${id}`,
            {
                headers,
            }
        );
        return dispatch({
            type: "DELETE_RULE",
            payload: id,
        });
    };
};

export const clearRules = () => {
    return async function (dispatch) {
        return dispatch({
            type: "CLEAR_RULES",
        });
    };
};
