import axios from "axios";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const EditarNombre = ({ producto, setProducto, setModal, tipo_producto }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [fields, setFields] = useState([]);
    const debounceInput = useRef(null);
    const debouncePropertyName = useRef(null);
    const authUser = useSelector((state) => state.authReducer);
    const [owner, setOwner] = useState({
        id_propietario: producto?.data.id_propietario || "",
        propietario: producto?.data.propietario || "",
    });
    const [inputName, setInputName] = useState(null);

    const handleInputChange = (e) => {
        if (e.target.name === "ownerName") {
            setOwner({
                id: null,
                propietario: e.target.value,
            });
            if (debounceInput.current) clearTimeout(debounceInput.current);
            if (e.target.value === "") {
                setFields([]);
            }
            debounceInput.current = setTimeout(async () => {
                const { data } = await axios(
                    `${process.env.REACT_APP_PROXY}/propietario/autocomplete?autocomplete=${e.target.value}`,
                    { headers }
                );
                setFields(data);
            }, 500);
        } else {
            if (debouncePropertyName.current)
                clearTimeout(debouncePropertyName.current);
            if (e.target.value === "") {
                setInputName(null);
            }
            debouncePropertyName.current = setTimeout(async () => {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/casa/getNombre`,
                    {
                        nombre: e.target.value,
                        id: producto.data.id,
                    },
                    { headers }
                );
                if (data === "No disponible") {
                    setInputName("¡Nombre de propiedad no disponible!");
                } else if (data === "Disponible")
                    setInputName("¡Nombre disponible!");
            }, 500);
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            setFields([]);
        }, 200);
    };

    const handleGuardar = async () => {
        if (producto.data.nombre === "") {
            return Swal.fire({
                title: "Tienes que elegir un nombre",
                icon: "warning",
            });
        }

        const payload = {
            nombre: producto.data.nombre,
            descripcion: producto.data.descripcion,
            id_propietario: owner.id_propietario,
            id: producto.data.id,
            id_usuario: authUser.id_usuario,
        };

        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/${tipo_producto}/actualizarNombre/${producto.data.id}`,
                payload,
                { headers }
            );
            setModal(false);
        } catch (error) {
            Swal.fire({
                icon: "warning",
                title: error.response.data.error,
            });
        }
    };

    return (
        <div className="border mt-6 p-4 rounded-lg">
            <h1>Título del anuncio</h1>

            <div className="w-full mt-3 ">
                {inputName && (
                    <h1
                        className={`${
                            inputName === "¡Nombre de propiedad no disponible!"
                                ? "text-red-600"
                                : "text-green-600"
                        } text-xs  font-semibold`}>
                        {inputName}
                    </h1>
                )}
                <input
                    onChange={(e) => {
                        handleInputChange(e);
                        setProducto({
                            ...producto,
                            data: {
                                ...producto.data,
                                nombre: e.target.value,
                            },
                        });
                    }}
                    value={producto?.data.nombre}
                    type={"text"}
                    placeholder="Ejm: Casa La Pradera"
                    className="w-full border my-2 bg-transparent rounded-lg py-3 px-3 outline-none focus:outline-none "
                />
            </div>
            {authUser.tipo_usuario !== 3 && authUser.tipo_usuario !== 4 && (
                <div className="w-full">
                    <h1 className="text-base text-black">
                        Nombre del propietario
                    </h1>

                    <input
                        type="text"
                        onBlur={handleBlur}
                        value={owner.propietario}
                        autoComplete="off"
                        name="ownerName"
                        onChange={handleInputChange}
                        placeholder="Ingrese el nombre del propietario"
                        className="w-full border my-3 bg-transparent rounded-lg py-3 px-3 outline-none focus:outline-none "
                    />

                    <div className="-mt-4">
                        {fields.length > 0 &&
                            fields.map((owner) => (
                                <div
                                    key={owner.id}
                                    className="w-full border my-3 bg-transparent rounded-lg py-3 px-3 outline-none focus:outline-none cursor-pointer"
                                    onClick={() => {
                                        setOwner({
                                            id_propietario: owner.id,
                                            propietario: owner.propietario,
                                        });
                                        setProducto({
                                            ...producto,
                                            data: {
                                                ...producto.data,
                                                id_propietario: owner.id,
                                                propietario: owner.propietario,
                                            },
                                        });
                                        setFields([]);
                                    }}>
                                    {owner.propietario}
                                </div>
                            ))}
                    </div>
                </div>
            )}
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarNombre;
