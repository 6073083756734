import axios from "axios";
import React from "react";

export const Scrapy = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const getScrapy = async () => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/scrapy/all/`,
            { headers }
        );
        console.log(json.data);
    };
    return (
        <div
            onClick={() => getScrapy()}
            className="w-20 p-3 mx-auto mt-5 text-center text-white bg-blue-500 rounded-md cursor-pointer">
            Scrapy
        </div>
    );
};
