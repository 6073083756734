import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import NavBar from "../NavBar";
import Footer from "../Footer";
import axios from "axios";
import Spinner from "../Spinner";
import Swal from "sweetalert2";

export const ResenasNew = () => {
    const { idPedido, puntuacion } = useParams();

    const [puntos, setPuntos] = useState(puntuacion);
    const [pedido, setPedido] = useState(null);
    const [loading, setLoading] = useState(false);
    const [comentario, setComentario] = useState("");
    const navigate = useNavigate();
    const getOnePedido = async () => {
        try {
            const { data } = await axios(
                `${process.env.REACT_APP_PROXY}/pedidoCasa/detalle/${idPedido}`
            );

            console.log(data);
            setPedido(data);
        } catch (error) {
            console.log(error);
        }
    };

    const registroResena = async () => {
        setLoading(true);
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/resenasNps/`,
                {
                    calificacion: puntos,
                    resena: comentario,
                    id_pedido: idPedido,
                }
            );

            console.log(data);
            Swal.fire({
                title: "Reseña registrada",
                text: " Gracias por su reseña",
                icon: "success",
                confirmButtonText: "Ok",
            });
            navigate("/");
            setPedido(data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getOnePedido();
    }, []);

    return (
        <div className="mt-32 xs:mt-40 ">
            <NavBar />
            {pedido === null ? (
                <div className="my-10">
                    <Spinner width={40} />
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center w-11/12 max-w-2xl mx-auto mb-10 xs:w-10/12">
                    <h2 className="text-xl font-bold cursor-pointer select-none xs:text-3xl">
                        ¡{pedido.cliente}, gracias por alojarte con nosotros!
                    </h2>

                    <div className="mt-8">
                        <p className="xs:text-lg">
                            De parte de Blue Corner queremos expresar nuestro
                            sincero agradecimiento por confiar en nosotros
                            alquilando la{" "}
                            <span className="font-semibold">{pedido.casa}</span>
                            , esperamos que hayan pasado una experiencia
                            agradable.
                        </p>
                        <p className="mt-4 xs:text-lg">
                            ¿Recomendarías BlueCorner a un amigo o familiar?
                        </p>
                        <div className="flex flex-col w-full p-2 mt-4 border-2 border-black rounded-md">
                            <p className="mb-2 text-center xs:hidden">
                                No recomendaría
                            </p>
                            <div className="flex flex-col flex-grow sm:flex-row ">
                                <div className="flex justify-between flex-grow">
                                    <div
                                        onClick={() => setPuntos("1")}
                                        className={` border-2 border-[#053465] ${
                                            puntos === "1"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none `}>
                                        1
                                    </div>
                                    <div
                                        onClick={() => setPuntos("2")}
                                        className={` ${
                                            puntos === "2"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } border-2 border-[#053465] sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none`}>
                                        2
                                    </div>
                                    <div
                                        onClick={() => setPuntos("3")}
                                        className={` ${
                                            puntos === "3"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } border-2 border-[#053465] sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none`}>
                                        3
                                    </div>
                                    <div
                                        onClick={() => setPuntos("4")}
                                        className={` ${
                                            puntos === "4"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } border-2 border-[#053465] sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none`}>
                                        4
                                    </div>
                                    <div
                                        onClick={() => setPuntos("5")}
                                        className={` ${
                                            puntos === "5"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } border-2 border-[#053465] sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none`}>
                                        5
                                    </div>
                                </div>

                                <div className="flex justify-between flex-grow mt-2 sm:ml-2 md723:ml-4 sm:mt-0 ">
                                    <div
                                        onClick={() => setPuntos("6")}
                                        className={` ${
                                            puntos === "6"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } border-2 border-[#053465] sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none`}>
                                        6
                                    </div>
                                    <div
                                        onClick={() => setPuntos("7")}
                                        className={`${
                                            puntos === "7"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } border-2 border-[#053465] sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none`}>
                                        7
                                    </div>
                                    <div
                                        onClick={() => setPuntos("8")}
                                        className={`${
                                            puntos === "8"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } border-2 border-[#053465] sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none`}>
                                        8
                                    </div>
                                    <div
                                        onClick={() => setPuntos("9")}
                                        className={`${
                                            puntos === "9"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } border-2 border-[#053465] sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none`}>
                                        9
                                    </div>
                                    <div
                                        onClick={() => setPuntos("10")}
                                        className={`${
                                            puntos === "10"
                                                ? "bg-[#053465] text-white"
                                                : " text-[#053465]"
                                        } border-2 border-[#053465] sm:w-12 sm:h-12 w-10 h-10  rounded-full flex justify-center items-center font-bold cursor-pointer select-none`}>
                                        10
                                    </div>
                                </div>
                            </div>
                            <p className="text-center xs:hidden">
                                Muy recomendado
                            </p>
                            <div class="xs:flex justify-between hidden">
                                <p>No recomendaría</p>
                                <p>Muy recomendado</p>
                            </div>
                        </div>

                        <p className="mt-4 xs:text-lg">
                            Nos encontramos en constante mejora cada día y nos
                            gustaría contar con sus comentarios para ofrecer
                            cada vez una mejor experiencia.
                        </p>
                        <div className="w-full px-2 mt-5 h-44 rounded-2xl bg-gray-50 ring-2 ring-gray-200">
                            <textarea
                                type="text"
                                value={comentario}
                                name="comentario"
                                onChange={(e) => setComentario(e.target.value)}
                                placeholder="Comentario"
                                className="w-full h-40 my-3 bg-transparent border-none outline-none focus:outline-none"
                            />
                        </div>
                        <div>
                            <button
                                className="w-full rounded-2xl border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold cursor-pointer select-none text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10 disabled:bg-slate-300 disabled:border-b-slate-500 disabled:cursor-not-allowed"
                                onClick={(e) => registroResena()}
                                disabled={loading}>
                                {loading ? "Enviando.." : "Enviar"}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
};
