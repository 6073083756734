import React, { useEffect, useState, useLayoutEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ModalDaysAvailableBoats from "./ModalDaysAvailableBoats";
import ModalEditDaysAvailableBoats from "./ModalEditDaysAvailableBoats";
import Spinner from "../../Spinner";
import { useSelector } from "react-redux";

const DaysAvailableBoats = ({
    onboarding,
    setModalDisponibilidad,
    setOnboarding,
    producto,
    tipo,
    setPage,
    actualPage,
    setRegresar,
}) => {
    const [days, setDays] = useState([]);
    const [siguiente, setSiguiente] = useState("Guardar");
    const [loading, setLoading] = useState(false);
    const [editDuracionSelect, setEditDuracionSelect] = useState("");
    const [createVisible, setCreateVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const authUser = useSelector((state) => state.authReducer);
    const [fakeRefresh, setFakeRefresh] = useState(false);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        const getvariables = async () => {
            setLoading(true);
            if (onboarding.tipo_producto === "embarcacion") {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/embarcacionDisponiblidad/all/${onboarding.id}`,
                    { headers }
                );

                setDays(data);
            }
            if (onboarding.tipo_producto === "casa") {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/productoDisponiblidad/all/${onboarding.id}`,
                    { headers }
                );

                setDays(data);
            }
        };

        getvariables();
    }, []);

    useLayoutEffect(() => {
        setLoading(false);
    }, [days]);

    useEffect(() => {
        const getvariables = async () => {
            if (onboarding.tipo_producto === "embarcacion") {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/embarcacionDisponiblidad/all/${onboarding.id}`,
                    { headers }
                );

                setDays(data);
            }
            if (onboarding.tipo_producto === "casa") {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/productoDisponiblidad/all/${onboarding.id}`,
                    { headers }
                );

                setDays(data);
            }
        };

        getvariables();
    }, [fakeRefresh]);

    const deleteVariable = async (id) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "Una vez eliminado, no podrás recuperar este registro",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "No, cancelar",
        }).then(async (result) => {
            if (result.value) {
                if (onboarding.tipo_producto === "embarcacion") {
                    const { data } = await axios.delete(
                        ` ${process.env.REACT_APP_PROXY}/embarcacionDisponiblidad/eliminar/${id}`,
                        { headers }
                    );

                    if (data.Message === "Eliminado con exito") {
                        Swal.fire(
                            "Eliminado",
                            "El registro ha sido eliminado",
                            "success"
                        );
                        setFakeRefresh(!fakeRefresh);
                    }
                }
                if (onboarding.tipo_producto === "casa") {
                    const { data } = await axios.delete(
                        ` ${process.env.REACT_APP_PROXY}/productoDisponiblidad/eliminar/${id}`,
                        {
                            data: { id_usuario: authUser.id_usuario },
                            headers: {
                                Authorization: token,
                            },
                        }
                    );

                    if (data.Message === "Eliminado con exito") {
                        Swal.fire(
                            "Eliminado",
                            "El registro ha sido eliminado",
                            "success"
                        );
                        setFakeRefresh(!fakeRefresh);
                    }
                }
            }
        });
    };

    const nextPage = async () => {
        setSiguiente(null);
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/${onboarding.tipo_producto}/detalle/${onboarding.id}/0`
        );

        if (json.status === 200) {
            setOnboarding({
                ...producto,
                data: {
                    ...json.data,
                    tipo_producto: tipo,
                },
            });
            setModalDisponibilidad(false);
            setRegresar(false);
        }
    };

    return (
        <div className="w-full p-5 ">
            <div
                className={
                    "flex items-center text-lg cursor-pointer pt-4 px-4 mt-6  w-full  "
                }
                onClick={() => (
                    setModalDisponibilidad(false), setRegresar(false)
                )}>
                <svg
                    className="w-6 h-6 -ml-2 text-black"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    {" "}
                    <polyline points="15 18 9 12 15 6" />
                </svg>
                <span className="mx-1">Regresar</span>
            </div>
            <div className="flex items-center justify-between w-full px-6 mx-auto ">
                <h1 className="text-4xl  py-10 text-center text-[#3C3C3C]">
                    Dias no disponibles
                </h1>
                <div className="mt-4 md:ml-10">
                    <button
                        className="flex items-center px-3 py-2 mx-auto text-lg border rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:text-white"
                        onClick={() => setCreateVisible(!createVisible)}>
                        <svg
                            className="w-5 h-5 text-blue-bluecorner group-hover:text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <line x1="12" y1="5" x2="12" y2="19" />{" "}
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        <span className="items-center hidden text-base sm:block ">
                            Añadir
                        </span>
                    </button>
                </div>
            </div>

            {/* <div className="w-10/12 mx-auto sm:flex xl:w-8/12 ">
                <button
                    className="inline-flex items-center px-3 py-2 mx-auto text-lg font-semibold text-blue-700 border border-blue-700 rounded group hover:bg-blue-700 hover:text-white "
                    onClick={() => setCreateVisible(!createVisible)}>
                    <svg
                        className="w-5 h-5 text-blue-700 group-hover:text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                    <span className="items-center">Añadir</span>
                </button>
            </div> */}
            {loading ? <Spinner width={20} /> : null}

            {createVisible && (
                <ModalDaysAvailableBoats
                    modalVisible={createVisible}
                    setModalVisible={setCreateVisible}
                    fakeRefresh={fakeRefresh}
                    setFakeRefresh={setFakeRefresh}
                    onboarding={onboarding}
                />
            )}
            {editVisible && (
                <ModalEditDaysAvailableBoats
                    dia={editDuracionSelect}
                    modalVisible={editVisible}
                    setModalVisible={setEditVisible}
                    fakeRefresh={fakeRefresh}
                    setFakeRefresh={setFakeRefresh}
                    onboarding={onboarding}
                />
            )}
            <div
                className={
                    days?.findIndex(
                        (v) => v.tipo_variante === "Rango de Fechas"
                    ) !== -1
                        ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5 overflow-auto"
                        : "hidden"
                }>
                <div className="w-full">
                    <p className="text-center text-xl sm:text-2xl text-[#3C3C3C] ">
                        No disponibles por fechas
                    </p>

                    <table className=" w-full  min-w-[580px] overflow-x-auto mt-14 ">
                        <thead className="text-left">
                            <tr>
                                <th className="w-2/12 min-w-[5rem] "></th>
                                <th className="w-2/12 min-w-[5rem] "></th>

                                <th className="w-1/12 text-right min-w-[5rem]  table-cell pr-10"></th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {days.map((dias) => (
                                <tr
                                    key={dias.id}
                                    className={
                                        dias?.tipo_variante ===
                                        "Rango de Fechas"
                                            ? "w-full border-t "
                                            : "hidden"
                                    }>
                                    <td>
                                        {dias?.tipo_variante ===
                                            "Rango de Fechas" && "Fechas"}
                                    </td>
                                    <td className="table-cell">
                                        {dias.tipo_variante ===
                                            "Rango de Fechas" &&
                                            "del " +
                                                dias.fecha_inicio
                                                    .slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("-") +
                                                " al " +
                                                dias.fecha_final
                                                    .slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("-")}
                                    </td>

                                    <td
                                        className={
                                            "flex justify-end py-2 px-2"
                                        }>
                                        <div className="flex">
                                            <button
                                                onClick={() => (
                                                    setEditDuracionSelect(dias),
                                                    setEditVisible(!editVisible)
                                                )}
                                                title="Editar"
                                                className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                <svg
                                                    className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />
                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                    <line
                                                        x1="16"
                                                        y1="5"
                                                        x2="19"
                                                        y2="8"
                                                    />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={() =>
                                                    deleteVariable(dias?.id)
                                                }
                                                title="Eliminar"
                                                className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                <svg
                                                    className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <line
                                                        x1="4"
                                                        y1="7"
                                                        x2="20"
                                                        y2="7"
                                                    />{" "}
                                                    <line
                                                        x1="10"
                                                        y1="11"
                                                        x2="10"
                                                        y2="17"
                                                    />{" "}
                                                    <line
                                                        x1="14"
                                                        y1="11"
                                                        x2="14"
                                                        y2="17"
                                                    />{" "}
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* <table className="w-full table-auto mt-2">
                        <thead className="bg-blue-700 ">
                            <tr className="text-center bg-primary">
                                <th className=" w-1/12 min-w-[85px] text-xs font-semibold text-white py-1  px-3 "></th>

                                <th className="w-2/12  text-xs font-semibold text-white py-1  px-3 "></th>
                                <th className="w-1/12  text-xs font-semibold text-white py-1  px-3 "></th>
                            </tr>
                        </thead>
                        {days.map((otro, i) => (
                            <tbody
                                key={i}
                                className={
                                    otro?.tipo_variante === "Rango de Fechas"
                                        ? ""
                                        : "hidden"
                                }>
                                <tr>
                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro?.tipo_variante ===
                                            "Rango de Fechas" &&
                                            "Rango de Fechas"}
                                    </td>

                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro.tipo_variante ===
                                            "Rango de Fechas" &&
                                            "del " +
                                                otro.fecha_inicio
                                                    .slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("-") +
                                                " al " +
                                                otro.fecha_final
                                                    .slice(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("-")}
                                    </td>
                                    <td
                                        className={
                                            "text-center text-dark  text-base py-1 px-2 text-red-600  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] lg:text-xl"
                                        }>
                                        <button
                                            onClick={() => (
                                                setEditDuracionSelect(otro),
                                                setEditVisible(!editVisible)
                                            )}
                                            title="Editar"
                                            className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white">
                                            <svg
                                                className="h-4 w-4 text-blue-500 group-hover:text-white"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                <line
                                                    x1="16"
                                                    y1="5"
                                                    x2="19"
                                                    y2="8"
                                                />
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() =>
                                                deleteVariable(otro?.id)
                                            }
                                            title="Eliminar"
                                            className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded  group hover:bg-red-700 hover:text-white">
                                            <svg
                                                className="h-4 w-4 text-red-500 group-hover:text-white"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <line
                                                    x1="4"
                                                    y1="7"
                                                    x2="20"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="10"
                                                    y1="11"
                                                    x2="10"
                                                    y2="17"
                                                />
                                                <line
                                                    x1="14"
                                                    y1="11"
                                                    x2="14"
                                                    y2="17"
                                                />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table> */}
                </div>
            </div>

            <div
                className={
                    days?.findIndex(
                        (v) => v.tipo_variante === "Rango de Meses"
                    ) !== -1
                        ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5 overflow-auto "
                        : "hidden"
                }>
                <div className="w-full">
                    <p className="text-center text-xl sm:text-2xl text-[#3C3C3C] ">
                        No disponibles por meses
                    </p>
                    <table className=" w-full  min-w-[580px] overflow-x-auto mt-14 ">
                        <thead className="text-left">
                            <tr>
                                <th className="w-2/12 min-w-[5rem] "></th>
                                <th className="w-2/12 min-w-[5rem] "></th>

                                <th className="w-1/12 text-right min-w-[5rem]  table-cell pr-10"></th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {days.map((dias) => (
                                <tr
                                    key={dias.id}
                                    className={
                                        dias?.tipo_variante === "Rango de Meses"
                                            ? "w-full border-t "
                                            : "hidden"
                                    }>
                                    <td>
                                        {dias?.tipo_variante ===
                                            "Rango de Meses" && "Meses"}
                                    </td>
                                    <td className="table-cell">
                                        {dias.tipo_variante ===
                                            "Rango de Meses" &&
                                            "de " +
                                                dias.mes_inicio +
                                                " a " +
                                                dias.mes_final}
                                    </td>

                                    <td
                                        className={
                                            "flex justify-end py-2 px-2"
                                        }>
                                        <div className="flex">
                                            <button
                                                onClick={() => (
                                                    setEditDuracionSelect(dias),
                                                    setEditVisible(!editVisible)
                                                )}
                                                title="Editar"
                                                className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                <svg
                                                    className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />
                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                    <line
                                                        x1="16"
                                                        y1="5"
                                                        x2="19"
                                                        y2="8"
                                                    />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={() =>
                                                    deleteVariable(dias?.id)
                                                }
                                                title="Eliminar"
                                                className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                <svg
                                                    className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <line
                                                        x1="4"
                                                        y1="7"
                                                        x2="20"
                                                        y2="7"
                                                    />{" "}
                                                    <line
                                                        x1="10"
                                                        y1="11"
                                                        x2="10"
                                                        y2="17"
                                                    />{" "}
                                                    <line
                                                        x1="14"
                                                        y1="11"
                                                        x2="14"
                                                        y2="17"
                                                    />{" "}
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* <table className="w-full table-auto mt-2">
                        <thead className="bg-blue-700 ">
                            <tr className="text-center bg-primary">
                                <th className=" w-1/12 min-w-[85px] text-xs font-semibold text-white py-1  px-3 "></th>

                                <th className="w-2/12  text-xs font-semibold text-white py-1  px-3 "></th>
                                <th className="w-1/12  text-xs font-semibold text-white py-1  px-3 "></th>
                            </tr>
                        </thead>
                        {days?.map((otro, i) => (
                            <tbody
                                key={i}
                                className={
                                    otro?.tipo_variante === "Rango de Meses"
                                        ? ""
                                        : "hidden"
                                }>
                                <tr>
                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro?.tipo_variante ===
                                            "Rango de Meses" &&
                                            "Rango de Meses"}
                                    </td>

                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro.tipo_variante ===
                                            "Rango de Meses" &&
                                            "de " +
                                                otro.mes_inicio +
                                                " a " +
                                                otro.mes_final}
                                    </td>
                                    <td
                                        className={
                                            "text-center text-dark  text-base py-1 px-2 text-red-600  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] lg:text-xl"
                                        }>
                                        <button
                                            onClick={() => (
                                                setEditDuracionSelect(otro),
                                                setEditVisible(!editVisible)
                                            )}
                                            title="Editar"
                                            className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white">
                                            <svg
                                                className="h-4 w-4 text-blue-500 group-hover:text-white"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                <line
                                                    x1="16"
                                                    y1="5"
                                                    x2="19"
                                                    y2="8"
                                                />
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() =>
                                                deleteVariable(otro?.id)
                                            }
                                            title="Eliminar"
                                            className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded  group hover:bg-red-700 hover:text-white">
                                            <svg
                                                className="h-4 w-4 text-red-500 group-hover:text-white"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <line
                                                    x1="4"
                                                    y1="7"
                                                    x2="20"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="10"
                                                    y1="11"
                                                    x2="10"
                                                    y2="17"
                                                />
                                                <line
                                                    x1="14"
                                                    y1="11"
                                                    x2="14"
                                                    y2="17"
                                                />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table> */}
                </div>
            </div>

            <div
                className={
                    days?.findIndex(
                        (v) => v.tipo_variante === "Rango de Dias"
                    ) !== -1
                        ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5 overflow-auto"
                        : "hidden"
                }>
                <div className="w-full">
                    <p className="text-center text-xl sm:text-2xl text-[#3C3C3C] ">
                        No disponibles por dias de la semana
                    </p>

                    <table className=" w-full  min-w-[580px] overflow-x-auto mt-14 ">
                        <thead className="text-left">
                            <tr>
                                <th className="w-2/12 min-w-[5rem] "></th>
                                <th className="w-2/12 min-w-[5rem] "></th>

                                <th className="w-1/12 text-right min-w-[5rem]  table-cell pr-10"></th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {days.map((dias) => (
                                <tr
                                    key={dias.id}
                                    className={
                                        dias?.tipo_variante === "Rango de Dias"
                                            ? "w-full border-t "
                                            : "hidden"
                                    }>
                                    <td>
                                        {dias?.tipo_variante ===
                                            "Rango de Dias" && "Dias"}
                                    </td>
                                    <td className="table-cell">
                                        {dias.tipo_variante ===
                                            "Rango de Dias" &&
                                            "de " +
                                                dias.dia_inicio +
                                                " a " +
                                                dias.dia_final}
                                    </td>

                                    <td
                                        className={
                                            "flex justify-end py-2 px-2"
                                        }>
                                        <div className="flex">
                                            <button
                                                onClick={() => (
                                                    setEditDuracionSelect(dias),
                                                    setEditVisible(!editVisible)
                                                )}
                                                title="Editar"
                                                className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                <svg
                                                    className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />
                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                    <line
                                                        x1="16"
                                                        y1="5"
                                                        x2="19"
                                                        y2="8"
                                                    />
                                                </svg>
                                            </button>
                                            <button
                                                onClick={() =>
                                                    deleteVariable(dias?.id)
                                                }
                                                title="Eliminar"
                                                className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white">
                                                <svg
                                                    className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <line
                                                        x1="4"
                                                        y1="7"
                                                        x2="20"
                                                        y2="7"
                                                    />{" "}
                                                    <line
                                                        x1="10"
                                                        y1="11"
                                                        x2="10"
                                                        y2="17"
                                                    />{" "}
                                                    <line
                                                        x1="14"
                                                        y1="11"
                                                        x2="14"
                                                        y2="17"
                                                    />{" "}
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* <table className="w-full table-auto mt-2">
                        <thead className="bg-blue-700 ">
                            <tr className="text-center bg-primary">
                                <th className=" w-1/12 min-w-[85px] text-xs font-semibold text-white py-1  px-3 "></th>

                                <th className="w-2/12  text-xs font-semibold text-white py-1  px-3 "></th>
                                <th className="w-1/12  text-xs font-semibold text-white py-1  px-3 "></th>
                            </tr>
                        </thead>
                        {days?.map((otro, i) => (
                            <tbody
                                key={i}
                                className={
                                    otro?.tipo_variante === "Rango de Dias"
                                        ? ""
                                        : "hidden"
                                }>
                                <tr>
                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro?.tipo_variante ===
                                            "Rango de Dias" && "Rango de Dias"}
                                    </td>

                                    <td
                                        className={
                                            "text-center text-dark  text-xs py-1 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8] md:text-sm lg:text-base"
                                        }>
                                        {otro.tipo_variante ===
                                            "Rango de Dias" &&
                                            "de " +
                                                otro.dia_inicio +
                                                " a " +
                                                otro.dia_final}
                                    </td>
                                    <td
                                        className={
                                            "text-center text-dark  text-base py-1 px-2 text-red-600  bg-[#F3F6FF] border-b border-l border-[#E8E8E8] lg:text-xl"
                                        }>
                                        <button
                                            onClick={() => (
                                                setEditDuracionSelect(otro),
                                                setEditVisible(!editVisible)
                                            )}
                                            title="Editar"
                                            className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white">
                                            <svg
                                                className="h-4 w-4 text-blue-500 group-hover:text-white"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                <line
                                                    x1="16"
                                                    y1="5"
                                                    x2="19"
                                                    y2="8"
                                                />
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() =>
                                                deleteVariable(otro?.id)
                                            }
                                            title="Eliminar"
                                            className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded  group hover:bg-red-700 hover:text-white">
                                            <svg
                                                className="h-4 w-4 text-red-500 group-hover:text-white"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />
                                                <line
                                                    x1="4"
                                                    y1="7"
                                                    x2="20"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="10"
                                                    y1="11"
                                                    x2="10"
                                                    y2="17"
                                                />
                                                <line
                                                    x1="14"
                                                    y1="11"
                                                    x2="14"
                                                    y2="17"
                                                />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table> */}
                </div>
            </div>
            <div className="flex items-center justify-center mt-20">
                <div className="pb-6 mt-4 text-center">
                    <button
                        disabled={!siguiente}
                        onClick={() => nextPage()}
                        className="text-center border-blue-400 bg-blue-bluecorner text-white w-full xs:w-80 border hover:bg-white hover:text-blue-bluecorner p-3 rounded-md disabled:bg-blue-400">
                        {siguiente ? siguiente : <Spinner width={7} />}
                    </button>
                </div>
            </div>
            {/* <div className="flex items-center justify-center mt-20">
                <button
                    onClick={() => setPage(actualPage - 1)}
                    className="p-2 pl-5 pr-5 mr-2 text-lg text-gray-500 min-w-[118px] bg-transparent border-2 border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 ">
                    Anterior
                </button>

                <button
                    disabled={!siguiente}
                    onClick={() => nextPage()}
                    className="p-2 pl-5 pr-5 text-lg text-gray-500 bg-transparent border-2 min-w-[118px] border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 disabled:bg-white disabled:border-black">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div> */}
        </div>
    );
};

export default DaysAvailableBoats;
