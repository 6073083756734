import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";
import { useSelector } from "react-redux";

const EditarEtiquetas = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
}) => {
    const [siguiente, setSiguiente] = useState("Guardar");
    const [enviar, setEnviar] = useState(true);
    const [input, setInput] = useState("");
    const [productLabels, setProductLabels] = useState([]);
    const [labelrandom, setLabelrandom] = useState([]);
    let matches = [];
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const debounceInput = useRef(null);
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        random();
    }, []);

    useEffect(() => {
        if (labelrandom.length && enviar) {
            etiquetas(producto.data.id);
            setEnviar(false);
        }
    }, [labelrandom]);

    const handleInputChangeDebounce = (e) => {
        setInput(e.target.value);
        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            setInput("");
            setProductLabels([]);
        }
        debounceInput.current = setTimeout(async () => {
            labelName(e.target.value);
        }, 500);
    };

    const labelName = async (text) => {
        console.log(text);
        if (tipo_producto === "casa") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/etiqueta/allByRandom/casa?nombre=${text}`,
                { headers }
            );

            let idDeEtiquetas = labelrandom.map((item) => item.id);
            console.log(idDeEtiquetas);
            console.log(json.data);

            matches = json.data.filter((label) => {
                return !idDeEtiquetas.includes(label.id);
            });

            setProductLabels(matches);
        } else if (tipo_producto === "embarcacion") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/etiqueta/allByRandom/embarcacion?nombre=${text}`,
                { headers }
            );
            let idDeEtiquetas = labelrandom.map((item) => item.id);

            matches = json.data.filter((label) => {
                return !idDeEtiquetas.includes(label.id);
            });
            setProductLabels(matches);
        }
    };

    const random = async () => {
        if (tipo_producto === "casa") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/etiqueta/allByRandom/casa`,
                { headers }
            );
            setLabelrandom(json.data);
        } else if (tipo_producto === "embarcacion") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/etiqueta/allByRandom/embarcacion`,
                { headers }
            );
            setLabelrandom(json.data);
        }
    };

    const addLabel = (etiqueta) => {
        let etiquetasEncontradas = producto.data.etiquetas.filter(
            (etiquetas) => etiquetas.id === etiqueta.id
        );
        //  onboarding.etiquetas.map((l) => {
        //     if (l.id === etiqueta.id) {
        //         return etiqueta.id;
        //     }
        // });
        console.log(etiquetasEncontradas);
        if (!etiquetasEncontradas.length) {
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    etiquetas: [...producto.data.etiquetas, etiqueta],
                },
            });
        } else {
            let etiquetasFiltradas = producto.data.etiquetas.filter(
                (etiquetas) => {
                    if (etiquetas.id !== etiqueta.id) {
                        return etiquetas;
                    }
                }
            );
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    etiquetas: etiquetasFiltradas,
                },
            });
        }

        let deleteLabel = productLabels.filter((etiquetas) => {
            if (etiquetas.id !== etiqueta.id) {
                return etiquetas;
            }
        });

        setProductLabels(deleteLabel);

        // setText(text);
    };

    const etiquetas = async (id) => {
        if (tipo_producto === "casa") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/productoEtiquetas/all/${id}`,
                { headers }
            );

            let aux = json.data.map((l) => {
                return { nombre: l.nombre, id: l.id_etiqueta };
            });

            console.log(json.data);
            if (json.data.length > 0) {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        etiquetas: aux,
                    },
                });

                let id = aux.map((l) => {
                    return l.id;
                });

                let repetido = labelrandom.filter((l) => {
                    return !id.includes(l.id);
                });

                setLabelrandom([...repetido, ...aux]);
            }
        } else if (tipo_producto === "embarcacion") {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/embarcacionEtiquetas/all/${id}`,
                { headers }
            );

            let aux = json.data.map((l) => {
                return { nombre: l.nombre, id: l.id_etiqueta };
            });

            if (json.data.length > 0) {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        etiquetas: aux,
                    },
                });

                let id = aux.map((l) => {
                    return l.id;
                });

                let repetido = labelrandom.filter((l) => {
                    return !id.includes(l.id);
                });

                setLabelrandom([...repetido, ...aux]);
            }
        }
    };

    const updateProduct = async (payload) => {
        setSiguiente(null);
        if (tipo_producto === "casa") {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/productoEtiquetas`,
                payload,
                { headers }
            );
            if (data.message === "registro exitoso") {
                setModal(false);
            }
            console.log(data);
        } else if (tipo_producto === "embarcacion") {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/embarcacionEtiquetas`,
                payload,
                { headers }
            );
            if (data.message === "registro exitoso") {
                setModal(false);
            }
        }
    };

    const handleGuardar = () => {
        let idEtiquetas = producto.data.etiquetas?.map((etiqueta) => {
            return etiqueta.id;
        });

        if (producto.data.etiquetas?.length > 0) {
            tipo_producto === "casa"
                ? updateProduct({
                      etiquetas: idEtiquetas,
                      id_producto: producto.data.id,
                      id_usuario: authUser.id_usuario,
                  })
                : updateProduct({
                      etiquetas: idEtiquetas,
                      id_embarcacion: producto.data.id,
                      id_usuario: authUser.id_usuario,
                  });
        } else {
            alert();
        }
    };

    const alert = () => {
        Swal.fire({
            title: "Debes seleccionar al menos una etiqueta",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Completar!",
        });
    };

    return (
        <div className="border p-4 rounded-lg">
            <h1>Etiquetas</h1>

            <div className="w-full mt-3">
                <div className="text-center ">
                    <div className="w-full px-3 rounded-lg border">
                        <input
                            onChange={handleInputChangeDebounce}
                            autoComplete="off"
                            id="default"
                            type="text"
                            name="default"
                            value={input}
                            placeholder="Busca o selecciona etiquetas"
                            className="w-full my-3 bg-transparent border-none outline-none focus:outline-none"
                        />
                    </div>

                    {!labelrandom.length ? (
                        <Spinner width={7} margin_top={5} />
                    ) : null}
                    <div className="flex flex-wrap mt-2">
                        {productLabels?.map((label) => {
                            return (
                                <div
                                    onClick={() => (
                                        addLabel(label),
                                        setLabelrandom([...labelrandom, label])
                                    )}
                                    key={label.id}
                                    className={
                                        "items-center p-2 m-1  text-center capitalize duration-150 transform border-2 border-gray-400 rounded-lg select-none "
                                    }>
                                    {label.nombre}
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex flex-wrap ">
                        {labelrandom?.map((r) => {
                            return (
                                <div
                                    onClick={() => addLabel(r)}
                                    key={r.id}
                                    className={
                                        producto.data.etiquetas?.includes(r)
                                            ? "items-center p-2 m-1  text-center capitalize duration-150 transform border-2 border-slate-200 bg-blue-bluecorner rounded-lg select-none text-white"
                                            : "items-center p-2 m-1  text-center capitalize duration-150 transform border-2 border-gray-400 rounded-lg select-none "
                                    }>
                                    <p>{r.nombre}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    disabled={!siguiente}
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div>
        </div>
    );
};

export default EditarEtiquetas;
