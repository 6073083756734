import React, { useState } from "react";

const ModalEstadoPedido = ({ setModalEstado, estadoReserva }) => {
    const [estado, setEstado] = useState(estadoReserva);
    const porcentaje = [5, 25, 40, 60, 80, 100];

    const handleClose = () => {
        setModalEstado(false);
    };

    const mensaje = [
        "Nuestro equipo especializado esta confirmando la disponibilidad de fechas de su reserva.",
        "Su reserva ha sido confirmada, estamos a la espera del primer pago.",
        "Primer pago procesado con exito, debe abonar el resto de la reserva.",
        "Segundo pago procesado con exito, su reserva ha sido confirmada.",
    ];
    console.log(estadoReserva);

    return (
        <div className={`relative items-center justify-center flex `}>
            <div className="fixed top-0 w-[130%] h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex items-center justify-center px-4 py-1  2xl:mx-auto md:px-2 sm:py-16 ">
                    <div className="relative flex flex-col items-center py-32 px-20 sm:px-12  bg-white rounded-lg w-96 md:w-5/12 dark:bg-white md:px-16  xl:px-24  overflow-auto h-120 ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mb-32 text-3xl font-bold">
                            Estado de la reserva
                        </header>
                        <div className="relative w-full">
                            {/* <hr className="absolute top-0 w-full border-r-2 border-gray-500 left-3">
                                {" "}
                            </hr> */}
                            <div className=" flex absolute w-full mx-auto h-1 md:h-2 bg-gray-300 top-3 md:top-4 ">
                                <div
                                    className={` bg-gray-500 ${
                                        estado === 6
                                            ? "w-full"
                                            : "w-[" +
                                              porcentaje[estado - 1] +
                                              "%]"
                                    }  `}></div>
                            </div>
                            <div className="flex justify-between w-full ">
                                <div
                                    className={` relative w-7 h-7 md:w-10 md:h-10  rounded-full ${
                                        estado >= 1
                                            ? "bg-gray-500 "
                                            : "bg-gray-300"
                                    } top-1/2  `}>
                                    <span
                                        className={`absolute top-[-60px] w-14 -rotate-45 translate-x-6 text-[10px] md:text-xs indent-[-10px] ${
                                            estado >= 1
                                                ? "opacity-100"
                                                : "opacity-50"
                                        }`}>
                                        Confirmando disponibilidad
                                    </span>
                                </div>
                                <div
                                    className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                        estado >= 2
                                            ? "bg-gray-500"
                                            : "bg-gray-300"
                                    } top-1/2  `}>
                                    <span
                                        className={`absolute top-[-60px] w-14 -rotate-45 translate-x-6 text-[10px] md:text-xs indent-[-10px] ${
                                            estado >= 2
                                                ? "opacity-100"
                                                : "opacity-50"
                                        }`}>
                                        Pendiente de pago
                                    </span>
                                </div>
                                <div
                                    className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                        estado >= 3
                                            ? "bg-gray-500"
                                            : "bg-gray-300"
                                    } top-1/2  `}>
                                    <span
                                        className={`absolute top-[-60px] w-14 -rotate-45 translate-x-6 text-[10px] md:text-xs indent-[-10px] ${
                                            estado >= 3
                                                ? "opacity-100"
                                                : "opacity-50"
                                        }`}>
                                        Pago Parcial
                                    </span>
                                </div>
                                <div
                                    className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                        estado >= 4
                                            ? "bg-gray-500"
                                            : "bg-gray-300"
                                    } top-1/2  `}>
                                    <span
                                        className={`absolute top-[-60px] w-14 -rotate-45 translate-x-6 text-[10px] md:text-xs indent-[-10px] ${
                                            estado >= 4
                                                ? "opacity-100"
                                                : "opacity-50"
                                        }`}>
                                        Pago Completo
                                    </span>
                                </div>
                                <div
                                    className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                        estado >= 5
                                            ? "bg-gray-500"
                                            : "bg-gray-300"
                                    } top-1/2  `}>
                                    <span
                                        className={`absolute top-[-60px] w-14 -rotate-45 translate-x-6 text-[10px] md:text-xs indent-[-10px] ${
                                            estado >= 5
                                                ? "opacity-100"
                                                : "opacity-50"
                                        }`}>
                                        Reseña de casa
                                    </span>
                                </div>
                                <div
                                    className={` relative w-7 h-7 md:w-10 md:h-10 rounded-full ${
                                        estado >= 6
                                            ? "bg-gray-500"
                                            : "bg-gray-300"
                                    } top-1/2  `}>
                                    <span
                                        className={`absolute top-[-60px] w-14 -rotate-45 translate-x-6 text-[10px] md:text-xs indent-[-10px] ${
                                            estado >= 6
                                                ? "opacity-100"
                                                : "opacity-50"
                                        }`}>
                                        Devolucion de garantia
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="w-9/12 md:text-2xl text-center">
                            <p>{mensaje[estado - 1]}</p>
                        </div>
                        <button
                            className="w-9/12 rounded-2xl border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                            onClick={handleClose}>
                            Salir
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEstadoPedido;
