import axios from "axios";
import Swal from "sweetalert2";
import { updateBoatFilters, updateHouseFilters } from "./filtersActions";
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const GET_POINTS = "GET_POINTS";
export const LOGOUT = "LOGOUT";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const SUMARANUNCIO = "SUMARANUNCIO";
export const MOSTRARCARTEL = "MOSTRARCARTEL";

export const loginUser = (
    email,
    password,
    dataGoogle = null,
    dataRegistro = null
) => {
    let data;

    return async function (dispatch) {
        if (dataGoogle) {
            data = dataGoogle;
        } else if (dataRegistro) {
            data = dataRegistro;
            console.log(dataRegistro);
        } else {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/autenticacion/login`,
                { email, password }
            );
            data = json.data;
            // console.log(json.data);
        }

        if (data.token) {
            const headers = {
                authorization: data.token,
            };
            const bluecoins = await axios(
                `${process.env.REACT_APP_PROXY}/cliente/getCountReferidos/${data.data[0].id}`,
                { headers }
            );

            const perfilTerminado = await axios.post(
                `${process.env.REACT_APP_PROXY}/autenticacion/getPerfil`,
                {
                    id_usuario: data.data[0].id,
                    tipo_usuario: data.data[0].tipo_usuario,
                },
                { headers }
            );

            data.data[0].perfil = perfilTerminado.data.message;

            if (data.data[0].tipo_usuario < 3) {
                data.data[0].casas = 1;
                data.data[0].embarcaciones = 1;
            } else {
                const getCasasEmbarcaciones = await axios.post(
                    `${process.env.REACT_APP_PROXY}/autenticacion/getCountCasasEmbarcaciones`,
                    {
                        id: data.data[0].id,
                        tipo_usuario: data.data[0].tipo_usuario,
                    },
                    { headers }
                );

                data.data[0].casas = getCasasEmbarcaciones.data[0].casas;
                data.data[0].embarcaciones =
                    getCasasEmbarcaciones.data[0].embarcaciones;
            }

            data.data[0].puntos = bluecoins.data;
        }

        return dispatch({
            type: LOGIN,
            payload: data,
        });
    };
};

export const loginEmpresaUser = (email, password, idEmpresa) => {
    let data;
    return async function (dispatch) {
        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/autenticacion/loginEmpresaUser`,
            { email, password, idEmpresa }
        );
        console.log(json.data);
        if (!json.data.error) {
            data = json.data;
            const headers = {
                authorization: data.token,
            };
            if (
                json.data.data[0].tipo_usuario === 6 ||
                json.data.data[0].tipo_usuario === 7
            ) {
                const colores = await axios(
                    `${process.env.REACT_APP_PROXY}/adminEmpresas/getEmpresa/${json.data.data[0].id}`,
                    { headers }
                );

                data.data[0].color_primario = colores.data.data.color_primario;
                data.data[0].color_secundario =
                    colores.data.data.color_secundario;
                data.data[0].logo = colores.data.data.logo;
                data.data[0].id_empresa = colores.data.data.id_empresa;

                data.data[0].nombre_empresa = colores.data.data?.empresa;
                data.data[0].mostrar_resumen =
                    colores.data.data?.mostrar_resumen;
                const detalleEmpresa = await axios(
                    `${process.env.REACT_APP_PROXY}/empresas/detalle/${colores.data.data.id_empresa}`,
                    { headers }
                );
                data.data[0].uso_prueba = detalleEmpresa.data?.uso_prueba;
                data.data[0].tamano = detalleEmpresa.data?.tamano;
                data.data[0].dias_prueba = detalleEmpresa.data?.dias_prueba;
                data.data[0].plan = detalleEmpresa.data?.plan;
                data.data[0].tipo = detalleEmpresa.data?.tipo;
                data.data[0].membresia = detalleEmpresa.data?.membresia;
            }
        } else {
            Swal.fire({
                title: "Error",
                text: `${json.data.error}`,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }

        return dispatch({
            type: LOGIN,
            payload: data,
        });
    };
};

export const registerUser = (id, payload) => {
    return async function (dispatch) {
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/atributo/actualizar/${id}`,
            payload
        );

        return dispatch({
            type: REGISTER,
            payload: json.data.message,
        });
    };
};

export const verifyToken = () => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/validarToken`,
            {},
            { headers }
        );
        if (!data.error) {
            if (data.tipo_usuario < 3) {
                const jsonAdmin = await axios.get(
                    `${process.env.REACT_APP_PROXY}/autenticacion/detalle/${data.id}`,
                    { headers }
                );
                if (jsonAdmin.data.data?.avatar) {
                    data.avatar =
                        jsonAdmin.data.data?.avatar || "sinAvatar.jpg";
                } else {
                    data.avatar = "sinAvatar.jpg";
                }
            } else {
                const json = await axios.get(
                    `${process.env.REACT_APP_PROXY}${
                        data.tipo_usuario === 4
                            ? "/subpropietario/detalle/"
                            : data.tipo_usuario === 3
                            ? "/propietario/detalle/"
                            : "/cliente/detalle/"
                    }${data.id}`,
                    { headers }
                );
                if (json.data.data?.avatar) {
                    data.avatar = json.data.data?.avatar || "sinAvatar.jpg";
                } else {
                    data.avatar = "sinAvatar.jpg";
                }
            }
        }
        console.log(data);
        if (!data.error) {
            if (data.tipo_usuario === 6 || data.tipo_usuario === 7) {
                const colores = await axios(
                    `${process.env.REACT_APP_PROXY}/adminEmpresas/getEmpresa/${data.id}`,
                    { headers }
                );

                data.color_primario = colores.data.data?.color_primario;
                data.color_secundario = colores.data.data?.color_secundario;
                data.logo = colores.data.data?.logo;
                data.id_empresa = colores.data.data?.id_empresa;
                data.nombre_empresa = colores.data.data?.empresa;
                data.mostrar_resumen = colores.data.data?.mostrar_resumen;
                const detalleEmpresa = await axios(
                    `${process.env.REACT_APP_PROXY}/empresas/detalle/${colores.data.data.id_empresa}`,
                    { headers }
                );

                data.tamano = detalleEmpresa.data?.tamano;
                data.uso_prueba = detalleEmpresa.data?.uso_prueba;

                data.dias_prueba = detalleEmpresa.data?.dias_prueba;
                data.plan = detalleEmpresa.data?.plan;
                data.tipo = detalleEmpresa.data?.tipo;
                data.membresia = detalleEmpresa.data?.membresia;
            }
            console.log("entrando a referidos", data.id);
            const bluecoins = await axios(
                `${process.env.REACT_APP_PROXY}/cliente/getCountReferidos/${data.id}`,
                { headers }
            );

            data.puntos = bluecoins.data;

            const perfilTerminado = await axios.post(
                `${process.env.REACT_APP_PROXY}/autenticacion/getPerfil`,
                {
                    id_usuario: data.id,
                    tipo_usuario: data.tipo_usuario,
                },
                { headers }
            );

            data.perfil = perfilTerminado.data.message;

            if (data.tipo_usuario < 3) {
                data.casas = 1;
                data.embarcaciones = 1;
            } else if (data.tipo_usuario < 5) {
                const getCasasEmbarcaciones = await axios.post(
                    `${process.env.REACT_APP_PROXY}/autenticacion/getCountCasasEmbarcaciones`,
                    {
                        id: data.id,
                        tipo_usuario: data.tipo_usuario,
                    },
                    { headers }
                );
                data.casas = getCasasEmbarcaciones.data[0].casas;
                data.embarcaciones =
                    getCasasEmbarcaciones.data[0].embarcaciones;
            }
        }
        dispatch(updateBoatFilters({ id_usuario: data.id }));
        dispatch(updateHouseFilters({ id_usuario: data.id }));

        return dispatch({
            type: VERIFY_TOKEN,
            payload: data,
        });
    };
};

export const getUserPoints = (userId) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };

        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/cliente/getCountReferidos/${userId}`,
            { headers }
        );
        return dispatch({
            type: GET_POINTS,
            payload: data,
        });
    };
};

export const logoutUser = () => {
    return async function (dispatch) {
        return dispatch({
            type: LOGOUT,
            payload: null,
        });
    };
};

export const actualizarMostrarCartel = () => {
    return async function (dispatch) {
        return dispatch({
            type: MOSTRARCARTEL,
            payload: null,
        });
    };
};

export const sumarAnuncio = (anuncio) => {
    return async function (dispatch) {
        return dispatch({
            type: SUMARANUNCIO,
            payload: anuncio === "casa" ? { casas: 1 } : { embarcaciones: 1 },
        });
    };
};
