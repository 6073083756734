import React from "react";
import { useNavigate } from "react-router";

const ButtonRegresar = () => {
    const navigate = useNavigate();
    return (
        <div
            className="flex py-5 items-center text-lg cursor-pointer w-max hover:underline hover:scale-105"
            onClick={() => navigate(-1)}>
            <svg
                className="w-6 h-6 text-black"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <polyline points="15 18 9 12 15 6" />
            </svg>
            <span className="mx-1">Regresar</span>
        </div>
    );
};

export default ButtonRegresar;
