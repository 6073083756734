import React from "react";

const TodasLasActividades = ({ empresa, actividades }) => {
    console.log(actividades);
    return (
        actividades.length > 0 &&
        actividades.map((actividad) => (
            <div key={actividad.id}>
                {actividad.tipo_feed === "Notas" && (
                    <div className="flex w-full p-2 mt-5 text-xs">
                        <div className="min-w-fit relative">
                            <img
                                className="object-cover w-12 h-12 border-gray-500 rounded-full cursor-pointer"
                                src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${actividad.avatar_creador}`}
                                alt="avatar de usuario"
                            />
                            <svg
                                className="h-7 w-7 text-white absolute -right-2 top-7 rounded-full"
                                fill="orange"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                                />
                            </svg>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex flex-col w-full ml-4 xl:items-center">
                                <div className="flex flex-col w-full gap-1">
                                    <div className="flex">
                                        <span className="font-bold min-w-fit text-sm">
                                            {actividad.usuario_creador}{" "}
                                            <span className="text-xs font-normal text-gray-400">
                                                {" "}
                                                - {actividad.fecha_registro}
                                            </span>
                                        </span>
                                    </div>
                                    <span>{actividad.nota}</span>
                                </div>
                            </div>
                            <div className="flex flex-col w-full mt-1 ml-4 pr-2">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: actividad.resumen,
                                    }}></span>
                            </div>
                        </div>
                    </div>
                )}
                {actividad.tipo_feed === "Actividades" && (
                    <div className="flex w-full p-2 mt-5 text-xs">
                        <div className="min-w-fit relative">
                            <img
                                className="object-cover w-12 h-12 border-gray-500 rounded-full cursor-pointer"
                                src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${actividad.avatar_creador}`}
                                alt="avatar de usuario"
                            />
                            <svg
                                className="h-6 w-6 text-white absolute -right-1 top-8 rounded-full"
                                viewBox="0 0 24 24"
                                fill="green"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />{" "}
                                <polyline points="22 4 12 14.01 9 11.01" />
                            </svg>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex flex-col w-full ml-4 xl:items-center">
                                <div className="flex flex-col w-full gap-1">
                                    <div className="flex">
                                        <span className="font-bold min-w-fit text-sm">
                                            {actividad.usuario_creador}{" "}
                                            <span className="text-xs font-normal text-gray-400">
                                                {" "}
                                                - {actividad.fecha_registro}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="flex gap-1">
                                        <div>
                                            Tipo de actividad:{" "}
                                            <b>{actividad.tipo_actividad}</b>
                                        </div>
                                        <div>
                                            Asignado a:{" "}
                                            <b>
                                                {
                                                    actividad.administrativo_asignado
                                                }
                                            </b>
                                        </div>
                                    </div>
                                    {actividad.comentario_finalizado !== "" && (
                                        <div className="flex gap-1 my-2">
                                            <div className="font-bold">
                                                Mensaje de finalización:{" "}
                                            </div>
                                            <div>
                                                {
                                                    actividad.comentario_finalizado
                                                }
                                            </div>
                                        </div>
                                    )}

                                    <span>{actividad.actividad}</span>
                                </div>
                            </div>
                            <div className="flex flex-col w-full mt-1 ml-4 pr-2">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: actividad.resumen,
                                    }}></span>
                            </div>
                        </div>
                    </div>
                )}
                {actividad.tipo_feed === "Mensajes" && (
                    <div className="flex w-full p-2 mt-5 text-xs">
                        <div className="min-w-fit relative">
                            <img
                                className="object-cover w-12 h-12 border-gray-500 rounded-full cursor-pointer"
                                src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${actividad.avatar_remitente}`}
                                alt="avatar de usuario"
                            />
                            <svg
                                className="h-6 w-6 text-white absolute -right-1 top-8 rounded-full"
                                fill="#007aff"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                />
                            </svg>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex flex-col w-full ml-4 xl:items-center">
                                <div className="flex flex-col w-full gap-1">
                                    <div className="flex">
                                        <span className="font-bold min-w-fit text-sm">
                                            {actividad.usuario_remitente}{" "}
                                            <span className="text-xs font-normal text-gray-400">
                                                {" "}
                                                - {actividad.fecha_registro}
                                            </span>
                                        </span>
                                    </div>
                                    <div>{actividad.asunto}</div>
                                    <div className="flex flex-col my-2">
                                        <span className="font-bold">
                                            Usuarios destinatarios:{" "}
                                        </span>
                                        <div className="flex flex-wrap gap-2 py-2">
                                            {actividad.destinatarios.map(
                                                (destinatario) => (
                                                    <img
                                                        key={
                                                            destinatario.id_destinatario
                                                        }
                                                        title={` ${destinatario.usuario_destinatario} \n${destinatario.correo_destinatario}`}
                                                        className="object-cover w-10 h-10 border-gray-500 rounded-full cursor-pointer hover:scale-110"
                                                        src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${destinatario.avatar_destinatario}`}
                                                        alt="avatar de usuario"
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full ml-4 pr-2">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: actividad.resumen,
                                    }}></span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ))
    );
};

export default TodasLasActividades;
