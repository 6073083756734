import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";
import colores from "../../helpers/colores";

const ImagesDetailedCasa = ({
    currentProduct,
    setModalImagesVisible,
    modalImagesVisible,
}) => {
    const authUser = useSelector((state) => state.authReducer);
    return (
        <div className="relative">
            <header
                className={`${
                    authUser.tipo_usuario !== null && authUser.tipo_usuario <= 2
                        ? "pt-[9.8rem]"
                        : "pt-[7.5rem]"
                } mx-auto `}
            >
                {currentProduct?.imagen_principal ? (
                    <div className="grid xs:grid-cols-[60%,40%] mt-6 relative overflow-hidden">
                        <div
                            className={
                                currentProduct.oferta_activada === "Si"
                                    ? "absolute left-[-40px] z-20 py-2 text-center -rotate-45 bg-red-700 w-44 top-7"
                                    : "hidden"
                            }
                        >
                            <p className="text-sm text-white">
                                {currentProduct.oferta}% de descuento
                            </p>
                        </div>

                        <div className="flex items-center justify-center ">
                            <img
                                alt="Imagen no encontrada"
                                src={`${
                                    process.env.REACT_APP_PROXY_ARCHIVOS
                                }${"/bluecorner/v1/casa/getImagenPrincipal/"}${
                                    currentProduct.imagen_principal
                                }`}
                                className="object-cover w-full h-[240px] xs:h-[300px] 2xl:h-[480px] rounded-xl xs:rounded-none xs:rounded-l-xl hover:brightness-[80%]  duration-700 "
                            />
                        </div>
                        <div className="flex-col items-center justify-center hidden pl-4 xs:flex">
                            <img
                                alt="Imagen no encontrada"
                                src={`${
                                    process.env.REACT_APP_PROXY_ARCHIVOS
                                }${"/bluecorner/v1/productoImagenes/getImagen/"}${
                                    currentProduct?.imagenes[0]?.imagen
                                }`}
                                className="object-cover w-full pb-2 rounded-tr-xl   h-[120px] xs:h-[150px]   2xl:h-[240px] hover:brightness-[80%]  duration-700 "
                            />
                            <img
                                alt="Imagen no encontrada"
                                src={`${
                                    process.env.REACT_APP_PROXY_ARCHIVOS
                                }${"/bluecorner/v1/productoImagenes/getImagen/"}${
                                    currentProduct?.imagenes[1]?.imagen
                                }`}
                                className="object-cover w-full pt-2 rounded-br-xl    h-[120px] xs:h-[150px]  2xl:h-[240px] hover:brightness-[80%]  duration-700 "
                            />
                        </div>
                    </div>
                ) : (
                    <div className="my-56">
                        <Spinner />
                    </div>
                )}
            </header>
            {currentProduct?.imagen_principal && (
                <>
                    <button
                        className={`hidden sm:block xs:text-xs lg:text-base absolute p-3 font-medium text-[${
                            colores.secundario
                        }] transition ease-linear delay-75 z-20 bg-white border-2 border-[${
                            colores.secundario
                        }] right-7   sm:bottom-4 top-40 sm:top-auto rounded-xl hover:bg-[${
                            colores.secundario
                        }] hover:text-white ${
                            currentProduct?.imagen_principal ? "" : "hidden"
                        }`}
                        onClick={() =>
                            setModalImagesVisible(!modalImagesVisible)
                        }
                    >
                        <span>Mostrar todas las fotos</span>
                    </button>
                    <button
                        className={` sm:hidden text-xs  absolute p-3 font-medium text-[${
                            colores.secundario
                        }] transition ease-linear delay-75 z-20 bg-white border-2 border-[${
                            colores.secundario
                        }] right-4   bottom-2  rounded-xl hover:bg-[${
                            colores.secundario
                        }] hover:text-white ${
                            currentProduct?.imagen_principal ? "" : "hidden"
                        }`}
                        onClick={() =>
                            setModalImagesVisible(!modalImagesVisible)
                        }
                    >
                        <span>Ver mas</span>
                    </button>
                </>
            )}
        </div>
    );
};

export default ImagesDetailedCasa;
