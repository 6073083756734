import axios from "axios";
import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import Swal from "sweetalert2";
import ModalEditarActividadPlanificada from "./actividades/ModalEditarActividadPlanificada";
import ModalPlanificarActividad from "./actividades/ModalPlanificarActividad";
import { CRMDetalleEmpresa } from "./CRMDetalleEmpresa";
import CRMModalCreateEmpresas from "./CRMModalCreateEmpresas";
import CRMModalEditEmpresas from "./CRMModalEditEmpresas";
import CRMModalEncargado from "./CRMModalEncargado";
import CRMSearchBar from "./CRMSearchBar";
import ModalNewUser from "./ModalNewUser";
import Semaforo from "./Semaforo";

export const CRMEmpresas = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [create, setCreate] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [fakeRefresh, setfakeRefresh] = useState(false);
    const [selectEdit, setSelectEdit] = useState(null);
    const [modalEliminar, setModalEliminar] = useState({
        visible: false,
        index: "",
    });
    const [modalOpciones, setModalOpciones] = useState({
        visible: false,
        index: "",
    });
    const [modalEncargado, setModalEncargado] = useState({
        bandera: false,
        nombre: "",
        id_encargado_bluecorner: 0,
    });
    const [refreshActividades, setRefreshActividades] = useState(true);
    const [modalEditarActividad, setModalEditarActividad] = useState(false);
    const [modalEditar, setModalEditar] = useState({
        bandera: false,
        empresa: "",
        actividad: "",
    });
    const [modalPlanificarActividad, setModalPlanificarActividad] =
        useState(false);
    const [empresa, setEmpresa] = useState(null);
    const [planed, setPlaned] = useState(null);
    const [comentarioTerminado, setComentarioTerminado] = useState("");
    const [actividadSelected, setActividadSelected] = useState(null);

    const [estado, setEstado] = useState("Seguimiento");
    const [colum1, setColum1] = useState([]);
    const [colum2, setColum2] = useState([]);
    const [colum3, setColum3] = useState([]);
    const [detalleEmpresa, setDetalleEmpresa] = useState({
        bandera: false,
        id: "",
    });

    const getAllSubPropietario = async (
        pagina = 1,
        habilitado = "Si",
        nombre = ""
    ) => {
        setColum1([]);
        setColum2([]);
        setColum3([]);

        const jsonembar = await axios.get(
            `${process.env.REACT_APP_PROXY}/empresas/all/${pagina}/${habilitado}?nombre=${nombre}`,
            { headers }
        );
        console.log(jsonembar.data.data);
        let seguimiento = jsonembar.data.data.filter(
            (empresa) => empresa.crm === "Seguimiento"
        );

        let ofertaEnviada = jsonembar.data.data.filter(
            (empresa) => empresa.crm === "Oferta enviada"
        );

        let negocioCerrado = jsonembar.data.data.filter(
            (empresa) => empresa.crm === "Negocio cerrado"
        );

        setColum1(seguimiento);
        setColum2(ofertaEnviada);
        setColum3(negocioCerrado);
    };

    const putEstadoEmpresas = async () => {
        let seguimiento = [];
        let ofertaEnviada = [];
        let negocioCerrado = [];

        colum1.map((empresa) => {
            return seguimiento.push({
                ...empresa,
                crm: "Seguimiento",
            });
        });

        colum2.map((empresa) => {
            return ofertaEnviada.push({
                ...empresa,
                crm: "Oferta enviada",
            });
        });

        colum3.map((empresa) => {
            return negocioCerrado.push({
                ...empresa,
                crm: "Negocio cerrado",
            });
        });

        const empresas = [...seguimiento, ...ofertaEnviada, ...negocioCerrado];
        const jsonembar = await axios.put(
            `${process.env.REACT_APP_PROXY}/empresas/actualizarCrm`,
            {
                empresas: empresas,
            },
            { headers }
        );
    };

    const handleDelete = async (idUser) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará la empresa a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/empresas/eliminar/${idUser}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Empresa eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllSubPropietario();
                    });
            }
        });
    };

    const handleFinish = async () => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/actividadesCrm/actualizarRealizado/${actividadSelected}`,
            { comentario_finalizado: comentarioTerminado },
            { headers }
        );

        if (data.message === "actuaizacion exitoso") {
            Swal.fire({
                title: "Actividad terminada",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
            setComentarioTerminado("");
            setPlaned(null);
            getAllSubPropietario();
        }
    };

    useEffect(() => {
        getAllSubPropietario();
    }, [
        fakeRefresh,
        modalEncargado,
        modalPlanificarActividad,
        refreshActividades,
    ]);

    useEffect(() => {
        if (modalEditar.bandera) {
            setModalEditarActividad(true);
            setModalEditar({
                ...modalEditar,
                bandera: false,
            });
        }
    }, [modalEditar]);

    useEffect(() => {
        setPlaned(null);
        if (empresa) {
            setModalPlanificarActividad(true);
        }
    }, [empresa]);

    useEffect(() => {
        if (colum1.length || colum2.length || colum3.length) {
            putEstadoEmpresas();
        }
    }, [colum1, colum2, colum3]);

    return (
        <div className=" w-full md:pl-[13rem] md818:pl-[14rem] md920:pl-[14rem] lg:pl-[17rem] p-4">
            {detalleEmpresa.bandera && (
                <CRMDetalleEmpresa
                    detalleEmpresa={detalleEmpresa}
                    setDetalleEmpresa={setDetalleEmpresa}
                />
            )}
            {modalEncargado.bandera && (
                <CRMModalEncargado
                    setcreate={setModalEncargado}
                    nombreEmpresa={modalEncargado}
                />
            )}
            {modalPlanificarActividad && (
                <ModalPlanificarActividad
                    empresa={empresa}
                    modalVisible={modalPlanificarActividad}
                    setModalVisible={setModalPlanificarActividad}
                    setRefreshActividades={setRefreshActividades}
                />
            )}
            <div className={detalleEmpresa.bandera ? "hidden" : ""}>
                {create && (
                    <ModalNewUser
                        modalVisible={create}
                        setmodalVisible={setCreate}
                        setfakeRefresh={setfakeRefresh}
                    />
                )}
                {selectEdit !== null && (
                    <CRMModalEditEmpresas
                        selectEdit={selectEdit}
                        setSelectEdit={setSelectEdit}
                        setColum1={setColum1}
                        setColum2={setColum2}
                        setColum3={setColum3}
                        estado={estado}
                        colum1={colum1}
                        colum2={colum2}
                        colum3={colum3}
                    />
                )}
                {modalEditarActividad && (
                    <ModalEditarActividadPlanificada
                        modalVisible={modalEditarActividad}
                        setModalVisible={setModalEditarActividad}
                        setRefreshActividades={setRefreshActividades}
                        empresa={modalEditar.empresa}
                        actividad={modalEditar.actividad}
                    />
                )}

                <h1 className="py-5 mx-4 text-3xl">CRM</h1>
                <div className="mt-2 md:ml-10">
                    <button
                        className="flex items-center justify-center w-8 h-8 p-2 ml-4 text-blue-500 border border-blue-500 rounded-lg md:w-auto md:h-auto group hover:bg-blue-500 hover:text-white"
                        onClick={() => setCreate(!create)}>
                        <svg
                            className="w-6 h-6 text-blue-500 group-hover:text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <line x1="12" y1="5" x2="12" y2="19" />{" "}
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        <h1 className="hidden md:flex">Nueva empresa</h1>
                    </button>
                </div>
            </div>
            <div className="flex items-center justify-end w-11/12 mx-auto mt-6 mb-2 md:mt-0 max-w-7xl ">
                <CRMSearchBar
                    setfakeRefresh={setfakeRefresh}
                    fakeRefresh={fakeRefresh}
                />
                <button className="relative flex items-center justify-center ml-5 border rounded-lg w-11 h-11 border-blue-bluecorner sm:hidden">
                    <svg
                        onClick={() => setShowButtons((prev) => !prev)}
                        className="text-blue-500 h-7 w-7 md:h-8 md:w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                        />
                    </svg>
                    {showButtons && (
                        <div className="border rounded-lg absolute -left-24 top-[35px] bg-white z-10 ">
                            <div
                                onClick={() => {
                                    setShowButtons((prev) => !prev);
                                    setEstado("Seguimiento");
                                }}
                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Seguimiento
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    setShowButtons((prev) => !prev);
                                    setEstado("Oferta enviada");
                                }}
                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Oferta enviada
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    setShowButtons((prev) => !prev);
                                    setEstado("Negocio cerrado");
                                }}
                                className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                <p
                                    className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                    Negocio cerrado
                                </p>
                            </div>
                        </div>
                    )}
                </button>
            </div>
            {/* -----mobile----- */}
            <div className="sm:hidden">
                <div className="w-11/12 pb-2 mx-auto mt-3 border-b ">
                    <p className="font-bold">{estado}</p>
                    {estado === "Seguimiento" && colum1.length > 0 && (
                        <Semaforo items={colum1} />
                    )}
                    {estado === "Oferta enviada" && colum1.length > 0 && (
                        <Semaforo items={colum2} />
                    )}
                    {estado === "Negocio cerrado" && colum1.length > 0 && (
                        <Semaforo items={colum3} />
                    )}
                </div>
                {estado === "Seguimiento"
                    ? colum1.map((p, i) => {
                          return (
                              <div
                                  className=" w-11/12 mx-auto border h-28 mt-3 rounded-lg p-3 text-[#3C3C3C] relative"
                                  key={i}>
                                  <span
                                      onClick={() =>
                                          setModalEliminar({
                                              visible: true,
                                              index:
                                                  i === modalEliminar.index
                                                      ? null
                                                      : i,
                                          })
                                      }
                                      className={`absolute right-3 top-5  cursor-pointer `}
                                      aria-label="close">
                                      <svg
                                          className="w-5 h-5 text-black"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round">
                                          {" "}
                                          <circle cx="12" cy="12" r="1" />{" "}
                                          <circle cx="12" cy="5" r="1" />{" "}
                                          <circle cx="12" cy="19" r="1" />
                                      </svg>
                                  </span>
                                  <div
                                      className={
                                          modalEliminar.visible === true &&
                                          modalEliminar.index === i
                                              ? "absolute bg-white  right-1 top-10 border rounded-lg  p-2 z-10"
                                              : "hidden"
                                      }>
                                      <p
                                          onClick={() => (
                                              setDetalleEmpresa({
                                                  bandera: true,
                                                  id: p.id,
                                              }),
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              })
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Detalle
                                      </p>
                                      <p
                                          onClick={() => (
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              }),
                                              setSelectEdit(p)
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Actualizar
                                      </p>
                                      <p
                                          onClick={() => (
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              }),
                                              setModalEncargado({
                                                  bandera: true,
                                                  nombre: p.nombre,

                                                  id_encargado_bluecorner:
                                                      p.id_encargado_bluecorner,
                                                  id_empresa: p.id,
                                              })
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Encargado
                                      </p>
                                      <p
                                          onClick={() => (
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              }),
                                              handleDelete(p.id)
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Eliminar
                                      </p>
                                  </div>
                                  <p className="text-sm ">{p.nombre}</p>
                                  <p className="text-sm ">{p.administrador} </p>
                                  <div className="absolute bottom-2 right-2 min-w-fit">
                                      <img
                                          className="object-cover w-8 h-8 border rounded-full cursor-pointer "
                                          src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${p.avatar_administrador_bluecorner}`}
                                          alt="avatar del encargado"
                                          title={p.administrador_bluecorner}
                                      />
                                  </div>
                                  <div className="absolute bottom-2 left-2">
                                      <div className="flex items-center ">
                                          <svg
                                              onClick={() =>
                                                  setPlaned(
                                                      planed === p.id
                                                          ? null
                                                          : p.id
                                                  )
                                              }
                                              style={{
                                                  color: p.color_reloj,
                                              }}
                                              className={`w-8 h-8 cursor-pointer `}
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              strokeWidth="2"
                                              stroke="currentColor"
                                              fill="none"
                                              strokeLinecap="round"
                                              strokeLinejoin="round">
                                              {" "}
                                              <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                              />{" "}
                                              <circle cx="12" cy="12" r="9" />{" "}
                                              <polyline points="12 7 12 12 15 15" />
                                          </svg>
                                          <div
                                              className={
                                                  planed === p.id
                                                      ? "absolute z-10 h-auto bg-white border rounded-md w-72 top-8"
                                                      : "hidden"
                                              }>
                                              <div className="grid grid-cols-[90%,10%] py-2 border-b justify-items-center">
                                                  <p className="text-[#053465] font-semibold text-sm">
                                                      Actividades
                                                  </p>
                                                  <p className="text-xs text-slate-400">
                                                      {
                                                          p
                                                              .actividadesPendientes
                                                              ?.length
                                                      }
                                                  </p>
                                              </div>
                                              {p.actividadesPendientes?.map(
                                                  (actividad, i) => {
                                                      return (
                                                          <div
                                                              className="grid grid-cols-[90%,10%] py-2 border-b "
                                                              key={i}>
                                                              <div>
                                                                  <div className="flex items-center">
                                                                      <p className="text-[#053465]  text-xs pl-1">
                                                                          {
                                                                              actividad.actividad
                                                                          }
                                                                      </p>
                                                                      <svg
                                                                          onClick={() => {
                                                                              setModalEditar(
                                                                                  {
                                                                                      bandera: true,
                                                                                      empresa:
                                                                                          p,
                                                                                      actividad:
                                                                                          actividad,
                                                                                  }
                                                                              );
                                                                              setPlaned(
                                                                                  null
                                                                              );
                                                                          }}
                                                                          className="w-4 h-4 ml-1 text-blue-500 cursor-pointer"
                                                                          width="24"
                                                                          height="24"
                                                                          viewBox="0 0 24 24"
                                                                          strokeWidth="2"
                                                                          stroke="currentColor"
                                                                          fill="none"
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round">
                                                                          {" "}
                                                                          <path
                                                                              stroke="none"
                                                                              d="M0 0h24v24H0z"
                                                                          />{" "}
                                                                          <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                                          <line
                                                                              x1="13.5"
                                                                              y1="6.5"
                                                                              x2="17.5"
                                                                              y2="10.5"
                                                                          />
                                                                      </svg>
                                                                  </div>
                                                                  <div>
                                                                      {actividad.dias_restantes ===
                                                                      0 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vence
                                                                              hoy
                                                                          </p>
                                                                      ) : actividad.dias_restantes ===
                                                                        1 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vence
                                                                              en
                                                                              1
                                                                              dia
                                                                          </p>
                                                                      ) : actividad.dias_restantes >
                                                                        1 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vence
                                                                              en{" "}
                                                                              {
                                                                                  actividad.dias_restantes
                                                                              }{" "}
                                                                              dias
                                                                          </p>
                                                                      ) : actividad.dias_restantes ===
                                                                        -1 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vencio
                                                                              hace
                                                                              1
                                                                              dia
                                                                          </p>
                                                                      ) : (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vencio
                                                                              hace{" "}
                                                                              {actividad.dias_restantes *
                                                                                  -1}{" "}
                                                                              dias
                                                                          </p>
                                                                      )}
                                                                  </div>
                                                              </div>
                                                              <div className="flex items-end">
                                                                  <button
                                                                      onClick={() =>
                                                                          setActividadSelected(
                                                                              actividadSelected ===
                                                                                  actividad.id
                                                                                  ? null
                                                                                  : actividad.id
                                                                          )
                                                                      }
                                                                      className="flex items-center justify-center w-5 h-5 px-1 py-1 mr-3 text-white border-2 rounded-full border-slate-300 group bg-slate-300 hover:white hover:bg-green-500 hover:border-green-500">
                                                                      <svg
                                                                          className="w-4 h-4 text-white group-hover:text-white"
                                                                          fill="none"
                                                                          viewBox="0 0 24 24"
                                                                          stroke="currentColor">
                                                                          <path
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                              strokeWidth="7"
                                                                              d="M5 13l4 4L19 7"
                                                                          />
                                                                      </svg>
                                                                  </button>
                                                              </div>
                                                              {actividadSelected ===
                                                                  actividad.id && (
                                                                  <div className="w-full mt-2 border-2">
                                                                      <span className="flex w-full p-1 px-3 bg-gray-200">
                                                                          Marcar
                                                                          como
                                                                          hecho
                                                                      </span>
                                                                      <div className="p-2">
                                                                          <textarea
                                                                              type="text"
                                                                              placeholder="Escribir comentarios"
                                                                              value={
                                                                                  comentarioTerminado
                                                                              }
                                                                              onChange={(
                                                                                  e
                                                                              ) =>
                                                                                  setComentarioTerminado(
                                                                                      e
                                                                                          .target
                                                                                          .value
                                                                                  )
                                                                              }
                                                                              className="w-full h-20 p-1 border outline-none"
                                                                          />
                                                                          <div className="flex gap-3 mt-2">
                                                                              <button
                                                                                  className="px-4 py-1 font-semibold text-white bg-blue-bluecorner"
                                                                                  onClick={
                                                                                      handleFinish
                                                                                  }>
                                                                                  TERMINAR
                                                                              </button>
                                                                              <button
                                                                                  className="font-semibold text-blue-bluecorner"
                                                                                  onClick={() => {
                                                                                      setActividadSelected(
                                                                                          null
                                                                                      );
                                                                                      setComentarioTerminado(
                                                                                          ""
                                                                                      );
                                                                                  }}>
                                                                                  Descartar
                                                                              </button>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              )}
                                                          </div>
                                                      );
                                                  }
                                              )}
                                              <div className="flex items-center justify-center mt-2 transition duration-300 cursor-pointer hover:bg-slate-200">
                                                  <svg
                                                      className="w-5 h-5 text-blue-500"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      strokeWidth="5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round">
                                                      {" "}
                                                      <line
                                                          x1="12"
                                                          y1="5"
                                                          x2="12"
                                                          y2="19"
                                                      />{" "}
                                                      <line
                                                          x1="5"
                                                          y1="12"
                                                          x2="19"
                                                          y2="12"
                                                      />
                                                  </svg>
                                                  <p
                                                      onClick={() =>
                                                          setEmpresa(p)
                                                      }
                                                      className="py-2 text-sm font-bold text-blue-500 uppercase">
                                                      planificar una actividad
                                                  </p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                    : estado === "Oferta enviada"
                    ? colum2.map((p, i) => {
                          return (
                              <div
                                  className=" w-11/12 mx-auto border h-28 mt-3 rounded-lg p-3 text-[#3C3C3C] relative"
                                  key={i}>
                                  <span
                                      onClick={() =>
                                          setModalEliminar({
                                              visible: true,
                                              index:
                                                  i === modalEliminar.index
                                                      ? null
                                                      : i,
                                          })
                                      }
                                      className={`absolute right-3 top-5  cursor-pointer `}
                                      aria-label="close">
                                      <svg
                                          className="w-5 h-5 text-black"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round">
                                          {" "}
                                          <circle cx="12" cy="12" r="1" />{" "}
                                          <circle cx="12" cy="5" r="1" />{" "}
                                          <circle cx="12" cy="19" r="1" />
                                      </svg>
                                  </span>
                                  <div
                                      className={
                                          modalEliminar.visible === true &&
                                          modalEliminar.index === i
                                              ? "absolute bg-white  right-1 top-10 border rounded-lg  p-2 z-10"
                                              : "hidden"
                                      }>
                                      <p
                                          onClick={() => (
                                              setDetalleEmpresa({
                                                  bandera: true,
                                                  id: p.id,
                                              }),
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              })
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Detalle
                                      </p>
                                      <p
                                          onClick={() => (
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              }),
                                              setSelectEdit(p)
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Actualizar
                                      </p>
                                      <p
                                          onClick={() => (
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              }),
                                              setModalEncargado({
                                                  bandera: true,
                                                  nombre: p.nombre,
                                                  id_encargado_bluecorner:
                                                      p.id_encargado_bluecorner,
                                                  id_empresa: p.id,
                                              })
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Encargado
                                      </p>
                                      <p
                                          onClick={() => (
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              }),
                                              handleDelete(p.id)
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Eliminar
                                      </p>
                                  </div>
                                  <p className="text-sm ">{p.nombre}</p>
                                  <p className="text-sm ">{p.administrador} </p>
                                  <div className="absolute bottom-2 right-2 min-w-fit">
                                      <img
                                          className="object-cover w-8 h-8 border rounded-full cursor-pointer "
                                          src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${p.avatar_administrador_bluecorner}`}
                                          alt="avatar del encargado"
                                          title={p.administrador_bluecorner}
                                      />
                                  </div>

                                  <div className="absolute bottom-2 left-2">
                                      <div className="flex items-center ">
                                          <svg
                                              onClick={() =>
                                                  setPlaned(
                                                      planed === p.id
                                                          ? null
                                                          : p.id
                                                  )
                                              }
                                              style={{
                                                  color: p.color_reloj,
                                              }}
                                              className={`w-8 h-8 cursor-pointer `}
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              strokeWidth="2"
                                              stroke="currentColor"
                                              fill="none"
                                              strokeLinecap="round"
                                              strokeLinejoin="round">
                                              {" "}
                                              <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                              />{" "}
                                              <circle cx="12" cy="12" r="9" />{" "}
                                              <polyline points="12 7 12 12 15 15" />
                                          </svg>
                                          <div
                                              className={
                                                  planed === p.id
                                                      ? "absolute z-10 h-auto bg-white border rounded-md w-72 top-8"
                                                      : "hidden"
                                              }>
                                              <div className="grid grid-cols-[90%,10%] py-2 border-b justify-items-center">
                                                  <p className="text-[#053465] font-semibold text-sm">
                                                      Actividades
                                                  </p>
                                                  <p className="text-xs text-slate-400">
                                                      {
                                                          p
                                                              .actividadesPendientes
                                                              ?.length
                                                      }
                                                  </p>
                                              </div>
                                              {p.actividadesPendientes?.map(
                                                  (actividad) => {
                                                      return (
                                                          <div
                                                              className="grid grid-cols-[90%,10%] py-2 border-b "
                                                              key={i}>
                                                              <div>
                                                                  <div className="flex items-center">
                                                                      <p className="text-[#053465]  text-xs pl-1">
                                                                          {
                                                                              actividad.actividad
                                                                          }
                                                                      </p>
                                                                      <svg
                                                                          onClick={() => {
                                                                              setModalEditar(
                                                                                  {
                                                                                      bandera: true,
                                                                                      empresa:
                                                                                          p,
                                                                                      actividad:
                                                                                          actividad,
                                                                                  }
                                                                              );
                                                                              setPlaned(
                                                                                  null
                                                                              );
                                                                          }}
                                                                          className="w-4 h-4 ml-1 text-blue-500 cursor-pointer"
                                                                          width="24"
                                                                          height="24"
                                                                          viewBox="0 0 24 24"
                                                                          strokeWidth="2"
                                                                          stroke="currentColor"
                                                                          fill="none"
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round">
                                                                          {" "}
                                                                          <path
                                                                              stroke="none"
                                                                              d="M0 0h24v24H0z"
                                                                          />{" "}
                                                                          <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                                          <line
                                                                              x1="13.5"
                                                                              y1="6.5"
                                                                              x2="17.5"
                                                                              y2="10.5"
                                                                          />
                                                                      </svg>
                                                                  </div>
                                                                  <div>
                                                                      {actividad.dias_restantes ===
                                                                      0 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vence
                                                                              hoy
                                                                          </p>
                                                                      ) : actividad.dias_restantes ===
                                                                        1 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vence
                                                                              en
                                                                              1
                                                                              dia
                                                                          </p>
                                                                      ) : actividad.dias_restantes >
                                                                        1 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vence
                                                                              en{" "}
                                                                              {
                                                                                  actividad.dias_restantes
                                                                              }{" "}
                                                                              dias
                                                                          </p>
                                                                      ) : actividad.dias_restantes ===
                                                                        -1 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vencio
                                                                              hace
                                                                              1
                                                                              dia
                                                                          </p>
                                                                      ) : (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vencio
                                                                              hace{" "}
                                                                              {actividad.dias_restantes *
                                                                                  -1}{" "}
                                                                              dias
                                                                          </p>
                                                                      )}
                                                                  </div>
                                                              </div>
                                                              <div className="flex items-end">
                                                                  <button
                                                                      onClick={() =>
                                                                          setActividadSelected(
                                                                              actividadSelected ===
                                                                                  actividad.id
                                                                                  ? null
                                                                                  : actividad.id
                                                                          )
                                                                      }
                                                                      className="flex items-center justify-center w-5 h-5 px-1 py-1 mr-3 text-white border-2 rounded-full border-slate-300 group bg-slate-300 hover:white hover:bg-green-500 hover:border-green-500">
                                                                      <svg
                                                                          className="w-4 h-4 text-white group-hover:text-white"
                                                                          fill="none"
                                                                          viewBox="0 0 24 24"
                                                                          stroke="currentColor">
                                                                          <path
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                              strokeWidth="7"
                                                                              d="M5 13l4 4L19 7"
                                                                          />
                                                                      </svg>
                                                                  </button>
                                                              </div>
                                                              {actividadSelected ===
                                                                  actividad.id && (
                                                                  <div className="w-full mt-2 border-2">
                                                                      <span className="flex w-full p-1 px-3 bg-gray-200">
                                                                          Marcar
                                                                          como
                                                                          hecho
                                                                      </span>
                                                                      <div className="p-2">
                                                                          <textarea
                                                                              type="text"
                                                                              placeholder="Escribir comentarios"
                                                                              value={
                                                                                  comentarioTerminado
                                                                              }
                                                                              onChange={(
                                                                                  e
                                                                              ) =>
                                                                                  setComentarioTerminado(
                                                                                      e
                                                                                          .target
                                                                                          .value
                                                                                  )
                                                                              }
                                                                              className="w-full h-20 p-1 border outline-none"
                                                                          />
                                                                          <div className="flex gap-3 mt-2">
                                                                              <button
                                                                                  className="px-4 py-1 font-semibold text-white bg-blue-bluecorner"
                                                                                  onClick={
                                                                                      handleFinish
                                                                                  }>
                                                                                  TERMINAR
                                                                              </button>
                                                                              <button
                                                                                  className="font-semibold text-blue-bluecorner"
                                                                                  onClick={() => {
                                                                                      setActividadSelected(
                                                                                          null
                                                                                      );
                                                                                      setComentarioTerminado(
                                                                                          ""
                                                                                      );
                                                                                  }}>
                                                                                  Descartar
                                                                              </button>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              )}
                                                          </div>
                                                      );
                                                  }
                                              )}
                                              <div className="flex items-center justify-center mt-2 transition duration-300 cursor-pointer hover:bg-slate-200">
                                                  <svg
                                                      className="w-5 h-5 text-blue-500"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      strokeWidth="5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round">
                                                      {" "}
                                                      <line
                                                          x1="12"
                                                          y1="5"
                                                          x2="12"
                                                          y2="19"
                                                      />{" "}
                                                      <line
                                                          x1="5"
                                                          y1="12"
                                                          x2="19"
                                                          y2="12"
                                                      />
                                                  </svg>
                                                  <p
                                                      onClick={() =>
                                                          setEmpresa(p)
                                                      }
                                                      className="py-2 text-sm font-bold text-blue-500 uppercase">
                                                      planificar una actividad
                                                  </p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                    : colum3.map((p, i) => {
                          return (
                              <div
                                  className=" w-11/12 mx-auto border h-28 mt-3  rounded-lg p-3 text-[#3C3C3C] relative"
                                  key={i}>
                                  <span
                                      onClick={() =>
                                          setModalEliminar({
                                              visible: true,
                                              index:
                                                  i === modalEliminar.index
                                                      ? null
                                                      : i,
                                          })
                                      }
                                      className={`absolute right-3 top-5  cursor-pointer `}
                                      aria-label="close">
                                      <svg
                                          className="w-5 h-5 text-black"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round">
                                          {" "}
                                          <circle cx="12" cy="12" r="1" />{" "}
                                          <circle cx="12" cy="5" r="1" />{" "}
                                          <circle cx="12" cy="19" r="1" />
                                      </svg>
                                  </span>
                                  <div
                                      className={
                                          modalEliminar.visible === true &&
                                          modalEliminar.index === i
                                              ? "absolute bg-white  right-1 top-10 border rounded-lg  p-2 z-10"
                                              : "hidden"
                                      }>
                                      <p
                                          onClick={() => (
                                              setDetalleEmpresa({
                                                  bandera: true,
                                                  id: p.id,
                                              }),
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              })
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Detalle
                                      </p>
                                      <p
                                          onClick={() => (
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              }),
                                              setSelectEdit(p)
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Actualizar
                                      </p>
                                      <p
                                          onClick={() => (
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              }),
                                              setModalEncargado({
                                                  bandera: true,
                                                  nombre: p.nombre,
                                                  id_encargado_bluecorner:
                                                      p.id_encargado_bluecorner,
                                                  id_empresa: p.id,
                                              })
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Encargado
                                      </p>
                                      <p
                                          onClick={() => (
                                              setModalEliminar({
                                                  visible: false,
                                                  index: null,
                                              }),
                                              handleDelete(p.id)
                                          )}
                                          className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                          Eliminar
                                      </p>
                                  </div>
                                  <p className="text-sm ">{p.nombre}</p>
                                  <p className="text-sm ">{p.administrador} </p>
                                  <div className="absolute bottom-2 right-2 min-w-fit">
                                      <img
                                          className="object-cover w-8 h-8 border rounded-full cursor-pointer "
                                          src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${p.avatar_administrador_bluecorner}`}
                                          alt="avatar del encargado"
                                          title={p.administrador_bluecorner}
                                      />
                                  </div>

                                  <div className="absolute bottom-2 left-2">
                                      <div className="flex items-center ">
                                          <svg
                                              onClick={() =>
                                                  setPlaned(
                                                      planed === p.id
                                                          ? null
                                                          : p.id
                                                  )
                                              }
                                              style={{
                                                  color: p.color_reloj,
                                              }}
                                              className={`w-8 h-8 cursor-pointer`}
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              strokeWidth="2"
                                              stroke="currentColor"
                                              fill="none"
                                              strokeLinecap="round"
                                              strokeLinejoin="round">
                                              {" "}
                                              <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                              />{" "}
                                              <circle cx="12" cy="12" r="9" />{" "}
                                              <polyline points="12 7 12 12 15 15" />
                                          </svg>
                                          <div
                                              className={
                                                  planed === p.id
                                                      ? "absolute z-10 h-auto bg-white border rounded-md w-72 top-8"
                                                      : "hidden"
                                              }>
                                              <div className="grid grid-cols-[90%,10%] py-2 border-b justify-items-center">
                                                  <p className="text-[#053465] font-semibold text-sm">
                                                      Actividades
                                                  </p>
                                                  <p className="text-xs text-slate-400">
                                                      {
                                                          p
                                                              .actividadesPendientes
                                                              ?.length
                                                      }
                                                  </p>
                                              </div>
                                              {p.actividadesPendientes?.map(
                                                  (actividad, i) => {
                                                      return (
                                                          <div
                                                              className="grid grid-cols-[90%,10%] py-2 border-b "
                                                              key={i}>
                                                              <div>
                                                                  <div className="flex items-center">
                                                                      <p className="text-[#053465]  text-xs pl-1">
                                                                          {
                                                                              actividad.actividad
                                                                          }
                                                                      </p>
                                                                      <svg
                                                                          onClick={() => {
                                                                              setModalEditar(
                                                                                  {
                                                                                      bandera: true,
                                                                                      empresa:
                                                                                          p,
                                                                                      actividad:
                                                                                          actividad,
                                                                                  }
                                                                              );
                                                                              setPlaned(
                                                                                  null
                                                                              );
                                                                          }}
                                                                          className="w-4 h-4 ml-1 text-blue-500 cursor-pointer"
                                                                          width="24"
                                                                          height="24"
                                                                          viewBox="0 0 24 24"
                                                                          strokeWidth="2"
                                                                          stroke="currentColor"
                                                                          fill="none"
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round">
                                                                          {" "}
                                                                          <path
                                                                              stroke="none"
                                                                              d="M0 0h24v24H0z"
                                                                          />{" "}
                                                                          <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                                          <line
                                                                              x1="13.5"
                                                                              y1="6.5"
                                                                              x2="17.5"
                                                                              y2="10.5"
                                                                          />
                                                                      </svg>
                                                                  </div>
                                                                  <div>
                                                                      {actividad.dias_restantes ===
                                                                      0 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vence
                                                                              hoy
                                                                          </p>
                                                                      ) : actividad.dias_restantes ===
                                                                        1 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vence
                                                                              en
                                                                              1
                                                                              dia
                                                                          </p>
                                                                      ) : actividad.dias_restantes >
                                                                        1 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vence
                                                                              en{" "}
                                                                              {
                                                                                  actividad.dias_restantes
                                                                              }{" "}
                                                                              dias
                                                                          </p>
                                                                      ) : actividad.dias_restantes ===
                                                                        -1 ? (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vencio
                                                                              hace
                                                                              1
                                                                              dia
                                                                          </p>
                                                                      ) : (
                                                                          <p
                                                                              style={{
                                                                                  color: actividad.color_actividad,
                                                                              }}
                                                                              className="pl-1 text-xs">
                                                                              vencio
                                                                              hace{" "}
                                                                              {actividad.dias_restantes *
                                                                                  -1}{" "}
                                                                              dias
                                                                          </p>
                                                                      )}
                                                                  </div>
                                                              </div>
                                                              <div className="flex items-end">
                                                                  <button
                                                                      onClick={() =>
                                                                          setActividadSelected(
                                                                              actividadSelected ===
                                                                                  actividad.id
                                                                                  ? null
                                                                                  : actividad.id
                                                                          )
                                                                      }
                                                                      className="flex items-center justify-center w-5 h-5 px-1 py-1 mr-3 text-white border-2 rounded-full border-slate-300 group bg-slate-300 hover:white hover:bg-green-500 hover:border-green-500">
                                                                      <svg
                                                                          className="w-4 h-4 text-white group-hover:text-white"
                                                                          fill="none"
                                                                          viewBox="0 0 24 24"
                                                                          stroke="currentColor">
                                                                          <path
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                              strokeWidth="7"
                                                                              d="M5 13l4 4L19 7"
                                                                          />
                                                                      </svg>
                                                                  </button>
                                                              </div>
                                                              {actividadSelected ===
                                                                  actividad.id && (
                                                                  <div className="w-full mt-2 border-2">
                                                                      <span className="flex w-full p-1 px-3 bg-gray-200">
                                                                          Marcar
                                                                          como
                                                                          hecho
                                                                      </span>
                                                                      <div className="p-2">
                                                                          <textarea
                                                                              type="text"
                                                                              placeholder="Escribir comentarios"
                                                                              value={
                                                                                  comentarioTerminado
                                                                              }
                                                                              onChange={(
                                                                                  e
                                                                              ) =>
                                                                                  setComentarioTerminado(
                                                                                      e
                                                                                          .target
                                                                                          .value
                                                                                  )
                                                                              }
                                                                              className="w-full h-20 p-1 border outline-none"
                                                                          />
                                                                          <div className="flex gap-3 mt-2">
                                                                              <button
                                                                                  className="px-4 py-1 font-semibold text-white bg-blue-bluecorner"
                                                                                  onClick={
                                                                                      handleFinish
                                                                                  }>
                                                                                  TERMINAR
                                                                              </button>
                                                                              <button
                                                                                  className="font-semibold text-blue-bluecorner"
                                                                                  onClick={() => {
                                                                                      setActividadSelected(
                                                                                          null
                                                                                      );
                                                                                      setComentarioTerminado(
                                                                                          ""
                                                                                      );
                                                                                  }}>
                                                                                  Descartar
                                                                              </button>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              )}
                                                          </div>
                                                      );
                                                  }
                                              )}
                                              <div className="flex items-center justify-center mt-2 transition duration-300 cursor-pointer hover:bg-slate-200">
                                                  <svg
                                                      className="w-5 h-5 text-blue-500"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      strokeWidth="5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round">
                                                      {" "}
                                                      <line
                                                          x1="12"
                                                          y1="5"
                                                          x2="12"
                                                          y2="19"
                                                      />{" "}
                                                      <line
                                                          x1="5"
                                                          y1="12"
                                                          x2="19"
                                                          y2="12"
                                                      />
                                                  </svg>
                                                  <p
                                                      onClick={() =>
                                                          setEmpresa(p)
                                                      }
                                                      className="py-2 text-sm font-bold text-blue-500 uppercase">
                                                      planificar una actividad
                                                  </p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          );
                      })}
            </div>
            {/* -----fin de mobile----- */}
            <div className="hidden w-11/12 mx-auto mt-2 text-center max-w-7xl sm:flex justify-evenly xl:text-lg ">
                <div className="w-2/6 font-bold ">
                    <p>Seguimiento</p>
                    {colum1.length > 0 && <Semaforo items={colum1} />}
                </div>
                <div className="w-2/6 font-bold">
                    <p>Oferta enviada</p>
                    {colum2.length > 0 && <Semaforo items={colum2} />}
                </div>
                <div className="w-2/6 font-bold">
                    <p>Negocio cerrado</p>
                    {colum3.length > 0 && <Semaforo items={colum3} />}
                </div>
            </div>
            <div className="justify-between hidden w-11/12 pb-10 mx-auto max-w-7xl sm:flex">
                <ReactSortable
                    className="w-1/3 min-h-[73vh] px-2 lg:px-4"
                    list={colum1}
                    setList={setColum1}
                    group={"empresas"}
                    dragClass="draggable"
                    animation={100}
                    multiDrag={true}>
                    {colum1.map((p, i) => {
                        return (
                            <div
                                key={p.id}
                                onMouseOut={() => {
                                    setModalOpciones({
                                        visible: false,
                                        index: "",
                                    });
                                }}
                                onMouseOver={() =>
                                    setModalOpciones({
                                        visible: true,
                                        index: p.id,
                                    })
                                }
                                className="relative ">
                                <div
                                    onMouseOut={() => {
                                        setModalOpciones({
                                            visible: false,
                                            index: "",
                                        });
                                    }}
                                    onMouseOver={() =>
                                        setModalOpciones({
                                            visible: true,
                                            index: p.id,
                                        })
                                    }
                                    onClick={() =>
                                        setDetalleEmpresa({
                                            bandera: true,
                                            id: p.id,
                                        })
                                    }
                                    className=" border  h-auto px-3 pt-4 pb-14 rounded-lg mt-2  text-[#3C3C3C] shadow-md cursor-pointer">
                                    <div></div>
                                    <p className="mb-2 text-base font-bold xl:text-xl">
                                        {p.nombre}
                                    </p>
                                    <p className="text-sm xl:text-base">
                                        {p.administrador}{" "}
                                    </p>
                                    <div className="absolute flex justify-end mt-3 right-3">
                                        <img
                                            className="object-cover w-8 h-8 border rounded-full "
                                            src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${p.avatar_administrador_bluecorner}`}
                                            alt="avatar del encargado"
                                            title={p.administrador_bluecorner}
                                        />
                                    </div>
                                </div>
                                <div className="absolute bottom-3 left-2">
                                    <div className="flex items-center ">
                                        <svg
                                            onClick={() =>
                                                setPlaned(
                                                    planed === p.id
                                                        ? null
                                                        : p.id
                                                )
                                            }
                                            style={{
                                                color: p.color_reloj,
                                            }}
                                            className={`w-8 h-8 cursor-pointer `}
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />{" "}
                                            <circle cx="12" cy="12" r="9" />{" "}
                                            <polyline points="12 7 12 12 15 15" />
                                        </svg>
                                        <div
                                            className={
                                                planed === p.id
                                                    ? "absolute z-10 h-auto bg-white border rounded-md w-72 top-8"
                                                    : "hidden"
                                            }>
                                            <div className="grid grid-cols-[90%,10%] py-2 border-b justify-items-center">
                                                <p className="text-[#053465] font-semibold text-sm">
                                                    Actividades
                                                </p>
                                                <p className="text-xs text-slate-400">
                                                    {
                                                        p.actividadesPendientes
                                                            ?.length
                                                    }
                                                </p>
                                            </div>
                                            {p.actividadesPendientes?.map(
                                                (actividad) => {
                                                    return (
                                                        <div
                                                            className="grid grid-cols-[90%,10%] py-2 border-b "
                                                            key={actividad.id}>
                                                            <div>
                                                                <div className="flex items-center">
                                                                    <p className="text-[#053465]  text-xs pl-1">
                                                                        {
                                                                            actividad.actividad
                                                                        }
                                                                    </p>
                                                                    <svg
                                                                        onClick={() => {
                                                                            setModalEditar(
                                                                                {
                                                                                    bandera: true,
                                                                                    empresa:
                                                                                        p,
                                                                                    actividad:
                                                                                        actividad,
                                                                                }
                                                                            );
                                                                            setPlaned(
                                                                                null
                                                                            );
                                                                        }}
                                                                        className="w-4 h-4 ml-1 text-blue-500 cursor-pointer"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        {" "}
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />{" "}
                                                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                                        <line
                                                                            x1="13.5"
                                                                            y1="6.5"
                                                                            x2="17.5"
                                                                            y2="10.5"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <div>
                                                                    {actividad.dias_restantes ===
                                                                    0 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vence
                                                                            hoy
                                                                        </p>
                                                                    ) : actividad.dias_restantes ===
                                                                      1 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vence
                                                                            en 1
                                                                            dia
                                                                        </p>
                                                                    ) : actividad.dias_restantes >
                                                                      1 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vence
                                                                            en{" "}
                                                                            {
                                                                                actividad.dias_restantes
                                                                            }{" "}
                                                                            dias
                                                                        </p>
                                                                    ) : actividad.dias_restantes ===
                                                                      -1 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vencio
                                                                            hace
                                                                            1
                                                                            dia
                                                                        </p>
                                                                    ) : (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vencio
                                                                            hace{" "}
                                                                            {actividad.dias_restantes *
                                                                                -1}{" "}
                                                                            dias
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex items-end">
                                                                <button
                                                                    onClick={() =>
                                                                        setActividadSelected(
                                                                            actividadSelected ===
                                                                                actividad.id
                                                                                ? null
                                                                                : actividad.id
                                                                        )
                                                                    }
                                                                    className="flex items-center justify-center w-5 h-5 px-1 py-1 mr-3 text-white border-2 rounded-full border-slate-300 group bg-slate-300 hover:white hover:bg-green-500 hover:border-green-500">
                                                                    <svg
                                                                        className="w-4 h-4 text-white group-hover:text-white"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor">
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth="7"
                                                                            d="M5 13l4 4L19 7"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            {actividadSelected ===
                                                                actividad.id && (
                                                                <div className="w-full mt-2 border-2">
                                                                    <span className="flex w-full p-1 px-3 bg-gray-200">
                                                                        Marcar
                                                                        como
                                                                        hecho
                                                                    </span>
                                                                    <div className="p-2">
                                                                        <textarea
                                                                            type="text"
                                                                            placeholder="Escribir comentarios"
                                                                            value={
                                                                                comentarioTerminado
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setComentarioTerminado(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            className="w-full h-20 p-1 border outline-none"
                                                                        />
                                                                        <div className="flex gap-3 mt-2">
                                                                            <button
                                                                                className="px-4 py-1 font-semibold text-white bg-blue-bluecorner"
                                                                                onClick={
                                                                                    handleFinish
                                                                                }>
                                                                                TERMINAR
                                                                            </button>
                                                                            <button
                                                                                className="font-semibold text-blue-bluecorner"
                                                                                onClick={() => {
                                                                                    setActividadSelected(
                                                                                        null
                                                                                    );
                                                                                    setComentarioTerminado(
                                                                                        ""
                                                                                    );
                                                                                }}>
                                                                                Descartar
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                            <div className="flex items-center justify-center mt-2 transition duration-300 cursor-pointer hover:bg-slate-200">
                                                <svg
                                                    className="w-5 h-5 text-blue-500"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <line
                                                        x1="12"
                                                        y1="5"
                                                        x2="12"
                                                        y2="19"
                                                    />{" "}
                                                    <line
                                                        x1="5"
                                                        y1="12"
                                                        x2="19"
                                                        y2="12"
                                                    />
                                                </svg>
                                                <p
                                                    onClick={() =>
                                                        setEmpresa(p)
                                                    }
                                                    className="py-2 text-sm font-bold text-blue-500 uppercase">
                                                    planificar una actividad
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        className={`absolute right-1 top-[-8px]  bg-[#053465] rounded-lg hover:bg-slate-200 cursor-pointer ${
                                            modalOpciones.index !== p.id
                                                ? "hidden"
                                                : "block"
                                        } `}
                                        aria-label="close">
                                        <span
                                            onClick={() =>
                                                setModalEliminar({
                                                    visible: true,
                                                    index:
                                                        p.id ===
                                                        modalEliminar.index
                                                            ? null
                                                            : p.id,
                                                })
                                            }
                                            className={`absolute right-2 top-5  cursor-pointer `}
                                            aria-label="close">
                                            <svg
                                                className="w-5 h-5 text-black"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="1"
                                                />{" "}
                                                <circle cx="12" cy="5" r="1" />{" "}
                                                <circle cx="12" cy="19" r="1" />
                                            </svg>
                                        </span>
                                        <div
                                            className={
                                                modalEliminar.visible ===
                                                    true &&
                                                modalEliminar.index === p.id
                                                    ? "absolute bg-white  right-1 top-10 border rounded-lg  p-1 text-sm"
                                                    : "hidden"
                                            }>
                                            <p
                                                onClick={() => (
                                                    setModalEliminar({
                                                        visible: false,
                                                        index: null,
                                                    }),
                                                    setModalEncargado({
                                                        bandera: true,
                                                        nombre: p.nombre,
                                                        id_encargado_bluecorner:
                                                            p.id_encargado_bluecorner,
                                                        id_empresa: p.id,
                                                    })
                                                )}
                                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                                Encargado
                                            </p>
                                            <p
                                                onClick={() => (
                                                    setModalEliminar({
                                                        visible: false,
                                                        index: null,
                                                    }),
                                                    handleDelete(p.id)
                                                )}
                                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                                Eliminar
                                            </p>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </ReactSortable>

                <ReactSortable
                    className="w-1/3 px-2 lg:px-4"
                    list={colum2}
                    setList={setColum2}
                    group={"empresas"}
                    dragClass="draggable"
                    animation={100}
                    multiDrag={true}>
                    {colum2.map((p, i) => {
                        return (
                            <div
                                key={p.id}
                                onMouseOut={() => {
                                    setModalOpciones({
                                        visible: false,
                                        index: "",
                                    });
                                }}
                                onMouseOver={() =>
                                    setModalOpciones({
                                        visible: true,
                                        index: p.id,
                                    })
                                }
                                className="relative ">
                                <div
                                    onMouseOut={() => {
                                        setModalOpciones({
                                            visible: false,
                                            index: "",
                                        });
                                    }}
                                    onMouseOver={() =>
                                        setModalOpciones({
                                            visible: true,
                                            index: p.id,
                                        })
                                    }
                                    onClick={() =>
                                        setDetalleEmpresa({
                                            bandera: true,
                                            id: p.id,
                                        })
                                    }
                                    className=" border  h-auto px-3 pt-4 pb-14 rounded-lg mt-2  text-[#3C3C3C] shadow-md cursor-pointer">
                                    <div></div>
                                    <p className="mb-2 text-base font-bold xl:text-xl">
                                        {p.nombre}
                                    </p>
                                    <p className="text-sm xl:text-base">
                                        {p.administrador}{" "}
                                    </p>
                                    <div className="absolute flex justify-end mt-3 right-3">
                                        <img
                                            className="object-cover w-8 h-8 border rounded-full "
                                            src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${p.avatar_administrador_bluecorner}`}
                                            alt="avatar del encargado"
                                            title={p.administrador_bluecorner}
                                        />
                                    </div>
                                </div>
                                <div className="absolute bottom-3 left-2">
                                    <div className="flex items-center ">
                                        <svg
                                            onClick={() =>
                                                setPlaned(
                                                    planed === p.id
                                                        ? null
                                                        : p.id
                                                )
                                            }
                                            style={{
                                                color: p.color_reloj,
                                            }}
                                            className={`w-8 h-8 cursor-pointer `}
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />{" "}
                                            <circle cx="12" cy="12" r="9" />{" "}
                                            <polyline points="12 7 12 12 15 15" />
                                        </svg>
                                        <div
                                            className={
                                                planed === p.id
                                                    ? "absolute z-10 h-auto bg-white border rounded-md w-72 top-8"
                                                    : "hidden"
                                            }>
                                            <div className="grid grid-cols-[90%,10%] py-2 border-b justify-items-center">
                                                <p className="text-[#053465] font-semibold text-sm">
                                                    Actividades
                                                </p>
                                                <p className="text-xs text-slate-400">
                                                    {
                                                        p.actividadesPendientes
                                                            ?.length
                                                    }
                                                </p>
                                            </div>
                                            {p.actividadesPendientes?.map(
                                                (actividad) => {
                                                    return (
                                                        <div
                                                            className="grid grid-cols-[90%,10%] py-2 border-b "
                                                            key={actividad.id}>
                                                            <div>
                                                                <div className="flex items-center">
                                                                    <p className="text-[#053465]  text-xs pl-1">
                                                                        {
                                                                            actividad.actividad
                                                                        }
                                                                    </p>
                                                                    <svg
                                                                        onClick={() => {
                                                                            setModalEditar(
                                                                                {
                                                                                    bandera: true,
                                                                                    empresa:
                                                                                        p,
                                                                                    actividad:
                                                                                        actividad,
                                                                                }
                                                                            );
                                                                            setPlaned(
                                                                                null
                                                                            );
                                                                        }}
                                                                        className="w-4 h-4 ml-1 text-blue-500 cursor-pointer"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        {" "}
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />{" "}
                                                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                                        <line
                                                                            x1="13.5"
                                                                            y1="6.5"
                                                                            x2="17.5"
                                                                            y2="10.5"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <div>
                                                                    {actividad.dias_restantes ===
                                                                    0 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vence
                                                                            hoy
                                                                        </p>
                                                                    ) : actividad.dias_restantes ===
                                                                      1 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vence
                                                                            en 1
                                                                            dia
                                                                        </p>
                                                                    ) : actividad.dias_restantes >
                                                                      1 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vence
                                                                            en{" "}
                                                                            {
                                                                                actividad.dias_restantes
                                                                            }{" "}
                                                                            dias
                                                                        </p>
                                                                    ) : actividad.dias_restantes ===
                                                                      -1 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vencio
                                                                            hace
                                                                            1
                                                                            dia
                                                                        </p>
                                                                    ) : (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vencio
                                                                            hace{" "}
                                                                            {actividad.dias_restantes *
                                                                                -1}{" "}
                                                                            dias
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex items-end">
                                                                <button
                                                                    onClick={() =>
                                                                        setActividadSelected(
                                                                            actividadSelected ===
                                                                                actividad.id
                                                                                ? null
                                                                                : actividad.id
                                                                        )
                                                                    }
                                                                    className="flex items-center justify-center w-5 h-5 px-1 py-1 mr-3 text-white border-2 rounded-full border-slate-300 group bg-slate-300 hover:white hover:bg-green-500 hover:border-green-500">
                                                                    <svg
                                                                        className="w-4 h-4 text-white group-hover:text-white"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor">
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth="7"
                                                                            d="M5 13l4 4L19 7"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            {actividadSelected ===
                                                                actividad.id && (
                                                                <div className="w-full mt-2 border-2">
                                                                    <span className="flex w-full p-1 px-3 bg-gray-200">
                                                                        Marcar
                                                                        como
                                                                        hecho
                                                                    </span>
                                                                    <div className="p-2">
                                                                        <textarea
                                                                            type="text"
                                                                            placeholder="Escribir comentarios"
                                                                            value={
                                                                                comentarioTerminado
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setComentarioTerminado(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            className="w-full h-20 p-1 border outline-none"
                                                                        />
                                                                        <div className="flex gap-3 mt-2">
                                                                            <button
                                                                                className="px-4 py-1 font-semibold text-white bg-blue-bluecorner"
                                                                                onClick={
                                                                                    handleFinish
                                                                                }>
                                                                                TERMINAR
                                                                            </button>
                                                                            <button
                                                                                className="font-semibold text-blue-bluecorner"
                                                                                onClick={() => {
                                                                                    setActividadSelected(
                                                                                        null
                                                                                    );
                                                                                    setComentarioTerminado(
                                                                                        ""
                                                                                    );
                                                                                }}>
                                                                                Descartar
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                            <div className="flex items-center justify-center mt-2 transition duration-300 cursor-pointer hover:bg-slate-200">
                                                <svg
                                                    className="w-5 h-5 text-blue-500"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <line
                                                        x1="12"
                                                        y1="5"
                                                        x2="12"
                                                        y2="19"
                                                    />{" "}
                                                    <line
                                                        x1="5"
                                                        y1="12"
                                                        x2="19"
                                                        y2="12"
                                                    />
                                                </svg>
                                                <p
                                                    onClick={() =>
                                                        setEmpresa(p)
                                                    }
                                                    className="py-2 text-sm font-bold text-blue-500 uppercase">
                                                    planificar una actividad
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        className={`absolute right-1 top-[-8px]  bg-[#053465] rounded-lg hover:bg-slate-200 cursor-pointer ${
                                            modalOpciones.index !== p.id
                                                ? "hidden"
                                                : "block"
                                        } `}
                                        aria-label="close">
                                        <span
                                            onClick={() =>
                                                setModalEliminar({
                                                    visible: true,
                                                    index:
                                                        p.id ===
                                                        modalEliminar.index
                                                            ? null
                                                            : p.id,
                                                })
                                            }
                                            className={`absolute right-2 top-5  cursor-pointer `}
                                            aria-label="close">
                                            <svg
                                                className="w-5 h-5 text-black"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="1"
                                                />{" "}
                                                <circle cx="12" cy="5" r="1" />{" "}
                                                <circle cx="12" cy="19" r="1" />
                                            </svg>
                                        </span>
                                        <div
                                            className={
                                                modalEliminar.visible ===
                                                    true &&
                                                modalEliminar.index === p.id
                                                    ? "absolute bg-white  right-1 top-10 border rounded-lg  p-1 text-sm"
                                                    : "hidden"
                                            }>
                                            <p
                                                onClick={() => (
                                                    setModalEliminar({
                                                        visible: false,
                                                        index: null,
                                                    }),
                                                    setModalEncargado({
                                                        bandera: true,
                                                        nombre: p.nombre,
                                                        id_encargado_bluecorner:
                                                            p.id_encargado_bluecorner,
                                                        id_empresa: p.id,
                                                    })
                                                )}
                                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                                Encargado
                                            </p>
                                            <p
                                                onClick={() => (
                                                    setModalEliminar({
                                                        visible: false,
                                                        index: null,
                                                    }),
                                                    handleDelete(p.id)
                                                )}
                                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                                Eliminar
                                            </p>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </ReactSortable>

                <ReactSortable
                    className="w-1/3 min-h-[73vh] px-2 lg:px-4"
                    list={colum3}
                    setList={setColum3}
                    group={"empresas"}
                    dragClass="draggable"
                    animation={100}
                    multiDrag={true}>
                    {colum3.map((p, i) => {
                        return (
                            <div
                                key={p.id}
                                onMouseOut={() => {
                                    setModalOpciones({
                                        visible: false,
                                        index: "",
                                    });
                                }}
                                onMouseOver={() =>
                                    setModalOpciones({
                                        visible: true,
                                        index: p.id,
                                    })
                                }
                                className="relative ">
                                <div
                                    onMouseOut={() => {
                                        setModalOpciones({
                                            visible: false,
                                            index: "",
                                        });
                                    }}
                                    onMouseOver={() =>
                                        setModalOpciones({
                                            visible: true,
                                            index: p.id,
                                        })
                                    }
                                    onClick={() =>
                                        setDetalleEmpresa({
                                            bandera: true,
                                            id: p.id,
                                        })
                                    }
                                    className=" border  h-auto px-3 pt-4 pb-14 rounded-lg mt-2  text-[#3C3C3C] shadow-md cursor-pointer">
                                    <div></div>
                                    <p className="mb-2 text-base font-bold xl:text-xl">
                                        {p.nombre}
                                    </p>
                                    <p className="text-sm xl:text-base">
                                        {p.administrador}{" "}
                                    </p>
                                    <div className="absolute flex justify-end mt-3 right-3">
                                        <img
                                            className="object-cover w-8 h-8 border rounded-full "
                                            src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${p.avatar_administrador_bluecorner}`}
                                            alt="avatar del encargado"
                                            title={p.administrador_bluecorner}
                                        />
                                    </div>
                                </div>
                                <div className="absolute bottom-3 left-2">
                                    <div className="flex items-center ">
                                        <svg
                                            onClick={() =>
                                                setPlaned(
                                                    planed === p.id
                                                        ? null
                                                        : p.id
                                                )
                                            }
                                            style={{
                                                color: p.color_reloj,
                                            }}
                                            className={`w-8 h-8 cursor-pointer `}
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />{" "}
                                            <circle cx="12" cy="12" r="9" />{" "}
                                            <polyline points="12 7 12 12 15 15" />
                                        </svg>
                                        <div
                                            className={
                                                planed === p.id
                                                    ? "absolute z-10 h-auto bg-white border rounded-md w-72 top-8"
                                                    : "hidden"
                                            }>
                                            <div className="grid grid-cols-[90%,10%] py-2 border-b justify-items-center">
                                                <p className="text-[#053465] font-semibold text-sm">
                                                    Actividades
                                                </p>
                                                <p className="text-xs text-slate-400">
                                                    {
                                                        p.actividadesPendientes
                                                            ?.length
                                                    }
                                                </p>
                                            </div>
                                            {p.actividadesPendientes?.map(
                                                (actividad) => {
                                                    return (
                                                        <div
                                                            className="grid grid-cols-[90%,10%] py-2 border-b "
                                                            key={actividad.id}>
                                                            <div>
                                                                <div className="flex items-center">
                                                                    <p className="text-[#053465]  text-xs pl-1">
                                                                        {
                                                                            actividad.actividad
                                                                        }
                                                                    </p>
                                                                    <svg
                                                                        onClick={() => {
                                                                            setModalEditar(
                                                                                {
                                                                                    bandera: true,
                                                                                    empresa:
                                                                                        p,
                                                                                    actividad:
                                                                                        actividad,
                                                                                }
                                                                            );
                                                                            setPlaned(
                                                                                null
                                                                            );
                                                                        }}
                                                                        className="w-4 h-4 ml-1 text-blue-500 cursor-pointer"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="2"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                        {" "}
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        />{" "}
                                                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                                        <line
                                                                            x1="13.5"
                                                                            y1="6.5"
                                                                            x2="17.5"
                                                                            y2="10.5"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <div>
                                                                    {actividad.dias_restantes ===
                                                                    0 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vence
                                                                            hoy
                                                                        </p>
                                                                    ) : actividad.dias_restantes ===
                                                                      1 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vence
                                                                            en 1
                                                                            dia
                                                                        </p>
                                                                    ) : actividad.dias_restantes >
                                                                      1 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vence
                                                                            en{" "}
                                                                            {
                                                                                actividad.dias_restantes
                                                                            }{" "}
                                                                            dias
                                                                        </p>
                                                                    ) : actividad.dias_restantes ===
                                                                      -1 ? (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vencio
                                                                            hace
                                                                            1
                                                                            dia
                                                                        </p>
                                                                    ) : (
                                                                        <p
                                                                            style={{
                                                                                color: actividad.color_actividad,
                                                                            }}
                                                                            className="pl-1 text-xs">
                                                                            vencio
                                                                            hace{" "}
                                                                            {actividad.dias_restantes *
                                                                                -1}{" "}
                                                                            dias
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex items-end">
                                                                <button
                                                                    onClick={() =>
                                                                        setActividadSelected(
                                                                            actividadSelected ===
                                                                                actividad.id
                                                                                ? null
                                                                                : actividad.id
                                                                        )
                                                                    }
                                                                    className="flex items-center justify-center w-5 h-5 px-1 py-1 mr-3 text-white border-2 rounded-full border-slate-300 group bg-slate-300 hover:white hover:bg-green-500 hover:border-green-500">
                                                                    <svg
                                                                        className="w-4 h-4 text-white group-hover:text-white"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor">
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth="7"
                                                                            d="M5 13l4 4L19 7"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            {actividadSelected ===
                                                                actividad.id && (
                                                                <div className="w-full mt-2 border-2">
                                                                    <span className="flex w-full p-1 px-3 bg-gray-200">
                                                                        Marcar
                                                                        como
                                                                        hecho
                                                                    </span>
                                                                    <div className="p-2">
                                                                        <textarea
                                                                            type="text"
                                                                            placeholder="Escribir comentarios"
                                                                            value={
                                                                                comentarioTerminado
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setComentarioTerminado(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                            className="w-full h-20 p-1 border outline-none"
                                                                        />
                                                                        <div className="flex gap-3 mt-2">
                                                                            <button
                                                                                className="px-4 py-1 font-semibold text-white bg-blue-bluecorner"
                                                                                onClick={
                                                                                    handleFinish
                                                                                }>
                                                                                TERMINAR
                                                                            </button>
                                                                            <button
                                                                                className="font-semibold text-blue-bluecorner"
                                                                                onClick={() => {
                                                                                    setActividadSelected(
                                                                                        null
                                                                                    );
                                                                                    setComentarioTerminado(
                                                                                        ""
                                                                                    );
                                                                                }}>
                                                                                Descartar
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                            <div className="flex items-center justify-center mt-2 transition duration-300 cursor-pointer hover:bg-slate-200">
                                                <svg
                                                    className="w-5 h-5 text-blue-500"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <line
                                                        x1="12"
                                                        y1="5"
                                                        x2="12"
                                                        y2="19"
                                                    />{" "}
                                                    <line
                                                        x1="5"
                                                        y1="12"
                                                        x2="19"
                                                        y2="12"
                                                    />
                                                </svg>
                                                <p
                                                    onClick={() =>
                                                        setEmpresa(p)
                                                    }
                                                    className="py-2 text-sm font-bold text-blue-500 uppercase">
                                                    planificar una actividad
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        className={`absolute right-1 top-[-8px]  bg-[#053465] rounded-lg hover:bg-slate-200 cursor-pointer ${
                                            modalOpciones.index !== p.id
                                                ? "hidden"
                                                : "block"
                                        } `}
                                        aria-label="close">
                                        <span
                                            onClick={() =>
                                                setModalEliminar({
                                                    visible: true,
                                                    index:
                                                        p.id ===
                                                        modalEliminar.index
                                                            ? null
                                                            : p.id,
                                                })
                                            }
                                            className={`absolute right-2 top-5  cursor-pointer `}
                                            aria-label="close">
                                            <svg
                                                className="w-5 h-5 text-black"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="1"
                                                />{" "}
                                                <circle cx="12" cy="5" r="1" />{" "}
                                                <circle cx="12" cy="19" r="1" />
                                            </svg>
                                        </span>
                                        <div
                                            className={
                                                modalEliminar.visible ===
                                                    true &&
                                                modalEliminar.index === p.id
                                                    ? "absolute bg-white  right-1 top-10 border rounded-lg  p-1 text-sm"
                                                    : "hidden"
                                            }>
                                            <p
                                                onClick={() => (
                                                    setModalEliminar({
                                                        visible: false,
                                                        index: null,
                                                    }),
                                                    setModalEncargado({
                                                        bandera: true,
                                                        nombre: p.nombre,
                                                        id_encargado_bluecorner:
                                                            p.id_encargado_bluecorner,
                                                        id_empresa: p.id,
                                                    })
                                                )}
                                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                                Encargado
                                            </p>
                                            <p
                                                onClick={() => (
                                                    setModalEliminar({
                                                        visible: false,
                                                        index: null,
                                                    }),
                                                    handleDelete(p.id)
                                                )}
                                                className="p-1 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg cursor-pointer">
                                                Eliminar
                                            </p>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </ReactSortable>
            </div>{" "}
        </div>
    );
};

export default CRMEmpresas;
