import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import ModalCreateManualType from "./ModalCreateManualType";
import ModalEditManualType from "./ModalEditManualType";

const token = localStorage.getItem("token");
const headers = {
  authorization: token,
};

const TipoManuales = () => {
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [habilitados, setHabilitados] = useState(true);
  const [typesInPage, setTypesInPage] = useState({
    checking: true,
    types: [],
  });
  const [typeSelected, setTypeSelected] = useState({});

  const getAllTypes = async (SioNo) => {
    setTypesInPage({
      checking: true,
      types: [],
    });
    const { data } = await axios(
      `${process.env.REACT_APP_PROXY}/tipoManual/all/${SioNo}`,
      { headers }
    );
    setTypesInPage({
      checking: false,
      types: data,
    });
  };

  useEffect(() => {
    if (habilitados) getAllTypes("Si");
    else getAllTypes("No");
  }, [habilitados]);

  const handleDelete = async (idType) => {
    Swal.fire({
      title: "¿Seguro que quieres eliminarlo?",
      text: "Se enviará la categoría a deshabilitados!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_PROXY}/tipoManual/eliminar/${idType}`,
            { headers }
          )
          .then((response) => {
            Swal.fire({
              title: "Tipo de manual eliminado correctamente!",
              icon: "success",
              confirmButtonText: "Aceptar",
            });
            getAllTypes("Si");
          });
      }
    });
  };

  const handleEnable = (idType) => {
    Swal.fire({
      title: "¿Seguro que quieres habilitarlo?",
      text: "Se enviará el atributo a habilitados!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, habilitar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            `${process.env.REACT_APP_PROXY}/tipoManual/restaurar/${idType}`,
            {},
            { headers }
          )
          .then((reponse) => {
            Swal.fire({
              title: "Tipo de manual restablecido correctamente!",
              icon: "success",
              confirmButtonText: "Aceptar",
            });
            getAllTypes("No");
          });
      }
    });
  };

  const handleEdit = (type) => {
    setTypeSelected(type);
    setModalEditVisible(!modalEditVisible);
  };

  return (
    <section className="bg-white">
      <h1 className="py-10 text-6xl font-bold text-center">Tipos de Manual</h1>

      <div className="flex w-full">
        {habilitados && (
          <button
            className="inline-flex items-center px-2 py-2 mx-auto text-lg font-semibold text-blue-700 border border-blue-700 rounded group hover:bg-blue-700 hover:text-white"
            onClick={() => setModalCreateVisible(!modalCreateVisible)}
          >
            <svg
              className="w-5 h-5 text-blue-700 group-hover:text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {" "}
              <line x1="12" y1="5" x2="12" y2="19" />{" "}
              <line x1="5" y1="12" x2="19" y2="12" />
            </svg>
            <span className="items-center">Añadir</span>
          </button>
        )}
      </div>
      <div className="container flex items-center justify-between p-1 mx-auto ">
        <button
          className={
            habilitados
              ? "inline-block p-2 mx-3 text-green-700 border border-green-500 rounded group hover:bg-green-700 hover:text-white"
              : "inline-block p-2 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white"
          }
          onClick={() => {
            setHabilitados(!habilitados);
            setPage(1);
          }}
        >
          {habilitados
            ? `Habilitados (${typesInPage.types.length})`
            : `Deshabilitados (${typesInPage.types.length})`}
        </button>
      </div>
      <ModalCreateManualType
        modalVisible={modalCreateVisible}
        setmodalVisible={setModalCreateVisible}
        getAllTypes={getAllTypes}
        actualPage={page}
      />
      {Object.keys(typeSelected).length > 0 && (
        <ModalEditManualType
          setModalEditVisible={setModalEditVisible}
          modalEditVisible={modalEditVisible}
          getAllTypes={getAllTypes}
          typeSelected={typeSelected}
          setTypeSelected={setTypeSelected}
        />
      )}
      <div className="container mx-auto mt-10">
        <div className="flex flex-wrap mx-auto">
          <div className="w-full px-4">
            <div className="max-w-full overflow-x-auto">
              {typesInPage.checking ? (
                <div className="w-full overflow-hidden ">
                  <svg
                    role="status"
                    className="w-12 h-12 mx-auto text-gray-200 animate-spin dark:text-black fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-center bg-blue-700">
                      <th className="w-[4%]  text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                        #
                      </th>
                      <th
                        className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              "
                      >
                        Nombre
                      </th>
                      <th
                        className={
                          habilitados
                            ? "w-1/6  min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent "
                            : "w-1/12 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent "
                        }
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {typesInPage?.types?.map((category, index) => {
                      return (
                        <tr key={category.id}>
                          <td
                            className={
                              habilitados
                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                            }
                          >
                            {page * 25 - 25 + index + 1}
                          </td>
                          <td
                            className={
                              habilitados
                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                            }
                          >
                            {category.nombre}
                          </td>
                          <td
                            className={
                              habilitados
                                ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                            }
                          >
                            {habilitados ? (
                              <div>
                                <button
                                  className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white"
                                  title="Editar"
                                  onClick={() => handleEdit(category)}
                                >
                                  <svg
                                    className="w-8 h-8 text-blue-500 group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                    <line x1="16" y1="5" x2="19" y2="8" />
                                  </svg>
                                </button>
                                <button
                                  className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white"
                                  onClick={() => handleDelete(category.id)}
                                  title="Eliminar"
                                >
                                  <svg
                                    className="w-8 h-8 text-red-500 group-hover:text-white"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="4" y1="7" x2="20" y2="7" />{" "}
                                    <line x1="10" y1="11" x2="10" y2="17" />{" "}
                                    <line x1="14" y1="11" x2="14" y2="17" />{" "}
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                  </svg>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button
                                  onClick={() => handleEnable(category.id)}
                                  title="Habilitar"
                                  className="inline-block px-1 py-1 mx-3 text-green-700 border border-green-500 rounded group hover:bg-green-700 hover:white"
                                >
                                  <svg
                                    className="w-8 h-8 text-green-500 group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <polyline points="17 1 21 5 17 9" />{" "}
                                    <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                    <polyline points="7 23 3 19 7 15" />{" "}
                                    <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                  </svg>
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TipoManuales;
