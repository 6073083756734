import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "../../ToggleButton";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import useHeaders from "../../../hooks/useHeaders";
import dateFromGuiontoSlash from "../../helpers/dateFromGuiontoSlash";
import formatedDate_YYYY_MM_DD from "../../helpers/formatedDate_YYYY_MM_DD";
import { actualizarMostrarCartel } from "../../../redux/actions/authActions";
import { useNavigate } from "react-router";

// let cupones = [
//     {
//         nombre: "Cupon Navideño",
//         contenido: [
//             {
//                 personal: "Wilson Andia",
//                 casa: "cas Driet",
//                 reserva: "1000 S",
//                 fecha: "25/12/2023 - 26/12/2023",
//                 monto_usado: "200 s",
//             },
//             {
//                 personal: "Wilson Andia",
//                 casa: "cas Driet",
//                 reserva: "1000 S",
//                 fecha: "25/12/2023 - 26/12/2023",
//                 monto_usado: "200 s",
//             },
//             {
//                 personal: "Wilson Andia",
//                 casa: "cas Driet",
//                 reserva: "1000 S",
//                 fecha: "25/12/2023 - 26/12/2023",
//                 monto_usado: "200 s",
//             },
//             {
//                 personal: "Wilson Andia",
//                 casa: "cas Driet",
//                 reserva: "1000 S",
//                 fecha: "25/12/2023 - 26/12/2023",
//                 monto_usado: "200 s",
//             },
//         ],
//     },
//     {
//         nombre: "Cupon Trabajador del mes",
//         contenido: [
//             {
//                 personal: "Wilson Andia",
//                 casa: "cas Driet",
//                 reserva: "1000 S",
//                 fecha: "25/12/2023 - 26/12/2023",
//                 monto_usado: "200 s",
//             },
//             {
//                 personal: "Wilson Andia",
//                 casa: "cas Driet",
//                 reserva: "1000 S",
//                 fecha: "25/12/2023 - 26/12/2023",
//                 monto_usado: "200 s",
//             },
//         ],
//     },
// ];
const ModalCuponesPorPagar = ({ setVerCuponesUsados }) => {
    registerLocale("es", es);
    const { headers } = useHeaders();
    const [input, setInput] = useState({
        nombre: "",
        desde: formatedDate_YYYY_MM_DD(new Date()),
        hasta: formatedDate_YYYY_MM_DD(new Date()),
        costo_asumido: "",
        moneda_costo_asumido: "PEN",
        activo: "Si",
        cantidadDeUso: "",
    });
    const [mostrarInputNumero, setMostrarInputNumero] = useState("no");
    const [modalPromoCreada, setModalPromoCreada] = useState(false);
    const [cupones, setCupones] = useState([]);
    const authUser = useSelector((state) => state.authReducer);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const getCupones = async () => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/promoEmpresas/getResumenCupones/${authUser.id_empresa}`,
            { headers }
        );
        console.log(data);
        setCupones(data.cupones);
        setTotal(data.costo_total);
    };
    const navigate = useNavigate();
    const handleCreate = async () => {
        navigate(`/checkout-promo-corporativo/${authUser.id_empresa}`);
        // let uso_cupones;
        // if (mostrarInputNumero === "no") {
        //     uso_cupones = 0;
        // } else if (input.cantidadDeUso === "") {
        //     uso_cupones = 0;
        // } else if (input.cantidadDeUso > 0) {
        //     uso_cupones = input.cantidadDeUso;
        // } else {
        //     uso_cupones = 0;
        // }
        // const promoCompleta = {
        //     activo: input.activo,
        //     id_usuario: authUser.id_usuario,
        //     nombre: input.nombre,
        //     fecha_inicio: input.desde,
        //     fecha_final: input.hasta,
        //     costo_asumido: input.costo_asumido,
        //     tipo_moneda: input.moneda_costo_asumido,
        //     uso_cupones,
        // };
        // console.log(promoCompleta);
        // const { data } = await axios.post(
        //     `${process.env.REACT_APP_PROXY}/promoEmpresas/`,
        //     promoCompleta,
        //     { headers }
        // );
        // console.log(data);
        // if (data.message === "registro exitoso") {
        //     setModalPromoCreada((prev) => {
        //         setCupon(data.data.cupon);
        //         getAllPromociones();
        //         return !prev;
        //     });
        // }
    };

    const handleClose = () => {
        dispatch(actualizarMostrarCartel());
        setVerCuponesUsados(false);
    };
    useEffect(() => {
        getCupones();
    }, []);

    return (
        <div className="fixed top-0 left-0 z-40 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
            <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 ">
                <div className="relative w-full block justify-center px-4 py-16 bg-white rounded-lg md:w-11/12 max-w-4xl dark:bg-white h-[calc(100vh-10vh)] overflow-y-auto">
                    <button
                        onClick={handleClose}
                        className="absolute text-gray-800 dark:text-gray-400 top-8 right-4 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                        <svg
                            className="rounded-full hover:bg-gray-600 hover:text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>

                    <header className="text-lg font-medium">
                        Cupones pendiente de pago
                    </header>

                    <hr className="mt-6" />
                    {/* Mapeo de la matriz de cupones */}
                    {cupones.map((cupon, index) => (
                        <div key={index} className="mt-4">
                            <h2 className="text-xl font-bold">
                                {cupon.nombre}
                            </h2>
                            <table className="mt-2 w-full border-collapse">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-400 p-2">
                                            Personal
                                        </th>
                                        <th className="border border-gray-400 p-2">
                                            Casa
                                        </th>
                                        <th className="border border-gray-400 p-2 hidden xs:table-cell">
                                            Reserva
                                        </th>
                                        <th className="border border-gray-400 p-2 hidden xs:table-cell">
                                            Fecha
                                        </th>
                                        <th className="border border-gray-400 p-2">
                                            Monto Usado
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Mapeo del contenido de cada cupón */}
                                    {cupon.contenido.map((detalle, idx) => (
                                        <tr key={idx} className="text-center">
                                            <td className="border border-gray-400 p-2">
                                                {detalle.personal}
                                            </td>
                                            <td className="border border-gray-400 p-2">
                                                {detalle.casa}
                                            </td>
                                            <td className="border border-gray-400 p-2 hidden xs:table-cell">
                                                {detalle.reserva}
                                            </td>
                                            <td className="border border-gray-400 p-2 hidden xs:table-cell">
                                                {detalle.fecha}
                                            </td>
                                            <td className="border border-gray-400 p-2">
                                                {detalle.monto_usado}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                    <div className="p-2 w-full flex justify-center">
                        <button
                            className="w-10/12 max-w-sm p-3 mt-6 text-white rounded-lg bg-blue-bluecorner active:translate-y-2 hover:scale-105"
                            onClick={() => handleCreate()}>
                            Pagar ({total})
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCuponesPorPagar;
