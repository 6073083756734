import React, { useState, useEffect, lazy, Suspense } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

// import FacebookLogin from "./../../helpers/LogeoFacebook";

import { loginUser } from "../../../redux/actions/authActions";
import { useNavigate } from "react-router";
import ModalOlvidadoContraseña from "../../contraseña/ModalOlvidadoContraseña";
// import { GoogleLogin } from "@react-oauth/google";
import UserRegister from "../../myAccount/UserRegister";
import colores from "../../helpers/colores";
const FacebookLogin = lazy(() => import("./../../helpers/LogeoFacebook"));
const GoogleLogin = lazy(() => import("./../../helpers/LogeoGoogle.js"));

const ModalLogeo = ({
    modalLogeo,
    setModalLogeo,
    tipo,
    id_pedido,
    setLoadingFavorito,
    id_cliente,
    admin = false,
}) => {
    const [input, setInput] = useState({
        new_password: "",
    });

    const emailStorage = localStorage.getItem("email");
    const [olvido, setOlvido] = useState(false);
    const [registro, setRegistro] = useState(admin);
    const [Acceder, setAcceder] = useState("Acceder");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPass, setShowPass] = useState(false);
    const [bandera, setBandera] = useState(true);

    const [banderaReseña, setBanderaReseña] = useState(true);
    const [login, setLogin] = useState({
        correo: emailStorage || "",
        password: "",
        rememberme: false,
    });
    const authUser = useSelector((state) => state.authReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const googleDatos = async (data) => {
        const credencialToken = jwt_decode(data.credential);

        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/autenticacion/loginGoogle`,
            {
                email: credencialToken.email,
            }
        );

        if (json.data.message === "logeo exitoso") {
            dispatch(loginUser(null, null, json.data));
        } else {
            Swal.fire({
                title: "Error",
                text: `${json.data.title}`,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            setLoading(false);
            setAcceder("Acceder");
        }
    };

    const responseFacebook = async (data) => {
        let nombre = data.name?.split(" ");

        if (nombre) {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/autenticacion/loginGoogle`,
                {
                    email: data.email,
                }
            );

            if (json.data.message === "logeo exitoso") {
                dispatch(loginUser(null, null, json.data));
            } else {
                Swal.fire({
                    title: "Error",
                    text: `${json.data.title}`,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                setLoading(false);
                setAcceder("Acceder");
            }
        }
    };
    useEffect(() => {
        /* global google*/
        google.accounts.id.initialize({
            client_id:
                "383088837702-acs6qsjvqiaihnfnkv6ggk0npke4coji.apps.googleusercontent.com",
            callback: googleDatos,
        });
    }, []);
    useEffect(() => {
        if (authUser.tipo_usuario && !admin) {
            if (id_cliente) {
                if (!banderaReseña) {
                    setBandera(false);
                    setLoading(false);
                    setBanderaReseña(true);
                    setAcceder("Acceder");
                    Swal.fire({
                        title: "Bienvenido",
                        text: registro
                            ? " se ha registrado correctamente"
                            : "se ha iniciado sesión correctamente",
                        icon: "success",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        setModalLogeo(!modalLogeo);
                    });
                }
                setBanderaReseña(false);
            } else if (bandera) {
                setBandera(false);
                setLoading(false);
                setAcceder("Acceder");
                Swal.fire({
                    title: "Bienvenido",
                    text: registro
                        ? " se ha registrado correctamente"
                        : "se ha iniciado sesión correctamente",
                    icon: "success",
                    confirmButtonText: "Ok",
                }).then((result) => {
                    setBandera(true);
                    tipo
                        ? window.location.reload()
                        : setModalLogeo(!modalLogeo);
                });
            }
        }
    }, [authUser]);

    const validateInput = (form) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores = {};
        if (!regExpEmail.test(form.correo)) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        }
        return errores;
    };
    const handleInputChange = (e) => {
        if (e.target.name === "rememberme") {
            setLogin({
                ...login,
                rememberme: e.target.checked,
            });
        } else {
            setLogin({
                ...login,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        /* const errors = validateInput(login); */
        const errors = "";
        setErrors(errors);
        if (Object.entries(errors).length === 0) {
            if (login.rememberme) localStorage.setItem("email", login.correo);
            else localStorage.removeItem("email");
            setLoading(true);
            setAcceder("Accediendo...");
            dispatch(loginUser(login.correo, login.password));
        }
    };

    window.handleCredentialResponse = function (res) {
        console.log(res);
    };

    const handleClose = () => {
        setBandera(true);
        setLoading(false);
        setAcceder("Acceder");
        setLoadingFavorito && setLoadingFavorito(true);
        setModalLogeo(!modalLogeo);
        // dispatch(clearUserSelected());
    };

    return (
        <div
            className={`relative z-[60] items-center justify-center ${
                modalLogeo ? "flex" : "hidden"
            }`}>
            {olvido && (
                <ModalOlvidadoContraseña
                    olvido={olvido}
                    setOlvido={setOlvido}
                />
            )}

            <div
                className={
                    "fixed top-0 w-[130%] h-full bg-gray-900 bg-opacity-80 sticky-0"
                }>
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-28 sm:py-28">
                    <div className="flex-col relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg  xs:w-auto dark:bg-white md:px-8  h-[calc(100vh-30vh)] sm:h-[calc(100vh-20vh)] p-10 overflow-y-auto ">
                        {registro && (
                            <div className=" max-h-[680px]">
                                <UserRegister
                                    registro={registro}
                                    setRegistro={setRegistro}
                                    desdeModal={true}
                                    setModalLogeo={setModalLogeo}
                                />
                            </div>
                        )}
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-12 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>

                        <div
                            className={
                                !registro
                                    ? "p-5  mb-8 w-[340px] mx-auto  h-full"
                                    : "hidden"
                            }>
                            <h1
                                className={`text-[${colores.primario}] text-4xl font-bold text-center  right-auto`}>
                                Iniciar sesión
                            </h1>

                            <div className="flex items-center mx-auto text-lg text-gray-500 ">
                                <p>¿Aun no tienes una cuenta? </p>
                            </div>
                            <p
                                onClick={() => setRegistro(true)}
                                className={`text-[${colores.secundario}] cursor-pointer text-lg pl-1`}>
                                Registrate ahora
                            </p>

                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="w-full mt-4">
                                    <label className="font-semibold">
                                        Correo electrónico:
                                        <span className="text-xl text-red-600">
                                            *
                                        </span>
                                    </label>
                                    {errors.correo && (
                                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                                            {errors.correo}
                                        </p>
                                    )}
                                    <input
                                        type="text"
                                        value={login.correo}
                                        name="correo"
                                        onChange={handleInputChange}
                                        placeholder="Ingrese su correo electrónico"
                                        className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                                    />
                                </div>
                                <div className="w-full mt-4">
                                    <label className="font-semibold ">
                                        Contraseña:
                                        <span className="text-xl text-red-600">
                                            *
                                        </span>
                                    </label>
                                    {errors.password && (
                                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                                            {errors.password}
                                        </p>
                                    )}
                                    <div className="flex items-center w-full rounded-lg - ring-2 ring-gray-200 ">
                                        <input
                                            type={`${
                                                showPass ? "text" : "password"
                                            }`}
                                            name="password"
                                            value={login.password}
                                            onChange={handleInputChange}
                                            className={`w-full p-3 placeholder-gray-500 rounded-lg focus:outline-none focus:border-[${colores.secundario}]`}
                                            placeholder="Ingresa tu contraseña"
                                        />

                                        {showPass ? (
                                            <>
                                                <svg
                                                    className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setShowPass(!showPass);
                                                    }}>
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                    />
                                                </svg>
                                            </>
                                        ) : (
                                            <>
                                                <svg
                                                    className={`w-6 h-6 mx-2 text-[${colores.secundario}] cursor-pointer`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    onClick={() => {
                                                        setShowPass(!showPass);
                                                    }}>
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                    />
                                                </svg>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center mt-4">
                                    <input
                                        type="checkbox"
                                        name="rememberme"
                                        className="mr-2 "
                                        onChange={handleInputChange}
                                    />
                                    <h2 className="text-base font-semibold">
                                        Recuérdame
                                    </h2>
                                </div>
                                <button
                                    disabled={loading}
                                    type="submit"
                                    className={`p-2 px-4 text-lg font-semibold text-white bg-[${colores.primario}] mt-4 rounded-lg disabled:bg-[#416688]`}>
                                    {Acceder}
                                </button>
                                <p
                                    onClick={() => setOlvido(true)}
                                    className={`block mt-5 text-center text-lg text-[${colores.secundario}] cursor-pointer`}>
                                    ¿Olvidaste la contraseña?
                                </p>
                                <div className="flex items-center justify-center mx-auto mt-2 text-gray-500">
                                    <p>o</p>
                                </div>
                                {/* <div className="flex items-center justify-center mx-auto mt-2">
                                    <GoogleLogin
                                        size="medium"
                                        shape="pill"
                                        width="260"
                                        theme="filled_blue"
                                        auto_select="false"
                                        onSuccess={googleDatos}
                                        onError={() => {
                                            console.log("Login Failed");
                                        }}
                                    />
                                    
                                </div> */}
                                <Suspense fallback={<div>Loading...</div>}>
                                    <GoogleLogin googleDatos={googleDatos} />
                                </Suspense>
                                <div className="flex items-center justify-center mx-auto mt-2">
                                    {/* <div>
                                        <FacebookLogin
                                            appId="515412373874363"
                                            fields="name,email"
                                            // onClick={componentClicked}
                                            callback={responseFacebook}
                                            textButton="Iniciar sesión con Facebook"
                                            cssClass="w-[260px] mx-auto mt-3 bg-blue-500 text-white rounded-2xl pl-[20px] pr-[27px]  py-[6px] flex justify-between items-center text-sm font-sans font-medium hover:bg-blue-400"
                                            icon="fa-facebook"
                                        />
                                    </div> */}
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <FacebookLogin
                                            responseFacebook={responseFacebook}
                                        />
                                    </Suspense>
                                </div>
                                {/* <div className="flex items-center justify-center mx-auto mt-4 text-sm text-gray-500">
                                    <p>¿Aun no tienes una cuenta? </p>
                                    <p
                                        onClick={() => setRegistro(true)}
                                        className={`text-[${colores.secundario}] cursor-pointer pl-1`}>
                                        Registrate ahora
                                    </p>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalLogeo;
