import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import feeBC from "../../helpers/feeBC";
const EditarPrecioBaseYGarantia = ({ producto, setProducto, setModal }) => {
    const [input, setInput] = useState({
        coin: producto.data?.moneda_precio_base || "PEN",
        price: producto.data?.precio_base || "0.00",
        warrantyType: producto.data?.tipo_garantia || "",
        warrantyTotal: producto.data?.garantia || "0.00",
        warrantyCoin: producto.data?.moneda_garantia || "PEN",
        tipo_moneda_garantia: producto.data?.tipo_moneda_garantia || "PEN",
        garantia_mascota: producto.data?.garantia_mascota || "0.00",
        tipo_estadia: producto.data?.tipo_estadia || "noche",
    });
    const [rangoDia, setRangoDia] = useState({
        dia_inicio: "",
        dia_final: "",
        precio: "",
        id_casa: producto.data.id,
    });

    const [rangoDias, setRangoDias] = useState([]);
    const authUser = useSelector((state) => state.authReducer);
    const MONEDAS = ["PEN", "USD"];
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        getRangoDias();
    }, []);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const alert = (alert) => {
        Swal.fire({
            title: `Debes completar el campo de: ${alert}`,
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Completar!",
        });
    };

    const updateProduct = async (payload) => {
        console.log(payload);
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/casa/actualizarPrecio/${producto.data.id}`,
            payload,
            { headers }
        );
        if (data.message === "actuaizacion exitoso") {
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    ...payload,
                },
            });
            setModal(false);
        }
    };
    const postRangoDia = async () => {
        if (rangoDia.precio === "") {
            alert("valor");
        } else if (rangoDia.dia_inicio === "") {
            alert("Desde");
        } else if (rangoDia.dia_final === "") {
            alert("Hasta");
        } else {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/casaGarantia/`,
                rangoDia,
                { headers }
            );

            console.log(data);
            if (data.message === "registro exitoso") {
                getRangoDias();
                setRangoDia({
                    dia_inicio: "",
                    dia_final: "",
                    precio: "",
                    id_casa: producto.data.id,
                });
            }
        }
    };

    const getRangoDias = async () => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/casaGarantia/all/${producto.data.id}`,
            { headers }
        );

        console.log(data);
        setRangoDias(data);
    };

    const deleteRangoDias = async (id) => {
        const { data } = await axios.delete(
            `${process.env.REACT_APP_PROXY}/casaGarantia/eliminar/${id}`,
            { headers }
        );

        console.log(data);
        getRangoDias();
    };

    const handleGuardar = () => {
        const payload = {
            precio_base: input.price,
            moneda_precio_base: input.coin,
            garantia: input.warrantyTotal,
            tipo_garantia: input.warrantyType,
            garantia_mascota:
                producto.garantia_mascota === "" ||
                producto.garantia_mascota === "0.00"
                    ? 0
                    : producto.garantia_mascota,
            tipo_moneda_garantia:
                input.warrantyType === "Valor" ||
                input.warrantyType === "Por noche" ||
                input.warrantyType === "Por día" ||
                input.warrantyType === "Rango de días"
                    ? input.tipo_moneda_garantia
                    : input.coin,
            id: producto.data.id,
            tipo_estadia: input.tipo_estadia,
            id_usuario: authUser.id_usuario,
        };
        if (payload.precio_base === "") alert("Costo por noche");
        else if (payload.garantia === "") alert("Garantía");
        else if (
            payload.tipo_garantia === "" ||
            payload.tipo_garantia === "Ninguna"
        )
            alert("Tipo de garantía");
        else updateProduct(payload);
    };

    let inputNumber = document.getElementById("the_number_input");
    inputNumber?.addEventListener("mousewheel", function (event) {
        this.blur();
    });

    return (
        <div className="p-4 border rounded-lg">
            <h1>Precio base</h1>

            <div className="w-full mt-3">
                <div className="h-[30vh] overflow-hidden">
                    <div className="h-[30vh] overflow-auto w-[105%]">
                        <div className="w-full mt-3 ">
                            <div className="flex items-center justify-center">
                                <div
                                    onClick={() =>
                                        input.price > 0 &&
                                        setInput({
                                            ...input,
                                            price: input.price - 1,
                                        })
                                    }
                                    className="flex items-center justify-center w-8 h-8 border-2 rounded-lg cursor-pointer border-blue-bluecorner">
                                    <svg
                                        className="w-4 h-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <input
                                    autoComplete="off"
                                    name="price"
                                    type="number"
                                    step=".01"
                                    id="the_number_input"
                                    value={input.price}
                                    onChange={handleInputChange}
                                    className="text-center font-bold outline-none focus:outline-none  text-[32px]  text-[#3C3C3C]  text-base mx-2 border border-[#E9E9E9] w-[122px] h-[71px] rounded-lg"
                                    // className="flex items-center w-full font-semibold text-center text-gray-700 bg-gray-300 outline-none focus:outline-none text-md hover:text-black focus:text-black md:text-basecursor-default "
                                ></input>
                                <div
                                    onClick={() =>
                                        setInput({
                                            ...input,
                                            price: Number(input.price) + 1,
                                        })
                                    }
                                    className="flex items-center justify-center w-8 h-8 border-2 rounded-lg cursor-pointer border-blue-bluecorner">
                                    <svg
                                        className="w-4 h-4 text-blue-bluecorner"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                            </div>

                            <div className="flex justify-center w-full">
                                <select
                                    name="tipo_estadia"
                                    onChange={handleInputChange}
                                    className="text-[#3C3C3C] border p-2 rounded-lg text-sm text-center my-3 outline-none w-[122px]"
                                    value={input.tipo_estadia}>
                                    <option value="dia">Por día</option>
                                    <option value="noche">Por noche</option>
                                </select>
                            </div>

                            <div className="mx-auto mt-2 w-72">
                                <label className=" w-full text-base text-[#3C3C3C]">
                                    Moneda
                                </label>
                                <select
                                    className="w-full border my-3 bg-transparent rounded-lg py-3 px-3  outline-none focus:outline-none text-[#3C3C3C] placeholder:font-bold"
                                    name="coin"
                                    defaultValue={
                                        producto.data.moneda_precio_base
                                    }
                                    onChange={handleInputChange}>
                                    {MONEDAS.map((moneda) => (
                                        <option key={moneda} value={moneda}>
                                            {" "}
                                            {moneda}{" "}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <hr className="mt-2"></hr>

                            {/* <p className="text-[#3C3C3C] text-sm  text-center mt-2 font-semibold">
                                Recibiras {input.coin === "USD" ? "USD" : "PEN"}{" "}
                                {Number(
                                    input.price -
                                        (producto.data.fee_propietario / 100) *
                                            input.price
                                ).toFixed(2)}{" "}
                                y Bluecorner{" "}
                                {input.coin === "USD" ? "USD" : "PEN"}{" "}
                                {Number(
                                    input.price *
                                        (producto.data.fee_propietario / 100)
                                ).toFixed(2)}{" "}
                                de comisión
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarPrecioBaseYGarantia;
