import {
    GET_ALL_BLOGS,
    CREATE_LABEL,
    RESET_CREATE_LABEL,
    ONE_BLOGS,
    SEARCH_BLOGS,
    RESET_ONE_BLOG,
    ENABLE_BLOGS,
    RESET_ENEBLE_BLOG,
} from "../actions/blogActions";

const initialState = {
    blogs: [],
    create: false,
    oneBlogs: [],
    enableBlogs: [],
};

export const blogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_BLOGS:
            return {
                ...state,
                blogs: action.payload,
            };
        case CREATE_LABEL:
            return {
                ...state,
                create: action.payload,
            };
        case RESET_CREATE_LABEL:
            return {
                ...state,
                create: false,
            };
        case ONE_BLOGS:
            return {
                ...state,
                oneBlogs: action.payload,
            };
        case SEARCH_BLOGS:
            return {
                ...state,
                blogs: action.payload,
            };
        case RESET_ONE_BLOG:
            return {
                ...state,
                oneBlogs: action.payload,
            };
        case ENABLE_BLOGS:
            return {
                ...state,
                enableBlogs: action.payload,
            };
        case RESET_ENEBLE_BLOG:
            return {
                ...state,
                enableBlogs: [],
            };

        default:
            return state;
    }
};
