import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../Spinner";

const ModalOlvidadoContraseña = ({ olvido, setOlvido }) => {
    const [correo, setCorreo] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        if (!correo) {
            Swal.fire({
                title: "Error!",
                text: "El correo es requerido",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            setLoading(true);
            enviarCorreo();
        }
    };

    const enviarCorreo = async () => {
        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/autenticacion/emailRecuperarPassword`,
            {
                correo: correo,
            }
        );
        if (json.data.message !== "Email enviado!") {
            Swal.fire({
                title: "Error!",
                text: "El correo no esta registrado, verifique que sea correcto",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            setLoading(false);
        } else {
            Swal.fire({
                title: "Exito!",
                text: "Se ha enviado un correo con las instrucciones para recuperar la contraseña",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
            setOlvido(false);
            setLoading(false);
        }
        console.log(json.data);
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed top-0 left-0 z-10 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-48">
                    <div className="relative flex flex-col items-center justify-center px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <header className="mb-3 mr-auto text-base font-bold lg:text-2xl">
                            Recuperar contraseña
                        </header>
                        <div className="w-full px-3 border rounded-lg mt-7">
                            <input
                                onChange={(e) => setCorreo(e.target.value)}
                                type="text"
                                value={correo}
                                placeholder="Correo electrónico"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none"
                            />
                        </div>

                        <div className="w-full px-4">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="pb-3 text-center ">
                                            <button
                                                className="w-full rounded-2xl border-b-4 border-b-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                                                onClick={() => handleSubmit()}>
                                                {loading ? (
                                                    <Spinner width={7} />
                                                ) : (
                                                    "Enviar"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => setOlvido(!olvido)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalOlvidadoContraseña;
