import React, { useState } from "react";
import EnviarMensaje from "../mensajes/ModalEnviarMensaje";
import ModalCreateNota from "../notas/ModalCreateNota";
import FeedActividadEmpresa from "./FeedActividadEmpresa";
import ModalPlanificarActividad from "./ModalPlanificarActividad";

export const CRMNotaEmpresas = ({ empresa }) => {
    const [tabSelected, setTabSelected] = useState("");
    const [refreshActividades, setRefreshActividades] = useState(true);
    const [modalEnviarMensaje, setModalEnviarMensaje] = useState(false);
    const [modalCrearNota, setModalCrearNota] = useState(false);
    const [modalPlanificarActividad, setModalPlanificarActividad] =
        useState(false);

    return (
        <div className="h-[86vh] border-x border-t">
            {modalPlanificarActividad && (
                <ModalPlanificarActividad
                    empresa={empresa}
                    modalVisible={modalPlanificarActividad}
                    setModalVisible={setModalPlanificarActividad}
                    setRefreshActividades={setRefreshActividades}
                />
            )}
            {modalCrearNota && (
                <ModalCreateNota
                    empresa={empresa}
                    modalVisible={modalCrearNota}
                    setModalVisible={setModalCrearNota}
                    setRefreshActividades={setRefreshActividades}
                />
            )}
            {modalEnviarMensaje && (
                <EnviarMensaje
                    empresa={empresa}
                    modalVisible={modalEnviarMensaje}
                    setModalVisible={setModalEnviarMensaje}
                    setRefreshActividades={setRefreshActividades}
                />
            )}
            <div className="flex justify-between p-2 gap-0.5">
                <span
                    onClick={() => {
                        setTabSelected("Enviar mensaje");
                        setModalEnviarMensaje(!modalEnviarMensaje);
                    }}
                    className={`${
                        tabSelected === "Enviar mensaje" &&
                        "bg-black bg-opacity-50 text-white"
                    } py-2 px-1 flex-grow text-center border cursor-pointer`}>
                    Enviar mensaje
                </span>
                <span
                    onClick={() => {
                        setTabSelected("Nota");
                        setModalCrearNota(!modalCrearNota);
                    }}
                    className={`${
                        tabSelected === "Nota" &&
                        "bg-black bg-opacity-50 text-white"
                    } py-2 px-1 flex-grow text-center border cursor-pointer`}>
                    Dejar nota
                </span>
                <span
                    onClick={() => {
                        setTabSelected("Planificar");
                        setModalPlanificarActividad(!modalPlanificarActividad);
                    }}
                    className={`${
                        tabSelected === "Planificar" &&
                        "bg-black bg-opacity-50 text-white"
                    } py-2 px-1 flex justify-center items-center gap-0.5 flex-grow text-center border cursor-pointer`}>
                    <svg
                        className={`h-4 w-4 text-blue-bluecorner ${
                            tabSelected === "Planificar" && "text-white"
                        }`}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {" "}
                        <path stroke="none" d="M0 0h24v24H0z" />{" "}
                        <polyline points="12 8 12 12 14 14" />{" "}
                        <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
                    </svg>
                    <span>Planificar actividad</span>
                </span>
            </div>
            {empresa && (
                <FeedActividadEmpresa
                    empresa={empresa}
                    refreshActividades={refreshActividades}
                    setRefreshActividades={setRefreshActividades}
                />
            )}
        </div>
    );
};
