import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import dividirFechaDatePicker from "../../helpers/dividirFechaDatePicker";
// import { getRulesPerPage } from "../../redux/actions/rulesActions";
// import Spinner from "../Spinner";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

const ModalEditPaquete = ({ setPaquete, paquete }) => {
    registerLocale("es", es);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const { tipo_usuario } = useSelector((state) => state.authReducer);
    const [descuento, setDescuento] = useState(
        paquete.pedido_paquete_empresa.descuento
    );
    const [tipoVenta, setTipoVenta] = useState(
        paquete.pedido_paquete_empresa.estado_venta || "Proforma"
    );
    const [modalEdit, setModalEdit] = useState({
        visible: false,
        index: "",
        costo: "",
        personas: "",
    });
    const [siguiente, setSiguiente] = useState("Enviar");
    const [input, setInput] = useState({
        id_paquete: "",

        nombre_paquete: "",
        descripcion_paquete: "",
        numero_personas: "",
        costo: "",
    });
    const [rangos, setRangos] = useState([]);
    const [rangoPersonalizado, setRangoPersonalizado] = useState({
        habilitado: false,
        personas: "",
    });

    const [allPaquetes, setAllPaquetes] = useState([]);
    const [paqueteDetalle, setPaqueteDetalle] = useState({
        ...paquete.pedido_paquete_empresa,
        fecha_evento: paquete.pedido_paquete_empresa.fecha_evento
            .split("/")
            .reverse()
            .join("-"),
    });
    const [allPedidos, setAllPedidos] = useState(
        paquete.detalle_pedido_paquete_empresa
    );
    const [detalleDisabled, setDetalleDisabled] = useState(true);

    const getPaquetes = async (pagina = 1, habilitado = "Si", input = "") => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/paquetesempresas/all/${pagina}/${habilitado}/servicio/?nombre=${input}`,

            { headers }
        );

        setAllPaquetes(json.data.data);
    };

    const getRangos = async (paquete) => {
        console.log(paquete);
        if (paquete === "Selecciona un servicio") {
            setRangos([]);
            setInput({
                id_paquete: "",
                nombre_paquete: "",
                descripcion_paquete: "",
                numero_personas: "",
                costo: "",
            });
            setDetalleDisabled(true);
        } else if (paquete === "Servicio personalizado") {
            setDetalleDisabled(false);
            setRangos([]);
            setInput({
                ...input,
                id_paquete: "",
                descripcion_paquete: "",
                nombre_paquete: paquete,
            });
        } else {
            setDetalleDisabled(true);

            let index = allPaquetes.findIndex((item) => item.id == paquete);

            setInput({
                ...input,
                nombre_paquete: allPaquetes[index].nombre,
                id_paquete: Number(paquete),
                descripcion_paquete: allPaquetes[index].descripcion,
                costo: allPaquetes[index].precio,
                imagen_minificada: allPaquetes[index].imagenes.length
                    ? allPaquetes[index].imagenes[0].imagen_minificada
                    : "",
            });
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/paquetesempresas/detalle/${paquete}`,

                { headers }
            );

            if (json.data.paquetes_empresas_personas.length) {
                setRangos(json.data.paquetes_empresas_personas);
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "",
                    icon: "error",
                    width: "350px",
                    confirmButtonText: "Aceptar",
                });
            }
        }

        // setModalEditVisible(!modalEditVisible);
    };

    const addRango = (rango) => {
        if (rango === "Otro rango") {
            setRangoPersonalizado({
                habilitado: true,
                precio: "",
            });
            setInput({
                ...input,

                costo: 0,
                numero_personas: "Otro rango",
            });
        } else if (rango === "Selecciona un rango") {
            setRangoPersonalizado({
                habilitado: false,
                precio: "",
            });
            setInput({
                ...input,
                costo: 0,
                numero_personas: "Selecciona un rango",
            });
        } else {
            setRangoPersonalizado({
                habilitado: false,
                precio: "",
            });
            let index = rangos.findIndex(
                (item) => item.numero_personas == rango
            );

            setInput({
                ...input,

                costo: rangos[index].precio,
                numero_personas: rangos[index].numero_personas,
            });
        }
    };

    const agregarPedido = () => {
        setDetalleDisabled(true);
        setAllPedidos([...allPedidos, input]);
        setRangoPersonalizado({
            habilitado: false,
            precio: "",
        });
        setRangos([]);
        setInput({
            id_paquete: "",
            nombre_paquete: "",
            descripcion_paquete: "",
            numero_personas: "",
            costo: "",
        });
    };
    const modificarPedido = (i) => {
        let pedidoParaModificar = allPedidos;
        pedidoParaModificar[i].costo = Number(modalEdit.costo);
        pedidoParaModificar[i].numero_personas = Number(modalEdit.personas);
        setAllPedidos(pedidoParaModificar);
        setModalEdit({
            id_paquete: "",

            nombre_paquete: "",
            descripcion_paquete: "",
            numero_personas: "",
            costo: "",
        });
    };

    const sumaTotal = () => {
        let total = 0;

        allPedidos.map((item) => {
            total += item.costo;
        });

        return total;
    };

    useEffect(() => {
        getPaquetes();
    }, []);

    const handleCreate = async (e) => {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        setSiguiente(null);
        e.preventDefault();

        console.log({
            costo: sumaTotal() + paqueteDetalle.costo_paquete,
            costo_paquete: paqueteDetalle.costo_paquete,
            costo_total: sumaTotal() + paqueteDetalle.costo_paquete - descuento,
            descuento: descuento,
            fecha_evento: paqueteDetalle.fecha_evento,
            hora_inicio: paqueteDetalle.hora_inicio,
            hora_final: paqueteDetalle.hora_final,
            numero_adultos: paqueteDetalle.numero_adultos,
            numero_ninos: paqueteDetalle.numero_ninos,
            estado_venta: tipoVenta,
            detalle_pedido: allPedidos,
        });
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/pedidoPaquetesEmpresas/actualizar/${paquete.id}`,
            {
                costo: sumaTotal() + paqueteDetalle.costo_paquete,
                costo_paquete: paqueteDetalle.costo_paquete,
                costo_total:
                    sumaTotal() + paqueteDetalle.costo_paquete - descuento,
                descuento: descuento,
                fecha_evento: paqueteDetalle.fecha_evento,
                hora_inicio: paqueteDetalle.hora_inicio,
                hora_final: paqueteDetalle.hora_final,
                numero_adultos: paqueteDetalle.numero_adultos,
                numero_ninos: paqueteDetalle.numero_ninos,
                estado_venta: tipoVenta,
                detalle_pedido: allPedidos,
            },
            {
                headers,
            }
        );

        if (json.data.message === "registro exitoso") {
            Swal.fire({
                title: "Paquete actualizado correctamente!",
                icon: "success",
                confirmButtonText: "Aceptar",
            });

            setInput({
                id_paquete: "",
                nombre_paquete: "",
                descripcion_paquete: "",
                numero_personas: "",
                costo: "",
            });
            setRangos([]);
            setAllPaquetes([]);
            setAllPedidos([]);
            setPaquete(null);
        }

        setSiguiente("Enviar");
        // }
    };

    const handleClose = () => {
        setInput({
            id_paquete: "",
            nombre_paquete: "",
            descripcion_paquete: "",
            numero_personas: "",
            costo: "",
        });
        setRangos([]);
        setAllPaquetes([]);
        setPaquete(null);
    };

    console.log({ paqueteDetalle, allPedidos });

    return (
        <div className={`relative items-center justify-center flex`}>
            <div className="fixed bottom-0 left-0 z-20 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-10 sm:py-8 ">
                    <div className="relative block justify-center px-4 bg-white rounded-lg  w-auto dark:bg-white md:px-10 h-[calc(100vh-10vh)] overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-3 right-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="px-2 mt-10 text-base font-bold text-left lg:text-2xl">
                            Actualizar paquete
                        </header>
                        <div>
                            <header className="p-2 mt-2 text-base font-bold text-center text-white bg-blue-300 rounded-lg lg:text-lg">
                                {paqueteDetalle.paquete_empresa} (
                                {paqueteDetalle.costo_paquete} USD)
                            </header>
                            <div className="">
                                <div className="relative grid w-full grid-cols-2 gap-2 mt-2">
                                    <div className="flex flex-col ">
                                        <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                            Hora inicial:
                                        </label>
                                        <input
                                            autoComplete="off"
                                            type="time"
                                            onChange={(e) =>
                                                setPaqueteDetalle({
                                                    ...paqueteDetalle,
                                                    hora_inicio: e.target.value,
                                                })
                                            }
                                            value={paqueteDetalle.hora_inicio}
                                            className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                        />
                                    </div>
                                    <div className="flex flex-col ">
                                        <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                            Hora final:
                                        </label>
                                        <input
                                            autoComplete="off"
                                            type="time"
                                            onChange={(e) =>
                                                setPaqueteDetalle({
                                                    ...paqueteDetalle,
                                                    hora_final: e.target.value,
                                                })
                                            }
                                            value={paqueteDetalle.hora_final}
                                            className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                        />
                                    </div>
                                </div>
                                <div className="relative grid w-full grid-cols-2 gap-2 mt-2">
                                    <div className="flex flex-col ">
                                        <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                            Adultos:
                                        </label>
                                        <input
                                            autoComplete="off"
                                            type="number"
                                            name="numero_adultos"
                                            onChange={(e) =>
                                                setPaqueteDetalle({
                                                    ...paqueteDetalle,
                                                    numero_adultos:
                                                        e.target.value,
                                                })
                                            }
                                            value={
                                                paqueteDetalle.numero_adultos
                                            }
                                            className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                            placeholder="Numero de adultos"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                            Niños:
                                        </label>
                                        <input
                                            autoComplete="off"
                                            type="number"
                                            name="numero_ninos"
                                            onChange={(e) =>
                                                setPaqueteDetalle({
                                                    ...paqueteDetalle,
                                                    numero_ninos:
                                                        e.target.value,
                                                })
                                            }
                                            value={paqueteDetalle.numero_ninos}
                                            className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50"
                                            placeholder="Numero de niños"
                                        />
                                    </div>
                                </div>
                                <div className="relative grid w-full gap-4 mt-2 grid-cols-">
                                    <div className="flex flex-col ">
                                        <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                            Fecha de evento:
                                        </label>
                                        <DatePicker
                                            className=" w-full py-2 pl-4 pr-4 mx-auto rounded-lg cursor-pointer text-sm border-[#e5e7eb] border"
                                            placeholderText="Fecha de evento"
                                            locale="es"
                                            autoComplete="off"
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                paqueteDetalle.fecha_evento !==
                                                ""
                                                    ? new Date(
                                                          dividirFechaDatePicker(
                                                              paqueteDetalle.fecha_evento
                                                          )[0],
                                                          dividirFechaDatePicker(
                                                              paqueteDetalle.fecha_evento
                                                          )[1] - 1,
                                                          dividirFechaDatePicker(
                                                              paqueteDetalle.fecha_evento
                                                          )[2]
                                                      )
                                                    : input.fecha_evento
                                            }
                                            onChange={(e) =>
                                                setPaqueteDetalle({
                                                    ...paqueteDetalle,
                                                    fecha_evento: `${e.getFullYear()}-${`${
                                                        e.getMonth() + 1
                                                    }`.padStart(
                                                        2,
                                                        "0"
                                                    )}-${`${e.getDate()}`.padStart(
                                                        2,
                                                        "0"
                                                    )}`,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-2 rounded-lg">
                            <label className="flex font-semibold">
                                Servicios
                            </label>
                            <select
                                disabled={
                                    tipo_usuario > 3 && tipoVenta === "Venta"
                                        ? true
                                        : false
                                }
                                value={input.id_paquete || input.nombre_paquete}
                                className="w-full p-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none disabled:bg-slate-200 disabled:cursor-not-allowed"
                                onChange={(e) => {
                                    getRangos(e.target.value);
                                }}>
                                <option>Selecciona un servicio</option>

                                {allPaquetes.length > 0 &&
                                    allPaquetes.map((user, i) => (
                                        <option key={i} value={user.id}>
                                            {user.nombre} ({user.precio} USD)
                                        </option>
                                    ))}
                            </select>
                        </div>

                        <div
                            className={
                                input.descripcion_paquete || !detalleDisabled
                                    ? "rounded-lg w-full mt-2"
                                    : "hidden"
                            }>
                            <label className="flex font-semibold">
                                Detalle del servicio
                            </label>
                            <textarea
                                type="text"
                                value={input.descripcion_paquete}
                                onChange={(e) => {
                                    setInput({
                                        ...input,
                                        descripcion_paquete: e.target.value,
                                    });
                                }}
                                name="description"
                                disabled={detalleDisabled}
                                className="w-full p-3 my-3 border rounded-lg outline-none h-28 disable:bg-slate-100 focus:outline-none "
                            />
                        </div>

                        <div
                            className={
                                input.descripcion_paquete
                                    ? "flex items-center justify-center rounded-lg w-full mx-auto"
                                    : "hidden"
                            }>
                            <button
                                className={
                                    "px-5 rounded-md border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white active:translate-y-[0.125rem]"
                                }
                                onClick={() => agregarPedido()}
                                disabled={!siguiente}>
                                Agregar
                            </button>
                        </div>
                        <div className="w-full mt-2">
                            <div className="overflow-hidden bg-white rounded-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div
                                            className={
                                                allPedidos.length
                                                    ? "mt-2 rounded-lg w-full "
                                                    : "hidden"
                                            }>
                                            <table className="w-full mt-2 overflow-x-auto">
                                                <thead className="text-left">
                                                    <tr>
                                                        <th className="table-cell w-6/12">
                                                            Nombre
                                                        </th>
                                                        <th className="w-3/12">
                                                            Precio
                                                        </th>
                                                        <th className="w-3/12" />
                                                    </tr>
                                                </thead>
                                                <tbody className="text-left">
                                                    {allPedidos.map(
                                                        (pedido, i) => (
                                                            <tr
                                                                key={i}
                                                                className="w-full border-y">
                                                                <td className="flex items-center mt-1">
                                                                    <div className="flex flex-col w-full md:gap-2 md:items-center md:flex-row">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${pedido.imagen_minificada}`}
                                                                            alt=""
                                                                            className="object-cover my-1 rounded-md w-14 h-14"
                                                                        />
                                                                        <div className="flex items-baseline gap-1 mb-1">
                                                                            <p className="max-w-min">
                                                                                {
                                                                                    pedido.nombre_paquete
                                                                                }
                                                                            </p>
                                                                            <p
                                                                                title={
                                                                                    pedido.descripcion_paquete
                                                                                }>
                                                                                <svg
                                                                                    className="w-5 h-5 mt-0.5 text-blue-500 cursor-help"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    strokeWidth="2"
                                                                                    stroke="currentColor"
                                                                                    fill="none"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                    {" "}
                                                                                    <path
                                                                                        stroke="none"
                                                                                        d="M0 0h24v24H0z"
                                                                                    />{" "}
                                                                                    <circle
                                                                                        cx="12"
                                                                                        cy="12"
                                                                                        r="9"
                                                                                    />{" "}
                                                                                    <line
                                                                                        x1="12"
                                                                                        y1="8"
                                                                                        x2="12"
                                                                                        y2="12"
                                                                                    />{" "}
                                                                                    <line
                                                                                        x1="12"
                                                                                        y1="16"
                                                                                        x2="12.01"
                                                                                        y2="16"
                                                                                    />
                                                                                </svg>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="table-cell">
                                                                    {modalEdit.visible &&
                                                                    modalEdit.index ===
                                                                        i ? (
                                                                        <input
                                                                            className="w-full px-1 border rounded-md"
                                                                            type="number"
                                                                            value={
                                                                                modalEdit.costo
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setModalEdit(
                                                                                    {
                                                                                        ...modalEdit,
                                                                                        costo: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <p>
                                                                            {pedido.costo >
                                                                            0
                                                                                ? `${pedido.costo} USD`
                                                                                : `a cotizar`}
                                                                        </p>
                                                                    )}
                                                                </td>
                                                                <td className="table-cell">
                                                                    <div className="flex gap-2.5">
                                                                        {modalEdit.index ===
                                                                        i ? (
                                                                            <svg
                                                                                onClick={() => {
                                                                                    modificarPedido(
                                                                                        i
                                                                                    );
                                                                                }}
                                                                                className="w-5 h-5 mx-auto text-green-500 cursor-pointer"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor">
                                                                                <path
                                                                                    stroke-Linecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth="2"
                                                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                                />
                                                                            </svg>
                                                                        ) : (
                                                                            <>
                                                                                <svg
                                                                                    onClick={() =>
                                                                                        setAllPedidos(
                                                                                            allPedidos.filter(
                                                                                                (
                                                                                                    pedido,
                                                                                                    index
                                                                                                ) =>
                                                                                                    index !==
                                                                                                    i
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    className={
                                                                                        tipo_usuario >
                                                                                            3 &&
                                                                                        tipoVenta ===
                                                                                            "Venta"
                                                                                            ? "hidden"
                                                                                            : "w-6 h-6 text-red-500 cursor-pointer hover:scale-110"
                                                                                    }
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                        strokeWidth="2"
                                                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                                    />
                                                                                </svg>
                                                                                <svg
                                                                                    onClick={() =>
                                                                                        setModalEdit(
                                                                                            {
                                                                                                index: i,
                                                                                                visible: true,
                                                                                                personas:
                                                                                                    pedido.numero_personas,
                                                                                                costo: pedido.costo,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    className={
                                                                                        tipo_usuario <
                                                                                        3
                                                                                            ? "w-6 h-6 text-blue-500 cursor-pointer hover:scale-110"
                                                                                            : "hidden"
                                                                                    }
                                                                                    width="24"
                                                                                    height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    strokeWidth="2"
                                                                                    stroke="currentColor"
                                                                                    fill="none"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round">
                                                                                    {" "}
                                                                                    <path
                                                                                        stroke="none"
                                                                                        d="M0 0h24v24H0z"
                                                                                    />{" "}
                                                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                                                    <line
                                                                                        x1="13.5"
                                                                                        y1="6.5"
                                                                                        x2="17.5"
                                                                                        y2="10.5"
                                                                                    />
                                                                                </svg>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                                            className={
                                                tipo_usuario < 3
                                                    ? "w-full my-4"
                                                    : "hidden"
                                            }>
                                            <div className="w-full mt-2">
                                                <div className="flex items-center ">
                                                    <label className="flex font-semibold">
                                                        Descuento
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={descuento}
                                                        onChange={(e) => {
                                                            setDescuento(
                                                                e.target.value
                                                            );
                                                        }}
                                                        name="descuento"
                                                        className="w-12 h-8 p-2 mx-2 border rounded-md outline-none disable:bg-slate-100 focus:outline-none "
                                                    />
                                                    <p className="font-medium">
                                                        USD
                                                    </p>
                                                </div>
                                                <div className="flex items-center mt-4">
                                                    <label className="flex font-semibold">
                                                        Estado
                                                    </label>
                                                    <select
                                                        value={tipoVenta}
                                                        className="w-[90px] h-8 py-1 mx-8 text-sm border rounded-md outline-none disable:bg-slate-100 focus:outline-none "
                                                        onChange={(e) => {
                                                            setTipoVenta(
                                                                e.target.value
                                                            );
                                                        }}>
                                                        <option
                                                            className="px-4"
                                                            value="Proforma">
                                                            Proforma
                                                        </option>
                                                        <option
                                                            className="px-4"
                                                            value="Venta">
                                                            Venta
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full mt-5 font-medium text-left">
                                            {descuento < 1 ? (
                                                <div>
                                                    <p>
                                                        Paquete:{" "}
                                                        {
                                                            paqueteDetalle.costo_paquete
                                                        }{" "}
                                                        USD
                                                    </p>
                                                    <p>
                                                        Servicios: {sumaTotal()}{" "}
                                                        USD
                                                    </p>
                                                    <p>
                                                        Total:{" "}
                                                        {sumaTotal() +
                                                            paqueteDetalle.costo_paquete}{" "}
                                                        USD
                                                    </p>
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>
                                                        Paquete:{" "}
                                                        {
                                                            paqueteDetalle.costo_paquete
                                                        }{" "}
                                                        USD
                                                    </p>
                                                    <p>
                                                        Servicios: {sumaTotal()}{" "}
                                                        USD
                                                    </p>
                                                    <p>
                                                        Descuento: -{descuento}{" "}
                                                        USD
                                                    </p>
                                                    <p>
                                                        Total:
                                                        {sumaTotal() +
                                                            paqueteDetalle.costo_paquete -
                                                            descuento}{" "}
                                                        USD
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <div className="pb-3 text-center">
                                            <button
                                                className="w-full rounded-lg border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white active:translate-y-[0.125rem] mt-5 disabled:bg-slate-400 disabled:border-slate-400"
                                                onClick={(e) => handleCreate(e)}
                                                disabled={!siguiente}>
                                                {siguiente
                                                    ? siguiente
                                                    : "Enviando.."}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditPaquete;
