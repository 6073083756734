import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
    createAttribute,
    resetCreateAttribute,
} from "../../redux/actions/attributesActions";

const ModalCreateAttributes = ({ create, setcreate }) => {
    const dispatch = useDispatch();
    const createRedux = useSelector((state) => state.attributesReducer.create);
    const form = new FormData();
    const [cargando, SetCargando] = useState(false);

    const [attributes, setAttributes] = useState({
        nombre: "",
        regla: "No",
        capacidad: "No",
        image: "",
    });

    const loadFile = function (event) {
        const output = document.getElementById("output");
        output.src = URL.createObjectURL(event.target.files[0]);
        output.onload = function () {
            URL.revokeObjectURL(output.src);
        };
    };

    const handleSubmit = () => {
        if (!attributes.nombre) {
            Swal.fire({
                title: "Error!",
                text: "El servcio debe tener un nombre",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else if (!attributes.image) {
            Swal.fire({
                title: "Error!",
                text: "El servicio debe tener una imagen",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            SetCargando(true);

            form.append("nombre", attributes.nombre);
            form.append("regla", attributes.regla);
            form.append("capacidad", attributes.capacidad);
            form.append("image_atributo", attributes.image);
            dispatch(createAttribute(form));
        }
    };
    useEffect(() => {
        if (createRedux === "registro exitoso") {
            setcreate(!create);
            dispatch(resetCreateAttribute());

            Swal.fire({
                title: "Procesado!",
                text: "Servicio creado",
                icon: "success",
                confirmButtonText: "Aceptar",
            });
        }
    }, [createRedux, dispatch]);

    const handleChangeImage = (e) => {
        if (e.target.files[0]) {
            loadFile(e);
            setAttributes({
                ...attributes,
                image: e.target.files[0],
            });
        }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-36 2xl:container 2xl:mx-auto  sm:py-48">
                    <div className="relative flex flex-col items-center  px-4 py-12 bg-white rounded-lg w-96 md:w-auto dark:bg-white md:px-24 xl:py-10 ">
                        <div className="w-full text-left my-3">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl font-bold ">
                                Agregar servicio
                            </header>
                        </div>
                        <div className="w-full text-left my-1">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base md:text-xl">
                                Nombre del servicio
                            </header>
                        </div>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <input
                                onChange={(e) =>
                                    setAttributes({
                                        ...attributes,
                                        nombre: e.target.value,
                                    })
                                }
                                type="text"
                                value={attributes.nombre}
                                placeholder="Ej: Horno de barro"
                                className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C]"
                            />
                        </div>
                        <div className="w-full text-left mt-4">
                            <header className=" pl-2 text-[#3C3C3C]  text-base md:text-xl">
                                Icono del servicio
                            </header>
                        </div>
                        <div className="w-full ">
                            <div className=" mx-auto overflow-hidden bg-white rounded-lg ">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <div className="py-3">
                                            <div className="mb-2">
                                                <div className="relative flex items-center justify-center h-40 bg-white border border-[#E9E9E9] rounded-lg hover:cursor-pointer ">
                                                    <div className="absolute">
                                                        <div className="flex flex-col items-center ">
                                                            <i className="text-gray-200 fa fa-cloud-upload fa-3x"></i>{" "}
                                                            {attributes.image ===
                                                            "" ? (
                                                                <div>
                                                                    <span className="block font-bold text-[#3C3C3C]">
                                                                        Arrastra
                                                                        el icono
                                                                        aquí
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <span className="block font-normal text-gray-400">
                                                                    {
                                                                        attributes
                                                                            .image
                                                                            .name
                                                                    }
                                                                </span>
                                                            )}
                                                            <span className="block font-normal text-blue-bluecorner mt-3">
                                                                sube la imagen
                                                                desde tu
                                                                dispositivo
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <input
                                                        accept="image/*"
                                                        type="file"
                                                        className="w-full h-full opacity-0 cursor-pointer"
                                                        onChange={
                                                            handleChangeImage
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        attributes.image === ""
                                                            ? "hidden"
                                                            : "flex items-center justify-center"
                                                    }>
                                                    <img
                                                        id="output"
                                                        className="w-12 h-12 mt-2 border border-blue-500"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pb-3 text-center ">
                                            <button
                                                onClick={() => handleSubmit()}
                                                disabled={cargando}
                                                className="text-center border-blue-400 bg-blue-bluecorner text-white w-full  border hover:bg-white hover:text-blue-bluecorner p-3 rounded-md disabled:bg-blue-400">
                                                {cargando
                                                    ? "Creando..."
                                                    : "Crear"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => setcreate(!create)}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreateAttributes;
