import React, { useState } from "react";
import axios from "axios";
import { readFile, utils } from "xlsx";
import excel_empleados from "../../../assets/excels/plantilla_carga_empleados.xlsx";
import Swal from "sweetalert2";
import ModalEmpleadosFallidos from "./ModalEmpleadosFallidos";
import { useSelector } from "react-redux";

const ModalCargaMasiva = ({
    modalVisible,
    setModalVisible,
    idEmpresa = null,
    setfakeRefresh,
}) => {
    const [modalFallidos, setModalFallidos] = useState(false);
    const [empleadosCargados, setEmpleadosCargados] = useState([]);
    const [empleadosFallidos, setEmpleadosFallidos] = useState([]);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const { id_empresa } = useSelector((state) => state.authReducer);

    const getEmpleadosFromExcel = async (excelEmpleados) => {
        const totalColumns = excelEmpleados[0].length;
        const arrayEmpleados = [];
        console.log(totalColumns);
        if (
            totalColumns !== 7 ||
            !excelEmpleados[0].includes("nombre") ||
            !excelEmpleados[0].includes("apellido_paterno") ||
            !excelEmpleados[0].includes("apellido_materno") ||
            !excelEmpleados[0].includes("correo") ||
            !excelEmpleados[0].includes("tipo_documentacion") ||
            !excelEmpleados[0].includes("dni") ||
            !excelEmpleados[0].includes("celular")
        ) {
            Swal.fire({
                title: "El excel ingresado no coincide con la plantilla especificada",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            excelEmpleados.forEach((empleado, index) => {
                if (index > 0) {
                    const newEmpleado = {
                        fila: index + 1,
                    };
                    for (let i = 0; i < totalColumns; i++) {
                        newEmpleado[excelEmpleados[0][i]] = empleado[i] || null;
                    }
                    arrayEmpleados.push(newEmpleado);
                }
            });
        }

        return arrayEmpleados;
    };

    const handleInputChange = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            console.log("No se cargo un archivo");
        } else {
            const file = e.target.files[0];
            const fileBuffer = await file.arrayBuffer();
            const EXCEL = readFile(fileBuffer);
            const empleadosExcel = utils.sheet_to_json(EXCEL.Sheets["Hoja1"], {
                header: 1,
            });

            if (empleadosExcel.length === 0) {
                return Swal.fire({
                    title: "Por favor utilice el mismo excel con la plantilla especificada",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
            const arrayEmpleados = await getEmpleadosFromExcel(empleadosExcel);

            console.log({
                arrayEmpleados,
                id_empresa: idEmpresa || id_empresa,
            });
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/empleadoEmpresas/cargaMasiva`,
                { arrayEmpleados, id_empresa: idEmpresa || id_empresa },
                {
                    headers,
                }
            );
            console.log(data);
            const { empleados_exitosos, empleados_fallidos } = data;

            console.log(data);
            if (data) {
                setfakeRefresh((prev) => !prev);
                setEmpleadosFallidos(empleados_fallidos);
                setEmpleadosCargados(empleados_exitosos);
                setModalFallidos(!modalFallidos);
            }
        }
    };

    const handleClose = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}
        >
            <div className="fixed bottom-0 left-0 w-full z-20 h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 2xl:container 2xl:mx-auto md:px-28 py-24  ">
                    <div className="relative w-full block justify-center px-4 bg-white rounded-lg  md:w-auto dark:bg-white md:px-14 h-[calc(100vh-10vh)] overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-3 right-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                        >
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mt-20 text-lg font-medium">
                            Carga masiva de empleados
                        </header>
                        <div className="mt-4 flex flex-col">
                            <span className="max-w-xs">
                                Antes de importar un excel, asegurate que cumpla
                                nuestros requisitos de plantilla
                            </span>
                            <button className="mt-4 h-12 relative md:h-auto justify-center max-w-[180px] items-center flex p-3 text-gray-600 border border-gray-400 rounded-lg hover:scale-105">
                                <a href={excel_empleados}>
                                    Descargar plantilla
                                </a>
                            </button>
                            <span className="max-w-xs mt-4">
                                Si ya descargaste y completaste la plantilla,
                                importala aqui debajo.
                            </span>
                        </div>
                        <div className="mt-4 hover:scale-105">
                            <button className="h-12 relative md:h-auto items-center justify-center flex p-3 text-blue-500 border border-blue-500 rounded-lg">
                                <span>Importar desde excel</span>
                                <input
                                    onChange={handleInputChange}
                                    type="file"
                                    name="excel"
                                    className="bg-red-50 w-full h-full absolute left-0 top-0 opacity-0 cursor-pointer file:cursor-pointer"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                {modalFallidos && (
                    <ModalEmpleadosFallidos
                        empleadosCargados={empleadosCargados}
                        empleadosFallidos={empleadosFallidos}
                        modalVisible={modalFallidos}
                        setModalVisible={setModalFallidos}
                    />
                )}
            </div>
        </div>
    );
};

export default ModalCargaMasiva;
