import React, { useState, useEffect, useRef } from "react";

import ButtonCopyToClipboard from "../ButtonCopyToClipboard";

const LinkEmpresa = ({ empresa, setLinkEmpresa }) => {
    console.log(empresa);
    const handleClose = () => {
        setLinkEmpresa(null);
    };

    return (
        <div className={`relative items-center justify-center `}>
            <div className="fixed top-0 left-0 z-10 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-20 sm:py-32 ">
                    <div className="relative w-full min-w-[300px] block justify-center  px-4 bg-white rounded-lg  xs:w-auto dark:bg-white md:px-10  h-80 py-10 overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-4 right-4 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>

                        <div className="w-full my-3 text-left">
                            <header className="mb-3 ml-1 mr-1 text-[#3C3C3C]  text-base  font-bold ">
                                Link de acceso
                            </header>
                            <header className="mb-3  text-[#3C3C3C]   ml-1 mr-1 font-semibold ">
                                {empresa.nombre}
                            </header>
                        </div>

                        <div className="flex items-center justify-between p-2 mx-auto mt-4 text-gray-600 border rounded-lg w-72">
                            <span>{`https://www.bluecorner.com.pe/my-account/${
                                empresa.id
                            }/${empresa.nombre.replace(/ /g, "-")}`}</span>

                            <ButtonCopyToClipboard
                                confirmationText="Link copiado al portapapeles correctamente"
                                textToCopy={`https://www.bluecorner.com.pe/my-account/${
                                    empresa.id
                                }/${empresa.nombre.replace(/ /g, "-")}`}
                            />
                        </div>

                        {/* <button
                            className="w-full py-3 mt-4 text-center text-white border border-blue-400 rounded-md bg-blue-bluecorner hover:bg-white hover:text-blue-bluecorner disabled:bg-blue-400"
                            onClick={(e) => handleCreate(e)}
                            disabled={!siguiente}>
                            {siguiente ? siguiente : <Spinner width={7} />}
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkEmpresa;
