import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import { ShowStarsRating } from "../StarsRating";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { normalizeString } from "../helpers/normalizeString";

const CardHouses = ({ house, tipo, eliminarFavorito }) => {
    const navigate = useNavigate();

    return (
        <div className="relative mt-10 shadow-xl select-none rounded-xl ">
            <div className="relative w-full  h-[250px] flex">
                <Swiper
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    rewind={true}
                    loop={true}>
                    <SwiperSlide
                        className="cursor-pointer"
                        onClick={() =>
                            navigate(
                                `/${
                                    tipo === "embarcacion"
                                        ? "embarcacion"
                                        : "alojamientos"
                                }/${house.id}/${normalizeString(house.nombre)}`
                            )
                        }>
                        <div className="h-full ">
                            <img
                                src={`${process.env.REACT_APP_PROXY_ARCHIVOS}${
                                    tipo === "embarcacion"
                                        ? "/bluecorner/v1/embarcacion/getImagenPrincipal/"
                                        : "/bluecorner/v1/casa/getImagenPrincipal/"
                                }${house.imagen_principal}`}
                                alt="Imagen del houseo"
                                className="object-cover w-full h-full rounded-xl"
                            />
                        </div>
                    </SwiperSlide>
                    {house.imagenes.map((imagen) => (
                        <SwiperSlide key={imagen.id} className="cursor-pointer">
                            <div
                                onClick={() =>
                                    navigate(
                                        `/${
                                            tipo === "embarcacion"
                                                ? "embarcacion"
                                                : "alojamientos"
                                        }/${house.id}/${normalizeString(
                                            house.nombre
                                        )}`
                                    )
                                }
                                className="h-full ">
                                <img
                                    alt="Imagen no encontrada"
                                    src={`${
                                        process.env.REACT_APP_PROXY_ARCHIVOS
                                    }${
                                        tipo === "embarcacion"
                                            ? "/bluecorner/v1/embarcacionImagenes/getImagen/"
                                            : "/bluecorner/v1/productoImagenes/getImagen/"
                                    }${imagen.imagen}`}
                                    className="object-cover w-full h-full rounded-xl"
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="absolute bottom-0 left-0 z-10 flex items-center w-full h-20 p-5 2xl:text-2xl text-lg font-bold text-white bg-black bg-opacity-50">
                    <span>
                        {`Desde ${house.precio_base} ${
                            house.moneda_precio_base || house.tipo_moneda
                        }`}
                    </span>
                    <span
                        className={
                            tipo === "embarcacion"
                                ? "hidden"
                                : "mx-2 mt-1 text-base font-medium"
                        }>
                        por noche
                    </span>
                </div>
            </div>
            <div className="p-4">
                <ShowStarsRating starsQuantity={5} width={6} />
                <div className="mt-3 2xl:text-2xl text-lg text-[#053465] font-bold  flex justify-between items-center">
                    <span
                        className="cursor-pointer"
                        onClick={() =>
                            navigate(
                                `/${
                                    tipo === "embarcacion"
                                        ? "embarcacion"
                                        : "alojamientos"
                                }/${house.id}/${normalizeString(house.nombre)}`
                            )
                        }>
                        {house.nombre}
                    </span>
                </div>
                <div className="mt-3 mb-7 text-base text-gray-500">
                    {house.direccion_calle} {house.direccion_numero},{" "}
                    {house.ciudad}
                </div>
                <div
                    className={
                        tipo === "embarcacion"
                            ? "hidden"
                            : "absolute bottom-1 left-4 text-amber-600"
                    }>
                    <span className="mr-2">
                        {house.numero_personas} personas
                    </span>
                    <span className="mx-2">
                        {house.numero_habitaciones} habitaciones
                    </span>
                    <span className="mx-2">{house.numero_banos} baños</span>
                </div>
            </div>
        </div>
    );
};

export default CardHouses;
