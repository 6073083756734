import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchBar from "./SearchBar";
import { Paginado } from "../helpers/Paginado";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";
import ToggleButton from "../ToggleButton";

const Disponibilidad = () => {
    const [cargando, setCargando] = useState(true);
    const [nombre, setNombre] = useState("");
    const [total, setTotal] = useState(0);
    const [pagina, setpagina] = useState(1);
    const [page, setPage] = useState(new Date().getMonth() + 1);
    const [prev, setPrev] = useState(0);
    const [pageAño, setPageAño] = useState(new Date().getFullYear());
    const [mesArray, setMesArray] = useState([]);
    const [disponibilidadCasas, setDisponibilidadCasas] = useState([]);
    const LABELS_PER_PAGE = 25;
    const authUser = useSelector((state) => state.authReducer);
    const [select, setSelect] = useState("");
    const [horarios, setHorarios] = useState([]);
    const [tipo, setTipo] = useState(
        authUser.tipo_usuario > 2 && authUser.casas > 0
            ? "casas"
            : authUser.tipo_usuario > 2 && authUser.embarcaciones > 0
            ? "embarcaciones"
            : "casas"
    );
    const [modalHabilitado, setModalHabilitado] = useState(false);
    const [toggleButton, setToggleButton] = useState(false);
    const [disponibilidadEmbarcaciones, setDisponibilidadEmbarcaciones] =
        useState([]);

    let hoy = new Date();

    let diasMes = new Date(pageAño, page, 0).getDate();
    let mesArrayBucle = [];
    let mesLetra = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];
    const [personas, setPersonas] = useState("Seleccionar");

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const getAllDisponibilidadCasas = async (propietario) => {
        setCargando(true);

        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/casa/getCasasDisponibilidad/`,
            propietario,
            { headers }
        );

        setDisponibilidadCasas(json.data.data);
        setTotal(json.data.total);
        setTimeout(() => {
            setCargando(false);
        }, 2500);
    };

    const getAllDuracion = async (proietario) => {
        const jsonembar = await axios.get(
            `${process.env.REACT_APP_PROXY}/propiedades/all/0/si/duracion`,
            { headers }
        );

        setHorarios(jsonembar.data.data);
        setSelect(jsonembar.data.data[0]);
    };
    const getAllDisponibilidadEmbarcaciones = async (propietario) => {
        const jsonembar = await axios.post(
            `${process.env.REACT_APP_PROXY}/embarcacion/allByDuracion/`,
            propietario,
            { headers }
        );

        setDisponibilidadEmbarcaciones(jsonembar.data.data);
        setTotal(jsonembar.data.total);
        setTimeout(() => {
            setCargando(false);
        }, 2000);
    };
    useEffect(() => {
        setCargando(true);
        getAllDisponibilidadEmbarcaciones({
            pagina: 1,
            id_duracion: select.id,
            nombre: nombre,
            id_usuario:
                authUser.tipo_usuario === 3
                    ? authUser.id_usuario
                    : authUser.tipo_usuario === 4
                    ? authUser.id_propietario
                    : 0,
        });
    }, [select]);

    useEffect(() => {
        if (tipo === "embarcaciones") {
            getAllDuracion();
        }
        if (tipo === "casas") {
            getAllDisponibilidadCasas({
                pagina: 1,
                nombre: nombre,
                id_usuario:
                    authUser.tipo_usuario === 3
                        ? authUser.id_usuario
                        : authUser.tipo_usuario === 4
                        ? authUser.id_propietario
                        : 0,
                top: toggleButton ? "Si" : "No",
                personas: personas === "Seleccionar" ? 0 : personas,
            });
        }
    }, [tipo, toggleButton, personas]);

    useEffect(() => {
        for (let i = 1; i < diasMes + 1; i++) {
            mesArrayBucle.push(i);
        }
        setMesArray(mesArrayBucle);
    }, [page]);

    const colorDia = (disponibilidad, diaDelMes, pedidos = []) => {
        let dias = disponibilidad.arrayDias;
        let fechas = disponibilidad.arrayFechas;
        let meses = disponibilidad.arrayMeses;
        let diaDate = new Date(`${page}/${diaDelMes}/${pageAño}`).getDay();
        let diaDeSemana = dias?.indexOf(diaDate);
        let diaPedido = `${pageAño}-${page < 10 ? `0${page}` : page}-${
            diaDelMes < 10 ? `0${diaDelMes}` : diaDelMes
        }`;

        if (
            dias.length === 0 &&
            fechas.length === 0 &&
            meses.length === 0 &&
            pedidos.length === 0
        ) {
            return "white";
        } else if (
            fechas.find(
                (fecha) =>
                    fecha.substring(0, 10) ===
                    `${pageAño}-${page < 10 ? `0${page}` : page}-${diaDelMes}`
            )
        ) {
            // console.log("entro");
            return "bg-[#E9E9E9]";
        } else if (pedidos.indexOf(diaPedido) !== -1) {
            return "bg-blue-bluecorner";
        } else if (meses.indexOf(page) !== -1) {
            return "bg-[#E9E9E9]";
        } else if (diaDeSemana !== -1) {
            return "bg-[#E9E9E9]";
        } else {
            return "white";
        }
    };

    const pagination = (pageNumber) => {
        // setLoading(true);
        setpagina(pageNumber);
        if (tipo === "embarcaciones") {
            getAllDisponibilidadEmbarcaciones({
                pagina: pageNumber,
                id_duracion: select.id,
                nombre: nombre,
                id_usuario:
                    authUser.tipo_usuario === 3
                        ? authUser.id_usuario
                        : authUser.tipo_usuario === 4
                        ? authUser.id_propietario
                        : 0,
            });
        }
        if (tipo === "casas") {
            setCargando(true);
            getAllDisponibilidadCasas({
                pagina: pageNumber,
                nombre: nombre,
                id_usuario:
                    authUser.tipo_usuario === 3
                        ? authUser.id_usuario
                        : authUser.tipo_usuario === 4
                        ? authUser.id_propietario
                        : 0,
            });
        }
    };

    return (
        <div className=" w-full md:pl-[12rem] md818:pl-[11rem] md920:pl-[11rem] lg:pl-[15rem] lg1170:pl-[16rem]  xl:pl-[16rem] ">
            <div className="flex items-center justify-between w-[95%] mx-auto mt-2">
                <h1 className="text-4xl  py-10 text-center text-[#3C3C3C]">
                    Disponibilidad
                </h1>
            </div>
            {cargando ? (
                <Spinner width={20} />
            ) : (
                <div className="mb-3 ">
                    <div
                        className={
                            authUser.tipo_usuario > 2 &&
                            authUser.casas === 0 &&
                            authUser.embarcaciones === 0
                                ? "hidden"
                                : "grid grid-cols-1 justify-items-center lg:justify-items-stretch lg:grid-cols-2  w-[95%] mx-auto"
                        }>
                        <div className="flex mb-5 lg:mb-0">
                            <button
                                className={
                                    authUser.tipo_usuario > 2 &&
                                    authUser.casas === 0
                                        ? "hidden"
                                        : `${
                                              tipo !== "casas"
                                                  ? "p-3 text-blue-500 border-2 border-blue-500 px-6 w-[156px] rounded-lg hover:bg-white hover:text-blue-500"
                                                  : "p-3 px-6 w-[156px]  text-white bg-blue-500 border-2 rounded-lg border-blue-500"
                                          }`
                                }
                                onClick={() => (
                                    setCargando(true), setTipo("casas")
                                )}>
                                Alojamientos
                            </button>
                            <button
                                className={
                                    authUser.tipo_usuario > 2 &&
                                    authUser.embarcaciones === 0
                                        ? "hidden"
                                        : `${
                                              tipo !== "embarcaciones"
                                                  ? "p-3 ml-2 text-blue-500 border-2 border-blue-500 px-6 w-[156px] rounded-lg hover:bg-white hover:text-blue-500"
                                                  : "p-3 px-6 w-[156px] text-white bg-blue-500 border-2 rounded-lg ml-2 border-blue-500"
                                          }`
                                }
                                onClick={() => (
                                    setCargando(true), setTipo("embarcaciones")
                                )}>
                                Embarcaciones
                            </button>
                        </div>
                        <div
                            className={
                                tipo === "casas"
                                    ? "flex items-center justify-center lg:justify-end "
                                    : "flex items-center justify-end "
                            }>
                            <SearchBar
                                select={select}
                                tipo={tipo}
                                getAllDisponibilidadEmbarcaciones={
                                    getAllDisponibilidadEmbarcaciones
                                }
                                getAllDisponibilidadCasas={
                                    getAllDisponibilidadCasas
                                }
                                pagina={pagina}
                                nombre={nombre}
                                setNombre={setNombre}
                                id={
                                    authUser.tipo_usuario === 3
                                        ? authUser.id_usuario
                                        : authUser.tipo_usuario === 4
                                        ? authUser.id_propietario
                                        : 0
                                }
                            />

                            <div
                                onMouseOut={() => setModalHabilitado(false)}
                                onMouseOver={() => setModalHabilitado(true)}
                                className={
                                    tipo === "casas"
                                        ? "hidden"
                                        : "relative ml-5"
                                }>
                                <button className="w-8 h-8 xs:w-[51px] xs:h-[51px] flex items-center justify-center px-1 py-1  text-blue-700 border-2 border-blue-500 rounded-lg group hover:bg-blue-700 hover:text-white">
                                    <svg
                                        className="w-6 h-6 text-blue-500 xs:w-8 xs:h-8 group-hover:text-white"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                        />
                                    </svg>
                                </button>
                                <div
                                    className={
                                        modalHabilitado
                                            ? "absolute top-8 xs:top-[50px]  w-36 z-10  pt-2 right-0 "
                                            : "hidden"
                                    }>
                                    <div
                                        className={
                                            modalHabilitado
                                                ? "block bg-white  top-16 md723:top-20 py-2 rounded-lg shadow-md"
                                                : "hidden"
                                        }>
                                        {horarios?.map((poli, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    onClick={() => {
                                                        setSelect(poli);
                                                        setModalHabilitado(
                                                            false
                                                        );
                                                    }}
                                                    className={
                                                        select === poli ||
                                                        tipo === "casas"
                                                            ? "hidden"
                                                            : "w-32 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start pl-2"
                                                    }>
                                                    <p>{poli.nombre}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className={
                                tipo === "casas"
                                    ? "grid grid-cols-1 justify-items-center md:justify-items-end   w-[95%] mx-auto mt-4"
                                    : "hidden"
                            }>
                            <div className="flex items-center">
                                <p className="mr-2">Casas top</p>
                                <ToggleButton
                                    isToggled={toggleButton}
                                    onChangeToggle={(e) => setToggleButton(e)}
                                />
                            </div>
                            <div className="flex items-center mt-4">
                                <p className="mr-2">Personas</p>
                                <select
                                    value={personas}
                                    onChange={(e) =>
                                        setPersonas(e.target.value)
                                    }
                                    className="px-2 py-1 border rounded-md">
                                    <option value={"Seleccionar"}>
                                        Seleccionar
                                    </option>
                                    {Array.from(
                                        { length: 100 },
                                        (_, index) => index + 1
                                    ).map((number) => (
                                        <option key={number} value={number}>
                                            {number}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-7 font-semibold text-lg lg:text-3xl md:text-2xl w-[95%] mx-auto py-2   bg-[#053465] text-white rounded-lg">
                        {tipo === "casas"
                            ? mesLetra[page - 1]
                            : mesLetra[page - 1] + " - " + select?.nombre}
                    </div>

                    <div>
                        <nav className="container flex justify-between  w-[95%] mx-auto select-none mb-2">
                            <div className="flex justify-between max-w-3xl pl-2 mt-8 cursor-pointer">
                                <ul className="inline-flex ">
                                    <li
                                        onClick={() => {
                                            if (prev !== 0) {
                                                if (page > 1) {
                                                    setPage(page - 1);
                                                    setPrev(prev - 1);
                                                } else {
                                                    setPage(12);
                                                    setPageAño(pageAño - 1);
                                                    setPrev(prev - 1);
                                                }
                                            }
                                        }}
                                        className="flex items-center hover:bg-slate-100">
                                        <svg
                                            className="h-8 w-8 p-0.5 text-blue-bluecorner border border-blue-bluecorner mx-1 rounded-lg"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <polyline points="15 18 9 12 15 6" />
                                        </svg>
                                    </li>

                                    <li
                                        className={`bg-blue-bluecorner text-white font-semibold border-blue-bluecorner hover:border-gray-200 px-3 text-lg border rounded-lg 
                                               
                                        `}
                                        onClick={() => {
                                            setPage(new Date().getMonth() + 1);
                                            setPageAño(
                                                new Date().getFullYear()
                                            );
                                            setPrev(0);
                                        }}>
                                        <span>{"Hoy"} </span>
                                    </li>

                                    <li
                                        onClick={() => {
                                            if (page < 12) {
                                                setPage(page + 1);
                                                setPrev(prev + 1);
                                            } else {
                                                setPage(1);
                                                setPageAño(pageAño + 1);
                                                setPrev(prev + 1);
                                            }
                                        }}
                                        className="flex items-center hover:bg-slate-100">
                                        <svg
                                            className="h-8 w-8 p-0.5 text-blue-bluecorner border rounded-lg border-blue-bluecorner mx-1"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            {" "}
                                            <polyline points="9 18 15 12 9 6" />
                                        </svg>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div
                        className={`container flex items-center ${
                            total < 25 ? "justify-center" : "justify-between"
                        }  w-[95%] mx-auto`}></div>
                    <div className="overflow-x-auto w-[95%] mx-auto">
                        <table className="w-full table-auto ">
                            <thead>
                                <tr className="text-center ">
                                    <th className=" min-w-[150px]  text-sm font-bold border border-[#E9E9E9] py-2 px-10 bg-[#053465] text-white">
                                        {tipo === "casas"
                                            ? "Alojamientos"
                                            : "Embarcaciones"}
                                    </th>

                                    {mesArray?.map((dia, i) => (
                                        <th
                                            className={`text-sm font-normal text-[#3C3C3C] border-y border-r border-[#E9E9E9] p-2 ${
                                                hoy.getDate() === dia &&
                                                page === hoy.getMonth() + 1 &&
                                                pageAño === hoy.getFullYear()
                                                    ? "bg-[#F7DC6F]"
                                                    : "bg-white"
                                            }`}
                                            key={i}>
                                            {dia < 10 ? `0${dia}` : dia}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            {tipo === "casas" ? (
                                <tbody>
                                    {disponibilidadCasas?.map((casa, i) => (
                                        <tr key={i}>
                                            <td className="px-2 py-2 text-sm  border-b border-x border-[#E9E9E9] flex flex-col ">
                                                {casa.nombre}
                                                <span className="text-xs text-gray-400 ">
                                                    Personas max:{" "}
                                                    {casa.numero_personas}
                                                </span>
                                            </td>
                                            {mesArray?.map((dia, i) => (
                                                <td
                                                    className={`text-sm  border-y border-r p-2 border-[#E9E9E9] ${
                                                        colorDia(
                                                            casa.disponibilidad,
                                                            dia,
                                                            casa.pedidos
                                                        ) === "white"
                                                            ? hoy.getDate() ===
                                                                  dia &&
                                                              page ===
                                                                  hoy.getMonth() +
                                                                      1 &&
                                                              pageAño ===
                                                                  hoy.getFullYear()
                                                                ? "bg-[#F7DC6F]"
                                                                : `bg-white`
                                                            : colorDia(
                                                                  casa.disponibilidad,
                                                                  dia,
                                                                  casa.pedidos
                                                              )
                                                    }
                                            }`}
                                                    key={i}></td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    {disponibilidadEmbarcaciones?.map(
                                        (embarcacion, i) => (
                                            <>
                                                <tr key={i}>
                                                    <td
                                                        className={`text-sm  border-x border-b border-[#E9E9E9] bg-white font-bold px-2`}>
                                                        {embarcacion.nombre}
                                                    </td>
                                                    {mesArray?.map((dia, i) => (
                                                        <td
                                                            className={`text-sm   p-2 ${
                                                                i ===
                                                                    mesArray.length -
                                                                        1 &&
                                                                "border-r border-[#E9E9E9] "
                                                            } ${
                                                                hoy.getDate() ===
                                                                    dia &&
                                                                page ===
                                                                    hoy.getMonth() +
                                                                        1 &&
                                                                pageAño ===
                                                                    hoy.getFullYear()
                                                                    ? "bg-[#F7DC6F]"
                                                                    : "bg-white "
                                                            }
                                            }`}
                                                            key={i}></td>
                                                    ))}
                                                </tr>
                                                {embarcacion.turno?.map(
                                                    (turno, i) => (
                                                        <tr key={i}>
                                                            <td className="px-2 py-2 text-sm border-[#E9E9E9] border-b border-x">
                                                                Turno {i + 1}
                                                            </td>
                                                            {mesArray?.map(
                                                                (dia, i) => (
                                                                    <td
                                                                        className={`text-sm border-[#E9E9E9] border-y border-r p-2 ${
                                                                            colorDia(
                                                                                turno.disponibilidad,
                                                                                dia,
                                                                                turno.pedidos
                                                                            ) ===
                                                                            "white"
                                                                                ? hoy.getDate() ===
                                                                                      dia &&
                                                                                  page ===
                                                                                      hoy.getMonth() +
                                                                                          1 &&
                                                                                  pageAño ===
                                                                                      hoy.getFullYear()
                                                                                    ? "bg-[#F7DC6F]"
                                                                                    : `bg-white`
                                                                                : colorDia(
                                                                                      turno.disponibilidad,
                                                                                      dia,
                                                                                      turno.pedidos
                                                                                  )
                                                                        }
                                            }`}
                                                                        key={
                                                                            i
                                                                        }></td>
                                                                )
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </>
                                        )
                                    )}
                                </tbody>
                            )}
                        </table>
                    </div>
                    <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                    <div className="container flex items-center justify-between p-1 mx-auto">
                        <Paginado
                            elementsPerPage={LABELS_PER_PAGE}
                            cantTotalElements={total}
                            pagination={pagination}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Disponibilidad;
