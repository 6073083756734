const dividirFechaDatePicker = (fecha) => {
    let fechaArray = fecha.split("-");
    let fechaArray2 = fecha.split("/");

    let arrayNumeros = [];
    if (fechaArray.length > 1) {
        fechaArray.map((n) => arrayNumeros.push(Number(n)));
    } else {
        fechaArray2.map((n) => arrayNumeros.push(Number(n)));
    }

    return arrayNumeros;
};

export default dividirFechaDatePicker;
