import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import React, { useState, useEffect } from "react";
import dividirFechaDatePicker from "../../helpers/dividirFechaDatePicker";
import axios from "axios";
import Swal from "sweetalert2";
import { ModalAgregarCasa } from "./ModalAgregarCasa";

export const ModalAdmin = ({
    toggleButton,
    editar,
    setEditar,
    modalAgregar,
    setModalAgregar,
}) => {
    registerLocale("es", es);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const [input, setInput] = useState({
        fecha_inicio: "",
        fecha_final: "",
    });

    const [grupos, setGrupos] = useState([]);

    useEffect(() => {
        getGrupos();
    }, []);

    const getGrupos = async () => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/gruposBlueSale/all`,
            { headers }
        );

        setGrupos(json.data.data);
    };
    const eliminarGrupo = async (id) => {
        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/gruposBlueSale/eliminar/${id}`,
            { headers }
        );

        getGrupos();
    };

    const crearGrupo = async () => {
        if (input.fecha_inicio === "" || input.fecha_final === "") {
            Swal.fire({
                title: "Debes seleccionar una fecha de inicio y una final",
                icon: "warning",
                confirmButtonText: "Ok",
            });
            return;
        }
        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/gruposBlueSale/`,
            {
                fecha_inicio: input.fecha_inicio,
                fecha_final: input.fecha_final,
            },
            { headers }
        );

        setInput({
            fecha_inicio: "",
            fecha_final: "",
        });
        getGrupos();
    };

    const actualizarGrupo = async (id) => {
        if (editar.fecha_inicio === "" || editar.fecha_final === "") {
            Swal.fire({
                title: "Debes seleccionar una fecha de inicio y una final",
                icon: "warning",
                confirmButtonText: "Ok",
            });
            return;
        }
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/gruposBlueSale/update/${id}`,
            {
                fecha_inicio: editar.fecha_inicio,
                fecha_final: editar.fecha_final,
            },
            { headers }
        );

        setEditar({
            editar: false,
            id: null,
            fecha_inicio: "",
            fecha_final: "",
        });
        getGrupos();
    };

    const agregarCasas = async (id, inicio, final) => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/casaBlueSale/all/${id}`,
            { headers }
        );

        setModalAgregar({
            activo: true,
            id_grupo: id,
            fecha_inicio: inicio,
            fecha_final: final,
        });
    };
    const transformarFecha = (fecha) => {
        let fechaDividida = fecha.split("/");

        return `${fechaDividida[2]}-${fechaDividida[1]}-${fechaDividida[0]}`;
    };

    return (
        <div
            className={`${
                !toggleButton ? "hidden" : "flex"
            } transition-all ease-out duration-500 flex-col  left-0 top-0 z-[999]  w-full items-center  border-b pb-4`}>
            {modalAgregar.activo && (
                <ModalAgregarCasa
                    setModalAgregar={setModalAgregar}
                    modalAgregar={modalAgregar}
                />
            )}
            <div className="flex flex-col w-full h-full">
                <div className="relative grid sm:w-11/12 grid-cols-[40%,40%,20%] gap-2  mt-2 mx-auto">
                    <div className="flex flex-col ">
                        <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                            Fecha inicial:
                        </label>
                        <DatePicker
                            className=" w-full p-2 mx-auto rounded-lg cursor-pointer  border-[#e5e7eb] border"
                            placeholderText="Fecha inicial"
                            locale="es"
                            autoComplete="off"
                            dateFormat="dd/MM/yyyy"
                            selected={
                                input.fecha_inicio !== ""
                                    ? new Date(
                                          dividirFechaDatePicker(
                                              input.fecha_inicio
                                          )[0],
                                          dividirFechaDatePicker(
                                              input.fecha_inicio
                                          )[1] - 1,
                                          dividirFechaDatePicker(
                                              input.fecha_inicio
                                          )[2]
                                      )
                                    : input.fecha_inicio
                            }
                            onChange={(e) =>
                                setInput({
                                    ...input,
                                    fecha_inicio: `${e.getFullYear()}-${`${
                                        e.getMonth() + 1
                                    }`.padStart(
                                        2,
                                        "0"
                                    )}-${`${e.getDate()}`.padStart(2, "0")}`,
                                })
                            }
                        />
                    </div>

                    <div className="flex flex-col ">
                        <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                            Fecha final:
                        </label>
                        <DatePicker
                            className="w-full p-2 mx-auto rounded-lg cursor-pointer  border-[#e5e7eb] border "
                            placeholderText="Fecha final"
                            locale="es"
                            autoComplete="off"
                            dateFormat="dd/MM/yyyy"
                            selected={
                                input.fecha_final !== ""
                                    ? new Date(
                                          dividirFechaDatePicker(
                                              input.fecha_final
                                          )[0],
                                          dividirFechaDatePicker(
                                              input.fecha_final
                                          )[1] - 1,
                                          dividirFechaDatePicker(
                                              input.fecha_final
                                          )[2]
                                      )
                                    : input.fecha_final
                            }
                            onChange={(e) =>
                                setInput({
                                    ...input,
                                    fecha_final: `${e.getFullYear()}-${`${
                                        e.getMonth() + 1
                                    }`.padStart(
                                        2,
                                        "0"
                                    )}-${`${e.getDate()}`.padStart(2, "0")}`,
                                })
                            }
                        />
                    </div>
                    <div
                        onClick={() => crearGrupo()}
                        className="flex items-end ">
                        <p className="w-full p-2 text-center text-white bg-blue-500 border border-blue-500 rounded-lg cursor-pointer hover:bg-blue-400">
                            Crear
                        </p>
                    </div>
                </div>
                {grupos?.length > 0 && (
                    <div
                        className={`sm:w-1/2 w-11/12 max-h-72 mt-2 overflow-auto mx-auto`}>
                        {grupos?.map((gropo) => (
                            <div
                                key={gropo.id}
                                // onClick={() => handleFieldClick(id)}
                                className="flex items-center justify-between p-1 mt-1 text-gray-400 bg-white border rounded-lg ">
                                <div>
                                    {editar.editar && editar.id === gropo.id ? (
                                        <div className="flex items-center">
                                            <p className="mr-1 text-sm sm:text-base">
                                                Del
                                            </p>{" "}
                                            <DatePicker
                                                className=" px-1  rounded-lg cursor-pointer  border-[#e5e7eb] border max-w-[92px] "
                                                placeholderText="Fecha final"
                                                locale="es"
                                                autoComplete="off"
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    editar.fecha_inicio !== ""
                                                        ? new Date(
                                                              dividirFechaDatePicker(
                                                                  editar.fecha_inicio
                                                              )[0],
                                                              dividirFechaDatePicker(
                                                                  editar.fecha_inicio
                                                              )[1] - 1,
                                                              dividirFechaDatePicker(
                                                                  editar.fecha_inicio
                                                              )[2]
                                                          )
                                                        : editar.fecha_inicio
                                                }
                                                onChange={(e) =>
                                                    setEditar({
                                                        ...editar,
                                                        fecha_inicio: `${e.getFullYear()}-${`${
                                                            e.getMonth() + 1
                                                        }`.padStart(
                                                            2,
                                                            "0"
                                                        )}-${`${e.getDate()}`.padStart(
                                                            2,
                                                            "0"
                                                        )}`,
                                                    })
                                                }
                                            />{" "}
                                            <p className="mx-1 text-sm sm:text-base">
                                                al
                                            </p>{" "}
                                            <DatePicker
                                                className="px-1  rounded-lg cursor-pointer  border-[#e5e7eb] border max-w-[92px] "
                                                placeholderText="Fecha final"
                                                locale="es"
                                                autoComplete="off"
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    editar.fecha_final !== ""
                                                        ? new Date(
                                                              dividirFechaDatePicker(
                                                                  editar.fecha_final
                                                              )[0],
                                                              dividirFechaDatePicker(
                                                                  editar.fecha_final
                                                              )[1] - 1,
                                                              dividirFechaDatePicker(
                                                                  editar.fecha_final
                                                              )[2]
                                                          )
                                                        : editar.fecha_final
                                                }
                                                onChange={(e) =>
                                                    setEditar({
                                                        ...editar,
                                                        fecha_final: `${e.getFullYear()}-${`${
                                                            e.getMonth() + 1
                                                        }`.padStart(
                                                            2,
                                                            "0"
                                                        )}-${`${e.getDate()}`.padStart(
                                                            2,
                                                            "0"
                                                        )}`,
                                                    })
                                                }
                                            />{" "}
                                        </div>
                                    ) : (
                                        <p className="text-sm sm:text-base">
                                            Del {gropo.fecha_inicio} al{" "}
                                            {gropo.fecha_final}
                                        </p>
                                    )}
                                </div>
                                {editar.editar && editar.id === gropo.id ? (
                                    <div>
                                        <svg
                                            onClick={() =>
                                                actualizarGrupo(gropo.id)
                                            }
                                            className="w-5 h-5 text-gray-500 cursor-pointer"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <span
                                            onClick={() =>
                                                agregarCasas(
                                                    gropo.id,
                                                    gropo.fecha_inicio,
                                                    gropo.fecha_final
                                                )
                                            }
                                            title="agregar casa">
                                            <svg
                                                className="w-5 h-5 text-gray-500 cursor-pointer"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />{" "}
                                                <polyline points="9 22 9 12 15 12 15 22" />
                                            </svg>
                                        </span>
                                        <span
                                            onClick={() =>
                                                setEditar({
                                                    editar: true,
                                                    id: gropo.id,
                                                    fecha_inicio:
                                                        transformarFecha(
                                                            gropo.fecha_inicio
                                                        ),
                                                    fecha_final:
                                                        transformarFecha(
                                                            gropo.fecha_final
                                                        ),
                                                })
                                            }
                                            title="editar">
                                            <svg
                                                className="w-5 h-5 mx-1 text-gray-500 cursor-pointer"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                <line
                                                    x1="13.5"
                                                    y1="6.5"
                                                    x2="17.5"
                                                    y2="10.5"
                                                />
                                            </svg>
                                        </span>
                                        <span title="eliminar">
                                            <svg
                                                onClick={() =>
                                                    eliminarGrupo(gropo.id)
                                                }
                                                className="w-5 h-5 text-gray-500 cursor-pointer"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
