import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { HabitacionComponent } from "../../detailedScreens/DetailedAlojamiento";
import colores from "../../helpers/colores";

const ModalAgregarHab = ({ room, setroomVisibility }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [input, setInput] = useState({
        nombre_habitacion: room.nombre_habitacion,
        descripcion: room.descripcion,
        image_habitacion: room.imagen_minificada,
    });
    const authUser = useSelector((state) => state.authReducer);
    const [comprobantePreview, setComprobantePreview] = useState(null);
    const [comprobante, setComprobante] = useState(null);

    const handleComprobante = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        setComprobante(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setComprobantePreview(objectUrl);
    };

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };
    const updateProduct = async () => {
        const form = new FormData();

        form.append("nombre_habitacion", input.nombre_habitacion?.trim());
        form.append("descripcion", input.descripcion?.trim());
        form.append(
            "image_habitacion",
            comprobante ? comprobante : input.image_habitacion
        );

        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/productosHabitaciones/putHabitacion/${room.id}`,
            form,
            { headers }
        );

        if (json.data.messagge === "actualizacion exitosa!") {
            Swal.fire({
                title: "Habitacion actualizada",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok!",
            });

            setroomVisibility(0);
        }
    };

    const createRoom = () => {
        // if (todo.length > 0) {
        updateProduct();
        // } else {
        //     Swal.fire({
        //         title: "Debes agregar al menos un elemento",
        //         icon: "warning",
        //         confirmButtonColor: "#3085d6",
        //         confirmButtonText: "Completar!",
        //     });
        // }
    };

    return (
        <div className="relative flex items-center justify-center ">
            <div className="fixed z-10 bottom-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-24 2xl:container 2xl:mx-auto md:px-20 sm:py-24 ">
                    <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg p-10 xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] overflow-y-auto ">
                        <header className="mb-3 mr-auto text-base font-bold lg:text-2xl">
                            Editar detalles
                        </header>
                        <div>
                            <div className="mt-3 rounded-xl p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-80 overflow-hidden">
                                <div className="relative flex  ">
                                    <img
                                        src={
                                            comprobantePreview
                                                ? comprobantePreview
                                                : `${
                                                      process.env
                                                          .REACT_APP_PROXY_ARCHIVOS
                                                  }${"/bluecorner/v1/productosHabitaciones/getImagen/"}${
                                                      input.image_habitacion
                                                  }`
                                        }
                                        alt="imagen habitacion"
                                        className="rounded-xl object-cover h-44 w-full"
                                    />

                                    <input
                                        type="file"
                                        onChange={handleComprobante}
                                        accept="image/*"
                                        className="absolute top-0 left-0 bg-red-200 opacity-0 cursor-pointer w-full h-full file:cursor-pointer"
                                    />
                                </div>
                                <input
                                    onChange={handleInputChange}
                                    name="nombre_habitacion"
                                    value={input.nombre_habitacion}
                                    className={`text-[${colores.primario}] text-lg font-semibold mb-2 px-2 border mt-2 w-full rounded-lg `}
                                />
                                {/* <HabitacionComponent habitacion={room} /> */}
                                <textarea
                                    type="text"
                                    value={input.descripcion}
                                    name="descripcion"
                                    onChange={handleInputChange}
                                    placeholder="Descripción"
                                    className="w-full h-40 my-3 bg-transparent border-none outline-none focus:outline-none"
                                />
                            </div>
                        </div>{" "}
                        <div className="w-full px-4">
                            <div className="max-w-md mx-auto overflow-hidden md:max-w-lg">
                                <div className="pb-3 text-center ">
                                    <button
                                        onClick={() => createRoom()}
                                        className="w-full rounded-lg border-b-4 border-b-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400 mt-10"
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => setroomVisibility(0)}
                            className="absolute text-gray-800 dark:text-gray-400 top-1 right-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                            aria-label="close"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAgregarHab;
