import { useState } from "react";
import { useSelector } from "react-redux";

const useColores = () => {
    //Checkear si el usuario logeado tiene colores predefinidos
    const [colores, setColores] = useState({
        primario: "#053465",
        secundario: "#307FE2",
    });

    const { color_primario, color_secundario } = useSelector(
        (state) => state.authReducer
    );

    return {
        setColores,
        primario: color_primario || colores.primario,
        secundario: color_secundario || colores.secundario,
    };
};

export default useColores;
