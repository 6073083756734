const initialState = {
  allCategories: [],
  categoriesInPage: { total: 0, data: [] },
  categorySelected: {},
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CATEGORIES":
      return {
        ...state,
        allCategories: action.payload,
      };

    case "GET_CATEGORIES":
      return {
        ...state,
        categoriesInPage: action.payload,
      };

    case "CATEGORY_SELECTED":
      const { data } = action.payload.category;
      return {
        ...state,
        categorySelected: data,
      };

    case "CLEAR_CATEGORY":
      return {
        ...state,
        categorySelected: {},
      };

    case "SEARCH_CATEGORIES":
      if (action.payload.SioNo === "Si") {
        const newCategories = state.allCategories.filter((category) => {
          if (
            (category.habilitado === "Si") &
            category.nombre
              .toLowerCase()
              .includes(action.payload.input.toLowerCase())
          )
            return category;
        });

        const newSearch = {
          total: newCategories.length,
          data: newCategories,
        };
        return {
          ...state,
          categoriesInPage: newSearch,
        };
      } else {
        const newCategories = state.allCategories.filter((category) => {
          if (
            (category.habilitado === "No") &
            category.nombre
              .toLowerCase()
              .includes(action.payload.input.toLowerCase())
          )
            return category;
        });

        const newSearch = {
          total: newCategories.length,
          data: newCategories,
        };
        return {
          ...state,
          categoriesInPage: newSearch,
        };
      }

    case "CLEAR_CATEGORIES":
      return {
        ...state,
        categoriesInPage: { total: 0, data: [] },
      };
    default:
      return state;
  }
};
