import React, { useState } from "react";
import axios from "axios";
import Spinner from "../../../Spinner";
import Swal from "sweetalert2";
import EjemploFinal from "./EjemploFinal";
import { useSelector } from "react-redux";

const ModalAddServices = ({
    modalVisible,
    setModalVisible,
    producto,
    setProducto,
    fakeRefresh,
    setFakeRefresh,
    tipo_producto,
}) => {
    const TIPOS = ["Opcional", "Obligatorio"];
    const [input, setInput] = useState({
        name: "",
        description: "",
        price: 0,
        tipo: "Opcional",
        tipo_moneda:
            tipo_producto === "embarcacion"
                ? producto.data.tipo_moneda
                : producto.data.moneda_precio_base || "",
        intervalo_dias: 0,
        intervalo_precio: 0,
    });
    const [ejemploFinal, setEjemploFinal] = useState(false);
    const [siguiente, setSiguiente] = useState("Guardar");
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        if (input.name === "") {
            return Swal.fire({
                title: "Error",
                text: "Debe ingresar un nombre",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
        if (input.description === "") {
            return Swal.fire({
                title: "Error",
                text: "Debe ingresar una descripción",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
        if (input.price === 0) {
            return Swal.fire({
                title: "Error",
                text: "Debe ingresar un precio mayor a 0",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
        if (
            input.name !== "" &&
            input.description !== "" &&
            input.price !== 0
        ) {
            setSiguiente(null);

            if (tipo_producto === "casa") {
                const payload = {
                    nombre: input.name,
                    descripcion: input.description,
                    precio: input.price,
                    tipo: input.tipo,
                    tipo_moneda: input.tipo_moneda,
                    intervalo_dias: input.intervalo_dias,
                    intervalo_precio: input.intervalo_precio,
                    id_usuario: authUser.id_usuario,
                };
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/productoServiciosAdicionales
            `,
                    {
                        id_productos: producto.data.id,
                        ...payload,
                    },
                    { headers }
                );
                console.log(data);
                setSiguiente("Añadir");
                setModalVisible(!modalVisible);
                setInput({
                    name: "",
                    description: "",
                    price: 0,
                    tipo: "Opcional",
                    tipo_moneda: "",
                    intervalo_dias: 0,
                    intervalo_precio: 0,
                });
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        servicios_adicionales: [
                            ...producto.data.servicios_adicionales,
                            payload,
                        ],
                    },
                });
                setFakeRefresh(!fakeRefresh);
            } else if (tipo_producto === "embarcacion") {
                const payload = {
                    nombre: input.name,
                    descripcion: input.description,
                    precio: input.price,
                    tipo: input.tipo,
                    tipo_moneda: input.tipo_moneda,
                };
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/embarcacionServiciosAdicionales
            `,
                    {
                        id_embarcacion: producto.data.id,
                        ...payload,
                    },
                    { headers }
                );

                setSiguiente("Añadir");
                setModalVisible(!modalVisible);
                setInput({
                    name: "",
                    description: "",
                    price: 0,
                    tipo: "Opcional",
                    tipo_moneda: "",
                    intervalo_dias: 0,
                    intervalo_precio: 0,
                });
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        servicios_adicionales: [
                            ...producto.data.servicios_adicionales,
                            payload,
                        ],
                    },
                });
                setFakeRefresh(!fakeRefresh);
            }
        }
    };

    const handleClose = () => {
        setInput({
            name: "",
            description: "",
            price: 0,
            tipo: "Opcional",
            tipo_moneda: "",
            intervalo_dias: 0,
            intervalo_precio: 0,
        });
        setModalVisible(!modalVisible);
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed z-10 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-20 sm:py-8 ">
                    <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg  xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-10vh)] p-10 overflow-y-auto ">
                        <header className="mb-3 mr-auto text-base font-bold lg:text-2xl">
                            Nuevo servicio
                        </header>
                        <div className="flex w-full items-center justify-between">
                            <h2 className="w-full">Tipo de servicio</h2>
                            <select
                                className="p-1 ml-2 border rounded-lg w-full"
                                name="tipo"
                                onChange={handleInputChange}>
                                {TIPOS.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {input.tipo_moneda ? (
                            <>
                                <div className="w-full px-3 rounded-lg border mt-2">
                                    <input
                                        type="text"
                                        value={input.name}
                                        name="name"
                                        onChange={handleInputChange}
                                        placeholder="Nombre"
                                        className="w-full my-3 bg-transparent border-none outline-none focus:outline-none"
                                    />
                                </div>
                                <div className="w-full px-3 rounded-lg border mt-4">
                                    <textarea
                                        type="text"
                                        value={input.description}
                                        name="description"
                                        onChange={handleInputChange}
                                        placeholder="Descripción"
                                        className="w-full h-40 my-3 bg-transparent border-none outline-none focus:outline-none"
                                    />
                                </div>
                                <div className="grid grid-cols-[20%_40%_40%] mt-5 w-full">
                                    <label className="text-lg font-medium">
                                        Precio
                                    </label>
                                    <input
                                        className="p-1 border rounded-lg outline-none"
                                        value={input.price}
                                        name="price"
                                        onChange={handleInputChange}
                                    />
                                    <select
                                        className="p-1 ml-1 border rounded-lg outline-none"
                                        value={input.tipo_moneda}
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                tipo_moneda: e.target.value,
                                            })
                                        }>
                                        <option value="USD">USD</option>
                                        <option value="PEN">PEN</option>
                                    </select>
                                </div>
                                {tipo_producto === "casa" && (
                                    <div className="mt-5 flex flex-col">
                                        <div>
                                            Abajo podrá eligir el aumento de la
                                            tarifa segun cantidad de días de
                                            estadía. (opcional)
                                        </div>
                                        <div className="flex mt-4 items-center">
                                            <span>El aumento será cada</span>
                                            <input
                                                className="mx-2 p-1 border rounded-lg outline-none w-10 text-center"
                                                value={input.intervalo_dias}
                                                type={"number"}
                                                min={1}
                                                onWheel={(e) => e.target.blur()}
                                                name="intervalo_dias"
                                                onChange={handleInputChange}
                                            />
                                            <span>días.</span>
                                        </div>
                                        <div className="flex mt-4 items-center">
                                            <span>Precio por cada aumento</span>
                                            <input
                                                className="mx-2 p-1 border rounded-lg outline-none w-10 text-center"
                                                value={input.intervalo_precio}
                                                type={"number"}
                                                min={1}
                                                onWheel={(e) => e.target.blur()}
                                                name="intervalo_precio"
                                                onChange={handleInputChange}
                                            />
                                            <span>{input.tipo_moneda}.</span>
                                        </div>
                                        {input.intervalo_precio > 0 &&
                                            input.intervalo_dias > 0 && (
                                                <div>
                                                    <button
                                                        className="border p-3 rounded-lg mt-3 hover:scale-105 border-blue-bluecorner text-blue-bluecorner"
                                                        onClick={() =>
                                                            setEjemploFinal(
                                                                (prev) => !prev
                                                            )
                                                        }>
                                                        {!ejemploFinal
                                                            ? "Ver ejemplo final"
                                                            : "Cerrar ejemplo final"}
                                                    </button>
                                                    {ejemploFinal && (
                                                        <EjemploFinal
                                                            values={input}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                )}
                                <button
                                    onClick={() => handleClose()}
                                    className="absolute text-gray-800 dark:text-gray-400 top-1 right-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                                    aria-label="close">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18 6L6 18"
                                            stroke="currentColor"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M6 6L18 18"
                                            stroke="currentColor"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                                <button
                                    className="w-full rounded-lg  bg-blue-bluecorner py-3 font-semibold text-white mt-5"
                                    onClick={(e) => handleCreate(e)}
                                    disabled={!siguiente}>
                                    {siguiente ? (
                                        siguiente
                                    ) : (
                                        <Spinner width={7} />
                                    )}
                                </button>
                            </>
                        ) : (
                            <div className="mt-16">
                                <Spinner width={10} />
                                <button
                                    onClick={() => handleClose()}
                                    className="absolute text-gray-800 dark:text-gray-400 top-1 right-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                                    aria-label="close">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18 6L6 18"
                                            stroke="currentColor"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M6 6L18 18"
                                            stroke="currentColor"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAddServices;
