import axios from "axios";
import React from "react";
import { useNavigate } from "react-router";

const ModalDisponibilidad = ({
    pedido,
    modalDisponibilidad,
    setModalDisponibilidad,
    IsAlojamiento,
}) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const navigate = useNavigate();

    const handleClose = () => {
        setModalDisponibilidad((prev) => !prev);
    };

    const handleGenerateLink = async () => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${
                IsAlojamiento ? "pedidoCasa" : "pedidoEmbarcacion"
            }/actualizarEstado/`,
            {
                id: pedido.id,
                estado: "Pendiente de pago",
            },
            { headers }
        );

        navigate(
            `/${IsAlojamiento ? "checkout" : "checkout-embarcacion"}/${
                pedido.id
            }`
        );
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalDisponibilidad ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex items-center justify-center w-full px-1 py-3 2xl:mx-auto md:px-2">
                    <div className="relative flex flex-col items-center justify-center py-16 bg-white rounded-lg w-96  dark:bg-white  xl:py-12 h-[calc(50vh-5rem)]">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-3 right-1 md:top-8 md:right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="mb-3 text-2xl font-bold text-center">
                            Disponibilidad y Link de pago
                        </header>
                        <div className="mb-3 text-xl font-semibold text-center">
                            Está dando por hecho que el alojamiento se encuentra
                            disponible.
                        </div>
                        <h2 className="mb-3 text-lg font-semibold text-center">
                            ¿Está seguro que desea generar el Link de Pago?
                        </h2>
                        <div className="flex justify-center w-full">
                            <button
                                className="w-28 mr-1 max-w-sm rounded-2xl border border-blue-400  py-3 font-bold text-blue-400 hover:scale-105 mt-10 active:translate-y-0.5"
                                onClick={() =>
                                    setModalDisponibilidad((prev) => !prev)
                                }>
                                No
                            </button>
                            <button
                                className="w-28 ml-1 rounded-2xl border border-blue-400 py-3 font-bold text-blue-400  mt-10 hover:scale-105 active:translate-y-0.5"
                                onClick={handleGenerateLink}>
                                Si
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDisponibilidad;
