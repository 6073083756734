import React, { useEffect, useLayoutEffect, useState } from "react";

const Semaforo = ({ items }) => {
    const [itemsAmarillos, setItemsAmarillos] = useState({
        total: 0,
        color: "",
    });
    const [itemsRojos, setItemsRojos] = useState({ total: 0, color: "" });
    const [itemsVerdes, setItemsVerdes] = useState({ total: 0, color: "" });
    const [itemsGrises, setItemsGrises] = useState({ total: 0, color: "" });
    const [barraWidth, setBarraWidth] = useState(0);
    const [widthVerdes, setWidthVerdes] = useState(0);
    const [widthAmarillos, setWidthAmarillos] = useState(0);
    const [widthRojos, setWidthRojos] = useState(0);
    const [widthGrises, setWidthGrises] = useState(0);
    const [fakeRefresh, setFakeRefresh] = useState(false);
    const [fakeRefresh2, setFakeRefresh2] = useState(false);
    const [hoverItem, setHoverItem] = useState({ open: false, estado: "" });

    useLayoutEffect(() => {
        llenarItemsRojos();
        llenarItemsAmarillos();
        llenarItemsVerdes();
        llenarItemsGrises();
        setBarraWidth(
            itemsRojos.total +
                itemsAmarillos.total +
                itemsVerdes.total +
                itemsGrises.total
        );
        setWidthRojos((itemsRojos.total * 100) / barraWidth);
        setWidthAmarillos((itemsAmarillos.total * 100) / barraWidth);
        setWidthVerdes((itemsVerdes.total * 100) / barraWidth);
        setWidthGrises((itemsGrises.total * 100) / barraWidth);
        if (!fakeRefresh) {
            setFakeRefresh(true);
        }
    }, [fakeRefresh2]);

    useEffect(() => {
        setFakeRefresh2(!fakeRefresh2);
    }, [fakeRefresh, items]);

    const llenarItemsRojos = () => {
        const itemsVencidos = items.filter(
            (item) => item.tipo_reloj === "Vencido"
        );

        if (itemsVencidos.length > 0) {
            setItemsRojos({
                total: itemsVencidos.length,
                color: itemsVencidos[0].color_reloj,
            });
        }
    };

    const llenarItemsAmarillos = () => {
        const itemsVencidos = items.filter(
            (item) => item.tipo_reloj === "Por vencer"
        );

        if (itemsVencidos.length > 0) {
            setItemsAmarillos({
                total: itemsVencidos.length,
                color: itemsVencidos[0].color_reloj,
            });
        }
    };

    const llenarItemsVerdes = () => {
        const itemsVencidos = items.filter(
            (item) => item.tipo_reloj === "Sin vencer"
        );

        if (itemsVencidos.length > 0) {
            setItemsVerdes({
                total: itemsVencidos.length,
                color: itemsVencidos[0].color_reloj,
            });
        }
    };

    const llenarItemsGrises = () => {
        const itemsVencidos = items.filter(
            (item) => item.tipo_reloj === "Sin Actividad"
        );

        if (itemsVencidos.length > 0) {
            setItemsGrises({
                total: itemsVencidos.length,
                color: itemsVencidos[0].color_reloj,
            });
        }
    };

    return (
        <div className="border-2 border-gray-300 p-2.5 m-2 relative">
            <div className="flex absolute left-0 top-0 w-[calc(100.1%)] h-full cursor-pointer">
                {itemsGrises.total > 0 && (
                    <span
                        onMouseEnter={() =>
                            setHoverItem({
                                estado: "Sin Actividad",
                                open: true,
                            })
                        }
                        onMouseLeave={() =>
                            setHoverItem({
                                estado: "",
                                open: false,
                            })
                        }
                        className="top-0 left-0 text-transparent select-none text-base"
                        style={{
                            width: `${widthGrises}%`,
                            backgroundColor: itemsGrises.color,
                        }}>
                        a
                    </span>
                )}
                {itemsVerdes.total > 0 && (
                    <span
                        onMouseEnter={() =>
                            setHoverItem({
                                estado: "Sin vencer",
                                open: true,
                            })
                        }
                        onMouseLeave={() =>
                            setHoverItem({
                                estado: "",
                                open: false,
                            })
                        }
                        className="top-0 left-0 text-transparent select-none text-base"
                        style={{
                            width: `${widthVerdes}%`,
                            backgroundColor: itemsVerdes.color,
                        }}>
                        a
                    </span>
                )}
                {itemsAmarillos.total > 0 && (
                    <span
                        onMouseEnter={() =>
                            setHoverItem({
                                estado: "Por vencer",
                                open: true,
                            })
                        }
                        onMouseLeave={() =>
                            setHoverItem({
                                estado: "",
                                open: false,
                            })
                        }
                        className="top-0 left-0 text-transparent select-none text-base"
                        style={{
                            width: `${widthAmarillos}%`,
                            backgroundColor: itemsAmarillos.color,
                        }}>
                        a
                    </span>
                )}
                {itemsRojos.total > 0 && (
                    <span
                        onMouseEnter={() =>
                            setHoverItem({
                                estado: "Vencidos",
                                open: true,
                            })
                        }
                        onMouseLeave={() =>
                            setHoverItem({
                                estado: "",
                                open: false,
                            })
                        }
                        className="top-0 left-0 text-transparent select-none text-base"
                        style={{
                            width: `${widthRojos}%`,
                            backgroundColor: itemsRojos.color,
                        }}>
                        a
                    </span>
                )}
            </div>
            {hoverItem.open && hoverItem.estado === "Sin Actividad" && (
                <div className="absolute flex left-0 top-5 border-2 p-2 bg-white z-20 font-normal">
                    Actividades sin actividad:{" "}
                    <span
                        // style={{ color: itemsGrises.color }}
                        className={`mx-1 font-bold text-[${itemsGrises.color}]`}>
                        {itemsGrises.total}{" "}
                    </span>
                </div>
            )}
            {hoverItem.open && hoverItem.estado === "Sin vencer" && (
                <div className="absolute flex left-24 top-5 border-2 p-2 bg-white z-20 font-normal">
                    Actividades sin vencer:{" "}
                    <span
                        // style={{ color: itemsVerdes.color }}
                        className={`mx-1 font-bold text-[${itemsVerdes.color}]`}>
                        {itemsVerdes.total}{" "}
                    </span>
                </div>
            )}
            {hoverItem.open && hoverItem.estado === "Por vencer" && (
                <div className="absolute flex left-36 top-5 border-2 p-2 bg-white z-20 font-normal">
                    Actividades por vencer:{" "}
                    <span
                        // style={{ color: itemsAmarillos.color }}
                        className={`mx-1 font-bold text-[${itemsAmarillos.color}]`}>
                        {itemsAmarillos.total}{" "}
                    </span>
                </div>
            )}
            {hoverItem.open && hoverItem.estado === "Vencidos" && (
                <div className="absolute flex right-0 top-5 border-2 p-2 bg-white z-20 font-normal">
                    Actividades vencidas:{" "}
                    <span
                        // style={{ color: itemsRojos.color }}
                        className={`mx-1 font-bold text-[${itemsRojos.color}]`}>
                        {itemsRojos.total}{" "}
                    </span>
                </div>
            )}
        </div>
    );
};

export default Semaforo;
