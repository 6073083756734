import React, { useState } from "react";
import { useSelector } from "react-redux";
import puntosDetalles from "../helpers/puntosDetalles";
import numberToCommas from "../helpers/numberToCommas";

const PUNTOS_NECESARIOS = 5;

const BluecoinsBar = ({ currentProduct }) => {
    const { puntos } = useSelector((state) => state.authReducer);
    const [verMas, setVerMas] = useState(false);

    return (
        <div className="text-blue-bluecorner font-medium flex flex-col grid-cols-[70%_30%] sm:grid-cols-[75%_25%] gap-1 mb-3">
            <span className="text-lg font-extrabold text-center">
                {/* {puntosDetalles.nombre_coins} */}
                Referidos
            </span>
            <div className="relative w-full h-4 bg-gray-300 rounded-md">
                <div
                    className="bg-blue-600 h-4 rounded-md animated-bar max-w-[100%]"
                    style={{
                        width: `${(puntos * 100) / PUNTOS_NECESARIOS}%`,
                    }}
                ></div>
                <span className="font-bold"> {numberToCommas(puntos)} </span>
                <span className="absolute font-bold right-1">
                    {numberToCommas(PUNTOS_NECESARIOS)}{" "}
                </span>
            </div>
            <div className="flex flex-col mt-6 text-center md:mt-3">
                {Math.max(0, PUNTOS_NECESARIOS - puntos) === 0 ? (
                    <>
                        <span>
                            ¡Ya tienes los{" "}
                            <span className="font-bold">
                                {/* {puntosDetalles.nombre_coins} */}
                                referidos
                            </span>{" "}
                            necesarios para obtener un
                        </span>
                        <span className="text-2xl font-bold md:text-3xl">
                            100%{" "}
                        </span>

                        <div className="flex justify-center">
                            <span>
                                de <b>descuento en el valor del alquiler!</b>
                            </span>
                            <p>
                                {/* <span className="px-2 py-1 font-bold text-white bg-blue-500 rounded-md cursor-pointer">
                                    Ver mas sobre blue coins
                                </span> */}
                            </p>
                            <p
                                className="relative "
                                onMouseOut={() => setVerMas(false)}
                                onMouseOver={() => setVerMas(true)}
                            >
                                <svg
                                    className="w-6 h-6 ml-1 text-blue-500 cursor-help"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <circle cx="12" cy="12" r="10" />{" "}
                                    <line x1="12" y1="8" x2="12" y2="12" />{" "}
                                    <line x1="12" y1="16" x2="12.01" y2="16" />
                                </svg>
                                <div
                                    className={
                                        verMas
                                            ? `absolute top-4 w-[260px] z-[999]  pt-5 sm:left-[-120px]  right-0`
                                            : "hidden"
                                    }
                                >
                                    <div
                                        className={
                                            verMas
                                                ? "block bg-white  top-16 md723:top-16 py-2 rounded-lg shadow-md"
                                                : "hidden"
                                        }
                                    >
                                        <p className="cursor-help">
                                            Puedes pedir que te refieran en los
                                            alquileres, y asi acumularas puntos,
                                            para obtener un full day en la casa
                                            Federal
                                        </p>
                                        <p
                                            onClick={() =>
                                                window.open(
                                                    `/referidos`,
                                                    "_blank"
                                                )
                                            }
                                            className="underline transition ease-in cursor-pointer hover:scale-95 underline-offset-2 "
                                        >
                                            {" "}
                                            Conoce más acerca de Referidos
                                        </p>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        <span>
                            Solo te faltan{" "}
                            {numberToCommas(
                                Math.max(0, PUNTOS_NECESARIOS - puntos)
                            )}{" "}
                            <span className="font-bold">
                                {/* {puntosDetalles.nombre_coins}
                                 */}
                                referidos
                            </span>{" "}
                            para obtener un
                        </span>
                        <span className="text-2xl font-bold md:text-3xl">
                            100%{" "}
                        </span>
                        <div className="flex justify-center">
                            <p>
                                <b>descuento en el valor del alquiler!</b>
                                {/* <span className="px-2 py-1 font-bold text-white bg-blue-500 rounded-md cursor-pointer">
                                    Ver mas sobre blue coins
                                </span> */}
                            </p>
                            <p
                                className="relative "
                                onMouseOut={() => setVerMas(false)}
                                onMouseOver={() => setVerMas(true)}
                            >
                                <svg
                                    className="w-6 h-6 ml-1 text-blue-500 cursor-help"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <circle cx="12" cy="12" r="10" />{" "}
                                    <line x1="12" y1="8" x2="12" y2="12" />{" "}
                                    <line x1="12" y1="16" x2="12.01" y2="16" />
                                </svg>
                                <div
                                    className={
                                        verMas
                                            ? `absolute top-4 w-[260px] z-[999]  pt-5 sm:left-[-120px]  right-0`
                                            : "hidden"
                                    }
                                >
                                    <div
                                        className={
                                            verMas
                                                ? "block bg-white  top-16 md723:top-16 py-2 rounded-lg shadow-md"
                                                : "hidden"
                                        }
                                    >
                                        <p className="cursor-help">
                                            Puedes pedir que te refieran en los
                                            alquileres, y asi acumularas puntos,
                                            para obtener un full day en la casa
                                            Federal
                                        </p>
                                        <p
                                            onClick={() =>
                                                window.open(
                                                    `/referidos`,
                                                    "_blank"
                                                )
                                            }
                                            className="underline transition ease-in cursor-pointer hover:scale-95 underline-offset-2 "
                                        >
                                            {" "}
                                            Conoce más acerca de Referidos
                                        </p>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BluecoinsBar;
