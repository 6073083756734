import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { NavbarEmpresasNew } from "./NavbarEmpresasNew";
import Spinner from "../Spinner";
import Swal from "sweetalert2";
import { verifyToken } from "../../redux/actions/authActions";
const EditEmpresa = () => {
    const dispatch = useDispatch();
    const [cargando, setCargando] = useState(false);
    const [siguiente, setSiguiente] = useState("Guardar");
    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const [empresa, setEmpresa] = useState({
        nombre: "",
        logo: "",
        color_primario: "#000000",
        color_secundario: "#000000",
    });

    const getEmpresa = async () => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/empresas/detalle/${authUser.id_empresa}`,

            { headers }
        );

        console.log(json.data);
        setEmpresa({ ...json.data });
    };

    useEffect(() => {
        getEmpresa();
    }, []);

    const putLogo = async (e) => {
        setCargando(true);
        const form = new FormData();
        form.append("logo_empresa", e.target.files[0]);
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/empresas/actualizarLogo/${authUser.id_empresa}`,
            form,

            { headers }
        );

        if (json.data.message === "La imagen fue actualizada correctamente!") {
            setEmpresa({
                ...empresa,
                logo: json.data.imagen,
            });
        } else {
            Swal.fire({
                title: "No se pudo cargar el logo , intente nuevamente",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
            });
        }
        setCargando(false);
    };

    const putColores = async (e) => {
        setCargando(true);
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/empresas/actualizarColor/${authUser.id_empresa}`,
            {
                color_primario: empresa.color_primario,
                color_secundario: empresa.color_secundario,
            },

            { headers }
        );
        dispatch(verifyToken());
        if (
            json.data.message ===
            "Los colores fueron actualizado correctamente!"
        ) {
            Swal.fire({
                title: "los datos de la empresa fueron actualizados correctamente!",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
            });
        } else {
            Swal.fire({
                title: "No se pudo cargar los colores , intente nuevamente",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
            });
        }
        setCargando(false);
    };

    return (
        <NavbarEmpresasNew>
            <div className="w-full mx-auto">
                <h1 className="py-5 text-4xl text-left w-11/12 xs:w-8/12 mx-auto lg1170:px-20 text-[#3C3C3C] text-center">
                    Editar mi empresa
                </h1>

                <div className="mx-auto  text-[#3C3C3C]">
                    <div className="pt-10 w-80 mx-auto">
                        <p className=" font-bold text-center">
                            {empresa.nombre}
                        </p>
                        <div className="mt-5">{cargando && <Spinner />}</div>
                        {empresa.logo === "" ||
                        empresa.logo === "sinImagen.png" ? (
                            <div
                                className={
                                    cargando
                                        ? "hidden"
                                        : "border-2 border-blue-500 rounded-full w-32 h-32 mx-auto mt-5  relative bg-gray-200"
                                }
                            >
                                <div className=" cursor-pointer564try0po9l' border-2 border-blue-500 bg-white rounded-full w-7 h-7 absolute bottom-[-16px] right-[48px] flex justify-center items-center  ">
                                    <svg
                                        className="w-5 h-5 text-blue-bluecorner cursor-pointer"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                        <line x1="16" y1="5" x2="19" y2="8" />
                                    </svg>
                                    <input
                                        accept="image/png,image/jpeg"
                                        type="file"
                                        name="logo"
                                        className="w-full h-full opacity-0 cursor-pointer absolute"
                                        onChange={putLogo}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={cargando ? "hidden" : "relative"}>
                                <img
                                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/empresas/getImage/${empresa.logo}`}
                                    className="object-contain border-2 border-blue-500 rounded-full w-32 h-32 mx-auto mt-5  relative bg-gray-200 "
                                    alt="logo"
                                />
                                <div className=" border-2 border-blue-500 bg-white rounded-full w-7 h-7 absolute bottom-[-16px] right-[148px] flex justify-center items-center ">
                                    <svg
                                        className="w-5 h-5 text-blue-bluecorner cursor-pointer"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                        <line x1="16" y1="5" x2="19" y2="8" />
                                    </svg>
                                    <input
                                        accept="image/png,image/jpeg"
                                        type="file"
                                        name="logo"
                                        className="w-full h-full opacity-0 cursor-pointer absolute"
                                        onChange={putLogo}
                                    />
                                </div>
                            </div>
                        )}
                        <p
                            className={
                                cargando ? "hidden" : "text-center mt-8 text-sm"
                            }
                        >
                            Sube una imagen en formato JPG o PNG.
                        </p>
                    </div>

                    <div className=" overflow-auto mx-auto">
                        <div
                            className={
                                cargando ? "hidden" : "pt-10 w-80 mx-auto"
                            }
                        >
                            <div className="flex flex-col items-center justify-center mt-5">
                                <p className="mt-2 font-bold text-center ">
                                    Color principal
                                </p>
                                <div className="grid grid-cols-2 mt-5">
                                    <div className="relative w-8 h-8 ml-1 overflow-hidden rounded-full ">
                                        <input
                                            className="absolute w-20 h-12 overflow-hidden -translate-x-2 -translate-y-2 bg-white cursor-pointer "
                                            type="color"
                                            onChange={(e) =>
                                                setEmpresa({
                                                    ...empresa,
                                                    color_primario:
                                                        e.target.value,
                                                })
                                            }
                                            value={empresa.color_primario}
                                        ></input>
                                    </div>
                                    <div>
                                        <p className="pt-1 font-bold uppercase ">
                                            {empresa.color_primario}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center mt-5">
                                <p className="mt-2 font-bold text-center ">
                                    Color secundario
                                </p>
                                <div className="grid grid-cols-2 mt-5">
                                    <div className="relative w-8 h-8 ml-1 overflow-hidden rounded-full ">
                                        <input
                                            className="absolute w-20 h-12 overflow-hidden -translate-x-2 -translate-y-2 bg-white cursor-pointer "
                                            type="color"
                                            onChange={(e) =>
                                                setEmpresa({
                                                    ...empresa,
                                                    color_secundario:
                                                        e.target.value,
                                                })
                                            }
                                            value={empresa.color_secundario}
                                        ></input>
                                    </div>
                                    <div>
                                        <p className="pt-1 font-bold uppercase ">
                                            {empresa.color_secundario}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10">{cargando && <Spinner />}</div>
                    </div>
                    <button
                        disabled={siguiente === "Guardar" ? false : true}
                        className="w-80 xs:w-80 flex items-center justify-center mx-auto rounded-lg text-center  bg-blue-500 py-3  text-white hover:bg-blue-400 active:translate-y-[0.125rem]  mt-3 mb-3"
                        onClick={putColores}
                    >
                        {siguiente}
                    </button>
                </div>
            </div>
        </NavbarEmpresasNew>
    );
};

export default EditEmpresa;
