import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Spinner from "../../Spinner";

import axios from "axios";
import ModalNights from "./ModalNights";
import ModalEditNights from "./ModalEditNights";

const VariableNights = ({ onboarding, setOnboarding, setPage, actualPage }) => {
    const dispatch = useDispatch();
    const [siguiente, setSiguiente] = useState("Siguiente");
    const [loading, setLoading] = useState(false);
    const [create, setcreate] = useState(false);
    const [availability, setAvailability] = useState([]);
    const [nightupdate, setNightupdate] = useState(false);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        dispatch(getAllNoches());
    }, []);
    useEffect(() => {
        dispatch(getAllNoches());
    }, [create, nightupdate]);

    useLayoutEffect(() => {
        setLoading(false);
    }, [availability]);

    const getAllNoches = () => {
        setLoading(true);
        return async function () {
            const json = await axios.get(
                `${process.env.REACT_APP_PROXY}/productoMinimaDisponibilidad/all/${onboarding.id}`,
                { headers }
            );
            let aux = json.data?.map((l) => {
                return {
                    fecha_inicio: l.fecha_inicio.substring(0, 10),
                    fecha_final: l.fecha_final.substring(0, 10),
                    numero_noches: l.numero_noches,
                    id: l.id,
                    id_productos: l.id_productos,
                };
            });

            setAvailability(aux.length > 0 ? aux : []);
        };
    };

    const deleteNoches = (id) => {
        return async function () {
            const json = await axios.delete(
                `${process.env.REACT_APP_PROXY}/productoMinimaDisponibilidad/eliminar/${id}`,
                { headers }
            );

            if (json.data.message === "Eliminado con exito") {
                Swal.fire({
                    title: "Exito",
                    text: "Se ha eliminado la minima disponibilidad",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                dispatch(getAllNoches());
            }
        };
    };

    const eliminarNoches = (nights) => {
        Swal.fire({
            title: "Estas seguro?",
            text: "Una vez eliminado no podras recuperarlo!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                dispatch(deleteNoches(nights.id));
            }
        });
    };

    const updateProduct = (payload) => {
        setSiguiente(null);

        return async function () {
            const json = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/actualizarAlquilerMinimo/${payload.id}`,
                payload,
                { headers }
            );

            if (json.data.message === "actuaizacion exitoso") {
                setPage(actualPage + 1);
            }
        };
    };

    const nextPage = () => {
        if (onboarding.alquiler_minimo < 1) {
            Swal.fire({
                title: "Error",
                text: "Debe ingresar un alquiler minimo",
                icon: "error",
                confirmButtonText: "Ok",
            });
        } else {
            dispatch(
                updateProduct({
                    alquiler_minimo: onboarding.alquiler_minimo,
                    id: onboarding.id,
                })
            );
        }
    };

    console.log(availability);

    return (
        <div className="w-full p-5 ">
            <h1 className="py-16 -mt-5 text-4xl font-bold text-center sm:py-10 sm:text-6xl">
                Alquiler minimo
            </h1>
            <div className="align-top">
                <div className="p-4 mt-2 flex justify-center items-center ">
                    <div
                        onClick={() =>
                            onboarding.alquiler_minimo > 1 &&
                            setOnboarding({
                                ...onboarding,
                                alquiler_minimo: onboarding.alquiler_minimo - 1,
                            })
                        }
                        className="cursor-pointer mr-2 h-9 w-9 xl:h-12 xl:w-12 font-bold xl:text-2xl  text-center bg-blue-500 text-white border-4 rounded-md border-blue-500 hover:bg-blue-300 hover:border-blue-300 ">
                        <span>-</span>
                    </div>
                    <div className="h-9 w-9 pt-1 xl:h-12 xl:w-12 font-bold xl:text-2xl  text-center text-blue-500  ">
                        {onboarding.alquiler_minimo}
                    </div>
                    <div
                        onClick={() =>
                            setOnboarding({
                                ...onboarding,
                                alquiler_minimo: onboarding.alquiler_minimo + 1,
                            })
                        }
                        className="cursor-pointer ml-2 h-9 w-9 xl:h-12 xl:w-12 font-bold xl:text-2xl  text-center bg-blue-500 text-white border-4 rounded-md border-blue-500 hover:bg-blue-300 hover:border-blue-300 ">
                        <span>+</span>
                    </div>
                    <div>
                        <p className="py-2 text-2xl pl-2 font-bold text-center sm:py-8 s xl:text-3xl">
                            Noche (s)
                        </p>
                    </div>
                </div>
                <hr className=" border-gray-200 mb-4" />
            </div>
            <h1 className="py-8 -mt-5 text-3xl font-bold text-center sm:py-12 sm:text-5xl">
                Variacion por fecha
            </h1>
            <div className="flex w-full">
                <button
                    className="inline-flex items-center px-3 py-2 mx-auto text-lg font-semibold text-blue-700 border border-blue-700 rounded group hover:bg-blue-700 hover:text-white"
                    onClick={() => setcreate(!create)}>
                    <svg
                        className="w-5 h-5 text-blue-700 group-hover:text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                    <span className="items-center">Añadir</span>
                </button>
            </div>
            {loading ? <Spinner width={20} /> : null}
            {create && (
                <ModalNights
                    create={create}
                    setcreate={setcreate}
                    onboarding={onboarding}
                />
            )}
            {nightupdate && (
                <ModalEditNights
                    nightupdate={nightupdate}
                    setNightupdate={setNightupdate}
                />
            )}

            <section
                className={
                    availability.length < 1
                        ? "hidden"
                        : "flex justify-center items-center lg:py-[10px] mt-2 "
                }>
                <div className="container px-8">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full ">
                            <div className="flex max-w-full overflow-x-auto text-center">
                                <table className="w-full table-auto">
                                    <thead className="bg-blue-700">
                                        <tr className="text-center bg-primary">
                                            <th className="w-1/12  text-lg font-semibold text-white py-4 ">
                                                Desde
                                            </th>

                                            <th className="w-1/12 text-lg font-semibold text-white py-4 ">
                                                Hasta
                                            </th>

                                            <th className="w-1/12  text-lg font-semibold text-white py-4 "></th>
                                        </tr>
                                    </thead>
                                    {availability?.map((nights, i) => (
                                        <tbody key={i}>
                                            <tr>
                                                <td
                                                    className={
                                                        "text-center text-dark font-medium text-base py-1 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                    }>
                                                    {nights?.fecha_inicio
                                                        .split("-")
                                                        .reverse()
                                                        .join("-")}
                                                </td>

                                                <td
                                                    className={
                                                        "text-center text-dark font-medium text-base py-1 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                    }>
                                                    {nights?.fecha_final
                                                        .split("-")
                                                        .reverse()
                                                        .join("-")}
                                                </td>

                                                <td
                                                    className={
                                                        "text-center text-dark font-medium text-base py-1 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                    }>
                                                    {
                                                        <div>
                                                            <button
                                                                onClick={() =>
                                                                    setNightupdate(
                                                                        nights
                                                                    )
                                                                }
                                                                title="Editar"
                                                                className="inline-block px-1 py-1 mx-3 text-blue-700 border border-blue-500 rounded group hover:bg-blue-700 hover:white">
                                                                <svg
                                                                    className="w-8 h-8 text-blue-500 group-hover:text-white"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                    />
                                                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                    <line
                                                                        x1="16"
                                                                        y1="5"
                                                                        x2="19"
                                                                        y2="8"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <button
                                                                onClick={() =>
                                                                    eliminarNoches(
                                                                        nights
                                                                    )
                                                                }
                                                                title="Eliminar"
                                                                className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white">
                                                                <svg
                                                                    className="w-8 h-8 text-red-500 group-hover:text-white"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round">
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                    />
                                                                    <line
                                                                        x1="4"
                                                                        y1="7"
                                                                        x2="20"
                                                                        y2="7"
                                                                    />
                                                                    <line
                                                                        x1="10"
                                                                        y1="11"
                                                                        x2="10"
                                                                        y2="17"
                                                                    />
                                                                    <line
                                                                        x1="14"
                                                                        y1="11"
                                                                        x2="14"
                                                                        y2="17"
                                                                    />
                                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="flex items-center justify-center mt-20">
                <button
                    onClick={() => setPage(actualPage - 1)}
                    className="p-2 pl-5 pr-5 mr-2 text-lg text-gray-500 min-w-[118px] bg-transparent border-2 border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 ">
                    Anterior
                </button>

                <button
                    disabled={!siguiente}
                    onClick={() => nextPage()}
                    className="p-2 pl-5 pr-5 text-lg text-gray-500 bg-transparent border-2 min-w-[118px] border-gray-500 rounded-lg hover:bg-blue-500 hover:text-gray-100 hover:border-blue-600 disabled:bg-white disabled:border-black">
                    {siguiente ? siguiente : <Spinner width={7} />}
                </button>
            </div>
        </div>
    );
};

export default VariableNights;
