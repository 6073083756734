import {
    GET_ALL_LABELS,
    CREATE_LABEL,
    RESET_CREATE_LABEL,
    ONE_LABELS,
    SEARCH_LABELS,
    RESET_ONE_LABEL,
    ENABLE_LABELS,
    RESET_ENEBLE_LABELS,
} from "../actions/labelsActions";

const initialState = {
    labels: [],
    create: false,
    oneLabels: [],
    enableLabels: [],
};

export const labelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_LABELS:
            return {
                ...state,
                labels: action.payload,
            };
        case CREATE_LABEL:
            return {
                ...state,
                create: action.payload,
            };
        case RESET_CREATE_LABEL:
            return {
                ...state,
                create: false,
            };
        case ONE_LABELS:
            return {
                ...state,
                oneLabels: action.payload,
            };
        case SEARCH_LABELS:
            return {
                ...state,
                labels: action.payload,
            };
        case RESET_ONE_LABEL:
            return {
                ...state,
                oneLabels: action.payload,
            };
        case ENABLE_LABELS:
            return {
                ...state,
                enableLabels: action.payload,
            };
        case RESET_ENEBLE_LABELS:
            return {
                ...state,
                enableLabels: [],
            };

        default:
            return state;
    }
};
