import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import ButtonCopyToClipboard from "../../ButtonCopyToClipboard";
import feeBC from "../../helpers/feeBC";
const EditarClaveExterna = ({ producto, setProducto, setModal }) => {
    const [cargando, setCargando] = useState(false);

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const alert = (alert) => {
        Swal.fire({
            title: `Debes completar el campo de: ${alert}`,
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Completar!",
        });
    };

    const updateProduct = async () => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/casa/updateClave/${producto.data.id}`,
            {},
            { headers }
        );
        console.log(data);
        if (data.message === "Actualizado con exito") {
            Swal.fire({
                title: `Nueva clave externa generada`,
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Completar!",
            });
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    clave_externa: data.casa.clave_externa,
                },
            });
            setModal(false);
        }
    };

    const handleGuardar = () => {
        setModal(false);
    };
    console.log(producto);
    return (
        <div className="p-4 mt-2 border rounded-lg">
            <div className="w-full mt-3 ">
                <div className="">
                    <div className="w-full mx-auto my-2">
                        <label className=" w-full text-base  text-[#3C3C3C]">
                            Clave externa
                        </label>
                        <div className="flex flex-col items-center w-full mt-2 xs:flex-row">
                            <div className="flex items-center">
                                <label className="text-lg font-medium ">
                                    Clave:
                                </label>

                                <div className="  ml-1 px-3 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400 flex justify-between items-center">
                                    <span className="mr-2 text-gray-400">
                                        {producto.data.clave_externa
                                            ? producto.data.clave_externa
                                            : "Aun no tienes una clave externa"}
                                    </span>
                                    {producto.data.clave_externa && (
                                        <ButtonCopyToClipboard
                                            confirmationText="Clave copiada al portapapeles correctamente"
                                            textToCopy={
                                                producto.data.clave_externa
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <button
                                onClick={() => updateProduct()}
                                disabled={cargando}
                                className="px-3 py-2 mt-2 ml-2 border rounded-lg xs:mt-0 hover:scale-105 border-blue-bluecorner text-blue-bluecorner disabled:bg-slate-300 disabled:text-white disabled:border-white disabled:cursor-not-allowed">
                                General clave
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarClaveExterna;
