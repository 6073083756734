import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import feeBC from "../../helpers/feeBC";
const EditarAdministrador = ({
    producto,
    setProducto,
    setModal,
    administradores,
}) => {
    const [input, setInput] = useState({
        id_encargado: producto.data?.id_encargado,
    });

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleInputChange = (id) => {
        setInput({
            id_encargado: id,
        });
    };

    const alert = (alert) => {
        Swal.fire({
            title: `Debes completar el campo de: ${alert}`,
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Completar!",
        });
    };

    const updateProduct = async (payload) => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${producto.data.tipo_producto}/updateEncargado/${producto.data.id}`,
            payload,
            { headers }
        );

        if (data.message === "Actualizado con exito") {
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    ...payload,
                },
            });
            setModal(false);
        }
    };

    const handleGuardar = () => {
        const payload = {
            id_encargado: input.id_encargado,
        };
        updateProduct(payload);
    };

    return (
        <div className="p-4 border rounded-lg">
            <div className="w-full mt-3 ">
                <div className="">
                    <div className="mx-auto my-2 w-72">
                        <label className=" w-full text-base text-center text-[#3C3C3C]">
                            Administrador de la propiedad
                        </label>
                    </div>

                    <div className="h-64 mx-auto overflow-y-auto w-72 xs:w-80 ">
                        {administradores.map((administrador) => (
                            <div
                                onClick={() =>
                                    handleInputChange(administrador.id)
                                }
                                className={
                                    input.id_encargado === administrador.id
                                        ? "w-60 xs:w-72 items-center  p-2 m-1  text-center   border-2 border-gray-400 rounded-lg  bg-blue-500 text-white cursor-pointer"
                                        : "w-60 xs:w-72 items-center p-2 m-1  text-center border-2 border-gray-400 rounded-lg  hover:bg-blue-500 hover:text-white cursor-pointer"
                                }
                                key={administrador.id}
                                value={administrador.id}>
                                {administrador.personal}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarAdministrador;
