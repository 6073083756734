import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginEmpresaUser, loginUser } from "../../redux/actions/authActions";
import { useNavigate, useParams } from "react-router";
import ModalOlvidadoContraseña from "../contraseña/ModalOlvidadoContraseña";
import jwt_decode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";
import NavBar from "../NavBar";
import Footer from "../Footer";
import useColores from "../../hooks/useColores";
import useHeaders from "../../hooks/useHeaders";
import Spinner from "../Spinner";

const EmpleadoEmpresaLogin = () => {
    const emailStorage = localStorage.getItem("email");
    const [errors, setErrors] = useState({});
    const [showPass, setShowPass] = useState(false);
    const [olvido, setOlvido] = useState(false);
    const [login, setLogin] = useState({
        correo: emailStorage || "",
        password: "",
        rememberme: false,
    });
    const [infoEmpresa, setInfoEmpresa] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { headers } = useHeaders();
    let { primario, secundario, setColores } = useColores();
    const { idEmpresa, nameEmpresa } = useParams();
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        getEmpresaThemes();

        return () => {
            localStorage.removeItem("logo_empresa");
            localStorage.removeItem("background_navbar");
        };
    }, []);

    useEffect(() => {
        if (authUser.tipo_usuario) {
            if (authUser.tipo_usuario < 3) {
                navigate("/dashboard/anuncios");
            } else if (
                authUser.tipo_usuario === 3 ||
                authUser.tipo_usuario === 4
            ) {
                navigate("/perfil/anuncios");
            } else if (authUser.tipo_usuario === 6) {
                authUser.logo === "sinImagen.png"
                    ? navigate("/miEmpresa")
                    : navigate("/dashboard-empresas/usuarios");
            } else {
                navigate("/");
            }
        }
    }, [authUser]);

    const getEmpresaThemes = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/empresas/detalle/${idEmpresa}`,
            { headers }
        );

        if (data.id) {
            setColores({
                primario: data.color_primario,
                secundario: data.color_secundario,
            });
            localStorage.setItem("background_navbar", data.color_primario);
            localStorage.setItem("logo_empresa", data.logo);
            setInfoEmpresa(data);
        }
    };

    const validateInput = (form) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores = {};
        if (!regExpEmail.test(form.correo)) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        }
        return errores;
    };

    const handleInputChange = (e) => {
        if (e.target.name === "rememberme") {
            setLogin({
                ...login,
                rememberme: e.target.checked,
            });
        } else {
            setLogin({
                ...login,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateInput(login);
        setErrors(errors);
        if (Object.entries(errors).length === 0) {
            if (login.rememberme) localStorage.setItem("email", login.correo);
            else localStorage.removeItem("email");
            dispatch(loginEmpresaUser(login.correo, login.password, idEmpresa));
        }
    };

    window.handleCredentialResponse = function (res) {};

    return infoEmpresa?.id ? (
        <div>
            <NavBar />
            <div
                className={`text-white text-center py-24 mt-32`}
                style={{ backgroundColor: primario }}>
                <div className="flex justify-center text-4xl font-bold ">
                    <h1>Mi cuenta -</h1>{" "}
                    <h1 className="capitalize ml-2.5">{infoEmpresa?.nombre}</h1>
                </div>
            </div>
            <div className="grid-cols-1 lg:gap-10 lg:mx-auto md:grid xl:w-4/5">
                <div>
                    {olvido && (
                        <ModalOlvidadoContraseña
                            olvido={olvido}
                            setOlvido={setOlvido}
                        />
                    )}
                    <NavBar />
                    <div
                        className={
                            "p-5 mt-10 mb-14 w-[340px] mx-auto shadow-xl"
                        }>
                        <h1
                            className={`text-4xl font-bold `}
                            style={{ color: primario }}>
                            Iniciar sesión
                        </h1>

                        <form
                            // className={medioDeRegistro === "Correo" ? "" : "hidden"}
                            onSubmit={(e) => handleSubmit(e)}>
                            <div className="w-full mt-4">
                                <label className="font-semibold">
                                    Correo electrónico:
                                    <span className="text-xl text-red-600">
                                        *
                                    </span>
                                </label>
                                {errors.correo && (
                                    <p className="my-1 text-sm font-bold text-red-500 text-md">
                                        {errors.correo}
                                    </p>
                                )}
                                <input
                                    type="text"
                                    value={login.correo}
                                    name="correo"
                                    onChange={handleInputChange}
                                    placeholder="Ingrese su correo electrónico"
                                    className="w-full p-3 rounded-lg outline-none - ring-2 ring-gray-200"
                                />
                            </div>
                            <div className="w-full mt-4">
                                <label className="font-semibold ">
                                    Contraseña:
                                    <span className="text-xl text-red-600">
                                        *
                                    </span>
                                </label>
                                {errors.password && (
                                    <p className="my-1 text-sm font-bold text-red-500 text-md">
                                        {errors.password}
                                    </p>
                                )}
                                <div className="flex items-center w-full rounded-lg - ring-2 ring-gray-200 ">
                                    <input
                                        type={`${
                                            showPass ? "text" : "password"
                                        }`}
                                        name="password"
                                        value={login.password}
                                        onChange={handleInputChange}
                                        className={`w-full p-3 placeholder-gray-500 rounded-lg focus:outline-none focus:border-[${secundario}]`}
                                        placeholder="Ingresa tu contraseña"
                                    />

                                    {showPass ? (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                style={{ color: secundario }}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                />
                                            </svg>
                                        </>
                                    ) : (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                style={{ color: secundario }}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                />
                                            </svg>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center mt-4">
                                <input
                                    type="checkbox"
                                    name="rememberme"
                                    className="mr-2 "
                                    onChange={handleInputChange}
                                />
                                <h2 className="text-base font-semibold">
                                    Recuérdame
                                </h2>
                            </div>
                            <button
                                type="submit"
                                className={`p-2 px-4 text-lg font-semibold text-white mt-4 rounded-lg`}
                                style={{ backgroundColor: primario }}>
                                Acceder
                            </button>
                            <p
                                onClick={() => setOlvido(true)}
                                className={`block mt-5 text-center text-lg cursor-pointer`}
                                style={{ color: secundario }}>
                                ¿Olvidaste la contraseña?
                            </p>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    ) : (
        <div className="flex items-center justify-center h-screen">
            <Spinner width={16} />
        </div>
    );
};

export default EmpleadoEmpresaLogin;
