import axios from "axios";

export const getUsersPerPage = (pageNumber = 1, SioNo) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios(
      `${process.env.REACT_APP_PROXY}/autenticacion/all/${pageNumber}/${SioNo}`,
      { headers }
    );
    return dispatch({
      type: "GET_USERS",
      payload: json.data,
    });
  };
};

export const userSelected = (id) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const user = await axios(
      `${process.env.REACT_APP_PROXY}/autenticacion/detalle/${id}`,
      { headers }
    );
    return dispatch({
      type: "USER_SELECTED",
      payload: {
        user: user.data.data,
      },
    });
  };
};

export const searchUsersByName = (query, SioNo) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    const headers = {
      authorization: token,
    };
    const json = await axios.get(
      `${process.env.REACT_APP_PROXY}/autenticacion/all/1/${SioNo}/?nombre=${query}`,
      { headers }
    );
    return dispatch({
      type: "SEARCH_USERS",
      payload: json.data,
    });
  };
};

export const clearUserSelected = () => {
  return async function (dispatch) {
    return dispatch({
      type: "CLEAR_USER_SELECTED",
    });
  };
};

export const clearAllUsers = () => {
  return async function (dispatch) {
    return dispatch({
      type: "CLEAR_ALL_USERS",
    });
  };
};
