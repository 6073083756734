import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../../Spinner";

const EditarFee = ({ producto, setProducto, setModal }) => {
    const [input, setInput] = useState({
        descuento_empresa_free: producto.data?.descuento_empresa_free || 0,
        descuento_empresa_premium:
            producto.data?.descuento_empresa_premium || 0,
    });

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const updateProduct = async (payload) => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/${producto.data.tipo_producto}/updateDescuentoEmpresa/${producto.data.id}`,
            payload,
            { headers }
        );

        if (data.message === "actualizacion exitoso") {
            setProducto({
                ...producto,
                data: {
                    ...producto.data,
                    ...payload,
                },
            });
            setModal(false);
        }
    };

    const handleGuardar = () => {
        const payload = {
            descuento_empresa_free: input.descuento_empresa_free,
            descuento_empresa_premium: input.descuento_empresa_premium,
        };
        if (payload.descuento_empresa_free === "")
            payload.descuento_empresa_free = 0;
        if (payload.descuento_empresa_premium === "")
            payload.descuento_empresa_premium = 0;
        updateProduct(payload);
    };

    return (
        <div className="p-4 border rounded-lg">
            <div className="w-full mt-3 ">
                <div className="w-1/2 ">
                    <div className="w-32 mt-2 ">
                        <label className=" w-full text-base text-[#3C3C3C]">
                            Descuento empresas free
                        </label>
                    </div>

                    <div className="flex items-center justify-center w-32 px-3 py-3 border-2 rounded-lg">
                        <input
                            autoComplete="off"
                            className="w-full rounded-lg outline-none"
                            placeholder="15"
                            name="descuento_empresa_free"
                            type="number"
                            step=".01"
                            onChange={handleInputChange}
                            value={input.descuento_empresa_free}
                        ></input>
                        <span>
                            <svg
                                className="w-4 h-4 text-[#3C3C3C] rounded-lg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="19" y1="5" x2="5" y2="19" />{" "}
                                <circle cx="6.5" cy="6.5" r="2.5" />{" "}
                                <circle cx="17.5" cy="17.5" r="2.5" />
                            </svg>
                        </span>
                    </div>
                </div>

                <div className="w-1/2 ">
                    <div className="w-32 mt-2 ">
                        <label className=" w-full text-base text-[#3C3C3C]">
                            Descuento empresas Premium
                        </label>
                    </div>

                    <div className="flex items-center justify-center w-32 px-3 py-3 border-2 rounded-lg">
                        <input
                            autoComplete="off"
                            className="w-full rounded-lg outline-none"
                            placeholder="15"
                            name="descuento_empresa_premium"
                            type="number"
                            step=".01"
                            onChange={handleInputChange}
                            value={input.descuento_empresa_premium}
                        ></input>
                        <span>
                            <svg
                                className="w-4 h-4 text-[#3C3C3C] rounded-lg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="19" y1="5" x2="5" y2="19" />{" "}
                                <circle cx="6.5" cy="6.5" r="2.5" />{" "}
                                <circle cx="17.5" cy="17.5" r="2.5" />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7"
                >
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarFee;
