import axios from "axios";
import React, { useState } from "react";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const DetailedMembresia = ({ membresia, getMembresiasEmpresa }) => {
    const authUser = useSelector((state) => state.authReducer);
    const [pagoAceptado, setPagoAceptado] = useState("Aceptado");
    const [input, setInput] = useState({
        motivo_rechazado: "",
        checking: false,
    });

    const { headers } = useHeaders();
    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        if (pagoAceptado !== "Aceptado") {
            if (input.motivo_rechazado === "") {
                return Swal.fire({
                    icon: "warning",
                    text: "Debes especificar el motivo de rechazo",
                    confirmButtonText: "Aceptar",
                });
            }
        }
        setInput({
            ...input,
            checking: true,
        });

        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/empresaSuscripcion/putTransaccion/${membresia.id}`,
            {
                estado: pagoAceptado,
                motivo_rechazado: input.motivo_rechazado,
            },
            { headers }
        );
        console.log(data);
        if (data.message === "actualizacion exitoso") {
            Swal.fire({
                icon: "success",
                text: "Actualizacion exitosa",

                confirmButtonText: "Aceptar",
            });
            setInput({
                ...input,
                checking: false,
            });
            getMembresiasEmpresa();
        }
    };

    if (!membresia)
        return (
            <div className="mt-4 p-4 border-2 rounded-md font-medium md:text-lg min-h-[10rem] flex justify-center items-center text-center w-full">
                Selecciona una membresia para ver sus detalles
            </div>
        );
    return (
        <div className="mt-4 p-4 border-2 rounded-md min-h-[10rem] flex flex-col w-full">
            <h1>
                Plan de membresía{" "}
                <b className="font-medium">{membresia.plan}</b>
            </h1>
            <div className="flex gap-1">
                <span className="text-gray-500">
                    Desde {membresia.fecha_inicio}
                </span>
                <span className="text-gray-500">
                    Hasta {membresia.fecha_final}
                </span>
            </div>
            <div className="flex flex-col gap-2 mt-1 select-none">
                <div
                    className={`flex flex-col w-full px-4 py-2 mt-4 border rounded-md md:mt-0 `}>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col ">
                            <div className="flex">
                                <span>
                                    <b className="font-medium">
                                        {" "}
                                        Detalle de Pago
                                    </b>
                                </span>
                                {membresia.estado === "Revisión" && (
                                    <div className="relative">
                                        <span className="absolute inline-flex w-4 h-4 bg-red-400 rounded-full animate-ping left-[2px] top-[-5px]"></span>
                                        <span className="absolute flex items-center justify-center w-4 h-4 text-xs font-light text-center left-[2px] top-[-5px] text-white bg-red-500 rounded-full ">
                                            {" "}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mt-2">
                                <span>
                                    Estado:{" "}
                                    <b
                                        className={` ${
                                            membresia.estado === "Aceptado"
                                                ? "text-green-600"
                                                : membresia.estado ===
                                                  "Revision"
                                                ? "text-orange-400"
                                                : "text-red-400"
                                        } font-medium`}>
                                        {membresia.estado}
                                    </b>
                                </span>
                                {membresia.estado === "Aceptado" && (
                                    <span>
                                        Fecha de pago:{" "}
                                        <b className="font-medium">
                                            {membresia.fecha_pago}
                                        </b>
                                    </span>
                                )}
                                {/* {membresia.estado === "Revision" && (
                                    <span>
                                        Fecha de caducidad:{" "}
                                        <b className="font-medium">
                                            {membresia.fecha_caducidad}
                                        </b>
                                    </span>
                                )} */}
                                {membresia.estado === "Aceptado" && (
                                    <span>
                                        Método de pago:{" "}
                                        <b className="font-medium">
                                            {membresia.metodo === "Stripe"
                                                ? "Tarjeta de crédito"
                                                : "Transferencia bancaria"}
                                        </b>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div
                            className={`${
                                membresia.estado === "Aceptado"
                                    ? "text-gray-500"
                                    : "text-gray-300"
                            } font-bold md:text-3xl`}>
                            USD {membresia.costo.toFixed(2)}
                        </div>
                    </div>
                    {membresia.metodo === "Transaccion" && (
                        <div>
                            {membresia.imagen && (
                                <div className="flex flex-col font-medium">
                                    Imagen de comprobante
                                    <img
                                        className="mt-3"
                                        src={`${process.env.REACT_APP_PROXY}/empresaSuscripcion/getImage/${membresia.imagen}`}
                                    />
                                </div>
                            )}

                            <select
                                value={pagoAceptado}
                                onChange={(e) => {
                                    setPagoAceptado(e.target.value);
                                }}
                                className={
                                    authUser.tipo_usuario < 3
                                        ? "w-full p-2 mt-4 border rounded-lg"
                                        : "hidden"
                                }>
                                <option value={"Aceptado"}>
                                    Pago aceptado
                                </option>
                                <option value={"Rechazado"}>
                                    Pago rechazado
                                </option>
                            </select>
                            {pagoAceptado === "Rechazado" && (
                                <div className="flex flex-col w-full mt-4">
                                    <h1>
                                        Detalle la razón de rechazo a
                                        continuación
                                    </h1>
                                    <textarea
                                        value={input.motivo_rechazado}
                                        className="w-full h-32 p-2 mt-4 border"
                                        name="motivo_rechazado"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                            <button
                                disabled={input.checking}
                                onClick={() => handleSubmit()}
                                className={
                                    authUser.tipo_usuario < 3
                                        ? "w-full disabled:bg-gray-400 rounded-lg bg-blue-bluecorner py-3 text-white  active:translate-y-[0.125rem] mt-10"
                                        : "hidden"
                                }>
                                Guardar
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DetailedMembresia;
