import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import feeBC from "../../helpers/feeBC";
const EditarPrecioPorEmbarque = ({ producto, setProducto, setModal }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const updateProduct = async (payload) => {
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY}/embarcacion/actualizarEmbarque/${producto.data.id}`,
            payload,
            { headers }
        );

        if (data.message === "actuaizacion exitoso") {
            setModal(false);
        }
    };

    const handleGuardar = () => {
        updateProduct({
            tipo_moneda_embarque: producto.data.tipo_moneda_embarque
                ? producto.data.tipo_moneda_embarque
                : "PEN",
            precio_embarque: producto.data.precio_embarque,
        });
    };

    return (
        <div className="border p-4 rounded-lg">
            <h1>Precio de embarque por persona</h1>
            <div className="flex mt-2">
                <div className="flex justify-center items-center mr-3">
                    <p className="ml-1 text-base font-semibold ">Moneda: </p>
                    <select
                        className="p-1 ml-1 border rounded-lg outline-none"
                        value={producto.data.tipo_moneda_embarque}
                        onChange={(e) =>
                            setProducto({
                                ...producto,
                                data: {
                                    ...producto.data,
                                    tipo_moneda_embarque: e.target.value,
                                },
                            })
                        }>
                        <option value="PEN">PEN</option>
                        <option value="USD">USD</option>
                    </select>
                </div>
                <div>
                    <label className="mx-2 text-base font-semibold ">
                        Valor por persona:
                    </label>
                    <input
                        value={
                            producto.data.precio_embarque
                                ? producto.data.precio_embarque
                                : ""
                        }
                        type="number"
                        name="precio_embarque"
                        placeholder="100"
                        className="rounded-lg border border-[#E9E9E9] outline-none w-16 py-2 placeholder:text-center text-center "
                        onChange={(e) =>
                            setProducto({
                                ...producto,
                                data: {
                                    ...producto.data,
                                    precio_embarque: e.target.value,
                                },
                            })
                        }
                    />
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => handleGuardar()}
                    className="border border-blue-bluecorner text-blue-bluecorner p-3 px-7 my-6 rounded-lg">
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default EditarPrecioPorEmbarque;
