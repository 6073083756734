import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Paginado } from "../../helpers/Paginado";
import ModalDetallesPedido from "./ModalDetallesPedido";
import ModalDisponibilidad from "./ModalDisponibilidad";
import ModalEstadoPedido from "./ModalEstadoPedido";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const AllpedidosEmbarcaciones = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const [modalDisponibilidad, setModalDisponibilidad] = useState(false);
    const [modalDetalles, setModalDetalles] = useState(false);
    const [modalEstado, setModalEstado] = useState(false);
    const [page, setPage] = useState(1);
    const [habilitados, setHabilitados] = useState(true);
    const [allPedidos, setAllPedidos] = useState({
        checking: true,
        pedidos: [],
        total: 0,
    });
    const [pedidoSelected, setPedidoSelected] = useState({});
    const PEDIDOS_PER_PAGE = 25;
    const estados = [
        "Confirmar Disponibilidad",
        "Pendiente de pago",
        "Pago parcial",
        "Pago completo",
        "Reseña de casa",
        "Devolución de garantía",
    ];
    const getAllPedidos = async (habilitado, pageNumber = 1) => {
        setAllPedidos({
            checking: true,
            pedidos: [],
            total: 0,
        });
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/pedidoEmbarcacion/all/${pageNumber}/${habilitado}/${authUser.id_usuario}`,
            { headers }
        );
        setAllPedidos({
            checking: false,
            pedidos: data.data,
            total: data.total,
        });
    };

    useEffect(() => {
        getAllPedidos("Si");
    }, []);

    useEffect(() => {
        if (habilitados) getAllPedidos("Si");
        else getAllPedidos("No");
    }, [habilitados]);

    const handleDelete = async (idPedido) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará el manual a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/pedidoEmbarcacion/eliminar/${idPedido}`,
                        { headers }
                    )
                    .then((response) => {
                        Swal.fire({
                            title: "Manual eliminado correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllPedidos("Si");
                    });
            }
        });
    };

    const handleEnable = (idPedido) => {
        Swal.fire({
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará el manual  a habilitados!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(
                        `${process.env.REACT_APP_PROXY}/pedidoEmbarcacion/restaurar/${idPedido}`,
                        {},
                        { headers }
                    ) // DEJAR EL BODY VACIO {}, O NO FUNCIONARÁ HEADERS
                    .then((response) => {
                        Swal.fire({
                            title: "Manual restablecido correctamente!",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        });
                        getAllPedidos("No");
                    });
            }
        });
    };

    const handleDetails = async (pedido) => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/pedidoEmbarcacion/detalle/${pedido.id}`,
            { headers }
        );
        setPedidoSelected(data);
        setModalDetalles((prev) => !prev);
    };

    const handleEstado = async (pedido) => {
        setPedidoSelected(pedido);
        setModalEstado(true);
    };

    const pagination = (pageNumber) => {
        getAllPedidos(pageNumber);
        setPage(pageNumber);
    };

    const handleLink = (pedido) => {
        setPedidoSelected(pedido);
        setModalDisponibilidad((prev) => !prev);
    };

    return (
        <section className="bg-white py-20">
            <h1 className="py-10 text-6xl font-bold text-center">
                Pedidos de embarcaciones
            </h1>
            <div className="container flex items-center justify-between p-1 mx-auto">
                <button
                    className={
                        habilitados
                            ? "inline-block p-2 mx-3 text-green-700 border border-green-500 rounded group hover:bg-green-700 hover:text-white"
                            : "inline-block p-2 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white"
                    }
                    onClick={() => {
                        setHabilitados(!habilitados);
                        setPage(1);
                    }}>
                    {habilitados
                        ? `Habilitados (${allPedidos?.total})`
                        : `Deshabilitados (${allPedidos?.total})`}
                </button>
            </div>
            {modalDisponibilidad && (
                <ModalDisponibilidad
                    pedido={pedidoSelected}
                    setModalDisponibilidad={setModalDisponibilidad}
                    modalDisponibilidad={modalDisponibilidad}
                />
            )}
            {modalEstado && (
                <ModalEstadoPedido
                    setModalEstado={setModalEstado}
                    estadoReserva={estados.indexOf(pedidoSelected.estado) + 1}
                />
            )}
            {modalDetalles && (
                <ModalDetallesPedido
                    modalDetalles={modalDetalles}
                    setModalDetalles={setModalDetalles}
                    pedido={pedidoSelected}
                />
            )}
            <Paginado
                elementsPerPage={PEDIDOS_PER_PAGE}
                cantTotalElements={allPedidos.total}
                pagination={pagination}
            />
            <div className="container mx-auto mt-10">
                <div className="flex flex-wrap mx-auto">
                    <div className="w-full px-4">
                        <div className="max-w-full overflow-x-auto">
                            {allPedidos.checking ? (
                                <div className="w-full overflow-hidden ">
                                    <svg
                                        role="status"
                                        className="w-12 h-12 mx-auto text-gray-200 animate-spin dark:text-black fill-blue-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                </div>
                            ) : allPedidos.pedidos.length ? (
                                <table className="w-full table-auto">
                                    <thead>
                                        <tr className="text-center bg-blue-700">
                                            <th className="w-[4%]  text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4">
                                                #
                                            </th>
                                            <th
                                                className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              ">
                                                Nombre de embarcacion
                                            </th>
                                            <th
                                                className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              ">
                                                Nombre de cliente
                                            </th>
                                            <th
                                                className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              ">
                                                Fecha de reserva
                                            </th>
                                            <th
                                                className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              ">
                                                Numero de turno
                                            </th>
                                            <th
                                                className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              ">
                                                Hora de entrada
                                            </th>
                                            <th
                                                className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              ">
                                                Hora de salida
                                            </th>

                                            <th
                                                className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              ">
                                                Estado
                                            </th>
                                            <th
                                                className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              ">
                                                Costo total alquiler
                                            </th>
                                            <th
                                                className="
                              w-1/6
                              min-w-[160px]
                              text-lg
                              font-semibold
                              text-white
                              py-4
                              lg:py-7
                              px-3
                              lg:px-4
                              border-l border-transparent
                              ">
                                                Costo total
                                            </th>
                                            <th
                                                className={
                                                    habilitados
                                                        ? "w-1/6  min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent "
                                                        : "w-1/12 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent "
                                                }>
                                                Acciones
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {allPedidos?.pedidos?.map(
                                            (pedido, index) => {
                                                return (
                                                    <tr key={pedido.id}>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {page * 25 -
                                                                25 +
                                                                index +
                                                                1}
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {pedido.embarcacion}
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {pedido.cliente}
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {
                                                                pedido.fecha.split(
                                                                    "T"
                                                                )[0]
                                                            }
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {pedido.turno}
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {
                                                                pedido.hora_entrada
                                                            }
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {pedido.hora_salida}
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {pedido.estado}
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {
                                                                pedido.costo_total_alquiler
                                                            }{" "}
                                                            {pedido.tipo_moneda}
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {pedido.costo_total}{" "}
                                                            {pedido.tipo_moneda}
                                                        </td>
                                                        <td
                                                            className={
                                                                habilitados
                                                                    ? "text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]"
                                                                    : "text-center text-dark font-medium text-base py-5 px-2 bg-red-300 border-b border-l border-[#E8E8E8]"
                                                            }>
                                                            {habilitados ? (
                                                                <div className="flex">
                                                                    <button
                                                                        className="inline-block px-1 py-1 mx-3 text-blue-500 border border-blue-500 rounded group hover:bg-blue-500 hover:text-white"
                                                                        title="Detalles bancarios"
                                                                        onClick={() =>
                                                                            handleDetails(
                                                                                pedido
                                                                            )
                                                                        }>
                                                                        <svg
                                                                            className="w-8 h-8 text-blue-500 group-hover:text-white"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />{" "}
                                                                            <polyline points="14 2 14 8 20 8" />{" "}
                                                                            <line
                                                                                x1="16"
                                                                                y1="13"
                                                                                x2="8"
                                                                                y2="13"
                                                                            />{" "}
                                                                            <line
                                                                                x1="16"
                                                                                y1="17"
                                                                                x2="8"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <polyline points="10 9 9 9 8 9" />
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                        className="inline-block px-1 py-1 mx-3 text-indigo-500 border border-indigo-500 rounded group hover:bg-indigo-500 hover:text-white"
                                                                        title="Estado de la reserva"
                                                                        onClick={() =>
                                                                            handleEstado(
                                                                                pedido
                                                                            )
                                                                        }>
                                                                        <svg
                                                                            className="h-8 w-8 text-indigo-500 group-hover:text-white"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />{" "}
                                                                            <circle
                                                                                cx="5"
                                                                                cy="12"
                                                                                r="1"
                                                                            />{" "}
                                                                            <circle
                                                                                cx="12"
                                                                                cy="12"
                                                                                r="1"
                                                                            />{" "}
                                                                            <circle
                                                                                cx="19"
                                                                                cy="12"
                                                                                r="1"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                    <div
                                                                        className="cursor-pointer inline-block px-1 py-1 mx-3 text-green-500 border border-green-500 rounded group hover:bg-green-500 hover:text-white"
                                                                        title="Link de pago"
                                                                        onClick={() =>
                                                                            handleLink(
                                                                                pedido
                                                                            )
                                                                        }>
                                                                        <svg
                                                                            className="w-8 h-8 text-green-500 group-hover:text-white"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor">
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth="2"
                                                                                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                    <Link
                                                                        to={`/reviews/pedidoEmbarcacion/${pedido.id}`}>
                                                                        <button
                                                                            className="inline-block px-1 py-1 mx-3 text-teal-500 border border-teal-500 rounded group hover:bg-teal-700 hover:text-white"
                                                                            title="Reseña">
                                                                            <svg
                                                                                class="h-8 w-8 text-teal-500 group-hover:text-white"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round">
                                                                                {" "}
                                                                                <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" />{" "}
                                                                                <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" />
                                                                            </svg>
                                                                        </button>
                                                                    </Link>
                                                                    <button
                                                                        className="inline-block px-1 py-1 mx-3 text-red-700 border border-red-500 rounded group hover:bg-red-700 hover:text-white"
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                pedido.id
                                                                            )
                                                                        }
                                                                        title="Eliminar">
                                                                        <svg
                                                                            className="w-8 h-8 text-red-500 group-hover:text-white"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />{" "}
                                                                            <line
                                                                                x1="4"
                                                                                y1="7"
                                                                                x2="20"
                                                                                y2="7"
                                                                            />{" "}
                                                                            <line
                                                                                x1="10"
                                                                                y1="11"
                                                                                x2="10"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <line
                                                                                x1="14"
                                                                                y1="11"
                                                                                x2="14"
                                                                                y2="17"
                                                                            />{" "}
                                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <button
                                                                        onClick={() =>
                                                                            handleEnable(
                                                                                pedido.id
                                                                            )
                                                                        }
                                                                        title="Habilitar"
                                                                        className="inline-block px-1 py-1 mx-3 text-green-700 border border-green-500 rounded group hover:bg-green-700 hover:white">
                                                                        <svg
                                                                            className="w-8 h-8 text-green-500 group-hover:text-white"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round">
                                                                            {" "}
                                                                            <polyline points="17 1 21 5 17 9" />{" "}
                                                                            <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                            <polyline points="7 23 3 19 7 15" />{" "}
                                                                            <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : habilitados ? (
                                <h1 className="text-3xl text-center overflow-hidden h-12">
                                    Aun no tienes ningun pedido :(
                                </h1>
                            ) : (
                                <h1 className="text-3xl text-center overflow-hidden h-12">
                                    Aun no tienes ningun pedido deshabilitado :(
                                </h1>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AllpedidosEmbarcaciones;
