import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import useHeaders from "../../../hooks/useHeaders";
import { ReactSortable } from "react-sortablejs";

export const ModalAgregarCasa = ({ setModalAgregar, modalAgregar }) => {
    const [input, setInput] = useState("");
    const [info, setInfo] = useState(modalAgregar);
    const [casaSelect, setCasaSelect] = useState({
        id_casa: null,
        nombre: "",
        precio_antes: "",
        precio_despues: "",
        tipo_moneda: "PEN",
        nombre: "",
    });
    const [casasDelGrupo, setCasasDelGrupo] = useState([]);
    const [fields, setFields] = useState([]);
    const { headers } = useHeaders();
    const debounceInput = useRef(null);
    const handleClose = () => {
        setModalAgregar({
            activo: false,
            id_grupo: null,
            fecha_inicio: "",
            fecha_final: "",
        });
    };

    useEffect(() => {
        getCasas(modalAgregar.id_grupo);
    }, []);

    const handleDragEnd = async () => {
        const allHousesID = casasDelGrupo.map((house) => house.id);

        console.log(allHousesID);
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casaBlueSale/updateOrden/`,
                {
                    array_orden: allHousesID,
                    id_grupos_blue_sale: info.id_grupo,
                },
                { headers }
            );
            console.log(data);
        } catch (error) {
            console.log(error.response);
        }
    };

    const getCasas = async (id) => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/casaBlueSale/all/${id}`,
            { headers }
        );

        console.log(json.data);
        setCasasDelGrupo(json.data);
    };

    const crearCasa = async () => {
        if ((casaSelect.precio_antes > 0) & (casaSelect.precio_despues > 0)) {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/casaBlueSale/`,
                {
                    id_grupos_blue_sale: info.id_grupo,
                    id_casa: casaSelect.id_casa,
                    precio_antes: casaSelect.precio_antes,
                    precio_despues: casaSelect.precio_despues,
                    tipo_moneda: casaSelect.tipo_moneda,
                },
                { headers }
            );

            setCasaSelect({
                id_casa: null,
                nombre: "",
                precio_antes: "",
                precio_despues: "",
                tipo_moneda: "PEN",
                nombre: "",
            });
            getCasas(modalAgregar.id_grupo);
        } else {
            Swal.fire({
                title: "Debes seleccionar una precio de antes y uno de despues",
                icon: "warning",
                confirmButtonText: "Ok",
            });
        }
    };

    const handleInputChangeDebounce = (e) => {
        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            setInput(e.target.value);
            setFields([]);
            return;
        }
        setInput(e.target.value);

        debounceInput.current = setTimeout(async () => {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/casaBlueSale/searchCasa/${e.target.value}`,
                    {
                        id_grupos_blue_sale: info.id_grupo,
                    },
                    { headers }
                );
                console.log(data);
                setFields(data.data);
            } catch (error) {
                console.log(error.response);
            }
        }, 500);
    };

    const deleteImage = (house_id, house_name) => {
        Swal.fire({
            title: `¿Seguro que quieres eliminar ${house_name} de la promo?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, mejor no",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/casaBlueSale/eliminar/${house_id}`,
                        { headers }
                    );
                    console.log(data);
                    getCasas(modalAgregar.id_grupo);
                } catch (error) {
                    console.log(error.response);
                }
            }
        });
    };

    return (
        <div className={`relative items-center justify-center "flex" `}>
            <div className="fixed bottom-0 left-0 z-20 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 pt-44">
                <div className="flex justify-center px-4 py-8 2xl:container 2xl:mx-auto md:px-10 sm:py-8 ">
                    <div className="relative block justify-center px-4 bg-white rounded-lg  w-11/12 max-w-lg dark:bg-white md:px-10 h-[calc(100vh-30vh)] overflow-y-auto  ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="px-2 mt-10 text-base font-bold text-left lg:text-2xl">
                            {"Agregar casas"}
                        </header>
                        <div className="px-3 rounded-lg mt-7">
                            <label className="flex font-semibold">
                                Desde {info.fecha_inicio} al {info.fecha_final}
                            </label>
                            <div className="relative flex-1 flex flex-col px-3 py-2 border-2 border-gray-300 rounded-md max-w-[400px] mt-2">
                                <div className="flex items-center gap-3">
                                    <svg
                                        className="w-6 h-6 text-gray-400 cursor-pointer"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />{" "}
                                        <polyline points="9 22 9 12 15 12 15 22" />
                                    </svg>
                                    <input
                                        value={input}
                                        onChange={handleInputChangeDebounce}
                                        type={"text"}
                                        placeholder="Busca una casa para agregar"
                                        className="w-full outline-none"
                                    />
                                </div>
                            </div>
                            {fields?.length > 0 && (
                                <div
                                    className={` border-2 rounded-md border-gray-300 p-2  max-w-[400px] max-h-80 mt-0.5 overflow-auto`}>
                                    {fields.map(({ id, nombre, check }) => (
                                        <button
                                            key={id}
                                            onClick={() => (
                                                setCasaSelect({
                                                    ...casaSelect,
                                                    id_casa: id,
                                                    nombre: nombre,
                                                }),
                                                setInput(""),
                                                setFields([])
                                            )}
                                            disabled={check === "No"}
                                            className="w-full p-2 mt-1 bg-white border-2 rounded-md cursor-pointer disabled:bg-gray-100 disabled:cursor-not-allowed">
                                            {nombre}
                                        </button>
                                    ))}
                                </div>
                            )}
                            {casaSelect.id_casa !== null && (
                                <div>
                                    <div className="relative grid max-w-[400px] grid-cols-1 gap-4 mx-auto mt-2">
                                        <div className="flex flex-col ">
                                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                                Casa:
                                            </label>
                                            <input
                                                disabled
                                                autoComplete="off"
                                                type="text"
                                                name="nombre"
                                                value={casaSelect.nombre}
                                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 cursor-not-allowed "
                                                placeholder="Nombre"
                                            />
                                        </div>
                                    </div>
                                    <div className="relative grid max-w-[400px] grid-cols-2 gap-4 mx-auto mt-2">
                                        <div className="flex flex-col ">
                                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                                Precio antes:
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="nombre"
                                                onChange={(e) =>
                                                    setCasaSelect({
                                                        ...casaSelect,
                                                        precio_antes:
                                                            e.target.value,
                                                    })
                                                }
                                                value={casaSelect.precio_antes}
                                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50  "
                                                placeholder="Precio antes"
                                            />
                                        </div>
                                        <div className="flex flex-col ">
                                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                                Precio despues:
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="apellido"
                                                onChange={(e) =>
                                                    setCasaSelect({
                                                        ...casaSelect,
                                                        precio_despues:
                                                            e.target.value,
                                                    })
                                                }
                                                value={
                                                    casaSelect.precio_despues
                                                }
                                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 c"
                                                placeholder="Precio despues"
                                            />
                                        </div>
                                    </div>
                                    <div className="relative grid max-w-[400px] grid-cols-2 gap-4 mx-auto mt-2">
                                        <div className="flex flex-col ">
                                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                                Moneda:
                                            </label>
                                            <select
                                                onChange={(e) =>
                                                    setCasaSelect({
                                                        ...casaSelect,
                                                        tipo_moneda:
                                                            e.target.value,
                                                    })
                                                }
                                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 ">
                                                <option value={"PEN"}>
                                                    PEN
                                                </option>
                                                <option value={"USD"}>
                                                    USD
                                                </option>
                                            </select>
                                        </div>
                                        <div className="flex items-end ">
                                            <p
                                                onClick={() => crearCasa()}
                                                className="w-full p-2 text-center text-white bg-blue-500 border border-blue-500 rounded-lg cursor-pointer hover:bg-blue-400">
                                                Agregar
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {casasDelGrupo?.length > 0 && (
                                <div
                                    className={` border-2 rounded-md border-gray-300 p-2  max-w-[400px] max-h-80 overflow-auto mt-4 mb-2`}>
                                    <p className="pb-1 my-2 font-medium text-center border-b">
                                        Casas Agregadas
                                    </p>
                                    <ReactSortable
                                        onEnd={() => handleDragEnd()}
                                        animation={200}
                                        delayOnTouchStart={true}
                                        delay={2}
                                        list={casasDelGrupo}
                                        setList={setCasasDelGrupo}
                                        // className="w-full grid-cols-2 gap-4 p-4 md:grid lg:grid-cols-3 2xl:grid-cols-4 xl:px-32 2xl:px-28"
                                    >
                                        {casasDelGrupo.map(
                                            ({
                                                id,
                                                casa,
                                                tipo_moneda,
                                                imagenes,
                                                precio_antes,
                                                precio_despues,
                                            }) => (
                                                <div
                                                    key={id}
                                                    className="w-full p-1 mt-1 bg-white border-2 rounded-md cursor-move">
                                                    <div className="relative flex items-center py-1 ">
                                                        <span
                                                            onClick={() =>
                                                                deleteImage(
                                                                    id,
                                                                    casa
                                                                )
                                                            }
                                                            className={`absolute right-2 top-2 p-0.5 text-gray-800 dark:text-gray-400 rounded-sm bg-slate-100 hover:bg-slate-100 z-10`}
                                                            aria-label="close">
                                                            <svg
                                                                className="w-4 h-4 text-blue-500 cursor-pointer"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M6 18L18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <img
                                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/productoImagenes/getImagen/${imagenes[0].imagen_minificada}`}
                                                            alt="Imagen de la reserva"
                                                            className="object-cover h-10 rounded-lg w-14"
                                                        />
                                                        <div className="flex mx-3 ">
                                                            <div className="flex flex-col">
                                                                <h1 className="">
                                                                    {casa}
                                                                </h1>
                                                                <p>
                                                                    <span className="text-xs text-gray-400">
                                                                        Antes :{" "}
                                                                        {precio_antes +
                                                                            " " +
                                                                            tipo_moneda}{" "}
                                                                        - Ahora
                                                                        :{" "}
                                                                        {precio_despues +
                                                                            " " +
                                                                            tipo_moneda}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </ReactSortable>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
