import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const ModalEditPaquete = ({
    paquete,
    setPaquete,
    showServicios,
    setModalVisible,
}) => {
    const [siguiente, setSiguiente] = useState("Crear");
    const [input, setInput] = useState({
        nombre: paquete.nombre,
        descripcion: paquete.descripcion,
        tipo: paquete.tipo,
        precio: paquete.precio,
    });

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleCreate = async (e) => {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        setSiguiente(null);
        e.preventDefault();
        if (!input.nombre.trim() || !input.descripcion.trim()) {
            Swal.fire({
                title: "Error!",
                text: "el paquete debe tener nombre y descripcion",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
            setSiguiente("Crear");
        } else if (input.precio <= 0) {
            Swal.fire({
                title: "Error!",
                text: "el paquete debe tener precio y ser mayor a 0",
                icon: "error",
                width: "350px",
                confirmButtonText: "Aceptar",
            });
            setSiguiente("Crear");
        } else {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/paquetesEmpresas/actualizar/${paquete.id}`,
                input,
                {
                    headers,
                }
            );
            if (data.message === "registro exitoso") {
                Swal.fire({
                    title: "Paquete actualizado correctamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                setInput({
                    nombre: "",
                    description: "",
                    tipo: "",
                    precio: 0,
                });
                setModalVisible(false);
                setPaquete(null);
            }

            setSiguiente("Crear");
        }
    };

    const handleClose = () => {
        setInput({
            nombre: "",
            description: "",
            tipo: "",
            precio: 0,
        });
        setModalVisible(false);
        setPaquete(null);
    };

    return (
        <div className={`relative items-center justify-center "flex"  }`}>
            <div className="fixed bottom-0 left-0 z-20 w-full h-full bg-gray-900 bg-opacity-80 sticky-0 ">
                <div className="flex justify-center px-4 2xl:container 2xl:mx-auto md:px-10 py-24  ">
                    <div className="relative block justify-center px-4 bg-white rounded-lg  w-auto dark:bg-white md:px-10 h-[calc(100vh-10vh)] overflow-y-auto ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <header className="px-2 mt-10 text-base font-bold text-left lg:text-2xl">
                            {showServicios && "Editar servicio"}
                            {!showServicios && "Editar paquete"}
                        </header>
                        <div className="px-3 rounded-lg w-80 mt-7 ">
                            <label className="flex font-semibold">
                                {showServicios && "Nombre del servicio"}
                                {!showServicios && "Nombre del paquete"}
                            </label>
                            <input
                                type="text"
                                value={input.nombre}
                                name="nombre"
                                onChange={handleInputChange}
                                placeholder="Ejemplo: Paquete navideño"
                                className="w-full p-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                            />
                        </div>
                        <div className="px-3 rounded-lg w-80 mt-7 ">
                            <label className="flex font-semibold">
                                {showServicios && "Descripción del servicio"}
                                {!showServicios && "Descripción del paquete"}
                            </label>
                            <textarea
                                type="text"
                                value={input.descripcion}
                                name="description"
                                onChange={handleInputChange}
                                placeholder="Ejemplo: Incluye comida, bebida y decoracion"
                                className="w-full p-3 my-3 bg-transparent border rounded-lg outline-none h-28 focus:outline-none"
                            />
                        </div>
                        <div className="mt-2 w-80">
                            <div className="max-w-md mx-auto overflow-hidden bg-white rounded-lg md:max-w-lg">
                                <div className="md:flex">
                                    <div className="w-full">
                                        <hr className="w-80 " />
                                        <div className="px-3 rounded-lg w-80 mt-7 ">
                                            <label className="flex font-semibold">
                                                Precio
                                            </label>
                                            <div className="flex">
                                                <div className="flex items-center justify-center gap-3">
                                                    <input
                                                        type="number"
                                                        value={input.precio}
                                                        name="nombre"
                                                        onChange={(e) =>
                                                            setInput({
                                                                ...input,
                                                                precio: e.target
                                                                    .value,
                                                            })
                                                        }
                                                        placeholder="Precio"
                                                        className="w-20 p-3 my-3 bg-transparent border rounded-lg outline-none focus:outline-none"
                                                    />
                                                    <p className="ml-[2px] font-bold">
                                                        USD
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="pb-3 text-center w-full">
                                            <button
                                                className="w-full rounded-lg border-1 border-blue-bluecorner bg-blue-bluecorner py-3 font-bold text-white  active:translate-y-[0.125rem]  mt-10 disabled:bg-slate-400 disabled:border-slate-400"
                                                onClick={(e) => handleCreate(e)}
                                                disabled={!siguiente}>
                                                {siguiente
                                                    ? siguiente
                                                    : "Creando.."}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditPaquete;
