import React, { useEffect } from "react";

const SideBarMembresias = ({
    membresias,
    membresiaSelected,
    setMembresiaSelected,
    setModalEstadoDetalle,
}) => {
    useEffect(() => {
        if (membresias.length > 0) {
            setMembresiaSelected(membresias[0]);
        }
    }, []);
    return (
        <div className="flex flex-col gap-4 p-4 mt-4 border-2 rounded-md md:w-3/4 lg:w-1/2">
            <h1 className="w-full font-medium md:text-lg">
                Todas las membresías
            </h1>
            {membresias.map((membresia) => (
                <div
                    key={membresia.id}
                    className={`${
                        membresiaSelected?.id === membresia.id
                            ? "bg-[rgba(26,24,24,0.05)] border-gray-400 border-2"
                            : "border"
                    } py-2 px-4 w-full rounded-md cursor-pointer md:hover:scale-105`}
                    onClick={() => setMembresiaSelected(membresia)}>
                    <h2>
                        Plan de membresía{" "}
                        <b className="font-medium">{membresia.plan}</b>
                    </h2>
                    <div className="flex flex-col">
                        <span className="text-gray-500">
                            Desde {membresia.fecha_inicio}
                        </span>
                        <span className="flex justify-between text-gray-500">
                            <span>Hasta {membresia.fecha_final}</span>
                            {
                                <span
                                    className={`font-semibold
                                     ${
                                         membresia.estado === "Aceptado"
                                             ? "text-green-600"
                                             : membresia.estado === "Revision"
                                             ? "text-orange-400"
                                             : "text-red-400"
                                     }`}>
                                    {membresia.estado}
                                </span>
                            }
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SideBarMembresias;
